import React from 'react';
import { Typography } from '@material-ui/core';

type DescriptionTYpe = {
  children: string | JSX.Element;
};

export const Description = (props: DescriptionTYpe) => (
  <Typography>{props.children}</Typography>
);
