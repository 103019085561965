import React from 'react';
import { connect } from 'react-redux';

import UserModel from 'models/UserModel';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import CheckboxGroup from 'modules/addons/sales_channels/shared/CheckboxGroup';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import Button from 'modules/shared/components/inputs/Button';
import HistoryPanelSection from 'containers/next-gen/components/shared/HistorySection';
import ConfirmationModal from 'modules/addons/sales_channels/shared/ConfirmationModal';
import Table from 'modules/shared/components/table/Table';
import get from 'lodash.get';

import './callcentre-channel-content.scss';
import AddonConfigModel from 'models/AddonConfigModel';
import api from 'api';
import {
  createAddonVersion,
  loadCallcentreAddon,
  updateAddonRuleset,
} from 'modules/addons/actions';
import mixpanel from 'mixpanel-browser';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

type ApplicationTypes = string[];

type AddonAttributes = {
  addon_config_id?: string;
  addon_module_name?: string;
  application_types?: ApplicationTypes;
  config: {};
  name?: string;
};

type NewChanges = {
  callcentre_user_agreement: any;
  active?: boolean;
};

const addon_module_name = 'call_centre';

const instructionsText = (
  <span>
    {`The Call Centre channel is available to those customers who are 1Centre accredited and
comply with our Terms of use. If you would like to discuss turning on this channel, please contact `}
    <a href="mailto:success@1centre.com">success@1centre.com</a>
    {'.'}
  </span>
);

const applicationText =
  'Upon agreeing to the below, you are authorised and acting on behalf of your company to comply with the below minimum standards.';

const checkboxOptions = {
  compliance: {
    label:
      'I confirm that all Call Centre users will be fully trained on the use and compliance of this feature.',
  },
  consultance: {
    label:
      'I confirm that I have consulted with my own legal counsel to turn on this channel. ',
  },
  liability: {
    label:
      'I confirm that 1Centre will not be liable for any misuse of this feature by any user or representative of my business.',
  },
};

const DATA_COLUMNS = [
  {
    Header: 'User',
    accessor: 'full_name',
    filter: 'text',
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableFilters: true,
  },
  {
    Header: 'Tier',
    accessor: 'tier',
    disableFilters: true,
  },
  {
    Header: 'User type',
    accessor: 'role_types',
    disableFilters: true,
  },
];

function transformDataFromResponse(accessor: string, userData) {
  switch (accessor) {
    case 'role_types':
      return userData[accessor].join(',');
    default:
      return userData[accessor];
  }
}

function transformResponseToTableData(userRecords) {
  const data = userRecords || [];
  return data.map((userResponseData) => {
    const { attributes } = userResponseData;

    return DATA_COLUMNS.reduce((userData, columnData) => {
      const { accessor } = columnData;
      userData[accessor] = transformDataFromResponse(accessor, attributes);
      return userData;
    }, {});
  });
}

function initializeAddonModule() {
  return new AddonConfigModel({
    attributes: { addon_module_name },
  });
}

function initializeCallcentreUserAgreement(allChecked?: boolean) {
  return Object.entries(checkboxOptions).map(([key, value]) => {
    return {
      key: key,
      agreement: value.label,
      checked: allChecked || false,
    };
  });
}

function updateCallcentreUserAgreement(checkboxValues) {
  return (
    Object.entries(checkboxOptions).map(([key, value]) => {
      return {
        key: key,
        agreement: value.label,
        checked: checkboxValues.includes(key),
      };
    }) || []
  );
}

function getCallcentreCheckboxValue(callcentreUserAgreement) {
  return Object.values(callcentreUserAgreement).reduce(
    (checkboxValue: string[], value: any) => {
      if (value.checked) checkboxValue.push(value.key);
      return checkboxValue;
    },
    []
  );
}

function getNewVersionAttribute(addonConfig, agreement?: any) {
  const attributes: AddonAttributes = {
    config: {
      callcentre_user_agreement:
        agreement || initializeCallcentreUserAgreement(),
    },
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = 'Call Centre';
    attributes.addon_module_name = addon_module_name;
  }

  return attributes;
}

const CallCentreChannelContent = ({
  allAgreementChecked,
  callcentreActive,
  callcentreData,
  callCentreEnabled,
  callcentreHistory,
  callcentreUserAgreementCheckbox,
  companyName,
  currentUser,
  dispatch,
  entityId,
  isReadonly,
}) => {
  React.useEffect(() => {
    dispatch(loadCallcentreAddon());

    trackMixPanel('Sales channels - Call centre channel');
  }, []);

  const trackMixPanel = (eventName) => {
    mixpanel.track(eventName, {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  };

  const processedCurrentUser = UserModel.fromCurrentUser(currentUser);
  const [addonConfig, setAddonConfig] = React.useState(initializeAddonModule());

  React.useEffect(() => {
    setAddonConfig(callcentreData);
  }, [callcentreData]);

  const [withChanges, setWithChanges] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [newChanges, setNewChanges] = React.useState({} as NewChanges);
  const [showModal, setShowModal] = React.useState(false);
  const [allowSave, setAllowSave] = React.useState(false);

  React.useEffect(() => {
    setActive(callcentreActive);
  }, [callcentreActive]);

  const reloadData = () => {
    dispatch(loadCallcentreAddon());
    setShowModal(false);
    setWithChanges(false);
  };

  const saveChanges = (changes?: any) => {
    const newVersion = addonConfig.generateNewVersion();
    const attributes = getNewVersionAttribute(
      addonConfig,
      changes || newChanges.callcentre_user_agreement
    );
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser: processedCurrentUser,
      onSuccessCallback: reloadData,
      onErrorCallback: (error) => console.error(error),
    });
  };

  const handleSwitchChange = (newValue) => {
    if (!callCentreEnabled) return;

    trackMixPanel(
      `Sales channels - Call centre channel - Toggle ${newValue ? 'on' : 'off'}`
    );

    setActive(newValue);
    setShowModal(true);
  };

  const saveCallCentreChanges = () => {
    const updateCheckbox = () => {
      const payload = active ? undefined : initializeCallcentreUserAgreement();
      saveChanges(payload);
    };

    if (callcentreData.id) {
      dispatch(
        updateAddonRuleset(
          callcentreData.id,
          callcentreData.attributes,
          addon_module_name,
          updateCheckbox
        )
      );
    } else {
      dispatch(
        createAddonVersion(
          getNewVersionAttribute(
            initializeAddonModule(),
            newChanges.callcentre_user_agreement
          ),
          addon_module_name,
          reloadData
        )
      );
    }

    trackMixPanel('Sales channels - Call centre channel - Saved changes');
  };

  const cancelCloseModal = () => {
    setActive(!active);
    setShowModal(false);
  };

  const handleCheckboxChange = (newStatus) => {
    setAllowSave(newStatus.length === 3);

    setNewChanges((changes) => ({
      ...changes,
      callcentre_user_agreement: updateCallcentreUserAgreement(newStatus),
    }));
    setWithChanges(true);
  };

  // Table Data
  const [userRecords, setUserRecords] = React.useState<any>([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [tableLoading, setTableLoading] = React.useState(false);
  const userEntityLinkAPI = api(
    'user_entity_links',
    currentUser.accessToken,
    entityId
  );

  React.useEffect(() => {
    fetchData(loadData);
  }, []);

  const loadData = (responseData) => {
    const { data, total_users } = responseData;
    setUserRecords(data);
    if (totalRecords !== total_users) {
      setTotalRecords(responseData.total_users);
    }
  };

  const fetchData = (successCallback: (data) => void, payload?: any) => {
    setTableLoading(true);
    userEntityLinkAPI
      .getCallCentreUsers(payload)
      .then((response) => {
        if (response.status === 200) {
          successCallback(response.data);
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error, 'Failed to get call centre user data');
      });
  };

  const handleTableChange = React.useCallback((tableInfo) => {
    const defaultPayload = { page: 1, per_page: 25 };
    const queryPayload = {
      page: tableInfo.pageIndex + 1,
      per_page: tableInfo.pageSize,
      ...(tableInfo.searchFilter ? { keyword: tableInfo.searchFilter } : {}),
    };
    const payload = {
      ...defaultPayload,
      ...queryPayload,
    };
    fetchData(loadData, payload);
  }, []);

  const MEMOIZED_DATA_COLUMNS = React.useMemo(() => DATA_COLUMNS, []);
  const tableData = React.useMemo(
    () => transformResponseToTableData(userRecords),
    [userRecords]
  );

  return (
    <SideMenuPanel>
      <PanelSection>
        <ConfirmationModal
          companyName={companyName}
          isActive={active}
          showModal={showModal}
          cancelCloseModal={cancelCloseModal}
          confirmModal={() => {
            if (active) {
              setShowModal(false);
            } else {
              saveCallCentreChanges();
            }
          }}
          type={addon_module_name}
        />
        {instructionsText}
        <div className="switch-container">
          <Switch3
            label={active ? 'On' : 'Off'}
            value={active}
            onClick={handleSwitchChange}
            isDisabled={isReadonly || !callCentreEnabled}
          />
        </div>
      </PanelSection>
      <PanelSection borderless>
        {applicationText}
        <CheckboxGroup
          passedValue={callcentreUserAgreementCheckbox}
          handleChange={handleCheckboxChange}
          disabled={isReadonly || !active || allAgreementChecked}
          options={checkboxOptions}
          direction="vertical"
        />
        <Button
          type="button"
          text="Save"
          disabled={isReadonly || (withChanges ? !active || !allowSave : true)}
          onClick={() => saveCallCentreChanges()}
        />
      </PanelSection>
      <HistoryPanelSection historyList={callcentreHistory} historyCount={5} />
      {active && (
        <div>
          <Table
            columns={MEMOIZED_DATA_COLUMNS}
            data={tableData}
            dataLabel="users"
            fetchData={handleTableChange}
            totalRecords={totalRecords}
            loading={tableLoading}
            withBackendFilter
          />
        </div>
      )}
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');
  const companyName = get(
    currentUser,
    'current_entity.attributes.company_name'
  );
  const callcentreData = get(state, 'add_ons.call_centre');
  const callcentreHistory = get(
    callcentreData,
    'attributes.history_version.data',
    []
  );
  const callcentreActive = get(callcentreData, 'attributes.active', false);
  const callcentreUserAgreement = get(
    callcentreHistory[0],
    'attributes.config.callcentre_user_agreement',
    {}
  );
  const callcentreUserAgreementCheckbox = getCallcentreCheckboxValue(
    callcentreUserAgreement
  );
  const allAgreementChecked = callcentreUserAgreementCheckbox.length === 3;
  const callCentreEnabled = get(
    currentUser,
    'current_entity.attributes.call_centre_enabled',
    false
  );
  const isReadonly = !isFeatureEditEnabled('CallCentre');

  return {
    allAgreementChecked,
    callcentreActive,
    callcentreData,
    callCentreEnabled,
    callcentreHistory,
    callcentreUserAgreementCheckbox,
    companyName,
    currentUser,
    entityId,
    isReadonly,
  };
})(CallCentreChannelContent);
