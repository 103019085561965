import React, { useContext } from 'react';
import { AccountMonitoringContext } from './AccountMonitoringContext';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import RecordHistory from 'modules/new-applications/components/RecordHistory';

export const HistoryLogs = () => {
  const {
    application: { creditControlHistories },
  } = useContext(AccountMonitoringContext);

  if (creditControlHistories.length) {
    return (
      <ContentWithFooter
        footer={<RecordHistory histories={creditControlHistories} />}
      />
    );
  }

  return null;
};
