import RangedNumberInput from 'modules/shared/components/inputs/RangedNumberInput';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';

import { setValue } from '../actions';
import styles from './css/AutoDecisioning.css';
import credit_checks_styles from './css/CreditChecks.css';
import ExpandableSection from './ExpandableSection';
import OptionsSection from './OptionsSection';

class CreditChecks extends Component {
  get currentCreditCheckRules() {
    const { entity_credit_check_rules, read_only, rule_set } = this.props;

    if (read_only && !!rule_set.credit_check_rule) {
      return rule_set.credit_check_rule;
    }

    return entity_credit_check_rules;
  }

  get nzRuleSelection() {
    return this.currentCreditCheckRules.nz_rule_selection || {};
  }

  get nzPersonalRuleSelection() {
    return this.currentCreditCheckRules.nz_personal_rule_selection || {};
  }

  get auRuleSelection() {
    return this.currentCreditCheckRules.au_rule_selection || {};
  }

  get auPersonalRuleSelection() {
    return this.currentCreditCheckRules.au_personal_rule_selection || {};
  }

  get isJudgementsVisible() {
    return (
      this.nzRuleSelection.auto_decision_judgements_visible ||
      this.nzPersonalRuleSelection.auto_decision_judgements_visible ||
      this.auRuleSelection.auto_decision_judgements_visible ||
      this.auPersonalRuleSelection.auto_decision_judgements_visible
    );
  }

  get isDefaultsVisible() {
    return (
      this.nzRuleSelection.auto_decision_defaults_visible ||
      this.nzPersonalRuleSelection.auto_decision_defaults_visible ||
      this.auRuleSelection.auto_decision_defaults_visible ||
      this.auPersonalRuleSelection.auto_decision_defaults_visible
    );
  }

  get isBankruptcyVisible() {
    return (
      this.nzRuleSelection.auto_decision_bankruptcy_visible ||
      this.nzPersonalRuleSelection.auto_decision_bankruptcy_visible ||
      this.auRuleSelection.auto_decision_bankruptcy_visible ||
      this.auPersonalRuleSelection.auto_decision_bankruptcy_visible
    );
  }

  handleCreditRiskChange = (_, e) => {
    const { dispatch } = this.props;
    dispatch(setValue(e.target.id, e.target.value));
  };

  renderNZBusinessCreditIndicator() {
    const { errors, isOnlyPersonal, read_only, rule_set } = this.props;

    if (isBlank(this.nzRuleSelection)) {
      return;
    }

    if (isOnlyPersonal) {
      return;
    }

    const min = this.nzRuleSelection.min_credit_risk_indicator_business || 0;
    const max = this.nzRuleSelection.max_credit_risk_indicator_business;
    const value = rule_set.nz_business_credit_indicator || '0';
    let errorMarkup;

    if (
      errors &&
      !rule_set.nz_business_credit_indicator &&
      errors.nz_business_credit_indicator
    ) {
      errorMarkup = (
        <div className={styles.error}>
          {errors.nz_business_credit_indicator}
        </div>
      );
    }

    return (
      <div key="nz_business_credit_indicator" className={styles.inner_section}>
        <h3>
          {(this.nzRuleSelection.auto_decision_title || {}).business_check}
        </h3>
        <div>
          {errorMarkup}
          <div className={styles.row_info}>
            Please set the minimum level{' '}
            <strong>
              ({min} ~ {max})
            </strong>{' '}
            required on the credit risk indicator to qualify for an automatic
            decision
          </div>
          <div className={credit_checks_styles.row_number_input}>
            <RangedNumberInput
              id="nz_business_credit_indicator"
              key="nz_business_credit_indicator"
              handleChange={this.handleCreditRiskChange}
              value={value}
              min={min}
              max={max}
              disabled={read_only}
              needSperator={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNZPersonalCreditIndicator() {
    const { errors, read_only, rule_set } = this.props;

    if (isBlank(this.nzPersonalRuleSelection)) {
      return;
    }

    const min =
      this.nzPersonalRuleSelection.min_credit_risk_indicator_personal || 0;
    const max = this.nzPersonalRuleSelection.max_credit_risk_indicator_personal;
    const value = rule_set.nz_personal_credit_indicator || '0';
    let errorMarkup;

    if (
      errors &&
      !rule_set.nz_personal_credit_indicator &&
      errors.nz_personal_credit_indicator
    ) {
      errorMarkup = (
        <div className={styles.error}>
          {errors.nz_personal_credit_indicator}
        </div>
      );
    }

    return (
      <div key="nz_personal_credit_indicator" className={styles.inner_section}>
        <h3>
          {
            (this.nzPersonalRuleSelection.auto_decision_title || {})
              .personal_check
          }
        </h3>
        <div>
          <div className={styles.row_info}>
            Please set the minimum level{' '}
            <strong>
              ({min} ~ {max})
            </strong>{' '}
            required on the credit risk indicator to qualify for an automatic
            decision
          </div>
          {errorMarkup}
          <div className={credit_checks_styles.row_number_input}>
            <RangedNumberInput
              id="nz_personal_credit_indicator"
              key="nz_personal_credit_indicator"
              handleChange={this.handleCreditRiskChange}
              value={value}
              min={min}
              max={max}
              disabled={read_only}
              needSperator={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderAUBusinessCreditIndicator() {
    const { errors, isOnlyPersonal, read_only, rule_set } = this.props;

    if (isBlank(this.auRuleSelection)) {
      return;
    }

    if (isOnlyPersonal) {
      return;
    }

    const min = this.auRuleSelection.min_credit_risk_indicator_business || 0;
    const max = this.auRuleSelection.max_credit_risk_indicator_business;
    const value = rule_set.au_business_credit_indicator || '0';
    let errorMarkup;

    if (
      errors &&
      !rule_set.au_business_credit_indicator &&
      errors.au_business_credit_indicator
    ) {
      errorMarkup = (
        <div className={styles.error}>
          {errors.au_business_credit_indicator}
        </div>
      );
    }

    return (
      <div key="au_business_credit_indicator" className={styles.inner_section}>
        <h3>
          {(this.auRuleSelection.auto_decision_title || {}).business_check}
        </h3>
        <div>
          {errorMarkup}
          <div className={styles.row_info}>
            Please set the minimum level{' '}
            <strong>
              ({min} ~ {max})
            </strong>{' '}
            required on the credit risk indicator to qualify for an automatic
            decision
          </div>
          <div className={credit_checks_styles.row_number_input}>
            <RangedNumberInput
              id="au_business_credit_indicator"
              key="au_business_credit_indicator"
              handleChange={this.handleCreditRiskChange}
              value={value}
              min={min}
              max={max}
              disabled={read_only}
              needSperator={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderAUPersonalCreditIndicator() {
    if (isBlank(this.auPersonalRuleSelection)) {
      return;
    }

    const { errors, read_only, rule_set } = this.props;

    const min =
      this.auPersonalRuleSelection.min_credit_risk_indicator_personal || 0;
    const max = this.auPersonalRuleSelection.max_credit_risk_indicator_personal;
    const value = rule_set.au_personal_credit_indicator || '0';
    let errorMarkup;

    if (
      errors &&
      !rule_set.au_personal_credit_indicator &&
      errors.au_personal_credit_indicator
    ) {
      errorMarkup = (
        <div className={styles.error}>
          {errors.au_personal_credit_indicator}
        </div>
      );
    }

    return (
      <div key="au_personal_credit_indicator" className={styles.inner_section}>
        <h3>
          {
            (this.auPersonalRuleSelection.auto_decision_title || {})
              .personal_check
          }
        </h3>
        <div>
          {errorMarkup}
          <div className={styles.row_info}>
            Please set the minimum level{' '}
            <strong>
              ({min} ~ {max})
            </strong>{' '}
            required on the credit risk indicator to qualify for an automatic
            decision
          </div>
          <div className={credit_checks_styles.row_number_input}>
            <RangedNumberInput
              id="au_personal_credit_indicator"
              key="au_personal_credit_indicator"
              handleChange={this.handleCreditRiskChange}
              value={value}
              min={min}
              max={max}
              disabled={read_only}
              needSperator={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderJudgementsSection() {
    if (!this.isJudgementsVisible) {
      return;
    }

    const { current_entity, errors, read_only } = this.props;

    return (
      <div>
        <div className={styles.padder} />

        <ExpandableSection
          id="judgements"
          title="Judgements"
          disabled={read_only}
          hide_setting_message={read_only}
          info_text="Would you use auto decision for any credit applications deemed to contain unfavourable Judgements against the applicant?"
          account_rule_on={current_entity.requires_credit_check}
          error={errors ? errors.judgements : null}
        />
      </div>
    );
  }

  renderDefaultsSection() {
    if (!this.isDefaultsVisible) {
      return;
    }

    const { current_entity, errors, read_only } = this.props;

    return (
      <div>
        <div className={styles.padder} />

        <ExpandableSection
          id="defaults"
          title="Defaults"
          disabled={read_only}
          hide_setting_message={read_only}
          info_text="Would you use auto decision for any credit applications that have defaults associated with the applicant?"
          account_rule_on={current_entity.requires_credit_check}
          error={errors ? errors.defaults : null}
        />
      </div>
    );
  }

  renderBankruptcySection() {
    if (!this.isBankruptcyVisible) {
      return;
    }

    const { read_only } = this.props;

    return (
      <div>
        <div className={styles.padder} />

        <OptionsSection
          id="bankruptcy"
          title="Bankruptcy"
          disabled={read_only}
          hide_setting_message={read_only}
          info_text="Any application with a history of bankruptcy will be deferred for manual decision"
        />
      </div>
    );
  }

  render() {
    const { current_entity, errors, read_only } = this.props;
    const creditIndicators = [
      this.renderNZBusinessCreditIndicator(),
      this.renderNZPersonalCreditIndicator(),
      this.renderAUBusinessCreditIndicator(),
      this.renderAUPersonalCreditIndicator(),
    ];

    return (
      <div className={styles.section}>
        <ExpandableSection
          id="credit_checks_required"
          title="Credit Checks"
          disabled={read_only}
          account_rule_on={current_entity.requires_credit_check}
          hide_setting_message={read_only}
          info_text="Are credit checks required for auto decisioning?"
          error={errors ? errors.required : null}
        >
          {creditIndicators}
          {this.renderJudgementsSection()}
          {this.renderDefaultsSection()}
          {this.renderBankruptcySection()}
        </ExpandableSection>
      </div>
    );
  }
}

const defaults = {
  rule_set: {},
  title: 'Select your business type',
};

export default connect((state, ownProps) => {
  return {
    ...defaults,
    credit_risk_indicator_maxs: defaults.credit_risk_indicator_maxs,
    current_entity: state.current_user.current_entity
      ? state.current_user.current_entity.attributes
      : null,
    entity_credit_check_rules: state.current_user.current_entity
      ? state.current_user.current_entity.attributes.selected_credit_check_rules
      : null,
    options: defaults.options,
    rule_set: ownProps.rule_set,
  };
})(CreditChecks);
