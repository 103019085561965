import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import BorderedAutocomplete from '../BorderedAutocomplete';
import BorderedSelect from '../BorderedSelect';
import BorderedTextField from '../BorderedTextField';
import { MultipleFieldAddButton, MultipleFieldRemoveButton } from './styles';

const createInputProps = (value?: any) => ({
  [uuidv4()]: { value: value || '' },
});

const generateInputsFromValue = (values?: any[]) => {
  const inputs = values && values.length ? values : [null];

  return inputs.reduce((inputsObject, value) => {
    inputsObject = { ...inputsObject, ...createInputProps(value) };
    return inputsObject;
  }, {});
};

const returnArrayValues = (inputs) => {
  const arrayValues: any[] = [];
  Object.keys(inputs).forEach((inputKey) => {
    const value = inputs[inputKey].value;
    if (value) arrayValues.push(value);
  });
  return arrayValues;
};

const MultipleInputField = (props) => {
  const {
    value,
    onChange,
    buttonLabel,
    type,
    disabled,
    options,
    ...inputProps
  } = props;
  const initialInputState = generateInputsFromValue(value);

  const [inputs, setInputs] = useState(initialInputState);

  const handleInputChange = (id, inputValue) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [id]: {
        ...prevInputs[id],
        value: inputValue,
      },
    }));
  };

  const handleAddInput = () => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      ...createInputProps(),
    }));
  };

  const handleRemoveInput = (id) => {
    setInputs((prevInputs) => {
      const { [id]: removedInput, ...remainingInputs } = prevInputs;
      return remainingInputs;
    });
  };

  const filterUnselectedOptions = (currentValue, options) => {
    return options.filter(
      (option) =>
        currentValue === option.value ||
        !returnArrayValues(inputs).includes(option.value)
    );
  };

  useEffect(() => {
    onChange && onChange(returnArrayValues(inputs));
  }, [inputs]);

  const StyledBorderedAutocomplete = (props) => (
    <MuiThemeProvider theme={muiTheme()}>
      <BorderedAutocomplete {...props} />
    </MuiThemeProvider>
  );

  const InputTypes = {
    autocomplete: StyledBorderedAutocomplete,
    select: BorderedSelect,
    text: BorderedTextField,
  };

  const InputComponent = InputTypes[type || 'text'];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {Object.keys(inputs).map((inputKey, index) => {
        return (
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr 50px' }}
            key={inputKey}
          >
            <InputComponent
              value={
                type === 'autocomplete'
                  ? options.find(
                      (option) => option.value === inputs[inputKey].value
                    )
                  : inputs[inputKey].value
              }
              onChange={(event, option) =>
                handleInputChange(
                  inputKey,
                  type === 'autocomplete'
                    ? option
                      ? option.value
                      : null
                    : event.target.value
                )
              }
              options={
                options
                  ? filterUnselectedOptions(inputs[inputKey].value, options)
                  : undefined
              }
              disabled={disabled}
              {...inputProps}
            />
            {index === 0 && Object.keys(inputs).length === 1 ? (
              <span></span>
            ) : (
              <MultipleFieldRemoveButton
                disableTouchRipple
                disabled={disabled}
                onClick={() => handleRemoveInput(inputKey)}
              >
                <CancelIcon />
              </MultipleFieldRemoveButton>
            )}
          </div>
        );
      })}
      <MultipleFieldAddButton
        disableTouchRipple
        disabled={disabled}
        onClick={handleAddInput}
      >
        {buttonLabel || '+ Add item'}
      </MultipleFieldAddButton>
    </div>
  );
};

export default MultipleInputField;
