import get from 'lodash.get';
import { Input } from 'modules/consumer-onboarding/v2/Verification/VerificationCode/CodeField/CodeInputField/styles';
import React, { ReactElement } from 'react';

const DIGIT_REGEX = /\D/g;
const INPUT_LEFT_MARGIN = 42;

const CodeInputField = (props): ReactElement => {
  const {
    codeLength,
    currentIndex,
    inputRef,
    onSetVerificationCode,
    setIsFocused,
    trigger,
    verificationCode,
    verificationCodeRegister,
  } = props;

  const onFocus = () => setIsFocused(true);
  const onBlur = () => {
    setIsFocused(false);
    if (verificationCode > 0) trigger(verificationCodeRegister.name);
  };

  const onChange = (e) => {
    const value = get(e, 'target.value');
    const sanitizedValue = value.replace(DIGIT_REGEX, '');

    if (verificationCode.length < codeLength) {
      const processedValue = (verificationCode + sanitizedValue).slice(
        0,
        codeLength
      );
      onSetVerificationCode(processedValue);
    }
  };

  const onKeyUp = (e) => {
    if (e.key === 'Backspace') {
      const processedVerificationCode = verificationCode.slice(
        0,
        verificationCode.length - 1
      );
      onSetVerificationCode(processedVerificationCode);
    }
  };

  const isInputVisible = verificationCode.length < codeLength;

  return (
    <Input
      value=""
      ref={inputRef}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onKeyUp={onKeyUp}
      style={{
        left: `${currentIndex * INPUT_LEFT_MARGIN}px`,
        opacity: isInputVisible ? 1 : 0,
      }}
    />
  );
};

export default CodeInputField;
