import { PhotoshopPicker } from 'react-color';
import React from 'react';
import styles from './css/SketchButton.css';

class SketchButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: props.color,
      displayColorPicker: false,
      prevColor: props.color,
      usedColors: props.usedColor,
    };
  }

  showColorPicker() {
    this.setState({ displayColorPicker: true });
  }

  hideColorPicke() {
    this.setState({ displayColorPicker: false });
  }

  onChangeColor(color) {
    this.setState({ color });
    const { name, onChange } = this.props;
    onChange({ [name]: color });
  }

  onAccept() {
    const { name, onChange } = this.props;
    const { usedColors, color } = this.state;
    onChange({ [name]: color, accept: true });
    this.setState({ prevColor: color });
    if (!usedColors.includes(color)) {
      this.setState({ usedColors: usedColors.concat([color]) });
    }
    this.hideColorPicke();
  }

  onCancel() {
    const { name, onChange } = this.props;
    const prevColor = this.state.prevColor;
    onChange({ [name]: prevColor });
    this.setState({ color: prevColor });
    this.hideColorPicke();
  }

  renderUsedColors() {
    return (
      <div className={styles.used_color_board}>
        <span className={styles.used_color_board_title}>Used colour</span>
        {this.state.usedColors.map((color) => {
          return (
            <div
              className={styles.used_color_cell}
              onClick={() => this.onChangeColor(color)}
              key={color}
              style={{ backgroundColor: `${color}` }}
            />
          );
        })}
      </div>
    );
  }

  renderColorPicker() {
    return (
      <div className={styles.popover}>
        <PhotoshopPicker
          className={styles.picker}
          color={{ hex: this.state.color }}
          onChangeComplete={(color) => this.onChangeColor(color.hex)}
          onAccept={this.onAccept.bind(this)}
          onCancel={this.onCancel.bind(this)}
        />
        {this.renderUsedColors()}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div
          className={styles.color}
          style={{ background: this.state.color }}
          onClick={this.showColorPicker.bind(this)}
        />
        {this.state.displayColorPicker && this.renderColorPicker()}
      </div>
    );
  }
}

export default SketchButton;
