import React from 'react';
import styled from 'styled-components';

import { Header } from './Header';

const PageContainer = styled.div`
  background-color: white;
  min-height: calc(100vh - 100px);
  @media screen and (min-width: 992px) {
    min-height: calc(100vh - 222px);
  }
`;

const ContentContainer = styled.div`
  background-color: white;
  padding: 20px;
  padding-bottom: 60px;
  z-index: 1;
  @media screen and (min-width: 992px) {
    padding: 20px 50px 0 50px;
  }
`;

type DashboardPageContainerProps = {
  pageTitle?: string;
  headerComponents: JSX.Element | JSX.Element[];
  contentComponents: JSX.Element | JSX.Element[];
  scrollingHeader?: boolean;
};

export const DashboardPageContainer = (props: DashboardPageContainerProps) => {
  const { pageTitle, headerComponents, contentComponents, scrollingHeader } =
    props;
  return (
    <PageContainer>
      <Header title={pageTitle} scrolling={!!scrollingHeader}>
        {headerComponents}
      </Header>
      <ContentContainer>{contentComponents}</ContentContainer>
    </PageContainer>
  );
};
