import { isEmpty } from 'lodash';
import get from 'lodash.get';
import styles from 'modules/card-management-onboarding/css/Details.css';
import { lookupAddress } from 'modules/consumer-onboarding/actions/onboarding';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import ConsumerFromRender from 'modules/shared/components/widgets/interactive/form_builder/ConsumerFromRender';
import React, { useState } from 'react';
import { formatMoney } from 'utils/formatting';
import getIsThisYouLabel from 'utils/getIsThisYouLabel';
import isPresent from 'utils/isPresent';

import {
  CardholderBlock,
  CardholderRow,
  CardholderTitle,
  DeleteBtn,
  SubTitle,
} from './styles';

function resetError({ errors, setErrors, fieldKey }) {
  const newErrors = {};
  Object.assign(newErrors, errors);
  delete newErrors[fieldKey];

  setErrors(newErrors);
}

const CARD_ISSUING_REASONS_LABEL = {
  'Additional card': 'Additional',
  'Damaged card': 'Damaged',
  'Limit increase': 'Limit Increase',
  'Lost card': 'Lost',
  'New card': 'New',
  'Stolen card': 'Stolen',
  'Cancellation': 'Cancellation',
};

function CardholderDetails(props) {
  const {
    additionalFieldCheck,
    application,
    cardholder,
    deleteCardholder,
    deliveryAddressData,
    deliveryAddressLoading,
    deliveryAddressOptions,
    displayDeleteBtn,
    dispatch,
    errors,
    hasApplicantCardholder,
    index,
    region,
    setActiveCardIndex,
    setErrors,
    updateAndValidateCardholder,
  } = props;

  const signatureRequired = application.signatureRequired === 'on';
  const cardLimitEnabled = application.cardLimitEnabled === 'on';
  const isApplicant = cardholder.is_applicant === 'Yes';
  const hasFilledData = !isEmpty(cardholder);
  const displayIndex = hasApplicantCardholder ? index : index + 1;

  const inputForm = [
    [
      { label: 'First name', name: 'first_name', required: true },
      { label: 'Middle name', name: 'middle_name', required: false },
      { label: 'Last name', name: 'last_name', required: true },
    ],
    [
      { label: 'Email', name: 'email', required: true },
      { label: 'Phone number', name: 'phone_number', required: true },
    ],
    [
      {
        label: 'Monthly card limit',
        name: 'card_limit',
        required: true,
      },
    ],
  ];

  const [addressKeyword, setAddressKeyword] = useState(
    get(cardholder, 'delivery_address.full_address')
  );

  // const debouncedSearchAddress = useRef(
  //   debounce(lookupDeliveryAddress, DEBOUNCE_INTERVAL),
  // ).current;

  const lookupDeliveryAddress = (keyword) => {
    dispatch(lookupAddress(keyword, 'Delivery', region));
  };

  const handleDeliveryAddressChange = (event) => {
    const keyword = event.target.value;
    setAddressKeyword(keyword);
    lookupDeliveryAddress(keyword);
  };

  const handleDeliveryAddressClick = (value) => {
    const addressValue = deliveryAddressData[value];
    setAddressKeyword(addressValue.full_address);
    updateAndValidateCardholder(index, { delivery_address: addressValue });

    resetError({
      errors,
      fieldKey: `cardholders[${index}].delivery_address`,
      setErrors,
    });
  };

  const handleAdditionalFieldChange = (params) => {
    const currentAnswers = cardholder.additional_fields || [];
    const answerIndex = currentAnswers.findIndex(
      (answer) => answer.description === params.description
    );
    if (answerIndex === -1) {
      currentAnswers.push(params);
    } else {
      currentAnswers[answerIndex] = params;
    }
    updateAndValidateCardholder(index, { additional_fields: currentAnswers });
  };

  const removeCardholder = () => {
    if (hasFilledData) {
      return setActiveCardIndex(index);
    }

    setActiveCardIndex(null);
    deleteCardholder(index);
  };

  const processedCardLimit = formatMoney(
    parseInt(cardholder['card_limit'], 10)
  );
  let cardIssuingReasons = application.cardIssuingReasons || [];
  const limitIncreaseOption = 'Limit increase';

  if (!cardLimitEnabled && cardIssuingReasons.includes(limitIncreaseOption)) {
    cardIssuingReasons = application.cardIssuingReasons.filter(
      (reason) => reason !== limitIncreaseOption
    );
  }

  cardIssuingReasons = cardIssuingReasons.map((reason) => {
    return {
      label: CARD_ISSUING_REASONS_LABEL[reason],
      value: reason,
    };
  });

  return (
    <CardholderBlock>
      <CardholderTitle>
        {isApplicant
          ? 'Please enter details for your card'
          : `Card ${displayIndex}`}
        {displayDeleteBtn && (
          <DeleteBtn
            type="button"
            className="delete is-medium"
            onClick={removeCardholder}
          />
        )}
      </CardholderTitle>
      <SubTitle>Card issuing reasons</SubTitle>
      <CardholderRow className={styles.cardholder_row}>
        <Radiobox
          name={`cardholders[${index}]card_issuing_reason`}
          handleChange={(event) =>
            updateAndValidateCardholder(index, {
              card_issuing_reason: event.target.value,
            })
          }
          label=""
          value={cardholder.card_issuing_reason}
          radioList={cardIssuingReasons}
          error={get(errors, `cardholders[${index}].card_issuing_reason`, '')}
        />
      </CardholderRow>
      <div className="columns is-multiline">
        {!signatureRequired && (
          <div key="name_on_card" className="column is-6-tablet is-6-desktop">
            <TextInput
              label="Name to appear on card"
              handleChange={(event) => {
                updateAndValidateCardholder(index, {
                  first_name: event.target.value,
                });
              }}
              required
              value={cardholder['first_name']}
              error={get(errors, `cardholders[${index}].first_name`, '')}
            />
          </div>
        )}
        {signatureRequired &&
          inputForm[0].map((input) => (
            <div key={input.label} className="column is-6-tablet is-4-desktop">
              <TextInput
                label={input.label}
                handleChange={(event) => {
                  updateAndValidateCardholder(index, {
                    [input.name]: event.target.value,
                  });
                }}
                required={input.required}
                value={cardholder[input.name]}
                error={get(errors, `cardholders[${index}].${input.name}`, '')}
              />
            </div>
          ))}
        {signatureRequired &&
          inputForm[1].map((input) => (
            <div key={input.label} className="column is-6-tablet is-6-desktop">
              <TextInput
                label={input.label}
                handleChange={(event) => {
                  updateAndValidateCardholder(index, {
                    [input.name]: event.target.value,
                  });
                }}
                required={input.required}
                value={cardholder[input.name]}
                error={get(errors, `cardholders[${index}].${input.name}`, '')}
              />
            </div>
          ))}
        {signatureRequired && (
          <div className="column is-6-tablet">
            <AutoSuggest
              label={'Card delivery address'}
              value={addressKeyword}
              loading={deliveryAddressLoading}
              suggest_items={deliveryAddressOptions || []}
              handleChange={handleDeliveryAddressChange}
              handleClick={handleDeliveryAddressClick}
              required={true}
              css_class={'address_lookup'}
              error={get(errors, `cardholders[${index}].delivery_address`, '')}
            />
          </div>
        )}
        {cardLimitEnabled &&
          inputForm[2].map(({ label, name, required, type }) => (
            <div key={label} className="column is-6-tablet">
              <TextInput
                label={label}
                handleChange={(event) => {
                  updateAndValidateCardholder(index, {
                    [name]: parseInt(
                      event.target.value.toString().replace(/[^0-9]/g, '') || 0,
                      10
                    ),
                  });
                }}
                required={required}
                value={processedCardLimit === 'NaN' ? '' : processedCardLimit}
                error={get(errors, `cardholders[${index}].${name}`, '')}
                type={type}
              />
            </div>
          ))}
      </div>
      {isPresent(application.additionalFields) && (
        <SubTitle>Additional fields</SubTitle>
      )}
      <ConsumerFromRender
        components={application.additionalFields || []}
        onChange={handleAdditionalFieldChange}
        reduxKey={`card_management_${index}`}
        validationTrigger={additionalFieldCheck}
        answers={cardholder.additional_fields}
      />
    </CardholderBlock>
  );
}

export default CardholderDetails;
