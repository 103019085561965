import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { ReactElement } from 'react';

const CardholderField = ({
  content,
  isVisible,
  label,
}: {
  content: string;
  isVisible: boolean;
  label: string;
}): ReactElement | null => {
  if (!isVisible) {
    return null;
  }

  return <LabeledContent label={label} content={content} />;
};

export default CardholderField;
