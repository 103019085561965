import {
  manualSetCompany,
  setEntityFormValue,
} from 'modules/consumer-onboarding/actions/onboarding';
import REGION from 'types/region';

const setReduxStates = ({
  data,
  hasEntityNameFromApi,
  region,
}: {
  data: { [key: string]: any };
  hasEntityNameFromApi: boolean;
  region: REGION;
}): ((dispatch: any) => void) => (dispatch) => {
  dispatch(manualSetCompany('business_number', data.businessNumber));
  dispatch(manualSetCompany('company_number', data.companyNumber));
  dispatch(manualSetCompany('registered_at', data.registeredAt));
  dispatch(manualSetCompany('has_entity_name_from_api', hasEntityNameFromApi));

  if (region === 'AU') {
    dispatch(manualSetCompany('company_name', data.companyName));
    dispatch(manualSetCompany('trading_name', data.tradingName));
    dispatch(setEntityFormValue('entity_name', data.tradingName));
  }

  if (region === 'NZ') {
    if (data.legalType === 'company') {
      dispatch(manualSetCompany('company_name', data.companyName));
    } else {
      dispatch(manualSetCompany('trading_name', data.entityName));
    }
  }
};
export default setReduxStates;
