//
// Add other Credit Check components into this list
// Naming convention "[credit_check_type]Component"
//   * credit_check_type
//     - this is the `type` field from Rails, i.e. CreditWorks: CreditWorksComponent
//

import AustraliaEquifaxComponent from './australia/equifax/EquifaxComponent';
import AustraliaEquifaxPersonalComponentOld from './australia/equifax/EquifaxPersonalComponent';
import AustraliaEquifaxPersonalComponentNew from './australia/equifax/components/PersonalReport';
import AustraliaIllionConsumerRiskScoreComponent from './australia/illion/ConsumerRiskScoreComponent';
import AustraliaIllionPaymentPredictorComponent from './australia/illion/PaymentPredictorComponent';
import CentrixComponent from './centrix/CentrixComponent';
import CreditWorksComponent from './credit-works/CreditWorksComponent';
import { FEATURE_FLAGS } from 'conf';

const AustraliaEquifaxPersonalComponent =
  FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_COMMERCIAL_PLUS
    ? AustraliaEquifaxPersonalComponentNew
    : AustraliaEquifaxPersonalComponentOld;

export { AustraliaEquifaxComponent };
export { AustraliaEquifaxPersonalComponent };
export { AustraliaIllionConsumerRiskScoreComponent };
export { AustraliaIllionPaymentPredictorComponent };
export { CentrixComponent };
export { CreditWorksComponent };
