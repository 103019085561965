export default function getCommonConfigAttributes(addonConfig, data) {
  const attributes = {
    collateral_preset: {
      ...data.collateralPreset,
    },
    financing: {
      auto_renew: data.financingStatementAutoRenew,
    },
    secured_party_group: {
      organisation: {
        address: data.organisationAddress,
        email: data.organisationEmail,
        name: data.organisationName,
        phone: data.organisationPhone,
      },
      person: {
        first_name: data.personFirstName,
        last_name: data.personLastName,
      },
      spg_id: data.spgId,
      spg_pwd: data.spgPwd,
    },
  };

  return attributes;
}
