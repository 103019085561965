import { datadogLogs } from '@datadog/browser-logs';
import { AxiosRequestConfig } from 'axios';

export const requestInterceptor = (
  message: string,
  request: AxiosRequestConfig
): AxiosRequestConfig => {
  const { baseURL, data, method, params, url } = request;

  datadogLogs.logger.log(
    `${message}: [${method}] ${baseURL}${url}`,
    {
      baseURL,
      data,
      method,
      params,
      url,
    },
    'info'
  );

  return request;
};
