import React, { ReactElement } from 'react';
import FormFieldWrapper from 'modules/consumer-onboarding/v2/FormFieldWrapper';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import get from 'lodash.get';

const SelectField = (props): ReactElement | null => {
  const {
    errors,
    isDisabled,
    isVisible,
    label,
    name,
    onChange,
    options,
    rawOptions,
    watch,
  } = props;

  if (!isVisible) {
    return null;
  }

  const error = get(errors, `${name}.message`);

  return (
    <FormFieldWrapper label={label}>
      <BorderedSelect
        disabled={isDisabled}
        error={Boolean(error)}
        helperText={error}
        name={name}
        onChange={onChange}
        options={options}
        rawOptions={rawOptions}
        value={watch(name)}
      />
    </FormFieldWrapper>
  );
};

export default SelectField;
