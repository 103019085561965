import axios from './axios';

let Addresses = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = 'addresses';
};

Addresses.prototype.createAddress = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Addresses.prototype.updateAddress = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Addresses.prototype.getAddress = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

Addresses.prototype.deleteAddress = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

Addresses.prototype.getAddressAsync = function(id, options = {}) {
  return this.axios.get(`/${this.type}/${id}`, options);
};

export default Addresses;
