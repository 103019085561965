import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection8 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>8.</span> Confidential
            Information
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>8.1</span>{' '}
          <strong>Obligations of the parties</strong>: Except as permitted under
          clause 7 (IP Ownership and Data Use), each party will keep secret and
          confidential at all times, all Confidential Information of the other
          party and will not use, communicate, cause to be communicated, copy,
          make available or otherwise resupply any Confidential Information of
          the other party to any person other than those of its employees,
          contractors, agents, or representatives to whom disclosure is
          reasonably necessary for the purposes of these Terms, or as otherwise
          required by law.
        </p>
      </div>
    );
  }
}
