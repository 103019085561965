import { addFlaggedObject } from 'conf/flaggingHelpers';
import AddonConfigModel from 'models/AddonConfigModel';
import { useState } from 'react';
import AntiFraudCategoryRule from 'utils/AntiFraudCategoryRule';
import { formatMoney } from 'utils/formatting';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

import { Obj, Rule } from './types';
import { FEATURE_FLAGS } from 'conf';

export const MIN_SIMILARITY_SCORE = 80;
export const MAX_SIMILARITY_SCORE = 100;
export const MIN_CONFIDENCE_SCORE = 30;
export const MAX_CONFIDENCE_SCORE = 100;

const getNumberValidationMessage = (min: number, max: number) =>
  `Please input a number within range ${min} - ${max}`;
const getMinScoreMessage = (value: number) => `Minimum score is ${value}`;
const getMaxScoreMessage = (value: number) => `Maximum score is ${value}`;

export const _formatMoney = (money: number | string | undefined): string => {
  if ((typeof money !== 'number' && !money) || money === '') {
    return '';
  }

  const formattedMoney = formatMoney(money, null, null, null, null);

  return formattedMoney;
};

export function toNumber(
  value: string | number | undefined,
  noUndefined = false
): string | number | undefined {
  if (!value || value === '') {
    if (noUndefined) {
      return 0;
    }

    return;
  }

  if (typeof value === 'number') {
    return Math.round(value);
  }

  const processedValue = value.replace(/,/g, '');

  return Math.round(parseInt(processedValue));
}

export function getOrInitializeAddonModule(addonConfig, activeTab: string) {
  if (isPresent(addonConfig)) {
    return addonConfig;
  }

  return new AddonConfigModel({
    attributes: { addon_module_name: `anti_fraud_${activeTab}` },
  });
}

export function getNewVersionAttribute(
  addonConfig: Obj,
  data: Rule,
  activeTab: string
) {
  const addonModuleName = `anti_fraud_${activeTab}`;
  const name = '1CAF';

  const {
    authorisationTypes,
    autoPassMinScore,
    category,
    entityTypes,
    maxCreditValue,
    minCreditValue,
    tokenised,
    accountTypes,
    autoPassMinConfidenceScore,
    autoPassMinSimilarityScore,
  } = data;

  const attributes = {
    addon_config_id: '',
    addon_module_name: addonModuleName,
    config: {
      ...addFlaggedObject('FEATURE_FLAG_1CAF_ACCOUNT_TYPES', {
        account_types: accountTypes || ['cash', 'credit'],
      }),
      ...addFlaggedObject('FEATURE_FLAG_1CAF_CAT_6', {
        auto_pass_min_confidence_score: autoPassMinConfidenceScore,
      }),
      authorisation_types: authorisationTypes,
      auto_pass_min_score: autoPassMinScore,
      category,
      tokenised,
    },
    legal_types: entityTypes,
    max_credit_value: maxCreditValue || null,
    min_credit_value:
      typeof minCreditValue === 'number'
        ? minCreditValue
        : minCreditValue || null,
    name,
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id as string;
  } else {
    attributes.name = name;
    attributes.addon_module_name = addonModuleName;
  }

  return attributes;
}

function isManualCategory(category: string) {
  return category === '1';
}

export function getFormSchema({
  tradeAccountLimit,
}: {
  tradeAccountLimit: number;
}) {
  const TOKENISED_ERROR = 'Please select one option';

  return yup.object().shape(
    {
      ...addFlaggedObject('FEATURE_FLAG_1CAF_ACCOUNT_TYPES', {
        accountTypes: yup
          .array()
          .min(1, 'Please select at least one type')
          .required('Please select at least one type'),
      }),
      authorisationTypes: FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0
        ? yup
            .array()
            .min(1, 'Please select at least one type')
            .required('Please select at least one type')
        : yup.array().when('category', {
            is: (category: string) => !isManualCategory(category),
            then: yup
              .array()
              .min(1, 'Please select at least one type')
              .required('Please select at least one type'),
          }),
      autoPassMinScore: yup
        .number()
        .typeError('You must specify a number')
        .when('category', {
          is: (category: string) => {
            // @ts-ignore-next-line: category parameter conforms with the enum
            const rule = new AntiFraudCategoryRule(category);
            return rule.isFrontFaceImageRequired || rule.isProofOfLifeRequired;
          },
          then: yup
            .number()
            .required('Please set auto pass minimum similarity score')
            .integer(
              getNumberValidationMessage(
                MIN_SIMILARITY_SCORE,
                MAX_SIMILARITY_SCORE
              )
            )
            .min(MIN_SIMILARITY_SCORE, getMinScoreMessage(MIN_SIMILARITY_SCORE))
            .max(
              MAX_SIMILARITY_SCORE,
              getMaxScoreMessage(MAX_SIMILARITY_SCORE)
            ),
        }),
      category: yup.string().required('Please select one of the category'),
      entityTypes: yup
        .array()
        .min(1, 'Please select at least one type')
        .required('Please select at least one type'),
      maxCreditValue: yup
        .number()
        .typeError('Please input only number')
        .nullable(true)
        .max(
          tradeAccountLimit,
          `Max credit limit is ${_formatMoney(tradeAccountLimit)}`
        )
        .when('minCreditValue', {
          is: (minCreditValue) => !!minCreditValue,
          then: yup
            .number()
            .typeError('Please input only number')
            .required('Credit limit cannot be $0')
            .integer('Credit limit must be a number')
            .positive('Credit limit must be greater than $0')
            .test({
              exclusive: true,
              message:
                'Max limit range value must be greater than min limit range.',
              name: 'min',
              test(val) {
                const minCreditLimit = this.parent.minCreditLimit;
                const max = toNumber(val, true) as number;
                const min = toNumber(minCreditLimit, true) as number;

                return max > min;
              },
            }),
        }),
      minCreditValue: yup
        .number()
        .typeError('Please input only number')
        .nullable(true)
        .when('maxCreditValue', {
          is: (maxCreditValue) => isPresent(maxCreditValue),
          then: yup
            .number()
            .typeError('Please input only number')
            .required('Please set a minimum limit range.')
            .test({
              exclusive: true,
              message:
                'Min limit range value must not be equal or greater than max limit range.',
              name: 'min',
              test(val) {
                const maxCreditValue = this.parent.maxCreditValue;
                const min = toNumber(val, true) as number;
                const max = toNumber(maxCreditValue, true) as number;

                return min < max;
              },
            }),
        })
        .max(
          tradeAccountLimit,
          `Max credit limit is ${_formatMoney(tradeAccountLimit)}`
        ),
      tokenised: yup.boolean().when('category', {
        is: (value) => value !== '0',
        then: yup
          .boolean()
          .typeError(TOKENISED_ERROR)
          .required(TOKENISED_ERROR),
      }),
      autoPassMinConfidenceScore: yup
        .number(
          getNumberValidationMessage(MIN_CONFIDENCE_SCORE, MAX_CONFIDENCE_SCORE)
        )
        .typeError('You must specify a number')
        .integer(
          getNumberValidationMessage(MIN_CONFIDENCE_SCORE, MAX_CONFIDENCE_SCORE)
        )
        .min(MIN_CONFIDENCE_SCORE, getMinScoreMessage(MIN_CONFIDENCE_SCORE))
        .max(MAX_CONFIDENCE_SCORE, getMaxScoreMessage(MAX_CONFIDENCE_SCORE))
        .when('category', {
          is: (category: string) => {
            // @ts-ignore-next-line: category parameter conforms with the enum
            const rule = new AntiFraudCategoryRule(category);
            return rule.isProofOfLifeRequired;
          },
          then: yup
            .number()
            .required('Please set auto pass minimum confidence score'),
        }),
    },
    [['minCreditValue', 'maxCreditValue', 'category']]
  );
}

export function useForceUpdate(): () => void {
  const [, setValue] = useState(0);

  return () => setValue((value) => value + 1);
}
