import styled from 'styled-components';
import { FONT_SIZES, FONT_WEIGHTS } from 'variables/typography';

export const CardholderTitle = styled.div`
  font-size: ${FONT_SIZES.title};
  font-weight: ${FONT_WEIGHTS.normal};
  margin-bottom: 1rem;
  padding: 15px 0 10px;
  position: relative;
`;

export const DeleteBtn = styled.div`
  position: absolute;
  right: 0;
`;

export const Wrapper = styled.section`
  padding-top: 1rem;
`;
