import React from 'react';
import { IndicatorStatus } from './type';
import StatusIndicator from 'modules/new-applications/components/StatusIndicator';

interface IProps {
  label: string;
  status?: IndicatorStatus;
}

const TabLabel = ({ label, status = 'incomplete' }: IProps): JSX.Element => {
  return (
    <div>
      <StatusIndicator
        status={status === 'incomplete' ? 'not_started' : status}
      />
      {label}
    </div>
  );
};

export default TabLabel;
