import React, { Component, Fragment } from 'react';

import Appendix from './payment-predictor-sections/Appendix';
import Button from 'modules/shared/components/inputs/Button';
import CompanyInformation from './payment-predictor-sections/CompanyInformation';
import CorporateOwnership from './payment-predictor-sections/CorporateOwnership';
import { DDS_SCORE_RANGE } from './constants';
import IdentificationAndOperations from './payment-predictor-sections/IdentificationAndOperations';
import LegalEventsCollectionsDefaults from './payment-predictor-sections/LegalEventsCollectionsDefaults';
import Officers from './payment-predictor-sections/Officers';
import RegisteredCharges from './payment-predictor-sections/RegisteredCharges';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import Summary from './payment-predictor-sections/Summary';
import get from 'lodash.get';
import isPresent from 'utils/isPresent';
import logo from 'images/illion.png';
import styles from './css/payment-predictor/PaymentPredictorComponent.css';

export function renderAddress(address, options = { emphasize: false }) {
  const className = options.emphasize ? styles.emphasize : '';

  return (
    <div className={styles.address_container}>
      <div className={className}>{address.unformatted_address}</div>
      <div className={className}>
        {`${address.city}, ${address.state} ${address.postcode}`}
      </div>
      <div className={className}>{address.country}</div>
    </div>
  );
}

export function getRiskClassProbabilityOfLatePayment(ddsScore) {
  const score = parseInt(ddsScore);
  const riskClass = getRiskClass(score);
  return (DDS_SCORE_RANGE[riskClass] || {}).probability_of_late_payment;
}

export function getRiskClass(ddsScore) {
  const score = parseInt(ddsScore);
  const riskClasses = Object.keys(DDS_SCORE_RANGE);

  const riskClassScore = riskClasses.find((riskClass) => {
    const scoreRange = DDS_SCORE_RANGE[riskClass];

    return score >= scoreRange.min && score <= scoreRange.max;
  });

  if (isPresent(riskClassScore)) {
    return riskClassScore;
  }

  console.error(`${ddsScore} out of score range`);

  if (ddsScore > DDS_SCORE_RANGE['A'].max) {
    return 'A';
  }

  if (ddsScore < DDS_SCORE_RANGE['H'].min) {
    return 'H';
  }
}

export default class PaymentPredictorComponent extends Component {
  get response() {
    const data = get(this.props, 'data', {});

    return get(data, 'response', {});
  }

  get companyBasicDetails() {
    return get(this.response, 'company_basic_details', {});
  }

  get error() {
    return get(this.response, 'messages.error', {});
  }

  constructor(props) {
    super(props);

    this.state = { isAllExpanded: false };
  }

  onToggleExpandAll = () => {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  };

  renderExpandAllButton() {
    let message = '+ expand all';
    if (this.state.isAllExpanded) {
      message = '- collapse all';
    }

    return (
      <Button
        small
        style={{ fontSize: '10px', margin: '1em 0 1em 66px' }}
        text={message}
        handleClick={this.onToggleExpandAll}
      />
    );
  }

  renderError() {
    const { consumer } = this.props;

    return (
      <ReviewContainer
        subHeading={consumer.attributes.trading_name}
        content_class="content_wide"
        css_class="block_noborder"
      >
        <p>{this.error.desc}</p>
        <p>
          Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
          or live chat with us.
        </p>
      </ReviewContainer>
    );
  }

  renderCreditCheck() {
    const { consumer } = this.props;

    return (
      <Fragment>
        <div className={styles.illion_report}>
          <img src={logo} alt="Illion" className={styles.logo} />
          <div className={styles.header}>
            <h2>{this.companyBasicDetails.organisation_name}</h2>
            <h2>DUNS: {this.companyBasicDetails.duns}</h2>
          </div>
          <Summary
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          {this.renderExpandAllButton()}
          <CompanyInformation
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          <LegalEventsCollectionsDefaults
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          <CorporateOwnership
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          <IdentificationAndOperations
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          <Officers
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          <RegisteredCharges
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          <Appendix
            response={this.response}
            isAllExpanded={this.state.isAllExpanded}
            {...this.props}
          />
          {this.renderExpandAllButton()}
        </div>
      </Fragment>
    );
  }

  render() {
    if (isPresent(this.error)) {
      return this.renderError();
    }

    return this.renderCreditCheck();
  }
}
