import {
  ASSOCIATION_LOAD_RECOVERY_CANDIDATE_START,
  ASSOCIATION_LOAD_RECOVERY_CANDIDATE_SUCCESS,
  ASSOCIATION_LOAD_RECOVERY_CANDIDATE_ERROR,
  ASSOCIATION_NEW_USER_FORM_SET_VALUES,
  ASSOCIATION_NEW_USER_SUCCESS,
  ASSOCIATION_NEW_USER_ERROR,
} from './constants';

var association_defaults = {
  recovery_candidate: null,
  recovery_candidate_loading: false,
  loading_error: false,
  new_user_form_values: {
    first_name: '',
    last_name: '',
    email: '',
    contact_country_code: '',
    contact_phone_number: '',
    password: '',
    password_confirmation: '',
  },
};

export function associationReducer(state = association_defaults, action) {
  switch (action.type) {
    case ASSOCIATION_LOAD_RECOVERY_CANDIDATE_START:
      return {
        ...state,
        recovery_candidate_loading: true,
        loading_error: false,
      };
    case ASSOCIATION_LOAD_RECOVERY_CANDIDATE_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        recovery_candidate_loading: false,
        recovery_candidate: payload,
        new_user_form_values: {
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
          contact_country_code: '',
          contact_phone_number: '',
          password: '',
          password_confirmation: '',
        },
      };
    case ASSOCIATION_LOAD_RECOVERY_CANDIDATE_ERROR:
      return {
        ...state,
        recovery_candidate_loading: false,
        loading_error: true,
      };
    case ASSOCIATION_NEW_USER_FORM_SET_VALUES:
      return { ...state, new_user_form_values: action.payload };
    case ASSOCIATION_NEW_USER_SUCCESS:
      return { ...state, new_user_success: true };
    case ASSOCIATION_NEW_USER_ERROR:
      return { ...state };
    default:
      return { ...state };
  }
}
