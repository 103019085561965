import {
  COB_MONEY_SET_REQUESTED_LIMIT,
  COB_MONEY_COMPONENT_COMPLETE,
  COB_MONEY_SECTION_COMPLETE,
} from '../constants/money';

import { STATE_LOAD_STATE_SUCCESS } from '../../state/constants.js';

import { COB_TRADEACCOUNT_CLEAR_DATA } from '../constants/section';

const money_defaults = {
  completed: {},
  requested_limit: 0,
  section_complete: false,
  section_data: {},
};

export function consumerOnboardingMoneyReducer(state = money_defaults, action) {
  switch (action.type) {
    case COB_TRADEACCOUNT_CLEAR_DATA:
      return { ...money_defaults };
    case COB_MONEY_SET_REQUESTED_LIMIT:
      return { ...state, requested_limit: action.payload };
      break;
    case COB_MONEY_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };
      break;
    case COB_MONEY_SECTION_COMPLETE:
      return {
        ...state,
        section_data: action.payload.data,
        section_complete: action.payload.complete,
      };

    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload['cob_money']) {
        return { ...state, ...action.payload.cob_money };
      }

    default:
      return { ...state };
  }
}
