import { useRef, useState } from 'react';
import { useMousePosition } from 'hooks';

export const useTooltip = () => {
  const [tooltipContent, setTooltipContent] = useState('');

  const mouse = useMousePosition();

  const tooltipX = mouse.x;
  const tooltipY = mouse.y;

  return { tooltipContent, setTooltipContent, tooltipX, tooltipY };
};
