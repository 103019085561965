import api from 'api';
import get from 'lodash.get';
import { appendQuery } from 'modules/addons/helpers';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import React, { useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import isBlank from 'utils/isBlank';

import styles from './embeddedcomponents.css';

function rawHTMLButton(props) {
  return renderToString(<Button {...props} />);
}

function rawHTMLQRCode(props) {
  return renderToString(<QRCodeImage {...props} />);
}

function Button(props) {
  const { link, logo, text } = props;

  return (
    <a
      id="1Centre-website-button"
      href={link}
      style={{
        color: 'black',
        fontFamily: 'Atlas Grotesk Web, sans-serif',
        fontSize: 14,
        fontWeight: 400,
        textDecoration: 'none',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          borderRadius: 20,
          boxShadow: '2px 4px 5px -2px #c9c9c9',
          display: 'flex',
          paddingRight: 20,
          width: 'fit-content',
        }}
      >
        <div
          style={{
            backgroundImage: `url(${logo.url})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '70%',
            borderRadius: '50%',
            boxShadow: '2px 4px 5px -2px #c9c9c9',
            height: 40,
            width: 40,
          }}
        ></div>
        <div
          style={{
            marginLeft: 10,
          }}
        >
          {text}
        </div>
      </div>
    </a>
  );
}

function QRCodeImage(props) {
  const { qrCode } = props;

  return <img src={qrCode} alt="qr-code" />;
}

function QRCode(props) {
  const { currentUser, link, title } = props;
  const [qrCode, setQrCode] = useState(null);

  const qrCodeAPI = api(
    'qr_code',
    currentUser.accessToken,
    get(currentUser, 'currentEntity.id')
  );

  useEffect(() => {
    (async () => {
      const result = await qrCodeAPI.generate(link);
      setQrCode(get(result, 'data.data'));
    })();
  }, []);

  if (isBlank(qrCode)) {
    return null;
  }

  return (
    <div>
      {title && <span>{title}</span>}
      <div className={styles.qr_img_container}>
        <QRCodeImage qrCode={qrCode} />
      </div>
    </div>
  );
}

function ButtonComponent(props) {
  const { link, title, logo } = props;
  const [showToolTip, setShowToolTip] = useState(!logo);

  const websiteLink = appendQuery(link, 'channel=website');
  const rawHTML = rawHTMLButton({ ...props, link: websiteLink }).replace(
    '&amp;channel',
    '&channel'
  );

  const dismissToolTip = () => {
    setShowToolTip(false);
  };

  return (
    <div>
      {title && <span>{title}</span>}
      <div className={styles.text_area}>
        <BorderedTextArea value={rawHTML} readOnly={true} />
      </div>
      <div className={styles.button_content}>
        <Button {...props} link={websiteLink} />
      </div>
      {showToolTip && (
        <ToolTip
          tip_name="IntegrationsWebsiteButton"
          css_style="integrations_website_button"
          dismissHandler={dismissToolTip}
        />
      )}
    </div>
  );
}

function RawLink(props) {
  const { link, title } = props;
  const websiteLink = appendQuery(link, 'channel=website');
  return (
    <div>
      {title && <span>{title}</span>}
      <div className={styles.text_area}>
        <BorderedTextArea value={websiteLink} readOnly={true} />
      </div>
    </div>
  );
}

function QRWidget(props) {
  const { currentUser, link, title } = props;
  const [qrCode, setQrCode] = useState(null);

  const qrCodeAPI = api(
    'qr_code',
    currentUser.accessToken,
    get(currentUser, 'currentEntity.id')
  );

  useEffect(() => {
    (async () => {
      const result = await qrCodeAPI.generate(link);
      setQrCode(get(result, 'data.data'));
    })();
  }, []);

  if (isBlank(qrCode)) {
    return null;
  }

  const rawHTML = rawHTMLQRCode({ qrCode });

  return (
    <div>
      {title && <span>{title}</span>}
      <div className={styles.qr_text_area}>
        <BorderedTextArea value={rawHTML} readOnly={true} />
      </div>
    </div>
  );
}

function Component(props) {
  const { link } = props;
  const websiteLink = appendQuery(link, 'channel=website');
  const rawHTML = rawHTMLButton({ ...props, link: websiteLink }).replace(
    '&amp;channel',
    '&channel'
  );
  const qrLink = appendQuery(link, 'channel=qr');

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.component_text_area}>
          <BorderedTextArea value={rawHTML} readOnly={true} />
        </div>
        <div className={styles.button_content}>
          <Button {...props} link={websiteLink} />
        </div>
      </div>

      <QRCode {...props} link={qrLink} />
    </div>
  );
}

const EmbeddedComponents = {
  Button,
  ButtonComponent,
  Component,
  QRCode,
  QRWidget,
  RawLink,
  rawHTMLButton,
  rawHTMLQRCode,
};

export default EmbeddedComponents;
