import React from 'react';

import { AddonTitle } from './AddOnTitle';
import { CustomerView } from './CustomerView/CustomerView';
import EntityStructure from './EntityStructure';
import Tabs from 'modules/shared/components/widgets/interactive/Tabs';
import styled from 'styled-components';

const ContentContainer = styled.div`
  padding-top: 10px;
`;

const basePath = '/dashboard/addons?active_tab=1account';

const tabs = [
  { title: 'Organisation view', path: '&tab=organisation' },
  { title: 'Customer view', path: '&tab=customer' },
];

export const OneAccount = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tab = queryParams.get('tab') || 'organisation';
  return (
    <>
      <AddonTitle>1Account</AddonTitle>

      <Tabs
        tab_list={tabs}
        base_path="/dashboard/addons?active_tab=1account"
        active_path={`${basePath}&tab=${tab}`}
      />
      <ContentContainer>
        {tab === 'organisation' && (
          <EntityStructure location={window.location} />
        )}
        {tab === 'customer' && <CustomerView />}
      </ContentContainer>
    </>
  );
};
