import React, { Fragment } from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import getAccountTypeName from 'utils/getAccountTypeName';
import GridContent from 'modules/shared/components/containers/GridContent';

interface BulkUploadBusinessInformationProps {
  application: any;
}

const BulkUploadBusinessInformation = ({
  application,
}: BulkUploadBusinessInformationProps) => {
  if (!application.isBulkApplication) return null;

  return (
    <FixedContent header="Customer details">
      <GridContent>
        <div>
          <LabeledContent
            label="Account name"
            content={application.companyName}
          />
          <LabeledContent
            label="Account type"
            content={getAccountTypeName(application.applicationType)}
          />
          <LabeledContent
            label="Business number"
            content={application.businessNumber}
          />
          <LabeledContent
            label="Business start date"
            content={application.formattedRegisteredAt}
          />
        </div>
        <div>
          <LabeledContent
            label="Entity type"
            content={application.formattedLegalType}
          />
          <LabeledContent
            label="Business location"
            content={application.formattedRegion}
          />
          <LabeledContent
            label="Company number"
            content={application.companyNumber}
          />
          <LabeledContent
            label="PPSR Applies?"
            content={application.formattedPpsrApplies}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
};

export default BulkUploadBusinessInformation;
