import React from 'react';
import styled from 'styled-components';
import { Description } from './Description';

const Container = styled.div`
  padding: 20px 0 6px;
`;

export const DescriptionSection = () => (
  <Container>
    <Description>
      Rapid transfer allows you to import your existing customers into your
      1Centre account. This will give you the ability to use functionalities
      including Watchtower, PPSR and Account update for your existing customers.
    </Description>
  </Container>
);
