import React, { Component } from 'react';
import { displayEmpty, formatMoney } from 'utils/formatting';

import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';
import { formatDateFromObject } from 'utils/dateFormatter';
import objectToArray from 'utils/objectToArray';
import { renderAddress } from '../PaymentPredictorComponent';
import styles from '../css/payment-predictor/LegalEventsCollectionsDefaults.css';

export default class LegalEventsCollectionsDefaults extends Component {
  get courtActions() {
    return objectToArray(
      (this.props.response.court_actions || {}).court_action
    );
  }

  get collections() {
    return objectToArray((this.props.response.collections || {}).collection);
  }

  renderCourtActions() {
    const courtActions = this.courtActions.map((courtAction, i) =>
      this.renderCourtAction(courtAction, i)
    );

    if (courtActions.length === 0) {
      return (
        <p className={styles.no_content_notes}>
          There are no court actions recorded against this entity.
        </p>
      );
    }

    return courtActions;
  }

  renderCourtAction(courtAction, i) {
    const address = courtAction.defendant_address || {};

    return (
      <div key={`court-action-${i}`}>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>
            {formatDateFromObject(courtAction.date_lodged)}
          </p>
        </div>
        <div className={styles.court_action_container}>
          <table
            className={`${commonStyles.table} ${styles.court_action_table}`}
          >
            <tbody>
              <tr>
                <td>Defendant</td>
                <td className={commonStyles.highlight_cell}>
                  {displayEmpty(courtAction.defendant)}
                </td>
              </tr>
              <tr>
                <td>Actions</td>
                <td className={commonStyles.highlight_cell}>
                  {displayEmpty(courtAction.action_type_desc)}
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td className={commonStyles.highlight_cell}>
                  {renderAddress(address)}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            className={`${commonStyles.table} ${styles.court_action_table}`}
          >
            <tbody>
              <tr>
                <td>Plaintiff</td>
                <td className={commonStyles.highlight_cell}>
                  {displayEmpty(courtAction.plaintiff_name)}
                </td>
              </tr>
              <tr>
                <td>Amount</td>
                <td className={styles.court_action_amount}>
                  <span className={commonStyles.emphasize}>
                    ${formatMoney(parseFloat(courtAction.amt), 2)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>File number</td>
                <td className={commonStyles.highlight_cell}>
                  {displayEmpty(courtAction.file_num)}
                </td>
              </tr>
              <tr>
                <td>Comments</td>
                <td className={commonStyles.highlight_cell}>-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderCollections() {
    if (this.collections.length === 0) {
      return (
        <p>There are no collections activity recorded against this entity.</p>
      );
    }

    return (
      <div>
        {this.renderCollectionsSummary()}
        {this.renderCollectionsDetails()}
      </div>
    );
  }

  renderCollectionsSummary() {
    const yearlySummary = {};

    let totalAmtOwing = 0;
    let totalAmtPaid = 0;
    let totalCollections = 0;
    let totalValue = 0;

    for (const collection of this.collections) {
      const year = collection.start_date.year;
      let {
        totalAmtOwingByYear,
        totalAmtPaidByYear,
        totalCollectionsByYear,
        totalValueByYear,
      } = yearlySummary[year] || {
        totalAmtOwingByYear: 0,
        totalAmtPaidByYear: 0,
        totalCollectionsByYear: 0,
        totalValueByYear: 0,
      };

      const startAmt = parseFloat(collection.start_amt) || 0;
      const amtOwing = parseFloat(collection.amt_owing) || 0;
      const amtPaid = startAmt - amtOwing;

      // Yearly summary
      totalAmtOwingByYear += amtOwing;
      totalAmtPaidByYear += amtPaid;
      totalCollectionsByYear += 1;
      totalValueByYear += startAmt;

      // Total summary
      totalAmtOwing += amtOwing;
      totalAmtPaid += amtPaid;
      totalCollections += 1;
      totalValue += startAmt;

      yearlySummary[year] = {
        totalAmtOwingByYear,
        totalAmtPaidByYear,
        totalCollectionsByYear,
        totalValueByYear,
      };
    }

    const collectionSummary = Object.keys(yearlySummary).map((year, i) => {
      const summary = yearlySummary[year];

      return this.renderCollectionYearlySummaryByRow(year, summary, i);
    });

    return (
      <table className={`${commonStyles.table} ${styles.collections_table}`}>
        <thead>
          <tr>
            <th>Summary</th>
            <th>Total collections</th>
            <th>Total value</th>
            <th>Amount owing</th>
            <th>Amount paid</th>
          </tr>
        </thead>
        <tbody>
          {collectionSummary}
          <tr>
            <td className={styles.highlight_cell}>Total</td>
            <td className={styles.highlight_cell}>{totalCollections}</td>
            <td className={styles.highlight_cell}>{totalValue}</td>
            <td className={styles.highlight_cell}>{totalAmtOwing}</td>
            <td className={styles.court_action_amount}>{totalAmtPaid}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderCollectionYearlySummaryByRow(year, summary, i) {
    return (
      <tr>
        <td>{year}</td>
        <td>{summary.totalCollectionsByYear}</td>
        <td>{summary.totalValueByYear}</td>
        <td>{summary.totalAmtOwingByYear}</td>
        <td>{summary.totalAmtPaidByYear}</td>
      </tr>
    );
  }

  renderCollectionsDetails() {
    const collections = this.collections.map((collection, i) =>
      this.renderCollectionRow(collection, i)
    );

    return (
      <table className={`${commonStyles.table} ${styles.collections_table}`}>
        <thead>
          <tr>
            <th>Detail</th>
            <th>Creditor</th>
            <th>Start amount</th>
            <th>Amount owing</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{collections}</tbody>
      </table>
    );
  }

  renderCollectionRow(collection, i) {
    const totalValueByYear = parseFloat(collection.start_amt) || 0;
    const amtOwing = parseFloat(collection.amt_owing) || 0;

    return (
      <tr key={`collection-${i}`}>
        <td>{formatDateFromObject(collection.start_date)}</td>
        <td>{displayEmpty(collection.creditor)}</td>
        <td>{`$${formatMoney(totalValueByYear, 2)}`}</td>
        <td>{`$${formatMoney(amtOwing, 2)}`}</td>
        <td className={commonStyles.highlight_cell}>
          {displayEmpty(collection.comments)}
        </td>
      </tr>
    );
  }

  renderDefaults() {
    return <p>There are no defaults recorded against this entity.</p>;
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Legal events, collections and commercial defaults"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.section_container}>
          <div className={commonStyles.subsection_header}>
            <p className={commonStyles.emphasize}>Court actions</p>
          </div>
          {this.renderCourtActions()}
        </div>

        <div className={styles.section_container}>
          <div className={commonStyles.subsection_header}>
            <p className={commonStyles.emphasize}>Collections</p>
          </div>
          {this.renderCollections()}
        </div>

        <div className={styles.section_container}>
          <div className={commonStyles.subsection_header}>
            <p className={commonStyles.emphasize}>Defaults</p>
          </div>
          {this.renderDefaults()}
        </div>
      </ReviewContainer>
    );
  }
}
