import { useEffect, useState } from 'react';

import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
} from 'constants/pagination';

const queryStringToJson = (queryString: string) => {
  const json: Record<string, number | string> = {};
  const searchParams = new URLSearchParams(queryString);
  searchParams.forEach((value, key) => {
    if (['page', 'per'].includes(key)) {
      json[key] = Number(value);
    } else {
      json[key] = value;
    }
  });
  return json;
};

const defaultParams = {
  page: DEFAULT_PAGE_NUMBER,
  per: DEFAULT_ITEMS_PER_PAGE,
  key_word: '',
  only_current_user: 'false',
  status: 'all',
};

export const useCustomersParams = () => {
  const queryString = window.location.search;

  const [params, setParams] = useState(defaultParams);

  useEffect(() => {
    if (queryString) {
      setParams({ ...defaultParams, ...queryStringToJson(queryString) });
    }
  }, [queryString]);

  return params;
};
