import Abstract from '../modules/authorisation/components/Abstract';
import AuthorisationOnBoarding from '../modules/authorisation/components/AuthorisationOnBoarding';
import Confirm from '../modules/authorisation/components/Confirm';
import IdentityDetails from '../modules/authorisation/components/IdentityDetails';
import Review from '../modules/authorisation/components/Review';
import Terms from '../modules/authorisation/components/Terms';
import Layout from '../modules/authorisation/containers/Layout';

const AuthorisationRoutes = [
  {
    component: Review,
    path: 'review',
  },
  {
    component: Abstract,
    path: 'abstract',
  },
  {
    component: IdentityDetails,
    path: 'identity-details',
  },
  {
    component: Terms,
    path: 'terms',
  },
  {
    component: Confirm,
    path: 'confirm',
  },
  {
    component: AuthorisationOnBoarding,
    path: ':authorisation_id/:component(/**)',
  },
];

module.exports = {
  childRoutes: AuthorisationRoutes,
  component: Layout,
  path: 'authorisation',
};
