export function getEighteenYearsAgo() {
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(new Date().getFullYear() - 18);

  return eighteenYearsAgo;
}

export function get100YearsAgo() {
  return new Date(new Date().getFullYear() - 100, 0, 1);
}
