import React, { ReactElement } from 'react';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';

type Props = {
  disabled: boolean;
  error: string;
  handleBlur: () => void;
  handleChange: () => void;
  required: boolean;
  value: string;
};

export default function StateDropdown({
  disabled,
  error,
  handleBlur,
  handleChange,
  required,
  value,
}: Props): ReactElement {
  const states = [
    {
      label: 'NSW',
      value: 'NSW',
    },
    {
      label: 'QLD',
      value: 'QLD',
    },
    {
      label: 'SA',
      value: 'SA',
    },
    {
      label: 'TAS',
      value: 'TAS',
    },
    {
      label: 'VIC',
      value: 'VIC',
    },
    {
      label: 'WA',
      value: 'WA',
    },
    {
      label: 'ACT',
      value: 'ACT',
    },
    {
      label: 'NT',
      value: 'NT',
    },
  ];

  return (
    <OptionsDropdown
      id="driver_licence_state"
      name="driver_licence_state"
      handleChange={handleChange}
      handleBlur={handleBlur}
      error={error}
      label="State"
      value={value}
      required={required}
      options={states}
      disabled={disabled}
    />
  );
}
