import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from 'modules/FileUpload';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import { setProofOfAddress } from '../actions';
import { BoldItalicLabel } from '../components/IdentityCapture/styles';

function ProofOfAdressUpload(props) {
  const {
    apiType,
    authorisationID,
    dispatch,
    distinctId,
    resourceType,
    hasError,
    proof_of_address,
  } = props;

  const fileId = 'proof_of_address';

  return (
    <div>
      <SectionHeader title="Proof of address upload">
        <p>
          Please add proof of address {'('}eg. utility bill, current bank
          statement, council rates notice{')'}. The file needs to be{' '}
          <BoldItalicLabel>JPEG</BoldItalicLabel>,{' '}
          <BoldItalicLabel>PNG</BoldItalicLabel> or a{' '}
          <BoldItalicLabel>one page PDF</BoldItalicLabel> smaller than 20MB.
        </p>
      </SectionHeader>
      <FileUpload
        apiType={apiType}
        authorisationID={authorisationID}
        attachmentType={fileId}
        distinctId={distinctId}
        errorMessage="Please upload your proof of address."
        fileId={fileId}
        hasError={hasError}
        resourceType={resourceType}
        uploadedFile={proof_of_address}
      />
    </div>
  );
}

ProofOfAdressUpload.propTypes = {
  apiType: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
};

ProofOfAdressUpload.defaultProps = {
  apiType: 'authorisations',
  resourceType: 'authorisation',
};

export default ProofOfAdressUpload;
