import React, { useEffect, useState } from 'react';
import Modal from 'modules/shared/components/v2/Modal';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import { ACCOUNT_UPDATE_REASONS, EMAIL_REGEX } from '../../../../constants';

import './accountUpdateModal.scss';

type ApplicationTypeNames = { credit: string; cash: string };

interface IAccountUpdateModalProps {
  handleClose: () => void;
  isOpen: boolean;
  applicationTypeNames: ApplicationTypeNames;
  supplierId: string;
  currentApplicationId: string;
  invitesApi: any;
}

interface IAccountUpdateState {
  application_type: string;
  email: string;
  reason: string;
}

const accountUpdateInitialValues = {
  application_type: '',
  email: '',
  reason: '',
};

const buildApplicationTypeNameOption = (
  applicationTypeNames: ApplicationTypeNames
) =>
  Object.freeze([
    { label: applicationTypeNames.credit, value: 'credit' },
    { label: applicationTypeNames.cash, value: 'cash' },
  ]);

const AccountUpdateModal = ({
  handleClose,
  isOpen,
  applicationTypeNames,
  supplierId,
  currentApplicationId,
  invitesApi,
}: IAccountUpdateModalProps) => {
  const [emailError, setEmailError] = useState<string>('');
  const [isInviteSent, setIsInviteSent] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [newId, setNewId] = useState<string>('');

  const [accountUpdateState, setAccountUpdateState] = useState<
    IAccountUpdateState
  >(accountUpdateInitialValues);
  const applicationTypeOptions = buildApplicationTypeNameOption(
    applicationTypeNames
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyName: string
  ) => {
    event.persist();
    const fieldValue = event.target.value;
    setAccountUpdateState((prevState) => ({
      ...prevState,
      [propertyName]: fieldValue,
    }));
    if (propertyName === 'email') {
      const errorMessage = fieldValue.match(EMAIL_REGEX)
        ? ''
        : 'Please enter a valid email address';
      setEmailError(errorMessage);
    }
  };

  const successCallback = (response) => {
    setIsInviteSent(true);
    setNewId(response.data.data[0].id);
  };

  const handleSubmit = () => {
    if (emailError) {
      return;
    }

    const attributes = {
      action_type: 'signup_and_application',
      supplier_id: supplierId,
      prev_application_id: currentApplicationId,
      ...accountUpdateState,
    };

    invitesApi.create(attributes, successCallback);
  };

  useEffect(() => {
    for (let value of Object.values(accountUpdateState)) {
      if (!value) {
        setDisabledButton(true);
        return;
      } else {
        setDisabledButton(false);
      }
    }
  }, [accountUpdateState]);

  if (!isOpen) return null;

  const inviteSentBody = (
    <div className="modal-unsent-body">
      Account update form has been sent to your customer with all existing info
      pre-filled.
    </div>
  );

  const closeModal = () => {
    if (isInviteSent && newId) {
      window.location.href = `/dashboard/applications/${newId}/`;
    }
    handleClose();
  };

  const inviteUnsentBody = (
    <div>
      <div className="formfield-wrapper">
        <BorderedSelect
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event, 'application_type')
          }
          label="New application type"
          value={accountUpdateState.application_type}
          required={true}
          options={applicationTypeOptions}
        />
      </div>
      <div className="formfield-wrapper">
        <BorderedTextField
          label="Email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event, 'email')
          }
          required={true}
          value={accountUpdateState.email}
          error={emailError}
          helperText={emailError}
          variant="outlined"
        />
      </div>
      <BorderedSelect
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(event, 'reason')
        }
        label="Account update reason"
        value={accountUpdateState.reason}
        required={true}
        options={ACCOUNT_UPDATE_REASONS}
      />
    </div>
  );

  return (
    <Modal
      title="Account update"
      onClose={closeModal}
      rootClassName="modal-account-update"
      footer={
        isInviteSent ? null : (
          <Button
            text="Send"
            handleClick={handleSubmit}
            disabled={disabledButton}
          />
        )
      }
    >
      {isInviteSent ? inviteSentBody : inviteUnsentBody}
    </Modal>
  );
};

export default AccountUpdateModal;
