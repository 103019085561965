import axios from './axios';

let Trm = function(config) {
  this.axios = axios(config);
  this.type = 'trms';
};

Trm.prototype.getTrms = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

export default Trm;
