import React from 'react';

import { FormControl, FormHelperText } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ThemeProvider as MuiThemeProvider, styled } from '@material-ui/core/styles';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import styles from 'modules/shared/components/inputs/css/FormRadioBox.css';
import { Controller } from 'react-hook-form-latest';

const StyledFormHelperText = styled(FormHelperText)({
  fontSize: 12
})

const FormRadioBox = (props): JSX.Element => {
  const {
    control,
    defaultValue = '',
    error,
    id,
    label,
    name,
    options,
    row,
    value,
    onChange,
    disabled,
  } = props;
  return (
    <MuiThemeProvider theme={muiTheme()}>
      <FormControl error={!!error}>
        <div className={styles.label}>{label}</div>
        <Controller
          render={({ field }) => (
            <RadioGroup
              row={row}
              defaultValue={defaultValue}
              defaultChecked={defaultValue}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e.target.value);
              }}
            >
              {options.map((option) => (
                <FormControlLabel
                  name={id}
                  checked={
                    value !== undefined
                      ? value === option.value
                      : field?.value === option.value
                  }
                  disabled={disabled}
                  key={`${name}-${option.label}`}
                  value={option.value}
                  control={<Radio />}
                  label={<div className={styles.label}>{option.label}</div>}
                />
              ))}
            </RadioGroup>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue}
        />
        <StyledFormHelperText
          error={!!error}
        >
          {error}
        </StyledFormHelperText>
      </FormControl>
    </MuiThemeProvider>
  );
};

export default FormRadioBox;
