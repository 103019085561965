import { DeleteBtn } from 'modules/card-management-onboarding/components/Details/components/CardholderDetails/styles';
import styled, { css } from 'styled-components';
import { BORDERS } from 'variables/theme';
import { FONT_SIZES } from 'variables/typography';

export const Section = styled.div<{ unselected?: boolean }>`
  position: relative;

  &:not(:last-child) {
    border-bottom: ${BORDERS.generic};
    margin-bottom: 3rem;
    padding-bottom: 2rem;
  }

  h3 {
    font-size: ${FONT_SIZES.title};
  }

  ${(props) =>
    props.unselected &&
    css`
      opacity: 0.3;
    `}
`;

export const Delete = styled(DeleteBtn)`
  top: 0.5rem;
`;
