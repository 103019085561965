import React, { Component } from 'react';

import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { TERMS_AND_CONDITIONS } from 'constants';
import { customTermsUrl } from 'utils/extractAttachmentUrl';
import styles from './css/Review.css';

export default class ReviewTerms extends Component {
  hasCustomSupplierTerms() {
    const attributes = this.props.data.application.attributes;

    return (
      attributes.uses_custom_supplier_terms && attributes.custom_terms.file
    );
  }

  supplierTerms() {
    const { data } = this.props;
    const application = data.application;

    if (this.hasCustomSupplierTerms()) {
      return customTermsUrl(application.attributes.custom_terms).url;
    }

    return TERMS_AND_CONDITIONS[data.supplier.attributes.region].supplier;
  }

  render() {
    const termsContent = <PdfReader url={this.supplierTerms()} />;

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <ReviewContainer content_class="content_full">
          {termsContent}
        </ReviewContainer>
      </section>
    );
  }
}
