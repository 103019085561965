import React from 'react';
import get from 'lodash.get';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';

const Addresses = (props) => {
  const { application } = props;

  if (application.legalType === 'personal') {
    return null;
  }

  const physicalAddress = get(application, 'physicalAddress');
  const postalAddress = get(application, 'postalAddress');

  let postalAddressLabel = 'Postal/delivery address';
  if (application.legalType === 'company') {
    postalAddressLabel = 'Postal address';
  }

  return (
    <FixedContent header="Address">
      <GridContent>
        <div>
          <LabeledContent
            label="Business address"
            content={physicalAddress.fullAddress}
          />
          <LabeledContent
            label="Delivery instructions"
            content={application.deliveryInstruction}
            isVisibleWhenEmpty={application.legalType !== 'company'}
          />
        </div>

        <div>
          <LabeledContent
            label={postalAddressLabel}
            content={postalAddress.fullAddress}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
};

export default Addresses;
