import React, { useEffect, useMemo, useState } from 'react';
import get from 'lodash.get';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import {
  useTheme,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';

import {
  createRuleSet,
  updateRuleSet,
} from 'modules/addons/auto_decisioning/actions';
import {
  loadAddons,
  loadAutoDecisions,
  updateSelectedRulesetValue,
  updateAutodecisionRuleset,
} from 'modules/addons/actions';
import { loadAmlCheckRules } from 'modules/profile/actions';

import { AdditionsForm, ExitConfirmation, SetupComplete } from './components';

import GeneralDetailsForm from './GeneralDetailsForm';
import TabLabel from './TabLabel';
import ValidationForm from './ValidationForm';
import EditHistory from './EditHistory';
import CreditCheckForm from './CreditCheckForm';
import FinancialsForm from './FinancialsForm';
import { IAutoDecision, IRuleSetAttributes, TabIds } from './type';
import { useTabs } from './hooks';
import './RulesetContent.scss';
import { withRouter } from 'react-router';
import { getButtonLabel } from './getButtonLabel';
import {
  checkAmlEnabledInSettings,
  checkValidationFormComplete,
  processAntiFraudSettings,
} from './utils';
import { getYesOrNo } from './YesOrNoRadioBoxGroup';
import { AmlSettings } from 'types/AmlSettings';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import { FEATURE_FLAGS } from 'conf';

const applyCashRuleChanges = FEATURE_FLAGS.FEATURE_FLAG_1CAD_CASH_RULE_CHANGES;

const useStyles = makeStyles({
  ruleSetNameRoot: {
    width: '50%',
    height: '50px',
  },
  inputRoot: {
    fontSize: '16px',
    width: '100%',
  },
  helperText: {
    fontSize: '12px',
  },
  selectedTab: {
    '&.Mui-selected span': {
      color: 'red',
    },
  },
});

interface IProps {
  actions: {
    createRuleSet: (
      ruleset: IRuleSetAttributes,
      successCallback: () => void
    ) => void;
    updateRuleSet: (
      id: string,
      ruleset: IRuleSetAttributes,
      onSuccess: () => void
    ) => void;
    loadAddons: (addonType: string) => void;
    loadAutoDecisions: () => void;
    loadAmlCheckRules: () => void;
    updateSelectedRulesetValue: (
      selectedRulesetIndex: number,
      property: string,
      value: unknown
    ) => void;
    updateAutodecisionRuleset: (
      selectedRulesetIndex: number,
      ruleset: any
    ) => void;
  };
  isUpdating: boolean;
  rulesetIndex: number;
  selectedRulesetIndex: number;
  autoDecisions: IAutoDecision[];
  location: Location;
  selectedRulesetVersionIndex: number | null;
  isGuarantorRequiredInSettings: boolean;
  settingsAntiFraud: any;
  settingsAmlCheckRules: AmlSettings;
  settingsGuarantorCount: number;
  isReadonly: boolean;
  isViewingHistoryVersion: boolean;
}

const RulesetContent = ({
  rulesetIndex,
  actions,
  autoDecisions,
  isReadonly,
  isUpdating,
  location,
  selectedRulesetVersionIndex,
  isGuarantorRequiredInSettings,
  settingsAntiFraud,
  settingsAmlCheckRules,
  settingsGuarantorCount,
  isViewingHistoryVersion,
}: IProps): JSX.Element => {
  useEffect(() => {
    actions.loadAddons('financials');
    actions.loadAddons('paperless');
    actions.loadAmlCheckRules();
  }, []);

  const currentRuleset = useMemo(() => {
    if (selectedRulesetVersionIndex === null) {
      // No history version is selected
      // We need to show the latest one
      return autoDecisions[rulesetIndex];
    }

    return {
      ...autoDecisions[rulesetIndex],
      attributes: {
        ...autoDecisions[rulesetIndex].attributes,
        ...autoDecisions[rulesetIndex]?.attributes?.history_data?.[
          selectedRulesetVersionIndex
        ],
      },
    };
  }, [autoDecisions, selectedRulesetVersionIndex, rulesetIndex, rulesetIndex]);

  const { attributes, id, is_new: isNewRuleset } = currentRuleset;

  let rulesetTheme = useTheme();

  rulesetTheme = createMuiTheme(rulesetTheme, {
    overrides: {
      MuiTabs: {
        indicator: {
          height: '6px',
        },
      },
    },
    MuiTab: {
      root: {
        color: rulesetTheme.palette.text.primary,
        padding: '6px 0',
        marginRight: '16px',
        fontSize: '14px',
        textTransform: 'none',
        '&$selected': {
          '& span': {
            color: rulesetTheme.palette.primary.main,
          },
        },
      },
      textColorPrimary: {
        color: rulesetTheme.palette.text.primary,
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'start',
      },
    },
  });

  const {
    tabs,
    activeTab,
    onTabChange,
    navigateToValidationsTab,
    navigateToNextTab,
  } = useTabs(currentRuleset, location);

  const classes = useStyles();
  const [rulesetName, setRulesetName] = useState('');
  const [nameModified, setNameModified] = useState<boolean>(false);
  const [ruleSetNameError, setRulesetNameError] = useState<string | null>(null);
  const [isSetupCompleteDialogOpen, setSetupCompleteDialogOpen] =
    useState(false);
  const [rulesetToCreate, setRuleSetToCreate] =
    useState<IRuleSetAttributes | null>(null);
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    setRulesetName(attributes.name || '');
  }, [id]);

  const {
    legal_types,

    credit_checks_required,
    nz_personal_credit_indicator,
    au_personal_credit_indicator,

    require_anti_fraud,
    aml_verification,
    guarantors,
    guarantors_count,
    signatories,
    signatories_count,
    validation_status,
  } = attributes;

  const isPersonalCreditCheckSet =
    credit_checks_required &&
    (nz_personal_credit_indicator || au_personal_credit_indicator);

  const isCompanyEntitySelected = legal_types.includes('company');
  const isCreditRuleset = attributes.application_types.includes('credit');

  const isGuarantorRequired = applyCashRuleChanges
    ? isCreditRuleset &&
      isGuarantorRequiredInSettings &&
      isPersonalCreditCheckSet &&
      isCompanyEntitySelected
    : isGuarantorRequiredInSettings &&
      isPersonalCreditCheckSet &&
      isCompanyEntitySelected;

  const isGuarantorSet =
    guarantors_count !== null && guarantors_count !== undefined;

  const shouldShowGuarantorError = isGuarantorRequired && !isGuarantorSet;

  const isPersonalEntityClassSelected =
    legal_types.includes('trust') ||
    legal_types.includes('partnership') ||
    legal_types.includes('personal');

  const isSoleTraderClassSelected =
    FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER
      ? legal_types.includes('sole_trader')
      : false;

  const isSignatoryRequired = applyCashRuleChanges
    ? isCreditRuleset &&
      isPersonalCreditCheckSet &&
      (isPersonalEntityClassSelected || isSoleTraderClassSelected)
    : isPersonalCreditCheckSet &&
      (isPersonalEntityClassSelected || isSoleTraderClassSelected);

  const isSignatorySet =
    signatories_count !== null && signatories_count !== undefined;

  const shouldShowSignatoryError = isSignatoryRequired && !isSignatorySet;

  useEffect(() => {
    if (shouldShowGuarantorError || shouldShowSignatoryError) {
      // Personal credit check is set in Credit check tab
      // But guarantors and signatories are not set in Validation tab
      // We need to pause the Validation tab and let user know about this
      actions.updateSelectedRulesetValue(
        rulesetIndex,
        'validation_status',
        'paused'
      );
      navigateToValidationsTab();
    } else if (validation_status === 'paused') {
      const formValues = {
        require_anti_fraud: getYesOrNo(require_anti_fraud),
        aml_verification: getYesOrNo(aml_verification),
        guarantors: getYesOrNo(guarantors),
        guarantors_count,
        signatories: getYesOrNo(signatories),
        signatories_count,
      };
      const isAntiFraudApplicable = processAntiFraudSettings(
        settingsAntiFraud,
        attributes
      );
      const isAmlEnabledInSettings = checkAmlEnabledInSettings(
        settingsAmlCheckRules
      );
      const isValidationFormComplete = checkValidationFormComplete(
        formValues,
        isAmlEnabledInSettings,
        isAntiFraudApplicable,
        settingsGuarantorCount,
        isCreditRuleset
      );
      actions.updateSelectedRulesetValue(
        rulesetIndex,
        'validation_status',
        isValidationFormComplete ? 'complete' : 'incomplete'
      );
      navigateToValidationsTab();
    }
  }, [shouldShowGuarantorError, shouldShowSignatoryError]);

  const submitButtonLabel = getButtonLabel(attributes, activeTab);

  const validateRuleSetName = (currentName: string | null): boolean => {
    let error: string | null = null;
    if (!currentName) {
      error = 'Please enter a ruleset name';
    } else {
      const matchingAutoDecision = autoDecisions.find(
        (autoDecision) =>
          autoDecision.id !== id && autoDecision.attributes.name === currentName
      );

      if (matchingAutoDecision) {
        error = 'The ruleset name already exists';
      }

      if (currentName.length > 100) {
        error = 'Ruleset name must not be more than 100 characters long';
      }
    }

    setRulesetNameError(error);

    return !error;
  };

  const ruleSetNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const name = event.target.value;
    setRulesetName(name);
    validateRuleSetName(name);
    setNameModified(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const isReadyToSubmitForm = (): boolean => {
    const isNameValid = validateRuleSetName(rulesetName);
    if (!isNameValid) {
      scrollToTop();
    }

    return isNameValid;
  };

  const onSubmitTabData = (data) => {
    if (isNewRuleset) {
      actions.updateAutodecisionRuleset(rulesetIndex, data);
      if (submitButtonLabel === 'Next') {
        actions.updateAutodecisionRuleset(rulesetIndex, data);
        navigateToNextTab();
      } else {
        const newRuleset = { ...attributes, ...data };
        setSetupCompleteDialogOpen(true);
        setRuleSetToCreate(newRuleset);
      }
      return;
    }

    if (id) {
      const updatedRuleset = { ...attributes, ...data };
      actions.updateRuleSet(id, updatedRuleset, actions.loadAutoDecisions);
    }
  };

  const handleCancelSetupComplete = () => {
    setRuleSetToCreate(null);
    setSetupCompleteDialogOpen(false);
  };

  const onSuccessCreateRuleset = () => {
    setRuleSetToCreate(null);
    setSetupCompleteDialogOpen(false);
    actions.loadAutoDecisions();
  };

  const isEditingDisabled = selectedRulesetVersionIndex !== null || isReadonly;

  const getTabContent = () => {
    let FormComponent;
    switch (activeTab) {
      case TabIds.general: {
        FormComponent = GeneralDetailsForm;
        break;
      }
      case TabIds.validation: {
        FormComponent = ValidationForm;
        break;
      }
      case TabIds.credit: {
        FormComponent = CreditCheckForm;
        break;
      }
      case TabIds.additions: {
        FormComponent = AdditionsForm;
        break;
      }
      case TabIds.financials: {
        FormComponent = FinancialsForm;
        break;
      }
      default:
    }

    if (FormComponent) {
      return (
        <FormComponent
          attributes={attributes}
          id={id}
          disabled={isEditingDisabled}
          hideSaveButton={isEditingDisabled}
          isGuarantorRequired={isGuarantorRequired}
          shouldShowGuarantorError={shouldShowGuarantorError}
          isSignatoryRequired={isSignatoryRequired}
          shouldShowSignatoryError={shouldShowSignatoryError}
          nameModified={nameModified}
          setNameModified={setNameModified}
          isReadyToSubmitForm={isReadyToSubmitForm}
          isUpdating={isUpdating}
          rulesetIndex={rulesetIndex}
          onSubmitTabData={onSubmitTabData}
          submitButtonLabel={submitButtonLabel}
          navigateToValidationsTab={
            activeTab === TabIds.credit ? navigateToValidationsTab : undefined
          }
          rulesetName={rulesetName}
          setDirty={setDirty}
          isViewingHistoryVersion={isViewingHistoryVersion}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="rulesetContent">
      <TextField
        classes={{ root: classes.ruleSetNameRoot }}
        disabled={isEditingDisabled}
        InputProps={{
          classes: { root: classes.inputRoot },
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        placeholder="Ruleset name"
        value={rulesetName}
        onChange={ruleSetNameChangeHandler}
        error={!!ruleSetNameError}
        helperText={ruleSetNameError}
        required
      />
      <MuiThemeProvider theme={rulesetTheme}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
          aria-label="1CAD tabs"
        >
          {tabs.map(({ label, status, value }, index) => (
            <Tab
              key={index}
              value={value}
              label={<TabLabel label={label} status={status} />}
            />
          ))}
        </Tabs>
      </MuiThemeProvider>
      {getTabContent()}
      <EditHistory
        history={{
          name: attributes.editor_first_name,
          updatedTime: attributes.updated_at,
        }}
        trailLogs={attributes.trail_logs || []}
        activeTab={activeTab}
      />
      {isNewRuleset || isDirty ? <ExitConfirmation /> : null}
      <SetupComplete
        isOpen={isSetupCompleteDialogOpen}
        onCancel={handleCancelSetupComplete}
        onSuccess={onSuccessCreateRuleset}
        ruleset={rulesetToCreate}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  autoDecisions: state.add_ons.auto_decisions,
  isUpdating: state.auto_decisions.updating,
  isGuarantorRequiredInSettings: get(
    state,
    'manage_profile.current_entity.attributes.requires_guarantees',
    false
  ),
  isReadonly: !isFeatureEditEnabled('1CAD'),
  settingsGuarantorCount: get(
    state,
    'manage_profile.current_entity.attributes.minimum_guarantees',
    0
  ),
  settingsAntiFraud: get(state, 'manage_profile.settingsAntiFraud', []),
  settingsAmlCheckRules: get(state, 'manage_profile.settingsAmlCheckRules', {}),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createRuleSet,
      updateRuleSet,
      loadAddons,
      loadAutoDecisions,
      loadAmlCheckRules,
      updateSelectedRulesetValue,
      updateAutodecisionRuleset,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RulesetContent)
);
