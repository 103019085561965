import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import intersection from 'lodash.intersection';
import AntiFraudCategoryRule, {
  DEFAULT_ANTI_FRAUD_CATEGORY,
} from 'utils/AntiFraudCategoryRule';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

const getIsWithinLimitRange = ({
  antiFraud,
  requestedLimit,
  applicationType,
}) => {
  if (antiFraud.category === '0' || applicationType === 'cash') {
    return true;
  }
  const { maxCreditValue, minCreditValue } = antiFraud;

  if (isBlank(maxCreditValue)) {
    return true;
  }

  const maxValue = parseInt(maxCreditValue);
  const minValue = parseInt(minCreditValue || 0);

  return requestedLimit <= maxValue && requestedLimit >= minValue;
};

const getApplicableAntiFraudRules = ({
  antiFraud,
  application,
  authorisationRoles,
  requestedLimit,
}) => {
  // If AntiFraud is not set, it means AML is selected
  if (isBlank(antiFraud) || !antiFraud.isPersisted) {
    return {
      requiresFrontFaceImage: false,
      requiresGovernmentVerification: false,
      requiresIdentificationImage: true,
      requiresIdentificationSection: true,
      requiresProofOfLife: false,
    };
  }

  const antiFraudConfig = get(antiFraud, 'config', {});
  const checkAccountType = FEATURE_FLAGS.FEATURE_FLAG_1CAF_ACCOUNT_TYPES
    ? antiFraudConfig.account_types &&
      !antiFraudConfig.account_types.includes(application.applicationType)
    : false;

  const applicableAuthorisationTypes = get(
    antiFraud,
    'config.authorisation_types',
    []
  );

  const hasAuthorisationTypeIntersection = isPresent(
    intersection(authorisationRoles, applicableAuthorisationTypes)
  );

  const hasLimitIntersection = getIsWithinLimitRange({
    antiFraud,
    requestedLimit,
    applicationType: application.applicationType,
  });

  if (
    !antiFraud.legalTypes.includes(application.legalType) ||
    checkAccountType ||
    (FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0 &&
      (!hasAuthorisationTypeIntersection || !hasLimitIntersection))
  ) {
    // Disable the whole ID section
    return {
      requiresFrontFaceImage: false,
      requiresGovernmentVerification: false,
      requiresIdentificationImage: false,
      requiresIdentificationSection: false,
      requiresProofOfLife: false,
    };
  }

  let applicableCategory = DEFAULT_ANTI_FRAUD_CATEGORY;

  if (hasAuthorisationTypeIntersection && hasLimitIntersection) {
    applicableCategory = antiFraud.category;
  }

  const categoryRule = new AntiFraudCategoryRule(applicableCategory);
  return categoryRule.rulesForCategory;
};

export default getApplicableAntiFraudRules;
