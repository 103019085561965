import React from 'react';

import styles from '../../css/CommonReviewBusiness.css';

var PPSRFinanceStatement = createClass({
  _companies(companies) {
    return companies.map((company, index) => {
      return (
        <tr key={index}>
          <td>{company.company_name}</td>
          <td>{company.company_number}</td>
          <td>{company.company_status_description}</td>
          <td>{company.combined_credit_limit}</td>
          <td>{company.number_of_suppliers}</td>
          <td>{company.dso}</td>
        </tr>
      );
    });
  },

  render() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      parentCompanies = companyDetails.parent_companies || {},
      companies = parentCompanies.company2_ws_dto || [];

    if (companies.constructor === Object) companies = [companies];

    return (
      <div>
        <p>
          <strong>Parent Companies</strong>
        </p>
        <p>
          Companies that have a shareholding in{' '}
          {companyDetails.company.company_name}, and other companies in the
          group.
        </p>
        <table className={styles.table_w20}>
          <thead>
            <tr>
              <th>Company</th>
              <th>Company Number</th>
              <th>Status</th>
              <th>Credit Limit</th>
              <th>Number of Suppliers</th>
              <th>DSO</th>
            </tr>
          </thead>
          <tbody>{this._companies(companies)}</tbody>
        </table>
      </div>
    );
  },
});

export default PPSRFinanceStatement;
