import { string } from 'prop-types';
import { createContext, useState } from 'react';
import isPresent from 'utils/isPresent';

interface IUserContext {
  email: string;
  emailFromParams: string;
  isEmailFieldDisabled: boolean;
  registrationStatus: string | null;
  registrationToken: string | null;
  setEmail: (email: string) => void;
  setEmailFromParams: (emailFromParams: string) => void;
  setRegistrationStatus: (registrationStatus: string) => void;
  setRegistrationToken: (registrationToken: string) => void;
  setUserId: (userId: string) => void;
  setVerifiedStatePayload: ({
    registrationStatus,
    registrationToken,
    userId,
  }: {
    registrationStatus: string;
    registrationToken: string;
    userId: string;
  }) => void;
  userId: string | null;
}

export const UserContext = createContext<IUserContext>({
  email: '',
  emailFromParams: '',
  isEmailFieldDisabled: false,
  registrationStatus: null,
  registrationToken: null,
  setEmail: (email: string) => email,
  setEmailFromParams: (emailFromParams: string) => emailFromParams,
  setRegistrationStatus: (registrationStatus: string) => registrationStatus,
  setRegistrationToken: (registrationToken: string) => registrationToken,
  setUserId: (userId: string) => userId,
  setVerifiedStatePayload: () => null,
  userId: null,
});

export const useUserState = (): IUserContext => {
  const [emailFromParams, setEmailFromParams] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [registrationStatus, setRegistrationStatus] = useState<string | null>(
    null
  );
  const [userId, setUserId] = useState<string | null>(null);
  const [registrationToken, setRegistrationToken] = useState<string | null>(
    null
  );

  const isEmailFieldDisabled = isPresent(emailFromParams);

  const setVerifiedStatePayload = ({
    registrationStatus,
    registrationToken,
    userId,
  }) => {
    setRegistrationStatus(registrationStatus);
    setRegistrationToken(registrationToken);
    setUserId(userId);
  };

  return {
    email,
    emailFromParams,
    isEmailFieldDisabled,
    registrationStatus,
    registrationToken,
    setEmail,
    setEmailFromParams,
    setRegistrationStatus,
    setRegistrationToken,
    setUserId,
    setVerifiedStatePayload,
    userId,
  };
};
