import React, { FunctionComponent } from 'react';
import PanelSection from './Section';
import moment from 'moment';

import './historysection.scss';

const HistoryItem = ({ historyData, format = 'edited' }) => {
  const { attributes } = historyData;

  const templates = {
    edited: (attributes) => `Last edited by ${attributes.editor_first_name} at
     ${moment(attributes.created_at).format('DD/MM/YYYY HH:mm')}.`,
  };

  return <span>{templates[format || 'edited'](attributes)}</span>;
};

interface HistoryPanelProps {
  historyList: any;
  historyCount?: number | 'all';
  borderless?: boolean;
}

const HistoryPanelSection: FunctionComponent<HistoryPanelProps> = ({
  historyList = [],
  historyCount = 0,
  borderless = false,
}) => {
  const getHistory = () => {
    const historyLength = historyList.length;
    if (!historyLength) return null;

    const historyIndex =
      historyCount === 'all' ? historyLength : historyCount ? historyCount : 1;

    const historyItems = historyList.slice(0, historyIndex);

    return historyItems.map((historyData, index) => {
      return <HistoryItem key={index} historyData={historyData} />;
    });
  };

  return (
    <PanelSection borderless={borderless}>
      <div className="panel-section-history-container">{getHistory()}</div>
    </PanelSection>
  );
};

export default HistoryPanelSection;
