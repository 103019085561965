import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import { InputAdornment } from 'mui-latest';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form-latest';
import FormFieldWrapper from 'modules/consumer-onboarding/v2/FormFieldWrapper';
import {
  FormWrapper,
  MobileWrapper,
  SectionHeader,
  FormGroupWrapper,
} from 'modules/consumer-onboarding/v2/styles';
import ButtonGroupWrapper from 'modules/consumer-onboarding/v2/ButtonGroupWrapper';
import Button from 'modules/shared/components/inputs/Button';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import * as yup from 'yup';
import isBlank from 'utils/isBlank';
import { connect } from 'react-redux';
import { userLogin } from 'modules/user/actions';
import api from 'api';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

const MINIMUM_PASSWORD_LENGTH = 8;

const validationSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .matches(/^(?=.*[\W_\s])/, {
        excludeEmptyString: true,
        message: 'Please include at least one special character',
      })
      .matches(/^(?=.*[0-9])/, {
        excludeEmptyString: true,
        message: 'Please include at least one number',
      })
      .matches(/^(?=.*[a-z])/, {
        excludeEmptyString: true,
        message: 'Please include at least one lower case character',
      })
      .matches(/^(?=.*[A-Z])/, {
        excludeEmptyString: true,
        message: 'Please include at least one upper case character',
      })
      .min(
        MINIMUM_PASSWORD_LENGTH,
        `Please enter at least ${MINIMUM_PASSWORD_LENGTH} characters`
      )
      .required('Please enter password'),
    passwordConfirmation: yup
      .string()
      .test('password-match', "Passwords don't match", function(value) {
        // @ts-ignore-next-line
        if (isBlank(this.parent.password)) {
          return true;
        }
        // @ts-ignore-next-line
        return this.parent.password === value;
      }),
  });

const NewPasswordForm = (props): ReactElement => {
  const { dispatch, onBack, redirectPath, userState } = props;
  const { email, registrationToken, userId } = userState;
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const { password } = data;
    const userAPI = api('users');

    try {
      await userAPI.createPassword({
        id: userId,
        password,
        token: registrationToken,
      });

      dispatch(userLogin(email, password, redirectPath));
    } catch (error) {
      console.error(error);
      // TODO: Do something with the error
    }
  };

  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      {isMobileScreen && (
        <MobileWrapper className="columns is-mobile is-centered">
          <SectionHeader className="mt-6">
            One last step before starting the application
          </SectionHeader>
        </MobileWrapper>
      )}
      {!isMobileScreen && (
        <SectionHeader className="mb-6">
          Create a password to protect your private info
        </SectionHeader>
      )}
      <div className="content mb-4">
        <div>Please make sure the password contains</div>
        <ul>
          <li>At least 8 characters</li>
          <li>Both upper case and lower case characters</li>
          <li>At least one number</li>
          <li>At least one special character</li>
        </ul>
      </div>
      <FormGroupWrapper>
        <FormFieldWrapper>
          <RHFBorderedTextField
            control={control}
            customProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="icon is-small is-left ">
                    <i className="fas fa-key"></i>
                  </span>
                </InputAdornment>
              ),
            }}
            inputProps={{ type: 'password' }}
            name="password"
            placeholder="Create password"
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <RHFBorderedTextField
            control={control}
            customProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span className="icon is-small is-left ">
                    <i className="fas fa-key"></i>
                  </span>
                </InputAdornment>
              ),
            }}
            inputProps={{ type: 'password' }}
            name="passwordConfirmation"
            placeholder="Confirm password"
          />
        </FormFieldWrapper>
      </FormGroupWrapper>
      <ButtonGroupWrapper>
        <FormFieldWrapper>
          <Button text="Next" type="submit" />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Button text="Back" handleClick={onBack} white />
        </FormFieldWrapper>
      </ButtonGroupWrapper>
    </FormWrapper>
  );
};

export default connect(() => {
  return {};
})(NewPasswordForm);
