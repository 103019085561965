import { Alert, AlertTitle } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { Link } from 'react-router';
import { pluralizeNoun } from 'utils';

type ErrorPropsTypw = {
  csvUrl: string;
  hasSuccessEntries?: boolean;
};

export const Error = (props: ErrorPropsTypw) => {
  const { csvUrl, hasSuccessEntries } = props;

  const [lineCount, setLineCount] = useState<number | null>(null);

  const readCsv = (url: string) => {
    fetch(url)
      .then((res) => res.text())
      .then((text) => {
        const linesArray = text.split('\n');
        setLineCount(linesArray.length - 2); // Subtract two account for header and new line at end of file
      });
  };

  useEffect(() => {
    if (csvUrl) {
      readCsv(csvUrl);
    }
  }, [csvUrl]);

  if (!lineCount) {
    return null;
  }

  return (
    <Alert severity="warning" icon={<WarningIcon />}>
      <AlertTitle>Exception report</AlertTitle>
      <p>
        {`Data validation was unsuccessful${
          lineCount !== null &&
          ` on ${lineCount} ${pluralizeNoun('entry', lineCount, 'entries')}`
        }. We created an exception
        report for you to review. Click `}
        <Link to={csvUrl} target="_blank">
          here
        </Link>{' '}
        to download and revise. Once corrected, please upload in Step 2.
        {hasSuccessEntries &&
          ` The rest of the data has been successfully uploaded.`}
      </p>
    </Alert>
  );
};
