/* Import libraries */
import { setPaperlessAnswer } from 'modules/consumer-onboarding/actions/paperless';
import Button from 'modules/shared/components/inputs/Button';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isValidEmail, isValidName } from 'utils/validators';
import styles from './css/Paperless.css';
import NewZealandPaperlessDetails from 'modules/consumer-onboarding/components/paperless/NewZealandPaperlessDetails';
import AustraliaPaperlessDetails from 'modules/consumer-onboarding/components/paperless/AustraliaPaperlessDetails';
import validateAccountNumber from 'utils/direct-debit/validateAccountNumber';
import validateBankNumber from 'utils/direct-debit/validateBankNumber';
import bankNumberLabel from 'utils/direct-debit/bankNumberLabel';
import { getWatchtowerBlockedMessage } from 'utils';
import { FEATURE_FLAGS } from 'conf';
import { Typography } from '@material-ui/core';
import get from 'lodash.get';

interface IProps {
  dispatch: (any) => void;
  handleComplete: (boolean, string?) => void;
  page_validation_start: boolean;
  region: string;
  setPageValidationStartFinish: () => void;
  answers: any;
  reviewMode: boolean;
  terms?: string;
  optional?: boolean;
  blockedEmails: string[];
}

interface IError {
  jointSignatories?: Record<string, string>[];
  signature?: string;
}

const PaperlessDetails = (props: IProps): JSX.Element => {
  const {
    handleComplete,
    page_validation_start: pageValidationStart,
    region,
    setPageValidationStartFinish,
    answers,
    blockedEmails,
  } = props;

  const [error, setError] = useState<IError>({});
  const [triggerValidation, setTriggerValidation] = useState(false);

  // when the page is loaded, need to check if the page is complete or not
  useEffect(() => {
    const validationResult = validate(answers, region);
    if (handleComplete) {
      handleComplete(validationResult.isValid, 'paperless');
    }
  }, [answers]);

  useEffect(() => {
    if (pageValidationStart || triggerValidation) {
      const validationResult = validate(props.answers, region);
      if (handleComplete) handleComplete(validationResult.isValid, 'paperless');
      if (setPageValidationStartFinish) setPageValidationStartFinish();

      validationResult && setError(validationResult.error);
      setTriggerValidation(false);
    }
  }, [
    handleComplete,
    pageValidationStart,
    region,
    setPageValidationStartFinish,
  ]);

  useEffect(() => {
    if (
      blockedEmails.length > 0 &&
      answers.multiSignature &&
      !error.jointSignatories &&
      FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER
    ) {
      const jointSignatoriesError: any = [];

      answers.jointSignatories.forEach((signatory, index) => {
        const signatoryEmail = signatory.email;
        const isBlocked = blockedEmails.includes(signatoryEmail);

        jointSignatoriesError.push(
          isBlocked ? { email: getWatchtowerBlockedMessage('email') } : {}
        );
      });
      setError((currentError) => ({
        ...currentError,
        jointSignatories: jointSignatoriesError,
      }));
    }
  }, [blockedEmails]);

  const noNeedForValidate = () => {
    const { answers, optional } = props;
    return (
      optional &&
      !answers.account &&
      !answers.accountNumber &&
      !answers.applicantAuthorised &&
      !answers.multiSignature
    );
  };

  const validate = (values, region) => {
    const validationInfo = { error: {}, isValid: true };
    if (noNeedForValidate()) {
      return validationInfo;
    }
    const fieldList = [
      'account',
      'accountNumber',
      'bankNumber',
      'applicantAuthorised',
      'multiSignature',
    ];
    if (values.multiSignature) fieldList.push('jointSignatories');
    fieldList.forEach((componentName) => {
      const componentValidationResult = componentValidator({
        componentName,
        region,
        values,
      });
      validationInfo.isValid =
        validationInfo.isValid && componentValidationResult.isValid;
      if (!componentValidationResult.isValid) {
        validationInfo.error = {
          ...validationInfo.error,
          ...componentValidationResult.error,
        };
      }
    });
    return validationInfo;
  };

  const componentValidator = ({ componentName, region, values }) => {
    const value = values[componentName];
    let isValid = true;
    let error: Record<string, any> | undefined;
    switch (componentName) {
      case 'account':
        isValid = !!value;
        error = errorMessage({ componentName, region, value });
        break;
      case 'middle_name':
        isValid = !value || isValidName(value);
        error = errorMessage({ componentName, region, value });
        break;
      case 'first_name':
      case 'last_name':
        isValid = value && isValidName(value);
        error = errorMessage({ componentName, region, value });
        break;
      case 'accountNumber':
        isValid = validateAccountNumber(region, value);
        error = errorMessage({ componentName, region, value });
        break;
      case 'bankNumber':
        isValid = validateBankNumber(region, value);
        error = errorMessage({ componentName, region, value });
        break;
      case 'email':
        isValid = isValidEmail(value);
        error = errorMessage({ componentName, region, value });
        break;
      case 'jointSignatories': {
        const signatoriesValidationResult = jointSignatoriesValidator(
          value,
          region
        );
        isValid = signatoriesValidationResult.isValid;
        error = { jointSignatories: signatoriesValidationResult.error };
        break;
      }
      case 'applicantAuthorised':
        isValid = (value && !values.multiSignature) || values.multiSignature;
        error = errorMessage({ componentName, region, value });
        break;
      case 'multiSignature':
        isValid = (values.applicantAuthorised && !value) || value;
        error = errorMessage({ componentName, region, value });
    }
    return { error, isValid };
  };

  const errorMessage = ({
    componentName,
    region,
    value,
  }): Record<string, string> | undefined => {
    switch (componentName) {
      case 'account':
        return {
          [componentName]: `Please input ${componentName.replace('_', ' ')}`,
        };
      case 'first_name':
      case 'last_name':
        return {
          [componentName]: `Please input ${
            value ? 'a valid ' : ''
          }${componentName.replace('_', ' ')}`,
        };
      case 'middle_name':
        return {
          [componentName]: 'Please input a valid middle name',
        };
      case 'accountNumber':
        if (value) {
          return { accountNumber: 'Please input a valid account number' };
        }
        return { accountNumber: 'Please input account number' };
      case 'bankNumber':
        return {
          bankNumber: `Please enter a valid ${
            bankNumberLabel[region.toUpperCase()]
          }`,
        };
      case 'email':
        if (value) return { email: 'Please input a valid email' };
        return { email: 'Please input email' };
      case 'applicantAuthorised':
      case 'multiSignature':
        return {
          signature: 'Does this account require other signatories?',
        };
      default:
        return {};
    }
  };

  const jointSignatoriesValidator = (signatories = [], region) => {
    const validationInfo: {
      error: Record<string, string>[];
      isValid: boolean;
    } = { error: [], isValid: true };

    signatories.forEach((signatory) => {
      let signatoryError = {};
      Object.keys(signatory).forEach((component) => {
        const componentValidationResult = componentValidator({
          componentName: component,
          region,
          values: signatory,
        });
        validationInfo.isValid =
          validationInfo.isValid && componentValidationResult.isValid;
        if (!componentValidationResult.isValid) {
          signatoryError = {
            ...componentValidationResult.error,
            ...signatoryError,
          };
        }
      });
      validationInfo.error.push(signatoryError);
    });
    return validationInfo;
  };

  const updateAnswer = (answer) => {
    const { dispatch } = props;
    dispatch(setPaperlessAnswer(answer));
  };

  const setSignatoryValue = (inputValue, inputName, index) => {
    const { answers, region } = props;
    const jointSignatories = answers.jointSignatories;
    if (inputName === 'email') {
      inputValue = inputValue.toLowerCase();
    }
    jointSignatories[index][inputName] = inputValue;
    updateAnswer({ jointSignatories });
    const validationResult = validate(answers, region);
    setError(validationResult.error);
  };

  const addSignatory = () => {
    const { answers } = props;
    const jointSignatories = answers.jointSignatories;
    jointSignatories.push({ email: null, first_name: null, last_name: null });
    updateAnswer({ jointSignatories });
  };

  const deleteSignatory = (index) => {
    const { answers } = props;
    const jointSignatories = answers.jointSignatories;
    jointSignatories.splice(index, 1);
    if (jointSignatories.length <= 0) {
      jointSignatories.push({ email: null, first_name: null, last_name: null });
    }
    updateAnswer({ jointSignatories });
  };

  const renderInputs = () => {
    const { answers, region } = props;

    let detailsByRegion;

    switch (region) {
      case 'nz':
        detailsByRegion = (
          <NewZealandPaperlessDetails
            answers={answers}
            error={error}
            onSetState={(newState) =>
              setTriggerValidation(newState.triggerValidation)
            }
            onSetValue={(inputValue, inputName) =>
              updateAnswer({ [inputName]: inputValue })
            }
          />
        );
        break;
      case 'au':
        detailsByRegion = (
          <AustraliaPaperlessDetails
            answers={answers}
            error={error}
            onSetState={(newState) =>
              setTriggerValidation(newState.triggerValidation)
            }
            onSetValue={(inputValue, inputName) =>
              updateAnswer({ [inputName]: inputValue })
            }
          />
        );
        break;
    }

    return detailsByRegion;
  };

  const renderCheckBoxes = () => {
    const { answers } = props;
    const components: JSX.Element[] = [];
    ['applicantAuthorised', 'multiSignature'].forEach((componentName) => {
      components.push(
        <div className={styles.full_col} key={componentName}>
          <div
            onClick={() => {
              updateAnswer({
                [componentName]: !answers[componentName],
              });
            }}
          >
            <SquareCheckbox
              id={componentName}
              label={inputs[componentName].label}
              checked={answers[componentName]}
            />
          </div>
        </div>
      );
    });
    return components;
  };

  const renderSignatureError = () => {
    if (!error.signature) return;
    return (
      <div className={styles.full_col}>
        <span className={styles.error_text}>{error.signature}</span>
      </div>
    );
  };

  const renderJointSignatories = () => {
    const { answers } = props;
    const jointSignatories = answers.jointSignatories;
    const jointSignatoriesErrors = error.jointSignatories;
    if (!answers.multiSignature) {
      return;
    }
    const components: JSX.Element[] = [];
    const numberOfComponents = jointSignatories.length;
    jointSignatories.forEach((signatory, index) => {
      components.push(
        <div key={`signatory-${index + 1}`}>
          <div className={`${styles.full_col} ${styles.signatory_title}`}>
            <span>Signatory {index + 1}</span>
          </div>
          <div className={styles.full_col}>
            {['first_name', 'middle_name', 'last_name', 'email'].map(
              (field) => (
                <div key={`${field}-${index}`} className={styles.quarter_col}>
                  <TextInput
                    key={`${field}-${index}`}
                    label={inputs.jointSignatories[field].label}
                    required={inputs.jointSignatories[field].require}
                    onChange={(event) => {
                      setSignatoryValue(event.target.value, field, index);
                    }}
                    value={signatory[field]}
                    error={
                      jointSignatoriesErrors &&
                      jointSignatoriesErrors[index] &&
                      jointSignatoriesErrors[index][field]
                    }
                  />
                </div>
              )
            )}
            {numberOfComponents > 1 && (
              <Button
                css_style={'button_small_font'}
                style={{ margin: '10px', zIndex: 90 }}
                text={'Delete'}
                onClick={() => deleteSignatory(index)}
              />
            )}
            {index + 1 === numberOfComponents && (
              <Button
                css_style={'button_small_font'}
                style={{ margin: '10px', zIndex: 90 }}
                text={'Add'}
                onClick={() => addSignatory()}
              />
            )}
          </div>
        </div>
      );
    });
    return components;
  };

  const renderTerms = () => {
    const { terms, reviewMode } = props;
    if (reviewMode) return;
    return (
      <div className={styles.full_col}>
        <span className={styles.terms}>{terms}</span>
      </div>
    );
  };

  const { optional } = props;

  return (
    <section>
      <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.full_col}>
            <PanelTitle
              text={optional ? `${defaults.title} (optional)` : defaults.title}
            />
          </div>
        </div>
      </div>
      <div className={styles.section} style={{ paddingBottom: '20px' }}>
        <div className={styles.row}>{renderInputs()}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.row}>{renderTerms()}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.row}>
          {FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS && (
            <Typography variant="h5" gutterBottom>
              General Acknowledgement and Agreement
            </Typography>
          )}
          {renderCheckBoxes()}
          {renderSignatureError()}
          <div className={styles.full_col} style={{ margin: '10px' }}></div>
          {renderJointSignatories()}
        </div>
      </div>
    </section>
  );
};

const defaults = {
  moduleName: 'paperless',
  title: 'Direct debit',
};

const inputs = {
  account: {
    label: 'Account name to be debited',
    require: true,
  },
  accountNumber: {
    label: 'Bank account number',
    require: true,
  },
  applicantAuthorised: {
    label: 'I am authorised to sign this direct debit',
  },
  jointSignatories: {
    email: {
      label: 'Email',
      require: true,
    },
    first_name: {
      label: 'First name',
      require: true,
    },
    last_name: {
      label: 'Last name',
      require: true,
    },
    middle_name: {
      label: 'Middle name',
      require: false,
    },
  },
  multiSignature: {
    label: 'Does this account require more than one signature?',
  },
};

export default connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;

  let config;
  let region;

  if (previewAddon) {
    config = previewAddon.config;
    region = ownProps.region;
  } else {
    const addon = state.cob_section.addonRules.find(
      (rule) => rule.attributes.addon_module_name === defaults.moduleName
    );
    config = addon.attributes.addon_version.data.attributes.config;
    region = state.cob_business.entity_region;
  }

  const isApplicationPaperlessMandatoryString = get(
    state,
    'cob_section.application.attributes.direct_debit_is_mandatory_string'
  );

  const isApplicationPaperlessMandatoryBoolean = get(
    state,
    'cob_section.application.attributes.direct_debit_is_mandatory'
  );

  const isApplicationPaperlessMandatory =
    isApplicationPaperlessMandatoryString === 'false'
      ? false
      : isApplicationPaperlessMandatoryBoolean;

  const isPaperlessMandatory = FEATURE_FLAGS.FEATURE_FLAG_DIRECT_DEBIT_TOGGLE
    ? typeof isApplicationPaperlessMandatory === 'boolean'
      ? isApplicationPaperlessMandatory
      : config.mandatory
    : config.mandatory;

  return {
    answers: state.cob_paperless.answers,
    optional: !isPaperlessMandatory,
    terms: config.terms,
    region: region.toLowerCase(),
    blockedEmails: state.cob_paperless.blockedEmails,
  };
})(PaperlessDetails);
