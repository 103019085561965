import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import get from 'lodash.get';
import { onCloseSidebar } from 'modules/shared/components/top/Overlay/redux/actions';
import React from 'react';
import { connect } from 'react-redux';

import { ChecklistPanel } from './ChecklistPanel';
import { ProgressPanel } from './ProgressPanel';
import QuestionsPanel from './QandAPanel';
import { ButtonDrawer, CloseDrawerIcon, ScrollableBox } from './styles';
import { TermsPanel } from './TermsPanel';

const SectionDrawer = (props) => {
  const {
    activeSidebar,
    applicationChecklist,
    applicationId,
    currentSection,
    dispatch,
    entityType,
    progressSections,
    supplierTerms,
    termsList,
  } = props;

  React.useEffect(() => {
    const documentBody = document.getElementsByTagName('body')[0];
    if (documentBody) documentBody.classList.add('bc-flow');
  }, []);

  const handleDrawerClose = () => dispatch(onCloseSidebar());
  const isOpen = activeSidebar !== '';

  return (
    <ButtonDrawer open={isOpen} variant="temporary" anchor="right">
      <ScrollableBox>
        <CloseDrawerIcon>
          <FontAwesomeIcon
            color="#666666"
            cursor="pointer"
            icon={['fas', 'times-circle']}
            onClick={handleDrawerClose}
            size="lg"
          />
        </CloseDrawerIcon>
        {activeSidebar === 'checklist' && applicationChecklist && (
          <ChecklistPanel
            applicationChecklist={applicationChecklist}
            applicationId={applicationId}
          />
        )}
        {activeSidebar === 'progress' && (
          <ProgressPanel
            currentSection={currentSection}
            entityType={entityType}
            progressSections={progressSections}
          />
        )}
        {activeSidebar === 't-and-c' && (
          <TermsPanel supplierTerms={supplierTerms} termsList={termsList} />
        )}
        {activeSidebar === 'q-and-a' && <QuestionsPanel />}
      </ScrollableBox>
    </ButtonDrawer>
  );
};

export default connect((state) => {
  return {
    activeSidebar: get(state, 'applicationOverlayReducer.activeSidebar', ''),
  };
})(SectionDrawer);
