export function deepCopyArray(arr) {
  let out = [];
  for (let i = 0, len = arr.length; i < len; i++) {
    let item = arr[i];
    let obj = {};
    for (let k in item) {
      obj[k] = item[k];
    }
    out.push(obj);
  }
  return out;
}
