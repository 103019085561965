import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  color: black;

  &:hover {
    background-color: var(--main-color);
    color: white;
  }
`;

type VersionItemProps = {
  createdAt: string;
  isCurrent: boolean;
  versionNumber: number;
  src: string;
};

export const VersionItem = (props: VersionItemProps) => {
  const { createdAt, isCurrent, versionNumber, src } = props;

  return (
    <Container href={src} rel="noopener" target="_blank">
      <Typography>
        Version: {versionNumber} {isCurrent && '(current version)'}
      </Typography>
      <Typography>Created At: {createdAt}</Typography>
    </Container>
  );
};
