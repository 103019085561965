export type IndicatorStatus = 'incomplete' | 'paused' | 'complete';

export enum TabIds {
  'general' = 'general',
  'validation' = 'validation',
  'credit' = 'credit',
  'additions' = 'additions',
  'financials' = 'financials',
}

export type TrailLogItem = {
  history_type: string;
  status_changed: string;
  updated_by_content: string;
};

export type IHistoryData = { history_data: Array<IRuleSetAttributes> };

export interface IGeneralDetails {
  name: string;
  regions: string[];
  legal_types: string[];
  application_types: string[];
  max_credit_value: number;
  min_credit_value: number;
  time_in_business?: number;
  general_details_status: IndicatorStatus;
}

export interface IValidationDetails {
  name: string;
  guarantors: boolean;
  guarantors_count: any;
  signatories: boolean;
  signatories_count: number;
  aml_verification: boolean;
  require_anti_fraud: boolean;
  validation_status: IndicatorStatus;
}

export interface ICreditChecks {
  name: string;
  credit_checks_required?: boolean;
  au_business_credit_indicator?: number;
  au_personal_credit_indicator?: number;
  nz_business_credit_indicator?: number;
  nz_personal_credit_indicator?: number;
  credit_checks_status: IndicatorStatus;
  judgements?: boolean;
  defaults?: boolean;
}

interface FinancialIndicators {
  fixed_min?: number | null;
  fixed_max?: number | null;
  dynamic_min?: number | null;
  dynamic_max?: number | null;
}

export interface IFinancials {
  name: string;
  financials_check: boolean;
  financials_status: IndicatorStatus;
  net_worth_indicators: FinancialIndicators;
  surplus_indicators: FinancialIndicators;
  property_ownership_indicator: { fixed_min?: number | null };
  net_property_value_indicator: { fixed_min?: number | null };
}

export interface IRulesetBasic {
  name: string;
  updated_at?: string;
  editor_first_name?: string;
  current_version?: number;
}

export interface IAdditions {
  trade_reference?: boolean;
  trade_reference_count: any;
  paperless_check?: boolean;
  additions_status: IndicatorStatus;
}

export interface IRuleSetAttributes
  extends IGeneralDetails,
    IValidationDetails,
    ICreditChecks,
    IAdditions,
    IFinancials,
    IRulesetBasic,
    IHistoryData {
  active: boolean;
  trail_logs: TrailLogItem[];
}

export interface IHistory {
  name?: string;
  updatedTime?: string;
}

export interface IAutoDecision {
  id?: string;
  attributes: IRuleSetAttributes;
  is_new: boolean;
}

export interface IConflict {
  value: string[];
  conflict: string[];
}
export interface IRulesetConflict {
  rulesetName: string;
  regions: IConflict;
  entityType: IConflict;
  applicationType: IConflict;
  limitRange: IConflict;
}

export interface ICreditScore {
  id: string;
  name: string;
  region: 'NZ' | 'AU';
  description: string;
  minScore: number;
  maxScore: number;
  type: 'business' | 'personal' | 'individual';
  slug: string;
}

export interface ISelectedCreditCheck {
  au_personal_rule_selection_id: string | null;
  au_rule_selection_id: string | null;
  nz_personal_rule_selection_id: string | null;
  nz_rule_selection_id: string | null;
}

export interface ICreditScoreInput {
  minScore: number;
  maxScore: number;
  description: string;
}
