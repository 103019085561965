import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import React, { ReactElement } from 'react';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';

type Address = {
  address: string;
  startDate: string;
  endDate: string;
};

type Props = {
  data: {
    physicalAddress: Address;
    registeredAddress: Address;
  };
};

function Addresses(props: Props): ReactElement | null {
  const { data } = props;

  if (!data) {
    return null;
  }

  const { physicalAddress, registeredAddress } = data;

  return (
    <div className={historyStyles.container}>
      <SubHeading>Addresses</SubHeading>
      <table className={styles.table_w20}>
        <tbody>
          <tr>
            <th>Physical Address</th>
            <td>{physicalAddress.address}</td>
          </tr>
          <tr>
            <th>Registered Address</th>
            <td>{registeredAddress.address}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Addresses;
