export const SAMPLE_PAYLOAD = `{
  "data": {
    "attributes": {
      "active": true,
      "alert_type": "Monitoring"
    },
    "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
  },
  "version":"1.0.0"
}`;
