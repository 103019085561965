import get from 'lodash.get';
import getApplicableAntiFraudRules from 'utils/anti-fraud/getApplicableAntiFraudRules';

const getAuthorisationRoles = (authorisation) => {
  const roles: string[] = [];

  if (get(authorisation, 'agreements.applicant')) {
    roles.push('applicant');
  }

  if (get(authorisation, 'agreements.guarantor')) {
    roles.push('guarantor');
  }

  if (get(authorisation, 'agreements.signatory')) {
    roles.push('signatory');
  }

  if (get(authorisation, 'agreements.cardholder')) {
    roles.push('cardholder');
  }

  if (get(authorisation, 'agreements.payment')) {
    roles.push('direct_debit');
  }

  return roles;
};

const getApplicableAntiFraudRulesForCreditFile = ({
  application,
  authorisation,
}) => {
  // Get requestedLimit from application
  // @ts-ignore-next-line: ApplicationModel is a JS file
  const requestedLimit = application.tradeAccountLimit;

  const authorisationRoles = getAuthorisationRoles(authorisation);

  return getApplicableAntiFraudRules({
    antiFraud: application.antiFraudAddonVersion,
    application,
    authorisationRoles,
    requestedLimit,
  });
};

export default getApplicableAntiFraudRulesForCreditFile;
