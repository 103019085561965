import PaperlessDetails from 'modules/addons/paperless/components/PaperlessDetails';
import Button from 'modules/shared/components/inputs/Button';
import VideoModal from 'modules/shared/components/widgets/interactive/VideoModal';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadAddons, setCurrentAddonRuleset } from '../actions';
import { getRulesetConflicts, sortedAddonList } from './../helpers';
import AddonSkeleton from './AddonSkeleton';
import AddOnsListItem from './AddOnsListItem';
import commonStyles from './css/CommonAddOns.css';

class PaperlessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailPage: false,
      showNeedEnablePopup: false,
      viewing: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadAddons(moduleName));
  }

  startCreate(serialNumber, version) {
    const { dispatch } = this.props;
    if (version) {
      dispatch(setCurrentAddonRuleset(serialNumber, version, moduleName));
    }
    this.setState({ showDetailPage: true });
  }

  handleDiscard() {
    this.setState({
      showDetailPage: false,
      viewing: false,
    });
  }

  startViewing(serialNumber, version) {
    const { dispatch } = this.props;
    if (version) {
      dispatch(setCurrentAddonRuleset(serialNumber, version, moduleName));
    }
    this.setState({
      showDetailPage: true,
      viewing: true,
    });
  }

  showPopup() {
    this.setState({ showNeedEnablePopup: true });
  }

  hidePopup() {
    this.setState({ showNeedEnablePopup: false });
  }

  renderList() {
    const { configs, activeUpdatingId, editable } = this.props;
    const items = [];

    configs.forEach((config, index) => {
      items.push(
        <AddOnsListItem
          key={`${config.attributes.addon_module_name}_${index}`}
          addonVersions={config.attributes.history_version.data}
          rulesetConflicts={getRulesetConflicts(
            configs,
            config.attributes.history_version.data[0]
          )}
          currentAddonData={config}
          onEdit={(serialNumber, currentConfig) => {
            this.startCreate(serialNumber, currentConfig);
          }}
          viewHistory={(serialNumber, version) => {
            this.startViewing(serialNumber, version);
          }}
          updating={config.id === activeUpdatingId}
          disabled={!isFeatureEditEnabled('DirectDebit')}
          addonType={moduleName}
        />
      );
    });

    return (
      <div>
        <div className={commonStyles.header_container}>
          <span>
            <span className={commonStyles.header}>Set your rules here</span>
            <VideoModal videoName={'paperless'} />
          </span>
          {isFeatureEditEnabled('DirectDebit') && (
            <Button
              style={{ fontWeight: '700' }}
              disabled={false}
              text="+ Add"
              handleClick={() => {
                editable ? this.startCreate() : this.showPopup();
              }}
            />
          )}
        </div>
        <div className={commonStyles.container}>
          <ul className={commonStyles.list}>{items}</ul>
        </div>
      </div>
    );
  }

  renderDetails() {
    const { editable } = this.props;
    return (
      <PaperlessDetails
        handleDiscard={() => {
          this.handleDiscard();
        }}
        readOnly={
          this.state.viewing ||
          !isFeatureEditEnabled('DirectDebit') ||
          !editable
        }
      />
    );
  }

  renderNeedEnablePopup() {
    return (
      <SquareModal title={'Uneditable'} size={'small'}>
        <p>
          Paperless direct debits is not enabled for this account. Please
          contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
          if you would like to enable.
        </p>
        <div>
          <Button text={'Ok'} handleClick={this.hidePopup.bind(this)} />
        </div>
      </SquareModal>
    );
  }

  render() {
    const { loading } = this.props;
    const { showDetailPage, showNeedEnablePopup } = this.state;
    const contents = showDetailPage ? this.renderDetails() : this.renderList();
    const popup = showNeedEnablePopup ? this.renderNeedEnablePopup() : null;

    if (loading) {
      return <AddonSkeleton />;
    }

    return (
      <div className={commonStyles.row}>
        <div className={commonStyles.block_wide}>
          {contents}
          {popup}
        </div>
      </div>
    );
  }
}

const moduleName = 'paperless';

export default connect((state) => {
  const configs = sortedAddonList(state, moduleName);

  return {
    activeUpdatingId: state.add_ons.paperless_active_updating_id,
    configs,
    editable: state.current_user.current_entity.attributes.paperless_enabled,
    loading: state.add_ons.paperless_loading,
  };
})(PaperlessList);
