import React, { ReactElement } from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';
import { taggedApproverEscalationData } from 'modules/new-applications/utils/escalationHelpers';
import { FEATURE_FLAGS } from 'conf';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import styled from 'styled-components';

const showImprovedNotes = FEATURE_FLAGS.FEATURE_FLAG_IMPROVED_NOTES;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media screen and (min-width: 991px) {
    width: 400px;
  }
`;

const getReviewNotes = (review, reviewEscalations) => {
  if (review.decision === 'escalated') {
    const escalationData = taggedApproverEscalationData({
      review,
      reviewEscalations: reviewEscalations,
    });

    if (escalationData) {
      const { notes } = escalationData.attributes || {};
      return notes || 'No notes.';
    }
  }
  return review.notes || 'No notes.';
};

const getReviewMessage = (review, reviewEscalations) => {
  if (review.decision === 'escalated') {
    const escalationData = taggedApproverEscalationData({
      review,
      reviewEscalations: reviewEscalations,
    });

    if (escalationData) {
      const { escalated_to_level, escalated_for_user_name } =
        escalationData.attributes;

      return `Escalated approval to ${escalated_for_user_name} (L${escalated_to_level}) on ${review.formattedReviewedAt}.`;
    }
    return `Escalated approval from L${review.level} to L${
      review.level + 1
    } on ${review.formattedReviewedAt}.`;
  }

  return `${review.formattedDecision} ${showImprovedNotes ? 'on' : 'at'} ${
    review.formattedReviewedAt
  } (approval hierarchy version ${review.approvalHierarchyVersion})`;
};

const getApprovedMessage = (application, review) => {
  let actionMessage = 'Recommended';

  if (application.finalReviewId === review.id) {
    actionMessage = 'Approved';
  }

  return `${actionMessage} ${review.formattedTradeAccountLimit} with ${review.formattedDiscount} discount`;
};

const ApproverContent = ({
  application,
  review,
}: {
  application: { [key: string]: any };
  review: { [key: string]: any };
}): ReactElement =>
  showImprovedNotes ? (
    <FixedContent withBottomSeparator={false} withBottomMargin={false}>
      <ContentContainer>
        <p className={styles.approver_name}>
          {`Approver name: ${review.reviewerName}`}
        </p>
        {review.decision === 'accepted' && (
          <p>{getApprovedMessage(application, review)}</p>
        )}
        <p>{getReviewMessage(review, application.reviewEscalations)}</p>

        <BorderedTextArea
          disabled
          darkerDisabledText
          label={
            review.decision === 'escalated'
              ? 'Escalate notes'
              : 'Approver notes'
          }
          value={getReviewNotes(review, application.reviewEscalations)}
        />
      </ContentContainer>
    </FixedContent>
  ) : (
    <FixedContent withBottomSeparator={false} withBottomMargin={false}>
      <p className={styles.approver_name}>
        {`Approver name: ${review.reviewerName}`}
      </p>
      {review.decision === 'accepted' && (
        <p>{getApprovedMessage(application, review)}</p>
      )}
      <p>{getReviewMessage(review, application.reviewEscalations)}</p>
      <p>{getReviewNotes(review, application.reviewEscalations)}</p>
    </FixedContent>
  );

export default ApproverContent;
