import {
  SectionDescription,
  SectionHeader,
  SubSection,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import React, { Fragment, ReactElement } from 'react';

const Introduction = (): ReactElement => (
  <Fragment>
    <SectionHeader>Introduction</SectionHeader>
    <SubSection>
      <SectionDescription>
        1Centre can push information about an application into your system using
        webhooks.
      </SectionDescription>
      <SectionDescription>
        The webhook sends a JSON payload via POST request into a nominated
        endpoint from your system.
      </SectionDescription>
    </SubSection>
  </Fragment>
);

export default Introduction;
