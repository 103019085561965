import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { ReactElement, ReactNode } from 'react';

const Header = ({
  title,
  underlined,
}: {
  title: string | ReactNode;
  underlined?: boolean;
}): ReactElement => (
  <div
    className={`${styles.e_signature_header} ${underlined && 'is-underlined'}`}
  >
    {title}
  </div>
);

export default Header;
