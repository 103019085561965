import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import moment from 'moment';
import React, { ReactElement } from 'react';

type PreviousEnquiry = {
  enquirer: string;
  enquiryDate: string;
};

type Props = {
  data: PreviousEnquiry[];
};

function PreviousEnquiries(props: Props): ReactElement {
  const { data } = props;

  const getEnquiries = () =>
    data.reverse().map((enquiry, index) => (
      <tr key={index}>
        <th>{moment(enquiry.enquiryDate).format('DD-MMM-YYYY')}</th>
        <td>{enquiry.enquirer}</td>
      </tr>
    ));

  return (
    <div className={historyStyles.container}>
      <div>
        <SubHeading>Previous Enquiries / Credit Activity</SubHeading>
        <em>
          This information was obtained from the New Zealand Companies Office.
        </em>
      </div>
      <table className={styles.table_w20}>
        <tbody>{getEnquiries()}</tbody>
      </table>
    </div>
  );
}

export default PreviousEnquiries;
