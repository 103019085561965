import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import get from 'lodash.get';

import styled from 'styled-components';

const ToggleLabelWrapper = styled.div`
  display: flex;
  align-items: middle;
  gap: 5px;
`;

const ToggleInputWrapper = styled.div`
  width: 450px;
  margin: 10px 0 30px 0;
`;

const dropdownOptions = [
  { label: 'Mandatory', value: true },
  { label: 'Optional', value: false },
];

const DirectDebitToggle = (props) => {
  const { currentUser, application, onFetchApplicationRecord } = props;
  const hasTogglePermission = get(
    currentUser,
    'currentUserEntityLink.pagePermissions.can_override_direct_debit',
    false
  );

  const applicationCompleted =
    application.paperlessStatus === 'complete' ||
    application.submissionStatus === 'complete';

  const toggleDirectDebit = (event) => {
    const value = event.target.value;

    application.update({
      attributes: { direct_debit_is_mandatory: value },
      currentUser,
      onSuccessCallback: () => {
        onFetchApplicationRecord();
      },
    });
  };

  return (
    <div>
      <ToggleLabelWrapper>
        <span>
          If you have permission, you are able to change this Direct Debit rule
          if required for this application.
        </span>
        <PopperTooltip title="The function can only be used before Direct Debit has been completed. If you require permission, contact your Manager.">
          <HelpIcon color="primary" fontSize="small" />
        </PopperTooltip>
      </ToggleLabelWrapper>
      <ToggleInputWrapper>
        <BorderedSelect
          id="isDirectDebitMandatory"
          value={application.paperlessMandatory}
          options={dropdownOptions}
          formControlCustomProps={{ withBottomMargin: false }}
          onChange={toggleDirectDebit}
          disabled={applicationCompleted || !hasTogglePermission}
        />
      </ToggleInputWrapper>
    </div>
  );
};

export default DirectDebitToggle;
