import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import {
  InnerContainer,
  InnerSubHeading,
  SubHeading,
} from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import React, { Fragment, ReactElement } from 'react';

type Shareholders = {
  address: string;
  companyNumber: string;
  name: string;
  nzbn: string;
};

type ShareholdersData = {
  shareholders: Shareholders[];
  numberOfShares: number;
};

type Props = {
  data: ShareholdersData[];
};

type DetailProp = {
  prefix?: string;
  children: any;
};

function Shareholders(props: Props): ReactElement | null {
  const { data } = props;

  if (data.length === 0) {
    return null;
  }

  const Detail = ({ prefix, children }: DetailProp): ReactElement | null =>
    children ? (
      <p>
        {prefix && `${prefix}: `}
        {children}
      </p>
    ) : null;

  const getDetails = (shareholders: Shareholders[]) =>
    shareholders.map(({ address, companyNumber, name, nzbn }, index) => (
      <div key={index} className={index > 0 ? 'mt-4' : ''}>
        <Detail>{name}</Detail>
        <Detail prefix="NZBN">{nzbn}</Detail>
        <Detail prefix="Company Number">{companyNumber}</Detail>
        <Detail>{address}</Detail>
      </div>
    ));

  const getShareholders = () =>
    data.map((shareholdersData: ShareholdersData, index) => {
      const { numberOfShares, shareholders } = shareholdersData;

      return (
        <InnerContainer key={index}>
          <InnerSubHeading>Share allocation</InnerSubHeading>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name &amp; Address</th>
                <td>{getDetails(shareholders)}</td>
              </tr>
              <tr>
                <th>Number of shares</th>
                <td>{numberOfShares}</td>
              </tr>
            </tbody>
          </table>
        </InnerContainer>
      );
    });

  return (
    <div className={historyStyles.container}>
      <SubHeading>Shareholders</SubHeading>
      {getShareholders()}
    </div>
  );
}

export default Shareholders;
