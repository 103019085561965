import step1 from 'images/webhooks/add-web-hook-step-1.png';
import step2 from 'images/webhooks/add-web-hook-step-2.png';
import editWebhook from 'images/webhooks/edit-webhook.png';
import webhookCreated from 'images/webhooks/webhook-created.png';
import {
  DefinitionList,
  Figure,
  OrderedList,
  SectionDescription,
  SectionHeader,
  SubSection,
  SubSectionHeader,
  UnorderedList,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import SyntaxHighlighter from 'modules/webhooks/v2/components/V2Documentation/SyntaxHighlighter';
import TagCodeBlock from 'modules/webhooks/v2/components/V2Documentation/TagCodeBlock';
import React, { Fragment, ReactElement } from 'react';

const Connecting = (): ReactElement => (
  <Fragment>
    <SectionHeader>Connecting</SectionHeader>

    <SubSection>
      <SubSectionHeader>Creating a webhook</SubSectionHeader>
      <OrderedList>
        <li>
          <div>
            To start using the webhooks, go to the{' '}
            <a
              href="https://my.1centre.com/dashboard/connected-apps/webhooks"
              target="_blank"
              rel="noopener noreferrer"
            >
              webhooks page
            </a>{' '}
            and click on the &quot;plus&quot; button
          </div>
          <Figure>
            <img src={step1} alt="step-1" />
          </Figure>
        </li>
        <li>
          <div>Supply the fields required</div>
          <Figure>
            <img src={step2} alt="step-2" />
          </Figure>
          <p>
            <strong>Field definition:</strong>
          </p>
          <DefinitionList>
            <dt>App URL</dt>
            <dd>
              The endpoint that will receive the POST request from 1Centre.
            </dd>
            <dt>App name</dt>
            <dd>A description or identifier of the webhook.</dd>
            <dt>Event type</dt>
            <dd>Refer to the Events section for more details.</dd>
            <dt>API version</dt>
            <dd>
              The webhook version to use. Version 2.0.0 is the most up to date
              version.
            </dd>
            <dt>Email</dt>
            <dd>
              Optional field. The inbox that receives notifications whenever a
              request has not been successful, i.e. when the receiving endpoint
              responds with 4xx or 5xx errors.
            </dd>
            <dt>Signing secret</dt>
            <dd>
              This field is automatically populated upon creating the web hook
            </dd>
            <dt>X-Hook-Username</dt>
            <dd>
              Optional field. A user name included in the headers if added
              security is a requirement.
            </dd>
            <dt>X-Hook-Password</dt>
            <dd>
              Optional field. A password (in conjunction with{' '}
              <TagCodeBlock>X-Hook-Username</TagCodeBlock>) included in the
              headers if added security is a requirement.
            </dd>
          </DefinitionList>
        </li>
        <li>
          Click save. Upon saving, the system will redirect the user back to the
          main webhooks page.
        </li>
      </OrderedList>
    </SubSection>

    <SubSection>
      <SubSectionHeader>Signing secret</SubSectionHeader>
      <SectionDescription>
        A signing secret is generated every time a new webhook is created. This
        is used to generate a signature that is included in the POST
        request&apos;s header. The signature from the header can then be used on
        the receiving side to validate the authenticity of the requests.
      </SectionDescription>
      <SectionDescription>
        Steps outlined below to view the signing secret:
      </SectionDescription>
      <OrderedList>
        <li>
          <div>
            In the{' '}
            <a href="my.1centre.com/dashboard/connected-apps/webhooks">
              webhooks page
            </a>{' '}
            , click &quot;edit&quot; on the webhook you want to view the signing
            secret
          </div>
          <Figure>
            <img src={webhookCreated} alt="webhook-created" />
          </Figure>
        </li>
        <li>
          <div>
            In the edit page, click &quot;click to review secret&quot; at the
            bottom of the form.
          </div>
          <Figure>
            <img src={editWebhook} alt="webhook-edit" />
          </Figure>
        </li>
      </OrderedList>
    </SubSection>

    <SubSection>
      <SubSectionHeader>Verifying requests</SubSectionHeader>
      <SectionDescription>
        To verify the authenticity of a request, you will need the following:
        <UnorderedList>
          <li>Signing secret described in the previous section</li>
          <li>
            The following from the request header:
            <dl>
              <dt>
                <TagCodeBlock>X-Hook-Signature-v2</TagCodeBlock>
              </dt>
              <dd>
                a unique signature to validate that is generated with a
                combination of several parameters.
              </dd>
              <dt>
                <TagCodeBlock>X-Hook-Timestamp</TagCodeBlock>
              </dt>
              <dd>
                the integer number of seconds since the Epoch and used as a
                parameter to generate the signature.
              </dd>
            </dl>
          </li>
          <li>The application_id from the JSON payload</li>
        </UnorderedList>
      </SectionDescription>

      <SectionDescription>
        The receiving end should then generate a signature that will match the
        value of <TagCodeBlock>X-Hook-Signature-V2</TagCodeBlock>. Generate a
        signature by using SHA1 encoded HMAC using the signing secret of the
        webhook + the <TagCodeBlock>application_id</TagCodeBlock> and the{' '}
        <TagCodeBlock>X-Hook-Timestamp</TagCodeBlock>. The format for the second
        argument will be{' '}
        <TagCodeBlock>application_id + | + X-HookTimestamp</TagCodeBlock> (there
        is a pipe <TagCodeBlock>|</TagCodeBlock> in between the{' '}
        <TagCodeBlock>application_id</TagCodeBlock> and
        <TagCodeBlock>X-Hook-Timestamp</TagCodeBlock>).
      </SectionDescription>

      <SectionDescription>Sample code below</SectionDescription>
      <SyntaxHighlighter language="ruby">
        {`
          # Sample code in Ruby
          request_signature = request.headers['X-Hook-Signature-V2']
          request_timestamp = requres.headers['X-Hook-Timestamp']
          application_id = JSON.parse(request.raw_post)['data']['id']
          secret = 'abc123' # This is the Signing secret from the 1Centre Webhook page

          generated_signature = OpenSSL::HMAC.hexdigest(
            OpenSSL::Digest.new('sha1'),
            secret,
            "#{application_id}|#{request_timestamp}"
          )

          if request_signature == generated_signature
            # Request authenticated. Do something with the data
          else
            # Request not authenticated and do not proceed
          end
        `}
      </SyntaxHighlighter>
    </SubSection>
  </Fragment>
);

export default Connecting;
