import axios from './axios';

let EntityNameSearch = function(config) {
  this.axios = axios(config);
};

EntityNameSearch.prototype.companySearch = function(string, success, error) {
  return this.axios
    .get('/entity_name_search', { params: { name: string } })
    .then(success)
    .catch(error);
};

export default EntityNameSearch;
