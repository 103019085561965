// Check the file extension of the file from S3
export default function isPDF(file: string | null | undefined): boolean {
  if (typeof file !== 'string') {
    return false;
  }

  return (
    file.startsWith('data:application/pdf') ||
    (file.split('.').pop() || '').startsWith('pdf')
  );
}
