import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#people-creating-a-person-for-an-entity
 */
let People = function(config) {
  this.axios = axios(config);
  this.type = 'people';
};

People.prototype.createPerson = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

People.prototype.createPersonForAnotherEntity = function(
  attributes,
  entity_id,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          entity: {
            data: {
              id: entity_id,
              type: 'entities',
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

People.prototype.updatePerson = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

People.prototype.deletePerson = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

export default People;
