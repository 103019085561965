/* Import libraries */
import React, { Component } from 'react';
import { formatLocalTime } from 'utils/dateFormatter';

class DateString extends Component {
  render() {
    const { value, format, showNull } = this.props;
    if (!value || value === 'null') {
      if (showNull) {
        return <span>-</span>;
      } else {
        return null;
      }
    }

    return <div>{formatLocalTime(value, format)}</div>;
  }
}

export default DateString;
