import userTypes from 'modules/shared/users/userTypes';

export const ROLE_TYPE_CONSUMER_MANAGER = 'consumer_manager';

export const UserUpdateDefaults = {
  title: 'Update User',
  user_type: {
    default_title: 'User type',
    options: userTypes.filter(({ type }) => type === 'user_type'),
  },
};

export const BULK_REASSIGN_ERROR_MESSAGE = `\
Oops! There was an error while trying to reassign,\
please refresh this page and try again or contact the\
support team if the problem still persist.
`;
