import formatMoney from 'utils/formatMoney';
import { CHECKBOX_REGION_OPTIONS } from './sales_channels/shared/constants';

export function findLimitBreakpoints(allConfigs, currentConfig) {
  const limitBreakpoints = {
    association: [],
    club: [],
    company: [],
    education: [],
    government: [],
    other: [],
    partnership: [],
    personal: [],
    society: [],
    sole_trader: [],
    trust: [],
  };

  allConfigs.forEach((config) => {
    if (!config.attributes.active) return;
    if (!isTheSameConfig({ configToCompare: config, currentConfig })) {
      const latestConfig = config.attributes.history_version.data[0].attributes;
      const { application_types } = latestConfig;

      if (
        application_types &&
        application_types.length === 1 &&
        application_types.includes('cash')
      ) {
        return;
      }

      latestConfig.legal_types.forEach((legalType) => {
        const currentBreakpoints = limitBreakpoints[legalType];
        // Make sure to only add to the array if the object doens't exist
        currentBreakpoints.push({
          addon_config_id: config.id,
          max: latestConfig.max_credit_value,
          min: latestConfig.min_credit_value,
          name: latestConfig.name,
        });
        currentBreakpoints.sort((obj) => obj.min - obj.min);
      });
    }
  });

  return limitBreakpoints;
}

export function isTheSameConfig({ configToCompare, currentConfig }) {
  let id;
  if (currentConfig.type === 'auto_decisioning_rulesets') {
    id = currentConfig.id;
  } else {
    id = currentConfig.attributes.addon_config_id;
  }

  return configToCompare.id === id;
}

export function findLimitConflicts(limitBreakpoints, currentConfig) {
  const {
    application_types,
    legal_types,
    max_credit_value,
    min_credit_value,
  } = currentConfig.attributes;

  const limitConflicts = [];
  const legalTypes = Array.isArray(legal_types[0])
    ? legal_types[0]
    : legal_types;

  legalTypes.forEach((legalType) => {
    limitBreakpoints[legalType].forEach((breakpoint) => {
      if (!limitConflicts.find((obj) => obj.id === breakpoint.id)) {
        const minCreditValue = parseInt(min_credit_value);
        const maxCreditValue = parseInt(max_credit_value);
        const breakpointMin = parseInt(breakpoint.min);
        const breakpointMax = parseInt(breakpoint.max);
        if (
          (minCreditValue <= breakpointMin &&
            maxCreditValue >= breakpointMin) ||
          (minCreditValue <= breakpointMax &&
            maxCreditValue >= breakpointMax) ||
          (minCreditValue >= breakpointMin && maxCreditValue <= breakpointMax)
        ) {
          limitConflicts.push(breakpoint);
        }
      }
    });
  });
  return limitConflicts.length ? limitConflicts : null;
}

export function findEntityTypeConflicts(
  allConfigs,
  currentConfig,
  returnConflictingRuleset = false
) {
  const otherActiveConfigs = allConfigs.filter(
    (config) =>
      config.attributes.active &&
      !isTheSameConfig({ configToCompare: config, currentConfig })
  );

  if (otherActiveConfigs.length === 0) {
    return;
  }

  const conflictedConfigs = [];

  otherActiveConfigs.forEach(({ attributes }) => {
    const {
      application_types,
      legal_types,
      name,
    } = attributes.history_version.data[0].attributes;

    const {
      application_types: currentApplicationTypes,
      legal_types: currentLegalTypes,
    } = currentConfig.attributes;

    let conflictedApplicationTypes = [];
    const conflictedLegalTypes = currentLegalTypes.filter(
      (currentLegalType) => {
        application_types.forEach((applicationType) => {
          const conflictApplicationType =
            currentApplicationTypes &&
            currentApplicationTypes.includes(applicationType);

          if (
            conflictApplicationType &&
            !conflictedApplicationTypes.includes(applicationType)
          ) {
            conflictedApplicationTypes.push(applicationType);
          }
        });

        return (
          conflictedApplicationTypes.length &&
          legal_types.includes(currentLegalType)
        );
      }
    );

    if (conflictedLegalTypes.length === 0) {
      return;
    }

    conflictedConfigs.push({
      applicationTypes: conflictedApplicationTypes,
      legalTypes: conflictedLegalTypes,
      name,
    });
  });

  if (conflictedConfigs.length === 0) {
    return;
  }

  const conflictedRulesetName = getConflictingRulesetNames(conflictedConfigs);

  return {
    errorMsg: `The selected entity types are conflicts with ruleset ${conflictedRulesetName.join(
      ', '
    )}.`,
    ...(returnConflictingRuleset
      ? { conflictingRulesets: conflictedConfigs }
      : {}),
  };
}

export function getConflictingRulesetNames(conflictedConfigs) {
  return conflictedConfigs.map((config) => config.name);
}

export function getConflictingAccountTypes(conflictingConfig) {
  if (!conflictingConfig) return [];

  let conflictingAccountTypes = [];
  conflictingConfig.conflictingRulesets.forEach((ruleset) => {
    conflictingAccountTypes = conflictingAccountTypes.concat(
      ruleset.applicationTypes
    );
  });

  return [...new Set(conflictingAccountTypes)];
}

export function createLimitConflictMessage(limitConflicts) {
  if (!limitConflicts || limitConflicts.length === 0) return;
  let message = `This limit range conflicts with ruleset${
    limitConflicts.length > 1 ? 's' : ''
  }: `;

  limitConflicts.forEach((conflict, i) => {
    message = `${message}"${conflict.name} ($${conflict.min}-$${
      conflict.max
    })"${i < limitConflicts.length - 1 ? ', ' : ''}`;
  });
  return message;
}

export function getLegalTypesError(legalTypes) {
  if (legalTypes.length === 0) {
    return { legal_types: 'Please select at least one legal type' };
  }

  return {};
}

function stringToInt(value) {
  if (typeof value === 'number') {
    return value;
  }

  return parseInt(value);
}

export function getCreditLimitError(
  minCreditValue,
  maxCreditValue,
  tradeAccountLimit
) {
  const processedMinValue = stringToInt(minCreditValue);
  const processedMaxValue = stringToInt(maxCreditValue);

  if (processedMaxValue === 0) {
    return { credit_limit: 'Please select a max credit value' };
  }

  if (processedMaxValue <= processedMinValue) {
    return {
      credit_limit: 'Max credit value must be greater than the minimum',
    };
  }

  if (tradeAccountLimit && processedMaxValue > tradeAccountLimit) {
    return {
      credit_limit: `Max credit limit is $${formatMoney(tradeAccountLimit)}`,
    };
  }

  if (tradeAccountLimit && processedMaxValue > tradeAccountLimit) {
    return {
      credit_limit: `Max credit limit is $${formatMoney(tradeAccountLimit)}`,
    };
  }

  return {};
}

export function getRulesetConflicts(allConfigs, currentConfig) {
  const { attributes } = currentConfig;

  const definedApplicationType = Boolean(attributes.application_types);

  const hasCreditApplication =
    definedApplicationType && attributes.application_types.includes('credit');

  const hasCashApplication =
    definedApplicationType && attributes.application_types.includes('cash');

  const entityConflicts = findEntityTypeConflicts(
    allConfigs,
    currentConfig,
    true
  );

  const limitBreakpoints = findLimitBreakpoints(allConfigs, currentConfig);
  const limitConflicts = findLimitConflicts(limitBreakpoints, currentConfig);
  const conflictingAccountTypes = getConflictingAccountTypes(entityConflicts);

  const conflictingCreditApplication =
    hasCreditApplication &&
    entityConflicts &&
    limitConflicts &&
    conflictingAccountTypes.includes('credit');

  const conflictingCashApplication =
    hasCashApplication &&
    entityConflicts &&
    conflictingAccountTypes.includes('cash');

  if (conflictingCashApplication || conflictingCreditApplication) {
    return limitConflicts
      ? limitConflicts
      : entityConflicts.conflictingRulesets;
  }

  return null;
}

export function sortedAddonList(state, moduleName) {
  const addonsList = state.add_ons[moduleName];
  const sortedAddonsList = addonsList
    ? [
        ...addonsList
          .filter((addon) => addon.attributes.active)
          .sort(
            (a, b) => a.attributes.serial_number - b.attributes.serial_number
          ),
        ...addonsList
          .filter((addon) => !addon.attributes.active)
          .sort(
            (a, b) => a.attributes.serial_number - b.attributes.serial_number
          ),
      ]
    : [];

  return sortedAddonsList;
}

export function getCardManagementModuleLink(id) {
  return `${window.location.origin}/card-management/${id}/email-verification`;
}

export function getDirectDebitModuleLink(id) {
  return `${window.location.origin}/paperless/${id}/email-verification`;
}

export function objHasProp(object, property) {
  if (
    !object ||
    !property ||
    typeof object !== 'object' ||
    typeof property !== 'string'
  ) {
    return false;
  }

  return object.hasOwnProperty(property);
}

export const channelToAddonType = (channel) => {
  switch (channel) {
    case 'qr':
      return 'qr_code';
    case 'website':
      return 'website_button';
    case 'sales':
      return 'rep_channel';
    default:
      return null;
  }
};

export function appendQuery(link, string) {
  if (!link || !string) return;

  const appendChar = link.includes('?') ? '&' : '?';
  return `${link}${appendChar}${string}`;
}
