import React from 'react';
import styled from 'styled-components';
import { ConnectDragSource } from 'react-dnd';

import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';

const Content = styled.div<{ $hasBg: boolean }>`
  padding: 8px 18px;
  background: ${(props) => (props.$hasBg ? '#E3E3E3' : 'white')};
  border-radius: 5px;
  box-shadow: 2px 2px 4px 2px #00000040;
`;

type T3Props = {
  connectDragSource: ConnectDragSource;
  node: { title: string; isChannelDecentralised: boolean };
};

export const T3 = (props: T3Props) => {
  const { connectDragSource, node } = props;
  const { title, isChannelDecentralised } = node;

  const content = () => (
    <Content $hasBg={isChannelDecentralised}>{title}</Content>
  );

  return connectDragSource(
    <div>
      {isChannelDecentralised ? (
        <PopperTooltip
          placement="top"
          title={'Decentralised sales channels apply. (Rep, Website, Location)'}
        >
          {content()}
        </PopperTooltip>
      ) : (
        content()
      )}
    </div>,
    { dropEffect: 'copy' }
  );
};
