/* Import components */
import AdminTable from 'modules/shared/components/widgets/interactive/AdminTable';
import DateString from 'modules/shared/components/widgets/static/DateString';
/* Import libraries */
import React from 'react';
import StatusIndicators from './StatusIndicators';
import StatusOverview from './StatusOverview';
import { connect } from 'react-redux';

function TrmApplicationList(props) {
  const { data, config, expandable, dispatch, ...rest } = props;

  return (
    <AdminTable data={data} expandable={expandable} config={config} {...rest} />
  );
}

const options = {
  expandable: {
    key: 'attributes.overview',
  },
  columns: [
    {
      type: 'expandable',
      key: 'attributes.overview',
    },
    {
      type: 'data',
      label: 'Consumer',
      key: 'attributes.consumer_name',
      widthDefault: 250,
      widthAdminView: 250,
    },
    {
      type: 'data',
      label: 'Supplier',
      key: 'attributes.supplier_name',
      widthDefault: 250,
      widthAdminView: 250,
    },
    {
      type: 'data',
      label: 'Status',
      key: 'attributes.status_indicators',
      sortKey: 'attributes.status_count',
      widthDefault: 200,
      widthAdminView: 200,
    },
    {
      type: 'data',
      label: 'Received',
      key: 'attributes.submission_modified_at_formatted',
      sortKey: 'attributes.submission_modified_at',
      widthDefault: 120,
      widthAdminView: 120,
    },
    {
      type: 'data',
      label: 'Started',
      key: 'attributes.check_started_at_formatted',
      sortKey: 'attributes.check_started_at',
      cellClass: 'letter_spacing',
      widthDefault: 120,
      widthAdminView: 120,
    },
    {
      type: 'data',
      label: 'Modified',
      key: 'attributes.check_updated_at_formatted',
      sortKey: 'attributes.check_updated_at',
      cellClass: 'letter_spacing',
    },
  ],
};

const applicationStatusCount = (checks) => {
  const result = { remaining: 0, failed: 0, passed: 0 };
  if (checks) {
    checks.forEach((check) => {
      if (check.outcome == 'passed') {
        result.passed += 1;
      } else if (check.outcome == 'failed') {
        result.failed += 1;
      } else {
        result.remaining += 1;
      }
    });
  }

  return result.remaining * 100 + result.failed * 10 + result.passed;
};

export default connect((state, ownProps) => {
  let data = ownProps.data;
  const config = options.columns;
  const activeFilter = ownProps.location.query.filter || 'all';

  data = data.map((row) => {
    row.attributes['submission_modified_at_formatted'] = (
      <DateString
        showNull={true}
        format={'D/M/YY'}
        value={row.attributes.submission_modified_at}
      />
    );
    row.attributes['check_started_at_formatted'] = (
      <DateString
        showNull={true}
        format={'D/M/YY'}
        value={row.attributes.check_started_at}
      />
    );
    row.attributes['check_updated_at_formatted'] = (
      <DateString
        showNull={true}
        format={'D/M/YY'}
        value={row.attributes.check_updated_at}
      />
    );

    // Formatted sortable status integer
    row.attributes.status_count = applicationStatusCount(row.attributes.checks);
    // Add status indicator td
    row.attributes.status_indicators = <StatusIndicators application={row} />;
    // Add expandable section
    row.attributes.overview = <StatusOverview application={row} id={row.id} />;

    return row;
  });

  return {
    location: ownProps.location,
    data,
    config,
    activeFilter,
    expandable: options.expandable,
  };
})(TrmApplicationList);
