import React, { ReactElement } from 'react';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import isBlank from 'utils/isBlank';
import QAndAHelpLink from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink';
import { COMPANY_NUMBER } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';

const CompanyNumberField = ({
  control,
  isDisabled,
  isLoading,
  label,
  labelShrink,
  name,
  onReset,
  onRetrieveDetailsWithCompanyNumber,
  placeholder,
  region,
}): ReactElement => {
  const [isFocused, setIsFocused] = React.useState(false);

  const onChangeCompanyNumber = (_, processedValue) => {
    const companyNumber = processedValue;
    if (isBlank(companyNumber)) {
      onReset();

      return;
    }

    if (onRetrieveDetailsWithCompanyNumber) {
      onRetrieveDetailsWithCompanyNumber(companyNumber);
    }
  };

  return (
    <div className="column">
      <RHFBorderedTextField
        control={control}
        isLoading={isLoading}
        label={label}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        labelShrink={labelShrink || isFocused}
        name={name}
        noWhiteSpaces={true}
        onChange={onChangeCompanyNumber}
        readOnly={isDisabled || isLoading}
        placeholder={placeholder}
      />
      {region === 'AU' && <QAndAHelpLink questionKey={COMPANY_NUMBER} />}
    </div>
  );
};

export default CompanyNumberField;
