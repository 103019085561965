import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React from 'react';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
    },
  };
});

export default function DateTimePicker(props) {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <TextField
        variant="outlined"
        size="small"
        color="primary"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        {...props}
      />
    </MuiThemeProvider>
  );
}
