/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';

/* Import CSS */
import styles from './css/CommonEditProfile.css';

/* Import components */
import Button from 'modules/shared/components/inputs/Button';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import LargeScrollingDropdown from 'modules/shared/components/widgets/interactive/LargeScrollingDropdown';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';

/* Import actions */
import {
  getAnzsicDivisions,
  getAnzsicSubdivisions,
  setAnzsicDivision,
  setAnzsicSubdivision,
  getAnzsicGroups,
  setAnzsicGroup,
  getAnzsicClasses,
  setAnzsicClass,
  loadBusinessDescription,
  updateEntityBusinessDescription,
} from '../actions';

var AnzicsCode = createClass({
  componentWillMount: function() {
    const { dispatch, business_description } = this.props;
    dispatch(loadBusinessDescription());
    dispatch(getAnzsicDivisions());
  },

  submit: function() {
    const { dispatch, business_description } = this.props;
    if (business_description && business_description['class']) {
      dispatch(
        updateEntityBusinessDescription(business_description['class'].key)
      );
    }
  },

  selectDivision: function(value) {
    const { dispatch } = this.props;
    dispatch(setAnzsicDivision(value));
    dispatch(getAnzsicSubdivisions(value.key));
  },

  selectSubdivision: function(value) {
    const { dispatch } = this.props;
    dispatch(setAnzsicSubdivision(value));
    dispatch(getAnzsicGroups(value.key));
  },

  selectGroup: function(value) {
    const { dispatch } = this.props;
    dispatch(setAnzsicGroup(value));
    dispatch(getAnzsicClasses(value.key));
  },

  selectClass: function(value) {
    const { dispatch } = this.props;
    dispatch(setAnzsicClass(value));
    dispatch(updateEntityBusinessDescription(value.key));
  },

  checkAllValid: function() {
    const { business_description } = this.props;
    if (!business_description) {
      return false;
    }
    if (!business_description['division']) {
      return false;
    }
    if (!business_description['subdivision']) {
      return false;
    }
    if (!business_description['group']) {
      return false;
    }
    if (!business_description['class']) {
      return false;
    }

    return true;
  },

  render: function() {
    const {
      title,
      update_loading,
      divisions,
      divisions_label,
      subdivisions,
      subdivisions_label,
      groups,
      groups_label,
      classes,
      classes_label,
      business_description,
      changes_made,
    } = this.props;

    let content = [];
    if (business_description) {
      content.push(
        <LargeScrollingDropdown
          label={divisions_label}
          options={divisions}
          value={business_description['division']}
          first={true}
          handleClick={this.selectDivision}
          admin={true}
          key={'division'}
        />
      );

      if (business_description['division'] && subdivisions) {
        content.push(
          <LargeScrollingDropdown
            label={subdivisions_label}
            options={subdivisions}
            value={business_description['subdivision']}
            forceOpen={
              business_description['division'] &&
              !business_description['subdivision']
            }
            handleClick={this.selectSubdivision}
            admin={true}
            key={'subdivision'}
          />
        );
      }

      if (business_description['subdivision'] && groups) {
        content.push(
          <LargeScrollingDropdown
            label={groups_label}
            options={groups}
            value={business_description['group']}
            forceOpen={
              business_description['subdivision'] &&
              !business_description['group']
            }
            handleClick={this.selectGroup}
            admin={true}
            key={'group'}
          />
        );
      }

      if (business_description['group'] && classes) {
        content.push(
          <LargeScrollingDropdown
            label={classes_label}
            options={classes}
            value={business_description['class']}
            forceOpen={
              business_description['group'] && !business_description['class']
            }
            handleClick={this.selectClass}
            admin={true}
            key={'class'}
          />
        );
      }
    } else {
      content.push(
        <LargeScrollingDropdown
          label={divisions_label}
          options={divisions}
          first={true}
          handleClick={this.selectDivision}
          admin={true}
          key={0}
        />
      );
    }

    var saving_loader = null,
      update_button_text = 'update industy',
      update_click = this.submit,
      disable_btn = false;
    if (update_loading) {
      saving_loader = <span className={styles.loading}></span>;
      update_button_text = 'saving';
      update_click = (event) => {};
    }

    if (!this.checkAllValid()) {
      update_button_text = 'invalid info';
      disable_btn = true;
    }

    let update_button = null;
    if (changes_made === true) {
      update_button = (
        <div className={styles.button}>
          <Button
            handleClick={update_click}
            text={update_button_text}
            disabled={disable_btn}
            grey={disable_btn}
          />
          {saving_loader}
        </div>
      );
    }

    return (
      <div className={styles.row_anzic_codes}>
        <div className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>{title}</h2>
            {update_button}
            <div className={styles.anzic_codes}>{content}</div>
          </div>
        </div>
      </div>
    );
  },
});

var defaults = {
  title: 'Company industy',
  divisions_label: 'What does your business do?',
  subdivisions_label: 'In which industry?',
  groups_label: 'And which sector',
  classes_label: 'Last one we promise.',
};

module.exports = connect((state, ownProps) => {
  let business_description = state.manage_profile.settings_business_description,
    s = state.manage_profile;

  return {
    title: defaults.title,
    divisions_label: defaults.divisions_label,
    divisions: s.settings_anzsic_options.divisions,
    subdivisions_label: defaults.subdivisions_label,
    subdivisions: s.settings_anzsic_options.subdivisions,
    groups_label: defaults.groups_label,
    groups: s.settings_anzsic_options.groups,
    classes_label: defaults.classes_label,
    classes: s.settings_anzsic_options.classes,
    update_loading: s.settings_updating_anzsic,
    business_description,
    changes_made: s.settings_business_description_altered,
  };
})(AnzicsCode);
