import Button from 'modules/shared/components/inputs/Button';
import React, { useState } from 'react';
import styled from 'styled-components';

import { AddNoteButton } from '../../Notes';
import { ConfirmClear } from './ConfirmClear';

const Conatainer = styled.div`
  display: flex;
  gap: 20px;
`;

type ButtonsProps = {
  applicationId: string;
  handleSave: () => void;
  handleClear: () => void;
  isActive: boolean;
  isPending: boolean;
  noteCategory: 'alert' | 'review_date';
};

export const Buttons = (props: ButtonsProps) => {
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const openConfirmationModal = () => setConfirmationModalOpen(true);
  const closeConfirmationModal = () => setConfirmationModalOpen(false);

  const {
    applicationId,
    handleSave,
    handleClear,
    isActive,
    isPending,
    noteCategory,
  } = props;

  const onConfirm = () => {
    closeConfirmationModal();
    handleClear();
  };

  return isActive ? (
    <Conatainer>
      <Button
        handleClick={openConfirmationModal}
        disabled={isPending}
        loading={isPending}
        red
        text="Clear"
        type="button"
      />
      <ConfirmClear
        isOpen={isConfirmationModalOpen}
        onCancel={closeConfirmationModal}
        onConfirm={onConfirm}
      />
    </Conatainer>
  ) : (
    <Conatainer>
      <Button
        handleClick={handleSave}
        disabled={isPending}
        loading={isPending}
        text="Save"
        type="submit"
      />
      <AddNoteButton
        params={{ application_id: applicationId }}
        noteCategory={noteCategory}
      />
    </Conatainer>
  );
};
