import axios from './axios';

export default class ReCaptcha {
  constructor(config) {
    this.axios = axios(config);
  }

  verify(token) {
    return this.axios.post(`/verify?response=${token}`);
  }
}
