import {
  lookupPersonPostalAddress,
  lookupResidentialAddress,
  removeEntityPartyDetails,
  setCorporateTrusteeDetails,
  setEntityPartyDetailsData,
  setEntityPartyDetailsIndex,
  toggleIsApplicant,
} from '../../actions/onboarding';

import BusinessPartyDetailsForm from './BusinessPartyDetailsForm';
import { EMAIL_REGEX } from 'constants';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import { requiresSignatories } from 'modules/shared/helpers/applicationDetectInBCFlow';
import styles from './css/Business.css';
import SignatorySwitchModal from '../guarantors/SignatorySwitchModal';
import {
  deleteDuplicateAuthorisation,
  displayDataLostWarning,
  getAddonRules,
  getAffectedPages,
  setAffectedPages,
} from 'modules/consumer-onboarding/actions/section';
import get from 'lodash.get';
import { isValidName } from 'utils/validators';
import { isWatchtowerBlock, getWatchtowerBlockedMessage } from 'utils';
import api from 'api';
import { getPersonGuarantorDetails } from '../utils';
import { FEATURE_FLAGS } from 'conf';
import { DirectorCount } from './corporate-trustee/DirectorCount';

const ACN_LENGTH = 9;
const NZCN_LENGTHS = [6, 7];
const isDuplicateAuthFixEnabled = FEATURE_FLAGS.FEATURE_FLAG_DUPLICATE_AUTH_FIX;

class BusinessPartyDetails extends React.Component {
  state = {
    form_errors: {},
    error_messages: {
      contact_country_code: 'You need to enter a country code.',
      contact_phone_number: 'You need to enter a phone number.',
      first_name_required: 'Please enter a first name',
      first_name_invalid: 'Please enter a valid first name',
      is_applicant: 'Please select yes or no if this is you',
      last_name_required: 'Please enter a last name',
      last_name_invalid: 'Please enter a valid last name',
      middle_name_invalid: 'Please enter a valid middle name',
      previous_last_name_invalid: 'Please enter a valid last name',
      person_postal_address: 'You must select a valid postal address',
      residential_address: 'You must select a valid physical address',
      residential_type: 'Please select a residential type',
      title_invalid: 'Titles (such as Lady or Sir) are not valid first names',
      trustee_type_required: 'This field is mandatory',
      corporate_trustee_company_number_required: 'This field is mandatory',
      corporate_trustee_company_name_required: 'This field is mandatory',
      corporate_trustee_company_number_invalid:
        'Please enter a valid company number',
      corporate_trustee_director_count_required: 'This field is mandatory',
    },
    modal: {
      show: false,
    },
  };

  componentWillReceiveProps(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.form_values));
    if (nextProps.page_validation_start) {
      this.checkValidAll();
    }
  }

  checkValidAll = () => {
    const {
      form_values,
      setPageValidationStartFinish,
      entity_type,
      subComponent,
      isCorporateTrusteeEnabled,
    } = this.props;

    let inputs = ['first_name', 'last_name', 'is_applicant'];

    if (entity_type !== 'company') {
      inputs.push('email');
    }

    if (entity_type == 'personal') {
      const personal_inputs = [
        'contact_country_code',
        'contact_phone_number',
        'person_postal_address',
        'residential_address',
        'residential_type',
        'is_applicant',
      ];
      inputs = inputs.concat(personal_inputs);
    }

    if (entity_type === 'trust' && isCorporateTrusteeEnabled) {
      inputs.push('trustee_type');
      if (form_values.trustee_type === 'corporate') {
        inputs.push('corporate_trustee_company_number');
        inputs.push('corporate_trustee_company_name');
      }
      if (subComponent === 'director-count') {
        inputs.push('corporate_trustee_director_count');
      }
    }

    inputs.forEach((value) => {
      let target = {
        id: value,
        name: value,
        value: form_values[value],
        processedValue: form_values[value],
      };
      setTimeout(() => {
        if (value === 'email') {
          this.refs.party_details.refs.email._onBlur();
        } else {
          this.checkFormValid(target);
        }
      }, 500);
    });

    setPageValidationStartFinish();
  };

  componentDidMount() {
    const { dispatch, component_index, handleComplete } = this.props;

    if (component_index === 0) {
      dispatch(getAddonRules());
    }

    handleComplete(this.isComplete());
    this.removeEntityPartyDetails();

    this.assignIsApplicant();
  }

  assignIsApplicant = () => {
    const { application, component_index, dispatch, entity_type, party_count } =
      this.props;

    if (entity_type !== 'personal' || party_count > 1) {
      return;
    }

    dispatch(toggleIsApplicant({ index: component_index, isApplicant: true }));
    const applicantEmail = get(
      application,
      'attributes.consumer_contact_email',
      ''
    );
    this._emailChangeHandler(applicantEmail, true);
  };

  isComplete = (form_values) => {
    const {
      entity_type,
      canSelectSignatoryApplicant,
      subComponent,
      component,
      isCorporateTrusteeEnabled,
      isCorporateTrusteeGuarantorEnabled,
    } = this.props;
    const { form_errors } = this.state;

    const isTrustPartyDetailsGuarantorEnabled =
      entity_type === 'trust' &&
      component === 'party-details' &&
      isCorporateTrusteeEnabled &&
      isCorporateTrusteeGuarantorEnabled;

    const skipTrusteeEmailAndIsApplicant =
      isTrustPartyDetailsGuarantorEnabled &&
      form_values &&
      (form_values.trustee_type === 'individual' ||
        subComponent === 'director-details');

    if (skipTrusteeEmailAndIsApplicant) {
      return (
        !!get(form_values, 'first_name') && !!get(form_values, 'last_name')
      );
    }

    if (form_errors.emailErrorMessage) {
      // The only error held by form_errors.emailErrorMessage is whether the
      // email is blocked by Watchtower or not. If we don't do this, the error
      // will be reset elsewhere, letting the user proceed to the next section
      // even if the email is blocked by Watchtower, and the error is displatyed.
      return false;
    }

    var re = EMAIL_REGEX;

    if (!form_values) {
      var form_values = this.props.form_values;
    }

    if (
      isCorporateTrusteeEnabled &&
      entity_type === 'trust' &&
      !form_values.trustee_type
    ) {
      return false;
    }
    if (
      entity_type === 'trust' &&
      form_values.trustee_type === 'corporate' &&
      subComponent !== 'director-details'
    ) {
      if (subComponent === 'director-count') {
        return Boolean(parseInt(form_values.corporate_trustee_director_count));
      }

      const {
        corporate_trustee_company_number,
        corporate_trustee_company_name,
      } = form_values;

      return Boolean(
        corporate_trustee_company_number && corporate_trustee_company_name
      );
    }

    if (form_errors.hasOwnProperty('email') && form_errors.email) return false;
    // NOTES: I do not want to confuse myself with the existing condition
    // hence wrapping the whole thing with the `entity_type` condition instead.
    // Not ideal and the condition needs to be tidied up. Better yet if the
    // entire BC flow gets rewritten.
    if (entity_type !== 'company') {
      if (
        !form_values.email ||
        !this.refs.party_details.isEmailValid() ||
        (form_values.email &&
          form_values.email.length > 0 &&
          !re.test(form_values.email))
      ) {
        return false;
      }
    }

    if (
      canSelectSignatoryApplicant &&
      isBlank(form_values.is_applicant) &&
      form_values.trustee_type !== 'corporate' &&
      form_values.trustee_type !== 'independent'
    ) {
      return false;
    }

    if (
      form_values.first_name &&
      isValidName(form_values.first_name) &&
      form_values.last_name &&
      isValidName(form_values.last_name) &&
      this.isPersonalFormValuesValid(entity_type, form_values)
    ) {
      // First name and last name are present, and they are valid.
      // We should also check the other name components, if they are presnt

      // Middle name and previous last name are optional.
      // But if present, they should be valid
      if (
        (form_values.middle_name && !isValidName(form_values.middle_name)) ||
        (entity_type === 'personal' &&
          form_values.previous_last_name &&
          !isValidName(form_values.previous_last_name))
      ) {
        return false;
      }

      return true;
    }
    return false;
  };

  isPersonalFormValuesValid = (entityType, formValues) => {
    const { hasToBeApplicant, form_values } = this.props;
    const { form_errors } = this.state;

    if (entityType !== 'personal') {
      return true;
    }

    if (
      isBlank(formValues.person_postal_address_api_id) &&
      isPresent(formValues.person_postal_address) &&
      (formValues.person_postal_address.full_address || '').length > 0
    ) {
      return false;
    }

    if (hasToBeApplicant && !formValues.is_applicant) return false;

    return (
      formValues.residential_api_id &&
      formValues.residential_type &&
      formValues.contact_phone_number &&
      formValues.contact_country_code
    );
  };

  checkFormValid = (target) => {
    const { form_errors, error_messages } = this.state;
    const { form_values, hasToBeApplicant, entity_region, subComponent } =
      this.props;
    const errors = Object.assign({}, this.state.form_errors);

    switch (target.id || target.name) {
      case 'first_name':
        if (!target.value || !isValidName(target.value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: target.value
                ? error_messages['first_name_invalid']
                : error_messages['first_name_required'],
            },
          });
        } else if (
          ['sir', 'lord', 'princess', 'lady'].includes(
            target.value.toLowerCase()
          )
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages['title_invalid'],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'last_name':
        if (!target.value || !isValidName(target.value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: target.value
                ? error_messages['last_name_invalid']
                : error_messages['last_name_required'],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'middle_name':
        if (target.value && !isValidName(target.value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages['middle_name_invalid'],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'previous_last_name':
        if (target.value && !isValidName(target.value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages['previous_last_name_invalid'],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'residential_address':
        if (
          !form_values ||
          !form_values.residential_api_id ||
          form_values.residential_api_id === null
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              [target.id]: error_messages[target.id],
            },
          });
        } else {
          delete errors[target.id];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'residential_api_id':
        if (!target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              ['residential_address']: error_messages['residential_address'],
            },
          });
        } else {
          delete errors['residential_address'];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'residential_type':
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              ['residential_type']: error_messages['residential_type'],
            },
          });
        } else {
          delete errors['residential_type'];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'contact_phone_number':
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              ['contact_phone_number']: error_messages['contact_phone_number'],
            },
          });
        } else {
          delete errors['contact_phone_number'];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'contact_country_code':
        if (!target.value || target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              ['contact_country_code']: error_messages['contact_country_code'],
            },
          });
        } else {
          delete errors['contact_country_code'];
          this.setState({
            form_errors: errors,
          });
        }
        break;
      case 'person_postal_address':
        if (
          isBlank(form_values.person_postal_address_api_id) &&
          isPresent(form_values.person_postal_address) &&
          (form_values.person_postal_address.full_address || '').length > 0
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              person_postal_address: error_messages['person_postal_address'],
            },
          });
        } else {
          delete errors['person_postal_address'];
          this.setState({ form_errors: errors });
        }
        break;
      case 'is_applicant':
        const value = target.processedValue;
        if (
          form_values.trustee_type === 'corporate' ||
          form_values.trustee_type === 'independent'
        ) {
          delete errors.is_applicant;
          this.setState({ form_errors: errors });
        } else if (!subComponent && isBlank(value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              is_applicant: error_messages.is_applicant,
            },
          });
        } else {
          delete errors.is_applicant;
          this.setState({ form_errors: errors });
        }
        if (hasToBeApplicant) {
          if (!value) {
            this.setState({
              form_errors: {
                ...form_errors,
                is_applicant:
                  'Please select at least one person as the applicant',
              },
            });
          } else {
            delete errors['is_applicant'];
            this.setState({ form_errors: errors });
          }
        }
        break;
      case 'trustee_type':
        if (!target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              trustee_type: error_messages.trustee_type_required,
            },
          });
        } else {
          delete errors['trustee_type'];
          this.setState({ form_errors: errors });
        }
        break;
      case 'corporate_trustee_company_number':
        if (!subComponent && !target.value) {
          this.setState({
            form_errors: {
              ...form_errors,
              corporate_trustee_company_number:
                error_messages.corporate_trustee_company_number_required,
            },
          });
        } else if (
          (entity_region === 'AU' && target.value.length !== ACN_LENGTH) ||
          (entity_region === 'NZ' &&
            !NZCN_LENGTHS.includes(target.value.length))
        ) {
          this.setState({
            form_errors: {
              ...form_errors,
              corporate_trustee_company_number:
                error_messages.corporate_trustee_company_number_invalid,
            },
          });
        } else {
          delete errors['corporate_trustee_company_number'];
          this.setState({ form_errors: errors });
        }
        break;
      case 'corporate_trustee_director_count':
        if (!parseInt(target.value)) {
          this.setState({
            form_errors: {
              ...form_errors,
              corporate_trustee_director_count:
                error_messages.corporate_trustee_director_count_required,
            },
          });
        } else {
          delete errors['corporate_trustee_director_count'];
          this.setState({ form_errors: errors });
        }
        break;
    }
  };

  handleChange = (event) => {
    const {
      dispatch,
      component_index,
      form_values,
      subComponent,
      subComponentIndex,
    } = this.props;
    if (event.hasOwnProperty('target')) {
      this.checkFormValid(event.target);
    }

    if (subComponent === 'director-details') {
      dispatch(
        setCorporateTrusteeDetails(
          form_values.corporate_trustee_id,
          subComponentIndex,
          event.target.name,
          event.target.value
        )
      );
    } else {
      dispatch(
        setEntityPartyDetailsData(
          component_index,
          event.target.name,
          event.target.value
        )
      );
    }
  };

  removeEntityPartyDetails = () => {
    const { dispatch, party_count, values_count, form_values } = this.props;
    if (values_count > party_count) {
      dispatch(removeEntityPartyDetails(party_count));
    }
  };

  handleBlur = (event) => {
    const { dispatch, component_index, subComponent, subComponentIndex } =
      this.props;

    if (
      event.target.name !== 'residential_address' &&
      event.target.name !== 'person_postal_address'
    ) {
      if (subComponent === 'director-details' && form_values) {
        dispatch(
          setCorporateTrusteeDetails(
            form_values.corporate_trustee_id,
            subComponentIndex,
            event.target.name,
            event.target.value
          )
        );
      } else {
        dispatch(
          setEntityPartyDetailsData(
            component_index,
            event.target.name,
            event.target.value && event.target.value.trim()
          )
        );
      }
    }
    this.checkFormValid(event.target);
  };

  _emailChangeCallback = () => {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete());
  };

  _emailBlurHandler = async (value, isValid) => {
    if (isValid) {
      const blockedResourcesApi = api(
        'blocked_resources',
        '',
        this.props.consumerId
      );

      try {
        await blockedResourcesApi.isResourceBlocked({
          value,
          type: 'Email',
          supplierId: this.props.supplierId,
        });
      } catch (error) {
        const errors = this.state.form_errors;
        this.setState({
          form_errors: {
            ...errors,
            email: true,
            emailErrorMessage: isWatchtowerBlock(error)
              ? getWatchtowerBlockedMessage('email')
              : 'Cannot verify email',
          },
        });
      }
    }
  };

  _emailChangeHandler = (value, isValid) => {
    const {
      dispatch,
      component_index,
      subComponentIndex,
      subComponent,
      form_values,
    } = this.props;
    const { form_errors } = this.state;

    if (subComponent === 'director-details') {
      dispatch(
        setCorporateTrusteeDetails(
          form_values.corporate_trustee_id,
          subComponentIndex,
          'email',
          value
        )
      );
    } else {
      dispatch(setEntityPartyDetailsData(component_index, 'email', value));
    }

    if (isValid) {
      const { email, ...rest } = form_errors;
      this.setState(
        {
          form_errors: { ...rest },
        },
        this._emailChangeCallback
      );
    } else {
      this.setState(
        {
          form_errors: { ...form_errors, email: true },
        },
        this._emailChangeCallback
      );
    }
  };

  handleResidentialChange = (event) => {
    const { dispatch, component_index, entity_region } = this.props;
    dispatch(
      setEntityPartyDetailsData(component_index, 'residential_address', {
        full_address: event.target.value,
      })
    );
    if (event.target.value.length > 0) {
      event.persist();
      dispatch(
        lookupResidentialAddress(
          component_index,
          event.target.value,
          entity_region
        )
      );
    }
  };

  handleResidentialClick = (value) => {
    const { dispatch, component_index } = this.props;
    dispatch(
      setEntityPartyDetailsData(component_index, 'residential_api_id', value)
    );
    this.checkFormValid({ id: 'residential_api_id', value: value });
  };

  handleResidentialTypeChange = (event) => {
    const { dispatch, component_index } = this.props;
    if (event.hasOwnProperty('target')) {
      this.checkFormValid(event.target);
    }
    dispatch(
      setEntityPartyDetailsData(
        component_index,
        'residential_type',
        event.target.value.trim()
      )
    );
  };

  handleIsApplicantChange = (event) => {
    const { dispatch, form_values } = this.props;
    const value = event.target.value;

    const signatoryData = {
      signatory: form_values,
      selectedValue: value,
    };
    const affectedPagesParam = isDuplicateAuthFixEnabled
      ? { signatoryData }
      : undefined;
    const willAffectPages = dispatch(
      displayDataLostWarning(affectedPagesParam)
    );
    const affectedPages = dispatch(getAffectedPages(affectedPagesParam));
    const checkSignatoryAffected =
      isDuplicateAuthFixEnabled && affectedPages.includes('signatory');

    if (willAffectPages || checkSignatoryAffected) {
      return this.setState({
        modal: {
          display: true,
          value,
        },
      });
    }

    this.dispatchIsApplicantChange(value);
  };

  dispatchIsApplicantChange = (value, isAffecting = false) => {
    const {
      application,
      dispatch,
      component_index,
      subComponentIndex,
      subComponent,
      form_values,
    } = this.props;

    const signatoryData = {
      signatory: form_values,
      selectedValue: value,
    };
    const affectedPagesParam = isDuplicateAuthFixEnabled
      ? { signatoryData }
      : undefined;
    const affectedPages = dispatch(getAffectedPages(affectedPagesParam));

    const processedValue = value === 'Yes';
    this.checkFormValid({ id: 'is_applicant', processedValue });

    let applicantEmail = '';
    if (processedValue) {
      applicantEmail = get(
        application,
        'attributes.consumer_contact_email',
        ''
      );

      if (isDuplicateAuthFixEnabled && affectedPages.includes('signatory')) {
        dispatch(deleteDuplicateAuthorisation());
      }
    }
    this._emailChangeHandler(applicantEmail, true);

    if (subComponent === 'director-details') {
      dispatch(
        setCorporateTrusteeDetails(
          form_values.corporate_trustee_id,
          subComponentIndex,
          'is_applicant',
          processedValue
        )
      );
    } else {
      dispatch(
        toggleIsApplicant({
          index: component_index,
          isApplicant: processedValue,
        })
      );
    }

    if (isAffecting && affectedPages.length > 0) {
      dispatch(setAffectedPages(affectedPages));
    }
  };

  handlePostalAddressChange = (event) => {
    const { dispatch, component_index, entity_region } = this.props;
    const value = event.target.value;

    dispatch(
      setEntityPartyDetailsData(component_index, 'person_postal_address', {
        full_address: value,
      })
    );

    if (event.target.value.length > 0) {
      event.persist();
      dispatch(
        lookupPersonPostalAddress(component_index, value, entity_region)
      );
    }
  };

  handlePostalAddressClick = (value) => {
    const { dispatch, component_index } = this.props;

    dispatch(
      setEntityPartyDetailsData(
        component_index,
        'person_postal_address_api_id',
        value
      )
    );
    this.checkFormValid({ id: 'person_postal_address_api_id', value });
  };

  countryCodeHandleChange = (target) => {
    const { dispatch, component_index } = this.props;
    if (target) {
      this.checkFormValid(target);
    }
    dispatch(
      setEntityPartyDetailsData(
        component_index,
        'contact_country_code',
        target.value
      )
    );
  };

  setCompanyNumberInvalidError = () => {
    this.setState({
      form_errors: {
        ...this.state.form_errors,
        corporate_trustee_company_number:
          this.state.error_messages.corporate_trustee_company_number_invalid,
      },
    });
  };

  render() {
    const {
      application,
      dispatch,
      canSelectSignatoryApplicant,
      entity_type,
      form_values,
      title,
      showGuarantorRemoveModal,
      hideShowGuarantorModal,
      saveBusinessState,
      isGuarantorApproved,
      subComponent,
      entity_region,
      isCorporateTrusteeEnabled,
      isCorporateTrusteeGuarantorEnabled,
      isCorporateTrusteeLocked,
      hasApplicantSignatoryDecision,
    } = this.props;

    const { form_errors, modal } = this.state;

    const signatoryData = {
      signatory: form_values,
      selectedValue: modal.value,
    };
    const affectedPagesParam = isDuplicateAuthFixEnabled
      ? { signatoryData }
      : undefined;
    const addonRuleKeys = dispatch(getAffectedPages(affectedPagesParam));

    if (subComponent === 'director-count') {
      return (
        <section className={styles.section}>
          <DirectorCount
            directorCount={form_values.corporate_trustee_director_count}
            error={form_errors.corporate_trustee_director_count}
            handleChange={this.handleChange}
          />
        </section>
      );
    }

    return (
      <Fragment>
        <section className={styles.section}>
          <div className={styles.row}>
            <div className={styles.full_col}>
              <PanelTitle text={title} margin_bottom="1em" />
            </div>
          </div>
          <div className={styles.row}>
            <BusinessPartyDetailsForm
              key="party_details"
              ref="party_details"
              application={application}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              emailChangeHandler={this._emailChangeHandler}
              emailBlurHanlder={this._emailBlurHandler}
              values={form_values}
              errors={form_errors}
              entity_type={entity_type}
              canSelectSignatoryApplicant={canSelectSignatoryApplicant}
              handleResidentialChange={this.handleResidentialChange}
              handleResidentialClick={this.handleResidentialClick}
              handleResidentialTypeChange={this.handleResidentialTypeChange}
              handleIsApplicantChange={this.handleIsApplicantChange}
              handlePostalAddressChange={this.handlePostalAddressChange}
              handlePostalAddressClick={this.handlePostalAddressClick}
              onCountryCodeChangeHandler={this.countryCodeHandleChange}
              isGuarantorApproved={isGuarantorApproved}
              setCompanyNumberInvalidError={this.setCompanyNumberInvalidError}
              subComponent={subComponent}
              dispatch={dispatch}
              entityRegion={entity_region}
              isCorporateTrusteeEnabled={isCorporateTrusteeEnabled}
              isCorporateTrusteeGuarantorEnabled={
                isCorporateTrusteeGuarantorEnabled
              }
              isCorporateTrusteeLocked={isCorporateTrusteeLocked}
              hasApplicantSignatoryDecision={hasApplicantSignatoryDecision}
            />
          </div>
        </section>
        {showGuarantorRemoveModal &&
          FEATURE_FLAGS.FEATURE_FLAG_BC_FLOW_GUARANTOR_FIX && (
            <SignatorySwitchModal
              affectedPages={['guarantors']}
              closeModal={hideShowGuarantorModal}
              handleSubmit={() => saveBusinessState(true)}
            />
          )}
        {modal.display && (
          <SignatorySwitchModal
            affectedPages={addonRuleKeys}
            closeModal={() => this.setState({ modal: { show: false } })}
            handleSubmit={() =>
              this.dispatchIsApplicantChange(modal.value, true)
            }
          />
        )}
      </Fragment>
    );
  }
}

const defaults = {
  title: 'A few details on index of total entity_type',
  title_trust: 'A few details on each trustee',
};

export default connect((state, ownProps) => {
  const index = ownProps.component_index;
  const subComponent = ownProps.subComponent;

  let total = state.cob_business.entity_party_count;

  let title = '';
  let values = {};
  let hasToBeApplicant = false;

  let corporateTrusteeId = null;

  if (index < state.cob_business.entity_party_details_values.length) {
    values = state.cob_business.entity_party_details_values[index];
  }

  if (
    values.trustee_type &&
    values.trustee_type === 'corporate' &&
    subComponent === 'director-details'
  ) {
    corporateTrusteeId = values.corporate_trustee_id;
    total = values.corporate_trustee_director_count;
    if (state.cob_business.corporateTrusteeDirectors[corporateTrusteeId]) {
      values = {
        ...state.cob_business.corporateTrusteeDirectors[corporateTrusteeId][
          ownProps.subComponentIndex
        ],
        trustee_type: 'corporate',
        corporate_trustee_id: corporateTrusteeId,
      };
    } else {
      values = {
        first_name: '',
        middle_name: '',
        last_name: '',
        trustee_type: 'corporate',
        corporate_trustee_id: corporateTrusteeId,
      };
    }
  }

  if (state.cob_business.entity_type !== null) {
    if (
      state.cob_business.entity_type === 'company' &&
      state.cob_business.entity_region === 'AU'
    ) {
      title = defaults.title.replace('entity_type', 'director/s');
    }
    if (state.cob_business.entity_type === 'partnership') {
      title = defaults.title.replace('entity_type', 'partner/s');
    }
    if (state.cob_business.entity_type === 'trust') {
      if (subComponent === 'director-details') {
        title =
          'Director details for corporate trustee (index of total director/s)';
      } else {
        title = defaults.title.replace('entity_type', 'trustee/s');
      }
    }
    if (state.cob_business.entity_type === 'personal') {
      title = defaults.title.replace('entity_type', 'individual/s');
    }
    if (
      state.cob_business.entity_type === 'sole_trader' &&
      state.cob_section.application.attributes.requires_signatories &&
      FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER
    ) {
      title = defaults.title.replace('entity_type', 'sole trader');
    }
    if (
      values.trustee_type &&
      values.trustee_type === 'corporate' &&
      subComponent === 'director-details'
    ) {
      title = title.replace('index', ownProps.subComponentIndex + 1);
    } else {
      title = title.replace('index', index + 1);
    }
    title = title.replace('total', total);
  }

  const signatoryApplicantIndex =
    state.cob_business.entity_party_details_values.findIndex(
      (person) => person.is_applicant
    );

  if (state.cob_business.entity_type === 'personal') {
    const lastPerson = index + 1 == state.cob_business.entity_party_count;
    hasToBeApplicant =
      lastPerson &&
      (signatoryApplicantIndex === -1 || signatoryApplicantIndex === index);
  }
  const canSelectSignatoryApplicant =
    requiresSignatories() &&
    (signatoryApplicantIndex === -1 || signatoryApplicantIndex === index);

  const guarantors = get(state, 'cob_guarantors.form_values', []);
  const isPersonGuarantor = getPersonGuarantorDetails(values, guarantors);

  const isCorporateTrusteeEnabled =
    FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

  const requires_guarantees = get(
    state,
    'cob_section.application.attributes.requires_guarantees',
    false
  );

  const minimum_guarantees_trust = get(
    state,
    'cob_section.application.attributes.minimum_guarantees_trust',
    0
  );
  const isCorporateTrusteeGuarantorEnabled =
    !!minimum_guarantees_trust && !!requires_guarantees;

  const signatories = get(state, 'cob_section.allAuthorisations', []);

  const hasApplicantSignatoryDecision = //Check if there's an applicant as signatory that has already agreed/declined
    isDuplicateAuthFixEnabled
      ? signatories.some((signatory) => {
          const {
            is_signatory,
            is_applicant,
            agreements = {},
          } = signatory.attributes;

          return (
            is_signatory &&
            is_applicant &&
            typeof agreements.signatory === 'boolean'
          );
        })
      : false;

  return {
    canSelectSignatoryApplicant,
    entity_region: state.cob_business.entity_region,
    entity_type: state.cob_business.entity_type,
    form_values: values,
    hasToBeApplicant,
    party_count: state.cob_business.entity_party_count || 5,
    title,
    values_count: state.cob_business.entity_party_details_values.length,
    consumerId: state.cob_section.current_entity.id,
    supplierId: get(state, 'cob_section.supplier.id', ''),
    isGuarantorApproved:
      isPersonGuarantor && FEATURE_FLAGS.FEATURE_FLAG_BC_FLOW_GUARANTOR_FIX
        ? isPersonGuarantor.approved
        : false,
    isCorporateTrusteeEnabled,
    isCorporateTrusteeGuarantorEnabled,
    hasApplicantSignatoryDecision,
  };
})(BusinessPartyDetails);
