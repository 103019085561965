import 'font-awesome/css/font-awesome.min.css';

import api from 'api';
import { EMAIL_REGEX } from 'constants';
import loginImage from 'images/login.png';
import Logo from 'images/1centre-black.png';
import Pig from 'images/svgs/pig.svg';
import Ppl from 'images/svgs/ppl.svg';
import get from 'lodash.get';
import styles from 'modules/new-applications/css/Customization.module.css';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React, { useEffect, useState } from 'react';
import { browserHistory, Link } from 'react-router';
import isPresent from 'utils/isPresent';

import { RoundedCard } from './styles';
import { FEATURE_FLAGS } from 'conf';

const LoginFormSSO = (props) => (
  <section className={styles.login_bg}>
    <section className="hero is-fullheight-with-navbar">
      <Header />
      <div className={`hero-body ${styles.adjust_header}`}>
        <div className="container is-widescreen">
          <LoginForm {...props} />
          <Footer />
        </div>
      </div>
    </section>
  </section>
);

export const Header = () => {
  if (isMobile()) {
    return (
      <div>
        <article className={`media ${styles.adjust_mobile_logo}`}>
          <figure className="media-left mr-0">
            <p className="image is-64x64 ">
              <img src={Logo}></img>
            </p>
          </figure>
          <div className="content">
            <div className="mt-2 mb-0">
              {''}
              <span className={styles.header_1centre}>1Centre</span>
              <div className={styles.header_trade}>Trade platform</div>
            </div>
          </div>
        </article>
      </div>
    );
  }
  return (
    <div>
      <article className={`media ${styles.adjust_logo}`}>
        <figure className="media-left mr-0">
          <p className="image is-64x64 ">
            <img src={Logo}></img>
          </p>
        </figure>
        <div className="content">
          <div className="mt-2 mb-0">
            {''}
            <span className={styles.header_1centre}>1Centre</span>
            <div className={styles.header_trade}>Trade platform</div>
          </div>
        </div>
      </article>
    </div>
  );
};

const Footer = () => (
  <footer className={`footer is-hidden-mobile ${styles.login_footer}`}>
    <div className={`container has-text-centered ${styles.footer_container}`}>
      <div className={styles.speech_bubble}>
        <p className={`py-2 ${styles.footer_bold}`}>
          Get in touch with us at <br />
          <a className={styles.header_a} href="mailto:support@1centre.com">
            support@1centre.com
          </a>
        </p>
      </div>
      <Ppl className={styles.ppl} />
    </div>
  </footer>
);

const LoginForm = (props) => {
  const { email_address, redirectPath, submitHandler } = props;

  const [email, setEmail] = useState(email_address || '');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [{ provider, entityID }, setSSODetails] = useState({
    entityID: '',
    provider: '',
  });
  const [visible, setVisible] = useState(false);

  let forgotPasswordPath = '/user/forgot-password';
  if (isPresent(redirectPath)) {
    forgotPasswordPath = `${forgotPasswordPath}?redirect=${redirectPath}`;
  }

  function validateEmail() {
    const isValid = email && email.toLowerCase().match(EMAIL_REGEX);

    if (isValid) {
      setError(null);
    } else {
      setError('Please enter a valid email');
    }

    return isValid;
  }

  async function checkIfEmailExists() {
    if (!validateEmail()) {
      return;
    }

    const res = await api('login_disposition').get(email);
    if (get(res.data, 'strategy') === 'password') {
      setError('');
      setSSODetails((details) => ({ ...details, provider: 'no_provider' }));
    } else if (get(res.data, 'strategy') === 'sso') {
      setError('');
      setSSODetails(() => ({
        entityID: get(res.data, 'entity_id', ''),
        provider: get(res.data, 'sso_provider', 'no_provider'),
      }));
    }
  }

  if (error === '' && provider !== 'no_provider' && !!provider) {
    browserHistory.push(
      `/user/${provider}${entityID ? `?entity_id=${entityID}` : ''}`
    );
  }

  const animateEmail = () => {
    document.getElementById('pig').classList.toggle('animate__shakeY');
  };

  const toggleEye = (e) => {
    setVisible((v) => !v);
    document
      .getElementById('icon_pwd')
      .classList.remove('fa-eye', 'fa-eye-slash');
    if (visible) {
      document.getElementById('icon_pwd').classList.add('fa-eye-slash');
    } else {
      document.getElementById('icon_pwd').classList.add('fa-eye');
    }
  };

  return (
    <React.Fragment>
      <div className="container has-text-centered ">
        {FEATURE_FLAGS.NEW_LOGIN_IMAGE ? (
          <img src={loginImage} />
        ) : (
          <Pig
            className={`animate__animated animate__shakeY mb-1 ${styles.pig}`}
            id="pig"
          />
        )}

        <div className=" has-text-centered columns pt-1">
          <div className="column container has-text-centered box mb-5 is-two-thirds-tablet is-one-quarter-desktop">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitHandler(email, password);
              }}
            >
              <div className="field px-4">
                <label className="label mt-4" htmlFor="email">
                  <strong className={styles.header_login}>Log in</strong>
                </label>
                <div className="control mt-6 has-icons-left">
                  <span className="icon is-small is-left ">
                    <i className="fas fa-envelope"></i>
                  </span>
                  <input
                    className={`input is-primary is-focused ${styles.header_email}`}
                    name="email"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) =>
                      setEmail(e.target.value.toLowerCase().trim())
                    }
                    onInput={animateEmail}
                    disabled={error === ''}
                    data-cy="login-email-text-field"
                  />
                </div>
              </div>

              {error !== '' && (
                <p
                  className={`has-text-left mt-4 has-text-weight-normal ${styles.header_error}`}
                >
                  {error}
                </p>
              )}

              {(error !== '' || error === null) && (
                <div className="field">
                  <div className="control buttons is-centered">
                    <input
                      className={`button is-info mt-6 mb-6 is-rounded ${styles.privacy}`}
                      type="submit"
                      value="Next"
                      onClick={() => checkIfEmailExists()}
                      data-cy="login-next-button"
                    />
                  </div>
                </div>
              )}

              {error === '' && provider === 'no_provider' && (
                <React.Fragment>
                  <div className="field mt-5 px-4">
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input is-primary is-focused ${styles.header_email} `}
                        name="password"
                        type={visible ? 'text' : 'password'}
                        placeholder="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        onInput={animateEmail}
                        data-cy="login-password-text-field"
                      />
                      <span className="icon is-small is-left ">
                        <i className="fas fa-lock"></i>
                      </span>
                      <span
                        className="icon is-small is-right "
                        style={{ cursor: 'pointer', pointerEvents: 'all' }}
                        onClick={(e) => toggleEye()}
                      >
                        <i id="icon_pwd" className="fa fa-eye-slash"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field mt-4">
                    <div
                      className={`control has-text-left px-4 ${styles.header_control}`}
                    >
                      <a
                        href={forgotPasswordPath}
                        className={styles.header_control}
                        data-cy="forgot-password-link"
                      >
                        Forgotten your password?
                      </a>
                    </div>
                  </div>

                  <div className="field is-vcentered">
                    <span className=" is-centered  column">
                      <input
                        className={`button is-info is-rounded ${styles.header_email}`}
                        type="submit"
                        value="Log In"
                        data-cy="login-submit-button"
                      />
                    </span>
                    <span className=" column">
                      <Link to="/" className={`  ${styles.a_button}`}>
                        Go Back
                      </Link>
                    </span>
                  </div>
                </React.Fragment>
              )}
            </form>
          </div>
        </div>
        <a
          href="https://www.1centre.com/Privacy/index.html"
          className={styles.privacy}
        >
          Privacy policy
        </a>
      </div>
    </React.Fragment>
  );
};

const SignInFailed = () => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const _t =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

    return () => clearInterval(_t);
  }, [counter]);

  if (counter === 0) {
    browserHistory.push('/user/login');
  }
  return (
    <div className="hero is-fullheight-with-navbar">
      <div className="hero-body columns is-vcentered mx-6 px-6">
        <RoundedCard className="card column is-one-third is-offset-one-third ">
          <div className="card-content has-text-centered mt-6">
            <div>
              <i className="fas fa-exclamation-circle fa-4x"></i>
            </div>
            <br />
            <div className="my-0 is-size-large">Please use another email</div>
            <br />
            <div className="my-0 is-size-normal">
              Please log in with your company email
              {/* <span className="has-text-danger">Name1@email.com </span> */}
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="my-6 is-size-normal">
              This page will be redirected in {counter} seconds, or click{' '}
              <span className="has-text-info">
                <a href="/user/login">redirect</a>
              </span>
            </div>
            {/* Errors! {loginEmail},{ssoEmail} */}
          </div>
        </RoundedCard>
      </div>
    </div>
  );
};

export { LoginFormSSO, SignInFailed };
