import React from 'react';

import CompanyIdentity from './CompanyIdentity';
import BusinessIdentity from './BusinessIdentity';

import { isCompany } from './helpers/reportHelpers';

var Identity = createClass({
  render() {
    const { data, isAllExpanded, entityType } = this.props;

    if (isCompany(entityType))
      return (
        <CompanyIdentity
          data={data}
          entityType={entityType}
          isAllExpanded={isAllExpanded}
        />
      );

    return (
      <BusinessIdentity
        data={data}
        entityType={entityType}
        isAllExpanded={isAllExpanded}
      />
    );
  },
});

export default Identity;
