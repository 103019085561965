import api from 'api';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import get from 'lodash.get';
import Table from 'modules/shared/components/table/Table';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import StatusIndicator from 'modules/new-applications/components/StatusIndicator';
import { loadOverviewStatus } from 'modules/addons/actions';
import mixpanel from 'mixpanel-browser';

const renderDotCell = ({ value }: { value: boolean }) => (
  <StatusIndicator status={value ? 'complete' : 'not_started'} />
);

const DATA_COLUMNS = [
  {
    Header: 'Tier',
    accessor: 'tier',
  },
  {
    Cell: renderDotCell,
    Header: 'Rep channel',
    accessor: 'isRepChannelActive',
  },
  {
    Cell: renderDotCell,
    Header: 'Website channel',
    accessor: 'isWebsiteButtonChannelActive',
  },
  {
    Cell: renderDotCell,
    Header: 'Location channel',
    accessor: 'isLocationChannelActive',
  },
  {
    Cell: renderDotCell,
    Header: 'Call Centre channel',
    accessor: 'isCallCentreChannelActive',
  },
];

const transformData = (attributes) => {
  return {
    isCallCentreChannelActive: attributes.call_centre_active,
    isLocationChannelActive: attributes.qr_code_active,
    isRepChannelActive: true,
    isWebsiteButtonChannelActive: attributes.website_button_active,
    tier: `${attributes.tier} - ${attributes.trading_name}`,
  };
};

const fetchSalesChannels = async ({
  accessToken,
  entityId,
  setSalesChannels,
  successCallback,
  errorCallback,
}: {
  accessToken: string;
  entityId: string;
  setSalesChannels: any;
  successCallback?: (response: any) => void;
  errorCallback?: (error: any) => void;
}) => {
  const salesChannelsAPI = api('sales_channels', accessToken);

  try {
    const response = await salesChannelsAPI.fetchOverview(entityId);

    const overviewByTiers = get(response, 'data.data', []);
    const transformedData = overviewByTiers.map((tierData) =>
      transformData(get(tierData, 'attributes', {}))
    );

    setSalesChannels(transformedData);

    if (successCallback) successCallback(response);
  } catch (error) {
    if (errorCallback) errorCallback(error);
    console.error(error);
  }
};

const SalesChannelOverview = ({
  currentUser,
  entityId,
  dispatch,
}: {
  currentUser: any;
  entityId: string;
  dispatch?: any;
}): ReactElement => {
  const [salesChannels, setSalesChannels] = useState([]);

  useEffect(() => {
    mixpanel.track('Sales channels - Sales channels overview', {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  }, [currentUser, entityId]);

  useEffect(() => {
    dispatch(loadOverviewStatus('START'));

    if (entityId) {
      (async () => {
        fetchSalesChannels({
          accessToken: currentUser.accessToken,
          entityId,
          setSalesChannels,
          successCallback: (response) =>
            dispatch(loadOverviewStatus('SUCCESS', response, entityId)),
          errorCallback: (error) => dispatch('ERROR', error),
        });
      })();
    }
  }, []);

  return (
    <SideMenuPanel>
      <div className="mb-4">
        You can overview where the sales channels are enabled in your
        organisation.
      </div>
      <PanelSection>
        <Table
          columns={DATA_COLUMNS}
          data={salesChannels}
          withPagination={false}
        />
      </PanelSection>
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');

  return {
    currentUser,
    entityId,
  };
})(SalesChannelOverview);
