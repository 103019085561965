import api from '../../api';
import { SET, SET_PAYMENT, SET_PAYMENT_CARD, RESET } from './constants';

export function set(payload) {
  return {
    type: SET,
    payload,
  };
}
export function setPayment(payload) {
  return {
    type: SET_PAYMENT,
    payload,
  };
}

export function setPaymentCard(payload) {
  return {
    type: SET_PAYMENT_CARD,
    payload,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function getCard() {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { cardLoading: true },
    });
    const entities = api(
      'entities',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    entities.getEntityCards(
      entityId,
      (result) => {
        dispatch({
          type: SET,
          payload: { cardLoading: false, cards: result.data.data },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { cardLoading: false },
        });
      }
    );
  };
}
export function addCard(attributes, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { addCardLoading: true },
    });
    const cards = api(
      'cards',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    cards.createCard(
      attributes,
      (result) => {
        dispatch(
          activateAccount(() => {
            dispatch({
              type: SET,
              payload: {
                addCardLoading: false,
                updateCard: false,
                cards: [result.data.data],
                showSuccessModal: true,
              },
              meta: {
                mixpanel: {
                  event: 'Add credit card',
                  'Entity ID': getState().current_user.current_entity.id,
                },
              },
            });
          })
        );
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { addCardLoading: false, errorAddingCard: error },
        });
      }
    );
  };
}
export function updateCard(id, attributes, callback) {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { addCardLoading: true },
    });
    const cards = api(
      'cards',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    cards.updateCard(
      id,
      attributes,
      (result) => {
        dispatch(
          activateAccount(() => {
            dispatch({
              type: SET,
              payload: {
                addCardLoading: false,
                updateCard: false,
                cards: [result.data.data],
                showSuccessModal: true,
              },
              meta: {
                mixpanel: {
                  event: 'Update credit card',
                  'Entity ID': getState().current_user.current_entity.id,
                },
              },
            });
          })
        );
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { addCardLoading: false },
        });
      }
    );
  };
}
export function activateAccount(callback) {
  return (dispatch, getState) => {
    let entities = api(
        'entities',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      id = getState().current_user.current_entity.id;

    const attributes = { subscription_state: 'active' };
    entities.updateEntity(
      id,
      attributes,
      (result) => {
        callback();
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { activatingError: error },
        });
      }
    );
  };
}
