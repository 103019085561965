const base = '2rem';

export const SPACING = {
  base,
  lg: '3rem',
  md: base,
  sm: '0.85rem',
};

export const FORM_SPACING = {
  inputPadding: '1.5rem 1rem',
  textareaPadding: '1rem',
  titleMargin: '1rem',
};

export const BORDER_RADIUS = '6px';
