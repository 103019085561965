/* Import libraries */
/* Import components */
import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

/* Import actions */
import { setValue } from '../actions';
/* Import CSS */
import styles from './css/AutoDecisioning.css';

class ExpandableSection extends React.Component {
  componentDidMount() {
    const { dispatch, account_rule_on, id } = this.props;
    if (!account_rule_on) {
      dispatch(setValue(id, false));
    }
  }

  hintToProfile = () => (
    <div className={styles.reminder}>
      This setting is disabled according your account rules, please click&nbsp;
      <Link
        className={styles.highlight}
        to="/dashboard/profile?active_tab=account_rules"
      >
        here
      </Link>
      &nbsp; to enable this setting.
    </div>
  );

  hintToAddon = () => (
    <div className={styles.reminder}>
      This setting is disabled according your addon rules, please click&nbsp;
      <Link
        className={styles.highlight}
        to="/dashboard/addons?active_tab=financials"
      >
        here
      </Link>
      &nbsp; to enable this setting.
    </div>
  );

  render() {
    const {
      loading,
      rule_set,
      selected,
      info_text,
      title,
      expand_on,
      children,
      id,
      dispatch,
      disabled,
      disabled_text,
      error,
      hide_setting_message,
      account_rule_on,
    } = this.props;
    let showError = false;

    if (['financials_check', 'guarantors', 'signatories'].includes(id)) {
      showError = !!error;
    } else {
      showError = error && typeof selected === 'undefined';
    }

    return (
      <div className={styles.expandable}>
        <div className={disabled ? styles.expandable_disabled : null}>
          <h3>{title}</h3>
          <div>
            {!account_rule_on &&
              !hide_setting_message &&
              (id === 'financials_check'
                ? this.hintToAddon()
                : this.hintToProfile())}
            {showError && <div className={styles.error}>{error}</div>}
            <div
              dangerouslySetInnerHTML={{ __html: info_text }}
              className={styles.info}
            ></div>
            <div className={styles.checkboxes}>
              <Checkbox
                key={'yes'}
                checkboxId={`yes${id}`}
                checked={selected === true}
                handleChange={(e) => {
                  dispatch(setValue(id, e.target.id === `yes${id}`));
                }}
                label={'Yes'}
                disabled={disabled || !account_rule_on}
              />
              <Checkbox
                key={'no'}
                checkboxId={`no${id}`}
                checked={selected === false || (!disabled && !account_rule_on)}
                handleChange={(e) => {
                  dispatch(setValue(id, e.target.id === `yes${id}`));
                }}
                label={'No'}
                disabled={disabled}
              />
            </div>
          </div>
          {expand_on === selected && children && <div>{children}</div>}
        </div>
      </div>
    );
  }
}

const defaults = {
  expand_on: true,
};

export default connect((state, ownProps) => {
  return {
    ...defaults,
    ...ownProps,
    children: ownProps.children,
    current_entity: state.current_user.current_entity
      ? state.current_user.current_entity.attributes
      : null,
    disabled: ownProps.disabled,
    expand_on: ownProps.expand_on || defaults.expand_on,
    id: ownProps.id,
    info_text: ownProps.info_text,
    rule_set: ownProps.rule_set,
    selected: state.auto_decisions.current_rule_set.attributes[ownProps.id],
    title: ownProps.title || '',
  };
})(ExpandableSection);
