/* eslint-disabled max-lines */

import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import React, { useContext } from 'react';

import { HeaderWrapper } from '../WatchtowerModule/styles';
import Header from '../PPSRRegistration/forms/Header';
import RapidTransfer from './RapidTransfer/RapidTransfer';

const RapidTransferEditComponent = () => {
  const { onClickBack } = useContext(DigitalOnboardingContext);

  return (
    <div className={styles.container}>
      <HeaderWrapper>
        <Header addonModuleName="rapid_transfer_module" />
        <CloseButton handleClick={onClickBack} />
      </HeaderWrapper>
      <RapidTransfer />
    </div>
  );
};

export default RapidTransferEditComponent;
