import React from 'react';

import HelpIcon from '@material-ui/icons/Help';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';

const SignatureHelpTooltip = ({ children, noMaxWidth = false }) => (
  <PopperTooltip
    placement="top"
    style={{ margin: '0px 2px -3px' }}
    noMaxWidth={noMaxWidth}
    extraComponent={children}
  >
    <HelpIcon color="primary" fontSize="small" />
  </PopperTooltip>
);

export default SignatureHelpTooltip;
