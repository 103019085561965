import api from 'api';
import get from 'lodash.get';
import ButtonGroupWrapper from 'modules/consumer-onboarding/v2/ButtonGroupWrapper';
import ColumnWrapper from 'modules/consumer-onboarding/v2/ColumnWrapper';
import ControlWrapper from 'modules/consumer-onboarding/v2/ControlWrapper';
import FormFieldWrapper from 'modules/consumer-onboarding/v2/FormFieldWrapper';
import { UserContext } from 'modules/consumer-onboarding/v2/hooks/UserContext';
import {
  AlignedWidthButtons,
  EmailPrompt,
  FormWrapper,
  MobileWrapper,
  SectionHeader,
} from 'modules/consumer-onboarding/v2/styles';
import { requestVerificationCode } from 'modules/consumer-onboarding/v2/utils/verificationCode';
import CodeField from 'modules/consumer-onboarding/v2/Verification/VerificationCode/CodeField';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement, useContext, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import isBlank from 'utils/isBlank';
import * as yup from 'yup';

type FORM_VALUES = {
  email: string;
  verificationCode: string;
};

const CODE_LENGTH = 6;
const TIMEOUT_DURATION = 3000;

const validationSchema = () =>
  yup.object().shape({
    verificationCode: yup
      .string()
      .length(CODE_LENGTH, 'Invalid verification code')
      .required('Please enter verification code'),
  });

const VerificationCode = (props): ReactElement => {
  const { applicationId, onBack, onNext, supplier } = props;
  const userState = useContext(UserContext);
  const { email, setVerifiedStatePayload } = userState;
  const [isCodeResent, setIsCodeResent] = useState(false);
  const [verificationCodeRequestHasError, setVerificationCodeRequestHasError] =
    useState(false);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    register,
    setError,
    setValue,
    trigger,
  } = useForm<FORM_VALUES>({
    defaultValues: {
      email,
      verificationCode: '',
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const verificationCodeRegister = register('verificationCode');

  const onError = () =>
    // @ts-ignore-next-line
    setError(verificationCodeRegister.name, {
      message: 'Incorrect verification code',
      type: 'custom',
    });

  const onSubmit = async (data) => {
    const { verificationCode } = data;

    const userAPI = api('users');

    try {
      const result = await userAPI.validateVerificationCode(
        email,
        verificationCode
      );

      const data = get(result, 'data', {});

      if (isBlank(data)) {
        onError();
        return;
      }

      const {
        registration_status: registrationStatus,
        registration_token: registrationToken,
        user_id: userId,
        verified,
      } = data;

      if (verified) {
        setVerifiedStatePayload({
          registrationStatus,
          registrationToken,
          userId,
        });

        onNext();
      } else {
        onError();
      }
    } catch (error) {
      console.error(error);
      onError();
    }
  };

  const onResendVerificationCode = () => {
    requestVerificationCode({
      applicationId,
      email,
      errorCallback: () => {
        setIsCodeResent(false);
        setVerificationCodeRequestHasError(true);
        setTimeout(
          () => setVerificationCodeRequestHasError(false),
          TIMEOUT_DURATION
        );
      },
      successCallback: () => {
        setVerificationCodeRequestHasError(false);
        setIsCodeResent(true);
        setTimeout(() => setIsCodeResent(false), TIMEOUT_DURATION);
      },
      supplierId: supplier.id,
    });
  };

  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );

  return (
    <ColumnWrapper>
      {isMobileScreen && (
        <MobileWrapper>
          <SectionHeader>Start your application with 1Centre</SectionHeader>
          <EmailPrompt>Please enter the code we sent to your email</EmailPrompt>
        </MobileWrapper>
      )}
      {!isMobileScreen && (
        <SectionHeader>
          We just sent you a verification code, enter below to confirm your
          email
        </SectionHeader>
      )}
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FormFieldWrapper>
          <RHFBorderedEmailField
            control={control}
            name="email"
            withBottomMargin={false}
            disabled={true}
            defaultValue={email}
            label="Email address"
          />
        </FormFieldWrapper>
        <div className="is-full-width">
          <a onClick={onResendVerificationCode} className="is-link">
            Resend code
          </a>
          {isCodeResent && (
            <span className="has-text-success ml-2">
              Verification code sent!
            </span>
          )}
          {verificationCodeRequestHasError && (
            <span className="has-text-danger ml-2">
              Something went wrong. Please try again after a few seconds.
            </span>
          )}
        </div>
        <CodeField
          clearErrors={clearErrors}
          codeLength={CODE_LENGTH}
          error={get(
            formState,
            `errors.${verificationCodeRegister.name}.message`,
            ''
          )}
          setValue={setValue}
          trigger={trigger}
          verificationCodeRegister={verificationCodeRegister}
        />
        <ButtonGroupWrapper>
          <AlignedWidthButtons>
            <ControlWrapper>
              <Button text="Confirm code" type="submit" />
            </ControlWrapper>
            <ControlWrapper>
              <Button text="Back" handleClick={onBack} white />
            </ControlWrapper>
          </AlignedWidthButtons>
        </ButtonGroupWrapper>
      </FormWrapper>
    </ColumnWrapper>
  );
};

export default VerificationCode;
