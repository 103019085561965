import styled from 'styled-components';
import { THEME_COLORS } from 'variables/theme';
import { FONT_SIZES } from 'variables/typography';

export const Description = styled.p`
  font-size: ${FONT_SIZES.title};
`;

export const FormActions = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  button {
    &:not(.is-outlined) {
      background: ${THEME_COLORS.primary};
      border-color: ${THEME_COLORS.primary};

      &:focus,
      &:hover,
      &:active {
        background: ${THEME_COLORS.primary};
        border-color: ${THEME_COLORS.primary};
      }
    }
  }
`;

export const SuccessWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
