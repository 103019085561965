import React from 'react';
import { IConflict } from '../type';
import styled from 'styled-components';
import { DialogContentText } from 'mui-latest';

const HighlightedConflictSpan = styled.span`
  color: red;
`;

interface IProps {
  ruleConflict: IConflict;
  conflictName: string;
}

export const HighlightSpan = styled.span`
  font-size: 12px;
  font-style: italic;
  font-weight: bold !important;
`;

export const StyledContentText = styled(DialogContentText)`
  &.MuiDialogContentText-root {
    font-size: 12px;
    color: black;
    margin-bottom: 6px;
  }
`;

const ConflictRow = ({ ruleConflict, conflictName }: IProps): JSX.Element => {
  const { value, conflict } = ruleConflict;
  return (
    <StyledContentText>
      <HighlightSpan>{conflictName}: </HighlightSpan>
      {value.map((origionValue, index) => {
        const valueToDisplay =
          index === value.length - 1 ? origionValue : `${origionValue}, `;
        return conflict.includes(origionValue) ? (
          <HighlightedConflictSpan>{valueToDisplay}</HighlightedConflictSpan>
        ) : (
          <>{valueToDisplay}</>
        );
      })}
    </StyledContentText>
  );
};

export default ConflictRow;
