import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React from 'react';
import { Wrapper } from './styles';
import userTypes from 'modules/shared/users/userTypes';

type handleArgs = {
  value: boolean;
  type: string;
};

interface Props {
  className: string;
  handleCheck: (args: handleArgs) => void;
  isManager: boolean;
}

const ConsumerManagerUserType = ({
  className,
  handleCheck,
  isManager,
}: Props): JSX.Element | null => {
  const consumerMangerUserType = userTypes.find(
    ({ type }) => type === 'user_type_consumer_manager'
  );

  if (!consumerMangerUserType) return null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, ...rest } = consumerMangerUserType;

  const onClick = () => handleCheck({ value: !isManager, ...rest });

  return (
    <Wrapper className={className}>
      <Checkbox
        checkboxId={consumerMangerUserType.id}
        checked={isManager}
        handleChange={onClick}
        label={consumerMangerUserType.label}
        description={consumerMangerUserType.description}
        disabled={true}
      />
    </Wrapper>
  );
};

export default ConsumerManagerUserType;
