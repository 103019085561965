import axios from './axios';

export default class QRCode {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'qr_code';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  generate(url) {
    return this.axios.post(this.path, { url });
  }
}
