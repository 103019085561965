import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loadUserApplicationsCount } from '../actions';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import Button from 'modules/shared/components/inputs/Button';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';

function RemoveUser(props) {
  const {
    dispatch,
    user,
    title,
    loading,
    total_user_applications,
    onCancel,
    onRemove,
  } = props;

  useEffect(() => {
    dispatch(loadUserApplicationsCount(user.id));
  }, []);

  const [removing, setRemoving] = useState(false);

  function remove() {
    setRemoving(true);
    onRemove(user);
  }

  function edit() {
    browserHistory.push({
      pathname: `/dashboard/users/update/${user.id}`,
    });
  }

  function render() {
    if (!user) {
      return <div />;
    }
    let content;
    if (loading) {
      content = <SimpleLoader css_class="loader_relative" />;
    } else if (total_user_applications < 1) {
      content = (
        <div>
          <Button
            text={'remove'}
            loading_text={'removing'}
            handleClick={remove}
            loading={removing}
            disableOnLoading={true}
          />
        </div>
      );
    } else {
      content = (
        <div>
          <p>
            {user.attributes.full_name} is key contact for{' '}
            {total_user_applications} credit files.
          </p>
          <p>Click 'edit user' to reallocate.</p>
          <Button text={'edit user'} handleClick={edit} />
        </div>
      );
    }

    return (
      <CircleModal title={title} dismissHandler={onCancel}>
        {content}
      </CircleModal>
    );
  }

  return render();
}

module.exports = connect((state, props) => {
  return {
    title: 'Remove user?',
    total_user_applications: state.manage_users.total_user_applications,
  };
})(RemoveUser);
