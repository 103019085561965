export const allPpsrFinancingStatements = (allVersionsParam) => {
  const allVersions = allVersionsParam || [];

  const allPpsrStatements = allVersions.reduce((ppsrToReturn, version) => {
    const ppsrsInVersion = version.ppsr_financing_statements;
    ppsrsInVersion.forEach((ppsr) => {
      const ppsrIdIndex = ppsrToReturn.findIndex(
        (returnPpsr) => returnPpsr.id === ppsr.id
      );

      if (ppsrIdIndex === -1) {
        ppsrToReturn = [...ppsrToReturn, ...buildPPSRdata(ppsr)];
      }
    });

    return ppsrToReturn;
  }, []);
  return allPpsrStatements;
};

export const buildRelationshipStructure = (
  data,
  type,
  noAttributes = false
) => {
  if (Array.isArray(data)) {
    return data.map((currData) => buildRelationshipStructure(currData, type));
  }
  const { id, ...attributes } = data;
  const returnData = {
    id,
    type,
  };

  const modifyAttribute = {
    ppsr_financing_statements: [
      'raw_data',
      'error_log',
      'etag',
      'created_by_id',
      'expiry_duration_custom_years',
    ],
  };

  if (modifyAttribute[type]) {
    const keysToRemove = modifyAttribute[type];
    keysToRemove.forEach((key) => delete attributes[key]);
    attributes.application_id = '2464f7de-b422-4a9c-8787-a2c7a8efeae3';
  }

  Object.keys(attributes).forEach((key) => {
    const excludedKeys = ['registration_reference'];
    if (attributes[key] === null && !excludedKeys.includes(key)) {
      delete attributes[key];
    }
  });

  return noAttributes ? returnData : { ...returnData, attributes };
};

const buildPpsrDebtorStructure = (data) => {
  return data.map((ppsrDebtor) => {
    const { debtor_address, ...debtorData } = ppsrDebtor;

    return {
      ...buildRelationshipStructure(
        debtorData,
        'ppsr_financing_statement_debtors'
      ),
      ...(debtor_address
        ? {
            relationships: {
              debtor_address: {
                data: buildRelationshipStructure(
                  debtor_address,
                  'addresses',
                  true
                ),
              },
            },
          }
        : {}),
    };
  });
};

const buildPPSRdata = (ppsr) => {
  const {
    ppsr_financing_statement_collaterals,
    ppsr_financing_statement_debtors,
    ppsr_financing_statement_histories,
    ...rawPpsrData
  } = ppsr;

  const ppsrDebtorWithAddress = ppsr_financing_statement_debtors.filter(
    (ppsrDebtorData) => !!ppsrDebtorData.debtor_address
  );
  const debtorAddresses = ppsrDebtorWithAddress.map(
    (ppsrDebtorData) => ppsrDebtorData.debtor_address
  );

  const includedData = [
    ...buildRelationshipStructure(
      ppsr_financing_statement_collaterals,
      'ppsr_financing_statement_collaterals'
    ),
    ...(debtorAddresses.length
      ? buildRelationshipStructure(debtorAddresses, 'addresses')
      : []),

    ...buildPpsrDebtorStructure(ppsr_financing_statement_debtors),

    ...buildRelationshipStructure(
      ppsr_financing_statement_histories,
      'ppsr_financing_statement_histories'
    ),
  ];

  const relationshipsData = {
    ppsr_financing_statement_collaterals: {
      data: buildRelationshipStructure(
        ppsr_financing_statement_collaterals,
        'ppsr_financing_statement_collaterals',
        true
      ),
    },
    ppsr_financing_statement_debtors: {
      data: buildRelationshipStructure(
        ppsr_financing_statement_debtors,
        'ppsr_financing_statement_debtors',
        true
      ),
    },
    ppsr_financing_statement_histories: {
      data: buildRelationshipStructure(
        ppsr_financing_statement_histories,
        'ppsr_financing_statement_histories',
        true
      ),
    },
  };

  const ppsrData = {
    ...buildRelationshipStructure(
      { ...rawPpsrData },
      'ppsr_financing_statements'
    ),
    relationships: relationshipsData,
  };

  return [...includedData, ppsrData];
};
