import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React, { ReactElement } from 'react';

type handleArgs = {
  value: boolean;
};

interface Props {
  handleCheck: (args: handleArgs) => void;
  isChecked: boolean;
  userType: string;
}

function CreditChecks({ handleCheck, isChecked }: Props): ReactElement {
  const item = {
    description: `
      By selecting, the user will be able to view credit checks in the Virtual credit file.\
      All users except for approver users will default to 'cannot' view.
    `,
    id: 'can_review_credit_checks',
    label: 'Credit checks',
    type: 'page_permissions',
  };

  const onClick = () => handleCheck({ value: !isChecked, ...item });

  return (
    <Checkbox
      checkboxId="can_review_credit_checks"
      checked={isChecked}
      handleChange={onClick}
      label={item.label}
      description={item.description}
    />
  );
}

export default CreditChecks;
