const LOCAL_STORAGE_KEY = 'reporting-filters-';

export function loadLocalStorage(entityId) {
  return (
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_KEY}${entityId}`)) || {}
  );
}

export function loadFilterValues(key, entityId) {
  const localStorageData = loadLocalStorage(entityId);

  return localStorageData[key];
}

function setLocalStorage(key, value, entityId) {
  const localStorageData = loadLocalStorage(entityId);

  const updatedLocalStorageData = {
    ...localStorageData,
    [key]: value,
  };

  localStorage.setItem(
    `${LOCAL_STORAGE_KEY}${entityId}`,
    JSON.stringify(updatedLocalStorageData)
  );
}

function removeLocalStorage(entityId) {
  localStorage.removeItem(`${LOCAL_STORAGE_KEY}${entityId}`);
}

export function set(key, value, entityId) {
  setLocalStorage(key, value, entityId);
}

export function remove(entityId) {
  removeLocalStorage(entityId);
}
