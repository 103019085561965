import api from 'api';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import { formatLocalTime } from 'utils/dateFormatter';
import isPresent from 'utils/isPresent';

export const APPLICATION_NOTE_CATEGORIES = [
  { label: 'Customer', value: 'customer' },
  { label: 'Alert', value: 'alert' },
  { label: 'Review date', value: 'review_date' },
  { label: 'Reallocate/Reassign', value: 'reallocate_reassign' },
  { label: 'Archive', value: 'archive' },
];

export const LEAD_NOTE_CATEGORIES = [
  { label: 'Customer', value: 'customer' },
  { label: 'Archive', value: 'archive' },
];

const NOTABLE_TYPE = {
  applications: 'Application',
  leads: 'Lead',
  module_cardholder_application: 'ModuleCardholderApplication',
};

export default class NoteModel extends BaseModel {
  static createNote({
    accessToken,
    attributes,
    entityId,
    notableId,
    notableType,
    onSuccessCallback,
  }) {
    const notesAPI = api('notes', accessToken, entityId);
    const onError = (error) => console.error(error);

    notesAPI.createNote(
      notableId,
      attributes,
      notableType,
      onSuccessCallback,
      onError
    );
  }

  static updateNote({
    accessToken,
    attributes,
    entityId,
    note,
    onSuccessCallback,
  }) {
    const notesAPI = api('notes', accessToken, entityId);
    const onError = (error) => console.error(error);

    notesAPI.updateNote(note.id, attributes, onSuccessCallback, onError);
  }

  get formattedApplicationCategory() {
    return this.formatCategory(APPLICATION_NOTE_CATEGORIES, this.category);
  }

  get formattedLeadCategory() {
    return this.formatCategory(LEAD_NOTE_CATEGORIES, this.category);
  }

  get formattedCreatedAt() {
    return formatLocalTime(this.createdAt, 'minute');
  }

  get formattedUpdatedAt() {
    return formatLocalTime(this.updatedAt, 'minute');
  }

  save({ attributes, currentUser, notable, onSuccessCallback }) {
    this.isLoading = true;
    this.setAttributes(attributes);

    const accessToken = currentUser.accessToken;
    const entityId = get(currentUser, 'currentEntity.id');

    const successCallback = (result) => {
      const newNote = new NoteModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
      const index = notable.notes.findIndex(
        (note) => note.objectId === this.objectId
      );

      notable.addNote(newNote, index);

      this.isLoading = false;
      onSuccessCallback(newNote);
    };

    // eslint-disable-next-line camelcase
    const { category, file, name, parent_id, text } = this.attributes;
    const whitelistedAttributes = {
      category,
      file,
      name,
      parent_id,
      text,
    };

    if (isPresent(this.id)) {
      NoteModel.updateNote({
        accessToken,
        attributes: whitelistedAttributes,
        entityId,
        note: this,
        onSuccessCallback: successCallback,
      });
    } else {
      NoteModel.createNote({
        accessToken,
        attributes: whitelistedAttributes,
        entityId,
        notableId: notable.id,
        notableType: NOTABLE_TYPE[notable.modelType],
        onSuccessCallback: successCallback,
      });
    }
  }

  /** Private functions */
  formatCategory(categories, category) {
    return (categories.find((c) => c.value === category) || {}).label;
  }
}
