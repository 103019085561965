export const getAuthenticityOutcome = (authenticityScore: any, minPassScore: number) => {
  const authScore = parseFloat(authenticityScore || 0);

  if (authScore >= minPassScore) {
    return 'Pass';
  }

  if (authScore >= 0.5 && authScore < minPassScore) {
    return 'Warning';
  }

  //authScore < 0.5
  return 'Fail';
};

export const getAuthenticityScoreDetails = (authenticityScore: any, minPassScore: number) => {
  const authScore = parseFloat(authenticityScore || 0);

  if (authScore >= minPassScore) {
    return 'Good quality image and no signs of tampering.';
  }
  if (authScore >= 0.5 && authScore < minPassScore) {
    return 'Poor quality image and/or signs of document tampering.';
  }

  //authScore < 0.5
  return 'Document is fraudulent, unsupported or of very poor quality.';
};
