import api from 'api';
import {
  USER_GET_TOKEN_BEGIN,
  USER_GET_TOKEN_ERROR,
  USER_GET_TOKEN_SUCCESS,
} from 'modules/user/constants';
import store from 'stores/store';

import StorageService from '../storage/StorageService';
import { SET_TOKEN } from './constants';

export function setToken(jwt: string) {
  return (dispatch) => {
    dispatch({ payload: jwt, type: SET_TOKEN });
  };
}

export function getToken() {
  const state = store.getState();
  const dispatch = store.dispatch;
  const { jwt } = state.auth;
  if (jwt) {
    return jwt;
  }

  const authApi = api('auth');

  dispatch({ type: USER_GET_TOKEN_BEGIN });

  return authApi
    .currentToken()
    .then((response) => {
      const token = response.data;
      setToken(token);
      dispatch({ type: USER_GET_TOKEN_SUCCESS });
      return token;
    })
    .catch((e) => {
      // console.log(e);
      // dispatch({ type: USER_GET_TOKEN_ERROR });
    });
}

export function setSudoToken(sudoToken: string): void {
  const storage = new StorageService('session');
  storage.set('sudoToken', sudoToken);
}

export function getSudoToken(): string | null {
  const storage = new StorageService('session');
  return storage.get('sudoToken');
}
