import { useState } from 'react';

export const useSignatureStyle = () => {
  const [signatureFont, setSignatureFont] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);

  const setSignatureStyle = (type, value) => {
    if (type === 'font') {
      setSignatureFont(value);
      setSignatureImage(null);
    } else {
      setSignatureFont(null);
      setSignatureImage(value);
    }
  };

  return {
    signatureFont,
    signatureImage,
    setSignatureStyle,
  };
};
