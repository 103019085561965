import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

function isAtLeastOneFieldEmpty(fields) {
  const emptyIndex = fields.findIndex((value) => isBlank(value));

  return emptyIndex >= 0;
}

function isFieldValid(value, dependentFields) {
  if (isPresent(value)) {
    return true;
  }

  return !isAtLeastOneFieldEmpty(dependentFields);
}

function validateSPGDetails(value) {
  const { spgId, spgPwd } = this.parent;

  return isFieldValid(value, [spgId, spgPwd]);
}

export function validateSPGCredentials(value) {
  const {
    organisationAddress,
    organisationEmail,
    organisationName,
    organisationPhone,
    personFirstName,
    personLastName,
  } = this.parent;

  const res = isFieldValid(value, [
    organisationAddress,
    organisationEmail,
    organisationName,
    organisationPhone,
    personFirstName,
    personLastName,
  ]);

  return res;
}

function validateCollateralPresets(value) {
  const collateralValues = Object.values(value);
  const activeIndex = collateralValues.findIndex(
    (collateralValue) => collateralValue.active
  );

  return activeIndex >= 0;
}

export const COMMON_VALIDATION_SCHEMA = {
  collateralPreset: yup
    .object()
    .test(
      'collateral-preset',
      'Please select at least one collateral.',
      validateCollateralPresets
    ),
  organisationAddress: yup
    .object()
    .test(
      'organisation-address',
      'Please select organisation address',
      validateSPGDetails
    ),
  organisationEmail: yup
    .string()
    .email('Please enter correct email')
    .test(
      'organisation-email',
      'Please enter organisation email',
      validateSPGDetails
    ),
  organisationName: yup
    .string()
    .test(
      'organisation-name',
      'Please enter organisation name',
      validateSPGDetails
    ),
  organisationPhone: yup
    .string()
    .test(
      'organisation-phone',
      'Please enter organisation phone',
      validateSPGDetails
    ),
  personFirstName: yup
    .string()
    .test('person-first-name', 'Please enter first name', validateSPGDetails),
  personLastName: yup
    .string()
    .test('person-last-name', 'Please enter last name', validateSPGDetails),
  spgId: yup
    .string()
    .trim()
    .test('spg-id', 'Please enter SPG ID', validateSPGCredentials),
  spgPwd: yup
    .string()
    .trim()
    .test('spg-pwd', 'Please enter password', validateSPGCredentials),
};
