import { useState, useEffect } from 'react';
// @ts-ignore
import { withRouter, browserHistory } from 'react-router';
import { validateEnumValue } from 'utils/validateEnumValue';
import { IAutoDecision, IndicatorStatus, TabIds } from '../type';

interface ITabState {
  label: string;
  status?: IndicatorStatus;
  value: TabIds;
  statusKey: string;
}

const initialTabs: ITabState[] = [
  {
    label: 'General details',
    value: TabIds.general,
    statusKey: 'general_details_status',
  },
  {
    label: 'Validation',
    value: TabIds.validation,
    statusKey: 'validation_status',
  },
  {
    label: 'Credit checks',
    value: TabIds.credit,
    statusKey: 'credit_checks_status',
  },
  {
    label: 'Additions',
    value: TabIds.additions,
    statusKey: 'additions_status',
  },
  {
    label: 'Financials',
    value: TabIds.financials,
    statusKey: 'financials_status',
  },
];

export const useTabs = (currentRuleset: IAutoDecision, location: Location) => {
  const { id, attributes } = currentRuleset;

  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get('step');
  let activeTab = TabIds.general;
  if (tabParam) {
    activeTab = validateEnumValue(tabParam, TabIds) || TabIds.general;
  }

  const [tabs, setTabs] = useState<ITabState[]>(initialTabs);

  useEffect(() => {
    const tabs = initialTabs.map((tab) => ({
      ...tab,
      status: attributes[tab.statusKey] as IndicatorStatus,
    }));
    setTabs(tabs);
  }, [currentRuleset]);

  const navigateToTab = (tab: TabIds) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('step', tab);
    newSearchParams.delete('id');
    const url = `${location.pathname}?${newSearchParams.toString()}`;
    browserHistory.push(url);
  };

  const navigateToValidationsTab = () => navigateToTab(TabIds.validation);
  const navigateToCreditCheckTab = () => navigateToTab(TabIds.credit);
  const navigateToAdditionsTab = () => navigateToTab(TabIds.additions);
  const navigateToFinancialsTab = () => navigateToTab(TabIds.financials);

  let navigateToNextTab = () => {};
  switch (activeTab) {
    case TabIds.general: {
      navigateToNextTab = navigateToValidationsTab;
      break;
    }
    case TabIds.validation: {
      navigateToNextTab = navigateToCreditCheckTab;
      break;
    }
    case TabIds.credit: {
      navigateToNextTab = navigateToAdditionsTab;
      break;
    }
    case TabIds.additions: {
      navigateToNextTab = navigateToFinancialsTab;
      break;
    }
  }

  useEffect(() => {
    navigateToTab(TabIds.general);
  }, [id]);

  const onTabChange = (_, newTabValue: TabIds) => {
    navigateToTab(newTabValue);
  };

  return {
    tabs,
    activeTab,
    onTabChange,
    navigateToValidationsTab,
    navigateToCreditCheckTab,
    navigateToAdditionsTab,
    navigateToFinancialsTab,
    navigateToNextTab,
  };
};
