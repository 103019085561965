/* Import libraries */
/* Import components */
import Checkbox from 'modules/shared/components/inputs/Checkbox';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React, { useState } from 'react';

import { List, Title } from './styles';

function UserRolePermissions(props) {
  const {
    selected_value,
    component,
    children,
    handleChange,
    permissionTip,
  } = props;

  const [isTooltipActive, setIsTooltipActive] = useState(false);

  function showTooltip() {
    setIsTooltipActive(true);
  }

  function hideTooltip() {
    setIsTooltipActive(false);
  }

  const options: React.ReactNode[] = [];

  component.options.forEach((item, index) => {
    let tool_tip;
    let selected = false;
    if (item.value === selected_value) {
      selected = true;
    }

    if (isTooltipActive && !isMobile() && permissionTip) {
      tool_tip = (
        <ToolTip css_style="mandatory_check" tip_name="MandatoryCheck" />
      );
    }

    options.push(
      <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        <Checkbox
          key={index}
          checkboxId={item.id}
          checked={selected}
          handleChange={() => handleChange(item)}
          label={item.label}
          description={item.description}
        />
        {tool_tip}
      </div>
    );
  });

  return (
    <div>
      <Title>{component.default_title}</Title>
      <List>
        {options}
        {children}
      </List>
    </div>
  );
}

export default UserRolePermissions;
