import { CARDHOLDER_DEFAULT_FIELDS } from 'modules/consumer-onboarding/reducers/cards';
import EmailInput from 'modules/shared/components/inputs/EmailInput';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import TextInput from 'modules/shared/components/inputs/TextInput';
import ConsumerFromRender from 'modules/shared/components/widgets/interactive/form_builder/ConsumerFromRender';
import PersonAutoSuggest from 'modules/shared/components/widgets/interactive/PersonAutoSuggest';
import React, { Component, Fragment } from 'react';
import { formatMoney } from 'utils/formatting';
import getIsThisYouLabel from 'utils/getIsThisYouLabel';
import isPresent from 'utils/isPresent';

import styles from './css/Cards';

export default class CardholderDetailsForm extends Component {
  /**
   * Disable first name, last name and email fields if the guarantor is also the
   * applicant and the cardholder is also an applicant to make sure that the
   * details are all the same
   */
  get isFieldDisabled() {
    const { cardholder, guarantorApplicant, signatoryApplicant } = this.props;
    const applicant = guarantorApplicant || signatoryApplicant;

    return (
      isPresent(applicant) &&
      cardholder.attributes.isApplicant &&
      isPresent(cardholder.attributes.firstName) &&
      isPresent(cardholder.attributes.lastName) &&
      isPresent(cardholder.attributes.email)
    );
  }

  /**
   * Disable first name and last name fields
   * if the cardholder is also an applicant to make sure that
   * the details are all the same in final review page in BC flow
   */
  get isFieldDisabledInFinalReview() {
    const { cardholder, isFinalReview } = this.props;

    return cardholder.attributes.isApplicant && isFinalReview;
  }

  get autoSuggestList() {
    const { personAutoSuggestList } = this.props;

    return personAutoSuggestList.filter(
      (person) => !this.isNameExcludedFromList(person)
    );
  }

  /**
   * Excluded existing cardholders from autosuggestion list
   */
  get excludedFromAutoSuggestList() {
    const { cardholders, index } = this.props;

    return cardholders.filter((_, i) => i !== index);
  }

  isNameExcludedFromList(person) {
    const excludedNames = this.excludedFromAutoSuggestList;
    const index = excludedNames.findIndex((name) => {
      const attributes = name.attributes;

      return (
        person.firstName === attributes.firstName &&
        person.lastName === attributes.lastName &&
        person.middleName === attributes.middleName
      );
    });

    return index > -1;
  }

  onHandleChangeValue(key, event) {
    this.props.onHandleChangeValue(key, event.target.value);
  }

  onHandleChangeIsApplicant(event) {
    const { onHandleChangeValue } = this.props;
    onHandleChangeValue('isApplicant', event.target.value === 'Yes');
  }

  onHandleBlur(key, event) {
    this.props.onHandleBlur(key, event.target.value);
  }

  onHandleChangeCardLimit(event) {
    const value = event.target.value;
    const processedValue = parseInt(
      value.toString().replace(/[^0-9]/g, '') || 0,
      10
    );

    this.props.onHandleChangeValue('cardLimit', processedValue);
  }

  renderFormBuilder() {
    const {
      cardholder,
      components,
      index,
      onHandleChangeAddonAnswer,
      pageValidationStart,
      triggerValidation,
    } = this.props;

    return (
      <ConsumerFromRender
        answers={cardholder.attributes.answers}
        components={components}
        pageIndex={index}
        reduxKey={`cardholder_${index}`}
        validationTrigger={triggerValidation || pageValidationStart}
        onChange={onHandleChangeAddonAnswer}
      />
    );
  }

  isApplicantField() {
    const {
      cardholder,
      cardholderApplicant,
      guarantorApplicant,
      signatoryApplicant,
      isFinalReview,
    } = this.props;

    if (
      isPresent(cardholderApplicant) &&
      isPresent(guarantorApplicant || signatoryApplicant)
    ) {
      return;
    }

    if (isFinalReview) return;

    const attributes = cardholder.attributes || {};
    const { firstName, lastName } = attributes;

    const isThisYouLabel = getIsThisYouLabel({ firstName, lastName });

    return (
      <div className={styles.full_width}>
        <div className={styles.is_this_you_container}>
          <div className={styles.full_width}>
            <Radiobox
              error=""
              id="isApplicant"
              name="isApplicant"
              label={isThisYouLabel}
              radioList={['Yes', 'No']}
              value={attributes.isApplicant ? 'Yes' : 'No'}
              handleChange={this.onHandleChangeIsApplicant.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  signatureFields() {
    const {
      cardholder,
      formErrors,
      onHandleChangeEmail,
      triggerValidation,
    } = this.props;
    const attributes = cardholder.attributes;

    return (
      <Fragment>
        <div className="column is-12-mobile is-4-tablet">
          <PersonAutoSuggest
            disabled={this.isFieldDisabled || this.isFieldDisabledInFinalReview}
            error={formErrors.firstName}
            id="firstName"
            label="Legal first name"
            name="firstName"
            autosuggestItems={this.autoSuggestList}
            required={true}
            value={attributes.firstName}
            onSelectSuggestion={this.onHandleSelectSuggestion}
            onBlur={this.onHandleFirstNameBlur}
            onChange={this.onHandleFirstNameChange}
          />
        </div>

        <div className="column is-12-mobile is-4-tablet">
          <TextInput
            disabled={this.isFieldDisabled || this.isFieldDisabledInFinalReview}
            error={formErrors.middleName}
            id="middleName"
            name="middleName"
            label="Middle name"
            required={false}
            value={attributes.middleName}
            handleBlur={this.onHandleBlur.bind(this, 'middleName')}
            handleChange={this.onHandleChangeValue.bind(this, 'middleName')}
          />
        </div>
        <div className="column is-12-mobile is-4-tablet">
          <TextInput
            disabled={this.isFieldDisabled || this.isFieldDisabledInFinalReview}
            error={formErrors.lastName}
            id="lastName"
            name="lastName"
            label="Last name"
            required={true}
            value={attributes.lastName}
            handleBlur={this.onHandleBlur.bind(this, 'lastName')}
            handleChange={this.onHandleChangeValue.bind(this, 'lastName')}
          />
        </div>

        <div className="column is-12-mobile is-6-tablet">
          <EmailInput
            customError={formErrors.email}
            disabled={this.isFieldDisabled}
            id="email"
            label="Email"
            name="email"
            required={true}
            triggerValidation={triggerValidation}
            type="email"
            value={attributes.email}
            handleBlur={onHandleChangeEmail}
            handleChange={onHandleChangeEmail}
          />
        </div>
        <div className="column is-12-mobile is-6-tablet">
          <TextInput
            error={formErrors.mobile}
            id="mobile"
            name="mobile"
            label="Phone/Mobile number"
            required={true}
            type="tel"
            value={attributes.mobile}
            handleBlur={this.onHandleBlur.bind(this, 'mobile')}
            handleChange={this.onHandleChangeValue.bind(this, 'mobile')}
          />
        </div>
      </Fragment>
    );
  }

  noSignatureFields() {
    const { cardholder, formErrors } = this.props;
    const attributes = cardholder.attributes;

    return (
      <div className="column is-12-mobile is-6-tablet">
        <TextInput
          error={formErrors.firstName}
          id="firstName"
          name="firstName"
          label="Name to appear on card"
          value={attributes.firstName}
          handleBlur={this.onHandleBlur.bind(this, 'firstName')}
          handleChange={this.onHandleChangeValue.bind(this, 'firstName')}
          required
        />
      </div>
    );
  }

  onHandleFirstNameBlur = (event) => {
    this.onHandleBlur('firstName', event);
  };

  onHandleFirstNameChange = (event) => {
    this.onHandleChangeValue('firstName', event);
  };

  onHandleSelectSuggestion = (suggestion) => {
    this.props.onHandleSelectSuggestion(suggestion);
  };

  render() {
    const { cardholder, config, formErrors } = this.props;
    const attributes = cardholder.attributes;
    const { cardLimitEnabled, signatureRequired } = config || {};
    const { cardLimit } = attributes;

    return (
      <div>
        <div className="columns is-multiline">
          {signatureRequired
            ? this.signatureFields()
            : this.noSignatureFields()}
          {cardLimitEnabled && (
            <div className="column is-12-mobile is-6-tablet">
              <TextInput
                error={formErrors.cardLimit}
                id="cardLimit"
                name="cardLimit"
                label="Card Limit"
                value={formatMoney(parseInt(cardLimit) || 0)}
                handleBlur={this.onHandleBlur.bind(this, 'cardLimit')}
                handleChange={this.onHandleChangeCardLimit.bind(this)}
                required
              />
            </div>
          )}
          <div className="column is-12">{this.renderFormBuilder()}</div>
        </div>
        {/* <div className={styles.row}>{this.isApplicantField()}</div> */}
      </div>
    );
  }
}

CardholderDetailsForm.defaultProps = {
  formErrors: {},
  index: 0,
  pageValidationStart: false,
  personAutoSuggestList: [],
  triggerValidation: false,
};
