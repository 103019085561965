import Button from 'modules/shared/components/inputs/Button';
import DateString from 'modules/shared/components/widgets/static/DateString';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';
import { isMobileOnly } from 'modules/shared/helpers/mobileOnlyDetect';
import moment from 'moment';
import styles from './css/NotesTable.css';

var NotesTable = createClass({
  edit: function(id) {
    const { handleEdit } = this.props;
    handleEdit(id);
  },

  expand: function(i, j) {
    const { location } = this.props;
    let { openRow, expandRow } = this.props;
    if (openRow == i && j == null) {
      openRow = null;
    } else {
      openRow = i;
    }

    if (expandRow == j) {
      expandRow = null;
    } else {
      expandRow = j;
    }

    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, {
        open_note: openRow,
        expand_note: expandRow,
      }),
    });
  },

  sort: function(data) {
    const { startDate, endDate } = this.props;
    // make notes in desc order
    data = data.sort(function(noteA, noteB) {
      var dateA = new Date(noteA.attributes.created_at),
        dateB = new Date(noteB.attributes.created_at);
      return dateB - dateA;
    });

    var ID_KEY = 'id';
    var CHILDREN_KEY = 'children';
    var tree = [],
      childrenOf = {};
    var item, id, parentId;

    for (var i = 0, length = data.length; i < length; i++) {
      item = data[i];
      id = item[ID_KEY];
      parentId = item.attributes.parent_id || 0;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId != 0) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }

    var filteredData = (function() {
      if (startDate != undefined && endDate != undefined) {
        return tree.filter(
          (v) =>
            moment(v.attributes.created_at) >= startDate &&
            moment(v.attributes.created_at) <= endDate
        );
      }
      if (startDate != undefined) {
        return tree.filter((v) => moment(v.attributes.created_at) >= startDate);
      }
      if (endDate != undefined) {
        return tree.filter((v) => moment(v.attributes.created_at) <= endDate);
      }
      return tree;
    })();
    return filteredData;
  },

  renderEditButton(rowData) {
    const { isEditButtonHidden } = this.props;
    const isHidden = isBlank(isEditButtonHidden) ? false : isEditButtonHidden;

    if (!isHidden) {
      return (
        <Button
          grey={true}
          small={true}
          text="Edit"
          handleClick={this.edit.bind(null, rowData.id)}
        />
      );
    }
  },

  render: function() {
    const { data, config, openRow, expandRow, ...rest } = this.props;
    let actions = {
      edit: this.edit,
    };
    let sortedData = this.sort(data),
      rows = [];

    sortedData.forEach((rowData, i) => {
      const tds = [];
      const editButton = this.renderEditButton(rowData);

      tds.push(<td key="0">{rowData.attributes.name}</td>);
      tds.push(<td key="1" className={styles.attachment}></td>);
      tds.push(<td key="2">{rowData.attributes.created_at_formatted}</td>);
      tds.push(<td key="3"></td>);
      tds.push(<td key="4">{rowData.attributes.created_by_full_name}</td>);
      tds.push(<td key="5">{isMobileOnly() ? null : editButton}</td>);

      rows.push(
        <tr
          key={i}
          className={
            openRow == i
              ? [styles.expandable, styles.expanded].join(' ')
              : styles.expandable
          }
          onClick={this.expand.bind(null, i, null)}
        >
          {tds}
        </tr>
      );

      if (isMobileOnly()) {
        rows.push(
          <tr key={`${i}-btn`}>
            <td key="5" colSpan="6">
              {editBUtton}
            </td>
          </tr>
        );
      }
      if (openRow == i) {
        let children = rowData.children,
          childrenRows = [],
          childrentds = [];
        if (children.length > 0) {
          children.forEach((childData, j) => {
            let current_row_text = childData.attributes.text || '';
            childrenRows.push(
              <tr
                key={`expand-${i}${j + 1}`}
                className={
                  expandRow == j + 1
                    ? [styles.expandable_grey, styles.expanded].join(' ')
                    : styles.expandable_grey
                }
                onClick={this.expand.bind(null, i, j + 1)}
              >
                <td key={`0-${j + 1}`} className={styles.multiline_text}>
                  {expandRow == j + 1
                    ? current_row_text
                    : current_row_text.substring(0, 20)}
                </td>
                <td key={`1-${j + 1}`} className={styles.attachment}>
                  {childData.attributes.filename ? (
                    <a
                      className={styles.downfile}
                      href={
                        extractAttachmentUrl(childData.attributes, 'file').url
                      }
                      target="_blank"
                    ></a>
                  ) : (
                    ''
                  )}
                </td>
                <td key={`2-${j + 1}`}></td>
                <td key={`3-${j + 1}`}>
                  {childData.attributes.created_at_formatted}
                </td>
                <td key={`4-${j + 1}`}>
                  {childData.attributes.created_by_full_name}
                </td>
                <td key={`5-${j + 1}`}></td>
              </tr>
            );
          });
        }
        let last_row_text = rowData.attributes.text || '';
        childrenRows.push(
          <tr
            key={`expand-${i}0`}
            className={
              expandRow == 0
                ? [styles.expandable_grey, styles.expanded].join(' ')
                : styles.expandable_grey
            }
            onClick={this.expand.bind(null, i, 0)}
          >
            <td key={`0-0`} className={styles.multiline_text}>
              {expandRow == 0 ? last_row_text : last_row_text.substring(0, 20)}
            </td>
            <td key={`1-0`} className={styles.attachment}>
              {rowData.attributes.filename ? (
                <a
                  className={styles.downfile}
                  href={extractAttachmentUrl(rowData.attributes, 'file').url}
                  target="_blank"
                ></a>
              ) : (
                ''
              )}
            </td>
            <td key={`2-0`}></td>
            <td key={`3-0`}>{rowData.attributes.created_at_formatted}</td>
            <td key={`4-0`}>{rowData.attributes.created_by_full_name}</td>
            <td key={`5-0`}></td>
          </tr>
        );
        rows.push(
          <tr key={`expand-${i}`}>
            <td colSpan="6" className={styles.details}>
              <table className={styles.detailsTable}>
                <tbody>{childrenRows}</tbody>
              </table>
            </td>
          </tr>
        );
      }
    });
    return (
      <table className={styles.notesTable}>
        <thead>
          <tr>
            <th>Note</th>
            <th></th>
            <th>Created</th>
            <th>Modified</th>
            <th>Team member</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  },
});

module.exports = connect((state, ownProps) => {
  let data = ownProps.data;

  // clone data and format dates
  let formatted_data = JSON.parse(JSON.stringify(data));
  formatted_data = formatted_data.map((note, index) => {
    note.attributes.updated_at_formatted = (
      <DateString
        showNull={true}
        format={'D/M/YY HH:mm'}
        value={note.attributes.updated_at}
      />
    );

    note.attributes.created_at_formatted = (
      <DateString
        showNull={true}
        format={'D/M/YY HH:mm'}
        value={note.attributes.created_at}
      />
    );
    note.index = index;

    return note;
  });

  return {
    location: ownProps.location,
    data: formatted_data,
    openRow: ownProps.location.query.open_note || null,
    expandRow: ownProps.location.query.expand_note || null,
  };
})(NotesTable);
