import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import moment from 'moment';
import React, { ReactElement } from 'react';

type CollateralType = {
  code: string;
  description: string;
};

type SecuredParty = {
  securedPartyName: string;
  securedPartyType: string;
};

type PPSRFinanceStatement = {
  financingStatementKey: {
    registrationNumber: string;
  };
  status: string;
  registrationDate: string;
  expiryDate: string;
  securedParties: SecuredParty[];
  collateralTypes: CollateralType[];
};

type Props = {
  data: PPSRFinanceStatement[];
};

function PPSRFinanceStatement(props: Props): ReactElement | null {
  const { data } = props;

  const parseDate = (date) => moment(date).format('DD-MMM-YYYY');

  const financeStatement = () =>
    data.map(
      (
        {
          collateralTypes,
          financingStatementKey,
          registrationDate,
          securedParties,
        },
        index
      ) => (
        <tr key={index}>
          <td>{financingStatementKey.registrationNumber}</td>
          <td>{parseDate(registrationDate)}</td>
          <td>
            {securedParties.map(({ securedPartyName }) => securedPartyName)}
          </td>
          <td>{collateralTypes.map(({ description }) => description)}</td>
        </tr>
      )
    );

  if (data.length === 0) {
    return null;
  }

  return (
    <div className={historyStyles.container}>
      <SubHeading>PPSR Financing Statements</SubHeading>
      <table className={styles.table_w20}>
        <thead>
          <tr>
            <th>Number</th>
            <th>Date</th>
            <th>Secured Party</th>
            <th>Collateral</th>
          </tr>
        </thead>
        <tbody>{financeStatement()}</tbody>
      </table>
    </div>
  );
}

export default PPSRFinanceStatement;
