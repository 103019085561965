import { get } from 'lodash';
import AntiFraudAddonVersionModel from 'models/AntiFraudAddonVersionModel';
import ApplicationModel from 'models/ApplicationModel';
import IdentityCapture from 'modules/identity/components/IdentityCapture/IdentityCapture';
import PageHeader from 'modules/shared/components/v2/PageHeader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import getApplicableAntiFraudRulesForApplicationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForApplicationFlow';
import getApplicableAntiFraudRulesForAuthorisationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForAuthorisationFlow';

import { IdentityConfirm } from './styles';
import isPresent from 'utils/isPresent';
import { FEATURE_FLAGS } from 'conf';

class Identity extends Component {
  constructor(props) {
    const { isIdentificationImageRequired } = props;
    let photoComplete = false;

    if (!isIdentificationImageRequired) {
      photoComplete = true;
    }

    super(props);
    this.state = {
      detailsComplete: false,
      photoComplete,
    };
  }

  componentDidMount() {
    const { detailsComplete, photoComplete } = this.state;
    const { handleComplete } = this.props;
    handleComplete(detailsComplete && photoComplete, 'identity');
  }

  idPhotoUploadComplete(photoComplete) {
    const { handleComplete } = this.props;
    if (photoComplete !== this.state.photoComplete) {
      this.setState({ photoComplete });
    }
    handleComplete(photoComplete && this.state.detailsComplete, 'identity');
  }

  idDetailsFormComplete(detailsComplete) {
    const { handleComplete } = this.props;
    if (detailsComplete !== this.state.detailsComplete) {
      this.setState({ detailsComplete });
    }
    handleComplete(this.state.photoComplete && detailsComplete, 'identity');
  }

  render() {
    const {
      consumerName,
      isIdentificationImageRequired,
      supplierLegalName,
      supplierName,
      application,
      trustApplicantName,
    } = this.props;

    const attributes = application ? application.attributes : null;
    const supplierLegalNameProps = attributes
      ? attributes.supplier_legal_name
      : null;
    const supplierLegalNameSanitised =
      supplierLegalName || supplierLegalNameProps || supplierName;
    const certifyName =
      FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS && trustApplicantName
        ? trustApplicantName
        : consumerName;

    return (
      <div>
        <PageHeader title="Authorisation">
          <p>
            You are certifying that you are an authorised party for the
            application between {certifyName} and {supplierLegalNameSanitised}.
          </p>
        </PageHeader>
        {isIdentificationImageRequired && (
          <IdentityCapture
            {...this.props}
            isProofOfAddressVisible={
              this.props.isProofOfAddressVisible ||
              this.props.requiresProofOfAddress
            }
            handleComplete={this.idPhotoUploadComplete.bind(this)}
          />
        )}
        <IdentityConfirm
          {...this.props}
          hasExternalDataSelection={isIdentificationImageRequired}
          handleComplete={this.idDetailsFormComplete.bind(this)}
        />
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  const { authorisation, cob_cards, cob_guarantors, cob_section } = state;
  const applicantGuarantor = cob_guarantors.form_values.find(
    (g) => g && g.is_applicant
  );
  const people = get(cob_section, 'people') || [];
  const cardholders = cob_cards.cardholders;
  const applicantPeople = people.find((p) => p && p.is_applicant);
  const processedAuthorisation = authorisation.firstName
    ? {
        first_name: authorisation.firstName,
      }
    : null;
  const applicantCardholder = cardholders.find((cardholder) =>
    get(cardholder, 'attributes.isApplicant')
  );
  const processedApplicantCardholder = applicantCardholder
    ? {
        first_name: get(applicantCardholder, 'attributes.firstName'),
      }
    : null;

  const cobSectionAntifraudConfig = get(cob_section, 'antiFraud', null);
  const antiFraudConfig = cobSectionAntifraudConfig
    ? new AntiFraudAddonVersionModel(cobSectionAntifraudConfig)
    : get(authorisation, 'antiFraud', {});

  const applicationSource =
    get(cob_section, 'application', null) ||
    get(authorisation, 'data.application', {});
  const applicationConfig = new ApplicationModel(applicationSource);

  let isIdentificationImageRequired = true;

  const isAuthorisationFlow =
    isPresent(authorisation.guarantor) ||
    isPresent(authorisation.signatory) ||
    isPresent(authorisation.paperless) ||
    isPresent(authorisation.cardholder);

  const antiFraudCategoryRules =
    FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0 && isAuthorisationFlow
      ? getApplicableAntiFraudRulesForAuthorisationFlow(authorisation)
      : getApplicableAntiFraudRulesForApplicationFlow({
          antiFraud: antiFraudConfig,
          application: applicationConfig,
          cards: cardholders,
          guarantors: cob_guarantors,
          paperless: get(state, 'cob_paperless', []),
          people: get(cob_section, 'current_people', []),
          requestedLimit: get(state, 'cob_money.requested_limit'),
        });

  const {
    requiresIdentificationImage,
    requiresFrontFaceImage,
    requiresProofOfAddress,
    requiresProofOfLife,
  } = antiFraudCategoryRules;
  const requiresImage = requiresIdentificationImage || requiresFrontFaceImage;
  if (
    Object.keys(ownProps).includes('isIdentificationImageRequired') &&
    ownProps.isIdentificationImageRequired === requiresImage
  ) {
    isIdentificationImageRequired = ownProps.isIdentificationImageRequired;
  } else if (antiFraudConfig.isPersisted) {
    isIdentificationImageRequired = requiresImage;
  }

  const applicantSignatory =
    applicantGuarantor ||
    applicantPeople ||
    processedApplicantCardholder ||
    processedAuthorisation;

  return {
    applicantSignatory,
    applicant_guarantor: applicantGuarantor,
    isFaceMatchEnabled: requiresFrontFaceImage,
    isIdentificationImageRequired,
    requiresProofOfAddress,
    requiresProofOfLife:
      requiresProofOfLife && FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6,
  };
})(Identity);
