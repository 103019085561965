import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React, { ReactElement } from 'react';

type handleArgs = {
  value: boolean;
};

interface Props {
  handleCheck: (args: handleArgs) => void;
  isChecked: boolean;
  userType: string;
}

function IUFApproval({
  handleCheck,
  isChecked,
  userType,
}: Props): ReactElement | null {
  if (userType === 'standard') {
    return null;
  }

  const item = {
    description: `
      By selecting, the user will be able to approve Internal Use Field(IUF).
    `,
    id: 'can_approve_iuf',
    label: 'IUF approval',
    type: 'page_permissions',
  };

  const onClick = () => handleCheck({ value: !isChecked, ...item });

  return (
    <Checkbox
      checkboxId="can_approve_iuf"
      checked={isChecked}
      handleChange={onClick}
      label={item.label}
      description={item.description}
    />
  );
}

export default IUFApproval;
