import ColorScheme from 'color-scheme';
import { COLORS, THEME_COLORS } from 'variables/theme';

export const getColorVariations = (passedColor): string[] => {
  const colorSchema = new ColorScheme();
  colorSchema.from_hex(passedColor.replace('#', ''));
  return colorSchema.colors().map((color: string) => `#${color}`);
};

export const getThemeColors = (): string[] => {
  return [THEME_COLORS.primary, THEME_COLORS.primaryLight, COLORS.darkGrey];
};
