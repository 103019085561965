import React, { useEffect, useState } from 'react';
import ReactTimeout from 'react-timeout';

import { connect } from 'react-redux';

import { clearApplicationStates, loadSupplier } from '../actions/section';

import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import Loader from 'modules/shared/components/widgets/static/Loader';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import AddonConfigModel from 'models/AddonConfigModel';
import { get } from 'lodash';

import styles from './css/ConsumerApplication.css';

function ConsumerWebsitebuttonApplication(props) {
  const {
    current_pricing_plan_website_button,
    dispatch,
    loading,
    location,
    params,
  } = props;
  const [isApplicationTypesLoading, setIsApplicationTypesLoading] = useState(
    true
  );
  const [applicationTypes, setApplicationTypes] = useState(null);
  const pathname = location.pathname;
  const mobileMinHeight = document.documentElement.clientHeight - 56;

  const fetchApplicationTypes = () => {
    (async () => {
      const result = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: ['website_button'],
        entityId: params.entity_id,
      });

      const latestConfig = get(result[0], 'attributes.history_version.data')[0];
      setApplicationTypes(get(latestConfig, 'attributes.application_types'));
      setIsApplicationTypesLoading(false);
    })();
  };

  useEffect(() => {
    dispatch(clearApplicationStates());
    dispatch(loadSupplier(`${params.entity_id}`));

    if (!applicationTypes) {
      fetchApplicationTypes();
    }
  }, []);

  if (loading || isApplicationTypesLoading) {
    return (
      <Loader
        message="Please wait while we load your progress."
        loading={loading}
      />
    );
  }

  if (!current_pricing_plan_website_button) {
    return (
      <CircleModal>
        <div>
          <p>
            oops - looks like the supplier does not have this service. Please
            contact them for more information.
          </p>
        </div>
      </CircleModal>
    );
  }

  return (
    <div className={styles.page}>
      <div className="is-full-height has-background-white"></div>
    </div>
  );
}

export default connect((state, _props) => {
  let current_pricing_plan_website_button = null;
  let supplier = null;

  if (state.cob_section.supplier) {
    supplier = state.cob_section.supplier;
    current_pricing_plan_website_button =
      supplier.attributes.entity_plan_website_button;
  }

  return {
    loading: state.cob_section.loading,
    supplier,
    current_pricing_plan_website_button,
  };
})(ReactTimeout(ConsumerWebsitebuttonApplication));
