import React, { useContext } from 'react';
// @ts-ignore
import { browserHistory } from 'react-router';
import { useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';

import { CustomersTable } from './CustomersTable/CustomersTable';
import { CustomersContext } from '../CustomersContext';
import { DEFAULT_PAGE_NUMBER } from 'constants/pagination';
import { LoadMore } from './LoadMore';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import TableListSkeleton from 'modules/shared/components/v2/Skeletons/TableListSkeleton';

const Container = styled.div`
  background: white;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  padding-bottom: 60px;
`;

const LoadMoreContainer = styled.div`
  display: block;
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const PaginationContainer = styled.div`
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
`;

export const CustomersContent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const { isLoading, isEmpty, meta, params } = useContext(CustomersContext);

  const currentUrlParams = new URLSearchParams(window.location.search);

  const handleChangePage = (newPage: number) => {
    currentUrlParams.set('page', String(newPage));
    browserHistory.push(`/dashboard/customers?${currentUrlParams}`);
  };

  const handleChangeItemsPerPage = (newCount: number) => {
    currentUrlParams.set('per', String(newCount));
    currentUrlParams.set('page', String(DEFAULT_PAGE_NUMBER));
    browserHistory.push(`/dashboard/customers?${currentUrlParams}`);
  };

  return (
    <Container>
      {isLoading && isDesktop ? (
        <div>
          <TableListSkeleton />
        </div>
      ) : (
        <CustomersTable />
      )}

      {!isEmpty && (
        <>
          <LoadMoreContainer>
            <LoadMore />
          </LoadMoreContainer>

          <PaginationContainer>
            <Pagination
              dataLength={meta.total}
              currentPage={params.page}
              todosPerPage={params.per}
              resourceName="Applications"
              optionViewPage={[
                { label: '25', value: 25 },
                { label: '50', value: 50 },
              ]}
              handleClick={handleChangePage}
              handleClickTodosPerPage={handleChangeItemsPerPage}
            />
          </PaginationContainer>
        </>
      )}
    </Container>
  );
};
