import Abstract from './components/Abstract';
import Abstract_depcrecated from './components/Abstract_depcrecated';
import Complete from './components/Complete';
import Confirm from './components/Confirm';
import IdentityDetails from './components/IdentityDetails';
import React from 'react';
import Review from './components/Review';
import Terms from './components/Terms';
import { FEATURE_FLAGS } from 'conf';

export function componentFactory(section, props = {}) {
  switch (section) {
    case 'abstract':
      return FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS ? (
        <Abstract {...props} />
      ) : (
        <Abstract_depcrecated {...props} />
      );
    case 'review':
      return <Review {...props} />;
    case 'identity_details':
      return <IdentityDetails {...props} />;
    case 'terms':
      return <Terms {...props} />;
    case 'confirm':
      return <Confirm {...props} />;
    case 'complete':
      return <Complete {...props} />;
  }
}
