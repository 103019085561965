import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import api from 'api';
import get from 'lodash.get';
import SupplierModel from 'models/SupplierModel';
import UserModel from 'models/UserModel';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import styles from 'modules/super-admin/css/SupplierList.css';
import { loadCurrentEntity } from 'modules/user/actions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

function useSuppliersState(currentUser) {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);

  const onFetchSuppliers = () => {
    (async () => {
      setLoading(true);
      const suppliersAPI = api(
        'suppliers',
        currentUser.accessToken,
        currentUser.currentEntity.id
      );

      try {
        const result = await suppliersAPI.getSuppliers();
        const data = get(result, 'data.data', []);

        const loadedSuppliers = data.map(
          (supplier) => new SupplierModel(supplier)
        );

        setSuppliers(loadedSuppliers);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    onFetchSuppliers();
  }, []);

  return { loading, suppliers };
}

function SupplierRow(props) {
  const { dispatch, supplier } = props;

  const onClick = () => {
    dispatch(loadCurrentEntity(supplier.id, true));
  };

  return (
    <div className={styles.grid_table}>
      <div className={styles.clickable} onClick={onClick}>
        {supplier.formattedTradingName}
      </div>
      <div>{supplier.id}</div>
      <div>{supplier.keyContact}</div>
      <div>{supplier.subscriptionState}</div>
      <div>{supplier.type}</div>
    </div>
  );
}

function SupplierList(props) {
  const { currentUser, dispatch } = props;
  const { loading, suppliers } = useSuppliersState(currentUser);
  const [filter, setFilter] = useState('all');

  let filteredSuppliers = suppliers;

  if (filter === 'active' || filter === 'deactivated') {
    filteredSuppliers = filteredSuppliers.filter(
      (supplier) => supplier.subscriptionState === filter
    );
  }

  const rows = filteredSuppliers.map((supplier, i) => (
    <SupplierRow
      key={`supplier-row-${i + 1}`}
      dispatch={dispatch}
      supplier={supplier}
    />
  ));

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={styles.container}>
        <div className={styles.header_container}>
          <div className={styles.title}>
            <AdminInnerTitle text="Suppliers" isNormalFont={true} />
          </div>
          <div className={styles.radio}>
            <RadioGroup
              row
              aria-label="filter"
              id="filter"
              name="filter"
              value={filter}
              onChange={(e) => e.target && setFilter(e.target.value)}
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active"
              />

              <FormControlLabel
                value="deactivated"
                control={<Radio />}
                label="Deactive"
              />

              <FormControlLabel value="all" control={<Radio />} label="All" />
            </RadioGroup>
          </div>
        </div>

        <div className={styles.table_container}>
          <div className={styles.section}>
            <div className={`${styles.grid_table} ${styles.table_header}`}>
              <div>Trading name</div>
              <div>ID</div>
              <div>Key contact</div>
              <div>Status</div>
              <div>Type</div>
            </div>
            {loading && (
              <Typography variant="h3">
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Typography>
            )}
            {rows}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(SupplierList);
