import styles from 'css/shared/PipelineSearch.css';
import {
  externalAlertDropdownOptions,
  internalAlertsDropdownOptions,
} from 'modules/addons/components/digital-onboarding/WatchtowerModule/constants';
import useFilterState from 'modules/reporting/hooks/useFilterState';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React, { Fragment, useEffect } from 'react';

import { defaultSearchParams } from '../helpers';
import { PipelinePageHeaderComponents } from 'modules/shared/components';

const { ButtonsContainer, AdvancedSearchButton } = PipelinePageHeaderComponents;

const Filter = (props) => {
  const { updateSearchParams, searchParams, location } = props;
  const { filter, filterParams, onToggleIsOpen } = useFilterState({
    ...defaultSearchParams,
    searchParams,
  });

  const pipelineFilter = React.cloneElement(filter, {
    dynamicFilterOptions: {},
    filterType: 'watchtower',
  });

  const onChangeSearch = (event) => {
    const { value } = event.target;
    const params = value ? { key_word: value } : {};
    updateSearchParams(params);
  };

  const parseFilterParams = (parseFilterParams) => {
    const filterKeyMap = {
      alert_actioned: {
        key: 'actioned',
        values: {
          actioned: { actioned: true },
          unactioned: { actioned: false },
        },
      },
      alert_group: {
        key: 'grouped',
        values: {
          grouped: { grouped: true },
          ungrouped: { grouped: false },
        },
      },
      alert_irregular: {
        key: 'irregular_accounts',
        values: {
          irregular: { irregular_accounts: true },
        },
      },
      alert_pipeline: {
        key: 'pipeline',
        values: {
          customers: { pipeline: 'customers' },
          sales: { pipeline: 'sales' },
        },
      },
    };

    const changeKeyMap = {
      alert_date_from: 'start_date_from',
      alert_date_to: 'start_date_to',
    };

    let currentParams = { ...searchParams, ...parseFilterParams };

    Object.keys(currentParams).forEach((filterKey) => {
      if (currentParams[filterKey] && currentParams[filterKey] === 'All') {
        delete currentParams[filterKey];
        if (filterKeyMap[filterKey]) {
          delete currentParams[filterKeyMap[filterKey].key];
        }
      } else {
        if (filterKeyMap[filterKey]) {
          currentParams = {
            ...currentParams,
            ...filterKeyMap[filterKey].values[currentParams[filterKey]],
          };

          delete currentParams[filterKey];
        }
        if (changeKeyMap[filterKey]) {
          currentParams[changeKeyMap[filterKey]] = currentParams[filterKey];
          delete currentParams[filterKey];
        }
      }
    });

    return currentParams;
  };

  useEffect(() => {
    updateSearchParams(parseFilterParams(filterParams), false);
  }, [filterParams]);

  return (
    <Fragment>
      <div className={styles.search_container}>
        <div className={styles.search_input}>
          <BorderedTextField
            id="search"
            label="Search"
            onChange={onChangeSearch}
            value={location.query.key_word || ''}
          />
        </div>
        <ButtonsContainer>
          <AdvancedSearchButton onClick={onToggleIsOpen} />
        </ButtonsContainer>
      </div>
      {pipelineFilter}
    </Fragment>
  );
};

export default Filter;
