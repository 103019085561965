import PPSRFinanceStatement from './PPSRFinanceStatement';
import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import styles from '../../css/CommonReviewBusiness.css';

var PPSRFinanceStatementsCollateralSummary = createClass({
  _summary() {
    const { data } = this.props;

    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      statementSummaries = enquiryResult.ppsr_finance_statement_summaries || {},
      statementSummary =
        statementSummaries.ppsr_finance_statement_summary || [];

    if (statementSummary.constructor === Object) {
      statementSummary = [statementSummary];
    }

    let summary = {};

    statementSummary.forEach((statement) => {
      summary[statement.collateral] = (summary[statement.collateral] || 0) + 1;
    });

    return Object.keys(summary).map((collateral, index) => {
      let total = summary[collateral],
        statement = 'statement';

      if (total > 1) statement = 'statements';

      return (
        <p key={index}>
          {summary[collateral]} finance {statement} with {collateral}
        </p>
      );
    });
  },

  render() {
    const { data, isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="PPSR Finance Statements Collateral Summary"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          The following Finance Statement Collateral Items are registered on the
          PPSR against this company. Some finance statements have multiple
          collateral iteams so the total number of statements might be less than
          the total of collateral items.
        </p>
        <p>
          Please use the PPSR page to view complete details of PPSR Finance
          Statements
        </p>
        {this._summary()}
        <PPSRFinanceStatement data={data} />
      </ReviewContainer>
    );
  },
});

export default PPSRFinanceStatementsCollateralSummary;
