// REDUX UTILS FOR IDENTITY COMPONENTS

import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import moment from 'moment';

export const checkScrapedData = (state, scrapedData): any => {
  let newData: any = {
    is_valid_identification: scrapedData.is_valid_identification,
  };

  const isQldLicence = isQldLicenceScraped(state, scrapedData);
  const hasScrapedData = scrapedData && Object.keys(scrapedData).length > 0;

  if (!isQldLicence && hasScrapedData) {
    if (!state.names_locked) {
      newData = {
        ...newData,
        first_name: get(scrapedData, 'first_name') || state.first_name,
        last_name: get(scrapedData, 'last_name') || state.last_name,
        middle_name: get(scrapedData, 'middle_name') || state.middle_name,
      };
    }
    if (scrapedData.dob) {
      newData = {
        ...newData,
        dob: moment(scrapedData.dob, 'DD-MM-YYYY').toDate(),
      };
    }
    if (scrapedData.identification_expiry_date) {
      newData = {
        ...newData,
        identification_exp_date: moment(
          scrapedData.identification_expiry_date,
          'DD-MM-YYYY'
        ).toDate(),
      };
    }

    const identificationType = getIdentificationType(state, scrapedData);

    newData = {
      ...newData,
      ...identificationType,
      driver_licence_version:
        get(scrapedData, 'identification_version') ||
        state.driver_licence_version,
      //Prevent changes on  driver_licence_state state when user selected QLD [BT-16137]
      driver_licence_state: isQldLicence
        ? state.driver_licence_state
        : get(scrapedData, 'identification_state') ||
          state.driver_licence_state,
      // Original logic prior [BT-16137]
      //driver_licence_state: scrapedData.identification_state,

      number: get(scrapedData, 'identification_number') || state.number,
      region: get(scrapedData, 'region') || state.region,
      identification_card_number:
        get(scrapedData, 'identification_card_number') ||
        state.identification_card_number,
      scraped_identification_type: scrapedData.scraped_identification_type,
    };
  }

  return {
    ...newData,
    scraping: false,
    isScrapeDetailsModalVisible: true,
  };
};

export const isQldLicenceScraped = (state, scrapedData) => {
  if (!FEATURE_FLAGS.FEATURE_FLAG_TEMP_BLOCK_QLD_DL) return false;
  return (
    state.driver_licence_state === 'QLD' &&
    state.type === 'driver_licence' &&
    scrapedData.identification_state !== 'QLD'
  );
};

export const getIdentificationType = (state, scrapedData) => {
  return state.type === 'other' &&
    ['driver_licence', 'passport'].includes(scrapedData.identification_type)
    ? { type: scrapedData.identification_type }
    : {};
};
