import get from 'lodash.get';
/* eslint-disable max-len */
import store from 'stores/store';
import isBlank from 'utils/isBlank';

const TIER_3_DECENTRALIZABLE_FEATURES = [
  'TC',
  'IUF',
  'Business',
  'Additional',
  'WebsiteButton',
];

export function isBranchUser() {
  const currentUser = store.getState().current_user;
  const currentEntityId = currentUser.current_entity.id;
  const entityIdFromUser =
    currentUser.current_user_entity_link.attributes.entity_id;
  return isBranchOrRegion() && currentEntityId !== entityIdFromUser;
}

export function isDifferentBranchApplication({
  application,
  currentUser,
  supplierId,
}) {
  const currentEntityId = get(currentUser, 'currentEntity.id');
  const entityIdFromApplication = supplierId || application.supplierId;
  return currentEntityId !== entityIdFromApplication || isBranchUser();
}

export function isBranchApplication(supplierId) {
  const currentEntityId = store.getState().current_user.current_entity.id;
  const entityIdFromApplication =
    supplierId ||
    store.getState().applications.review_application.attributes.supplier_id;
  return currentEntityId !== entityIdFromApplication || isBranchUser();
}

export function isBranchLead(entityId) {
  const currentEntityId = store.getState().current_user.current_entity.id;
  const entityIdFromLead =
    entityId || store.getState().leads.review_lead.attributes.entity_id;
  return currentEntityId !== entityIdFromLead || isBranchUser();
}

export function isHeadquarter() {
  return _companyType('Headquarter');
}

export function isBranch() {
  return _companyType('Branch');
}

export function isRegular() {
  return _companyType('Regular');
}

export function isRegion() {
  return _companyType('Region');
}
export function isBranchOrRegion() {
  return _companyType('Branch') || _companyType('Region');
}

export function isFeatureAvailable(feature) {
  const currentEntity = store.getState().current_user.current_entity || {};

  return (currentEntity['attributes'] || {})[`${feature}_enabled`] || false;
}

function _companyType(type) {
  return store.getState().current_user.current_entity.attributes.type === type;
}

export function isFeatureEditEnabled(feature = '') {
  if (isBlank(feature)) {
    return false;
  }

  if (isRegular() || isHeadquarter()) {
    return true;
  }

  const state = store.getState();

  const cfg = get(
    state,
    'current_user.current_entity.attributes.hqmode_config',
    {}
  );

  if (['Region', 'Branch'].includes(cfg.type) && cfg.d_list.includes(feature)) {
    return true;
  }

  return false;
}

// ----------------------------------------------------------------
// refactored
// ----------------------------------------------------------------

export function isDecentralize1CAHBranch() {
  const state = store.getState();

  const cfg = get(
    state,
    'current_user.current_entity.attributes.hqmode_config',
    {}
  );

  if (
    (cfg.type === 'Region' || cfg.type === 'Branch') &&
    cfg.d_list.includes('1CAH')
  ) {
    return true;
  }

  return false;
}

// export function isDecentralize1CADBranch() {
//   const state = store.getState();

//   const cfg = get(
//     state,
//     "current_user.current_entity.attributes.hqmode_config",
//     {},
//   );

//   if (
//     (cfg.type === "Region" || cfg.type === "Branch") &&
//     cfg.d_list.includes("1CAD")
//   ) {
//     return true;
//   }

//   return false;
// }

// export function isCentralizeAllBranch() {
//   return (
//     !isDecentralize1CAHBranch() &&
//     !isDecentralize1CADBranch() &&
//     isBranchOrRegion()
//   );
// }

export function isCentralize1CAHHeadquarter() {
  if (!isHeadquarter()) {
    return false;
  }

  const state = store.getState();

  const cfg = get(
    state,
    'current_user.current_entity.attributes.hqmode_config',
    {}
  );

  if (cfg.hq && cfg.hq.includes('1CAH')) {
    return false;
  }

  return true;
}
