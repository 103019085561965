export const COB_FINANCIALS_SET_ADDITIONAL_FIELDS =
  'COB_FINANCIALS_SET_ADDITIONAL_FIELDS';
export const COB_FINANCIALS_LOAD_ANSWERS = 'COB_FINANCIALS_LOAD_ANSWERS';
export const COB_FINANCIALS_SET_ANSWER = 'COB_FINANCIALS_SET_ANSWER';
export const COB_FINANCIALS_COMPONENT_COMPLETE =
  'COB_FINANCIALS_COMPONENT_COMPLETE';
export const COB_FINANCIALS_SET_FILE = 'COB_FINANCIALS_SET_FILE';
export const COB_FINANCIALS_RESET_DATA = 'COB_FINANCIALS_RESET_DATA';
export const COB_FINANCIALS_REMOVE_ADDITIONAL_FIELDS =
  'COB_FINANCIALS_REMOVE_ADDITIONAL_FIELDS';
