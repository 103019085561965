import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import {
  ThemeProvider as MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { useState } from 'react';

import { MobileProgressDrawer } from './MobileProgressDrawer';
import OverlayButtonsList from './overlay-buttons';
import RoundButton from './RoundButton';
import SectionDrawer from './SectionDrawer';
import {
  ButtonGroup,
  HamburgerButton,
  Header,
  ImageContainer,
  Logo,
  LogoImage,
} from './styles';
import { FEATURE_FLAGS } from 'conf';

const Overlay = (props) => {
  const [activeButton, setActiveButton] = useState(false);
  const {
    applicationChecklist,
    applicationId,
    currentSection,
    entityType,
    progressSections,
    supplierLogoUrl,
    supplierTerms,
    withButtons,
    termsList,
    isPreview,
  } = props;

  const isAuthorisation =
    FEATURE_FLAGS.FEATURE_FLAGS_AUTH_TNCS &&
    termsList &&
    Array.isArray(termsList);

  const buttonList = OverlayButtonsList(applicationChecklist, isAuthorisation);

  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );

  const StyledAppBar = withStyles({
    root: {
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  })(AppBar);

  const toggleMobileMenu = () => {
    if (isPreview) {
      return;
    }
    setActiveButton(!activeButton);
  };

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Box>
        <StyledAppBar position="static" elevation={0}>
          <Header>
            <Toolbar>
              <Logo>
                <ImageContainer>
                  <LogoImage src={supplierLogoUrl} />
                </ImageContainer>
              </Logo>
              {withButtons && (
                <ButtonGroup>
                  {isMobileScreen && (
                    <HamburgerButton
                      type="button"
                      className={`button is-rounded is-outlined ${
                        activeButton === true ? 'active-button' : ''
                      }`}
                      onClick={toggleMobileMenu}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'bars']}
                        size={'lg'}
                        color="white"
                      />
                      <MobileProgressDrawer
                        activeButton={activeButton}
                        currentSection={currentSection}
                        entityType={entityType}
                        progressSections={progressSections}
                      />
                    </HamburgerButton>
                  )}
                  {buttonList.map(
                    ({ buttonName, buttonLabel }) =>
                      !isMobileScreen && (
                        <RoundButton
                          key={buttonName}
                          label={buttonLabel}
                          sideBarName={buttonName}
                          isPreview={isPreview}
                        />
                      )
                  )}
                </ButtonGroup>
              )}
            </Toolbar>
          </Header>
        </StyledAppBar>
        <SectionDrawer
          applicationChecklist={applicationChecklist}
          applicationId={applicationId}
          currentSection={currentSection}
          entityType={entityType}
          progressSections={progressSections}
          supplierTerms={supplierTerms}
          termsList={termsList}
        />
      </Box>
    </MuiThemeProvider>
  );
};

Overlay.defaultProps = {
  withButtons: true,
};

export default Overlay;
