import { FEATURE_FLAGS } from 'conf';
import AddonConfigModel from 'models/AddonConfigModel';

const defaultReturnValue = {
  customerViewOptions: [],
  isCustomerViewActive: false,
};

type Option = {
  id: number | string;
  entity_id: number | string;
  trading_name: string;
  parent_id: number | string | null;
  type: 'Region' | 'Branch';
};

export const getCustomerView = async (tradingNames, supplierId) => {
  if (!FEATURE_FLAGS.FEATURE_FLAG_1ACCOUNT_CUSTOMER_VIEW) {
    return defaultReturnValue;
  }

  const data = await AddonConfigModel.fetchAddonConfigByAddonType({
    addonType: ['one_acv_module'],
    entityId: supplierId,
    accessToken: '',
  });

  const isCustomerViewConfigured = data && data.length;
  if (!isCustomerViewConfigured) {
    return defaultReturnValue;
  }

  const attributes = data[0].attributes.history_version.data[0].attributes;
  if (!attributes.active) {
    return defaultReturnValue;
  }

  const { config } = attributes;

  let customerViewOptions: Option[] = [];

  if (config[0] && config[0].children) {
    config[0].children.forEach((group, groupIndex) => {
      const groupItem = {
        id: groupIndex,
        entity_id: groupIndex,
        trading_name: group.title,
        parent_id: null,
        type: 'Region' as const,
      };

      if (group.children) {
        const t3sUnderGroup: any = [];

        group.children.forEach((t3) => {
          // We need to find if the T3 configured in customer view is present in
          // available trading names. If the T3 is not presnt, it should not be
          // displayed in the tier 3 selection.
          const tradingName = tradingNames.find(
            (tradingName) => tradingName.entity_id === t3.entityId
          );

          // We also need to find out whether the parent region of the T3 is
          // present in the available trading names.
          // If the parent region is not present, that means that region is
          // decentralised for sales channel. So the T3 should not be displayed
          // in the tier 3 selection.
          const hasParent = tradingName
            ? tradingNames.some(
                (tradingNameItem) =>
                  tradingNameItem.entity_id === tradingName.parent_id
              )
            : false;

          if (tradingName && hasParent) {
            t3sUnderGroup.push({
              id: tradingName.id,
              entity_id: t3.entityId,
              trading_name: t3.title,
              parent_id: groupItem.entity_id,
              type: 'Branch' as const,
            });
          }
        });

        if (t3sUnderGroup.length) {
          customerViewOptions = [
            ...customerViewOptions,
            groupItem,
            ...t3sUnderGroup,
          ];
        }
      }
    });
  }

  return {
    customerViewOptions,
    isCustomerViewActive: customerViewOptions.length,
  };
};
