import AlertsIndex from 'modules/alerts/components/AlertsIndex';

const AlertApplicationRoute = {
  indexRoute: {
    component: AlertsIndex,
  },
  path: 'alert_applications',
};

export default AlertApplicationRoute;
