import axios from './axios';
class AskNicely {
  constructor(config) {
    config.timeout = 60000;
    this.axios = axios(config);
    this.type = 'asknicely';
    this.path = `/${this.type}`;
  }

  createSurvey(attributes, success, error) {
    return this.axios
      .post(this.path, {
        data: { attributes, type: this.type },
      })
      .then(success)
      .catch(error);
  }
}

export default AskNicely;
