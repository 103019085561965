import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@material-ui/core';
import api from 'api';
import commonStyles from 'css/shared/PipelineStyles.css';
import {
  ALERTS_NAME_MAP,
  irregularAlerts,
} from 'modules/new-applications/components/application-actions/Watchtower/Alerts/constants';
import { ActionWrapper } from 'modules/new-applications/components/application-actions/Watchtower/Alerts/styles';
import { DataRowTierText } from 'modules/new-applications/components/application-actions/Watchtower/Alerts/WatchtowerAlerts';
import ActionModal from 'modules/new-applications/components/application-actions/Watchtower/Modal/ActionModal';
import Button from 'modules/shared/components/inputs/Button';
import TableListSkeleton from 'modules/shared/components/v2/Skeletons/TableListSkeleton';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import useCollapsibleTableRowState from 'modules/shared/hooks/useCollapsibleTableRowState';
import React, { useEffect, useState } from 'react';
// @ts-ignore browserHistory exist on react-router
import { browserHistory } from 'react-router';
import { formatMoney } from 'utils/formatting';

import {
  AccountMonitoringIcon,
  AccountMonitoringIconContainer,
  AlertNameWrapper,
  HeaderWithTootltip,
  IconWrapper,
  RowIcon,
} from '../styles';
import get from 'lodash.get';

function WatchtowerTableRow(props) {
  const {
    rowData,
    current,
    index,
    setCurrentIndex,
    isDetails,
    activeBlock,
    removeFromGroup,
    userEntityLinks,
    currentUser,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { icon, isOpen, onToggleIsOpen } = useCollapsibleTableRowState({
    useAddIcon: true,
  });

  const onClick = () => {
    setCurrentIndex(index);
    onToggleIsOpen();
  };

  useEffect(() => {
    if (!current && isOpen) onToggleIsOpen();

    if (current && !isOpen) onToggleIsOpen();
  }, [current]);

  const isExpandable = rowData.rowGroupData && activeBlock !== 'blocked';

  const getRowStyles = () => {
    const rowStyles: string[] = [commonStyles.default_row];

    if (isExpandable) {
      rowStyles.push(commonStyles.expandable_col);
    }

    if (isDetails) {
      rowStyles.push(commonStyles.details_row_styles);
    }

    return rowStyles.join(' ');
  };

  const goToApplication = () => {
    browserHistory.push({
      pathname: `/dashboard/applications/${rowData.application_id}`,
    });
  };

  const getAlertType = (rowData) => {
    const isUnactioned = rowData.status === 'open';
    const isBlocked = rowData.status === 'blocked';
    const isIrregularAlert = irregularAlerts.includes(rowData.alert_name);

    if (isBlocked || isIrregularAlert) {
      return 'external';
    }

    if (isUnactioned) {
      return rowData.alert_type;
    }

    return undefined;
  };

  const isBlocked = activeBlock === 'blocked';

  return (
    <div>
      <div
        className={
          isBlocked ? commonStyles.grid_table_4 : commonStyles.grid_table_6
        }
      >
        {isBlocked ? (
          <React.Fragment>
            <div className={getRowStyles()}>{rowData.alert_source_data}</div>
            <div className={commonStyles.cell}>
              {new Date(rowData.updated_at).toLocaleDateString()}
            </div>
            <div className={commonStyles.cell}>
              {
                <AlertNameWrapper type={getAlertType(rowData)}>
                  {ALERTS_NAME_MAP[rowData.alert_name]}
                </AlertNameWrapper>
              }
            </div>
            <div className={commonStyles.cell}>{rowData.customer_name}</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={getRowStyles()} onClick={onClick}>
              {isExpandable && <IconWrapper>{icon}</IconWrapper>}
              <span
                className={commonStyles.pointer}
                onClick={() => {
                  if (isExpandable) return;
                  goToApplication();
                }}
              >
                {rowData.customer_name}
                {rowData.hasAccountMonitoring && (
                  <PopperTooltip
                    placement="top"
                    title="Account monitoring (Account status/Review date)  apply. Go to VCF for more information"
                  >
                    <AccountMonitoringIconContainer>
                      <AccountMonitoringIcon />
                    </AccountMonitoringIconContainer>
                  </PopperTooltip>
                )}
              </span>
            </div>
            <div className={commonStyles.cell}>
              {!rowData.trade_account_limit
                ? '-'
                : `$${formatMoney(
                    parseFloat(rowData.trade_account_limit),
                    null,
                    null,
                    null,
                    null
                  )}`}
            </div>
            <div className={commonStyles.cell}>
              <AlertNameWrapper type={getAlertType(rowData)}>
                {ALERTS_NAME_MAP[rowData.alert_name]}
              </AlertNameWrapper>
            </div>
            <div className={commonStyles.cell}>
              {new Date(rowData.created_at).toLocaleDateString()}
            </div>
            <div className={commonStyles.cell}>
              <DataRowTierText
                linkedApplications={[rowData.rawData.application]}
                entityTierData={get(
                  currentUser,
                  'currentEntity.entityTierData',
                  []
                )}
              />
            </div>
            <div className={commonStyles.cell}>
              {(isExpandable || isDetails) && (
                <ActionWrapper>
                  <a onClick={() => setIsModalOpen(true)}>Remove from group</a>
                </ActionWrapper>
              )}
            </div>
            {isModalOpen && (
              <ActionModal
                onConfirm={() => removeFromGroup(rowData.application_id)}
                onCancel={() => setIsModalOpen(false)}
                actionType={'remove_group'}
              />
            )}
          </React.Fragment>
        )}
      </div>

      {isExpandable && (
        <Collapse in={current && isOpen}>
          <div className={commonStyles.details}>
            {rowData.rowGroupData.map((rowData, index) => (
              <WatchtowerTableRow
                key={index}
                rowData={rowData}
                userEntityLinks={userEntityLinks}
                removeFromGroup={removeFromGroup}
                currentUser={currentUser}
                isDetails
              />
            ))}
            <div className={commonStyles.manage_account_button}>
              <Button text="Manage account" onClick={goToApplication} />
            </div>
          </div>
        </Collapse>
      )}
    </div>
  );
}

const WatchtowerTableHeader = (props) => {
  const isBlocked = props.activeBlock === 'blocked';

  return (
    <div
      className={`${
        isBlocked ? commonStyles.grid_table_4 : commonStyles.grid_table_6
      } ${commonStyles.table_header}`}
    >
      {isBlocked ? (
        <React.Fragment>
          <div>Email/IP Blocked</div>
          <div>Date blocked</div>
          <div>Alert</div>
          <div>
            <HeaderWithTootltip>
              Linked application
              <PopperTooltip
                title="The linked application will only appear if it is attached to your account. If it does not appear, it means that another 1Centre supplier has blocked this email/IP as fraudulent."
                placement="top"
              >
                <RowIcon fontSize="small">
                  <FontAwesomeIcon icon={['fas', 'question-circle']} />
                </RowIcon>
              </PopperTooltip>
            </HeaderWithTootltip>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>Customer</div>
          <div>Limit</div>
          <div>Alert</div>
          <div>Alert date</div>
          <div>Tier</div>
          <div>Grouping</div>
        </React.Fragment>
      )}
    </div>
  );
};

const WatchtowerTable = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const defaultOpen = queryParams.has('default_open');

  const { tableData = {}, loading, currentUser } = props;
  const [currentIndex, setCurrentIndex] = useState(defaultOpen ? 0 : undefined);

  if (loading) {
    return <TableListSkeleton />;
  }

  const applicationAPI = api(
    'applications',
    currentUser.accessToken,
    currentUser.currentEntity.id
  );

  const removeFromGroup = (applicationId) => {
    applicationAPI.removeAlertFromGrouping(applicationId, () =>
      location.reload()
    );
  };

  const rows = tableData.tableRows.map((rowData, index) => (
    <WatchtowerTableRow
      {...props}
      key={`application-${index}`}
      rowData={rowData}
      setCurrentIndex={setCurrentIndex}
      current={currentIndex === index}
      index={index}
      removeFromGroup={removeFromGroup}
      userEntityLinks={currentUser ? currentUser.included : []}
    />
  ));

  return (
    <div className={commonStyles.table_content}>
      <WatchtowerTableHeader {...props} />
      {rows}
    </div>
  );
};

const DEFAULT_PER_PAGE = 25;

const PipelineTable = (props) => {
  const { loading, meta = {}, updateSearchParams } = props;

  return (
    <React.Fragment>
      <WatchtowerTable loading={loading} meta={meta} {...props} />
      <Pagination
        dataLength={meta.total}
        currentPage={meta.page}
        todosPerPage={meta.per}
        resourceName="Entries"
        optionViewPage={[
          { label: '25', value: DEFAULT_PER_PAGE },
          { label: '50', value: 50 },
        ]}
        handleClick={(page) => updateSearchParams({ page })}
        handleClickTodosPerPage={(per) => updateSearchParams({ per })}
      />
    </React.Fragment>
  );
};

export default PipelineTable;
