import api from 'api';
import get from 'lodash.get';

interface IRequestVerificationCodeParams {
  applicationId?: string;
  email: string;
  supplierId: string;
  errorCallback: (error: any) => void;
  successCallback: (results: any) => void;
}

// eslint-disable-next-line space-before-function-paren
export const requestVerificationCode = async ({
  applicationId,
  email,
  supplierId,
  errorCallback,
  successCallback,
}: IRequestVerificationCodeParams): Promise<void> => {
  const onboardingAPI = api('onboarding');

  try {
    const result = await onboardingAPI.requestVerificationCode({
      applicationId,
      email,
      supplierId,
    });

    if (get(result, 'data.success') && successCallback) {
      successCallback(result);
    } else {
      errorCallback(result);
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback(error);
    }
  }
};
