import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React, { ReactElement, useEffect, useState } from 'react';
import Table from 'modules/shared/components/table/Table';
import api from 'api';
import get from 'lodash.get';
import { capitalize } from 'utils/formatting';

import './timelog.scss';

import './timelog.scss';
import { formatLocalTime } from 'utils/dateFormatter';

const DATA_COLUMNS = [
  {
    Header: 'Activity Started',
    accessor: 'value',
  },
  {
    Header: 'Activity',
    accessor: 'type',
  },
];

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

export default function TimeLog(props: Props): ReactElement | null {
  const { application, currentUser } = props;
  const accessToken = get(currentUser, 'accessToken');
  const applicationId = get(application, 'id');

  const applicationAPI = api('applications', accessToken);

  if (!applicationId) {
    return null;
  }

  const [allVersionActivities, setAllVersionActivities] = useState([]);

  useEffect(() => {
    applicationAPI
      .applicationActivities(applicationId)
      .then((response) => {
        const responseData = get(response, 'data', []);
        const filteredData = responseData.filter(
          (callCentreData) => Object.keys(callCentreData.data).length
        );
        setAllVersionActivities(filteredData.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [applicationId, currentUser]);

  const EVENT_TYPE_MAPPING = {
    submission_started_at: 'Application started',
    submission_completed_at: 'Application completed',
    sent_at: 'Application sent',
  };

  const eventTypeKeys = Object.keys(EVENT_TYPE_MAPPING);

  const createActivitiesTableData = (events) => {
    if (!events) return;
    let tableEvents: any = [];

    events.forEach((event) => {
      const { type, value } = event;

      if (['approved_at'].includes(type) || !value) return;

      if (type === 'reviews_activity') {
        value.forEach((reviewEvent, index) => {
          const { approved_at, level } = reviewEvent;
          const approvedAt = approved_at
            ? formatLocalTime(approved_at, 'minute')
            : '-';

          tableEvents.push({
            type:
              level === 0
                ? 'Reviewed'
                : `Level ${level} Approved${
                    value.length - 1 === index ? '/Application accepted' : ''
                  }`,
            value: approvedAt,
          });
        });
      } else {
        const eventType = eventTypeKeys.includes(type)
          ? EVENT_TYPE_MAPPING[type]
          : capitalize(type.split('_').join(' '));

        tableEvents.push({
          type: eventType,
          value: value ? formatLocalTime(value, 'minute') : '-',
        });
      }
    });

    return tableEvents;
  };

  const hasApplicationActivities =
    allVersionActivities && allVersionActivities.length > 0;

  return (
    <ScrollableContent>
      {!hasApplicationActivities && (
        <span style={{ fontSize: '16px', marginBottom: '10px' }}>
          No log available
        </span>
      )}
      {hasApplicationActivities &&
        allVersionActivities.map((versionActivities: any) => {
          const { events, version_number } = versionActivities.data.attributes;
          const activitiesTableData = createActivitiesTableData(events);
          return (
            <div className="timelog-table-container">
              <span
                style={{ fontSize: '16px', marginBottom: '10px' }}
              >{`Application Version ${version_number}`}</span>
              <Table
                columns={DATA_COLUMNS}
                data={activitiesTableData}
                dataLabel="activities"
                withPagination={false}
              />
            </div>
          );
        })}
    </ScrollableContent>
  );
}
