import { IconButton, Menu, MenuItem } from '@material-ui/core';

import React, { useContext, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styled from 'styled-components';

import { ConfirmRemoveGroup } from './ConfirmRemoveGroup';
import { CustomerViewContext } from './CustomerViewContext';

const MoreButton = styled(IconButton)`
  svg {
    color: var(--main-color);
  }
`;

type GroupMenuProps = {
  groupName: string;
  path: number[];
};

export const GroupMenu = (props: GroupMenuProps) => {
  const { groupName, path } = props;

  const { removeGroup, setEditPath } = useContext(CustomerViewContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isRemoveConfirmationVisible, setRemoveConfirmationVisible] =
    useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const enableEdit = () => setEditPath(path);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const showConfirmRemoval = () => {
    handleCloseMenu();
    setRemoveConfirmationVisible(true);
  };

  const confirmRemoval = () => {
    removeGroup(path);
    setRemoveConfirmationVisible(false);
  };

  const cancelRemoval = () => setRemoveConfirmationVisible(false);

  return (
    <>
      <MoreButton
        aria-controls="group-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </MoreButton>
      <Menu
        id="group-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={enableEdit}>Edit</MenuItem>
        <MenuItem onClick={showConfirmRemoval}>Remove</MenuItem>
      </Menu>

      <ConfirmRemoveGroup
        groupName={groupName}
        isOpen={isRemoveConfirmationVisible}
        onCancel={cancelRemoval}
        onConfirm={confirmRemoval}
      />
    </>
  );
};
