import styled, { css } from 'styled-components';
import { THEME_COLORS } from 'variables/theme';

type RankProps = {
  readonly rankValue?: number;
};

export const Header = styled.thead`
  td {
    padding-bottom: 1rem;
  }
`;

export const Value = styled.span`
  color: ${THEME_COLORS.primary};
`;

export const Alert = styled.span`
  color: ${THEME_COLORS.danger};
`;

export const LineItem = styled.div`
  > *:not(:last-child) {
    display: inline-block;
    margin-right: 1rem;
  }
`;

export const Ranking = styled.span<RankProps>`
  text-align: center !important;
`;

export const Rank = styled.span<RankProps>`
  border-radius: 0.25rem;
  display: inline-block;

  ${(props) =>
    props.rankValue &&
    props.rankValue < 4 &&
    css`
    // background: ${THEME_COLORS.primary};
    // box-shadow: 2px 2px 3px 0 rgba(0,0,0, 0.2);
    // color: white;
    // height: 1.5rem;
    // width: 1.5rem;
  `}
`;

export const Trend = styled.span`
  text-align: center !important;
`;

export const TooltipContentWrapper = styled.span`
  > *:not(:last-child) {
    display: inline-block;
    margin-right: 1rem;
  }
`;

export const Wrapper = styled.div`
  > ${LineItem} {
    margin-bottom: 1rem;
  }

  tbody {
    td {
      padding-bottom: 1rem;
    }
  }
`;
