import React from 'react';
import ApplicationModel from 'models/ApplicationModel';
import { createContext } from 'react';

type AccountMonitoringContextType = {
  application: any;
  currentUser: {
    accessToken: string;
    currentEntity: {
      id: string;
    };
    id: string;
  };
  onFetchApplicationRecord: () => void;
};

const defaultValues: AccountMonitoringContextType = {
  application: null,
  currentUser: {
    accessToken: '',
    currentEntity: {
      id: '',
    },
    id: '',
  },
  onFetchApplicationRecord: () => {},
};

export const AccountMonitoringContext = createContext(defaultValues);

type AccountMonitoringContextProviderProps = {
  application: ApplicationModel;
  currentUser: {
    accessToken: string;
    currentEntity: {
      id: string;
    };
    id: string;
  };
  onFetchApplicationRecord: () => void;
  children: React.ReactNode;
};

export const AccountMonitoringContextProvider = (
  props: AccountMonitoringContextProviderProps
) => {
  const { application, currentUser, onFetchApplicationRecord, children } =
    props;
  return (
    <AccountMonitoringContext.Provider
      value={{ application, currentUser, onFetchApplicationRecord }}
    >
      {children}
    </AccountMonitoringContext.Provider>
  );
};
