import { FEATURE_FLAGS } from 'conf';
import api from '../api';
import { ZendeskAuthParams } from 'api/zendeskChat';

declare const window: {
  zE: any;
} & Window;

export function loadChat(identifier, isAuthorisation = false, successCallback) {
  const zendeskChat = api('zendesk_chat');

  const paramName = isAuthorisation ? 'authorisation_id' : 'user_id';
  const params: ZendeskAuthParams = {
    [paramName]: identifier,
  };

  zendeskChat.getAuthentication(params).then((res) => {
    if (window.zE) {
      window.zE('messenger', 'show');
      window.zE('messenger', 'loginUser', function (callback) {
        callback(res.data.jwt);
      });
      window.zE('messenger', 'close');
      successCallback(res);
    }
  });
}

export function hideChat() {
  if (!FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT) return;

  window.zE && window.zE('messenger', 'hide');
}
