import React, { ReactElement } from 'react';
import { formatLocalTime } from 'utils/dateFormatter';

import { Wrapper } from './styles';

type AddonVersion = {
  createdAt: string;
  editorFirstName: string;
  id: string;
};

interface Props {
  addonVersions: AddonVersion[];
}

function HistoryLogs({ addonVersions }: Props): ReactElement {
  return (
    <Wrapper className="mt-5">
      {addonVersions.map(({ createdAt, editorFirstName, id }) => (
        <p className="mb-2" key={id}>
          Edited on {formatLocalTime(createdAt, 'minute')} by {editorFirstName}
        </p>
      ))}
    </Wrapper>
  );
}

export default HistoryLogs;
