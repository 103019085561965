import React from 'react';

import { StepSection } from './StepSection';
import { UploadButton } from './UploadButton';

export const UploadSection = () => {
  const handleClickAU = () => {};
  const handleClickNZ = () => {};

  return (
    <StepSection
      index={2}
      title="Upload CSV"
      description="Upload the completed CSV spreadsheets."
    >
      <UploadButton region="au" />
      <UploadButton region="nz" />
    </StepSection>
  );
};
