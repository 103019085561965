import React from 'react';

/* Import CSS */
import styles from './css/Review.css';

/* Import components */
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import EmailInput from 'modules/shared/components/inputs/EmailInput';

var ReviewGuarantors = createClass({
  isEmailValid() {
    const { email } = this.props;
    return this.refs.email._validate(email);
  },

  _emailChangeHandler(value, isValid) {
    const { index, onChangeEmail } = this.props;
    onChangeEmail(index, value, isValid);
  },

  _nameChangeHandler(e) {
    const { index, onChangeNameSelection } = this.props;
    onChangeNameSelection(
      index,
      e.value,
      e.percentage_share,
      e.position,
      e.first_name,
      e.last_name,
      e.middle_name
    );
  },

  render() {
    const {
      index,
      css_class,
      name,
      email,
      sort_dropdown,
      error,
      percentage_share,
      position,
      is_applicant,
      first_name,
      last_name,
      middle_name,
    } = this.props;

    return (
      <ReviewContainer
        key={`guarantor-${index}`}
        css_class={css_class}
        subHeading={'Nominate Guarantor'}
      >
        <div className={styles.col}>
          <OptionsDropdown
            key="name"
            id="name"
            error={error[index] && error[index].name}
            handleChange={this._nameChangeHandler}
            value={name}
            label={`Guarantor ${index + 1}`}
            required={true}
            options={sort_dropdown}
            percentage_share={percentage_share}
            position={position}
            disabled={is_applicant}
            first_name={first_name}
            last_name={last_name}
            middle_name={middle_name}
          />
        </div>
        <div className={styles.col}>
          <EmailInput
            key="email"
            id="email"
            name="email"
            ref="email"
            handleChange={this._emailChangeHandler}
            handleBlur={this._emailChangeHandler}
            label="Guarantor email"
            required={true}
            value={email}
            disabled={is_applicant}
          />
        </div>
      </ReviewContainer>
    );
  },
});

module.exports = ReviewGuarantors;
