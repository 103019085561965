/* eslint-disable max-classes-per-file */
function _base64ToArrayBuffer(data) {
  let b64Data = data.replace(/^[^,]+,/, '');

  b64Data = b64Data.replace(/\s/g, '');

  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return byteArray;
}

export function isDownloadPDFWithIE() {
  return window.navigator && window.navigator.msSaveOrOpenBlob;
}

export function iePDFDownloader(data, filename) {
  const arrBuffer = _base64ToArrayBuffer(data);
  const newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

  window.navigator.msSaveOrOpenBlob(newBlob, filename);
}
/* eslint-enable max-classes-per-file */
