import {
  creditCheckHasError,
  creditCheckIsEmpty,
  extractCreditCheckData,
  isCompany,
  isNonRegisteredSoleTrader,
} from './helpers/reportHelpers';

import AboutTheScore from './AboutTheScore';
import Address from './Address';
import AustralianBusinessNumberDetails from './AustralianBusinessNumberDetails';
import Button from 'modules/shared/components/inputs/Button';
import CompanyDocumentListing from './CompanyDocumentListing';
import CourtActions from './CourtActions';
import CourtWrits from './CourtWrits';
import CreditEnquiries from './CreditEnquiries';
import CreditHistorySnapshot from './CreditHistorySnapshot';
import CurrentDirectors from './CurrentDirectors';
import CurrentSecretaries from './CurrentSecretaries';
import DirectorsAdverse from './DirectorsAdverse';
import Documents from './Documents';
import FileMessages from './FileMessages';
import Header from './Header';
import Identity from './Identity';
import IndustryClassifications from './IndustryClassifications';
import MercantileAgentEnquiries from './MercantileAgentEnquiries';
import PPSRRegistrationSummaryAndFilter from './PPSRRegistrationSummaryAndFilter';
import PPSRRegistrations from './PPSRRegistrations';
import PPSRReportInformation from './PPSRReportInformation';
import PaymentDefaults from './PaymentDefaults';
import PublicRecordInformation from './PublicRecordInformation';
import React, { Fragment } from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import Score from './Score';
import SoleIndividual from './SoleIndividual';
import logo from 'images/logo-equifax.png';
import styles from '../../../css/CommonReviewBusiness.css';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import get from 'lodash.get';
import CurrentShareholders from './CurrentShareholders';
import NewCourtActions from './NewCourtActions';
import { FEATURE_FLAGS } from 'conf';
import BrokerEnquiries from './BrokerEnquiries';
import ReportPdf from './components/shared/ReportPdf';
import ReportSection from './components/shared/ReportSection';

const hasErrors = (data) => {
  if (creditCheckHasError(data)) {
    return true;
  }

  const report = get(data, 'response.company_enquiry_report');
  if (isPresent(report)) {
    return false;
  }

  const attributes = (data || {}).attributes || {};
  return (
    attributes.status === 'errored' ||
    attributes.failed ||
    isBlank(attributes.data)
  );
};

var EquifaxComponent = createClass({
  _companyCreditCheck(entityType) {
    const { data, consumer, pdf, signed_pdf } = this.props;

    return (
      <div>
        <div className={styles.header_with_logo}>
          <h2 className={styles.heading_large}>Company Scored Enquiry</h2>
          <div className={styles.equifax_logo}>
            <img src={logo} alt="Equifax" width="277.9" height="79.1" />
          </div>
        </div>
        <Header data={data} entityType={entityType} consumer={consumer} />
        {this._expandAllButton()}
        <Score
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <Identity
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <PublicRecordInformation
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CreditHistorySnapshot
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <DirectorsAdverse
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <AustralianBusinessNumberDetails
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <IndustryClassifications
          data={data}
          entity={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <PPSRRegistrations
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <Documents
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <AboutTheScore
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CurrentDirectors
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CurrentSecretaries
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <FileMessages
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <PaymentDefaults
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CreditEnquiries
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        {FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_FIELDS && (
          <Fragment>
            <BrokerEnquiries
              data={data}
              entityType={entityType}
              isAllExpanded={this.state.isAllExpanded}
            />
            <MercantileAgentEnquiries
              data={data}
              entityType={entityType}
              isAllExpanded={this.state.isAllExpanded}
            />
            <CurrentShareholders
              data={data}
              entityType={entityType}
              isAllExpanded={this.state.isAllExpanded}
            />
          </Fragment>
        )}

        <Address
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        {FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_FIELDS && (
          <NewCourtActions
            data={data}
            entityType={entityType}
            isAllExpanded={this.state.isAllExpanded}
          />
        )}
        <PPSRReportInformation
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <PPSRRegistrationSummaryAndFilter
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CompanyDocumentListing
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        {this._expandAllButton()}
        <ReportSection>
          <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
        </ReportSection>
      </div>
    );
  },

  _businessCreditCheck(entityType) {
    const { data, consumer, pdf, signed_pdf } = this.props;

    return (
      <div>
        <div className={styles.header_with_logo}>
          <h2 className={styles.heading_large}>Business Scored Enquiry</h2>
          <div className={styles.equifax_logo}>
            <img src={logo} alt="Equifax" width="277.9" height="79.1" />
          </div>
        </div>
        <Header data={data} entityType={entityType} consumer={consumer} />
        {this._expandAllButton()}
        <Score
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <Identity
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CreditHistorySnapshot
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <PublicRecordInformation
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <AustralianBusinessNumberDetails
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <IndustryClassifications
          data={data}
          entity={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <PPSRRegistrations
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <AboutTheScore
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <SoleIndividual
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CreditEnquiries
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <MercantileAgentEnquiries
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <Address
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CourtWrits
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <CourtActions
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        <FileMessages
          data={data}
          entityType={entityType}
          isAllExpanded={this.state.isAllExpanded}
        />
        {this._expandAllButton()}
        <ReportSection>
          <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
        </ReportSection>
      </div>
    );
  },

  _nonRegisteredSoleTrader() {
    const { data, consumer } = this.props;

    let creditCheckData = extractCreditCheckData(data);

    return (
      <div>
        <ReviewContainer
          subHeading={consumer.attributes.trading_name}
          content_class="content_wide"
          css_class="block_noborder"
        >
          <p>{creditCheckData.message}</p>
        </ReviewContainer>
      </div>
    );
  },

  _errorDescription() {
    const { data } = this.props;

    let creditCheckData = extractCreditCheckData(data),
      fault = creditCheckData.fault || {},
      faultString = fault.faultstring,
      detail = fault.detail || {},
      error = detail.error || {};

    let errorMessage = error.description;

    if (
      errorMessage === null ||
      errorMessage === undefined ||
      errorMessage === 'null'
    ) {
      errorMessage = faultString;
    }

    return <p>{errorMessage}</p>;
  },

  _errorPage() {
    const { data, consumer } = this.props;

    return (
      <div>
        <ReviewContainer
          subHeading={consumer.attributes.trading_name}
          content_class="content_wide"
          css_class="block_noborder"
        >
          {this._errorDescription()}
          <p>
            Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
            or live chat with us.
          </p>
        </ReviewContainer>
      </div>
    );
  },

  getInitialState() {
    return {
      isAllExpanded: false,
    };
  },

  _expandAll() {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  },

  _expandAllButton() {
    let message = '+ expand all';
    if (this.state.isAllExpanded) message = '- collapse all';

    return (
      <Button
        small
        text={message}
        style={{ fontSize: '10px', margin: '1em 0 1em 66px' }}
        handleClick={this._expandAll}
      />
    );
  },

  render() {
    const { data, consumer, entityType: entityTypeProp } = this.props;

    if (creditCheckIsEmpty(data)) return null;
    if (hasErrors(data)) return this._errorPage();
    if (isNonRegisteredSoleTrader(data)) return this._nonRegisteredSoleTrader();

    let entityType = entityTypeProp || consumer.attributes.legal_type;

    if (isCompany(entityType)) return this._companyCreditCheck(entityType);

    entityType = 'business'; // Trust, Partnership and Sole-traders are considered business types
    return this._businessCreditCheck(entityType);
  },
});

export default EquifaxComponent;
