import React, { Fragment, ReactElement } from 'react';

const NewZealandBankAccountDetails = ({
  bankAccountName,
  bankAccountNumber,
}: {
  bankAccountName: string;
  bankAccountNumber: string;
}): ReactElement => (
  <Fragment>
    &nbsp;
    <strong>
      {bankAccountName}({bankAccountNumber})
    </strong>
  </Fragment>
);

export default NewZealandBankAccountDetails;
