import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import React from 'react';

const ReportSection = (props) => {
  const {
    sectionHeader,
    sectionSubheader,
    children,
    headerProps,
    subHeaderClas,
  } = props;

  const { contentClass, cssClass, fontSize } = headerProps || {};

  return (
    <ReviewContainer
      subHeading={sectionHeader}
      content_class={contentClass || 'content_wide'}
      css_class={cssClass || 'block_noborder'}
      fontSize={fontSize === 'default' ? undefined : fontSize || 'medium'}
    >
      <div>
        {sectionSubheader && (
          <p className={'is-pulled-right' || subHeaderClas}>
            {sectionSubheader}
          </p>
        )}
        {children}
      </div>
    </ReviewContainer>
  );
};

export default ReportSection;
