import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection3 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>3.</span> Service
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.1</span>{' '}
          <strong>Access</strong>: Subject to the Supplier’s proper performance
          of its obligations, including payment of all Fees when due, 1Centre
          will permit the Supplier to access and use the Service on these Terms.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.2</span>{' '}
          <strong>Use of the Service</strong>: When accessing or using the
          Service, the Supplier must access or use the Service solely for its
          own lawful internal business purposes within New Zealand in accordance
          with all reasonable instructions and directions given by 1Centre
          concerning the use of the Service.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.3</span>{' '}
          <strong>Provision of Service</strong>: 1Centre endeavours to provide
          uninterrupted service at all times to the Platform; however the
          Service provided to the Supplier is not continuous or fault-free and
          relies on factors outside the control of 1Centre. The Service is
          provided to the Supplier at such times and means as 1Centre decides.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.4</span>{' '}
          <strong>Suspension of Service</strong>: 1Centre may from time to time
          suspend any Service or disconnect or deny the Supplier access to any
          Service:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> during any technical
          failure, modification or maintenance that relates to the Service
          although 1Centre will endeavour to procure the resumption of the
          Services as reasonably practicable;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> where the Supplier
          undermines the security or integrity of the Service; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> if the Supplier is, in
          1Centre’s opinion, in breach of these Terms.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.5</span>{' '}
          <strong>Notice of suspension</strong>: Where 1Centre suspends the
          Service (for reasons other than non-payment) 1Centre will endeavour to
          give reasonable notice to the Supplier.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.6</span>{' '}
          <strong>Payment still due</strong>: Notwithstanding any suspension of
          any Service under clause 3.5 the Supplier shall remain liable for all
          Fees due through the period of suspension.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.7</span>{' '}
          <strong>Supplier Personnel</strong>: The Supplier shall ensure that
          use of the Service is restricted to Supplier Personnel and solely for
          the purposes of the Suppliers internal business. The Supplier must
          ensure that:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> all Supplier Personnel
          are made aware of these Terms and comply; and
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> all Supplier Personnel
          comply with all security and operational requirements relating to the
          use of the Service notified by 1Centre to the Supplier from time to
          time.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.8</span>{' '}
          <strong>Use restrictions</strong>: The Supplier must not:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> use the Service for any
          purpose not expressly permitted by these Terms;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> modify, adapt, enhance,
          decompile, disassemble, or reverse engineer the Platform (or any other
          aspect of the Service), or attempt to do the same, except as expressly
          permitted by law; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> resupply the Service or
          otherwise make the Service available to any person other than Supplier
          Personnel or a Business Customer (and then only in relation to the
          Supplier’s internal business), except with 1Centre’s prior written
          consent in each instance.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.9</span>{' '}
          <strong>Changes to Service</strong>: 1Centre may make modifications
          and enhancements to the Service from time to time without notice to
          the Supplier provided that such modifications and enhancements do not
          materially impair the functionality of the Service.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>3.10</span>{' '}
          <strong>Acknowledgement</strong>: The Supplier acknowledges that for a
          Business Customer to be able to access and use the Platform, and
          therefore submit a Trade Account Application, the Business Customer
          must agree to the Business Customer Terms.
        </p>
      </div>
    );
  }
}
