import TextField from '@material-ui/core/TextField';
import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import DateTimePicker from 'modules/shared/components/inputs/Timepicker';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import React, { ReactElement, useEffect, useState } from 'react';

import {
  UnusualApplicationText,
  UnusualApplicationTimeInputFields,
  WatchtowerDynamicFieldsTitle,
} from '../styles';

const UnusualApplicationTimeInput = (props): ReactElement => {
  const { value, onChange } = props;
  const [startTime, setStartTime] = useState(value.start_time || '');
  const [endTime, setEndtime] = useState(value.end_time || '');

  useEffect(() => {
    onChange({
      ...props.value,
      start_time: startTime,
    });
  }, [startTime]);

  useEffect(() => {
    onChange({
      ...props.value,
      end_time: endTime,
    });
  }, [endTime]);

  return (
    <ContentContainer>
      <div className="pb-4">
        <div className={`${styles.info} pb-4`}>
          <WatchtowerDynamicFieldsTitle>
            Unusual application time
          </WatchtowerDynamicFieldsTitle>
          {
            'Please select the time range considered unusual, and you will be alerted when an application start time falls into the range'
          }
        </div>
        <div className="columns">
          <div className="column is-6">
            <UnusualApplicationTimeInputFields>
              <DateTimePicker
                id="start_time"
                type="time"
                value={startTime}
                onChange={(event) => {
                  setStartTime(event.target.value);
                }}
                disabled={props.disabled}
              />
              <UnusualApplicationText>to</UnusualApplicationText>
              <DateTimePicker
                id="end_time"
                type="time"
                value={endTime}
                onChange={(event) => {
                  setEndtime(event.target.value);
                }}
                disabled={props.disabled}
              />
            </UnusualApplicationTimeInputFields>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default UnusualApplicationTimeInput;
