import { Autocomplete } from '@material-ui/lab';
import { Button } from 'mui-latest';
import styled from 'styled-components';

const actionButtonStyle = `
color: var(--main-color);
text-transform: none;
background-color: transparent;
width: fit-content;
min-width: unset;
height: fit-content;
:hover {
  color: var(--main-color-active);
  background-color: transparent;
}`;

export const MultipleFieldAddButton = styled(Button)`
  &&& {
    ${actionButtonStyle}
  }
`;

export const MultipleFieldRemoveButton = styled(Button)`
  &&& {
    padding: 10px;
    color: gray;
    ${actionButtonStyle}
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  &&& {
    padding: 0;
    color: gray;
    ${actionButtonStyle}
  }
`;
