export function extractCreditCheckData(data) {
  return data;
}

export function isCompany(entityType) {
  const COMPANY = 'company';

  return entityType === COMPANY;
}

export function isBusiness(entityType) {
  return !isCompany(entityType);
}

export function enquiryReportData(data, entityType) {
  let creditCheckData = extractCreditCheckData(data),
    enquiryReportKey = entityType.concat('_enquiry_report');

  return creditCheckData.response[enquiryReportKey];
}

export function enquiryResponseData(data, entityType) {
  let reportData = enquiryReportData(data, entityType),
    enquiryResponseKey = entityType.concat('_response');

  return reportData[enquiryResponseKey];
}

export function creditCheckHasError(data) {
  let creditCheckData = extractCreditCheckData(data);

  return creditCheckData.hasOwnProperty('fault');
}

export function creditCheckIsEmpty(data) {
  let creditCheckData = extractCreditCheckData(data);

  return creditCheckData === null || creditCheckData === undefined;
}

export function isNonRegisteredSoleTrader(data) {
  let creditCheckData = extractCreditCheckData(data);

  return creditCheckData.sole_trader && creditCheckData.business_not_registered;
}
