import styled from 'styled-components';
import { COLORS } from 'variables/theme';
import { FONT_SIZES } from 'variables/typography';

export const Wrapper = styled.div`
  background: ${COLORS.white};
  max-width: 500px;
  padding: rem;

  p {
    overflow-wrap: anywhere;
  }

  a {
    overflow-wrap: break-word;
  }

  > div > div:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export const TitleWrapper = styled.h3`
  font-size: ${FONT_SIZES.title};
  margin-bottom: 10px;
`;

export const LinkWrapper = styled.div`
  a:hover {
    color: var(--main-color-active) !important;
  }
`;

export const CheckboxWrapper = styled.div`
  & > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const CheckboxContainer = styled.div`
  & > div {
    @media (max-width: 767px) {
      float: initial !important;
    }
  }
`;
