import CreditWorksComponentV1 from 'modules/applications/components/credit-checks/credit-works/v1/CreditWorksComponent';
import CreditWorksComponentV2 from 'modules/applications/components/credit-checks/credit-works/v2/CreditWorksComponent';
import React, { ReactElement } from 'react';

interface Props {
  version: number;
}

const Components = {
  1: CreditWorksComponentV1,
  2: CreditWorksComponentV2,
};

function CreditWorksComponent(props: Props): ReactElement {
  const { version } = props;
  const Component = Components[version];

  return <Component {...props} />;
}

export default CreditWorksComponent;
