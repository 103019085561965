import get from 'lodash.get';
import UserModel from 'models/UserModel';
import VehicleDetails from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms/MVCollateralDetailsForm/VehicleDetails';
import VehicleLookup from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRCollateralAdditionalForms/CollateralsAdditionalForms/MVCollateralDetailsForm/VehicleLookup';
import React, { Fragment, ReactElement, useState } from 'react';
import { Field } from 'react-hook-form';
import isPresent from 'utils/isPresent';

const MotorVehicleCollateralDetailsForm = (props: {
  collateralPrefix: string;
  currentUser: UserModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: { [key: string]: any };
  field: Field;
  onSetAlert: ({ message, type }: { message: string; type: string }) => null;
  register: () => null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (key: string, value: any) => null;
  disabled: boolean;
}): ReactElement => {
  const {
    collateralPrefix,
    currentUser,
    errors,
    field,
    onSetAlert,
    register,
    setValue,
    disabled,
  } = props;

  const fieldNamePrefix = `${collateralPrefix}.additionalDetails`;
  const [isVehicleDetailsVisible, setIsVehicleDetailsVisible] =
    useState<boolean>(
      isPresent(get(field, 'additionalDetails.registrationPlate'))
    );

  const onShowVehicleDetails = () => setIsVehicleDetailsVisible(true);

  return (
    <Fragment>
      <VehicleLookup
        currentUser={currentUser}
        fieldNamePrefix={fieldNamePrefix}
        isVehicleDetailsVisible={isVehicleDetailsVisible}
        onSetAlert={onSetAlert}
        setIsVehicleDetailsVisible={onShowVehicleDetails}
        setValue={setValue}
      />

      <input
        name={`${fieldNamePrefix}.itemId`}
        type="hidden"
        ref={register()}
        defaultValue={field[`${fieldNamePrefix}.itemId`]}
        disabled={disabled}
      />
      <VehicleDetails
        isVisible={isVehicleDetailsVisible}
        errors={errors}
        field={field}
        fieldNamePrefix={fieldNamePrefix}
        register={register}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default MotorVehicleCollateralDetailsForm;
