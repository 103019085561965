import React from 'react';
import styles from './css/FileWidgetButton.css';

var FileWidgetButton = createClass({
  setDragOver: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isDrag: true,
      text: 'Let it go, let it go',
    });
  },

  setDragOut: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState(this.getDefaultState());
  },

  fileDrop: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isDrag: false,
      isLoading: true,
    });
    this.processFile(event.dataTransfer.files);
  },

  fileAdded: function(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isLoading: true,
    });
    this.processFile(event.target.files);
  },

  fileReady: function(data) {
    const { handleFileCallback, edit_text } = this.props;
    this.setState({
      isLoading: false,
      isLoaded: true,
      text: edit_text ? edit_text : 'edit file',
    });
    handleFileCallback(data.target.result, this.state.file_name);
  },

  processFile: function(files) {
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = this.fileReady;
      reader.readAsDataURL(files[0]);
      this.setState({
        file_name: files[0].name,
      });
    }
  },

  getDefaultState: function() {
    return {
      isLoading: false,
      isLoaded: false,
      isDrag: false,
      text: this.props.text || '',
      file_name: '',
    };
  },

  getInitialState: function() {
    return this.getDefaultState();
  },

  componentDidUpdate: function(prevProps, prevState) {
    const { text } = this.props;
    if (prevProps.text !== text) {
      this.setState({
        text: text || prevProps.text,
        file_name: '',
      });
    }
  },

  render: function() {
    const {
      accept,
      inputId,
      isCompact,
      completeWidget,
      theme,
      disabled,
    } = this.props;
    const { isDrag, isLoading, isLoaded, btnText, text } = this.state;

    let theme_style = styles;
    let label_text = text;

    if (theme) {
      theme_style = theme;
    }

    // Allows for is loading styles
    var innerStyles = theme_style.inner;

    // Allows a parent to define the completed Widget, ie logo image or file icon etc.
    var widget = <p className={theme_style.text}>{text}</p>;
    if (completeWidget) {
      widget = completeWidget;
      containerStyles = theme_style.completed;
    }

    let labelClassName = theme_style.label;
    if (isCompact) {
      labelClassName += ` ${styles.is_compact}`;
    }

    return (
      <div>
        <input
          accept={accept}
          onChange={this.fileAdded}
          type="file"
          name="logoFile"
          id={inputId || 'logoFile'}
          className={theme_style.file}
          disabled={disabled}
        />
        <label className={labelClassName} htmlFor={inputId || 'logoFile'}>
          {label_text}
        </label>
      </div>
    );
  },
});

export default FileWidgetButton;
