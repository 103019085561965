import Modal from 'modules/dashboard/containers/components/Modal';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement, useState } from 'react';

import {
  ConfirmAcnModalButtonsWrapper,
  ConfirmAcnTitle,
  ConfirmAcnBoldItalicText,
} from './styles';
import { createCorporateTrustee } from '../../utils/corporateTrustees';
import api from 'api';
import { useSnackbar } from 'notistack';
import {
  setCorporateTrusteeDirectorsOfAssociatedEntity,
  setEntityPartyDetailsData,
} from 'modules/consumer-onboarding/actions/onboarding';
import get from 'lodash.get';

type IConfirmAcnModal = {
  onConfirm: ({
    corporateTrusteeId,
    hasPopulatedDirectors,
  }: {
    corporateTrusteeId: string;
    hasPopulatedDirectors: boolean;
  }) => void;
  onCancel: () => void;
  applicationId: string;
  entityId: string;
  trustee: any;
  componentIndex: number;
  dispatch: any;
};

function ConfirmAcnModal(props: IConfirmAcnModal): ReactElement {
  const {
    onConfirm,
    onCancel,
    applicationId,
    entityId,
    trustee,
    componentIndex,
    dispatch,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = useState(false);
  const [id, setId] = useState('');

  const handleProcced = async () => {
    setSubmitting(true);

    let corporateTrusteeId = id;

    if (!id) {
      corporateTrusteeId = await createCorporateTrustee({
        applicationId,
        entityId,
        trustee,
      });
      dispatch(
        setEntityPartyDetailsData(
          componentIndex,
          'corporate_trustee_id',
          corporateTrusteeId
        )
      );
      setId(corporateTrusteeId);
    }

    const creditCheckApi = api('credit_checks');

    try {
      const directorsResponse =
        await creditCheckApi.getCorporateTrusteeDirectors(
          applicationId,
          corporateTrusteeId
        );

      const directors = get(directorsResponse, 'data.directors', []);

      if (directors && directors.length) {
        const directorsPayload = directors.map((director) => ({
          appointed_at: director.appointment_date,
          first_name: director.first_name,
          middle_name: director.middle_name,
          last_name: director.last_name,
          name: director.middle_name
            ? `${director.first_name} ${director.middle_name} ${director.last_name}`
            : `${director.first_name} ${director.last_name}`,
          email: null,
          full_address: director.address,
          legal_type: 'director',
          entity_id: entityId,
        }));
        const associatedEntityApi = api('associated_entities');
        await associatedEntityApi.createPeople(corporateTrusteeId, {
          people: directorsPayload,
        });

        const directorsForStore = directorsPayload.map((director) => ({
          first_name: director.first_name,
          middle_name: director.middle_name,
          last_name: director.last_name,
          name: director.name,
          legal_type: 'director',
        }));
        dispatch(
          setCorporateTrusteeDirectorsOfAssociatedEntity(
            corporateTrusteeId,
            directorsForStore
          )
        );
        dispatch(
          setEntityPartyDetailsData(
            componentIndex,
            'corporate_trustee_director_count',
            directorsForStore.length
          )
        );

        onConfirm({ corporateTrusteeId, hasPopulatedDirectors: true });
      } else {
        onConfirm({ corporateTrusteeId, hasPopulatedDirectors: false });
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      onClose={onCancel}
      title={
        <ConfirmAcnTitle>
          <span>Confirm ACN</span>
        </ConfirmAcnTitle>
      }
      content={
        <p>
          Once proceeded, you won't be able to amend the ACN. Please{' '}
          <ConfirmAcnBoldItalicText>
            double check the ACN and the Company name
          </ConfirmAcnBoldItalicText>
          , only continue if both details are correct.
        </p>
      }
      footer={
        <ConfirmAcnModalButtonsWrapper>
          <Button
            text="Yes, proceed"
            handleClick={handleProcced}
            loading={submitting}
            disableOnLoading
          />
          <Button
            text="Cancel"
            white={true}
            handleClick={onCancel}
            disabled={submitting}
          />
        </ConfirmAcnModalButtonsWrapper>
      }
      dataCy="corporate-trustee-confirm-acn-modal"
    />
  );
}

export default ConfirmAcnModal;
