import styles from 'modules/addons/components/css/AddOnsDetails.css';
import getAccountTypeCheckboxOptions from 'modules/addons/sales_channels/shared/getAccountTypeCheckboxOptions';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Error, Title, Wrapper } from './styles';

type Props = {
  description?: string;
  error: string | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  readOnly?: boolean;
  selected: string[];
};

function AccountTypeCheckbox({
  description,
  error,
  handleChange,
  label,
  readOnly,
  selected,
}: Props): JSX.Element {
  const standalone = !label && !description;
  const typeOptions = useMemo(getAccountTypeCheckboxOptions, []);

  return (
    <Wrapper
      className={`${styles.section} ${standalone ? styles.standalone : ''}`}
    >
      {label && <Title>{label}</Title>}
      {description && <p>{description}</p>}
      {error && (
        <Error className="notification is-danger is-light">{error}</Error>
      )}
      <div className={styles.row}>
        {Object.keys(typeOptions).map((key) => (
          <div
            className={standalone ? styles.standalone_col : styles.col}
            key={`checkbox-${key}`}
          >
            <SquareCheckbox
              key={key}
              checkboxId={key}
              checkboxName={key}
              checked={selected.includes(key)}
              handleChange={handleChange}
              label={typeOptions[key].label}
              disabled={readOnly}
            />
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

AccountTypeCheckbox.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  selected: PropTypes.array,
};

AccountTypeCheckbox.defaultProps = {
  selected: [],
};

export default AccountTypeCheckbox;
