const sanitizeName = (str: string): string => {
  try {
    return str
      .replace(/[^A-Z0-9]/gi, '')
      .toLowerCase()
      .trim();
  } catch (e) {
    console.error(e);
    return str;
  }
};

const isSanitizedStringEqual = (string1: string, string2: string): boolean => {
  return sanitizeName(string1) === sanitizeName(string2);
};

export default sanitizeName;
export { isSanitizedStringEqual };
