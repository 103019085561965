import React from 'react';
import { connect } from 'react-redux';

import styles from './css/Loader.css';

var Loader = createClass({
  render: function() {
    const { message, state } = this.props;
    let loader_title = null;
    let loading_styles = styles.loading;

    if (state === 'done') {
      loading_styles = styles.loading_complete;
    }

    if (message) {
      loader_title = <h1 className={styles.title}>{message}</h1>;
    }

    return (
      <div className={styles.loader}>
        {loader_title}
        <div className={loading_styles}>
          <div className={styles.icon}></div>
          <div className={styles.circle_first}></div>
          <div className={styles.circle_second}></div>
        </div>
      </div>
    );
  },
});

module.exports = connect((state, ownProps) => {
  return {
    state: state.loader.state,
    active: state.loader.active,
    message: state.loader.message,
  };
})(Loader);
