import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user
 */
const Integrations = function(config) {
  this.axios = axios(config);
  this.type = 'integrations';
};

// TODO: Fix number of parameters and remove `eslint-disable max-params`
/* eslint-disable max-params */
Integrations.prototype.connectIntegration = function(
  entityId,
  service,
  success,
  error
) {
  return this.axios
    .get(`/${service}`)
    .then(success)
    .catch(error);
};
/* eslint-enable max-params */

Integrations.prototype.deleteIntegration = function(
  integration,
  success,
  error
) {
  return this.axios
    .delete(`/${this.type}/${integration.attributes.id}`)
    .then(success)
    .catch(error);
};

Integrations.prototype.save = function(integration, success, error) {
  const { id, ...attributes } = integration;
  const data = { id, attributes, type: this.type };

  if (id) {
    return this.axios
      .patch(`/${this.type}/${id}`, { data })
      .then(success)
      .catch(error);
  }

  return this.axios
    .post(`/${this.type}`, { data })
    .then(success)
    .catch(error);
};

export default Integrations;
