import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ColorOption = createClass({
  propTypes: {
    children: PropTypes.node,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    option: PropTypes.object.isRequired,
  },
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  },
  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  },
  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  },
  render() {
    let colorStyle = {
      borderRadius: 3,
      display: 'inline-block',
      marginRight: 10,
      position: 'relative',
      top: -2,
      verticalAlign: 'middle',
      minWidth: '10px',
      minHeight: '10px',
      borderRadius: '50%',
      backgroundColor: this.props.option.color,
    };
    if (!this.props.option.color) {
      colorStyle.border = '1px solid #00afef';
    }
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
      >
        <div style={colorStyle} />
        {this.props.children}
      </div>
    );
  },
});
export default ColorOption;
