import React, { Fragment, useEffect, useState } from 'react';
import MenuItem from '../MenuItem';
//@ts-ignore
import { browserHistory } from 'react-router';
import get from 'lodash.get';
import SubMenuItem from '../SubMenuItem';
import { FEATURE_FLAGS } from 'conf';
import api from 'api';
import usePolling from 'hooks/usePolling';
import isBlank from 'utils/isBlank';
import MenuItemIndicator from '../MenuItemIndicator';
import DropdownIcon from './DropdownIcon';
import NavSearch from './NavSearch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { AddonNoticeModal } from './AddonNoticeModal';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const MODULES_MENU_MAPPING = {
  watchtower_module: {
    path: '/dashboard/watchtower',
    title: 'Watchtower',
  },
  direct_debit_module: {
    path: '/dashboard/direct_debit_authorisations',
    title: 'Direct debit',
  },
  card_management_module: {
    path: '/dashboard/card_orders',
    title: 'Card mgmt',
  },
  ppsr_module: {
    path: '/dashboard/ppsr_registrations',
    title: 'PPSR',
  },
  alert_module: {
    path: '/dashboard/alert_applications',
    title: 'Alerts',
  },
};

const NavMenuSearch = (props) => {
  const { current_user, current_entity, displayUserProfile, username } = props;
  const addon_enabled = get(
    current_user,
    'current_entity.attributes.addon_enabled',
    false
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = !useMediaQuery(theme.breakpoints.up('lg'));

  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(null);
  const [navActive, setNavActive] = useState(null);
  const [navToggle, setNavToggle] = useState(false);
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const handleLinkClick = (url, name) => {
    const { location } = props;

    if (url === '/dashboard/addons' && name === 'addons' && !addon_enabled) {
      // ! TODO
      setShowModal(true);
      return;
    }

    setNavToggle(false);
    setActive(null);
    setNavActive(name);

    browserHistory.push({
      pathname: url,
      state: {
        referrer: location.pathname,
        userTriggered: true,
      },
    });
  };

  const roleTypes = get(
    current_user,
    'current_user_entity_link.attributes.role_types'
  );
  const isConsumerOnly =
    roleTypes.length === 1 && roleTypes.includes('consumer_manager');
  const isSupplier = !!current_entity.attributes.supplier;
  const isSuperAdmin = get(
    current_user,
    'data.data.attributes.super_admin',
    false
  );
  const isAdmin = roleTypes.includes('admin') || isSuperAdmin;

  const onDashboardClick = () => {
    if (isConsumerOnly) {
      handleLinkClick('/dashboard/reporting', 'home');
    } else {
      handleLinkClick('/dashboard/home', 'home');
    }
  };

  const showTooltip = () => {
    setIsTooltipActive(true);
  };

  const toggleDropDown = (id) => {
    if (active === id) {
      setActive(null);
    } else {
      setActive(id);
    }
    if (id === 'settings') {
      showTooltip();
    }
  };

  const hideModal = () => setShowModal(false);

  const SettingsMenu = () => (
    <MenuItem
      visible={true}
      active={active === 'settings'}
      navActive={navActive === 'settings'}
      icon={'setting'}
      target={() => toggleDropDown('settings')}
      showTooltip={showTooltip}
      dataCy="navbar-settings-menu"
    >
      {isDesktop && (
        <Fragment>
          <SubMenuItem
            title="Suppliers"
            id="profile"
            dataCy="navbar-settings-menu-suppliers-option"
            target={() =>
              handleLinkClick('/dashboard/supplier_list', 'supplier_list')
            }
            visible={isSuperAdmin}
          />
          <SubMenuItem
            title="Your Profile"
            id="profile"
            dataCy="navbar-settings-menu-your-profile-option"
            target={() =>
              handleLinkClick('/dashboard/users/profile', 'profile')
            }
            visible={displayUserProfile}
          />
          <SubMenuItem
            title="Business Profile"
            id="profile"
            dataCy="navbar-settings-menu-business-profile-option"
            target={() => handleLinkClick('/dashboard/profile', 'profile')}
            visible={isAdmin && isSupplier}
          />
          <SubMenuItem
            title="Add ons"
            id="addons"
            dataCy="navbar-settings-menu-add-ons-option"
            target={() => handleLinkClick('/dashboard/addons', 'addons')}
            visible={isAdmin && isSupplier && isDesktop}
            hiddenxs={true}
          />
          <SubMenuItem
            title={'Account'}
            id={'account'}
            dataCy="navbar-settings-menu-account-option"
            target={() =>
              handleLinkClick('/dashboard/account-settings', 'settings')
            }
            visible={isAdmin && isSupplier}
            hiddenxs={true}
          />
          <SubMenuItem
            title={'Pricing'}
            id={'pricingPlans'}
            dataCy="navbar-settings-menu-pricing-option"
            target={() =>
              handleLinkClick('/dashboard/pricing-plans', 'settings')
            }
            visible={isAdmin && isSupplier}
            hiddenxs={true}
          />
          <SubMenuItem
            title={'Integrations'}
            id={'connectedApps'}
            dataCy="navbar-settings-menu-integrations-option"
            target={() =>
              handleLinkClick('/dashboard/connected-apps/webhooks', 'settings')
            }
            visible={isAdmin && isSupplier}
            hiddenxs={true}
          />
        </Fragment>
      )}
      <SubMenuItem
        title={'Log Out'}
        id={'logout'}
        target={() => handleLinkClick('/user/logout', 'settings')}
      />
    </MenuItem>
  );

  const ModuleMenu = () => {
    const [submenuAlerts, setSubmenuAlerts] = useState<any>({
      watchtower_module: 0,
    });

    const notificationsApi = api(
      'notifications',
      current_user.access_token,
      current_user.current_entity.id
    );

    async function getWatchtowerNotifsCount() {
      const result = await notificationsApi.watchtower_unreads();
      const alertCount = result.data.count;
      if (submenuAlerts.watchtower_module !== alertCount) {
        setSubmenuAlerts({ watchtower_module: alertCount });
      }
    }

    if (FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER) {
      useEffect(() => {
        getWatchtowerNotifsCount();
      }, []);

      usePolling(getWatchtowerNotifsCount);
    }

    if (!isDesktop) {
      return null;
    }

    const modulesConfigured = get(
      current_user,
      'current_entity.attributes.modules_configured',
      []
    ).filter((module) => module !== 'one_acv_module');

    if (
      (!roleTypes.includes('admin') && !roleTypes.includes('approver')) ||
      isBlank(modulesConfigured)
    ) {
      return null;
    }

    const clearAlert = async (module) => {
      const onClearSuccess = () => {
        setSubmenuAlerts({ [module]: 0 });
      };

      await notificationsApi.clear_watchtower(onClearSuccess);
    };

    const subMenuItems = modulesConfigured.map((module, index) => {
      // Prevent watch tower module option from showing up in prod
      if (
        !FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER &&
        module === 'watchtower_module'
      ) {
        return;
      }
      if (FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER && module === 'alert_module') {
        return;
      }

      const menuMapping = MODULES_MENU_MAPPING[module];

      const handleClick = () => {
        if (FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER && submenuAlerts[module]) {
          clearAlert(module);
        }
        handleLinkClick(menuMapping.path, 'credit_control');
      };

      const submenuAlertLabel = {
        watchtower_module: 'unactioned alerts',
      };

      return (
        <SubMenuItem
          key={`module-sub-menu-item-${index + 1}`}
          title={menuMapping.title}
          alertCount={submenuAlerts[module]}
          alertLabel={submenuAlertLabel[module]}
          id={module}
          target={handleClick}
        />
      );
    });

    const alertCount = Object.keys(submenuAlerts).reduce((alertCount, key) => {
      alertCount = alertCount + submenuAlerts[key];
      return alertCount;
    }, 0);

    const subMenuItemsCount = subMenuItems.filter((subMenuItem) =>
      Boolean(subMenuItem)
    ).length;

    if (!subMenuItemsCount) {
      return null;
    }

    return (
      <MenuItem
        title={
          <span className="dashboard-header_nav-list-dropdown-title">
            {FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER
              ? 'Modules'
              : 'Credit control'}{' '}
            <DropdownIcon />
          </span>
        }
        visible={true}
        dataCy="navbar-modules-dropdown"
        active={active === 'credit_control'}
        navActive={navActive === 'credit_control'}
        icon={'credit_control'}
        target={() => toggleDropDown('credit_control')}
        alertCount={alertCount}
      >
        {subMenuItems}
      </MenuItem>
    );
  };

  const PipelineMenuItem = () => (
    <MenuItem
      title={
        <span className="dashboard-header_nav-list-dropdown-title">
          Pipeline <DropdownIcon />
        </span>
      }
      visible={isDesktop}
      dataCy="navbar-pipelines-dropdown"
      active={active === 'applications'}
      navActive={navActive === 'applications'}
      icon={'application'}
      target={() => toggleDropDown('applications')}
    >
      <Fragment>
        <SubMenuItem
          title="Sales"
          id="sales"
          dataCy="navbar-pipelines-dropdown-sales-pipeline"
          target={() => handleLinkClick('/dashboard/reporting', 'applications')}
          visible={isSupplier}
        />
        {FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIPELINE && (
          <SubMenuItem
            title="Customer"
            id="customer"
            dataCy="navbar-pipelines-dropdown-customer-pipeline"
            target={() =>
              handleLinkClick('/dashboard/customers', 'applications')
            }
            visible={isSupplier}
          />
        )}
        <SubMenuItem
          title={current_entity.attributes.lead_name || 'Leads'}
          id="leads"
          dataCy="navbar-pipelines-dropdown-leads-pipeline"
          target={() => handleLinkClick('/dashboard/leads/list', 'leads')}
          visible={isSupplier}
        />
      </Fragment>
    </MenuItem>
  );

  return (
    <Fragment>
      {showModal && <AddonNoticeModal isOpen={showModal} onClose={hideModal} />}
      <div className="dashboard-header_nav-list-container">
        <div className="dashboard-header_nav-list--left">
          <ul className="dashboard-header_nav-list">
            <MenuItem
              title={'Dashboard'}
              dataCy="navbar-dashboard-home"
              visible={isSupplier}
              active={active === 'home'}
              navActive={navActive === 'home'}
              icon={'home'}
              target={onDashboardClick}
            />
            <PipelineMenuItem />
            <ModuleMenu />
          </ul>
        </div>
        <div className="dashboard-header_nav-list--right">
          {isTablet ? null : <NavSearch currentUser={current_user} />}
          <ul className="dashboard-header_nav-list">
            <MenuItemIndicator visible={isDesktop} icon={'notification'} />
            <SettingsMenu />
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default NavMenuSearch;
