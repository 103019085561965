import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from 'variables/theme';

const Container = styled.div<{ $disabled?: boolean }>`
  cursor: pointer;
  p {
    letter-spacing: normal !important;
  }
  span {
    color: var(--main-color);
    text-decoration: underline;
  }

  ${(props) =>
    props.$disabled &&
    css`
      cursor: default;
      color: ${COLORS.mediumGrey};
      span {
        color: ${COLORS.mediumGrey};
        cursor: default;
      }
    `}
`;

type FilterButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

export const FilterButton = ({
  children,
  disabled,
  onClick,
}: FilterButtonProps) => {
  return (
    <Container $disabled={disabled} onClick={onClick}>
      {children}
    </Container>
  );
};
