import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Helmet from 'react-helmet';
import { hideChat, loadChat } from 'utils/zendeskChat';
import { hideLiveChat } from 'utils/liveChat';
import { isMobile } from 'modules/shared/helpers/mobileDetect';

const ZendeskChat = (props) => {
  hideLiveChat();

  const {
    loggedIn,
    currentUserId,
    isSupplier,
    isBcFlow,
    hasCurrentEntity,
    authorisationId,
  } = props;

  const isAuthorisation =
    !loggedIn && !hasCurrentEntity && !currentUserId && !!authorisationId;

  const loadChatScript =
    (!loggedIn || (!hasCurrentEntity && !isBcFlow)) && !isAuthorisation;
  if (loadChatScript || isMobile()) return null;

  const ZENDESK_KEYS = {
    consumer: 'f2251e7c-6e1a-4f5c-829a-3c6d978c0561',
    supplier: '0003e11e-12b0-4cfe-9685-9d681b89dbb1',
  };

  const keyPurpose = isSupplier && !isAuthorisation ? 'supplier' : 'consumer';

  const [chatLoaded, setChatLoaded] = useState(false);

  useEffect(() => {
    if (!chatLoaded) {
      const idToLoad = isAuthorisation ? authorisationId : currentUserId;
      loadChat(idToLoad, isAuthorisation, () => setChatLoaded(true));
    }
  }, [loadChatScript, loggedIn]);

  return (
    <Helmet>
      <script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEYS[keyPurpose]}`}
      />
    </Helmet>
  );
};

export default connect((state) => {
  const { loggedIn, current_entity: currentEntity } = state.current_user;
  const hasCurrentEntity = !!currentEntity;
  const isSupplier = hasCurrentEntity && currentEntity.attributes.supplier;
  const currentUserData = get(state, 'current_user.data.data', {});
  const currentUserId = currentUserData.id;
  const authorisationId = get(state, 'authorisation.data.authorisation.id');

  return {
    loggedIn,
    isSupplier,
    currentUserId,
    hasCurrentEntity,
    authorisationId,
  };
})(ZendeskChat);
