import React, { ReactElement } from 'react';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { SelectDropdownProps } from './types';

const entityOptions = [
  { label: 'Applicant', value: 'applicant', brief: 'Applicant' },
  { label: 'Guarantor', value: 'guarantor', brief: 'Guarantor' },
  { label: 'Signatory', value: 'signatory', brief: 'Signatory' },
  { label: 'Cardholder', value: 'cardholder', brief: 'Cardholder' },
  { label: 'Direct debit', value: 'direct_debit', brief: 'Direct debit' },
];

interface AuthorisationTypeSelectDropdownProps extends SelectDropdownProps {
  isAML: boolean;
}

function AuthorisationTypeSelectDropdown(
  props: AuthorisationTypeSelectDropdownProps
): ReactElement {
  const { handleChange, isAML } = props;

  const authorisationEntityOptions = isAML
    ? entityOptions.filter((option) => option.value !== 'direct_debit')
    : entityOptions;

  return (
    <SimpleMultiSelectDropdown
      options={authorisationEntityOptions}
      onChange={handleChange}
      id="entity-type-select"
      {...props}
    />
  );
}

export default AuthorisationTypeSelectDropdown;
