import get from 'lodash.get';
import {
  CLOSE_SIDE_BAR,
  SET_ACTIVE_SIDE_BAR,
  SET_ACTIVE_QUESTION_AND_ANSWER,
} from 'modules/shared/components/top/Overlay/redux/constants';

export const onToggleSidebar = (sidebar: string) => (dispatch, getState) => {
  const activeSidebar = get(
    getState(),
    'applicationOverlayReducer.activeSidebar',
    ''
  );

  const newActiveSidebar = activeSidebar === sidebar ? '' : sidebar;

  return dispatch({
    payload: newActiveSidebar,
    type: SET_ACTIVE_SIDE_BAR,
  });
};

export const onCloseSidebar = () => (dispatch) =>
  dispatch({ type: CLOSE_SIDE_BAR });

export const onSetActiveQuestionAndAnswer = (questionAndAnswer: string) => (
  dispatch
) =>
  dispatch({
    payload: questionAndAnswer,
    type: SET_ACTIVE_QUESTION_AND_ANSWER,
  });
