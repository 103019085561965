import styled from 'styled-components';

export const Link = styled.a`
  color: var(--main-color) !important;
  text-decoration: underline !important ;

  &:hover {
    color: var(--main-color-active) !important;
  }
`;

export const IconWrapper = styled.span`
  color: var(--main-color-active) !important;
  height: fit-content !important;
  width: fit-content !important;
  margin-left: 3px;

  &.inactive {
    color: var(--main-color) !important;
  }
`;
