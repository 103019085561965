import { FEATURE_FLAGS } from 'conf';
import compact from 'lodash.compact';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import isPresent from 'utils/isPresent';

export const MAXIMUM_RETRY_ATTEMPTS = 3;

const isCentraPassAllVerified = ({ dobVerified, idVerified, nameVerified }) =>
  compact([dobVerified, idVerified, nameVerified]).every(
    (status) => status === 'Pass'
  );

export { isCentraPassAllVerified };

export default class IdentificationCheckModel extends BaseModel {
  get isIdentificationCheckSuccess() {
    if (['errored', 'deferred'].includes(this.status)) {
      return false;
    }

    if (this.type === 'IdentificationCheck::NewZealand::Centrix') {
      return get(this.attributes, 'data.data_sets.smart_id.is_verified', false);
    }

    // BT-16222
    if (this.isAML && FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES) {
      return (this.formattedDetails || {}).aml_verified === 'Pass';
    }

    return this.centraPassOutcome;
  }

  get isAML() {
    return this.isCentrixAml || (this.formattedDetails || {}).is_aml;
  }

  get isCentrixAml() {
    return this.checkType === 'aml';
  }

  get isIdentification() {
    return this.checkType === 'identification';
  }

  get amlOutcome() {
    if (!this.isAML) {
      return null;
    }

    if (this.status === 'errored') {
      return 'Errored';
    }

    if (this.status === 'deferred') {
      return this.deferredOutcome;
    }

    if (this.isIdentificationCheckSuccess) {
      return 'Pass';
    }

    return 'Fail';
  }

  get amlPEPWatchlistOutcome() {
    if (!this.isAML) {
      return null;
    }

    let isWatchlistClear = get(
      this.data,
      'data_sets.pep_watchlist_data.international_watchlist_is_clear',
      null
    );

    if (isWatchlistClear === null) {
      const watchlist = get(this.data, 'serviceResponses.Watchlist AML', null);
      isWatchlistClear =
        watchlist === null
          ? null
          : watchlist.status === 2 &&
            Object.keys(watchlist.returnedData).length === 0;
    }

    if (isWatchlistClear === null) {
      return null;
    }

    return isWatchlistClear ? 'Pass' : 'Fail';
  }

  get amlFile() {
    return get(
      this.data,
      'data_sets.pep_watchlist_data.watchlist_data.watchlist_data_item.data_item_value'
    );
  }

  get amlDataSourceMatchResult() {
    return get(
      this.data,
      'data_sets.smart_id.data_source_match_results.data_source_match_result',
      []
    );
  }

  get amlPassportError() {
    const diaPassportVerified = get(
      this.data,
      'data_sets.dia_passport.dia_passport_verified',
      null
    );

    if (diaPassportVerified === null || diaPassportVerified) {
      return null;
    }

    const errorMessage = get(this.data, 'data_sets.dia_passport.error_message');

    if (errorMessage) {
      return {
        content: errorMessage,
        label: 'Passport',
      };
    }

    return null;
  }

  get deferredOutcome() {
    if (this.status !== 'deferred') {
      return null;
    }

    const messageCode = this.data.message_code || '';

    if (messageCode === 'dia_requirement_changes') {
      return 'Cannot verify passport.';
    }

    if (messageCode === 'other_identification_type') {
      return 'Cannot verify other identification types';
    }

    return null;
  }

  get centraPassOutcome() {
    if (
      this.type === 'IdentificationCheck::CentraPass' ||
      this.type === 'IdentificationCheck::DataZoo'
    ) {
      const {
        dob_verified: dobVerified,
        identification_verification_string: idVerified,
        name_verified: nameVerified,
      } = this.formattedDetails;

      return isCentraPassAllVerified({
        dobVerified,
        idVerified,
        nameVerified,
      });
    }

    return null;
  }

  get identificationCheckOutcome() {
    if (this.status === 'errored') {
      return 'Errored';
    }

    if (this.status === 'deferred') {
      return this.deferredOutcome;
    }

    return 'Pending';
  }

  amlErrorFields(identificationType) {
    const dataSource = this.getDataSource(identificationType);
    const errors = [];

    if (this.isIdentificationCheckSuccess) {
      return errors;
    }

    const smartId = get(this.data, 'data_sets.smart_id', {});

    if (smartId.is_verified) {
      return errors;
    }

    if (!smartId.is_name_verified) {
      errors.push({
        content: dataSource.name_match_status,
        label: 'Name',
      });
    }

    if (!smartId.is_date_of_birth_verified) {
      errors.push({
        content: dataSource.date_of_birth_match_status,
        label: 'Date of birth',
      });
    }

    if (!smartId.is_address_verified) {
      errors.push({
        content: dataSource.address_match_status,
        label: 'Address',
      });
    }

    const passportError = this.amlPassportError;
    if (passportError !== null) {
      errors.push(passportError);
    }

    const statusMessage = get(this.data, 'status_messages.status_message', {});
    if (
      statusMessage.message_type === 'Error' &&
      isPresent(statusMessage.message_text)
    ) {
      errors.push({
        content: statusMessage.message_text,
        label: 'Status message',
      });
    }

    return errors;
  }

  /** Private functions */

  getDataSource(identificationType) {
    let dataSourceName = '';

    if (identificationType === 'passport') {
      dataSourceName = 'DIAPassport';
    }

    if (identificationType === 'driver_licence') {
      dataSourceName = 'NZTADriverLicence';
    }

    return (
      this.amlDataSourceMatchResult.find(
        (result) => result.data_source_name === dataSourceName
      ) || {}
    );
  }
}
