import axios from './axios';

const VoucherCodes = function(config) {
  this.axios = axios(config);
  this.type = 'voucher_codes';
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
};

//
// Await-able functions
// The following functions allows the use of async/await
//
VoucherCodes.prototype.validateVoucherCode = function(options) {
  return this.axios.get(`/${this.type}/valid_code`, options);
};

export default VoucherCodes;
