import React from 'react';
import styles from './report-styles.css';
import { FEATURE_FLAGS } from 'conf';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import Button from 'modules/shared/components/inputs/Button';

const ReportPdf = ({ pdf, signed_pdf }) => {
  if (!pdf) return null;

  const pdf_url = FEATURE_FLAGS.FEATURE_FLAG_SECURE_S3 ? signed_pdf : pdf;

  const downloadPdf = (url) => {
    var win = window.open(url, 'equfiax_personal_pdf_window');
  };

  return (
    <Clickable target={() => downloadPdf(pdf_url)}>
      <Button css_style="button_white_outer" text="download pdf" />
    </Clickable>
  );
};

export default ReportPdf;
