import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import styles from 'modules/shared/components/inputs/css/BorderedRegionStatesSelect.css';
import React from 'react';

const regions = [
  { label: 'All', states: false, value: 'All' },
  { label: 'New Zealand', states: false, value: 'NZ' },
  { label: 'Australia', states: true, value: 'AU' },
];

const states = {
  AU: [
    { label: 'All', value: 'All' },
    { label: 'NSW', value: 'NSW' },
    { label: 'QLD', value: 'QLD' },
    { label: 'SA', value: 'SA' },
    { label: 'TAS', value: 'TAS' },
    { label: 'VIC', value: 'VIC' },
    { label: 'WA', value: 'WA' },
    { label: 'ACT', value: 'ACT' },
    { label: 'NT', value: 'NT' },
  ],
};

function StateSelect(props) {
  const { isVisible, isStatesOptionsVisible, handleChange, values } = props;

  if (!isStatesOptionsVisible) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  const handleStateChange = (event) => {
    handleChange({ region: values.region, state: event.target.value });
  };

  return (
    <div className={styles.state_col}>
      <BorderedSelect
        label={'State'}
        value={values.state}
        options={states.AU}
        onChange={handleStateChange}
      />
    </div>
  );
}

export default function BorderedRegionStatesSelect(props) {
  const {
    handleChange,
    isStatesOptionsVisible,
    label,
    values,
    ...regionStatesSelectProps
  } = props;

  const selectedRegion = regions.find(
    (region) => region.value === values.region
  );

  const handleRegionChange = (event) => {
    const selectedRegion = regions.find(
      (region) => region.value === event.target.value
    );
    const hasStates = selectedRegion.states;
    if (hasStates) {
      handleChange({ region: selectedRegion.value, state: values.state });
    } else {
      handleChange({ region: selectedRegion.value });
    }
  };

  return (
    <div className={styles.container}>
      <BorderedSelect
        label={label}
        options={regions}
        onChange={handleRegionChange}
        value={values.region}
        {...regionStatesSelectProps}
      />
      <StateSelect
        isVisible={selectedRegion.states}
        isStatesOptionsVisible={isStatesOptionsVisible}
        handleChange={handleChange}
        values={values}
      />
    </div>
  );
}

BorderedRegionStatesSelect.defaultProps = {
  isStatesOptionsVisible: true,
};
