import React, { ReactElement } from 'react';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';

const RequestApproved = ({
  authorisationRequest,
}: {
  authorisationRequest: AuthorisationRequestModel;
}): ReactElement => {
  const { awaitingPermissions } = authorisationRequest;

  const sentToNames = awaitingPermissions
    .filter((permission) => permission.status === 'approved')
    .map((permission) => permission.requesteeFullName);

  return (
    <>
      <span className="has-text-success icon mr-2">
        <i className="fas fa-check-circle"></i>
      </span>
      Authorised by {sentToNames.join(', ')}.
    </>
  );
};

export default RequestApproved;
