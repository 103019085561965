import styled from 'styled-components';
import { BORDERS } from 'variables/theme';
import { FONT_SIZES, FONT_WEIGHTS } from 'variables/typography';

export const CardholderTitle = styled.div`
  font-size: ${FONT_SIZES.title};
  font-weight: ${FONT_WEIGHTS.normal};
  padding: 15px 0 10px;
  position: relative;
`;

export const CardholderBlock = styled.div`
  margin: 2rem 0 0;
  padding: 1rem 0;

  &:first-child {
    margin-top: -1rem;
  }

  &:not(:only-child):not(:last-child) {
    border-bottom: ${BORDERS.generic};
  }
`;

export const CardholderRow = styled.div`
  // composes: row from "css/grid/grid.css";
  margin-left: 0;
  margin-right: 0;
  padding: 0 6px 0 6px;
`;

export const CardholderButton = styled.div`
  > a {
    margin-left: 10px;
  }
`;

export const CardholderDeleteButton = styled.div`
  position: absolute;
  right: 15px;
  color: main-color;
  vertical-align: middle;
  cursor: pointer;
`;

export const DeleteBtn = styled.button`
  position: absolute;
  right: 0;
`;

export const SubTitle = styled.div`
  padding: 10px 0;
  font-size: ${FONT_SIZES.base};
`;
