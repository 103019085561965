import styles from 'modules/new-applications/css/IdentificationChecks.css';
import ManualPassContent from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/ManualVerification/ManualPassContent';
import React, { ReactElement } from 'react';

const ManualVerification = ({ signature }): ReactElement | null => {
  const pass = signature.manuallyApproved;

  if (!pass) {
    return null;
  }

  return (
    <div className={styles.e_signature_header}>
      <ManualPassContent />
    </div>
  );
};

export default ManualVerification;
