import RecordHistoryModel from 'models/RecordHistoryModel';
import { formatDate, formatLocalTime } from 'utils/dateFormatter';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

export default class ApplicationHistoryModel extends RecordHistoryModel {
  get formattedContent() {
    if (isBlank(this.content)) {
      return '';
    }

    /* eslint-disable no-template-curly-in-string */
    const content = this.replacePlaceholderContent({
      actualValue: formatLocalTime(this.createdAt, 'minute'),
      placeholder: '${date}',
      string: this.content,
    });
    /* eslint-enable no-template-curly-in-string */

    if (this.historyType === 'review_date') {
      return this.formatReviewDateHistoryContent(content);
    }

    return content;
  }

  get formattedContentWithoutNote() {
    if (isBlank(this.content)) {
      return '';
    }

    /* eslint-disable no-template-curly-in-string */
    const content = this.replacePlaceholderContent({
      actualValue: formatLocalTime(this.createdAt, 'minute'),
      placeholder: '${date}',
      string: this.content.replace(/(\${date}\.).*/, '$1'),
    });
    /* eslint-enable no-template-curly-in-string */

    if (this.historyType === 'review_date') {
      return this.formatReviewDateHistoryContent(content);
    }

    return content;
  }

  /** Private functions */

  formatReviewDateHistoryContent(content) {
    const reviewDate = this.attributeChanges.review_date || [];
    const oldReviewDate = this.convertToLocalTime(reviewDate[0]);
    const newReviewDate = this.convertToLocalTime(reviewDate[1]);

    /* eslint-disable no-template-curly-in-string */
    let newContent = this.replacePlaceholderContent({
      actualValue: formatDate(newReviewDate, 'DD/MM/YY'),
      placeholder: '${new_review_date}',
      string: content,
    });

    if (isPresent(oldReviewDate)) {
      newContent = this.replacePlaceholderContent({
        actualValue: formatDate(oldReviewDate, 'DD/MM/YY'),
        placeholder: '${old_review_date}',
        string: newContent,
      });
    }
    /* eslint-enable no-template-curly-in-string */

    return newContent;
  }

  convertToLocalTime(dateTimeString) {
    return isBlank(dateTimeString) ? '' : Date.parse(dateTimeString);
  }
}
