/* Import libraries */
import React from 'react';

import styles from './css/Card.css';

class Card extends React.Component {
  render() {
    return (
      <div className={styles.row}>
        <div className={styles.block}>
          <div className={styles.liner}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Card;
