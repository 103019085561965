import api from 'api';
import BaseModel from 'models/BaseModel';
import { setTheme } from 'modules/shared/helpers/colorPalettes';

export default class ModuleCardholderAuthorisationFlowModel extends BaseModel {
  async load(cardholderAuthorisationId) {
    const cardholderAuthorisationAPI = api(
      'module_cardholder_authorisation_flows'
    );
    try {
      this.isLoading = true;
      const result = await cardholderAuthorisationAPI.getDetails(
        cardholderAuthorisationId
      );
      this.setAttributes(result.data);
      if (result.data.theme.main_color) {
        setTheme(result.data.theme);
      }
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async setInProgress(cardholderAuthorisationId) {
    const cardholderAuthorisationAPI = api(
      'module_cardholder_authorisation_flows'
    );
    try {
      cardholderAuthorisationAPI.update(cardholderAuthorisationId, {
        in_progress: true,
      });
    } catch (error) {
      console.error(error);
    }
  }

  get signatory() {
    const { identification_image, ...signatoryData } = this.cardholderSignature;
    return {
      signatory: signatoryData,
    };
  }

  async updateSignature(cardholderAuthorisationId) {
    const cardholderAuthorisationAPI = api(
      'module_cardholder_authorisation_flows'
    );
    try {
      this.isLoading = true;
      const result = await cardholderAuthorisationAPI.update(
        cardholderAuthorisationId,
        this.signatory
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  async saveDecision(cardholderAuthorisationId) {
    const cardholderAuthorisationAPI = api(
      'module_cardholder_authorisation_flows'
    );
    const params = { approved: this.attributes.approved };
    try {
      this.isLoading = true;
      const result = await cardholderAuthorisationAPI.update(
        cardholderAuthorisationId,
        params
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }
}
