import PropTypes from 'prop-types';
import React from 'react';

import { Description, Header, Wrapper } from './styles';

function SectionHeader({ children, title }) {
  return (
    <Wrapper>
      <Header>{title}</Header>
      <Description>{children}</Description>
    </Wrapper>
  );
}

SectionHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

SectionHeader.defaultProps = {
  children: null,
};

export default SectionHeader;
