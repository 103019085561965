import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const FONT_MAP = {
  'alex-brush': 'Alex Brush',
  'great-vibes': 'Great Vibes',
  'calligraffitti': 'Calligraffitti',
  'marck-script': 'Marck Script',
  'whisper': 'Whisper',
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 0px;
  position: relative;
  width: 100%;
`;

const TextContainer = styled.div`
  border-left: 1.5px solid var(--main-color);
  border-top: 1.5px solid var(--main-color);
  border-bottom: 1.5px solid var(--main-color);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
  width: 20px;
  height: 56px;
`;

const Text = styled(Typography)<{ $font: string }>`
  font-family: ${(props) => props.$font} !important;
  font-size: 16px !important;
  margin-left: -20px !important;
  @media screen and (min-width: 991px) {
    font-size: 20px !important;
  }
`;

const SignedBy = styled(Typography)`
  position: absolute;
  font-size: 8px !important;
  top: 20px;
  left: 24px;
`;

type StyleProps = {
  text: string;
  font: string;
};

export const SignatureFont = (props: StyleProps) => {
  const { text, font } = props;
  return (
    <Container>
      <TextContainer />
      <SignedBy>Signed by:</SignedBy>
      <Text $font={FONT_MAP[font]}>{text}</Text>
    </Container>
  );
};
