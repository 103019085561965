import styled, { css } from 'styled-components';
import { THEME_COLORS } from 'variables/theme';
import { GLOBAL_ANIMATION } from 'variables/utils';

export const Actions = styled.div`
  ${GLOBAL_ANIMATION};

  align-items: center;
  background: ${THEME_COLORS.overlayBackground};
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const Preview = styled.img`
  width: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
