import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement } from 'react';

const MobileDismissButton = ({
  onCancel,
}: {
  onCancel: () => void;
}): ReactElement => (
  <div className="has-text-centered mt-100 is-hidden-tablet">
    <Button text="Close" handleClick={onCancel} white />
  </div>
);

export default MobileDismissButton;
