import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
// @ts-ignore
import { Link } from 'react-router';

import { BulkUploadJobType } from 'types/BulkUploadJobType';
import { formatLocalTime } from 'utils/dateFormatter';

const DownloadLink = ({ url }: { url: string }) => {
  return (
    <Link to={url} target="_blank" rel="noopener noreferrer">
      View CSV here
    </Link>
  );
};

export const HistoryLogItem = ({
  historyLogItem,
}: {
  historyLogItem: BulkUploadJobType;
}) => {
  const { id, success_csv, error_csv, updated_by, updated_at } = historyLogItem;

  const logDisplayItems: ReactElement[] = [];

  if (success_csv?.url) {
    logDisplayItems.push(
      <Typography key={`${id}-1`}>
        {`Rapid transfer completed by ${updated_by} on ${formatLocalTime(
          updated_at,
          'minute_with_comma'
        )}. `}
        <DownloadLink url={success_csv.url} />
        {'.'}
      </Typography>
    );
  }

  if (error_csv?.url) {
    logDisplayItems.push(
      <Typography key={`${id}-1`}>
        Exception report generated. <DownloadLink url={error_csv.url} />
        {'.'}
      </Typography>
    );
  }

  return <>{logDisplayItems.map((logItem) => logItem)}</>;
};
