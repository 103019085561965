import axios from './axios';

let Notes = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = 'notes';
};

Notes.prototype.createNote = function(id, attributes, type, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          notable: {
            data: {
              type: type,
              id: id,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

Notes.prototype.getNotes = function(id, type, success, error, options = {}) {
  return this.axios
    .get(`/${type}/${id}/notes`, options)
    .then(success)
    .catch(error);
};

Notes.prototype.updateNote = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Notes.prototype.deleteNote = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

export default Notes;
