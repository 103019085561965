import api from 'api';

export const createCorporateTrustee = async ({
  applicationId,
  entityId,
  trustee,
}) => {
  const corporateTrusteePayload = {
    attributes: {
      business_number: trustee.corporate_trustee_business_number,
      company_number: trustee.corporate_trustee_company_number,
      entity_name: trustee.corporate_trustee_company_name,
      legal_type: 'company',
      association_type: 'corporate_trustee',
      application_id: applicationId,
    },
  };
  const associatedEntityApi = api('associated_entities');
  const associatedEntityResponse =
    await associatedEntityApi.createAssociatedEntity(
      entityId,
      corporateTrusteePayload
    );
  const associatedEntityId = associatedEntityResponse.data.associated_entity_id;
  return associatedEntityId;
};
