import api from 'api';
import { isWatchtowerBlock } from './isWatchtowerBlock';

type FindWatchtowerBlockedEmailsParams = {
  emails: string[];
  consumerId: string;
  supplierId: string;
};

/**
 * Finds blocked emails using API for a given set of emails.
 *
 * @async
 * @function findWatchtowerBlockedEmails
 * @param {FindWatchtowerBlockedEmailsParams} params - The parameters for the function.
 * @param {string[]} params.emails - An array of email addresses to be checked.
 * @param {string} params.consumerId - The consumer ID to be passed to the API.
 * @param {string} params.supplierId - The supplier ID to be passed to the API.
 * @returns {Promise<{
 *   blockedEmails: string[],
 *   hasOtherError: boolean,
 *   error: string | any,
 * }>} A promise that resolves with an object containing the results.
 */

export const findWatchtowerBlockedEmails = ({
  emails,
  consumerId,
  supplierId,
}: FindWatchtowerBlockedEmailsParams) =>
  new Promise(async (resolve) => {
    const blockedResourcesApi = api('blocked_resources', '', consumerId);

    const blockedEmails: string[] = [];

    let hasOtherError = false;
    let error: any = null;

    // Iterate over the emails.
    for (const email of emails) {
      try {
        // Call the API to check if the email is blocked or not.
        await blockedResourcesApi.isResourceBlocked({
          value: email,
          type: 'Email',
          supplierId,
        });
      } catch (errorResponse) {
        // There is an error. This could be either becuase the email is blocked,
        // or it could be some other error.

        // Check if the error is becuase the email is blocked.
        const isBlocked = isWatchtowerBlock(errorResponse);

        error = errorResponse;

        if (isBlocked) {
          // The error happened becuase the email is blocked.
          // Append it to our array of blocked emails.
          blockedEmails.push(email);
        } else {
          // The error is not becuase the email is blocked.
          hasOtherError = true;
        }
      }
    }

    resolve({
      blockedEmails,
      hasOtherError,
      error: error || 'Could not check whether one or more emails are blocked',
    });
  });
