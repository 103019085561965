import { IconButton, TextField, makeStyles } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';

import { CustomerViewContext } from './CustomerViewContext';
import { Group } from './types';

const useTextFieldStyles = makeStyles({
  root: {
    width: '100%',
  },
  input: {
    padding: '10px',
  },
});

const useInputStyles = makeStyles({
  input: {
    padding: '10px',
  },
});

const Container = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
  svg {
    color: var(--main-color);
  }
`;

type EditGroupProps = {
  group: Group;
  path: number[];
};

export const EditGroup = (props: EditGroupProps) => {
  const textFieldClasses = useTextFieldStyles();
  const inputClasses = useInputStyles();

  const { editPath, setEditPath, editGroup } = useContext(CustomerViewContext);

  const { group, path } = props;

  const [name, setName] = useState(group.title);

  const clearEditMode = () => setEditPath([]);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const saveChanges = () => {
    editGroup({ ...group, title: name });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      saveChanges();
    }
  };

  return (
    <Container>
      <TextField
        value={name}
        onChange={handleChange}
        placeholder="Enter a group name here"
        error={isEqual(editPath, path) && !name}
        variant="outlined"
        classes={textFieldClasses}
        InputProps={{ classes: inputClasses }}
        onKeyPressCapture={handleKeyPress}
      />
      <StyledIconButton onClick={saveChanges}>
        <Check />
      </StyledIconButton>
      <StyledIconButton onClick={clearEditMode}>
        <Close />
      </StyledIconButton>
    </Container>
  );
};
