import React, { useState } from 'react';
import Button from 'modules/shared/components/inputs/Button';
import SimpleDropdown from 'modules/shared/components/widgets/interactive/SimpleDropdown';
import SubMenuItem from 'modules/dashboard/containers/components/SubMenuItem';
import getAccountTypeName from 'utils/getAccountTypeName';
import { isBranchUser } from 'modules/shared/helpers/headquarterDetect';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const NewApplicationButton = (props) => {
  const { currentEntity, startApplicationClick } = props;

  if (
    currentEntity != null &&
    (!currentEntity.attributes.supplier || isBranchUser())
  ) {
    return null;
  }
  const currentPath = location.pathname;
  const isSendApplicationDisabled = currentPath.startsWith('/dashboard/invite');

  return (
    <div className="dashboard-header_application-start hidden-xs">
      <Button
        type="button"
        className="dashboard-header_application-start_btn"
        dataCy="send-application-dropdown"
        text={
          <>
            Send <ArrowDropDownIcon />
          </>
        }
        disabled={isSendApplicationDisabled}
      />
      {!isSendApplicationDisabled && (
        <div className="dropdown-list">
          <SimpleDropdown type="start_application">
            <SubMenuItem
              title={getAccountTypeName('credit')}
              key="creditApplication"
              id="creditApplication"
              target={() => startApplicationClick('credit')}
              hiddenxs={true}
              dataCy="send-application-credit-option"
            />
            <SubMenuItem
              title={getAccountTypeName('cash')}
              key="cashApplication"
              id="cashApplication"
              target={() => startApplicationClick('cash')}
              hiddenxs={true}
              dataCy="send-application-cash-option"
            />
          </SimpleDropdown>
        </div>
      )}
    </div>
  );
};

export default NewApplicationButton;
