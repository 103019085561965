import api from 'api';
import BaseModel from 'models/BaseModel';
import PersonModel from 'models/PersonModel';
import { setTheme } from 'modules/shared/helpers/colorPalettes';

export default class EntityModel extends BaseModel {
  async getLogoAndTheme() {
    const entitiesAPI = api('entities');
    try {
      const result = await entitiesAPI.getLogoAndTheme(this.id);
      this.setAttributes(result.data);
      if (result.data.theme.main_color) {
        setTheme(result.data.theme);
      }
    } catch (error) {
      console.error(error);
    }
  }

  get sortedApprovers() {
    return (this.approvers || []).sort((a, b) => {
      const nameA = a.fullName.toUpperCase();
      const nameB = b.fullName.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  get entityApplicationTypeNames() {
    return {
      credit: this.creditApplicationTypeName,
      cash: this.cashApplicationTypeName,
    };
  }

  get entityTierData() {
    const currentHqConfig = this.hqmodeConfig;

    if (currentHqConfig && !!Object.keys(currentHqConfig).length) {
      const tierData = [];

      const pushTierData = (data, level = 1) => {
        const { id, title, type, children } = data;
        tierData.push({
          id,
          level,
          title,
          type,
        });

        if (children && !!children.length) {
          children.forEach((child) => pushTierData(child, level + 1));
        }
      };

      pushTierData(currentHqConfig);

      return tierData;
    }
    return [];
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignManyRelationship({
      included: this.included,
      key: 'people',
      model: PersonModel,
    });
  }
}
