import FormRadioBox from 'modules/shared/components/v2/FormRadioBox';
import { makeStyles } from 'mui-latest';
import React from 'react';

export enum YesOrNo {
  NO = 'no',
  YES = 'yes',
  NOT_SELECTED = '',
}

export const getYesOrNo = (checked: boolean | undefined): YesOrNo => {
  if (checked === true) return YesOrNo.YES;
  if (checked === false) return YesOrNo.NO;
  return YesOrNo.NOT_SELECTED;
};

const YesOrNoRadioBoxGroup = (props): JSX.Element => {
  return (
    <FormRadioBox
      row
      options={[
        { label: 'Yes', value: YesOrNo.YES },
        { label: 'No', value: YesOrNo.NO },
      ]}
      {...props}
    />
  );
};

export default YesOrNoRadioBoxGroup;
