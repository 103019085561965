import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import React, { ReactElement } from 'react';

import { blacklistCountriesOptions } from '../constants';
import { WatchtowerDynamicFieldsTitle } from '../styles';
import { CountryPermissionsData } from '../types';

const ForeignIpSelect = (props): ReactElement => {
  const { value, onChange, ...inputProps } = props;

  const handleChange = (target) => {
    const values: CountryPermissionsData = {
      blacklist_countries: target.value.filter(
        (country) => country !== 'non_au_nz'
      ),
    };
    if (target.value.includes('non_au_nz')) {
      values.whitelist_countries = ['AU', 'NZ'];
    }
    onChange(values);
  };

  const getDropdownValue = (value) => {
    const arrayValues = value.blacklist_countries;

    if (value.whitelist_countries && !arrayValues.includes('non_au_nz')) {
      arrayValues.push('non_au_nz');
    }
    return arrayValues;
  };

  return (
    <ContentContainer>
      <div className="pb-4">
        <div className={`${styles.info} pb-4`}>
          <WatchtowerDynamicFieldsTitle>
            Foreign IP addresses
          </WatchtowerDynamicFieldsTitle>
          {
            'Please select the countries of IP addresses you would like to be alerted'
          }
        </div>
        <div className="columns">
          <div className="column is-6">
            <SimpleMultiSelectDropdown
              options={blacklistCountriesOptions}
              id="foreign-ips-select"
              multiple
              placeholder="Countries"
              onChange={handleChange}
              value={getDropdownValue(value)}
              {...inputProps}
              disabled={props.disabled}
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default ForeignIpSelect;
