import get from 'lodash.get';
import getApplicableAntiFraudRules from 'utils/anti-fraud/getApplicableAntiFraudRules';
import isPresent from 'utils/isPresent';

const getIsGuarantor = ({ guarantors, legalType }) => {
  if (legalType !== 'company') {
    return false;
  }

  const formValues = get(guarantors, 'form_values', []);
  return (
    isPresent(formValues) &&
    formValues.find((guarantor) => guarantor.is_applicant)
  );
};

const getIsSignatory = (people) =>
  isPresent(people) &&
  people.find((person) => get(person, 'attributes.is_applicant', false));

const getIsCardholder = (cards) =>
  isPresent(cards) &&
  cards.find((card) => get(card, 'attributes.isApplicant', false));

const getApplicantRoles = ({
  cards,
  guarantors,
  legalType,
  paperless,
  people,
}) => {
  const roles = ['applicant'];

  if (getIsGuarantor({ guarantors, legalType })) {
    roles.push('guarantor');
  }

  if (getIsSignatory(people)) {
    roles.push('signatory');
  }

  if (getIsCardholder(cards)) {
    roles.push('cardholder');
  }

  if (get(paperless, 'answers.applicantAuthorised')) {
    roles.push('direct_debit');
  }

  return roles;
};

const getApplicableAntiFraudRulesForApplicationFlow = ({
  antiFraud,
  application,
  cards,
  guarantors,
  paperless,
  people,
  requestedLimit,
}) => {
  const applicantRoles = getApplicantRoles({
    cards,
    guarantors,
    legalType: application.legalType,
    paperless,
    people,
  });
  return getApplicableAntiFraudRules({
    antiFraud,
    application,
    authorisationRoles: applicantRoles,
    requestedLimit,
  });
};

export default getApplicableAntiFraudRulesForApplicationFlow;
