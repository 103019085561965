import { Pie } from '@ant-design/charts';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';
import React, { Fragment, ReactElement } from 'react';

interface IAgedBalanceGroupIndustrySummary {
  balanceMonth: number;
  balanceYear: number;
  dso: number;
  industryShare: number;
  industryTypeDescription: string;
}

const filterDataByMonths = (data) => {
  return data.reduce((pieData, currentData) => {
    const currentMonths = Object.keys(pieData) || [];
    const { balanceMonth } = currentData;
    if (!currentMonths.includes(balanceMonth.toString())) {
      pieData[balanceMonth] = [];
    }
    pieData[balanceMonth].push(currentData);

    return pieData;
  }, {});
};

const getPieDataToRender = (data) => {
  const filteredData = filterDataByMonths(data);
  const filteredDataKeys = Object.keys(filteredData);
  // return original data if there's less than 2 months
  // use original dataset
  if (filteredDataKeys.length < 2) {
    return data;
  }
  //ensure months are sorted ascending
  const dataMonths = filteredDataKeys.sort((a, b) => {
    const monthA = parseInt(a);
    const monthB = parseInt(b);
    return monthA < monthB ? -1 : monthA > monthB ? 1 : 0;
  });
  const previousMonthKey = dataMonths[0];
  return filteredData[previousMonthKey];
};

const getPieData = (data) => {
  const dataToRender = getPieDataToRender(data);

  return dataToRender.map((datum) => {
    return {
      ...datum,
      legend: `${datum.industryTypeDescription} (${datum.industryShare}%) ${datum.dso} avg. dso`,
    };
  });
};

const SupplierByIndustryPieChart = ({
  data,
}: {
  data: IAgedBalanceGroupIndustrySummary[];
}): ReactElement | null => {
  if (!data || data.length === 0) {
    return null;
  }

  const pieData = getPieData(data);

  const config = {
    angleField: 'industryShare',
    appendPadding: 20,
    colorField: 'legend',
    data: pieData,
    height: 250,
    label: {
      formatter: (datum) =>
        `${datum.industryTypeDescription} (${datum.industryShare}%)`,
    },
    tooltip: {
      fields: ['dso', 'industryShare', 'industryTypeDescription'],
      formatter: (datum) => {
        return {
          name: datum.industryTypeDescription,
          value: `(${datum.industryShare}%) ${datum.dso} avg. dso`,
        };
      },
      showContent: true,
      showTitle: false,
    },

    width: 250,
  };

  return (
    <Fragment>
      <SubHeading>Suppliers by Industry</SubHeading>
      <Pie {...config} />
    </Fragment>
  );
};

export default SupplierByIndustryPieChart;
