import React, { ReactElement } from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import styles from 'modules/new-applications/css/LimitAndApprovals.css';
import * as Sentry from '@sentry/browser';
import { taggedApproverEscalationData } from 'modules/new-applications/utils/escalationHelpers';
import { FEATURE_FLAGS } from 'conf';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import styled from 'styled-components';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media screen and (min-width: 991px) {
    width: 400px;
  }
`;

const getReviewMessage = (review: any, reviewEscalations: any): string => {
  const { approvalHierarchyVersion, decision, formattedReviewedAt, id, level } =
    review;

  if (decision === 'escalated') {
    const escalationData = taggedApproverEscalationData({
      review,
      reviewEscalations: reviewEscalations,
    });

    if (escalationData) {
      const { escalated_to_level, escalated_for_user_name } =
        escalationData.attributes;
      const level = escalated_to_level > 0 ? `(L${escalated_to_level})` : '(R)';
      return `Escalated approval to ${escalated_for_user_name} ${level} on ${review.formattedReviewedAt}.`;
    }

    return `Escalated to L${
      level + 1
    } on ${formattedReviewedAt} (approval hierarchy version ${approvalHierarchyVersion}).`;
  }

  if (decision === 'reviewed') {
    return `Reviewed on ${formattedReviewedAt} (approval hierarchy version ${approvalHierarchyVersion})`;
  }

  console.error('Invalid review decision');
  Sentry.captureException(`Invalid review decision for review ${id}`);
  return '';
};

const ReviewerContent = ({
  review,
  application,
}: {
  review: { [key: string]: any };
  application: any;
}): ReactElement =>
  FEATURE_FLAGS.FEATURE_FLAG_IMPROVED_NOTES ? (
    <FixedContent withBottomSeparator={false} withBottomMargin={false}>
      <ContentContainer>
        <p className={styles.approver_name}>
          {`Reviewer name: ${review.reviewerName}`}
        </p>
        <p>{getReviewMessage(review, application.reviewEscalations)}</p>
        {review.notes ? (
          <BorderedTextArea
            disabled
            darkerDisabledText
            label={
              review.decision === 'escalated'
                ? 'Escalate notes'
                : 'Reviewer notes'
            }
            value={review.notes}
          />
        ) : null}
      </ContentContainer>
    </FixedContent>
  ) : (
    <FixedContent withBottomSeparator={false} withBottomMargin={false}>
      <p className={styles.approver_name}>
        {`Reviewer name: ${review.reviewerName}`}
      </p>
      <p>{getReviewMessage(review, application.reviewEscalations)}</p>
      <p>{review.notes || 'No notes.'}</p>
    </FixedContent>
  );

export default ReviewerContent;
