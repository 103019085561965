import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Button from 'modules/shared/components/inputs/Button';

import { StyleSelection } from './StyleSelection';
import { Draw } from './Draw';
import { Preview } from './Preview';

const Container = styled.div`
  .MuiTab-root.Mui-selected span {
    color: var(--main-color);
  }
  .MuiTabs-indicator {
    background-color: var(--main-color);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const CreateButton = styled(Button)`
  margin-top: 36px;
`;

type StyledSignatureProps = {
  firstName: string;
  lastName: string;
  font: string | null;
  image: string | null;
  onCreate: (type: 'font' | 'image', value: string | null) => void;
};

export const StyledSignature = (props: StyledSignatureProps) => {
  const { firstName, lastName, font, image, onCreate } = props;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [tab, setTab] = useState(0);
  const [selectedFont, setSelectedFont] = useState<string | null>(null);
  const [signatureImage, setSignatureImage] = useState<string | null>(null);
  const [isPreview, setPreview] = useState(false);
  const [hasDrawn, setDrawn] = useState(false);

  useEffect(() => {
    if (font) {
      setSelectedFont(font);
      setPreview(true);
    }
  }, [font]);

  useEffect(() => {
    if (image) {
      setSignatureImage(image);
      setPreview(true);
    }
  }, [image]);

  const handleChangeTab = (_, newTab) => setTab(newTab);

  const handleCreate = () => {
    setPreview(true);
    if (tab === 0) {
      setSignatureImage(null);
      onCreate('font', selectedFont);
    } else {
      setSelectedFont(null);
      const image = getImage();
      onCreate('image', image);
    }
  };

  const handleChangeStyle = () => {
    setPreview(false);
    onCreate('font', null);
  };

  const handleSelectFont = (font: string) => setSelectedFont(font);

  const getImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const image = canvas.toDataURL('image/png');
      return image;
    }
    return null;
  };

  const name = `${firstName} ${lastName}`;

  const isCreateButtonEnabled =
    firstName && lastName && (selectedFont || hasDrawn);

  const isCreateButtonVisible = !isPreview;

  return (
    <Container>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab label="Style" disabled={isPreview} />
        <Tab label="Draw" disabled={isPreview} />
      </Tabs>
      {isPreview && (
        <Preview
          font={selectedFont}
          image={signatureImage}
          text={name}
          handleChangeStyle={handleChangeStyle}
        />
      )}
      {tab === 0 && !isPreview && (
        <StyleSelection
          text={name}
          selectedFont={selectedFont}
          handleSelect={handleSelectFont}
        />
      )}
      {tab === 1 && !isPreview && (
        <Draw canvasRef={canvasRef} setDrawn={setDrawn} />
      )}
      {isCreateButtonVisible && (
        <CreateButton
          text="Create"
          handleClick={handleCreate}
          disabled={!isCreateButtonEnabled}
        />
      )}
    </Container>
  );
};
