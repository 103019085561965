import { useState } from 'react';
import { getInitialValues } from './utils';
import toggleArrayValue from 'utils/toggleArrayValue';
import { OptionType, Row } from './types';

type UseAutocompleteParams = {
  multiple: boolean;
  hasMainOption: boolean;
  options: OptionType[];
  onChange: (
    value: string | string[] | { main: string | null; other: string[] }
  ) => void;
  value: { main: string | null; other: string[] };
};

export const useAutocomplete = (params: UseAutocompleteParams) => {
  const { multiple, hasMainOption, onChange, value, options } = params;
  const initialValues = getInitialValues(value, multiple, hasMainOption);

  const [mainValue, setMainValue] = useState(initialValues.main);
  const [otherValue, setOtherValue] = useState(initialValues.other);

  const handleChangeMultiple = (value: OptionType[]) => {
    setOtherValue(value.map((valueItem) => valueItem.value));
  };

  const handleSingleClick = (value: string) => {
    let newMainValue: string | null = null;
    let newOtherValue: string[] = [];

    // single option
    if (!multiple) {
      onChange(value);
      setOtherValue([value]);
      return;
    }

    // multiple option
    if (!hasMainOption) {
      newOtherValue = toggleArrayValue(otherValue, value);
      onChange(newOtherValue);
      setOtherValue(newOtherValue);
      return;
    }

    // multiple option with main
    if (value === mainValue) {
      newMainValue = null;
      newOtherValue = otherValue;
    } else {
      newMainValue = mainValue;
      newOtherValue = toggleArrayValue(otherValue, value);
    }
    onChange({
      main: newMainValue,
      other: newOtherValue,
    });
    setMainValue(newMainValue);
    setOtherValue(newOtherValue);
  };

  const handleToggleMain = (toggle: boolean, value: string) => {
    if (!value) {
      return;
    }
    const oldMainValue = mainValue;
    let newOtherValue = otherValue;
    if (oldMainValue && !newOtherValue.includes(oldMainValue)) {
      newOtherValue.push(oldMainValue);
    }
    newOtherValue = newOtherValue.filter((e) => e !== value);
    if (toggle) {
      onChange({
        main: value,
        other: newOtherValue,
      });
      setMainValue(value);
      setOtherValue(newOtherValue);
    } else {
      onChange({
        main: null,
        other: newOtherValue,
      });
      setMainValue(null);
      if (value) {
        newOtherValue.push(value);
      }
      setOtherValue(newOtherValue);
    }
  };

  const renderValues = () => {
    const values =
      multiple && hasMainOption && mainValue
        ? [mainValue, ...otherValue]
        : otherValue;
    return values.join(', ');
  };

  const rows: Row[] = [];
  options.forEach((option) => {
    if (option.value === mainValue) {
      rows.push({
        ...option,
        selected: true,
        main: true,
        label: `${option.label} (main option)`,
      });
    } else if (otherValue.includes(option.value)) {
      rows.push({ ...option, selected: true });
    } else {
      rows.push(option);
    }
  });

  return {
    mainValue,
    otherValue,
    handleSingleClick,
    handleToggleMain,
    handleChangeMultiple,
    renderValues,
    rows,
  };
};
