import React, { Component } from 'react';

import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';
import { formatDateFromObject } from 'utils/dateFormatter';
import objectToArray from 'utils/objectToArray';
import { renderAddress } from '../PaymentPredictorComponent';
import styles from '../css/payment-predictor/Officers.css';

export default class Officers extends Component {
  get asicOfficerRoleDetails() {
    return objectToArray(
      (this.props.response.asic_officer_role_details || {}).asic_officer_role
    );
  }

  get directors() {
    return objectToArray((this.props.response.directors || {}).director);
  }

  renderDisclaimer() {
    return (
      <div className={styles.disclaimer}>
        <p className={commonStyles.emphasize}>Disclaimer</p>
        <p className={commonStyles.emphasize}>
          The officeholders shown below were not necessarily officeholders of
          the companies stated at the time of any company status change. The
          listings reflect the last ASIC/NZCO data purchased for D&amp;B
          products which may be older than the report date.
        </p>
      </div>
    );
  }

  renderAppointedAuditors() {
    const appointedAuditors = this.asicOfficerRoleDetails
      .filter((officer) => officer.officer_role === 'Appointed Auditor')
      .map((auditor, i) => this.renderAuditor(auditor, i));

    if (appointedAuditors.length === 0) {
      return;
    }

    return (
      <div>
        <p className={commonStyles.emphasize}>Appointed Auditor</p>
        {appointedAuditors}
      </div>
    );
  }

  renderAuditor(auditor, i) {
    const address = auditor.address;
    let auditorName = auditor.organisation_name;
    if (auditor.company_num) {
      auditorName += ` (${auditor.company_num})`;
    }

    return (
      <div key={`auditor-${i}`} className={styles.officers_section}>
        <p className={commonStyles.emphasize}>{auditorName}</p>
        <div className={styles.officer_container}>
          <div className={styles.officer_content}>
            <div>Appointment date</div>
            <div className={commonStyles.emphasize}>
              {formatDateFromObject(auditor.date_appointed)}
            </div>
            <div>Address</div>
            {renderAddress(address, { emphasize: true })}
          </div>

          <div className={styles.officer_content}>
            <div>Number</div>
            <div className={commonStyles.emphasize}>{auditor.company_num}</div>
          </div>
        </div>
      </div>
    );
  }

  renderDirectors() {
    const directors = this.directors.map((director, i) =>
      this.renderDirector(director, i)
    );

    return (
      <div>
        <p className={commonStyles.emphasize}>Directors</p>
        {directors}
      </div>
    );
  }

  renderDirector(director, i) {
    const person = director.entity.person;
    const name = [person.first_name, person.other_name, person.surname].join(
      ' '
    );
    const key = `director-${i}`;

    return (
      <div key={key} className={styles.officers_section}>
        <div>
          <p className={commonStyles.underlined}>{name}</p>
          <div className={styles.officer_container}>
            <div className={styles.officer_content}>
              <div>Appointment date</div>
              <div className={commonStyles.emphasize}>
                {formatDateFromObject(director.date_appointed)}
              </div>

              <div>Date of birth</div>
              <div className={commonStyles.emphasize}>
                {formatDateFromObject(person.date_of_birth)}
              </div>

              <div>Place of birth</div>
              <div
                className={commonStyles.emphasize}
              >{`${person.birth_location}, ${person.birth_state}`}</div>
            </div>
            <div className={styles.officer_content}>
              <div>Address</div>
              <div className={commonStyles.emphasize}>
                {renderAddress(director.address, { emphasize: true })}
              </div>
            </div>
          </div>
        </div>
        {this.renderOtherBoards(director, key)}
        {this.renderPreviousBoards(director, key)}
      </div>
    );
  }

  renderDirectorBoards(directorBoards, title, key, type) {
    const boards = directorBoards.map((board, i) =>
      this.renderBoard(board, key, type, i)
    );

    if (boards.length === 0) {
      return;
    }

    return (
      <div>
        <table className={`${commonStyles.table} ${styles.director_boards}`}>
          <thead>
            <tr>
              <th className={styles.director_boards_title}>{title}</th>
              <th className={styles.director_boards_duns}>D-U-N-S</th>
              <th className={styles.director_boards_status}>Status</th>
            </tr>
          </thead>
          <tbody>{boards}</tbody>
        </table>
      </div>
    );
  }

  renderOtherBoards(director, key) {
    const otherBoards = objectToArray((director.other_boards || {}).board);

    if (otherBoards.length === 0) {
      return;
    }

    return this.renderDirectorBoards(otherBoards, 'Other boards', key, 'other');
  }

  renderPreviousBoards(director, key) {
    const previousBoards = objectToArray(
      (director.other_boards_previous || {}).board
    );

    if (previousBoards.length === 0) {
      return;
    }

    return this.renderDirectorBoards(
      previousBoards,
      'Previous boards',
      key,
      'previous'
    );
  }

  renderBoard(board, key, type, i) {
    return (
      <tr key={`${key}-${type}-board-${i}`}>
        <td>{board.organisation_name}</td>
        <td className={styles.director_board_content}>
          <span className={commonStyles.underlined}>{board.duns}</span>
        </td>
        <td
          className={`${commonStyles.highlight_cell} ${styles.director_board_content}`}
        >
          {board.company_status}
        </td>
      </tr>
    );
  }

  renderSecretaries() {
    const secretaries = this.asicOfficerRoleDetails
      .filter((officer) => officer.officer_role === 'Secretary')
      .map((secretary, i) => this.renderSecretary(secretary, i));

    if (secretaries.length === 0) {
      return;
    }

    return (
      <div>
        <p className={commonStyles.emphasize}>Secretary</p>
        {secretaries}
      </div>
    );
  }

  renderSecretary(secretary, i) {
    const name = [
      secretary.first_name,
      secretary.other_names,
      secretary.surname,
    ].join(' ');

    return (
      <div key={`secretary-${i}`} className={styles.officers_section}>
        <p className={commonStyles.underlined}>{name}</p>
        <div className={styles.officer_container}>
          <div className={styles.officer_content}>
            <div>Appointment date</div>
            <div className={commonStyles.emphasize}>
              {formatDateFromObject(secretary.date_appointed)}
            </div>

            <div>Date of birth</div>
            <div className={commonStyles.emphasize}>
              {formatDateFromObject(secretary.date_of_birth)}
            </div>

            <div>Place of birth</div>
            <div
              className={commonStyles.emphasize}
            >{`${secretary.birth_location}, ${secretary.birth_state}`}</div>

            <div>Address</div>
            <div className={commonStyles.emphasize}>
              {renderAddress(secretary.address, { emphasize: true })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUltimateHoldingCompanies() {
    const companies = this.asicOfficerRoleDetails
      .filter((officer) => officer.officer_role === 'Ultimate Holding Company')
      .map((company, i) => this.renderUltimateHoldingCompany(company, i));

    if (companies.length === 0) {
      return;
    }

    return (
      <div>
        <p className={commonStyles.emphasize}>Ultimate Holding Company</p>
        {companies}
      </div>
    );
  }

  renderUltimateHoldingCompany(company, i) {
    return (
      <div key={`company-${i}`}>
        <p className={commonStyles.emphasize}>{company.organisation_name}</p>
        <div className={styles.officer_container}>
          <div className={styles.officer_content}>
            <div>ARBN (Australian Registered Body Number)</div>
            <div>{company.company_num}</div>
          </div>
        </div>
      </div>
    );
  }

  renderDirectorCourtActions() {
    return (
      <div className={styles.director_court_actions_container}>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Director court actions</p>
        </div>
        <div>There are no director court actions recorded.</div>
      </div>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Officers"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderDisclaimer()}
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Current Officeholders</p>
        </div>
        {this.renderAppointedAuditors()}
        {this.renderDirectors()}
        {this.renderSecretaries()}
        {this.renderUltimateHoldingCompanies()}
        {this.renderDirectorCourtActions()}
      </ReviewContainer>
    );
  }
}
