export const SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_START =
  'SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_START';
export const SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_SUCCESS =
  'SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_SUCCESS';
export const SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_ERROR =
  'SHARED_DATA_LOAD_ENTITY_TRADING_NAMES_ERROR';

export const SHARED_DATA_SET_FORM_BUILDER_VALIDATION_RESULTS =
  'SHARED_DATA_SET_FORM_BUILDER_VALIDATION_RESULTS';
export const SHARED_DATA_REMOVE_FORM_BUILDER_VALIDATION_RESULTS =
  'SHARED_DATA_REMOVE_FORM_BUILDER_VALIDATION_RESULTS';
