/* Import libraries */
import { setCardholdersCount } from 'modules/consumer-onboarding/actions/cards';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isInteger from 'utils/isInteger';

import styles from './css/Cards';

function getCardholdersCountErrorMessage(value) {
  if (!isInteger(value) || value <= 0) {
    return 'Please enter a number greater than 0';
  }

  return '';
}

class CardholdersCount extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      cardholdersCount,
      handleComplete,
      page_validation_start: pageValidationStart,
      setPageValidationStartFinish,
    } = nextProps;

    const formErrors = { ...prevState.formErrors };
    formErrors.cardholdersCount = getCardholdersCountErrorMessage(
      cardholdersCount
    );

    handleComplete(CardholdersCount.isComplete(formErrors));
    setPageValidationStartFinish();

    if (pageValidationStart) {
      return { formErrors };
    }

    return null;
  }

  static isComplete(formErrors) {
    const hasErrors = Object.values(formErrors).some(
      (error) => error.length > 0
    );

    return !hasErrors;
  }

  constructor() {
    super();

    this.state = {
      formErrors: {
        cardholdersCount: '',
      },
    };
  }

  onHandleChangeValue(event) {
    const value = parseInt(event.target.value);
    this.validateCardholderCountChange(value);
    this.props.dispatch(setCardholdersCount(value));
  }

  onHandleBlur(event) {
    this.validateCardholderCountChange(parseInt(event.target.value));
  }

  validateCardholderCountChange(cardholderCount) {
    const cardholdersCountErrorMessage = getCardholdersCountErrorMessage(
      cardholderCount
    );

    this.setState({
      formErrors: {
        ...this.state.formErrors,
        cardholdersCount: cardholdersCountErrorMessage,
      },
    });
  }

  render() {
    const { cardholdersCount } = this.props;

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.full_width}>
            <PanelTitle text="Card order form" margin_bottom="1em" />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.half_width}>
            <TextInput
              key="cardholdersCount"
              id="cardholdersCount"
              name="cardholdersCount"
              error={this.state.formErrors.cardholdersCount}
              handleChange={this.onHandleChangeValue.bind(this)}
              handleBlur={this.onHandleBlur.bind(this)}
              number_only={true}
              type="number"
              label="Number of cardholders"
              required={true}
              value={cardholdersCount}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default connect((state) => {
  return {
    cardholdersCount:
      state.cob_section.application.attributes.cardholders_count,
  };
})(CardholdersCount);
