import ConsumerFromRender from 'modules/shared/components/widgets/interactive/form_builder/ConsumerFromRender';
import FileWidgetSmall from 'modules/shared/components/widgets/interactive/FileWidgetSmall';
import FormBuilder from 'modules/shared/components/widgets/interactive/form_builder/FormBuilder';
import PDFLogo from 'modules/shared/components/widgets/static/PDFLogo';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import TextInput from 'modules/shared/components/inputs/TextInput';
import { connect } from 'react-redux';
import { formatMoney } from 'utils/formatting';
import styles from './css/FinancialsForm.css';

class FinancialsForm extends React.Component {
  constructor() {
    super();
    this.state = {
      error: {
        file: 'This is mandatory.',
      },
    };
  }

  setAdditionalFields(key, params) {
    const { handleChange } = this.props;
    handleChange(key, params);
  }

  docReady(data) {
    const { fileHandleChange } = this.props;
    fileHandleChange(data);
  }

  renderNotice() {
    const { selected } = this.props;
    if (
      !selected.includes('assets_liabilities') &&
      !selected.includes('income_expenses')
    )
      return;
    return (
      <div className={styles.row}>
        <div className={styles.notice}>
          <b>Important notice: </b>
          Whilst not all fields are mandatory, please complete this page as
          completely as you can. It will influence the assessment of your
          application and incomplete information may result in having to come
          back to you.
        </div>
      </div>
    );
  }

  renderFormBuilder(key) {
    const {
      readOnly,
      supplier,
      components,
      moduleName,
      validationTrigger,
      answers,
      selfDefinedIndicators,
    } = this.props;
    if (supplier) {
      const showIndicatorOption = selfDefinedIndicators.includes(
        componentIndicatorNameMapper[key]
      );
      return (
        <FormBuilder
          module={moduleName}
          components={components[key]}
          showIndicatorOption={showIndicatorOption}
          editable={!readOnly}
          onChange={(params) => {
            this.setAdditionalFields(key, params);
          }}
        />
      );
    }

    return (
      <ConsumerFromRender
        components={components[key]}
        onChange={(params) => {
          this.setAdditionalFields(key, params);
        }}
        reduxKey={`${moduleName}_${key}`}
        validationTrigger={validationTrigger}
        answers={answers[key]}
      />
    );
  }

  renderAssets() {
    const { financials } = this.props;

    return (
      <div key={'assets'}>
        <PanelTitle text={'Assets'} />
        <div className={styles.row}>
          {this.renderFormBuilder('assets')}
          <div className={styles.half_width}></div>
          <div className={styles.half_width}>
            <TextInput
              id="total_assets"
              name="total_assets"
              label={'Total assets'}
              required={true}
              disabled={true}
              value={`$${formatMoney(
                parseInt(financials.answers.total_assets)
              )}`}
            />
          </div>
        </div>
      </div>
    );
  }

  renderLiabilities() {
    const { financials } = this.props;

    return (
      <div key={'liabilities'}>
        <PanelTitle text={'Liabilities'} />
        <div className={styles.row}>
          {this.renderFormBuilder('liabilities')}
          <div className={styles.half_width}></div>
          <div className={styles.half_width}>
            <TextInput
              id="total_liabilities"
              name="total_liabilities"
              label={'Total liabilities'}
              required={true}
              disabled={true}
              value={`$${formatMoney(
                parseInt(financials.answers.total_liabilities)
              )}`}
            />
          </div>
          <div className={styles.half_width}></div>
          <div className={styles.half_width}>
            <TextInput
              id="net_worth"
              name="net_worth"
              label={'Net worth (Assets - Liabilities)'}
              required={true}
              disabled={true}
              value={`$${formatMoney(parseInt(financials.answers.net_worth))}`}
            />
          </div>
        </div>
      </div>
    );
  }

  renderIncome() {
    const { financials } = this.props;

    return (
      <div key={'income'}>
        <PanelTitle text={'Income (monthly)'} />
        <div className={styles.row}>
          {this.renderFormBuilder('income')}
          <div className={styles.half_width}></div>
          <div className={styles.half_width}>
            <TextInput
              id="total_income"
              name="total_income"
              label={'Total income'}
              required={true}
              disabled={true}
              value={`$${formatMoney(
                parseInt(financials.answers.total_income)
              )}`}
            />
          </div>
        </div>
      </div>
    );
  }

  renderExpenses() {
    const { financials } = this.props;

    return (
      <div key="expenses">
        <PanelTitle text={'Expenses (monthly)'} />
        <div className={styles.row}>
          {this.renderFormBuilder('expenses')}
          <div className={styles.half_width}></div>
          <div className={styles.half_width}>
            <TextInput
              id="total_expenses"
              name="total_expenses"
              label={'Total expenses'}
              required={true}
              disabled={true}
              value={`$${formatMoney(
                parseInt(financials.answers.total_expenses)
              )}`}
            />
          </div>
          <div className={styles.half_width}></div>
          <div className={styles.half_width}>
            <TextInput
              id="surplus"
              name="surplus"
              label={'Surplus (Income - Expenses)'}
              required={true}
              disabled={true}
              value={`$${formatMoney(parseInt(financials.answers.surplus))}`}
            />
          </div>
        </div>
      </div>
    );
  }

  renderFile() {
    const {
      fileSrc,
      supplier,
      readOnly,
      page_validation_start,
      validationTrigger,
    } = this.props;
    const isError =
      (page_validation_start || validationTrigger) &&
      (!fileSrc || !fileSrc.url);
    let completeWidget;
    let viewSrc;

    if (fileSrc && fileSrc.url) {
      completeWidget = <PDFLogo alt="PDF Upload" logoStyle="small" />;
      viewSrc = fileSrc;
    }

    return (
      <div key="file">
        <div className={styles.row}>
          <FileWidgetSmall
            accept=".pdf"
            completeWidget={completeWidget}
            disabled={readOnly || supplier}
            filename="financials.pdf"
            id="financials-statement"
            handleFileCallback={(data) => {
              this.docReady(data);
            }}
            error={isError && this.state.error.file}
            text="Drag and drop"
            title="Please upload a copy of your latest Financial Statements. (PDF only)"
            viewSrc={viewSrc}
          />
        </div>
      </div>
    );
  }

  render() {
    const { collapsible, selected } = this.props;

    const contents = [this.renderNotice()];

    if (selected.includes('assets_liabilities')) {
      if (collapsible) {
        contents.push(
          <ReviewContainer
            key={'assets_liabilities'}
            css_class={'block_noborder'}
            subHeading={'Assets & Liabilities'}
            collapsible={true}
            content_class="content_wide"
          >
            <div className={styles.width_100}>
              {this.renderAssets()}
              {this.renderLiabilities()}
            </div>
          </ReviewContainer>
        );
      } else {
        contents.push(this.renderAssets());
        contents.push(this.renderLiabilities());
      }
    }

    if (selected.includes('income_expenses')) {
      if (collapsible) {
        contents.push(
          <ReviewContainer
            key={'income_expenses'}
            css_class={'block_noborder'}
            subHeading={'Income & Expenses'}
            collapsible={true}
            content_class="content_wide"
          >
            <div className={styles.width_100}>
              {this.renderIncome()}
              {this.renderExpenses()}
            </div>
          </ReviewContainer>
        );
      } else {
        contents.push(this.renderIncome());
        contents.push(this.renderExpenses());
      }
    }

    if (selected.includes('file')) {
      if (collapsible) {
        contents.push(
          <ReviewContainer
            key={'file'}
            css_class={'block_noborder'}
            subHeading={'Financial attachment'}
            collapsible={true}
            content_class="content_wide"
          >
            <div className={styles.width_100}>{this.renderFile()}</div>
          </ReviewContainer>
        );
      } else {
        contents.push(this.renderFile());
      }
    }

    return <div className={styles.section}>{contents}</div>;
  }
}

const componentIndicatorNameMapper = {
  assets: 'net worth',
  liabilities: 'net worth',
  income: 'surplus',
  expenses: 'surplus',
};

export default connect((state) => {
  return {
    financials: state.cob_financials,
    moduleName: 'financials',
  };
})(FinancialsForm);
