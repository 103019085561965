export const getWatchtowerBlockedMessage = (type: 'email' | 'ip') => {
  if (type === 'email') {
    return 'This email address is blocked. Please contact support@1centre.com for assistance.';
  }

  if (type === 'ip') {
    return 'Unable to submit. Please contact support@1centre.com for assistance.';
  }

  return 'Unable to process. Please contact support@1centre.com for assistance.';
};
