import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';

type Default = {
  dateOfDefault: string;
  accountType: string;
  accountTypeDescription: string;
  creditProvider: string;
  collectionIndustrySupplier: string;
  reference: string;
  originalDefaultAmount: number;
  currentDefaultBalance: number;
  defaultStatus: string;
  defaultStatusDescription: string;
  statusDate: string;
  dataSupplier: string;
  dataSupplierDescription: string;
};

type Props = {
  data: Default[];
};

function Defaults(props: Props): ReactElement {
  const { data } = props;

  const getDefaults = () =>
    data.map(
      (
        {
          dateOfDefault,
          accountType,
          creditProvider,
          reference,
          originalDefaultAmount,
          currentDefaultBalance,
          defaultStatus,
          statusDate,
          dataSupplier,
        },
        index
      ) => (
        <div key={index}>
          <h4>Default</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Reference</th>
                <td>{reference}</td>
              </tr>
              <tr>
                <th>Account type</th>
                <td>{accountType}</td>
              </tr>
              <tr>
                <th>Credit provider</th>
                <td>{creditProvider}</td>
              </tr>
              <tr>
                <th>Agency</th>
                <td>{}</td>
              </tr>
              <tr>
                <th>Data Supplier</th>
                <td>{dataSupplier}</td>
              </tr>
              <tr>
                <th>Date of Default</th>
                <td>{moment(dateOfDefault).format('DD-MMM-YYYY')}</td>
              </tr>
              <tr>
                <th>Original Amount</th>
                <td>
                  {parseFloat(originalDefaultAmount.toString()).toLocaleString(
                    'en',
                    { maximumFractionDigits: 2 }
                  )}
                </td>
              </tr>
              <tr>
                <th>Current Balance</th>
                <td>
                  {parseFloat(currentDefaultBalance.toString()).toLocaleString(
                    'en',
                    { maximumFractionDigits: 2 }
                  )}
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{defaultStatus}</td>
              </tr>
              <tr>
                <th>Status Date</th>
                <td>{moment(statusDate).format('DD-MMM-YYYY')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );

  return (
    <div className={historyStyles.container}>
      <SubHeading>Defaults</SubHeading>
      {data.length === 0 && (
        <p>There have been no defaults lodged against this company.</p>
      )}
      {data.length > 0 && getDefaults()}
    </div>
  );
}

export default Defaults;
