import React, { useState, useEffect } from 'react';
import FileUpload from 'modules/FileUpload';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import { COB_TRADEACCOUNT_LOAD_SIGNATURE } from 'modules/consumer-onboarding/constants/section';
import { AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS } from 'modules/authorisation/constants';
import {
  IDENTITY_SCRAPE_IMAGE_END,
  IDENTITY_SET_ANTI_FRAUD_RESULT,
} from 'modules/identity/constants';
import { getErrorMessage } from '../components/utils';
import mixpanel from 'mixpanel-browser';
import { get } from 'lodash';

function IDUpload(props) {
  const {
    application,
    authorisation,
    apiType,
    authorisationID,
    currentUser,
    dispatch,
    distinctId,
    getOcrResult,
    hasError,
    idNameMatched,
    image_64,
    back_image_64,
    isAntiFraudEnabled,
    isAntiFraudPassing,
    isFaceMatchEnabled,
    isValidIdentification,
    isTokenised,
    overrideIdValidation,
    resourceType,
    signature,
    attachmentType,
    headerProps,
    isFailedAuthenticity,
    uploadedFile,
    hasDependency,
    fileId,
    idTypeMismatch,
    requiresProofOfLife,
    hasProofOfLifeImage,
    maxRetries,
    isOtherIdType,
    allowPdfUpload,
  } = props;

  // Textract has a limit of 10mb for filesize
  // https://docs.aws.amazon.com/textract/latest/dg/limits.html
  const maxFileSize = 9000000;

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const error = getErrorMessage({
      idNameMatched,
      image_64,
      back_image_64,
      isAntiFraudPassing,
      isFaceMatchEnabled,
      isValidIdentification,
      overrideIdValidation,
      failedAuthenticity: isFailedAuthenticity,
      type: fileId || 'image_64',
      idTypeMismatch,
      hasDependency,
      requiresProofOfLife,
      hasProofOfLifeImage,
      maxRetries,
    });
    setErrorMessage(error);
  }, [
    image_64,
    back_image_64,
    hasError,
    isAntiFraudPassing,
    isValidIdentification,
    isFailedAuthenticity,
    idTypeMismatch,
  ]);

  const updateSignatureImageScrape = (data) => {
    if (authorisation && authorisation.data) {
      return dispatch({
        type: AUTHORISATION_UPDATE_FORMATTED_SCRAPED_DETAILS,
        payload: data,
      });
    }

    const updatedSignature = {
      ...signature,
      formatted_scraped_details: data,
    };
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_SIGNATURE,
      payload: { ...updatedSignature },
    });
  };

  const callback = (data) => {
    //Updates the fields in store to what the data on scraped image is
    dispatch({ type: IDENTITY_SCRAPE_IMAGE_END, payload: data });
    //Sets antiFraudCheckPassed
    dispatch({
      type: IDENTITY_SET_ANTI_FRAUD_RESULT,
      payload: data,
    });
    //Updates formatted_scraped_details from signature
    updateSignatureImageScrape(data);

    //Probably deprecated but sets OCR Result callback under cmm authorisations
    if (getOcrResult) {
      getOcrResult(data);
    }

    if (isAntiFraudEnabled) {
      const attributes = {
        'Entity ID': get(currentUser, 'currentEntity.id'),
        distinct_id: currentUser.id,
      };

      if (application) {
        attributes['Application ID'] = application.id;
      }

      mixpanel.track('1CAF id uploaded', attributes);
    }
  };

  const fileUploadAttachmentType = attachmentType || 'identification_image';

  const acceptedFileTypes = allowPdfUpload
    ? ['jpg', 'jpeg', 'png', 'pdf']
    : ['jpg', 'jpeg', 'png'];
  return (
    <div>
      {!!headerProps && headerProps}
      <FileUpload
        acceptedFileTypes={acceptedFileTypes}
        apiType={apiType}
        authorisationID={authorisationID}
        attachmentType={fileUploadAttachmentType}
        callback={callback}
        distinctId={distinctId}
        errorMessage={errorMessage}
        fileId={fileId || 'image_64'}
        hasError={hasError}
        resourceType={resourceType}
        uploadedFile={uploadedFile}
        maxFileSize={maxFileSize}
        hasDependency={hasDependency}
        maxRetries={maxRetries}
        isOtherIdType={isOtherIdType}
      />
    </div>
  );
}

export default IDUpload;
