import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';
import AddonVersionModel from 'models/AddonVersionModel';
import { loadAdditionalAnswers } from 'modules/consumer-onboarding/actions/additional.js';
import { loadFinancialsAnswers } from 'modules/consumer-onboarding/actions/financials.js';
import {
  clearPaperless,
  loadPaperlessAnswers,
} from 'modules/consumer-onboarding/actions/paperless';
import {
  selectAddress,
  setAddress,
  setIdentity,
  setIdentityValue,
} from 'modules/identity/actions';
import EntityService from 'modules/shared/auth/EntityService';
import { setTheme } from 'modules/shared/helpers/colorPalettes';
import { getBase64Image } from 'modules/shared/helpers/convertURLToBase64.js';
import { signatureLoaderFormatter } from 'modules/shared/helpers/signatureDataFormatter';
import { USER_LOAD_CURRENT_ENTITY_SUCCESS } from 'modules/user/constants';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import { fullNameSplitter } from 'utils/fullNameSplitter.js';
import { getObjectFromArray } from 'utils/getObjectFromArray.js';
import { getObjectsFromArray } from 'utils/getObjectsFromArray.js';
import isBlank from 'utils/isBlank';
import isGuarantorsRequiredForEntityType from 'utils/isGuarantorsRequiredForEntityType';

import api from '../../../api';
import { userLogin } from '../../user/actions';
import {
  COB_SECTION_SET_IS_BUSINESS_OVERVIEW_LOADED,
  COB_SECTION_TOGGLE_IS_APPLICANT_REMOVE_AUTHORISATION,
  COB_TRADEACCOUNT_CLEAR_DATA,
  COB_TRADEACCOUNT_LOAD_1CAF_TYPE,
  COB_TRADEACCOUNT_LOAD_ADDON_RULES,
  COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION,
  COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION_ERROR,
  COB_TRADEACCOUNT_LOAD_AML_CHECK,
  COB_TRADEACCOUNT_LOAD_ANTI_FRAUD,
  COB_TRADEACCOUNT_LOAD_APPLICABLE_LEGAL_TYPES,
  COB_TRADEACCOUNT_LOAD_APPLICATION_ERROR,
  COB_TRADEACCOUNT_LOAD_APPLICATION_START,
  COB_TRADEACCOUNT_LOAD_APPLICATION_SUCCESS,
  COB_TRADEACCOUNT_LOAD_APPLICATIONS_ERROR,
  COB_TRADEACCOUNT_LOAD_APPLICATIONS_START,
  COB_TRADEACCOUNT_LOAD_APPLICATIONS_SUCCESS,
  COB_TRADEACCOUNT_LOAD_AUTHORISATION,
  COB_TRADEACCOUNT_LOAD_COLOR_PALETTE,
  COB_TRADEACCOUNT_LOAD_CONSUMER_TRADING_NAMES,
  COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_ERROR,
  COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_START,
  COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_SUCCESS,
  COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS,
  COB_TRADEACCOUNT_LOAD_CURRENT_TRADING_NAMES_SUCCESS,
  COB_TRADEACCOUNT_LOAD_SIGNATURE,
  COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS,
  COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
  COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START,
  COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
  COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR,
  COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_START,
  COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS,
  COB_TRADEACCOUNT_SET_COLOR_PALETTE,
  COB_TRADEACCOUNT_SET_PEOPLE_FIRSTNAME,
  COB_TRADEACCOUNT_SET_PEOPLE_LASTNAME,
  COB_TRADEACCOUNT_SET_SECTIONS_TO_CLEAR,
  COB_TRADEACCOUNT_SET_SELECTED_TRADING_NAMES,
  COB_TRADEACCOUNT_SET_START_COMPLETE,
  COB_TRADEACCOUNT_SET_TRADING_NAMES,
} from '../constants/section';
import { clearCardholders } from './cards';
import { setQuestionData } from './extras';
import { setGuarantorFormItem, toggleGuarantorIsApplicant } from './guarantors';
import { setMoneyLimit } from './money';
import {
  manualSetCompany,
  preSetCompanyDetails,
  preSetPhysicalAddress,
  preSetPostalAddress,
  selectEntityPartyCount,
  selectEntityType,
  setBusinessApiDetails,
  setCorporateTrusteeDirectors,
  setCorporateTrusteeShareholders,
  setDirectorsLoadingStatus,
  setEntityFormValue,
  setEntityPartyDetails,
} from './onboarding';
import { updateApplicationExtraData } from './review';
import { preSetRefereeData } from './trade-reference';
import { FEATURE_FLAGS } from 'conf';
import { addFlaggedObject } from 'conf/flaggingHelpers';

export function clearApplicationStates() {
  return {
    type: COB_TRADEACCOUNT_CLEAR_DATA,
  };
}

export function setIsBusinessOverviewLoaded() {
  return (dispatch) => {
    dispatch({
      payload: true,
      type: COB_SECTION_SET_IS_BUSINESS_OVERVIEW_LOADED,
    });
  };
}

export function loadApplicationSupplier(application, callback) {
  return (dispatch, getState) => {
    const applications = api(
      'applications',
      getState().current_user.access_token
    );
    dispatch({
      payload: application,
      type: COB_TRADEACCOUNT_LOAD_APPLICATION_START,
    });

    applications.getApplication(
      application,
      (result) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Consumer onboarding Supplier',
              props: {
                'Suppier Contacter':
                  result.data.data.attributes.supplier_contact_name,
                Supplier: result.data.data.attributes.supplier_name,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: result.data.data,
          type: COB_TRADEACCOUNT_LOAD_APPLICATION_SUCCESS,
        });
        const signature = result.data.data.attributes.signature || {};
        dispatch({
          payload: { ...signature },
          type: COB_TRADEACCOUNT_LOAD_SIGNATURE,
        });

        let authorisation = null;

        if (FEATURE_FLAGS.FEATURE_FLAG_DUPLICATE_AUTH_FIX) {
          const all_authorisations =
            result.data.included.filter(
              (inc) => inc.type === 'authorisations'
            ) || null;

          authorisation =
            all_authorisations.find((auth) => !!auth.attributes.user_id) ||
            null;

          dispatch({
            payload: all_authorisations,
            type: COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION,
          });
        } else {
          authorisation =
            result.data.included.find(
              (inc) => inc.type === 'authorisations' && !!inc.attributes.user_id
            ) || null;
        }

        dispatch({
          payload: authorisation,
          type: COB_TRADEACCOUNT_LOAD_AUTHORISATION,
        });

        const amlCheck =
          result.data.included.find(
            (inc) =>
              inc.type === 'addon_rules' &&
              inc.attributes.addon_module_name === 'aml_check'
          ) || null;
        dispatch({
          payload: amlCheck,
          type: COB_TRADEACCOUNT_LOAD_AML_CHECK,
        });

        const antiFraudData =
          result.data.included.find(
            (inc) =>
              inc.type === 'addon_rules' &&
              inc.attributes.addon_module_name.includes('anti_fraud_')
          ) || null;

        let antiFraud = null;

        if (antiFraudData) {
          const antiFraudAddonVersion = new AddonConfigModel(antiFraudData)
            .addonVersion;
          antiFraud = new AddonVersionModel({
            attributes: antiFraudAddonVersion,
          });
        }

        dispatch({
          payload: antiFraud,
          type: COB_TRADEACCOUNT_LOAD_ANTI_FRAUD,
        });

        const applicableLegalTypes =
          result.data.included.find(
            (inc) =>
              inc.type === 'addon_rules' &&
              inc.attributes.addon_module_name === 'applicable_legal_types'
          ) || null;
        dispatch({
          payload: applicableLegalTypes,
          type: COB_TRADEACCOUNT_LOAD_APPLICABLE_LEGAL_TYPES,
        });

        const tradingNames = result.data.included
          .filter((inc) => inc.type === 'trading_names')
          .map((inc) => inc.id);
        dispatch({
          payload: tradingNames,
          type: COB_TRADEACCOUNT_LOAD_CONSUMER_TRADING_NAMES,
        });
        dispatch({
          payload: _formateTradingNameData(result.data.included),
          type: COB_TRADEACCOUNT_LOAD_CURRENT_TRADING_NAMES_SUCCESS,
        });

        const payload = result.data.included.find(
          (inc) => (inc.id = result.data.data.relationships.supplier.data.id)
        );

        if (payload.attributes.uses_custom_supplier_terms) {
          const entities = api(
            'entities',
            getState().current_user.access_token
          );

          entities.getTermsDocument(payload.id).then(function (response) {
            payload.terms_document = response.data.data;

            dispatch({
              payload,
              type: COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS,
            });
          });
        } else {
          dispatch({
            payload,
            type: COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS,
          });
        }
        const promises = [];
        if (result.data.data.relationships.consumer) {
          promises.push(
            dispatch(
              loadCurrentEntity(result.data.data.relationships.consumer.data.id)
            )
          );
        }
        if (callback) {
          promises.push(callback());
        }
        return Promise.all(promises).catch((error) =>
          Promise.reject('Promise List Failed')
        );
      },
      (error) => {
        console.error(error);

        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_LOAD_APPLICATION_ERROR,
        });
      },
      {
        // TODO: Keeping consumer_trading_names for now
        // since bc flow is using this, will take this out
        // once trading name issue is revisited - JIRA - 5567
        params: {
          include:
            'supplier,consumer,authorisations,addon_rules,consumer_trading_names',
        },
      }
    );
  };
}

export function loadUpdatedApplicationAuthorisations(applicationId, callback) {
  return (dispatch, getState) => {
    const applications = api(
      'applications',
      getState().current_user.access_token
    );

    applications.getApplication(
      applicationId,
      (result) => {
        const all_authorisations =
          result.data.included.filter((inc) => inc.type === 'authorisations') ||
          null;

        const authorisation =
          all_authorisations.find((auth) => !!auth.attributes.user_id) || null;

        dispatch({
          payload: all_authorisations,
          type: COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION,
        });

        dispatch({
          payload: authorisation,
          type: COB_TRADEACCOUNT_LOAD_AUTHORISATION,
        });

        if (callback) {
          callback();
        }
      },
      (error) => {
        console.error(error);

        dispatch({
          type: COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION_ERROR,
        });
      },
      {
        params: {
          include: 'authorisations',
        },
      }
    );
  };
}

export function loadCurrentEntity(id) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_START,
    });
    const entityService = new EntityService();
    entityService.setEntity(id);

    const entities = api('entities', getState().current_user.access_token);
    entities.getEntity(
      id,
      (success) => {
        dispatch({
          payload: {
            current_entity: success.data.data,
            current_user_entity_link:
              getState().current_user.data.included.find(
                (x) => x.attributes.entity_id === id
              ),
          },
          type: USER_LOAD_CURRENT_ENTITY_SUCCESS,
        });
        if (success.data.hasOwnProperty('included')) {
          dispatch({
            payload: getObjectsFromArray(success.data.included, 'people'),
            type: COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS,
          });
        }
      },
      (error) => {
        console.error(error);
        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_ERROR,
        });
      },
      {
        params: {
          context: { consumer_flow: true },
          include: 'people,trading_names',
        },
      }
    );
  };
}

function _formateTradingNameData(data) {
  const result = {};
  data
    .filter((item) => item.type === 'trading_names')
    .forEach((item) => {
      result[item.id] = item.attributes;
    });
  return result;
}

export function loadClientPeopleDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START,
    });

    const attributes = getState().cob_business;
    const people = [];
    switch (attributes.entity_type) {
      case 'company':
        if (attributes.entity_region === 'NZ') {
          const company_details_people = attributes.company_details.people
            ? attributes.company_details.people
            : get(attributes, 'company_nzbn_details.people', []);
          company_details_people.forEach((person) => {
            if (
              person.status === 'ACTIVE' &&
              person.relationship === 'Director'
            ) {
              people.push({
                appointed_at: person.valid_from,
                email: null,
                first_name: person.first_name,
                full_address: person.address,
                last_name: person.last_name,
                legal_type: 'director',
                middle_name: person.middle_name,
                name: person.name,
              });
            }
          });
        } else {
          attributes.entity_party_details_values.forEach((person) => {
            people.push({
              email: person.email,
              first_name: person.first_name,
              last_name: person.last_name,
              legal_type: 'director',
              middle_name: person.middle_name,
              name: person.middle_name
                ? `${person.first_name} ${person.middle_name} ${person.last_name}`
                : `${person.first_name} ${person.last_name}`,
            });
          });
        }
        break;
      case 'partnership':
      case 'trust':
        const values = attributes.entity_party_details_values;
        const valuesToUse =
          attributes.entity_type === 'trust' &&
          FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS
            ? values.filter((value) => value.trustee_type !== 'corporate')
            : values;
        valuesToUse.forEach((person) => {
          people.push({
            email: person.email,
            first_name: person.first_name,
            is_applicant: person.is_applicant,
            last_name: person.last_name,
            legal_type:
              attributes.entity_type === 'partnership'
                ? 'partner'
                : person.trustee_type === 'individual'
                ? 'trustee'
                : 'independent_trustee',
            middle_name: person.middle_name,
            name: person.middle_name
              ? `${person.first_name} ${person.middle_name} ${person.last_name}`
              : `${person.first_name} ${person.last_name}`,
            percentage_share: person.percentage_share,
          });
        });
        break;
      case 'sole_trader':
        if (!FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER) {
          break;
        }
        const isNewSoleTraderFlowEnabled =
          getState().cob_section.application.attributes.requires_signatories;
        if (isNewSoleTraderFlowEnabled) {
          attributes.entity_party_details_values.forEach((person) => {
            people.push({
              email: person.email,
              first_name: person.first_name,
              is_applicant: person.is_applicant,
              last_name: person.last_name,
              legal_type: 'sole_trader',
              middle_name: person.middle_name,
              name: person.middle_name
                ? `${person.first_name} ${person.middle_name} ${person.last_name}`
                : `${person.first_name} ${person.last_name}`,
            });
          });
        }
        break;
      case 'personal':
        attributes.entity_party_details_values.forEach((person) => {
          people.push({
            address_id: person.address_id,
            contact_country_code: person.contact_country_code,
            contact_phone_number: person.contact_phone_number,
            email: person.email,
            employer: person.employer,
            first_name: person.first_name,
            is_applicant: person.is_applicant,
            last_name: person.last_name,
            legal_type: 'individual',
            middle_name: person.middle_name,
            name: person.middle_name
              ? `${person.first_name} ${person.middle_name} ${person.last_name}`
              : `${person.first_name} ${person.last_name}`,
            person_postal_address: person.person_postal_address,
            person_postal_address_api_id: person.person_postal_address_api_id,
            position: person.position,
            postal_address_id: person.postal_address_id,
            previous_last_name: person.previous_last_name,
            residential_address: person.residential_address,
            residential_api_id: person.residential_api_id,
            residential_type: person.residential_type,
          });
        });
        break;
    }

    dispatch({
      payload: people,
      type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
    });
  };
}

export function setPeopleData(index, key, value) {
  let type;
  switch (key) {
    case 'firstname':
    case 'first_name':
      type = COB_TRADEACCOUNT_SET_PEOPLE_FIRSTNAME;
      break;
    case 'lastname':
    case 'last_name':
      type = COB_TRADEACCOUNT_SET_PEOPLE_LASTNAME;
      break;
  }
  return {
    payload: {
      index,
      name: key,
      value,
    },
    type,
  };
}

export function setStartComplete(application_id) {
  return (dispatch, getState) => {
    const apps = api(
      'applications',
      getState().current_user.access_token,
      get(getState(), 'current_user.current_entity.id')
    );
    const data = {
      submission_status: 'in_progress',
    };
    apps.getApplication(application_id, (result) => {
      if (result.data.data) {
        if (result.data.data.attributes.submission_status !== 'complete') {
          apps.updateApplication(
            application_id,
            data,
            (result1) => {
              dispatch({
                payload: true,
                type: COB_TRADEACCOUNT_SET_START_COMPLETE,
              });
            },
            (error) => {}
          );
        }
      }
    });
  };
}

function formAttributes(entity, application) {
  const applicationAttributes = application.attributes || {};
  const legalEntityAgreement = extractAttachmentUrl(
    applicationAttributes.legal_entity_agreement,
    'legal_entity_agreement'
  );

  let entityAttributes = {};

  // NOTES: The "current_entity" is sometimes not correct due to it being
  // stored in the session storage. There is a ticket already to resolve and
  // remove this entry from the session storage.
  const applicationConsumerId = get(application, 'attributes.consumer_id');
  if (applicationConsumerId && applicationConsumerId === entity.id) {
    entityAttributes = get(entity, 'attributes', {});
  }

  return {
    business_api_details:
      applicationAttributes.business_api_details ||
      entityAttributes.business_api_details ||
      {},
    business_number:
      applicationAttributes.business_number || entityAttributes.business_number,
    company_name:
      applicationAttributes.company_name || entityAttributes.company_name,
    company_number:
      applicationAttributes.company_number || entityAttributes.company_number,
    contact_phone_number:
      applicationAttributes.contact_phone_number ||
      entityAttributes.contact_phone_number ||
      '',
    delivery_instruction:
      applicationAttributes.delivery_instruction ||
      entityAttributes.delivery_instruction,
    incorporated_number:
      applicationAttributes.incorporated_number ||
      entityAttributes.incorporated_number,
    legal_entity_agreement: legalEntityAgreement,
    legal_type:
      applicationAttributes.legal_type || entityAttributes.legal_type || '',
    physical_address_id:
      applicationAttributes.physical_address_id ||
      entityAttributes.physical_address_id,
    postal_address_id:
      applicationAttributes.postal_address_id ||
      entityAttributes.postal_address_id,
    region: applicationAttributes.region || entityAttributes.region || '',
    registered_at:
      applicationAttributes.registered_at || entityAttributes.registered_at,
    staff_count:
      applicationAttributes.staff_count || entityAttributes.staff_count || '',
    state: applicationAttributes.state || entityAttributes.state || '',
    trading_name:
      applicationAttributes.trading_name || entityAttributes.trading_name || '',
  };
}

/*Load Business section for an existing customer */
export function loadApplicationBusiness(callback) {
  return async (dispatch, getState) => {
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START,
    });

    const user_entity = getState().current_user.current_entity || {};
    const application = getState().cob_section.application || {};
    const attributes = formAttributes(user_entity, application);

    dispatch({
      payload: user_entity,
      type: COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_SUCCESS,
    });

    dispatch(selectEntityType(attributes.legal_type, attributes.legal_type));
    dispatch(setEntityFormValue('entity_name', attributes.trading_name));
    dispatch(setEntityFormValue('entity_staff_count', attributes.staff_count));
    dispatch(
      setEntityFormValue('entity_phone', attributes.contact_phone_number)
    );
    dispatch(setEntityFormValue('entity_region', attributes.region));
    dispatch(setEntityFormValue('entity_state', attributes.state));
    dispatch(
      setEntityFormValue('legal_entity_agreement', {
        url: get(attributes, 'legal_entity_agreement.url'),
      })
    );

    if (attributes.registered_at) {
      const registered_at = attributes.registered_at.split('-');
      dispatch(
        manualSetCompany('registered_at', {
          day: registered_at[2].substring(0, 2),
          month: parseInt(registered_at[1]),
          year: registered_at[0],
        })
      );
    }

    if (attributes.region === 'AU' && attributes.legal_type !== 'personal') {
      dispatch(manualSetCompany('company_name', attributes.company_name));
      dispatch(manualSetCompany('company_number', attributes.company_number));
      dispatch(manualSetCompany('business_number', attributes.business_number));
      dispatch(setBusinessApiDetails(attributes.business_api_details));
    }

    // Load Address
    const addressesAPI = api(
      'addresses',
      getState().current_user.access_token,
      user_entity.id
    );

    if (attributes.physical_address_id) {
      addressesAPI.getAddress(
        attributes.physical_address_id,
        (address) => {
          dispatch(preSetPhysicalAddress(address.data.data));
        },
        (error) => {
          dispatch({
            payload: error,
            type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
          });
        }
      );
    }

    if (attributes.postal_address_id) {
      addressesAPI.getAddress(
        attributes.postal_address_id,
        (address) => {
          dispatch(preSetPostalAddress(address.data.data));
        },
        (error) => {
          dispatch({
            payload: error,
            type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
          });
        }
      );
    }

    const people = [];
    const entities = api('entities', getState().current_user.access_token);
    switch (attributes.legal_type) {
      case 'company':
        if (isBlank(attributes.business_number)) {
          break;
        }

        if (attributes.region === 'NZ') {
          const companySearchAPI = api(
            'company_search',
            getState().current_user.access_token
          );
          companySearchAPI.companyDetails(
            attributes.business_number,
            (data) => {
              dispatch(preSetCompanyDetails(data.data.data.attributes));
              data.data.data.attributes.people.forEach((person) => {
                if (
                  person.status === 'ACTIVE' &&
                  person.relationship === 'Director'
                ) {
                  people.push({
                    appointed_at: person.valid_from,
                    email: null,
                    first_name: person.first_name,
                    full_address: person.address,
                    last_name: person.last_name,
                    legal_type: 'director',
                    middle_name: person.middle_name,
                    name: person.name,
                  });
                }
              });
              dispatch({
                payload: people,
                type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
              });
            },
            (error) => {
              dispatch({
                payload: error,
                type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
              });
            },
            {
              params: { region: 'NZ' },
            }
          );
        } else {
          const companySearchAPI = api(
            'company_search',
            getState().current_user.access_token
          );
          companySearchAPI.companyDetails(
            attributes.business_number,
            (data) => {
              const companyDetails = data.data.data.attributes;
              if (companyDetails) {
                dispatch(preSetCompanyDetails(companyDetails));
              }
            },
            (error) => {},
            {
              params: {
                company_number: attributes.company_number,
                name: attributes.company_name,
                region: attributes.region,
              },
            }
          );

          if (FEATURE_FLAGS.FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS) {
            const loadPeople = (directorsFromApi) => {
              entities.getEntityPeople(
                user_entity.id,
                (data) => {
                  const hasDirectorsFromApi =
                    !!directorsFromApi && !!directorsFromApi.length;

                  const peopleReponse = get(data, 'data.data', []);
                  peopleReponse.forEach((person) => {
                    const shouldFilterOutDirectors = hasDirectorsFromApi
                      ? person.attributes.legal_type !== 'director'
                      : true;
                    if (person.type === 'people' && shouldFilterOutDirectors) {
                      people.push({
                        email: person.attributes.email,
                        first_name: person.attributes.first_name,
                        id: person.id,
                        last_name: person.attributes.last_name,
                        legal_type: person.attributes.legal_type,
                        middle_name: person.attributes.middle_name,
                        name: person.attributes.middle_name
                          ? `${person.attributes.first_name} ${person.attributes.middle_name} ${person.attributes.last_name}`
                          : `${person.attributes.first_name} ${person.attributes.last_name}`,
                        percentage_share: person.attributes.percentage_share,
                      });
                    }
                  });

                  if (hasDirectorsFromApi) {
                    directorsFromApi.forEach((director) => {
                      people.push({
                        appointed_at: director.appointment_date,
                        email: null,
                        first_name: director.first_name,
                        full_address: director.full_address,
                        last_name: director.last_name,
                        legal_type: 'director',
                        middle_name: director.middle_name,
                        name: `${director.first_name} ${
                          director.middle_name ? `${director.middle_name} ` : ''
                        } ${director.last_name}`,
                      });
                    });
                  }

                  people.reverse();
                  dispatch(selectEntityPartyCount(people.length));
                  dispatch(setEntityPartyDetails(people));
                  dispatch({
                    payload: people,
                    type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
                  });
                  dispatch(
                    setDirectorsLoadingStatus(
                      hasDirectorsFromApi ? 'success' : 'error'
                    )
                  );
                },
                (error) => {
                  dispatch({
                    payload: error,
                    type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
                  });
                  dispatch(setDirectorsLoadingStatus('error'));
                }
              );
            };

            const directorFromCreditCheckEnabled = get(
              getState(),
              'cob_section.supplier.attributes.director_from_credit_check_enabled'
            );
            if (
              !!directorFromCreditCheckEnabled &&
              attributes.region === 'AU'
            ) {
              dispatch(setDirectorsLoadingStatus('loading'));
              const accessToken = get(getState(), 'current_user.access_token');
              const creditCheckApi = api('credit_checks', accessToken);

              creditCheckApi
                .getAustralianCompanyDirectors(application.id)
                .then((response) => {
                  const { directors = [] } = response.data || {};
                  // Load directors fetched from api
                  loadPeople(directors);
                })
                .catch((error) => {
                  loadPeople();
                });
            } else {
              loadPeople();
            }
          } else {
            // Old implementation
            entities.getEntityPeople(
              user_entity.id,
              (data) => {
                data.data.data.forEach((person) => {
                  if (person.type === 'people') {
                    people.push({
                      email: person.attributes.email,
                      first_name: person.attributes.first_name,
                      id: person.id,
                      last_name: person.attributes.last_name,
                      legal_type: person.attributes.legal_type,
                      middle_name: person.attributes.middle_name,
                      name: person.attributes.middle_name
                        ? `${person.attributes.first_name} ${person.attributes.middle_name} ${person.attributes.last_name}`
                        : `${person.attributes.first_name} ${person.attributes.last_name}`,
                      percentage_share: person.attributes.percentage_share,
                    });
                  }
                });
                people.reverse();
                dispatch(selectEntityPartyCount(people.length));
                dispatch(setEntityPartyDetails(people));
                dispatch({
                  payload: people,
                  type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
                });
              },
              (error) => {
                dispatch({
                  payload: error,
                  type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR,
                });
              }
            );
          }
        }
        break;
      case 'partnership':
        entities.getEntityPeople(user_entity.id, (data) => {
          data.data.data.forEach((person) => {
            people.push({
              email: person.attributes.email,
              first_name: person.attributes.first_name,
              is_applicant: person.attributes.is_applicant,
              last_name: person.attributes.last_name,
              legal_type: person.attributes.legal_type,
              middle_name: person.attributes.middle_name,
              name: person.attributes.middle_name
                ? `${person.attributes.first_name} ${person.attributes.middle_name} ${person.attributes.last_name}`
                : `${person.attributes.first_name} ${person.attributes.last_name}`,
              percentage_share: person.attributes.percentage_share,
            });
          });
          dispatch(selectEntityPartyCount(people.length));
        });
        people.reverse();
        dispatch(setEntityPartyDetails(people));
        dispatch({
          payload: people,
          type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
        });
        break;
      case 'trust':
        let formattedCorporateTrustees = [];

        const { minimum_guarantees_trust } =
          getState().cob_section.application.attributes;

        if (FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS) {
          const application = getState().cob_section.application.attributes;

          const { corporate_trustees, trustee_directors, region } = application;

          const corporateTrustees = application.corporate_trustees;
          const trusteeDirectors = application.trustee_directors || [];
          if (corporateTrustees) {
            formattedCorporateTrustees = corporateTrustees.map((trustee) => ({
              trustee_type: 'corporate',
              corporate_trustee_id: trustee.id,
              corporate_trustee_company_name: trustee.entity_name,
              corporate_trustee_business_number: trustee.business_number,
              corporate_trustee_company_number: trustee.company_number,
              corporate_trustee_director_count: trusteeDirectors.filter(
                (director) => director.associated_entity_id === trustee.id
              ).length,
            }));

            const corporateTrusteeIds = formattedCorporateTrustees.map(
              (trustee) => trustee.corporate_trustee_id
            );

            const corporateTrusteeDirectors = {};

            corporateTrusteeIds.forEach((id) => {
              corporateTrusteeDirectors[id] = trusteeDirectors.filter(
                (director) => director.associated_entity_id === id
              );
            });

            dispatch(setCorporateTrusteeDirectors(corporateTrusteeDirectors));

            if (region === 'NZ') {
              const companySearchAPI = api('company_search');

              corporateTrustees.forEach((trustee) => {
                const { id, business_number } = trustee;
                companySearchAPI.companyDetails(
                  business_number,
                  ({ data }) => {
                    const { shareholders } = data.data.attributes;
                    if (shareholders && shareholders.length) {
                      dispatch(
                        setCorporateTrusteeShareholders(id, shareholders)
                      );
                    }
                  },
                  () => {},
                  { params: { region } }
                );
              });
            }
          }
        }

        await entities.getEntityPeople(user_entity.id, (data) => {
          data.data.data
            .filter((person) => person.attributes.legal_type !== 'director')
            .forEach((person) => {
              people.push({
                email: person.attributes.email,
                first_name: person.attributes.first_name,
                is_applicant: person.attributes.is_applicant,
                last_name: person.attributes.last_name,
                legal_type: person.attributes.legal_type,
                middle_name: person.attributes.middle_name,
                name: person.attributes.middle_name
                  ? `${person.attributes.first_name} ${person.attributes.middle_name} ${person.attributes.last_name}`
                  : `${person.attributes.first_name} ${person.attributes.last_name}`,
                percentage_share: person.attributes.percentage_share,
                trustee_type:
                  person.attributes.legal_type === 'independent_trustee'
                    ? 'independent'
                    : 'individual',
              });
            });
          dispatch(
            selectEntityPartyCount(
              people.length + formattedCorporateTrustees.length
            )
          );
        });
        people.reverse();
        if (formattedCorporateTrustees.length) {
          formattedCorporateTrustees.forEach((corporateTrustee) =>
            people.unshift(corporateTrustee)
          );
        }
        dispatch(setEntityPartyDetails(people));
        dispatch({
          payload: people,
          type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
        });
        break;
      case 'sole_trader':
        const isNewSoleTraderFlowEnabled =
          getState().cob_section.application.attributes.requires_signatories;
        if (
          isNewSoleTraderFlowEnabled &&
          FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER
        ) {
          entities.getEntityPeople(user_entity.id, (data) => {
            data.data.data.forEach(async (person) => {
              const personAttributes = {
                email: person.attributes.email,
                first_name: person.attributes.first_name,
                is_applicant: person.attributes.is_applicant,
                last_name: person.attributes.last_name,
                legal_type: person.attributes.legal_type,
                middle_name: person.attributes.middle_name,
                name: person.attributes.middle_name
                  ? `${person.attributes.first_name} ${person.attributes.middle_name} ${person.attributes.last_name}`
                  : `${person.attributes.first_name} ${person.attributes.last_name}`,
              };

              people.push(personAttributes);
            });

            const partyCount = 1;
            dispatch(selectEntityPartyCount(partyCount));
          });
          people.reverse();
          dispatch(setEntityPartyDetails(people));

          dispatch({
            payload: people,
            type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
          });
        } else {
          dispatch({
            payload: [
              {
                email: getState().current_user.data.data.attributes.email,
                first_name:
                  getState().current_user.data.data.attributes.first_name,
                last_name:
                  getState().current_user.data.data.attributes.last_name,
                legal_type: 'sole_trader',
                middle_name: null,
                name: `${
                  getState().current_user.data.data.attributes.first_name
                } ${getState().current_user.data.data.attributes.last_name}`,
              },
            ],
            type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
          });
        }
        break;
      case 'personal':
        entities.getEntityPeople(user_entity.id, (data) => {
          data.data.data.forEach(async (person) => {
            const personAttributes = {
              address_id: person.attributes.address_id,
              contact_country_code: person.attributes.contact_country_code,
              contact_phone_number: person.attributes.contact_phone_number,
              email: person.attributes.email,
              employer: person.attributes.employer,
              first_name: person.attributes.first_name,
              is_applicant: person.attributes.is_applicant,
              last_name: person.attributes.last_name,
              legal_type: person.attributes.legal_type,
              middle_name: person.attributes.middle_name,
              name: person.attributes.middle_name
                ? `${person.attributes.first_name} ${person.attributes.middle_name} ${person.attributes.last_name}`
                : `${person.attributes.first_name} ${person.attributes.last_name}`,
              person_postal_address: {},
              person_postal_address_api_id: null,
              person_postal_address_list: {},
              person_postal_address_loading: false,
              person_postal_address_raw_list: {},
              position: person.attributes.position,
              postal_address_id: person.attributes.postal_address_id,
              previous_last_name: person.attributes.previous_last_name,
              residential_address: {},
              residential_address_list: {},
              residential_address_loading: false,
              residential_address_raw_list: {},
              residential_api_id: null,
              residential_type: person.attributes.residential_type,
            };

            if (person.attributes.address_id) {
              const residentialAddressAttributes =
                await loadPersonalResidentialAddress(
                  addressesAPI,
                  person.attributes.address_id
                );
              personAttributes.residential_api_id =
                residentialAddressAttributes.residential_api_id;
              personAttributes.residential_address =
                residentialAddressAttributes.residential_address;
            }

            if (person.attributes.postal_address_id) {
              const postalAddressAttributes = await loadPersonalPostalAddress(
                addressesAPI,
                person.attributes.postal_address_id
              );

              personAttributes.person_postal_address_api_id =
                postalAddressAttributes.person_postal_address_api_id;
              personAttributes.person_postal_address =
                postalAddressAttributes.person_postal_address;
            }

            people.push(personAttributes);
          });

          const retrievedPartyCount = get(data, 'data.data.length', 1);
          const configuredPartyCount = get(
            getState(),
            'cob_section.applicableLegalTypes.attributes.addon_version.config.entity_party_count',
            null
          );

          const partyCount = getPartyCount({
            configuredPartyCount,
            retrievedPartyCount,
          });
          dispatch(selectEntityPartyCount(partyCount));
        });
        people.reverse();
        dispatch(setEntityPartyDetails(people));

        dispatch({
          payload: people,
          type: COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS,
        });
        break;
    }

    if (callback) {
      callback();
    }
  };
}

const getPartyCount = ({ retrievedPartyCount, configuredPartyCount }) => {
  if (retrievedPartyCount > 0) {
    return retrievedPartyCount;
  }

  return configuredPartyCount;
};

// This is the previous behaviour of loadApplicationSections.
// Keeping this but renamed to something else to make the rest of the BC flow
// work as before
function loadPreviousApplications(currentApplication) {
  return (dispatch, getState) => {
    const entityId = get(getState(), 'current_user.current_entity.id');

    if (isBlank(entityId)) {
      dispatch({ type: COB_TRADEACCOUNT_LOAD_APPLICATIONS_SUCCESS });

      return;
    }

    const applications = api(
      'applications',
      getState().current_user.access_token,
      getState().current_user.current_entity.id,
      { headers: { 'Content-Type': 'application/json' }, version: '/v2' }
    );

    let data = [];

    applications.getApplications(
      (result) => {
        if (result.data.data.length) {
          data = result.data.data;

          data.sort(function (a, b) {
            return (
              new Date(a.attributes.created_at).getTime() -
              new Date(b.attributes.created_at).getTime()
            );
          });
          data = data.filter((a) => a.id !== currentApplication.id);
          data.reverse();

          if (
            currentApplication.attributes.minimum_trade_references > 0 &&
            currentApplication.attributes.application_type === 'credit'
          ) {
            dispatch(
              loadApplicationReference(
                data,
                currentApplication,
                currentApplication.attributes.minimum_trade_references
              )
            );
          }

          const entityType = getState().cob_business.entity_type;
          const partyCount = getState().cob_business.entity_party_count;
          const isGuarantorRequired = isGuarantorsRequiredForEntityType(
            entityType,
            partyCount
          );

          // Load the guarantors only if they are required. This is for instances
          // when a personal joint account has been originally selected and would
          // require guarantors and have already been persisted in the database.
          // Then changed to a personal individual account wherein guarantors are
          // no longer required.
          if (isGuarantorRequired) {
            dispatch(
              loadApplicationGuarantors(
                currentApplication.id,
                currentApplication.attributes.minimum_guarantees
              )
            );
          }
        }

        dispatch({ type: COB_TRADEACCOUNT_LOAD_APPLICATIONS_SUCCESS });
      },
      (error) => {
        console.error(error);

        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_LOAD_APPLICATIONS_ERROR,
        });
      },
      { params: { 'filters[consumer_id]': entityId } }
    );
  };
}

export function loadApplicationSections(id) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_APPLICATIONS_START,
    });

    const applicationsAPI = api(
      'applications',
      getState().current_user.access_token,
      get(getState(), 'current_user.current_entity.id')
    );

    applicationsAPI.getApplication(
      id,
      (result) => {
        const currentApplication = get(result, 'data.data', {});

        dispatch(
          setMoneyLimit(currentApplication.attributes.trade_account_limit)
        );

        const currentSignature =
          currentApplication.attributes.signature || null;
        const signatures = [];
        if (currentSignature) {
          signatures.push({
            attributes: {
              ...currentSignature,
              address: {
                ...currentApplication.attributes.signature_address,
              },
            },
          });
        }
        dispatch(loadApplicationSignature(signatures));
        dispatch(loadPreviousApplications(currentApplication));
      },
      (error) => {
        console.error(error);

        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_LOAD_APPLICATIONS_ERROR,
        });
      }
    );
  };
}

/*Load Signature section for an existing customer */
export function loadApplicationSignature(signatures) {
  return (dispatch) => {
    let signature = signatures.find(
      (s) => s.attributes.identification_type !== 'no_identification'
    );
    if (!signature) {
      signature = signatures[0];
    }
    // signature data structure of old api is different from new v2 api
    // so it changes to use signatureLoaderFormatter
    if (signature) {
      if (signature.attributes.address) {
        const address = signature.attributes.address;
        signature.attributes.address = {};
        signature.attributes.address.data = { attributes: address };
      }
      if (signature.attributes.identification_image_base64) {
        signature.attributes.identification_image.identification_image = {
          url: signature.attributes.identification_image_base64,
        };
      }
    }
    dispatch(setIdentity(signatureLoaderFormatter(signature)));
  };
}

/*Load Trade-Reference section for an existing customer */
export function loadApplicationReference(
  applications,
  current_application,
  minimum_trade_references
) {
  return (dispatch, getState) => {
    const trade_references = [];
    const application_api = api(
      'applications',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    if (current_application) {
      if (trade_references.length < minimum_trade_references) {
        application_api.getApplication(
          current_application.id,
          (result) => {
            if (result.data.included) {
              result.data.included.forEach((ref) => {
                if (trade_references.length < minimum_trade_references) {
                  const exit_trade_ref = trade_references.find(
                    (t) =>
                      t.business_name == ref.attributes.business_name &&
                      t.first_name == ref.attributes.first_name &&
                      t.last_name == ref.attributes.last_name
                  );
                  if (!exit_trade_ref && !ref.archived) {
                    trade_references.push({
                      business_name: ref.attributes.business_name,
                      contact_country_code: ref.attributes.contact_country_code,
                      contact_phone: ref.attributes.contact_phone_number,
                      email: ref.attributes.email,
                      first_name: ref.attributes.first_name,
                      id: ref.id,
                      last_name: ref.attributes.last_name,
                      previous_business_name:
                        ref.attributes.previous_business_name,
                    });
                  }
                }
              });
            }
          },
          (error) => {
            console.error(error);
          },
          { params: { include: 'trade_references' } }
        );
      }
    }

    applications.forEach((a) => {
      if (trade_references.length < minimum_trade_references) {
        application_api.getApplication(
          a.id,
          (result) => {
            if (result.data.included) {
              result.data.included.forEach((ref) => {
                if (trade_references.length < minimum_trade_references) {
                  const exit_trade_ref = trade_references.find(
                    (t) =>
                      t.business_name == ref.attributes.business_name &&
                      t.first_name == ref.attributes.first_name &&
                      t.last_name == ref.attributes.last_name
                  );
                  if (!exit_trade_ref && !ref.archived) {
                    trade_references.push({
                      business_name: ref.attributes.business_name,
                      contact_country_code: ref.attributes.contact_country_code,
                      contact_phone: ref.attributes.contact_phone_number,
                      first_name: ref.attributes.first_name,
                      last_name: ref.attributes.last_name,
                    });
                  }
                }
              });
            }
          },
          (error) => {
            console.error(error);
          },
          { params: { include: 'trade_references' } }
        );
      }
    });
    dispatch(preSetRefereeData(trade_references));
  };
}

export function loadSupplier(id) {
  return (dispatch, getState) => {
    dispatch({
      type: COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_START,
    });

    const websitebuttons = api('websitebuttons');
    websitebuttons.getSupplier(
      id,
      (success) => {
        dispatch({
          payload: success.data.data,
          type: COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS,
        });
        if (success.data.data.attributes.theme) {
          setTheme(success.data.data.attributes.theme);
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR,
        });
      }
    );
  };
}

export function loadSupplierAntiFraudType(moduleName) {
  return (dispatch) => {
    dispatch({
      payload: moduleName,
      type: COB_TRADEACCOUNT_LOAD_1CAF_TYPE,
    });
  };
}

export function loadSupplierWithCallbacks({
  id,
  successCallback,
  errorCallback,
}) {
  return (dispatch) => {
    const websitebuttons = api('websitebuttons');
    websitebuttons.getSupplier(
      id,
      (success) => {
        dispatch({
          payload: success.data.data,
          type: COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS,
        });
        if (success.data.data.attributes.theme) {
          setTheme(success.data.data.attributes.theme);
        }

        if (successCallback) {
          successCallback();
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR,
        });

        if (errorCallback) {
          errorCallback();
        }
      }
    );
  };
}

export function loadCurrentPeople(id) {
  return (dispatch, getState) => {
    const entities = api('entities', getState().current_user.access_token);
    entities.getEntityPeople(id, (success) => {
      dispatch({
        payload: success.data.data,
        type: COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS,
      });
    });
  };
}

export function loadApplicationGuarantors(id, minimumGuarantees) {
  return (dispatch, getState) => {
    const guarantors = [];
    const applicationApi = api(
      'applications',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    applicationApi.getApplication(
      id,
      (result) => {
        if (result.data.included) {
          result.data.included.forEach((value, index) => {
            if (guarantors.length < minimumGuarantees) {
              dispatch(
                setGuarantorFormItem(index, 'name', value.attributes.name)
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'first_name',
                  value.attributes.first_name
                )
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'last_name',
                  value.attributes.last_name
                )
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'middle_name',
                  value.attributes.middle_name
                )
              );
              dispatch(setGuarantorFormItem(index, 'id', value.id));
              if (value.attributes.email) {
                dispatch(
                  setGuarantorFormItem(index, 'email', value.attributes.email)
                );
              }
              dispatch(
                setGuarantorFormItem(
                  index,
                  'percentage_share',
                  value.attributes.percentage_share || null
                )
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'position',
                  value.attributes.position || null
                )
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'is_applicant',
                  value.attributes.is_applicant || null
                )
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'approved',
                  value.attributes.approved || false
                )
              );
              dispatch(
                setGuarantorFormItem(
                  index,
                  'associated_entity_id',
                  value.attributes.associated_entity_id || null
                )
              );
              dispatch(
                toggleGuarantorIsApplicant(
                  index,
                  value.attributes.is_applicant,
                  value.attributes
                )
              );
              guarantors.push({
                email: value.attributes.email,
                first_name: value.attributes.first_name,
                id: value.id,
                last_name: value.attributes.last_name,
                middle_name: value.attributes.middle_name,
                name: value.attributes.name,
                percentage_share: value.attributes.percentage_share,
                position: value.attributes.position,
                associated_entity_id: value.attributes.associated_entity_id,
              });
            } else {
            }
          });
        }
      },
      (error) => {
        console.error(error);
      },
      { params: { include: 'guarantors' } }
    );
  };
}

/*Load Signature section from entity party details for new customer */
export function loadApplicationSignatureFromPartyDetails() {
  return (dispatch, getState) => {
    const person =
      getState().cob_business.entity_party_details_values.length > 0
        ? getState().cob_business.entity_party_details_values[0]
        : null;
    const identity = getState().identity;

    if (person) {
      if (identity.first_name == '') {
        dispatch(setIdentityValue('first_name', person.first_name));
      }
      if (identity.last_name == '') {
        dispatch(setIdentityValue('last_name', person.last_name));
      }
      if (Object.keys(identity.address).length === 0) {
        dispatch(
          setAddress({
            api_id: person.residential_address.api_id,
            api_provider: person.residential_address.api_provider,
            full_address: person.residential_address.full_address,
            raw_data: person.residential_address.raw_data,
          })
        );
      }
    }
  };
}

export function loadApplicationAddonRules(rules) {
  return {
    payload: rules,
    type: COB_TRADEACCOUNT_LOAD_ADDON_RULES,
  };
}

export function loadApplicationAddonAnswers(answers) {
  return (dispatch) => {
    answers.forEach((answer) => {
      switch (answer.attributes.addon_module_name) {
        case 'additional':
          dispatch(loadAdditionalAnswers(answer));
          break;
        case 'financials':
          dispatch(loadFinancialsAnswers(answer));
          break;
        case 'paperless':
          dispatch(loadPaperlessAnswers(answer));
          break;
      }
    });
  };
}

export function getAppColorPalette(applicationId) {
  return async (dispatch, getState) => {
    const applications = api(
      'applications',
      getState().current_user.access_token
    );
    dispatch({ type: COB_TRADEACCOUNT_LOAD_COLOR_PALETTE });

    try {
      const response = await applications.getAppColorPalette(applicationId);
      dispatch({
        payload: response.data,
        type: COB_TRADEACCOUNT_SET_COLOR_PALETTE,
      });
      setTheme(response.data);
    } catch (error) {
      /* do nothing */
    }
  };
}

async function loadPersonalResidentialAddress(addressesAPI, addressId) {
  try {
    const response = await addressesAPI.getAddress(addressId);
    const attributes = response.data.data.attributes;

    return {
      residential_address: {
        api_id: attributes.api_id,
        api_provider: attributes.api_provider,
        full_address: attributes.full_address,
        raw_data: attributes.raw_data,
      },
      residential_api_id: attributes.api_id,
    };
  } catch (error) {
    return {
      residential_address: {},
      residential_api_id: null,
    };
  }
}

async function loadPersonalPostalAddress(addressesAPI, addressId) {
  try {
    const response = await addressesAPI.getAddress(addressId);
    const attributes = response.data.data.attributes;

    return {
      person_postal_address: {
        api_id: attributes.api_id,
        api_provider: attributes.api_provider,
        full_address: attributes.full_address,
        raw_data: attributes.raw_data,
      },
      person_postal_address_api_id: attributes.api_id,
    };
  } catch (error) {
    return {
      person_postal_address: {},
      person_postal_address_api_id: null,
    };
  }
}

export function setTradingNameOptions(options) {
  return (dispatch, getState) => {
    dispatch({
      payload: options,
      type: COB_TRADEACCOUNT_SET_TRADING_NAMES,
    });
  };
}

export function setSelectedTradingNames(tradingNameIds) {
  return (dispatch, getState) => {
    dispatch({
      payload: tradingNameIds,
      type: COB_TRADEACCOUNT_SET_SELECTED_TRADING_NAMES,
    });
  };
}

const pages = {
  cards: {
    checkAffected: willCardsAffected,
    clear: clearCardholders,
  },
  paperless: {
    checkAffected: willPaperlessAffected,
    clear: clearPaperless,
  },
  ...addFlaggedObject('FEATURE_FLAG_DUPLICATE_AUTH_FIX', {
    signatory: {
      checkAffected: () => true,
    },
  }),
};

function willCardsAffected(state) {
  return state.cob_cards.cardholders.length > 0;
}

function willPaperlessAffected(state) {
  const answers = state.cob_paperless.answers;

  const answerKeys = ['account', 'accountNumber'];

  return answerKeys.some((key) => !!answers[key]);
}

export function displayDataLostWarning(params) {
  const { signatoryData } = params || {};
  return (dispatch, getState) => {
    const state = getState();

    const nothingToClear = state.cob_section.sectionsToClear.length === 0;
    const affectedPages = dispatch(getAffectedPages({ signatoryData }));
    return nothingToClear && affectedPages.length > 0;
  };
}

export function getDuplicateAuthorisationId(allAuth, applicantData) {
  const { name: applicantName } = applicantData || {};

  return allAuth.find((auth) => {
    const currAuth = auth.attributes;
    const { full_name: currAuthName } = currAuth || {};

    return (
      currAuthName &&
      applicantName &&
      currAuthName.toLowerCase() === applicantName.toLowerCase() &&
      !currAuth.is_applicant &&
      !applicantData.is_applicant
    );
  });
}

export function getAffectedPages(params) {
  return (dispatch, getState) => {
    const state = getState();
    const addonRules = state.cob_section.addonRules;

    const affectedPagesKey =
      addonRules.length > 0
        ? addonRules.map(({ attributes }) => attributes.addon_module_name)
        : [];

    if (FEATURE_FLAGS.FEATURE_FLAG_DUPLICATE_AUTH_FIX) {
      const { signatoryData } = params || {};

      const { selectedValue, signatory } = signatoryData || {};
      const allAuth = get(state, 'cob_section.allAuthorisations');
      const duplicateAuth = getDuplicateAuthorisationId(allAuth, signatory);

      if (duplicateAuth && selectedValue === 'Yes') {
        affectedPagesKey.push('signatory');

        const duplicateAuthId = get(state, 'cob_section.duplicateAuthId');
        if (duplicateAuthId !== duplicateAuth.id) {
          dispatch({
            payload: duplicateAuth.id,
            type: COB_SECTION_TOGGLE_IS_APPLICANT_REMOVE_AUTHORISATION,
          });
        }
      }
    }

    return affectedPagesKey.filter(
      (key) => pages[key] && pages[key].checkAffected(state)
    );
  };
}

export function setAffectedPages(affectedPages) {
  return (dispatch) =>
    dispatch({
      payload: affectedPages,
      type: COB_TRADEACCOUNT_SET_SECTIONS_TO_CLEAR,
    });
}

export function clearAffectedPages() {
  return (dispatch, getState) => {
    const affectedPages = getState().cob_section.sectionsToClear;

    if (affectedPages.length === 0) {
      return;
    }

    affectedPages.forEach((page) => {
      if (
        FEATURE_FLAGS.FEATURE_FLAG_DUPLICATE_AUTH_FIX &&
        !pages[page].hasOwnProperty('clear')
      ) {
        return;
      }
      dispatch(pages[page].clear());
    });

    dispatch(setAffectedPages([]));
  };
}

export function deleteDuplicateAuthorisation() {
  return (dispatch, getState) => {
    const state = getState();
    const duplicateAuthId = get(state, 'cob_section.duplicateAuthId');
    const authorisations = api(
      'authorisations',
      state.current_user.access_token,
      state.current_user.current_entity.id
    );

    authorisations.deleteAuthorisation(duplicateAuthId, () => {
      dispatch({
        payload: null,
        type: COB_SECTION_TOGGLE_IS_APPLICANT_REMOVE_AUTHORISATION,
      });
    });
  };
}

export function getAddonRules() {
  return (dispatch, getState) => {
    const currentEntity = getState().current_user.current_entity;
    const cobMoney = getState().cob_money;
    const addonRules = getState().cob_section.addonRules;

    if (!cobMoney.requested_limit || addonRules.length > 0) {
      return;
    }

    dispatch(updateApplicationExtraData(currentEntity.id));
  };
}

export function setToInprogress(applicationId) {
  return (_, getState) => {
    const applicationsAPI = api(
      'applications',
      getState().current_user.access_token
    );

    applicationsAPI.toInProgress(applicationId);
  };
}
