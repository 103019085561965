import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import BorderedTextField from '../../inputs/BorderedTextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { styled } from '@material-ui/core/styles';
import { UseFormRegisterReturn } from 'react-hook-form-latest';

const StyledTextField = styled(BorderedTextField)({
  minWidth: 224,
});

type Props = {
  isControlOptional?: boolean;
  checkboxId: string;
  error?: string;
  inputName?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  onCheck?: (isChecked: boolean) => void;
  register?: UseFormRegisterReturn;
  type?: 'number' | 'currency';
  fullWidth?: boolean;
};

const RangeItem: FC<Props> = (props) => {
  const {
    isControlOptional,
    checkboxId,
    error,
    isChecked,
    isDisabled,
    label,
    value,
    onChange,
    onCheck,
    register,
    type = 'number',
    fullWidth,
  } = props;
  const [checked, setChecked] = useState(isChecked);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (onChange) {
      onChange(value);
    } else {
      setInputValue(value);
    }
  };

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (onCheck) {
      onCheck(checked);
    } else {
      setChecked(checked);
    }
  };

  const startAdornment =
    type === 'currency' ? (
      <InputAdornment position="start">$</InputAdornment>
    ) : undefined;

  return (
    <Box display="flex" flex={fullWidth ? 1 : undefined}>
      {!isControlOptional && (
        <Box mr={2}>
          <SquareCheckbox
            disabled={isDisabled}
            label={label}
            checkboxId={checkboxId}
            checked={checked}
            handleChange={handleCheck}
            number_only
          />
        </Box>
      )}

      <StyledTextField
        customProps={{ startAdornment }}
        disabled={!checked || isDisabled}
        onChange={handleInputValueChange}
        register={register}
        value={inputValue}
        error={!!error}
        helperText={error}
        type="number"
        number_only
      />
    </Box>
  );
};

export default RangeItem;
