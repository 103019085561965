import './menuitem.scss';
import React, { FC, ReactElement } from 'react';
import StatusIndicator from 'modules/new-applications/components/StatusIndicator';
import { TitleIconContainer } from './style';
import { IMenuItem } from 'containers/next-gen/types/types';

const IndicatorContent = ({
  indicator,
  isIndicatorVisible,
  status,
  statusName,
}: {
  indicator?: boolean;
  isIndicatorVisible?: boolean;
  status?: string;
  statusName?: string;
}): ReactElement => {
  if (!isIndicatorVisible) {
    return <></>;
  }

  let dotStatus = 'pending';
  let dotLabel = 'Off';

  if (indicator) {
    dotStatus = 'complete';
    dotLabel = 'On';
  }

  if (status) {
    dotStatus = status;
    dotLabel = statusName || '';
  }

  return (
    <StatusIndicator
      withStatusName={true}
      status={dotStatus}
      statusName={dotLabel}
    />
  );
};

type MenuItemProps = {
  onClick?: () => void;
  isActive: boolean;
  rightIconButton?: React.ReactNode;
  customIndicator?: React.ReactNode;
} & IMenuItem;

const MenuItem: FC<MenuItemProps> = ({
  indicator,
  isActive,
  isIndicatorVisible,
  onClick,
  title,
  placeHolder,
  status,
  statusName,
  rightIconButton,
  customIndicator,
}): ReactElement => (
  <div
    onClick={onClick}
    className={'menu-item' + `${isActive ? ' menu-item__active' : ''}`}
  >
    <TitleIconContainer>
      {title ? (
        <p className="menu-item-title">{title}</p>
      ) : (
        <p className="menu-item__placeHolder">{placeHolder}</p>
      )}
      {rightIconButton}
    </TitleIconContainer>
    {customIndicator ? (
      customIndicator
    ) : (
      <span className={`indicator-wrapper ${indicator ? 'active' : ''}`}>
        <IndicatorContent
          isIndicatorVisible={isIndicatorVisible}
          status={status}
          statusName={statusName}
        />
      </span>
    )}
  </div>
);

MenuItem.defaultProps = {
  isIndicatorVisible: true,
};

export default MenuItem;
