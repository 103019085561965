import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { SignatureFont } from '../SignatureFont';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const PreviewContainer = styled.div`
  padding: 0 16px;
  background-color: #fff;
`;

const ChangeStyleText = styled(Typography)`
  color: var(--main-color);
  cursor: pointer;
`;

type PreviewProps = {
  text: string;
  font: string | null;
  image: string | null;
  handleChangeStyle: () => void;
};

export const Preview = (props: PreviewProps) => {
  const { text, font, image, handleChangeStyle } = props;
  return (
    <Container>
      <ControlsContainer>
        <Typography>Preview</Typography>
        <ChangeStyleText onClick={handleChangeStyle}>
          Change style
        </ChangeStyleText>
      </ControlsContainer>
      <PreviewContainer>
        {font && <SignatureFont text={text} font={font} />}
        {image && <img src={image} />}
      </PreviewContainer>
    </Container>
  );
};
