import get from 'lodash.get';
import React, { Component } from 'react';
import { formatMoney } from 'utils/formatting';

import styles from '../css/payment-predictor/AdverseInformation.css';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';

export default class AdverseInformation extends Component {
  get courtActionsSummary() {
    return get(this.props, 'response.court_actions_summary') || {};
  }

  get collectionsAndDefaultsSummary() {
    return get(this.props, 'response.collections_and_default_summary') || {};
  }

  renderCourtActions() {
    const threeToSixMonths =
      parseInt(this.courtActionsSummary.total_court_actions3_to6_months) || 0;
    const sixToTwelveMonths =
      parseInt(this.courtActionsSummary.total_court_actions6_to12_months) || 0;
    const zeroToTwelveMonths =
      parseInt(this.courtActionsSummary.total_court_actions0_to12_months) || 0;
    const zeroToSixtyMonths =
      parseInt(this.courtActionsSummary.total_court_actions0_to60_months) || 0;

    return (
      <tr>
        <td>Court actions</td>
        <td className={styles.total_cell}>
          {parseInt(this.courtActionsSummary.total_court_actions) || 0}
        </td>
        <td>
          {parseInt(this.courtActionsSummary.total_court_actions3_months) || 0}
        </td>
        <td>{threeToSixMonths + sixToTwelveMonths}</td>
        <td>{zeroToSixtyMonths - zeroToTwelveMonths}</td>
      </tr>
    );
  }

  renderCourtActionAmount() {
    const zeroToThreeMonths =
      parseFloat(
        this.courtActionsSummary.total_court_action_amount0_to3_months
      ) || 0;
    const zeroToTwelveMonths =
      parseFloat(
        this.courtActionsSummary.total_court_action_amount0_to12_months
      ) || 0;
    const zeroToSixtyMonths =
      parseFloat(
        this.courtActionsSummary.total_court_action_amount0_to60_months
      ) || 0;

    const fourToTwelveMonths = zeroToTwelveMonths - zeroToThreeMonths;
    const thirteenToSixtyMonths = zeroToSixtyMonths - zeroToTwelveMonths;

    return (
      <tr>
        <td>Court action amount</td>
        <td className={styles.total_cell}>{`$${formatMoney(
          zeroToSixtyMonths,
          2
        )}`}</td>
        <td>{`$${formatMoney(zeroToThreeMonths, 2)}`}</td>
        <td>{`$${formatMoney(fourToTwelveMonths, 2)}`}</td>
        <td>{`$${formatMoney(thirteenToSixtyMonths, 2)}`}</td>
      </tr>
    );
  }

  renderCollectionAndDefaults() {
    const threeToSixMonths =
      parseInt(
        this.collectionsAndDefaultsSummary
          .total_collections_and_defaults3_to6_months
      ) || 0;
    const sixToTwelveMonths =
      parseInt(
        this.collectionsAndDefaultsSummary
          .total_collections_and_defaults6_to12_months
      ) || 0;
    const zeroToTwelveMonths =
      parseInt(
        this.collectionsAndDefaultsSummary
          .total_collections_and_defaults0_to12_months
      ) || 0;
    const zeroToSixtyMonths =
      parseInt(
        this.collectionsAndDefaultsSummary
          .total_collections_and_defaults0_to60_months
      ) || 0;

    return (
      <tr>
        <td>Collection Notices and Defaults</td>
        <td className={styles.total_cell}>
          {parseInt(
            this.collectionsAndDefaultsSummary.total_collectionsand_defaults
          ) || 0}
        </td>
        <td>
          {parseInt(
            this.collectionsAndDefaultsSummary
              .total_collections_and_defaults3_months
          ) || 0}
        </td>
        <td>{threeToSixMonths + sixToTwelveMonths}</td>
        <td>{zeroToSixtyMonths - zeroToTwelveMonths}</td>
      </tr>
    );
  }

  renderCollectionAndDefaultsAmount() {
    const threeMonths =
      parseFloat(
        this.collectionsAndDefaultsSummary
          .total_amt_owing_collections_and_defaults3_months
      ) || 0;
    const threeToSixMonths =
      parseFloat(
        this.collectionsAndDefaultsSummary
          .total_amt_owing_collections_and_defaults3_to6_months
      ) || 0;
    const sixToTwelveMonths =
      parseFloat(
        this.collectionsAndDefaultsSummary
          .total_amt_owing_collections_and_defaults6_to12_months
      ) || 0;
    const zeroToSixtyMonths =
      parseFloat(
        this.collectionsAndDefaultsSummary
          .total_amt_owing_collections_and_defaults0_to60_months
      ) || 0;

    const fourToTwelveMonths = threeToSixMonths + sixToTwelveMonths;
    const zeroToTwelveMonths =
      parseFloat(
        this.collectionsAndDefaultsSummary
          .total_amt_owing_collections_and_defaults0_to12_months
      ) || 0;
    const thirteenToSixtyMonths = zeroToSixtyMonths - zeroToTwelveMonths;

    return (
      <tr>
        <td>Collection and Default amount</td>
        <td className={styles.total_cell}>{`$${formatMoney(
          zeroToSixtyMonths,
          2
        )}`}</td>
        <td>{`$${formatMoney(threeMonths, 2)}`}</td>
        <td>{`$${formatMoney(fourToTwelveMonths, 2)}`}</td>
        <td>{`$${formatMoney(thirteenToSixtyMonths, 2)}`}</td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        <table
          className={`${commonStyles.table} ${styles.adverse_information_table}`}
        >
          <thead>
            <tr>
              <th>Adverse Information</th>
              <th>Total</th>
              <th>0-3 mths</th>
              <th>4-12 mths</th>
              <th>13-60 mths</th>
            </tr>
          </thead>
          <tbody>
            {this.renderCourtActions()}
            {this.renderCourtActionAmount()}
            {this.renderCollectionAndDefaults()}
            {this.renderCollectionAndDefaultsAmount()}
          </tbody>
        </table>
      </div>
    );
  }
}
