import api from 'api';
import BaseModel from 'models/BaseModel';
import AuthorisationRequestRequesteeModel from 'models/AuthorisationRequestRequesteeModel';
import AuthorisationRequestAwaitingPermissionModel from 'models/AuthorisationRequestAwaitingPermissionModel';
import get from 'lodash.get';
import {
  IRequesterAttribute,
  IRequesteesAttribute,
} from 'types/authorisationRequest';
import isBlank from 'utils/isBlank';

export type RequestStatus =
  | 'approved'
  | 'not_started'
  | 'rejected'
  | 'request_sent';

export default class AuthorisationRequestModel extends BaseModel {
  // This is to retrieve if there are already existing requests for an
  // application and the consumer is not yet known during initial page load
  static retrieveForApplication = async ({
    accessToken,
    applicationId,
  }: {
    accessToken: string;
    applicationId: string;
  }): Promise<AuthorisationRequestModel> => {
    const onboardingAPI = api('onboarding_entity_details', accessToken);

    try {
      const result = await onboardingAPI.fetchAuthorisationRequestByApplication(
        { applicationId }
      );

      return new AuthorisationRequestModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
    } catch (error) {
      console.error(error);
      return new AuthorisationRequestModel();
    }
  };

  // This is to retrieve records when the requested consumer ID is loaded
  static retrieveForConsumerAndRequester = async ({
    accessToken,
    applicationId,
    consumerId,
    requesterId,
  }: {
    accessToken: string;
    applicationId: string;
    consumerId: string;
    requesterId: string;
  }): Promise<AuthorisationRequestModel> => {
    const onboardingAPI = api('onboarding_entity_details', accessToken);

    try {
      const result = await onboardingAPI.fetchAuthorisationRequestByConsumerAndRequester(
        {
          applicationId,
          consumerId,
          requesterId,
        }
      );

      return new AuthorisationRequestModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
    } catch (error) {
      console.error(error);
      return new AuthorisationRequestModel();
    }
  };

  static updateAuthorisationRequest = async ({
    accessToken,
    applicationId,
    consumerId,
    requestees,
    requester,
  }: {
    accessToken: string;
    applicationId: string;
    consumerId: string;
    requestees: IRequesteesAttribute[];
    requester: IRequesterAttribute;
  }): Promise<AuthorisationRequestModel | null> => {
    const onboardingAPI = api('onboarding_entity_details', accessToken);

    try {
      const result = await onboardingAPI.updateAuthorisationRequest({
        applicationId,
        consumerId,
        requestees,
        requester,
      });

      return new AuthorisationRequestModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
    } catch (error) {
      console.error(error);
      return new AuthorisationRequestModel();
    }
  };

  awaitingPermissions: AuthorisationRequestAwaitingPermissionModel[] = [];

  businessNumber: string | null;

  companyNumber: string | null;

  requestees: AuthorisationRequestRequesteeModel[] = [];

  status: RequestStatus;

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignDefaultValues();
    this.assignRelationships();
  }

  get lockDetails(): boolean {
    if (!this.isPersisted) {
      return false;
    }

    return (
      this.status &&
      ['approved', 'rejected', 'request_sent'].includes(this.status)
    );
  }

  get isApproved(): boolean {
    return this.status && this.status === 'approved';
  }

  /** Private functions */

  assignDefaultValues() {
    if (isBlank(this.status)) {
      this.status = 'not_started';
    }
  }

  assignRelationships() {
    /** Many relationships */
    // @ts-ignore-next-line
    this.assignManyRelationship({
      key: 'requestees',
      model: AuthorisationRequestRequesteeModel,
    });

    // @ts-ignore-next-line
    this.assignManyRelationship({
      included: this.included,
      key: 'awaiting_permissions',
      model: AuthorisationRequestAwaitingPermissionModel,
      overrideKey: 'awaitingPermissions',
    });
  }
}
