import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { ReactElement } from 'react';
import REGION from 'types/region';
import bankNumberLabel from 'utils/direct-debit/bankNumberLabel';
import isBankNumberRequired from 'utils/direct-debit/isBankNumberRequired';
import isBlank from 'utils/isBlank';

const BankNumber = ({
  bankNumber,
  region,
}: {
  bankNumber: string | null | undefined;
  region: REGION;
}): ReactElement | null => {
  if (isBlank(region) || !isBankNumberRequired(region)) {
    return null;
  }

  return (
    <LabeledContent label={bankNumberLabel[region]} content={bankNumber} />
  );
};

export default BankNumber;
