import BaseModel from 'models/BaseModel';
import isPresent from 'utils/isPresent';

export default class PaymentModel extends BaseModel {
  get fullName() {
    return [this.firstName, this.middleName, this.lastName]
      .filter((name) => isPresent(name))
      .join(' ');
  }
}
