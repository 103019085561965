import React, { ReactElement } from 'react';

import { InfoWrapper, Score, Wrapper } from './styles';

type Props = {
  comment: string | null;
  formatted_name: string;
  score: number;
  formatted_responded_at: string;
};

function Feedback({
  comment,
  formatted_name,
  score,
  formatted_responded_at,
}: Props): ReactElement {
  return (
    <Wrapper>
      <Score score={score}>{score}</Score>
      <div>
        <div>{comment ? comment : '-'}</div>
        <InfoWrapper>
          <span>{formatted_name}</span>
          <span>{formatted_responded_at}</span>
        </InfoWrapper>
      </div>
    </Wrapper>
  );
}

export default Feedback;
