import React, { useContext } from 'react';

import { CustomersContext } from '../CustomersContext';
import { PipelinePageHeaderComponents } from 'modules/shared/components';

const { AdvancedSearchButton } = PipelinePageHeaderComponents;

export const CustomersFilterTrigger = () => {
  const { toggleFilter } = useContext(CustomersContext);

  return <AdvancedSearchButton onClick={toggleFilter} />;
};
