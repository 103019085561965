import { useState } from 'react';

const useRetrieveBusinessDetailsState = () => {
  const [isBusinessNumberSearching, setIsBusinessNumberSearching] = useState(
    false
  );
  const [isCompanyNumberSearching, setIsCompanyNumberSearching] = useState(
    false
  );

  const onStartSearch = () => {
    setIsBusinessNumberSearching(true);
    setIsCompanyNumberSearching(true);
  };

  const onEndSearch = () => {
    setIsBusinessNumberSearching(false);
    setIsCompanyNumberSearching(false);
  };

  const isSearching = isBusinessNumberSearching || isCompanyNumberSearching;

  return {
    isBusinessNumberSearching,
    isCompanyNumberSearching,
    isSearching,
    onEndSearch,
    onStartSearch,
  };
};

export default useRetrieveBusinessDetailsState;
