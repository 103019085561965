import React, { Component } from 'react';

import FileWidgetModal from 'modules/shared/components/widgets/interactive/FileWidgetModal';
import FileWidgetSmall from 'modules/shared/components/widgets/interactive/FileWidgetSmall';
import ImageClick from 'modules/shared/components/widgets/interactive/ImageClick';
import ImageModal from 'modules/shared/components/widgets/static/ImageModal';
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import PDFLogo from 'modules/shared/components/widgets/static/PDFLogo';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import isBlank from 'utils/isBlank';
import isPDF from 'utils/isPDF';
import moment from 'moment';
import styles from '../css/ReviewIdentity.css';

function formatIdExpiryDate(date) {
  if (typeof date === 'undefined' || date === null) {
    return null;
  }

  if (moment.isMoment(date)) {
    return date.format('DD/MM/YYYY');
  }

  if (typeof date === 'string') {
    return date;
  }

  return moment(date).format('DD/MM/YYYY');
}

export default class ReviewIdentity extends Component {
  state = {
    isProofOfAddressImageModalVisible: false,
    showIDImageModal: false,
  };

  showLargeImage = () => {
    this.setState({ showIDImageModal: true });
  };

  cancelModal = () => {
    this.setState({ showIDImageModal: false });
  };

  _countryOfIdentification = () => {
    const { data, application } = this.props;

    let identityRegion;

    if (application.attributes.application_type === 'cash') {
      return null;
    }

    if (data.noIdentification) {
      return null;
    }

    if (data.other_region) {
      identityRegion = 'Other';
    } else if (data.region === 'NZ') {
      identityRegion = 'New Zealand';
    } else if (data.region === 'AU') {
      identityRegion = 'Australia';
    }

    return (
      <LabeledInformation
        label="Country of identification"
        data={identityRegion}
        noHumanize={true}
      />
    );
  };

  onShowProofOfAddressImageModal = () => {
    this.setState({ isProofOfAddressImageModalVisible: true });
  };

  onHideProofOfAddressImageModal = () => {
    this.setState({ isProofOfAddressImageModalVisible: false });
  };

  renderProofOfAddress = () => {
    const { data } = this.props;
    const url = (data.proof_of_address || {}).url;

    if (isBlank(url)) {
      return;
    }

    let viewSrc = null;

    if (isPDF(url)) {
      viewSrc = url;
    }

    return (
      <FileWidgetSmall
        accept=".png,.jpg,.jpeg,.gif,.pdf,application/pdf"
        completeWidget={this.renderProofOfAddressCompleteWidget()}
        disabled={true}
        filename="proof_of_address"
        text="Drag and drop"
        title="Proof of address"
        viewSrc={viewSrc}
        handleFileCallback={() => null}
      />
    );
  };

  renderProofOfAddressCompleteWidget() {
    const { data } = this.props;
    const url = (data.proof_of_address || {}).url;

    if (isBlank(url)) {
      return null;
    }

    if (isPDF(url)) {
      return <PDFLogo alt="PDF Upload" logoStyle="small" />;
    }

    return (
      <ImageClick
        src={url}
        height={104}
        handleClick={this.onShowProofOfAddressImageModal}
      />
    );
  }

  renderProofOfAddressModal() {
    const { isProofOfAddressImageModalVisible } = this.state;
    const { data } = this.props;
    const url = (data.proof_of_address || {}).url;

    if (!isProofOfAddressImageModalVisible || isBlank(url)) {
      return null;
    }

    return (
      <ImageModal
        src={url}
        dismissHandler={this.onHideProofOfAddressImageModal}
      />
    );
  }

  render() {
    const { data } = this.props;
    const { showIDImageModal } = this.state;
    const dob = data.dob;
    let formattedDate;
    let idNumberLabel;
    let option;
    let optionData;
    let modal;
    let expiryLabel;
    let expiryDate;

    const {
      driver_licence_version,
      identification_exp_date,
      noIdentification,
      region,
      type,
    } = data;
    const isAustraliaDriverLicence =
      !noIdentification && type === 'driver_licence' && region === 'AU';

    if (typeof dob === 'undefined') {
      formattedDate = moment(
        `${dob.month}/${dob.day}/${dob.year}`,
        'MM/DD/YYYY'
      ).format('DD/MM/YYYY');
    }

    if (type === 'driver_licence') {
      idNumberLabel = 'Drivers Licence No.';
      option = 'Drivers Licence Version';
      optionData = driver_licence_version;

      if (region === 'AU') {
        expiryLabel = 'Driver Licence Expiry Date';
        expiryDate = formatIdExpiryDate(identification_exp_date);
      }
    }

    if (type === 'passport') {
      idNumberLabel = 'Passport No.';
      expiryLabel = 'Passport Expiry Date';
      expiryDate = formatIdExpiryDate(identification_exp_date);
    }

    if (type === 'other') {
      idNumberLabel = 'Identification number';
    }

    if (showIDImageModal) {
      modal = (
        <ImageModal src={data.image_64} dismissHandler={this.cancelModal} />
      );
    }

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <ReviewContainer key="Authorised applicant" css_class="block_noborder">
          <div className={styles.col}>
            <LabeledInformation
              label="Name"
              data={[data.first_name, data.middle_name, data.last_name]
                .filter(Boolean)
                .join(' ')}
              noHumanize={true}
            />

            <LabeledInformation
              label="Your residential address"
              data={data.address.full_address}
              noHumanize={true}
            />

            {this.renderProofOfAddress()}

            <div className={data.image_64 ? '' : styles.hidden}>
              <h3 className={styles.h3}>ID image capture</h3>
              <FileWidgetModal
                id={data.id}
                disabled={true}
                completeWidget={
                  <ImageClick
                    src={data.image_64}
                    height={250}
                    handleClick={this.showLargeImage}
                  />
                }
              />
            </div>
          </div>
          <div className={styles.col}>
            <div
              className={
                !data.dob || !data.dob.year ? styles.hidden : styles.mar_top20
              }
            >
              <LabeledInformation label="Date of birth" data={formattedDate} />
            </div>

            {this._countryOfIdentification()}

            <div
              className={
                data.noIdentification ? styles.hidden : styles.mar_top20
              }
            >
              <LabeledInformation label={idNumberLabel} data={data.number} />
            </div>

            <div
              className={
                data.noIdentification ? styles.hidden : styles.mar_top20
              }
            >
              <LabeledInformation label={option} data={optionData} />
            </div>

            <div
              className={
                data.noIdentification ? styles.hidden : styles.mar_top20
              }
            >
              <LabeledInformation label={expiryLabel} data={expiryDate} />
            </div>

            {isAustraliaDriverLicence && (
              <div className={styles.mar_top20}>
                <LabeledInformation
                  label="Driver Licence Card Number"
                  data={data.identification_card_number}
                />
                <LabeledInformation
                  label="Driver Licence State"
                  data={data.driver_licence_state}
                />
              </div>
            )}
          </div>
          <div>{modal}</div>
          <div>{this.renderProofOfAddressModal()}</div>
        </ReviewContainer>
      </section>
    );
  }
}
