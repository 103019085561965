import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Description } from './Description';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 26px 0 29px;
`;

type StepSectionProps = {
  index: number;
  title: string;
  description: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
};

const headStyles = makeStyles({
  body1: {
    fontSize: 14,
  },
});

type HeadProps = {
  children: string;
};

const Head = (props: HeadProps) => {
  const classes = headStyles();
  return (
    <Typography classes={{ body1: classes.body1 }}>{props.children}</Typography>
  );
};

export const StepSection = (props: StepSectionProps) => {
  const { index, title, description, children } = props;
  const headText = `Step ${index}. ${title} `;
  return (
    <Container>
      <Head>{headText}</Head>
      <Description>{description}</Description>
      {children}
    </Container>
  );
};
