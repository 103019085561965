import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import api from 'api';
import debounce from 'debounce';
import get from 'lodash.get';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { IOption } from 'types/generalType';
import REGION from 'types/region';
import isBlank from 'utils/isBlank';

const DEBOUNCE_INTERVAL = 500;

const SearchableEntityName = ({
  businessNumber,
  currentUser,
  entityName,
  label,
  name,
  onReset,
  onSetBusinessNumber,
  placeholder,
  readOnly,
  region,
}: {
  businessNumber: string;
  currentUser: { accessToken: string };
  entityName: string;
  label: string;
  name: string;
  onReset: () => void;
  onSetBusinessNumber: (businessNumber: string) => void;
  placeholder: string;
  readOnly: boolean;
  region: REGION;
}): ReactElement => {
  const defaultOption = { label: entityName, value: businessNumber };
  const [searchOptions, setSearchOptions] = useState<IOption[]>([
    defaultOption,
  ]);
  const [inputValue, setInputValue] = useState('');

  const onClick = (_, selectedOption) => {
    const businessNumber = get(selectedOption, 'value', '');

    if (isBlank(businessNumber)) {
      onReset();
      return;
    }

    onSetBusinessNumber(businessNumber);
  };

  const searchCompany = (queryString) => {
    const companySearchAPI = api('company_search', currentUser.accessToken);

    companySearchAPI.companySearch(
      (result) => {
        const searchData = get(result, 'data.data', []);
        const formattedOptions = searchData.map((option) => {
          return {
            label: get(option, 'attributes.name', ''),
            value: get(option, 'attributes.business_number', ''),
          };
        });

        setSearchOptions(formattedOptions);
      },
      (error) => console.error({ error }),
      {
        params: {
          name: queryString,
          region,
        },
      }
    );
  };

  const debouncedSearchCompany = useRef(
    debounce(searchCompany, DEBOUNCE_INTERVAL)
  ).current;

  useEffect(() => {
    if (inputValue === '') {
      setSearchOptions([]);
      return;
    }

    if (businessNumber) {
      return;
    }

    debouncedSearchCompany(inputValue);
  }, [inputValue]);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <BorderedAutocomplete
        disabled={readOnly}
        name={name}
        placeholder={placeholder}
        onChange={onClick}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        options={searchOptions}
        textFieldProps={{
          disabled: readOnly,
          label,
        }}
        defaultValue={defaultOption}
        key={entityName}
      />
    </MuiThemeProvider>
  );
};

export default SearchableEntityName;
