import get from 'lodash.get';
import styles from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/SPGAccessCodeModal/css/AUSPGAccessCodeModal.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export default function AUSPGAccessCodeModal(props) {
  const { email, onSetAccessCode } = props;
  const { errors, handleSubmit, register } = useForm({
    validationSchema: yup.object().shape({
      accessCode: yup.string().required('Please enter access code.'),
    }),
  });
  const { isLoading, setIsLoading } = useIsLoadingState();

  const onSubmit = (data) => {
    setIsLoading(true);

    onSetAccessCode(data.accessCode);
  };

  return (
    <div>
      <div className="mb-4">
        <span className="has-text-danger has-text-weight-normal">
          IMPORTANT:
        </span>{' '}
        Your secured party group access code has been sent to{' '}
        <span className="has-text-weight-normal">{email}</span> in a PDF
        document. Please open the document, locate your personalised access code
        and copy it into the field at the top of the screen. This code will
        allow you to make future changes on registrations.
      </div>
      <form
        className={styles.access_code_form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <BorderedTextField
          label=""
          placeholder="Access code"
          inputRef={register}
          name="accessCode"
          error={Boolean(errors.accessCode)}
          helperText={get(errors, 'accessCode.message', ' ')}
        />
        <Button
          disabled={isLoading}
          loading={isLoading}
          text="Enter access code"
          type="submit"
        />
      </form>
    </div>
  );
}
