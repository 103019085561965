import React from 'react';
import styles from './css/ApplicationProgressDots.css';

import Clickable from '../interactive/Clickable';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import { connect } from 'react-redux';

const ProgressDot = createClass({
  render() {
    const {
      sectionIndex,
      style,
      section_label,
      section_style,
      link,
      current,
      last,
      flag,
    } = this.props;
    let section_index;
    if (!isMobile() || sectionIndex == current + 1) {
      //section_index = (<span className={styles.section_index}>{sectionIndex}</span>);
    }
    let section = null;

    if (typeof flag !== 'undefined') {
      section = (
        <div
          className={styles[section_style]}
          style={{ backgroundColor: flag }}
        >
          {section_index}
          {section_label}
        </div>
      );
    } else {
      section = (
        <div className={styles[section_style]}>
          {section_index}
          {section_label}
        </div>
      );
    }
    if (link) {
      if (typeof flag !== 'undefined') {
        section = (
          <div
            className={styles[section_style]}
            style={{ backgroundColor: flag }}
          >
            <Clickable target={link}>
              {section_index}
              {section_label}
            </Clickable>
          </div>
        );
      } else {
        section = (
          <div className={styles[section_style]}>
            <Clickable target={link}>
              {section_index}
              {section_label}
            </Clickable>
          </div>
        );
      }
    }

    let li_style = styles[style];

    if (last) {
      li_style = `${styles[style]} ${styles.last}`;
    }

    return (
      <li id={section_label.replace(/ /g, '-')} className={li_style}>
        {section}
      </li>
    );
  },
});

const ApplicationProgressDots = createClass({
  renderDesktop() {
    const { links, index, type } = this.props;
    const dots = [];
    let last = false;
    for (let i = 0; i < links.length; i++) {
      let section_label = links[i].label;
      if (isMobile()) {
        section_label = i + 1;
      }
      if (i <= index) {
        var style = 'complete';
        if (i === index) {
          style = 'current';
          if (i == links.length - 1) {
            last = true;
          }
          dots.push(
            <ProgressDot
              key={i}
              style={style}
              section_label={links[i].label}
              section_style={'section_complete'}
              sectionIndex={i + 1}
              current={index}
              last={last}
            />
          );
        } else {
          dots.push(
            <ProgressDot
              key={i}
              style={style}
              section_label={section_label}
              section_style={'section_complete'}
              link={links[i].path}
              sectionIndex={i + 1}
              current={index}
              last={last}
            />
          );
        }
      } else {
        var style = 'default';
        const section_style = 'section_default';
        if (i == links.length - 1) {
          last = true;
        }
        if (links[i].complete === true) {
          dots.push(
            <ProgressDot
              key={i}
              style={style}
              section_label={section_label}
              section_style={section_style}
              link={links[i].path}
              sectionIndex={i + 1}
              current={index}
              last={last}
            />
          );
        } else {
          dots.push(
            <ProgressDot
              key={i}
              style={style}
              section_label={section_label}
              section_style={section_style}
              sectionIndex={i + 1}
              current={index}
              last={last}
            />
          );
        }
      }
    }
    return (
      <div className={styles.processbar}>
        <div className={styles.section}>
          <ul className={styles[type]}>{dots}</ul>
        </div>
      </div>
    );
  },

  renderMobile() {
    const { mobile_pages, mobile_index } = this.props;
    return mobile_index ? (
      <div className={styles.mobile_progress}>
        Step {mobile_index} of {mobile_pages}
      </div>
    ) : (
      <div className={styles.empty_mobile_progress} />
    );
  },

  render() {
    if (isMobile()) {
      return this.renderMobile();
    }
    return this.renderDesktop();
  },
});

export default connect((state, ownProps) => {
  return {};
})(ApplicationProgressDots);
