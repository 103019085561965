import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';
import { channelToAddonType } from 'modules/addons/helpers';
import { useState } from 'react';
import isBlank from 'utils/isBlank';

interface ICustomApplicationTypeName {
  cash: string;
  credit: string;
}

const DEFAULT_APPLICATION_TYPE_NAMES = {
  cash: 'Cash',
  credit: 'Credit',
};

export const useApplicationTypeOptions = () => {
  const [applicationTypes, setApplicationTypes] = useState<string[]>([]);
  const [customApplicationTypeNames, setCustomApplicationTypeNames] = useState<
    ICustomApplicationTypeName
  >(DEFAULT_APPLICATION_TYPE_NAMES);

  const applicationTypeOptions = applicationTypes.map((applicationType) => {
    return {
      label: customApplicationTypeNames[applicationType],
      value: applicationType,
    };
  });

  return {
    applicationTypeOptions,
    applicationTypes,
    setApplicationTypes,
    setCustomApplicationTypeNames,
  };
};

export const getApplicationTypes = async ({
  errorCallback,
  successCallback,
  supplierId,
  channel,
}: {
  errorCallback?: (error: any) => void;
  successCallback?: ({
    applicationTypes,
    customApplicationTypeNames,
  }: {
    applicationTypes: string[];
    customApplicationTypeNames: ICustomApplicationTypeName;
  }) => void;
  supplierId: string;
  channel: string;
}): Promise<void> => {
  const addonType = channelToAddonType(channel);
  if (isBlank(addonType)) {
    throw new Error(`Invalid channel '${channel}'`);
  }

  try {
    const result = await AddonConfigModel.fetchAddonConfigByAddonType({
      accessToken: null,
      addonType: [addonType],
      entityId: supplierId,
    });

    const config = result[0] || {};
    const latestConfig =
      get(config, 'attributes.history_version.data', [])[0] || {};
    const applicationTypes = get(
      latestConfig,
      'attributes.application_types',
      []
    );
    const customApplicationTypeNames = get(
      latestConfig,
      'attributes.custom_application_type_name',
      {
        cash: 'Cash',
        credit: 'Credit',
      }
    );

    if (successCallback) {
      successCallback({ applicationTypes, customApplicationTypeNames });
    }
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
};

export const getAddonConfigBySupplierAndChannel = async ({
  supplierId,
  channel,
}: {
  supplierId: string;
  channel: string;
}): Promise<any> => {
  const addonType = channelToAddonType(channel);
  if (isBlank(addonType)) {
    throw new Error(`Invalid channel '${channel}'`);
  }

  return AddonConfigModel.fetchAddonConfigByAddonType({
    accessToken: null,
    addonType: [addonType],
    entityId: supplierId,
  }).then((result) => result[0]);
};

export const setApplicationTypeFromSalesChannel = ({
  isApplicationLoaded,
  loadedApplication,
  setApplicationTypes,
  setIsApplicationTypesLoaded,
}: {
  isApplicationLoaded: boolean;
  loadedApplication: any;
  setApplicationTypes: (options: any[]) => void;
  setIsApplicationTypesLoaded: (isLoaded: boolean) => void;
}): void => {
  if (!isApplicationLoaded) {
    return;
  }

  if (loadedApplication.isNewRecord) {
    throw 'Sales channel needs to have an application.';
  }

  const loadedType = loadedApplication.applicationType;
  if (isBlank(loadedType)) {
    throw 'Sales channel application should have an application type';
  }

  setApplicationTypes([loadedType]);
  setIsApplicationTypesLoaded(true);
};
