import CompanyAffiliations from './CompanyAffiliations';
import ConsumerInformation from './ConsumerInformation';
import CreditDefaults from './CreditDefaults';
import FileAccess from './FileAccess';
import Insolvencies from './Insolvencies';
import Judgements from './Judgements';
import DriverLicenceVerification from './DriverLicenceVerification';
import SummaryInformation from './SummaryInformation';
import PropertyOwnership from './PropertyOwnership';

export default [
  {
    component: SummaryInformation,
    title: 'Summary Information',
  },
  {
    component: ConsumerInformation,
    title: 'Consumer Information',
  },
  {
    component: DriverLicenceVerification,
    title: 'Driver Licence Verification',
  },
  {
    component: FileAccess,
    title: 'File Activity',
  },
  {
    component: CreditDefaults,
    title: 'Credit Defaults',
  },
  {
    component: Judgements,
    title: 'District Court Judgments',
  },
  {
    component: Insolvencies,
    title: 'Insolvencies',
  },
  {
    component: CompanyAffiliations,
    title: 'Company Affiliations',
  },
  {
    component: PropertyOwnership,
    title: 'Property Ownership',
  },
];
