import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<{
  $isFullScreen: boolean;
  $height?: number | string;
  $width?: number | string;
}>`
  position: relative;
  display: grid;
  grid-template-columns: 40px auto 40px;
  gap: ${({ $isFullScreen }) => ($isFullScreen ? '50px' : '5px')};
  padding: ${({ $isFullScreen }) => ($isFullScreen ? '40px 20px' : '0 5px')};
  align-items: center;
  background: white;
  height: fit-content;
  min-height: ${({ $height }) =>
    $height
      ? typeof $height === 'number'
        ? `${$height}px`
        : $height
      : '150px'};
  width: ${({ $width }) =>
    $width ? (typeof $width === 'number' ? `${$width}px` : $width) : 'auto'};
`;

const CounterContainer = styled.div<{ $isFullScreen: boolean }>`
  position: absolute;
  background: #000a;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: white;
  ${({ $isFullScreen }) =>
    $isFullScreen
      ? css`
          bottom: 6px;
          padding: 6px;
          border-radius: 20px;
          width: 80px;
        `
      : css`
          bottom: 0;
          font-size: 10px;
          border-radius: 4px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          padding: 4px;
          width: 48px;
        `}
`;

const Image = styled.img<{ $isFullScreen: boolean }>`
  width: ${({ $isFullScreen }) => ($isFullScreen ? '700px' : '100%')};
  height: 100%;
  object-fit: cover;
`;

const ImageContainer = styled.div`
  height: 100%;
  background-color: gray;
  display: flex;
`;

type CarouselContentProps = {
  isFullScreen: boolean;
  items: string[];
  onClickImage?: () => void;
  height?: string | number;
  width?: string | number;
};

export const CarouselContent = (props: CarouselContentProps) => {
  const { isFullScreen, items, onClickImage, height, width } = props;

  const [index, setIndex] = useState(0);
  const count = items.length;

  const handleNext = () => {
    if (index === count - 1) {
      return;
    }
    setIndex(index + 1);
  };

  const handlePrevious = () => {
    if (index === 0) {
      return;
    }
    setIndex(index - 1);
  };

  return (
    <Container $isFullScreen={isFullScreen} $height={height} $width={width}>
      <IconButton onClick={handlePrevious}>
        <ArrowBackIosIcon color="primary" />
      </IconButton>
      <ImageContainer>
        <Image
          src={items[index]}
          onClick={onClickImage}
          $isFullScreen={isFullScreen}
        />
      </ImageContainer>

      <IconButton onClick={handleNext}>
        <ArrowForwardIosIcon color="primary" />
      </IconButton>

      <CounterContainer $isFullScreen={isFullScreen}>
        {`${index + 1} / ${count}`}
      </CounterContainer>
    </Container>
  );
};
