const _isMobileDeprecated = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return (
      _isMobileDeprecated.Android() ||
      _isMobileDeprecated.BlackBerry() ||
      _isMobileDeprecated.iOS() ||
      _isMobileDeprecated.Opera() ||
      _isMobileDeprecated.Windows()
    );
  },
};

const _isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return (
      _isMobile.Android() ||
      _isMobile.BlackBerry() ||
      _isMobile.iOS() ||
      _isMobile.Opera() ||
      _isMobile.Windows()
    );
  },
};

export function isMobile() {
  return _isMobileDeprecated.any();
}

export function isMobileNew() {
  return _isMobile.any() || window.outerWidth <= 767;
}

export function isMobileDevice() {
  return isMobile() || isMobileNew();
}

export function isTablet() {
  return (
    navigator.userAgent.match('iPad') ||
    (window.outerWidth > 767 && window.outerWidth <= 1024)
  );
}

export function isTabletNew() {
  return (
    _isMobile.any() || (window.outerWidth > 767 && window.outerWidth <= 992)
  );
}
