import get from 'lodash.get';
import store from 'stores/store';

export default function isPseudoRole(): boolean {
  return get(
    store.getState(),
    'current_user.current_user_entity_link.attributes.pseudo_role',
    false
  );
}
