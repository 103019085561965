import React, { Component } from 'react';
import Card from 'modules/card/Card';

import TextInput from 'modules/shared/components/inputs/TextInput';
import Button from 'modules/shared/components/inputs/Button';

import styles from './css/EditCardInputs.css';

export default class EditCardInputs extends Component {
  componentWillMount() {
    try {
      window.Stripe.setPublishableKey(window._env_.STRIPE_PUBLISHABLE_KEY);
    } catch (err) {
      this.props.paymentActions.set({
        errorText: "Payments haven't been set up properly",
      });
    }
  }
  _onSubmit(e) {
    if (e) e.preventDefault();
    this.props.paymentActions.set({ submitDisabled: true, errorText: '' });
    const { name, number, expiry, cvc } = this.props.payment.card;
    const expMonth = expiry.replace(/[^0-9]/g, '').slice(0, 2);
    const expYear = expiry.replace(/[^0-9]/g, '').slice(2, 4);

    var reName = /[!@#$%^&*?]/;

    return new Promise((y, n) => {
      if (!window.Stripe.card.validateCardNumber(number)) {
        return n({
          stripeMessage: 'Please enter a valid credit card number',
          errorType: 'number',
        });
      }
      if (!window.Stripe.card.validateCVC(cvc)) {
        return n({
          stripeMessage: 'Please enter a valid CVC',
          errorType: 'cvc',
        });
      }
      if (!name || name.trim() == '' || reName.test(name)) {
        return n({
          stripeMessage: "Please enter your card's name",
          errorType: 'name',
        });
      }
      if (!window.Stripe.card.validateExpiry(expMonth, expYear)) {
        return n({
          stripeMessage: 'Please enter a valid expiry date',
          errorType: 'exp',
        });
      }
      window.Stripe.card.createToken(this._form, (status, response) => {
        if (response.error) {
          return n({
            stripeMessage: response.error.message,
            errorType: response.error.param,
          });
        }
        y(response);
      });
    });
  }
  render() {
    const { canEdit, payment } = this.props;
    const { card } = payment;
    const expMonth = card.expiry.replace(/[^0-9]/g, '').slice(0, 2);
    const expYear = card.expiry.replace(/[^0-9]/g, '').slice(2, 4);

    let exp = false;
    if (this.props.payment.errorType !== undefined) {
      if (this.props.payment.errorType.includes('exp')) {
        exp = true;
      }
    }

    if (!canEdit) {
      return (
        <p>
          Sorry, you do not have permission to add a credit card under this
          account.
        </p>
      );
    }

    return (
      <section className={styles.credit_card}>
        <form ref={(r) => (this._form = r)}>
          <input type="hidden" data-stripe="number" value={card.number} />
          <input type="hidden" data-stripe="exp_month" value={expMonth} />
          <input type="hidden" data-stripe="exp_year" value={expYear} />
          <input type="hidden" data-stripe="cvc" value={card.cvc} />
          <TextInput
            error={
              this.props.payment.errorType === 'number'
                ? this.props.payment.errorText
                : false
            }
            handleChange={(e) =>
              this.props.paymentActions.setCard({ number: e.target.value })
            }
            label="Card Number"
            required={true}
            value={card.number}
            onFocus={() =>
              this.props.paymentActions.setCard({ focused: 'number' })
            }
            onBlur={() => this.props.paymentActions.setCard({ focused: null })}
            maxLength="16"
          />
          <TextInput
            error={
              this.props.payment.errorType === 'name'
                ? this.props.payment.errorText
                : false
            }
            handleChange={(e) =>
              this.props.paymentActions.setCard({ name: e.target.value })
            }
            label="Name on card"
            required={true}
            value={card.name}
            onFocus={() =>
              this.props.paymentActions.setCard({ focused: 'name' })
            }
            onBlur={() => this.props.paymentActions.setCard({ focused: null })}
          />
          <div className={styles.card_bottom}>
            <TextInput
              error={exp ? this.props.payment.errorText : false}
              handleChange={(e) =>
                this.props.paymentActions.setCard({ expiry: e.target.value })
              }
              label="MM/YY"
              required={true}
              value={card.expiry}
              onFocus={() =>
                this.props.paymentActions.setCard({ focused: 'expiry' })
              }
              onBlur={() =>
                this.props.paymentActions.setCard({ focused: null })
              }
            />
            <TextInput
              error={
                this.props.payment.errorType === 'cvc'
                  ? this.props.payment.errorText
                  : false
              }
              handleChange={(e) =>
                this.props.paymentActions.setCard({ cvc: e.target.value })
              }
              label="CVC"
              required={true}
              value={card.cvc}
              onFocus={() =>
                this.props.paymentActions.setCard({ focused: 'cvc' })
              }
              onBlur={() =>
                this.props.paymentActions.setCard({ focused: null })
              }
              maxLength="3"
            />
          </div>
        </form>
        <div>
          <Card {...this.props.payment.card} />
        </div>
      </section>
    );
  }
}
