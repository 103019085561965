import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from './axios';

export default class Auth {
  axios: AxiosInstance;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
  }

  currentToken(): Promise<void> {
    return this.axios.get('/current_token');
  }

  logout(): Promise<void> {
    return this.axios.delete('/logout');
  }
}
