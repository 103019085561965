const updateAllVersionNotes = (newNote, application) => {
  const { allVersions } = application;
  const newAllVersion = [...allVersions];
  let existingNote = false;

  allVersions.forEach((version, versionIndex) => {
    for (let noteIndex = 0; noteIndex < version.notes.length; noteIndex++) {
      const note = version.notes[noteIndex];

      if (note.id === newNote.id) {
        newAllVersion[versionIndex].notes[noteIndex] = newNote.attributes;
        existingNote = true;
        break;
      }

      if (newNote.attributes.parent_id === note.id) {
        newAllVersion[versionIndex].notes.unshift({
          ...newNote.attributes,
          id: newNote.id,
        });
        existingNote = true;
        break;
      }
    }
  });

  if (!existingNote) {
    allVersions.forEach((version, index) => {
      if (version.id === application.id) {
        newAllVersion[index].notes.unshift({
          ...newNote.attributes,
          id: newNote.id,
        });
      }
    });
  }

  application.allVersions = newAllVersion;
};

export default updateAllVersionNotes;
