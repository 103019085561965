import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { formatMoney } from 'utils/formatting';
import styles from './css/RangedNumberInput.css';

class RangedNumberInput extends React.Component {
  setValidValue = (e) => {
    const value = this.formatValue(e.target.value);
    const { id, handleChange } = this.props;
    const event = { target: { id, value: this.validValue(value) } };
    handleChange(null, event);
  };

  formateValue(value) {
    const { digits } = this.props;
    const stringValue = value || '0';
    const negative = stringValue.includes('-');
    const filteredString = stringValue.replace(/[^0-9|.]/g, '');
    let resNumber = '';
    if (digits) {
      const [int, frac] = filteredString.split('.', 2);
      if (frac === undefined) {
        resNumber = parseInt(int || 0, 10).toString();
      } else {
        resNumber = `${parseInt(int || 0, 10).toString()}.${frac.slice(
          0,
          digits
        )}`;
      }
    } else {
      resNumber = parseInt(
        filteredString.replace(/\./g, '') || 0,
        10
      ).toString();
    }
    if (negative) {
      return `-${resNumber}`;
    }
    return resNumber;
  }

  setValidValue = (e) => {
    const value = this.formatValue(e.target.value);
    const { id, handleChange } = this.props;
    const event = { target: { id, value: this.validValue(value) } };
    handleChange(null, event);
  };

  validValue(value) {
    const { max, min } = this.props;
    if (value > max) return max;
    if (value < min) return min;
    return value;
  }

  setEvent(value) {
    const { id, handleChange } = this.props;
    const event = { target: { id, value } };
    handleChange(null, event);
  }

  renderStartAdornment() {
    const { moneyFormat } = this.props;
    if (moneyFormat) {
      return <InputAdornment position="start">$</InputAdornment>;
    }
    return null;
  }

  render() {
    const { id, disabled, value, moneyFormat, needSperator } = this.props;
    const displayValue =
      moneyFormat || needSperator ? formatMoney(parseInt(value)) : value;

    return (
      <Input
        id={id}
        classes={{ input: styles.indicator_value, root: styles.input_parent }}
        disabled={disabled}
        value={displayValue}
        onChange={(event) =>
          this.setEvent(this.formateValue(event.target.value))
        }
        startAdornment={this.renderStartAdornment()}
        onBlur={this.setValidValue}
      />
    );
  }
}

module.exports = RangedNumberInput;
