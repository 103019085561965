import React, { ReactElement } from 'react';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';
import QAndAHelpLink from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink';
import { AUTHORISATION_DECLINED } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';

const RequestRejected = ({
  authorisationRequest,
}: {
  authorisationRequest: AuthorisationRequestModel;
}): ReactElement => {
  const { awaitingPermissions } = authorisationRequest;

  const sentToNames = awaitingPermissions
    .filter((permission) => permission.status === 'rejected')
    .map((permission) => permission.requesteeFullName);

  return (
    <div className="is-flex">
      <span className="has-text-danger icon mr-2">
        <i className="fas fa-exclamation-circle"></i>
      </span>
      <span>Authorisation declined by {sentToNames.join(', ')}.</span>
      <QAndAHelpLink questionKey={AUTHORISATION_DECLINED} />
    </div>
  );
};

export default RequestRejected;
