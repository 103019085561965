import React from 'react';
import { StyleSelectionItem } from './StyleSelectionItem';

const fonts = [
  'alex-brush',
  'great-vibes',
  'calligraffitti',
  'marck-script',
  'whisper',
];

type StyleSelectionProps = {
  text: string;
  selectedFont: string | null;
  handleSelect: (font: string) => void;
};

export const StyleSelection = (props: StyleSelectionProps) => {
  const { text, selectedFont, handleSelect } = props;
  return (
    <>
      {fonts.map((font) => (
        <StyleSelectionItem
          font={font}
          text={text}
          isSelected={selectedFont === font}
          handleSelect={handleSelect}
        />
      ))}
    </>
  );
};
