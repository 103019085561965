import SimpleDropdown from 'modules/shared/components/widgets/interactive/SimpleDropdown';
import React from 'react';
import SubMenuItem from 'modules/dashboard/containers/components/SubMenuItem';
import { loadCurrentEntity } from 'modules/user/actions';
import { getEntityCard } from 'modules/dashboard/actions';
import { showPricingPlan } from 'modules/pricing-plans/actions';
import { isBranchUser } from 'modules/shared/helpers/headquarterDetect';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const CompanyDropdown = (props) => {
  const { currentUser, entityHandleChange } = props;

  const userEntityLinks = currentUser.data.included;
  const currentEntity = currentUser.current_entity;

  const companies = userEntityLinks.map((entity, index) => (
    <SubMenuItem
      key={index}
      title={entity.attributes.entity_trading_name || 'Your company'}
      id={entity.attributes.entity_id}
      target={props.entityHandleChange}
      css_style={'company_link'}
    />
  ));

  return (
    <div className="dashboard-header_company-dropdown">
      <div>
        <h1
          className={
            companies.length > 1 || isBranchUser()
              ? 'dashboard-header_company'
              : 'dashboard-header_company--single'
          }
        >
          {currentEntity.attributes.trading_name
            ? currentEntity.attributes.trading_name
            : 'Your company'}
          <ArrowDropDownIcon />
        </h1>
        {(companies.length > 1 || isBranchUser()) && (
          <div className="dropdown-list">
            <SimpleDropdown type="company_dropdownlist">
              {companies}
            </SimpleDropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyDropdown;
