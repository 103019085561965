import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection10 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>10.</span> Force majeure
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>10.1</span> Neither party will be
          liable for any delay in meeting, or failure to meet, its obligations
          (other than an obligation to pay money) where such delay or failure is
          caused by any event outside its reasonable control (including any
          delay or failure caused by any act or omission of the other party). If
          a party is prevented from meeting its obligations due to circumstances
          beyond its reasonable control it shall notify the other party of those
          circumstances as soon as reasonably practicable and shall use its
          reasonable endeavours to minimise the effects of its inability to
          perform its obligations.
        </p>
      </div>
    );
  }
}
