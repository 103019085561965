import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { message, messageHTML } = this.props;

    if (this.state.hasError) {
      if (messageHTML) {
        return messageHTML;
      }
      if (message) {
        return <h1>{message}</h1>;
      }
      return '';
    }
    return this.props.children;
  }
}
