import './checkboxgroup.scss';

import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import React from 'react';
import IAccountTypeOptions from 'types/accountTypeOptions';

type CheckboxGroupOption = {
  label: string;
};
interface CheckboxGroup {
  passedValue: string[];
  handleChange: (newState) => void;
  disabled?: boolean;
  options: { [key: string]: CheckboxGroupOption } | IAccountTypeOptions;
  direction?: 'vertical' | 'horizontal';
  errorMessage?: string;
}

const CheckboxGroup = ({
  passedValue,
  handleChange,
  disabled,
  options,
  direction = 'horizontal',
  errorMessage,
}: CheckboxGroup): JSX.Element => {
  const [checkedState, setCheckedState] = React.useState(passedValue);

  const handleCheckChange = (event) => {
    const value = event.target.name;
    const currentSelected = checkedState ? checkedState : [];
    const selection = currentSelected.includes(value)
      ? currentSelected.filter((selectedValue) => selectedValue !== value)
      : [...currentSelected, value];

    setCheckedState(selection);
    handleChange(selection);
  };

  React.useEffect(() => {
    setCheckedState(passedValue);
  }, [passedValue]);

  return (
    <React.Fragment>
      <div
        className={
          'checkbox_group_wrapper' +
          (direction === 'vertical' ? '--vertical' : '')
        }
      >
        {Object.keys(options).map((key) => (
          <div key={`checkbox-${key}`}>
            <SquareCheckbox
              key={key}
              checkboxId={key}
              checkboxName={key}
              checked={checkedState.includes(key)}
              onClick={handleCheckChange}
              label={options[key].label}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
      {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
    </React.Fragment>
  );
};

export default CheckboxGroup;
