import PropTypes from 'prop-types';
import React from 'react';

import Preview from './Preview';
import { Actions, Wrapper } from './styles';

function UploadedFile({
  cameraOnly,
  file,
  handleChangeFile,
  handleRemoveFile,
}) {
  return (
    <Wrapper>
      <Preview file={file} />
      <Actions>
        {!cameraOnly && (
          <button
            className="button is-primary is-rounded is-outlined is-white-base"
            onClick={handleChangeFile}
            onKeyUp={handleChangeFile}
            type="button"
            tabIndex={0}
          >
            Change
          </button>
        )}
        <button
          className="button is-danger is-rounded is-outlined is-white-base"
          onClick={handleRemoveFile}
          onKeyUp={handleRemoveFile}
          type="button"
          tabIndex={0}
        >
          Remove
        </button>
      </Actions>
    </Wrapper>
  );
}

UploadedFile.propTypes = {
  file: PropTypes.shape({}).isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  openFileDialog: PropTypes.func.isRequired,
};

export default UploadedFile;
