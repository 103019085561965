import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import React, { useMemo } from 'react';
import getAccountTypeOptions from 'utils/getAccountTypeOptions';

import { SelectDropdownProps } from './types';

function AccountTypeSelectDropdown(props: SelectDropdownProps): JSX.Element {
  const { handleChange, options } = props;

  const accountOptions = useMemo(() => {
    const defaultOptions = getAccountTypeOptions();

    return [
      ...defaultOptions,
      { brief: 'Leads', label: 'Leads', value: 'leads' },
    ];
  }, []);

  return (
    <SimpleMultiSelectDropdown
      options={options || accountOptions}
      onChange={handleChange}
      id="entity-type-select"
      {...props}
    />
  );
}

export default AccountTypeSelectDropdown;
