import api from '../../api';
import {
  SET,
  RESET,
  TEAM_LOAD_TEAM_START,
  TEAM_LOAD_TEAM_SUCCESS,
  TEAM_LOAD_TEAM_ERROR,
} from './constants';

export function set(payload) {
  return {
    type: SET,
    payload,
  };
}
export function reset() {
  return {
    type: RESET,
  };
}
export function loadTeam() {
  return (dispatch, getState) => {
    dispatch({
      type: TEAM_LOAD_TEAM_START,
    });

    var entities = api('entities', getState().current_user.access_token),
      entityId = getState().current_user.current_entity.id,
      data = {};

    entities.getEntityUsers(
      entityId,
      (result) => {
        if (result.data.data.length) {
          data = result.data.data;
        }
        const num_users = result.data.meta.record_count;

        dispatch({
          type: TEAM_LOAD_TEAM_SUCCESS,
          payload: { num_users, team_list: data },
        });
      },
      (error) => {
        dispatch({
          type: TEAM_LOAD_TEAM_ERROR,
          payload: error,
        });
      },
      { params: { 'page[size]': 100 } }
    );
  };
}

export function deactivateAccount(callback) {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { deactivating: true },
    });

    let deactivate = api(
      'deactivate',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    const attributes = { subscription_state: 'deactivated' };
    deactivate.deactivate(
      (result) => {
        dispatch({
          type: SET,
          payload: { deactivating: false },
          meta: {
            mixpanel: {
              event: 'Deactivating account',
              props: {
                'Entity ID': getState().current_user.current_entity.id,
              },
            },
          },
        });
        if (callback) {
          callback(result);
        }
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { deactivateError: error, deactivating: false },
        });
      }
    );
  };
}
