import { get } from 'lodash';
import store from 'stores/store';

function isReviewerEnabled(): boolean {
  return get(
    store.getState(),
    'manage_profile.current_entity.attributes.reviewer_enabled',
    false
  );
}

export default isReviewerEnabled;
