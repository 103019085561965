/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
/* Import actions */
import { setValue } from '../actions';

/* Import CSS */
import styles from './css/AutoDecisioning.css';
/* Import components */
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';

var TimeInBusiness = createClass({
  handleChange: function(e) {
    const { dispatch } = this.props;
    dispatch(setValue('time_in_business', e.value));
  },

  render: function() {
    const {
      loading,
      rule_set,
      current_entity,
      options,
      selectedOption,
      read_only,
      error,
    } = this.props;

    return (
      <div className={styles.section}>
        <h3>Time in Business</h3>
        {error && !selectedOption && (
          <div className={styles.error}>{error}</div>
        )}
        <div className={styles.info}>
          Please set the minimum timeframe a business has to be in operation to
          qualify for auto decisioning. (excludes personal applications)
        </div>
        <OptionsDropdown
          id={'time_in_business'}
          name={'time_in_business'}
          value={selectedOption}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          required={true}
          options={options}
          disabled={read_only}
          placeholder={'Minimum time in business'}
        />
      </div>
    );
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
var defaults = {
  title: 'Select your business type',
  rule_set: {},
  options: [
    {
      label: 'less than one year',
      value: 'less_than_1_year',
    },
    {
      label: '1+ Years',
      value: 'years_1_plus',
    },
    {
      label: '2+ Years',
      value: 'years_2_plus',
    },
    {
      label: '3+ Years',
      value: 'years_3_plus',
    },
    {
      label: '5+ Years',
      value: 'years_5_plus',
    },
  ],
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  return {
    ...defaults,
    rule_set: ownProps.rule_set,
    options: defaults.options,
    selectedOption:
      state.auto_decisions.current_rule_set.attributes.time_in_business || '',
    current_entity: state.current_user.current_entity
      ? state.current_user.current_entity.attributes
      : null,
  };
})(TimeInBusiness);
