import React, { useContext } from 'react';
import styled from 'styled-components';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from 'modules/shared/components/inputs/Button';
import { RapidTransferContext } from '../RapidTransferContext';
import { Divider } from '@material-ui/core';
import { HistoryLogItem } from './HistoryLogItem';

const Container = styled.div`
  padding-top: 120px;
`;

const LogsContainer = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 160px;
  overflow-y: scroll;
  a {
    text-decoration: underline;
  }
  button,
  .MuiAlert-root {
    width: fit-content;
  }
  .MuiAlert-root button {
    background: transparent;
  }
`;

export const HistoryLogs = () => {
  const {
    historyLogs: {
      hasMorePages,
      historyLogItems,
      isLoading,
      loadMore,
      refresh,
      error,
    },
  } = useContext(RapidTransferContext);

  return (
    <Container>
      <Divider />
      <LogsContainer>
        {error ? (
          <Alert
            action={
              <Button
                grey
                disabled={isLoading}
                loading={isLoading}
                small
                text="Try again"
                type="button"
                onClick={refresh}
              />
            }
            severity="warning"
          >
            <AlertTitle>Could not load history</AlertTitle>
            {error}
          </Alert>
        ) : (
          <>
            {historyLogItems.map((historyItem) => (
              <HistoryLogItem
                key={historyItem.id}
                historyLogItem={historyItem}
              />
            ))}
            {historyLogItems.length && hasMorePages ? (
              <Button
                grey
                disabled={isLoading}
                loading={isLoading}
                small
                text="Load more"
                type="button"
                onClick={loadMore}
              />
            ) : null}
          </>
        )}
      </LogsContainer>
    </Container>
  );
};
