import React from 'react';
import Button from 'modules/shared/components/inputs/Button';
import Modal from 'modules/shared/components/v2/Modal';

import './impactAutoDecisionRulesetPopup.scss';

type ImpactAutoDecisionRulesetPopupProps = {
  onCancel: () => void;
  onConfirm: () => void;
  rulesetNames: string;
};

export const ImpactAutoDecisionRulesetPopup: React.FC<
  ImpactAutoDecisionRulesetPopupProps
> = (props) => {
  const { onCancel, onConfirm, rulesetNames = [] } = props;

  return (
    <Modal
      rootClassName="impact-autodecision-modal"
      title={'Impact on 1CAD'}
      onClose={onCancel}
      footer={
        <div className="footer-container">
          <Button handleClick={onConfirm} text="Confirm" type="button" />
          <Button
            className="modal-button__cancel"
            handleClick={onCancel}
            text="Cancel"
            type="button"
          />
        </div>
      }
    >
      {`Change of this setting will `}
      {<span className="pause">pause</span>}
      {` Auto decisioning (1CAD) "${rulesetNames}".
      Please Confirm if you'd like to make the change.`}
    </Modal>
  );
};
