import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Title from '../../images/svgs/title-share-the-love.svg';
import ShareTheLoveForm from './form';
import {
  Buddy,
  Columns,
  Description,
  LeftColumn,
  People,
  RightColumn,
} from './styles';

const ShareTheLove = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={window._env_.RECAPTCHA_KEY}>
    <Columns className="columns">
      <LeftColumn className="column is-half-tablet">
        <Title />
        <Description>
          We&apos;re on a mission to create beautiful customer onboarding
          experiences among the &apos;Supplier&apos; community.
          <br />
          <br />
          We would welcome the opportunity to show your friends what we can do
          for them.
        </Description>
        <Buddy />
        <People />
      </LeftColumn>

      <RightColumn className="column is-half-tablet">
        <ShareTheLoveForm {...props} />
      </RightColumn>
    </Columns>
  </GoogleReCaptchaProvider>
);

export default ShareTheLove;
