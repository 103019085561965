import React from 'react';
import moment from 'moment';

import styles from '../../css/CommonReviewBusiness.css';
import historyStyles from './css/History.css';

var PreviousEnquiries = createClass({
  _enquiries() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      previousEnquiriesCollection =
        enquiryResult.previous_enquiries_collection || {},
      previousEnquiries =
        previousEnquiriesCollection.previous_enquiry_dto || [];

    if (previousEnquiries.constructor === Object)
      previousEnquiries = [previousEnquiries];

    return previousEnquiries.map((enquiry, index) => {
      return (
        <tr key={index}>
          <th>{moment(enquiry.date_stamp).format('DD-MMM-YYYY')}</th>
          <td>{enquiry.vendor_name}</td>
        </tr>
      );
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Previous Enquiries / Credit Activity</strong>
        </p>
        <table className={styles.table_w20}>
          <tbody>{this._enquiries()}</tbody>
        </table>
      </div>
    );
  },
});

export default PreviousEnquiries;
