import {
  CLOSE_SIDE_BAR,
  SET_ACTIVE_QUESTION_AND_ANSWER,
  SET_ACTIVE_SIDE_BAR,
} from 'modules/shared/components/top/Overlay/redux/constants';

const OVERLAY_DEFAULTS = {
  activeQuestionAndAnswer: '',
  activeSidebar: '',
  hasInteracted: false,
};

export const reducer = (state = OVERLAY_DEFAULTS, action) => {
  switch (action.type) {
    case CLOSE_SIDE_BAR: {
      return {
        ...state,
        activeQuestionAndAnswer: '',
        activeSidebar: '',
        hasInteracted: true,
      };
    }
    case SET_ACTIVE_QUESTION_AND_ANSWER: {
      return {
        ...state,
        activeQuestionAndAnswer: action.payload,
        activeSidebar: 'q-and-a',
      };
    }
    case SET_ACTIVE_SIDE_BAR: {
      return { ...state, activeSidebar: action.payload };
    }
    default:
      return { ...state };
  }
};
