import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputAdornment } from '@material-ui/core';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import {
  FormSubmissionStatus,
  Status,
} from 'modules/shared/components/v2/Form/useFormSubmissionStatus';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import { updatePassword } from 'modules/user/actions';
import React, { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-latest';
import * as yup from 'yup';

import { Props } from './types';

const PASSWORD_MIN_LENGTH = 8;

function PasswordInput(props) {
  const { control, error, label, name, handleBlur } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, ...fieldProps } }) => (
        <BorderedTextField
          {...fieldProps}
          type={showPassword ? 'text' : 'password'}
          label={label}
          helperText={error && error.message}
          error={!!error}
          onBlur={() => {
            if (handleBlur) {
              handleBlur();
            }
            onBlur();
          }}
          customProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  <FontAwesomeIcon
                    icon={['far', showPassword ? 'eye-slash' : 'eye']}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          labelShrink
        />
      )}
    />
  );
}

function getFormSchema() {
  return yup
    .object()
    .shape({
      confirmPassword: yup
        .string()
        .required('Password confirmation is required')
        .when('password', {
          is: (value) => !!value,
          then: yup
            .string()
            .oneOf([yup.ref('password')], "Password doesn't match"),
        }),
      currentPassword: yup.string().required('Current password is required'),
      password: yup
        .string()
        .required('Password is required.')
        .test('min', 'Password must be at least 8 characters long', (val) =>
          val ? val.length >= PASSWORD_MIN_LENGTH : true
        )
        .when('currentPassword', {
          is: (value) => !!value,
          then: yup
            .string()
            .notOneOf(
              [yup.ref('currentPassword')],
              'New password cannot be the same as current password'
            ),
        }),
    })
    .required();
}

function ResetPasswordForm(props: Props): ReactElement {
  const [status, setStatus] = useState<Status | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = props;

  const { control, formState, reset, handleSubmit, trigger, watch } = useForm<{
    confirmPassword: string;
    currentPassword: string;
    password: string;
  }>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: useYupValidationResolver(getFormSchema),
  });

  const { errors, isDirty } = formState;
  const hasError = Object.keys(errors).length > 0;
  const values = watch();

  function setSuccessStatus(message: string) {
    setStatus({ message, type: 'success' });
  }

  function setErrorStatus(message: string) {
    setStatus({ message, type: 'danger' });
  }

  function submit(data) {
    setIsLoading(true);
    const { currentPassword, password } = data;
    dispatch(
      updatePassword({
        errorCallback: (err) => {
          let errorMessage =
            'Oops! Something went wrong, please try again or contact support@1centre.com if the problem still persists.';

          if (err && err.response.status === 403) {
            errorMessage =
              'Fail to reset password, please make sure your current password is correct.';
          }

          setErrorStatus(errorMessage);
        },
        params: {
          currentPassword,
          password,
        },
        requestEndCallback: () => {
          reset({
            confirmPassword: '',
            currentPassword: '',
            password: '',
          });
          setIsLoading(false);
        },
        successCallback: () => setSuccessStatus('Password successfully reset!'),
      })
    );
  }

  function validateField(
    field: 'password' | 'currentPassword' | 'confirmPassword'
  ) {
    if (values[field]) {
      trigger(field);
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="columns is-multiline">
        <div className="column is-12 is-3-desktop">
          <PasswordInput
            control={control}
            name="currentPassword"
            label="Current password"
            handleBlur={() => validateField('password')}
            error={errors['currentPassword']}
          />
          <PasswordInput
            control={control}
            name="password"
            label="New password"
            handleBlur={() => validateField('confirmPassword')}
            error={errors['password']}
          />
          <PasswordInput
            control={control}
            name="confirmPassword"
            label="Confirm new password"
            error={errors['confirmPassword']}
          />
        </div>
        <div className="column is-12">
          <Button
            text="Reset password"
            type="submit"
            disabled={hasError || !isDirty}
            loading={isLoading}
          />
          {status && <FormSubmissionStatus {...status} />}
        </div>
      </div>
    </form>
  );
}

export default ResetPasswordForm;
