import axios from './axios';

let Tokens = function(config) {
  this.axios = axios(config);
  this.type = 'tokens';
};

Tokens.prototype.requestToken = function(data, success, error) {
  return this.axios
    .post('/tokens', data)
    .then(success)
    .catch(error);
};

Tokens.prototype.requestTokenBySudoToken = function(data, success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .post('/sudo_tokens', data)
    .then(success)
    .catch(error);
};

export default Tokens;
