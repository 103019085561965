import api from 'api';

const getApplicationAccessibility = async ({
  applicationId,
  errorCallback,
  successCallback,
  token,
}: {
  applicationId: string;
  errorCallback?: (error: any) => void;
  successCallback?: (result: any) => void;
  token: string;
}): Promise<void> => {
  const onboardingAPI = api('onboarding');

  try {
    const result = await onboardingAPI.applicationAccessibility({
      applicationId,
      token,
    });

    if (successCallback) {
      successCallback(result);
    }
  } catch (error) {
    console.error(error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
};

export default getApplicationAccessibility;
