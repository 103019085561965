export const ASSOCIATION_LOAD_RECOVERY_CANDIDATE_START =
  'ASSOCIATION_LOAD_RECOVERY_CANDIDATE_START';
export const ASSOCIATION_LOAD_RECOVERY_CANDIDATE_SUCCESS =
  'ASSOCIATION_LOAD_RECOVERY_CANDIDATE_SUCCESS';
export const ASSOCIATION_LOAD_RECOVERY_CANDIDATE_ERROR =
  'ASSOCIATION_LOAD_RECOVERY_CANDIDATE_ERROR';
export const ASSOCIATION_NEW_USER_FORM_SET_VALUES =
  'ASSOCIATION_NEW_USER_FORM_SET_VALUES';
export const ASSOCIATION_NEW_USER_SUCCESS = 'ASSOCIATION_NEW_USER_SUCCESS';
export const ASSOCIATION_NEW_USER_ERROR = 'ASSOCIATION_NEW_USER_ERROR';
