type Header = {
  alg: string;
};

type Payload = {
  api_token_type: string;
  cred: string;
  exp: number;
  originated: number;
  sub: string;
  token_origin: string;
};

type JWT = {
  header: Header;
  payload: Payload;
};

function jwtData(token: string): JWT {
  if (!token) {
    return {} as JWT;
  }

  const [header, payload] = token.split('.');

  return {
    header: JSON.parse(atob(header)),
    payload: JSON.parse(atob(payload)),
  };
}

export function jwtPayload(token: string) {
  return jwtData(token).payload;
}

export function tokenIsValid(jwt: string) {
  // Returning true to try improving user forced logout issue
  // https://1centre.atlassian.net/browse/BT-8512
  return true;

  if (!jwt || jwt === null) {
    return false;
  }

  const data = jwtData(jwt);
  let valid = false;

  // Check token date is still valid
  if (data.payload && data.payload.sub) {
    valid = true;
  }

  // Check token date is still valid
  if (Math.floor(Date.now() / 1000) < data.payload.exp) {
    valid = true;
  } else {
    valid = false;
  }

  return valid;
}

export function getSubject(jwt: string) {
  if (!tokenIsValid(jwt)) {
    return;
  }

  return jwtPayload(jwt).sub;
}

export function getTokenOrigin(jwt) {
  if (!tokenIsValid(jwt)) {
    return;
  }

  return jwtPayload(jwt).token_origin;
}
