import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { Controller } from 'react-hook-form-latest';

const FormCheckbox = (props): JSX.Element => {
  const {
    checkboxProps,
    formControlLabelProps,
    formControlProps,
    formHelperTextProps,
    helperText,
    name,
    control,
  } = props;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <FormControl {...formControlProps}>
        {control ? (
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      {...checkboxProps}
                      checked={field.value}
                    />
                  }
                  {...formControlLabelProps}
                />
              );
            }}
          />
        ) : (
          <FormControlLabel
            control={<Checkbox {...checkboxProps} />}
            {...formControlLabelProps}
          />
        )}
      </FormControl>
      <FormHelperText className="is-size-5" {...formHelperTextProps}>
        {helperText}
      </FormHelperText>
    </MuiThemeProvider>
  );
};

FormCheckbox.defaultProps = {
  checkboxProps: {},
  formControlLabelProps: {},
  formControlProps: {},
};

export default FormCheckbox;
