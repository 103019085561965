import React from 'react';
import styled from 'styled-components';
import { Limit } from './Limit';
import FixedContent from 'modules/shared/components/containers/FixedContent';

const LimitsContainer = styled.div`
  display: flex;
  gap: 76px;
`;

type LimitContentProps = {
  isApproved: boolean;
  requestedLimit: string;
  approvedLimit: string;
};

export const LimitContent = (props: LimitContentProps) => {
  const { isApproved, requestedLimit, approvedLimit } = props;

  return (
    <FixedContent>
      <LimitsContainer>
        <Limit label="Requested limit" value={requestedLimit} />
        <Limit
          label="Approved limit"
          value={isApproved ? approvedLimit : '-'}
        />
      </LimitsContainer>
    </FixedContent>
  );
};
