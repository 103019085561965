import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React, { ReactElement, useState } from 'react';

export const statusIcons = {
  danger: {
    icon: 'times-circle',
  },
  success: {
    icon: 'check-circle',
  },
};

type Alignment = 'left' | 'right';

export type Status = {
  alignment?: Alignment;
  message?: string;
  type?: 'success' | 'danger';
};

export type TFormSubmissionStatusRender = ReactElement | null;

export function FormSubmissionStatus(
  status: Status
): TFormSubmissionStatusRender {
  if (!status) {
    return null;
  }

  const { alignment, message, type } = status;

  const classes = [
    `has-text-${type}`,
    alignment ? `has-text-${alignment}` : '',
    'mb-4',
  ];

  return (
    <div className={classes.join(' ')}>
      {type ? (
        <FontAwesomeIcon {...(statusIcons[type!] as FontAwesomeIconProps)} />
      ) : (
        ''
      )}{' '}
      {message}
    </div>
  );
}

function useFormSubmissionStatus(
  alignment: Alignment = 'left'
): {
  clearStatus: () => void;
  FormSubmissionStatus: TFormSubmissionStatusRender;
  hasStatus: boolean;
  setSuccessStatus: (message?: string) => void;
  setErrorStatus: (message?: string) => void;
} {
  const [status, setStatus] = useState<Status | null>(null);
  const successMessage = 'Your changes have been saved successfully!';
  const errorMessage =
    'There was an error saving your changes. Please try again or contact us if the problem still persists.';

  // Create functions to set success or error status that is overridable by the user
  function setSuccessStatus(message?: string) {
    setStatus({
      message: message || successMessage,
      type: 'success',
    });
  }

  function setErrorStatus(message?: string) {
    setStatus({
      message: message || errorMessage,
      type: 'danger',
    });
  }

  function clearStatus() {
    setStatus(null);
  }

  return {
    clearStatus,
    FormSubmissionStatus: (
      <FormSubmissionStatus {...{ ...status, alignment }} />
    ),
    hasStatus: !!status,
    setSuccessStatus,
    setErrorStatus,
  };
}

export default useFormSubmissionStatus;
