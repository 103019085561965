import { useState } from 'react';

const useWebsiteButtonState = (channel) => {
  const [isWebsiteButtonEnabled, setIsWebsiteButtonEnabled] = useState<
    boolean | null
  >(null);

  const isReady = isWebsiteButtonEnabled !== null;
  let allowWebsiteButtonFlow = true;
  if (channel === 'website' || channel === 'qr') {
    allowWebsiteButtonFlow = isWebsiteButtonEnabled!;
  }

  return {
    allowWebsiteButtonFlow,
    isReady,
    isWebsiteButtonEnabled,
    setIsWebsiteButtonEnabled,
  };
};

export default useWebsiteButtonState;
