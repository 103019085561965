import ReviewAdditional from 'modules/guarantor/components/ReviewAdditional';
import ReviewBusiness from 'modules/guarantor/components/ReviewBusiness';
import ReviewFinancials from 'modules/guarantor/components/ReviewFinancials';
import ReviewPaperless from 'modules/guarantor/components/ReviewPaperless';
import ReviewPaymentInfo from 'modules/guarantor/components/ReviewPaymentInfo';
import ReviewTradeReferences from 'modules/guarantor/components/ReviewTradeReferences';
import AccordianContainer from 'modules/shared/components/containers/AccordianContainer';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './css/Section.css';

class ReviewSignatory extends Component {
  constructor() {
    super();
    this.state = {
      containerIndex: null,
    };
  }

  updateContainerIndex(guarantorContainerIndex) {
    const { containerIndex } = this.state;
    let index = guarantorContainerIndex;
    if (containerIndex === index) {
      index = null;
    }
    this.setState({ containerIndex: index });
  }

  render() {
    const { authorisation, options, title, subTitle } = this.props;

    const { containerIndex } = this.state;

    const sections = [];
    const components = {
      additional: ReviewAdditional,
      business: ReviewBusiness,
      financials: ReviewFinancials,
      paperless: ReviewPaperless,
      payment_info: ReviewPaymentInfo,
      trade_references: ReviewTradeReferences,
    };

    const appAttr = authorisation.application.attributes;

    if (
      appAttr.minimum_trade_references === 0 ||
      appAttr.legal_type === 'personal'
    ) {
      delete options.trade_references;
    }

    Object.keys(options).forEach((key) => {
      sections.push(
        React.createElement(components[key], {
          data: authorisation,
          'data-accordianLabel': options[key].label,
          id: key,
          key,
        })
      );
    });

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <PanelTitle text={title} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.content}>
            <p
              className={styles.subtitle}
              dangerouslySetInnerHTML={{ __html: subTitle }}
            ></p>
            <AccordianContainer
              type="review_edit"
              subPages={sections}
              subsection_index={containerIndex}
              handleClick={(index) => this.updateContainerIndex(index)}
            />
          </div>
        </div>
      </section>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    business: {
      label: 'Business details',
    },
    trade_references: {
      label: 'Trade references',
      requiredBy: 'requires_trade_reference_check',
    },
    financials: {
      label: 'Financials',
      addon: true,
    },
    additional: {
      label: 'Additional',
      addon: true,
    },
    paperless: {
      label: 'Direct debit',
      addon: true,
    },
    payment_info: {
      label: 'Limit',
    },
  },
  titlePrefix: 'Review details for trade account application between ',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect((state, ownProps) => {
  let limit;
  const options = defaults.options;
  const authorisation = state.authorisation.data;

  if (authorisation) {
    limit = authorisation.application.attributes.trade_account_limit;
    if (authorisation.application.attributes.legal_type === 'personal') {
      options.business.label = 'Customer details';
    }

    const addonRules = authorisation.addon_rules || null;
    const addonModuleNames = [];
    addonRules.forEach((answer) => {
      addonModuleNames.push(answer.attributes.addon_module_name);
    });

    Object.keys(options).forEach((key) => {
      if (defaults.options[key].addon && !addonModuleNames.includes(key)) {
        delete options[key];
      }
    });
  }

  return {
    authorisation,
    options,
    signatory: state.authorisation.signatory,
    signatoryApproved: state.authorisation.signatoryApproved,
    subTitle: `${
      ownProps.consumerName
    } is asking for a credit limit of <strong>$${Number(
      limit
    ).toLocaleString()}</strong>`,
    title: `${defaults.titlePrefix} ${ownProps.consumerName} and ${ownProps.supplierLegalName}`,
  };
})(ReviewSignatory);
