import React from 'react';
import { Typography } from '@material-ui/core';
import { getWatchtowerBlockedMessage } from 'utils';
import { styled } from '@material-ui/core/styles';

const StyledText = styled(Typography)({
  float: 'right',
});

export const WatchtowerBlockMessage: React.FunctionComponent = () => (
  <StyledText color="error">{getWatchtowerBlockedMessage('ip')}</StyledText>
);
