import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection1 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>1.</span> Definitions and
            interpretation
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>1.1</span>{' '}
          <strong>Definitions:</strong> In these Terms, unless the context
          otherwise requires:
          <br />‘<strong>Business Customer</strong>’ means any business that
          uses any interface of the Platform to interact with the Supplier
          and/or complete a Trade Account Application.
          <br />‘<strong>Business Customer Terms</strong>’ means the Business
          Customer general terms and conditions of use of the Platform agreed to
          by Business Customers as a condition of using the Platform.
          <br />‘<strong>Commencement Date</strong>’ means the date the
          Supplier’s registration is accepted by 1Centre.
          <br />‘<strong>Confidential Information</strong>’ shall include, but
          shall not be limited to, any information concerning a party's business
          activities and clients or associated bodies, but shall not extend to
          information that:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> was known to the
          receiving party prior to it entering into this agreement; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> is, or becomes, public
          knowledge without the fault of the receiving party; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> is, or becomes
          available to the receiving party from a source other than the
          disclosing party; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>d.</span> is independently
          developed by the receiving party; or
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>e.</span> is required to be
          disclosed by law or in accordance with a court order.
        </p>
        <p className={styles.list_type_1}>
          ‘<strong>Credit Reporting Agency</strong>’ means any organisation
          engaged to provide a credit report in relation to a Business Customer,
          based on information in a Trade Account Application.
          <br />‘<strong>Fees</strong>’ means the weekly access fee for base
          access to the Platform and the other amounts specified for specific
          features and services accessed through the Platform as notified at the
          time of registration or subsequently notified to the Supplier or
          published on the 1Centre website or through the Platform.
          <br />‘<strong>GST</strong>’ means goods and services tax payable in
          accordance with the Goods and Services Tax Act 1985.
          <br />‘<strong>Intellectual Property</strong>’ means any patent,
          copyright, trade mark, design right, trade secret, eligible layout or
          other industrial or intellectual property right.
          <br />‘<strong>Service</strong>’ means the digital collation service
          offered by 1Centre to the Supplier in using the Platform, as modified
          or enhanced from time to time, and as more particularly described on
          1Centre’s website.
          <br />‘<strong>Supplier Personnel</strong>’ means each employee or
          contractor of the Supplier.
          <br />‘<strong>Supplier Terms of Trade</strong>’ means the terms and
          conditions of the Supplier governing the provision of credit and goods
          and/or services by the Supplier to the Business Customer
          <br />‘<strong>Third Party Supplier Terms</strong>’ means the terms
          and conditions of third parties involved in providing the Services,
          disclosed to the Supplier as such by 1Centre, and which may include
          (without limitation) the terms and conditions of Trade Reference
          Checking Agencies and Credit Reporting Agencies.
          <br />‘<strong>Trade Account Application</strong>’ means an online
          trade account application form completed by a Business Customer
          through the Platform.
          <br />‘<strong>Trade Reference Checking Agency</strong>’ means any
          organisation engaged to conduct a trade reference check in relation to
          a Business Customer, based on information in a Trade Account
          Application.
          <br />‘<strong>Working Day</strong>’ means any day other than
          Saturday, Sunday, or a public holiday that is generally observed in
          Auckland, New Zealand.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>1.2</span>{' '}
          <strong>Interpretation</strong>: In these Terms, unless the context
          requires otherwise:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> the singular in all
          cases includes the plural and vice versa;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> references to sections,
          clauses or schedules are references to sections, clauses or schedules
          in these Terms;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> references to statutes
          or statutory provisions will be construed as references to those
          statutes or provisions as they may be re-enacted or amended;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>d.</span> references to monetary
          amounts are to the New Zealand dollar; and
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>e.</span> references to the
          parties include (insofar as is consistent with these Terms) their
          respective personnel, approved agents and subcontractors, successors
          in title, and permitted assigns.
        </p>
      </div>
    );
  }
}
