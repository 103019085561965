import styled from 'styled-components';
import { FONT_SIZES } from 'variables/typography';

export const Header = styled.h2`
  font-size: ${FONT_SIZES.title};
  font-weight: 200;
  line-height: 36px;
  color: grey-font-color;
  margin: 2px 0 25px;
  width: 80%;
`;

export const Description = styled.p`
  font-size: ${FONT_SIZES.base}
  margin: 20px;
`;

// export const ExtendExample = styled(SourceFile)``;
