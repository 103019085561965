import Button from 'modules/shared/components/inputs/Button';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import React from 'react';

class ConflictModal extends React.Component {
  render() {
    const { dismissHandler, conflict_message: conflictMessage } = this.props;
    return (
      <SquareModal title={'Conflicting Limits'} size="small">
        <div>
          <p style={{ marginTop: '10px', textAlign: 'center' }}>
            {conflictMessage || 'Limits of this ruleset conflicts with others.'}
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={dismissHandler} text="Dismiss" />
          </div>
        </div>
      </SquareModal>
    );
  }
}

export default ConflictModal;
