import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';

import DownloadIcon from 'images/svgs/icon-download.svg';
import UploadIcon from 'images/svgs/icon-upload.svg';

const Container = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: start;
  gap: 6px;
  cursor: pointer;
  width: fit-content;
  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const StyledText = styled(Typography)`
  text-decoration: underline;
`;

type ActionLinkProps = {
  disabled?: boolean;
  icon?: 'download' | 'upload';
  onClick: () => void;
  text: string;
};

const iconMap = {
  download: DownloadIcon,
  upload: UploadIcon,
};

export const ActionLink = (props: ActionLinkProps) => {
  const { disabled, icon, onClick, text } = props;
  const Icon = icon ? iconMap[icon] : null;
  return (
    <Container onClick={disabled ? () => {} : onClick} $disabled={disabled}>
      <StyledText color="primary">{text}</StyledText>
      {Icon ? <Icon /> : null}
    </Container>
  );
};
