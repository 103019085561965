import { BUSINESS_NUMBER } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';
import QAndAHelpLink from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import React, { ReactElement } from 'react';
import isBlank from 'utils/isBlank';

interface BusinessNumberFieldProps {
  control?: any;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  labelShrink?: boolean;
  name: string;
  onReset?: any;
  onRetrieveDetailsWithBusinessNumber?: any;
  placeholder?: string;
}

const BusinessNumberField = ({
  control,
  isDisabled,
  isLoading,
  label,
  labelShrink,
  name,
  onReset,
  onRetrieveDetailsWithBusinessNumber,
  placeholder,
}: BusinessNumberFieldProps): ReactElement => {
  const [isFocused, setIsFocused] = React.useState(false);

  const onChangeBusinessNumber = (_, processedValue) => {
    const businessNumber = processedValue;
    if (isBlank(businessNumber)) {
      onReset();

      return;
    }

    if (onRetrieveDetailsWithBusinessNumber) {
      onRetrieveDetailsWithBusinessNumber(businessNumber);
    }
  };

  return (
    <div className="column">
      <RHFBorderedTextField
        control={control}
        isLoading={isLoading}
        label={label}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        labelShrink={labelShrink || isFocused}
        name={name}
        noWhiteSpaces={true}
        onChange={onChangeBusinessNumber}
        readOnly={isDisabled || isLoading}
        placeholder={placeholder}
      />
      <QAndAHelpLink questionKey={BUSINESS_NUMBER} />
    </div>
  );
};

export default BusinessNumberField;
