import React, { ReactElement } from 'react';
import REGION from 'types/region';
import bankNumberLabel from 'utils/direct-debit/bankNumberLabel';
import isBankNumberRequired from 'utils/direct-debit/isBankNumberRequired';

const BankNumber = ({ region }: { region: REGION }): ReactElement | null => {
  if (!isBankNumberRequired(region)) {
    return null;
  }

  return <p>{bankNumberLabel[region]}</p>;
};

export default BankNumber;
