import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import Portal from '@material-ui/core/Portal';
import Select from '@material-ui/core/Select';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconReviewDate from 'images/svgs/icon-review-date.svg';
import get from 'lodash.get';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import moment from 'moment';
import React from 'react';
import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import isPresent from 'utils/isPresent';

import styles from './css/DateInput.css';
import TextInput from './TextInput';

const MAX_YEARS = 100;

function MonthOptions(props) {
  const { month, onMonthSelect } = props;

  const options = moment.months().map((label, value) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));

  return (
    <Select
      MenuProps={{
        classes: { paper: styles.calendar_select_paper },
      }}
      classes={{
        root: styles.calendar_select_root,
      }}
      variant="outlined"
      size="small"
      value={month.month()}
      onChange={(e) => onMonthSelect(month, e.target.value)}
    >
      {options}
    </Select>
  );
}

function YearOptions(props) {
  const { maxDate, minDate, month, onYearSelect } = props;

  let maxYear = moment().year();
  if (moment.isMoment(maxDate)) {
    maxYear = maxDate.year();
  }

  let minYear = maxYear - MAX_YEARS;
  if (moment.isMoment(minDate)) {
    minYear = minDate.year();
  }

  const options = [];

  for (let year = minYear; year <= maxYear; year++) {
    options.push(
      <MenuItem key={year} value={year.toString()}>
        {year}
      </MenuItem>
    );
  }

  return (
    <Select
      MenuProps={{
        classes: { paper: styles.calendar_select_paper },
      }}
      classes={{
        root: styles.calendar_select_root,
      }}
      variant="outlined"
      size="small"
      value={month.year()}
      onChange={(event) => onYearSelect(month, event.target.value)}
    >
      {options}
    </Select>
  );
}

function CalendarMonthElement(props) {
  const { maxDate, minDate, month, onMonthSelect, onYearSelect } = props;

  return (
    <div className={styles.calendar_month_element}>
      <div>
        <MonthOptions
          maxDate={maxDate}
          minDate={minDate}
          month={month}
          onMonthSelect={onMonthSelect}
        />
      </div>
      <div>
        <YearOptions
          maxDate={maxDate}
          minDate={minDate}
          month={month}
          onYearSelect={onYearSelect}
        />
      </div>
    </div>
  );
}

function isOutsideRange({ minDate, maxDate, actualDate }) {
  let dateToTest = actualDate;
  if (!moment.isMoment(dateToTest)) {
    dateToTest = moment(actualDate);
  }

  return (
    isInclusivelyBeforeDay(actualDate, moment(minDate).subtract(1, 'day')) ||
    isInclusivelyAfterDay(actualDate, moment(maxDate).add(1, 'day'))
  );
}

function ResetDate(props) {
  const { onResetDate, value } = props;

  const onClick = (event) => {
    event.stopPropagation();
    onResetDate();
  };

  if (value) {
    return (
      <IconButton size="small" onClick={onClick}>
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  }

  return null;
}

class DateInput extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { value } = props;

    let dateValue = '';
    if (typeof value === 'string') {
      dateValue = moment(value, 'DD/MM/YYYY').toDate();
    } else {
      dateValue = value;
    }

    return { ...state, theDate: dateValue };
  }

  constructor(props) {
    super(props);

    const { value } = props;

    let dateValue = '';
    if (isPresent(value)) {
      dateValue = moment(value, 'DD/MM/YYYY').toDate();
    }

    this.state = {
      anchorEl: null,
      showCalender: false,
      theDate: dateValue,
    };
  }

  formattedDate(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    }
    return '';
  }

  showCalender(target) {
    this.setState({ anchorEl: target, showCalender: true });
  }

  hideCalender() {
    this.setState({ anchorEl: null, showCalender: false });
  }

  setDate(value) {
    const { onChange } = this.props;
    this.setState({ theDate: value });
    onChange(this.formattedDate(value));
    this.hideCalender();
  }

  onResetDate = () => {
    const { onChange } = this.props;

    this.setDate({ theDate: null });

    if (onChange) {
      onChange(null);
    }
  };

  render() {
    const {
      borderedStyle,
      calendarDefaultSelectedDate,
      error,
      id,
      inputClasses,
      label,
      maxDate,
      minDate,
      required,
      value,
    } = this.props;

    let selectedDate = calendarDefaultSelectedDate || maxDate;
    if (value && !moment.isMoment(value)) {
      selectedDate = moment(value, 'DD/MM/YYYY');
    }

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <div>
          <TextInput
            id={id}
            label={label}
            required={required}
            inputClasses={inputClasses}
            EndAdornment={
              <InputAdornment
                classes={{
                  root: styles.calendar_icon,
                }}
                position="end"
              >
                <ResetDate onResetDate={this.onResetDate} value={value} />
                <IconReviewDate
                  fontSize="small"
                  onClick={(event) =>
                    this.showCalender(
                      get(event, 'currentTarget.parentElement.parentElement')
                    )
                  }
                />
              </InputAdornment>
            }
            onClick={(event) =>
              this.showCalender(
                get(event, 'currentTarget.parentElement.parentElement')
              )
            }
            value={this.formattedDate(this.state.theDate) || value}
            error={error}
          />
          <Popover
            id={id}
            open={this.state.showCalender}
            onClose={this.hideCalender.bind(this)}
            anchorEl={this.state.anchorEl}
          >
            <DayPickerSingleDateController
              date={selectedDate}
              focused={true}
              hideKeyboardShortcutsPanel={true}
              initialDate={selectedDate}
              initialVisibleMonth={() => {
                if (moment.isMoment(selectedDate)) {
                  return selectedDate;
                }

                return moment();
              }}
              isOutsideRange={(day) =>
                isOutsideRange({ actualDate: day, maxDate, minDate })
              }
              numberOfMonths={1}
              renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                <CalendarMonthElement
                  maxDate={maxDate}
                  minDate={minDate}
                  month={month}
                  onMonthSelect={onMonthSelect}
                  onYearSelect={onYearSelect}
                />
              )}
              onDateChange={(date) => this.setDate(date)}
            />
          </Popover>
        </div>
      </MuiThemeProvider>
    );
  }
}

DateInput.defaultProps = {
  maxDate: moment(),
  minDate: moment().subtract(MAX_YEARS, 'years'),
};

export default DateInput;
