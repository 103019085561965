import React from 'react';
import { browserHistory } from 'react-router';

/* Import CSS */
import styles from '../css/DashboardContainer.css';
/* Import components */
import MenuItem from './MenuItem';
import SubMenuItem from './SubMenuItem';

const BasicMenu = createClass({
  getInitialState() {
    return {
      active: null,
      navToggle: false,
    };
  },

  handleLinkClick(url) {
    const { navToggle } = this.state;
    this.setState({
      active: null,
      navToggle: false,
    });
    browserHistory.push(url);
  },

  navToggleClick(v) {
    const { navToggle } = this.state;
    this.setState({
      active: null,
      navToggle: !navToggle,
    });
  },

  render() {
    const { displayUsername, username } = this.props;
    const { active, navToggle } = this.state;

    return (
      <nav className={styles.nav}>
        <div className={styles.nav_mobile}>
          <div
            className={!navToggle ? styles.nav_toggle : styles.nav_toggle_close}
            onClick={this.navToggleClick}
          >
            <span className={styles.lines}></span>
          </div>
        </div>
        <ul
          className={
            navToggle
              ? styles.nav_list
              : `${styles.nav_list} ${styles.hidden_xs_sm}`
          }
        >
          {displayUsername && username && (
            <li className={styles.user}>
              <span className={styles.username}>{username}</span>
            </li>
          )}
          <MenuItem
            title="Settings"
            visible
            active={active === 'settings'}
            icon="setting"
            target={() => this.toggleDropDown('settings')}
          >
            <SubMenuItem
              title="Log Out"
              id="logout"
              target={() => this.handleLinkClick('/user/logout')}
              pullLeft
            />
          </MenuItem>
        </ul>
      </nav>
    );
  },

  showDropDown(id) {
    this.setState({
      active: id,
    });
  },

  toggleDropDown(id) {
    const { active } = this.state;

    if (active === id) {
      this.setState({
        active: null,
      });
    } else {
      this.setState({
        active: id,
      });
    }
  },
});

export default BasicMenu;
