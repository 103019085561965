import vcfAlerts from 'images/webhooks/vcf-alerts.png';
import {
  DefinitionList,
  Figure,
  OrderedList,
  SectionDescription,
  SectionHeader,
  SubSection,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import React, { Fragment, ReactElement } from 'react';

const IPWhitelisting = (): ReactElement => (
  <Fragment>
    <SectionHeader>IP Whitelisting</SectionHeader>
    <SubSection>
      <SectionDescription>
        In case for security reasons, you wish to whitelist 1Centre&apos;s IP.
        You will need to whitelist the following IP addresses: 54.66.76.176
      </SectionDescription>
    </SubSection>
  </Fragment>
);

export default IPWhitelisting;
