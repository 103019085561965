/* eslint-disable max-len */
import { FEATURE_FLAGS } from 'conf';
import store from 'stores/store';

export function isTrust() {
  return _entityType() === 'trust';
}

export function isPartnership() {
  return _entityType() === 'partnership';
}

export function isJoint() {
  return (
    _entityType() === 'personal' &&
    store.getState().cob_business.entity_party_count > 1
  );
}

export function isSoleTrader() {
  if (!FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER) return false;
  return _entityType() === 'sole_trader';
}

export function requiresSignatories() {
  return isTrust() || isPartnership() || isJoint() || isSoleTrader();
}

function _entityType() {
  return store.getState().cob_business.entity_type;
}
