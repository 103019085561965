import RangeSlider from 'modules/shared/components/widgets/interactive/RangeSlider';
import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import sliderStyles from './css/CreditRiskIndicator.css';
import styles from '../../css/CommonReviewBusiness.css';

var CreditRiskIndicator = createClass({
  _windowSizeHandler() {
    let element = this.refs.cri_range;
    this.setState({ inputRangeWidth: element.offsetWidth });
  },

  getInitialState() {
    return {
      inputRangeWidth: 0,
    };
  },

  componentDidMount() {
    this._windowSizeHandler();
  },

  componentWillMount() {
    window.addEventListener('resize', this._windowSizeHandler);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this._windowSizeHandler);
  },

  render() {
    const { data } = this.props;
    const max = 950,
      offset = 40; // Slide thumb width

    let cri = data.enquiry_response.enquiry_result.cri;
    let valuePosition =
      (((this.state.inputRangeWidth - 40) * cri) / max).toString() + 'px';

    return (
      <ReviewContainer
        subHeading="Credit Risk Indicator"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded
        fontSize="medium"
      >
        <p>
          <strong>Payment Performance</strong>
        </p>
        <div className={sliderStyles.value} style={{ left: valuePosition }}>
          {cri}
        </div>
        <input
          type="range"
          id={'cri'}
          name={'cri'}
          ref={'cri_range'}
          step={1}
          min={0}
          max={max}
          className={sliderStyles.default}
          readOnly={true}
          value={cri}
        />
        <div className={sliderStyles.labels}>
          <p>
            <strong>poor: 0</strong>
          </p>
          <p>
            <strong>excellent: 950</strong>
          </p>
        </div>
      </ReviewContainer>
    );
  },
});

export default CreditRiskIndicator;
