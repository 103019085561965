/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import styles from './css/SimpleTable.css';

import Button from 'modules/shared/components/inputs/Button';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import { formatLocalTime } from 'utils/dateFormatter';

var SimpleTable = createClass({
  getInitialState: function() {
    return {
      isTooltipActive: true,
    };
  },

  hideTooltip: function() {
    this.setState({ isTooltipActive: false });
  },

  getKeyValue: function(column, value, keyName = 'key') {
    const { config, admin } = this.props;
    return config[column][keyName].split('.').reduce((a, b) => a[b], value);
  },

  // Bound to each 'action' button - calls the function in the
  // 'actions' prop mapped by the column's actionKey property.
  handleAction: function(actionKey, value) {
    const { actions } = this.props;
    let action = actions[actionKey];
    action(value);
  },

  render: function() {
    const { data, config, tip_name, tip_css_style } = this.props;

    let tool_tip;
    if (tip_name && this.state.isTooltipActive && data.length) {
      tool_tip = (
        <ToolTip
          tip_name={tip_name}
          css_style={tip_css_style}
          dismissHandler={this.hideTooltip}
        />
      );
    }

    // We sort every render.
    let column_headers = [],
      rows = [];

    // Set up the headers from the column config prop.
    config.forEach((col, i) => {
      let th = null;

      if (col.type === 'data') {
        th = (
          <th key={i} className={styles.heading} width={col.widthDefault}>
            {col.label}
          </th>
        );
      }
      if (col.type === 'action') {
        th = <th key={i} />;
      }
      column_headers.push(th);
    });

    // Set up the rows.
    data.forEach((rowData, index) => {
      let tds = [];

      // Add content for table cells via the column config.
      config.forEach((col, i) => {
        let td = null;

        // Fetch value
        let keyValue = this.getKeyValue(i, rowData);
        // Formatting for regular data values.
        if (col.type === 'data') {
          if (col.hasOwnProperty('actionKey')) {
            var actionValue = this.getKeyValue(i, rowData, 'actionParameter');
            td = (
              <td
                key={i}
                className={styles[col.cellClass]}
                onClick={this.handleAction.bind(
                  null,
                  col.actionKey,
                  actionValue
                )}
              >
                {keyValue}
              </td>
            );
          } else if (col.timestamp) {
            td = (
              <td key={i} className={styles[col.cellClass]}>
                {formatLocalTime(keyValue, 'date')}
              </td>
            );
          } else {
            td = (
              <td key={i} className={styles[col.cellClass]}>
                {keyValue}
              </td>
            );
          }
        }

        // Formatting for buttons
        if (col.type === 'action') {
          let button = (
            <Button
              small={true}
              red={true}
              text={col.label}
              handleClick={this.handleAction.bind(
                null,
                col.actionKey,
                keyValue
              )}
            />
          );

          if (col.actionKey === 'edit') {
            button = (
              <Button
                small={true}
                grey={true}
                text={col.label}
                handleClick={this.handleAction.bind(
                  null,
                  col.actionKey,
                  keyValue
                )}
              />
            );
          }

          td = <td key={i}>{button}</td>;
        }

        tds.push(td);
      });

      rows.push(<tr key={index}>{tds}</tr>);
    });

    return (
      <table className={styles.simpleTable}>
        <thead>
          <tr>{column_headers}</tr>
        </thead>
        <tbody>
          {rows}
          {tool_tip}
        </tbody>
      </table>
    );
  },
});

module.exports = connect((state, ownProps) => {
  return {
    location: ownProps.location,
  };
})(SimpleTable);
