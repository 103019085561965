import api from 'api';
import BaseModel from 'models/BaseModel';

export default class ModuleCardholderApplicationFlowModel extends BaseModel {
  async sendVerificationCodeEmail() {
    const cardholderApplicationAPI = api('module_cardholder_application_flows');
    const params = {
      email: this.attributes.email.toLowerCase(),
      channel: this.attributes.channel,
    };

    try {
      await cardholderApplicationAPI.sendVerificationCode(params);
    } catch (error) {
      console.error(error);
    }
  }

  async validateCode() {
    const cardholderApplicationAPI = api('module_cardholder_application_flows');
    const params = {
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
    };
    try {
      const result = await cardholderApplicationAPI.validateVerificationCode({
        params,
      });
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async load(supplierId) {
    const cardholderApplicationAPI = api('module_cardholder_application_flows');
    const params = { email: this.attributes.email.toLowerCase() };
    try {
      this.isLoading = true;
      const result = await cardholderApplicationAPI.getDetails(supplierId, {
        params,
      });
      this.setAttributes(result.data);
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  get details() {
    return {
      account_name: this.attributes.account_name,
      account_number: this.attributes.account_number,
      additional_fields_general_answers: this.attributes
        .additional_fields_general_answers,
      cardholders: (this.attributes.cardholders || []).map((cardholder) => {
        if (cardholder.email) cardholder.email = cardholder.email.toLowerCase();
        return cardholder;
      }),
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
      region: this.attributes.region,
    };
  }

  async saveDetails(supplierId) {
    const cardholderApplicationAPI = api('module_cardholder_application_flows');
    try {
      this.isLoading = true;
      const result = await cardholderApplicationAPI.update(
        supplierId,
        this.details
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  get signature() {
    const {
      identification_image,
      ...applicantData
    } = this.attributes.applicant_signature;

    return {
      applicant: applicantData,
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
    };
  }

  async saveApplicantSignature(supplierId) {
    const cardholderApplicationAPI = api('module_cardholder_application_flows');
    try {
      this.isLoading = true;
      const result = await cardholderApplicationAPI.update(
        supplierId,
        this.signature
      );
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }

  async saveDecision(supplierId) {
    const cardholderApplicationAPI = api('module_cardholder_application_flows');
    const params = {
      approved: this.attributes.approved,
      code: this.attributes.verification_code,
      email: this.attributes.email.toLowerCase(),
    };
    try {
      this.isLoading = true;
      const result = await cardholderApplicationAPI.update(supplierId, params);
      this.isLoading = false;
      return result;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }
}
