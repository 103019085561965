import { Header } from 'modules/consumer-onboarding/v2/ApplicationInaccessible/style';
import React, { ReactElement } from 'react';

const ApplicationInaccessible = ({
  header,
  message,
}: {
  header: string;
  message: string;
}): ReactElement => (
  <div className="columns is-centered is-mobile">
    <div className="column is-half-desktop is-three-quarters-mobile mt-100">
      <Header className="mb-4">{header}</Header>
      <div>{message}</div>
    </div>
  </div>
);

export default ApplicationInaccessible;
