import React, { useEffect, useRef, useState } from 'react';

/* Import styles */
import styles from './css/TextArea.css';

/* import components */
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

function TextArea(props) {
  const {
    css_class,
    disabled,
    error,
    label,
    handleBlur,
    handleChange,
    handleSelect,
    helper_text,
    hide_helper_text,
    id,
    mentions,
    placeholder,
    readOnly,
    required,
    rows,
    value,
    style = {},
    ...rest
  } = props;

  const [hasScroll, setHasScroll] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const element = document.getElementById(id);
    if (mentions && element.clientHeight < element.scrollHeight) {
      setHasScroll(true);
    }
  }, []);

  function applyHighlights(text) {
    text = text.replace(/\n$/g, '\n\n');
    mentions.forEach((mention) => {
      text = text.replace(
        new RegExp(mention, 'g'),
        `<mark style="color: var(--main-font-color)">${mention}</mark>`
      );
    });

    return text;
  }

  function _handleScroll() {
    const element = document.getElementById(id);
    const scroll_top = element.scrollTop;
    const backdrop_element = ref;
    backdrop_element.current.scrollTop = scroll_top;
  }

  function _handleChange(e) {
    _handleScroll();
    const element = document.getElementById(id);
    setHasScroll(element.clientHeight < element.scrollHeight);
    handleChange(e);
  }

  return (
    <div className="material-container">
      <FormControl
        error={error ? true : false}
        disabled={disabled}
        onBlur={handleBlur}
        onSelect={handleSelect}
        className={styles.material_form_control}
        style={style.formControl}
      >
        <InputLabel
          classes={{
            root: styles.material_label,
            shrink: styles.material_label_shrink,
          }}
          htmlFor={id}
          style={style.inputLabel}
        >
          {label}
        </InputLabel>
        {mentions && (
          <div className={styles.backdrop} ref={ref}>
            <div
              className={
                hasScroll ? styles.highlights_with_scroll : styles.highlights
              }
              dangerouslySetInnerHTML={{
                __html: applyHighlights(value),
              }}
            ></div>
          </div>
        )}
        <Input
          value={value}
          id={id}
          name={id}
          type={'text'}
          placeholder={placeholder}
          className={styles.material_input}
          inputProps={{
            readOnly: readOnly,
            onChange: mentions ? _handleChange : handleChange,
            onScroll: mentions ? _handleScroll : null,
          }}
          multiline={true}
          rows={rows || 4}
          style={style.input}
        />
      </FormControl>
      <FormHelperText
        id={`${id}-helper-text`}
        error={error ? true : false}
        className={styles.material_helper_text}
        style={
          error
            ? { fontSize: 10, fontWeight: 800 }
            : { fontSize: 10, fontWeight: 400 }
        }
      >
        {hide_helper_text ? '' : helper_text || error}
      </FormHelperText>
    </div>
  );
}

export default TextArea;
