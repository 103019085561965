import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#states-creating-a-state-for-your-user
 */
let States = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = 'states';
};

States.prototype.createState = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

States.prototype.getState = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

States.prototype.updateState = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

States.prototype.deleteState = function(id, success, error) {
  return this.axios
    .delete(`/${this.type}/${id}`)
    .then(success)
    .catch(error);
};

States.prototype.getStates = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

export default States;
