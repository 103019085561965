import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import { MAXIMUM_RETRY_ATTEMPTS } from 'models/IdentificationCheckModel';
import getIsAntiFraudVerified from 'modules/new-applications/components/application-sections/IdentificationCheck/utils/getIsAntiFraudVerified';
import AntiFraudCategoryRule from 'utils/AntiFraudCategoryRule';

export default function getIsAntiFraudManualVerificationRequired({
  application,
  authorisation,
  identificationCheck,
  signature,
}): boolean {
  if (signature.isManuallyApproved) {
    return false;
  }

  const antiFraudConfig = get(application, 'antiFraudAddonRule.config', {});

  const applicableCategory =
    authorisation.getApplicableAntiFraudCategory(antiFraudConfig);

  if (
    FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0 &&
    String(applicableCategory) === '0'
  ) {
    // No need to display manual pass button for 1CAF Cat 0
    return false;
  }

  const isAntiFraudVerified = getIsAntiFraudVerified({
    application,
    authorisation,
    identificationCheck,
    signature,
  });

  const isAmlVerified =
    identificationCheck.isAML &&
    identificationCheck.isIdentificationCheckSuccess &&
    FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES;

  if (
    // Only force manual verification for other id type || max retry if isAntiFraudVerified is not passing
    !isAntiFraudVerified &&
    !isAmlVerified &&
    FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES &&
    (signature.signatoryHasReachedMaxRetries ||
      signature.isOtherIdentificationType)
  ) {
    return true;
  }

  // BT-16222
  if (isAmlVerified) {
    return false;
  }

  const antiFraudCategoryRules = new AntiFraudCategoryRule(applicableCategory);

  if (
    antiFraudCategoryRules.isGovernmentVerificationRequired &&
    identificationCheck.status === 'errored'
  ) {
    return identificationCheck.retryAttempts >= MAXIMUM_RETRY_ATTEMPTS;
  }

  return !isAntiFraudVerified;
}
