import styled from 'styled-components';

export const FormWrapper = styled.form`
  @media (max-width: 767px) {
    width: 80%;
    left: 10%;
    position: relative;
  }

  input {
    background-color: white;
  }
`;

export const ModalWrapper = styled.div`
  .square-modal-body {
    padding: 35px 30px;

    li:not(:last-child) {
      margin-bottom: 10px;
    }

    div.content {
      margin-bottom: 0;
    }
  }
`;
