import { Divider, Radio } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { SignatureFont } from '../SignatureFont';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #fff;
  padding: 0 16px;
  position: relative;
  @media screen and (min-width: 991px) {
    cursor: pointer;
    &:hover {
      background-color: rgba(250, 250, 250, 1);
    }
  }
  .MuiRadio-root,
  .MuiRadio-root.Mui-checked {
    color: var(--main-color);
  }
`;

type StyleSelectionItemProps = {
  text: string;
  font: string;
  isSelected: boolean;
  handleSelect: (font: string) => void;
};

export const StyleSelectionItem = (props: StyleSelectionItemProps) => {
  const { text, font, isSelected, handleSelect } = props;

  const handleClick = () => handleSelect(font);

  return (
    <>
      <Container onClick={handleClick}>
        <Radio checked={isSelected} />
        <SignatureFont font={font} text={text} />
      </Container>
      <Divider />
    </>
  );
};
