import { FEATURE_FLAGS, FEATURE_FLAGS_TYPE } from './featureFlags';

type anyObject = { [key: string]: any };
type anyArray = any[];

export const addFlaggedObject = (
  FLAG_NAME: FEATURE_FLAGS_TYPE,
  object: anyObject
): anyObject => {
  return FEATURE_FLAGS[FLAG_NAME] ? object : {};
};

export const addFlaggedArray = (
  FLAG_NAME: FEATURE_FLAGS_TYPE,
  array: anyArray
): anyArray => {
  const isArray = Array.isArray(array);
  if (!isArray) console.warn('Not an array:', array);

  return FEATURE_FLAGS[FLAG_NAME] && isArray ? array : [];
};
