import Button from 'modules/shared/components/inputs/Button';
import styled, { css } from 'styled-components';
import { COLORS, THEME_COLORS } from 'variables/theme';
import { FONT_SIZES } from 'variables/typography';
import { GLOBAL_TRANSITION } from 'variables/utils';

type ButtonProps = {
  active: boolean;
};

export const Section = styled.div`
  border-bottom: 1px solid ${THEME_COLORS.sectionBorder};
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  > p {
    max-width: 90%;
  }
`;

export const Title = styled.h1`
  font-size: ${FONT_SIZES.heading};
  margin-bottom: 0.5rem;
`;

export const SubsectionTitle = styled.p`
  margin: 1rem 0;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  margin-top: 2em;
  margin-bottom: 5em;
`;

export const DropdownWrapper = styled.div`
  width: 300px;
`;

export const SubTextWrapper = styled.span`
  color: ${COLORS.darkGrey};
`;

export const CheckboxWithMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 13px;
    color: red;
    margin-left: 30px;
  }
`;

export const Wrapper = styled.div`
  height: 8rem;
  overflow-y: auto;
`;
