import AddonVersionModel from 'models/AddonVersionModel';
import AntiFraudCategoryRule from 'utils/AntiFraudCategoryRule';

export default class AntiFraudAddonVersionModel extends AddonVersionModel {
  get category() {
    return this.config.category;
  }

  get categoryRules() {
    return new AntiFraudCategoryRule(this.category);
  }
}
