import './guarantorConfirmModal.scss';

import Button from 'modules/shared/components/inputs/Button';
import Modal from 'modules/shared/components/v2/Modal';
import React, { FC } from 'react';

type GuarantorConformModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  supplierLegalName: string;
};

const GuarantorConformModal: FC<GuarantorConformModalProps> = (props) => {
  const { onClose, onConfirm, supplierLegalName } = props;
  return (
    <Modal
      onClose={onClose}
      rootClassName="guarantor-confirmation-modal"
      title="Important information"
      footer={
        <div className="is-flex is-full-width is-justify-content-center">
          <Button handleClick={onConfirm} text="Confirm" type="button" />
        </div>
      }
    >
      By agreeing to guarantee, you become{' '}
      <span className="personally-liable">personally liable</span> if the
      applicant fails to pay any money owing to {supplierLegalName} for this
      account. Please confirm you have read the guarantor Terms and Conditions
      carefully and agree to guarantee.
    </Modal>
  );
};

export default GuarantorConformModal;
