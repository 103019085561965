import api from 'api';
import get from 'lodash.get';
import BranchModel from 'models/BranchModel';
import UserModel from 'models/UserModel';
import formatUsers, {
  RoleType,
} from 'modules/new-applications/utils/formatUsers';
import React, { useState } from 'react';
import isBlank from 'utils/isBlank';

type Branch = {
  id: string;
  tradingName: string;
};

type Option = {
  label: string;
  value: string;
};

type FormattedBranch = Option;

type FormattedUser = {
  label: string;
  level: number;
  roleTypes: RoleType[];
  value: string;
};

type CurrentUser = {
  [key: string]: any;
};

type Props = {
  currentUser: CurrentUser;
};

export function useEntityUsersState(
  props: Props
): {
  formattedUsers: FormattedUser[];
  onFetchUsers: (supplierId: string) => void;
} {
  const { currentUser } = props;
  const [users, setUsers] = useState([]);

  const userEntityLinksAPI = api(
    'user_entity_links',
    currentUser.accessToken,
    get(currentUser, 'currentEntity.id')
  );

  const onFetchUsers = (supplierId) => {
    if (isBlank(supplierId)) {
      return;
    }

    (async () => {
      try {
        const result = await userEntityLinksAPI.getUsersByEntity(supplierId);
        const data = get(result, 'data.data', []);
        const users = data.map((datum) => new UserModel(datum));

        setUsers(users);
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const formattedUsers = formatUsers(users);

  return { formattedUsers, onFetchUsers };
}

export function useBranchesState(
  props: Props
): {
  formattedBranches: FormattedBranch[];
  onFetchBranches: () => void;
} {
  const { currentUser } = props;
  const [branches, setBranches] = useState<Branch[]>([]);

  const onSuccessCallback = (results) => setBranches(results);

  const onFetchBranches = () => {
    BranchModel.fetchBranches({
      accessToken: currentUser.accessToken,
      entityId: get(currentUser, 'currentEntity.id'),
      onSuccessCallback,
      params: { only_agreed: true },
    });
  };

  const formattedBranches = branches.map((branch) => {
    return { label: branch.tradingName, value: branch.id };
  });

  return { formattedBranches, onFetchBranches };
}

export function useSelectedBranchState(
  props
): {
  selectedBranchId: string;
  selectedOption: Option;
  setSelectedBranchId: React.Dispatch<any>;
} {
  const { branchId, branches } = props;

  const [selectedBranchId, setSelectedBranchId] = useState(branchId);
  const selectedOption =
    branches.find((branch) => branch.value === selectedBranchId) || {};

  return { selectedBranchId, selectedOption, setSelectedBranchId };
}

export function useSelectedUserState(
  props
): {
  selectedOption: Option;
  selectedUserId: string;
  setSelectedUserId: React.Dispatch<any>;
} {
  const { userId, users } = props;

  const [selectedUserId, setSelectedUserId] = useState(userId);
  const selectedOption =
    users.find((user) => user.value === selectedUserId) || {};

  return { selectedOption, selectedUserId, setSelectedUserId };
}
