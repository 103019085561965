import api from 'api';
import get from 'lodash.get';
import { setCurrentEntityLeadName } from 'modules/user/actions';
import moment from 'moment';
import { browserHistory } from 'react-router';
import isPresent from 'utils/isPresent';

import {
  ENTITY_LEAD_NAME_UPDATE_ERROR,
  ENTITY_LEAD_NAME_UPDATE_START,
  ENTITY_LEAD_NAME_UPDATE_SUCCESS,
  LEAD_SET_LEAD_NAME,
  LEADS_ALL_LEADS_LIST_ERROR,
  LEADS_ALL_LEADS_LIST_START,
  LEADS_ALL_LEADS_LIST_SUCCESS,
  LEADS_CLEAR_REVIEW_DATA,
  LEADS_CREATE_NOTES_ERROR,
  LEADS_CREATE_NOTES_START,
  LEADS_CREATE_NOTES_SUCCESS,
  LEADS_LOAD_NOTES_ERROR,
  LEADS_LOAD_NOTES_START,
  LEADS_LOAD_NOTES_SUCCESS,
  LEADS_LOAD_REPORT_ERROR,
  LEADS_LOAD_REPORT_START,
  LEADS_LOAD_REPORT_SUCCESS,
  LEADS_REVIEW_LOAD_ERROR,
  LEADS_REVIEW_LOAD_START,
  LEADS_REVIEW_LOAD_SUCCESS,
  LEADS_SET_REGION,
  LEADS_SET_REVIEW_INDEX,
  LEADS_SET_STATE,
  LEADS_UPDATE_ERROR,
  LEADS_UPDATE_LEAD_ARCHIVE_ERROR,
  LEADS_UPDATE_LEAD_ARCHIVE_START,
  LEADS_UPDATE_LEAD_ARCHIVE_SUCCESS,
  LEADS_UPDATE_SET_DEFAULT_VALUES,
  LEADS_UPDATE_START,
  LEADS_UPDATE_SUCCESS,
  NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR,
  NEW_LEAD_COMPANY_NAME_LOOKUP_ERROR,
  NEW_LEAD_COMPANY_NAME_LOOKUP_START,
  NEW_LEAD_COMPANY_NAME_LOOKUP_SUCCESS,
  NEW_LEAD_CREATE_ERROR,
  NEW_LEAD_CREATE_START,
  NEW_LEAD_CREATE_SUCESS,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_START,
  NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  NEW_LEAD_RESET,
  NEW_LEAD_SET,
  NEW_LEAD_SET_ADDRESS,
  RESET,
  SET,
  SET_NEW_LEAD_FIELDS,
} from './constants';

const defaultRetry = 3;
const milliseconds = 1000;

const LOCAL_STORAGE_KEY = 'leads-search-params-';

const momentToString = (dateValue) => {
  if (moment.isMoment(dateValue)) {
    return dateValue.toISOString();
  }

  return dateValue;
};

export function loadLeads(query, retry = defaultRetry, isMobile = false) {
  return (dispatch, getState) => {
    dispatch({ type: LEADS_ALL_LEADS_LIST_START });

    const entityId = getState().current_user.current_entity.id;
    const leadsApi = api(
      'leads',
      getState().current_user.access_token,
      entityId,
      {
        headers: { Accept: 'application/json' },
      }
    );
    const leads = getState().leads;
    let meta = {};
    let data = [];

    const columns = [
      'consumer_name',
      'consumer_contact_email',
      'created_at',
      'supplier_contact_name',
    ];

    let order = { created_at: 'desc' };

    if (query && typeof query.sort !== 'undefined' && query.order) {
      order = { [columns[query.sort]]: query.order };
    }

    let page = leads.leadsPage;
    if (isMobile) {
      page = leads.mobilePage;
    }

    leadsApi.getLeads(
      {
        active_tab: leads.activeTab,
        entity_id: leads.branch,
        entity_type: leads.entity_type,
        from: momentToString(leads.start_date_from),
        initiator: leads.initiator,
        order,
        page,
        per_page: leads.leadsPerPage,
        region: leads.region,
        search: leads.search,
        state: leads.state,
        to: momentToString(leads.start_date_to),
        user: leads.user,
      },
      (result) => {
        if (result.data.data.length) {
          data = result.data.data;
        }
        if (result.data.meta) {
          meta = result.data.meta;
        }

        let leadList = [];
        if (isMobile) {
          leadList = (leads.lead_list || []).concat(data);
        } else {
          leadList = data;
        }

        let noMoreRecords = false;
        if (data.length < leads.leadsPerPage) {
          noMoreRecords = true;
        }

        const mobilePage = leads.mobilePage + 1;

        dispatch({
          payload: {
            lead_list: leadList,
            leadsMeta: meta,
            mobilePage,
            noMoreRecords,
          },
          type: LEADS_ALL_LEADS_LIST_SUCCESS,
        });
      },
      (error) => {
        retry > 0
          ? setTimeout(() => {
              dispatch(loadLeads(query, retry - 1));
            }, milliseconds)
          : dispatch({ payload: error, type: LEADS_ALL_LEADS_LIST_ERROR });
      }
    );
  };
}

export function setLocation(key, value) {
  let type;
  switch (key) {
    case 'region':
      type = LEADS_SET_REGION;
      break;
    case 'state':
      type = LEADS_SET_STATE;
      break;
  }
  return {
    payload: value,
    type,
  };
}

export function loadLocalStorage() {
  return (dispatch, getState) => {
    const currentUser = getState().current_user;
    const entityId = get(currentUser, 'current_entity.id');

    const searchParams =
      JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_KEY}${entityId}`)) || {};

    if (isPresent(searchParams)) {
      dispatch({ payload: searchParams, type: SET });
    }
  };
}

function setLocalStorage(getState) {
  const leads = getState().leads;
  const currentUser = getState().current_user;
  const entityId = get(currentUser, 'current_entity.id');

  const searchParams = {
    activeTab: leads.activeTab,
    branch: leads.branch,
    entity_type: leads.entity_type,
    initiator: leads.initiator,
    leadsPage: leads.leadsPage,
    leadsPerPage: leads.leadsPerPage,
    region: leads.region,
    search: leads.search,
    start_date_from: leads.start_date_from,
    start_date_to: leads.start_date_to,
    state: leads.state,
    user: leads.user,
  };

  localStorage.setItem(
    `${LOCAL_STORAGE_KEY}${entityId}`,
    JSON.stringify(searchParams)
  );
}

export function set(payload) {
  return (dispatch, getState) => {
    dispatch({ payload, type: SET });

    setLocalStorage(getState);
  };
}

export function reset() {
  return (dispatch, getState) => {
    dispatch({ type: RESET });

    setLocalStorage(getState);
  };
}

export function setLeadFormValue(key, value) {
  let type;
  switch (key) {
    case 'lead_name':
      type = LEAD_SET_LEAD_NAME;
      break;
  }

  return {
    payload: value,
    type,
  };
}

export function updateLeadName(formValues) {
  return (dispatch, getState) => {
    dispatch({ type: ENTITY_LEAD_NAME_UPDATE_START });

    const entityId = getState().current_user.current_entity.id;
    const entities = api(
      'entities',
      getState().current_user.access_token,
      entityId
    );

    entities.updateEntity(
      entityId,
      formValues,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Update Lead Name',
              props: { distinct_id: getState().current_user.data.data.id },
            },
          },
          payload: success,
          type: ENTITY_LEAD_NAME_UPDATE_SUCCESS,
        });
        dispatch(setCurrentEntityLeadName(formValues.lead_name));
      },
      (error) => {
        dispatch({
          payload: error,
          type: ENTITY_LEAD_NAME_UPDATE_ERROR,
        });
      }
    );
  };
}

export function loadLeadReport() {
  return (dispatch, getState) => {
    dispatch({ type: LEADS_LOAD_REPORT_START });

    const entityId = getState().current_user.current_entity.id;
    const leadsApi = api(
      'leads',
      getState().current_user.access_token,
      entityId
    );

    leadsApi.getLeadReport(
      (result) => {
        dispatch({
          payload: result.data.data.attributes,
          type: LEADS_LOAD_REPORT_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LEADS_LOAD_REPORT_ERROR,
        });
      }
    );
  };
}

export function updateLead({ callback, id, isDraft, data }) {
  return (dispatch, getState) => {
    dispatch({
      payload: isDraft ? 'draft' : 'submit',
      type: LEADS_UPDATE_START,
    });

    const leadsApi = api(
      'leads',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    leadsApi.updateLeadV2(
      id,
      data,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Update lead',
              props: {
                'Entity ID': getState().current_user.current_entity.id,
                'Lead ID': id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: LEADS_UPDATE_SUCCESS,
        });

        dispatch({ type: NEW_LEAD_RESET });
        dispatch({ type: NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR });
        dispatch({ type: NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR });
        dispatch(loadLeads());
        callback();
      },
      (error) => {
        dispatch(loadLeads());
        callback();
        dispatch({ type: LEADS_UPDATE_ERROR });
      }
    );
  };
}

export function updateLeadArchive(leadID, note) {
  return (dispatch, getState) => {
    dispatch({ type: LEADS_UPDATE_LEAD_ARCHIVE_START });

    const leadsApi = api(
      'leads',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    const data = {
      archived: true,
      archived_note: note,
    };

    leadsApi.updateLead(
      leadID,
      data,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Update lead archive',
              props: {
                'Entity ID': getState().current_user.current_entity.id,
                'Lead ID': leadID,
                Note: note,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: LEADS_UPDATE_LEAD_ARCHIVE_SUCCESS,
        });

        dispatch(loadLeads());
      },
      () => {
        dispatch({ type: LEADS_UPDATE_LEAD_ARCHIVE_ERROR });
      }
    );
  };
}

export function getNotes(id) {
  return (dispatch, getState) => {
    dispatch({
      payload: id,
      type: LEADS_LOAD_NOTES_START,
    });

    const notesApi = api(
      'notes',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    notesApi.getNotes(
      id,
      'leads',
      (result) => {
        dispatch({
          payload: result.data.data,
          type: LEADS_LOAD_NOTES_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LEADS_LOAD_NOTES_ERROR,
        });
      }
    );
  };
}

export function addNote(id, note) {
  return (dispatch, getState) => {
    dispatch({
      payload: id,
      type: LEADS_CREATE_NOTES_START,
    });

    const notesApi = api(
      'notes',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    notesApi.createNote(
      id,
      note.attributes,
      'leads',
      (result) => {
        dispatch({
          payload: result.data.data,
          type: LEADS_CREATE_NOTES_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LEADS_CREATE_NOTES_ERROR,
        });
      }
    );
  };
}

export function loadLeadDetails(id) {
  return (dispatch, getState) => {
    const included = [
      'addon_answer',
      'addon_answer.addon_answer_histories',
      'addon_rule',
      'iuf_approval',
      'iuf_approval.iuf_approval_histories',
      'notes',
    ];

    dispatch({
      meta: {
        mixpanel: {
          event: 'Review Lead',
          props: {
            'Entity ID': getState().current_user.current_entity.id,
            Lead: id,
            distinct_id: getState().current_user.data.data.id,
          },
        },
      },
      payload: id,
      type: LEADS_REVIEW_LOAD_START,
    });

    const leadsApi = api(
      'leads',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    leadsApi.getLead(
      id,
      (result) => {
        const data = get(result, 'data.data', {});
        const included = get(result, 'data.included', []);

        dispatch({
          payload: {
            data,
            included,
          },
          type: LEADS_REVIEW_LOAD_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LEADS_REVIEW_LOAD_ERROR,
        });
      },
      {
        params: { include: included.join(',') },
      }
    );
  };
}

export function clearReviewData() {
  return { type: LEADS_CLEAR_REVIEW_DATA };
}

export function setReviewIndex(index) {
  return {
    payload: index,
    type: LEADS_SET_REVIEW_INDEX,
  };
}

export function setNewLeadValues(value) {
  return (dispatch, getState) => {
    dispatch({
      payload: value,
      type: LEADS_UPDATE_SET_DEFAULT_VALUES,
    });
  };
}

export function setNewLeadValue(key, value, callback) {
  return (dispatch, getState) => {
    dispatch({
      payload: { key, value },
      type: NEW_LEAD_SET,
    });
    if (callback) {
      dispatch(callback);
    }
  };
}

export function setNewLeadAddressValue(key, value, callback) {
  return (dispatch, getState) => {
    dispatch({
      payload: { key, value },
      type: NEW_LEAD_SET_ADDRESS,
    });
    if (callback) {
      dispatch(callback);
    }
  };
}

export function setNewLeadFields(value) {
  return {
    payload: value,
    type: SET_NEW_LEAD_FIELDS,
  };
}

export function lookupCompanyName(string, region) {
  return (dispatch, getState) => {
    // If the string is empty, then zero out the lookup list.
    if (string.length === 0) {
      dispatch({
        type: NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        payload: string,
        type: NEW_LEAD_COMPANY_NAME_LOOKUP_START,
      });

      const companySearch = api(
        'company_search',
        getState().current_user.access_token
      );
      const data = {};
      companySearch.companySearch(
        (result) => {
          dispatch({
            payload: result.data.data,
            type: NEW_LEAD_COMPANY_NAME_LOOKUP_SUCCESS,
          });
        },
        (error) => {
          dispatch({
            payload: error,
            type: NEW_LEAD_COMPANY_NAME_LOOKUP_ERROR,
          });
        },
        {
          params: {
            name: string,
            region,
          },
        }
      );
    }
  };
}

const sanitizeAddressType = (addressType) => {
  const ADDRESS_TYPE_MAPPING = {
    physical_address: 'Physical',
    postal_address: 'Postal',
  };

  return ADDRESS_TYPE_MAPPING[addressType];
};

export function lookupAddress(string, type, region) {
  return (dispatch, getState) => {
    region = region || 'NZ';
    if (string.length === 0) {
      dispatch({
        type: NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
      });
    } else {
      dispatch({
        payload: string,
        type: NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_START,
      });
    }

    const addressSearch = api(
      'address_search',
      getState().current_user.access_token
    );
    let data = {};
    const rawData = {};
    const dropdownData = {};

    addressSearch.addressSearch(
      (result) => {
        for (let i = 0; i < result.data.data.length; i++) {
          const address = result.data.data[i];

          rawData[address.api_id] = address;
          dropdownData[address.api_id] = address.full_address;
        }
        data = {
          dropdownData,
          rawData,
        };
        dispatch({
          payload: data,
          type: NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_ERROR,
        });
      },
      {
        params: {
          address: string,
          region,
          type: sanitizeAddressType(type),
        },
      }
    );
  };
}

export function createNewLead(data, isDraft = false) {
  return (dispatch, getState) => {
    dispatch({
      payload: isDraft ? 'draft' : 'submit',
      type: NEW_LEAD_CREATE_START,
    });
    const lead_api = api(
      'leads',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    lead_api.createLead(
      data,
      (result) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Create lead',
              props: {
                'Entity ID': getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          type: NEW_LEAD_CREATE_SUCESS,
        });
        dispatch({ type: NEW_LEAD_RESET });
        dispatch({ type: NEW_LEAD_PHYSICAL_ADDRESS_LOOKUP_CLEAR });
        dispatch({ type: NEW_LEAD_COMPANY_NAME_LOOKUP_CLEAR });
        browserHistory.push('/dashboard/leads/complete');
      },
      (error) => {
        dispatch({
          payload: error,
          type: NEW_LEAD_CREATE_ERROR,
        });
      }
    );
  };
}

export function resetLeadForm() {
  return (dispatch) => dispatch({ type: NEW_LEAD_RESET });
}
