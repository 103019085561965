import React, { Fragment, useContext, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Link } from 'react-router';

import Button from 'modules/shared/components/inputs/Button';
import { StepSection } from './StepSection';
import { RapidTransferContext } from './RapidTransferContext';
import GenericConfirmModal from 'modules/shared/components/v2/ConfirmationModal/GenericConfirmationModal';

const StyledButton = styled(Button)`
  width: fit-content;
  @media screen and (max-width: 991px) {
    // This is to reset a style defined generically for button
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const PipelineLink = (
  <StyledLink to="/dashboard/customers">Customer pipeline</StyledLink>
);
const descriptionText =
  'Click the import button below to commence the rapid transfer. Imported customer data will sit in your ';

const modalTitle = 'Import';
const modalDescription =
  'Please note if Watchtower is also turned on, charges will apply.';

export const ImportSection = () => {
  const { hasFile, uploadFiles, isUploading, isWatchtowerActive } =
    useContext(RapidTransferContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImportClick = () => {
    if (isWatchtowerActive) {
      setIsModalOpen(true);
    } else {
      uploadFiles && uploadFiles();
    }
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    uploadFiles && uploadFiles();
  };

  return (
    <StepSection
      index={3}
      title="Import data"
      description={
        <>
          {descriptionText}
          {PipelineLink}
          {'.'}
        </>
      }
    >
      <Fragment>
        {isWatchtowerActive && !!isModalOpen && (
          <GenericConfirmModal
            title={modalTitle}
            description={modalDescription}
            onConfirm={handleConfirm}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Fragment>
      <StyledButton
        disabled={!hasFile || isUploading}
        text="Import"
        type="button"
        onClick={handleImportClick}
        loading={isUploading}
        loading_text={'Please wait...'}
      />
    </StepSection>
  );
};
