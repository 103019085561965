import {
  CONNECT_INTEGRATION_ERROR,
  CONNECT_INTEGRATION_SAVE_SUCCESS,
  CONNECT_INTEGRATION_START,
  CONNECT_INTEGRATION_SUCCESS,
  LOAD_INTEGRATIONS_ERROR,
  LOAD_INTEGRATIONS_START,
  LOAD_INTEGRATIONS_SUCCESS,
  REMOVE_INTEGRATION_ERROR,
  REMOVE_INTEGRATION_START,
  REMOVE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION,
} from './constants';

const initialState = {
  config: ['xero', 'unleashed'],
  connecting: {},
  loading: false,
};

export function integrationReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_INTEGRATIONS_START:
      return { ...state, loading: true };
    case LOAD_INTEGRATIONS_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case LOAD_INTEGRATIONS_ERROR:
      return { ...state, loading: false };
    case CONNECT_INTEGRATION_START:
      return {
        ...state,
        connecting: { ...state.connecting, [action.payload.service]: true },
      };
    case CONNECT_INTEGRATION_SUCCESS:
      return { ...state, authentication_url: action.payload.url };
    case CONNECT_INTEGRATION_SAVE_SUCCESS:
      return { ...state, ...action.payload };
    case CONNECT_INTEGRATION_ERROR:
      return {
        ...state,
        connecting: { ...state.connecting, [action.payload.service]: false },
      };
    case REMOVE_INTEGRATION_START:
      return {
        ...state,
        connecting: { ...state.connecting, [action.payload.service]: true },
      };
    case REMOVE_INTEGRATION_SUCCESS:
      return {
        ...state,
        ...action.payload.removedIntegration,
        connecting: { ...state.connecting, [action.payload.service]: false },
      };
    case REMOVE_INTEGRATION_ERROR:
      return {
        ...state,
        connecting: { ...state.connecting, [action.payload.service]: false },
      };
    case UPDATE_INTEGRATION:
      return {
        ...state,
        [action.payload.attributes.service]: action.payload,
      };
    default:
      return { ...state };
  }
}
