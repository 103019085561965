var _isMobileOnly = {
  Android() {
    if (navigator.userAgent.match(/Tablet|iPad/i)) {
      return false;
    } else {
      return navigator.userAgent.match(/Android/i);
    }
  },
  BlackBerry() {
    if (navigator.userAgent.match(/Tablet|iPad/i)) {
      return false;
    } else {
      return navigator.userAgent.match(/BlackBerry/i);
    }
  },
  iOS() {
    if (navigator.userAgent.match(/Tablet|iPad/i)) {
      return false;
    } else {
      return navigator.userAgent.match(/iPhone|iPod/i);
    }
  },
  Opera() {
    if (navigator.userAgent.match(/Tablet|iPad/i)) {
      return false;
    } else {
      return navigator.userAgent.match(/Opera Mini/i);
    }
  },
  Windows() {
    if (navigator.userAgent.match(/Tablet|iPad/i)) {
      return false;
    } else {
      return navigator.userAgent.match(/IEMobile/i);
    }
  },
  any() {
    return (
      _isMobileOnly.Android() ||
      _isMobileOnly.BlackBerry() ||
      _isMobileOnly.iOS() ||
      _isMobileOnly.Opera() ||
      _isMobileOnly.Windows()
    );
  },
};

export function isMobileOnly() {
  return _isMobileOnly.any();
}
