/* Import libraries */
import React, { Component } from 'react';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import Collapse from '@material-ui/core/Collapse';
import Dot from 'modules/shared/components/widgets/static/Dot';
import Icon from 'modules/shared/components/svg/Icon';
import { connect } from 'react-redux';
import { formatMoney } from 'utils/formatting';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import { loadCurrentEntity } from 'modules/user/actions';
import styles from './css/AddOnsListItem.css';
import { updateAddonRuleset } from '../actions';
import { formatLocalTime } from 'utils/dateFormatter';
import { getConflictingRulesetNames } from '../helpers';
import { FEATURE_FLAGS } from 'conf';
import { ImpactAutoDecisionRulesetPopup } from 'modules/shared/components/widgets/static/ImpactAutoDecisionRulesetPopup/ImpactAutoDecisionRulesetPopup';
import { updateRuleSet } from 'modules/addons/auto_decisioning/actions';

class AddOnsListItem extends Component {
  //todo: should be improved depends on addOn structure
  constructor(props) {
    super(props);
    this.state = {
      historyOpen: false,
      showEditMenu: false,
      showConflictError: false,
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
        tabStatusToBePaused: null,
      },
    };
  }

  showEditMenu() {
    this.setState({ showEditMenu: true });
  }

  hideEditMenu() {
    this.setState({ showEditMenu: false });
  }

  handleCollapseToggle() {
    const { historyOpen } = this.state;
    this.setState({ historyOpen: !historyOpen });
  }

  getLatestConfig = () => this.props.addonVersions[0].attributes;

  getAutoDecisionRulesetsImpactedByDirectDebitAddon = (
    autoDecisionRulesets
  ) => {
    const latestConfig = this.getLatestConfig();
    const directDebitApplicationTypes = latestConfig.application_types;
    const directDebitEntityTypes = latestConfig.legal_types;
    const directDebitMinLimit = latestConfig.min_credit_value;
    const directDebitMaxLimit = latestConfig.max_credit_value;

    // An auto decision ruleset will be affected if that one requries
    // paperless direct debit, that is, the corresponding flag should be true,
    // and the auto decision rule should overlap with the paperless direct debit rule
    const rulesetsThatWillBeImpacted = autoDecisionRulesets.filter(
      (ruleset) => {
        const { attributes } = ruleset;
        const requiresPaperlessDirectDebit = attributes.paperless_check;

        if (!requiresPaperlessDirectDebit) {
          // Paperless direct debit is not required in this auto decision ruleset.
          // Hence it won't be impacted.
          return false;
        }

        const rulesetApplicationType = attributes.application_types[0];
        const rulesetEntityTypes = attributes.legal_types || [];
        const rulesetMinLimit = attributes.min_credit_value;
        const rulesetMaxLimit = attributes.max_credit_value;

        const hasApplicationTypeOverlap = directDebitApplicationTypes.includes(
          rulesetApplicationType
        );
        const hasEntityTypeOverlap = rulesetEntityTypes.every(
          (rulesetEntityType) =>
            directDebitEntityTypes.includes(rulesetEntityType)
        );
        let hasCreditLimitOverlap = true;
        if (rulesetApplicationType && rulesetApplicationType === 'credit') {
          hasCreditLimitOverlap =
            rulesetMinLimit >= directDebitMinLimit &&
            rulesetMaxLimit <= directDebitMaxLimit;
        }

        return (
          hasApplicationTypeOverlap &&
          hasEntityTypeOverlap &&
          hasCreditLimitOverlap
        );
      }
    );

    return rulesetsThatWillBeImpacted;
  };

  getAutoDecisionRulesetsImpactedByFinancialAddon = (autoDecisionRulesets) => {
    const latestConfig = this.getLatestConfig();

    const mandatoryFields = latestConfig.config.mandatory_fields;
    let hasAssetsLiabilities = false;
    let hasIncomeExpenditure = false;
    if (mandatoryFields && Array.isArray(mandatoryFields)) {
      hasAssetsLiabilities = mandatoryFields.includes('assets_liabilities');
      hasIncomeExpenditure = mandatoryFields.includes('income_expenses');
    }

    if (!hasAssetsLiabilities && !hasIncomeExpenditure) {
      // The current financial rule has specified neither 'assets and liablities',
      // nor 'income and expenditure' as mandatory fields.
      // So this one doesn't have any impact on any active rulesets.
      return [];
    }

    const financialMinLimit = latestConfig.min_credit_value;
    const financialMaxLimit = latestConfig.max_credit_value;
    const financialEntityTypes = latestConfig.legal_types;

    // An auto decision ruleset will be affected if that one requries
    // financials, that is, the corresponding flag should be true,
    // and the auto decision rule should overlap with the financial rule
    const rulesetsThatWillBeImpacted = autoDecisionRulesets.filter(
      (ruleset) => {
        const { attributes } = ruleset;

        const isFinancialsRequired = attributes.financials_check;
        if (!isFinancialsRequired) {
          // Financial check is not required in this auto decision ruleset.
          // Hence it won't be impacted.
          return false;
        }

        const rulesetApplicationType = attributes.application_types[0];
        const rulesetEntityTypes = attributes.legal_types || [];
        const rulesetMinLimit = attributes.min_credit_value;
        const rulesetMaxLimit = attributes.max_credit_value;

        const hasEntityTypeOverlap = rulesetEntityTypes.every(
          (rulesetEntityType) =>
            financialEntityTypes.includes(rulesetEntityType)
        );
        let hasCreditLimitOverlap = true;
        if (rulesetApplicationType && rulesetApplicationType === 'credit') {
          hasCreditLimitOverlap =
            rulesetMinLimit >= financialMinLimit &&
            rulesetMaxLimit <= financialMaxLimit;
        }

        return hasEntityTypeOverlap && hasCreditLimitOverlap;
      }
    );

    return rulesetsThatWillBeImpacted;
  };

  checkImpactOnAutodecision = () => {
    const isAboutToBePaused =
      this.props.currentAddonData.attributes.active === true;

    const { addonType } = this.props;
    const isDirectDebitAddon = addonType === 'paperless';
    const isFinancialAddon = addonType === 'financials';

    if (
      (isDirectDebitAddon || isFinancialAddon) &&
      FEATURE_FLAGS.REVAMPED_1CAD &&
      isAboutToBePaused
    ) {
      const rulesetsThatWillBeImpacted =
        addonType === 'paperless'
          ? this.getAutoDecisionRulesetsImpactedByDirectDebitAddon(
              this.props.autoDecisionRulesets
            )
          : this.getAutoDecisionRulesetsImpactedByFinancialAddon(
              this.props.autoDecisionRulesets
            );
      if (!rulesetsThatWillBeImpacted.length) {
        // We didn't find any ruleset that will be impacted
        // We'll just proced to update the settings
        this.toggleRuleSet();
        return;
      }

      this.setState({
        autoDecisionImpact: {
          rulesetsThatWillBeImpacted,
          isAutoDecisionImpactModalVisible: true,
          onConfirm: this.toggleRuleSet,
          tabStatusToBePaused: isDirectDebitAddon
            ? 'additions_status'
            : 'financials_status',
        },
      });
    } else {
      this.toggleRuleSet();
    }
  };

  handleCancelAutoDecisionImpact = () => {
    this.setState({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
      },
    });
  };

  handleConfirmAutoDecisionImpact = () => {
    const onConfirm = this.state.autoDecisionImpact.onConfirm;
    onConfirm && onConfirm();

    const { rulesetsThatWillBeImpacted, tabStatusToBePaused } =
      this.state.autoDecisionImpact;
    rulesetsThatWillBeImpacted.forEach((ruleset) => {
      // Let's pause the auto decision rules
      const { attributes, id } = ruleset;
      const updatedAttributes = {
        ...attributes,
        [tabStatusToBePaused]: 'paused',
      };
      this.props.dispatch(updateRuleSet(id, updatedAttributes));
    });

    this.setState(() => ({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
      },
    }));
  };

  getRulesetNames = () => {
    const {
      autoDecisionImpact: { rulesetsThatWillBeImpacted },
    } = this.state;
    return rulesetsThatWillBeImpacted
      .map((ruleset) => ruleset.attributes.name)
      .join(', ');
  };

  toggleRuleSet = () => {
    const { dispatch, currentAddonData, addonType, rulesetConflicts } =
      this.props;

    if (rulesetConflicts && !currentAddonData.attributes.active) {
      this.setState({ showConflictError: true });
      return;
    }
    if (addonType === 'financials') {
      dispatch(
        updateAddonRuleset(
          currentAddonData.id,
          currentAddonData.attributes,
          addonType,
          dispatch(loadCurrentEntity())
        )
      );
    } else {
      dispatch(
        updateAddonRuleset(
          currentAddonData.id,
          currentAddonData.attributes,
          addonType
        )
      );
    }
  };

  renderHistory() {
    const { addonVersions, currentAddonData, viewHistory } = this.props;
    const { historyOpen } = this.state;

    return (
      <Collapse
        in={historyOpen}
        classes={{ container: styles['collapse-container'] }}
      >
        {addonVersions.map((addonVersion, index) => (
          <div
            key={`addon_version_${index}`}
            className={styles['history-item']}
            onClick={() => {
              viewHistory(
                currentAddonData.attributes.serial_number,
                addonVersion
              );
            }}
          >
            <div>
              {addonVersion.attributes.name} (Version{' '}
              {addonVersion.attributes.version})
            </div>
            <div>
              {addonVersion.attributes.editor_first_name}{' '}
              {formatLocalTime(addonVersion.attributes.created_at, 'minute')}
            </div>
          </div>
        ))}
      </Collapse>
    );
  }

  render() {
    const {
      addonType,
      currentAddonData,
      addonVersions,
      onEdit,
      disabled,
      rulesetConflicts,
    } = this.props;
    const {
      historyOpen,
      showConflictError,
      autoDecisionImpact: { isAutoDecisionImpactModalVisible },
    } = this.state;

    const latestConfig = addonVersions[0].attributes;
    const status = currentAddonData.attributes.active
      ? statusConfig.active
      : statusConfig.paused;

    let iconChevronStyle = `${styles['collapse-arrow']}`;
    if (historyOpen) {
      iconChevronStyle = `${styles['collapse-arrow']} ${styles['collapse-arrow-open']}`;
    }

    // TODO - Update condition if application_types are apply to other addonType;
    const isCreditApplication = (latestConfig.application_types || []).includes(
      'credit'
    );

    const displayCreditLimit =
      addonType !== 'internal_use_fields' &&
      ((addonType === 'additional' && isCreditApplication) ||
        addonType !== 'additional');

    const isPaperlessCashApplication =
      addonType === 'paperless' &&
      (latestConfig.application_types || []).includes('cash') &&
      !isCreditApplication;

    return (
      <li
        className={styles.item}
        id="item"
        onClick={(e) => {
          if (e.target.id.includes('item')) {
            onEdit(
              currentAddonData.attributes.serial_number,
              currentAddonData.attributes.history_version.data[0]
            );
          }
        }}
      >
        {isMobile() && this.renderHistory()}
        <div className={styles.header}>
          <div className={styles.details}>
            <div id="item-name" className={styles.name_input}>
              #{currentAddonData.attributes.serial_number} {latestConfig.name}
              {!displayCreditLimit && (
                <span>&nbsp; (Version {latestConfig.version})</span>
              )}
              &nbsp;&nbsp;&nbsp;
              <Dot color={status.color} text={status.text} />
            </div>
            {displayCreditLimit && (
              <div id="item-info" className={styles.info_container}>
                <span id="item-limit" className={styles.details_text}>
                  Limit:{' '}
                  {isPaperlessCashApplication
                    ? 'N/A'
                    : `$${formatMoney(
                        parseFloat(latestConfig.min_credit_value)
                      )} - $${formatMoney(
                        parseFloat(latestConfig.max_credit_value)
                      )}`}
                  &nbsp; (Version {latestConfig.version})
                </span>
              </div>
            )}
            <div id="item-info" className={styles.info_container}>
              <span id="item-limit" className={styles.details_text}>
                Last edited by {latestConfig.editor_first_name} at{' '}
                {formatLocalTime(latestConfig.created_at, 'minute')}
              </span>
            </div>
            {showConflictError && (
              <div id="item-info" className={styles.info_container}>
                <span id="item-limit" className={styles.details_text_error}>
                  {`Unable to activate ruleset, conflicts with: ${getConflictingRulesetNames(
                    rulesetConflicts
                  ).join(', ')}.`}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.options}>
          <div
            className={[
              styles.edit_menu,
              !this.state.showEditMenu && styles.hide,
            ].join(' ')}
            onPointerEnter={this.showEditMenu.bind(this)}
            onPointerLeave={this.hideEditMenu.bind(this)}
          >
            <Icon icon="more_vert" />
            <ul>
              <Clickable
                onClick={() =>
                  onEdit(
                    currentAddonData.attributes.serial_number,
                    currentAddonData.attributes.history_version.data[0]
                  )
                }
              >
                {disabled ? 'view' : 'edit'}
              </Clickable>
              {status.btnText && !disabled && (
                <Clickable onClick={this.checkImpactOnAutodecision}>
                  {status.btnText}
                </Clickable>
              )}
            </ul>
          </div>
          <img
            onClick={() => {
              this.handleCollapseToggle();
            }}
            src={require('images/icon_chevron_down-white.svg')}
            className={iconChevronStyle}
          />
        </div>
        {!isMobile() && this.renderHistory()}
        {FEATURE_FLAGS.REVAMPED_1CAD && isAutoDecisionImpactModalVisible && (
          <ImpactAutoDecisionRulesetPopup
            onCancel={this.handleCancelAutoDecisionImpact}
            onConfirm={this.handleConfirmAutoDecisionImpact}
            rulesetNames={this.getRulesetNames()}
          />
        )}
      </li>
    );
  }
}

const statusConfig = {
  active: {
    btnText: 'pause',
    color: 'blue',
    text: 'active',
  },
  paused: {
    btnText: 'enable',
    color: 'red',
    text: 'paused',
  },
};

export default connect((state) => {
  return { autoDecisionRulesets: state.add_ons.auto_decisions };
})(AddOnsListItem);
