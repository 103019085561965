import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useState } from 'react';

function Icon(props) {
  const { iconProps, isOpen, useAddIcon } = props;

  if (isOpen) {
    return useAddIcon ? (
      <RemoveIcon {...iconProps} />
    ) : (
      <KeyboardArrowDownIcon {...iconProps} />
    );
  }

  return useAddIcon ? (
    <AddIcon {...iconProps} />
  ) : (
    <KeyboardArrowRightIcon {...iconProps} />
  );
}

export default function useCollapsibleTableRowState(
  { useAddIcon } = { useAddIcon: false }
) {
  const [isOpen, setIsOpen] = useState(false);

  const onToggleIsOpen = () => setIsOpen(!isOpen);

  return {
    icon: (
      <Icon
        iconProps={{
          color: 'primary',
        }}
        isOpen={isOpen}
        useAddIcon={useAddIcon}
      />
    ),
    isOpen,
    onToggleIsOpen,
  };
}
