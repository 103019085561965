import React from 'react';
import styled from 'styled-components';

const ScoreName = styled.span`
  font-style: italic;
  font-weight: 700 !important;
`;

export const TooltipTitle = () => (
  <span>
    These four frames, algorithmically extracted from a selfie video of your
    prospect using AWS technology, undergo advanced analysis to generate two key
    metrics: <ScoreName>Confidence Score</ScoreName>, quantifying the
    probability the individual is live, and{' '}
    <ScoreName>Similarity Score</ScoreName>, assessing the match between the
    person in the selfie video and the uploaded ID.
  </span>
);
