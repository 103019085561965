import React, { useState } from 'react';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import styles from './css/Section.css';
import get from 'lodash.get';

const CheckListItem = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  const onToggleIsChecked = () => {
    if (!isChecked) props.clearError(props.checkboxName);
    setIsChecked(!isChecked);
  };

  return (
    <div>
      <div className={styles.row} onClick={onToggleIsChecked}>
        <SquareCheckbox
          checked={isChecked}
          label={props.label}
          inputRef={props.inputRef}
          checkboxName={props.checkboxName}
        />
      </div>
      <div className={styles.error}>
        {get(props.errors, `${props.checkboxName}.message`, '')}
      </div>
    </div>
  );
};

const Checklist = (props) => {
  const { checklist } = props;

  return (
    <div className={styles.check_list}>
      {checklist.map((item) => (
        <CheckListItem
          key={item.name}
          checkboxName={item.name}
          label={item.label}
          inputRef={props.inputRef}
          errors={props.errors}
          clearError={props.clearError}
        />
      ))}
    </div>
  );
};

export default Checklist;
