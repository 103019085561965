import React from 'react';
import styles from './css/Section.css';
import get from 'lodash.get';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import { FEATURE_FLAGS } from 'conf';
import { StyledSignature } from 'modules/shared/components';

const DigitalSignature = (props) => {
  const {
    firstName,
    lastName,
    signatureFont,
    signatureImage,
    setSignatureStyle,
  } = props;

  return (
    <div>
      <div className={styles.tnc_instruction}>
        If you agree please fill out your first and last name in the fields
        below and press confirm.
      </div>
      <div className={styles.row}>
        <div className={styles.half_col}>
          <UncontrolledTextInput
            id={'first_name'}
            label={'First name'}
            error={get(props.errors, 'first_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
        <div className={styles.half_col}>
          <UncontrolledTextInput
            id={'last_name'}
            label={'Last name'}
            error={get(props.errors, 'last_name.message', '')}
            inputRef={props.inputRef}
          />
        </div>
      </div>
      {FEATURE_FLAGS.FEATURE_FLAG_SIGNATURE_FONT && (firstName || lastName) && (
        <StyledSignature
          firstName={firstName}
          lastName={lastName}
          font={signatureFont}
          image={signatureImage}
          onCreate={setSignatureStyle}
        />
      )}
    </div>
  );
};

export default DigitalSignature;
