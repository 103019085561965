import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import RangeItem from './RangeItem';
import { UseFormRegisterReturn } from 'react-hook-form-latest';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
  },
  separator: {
    position: 'relative',
    top: 11,
  },
}));

type Props = {
  areControlsOptional?: boolean;
  checkBoxIds?: { minCheckBoxId: string; maxCheckBoxId: string };
  errors?: { minError?: string; maxError?: string };
  minLabel?: string;
  maxLabel?: string;
  isChecked?: { isMinChecked?: boolean; isMaxChecked?: boolean };
  isDisabled?: boolean;
  values?: { minValue?: string; maxValue?: string };
  onChange?: (key: 'min' | 'max', value: string) => void;
  onCheck?: (key: 'min' | 'max', checked: boolean) => void;
  registers?: {
    minRegister: UseFormRegisterReturn;
    maxRegister: UseFormRegisterReturn;
  };
  type?: 'number' | 'currency';
  fullWidth?: boolean;
};

const LimitRangeWithControls: FC<Props> = (props: Props) => {
  const classes = useStyles();

  const {
    areControlsOptional = false,
    checkBoxIds,
    errors,
    minLabel = 'Minimum',
    maxLabel = 'Maximum',
    isChecked,
    isDisabled,
    onCheck,
    registers,
    type = 'number',
    fullWidth,
  } = props;

  const { minCheckBoxId, maxCheckBoxId } = checkBoxIds || {};
  const { isMinChecked, isMaxChecked } = areControlsOptional
    ? { isMinChecked: true, isMaxChecked: true }
    : isChecked || {};
  const { minRegister, maxRegister } = registers || {};

  const handleCheckMin = (checked: boolean) =>
    onCheck && onCheck('min', checked);

  const handleCheckMax = (checked: boolean) =>
    onCheck && onCheck('max', checked);

  return (
    <Box className={classes.root} display="flex">
      <RangeItem
        label={minLabel}
        checkboxId={minCheckBoxId || 'minCheckbox'}
        isChecked={isMinChecked}
        isDisabled={isDisabled}
        onCheck={onCheck && handleCheckMin}
        register={minRegister}
        type={type}
        error={errors?.minError}
        isControlOptional={areControlsOptional}
        fullWidth={fullWidth}
      />
      <Box className={classes.separator}>-</Box>
      <RangeItem
        label={maxLabel}
        checkboxId={maxCheckBoxId || 'maxCheckbox'}
        isChecked={isMaxChecked}
        isDisabled={isDisabled}
        onCheck={onCheck && handleCheckMax}
        register={maxRegister}
        type={type}
        error={errors?.maxError}
        isControlOptional={areControlsOptional}
        fullWidth={fullWidth}
      />
    </Box>
  );
};

export default LimitRangeWithControls;
