import React from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';

import { AccountMonitoringContextProvider } from './AccountMonitoringContext';
import { AccountStatus } from './AccountStatus';
import { ReviewDate } from './ReviewDate';
import { HistoryLogs } from './HistoryLogs';
import { useFeatureFlag } from './useFeatureFlag';

const Container = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

type AccountMonitoringProps = {
  application: any;
  currentUser: {
    accessToken: string;
    currentEntity: {
      id: string;
    };
    id: string;
  };
  onFetchApplicationRecord: () => void;
};

export default function AccountMonitoring(props: AccountMonitoringProps) {
  useFeatureFlag();

  const { application, currentUser, onFetchApplicationRecord } = props;

  return (
    <AccountMonitoringContextProvider
      application={application}
      currentUser={currentUser}
      onFetchApplicationRecord={onFetchApplicationRecord}
    >
      <Container>
        <AccountStatus />
        <Divider />
        <ReviewDate />
        <HistoryLogs />
      </Container>
    </AccountMonitoringContextProvider>
  );
}
