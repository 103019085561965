import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { useForm } from 'react-hook-form-latest';

import {
  createRuleSet,
  updateRuleSet,
} from 'modules/addons/auto_decisioning/actions';
import { getLatestApprovalLevels } from 'modules/profile/actions';
import { loadAutoDecisions } from 'modules/addons/actions';

import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import Button from 'modules/shared/components/inputs/Button';
import EntityTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/EntityTypeSelectDropdown';
import LimitRangeWithControls from 'modules/shared/components/v2/LimitRangeWithControls/LimitRangeWithControls';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { ApprovalLevel } from 'modules/profile/components/AppValuesApprovalLevels/Form/types';
import { entityOptions } from 'modules/shared/components/v2/Form/SelectDropdown/EntityTypeSelectDropdown';

import {
  IAutoDecision,
  IConflict,
  IGeneralDetails,
  IRuleSetAttributes,
  IRulesetConflict,
} from './type';

import FormFieldSection from './FormFieldSection';
import RulesetConflictDialog from './RulesetConflictDialog';
import RuleSetSection from './RuleSetSection';

import getAccountTypeOptions from 'utils/getAccountTypeOptions';
import formatMoney from 'utils/formatMoney';
import { timeInBusinessOptions, regionOptions } from './constant';
import { getOtherTabData } from './utils';

interface GeneralDetailsFormProps {
  attributes: IRuleSetAttributes;
  id: string;
  isReadyToSubmitForm: () => boolean;
  isUpdating: boolean;
  actions: {
    loadAutoDecisions: () => void;
    getLatestApprovalLevels: () => void;
    createRuleSet: (
      ruleSetAttributes: IRuleSetAttributes,
      onSuccess?: () => void
    ) => void;
    updateRuleSet: (id: string, ruleset: IRuleSetAttributes, onSuccess) => void;
  };
  autoDecisions: IAutoDecision[];
  approvalLevels: ApprovalLevel[];
  nameModified: boolean;
  setNameModified: (boolean) => void;
  submitButtonLabel: string;
  onSubmitTabData: (data: IGeneralDetails) => void;
  disabled?: boolean;
  hideSaveButton?: boolean;
  rulesetName: IRuleSetAttributes['name'];
  setDirty: (flag: boolean) => void;
  rulesetIndex: number;
}

const GeneralDetailsForm: React.FC<GeneralDetailsFormProps> = (props) => {
  const {
    attributes,
    id,
    actions,
    autoDecisions,
    approvalLevels,
    isReadyToSubmitForm,
    isUpdating,
    setNameModified,
    nameModified,
    submitButtonLabel,
    onSubmitTabData,
    disabled = false,
    hideSaveButton = false,
    rulesetName,
    setDirty,
    rulesetIndex,
  } = props;

  const minCreditLimit = get(approvalLevels[0], 'min_credit_limit', 0);
  const maxCreditLimit = Math.max(
    ...approvalLevels.map(({ credit_limit }) => credit_limit)
  );

  const [open, setOpen] = useState<boolean>(true);
  const [rulesetConflict, setRulesetConflict] = useState<
    IRulesetConflict | undefined
  >();

  const handleClose = () => {
    setOpen(false);
    setRulesetConflict(undefined);
  };

  const applicationTypeOptions = getAccountTypeOptions();

  const defaultValues = {
    regions: attributes.regions || [],
    time_in_business: attributes.time_in_business,
    application_types: attributes.application_types,
    legal_types: attributes.legal_types,
    min_credit_value: attributes.min_credit_value,
    max_credit_value: attributes.max_credit_value,
  };

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const currentValues = watch();
  const isDirty =
    JSON.stringify(defaultValues) !== JSON.stringify(currentValues);

  const applicationType =
    currentValues.application_types &&
    Array.isArray(currentValues.application_types)
      ? currentValues.application_types[0]
      : '';
  const entityTypes =
    currentValues.legal_types && Array.isArray(currentValues.legal_types)
      ? currentValues.legal_types
      : [];
  const regions = currentValues.regions;
  const minCreditValue = currentValues.min_credit_value;
  const maxCreditValue = currentValues.max_credit_value;
  const timeInBusiness = currentValues.time_in_business;

  const isCreditRule = applicationType === 'credit';

  const MIN_CREDIT_VALUE_RANGE_ERROR = `Min credit limit should be in between ${minCreditLimit} and ${maxCreditLimit}`;

  const MAX_CREDIT_VALUE_RANGE_ERROR = `Max credit limit should be in between ${minCreditLimit} and ${maxCreditLimit}`;
  const MAX_CREDIT_VALUE_MIN_ERROR = `Max credit limit should be greater than the Min credit limit value`;

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    // the minimun credit limit and maximum credit limit are from approval levels
    actions.getLatestApprovalLevels();
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [attributes]);

  const submitSuccessCallback = () => {
    setNameModified(false);
    actions.loadAutoDecisions();
  };

  const validateMinCreditValue = (value: number) => {
    if (value < minCreditLimit || value > maxCreditLimit) {
      return MIN_CREDIT_VALUE_RANGE_ERROR;
    }
    return true;
  };

  const validateMaxCreditValue = (value: number) => {
    if (value < minCreditLimit || value > maxCreditLimit) {
      return MAX_CREDIT_VALUE_RANGE_ERROR;
    }
    if (minCreditValue && value <= minCreditValue) {
      return MAX_CREDIT_VALUE_MIN_ERROR;
    }
    return true;
  };

  const onSubmit = () => {
    const isReady = isReadyToSubmitForm();

    if (isReady) {
      const rulesetToSubmit: IGeneralDetails = {
        name: rulesetName,
        regions: regions || [],
        legal_types: entityTypes || [],
        application_types: [applicationType],
        max_credit_value: maxCreditValue || 0,
        min_credit_value: minCreditValue || 0,
        time_in_business: timeInBusiness,
        general_details_status: 'complete',
        ...getOtherTabData({ entityTypes, applicationType, regions }),
      };

      // before submitting, need to check if there is conflicted ruleset
      const conflictRuleset = getConflictRuleset();

      setOpen(!!conflictRuleset);
      setRulesetConflict(conflictRuleset);

      // If there is no conflict, then either update or create the ruleset
      if (!conflictRuleset) {
        onSubmitTabData(rulesetToSubmit);
      }
    }
  };

  const applicationTypeChangeHandler = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const updatedApplicationType = event.target.value;
    setValue('application_types', [updatedApplicationType]);
    // when application type is cash, limit range should be cleared
    if (updatedApplicationType === 'cash') {
      setValue('max_credit_value', 0);
      setValue('min_credit_value', 0);
    }
  };

  const labelMapper = (
    options: { label: string; value: string }[],
    values: string[]
  ): string[] => {
    return values.map(
      (value) =>
        get(
          options.find((option) => option.value === value),
          'label'
        ) || value
    );
  };

  const getConflictRuleset = () => {
    const rulesetConflictRegions: IConflict = {
      value: [],
      conflict: [],
    };

    const rulesetConflictApplicationTypes: IConflict = {
      value: [],
      conflict: [],
    };

    const rulesetConflictLimitRange: IConflict = {
      value: [],
      conflict: [],
    };

    const rulesetConflictEntityTypes: IConflict = {
      value: [],
      conflict: [],
    };

    const conflictRuleset = {
      rulesetName: '',
      regions: rulesetConflictRegions,
      entityType: rulesetConflictEntityTypes,
      applicationType: rulesetConflictApplicationTypes,
      limitRange: rulesetConflictLimitRange,
    };

    // Calculate the conflict ruleset
    const conflictAutoDecision = autoDecisions
      .filter((autoDecision) => autoDecision.id !== id)
      .find((autoDecision) => {
        const {
          name,
          current_version,
          regions,
          legal_types,
          application_types,
          max_credit_value,
          min_credit_value,
        } = autoDecision.attributes;

        // --- regions ----
        const conflictRegions = currentValues.regions
          ? currentValues.regions.filter((region) => regions?.includes(region))
          : [];
        if (conflictRegions.length) {
          rulesetConflictRegions.value = labelMapper(regionOptions, regions);
          rulesetConflictRegions.conflict = labelMapper(
            regionOptions,
            conflictRegions
          );
        } else {
          return false;
        }

        // --- entity types ----
        const conflictEntity = currentValues.legal_types
          ? currentValues.legal_types.filter((entityType) =>
              legal_types.includes(entityType)
            )
          : [];

        if (conflictEntity.length) {
          rulesetConflictEntityTypes.value = labelMapper(
            entityOptions,
            legal_types
          );
          rulesetConflictEntityTypes.conflict = labelMapper(
            entityOptions,
            conflictEntity
          );
        } else {
          return false;
        }

        // --- application types ----
        const conflictApplicationTypes = application_types.filter(
          (applicationTypeInExistingRuleset) =>
            applicationType === applicationTypeInExistingRuleset
        );
        if (conflictApplicationTypes.length) {
          rulesetConflictApplicationTypes.value = labelMapper(
            applicationTypeOptions,
            application_types
          );
          rulesetConflictApplicationTypes.conflict = labelMapper(
            applicationTypeOptions,
            conflictApplicationTypes
          );
        } else {
          return false;
        }

        // --- limit range ----
        if (
          conflictApplicationTypes &&
          conflictApplicationTypes[0] === 'credit'
        ) {
          const conflictLimitRange =
            (min_credit_value >= (currentValues.min_credit_value ?? 0) &&
              min_credit_value <= (currentValues.max_credit_value ?? 0)) ||
            (max_credit_value >= (currentValues.min_credit_value ?? 0) &&
              max_credit_value <= (currentValues.max_credit_value ?? 0));

          if (conflictLimitRange) {
            rulesetConflictLimitRange.value = [
              `$${formatMoney(min_credit_value)} - ${formatMoney(
                max_credit_value
              )}`,
            ];
            rulesetConflictLimitRange.conflict =
              rulesetConflictLimitRange.value;
          } else {
            return false;
          }
        }

        conflictRuleset.rulesetName = `${name} (V${current_version})`;
        return true;
      });

    return conflictAutoDecision ? conflictRuleset : undefined;
  };

  const isNotSupportedEntityTypes =
    entityTypes.length === 1 && entityTypes.includes('personal');

  const isTimeInBusinessDisabled = disabled || isNotSupportedEntityTypes;

  const timeInBusinessCheck = isTimeInBusinessDisabled
    ? false
    : !timeInBusiness;

  const hasEmptyFields =
    entityTypes.length === 0 ||
    regions?.length === 0 ||
    !applicationType ||
    timeInBusinessCheck ||
    (isCreditRule &&
      (isNaN(minCreditValue ?? 0) || isNaN(maxCreditValue ?? 0)));

  const isSaveButtonDisabled =
    disabled || (!isDirty && !nameModified) || hasEmptyFields;

  useEffect(() => {
    if (isNotSupportedEntityTypes) {
      setValue('time_in_business', undefined);
    }
  }, [entityTypes]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RuleSetSection title="Region">
        <FormFieldSection id="region" label="Which region does this apply to?">
          <SimpleMultiSelectDropdown
            id="region"
            key={'region' + rulesetIndex}
            options={regionOptions}
            multiple
            bulkSelect
            disabled={disabled}
            value={currentValues.regions}
            onChange={(target) => {
              setValue('regions', target.value);
            }}
          />
        </FormFieldSection>
      </RuleSetSection>
      <RuleSetSection title="Entity Type">
        <FormFieldSection
          id="entityType"
          label="Which entity type/s does this apply to?"
        >
          <EntityTypeSelectDropdown
            multiple
            bulkSelect
            disabled={disabled}
            value={currentValues.legal_types}
            onChange={(target) => {
              setValue('legal_types', target.value);
            }}
            key={'legal_types' + rulesetIndex}
          />
        </FormFieldSection>
      </RuleSetSection>
      <RuleSetSection title="Application type">
        <FormFieldSection
          id="applicationType"
          label="Which application type does this apply to?"
        >
          <BorderedSelect
            id="applicationType"
            value={applicationType || ''}
            options={applicationTypeOptions}
            formControlCustomProps={{ withBottomMargin: false }}
            onChange={applicationTypeChangeHandler}
            disabled={disabled}
          />
        </FormFieldSection>
      </RuleSetSection>
      <RuleSetSection
        title="Limit Range"
        tooltipTitle="The limit range values are bound by the limit range set in 1CAH."
      >
        <FormFieldSection
          id="limitRange"
          label="What limit range does this apply to?"
          inputClassName="is-12"
        >
          <LimitRangeWithControls
            areControlsOptional
            registers={{
              minRegister: register('min_credit_value', {
                validate: isCreditRule ? validateMinCreditValue : undefined,
                valueAsNumber: true,
              }),
              maxRegister: register('max_credit_value', {
                validate: isCreditRule ? validateMaxCreditValue : undefined,
                valueAsNumber: true,
              }),
            }}
            errors={{
              minError: errors?.min_credit_value?.message,
              maxError: errors?.max_credit_value?.message,
            }}
            type="currency"
            isDisabled={applicationType !== 'credit' || disabled}
            fullWidth
          />
        </FormFieldSection>
      </RuleSetSection>

      <RuleSetSection title="Time in business">
        <FormFieldSection
          id="timeInBusiness"
          label={`Set the minimum timeframe a business has to be in operation to qualify
          for auto decisioning. This rule doesn't apply to Personal entity type.`}
        >
          <BorderedSelect
            id="timeInBusiness"
            value={currentValues.time_in_business}
            options={timeInBusinessOptions}
            formControlCustomProps={{ withBottomMargin: false }}
            onChange={(event) => {
              const value = event.target.value;
              setValue('time_in_business', value);
            }}
            disabled={isTimeInBusinessDisabled}
          />
        </FormFieldSection>
      </RuleSetSection>
      {!hideSaveButton ? (
        <Button
          type="submit"
          text={submitButtonLabel}
          className="mt-6"
          disabled={isSaveButtonDisabled}
          loading={isUpdating}
          loading_text="Saving..."
        />
      ) : null}
      <RulesetConflictDialog
        open={open}
        onClose={handleClose}
        rulesetConflict={rulesetConflict}
      />
    </form>
  );
};

const mapStateToProps = (state) => ({
  autoDecisions: state.add_ons.auto_decisions,
  approvalLevels: get(
    state,
    'manage_profile.current_entity_approval_levels',
    []
  ),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getLatestApprovalLevels,
      createRuleSet,
      loadAutoDecisions,
      updateRuleSet,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDetailsForm);
