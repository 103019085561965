/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

/* Import CSS */
import styles from './css/UserForm.css';

/* Import components */
import TextInput from '../../shared/components/inputs/TextInput';
import EmailInput from '../../shared/components/inputs/EmailInput';
import Checkbox from 'modules/shared/components/inputs/Checkbox';
import OptionsDropdown from '../../shared/components/widgets/interactive/OptionsDropdown';

/* eslint-disable sort-keys-fix/sort-keys-fix */
var defaults = {
  form_inputs: {
    user_first_name: {
      label: 'First name',
      required: true,
    },
    user_last_name: {
      label: 'Last name',
      required: true,
    },
    user_email: {
      label: 'Email',
      required: true,
    },
    user_contact_phone_number: {
      label: 'Phone',
      required: true,
    },
    user_receive_email_notifications: {
      label: 'Receive Email Notifications?',
      required: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

var UserForm = createClass({
  _emailChangeHandler(value, isValid) {
    const { onEmailChangeHandler } = this.props;
    onEmailChangeHandler(value, isValid);
  },

  _emailBlurHandler(value, isValid) {
    const { onEmailBlurHandler } = this.props;
    onEmailBlurHandler(value, isValid);
  },

  render: function() {
    const {
      form_values,
      form_loading,
      cssClass,
      fields_theme,
      handleChange,
      handleBlur,
      handleEnter,
      form_errors,
      isSupplier,
    } = this.props;

    var form_elements = [],
      form_inputs = defaults.form_inputs,
      input,
      style = styles.default;

    if (cssClass) {
      style = styles[cssClass];
    }

    Object.keys(form_inputs).forEach((value, index) => {
      var loading = false;
      if (form_loading && form_loading.hasOwnProperty(value)) {
        loading = form_loading[value];
      }

      if (form_inputs[value].hasOwnProperty('options')) {
        input = (
          <OptionsDropdown
            key={index}
            id={value}
            error={form_errors[value]}
            handleBlur={handleBlur}
            loading={loading}
            handleEnter={handleEnter}
            handleChange={handleChange}
            default_title={form_inputs[value].default_title}
            value={form_values[value]}
            label={form_inputs[value].label}
            required={true}
            options={form_inputs[value].options}
            theme={fields_theme}
          />
        );
      } else {
        if (form_inputs[value].label === 'Email') {
          input = (
            <EmailInput
              key={index}
              id={value}
              type="email"
              customError={form_errors[value]}
              handleChange={this._emailChangeHandler}
              handleBlur={this._emailBlurHandler}
              loading={loading}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
              theme={fields_theme}
            />
          );
        } else if (value === 'user_receive_email_notifications') {
          if (!isSupplier) return;
          input = (
            <Checkbox
              key={index}
              checkboxId={value}
              checked={form_values[value]}
              handleChange={handleChange}
              label={form_inputs[value].label}
              description={''}
            />
          );
        } else {
          input = (
            <TextInput
              key={index}
              id={value}
              error={form_errors[value]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              loading={loading}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
              theme={fields_theme}
            />
          );
        }
      }
      form_elements.push(input);
    });

    return <div className={style}>{form_elements}</div>;
  },
});

module.exports = UserForm;
