import get from "lodash.get";
import styles from "modules/profile/components/css/CommonEditProfile.css";
import Form from "modules/profile/components/DigitalFormCustomisation/Form/Form";
import { isFeatureEditEnabled } from "modules/shared/helpers/headquarterDetect";
import React from "react";
import { connect } from "react-redux";

interface IProps {
  cashApplicationTypeName: string;
  creditApplicationTypeName: string;
}

const DigitalFormCustomisation = (props: IProps): JSX.Element => (
  <div className={styles.row}>
    <div className={styles.block}>
      <div className={styles.liner}>
        <h2 className={styles.header}>Customise digital forms</h2>
        <Form
          fieldName="creditApplicationTypeName"
          fieldValue={props.creditApplicationTypeName}
          isDisabled={!isFeatureEditEnabled("Business")}
          label="Credit application"
        />
        <Form
          fieldName="cashApplicationTypeName"
          fieldValue={props.cashApplicationTypeName}
          isDisabled={!isFeatureEditEnabled("Business")}
          label="Cash application"
        />
      </div>
    </div>
  </div>
);

export default connect(state => {
  return {
    cashApplicationTypeName: get(
      state,
      "manage_profile.current_entity.attributes.cash_application_type_name",
      ""
    ),
    creditApplicationTypeName: get(
      state,
      "manage_profile.current_entity.attributes.credit_application_type_name",
      ""
    ),
  };
})(DigitalFormCustomisation);
