/* Import constants */
import { get, pickBy } from 'lodash';
import BorderedTextField from 'modules/profile/components/AppValuesApprovalLevels/ReactHookFormInputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import RegionStatesDropdown from 'modules/shared/components/widgets/static/RegionStatesDropdown';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';

import {
  createNewLead,
  lookupAddress,
  lookupCompanyName,
  updateLead,
} from '../actions';
import CreateLeadLegalType from './CreateLeadLegalType';
/* Import CSS */
import styles from './css/CreateLead.css';
import { getFormSchema } from './helper';
import IUFEdit from './IUFEdit';
import {
  activeIUFConfig,
  isIUFCompleted,
  updatedIUFAnswers,
} from 'modules/shared/helpers/internalUseFieldsHelper';
import api from 'api';
import { getIsCustomConfigABNHidden } from 'modules/shared/helpers/customLeadConfigurationHelper';

function FormField(props) {
  const { className, children, visible } = props;

  if (!visible) {
    return null;
  }

  return <div className={className}>{children}</div>;
}

function InternalFieldsDeprecated({ control, values }) {
  const internalFieldValues = arrayFromNumber(
    4,
    (number) => values[`internal_field${number}`]
  );
  const isAllEmpty = internalFieldValues.every((value) => !value);

  if (isAllEmpty) {
    return null;
  }

  return arrayFromNumber(4, (number) => (
    <div className="column is-3">
      <BorderedTextField
        name={`internal_field${number + 1}`}
        label={`${number + 1}. Internal field`}
        control={control}
      />
    </div>
  ));
}

function CreateLeadBusinessDetails(props) {
  const {
    company_name_list,
    dismissHandler,
    dispatch,
    editableLead,
    isCustomConfigABNHidden,
    loading_company_names,
    new_lead,
    physical_address_list,
    physical_address_loading,
    submitting,
  } = props;
  const [loadingIufAnswers, setLoadingIufAnswers] = useState(
    Boolean(get(editableLead, 'addonAnswerId', false))
  );
  const debouncedCallback = useCallback(
    _.debounce((payload, callback) => callback(payload), 500),
    []
  );

  function searchAddress({ keyword, type, region }) {
    const { dispatch } = props;
    dispatch(lookupAddress(keyword, type, region));
  }

  function searchCompanyName({ keyword, region }) {
    const { dispatch } = props;
    dispatch(lookupCompanyName(keyword, region));
  }

  let defaultValues = {};

  if (activeConfig) {
    iuf_answers['addon_version_id'] = get(activeConfig, 'addon_version_id');
  }

  if (editableLead) {
    defaultValues = {
      ...editableLead.attributes,
      contact_phone_number: editableLead.contactPhoneNumber,
      key_contact_email: editableLead.consumerContactEmail,
      legal_type: editableLead.consumerLegalType,
      physical_address: {
        api_id: editableLead.apiId,
        full_address: editableLead.fullAddress,
        api_provider: editableLead.apiProvider,
        raw_data: editableLead.rawData,
      },
      region: editableLead.consumerRegion,
      iuf_answers: {},
    };
  }

  const {
    control,
    formState: { errors, isValid, submitCount },
    handleSubmit,
    resetField,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: useYupValidationResolver(
      (data) => getFormSchema(data),
      {},
      {
        context: { isCustomConfigABNHidden },
      }
    ),
  });

  const [iufFormMode, setIufFormMode] = useState('onBlur');

  useEffect(() => {
    if (submitCount === 0 || iufFormMode === 'onChange') {
      return;
    }

    setIufFormMode('onChange');
  }, [submitCount]);

  const values = watch();

  const activeConfig = useMemo(
    () => activeIUFConfig('leads', values.legal_type),
    [values.legal_type]
  );
  const canSubmit = isValid && isIUFCompleted();

  useEffect(() => {
    if (activeConfig) {
      setValue('iuf_answers', {
        ...get(values, 'iuf_answers'),
        addon_version_id: activeConfig.addon_version_id,
      });
    }
  }, [activeConfig]);

  function resetBusinessFields() {
    const defaultValue = null;
    resetField('business_number', { defaultValue });
    resetField('company_name', { defaultValue });
    resetField('company_number', { defaultValue });
  }

  function handleRegionStatesDropdownChange(e) {
    const value = e.value;

    if (value === values.region) {
      return;
    }

    setValue('region', e.value);
    resetBusinessFields();
    setValue('physical_address', {});
  }

  async function loadIufAddonAnswers() {
    const iufAddonAnswerAPI = api('addon_answers');
    try {
      const result = await iufAddonAnswerAPI.get(editableLead.addonAnswerId);
      const data = get(result, 'data.data');
      const answers = get(data, 'attributes.answers');

      setValue('iuf_answers', {
        ...get(values, 'iuf_answers', {}),
        ...answers,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIufAnswers(false);
    }
  }

  useEffect(() => {
    if (!editableLead) {
      return;
    }

    Object.keys(editableLead.attributes).forEach((key) => {
      setValue(key, editableLead.attributes[key]);
    });

    if (editableLead.addonAnswerId) {
      loadIufAddonAnswers();
    }
  }, [editableLead]);

  function handleAddressChange(e, type_name) {
    const value = e.target.value;
    const type = type_name.split('-')[0];
    debouncedCallback(
      { keyword: value, region: values.region, type },
      searchAddress
    );
    setValue('physical_address', {
      ...values.physical_address,
      full_address: value,
    });
  }

  function handleAddressClick(e, type_name) {
    setValue('physical_address', physical_address_list.rawData[e]);
  }

  function handleCompanyNameChange(e) {
    const value = e.target.value;
    debouncedCallback(
      { keyword: value, region: values.region },
      searchCompanyName
    );
    setValue('company_name', value);
  }

  function handleCompanyNameClick(e) {
    const selected_name = company_name_list[e];
    setValue('company_name', selected_name.name);
    setValue('business_number', selected_name.business_number, {
      shouldValidate: true,
    });
    setValue('company_number', selected_name.company_number);
  }

  function handleIUFChange(key, params) {
    const updatedAnswers = updatedIUFAnswers({
      iufAnswers: values.iuf_answers,
      key,
      params,
    });

    setValue('iuf_answers', updatedAnswers);
  }

  function processedValue(status) {
    const cleanedUpValues = pickBy(
      values,
      (value) => value && (typeof value === 'object' || value.length > 0)
    );

    if (!activeConfig) {
      cleanedUpValues['iuf_answers'] = {};
    }

    return {
      ...cleanedUpValues,
      status,
    };
  }

  function submitDraft() {
    const processedValues = processedValue('in_progress');

    if (new_lead.id) {
      dispatch(
        updateLead({
          callback: dismissHandler,
          id: new_lead.id,
          isDraft: true,
          data: processedValues,
        })
      );
    } else {
      dispatch(createNewLead(processedValues, true));
    }
  }

  function submit() {
    if (!canSubmit) {
      return;
    }

    const { dispatch } = props;
    const processedValues = processedValue('submitted');

    if (new_lead.id) {
      dispatch(
        updateLead({
          callback: dismissHandler,
          id: new_lead.id,
          isDraft: false,
          data: processedValues,
        })
      );
    } else {
      dispatch(createNewLead(processedValues));
    }
  }

  const displayField = Boolean(values.region) && Boolean(values.legal_type);
  const isCompany = values.legal_type === 'company';
  const displayCompanyName = displayField && isCompany;
  let displayBusinessNumber =
    displayField && !isCompany && values.region === 'AU';
  if (isCustomConfigABNHidden) {
    displayBusinessNumber = false;
  }

  return (
    <form>
      <CreateLeadLegalType
        resetBusinessFields={resetBusinessFields}
        setValue={setValue}
        selected={values.legal_type}
      />
      <div className="columns is-multiline">
        <FormField className="column is-6" visible>
          <RegionStatesDropdown
            borderedStyle
            id="region"
            regionId="region"
            label="Business location"
            regionError={errors.region}
            regionValue={values.region}
            handleChange={handleRegionStatesDropdownChange}
            required
            noStates
          />
        </FormField>
        <FormField className="column is-6" visible={displayCompanyName}>
          <AutoSuggest
            borderedStyle
            id="business_name"
            label="Company/Business name"
            value={values.company_name}
            loading={loading_company_names}
            error={get(errors, 'business_number.message')}
            suggest_items={company_name_list}
            handleChange={handleCompanyNameChange}
            handleClick={handleCompanyNameClick}
            required
          />
        </FormField>
        <FormField className="column is-6" visible={displayField}>
          <BorderedTextField
            control={control}
            label="Trading name"
            name="trading_name"
            required
          />
        </FormField>
        <FormField className="column is-6" visible={displayField}>
          <BorderedTextField
            control={control}
            label="First name"
            name="key_contact_first_name"
            required
          />
        </FormField>
        <FormField className="column is-6" visible={displayField}>
          <BorderedTextField
            control={control}
            label="Last name"
            name="key_contact_last_name"
            required
          />
        </FormField>
        <FormField className="column is-6" visible={displayField}>
          <AutoSuggest
            borderedStyle
            id="physical_address"
            label="Business address"
            value={get(values, 'physical_address.full_address')}
            loading={physical_address_loading}
            error={get(errors, 'physical_address.message')}
            handleChange={(e) => {
              handleAddressChange(e, name);
            }}
            handleBlur={() => trigger('physical_address')}
            handleClick={(e) => {
              handleAddressClick(e, name);
            }}
            suggest_items={physical_address_list.dropdownData || {}}
            required
            css_class="address_lookup"
          />
        </FormField>
        <FormField className="column is-6" visible={displayField}>
          <BorderedTextField
            control={control}
            label="Delivery instructions"
            name="delivery_instruction"
          />
        </FormField>
        <FormField className="column is-3" visible={displayField}>
          <BorderedTextField
            control={control}
            label="Business phone number"
            name="contact_phone_number"
            required
          />
        </FormField>
        <FormField className="column is-3" visible={displayField}>
          <BorderedTextField
            control={control}
            label="Email"
            name="key_contact_email"
            required
          />
        </FormField>
        <FormField className="column is-6" visible={displayBusinessNumber}>
          <BorderedTextField
            control={control}
            label="ABN"
            name="business_number"
            required={displayBusinessNumber}
            readOnly={!displayBusinessNumber}
          />
        </FormField>
        {/* TODO - There are some data in prod */}
        {/* Not sure can remove deprecated fields */}
        {/* <InternalFieldsDeprecated
          control={control}
          values={values}
        /> */}
        <IUFEdit
          loading={loadingIufAnswers}
          answers={values.iuf_answers}
          activeConfig={activeConfig}
          visible={displayField && activeConfig}
          onChange={handleIUFChange}
          formProps={{
            borderedStyle: true,
            mode: iufFormMode,
            validationTrigger: true,
          }}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          type="button"
          handleClick={submitDraft}
          disableOnLoading
          loading={submitting === 'draft'}
          text="Save as draft"
        />
        <Button
          handleClick={handleSubmit(submit)}
          disableOnLoading
          loading={submitting === 'submit'}
          text="Submit"
        />
      </div>
    </form>
  );
}

export default connect((state, ownProps) => {
  const company_name_list = {};
  const leads = state.leads;
  state.leads.new_lead_company_name_list.forEach((company_name, index) => {
    company_name_list[index] = {
      business_number: company_name.attributes.business_number,
      company_number: company_name.attributes.company_number,
      id: 'company_name_search',
      name: company_name.attributes.name,
    };
  });

  const submitting = leads.new_lead_creating || leads.isLeadUpdateLoading;

  return {
    company_name_list,
    isCustomConfigABNHidden: getIsCustomConfigABNHidden(),
    loading_company_names: state.leads.new_lead_company_name_list_loading,
    new_lead: state.leads.new_lead,
    physical_address_list: state.leads.new_lead_physical_address_list,
    physical_address_loading: state.leads.new_lead_physical_address_loading,
    required_fields: state.leads.new_lead_required_fields,
    submitting,
  };
})(CreateLeadBusinessDetails);
