import { useCallback } from "react";
import { ValidationError } from "yup";

type Error = ValidationError;

const useYupValidationResolver = (validationSchema, additionalData = {}, yupValidateOptions = {}) => {
  const validateOptions = yupValidateOptions;
  validateOptions["abortEarly"] = false;

  return useCallback(
    async data => {
      try {
        const values = await validationSchema(data, additionalData).validate(data, validateOptions);

        return {
          errors: {},
          values,
        };
      } catch (errors) {
        const typedError = errors as Error;

        return {
          errors: typedError.inner.reduce(
            (allErrors, currentError) => {
              return {
                ...allErrors,
                [currentError.path]: {
                  message: currentError.message,
                  type: currentError.type ?? "validation",
                },
              }
            },
            {}
          ),
          values: {},
        };
      }
    },
    [validationSchema]
  );
}

export default useYupValidationResolver;
