// Modal.tsx
import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  makeStyles,
} from 'mui-latest';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const useStyles = makeStyles({
  root: {
    width: '393px',
    paddingBottom: '36px',
  },
  content: {
    padding: '8px 39px',
  },
  title: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '20px',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    '& svg': {
      width: '27px',
      height: '27px',
    },
  },
});

interface ModalProps {
  isOpen: boolean;
  title: React.ReactNode;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, title, onClose, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.root }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {title}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={onClose}
        >
          <CancelRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
