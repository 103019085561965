/* eslint-disable max-lines */
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import get from 'lodash.get';
import { lookupAddress } from 'modules/consumer-onboarding/actions/onboarding';
import {
  setSelectedTradingNames as setSelectedTradingNamesAction,
  setTradingNameOptions as setTradingNameOptionsAction,
} from 'modules/consumer-onboarding/actions/section';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PageHeader from 'modules/shared/components/v2/PageHeader';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { throttle } from 'throttle-debounce';
import isBlank from 'utils/isBlank';
import toggleArrayValue from 'utils/toggleArrayValue';
import { isValidEmail } from 'utils/validators';
import { v4 as uuidv4 } from 'uuid';

import styles from '../css/BusinessChildTradingName';
import { Delete, Section } from './styles';
import api from 'api';
import { isWatchtowerBlock, getWatchtowerBlockedMessage } from 'utils';

function BusinessChildTradingName(props) {
  const {
    deliveryAddressData,
    deliveryAddressLoading,
    deliveryAddressOptions,
    dispatch,
    physicalAddress,
    entityRegion,
    handleComplete,
    page_validation_start: pageValidationStart,
    setPageValidationStartFinish,
    tradingNamesOptions,
    consumerId,
    supplierId,
  } = props;

  const [error, setError] = useState({});
  const [selectedTradingNameIds, setSelectedTradingNameIds] = useState(
    props.selectedTradingNameIds
  );
  const [validationTrigger, setValidationTrigger] = useState(false);

  const DELAY_VALIDATION_DURATION = 500;
  const THROTTLE_SPEED = 500;
  const throttledLookupDeliveryAddress = throttle(
    THROTTLE_SPEED,
    false,
    lookupDeliveryAddress
  );

  /**
   * Checks if any email associated with trading entities is blocked.
   * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether any email is blocked.
   */
  async function checkEmailBlock() {
    return new Promise(async (resolve) => {
      // Result object to store whether email is blocked for each trading entity
      const emailBlocked = {};

      const blockedResourcesApi = api('blocked_resources', '', consumerId);

      // Iteratively check whether the contact emails are blocked or not.
      for (const tradingEntityId of selectedTradingNameIds) {
        try {
          // Call the API
          await blockedResourcesApi.isResourceBlocked({
            value: tradingNamesOptions[tradingEntityId].contact_email,
            type: 'Email',
            supplierId,
          });

          // The API call succeeded. This means that the email is not blocked.
          // So we set the field corresponding to the entity id to FALSE,
          // indicating that the email is NOT blocked.
          emailBlocked[tradingEntityId] = false;
        } catch (error) {
          // The API call resulted in an error

          // Set the value corresponding to the entity id.
          // TRUE if the email is blocked, and FALSE otherwise.
          emailBlocked[tradingEntityId] = isWatchtowerBlock(error);
        }
      }

      // Get a copy of the error state.
      const errorInState = error;

      // Make a new error object, taking in values from "emailBlocked" object.
      const updatedError = selectedTradingNameIds.reduce(
        (accumulator, currentId) => {
          const currentEntityError = {
            ...errorInState[currentId],
            'contact_email': emailBlocked[currentId]
              ? // The email is blocked by Watchtower.
                // Set the corresponding error message.
                getWatchtowerBlockedMessage('email')
              : // The email is not blocked.
                // Leave the value as is.
                errorInState[currentId].contact_email,
          };
          return { ...accumulator, [currentId]: currentEntityError };
        },
        {}
      );
      // Update the component state.
      setError(updatedError);

      // Check if any of the emails is in blocked state.
      const isAnyEmailBlocked = selectedTradingNameIds.some(
        (id) => emailBlocked[id]
      );

      resolve(isAnyEmailBlocked);
    });
  }

  async function validate() {
    // Do normal validations
    const validationResult = isAllValid();

    // Let's check if any of the contact emails are blocked by Watchtower
    // "isAnyEmailBlocked" is a boolean flag.
    // It will be TRUE if at least one of the email ids are blocked.
    // It will be FALSE of all the email ids are not blocked.
    const isAnyEmailBlocked = await checkEmailBlock();

    // Opposite of "isAnyEmailBlocked", for readability.
    const areAllEmailsFine = !isAnyEmailBlocked;

    // Check if all validations, including check for Watchtower block have passed.
    const isEverythingFine = validationResult && areAllEmailsFine;

    handleComplete(isEverythingFine);
    setValidationTrigger(false);
  }

  useEffect(() => {
    if (pageValidationStart) {
      validate();
      setPageValidationStartFinish();
    }
  }, [pageValidationStart]);

  useEffect(() => {
    validate();
  }, [validationTrigger]);

  useEffect(() => {
    setDefaultValues();

    // UGLY! Hacking with timeout
    // Reason: Page animation unmount BusinessOtherDetails only after
    // the page is fully mounted. Validation from BusinessOtherDetails
    // is triggered after BusinessChildTradingName is mounted. Not spending
    // another century to convert BusinessOtherDetails to a functional component.
    const delayedValidate = setTimeout(validate, DELAY_VALIDATION_DURATION);

    return () => clearTimeout(delayedValidate);
  }, []);

  function isAllValid() {
    if (selectedTradingNameIds.length === 0) {
      return false;
    }
    let result = true;
    const fieldsError = {};
    selectedTradingNameIds.forEach((id) => {
      const { result: fieldResult, fieldError } = tradingNameDetailsValidate({
        ...tradingNamesOptions[id],
        id,
      });
      result = result && fieldResult;
      fieldsError[id] = fieldError;
    });
    setError(fieldsError);

    return result;
  }

  function tradingNameDetailsValidate(tradingName, inputNameToValidate) {
    if (!selectedTradingNameIds.includes(tradingName.id)) {
      return true;
    }

    let result = true;

    const fieldError = {};

    let inputNamesToValidate = inputNameToValidate
      ? Object.keys(formInputs).filter(
          (inputName) => inputName === inputNameToValidate
        )
      : Object.keys(formInputs);

    inputNamesToValidate &&
      inputNamesToValidate.forEach((inputName) => {
        const inputValue = tradingName[inputName];
        const inputValid = inputValidation(inputName, inputValue);
        fieldError[inputName] = inputValid
          ? null
          : errorMessage(inputName, inputValue);
        result = result && inputValid;
      });

    return {
      fieldError,
      result,
    };
  }

  function inputValidation(inputName, value) {
    const required = formInputs[inputName].required;
    switch (inputName) {
      case 'delivery_address_details':
        return !!(
          value &&
          value.api_id &&
          value.api_provider &&
          value.full_address
        );
      case 'contact_email':
        if (isBlank(value)) {
          return false;
        }

        return isValidEmail(value);
      default:
        return !(required && isBlank(value));
    }
  }

  function errorMessage(inputName, value) {
    switch (inputName) {
      case 'delivery_address_details':
        if (!value) {
          return 'You must enter delivery address';
        }
        return 'Please select one address';
      case 'contact_email':
        return 'Please enter a valid email';
      default:
        return `You must enter ${formInputs[inputName].label.toLowerCase()}`;
    }
  }

  function setDefaultValues() {
    if (!tradingNamesOptions || selectedTradingNameIds.length !== 0) {
      return;
    }

    const firstTradingNameId = uuidv4();

    const firstTradingName = {
      [firstTradingNameId]: {
        deletable: true,
        delivery_address_details: {
          full_address: '',
        },
        editable: true,
        index: 0,
        trading_name: '',
      },
    };

    setTradingNameOptions({
      ...tradingNamesOptions,
      ...firstTradingName,
    });

    setSelectedTradingNames([...selectedTradingNameIds, firstTradingNameId]);
  }

  function setTradingNameOptions(tradingNamesOptions) {
    dispatch(setTradingNameOptionsAction(tradingNamesOptions));
  }

  function setSelectedTradingNames(selectedTradingNameIds) {
    handleComplete(false);
    setSelectedTradingNameIds(selectedTradingNameIds);
    dispatch(setSelectedTradingNamesAction(selectedTradingNameIds));
  }

  function lookupDeliveryAddress(keyword) {
    dispatch(lookupAddress(keyword, 'Delivery', entityRegion));
  }

  function handleDeliveryAddressChange(event, id) {
    const keyword = event.target.value;
    const processedTradingNamesOptions = { ...tradingNamesOptions };
    processedTradingNamesOptions[id].delivery_address_details = {
      full_address: event.target.value,
    };
    setTradingNameOptions(processedTradingNamesOptions);
    if (keyword.length > 0) {
      event.persist();
      throttledLookupDeliveryAddress(keyword);
    }
  }

  const handleBlur = (inputName, id) => {
    const tradingName = {
      ...tradingNamesOptions[id],
      id,
    };
    const { fieldError } = tradingNameDetailsValidate(tradingName, inputName);

    setError((error) => ({
      ...error,
      [id]: {
        ...error[id],
        ...fieldError,
      },
    }));
    fieldError && handleComplete(false);
  };

  function handleDeliveryAddressClick(value, id) {
    const processedTradingNamesOptions = { ...tradingNamesOptions };
    processedTradingNamesOptions[id].delivery_address_details =
      deliveryAddressData[value];
    setTradingNameOptions(processedTradingNamesOptions);
    setValidationTrigger(true);
  }

  function setCurrentTradingNameDetails(inputName, event, id) {
    let value = event.target.value;
    if (inputName === 'contact_email') value = value.toLowerCase();
    const processedTradingNamesOptions = { ...tradingNamesOptions };
    const updatedTradingName = {
      ...processedTradingNamesOptions[id],
      [inputName]: value,
    };
    processedTradingNamesOptions[id] = updatedTradingName;

    setTradingNameOptions(processedTradingNamesOptions);
    const { fieldError } = tradingNameDetailsValidate(
      { ...updatedTradingName, id },
      inputName
    );

    setError((error) => ({
      ...error,
      [id]: {
        ...error[id],
        ...fieldError,
      },
    }));
    handleComplete(false);
  }

  function selectTradingName(id) {
    handleComplete(false);
    const selectedIds = toggleArrayValue(selectedTradingNameIds, id);
    setSelectedTradingNames(selectedIds);
  }

  function addNewTradingName() {
    const newTradingNameId = uuidv4();
    const processedTradingNamesOptions = {};

    processedTradingNamesOptions[newTradingNameId] = {
      deletable: true,
      delivery_address_details: {
        full_address: '',
      },
      editable: true,
      index: Object.keys(tradingNamesOptions).length,
      trading_name: '',
    };

    const tradingNamesOptions_ = modifyTradingNamesOptions({
      ...processedTradingNamesOptions,
      ...tradingNamesOptions,
    });

    setTradingNameOptions(tradingNamesOptions_);
    selectTradingName(newTradingNameId);
    handleComplete(false);
  }

  function renderTradingOptions() {
    const options = Object.keys(props.tradingNamesOptions).map((key) => {
      const label = props.tradingNamesOptions[key].label;

      return {
        brief: label,
        label,
        value: key,
      };
    });

    const error = selectedTradingNameIds.length === 0;
    const errorMessage = 'You must select at least one trading name';

    return (
      <div className="columns is-multiline">
        <div className="column is-6">
          <SimpleMultiSelectDropdown
            id="trading-name-dropdown"
            label="Trading name"
            error={error ? errorMessage : ''}
            onChange={({ value }) => {
              setSelectedTradingNames(value);
            }}
            multiple
            options={options}
            required
            value={selectedTradingNameIds}
          />
          <a onClick={addNewTradingName}>+ Add new trading entity</a>
        </div>
      </div>
    );
  }

  function renderInput(inputName, id) {
    const currentTradingName = tradingNamesOptions[id] || {};
    const type = formInputs[inputName].type;
    const selected = selectedTradingNameIds.includes(id);
    const { editable } = currentTradingName;
    const disabled = !selected || !editable;
    const textInputValue = currentTradingName[inputName];
    const displayError = selected;
    const fieldError = displayError && error[id] && error[id][inputName];

    switch (inputName) {
      case 'delivery_address_details': {
        const deliveryAddress =
          currentTradingName.delivery_address_details || physicalAddress;
        return (
          <AutoSuggest
            key={`delivery-address-details-${id}`}
            label={formInputs[inputName].label}
            value={deliveryAddress.full_address}
            loading={deliveryAddressLoading}
            suggest_items={deliveryAddressOptions}
            error={fieldError}
            handleBlur={() => handleBlur(inputName, id)}
            handleChange={(e) => handleDeliveryAddressChange(e, id)}
            handleClick={(e) => handleDeliveryAddressClick(e, id)}
            required={formInputs[inputName].required}
            css_class={'address_lookup'}
            disabled={disabled}
          />
        );
      }
      default:
        return (
          <TextInput
            key={`${type}-input-${id}`}
            type={type}
            label={formInputs[inputName].label}
            required={formInputs[inputName].required}
            value={textInputValue}
            handleBlur={() => handleBlur(inputName, id)}
            handleChange={(e) => setCurrentTradingNameDetails(inputName, e, id)}
            disabled={disabled}
            error={fieldError}
          />
        );
    }
  }

  function renderInputs() {
    if (!tradingNamesOptions) {
      return null;
    }

    const tradingNameOptionsKeys = Object.keys(tradingNamesOptions);
    const displayDelete = selectedTradingNameIds.length > 1;

    return tradingNameOptionsKeys.map((id, index) => {
      const { label } = tradingNamesOptions[id];
      const selected = selectedTradingNameIds.includes(id);

      if (!selected) {
        return null;
      }

      return (
        <Section key={`trading-name-${index}-${id}`} unselected={!selected}>
          <div className="columns is-multiline">
            <div className="column is-12">
              <h3>{label} </h3>
              {displayDelete ? (
                <Delete
                  type="button"
                  className="delete is-medium"
                  onClick={() => selectTradingName(id)}
                />
              ) : (
                ''
              )}
            </div>
            {Object.keys(formInputs).map((key) => (
              <div className="column is-6" key={key}>
                <div className={styles.form}>{renderInput(key, id)}</div>
              </div>
            ))}
          </div>
        </Section>
      );
    });
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className="mb-6">
        <Section>
          <PageHeader title="Trading entities" />
          {renderTradingOptions()}
        </Section>
        <PageHeader title="Trading details" />
        {renderInputs()}
      </div>
    </MuiThemeProvider>
  );
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const formInputs = {
  trading_name: {
    defaultValueKey: 'companyName',
    label: 'Trading name',
    required: true,
    type: 'text',
  },
  contact_name: {
    defaultValueKey: 'consumerName',
    label: 'Contact person',
    required: true,
  },
  contact_email: {
    defaultValueKey: 'consumerEmail',
    label: 'Contact email address',
    required: true,
  },
  contact_phone_number: {
    defaultValueKey: 'entityPhone',
    label: 'Contact phone number',
    required: true,
    type: 'tel',
  },
  delivery_address_details: {
    defaultValueKey: 'physicalAddress',
    label: 'Delivery address',
    required: true,
  },
  delivery_instruction: {
    label: 'Delivery instructions',
    required: false,
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

const modifyTradingNamesOptions = (tradingNamesOptions) => {
  const indexedTradingNames = Object.keys(tradingNamesOptions).reduce(
    (currentTradingNames, key, index) => {
      const currentTradingName = tradingNamesOptions[key];

      currentTradingNames[key] = {
        ...tradingNamesOptions[key],
        ...(!currentTradingName.index && currentTradingName.index !== 0
          ? { index }
          : {}),
      };

      return currentTradingNames;
    },
    {}
  );

  const sortedTradingNames = Object.keys(indexedTradingNames).sort(
    (left, right) => {
      const propLeft = tradingNamesOptions[left].index;
      const propRight = tradingNamesOptions[right].index;
      if (propLeft > propRight) {
        return 1;
      }
      if (propLeft < propRight) {
        return -1;
      }
      return 0;
    }
  );

  const result = sortedTradingNames.reduce((newTradingNames, key) => {
    newTradingNames[key] = {
      ...indexedTradingNames[key],
      label: `Trading name ${indexedTradingNames[key].index + 1}`,
    };
    return newTradingNames;
  }, {});

  return result;
};

export default connect((state) => {
  return {
    companyName: get(state, 'cob_business.company_details.name', ''),
    consumerEmail: get(
      state,
      'cob_section.application.attributes.consumer_contact_email',
      ''
    ),
    consumerName: get(
      state,
      'cob_section.application.attributes.consumer_contact_full_name',
      ''
    ),
    deliveryAddressData: state.cob_business.delivery_address_raw_list,
    deliveryAddressLoading: state.cob_business.delivery_address_loading,
    deliveryAddressOptions: state.cob_business.delivery_address_list,
    entityName: state.cob_business.entity_name,
    entityPhone: state.cob_business.entity_phone,
    entityRegion: state.cob_business.entity_region,
    physicalAddress: get(state, 'cob_business.physical_address', {}),
    selectedTradingNameIds: state.cob_section.selected_trading_names,
    tradingNamesOptions: modifyTradingNamesOptions(
      state.cob_section.trading_names || {}
    ),
    consumerId: get(state, 'cob_section.current_entity.id', ''),
    supplierId: get(state, 'cob_section.supplier.id', ''),
  };
})(BusinessChildTradingName);
