import React from 'react';
import styles from './report-styles.css';
import ScoreImpactorBoxes from '../../ScoreBoxes/ScoreBoxes';
import ReportScoreGraph from './ReportScoreGraph';

const ReportScore = (props) => {
  let { productData, scoreData } = props;

  let factors =
    (scoreData &&
      scoreData.score &&
      scoreData.score.key_contributing_factors.contributing_factor) ||
    [];
  if (!Array.isArray(factors)) {
    factors = [factors];
  }

  if (!productData || !scoreData || !scoreData.score) {
    return <p>Information Unavailable</p>;
  }

  const scoreFactors: any = [];
  factors.forEach((factor) => {
    scoreFactors.push(
      <li className="pb-4">
        <div className={styles.row}>
          <div className={styles.col_3}>
            <ScoreImpactorBoxes impactor={factor.score_impactor} />
          </div>
          <div className={styles.col_9}>
            <label className="has-text-weight-semibold mb-2">
              {factor.name}
            </label>
            <p>{factor.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.col_4 + ' mb-4'}>
          <label className="has-text-danger has-text-weight-semibold">
            Scores
          </label>
        </div>
      </div>

      <div className={styles.score_bg}>
        <br />
        <h3 className="mt-4 pl-6 has-text-weight-semibold">Score</h3>
        <div className={styles.row}>
          <div className={styles.col_7}>
            <ReportScoreGraph score={scoreData.score} />
          </div>
          <div className={styles.col_5}>
            <h3 className="has-text-weight-semibold mb-4">
              Score key contributing factors
            </h3>
            <ul className="mt-2 pr-6">{scoreFactors}</ul>
          </div>
        </div>

        <div className={styles.score_desc}>
          <hr />
          <div className="py-4 px-6">
            <p>
              <div className="has-text-weight-medium">About the score</div>
              <div>
                Bureau scores use available Equifax bureau data to calculate a
                risk estimate. The primary purpose of this score is to predict
                the likelihood of a future adverse event being recorded on the
                individual's Equifax bureau records in the next 12 months.
                Please note where Individuals Trading History has been included
                it is not able to be factored into the score at this time and
                should be reviewed separately.
              </div>
            </p>

            <p className="mt-4 mb-4">
              <div className="has-text-weight-medium">Disclaimer</div>
              <div>
                The score is a statistical rank ordering tool only and as such
                should always be used in conjunction with your organisation's
                credit policies and procedures and other relevant information
                you may have about the individual, company or business. The
                score should not be and is not intended to be the sole basis for
                making a decision about whether or not to deal with a particular
                individual, company or business.
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportScore;
