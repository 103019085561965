import get from 'lodash.get';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';
import UserModel from 'models/UserModel';
import InformationContent from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/InformationContent';
import {
  FormWrapper,
  ModalWrapper,
} from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/styles';
import SubmitButton from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/SubmitButton';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  requestees: string[];
};

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required('This field is mandatory')
      .trim()
      .email('Please enter a valid email'),
    firstName: yup.string().trim().required('This field is mandatory'),
    lastName: yup.string().trim().required('This field is mandatory'),
    phoneNumber: yup.string().trim().required('This field is mandatory'),
    requestees: yup.array().min(1, 'Please select at least one person'),
  });

const REQUESTEES_FIELD_NAME = 'requestees';
const REQUESTOR_EMAIL_FIELD_NAME = 'email';
const REQUESTOR_FIRST_NAME_FIELD_NAME = 'firstName';
const REQUESTOR_LAST_NAME_FIELD_NAME = 'lastName';
const REQUESTOR_PHONE_NUMBER_FILED_NAME = 'phoneNumber';

const FORM_FIELD_LABELS = {
  [REQUESTOR_FIRST_NAME_FIELD_NAME]: 'Your first name',
  [REQUESTOR_LAST_NAME_FIELD_NAME]: 'Your last name',
  [REQUESTOR_PHONE_NUMBER_FILED_NAME]: 'Your phone number',
};

const buildRequesteesAttributes = (selectedRequestees) =>
  selectedRequestees.map((selectedRequestee) => {
    const [type, id] = selectedRequestee.split('|');
    return { id, type };
  });

const buildDefaultSelectedRequestees = (authorisationRequest) => {
  const { awaitingPermissions } = authorisationRequest;

  return awaitingPermissions.map((permission) => {
    const { requestee } = permission;

    return `${requestee.modelType}|${requestee.id}`;
  });
};

const RequestAccess = (props): ReactElement | null => {
  const { application, businessOverviewState, currentUser } = props;
  const {
    authorisationRequest,
    consumerIdToJoin,
    isRequestAccessFormVisible,
    requesteesOptions,
    setAuthorisationRequest,
    isLoading,
  } = businessOverviewState;

  if (!isRequestAccessFormVisible) {
    return null;
  }

  const defaultSelectedRequestees =
    buildDefaultSelectedRequestees(authorisationRequest);

  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isSendingRequest, setSendingRequest] = useState(false);

  const {
    clearErrors,
    control,
    handleSubmit,
    formState,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      [REQUESTEES_FIELD_NAME]: defaultSelectedRequestees,
      [REQUESTOR_EMAIL_FIELD_NAME]: currentUser.email,
      [REQUESTOR_FIRST_NAME_FIELD_NAME]: currentUser.firstName,
      [REQUESTOR_LAST_NAME_FIELD_NAME]: currentUser.lastName,
      [REQUESTOR_PHONE_NUMBER_FILED_NAME]: currentUser.contactPhoneNumber,
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const { errors } = formState;

  register(REQUESTEES_FIELD_NAME);

  const onSelectRequestee = (target) => {
    const selectedOptions = target.value;

    setValue(REQUESTEES_FIELD_NAME, selectedOptions);
    if (isPresent(target.value)) {
      clearErrors(REQUESTEES_FIELD_NAME);
    }
  };

  const onSubmit = async (data) => {
    setSendingRequest(true);

    const { firstName, lastName, phoneNumber, requestees } = data;
    const requesteesAttributes = buildRequesteesAttributes(requestees);

    const newRecord =
      await AuthorisationRequestModel.updateAuthorisationRequest({
        accessToken: currentUser.accessToken,
        applicationId: application.id,
        consumerId: consumerIdToJoin,
        requestees: requesteesAttributes,
        requester: {
          first_name: firstName,
          id: currentUser.id,
          last_name: lastName,
          phone_number: phoneNumber,
        },
      });

    setAuthorisationRequest(newRecord);

    setIsRequestSent(true);
    setSendingRequest(false);
  };

  const [focusedField, setFocusedField] = useState('');
  const isFieldFocused = (fieldName) => focusedField === fieldName;
  const clearFocus = () => setFocusedField('');
  const closeRequestModal = () => setIsRequestSent(false);

  return (
    <>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InformationContent authorisationRequest={authorisationRequest} />
          <div className="columns mb-4">
            <div className="column is-half">
              <SimpleMultiSelectDropdown
                multiple
                onChange={onSelectRequestee}
                options={requesteesOptions}
                value={watch(REQUESTEES_FIELD_NAME)}
                error={get(errors, `${REQUESTEES_FIELD_NAME}.message`)}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="mb-4 mt-6">
            Enter your details to help the authorising individuals identify you
            and get in touch if necessary.
          </div>
          <form>
            <div className="columns">
              <div className="column">
                <RHFBorderedTextField
                  control={control}
                  name={REQUESTOR_FIRST_NAME_FIELD_NAME}
                  label={FORM_FIELD_LABELS[REQUESTOR_FIRST_NAME_FIELD_NAME]}
                  labelShrink={
                    isPresent(watch(REQUESTOR_FIRST_NAME_FIELD_NAME)) ||
                    isFieldFocused(REQUESTOR_FIRST_NAME_FIELD_NAME)
                  }
                  onBlur={clearFocus}
                  onFocus={() =>
                    setFocusedField(REQUESTOR_FIRST_NAME_FIELD_NAME)
                  }
                  readOnly={authorisationRequest.lockDetails || isLoading}
                />
              </div>

              <div className="column">
                <RHFBorderedTextField
                  control={control}
                  name={REQUESTOR_LAST_NAME_FIELD_NAME}
                  label={FORM_FIELD_LABELS[REQUESTOR_LAST_NAME_FIELD_NAME]}
                  labelShrink={
                    isPresent(watch(REQUESTOR_LAST_NAME_FIELD_NAME)) ||
                    isFieldFocused(REQUESTOR_LAST_NAME_FIELD_NAME)
                  }
                  onBlur={clearFocus}
                  onFocus={() =>
                    setFocusedField(REQUESTOR_LAST_NAME_FIELD_NAME)
                  }
                  readOnly={authorisationRequest.lockDetails || isLoading}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <RHFBorderedEmailField
                  control={control}
                  disabled={true}
                  name={REQUESTOR_EMAIL_FIELD_NAME}
                  label="Your email"
                  withIcon={false}
                />
              </div>

              <div className="column">
                <RHFBorderedTextField
                  control={control}
                  name={REQUESTOR_PHONE_NUMBER_FILED_NAME}
                  label={FORM_FIELD_LABELS[REQUESTOR_PHONE_NUMBER_FILED_NAME]}
                  labelShrink={
                    isPresent(watch(REQUESTOR_PHONE_NUMBER_FILED_NAME)) ||
                    isFieldFocused(REQUESTOR_PHONE_NUMBER_FILED_NAME)
                  }
                  onBlur={clearFocus}
                  onFocus={() =>
                    setFocusedField(REQUESTOR_PHONE_NUMBER_FILED_NAME)
                  }
                  readOnly={authorisationRequest.lockDetails || isLoading}
                />
              </div>
            </div>
            <div className="field">
              <SubmitButton
                authorisationRequest={authorisationRequest}
                disabled={isLoading}
                loading={isSendingRequest}
              />
            </div>
          </form>
        </div>
      </FormWrapper>
      {isRequestSent && (
        <ModalWrapper>
          <SquareModal
            title="Important information"
            size="small"
            dismissHandler={closeRequestModal}
          >
            <div className="content">
              <ul>
                <li>
                  Your application is saved up to this page. You can log out and
                  proceed later. You will receive an email notification once
                  your request is authorised.
                </li>
                <li>
                  If you prefer to stay on this page, you can refresh your
                  browser to proceed once your request is authorised.
                </li>
              </ul>
            </div>
            <div className="is-flex is-justify-content-center">
              <Button text="Confirm" onClick={closeRequestModal} />
            </div>
          </SquareModal>
        </ModalWrapper>
      )}
    </>
  );
};

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(RequestAccess);
