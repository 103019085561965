import {
  accountType,
  courtType,
  paymentStatus,
  role,
} from './helpers/codeTable';
import { amountTypeValue, countTypeValue } from './helpers/summary';
import { displayEmpty, formatMoney } from 'utils/formatting';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { enquiryResponseData } from './helpers/reportHelpers';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var CourtActions = createClass({
  _judgements() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      corePublicRecords = response.core_public_records || {},
      courtJudgementList = corePublicRecords.court_judgement_list || {},
      judgements = courtJudgementList.judgements || [];

    if (judgements.constructor === Object) judgements = [judgements];

    return judgements.map((judgement, index) => {
      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Action Date</th>
                <td>{stringToSimpleDate(judgement.action_date)}</td>
                <th>Role in Court Action</th>
                <td>{role((judgement.role || {}).type)}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{formatMoney(judgement.amount)}</td>
                <th>Co-Borrower</th>
                <td>{displayEmpty(judgement.co_borrower)}</td>
              </tr>
              <tr>
                <th>Creditor</th>
                <td>{displayEmpty(judgement.creditor)}</td>
                <th>Status</th>
                <td>{paymentStatus((judgement.action_status || {}).type)}</td>
              </tr>
              <tr>
                <th>Plaint Number</th>
                <td>{displayEmpty(judgement.plaint_number)}</td>
                <th>Status Date</th>
                <td>{stringToSimpleDate(judgement.status_date)}</td>
              </tr>
              <tr>
                <th>Court Type</th>
                <td>{courtType((judgement.court_type || {}).type)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Court Actions"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>Total: {countTypeValue(summaryEntry, 'Judgements')}</strong>
        </p>
        <p>
          <strong>
            Amount: {amountTypeValue(summaryEntry, 'Judgements_Value')}
          </strong>
        </p>
        {this._judgements()}
      </ReviewContainer>
    );
  },
});

export default CourtActions;
