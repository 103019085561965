/* Import libraries */
import CreditCardModal from 'modules/profile/components/CreditCardModal';
import Button from 'modules/shared/components/inputs/Button';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import RegionStatesDropdown from 'modules/shared/components/widgets/static/RegionStatesDropdown';
/*Import actions */
import { addNewEntityFromBusinessToggle } from 'modules/user/actions';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { reset } from '../../payment/actions';
import EntityService from '../../shared/auth/EntityService';
/* Import components */
import TextInput from '../../shared/components/inputs/TextInput';
/* Import CSS */
import styles from './css/AddNewCompany.css';

const AddNewCompany = createClass({
  cancelCardInfo() {
    this.setState({ showCreditInfoModal: false });
  },

  checkValid(target) {
    const { form_errors } = this.state;
    switch (target.name) {
      case 'trading_name':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              trading_name: 'You must specify a trading name.',
            },
          });
        } else {
          const { trading_name, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'entity_region':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_region: 'You must enter your company location.',
            },
          });
        } else {
          const { entity_region, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'entity_state':
        if (target.value.length < 1) {
          this.setState({
            form_errors: { ...form_errors, entity_state: 'State' },
          });
        } else {
          const { entity_state, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
    }
  },

  getInitialState() {
    return {
      form_errors: new Object(),
      form_values: {
        entity_region: 'NZ',
        entity_state: '',
        trading_name: '',
      },
      isTooltipActive: true,
      new_company_id: null,
      showCreditInfoModal: false,
    };
  },

  handleBlur(event) {
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target);
    }
  },

  handleChange(e) {
    const { form_values } = this.state;
    if (!e) {
      return;
    }
    if (e.hasOwnProperty('target')) {
      this.checkValid(event.target);
    }
    form_values.trading_name = e.target.value;

    this.setState({
      form_values,
    });
  },

  handleConfirm() {
    const { dispatch, current_pricing_plan, handleDismiss } = this.props;
    const { form_values, trading_name_error } = this.state;
    const that = this;
    if (this.isComplete()) {
      const attr = {
        region: form_values.entity_region,
        state: form_values.entity_state,
        supplier: true,
        trading_name: form_values.trading_name,
      };
      dispatch(
        addNewEntityFromBusinessToggle(attr, function(id) {
          if (current_pricing_plan.attributes.plan_no == 0) {
            handleDismiss();
            const entityService = new EntityService();
            entityService.setEntity(id, function() {
              window.location.href = '/';
            });
          } else {
            dispatch(reset());
            that.setState({
              new_company_id: id,
              showCreditInfoModal: true,
            });
          }
        })
      );
    }
  },

  hideTooltip() {
    this.setState({ isTooltipActive: false });
  },

  isComplete() {
    const { form_values, form_errors } = this.state;
    let errors = {};

    if (
      form_values.trading_name !== '' &&
      form_values.entity_region.length > 0 &&
      (form_values.entity_region === 'NZ' ||
        (form_values.entity_region === 'AU' &&
          form_values.entity_state &&
          form_values.entity_state.length > 0))
    ) {
      return true;
    }
    if (form_values.trading_name == '') {
      errors = {
        ...errors,
        key_contact_phone: 'You must specify a trading name.',
      };
    }
    if (form_values.entity_region == '') {
      errors = {
        ...errors,
        entity_region: 'You must enter your company location',
      };
    } else {
      if (form_values.entity_state == '') {
        errors = { ...errors, entity_state: 'State' };
      }
    }
    this.setState({
      form_errors: errors,
    });
    return false;
  },

  regionStateHandleChange(target) {
    const { form_values } = this.state;
    if (target) {
      this.checkValid(target);
    }
    form_values[target.name] = target.value;
    this.setState({
      form_values,
    });
  },

  render() {
    const { title, form_inputs, handleDismiss, submitting } = this.props;
    const {
      form_values,
      form_errors,
      showCreditInfoModal,
      new_company_id,
      isTooltipActive,
    } = this.state;
    if (showCreditInfoModal) {
      return (
        <CreditCardModal
          onSave={this.saveCard}
          new_company_id={new_company_id}
          onCancel={handleDismiss}
        ></CreditCardModal>
      );
    }
    const form_elements = [];
    Object.keys(form_inputs).forEach((value, index) => {
      switch (value) {
        case 'trading_name':
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              type={form_inputs[value].type}
              error={form_errors[value]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />
          );
          break;
        case 'entity_region':
          form_elements.push(
            <RegionStatesDropdown
              key={index}
              id={value}
              regionId="entity_region"
              stateId="entity_state"
              label={form_inputs[value].label}
              regionError={form_errors['entity_region']}
              stateError={form_errors['entity_state']}
              regionValue={form_values['entity_region']}
              stateValue={form_values['entity_state']}
              required={form_inputs[value].required}
              handleChange={this.regionStateHandleChange}
            />
          );
          break;
      }
    });

    let tool_tip;
    if (isTooltipActive) {
      tool_tip = (
        <ToolTip
          tip_name="AddNewCompanyPopup"
          css_style="add_new_company_popup"
          dismissHandler={this.hideTooltip}
        />
      );
    }

    return (
      <CircleModal title={title} dismissHandler={handleDismiss} size={'large'}>
        <div className={styles.form}>
          {tool_tip}
          {form_elements}
        </div>
        <div className={styles.buttons}>
          <Button text="cancel" handleClick={handleDismiss} white={true} />
          <Button
            text="confirm"
            handleClick={this.handleConfirm}
            loading_text={'submitting'}
            loading={submitting}
            disableOnLoading={true}
          />
        </div>
      </CircleModal>
    );
  },

  saveCard(response) {
    const { handleDismiss } = this.props;
    if (response) {
      this.setState({ showCreditInfoModal: false });
      handleDismiss();
      const entityService = new EntityService();
      entityService.setEntity(this.state.new_company_id, function() {
        window.location.href = '/';
      });
    }
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  title: 'Add a new account?',
  form_inputs: {
    trading_name: {
      label: 'Trading name',
      required: true,
      type: 'text',
    },
    entity_region: {
      label: 'Business location',
      required: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  return {
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    form_inputs: defaults.form_inputs,
    submitting: state.current_user.new_entity_submitting,
    title: defaults.title,
  };
})(AddNewCompany);
