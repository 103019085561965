import styled from 'styled-components';
import { COLORS, THEME_COLORS } from 'variables/theme';

type ScoreType = {
  score: number;
};

const getScoreColor = (score: number): string => {
  if (score > 8) {
    return THEME_COLORS.primary;
  }

  if (score > 6 && score <= 8) {
    return THEME_COLORS.primaryLight;
  }

  return COLORS.darkGrey;
};

export const InfoWrapper = styled.div`
  color: ${COLORS.mediumGrey};

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const Score = styled.div<ScoreType>`
  align-items: center;
  background: ${(props) => getScoreColor(props.score)};
  border-radius: 100%;
  color: white;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  margin-right: 1rem;
  width: 2rem;
  min-width: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
