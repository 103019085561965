import Button from 'modules/shared/components/inputs/Button';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import React, { ReactElement } from 'react';

type Props = {
  loading: boolean;
  handleSave: () => void;
  handleDiscard: () => void;
  handleEdit: () => void;
  isValid: boolean;
};

function UnsavedModal({
  loading,
  handleSave,
  handleDiscard,
  handleEdit,
  isValid,
}: Props): ReactElement {
  return (
    <SquareModal title="Unsaved changes" size="small">
      <div>
        <p>
          You have unsaved changes on this page.{' '}
          {isValid && 'Do you want to save before proceeding?'}
          {!isValid && 'Do you want to continue filling the form?'}
        </p>
      </div>
      <div>
        {isValid && (
          <Button text="Save" handleClick={handleSave} loading={loading} />
        )}
        {!isValid && <Button text="Edit" handleClick={handleEdit} />}
        <Button text="Discard" white={true} handleClick={handleDiscard} />
      </div>
    </SquareModal>
  );
}

export default UnsavedModal;
