import {
  COB_EXTRAS_SET_QUESTION_DATA,
  COB_EXTRAS_SECTION_COMPLETE,
  COB_EXTRAS_COMPONENT_COMPLETE,
} from '../constants/extras';

import { COB_TRADEACCOUNT_CLEAR_DATA } from '../constants/section';

import { STATE_LOAD_STATE_SUCCESS } from '../../state/constants.js';

const extras_defaults = {
  answers: [],
  section_complete: false,
  section_data: {},
  completed: {},
};

export function consumerOnboardingExtrasReducer(
  state = extras_defaults,
  action
) {
  switch (action.type) {
    case COB_TRADEACCOUNT_CLEAR_DATA:
      return { ...extras_defaults };
    case COB_EXTRAS_SET_QUESTION_DATA:
      var answers = state.answers.slice(0);
      if (action.payload.value !== null) {
        var new_answer = Object.assign({}, state.answers[action.payload.index]);
        new_answer[action.payload.field] = action.payload.value;
        answers[action.payload.index] = new_answer;
      } else {
        delete answers[action.payload.index];
      }
      return { ...state, answers: answers };

    case COB_EXTRAS_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };

    case COB_EXTRAS_SECTION_COMPLETE:
      return {
        ...state,
        section_data: action.payload.data,
        section_complete: action.payload.complete,
      };

    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload['cob_extras']) {
        return { ...state, ...action.payload.cob_extras };
      }
    default:
      return { ...state };
  }
}
