import { FEATURE_FLAGS } from 'conf';
import PDFWidget from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/PDFWidget';
import FileHeld from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/FileHeld';
import Header from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Header';
import Tokenised from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Tokenised';
import React, { Fragment } from 'react';

const ImageWidget = (props) => {
  const { application, hasPDFError, isVisibleWhenEmpty, signature } = props;
  const { antiFraudAddonRule } = application;

  const isIdentificationTokenised =
    antiFraudAddonRule.config.tokenised || !application.isSubmitted;

  if (!isVisibleWhenEmpty && !signature.identificationImageUrl) {
    return null;
  }

  if (hasPDFError) {
    return (
      <Fragment>
        <Header
          title={
            FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6
              ? 'Identification'
              : 'ID verification'
          }
          underlined
        />
        <PDFWidget identificationImageUrl={signature.identificationImageUrl} />
      </Fragment>
    );
  }

  if (isIdentificationTokenised) {
    return <Tokenised {...props} />;
  }

  return <FileHeld {...props} />;
};

export default ImageWidget;
