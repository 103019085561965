import {
  accountType,
  courtType,
  paymentStatus,
  role,
} from './helpers/codeTable';
import { amountTypeValue, countTypeValue } from './helpers/summary';
import { displayEmpty, formatMoney } from 'utils/formatting';
import { enquiryResponseData, isCompany } from './helpers/reportHelpers';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var CourtWrits = createClass({
  _writs() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      corePublicRecords = response.core_public_records || {},
      courtWritList = corePublicRecords.court_writ_list || {},
      writs = courtWritList.writs || [];

    if (writs.constructor === Object) writs = [writs];

    return writs.map((writ, index) => {
      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Action Date</th>
                <td>{stringToSimpleDate(writ.action_date)}</td>
                <th>Role in Court Action</th>
                <td>{role((writ.role || {}).type)}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{formatMoney(writ.amount)}</td>
                <th>Co-Borrower</th>
                <td>{displayEmpty(writ.co_borrower)}</td>
              </tr>
              <tr>
                <th>Creditor</th>
                <td>{displayEmpty(writ.creditor)}</td>
                <th>Status</th>
                <td>{paymentStatus((writ.action_status || {}).type)}</td>
              </tr>
              <tr>
                <th>Plaint Number</th>
                <td>{displayEmpty(writ.plaint_number)}</td>
                <th>Status Date</th>
                <td>{stringToSimpleDate(writ.status_date)}</td>
              </tr>
              <tr>
                <th>Court Type</th>
                <td>{courtType((writ.court_type || {}).type)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Court Writs"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, 'Writs_and_Summons')}
          </strong>
        </p>
        <p>
          <strong>
            Amount: {amountTypeValue(summaryEntry, 'Writs_and_Summons_Value')}
          </strong>
        </p>
        {this._writs()}
      </ReviewContainer>
    );
  },
});

export default CourtWrits;
