import { IRuleSetAttributes, IndicatorStatus, TabIds } from './type';

export const getButtonLabel = (
  ruleset: IRuleSetAttributes,
  currentTab: TabIds
) => {
  const {
    general_details_status,
    validation_status,
    credit_checks_status,
    additions_status,
    financials_status,
  } = ruleset;

  const tabStatuses = [
    { tab: TabIds.general, status: general_details_status },
    { tab: TabIds.validation, status: validation_status },
    { tab: TabIds.credit, status: credit_checks_status },
    { tab: TabIds.additions, status: additions_status },
    { tab: TabIds.financials, status: financials_status },
  ];

  const otherTabStatuses = tabStatuses.filter(
    (tabStatus) => tabStatus.tab !== currentTab
  );

  const areOtherTabsComplete = otherTabStatuses.every(
    (tabStatus) => tabStatus.status === 'complete'
  );

  return areOtherTabsComplete ? 'Save' : 'Next';
};
