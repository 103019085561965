/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/* Import functions */
import {} from '../actions';

/* Import components */
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';

import styles from './css/PreviewEmailModal.css';

var PreviewEmailModal = createClass({
  downloadPreviewAttachment: function(url) {
    var win = window.open('', 'preview_attachment_window');
    win.document.write(
      `<iframe width='100%' height='100%' src=${url} style="border:0;"></iframe>`
    );
  },

  renderHeader: function() {
    const { settings_preview_email, settings_email_template } = this.props;
    var headers = [];
    settings_preview_email.header.map((h) => {
      if (!['From', 'To', 'Subject'].includes(h['name'])) return;
      headers.push(<dt>{h['name']}:</dt>);
      headers.push(<dd>{h['unparsed_value']}</dd>);
    });

    if (
      settings_email_template &&
      settings_email_template.attributes &&
      settings_email_template.attributes.attachment &&
      settings_email_template.attributes.filename
    ) {
      let has_save_attachment =
        settings_email_template.attributes.attachment &&
        settings_email_template.attributes.attachment.attachment &&
        settings_email_template.attributes.attachment.attachment.url;
      let attachment_url = has_save_attachment
        ? settings_email_template.attributes.attachment.attachment.url
        : settings_email_template.attributes.attachment;
      let filename = settings_email_template.attributes.filename;
      if (has_save_attachment) {
        filename = attachment_url.substring(
          attachment_url.lastIndexOf('/') + 1
        );
      }
      headers.push(<dt>Attachment:</dt>);
      headers.push(
        <dd>
          <Clickable
            inline={true}
            target={() => this.downloadPreviewAttachment(attachment_url)}
          >
            {filename}
          </Clickable>
        </dd>
      );
    }

    return (
      <div className={styles.preview_header}>
        <dl>{headers}</dl>
      </div>
    );
  },

  render: function() {
    const {
      dismissHandler,
      settings_preview_email_loading,
      settings_preview_email,
    } = this.props;

    if (settings_preview_email_loading) {
      return (
        <div className={styles.modal}>
          <SimpleLoader />
        </div>
      );
    }

    return (
      <div className={styles.modal}>
        <CloseButton
          text="Close"
          css_class={'preview_button_close'}
          handleClick={dismissHandler}
        />
        <div className={styles.fade}>
          <div className={styles.content}>
            <div className={styles.container}>
              <div className={styles.col}>
                <div className={styles.body}>
                  <div className={styles.section}>
                    <div className={styles.row}>{this.renderHeader()}</div>
                    <div className={styles.row}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: settings_preview_email.body.body.raw_source,
                        }}
                        className={styles.preview_body}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = connect((state, ownProps) => {
  return {
    settings_preview_email_loading:
      state.manage_profile.settings_preview_email_loading,
    settings_preview_email: state.manage_profile.settings_preview_email,
    settings_email_template: state.manage_profile.settings_email_template,
  };
})(PreviewEmailModal);
