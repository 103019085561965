import { amountTypeValue, countTypeValue } from './helpers/summary';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { enquiryResponseData } from './helpers/reportHelpers';
import styles from '../../../css/CommonReviewBusiness.css';

var CreditHistorySnapshot = createClass({
  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Credit History Snapshot"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th></th>
                <th>
                  <strong>Number</strong>
                </th>
                <th>
                  <strong>Amount</strong>
                </th>
                <th></th>
                <th>
                  <strong>Number</strong>
                </th>
                <th>
                  <strong>Amount</strong>
                </th>
              </tr>
              <tr>
                <th>
                  <strong>Credit Enquiries</strong>
                </th>
                <td>
                  <strong>
                    {countTypeValue(summaryEntry, 'Credit_Enquiries')}
                  </strong>
                </td>
                <td>
                  <strong>
                    {amountTypeValue(summaryEntry, 'Credit_Enquiries_Value')}
                  </strong>
                </td>
                <th>
                  <strong>Payment Defaults</strong>
                </th>
                <td>
                  <strong>{countTypeValue(summaryEntry, 'Defaults')}</strong>
                </td>
                <td>
                  <strong>
                    {amountTypeValue(summaryEntry, 'Defaults-Value')}
                  </strong>
                </td>
              </tr>
              <tr>
                <th>Credit Enquiries &lt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Credit_Enquiries_Less_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Credit_Enquiries_Less_Than_12_Months_Value'
                  )}
                </td>
                <th>Defaults &lt; 12 Months</th>
                <td>{countTypeValue(summaryEntry, 'Defaults-12')}</td>
                <td>{amountTypeValue(summaryEntry, 'Defaults-12-Value')}</td>
              </tr>
              <tr>
                <th>Credit Enquiries &gt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Credit_Enquiries_More_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Credit_Enquiries_More_Than_12_Months_Value'
                  )}
                </td>
                <th>Defaults &gt; 12 Months</th>
                <td>
                  {countTypeValue(summaryEntry, 'Defaults_More_Than_12_Months')}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Defaults_More_Than_12_Months_Value'
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  <strong>Broker Dealer Enquiries</strong>
                </th>
                <td>
                  <strong>
                    {countTypeValue(summaryEntry, 'Broker_Dealer_Enquiries')}
                  </strong>
                </td>
                <td>
                  <strong>
                    {amountTypeValue(
                      summaryEntry,
                      'Broker_Dealer_Enquiries_Value'
                    )}
                  </strong>
                </td>
                <th colSpan={3}>
                  <strong>Category of Default</strong>
                </th>
              </tr>
              <tr>
                <th>Broker Dealer Enquiries &lt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Broker_Dealer_Enquiries_Less_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Broker_Dealer_Enquiries_Less_Than_12_Months_Value'
                  )}
                </td>
                <th>Telco Defaults &lt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Telco_Defaults_Less_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Telco_Defaults_Less_Than_12_Months_Value'
                  )}
                </td>
              </tr>
              <tr>
                <th>Borker Dealer Enquiries &gt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Broker_Dealer_Enquiries_More_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Broker_Dealer_Enquiries_More_Than_12_Months_Value'
                  )}
                </td>
                <th>Utility Defaults &lt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Utility_Defaults_Less_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Utility_Defaults_Less_Than_12_Months_Value'
                  )}
                </td>
              </tr>
              <tr>
                <th colSpan={3}></th>
                <th>Other Defaults &lt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Other_Defaults_Less_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Other_Defaults_Less_Than_12_Months_Value'
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  <strong>Mercantile Agent Enquiries</strong>
                </th>
                <td>
                  <strong>
                    {countTypeValue(summaryEntry, 'Mercantile_Agent')}
                  </strong>
                </td>
                <td>
                  <strong>
                    {amountTypeValue(
                      summaryEntry,
                      'Mercantile_Enquiries_Value'
                    )}
                  </strong>
                </td>
                <th colSpan={3}>
                  <strong>Default Status</strong>
                </th>
              </tr>
              <tr>
                <th>Mercantile Agent Enquiries &lt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Mercantile_Agent_Enquiries_Less_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Mercantile_Agent_Enquiries_Less_Than_12_Months_Value'
                  )}
                </td>
                <th>Paid Defaults</th>
                <td>{countTypeValue(summaryEntry, 'Defaults-Paid')}</td>
                <td>{amountTypeValue(summaryEntry, 'Defaults-Paid-Value')}</td>
              </tr>
              <tr>
                <th>Mercantile Agent Enquiries &gt; 12 Months</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Mercantile_Agent_Enquiries_More_Than_12_Months'
                  )}
                </td>
                <td>
                  {amountTypeValue(
                    summaryEntry,
                    'Mercantile_Agent_Enquiries_More_Than_12_Months_Value'
                  )}
                </td>
                <th>Other Status Defaults</th>
                <td>{countTypeValue(summaryEntry, 'Defaults-Unpaid')}</td>
                <td>
                  {amountTypeValue(summaryEntry, 'Defaults-Unpaid-Value')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ReviewContainer>
    );
  },
});

export default CreditHistorySnapshot;
