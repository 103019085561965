import get from 'lodash.get';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React from 'react';

function TradingName(props) {
  const { consumerTradingName } = props;

  return (
    <FixedContent header="Trading details">
      <GridContent>
        <div>
          <LabeledContent
            label="Trading name"
            content={consumerTradingName.tradingName}
          />
          <LabeledContent
            label="Contact email address"
            content={consumerTradingName.contactEmail}
          />
          <LabeledContent
            label="Delivery address"
            content={get(
              consumerTradingName,
              'deliveryAddressDetails.full_address'
            )}
          />
        </div>
        <div>
          <LabeledContent
            label="Contact person"
            content={consumerTradingName.contactName}
          />
          <LabeledContent
            label="Contact phone number"
            content={consumerTradingName.contactPhoneNumber}
          />
          <LabeledContent
            label="Delivery instructions"
            content={consumerTradingName.deliveryInstruction}
          />
        </div>
      </GridContent>
    </FixedContent>
  );
}

export default function TradingDetails(props) {
  const { application } = props;
  const consumerTradingNames = application.consumerTradingNames || [];

  return consumerTradingNames.map((consumerTradingName, index) => (
    <TradingName
      key={`trading-name-${index + 1}`}
      index={index}
      consumerTradingName={consumerTradingName}
    />
  ));
}
