import api from 'api';
import get from 'lodash.get';
import { getLabelForValue } from 'modules/consumer-onboarding/components/onboarding/utils/disablePersonalEntityType';
import React, { useState } from 'react';
import isBlank from 'utils/isBlank';

const sanitizeConsumerIdOptions = (supplierId, userEntityLinks) =>
  userEntityLinks.filter(
    (userEntityLink) => userEntityLink.entity_id !== supplierId
  );

const getEntityTypeDescription = (entityType) => {
  if (isBlank(entityType)) {
    return '';
  }

  return (
    <>
      {' (Entity type: '}
      <span className="is-italic has-text-weight-bold">
        {getLabelForValue(entityType)}
      </span>
      {') '}
    </>
  );
};

export const useConsumerIdOptions = (supplierId: string) => {
  const [consumerUserEntityLinks, setConsumerUserEntityLinks] = useState<
    Array<any>
  >([]);

  const consumerIdOptions = sanitizeConsumerIdOptions(
    supplierId,
    consumerUserEntityLinks
  ).map((userEntityLink) => {
    return {
      label: (
        <span>
          {userEntityLink.entity_name}
          {getEntityTypeDescription(userEntityLink.entity_type)}
        </span>
      ),
      region: userEntityLink.region,
      state: userEntityLink.state,
      value: userEntityLink.entity_id,
    };
  });
  consumerIdOptions.push({ label: 'Other business/person', value: 'new' });

  return { consumerIdOptions, setConsumerUserEntityLinks };
};

export const getConsumerUserEntityLinks = ({
  accessToken,
  email,
  errorCallback,
  successCallback,
}) => {
  const emailValidationAPI = api('user_exists_email', accessToken);

  emailValidationAPI.checkEmailValid(
    email,
    (result) => successCallback(get(result, 'data.data.attributes', {})),
    (error) => {
      console.error(error);
      errorCallback();
    }
  );
};
