type Item = { [key: string]: any };

export const removeDuplicatesByKey = <T extends Item>(
  array: T[],
  key: keyof T = 'id'
): T[] => {
  const seen = new Set<any>();
  return array.filter((item) => {
    if (seen.has(item[key])) {
      return false;
    }
    seen.add(item[key]);
    return true;
  });
};
