import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'modules/shared/components/inputs/Button';

import { loadTeam } from '../actions';

import { formatMoney } from 'utils/formatting';
import { calculateMonthlyCost } from 'utils/subscriptionsCalculate';

import styles from './css/LicenceInfo.css';

var LicenceInfo = createClass({
  _totalApplications() {
    const { currentPlan } = this.props;
    let applicationTally = currentPlan.application_tally;

    if (applicationTally === null || applicationTally === undefined) return '';
    if (currentPlan.plan_no === 0) return applicationTally;

    return applicationTally + ' of ' + currentPlan.application_max;
  },

  _totalConnections() {
    const { currentPlan } = this.props;
    let connectionTally = currentPlan.connection_tally;

    if (connectionTally === null || connectionTally === undefined) return '';
    if (currentPlan.plan_no === 0) return connectionTally;

    return connectionTally + ' of ' + currentPlan.connection_max;
  },

  _monthlyCost() {
    const { currentPlan } = this.props;
    let perUserCharge = currentPlan.per_user_charge;

    if (perUserCharge === null || perUserCharge === undefined) return '';

    let cost = calculateMonthlyCost(
      currentPlan.plan_no,
      currentPlan.number_of_users,
      currentPlan.per_user_charge
    );

    if (cost === null || cost === undefined) return '-';

    let formattedCost = '$' + formatMoney(cost, 2);
    if (cost === 0) return formattedCost;

    return formattedCost + ' +GST';
  },

  render: function() {
    const { currentPlan } = this.props;

    return (
      <div>
        <article className={styles.block}>
          <div className={styles.liner}>
            <h2 className={styles.header}>Licence info</h2>
            <section className={styles.main_text}>
              <div className={styles.info_block}>
                <div className={styles.block_title}>Current Plan</div>
                <div className={styles.num_users}>{currentPlan.name}</div>
              </div>
              <div className={styles.info_block}>
                <div className={styles.block_title}>Total users</div>
                <div className={styles.num_users}>
                  {currentPlan.number_of_users}
                </div>
              </div>
              <div className={styles.info_block}>
                <div className={styles.block_title}>Applications Sent</div>
                <div className={styles.num_users}>
                  {this._totalApplications()}
                </div>
              </div>
              <div className={styles.info_block}>
                <div className={styles.block_title}>Total Connections</div>
                <div className={styles.num_users}>
                  {this._totalConnections()}
                </div>
              </div>
              <div className={styles.info_block}>
                <div className={styles.block_title}>Monthly</div>
                <div className={styles.num_users}>{this._monthlyCost()}</div>
              </div>
            </section>
          </div>
        </article>
      </div>
    );
  },
});

module.exports = connect((state, ownProps) => {
  let currentPricingPlan = state.pricing_plans.current_pricing_plan || {},
    attributes = currentPricingPlan.attributes || {};

  return {
    currentPlan: attributes,
  };
})(LicenceInfo);
