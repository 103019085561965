import styled from 'styled-components';

export const Input = styled.input`
  position: absolute;
  border: none;
  text-align: center;
  background-color: transparent !important;
  outline: none;
  width: 32px;
  height: 100%;
  padding: 0;
  top: 0;
  bottom: 0;
`;
