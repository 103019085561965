import get from 'lodash.get';
import BankNumber from 'modules/authorisation/components/ReviewPaperless/BankNumber';
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './css/Section.css';

class ReviewPaperless extends Component {
  renderTerms() {
    const { terms } = this.props;
    return (
      <div className={styles.content}>
        <span className={styles.terms}>{terms}</span>
      </div>
    );
  }

  render() {
    const { paperless, region } = this.props;

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.content}>
            <div className={styles.half_width}>
              <LabeledInformation
                key={'first_name'}
                label={'First name'}
                data={paperless.attributes.first_name}
                noHumanize
                showEmpty={true}
              />
            </div>
            <div className={styles.half_width}>
              <LabeledInformation
                key={'middle_name'}
                label={'Middle name'}
                data={paperless.attributes.middle_name}
                noHumanize
                showEmpty={true}
              />
            </div>
            <div className={styles.half_width}>
              <LabeledInformation
                key={'last_name'}
                label={'Last name'}
                data={paperless.attributes.last_name}
                noHumanize
                showEmpty={true}
              />
            </div>
            <div className={styles.half_width}>
              <LabeledInformation
                key={'debit_account_name'}
                label={'Account to be debited'}
                data={paperless.attributes.debit_account_name}
                noHumanize
                showEmpty={true}
              />
            </div>
            <BankNumber
              bankNumber={get(paperless, 'attributes.bank_number')}
              region={region}
            />
            <div className={styles.half_width}>
              <LabeledInformation
                key={'bank_account_number'}
                label={'Bank account number'}
                data={paperless.attributes.bank_account_number}
                noHumanize
                showEmpty={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.content}>{this.renderTerms()}</div>
        </div>
      </section>
    );
  }
}

const moduleName = 'paperless';

export default connect((state) => {
  const authorisation = state.authorisation.data;
  const addonRule = authorisation.addon_rules.find(
    (rule) => rule.attributes.addon_module_name === moduleName
  );
  const addonVersion = Object.prototype.hasOwnProperty.call(
    addonRule.attributes.addon_version,
    'data'
  )
    ? addonRule.attributes.addon_version.data.attributes
    : addonRule.attributes.addon_version;
  const terms = addonVersion.config.terms;

  return {
    paperless: state.authorisation.paperless,
    paymentApproved: state.authorisation.paymentApproved,
    region: get(authorisation, 'application.attributes.region'),
    terms,
  };
})(ReviewPaperless);
