import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createContext } from 'react';
import {
  changeNodeAtPath,
  removeNodeAtPath,
  walk,
  find,
} from 'react-sortable-tree';
import intersection from 'lodash.intersection';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';

import basicSort from 'utils/basicSort';
import { loadConfig } from 'modules/addons/entity_structure/actions';
import {
  CustomerViewContextType,
  TreeData,
  Tier3,
  Group,
  PreviewData,
  ConfigStatus,
  ActionStatus,
} from './types';
import { getUnusedT3s } from './utils';

import api from 'api';
import AddonConfigModel from 'models/AddonConfigModel';
import { processError } from 'utils';

const channels = ['RepChannel', 'WebsiteButton', 'Location'];

const actionSnackbarMap = {
  draft: { message: 'Draft saved', variant: 'info' as const },
  save: { message: 'Configuration saved', variant: 'info' as const },
  on: { message: 'Customer view turned on', variant: 'success' as const },
  off: { message: 'Customer view turned off', variant: 'info' as const },
};

const defaultContextValues = {
  t3s: [],
  t3Name: 'branch',
  treeData: [],
  setTreeData: () => {},
  addNode: () => {},
  removeGroup: () => {},
  editPath: [],
  setEditPath: () => {},
  editGroup: () => {},
  isDirty: false,
  isSaveDisabled: false,
  isTurnOnDisabled: false,
  isAddDisabled: false,

  isPreviewShown: false,
  showPreview: () => {},
  hidePreview: () => {},
  previewData: [],

  saveDraft: () => {},
  save: () => {},
  turnOn: () => {},
  turnOff: () => {},

  isCustomerViewEnabled: false,

  configStatus: ConfigStatus.Absent,
  actionStatus: ActionStatus.None,

  showEmptyRootAlert: false,
  showEmptyGroupAlert: false,

  history: [],
};

export const CustomerViewContext =
  createContext<CustomerViewContextType>(defaultContextValues);

type CustomerViewContextProviderProps = {
  children: React.ReactNode;
  dispatch: any;

  entityId: string;
  isCustomerViewEnabled: boolean;
};

const mapStateToProps = (state) => ({
  entityId: state.current_user.current_entity.id,
  isCustomerViewEnabled:
    state.current_user.current_entity.attributes.one_acv_enabled,
});

export const CustomerViewContextProvider = connect(mapStateToProps)((
  props: CustomerViewContextProviderProps
) => {
  const { enqueueSnackbar } = useSnackbar();

  const { children, dispatch, entityId, isCustomerViewEnabled } = props;

  const [hqName, setHqName] = useState('');

  const [initialTreeData, setInitialTreeData] = useState<TreeData>([]);
  const [treeData, setTreeData] = useState<TreeData>([]);
  const [t3s, setT3s] = useState<Tier3[]>([]);
  const [t3Name, setT3Name] = useState('branch');
  const [editPath, setEditPath] = useState<number[]>([]);
  const [isPreviewShown, setPreviewShown] = useState(false);

  const [configId, setConfigId] = useState('');
  const [configStatus, setConfigStatus] = useState<ConfigStatus>(
    ConfigStatus.Absent
  );
  const [actionStatus, setActionStatus] = useState<ActionStatus>(
    ActionStatus.None
  );

  const [showEmptyRootAlert, setEmptyRootAlert] = useState(false);
  const [showEmptyGroupAlert, setEmptyGroupAlert] = useState(false);
  const [hasGroupWithEmptyName, setGroupWithEmptyName] = useState(false);

  const [history, setHistory] = useState<string[]>([]);

  const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex;

  const processConfig = useCallback(async (config) => {
    if (config.cfg?.tier_3) {
      setT3Name(config.cfg.tier_3);
    }

    setHqName(config.data.title);

    try {
      const data = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: ['one_acv_module'],
        entityId,
        accessToken: '',
      });

      if (data && data.length) {
        const config = data[0];
        setConfigId(config.id);

        const attributes =
          config.data.attributes.history_version.data[0].attributes;

        const treeData = attributes.config;

        if (attributes.is_draft) {
          setConfigStatus(ConfigStatus.Draft);
        } else {
          setConfigStatus(
            attributes.active ? ConfigStatus.On : ConfigStatus.Off
          );
        }

        setTreeData(treeData);
        setInitialTreeData(treeData);
      } else {
        // Customer view is not configured yet.
        // Show a default config.
        const fallbackTreeData: TreeData = [
          {
            expanded: true,
            children: [{ title: '', type: 'group' }],
            type: 'hq',
          },
        ];
        setTreeData(fallbackTreeData);
        setInitialTreeData(fallbackTreeData);
        setEditPath([0, 1]);
      }
    } catch (error) {
      console.log(error);
    }

    // Array of tier 3s for which no channels are decentralised
    let t3s: Tier3[] = [];

    // Array of tier 3s for which any of the channels are decentralised
    // at the region level or branch level
    let channelDecentralisedT3s: Tier3[] = [];

    config.data.children.forEach((region) => {
      // Channels decentralised at the region level
      const decentralisedChannels = intersection(channels, region.d_list);

      // Is any of the three channels decentralised
      const isChannelDecentralised = decentralisedChannels.length > 0;

      const regionT3s = region.children.map((branch) => ({
        entityId: branch.id,
        title: branch.title,
        isChannelDecentralised,
        type: 't3',
      }));

      if (isChannelDecentralised) {
        channelDecentralisedT3s = [...channelDecentralisedT3s, ...regionT3s];
      } else {
        t3s = [...t3s, ...regionT3s];
      }
    });

    setT3s([
      ...basicSort(t3s, 'title'),
      ...basicSort(channelDecentralisedT3s, 'title'),
    ]);
  }, []);

  const loadHistory = async () => {
    const entityApi = api('entities');
    try {
      const response = await entityApi.loadCustomerViewHistory(entityId);
      setHistory(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(loadConfig(processConfig));
    loadHistory();
  }, []);

  const refresh = () => {
    dispatch(loadConfig(processConfig));
    loadHistory();
  };

  const addNode = () => {
    const newTreeData = [
      {
        expanded: true as const,
        children: [
          ...treeData[0].children,
          { title: '', type: 'group' as const },
        ],
        type: 'hq' as const,
      },
    ];
    setTreeData(newTreeData);
    find({
      treeData: newTreeData,
      getNodeKey,
      searchMethod: ({ node, path }) => {
        if (!node.title) {
          setEditPath(path);
        }
      },
    });
  };

  const removeGroup = (path: number[]) => {
    setTreeData(
      removeNodeAtPath({
        treeData,
        path,
        getNodeKey,
      })
    );
  };

  const editGroup = (group: Group) => {
    setTreeData(
      changeNodeAtPath({
        treeData,
        path: editPath,
        getNodeKey,
        newNode: group,
      })
    );
    setEditPath([]);
  };

  const showPreview = () => setPreviewShown(true);
  const hidePreview = () => setPreviewShown(false);

  let previewData: PreviewData = [];

  if (treeData[0] && treeData[0].children) {
    treeData[0].children.forEach((group, groupIndex) => {
      const id = uuidv4();
      const groupItem = {
        id: uuidv4(),
        entity_id: uuidv4(),
        trading_name: group.title,
        parent_id: null,
        type: 'Region' as const,
      };
      previewData.push(groupItem);
      if (group.children) {
        previewData = [
          ...previewData,
          ...group.children.map((t3) => ({
            id: uuidv4(),
            entity_id: uuidv4(),
            trading_name: t3.title,
            parent_id: String(groupItem.entity_id),
            type: 'Branch' as const,
          })),
        ];
      }
    });
  }

  const checkGroupWithEmptyName = ({
    shouldEnableEdit,
  }: {
    shouldEnableEdit: boolean;
  }) => {
    let hasGroupWithEmptyName = false;
    walk({
      treeData,
      getNodeKey,
      callback: ({ node, path }) => {
        if (node.type === 'group' && !node.title) {
          if (shouldEnableEdit) {
            setEditPath(path);
          }
          hasGroupWithEmptyName = true;
        }
      },
    });
    return hasGroupWithEmptyName;
  };

  const hasEmptyGroups = treeData[0]?.children.some(
    (group) => !group.children || group.children.length === 0
  );

  const isRootEmpty =
    !treeData || !treeData[0] || treeData[0].children.length === 0;

  useEffect(() => {
    if (!isRootEmpty) {
      setEmptyRootAlert(false);
    }
  }, [isRootEmpty]);

  useEffect(() => {
    if (!hasEmptyGroups) {
      setEmptyGroupAlert(false);
    }
  }, [hasEmptyGroups]);

  useEffect(() => {
    setGroupWithEmptyName(checkGroupWithEmptyName({ shouldEnableEdit: false }));
  }, [treeData]);

  const update = async (action: 'draft' | 'save' | 'on' | 'off') => {
    const payload = {
      name: '1ACV Module',
      addon_module_name: 'one_acv_module',
      active:
        action === 'on' ||
        (action === 'save' && configStatus === ConfigStatus.On)
          ? true
          : false,
      is_draft: action === 'draft' ? true : false,
      config: treeData,
      addon_config_id: configId || undefined,
    };

    const addonVersionsApi = api('addon_versions', '', entityId);

    if (action === 'draft') {
      setActionStatus(ActionStatus.SavingDraft);
    } else if (action === 'save') {
      setActionStatus(ActionStatus.Saving);
    } else if (action === 'on') {
      setActionStatus(ActionStatus.TurningOn);
    } else if (action === 'off') {
      setActionStatus(ActionStatus.TurningOff);
    }

    try {
      await addonVersionsApi.createAddonVersion(payload, 'one_acv_module');
      const actionSnackbar = actionSnackbarMap[action];
      enqueueSnackbar(actionSnackbar.message, {
        variant: actionSnackbar.variant,
      });
      refresh();
    } catch (error: any) {
      const { errorMessage } = processError(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setActionStatus(ActionStatus.None);
    }
  };

  const isValid = () => {
    if (isRootEmpty) {
      setEmptyRootAlert(true);
      return false;
    } else if (hasEmptyGroups) {
      setEmptyGroupAlert(true);
      return false;
    }
    return true;
  };

  const turnOn = async () => {
    if (!checkGroupWithEmptyName({ shouldEnableEdit: true })) {
      if (isValid()) {
        update('on');
      }
    }
  };

  const saveDraft = async () => update('draft');

  const turnOff = () => update('off');

  const save = () => {
    if (!checkGroupWithEmptyName({ shouldEnableEdit: true })) {
      if (isValid()) {
        update('save');
      }
    }
  };

  const isDirty = !isEqual(treeData, initialTreeData);

  return (
    <CustomerViewContext.Provider
      value={{
        t3s: getUnusedT3s(treeData, t3s),
        t3Name,
        treeData: treeData.map((hqNode) => ({ ...hqNode, title: hqName })),
        setTreeData,
        addNode,
        removeGroup,
        editPath,
        setEditPath,
        editGroup,
        isDirty,
        isPreviewShown,
        showPreview,
        hidePreview,
        previewData,
        isSaveDisabled:
          !isDirty ||
          (configStatus === ConfigStatus.On &&
            (isRootEmpty || hasEmptyGroups)) ||
          !isEmpty(editPath),
        isTurnOnDisabled: isRootEmpty || hasEmptyGroups || !isEmpty(editPath),
        isAddDisabled: hasGroupWithEmptyName,

        saveDraft,
        save,
        turnOn,
        turnOff,

        isCustomerViewEnabled,

        configStatus,
        actionStatus,

        showEmptyRootAlert,
        showEmptyGroupAlert,

        history,
      }}
    >
      {children}
    </CustomerViewContext.Provider>
  );
});
