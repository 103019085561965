// These are the attributes to create a signature when 1CAF rule is not
// applicable to an entity
const buildNoIdentificationSignatureAttributes = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): {
  first_name: string;
  identification_type: string;
  last_name: string;
  no_identification_reason: string;
} => {
  return {
    first_name: firstName,
    identification_type: 'no_identification',
    last_name: lastName,
    no_identification_reason: 'not_required',
  };
};

export default buildNoIdentificationSignatureAttributes;
