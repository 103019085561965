import axios from './axios';
import { transformRequest, transformResponse } from 'utils/axios_transforms';

export default class WebhookSchemas {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'webhook_schemas';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  getSchemas() {
    return this.axios.get(`${this.path}`);
  }
}
