import get from 'lodash.get';
import AddonAnswer from 'modules/new-applications/components/AddonAnswer';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import FileWidgetSmall from 'modules/shared/components/widgets/interactive/FileWidgetSmall';
import PDFLogo from 'modules/shared/components/widgets/static/PDFLogo';
import React from 'react';
import isBlank from 'utils/isBlank';

export function CompleteWidget(props) {
  const { file, widgetKey } = props;
  const url = (file || {}).url;

  if (isBlank(url)) {
    return null;
  }

  return <PDFLogo alt="PDF Upload" key={widgetKey} logoStyle="small" />;
}

function AttachDocument(props) {
  const { application, answers, currentUser, onFetchApplicationRecord } = props;
  const { file } = answers;
  const { isLoading, setIsLoading } = useIsLoadingState();

  if (!file) {
    return null;
  }

  const onSuccessCallback = () => {
    onFetchApplicationRecord(() => setIsLoading(false));
  };

  const onUpload = (data) => {
    setIsLoading(true);

    answers.update({
      attributes: {
        file: data,
      },
      currentUser,
      onSuccessCallback,
    });
  };

  const addonRule = get(application, 'addonRules', []).find(
    ({ addonModuleName }) => addonModuleName === 'additional'
  );
  const addonConfig = get(addonRule, 'addonVersion.config', {});
  const { attachDocument, attach_document } = addonConfig;
  const { question } = attachDocument || attach_document;

  return (
    <FixedContent header="Attach document">
      {question && <p>{question}</p>}
      <FileWidgetSmall
        accept={['.pdf', 'image/jpeg', 'image/png', 'image/jpg'].join(',')}
        completeWidget={
          <CompleteWidget file={file} widgetKey="additional-attach-document" />
        }
        filename="s.pdf"
        id="additional-attach-document"
        key="additional-attach-document"
        loading={isLoading}
        text="Drag and drop"
        viewSrc={file}
        handleFileCallback={onUpload}
        disabled={application.isFated}
      />
    </FixedContent>
  );
}

export default function Additional(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const addonRule = application.additionalsAddonRule;
  const answers = application.additionalAddonAnswers;

  return (
    <ContentWithFooter footer={get(addonRule, 'formattedRuleLabel')}>
      <FixedContent header="Additional questions">
        <AddonAnswer
          answers={get(answers, 'answers.results', [])}
          components={addonRule.additionalComponents}
        />
      </FixedContent>
      <AttachDocument
        answers={answers}
        application={application}
        currentUser={currentUser}
        onFetchApplicationRecord={onFetchApplicationRecord}
      />
    </ContentWithFooter>
  );
}
