import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'modules/dashboard/containers/components/Modal';
import React, { ReactElement } from 'react';

import { ButtonWrapper, List, Title } from './styles';

interface Props {
  affectedPages: string[];
  closeModal: () => void;
  handleSubmit: () => void;
}

function SignatorySwitchModal({
  affectedPages,
  closeModal,
  handleSubmit,
}: Props): ReactElement {
  const pages = {
    cards: 'Cards',
    paperless: 'Direct debit',
    guarantors: 'Guarantor details',
    signatory: 'Terms and Conditions',
  };

  return (
    <Modal
      content={
        <div>
          <Title className="has-text-danger has-text-centered mb-4">
            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} /> Warning!
            Your data will be lost
          </Title>
          <div>
            You are changing the applicant signatory that will affect the
            following pages:
            <List>
              {affectedPages.map((page) => (
                <li key={`affected-page-${page}`}>{pages[page]}</li>
              ))}
            </List>
            The data on these pages will be removed, please revisit and fill in
            the forms on these pages again.
          </div>
          <ButtonWrapper className="has-text-centered">
            <button
              type="button"
              className="button is-danger is-outlined is-rounded"
              onClick={() => {
                handleSubmit();
                closeModal();
              }}
            >
              Yes, I understand
            </button>
            <button
              type="button"
              className="button is-primary is-rounded"
              onClick={closeModal}
            >
              Cancel
            </button>
          </ButtonWrapper>
        </div>
      }
      onClose={closeModal}
      zIndex={10000000}
      width={500}
    />
  );
}

export default SignatorySwitchModal;
