import IdentificationIcon from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/IdentificationIcon';
import React, { Fragment } from 'react';

const PDFWidget = ({
  identificationImageUrl,
}: {
  identificationImageUrl: string;
}) => {
  const onClick = () => {
    const win: any = window.open('', 'pdf_window');
    win.open(identificationImageUrl, 'pdf_window');
  };

  return (
    <Fragment>
      <IdentificationIcon
        canAccess={true}
        onIconClick={onClick}
        identificationCheckStatus={null}
      />
      <div className="has-text-left">
        There was an error loading a preview of the PDF. Please click on the
        icon to open in a new tab.
      </div>
    </Fragment>
  );
};

export default PDFWidget;
