export const COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_START =
  'COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_START';
export const COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_ERROR =
  'COB_TRADEACCOUNT_LOAD_SUPPLIER_ENTITY_ERROR';

export const COB_TRADEACCOUNT_LOAD_APPLICATION_START =
  'COB_TRADEACCOUNT_LOAD_APPLICATION_START';
export const COB_TRADEACCOUNT_LOAD_APPLICATION_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_APPLICATION_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_APPLICATION_ERROR =
  'COB_TRADEACCOUNT_LOAD_APPLICATION_ERROR';

export const COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START =
  'COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_START';
export const COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR =
  'COB_TRADEACCOUNT_LOAD_USER_ENTITY_DETAILS_ERROR';

export const COB_TRADEACCOUNT_CLEAR_DATA = 'COB_TRADEACCOUNT_CLEAR_DATA';

export const COB_TRADEACCOUNT_LOAD_SIGNATURE =
  'COB_TRADEACCOUNT_LOAD_SIGNATURE';

export const COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_START =
  'COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_START';
export const COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_ERROR =
  'COB_TRADEACCOUNT_LOAD_CURRENT_ENTITY_ERROR';

export const COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_CURRENT_PEOPLE_SUCCESS';

export const COB_TRADEACCOUNT_SET_START_COMPLETE =
  'COB_TRADEACCOUNT_SET_START_COMPLETE';

export const COB_TRADEACCOUNT_LOAD_APPLICATIONS_START =
  'COB_TRADEACCOUNT_LOAD_APPLICATIONS_START';
export const COB_TRADEACCOUNT_LOAD_APPLICATIONS_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_APPLICATIONS_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_APPLICATIONS_ERROR =
  'COB_TRADEACCOUNT_LOAD_APPLICATIONS_ERROR';

export const COB_TRADEACCOUNT_SET_PEOPLE_FIRSTNAME =
  'COB_TRADEACCOUNT_SET_PEOPLE_FIRSTNAME';
export const COB_TRADEACCOUNT_SET_PEOPLE_LASTNAME =
  'COB_TRADEACCOUNT_SET_PEOPLE_LASTNAME';

export const COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_START =
  'COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_START';
export const COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR =
  'COB_TRADEACCOUNT_LOAD_WEBSITEBUTTON_SUPPLIER_ERROR';
export const COB_TRADEACCOUNT_LOAD_ADDON_RULES =
  'COB_TRADEACCOUNT_LOAD_ADDON_RULES';

export const COB_TRADEACCOUNT_SET_CARDHOLDERS_COUNT =
  'COB_TRADEACCOUNT_SET_CARDHOLDERS_COUNT';
export const COB_TRADEACCOUNT_SET_SECTIONS_TO_CLEAR =
  'COB_TRADEACCOUNT_SET_SECTIONS_TO_CLEAR';
export const COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_START =
  'COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_START';
export const COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_SUCCESS =
  'COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_SUCCESS';
export const COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR =
  'COB_TRADEACCOUNT_SAVE_CARDHOLDERS_COUNT_ERROR';

export const COB_TRADEACCOUNT_LOAD_AUTHORISATION =
  'COB_TRADEACCOUNT_LOAD_AUTHORISATION';
export const COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION =
  'COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION';
export const COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION_ERROR =
  'COB_TRADEACCOUNT_LOAD_ALL_AUTHORISATION_ERROR';
export const COB_TRADEACCOUNT_LOAD_AML_CHECK =
  'COB_TRADEACCOUNT_LOAD_AML_CHECK';
export const COB_TRADEACCOUNT_LOAD_ANTI_FRAUD =
  'COB_TRADEACCOUNT_LOAD_ANTI_FRAUD';

export const COB_TRADEACCOUNT_SET_COLOR_PALETTE =
  'COB_TRADEACCOUNT_SET_COLOR_PALETTE';
export const COB_TRADEACCOUNT_LOAD_COLOR_PALETTE =
  'COB_TRADEACCOUNT_LOAD_COLOR_PALETTE';
export const COB_TRADEACCOUNT_LOAD_APPLICABLE_LEGAL_TYPES =
  'COB_TRADEACCOUNT_LOAD_APPLICABLE_LEGAL_TYPES';

export const COB_TRADEACCOUNT_LOAD_CURRENT_TRADING_NAMES_SUCCESS =
  'COB_TRADEACCOUNT_LOAD_CURRENT_TRADING_NAMES_SUCCESS';
export const COB_TRADEACCOUNT_LOAD_CONSUMER_TRADING_NAMES =
  'COB_TRADEACCOUNT_LOAD_CONSUMER_TRADING_NAMES';

export const COB_TRADEACCOUNT_SET_SELECTED_TRADING_NAMES =
  'COB_TRADEACCOUNT_SET_SELECTED_TRADING_NAMES';
export const COB_TRADEACCOUNT_SET_TRADING_NAMES =
  'COB_TRADEACCOUNT_SET_TRADING_NAMES';

export const COB_SECTION_SET_IS_BUSINESS_OVERVIEW_LOADED =
  'COB_SECTION_SET_IS_BUSINESS_OVERVIEW_LOADED';

export const COB_TRADEACCOUNT_LOAD_1CAF_TYPE =
  'COB_TRADEACCOUNT_LOAD_1CAF_TYPE';

export const COB_SECTION_TOGGLE_IS_APPLICANT_REMOVE_AUTHORISATION =
  'COB_SECTION_TOGGLE_IS_APPLICANT_REMOVE_AUTHORISATION';
