export const AUTO_DECISIONS_SET_COMPANYS = 'AUTO_DECISIONS_SET_COMPANYS';
export const AUTO_DECISIONS_SET_TIME_IN_BUSINESS =
  'AUTO_DECISIONS_SET_TIME_IN_BUSINESS';
export const AUTO_DECISIONS_SET_VALUE = 'AUTO_DECISIONS_SET_VALUE';
export const AUTO_DECISIONS_CREATE_RULESET = 'AUTO_DECISIONS_CREATE_RULESET';
export const AUTO_DECISIONS_CREATE_RULESET_SUCCESS =
  'AUTO_DECISIONS_CREATE_RULESET_SUCCESS';
export const AUTO_DECISIONS_CREATE_RULESET_ERROR =
  'AUTO_DECISIONS_CREATE_RULESET_ERROR';
export const AUTO_DECISIONS_UPDATE_RULESET = 'AUTO_DECISIONS_UPDATE_RULESET';
export const AUTO_DECISIONS_UPDATE_RULESET_SUCCESS =
  'AUTO_DECISIONS_UPDATE_RULESET_SUCCESS';
export const AUTO_DECISIONS_UPDATE_RULESET_ERROR =
  'AUTO_DECISIONS_UPDATE_RULESET_ERROR';
export const AUTO_DECISIONS_SET_RULESET = 'AUTO_DECISIONS_SET_RULESET';
export const AUTO_DECISIONS_RESET_CURRENT = 'AUTO_DECISIONS_RESET_CURRENT';
