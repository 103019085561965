/* eslint-disable max-classes-per-file */
import get from 'lodash.get';

/**
 * formatMoney(number, n, x, s, c)
 *
 * @param number number: number to format
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
/* eslint-disable max-params */
// Fix maximum number of params
export function formatMoney(number, n, x, s, c) {
  const DEFAULT_X = 3;

  /* eslint-disable prefer-template */
  /* eslint-disable no-bitwise */
  // Fix `re` assignment to use string template
  // Fix `~~n` bitwise notation
  const re =
    '\\d(?=(\\d{' + (x || DEFAULT_X) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  const num = number.toFixed(Math.max(0, ~~n));
  /* eslint-disable prefer-template */
  /* eslint-enable no-bitwise */

  return (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ',')
  );
}
/* eslint-enable max-params */
export function displayEmpty(value, replacement = '-') {
  if (value === null || typeof value === 'undefined') return replacement;
  if (value.length === 0) return replacement;

  return value;
}

export function capitalize(str) {
  return str.replace(/\b[a-z]/g, (f) => f.toUpperCase());
}

export function capitalizeSentence(str) {
  if (typeof str === 'string') {
    return str
      .toLowerCase()
      .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
  }
  return '';
}

export function ynToYesNo(value) {
  const ynMapping = { n: 'NO', y: 'YES' };
  const currentValue = value || '';

  return displayEmpty(ynMapping[currentValue.toLowerCase()]);
}

export function getFormattedAddress(address = {}) {
  let addressString = '';

  const keysWithComma = ['address_prefix', 'street_details'];

  const addressLinesKey = Object.keys(address);
  addressLinesKey.forEach((key, index) => {
    addressString =
      addressString +
      address[key] +
      `${keysWithComma.includes(key) ? ', ' : ' '}`;
  });

  return addressString;
}

export function getFormattedIndividualName(individual = {}) {
  if (!!!Object.keys(individual)) return null;

  const { family_name, first_given_name, other_given_name } = individual;
  const formattedOtherGivenName = other_given_name
    ? ` ${other_given_name}`
    : '';

  return `${family_name}, ${first_given_name}${formattedOtherGivenName}`;
}

export function getShareholderAddress(shareholderData) {
  const rawOrganisationAddress = get(
    shareholderData,
    'organisation_share_holder'
  );

  if (!!rawOrganisationAddress)
    return getFormattedAddress(rawOrganisationAddress.address_lines || {});

  const rawIndividualAddress = get(shareholderData, 'individual_share_holder');
  return getFormattedAddress(rawIndividualAddress.address_lines || {});
}

export function getShareholderName(shareholderData) {
  const organisationShareholderName = get(
    shareholderData,
    'organisation_share_holder.organisation_name',
    ''
  );

  if (!!organisationShareholderName) return organisationShareholderName;

  const rawIndividualShareholderName = get(
    shareholderData,
    'individual_share_holder.individual_name',
    {}
  );
  const individualShareholderName = getFormattedIndividualName(
    rawIndividualShareholderName
  );

  return individualShareholderName;
}
/* eslint-enable max-classes-per-file */
