export const GREY = '#e6e6e6';

export const DDS_SCORE_RANGE = {
  A: {
    color: '#245d2c',
    max: 799,
    min: 587,
    probability_of_late_payment: 1.04,
    riskLevel: 'Minimal',
  },
  B: {
    color: '#337037',
    max: 586,
    min: 546,
    probability_of_late_payment: 1.94,
    riskLevel: 'Very low',
  },
  C: {
    color: '#4d9048',
    max: 545,
    min: 495,
    probability_of_late_payment: 4.15,
    riskLevel: 'Low',
  },
  D: {
    color: '#60a955',
    max: 494,
    min: 422,
    probability_of_late_payment: 12.21,
    riskLevel: 'Average',
  },
  E: {
    color: '#e66c1a',
    max: 421,
    min: 387,
    probability_of_late_payment: 23.47,
    riskLevel: 'Moderate',
  },
  F: {
    color: '#c93429',
    max: 386,
    min: 340,
    probability_of_late_payment: 37.9,
    riskLevel: 'High',
  },
  G: {
    color: '#ad1a17',
    max: 339,
    min: 286,
    probability_of_late_payment: 59.63,
    riskLevel: 'Very High',
  },
  H: {
    color: '#910000',
    max: 285,
    min: 101,
    probability_of_late_payment: 78.28,
    riskLevel: 'Severe',
  },
};

export const CONSUMER_RISK_SCORE = {
  A: {
    color: '#2a6d36',
    min: 746,
    probability_of_default_max: 2,
  },
  B: {
    color: '#408545',
    max: 745,
    min: 716,
    probability_of_default_max: 3,
    probability_of_default_min: 2,
  },
  C: {
    color: '#75c369',
    max: 715,
    min: 676,
    probability_of_default_max: 4,
    probability_of_default_min: 3,
  },
  D: {
    color: '#ffd24b',
    max: 675,
    min: 632.6,
    probability_of_default_max: 5,
    probability_of_default_min: 4,
  },
  E: {
    color: '#ffbc40',
    max: 632.5,
    min: 607.6,
    probability_of_default_max: 6,
    probability_of_default_min: 5,
  },
  F: {
    color: '#ff9d2c',
    max: 607.5,
    min: 527.6,
    probability_of_default_max: 9,
    probability_of_default_min: 6,
  },
  G: {
    color: '#ff841f',
    max: 527.5,
    min: 436,
    probability_of_default_max: 16,
    probability_of_default_min: 9,
  },
  H: {
    color: '#ef5f2a',
    max: 435,
    min: 81,
    probability_of_default_max: 70,
    probability_of_default_min: 16,
  },
  I: {
    color: '#e24334',
    max: 80,
    min: -514,
    probability_of_default_max: 99,
    probability_of_default_min: 70,
  },
  J: {
    color: '#aa0000',
    max: -515,
    probability_of_default_min: 99,
  },
};

export const CCR_PURPOSE = ['Commercial', 'Consumer'];
