import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection11 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>11.</span> Liability
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>11.1</span>{' '}
          <strong>Limitation of liability</strong>: Except to the extent that it
          is unlawful to exclude such responsibility and liability:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> the Service and access
          to the Platform is provided to the Supplier without any warranties,
          representations, or guarantees of any kind (whether express, implied,
          or statutory) except as expressly stated in these Terms;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> 1Centre will not be
          liable for any loss of profits, loss of revenue, loss of savings or
          for any indirect, consequential, special, exemplary, or incidental
          damages suffered by the Supplier as a result of these Terms, the
          Service or the Platform;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> 1Centre will not be
          responsible or liable for:
        </p>
        <p className={styles.list_type_3}>
          <span className={styles.copy_number}>i.</span> any incorrect or
          fraudulent statement, detail or information provided by or on behalf
          of a Business Customer (or any guarantor of a Business Customer) in a
          Trade Account Application or otherwise or any failure by a Business
          Customer (or any guarantor of a Business Customer) to correctly
          complete a Trade Account Application;
        </p>
        <p className={styles.list_type_3}>
          <span className={styles.copy_number}>ii.</span> any breach by a
          Business Customer (or any guarantor of a Business Customer) of the
          Supplier Terms of Trade;
        </p>
        <p className={styles.list_type_3}>
          <span className={styles.copy_number}>iii.</span> any default by a
          Business Customer (or any guarantor of a Business Customer) to satisfy
          its obligation to repay credit extended by the Supplier;
        </p>
        <p className={styles.list_type_3}>
          <span className={styles.copy_number}>iv.</span> the enforceability or
          non-enforceability of the Supplier Terms of Trade, or any guarantee
          given, in relation to a Business Customer;
        </p>
        <p className={styles.list_type_3}>
          <span className={styles.copy_number}>v.</span> any breach by a
          Business Customer of the Business Customer Terms; or
        </p>
        <p className={styles.list_type_3}>
          <span className={styles.copy_number}>vi.</span> any error, act or
          omission of a Credit Reporting Agency or Trade Reference Checking
          Agency, including in performing any aspect of the Services;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>d.</span> without limiting
          sub-clauses (b) and (c) above, the liability of 1Centre to the
          Supplier arising out of any and all claims whatsoever (irrespective of
          the cause of action) will not in any circumstances exceed in aggregate
          the total amount of the sums paid by the Supplier to 1Centre for the
          Service in respect of which 1Centre is in default during the 6 month
          period prior to the most recent event giving rise to the liability.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>11.2</span>{' '}
          <strong>Indemnity</strong>: The Supplier indemnifies 1Centre against
          any cost or liability 1Centre incurs, pays or has to pay in dealing
          with any claim against it for loss or damage by a Business Customer,
          Credit Reporting Agency or other third party to the extent caused by
          the Supplier’s breach of these Terms.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>11.3</span>{' '}
          <strong>Time limit for claims</strong>: No claim arising in relation
          to this agreement, regardless of form, may be brought by either party
          more than six years after it becomes aware of the cause of action on
          which the claim is based.
        </p>
      </div>
    );
  }
}
