/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

/* Import CSS */
import styles from './css/Review.css';

/* Import actions */
import { loadGuarantorInfo, guarantorLogin } from '../actions';

/* Import components */
import Button from 'modules/shared/components/inputs/Button';
import GuarantorTermsAndConditionsText from 'modules/shared/components/text/GuarantorTermsAndConditionsText';
import TextInput from 'modules/shared/components/inputs/TextInput';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import PdfReaderModal from 'modules/shared/components/widgets/static/pdfReaderModal';
import ReviewAgreeModal from './ReviewAgreeModal';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import ArrowButton from 'modules/shared/components/widgets/interactive/ArrowButton';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import ApplicationProgressDots from 'modules/shared/components/widgets/static/ApplicationProgressDots';

var Terms = createClass({
  componentWillMount: function() {
    const {
      dispatch,
      location: {
        query: { g, t },
      },
    } = this.props;
    if (g && t) {
      dispatch(
        guarantorLogin(g, t, () => {
          dispatch(loadGuarantorInfo(g));
        })
      );
    }
  },

  componentWillReceiveProps: function(nextProps) {
    const { handleComplete } = this.props;
    this.isComplete({ ...nextProps });
  },

  isComplete: function() {
    const { acknowledged } = this.state;
    if (acknowledged) {
      return true;
    } else {
      return false;
    }
  },

  hasCustomTerms: function() {
    const { application } = this.props;
    return (
      application.attributes.uses_custom_guarantor_terms &&
      application.attributes.custom_guarantor_terms.file
    );
  },

  onScrollToBottom: function() {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
    }
  },

  toggleAcknowledged: function() {
    const { region } = this.props;
    this.setState({ isAgreeTooltipActive: false });

    if (this.state.acknowledged) {
      this.setState({ acknowledged: false });
    } else {
      this.setState({ acknowledged: true });
    }
  },

  getInitialState: function() {
    return {
      hasScrolled:
        this.props.region === 'NZ' ? false : isMobile() ? false : true,
      acknowledged: false,
      errors: {},
      first_name: '',
      last_name: '',
      showPdfReaderModal: false,
      showAgreeModal: false,
      isAgreeTooltipActive: false,
    };
  },

  cancelPdfModal: function() {
    this.setState({ showPdfReaderModal: false });
  },

  cancelAgreeModal: function() {
    this.setState({ showAgreeModal: false });
  },

  showPdfModal: function() {
    this.setState({ showPdfReaderModal: true });
  },

  showAgreePdfModal: function() {
    this.setState({ showAgreeModal: false });
    this.showPdfModal();
  },

  next: function() {
    const { location } = this.props;
    let nextPath = '/guarantor/confirm';
    if (this.isComplete()) {
      browserHistory.push({
        pathname: nextPath,
        query: location.query,
      });
    } else {
      this.setState({ isAgreeTooltipActive: true });
    }
  },

  componentGetDots: function() {
    const { guarantor } = this.props;
    if (isMobile() && guarantor) {
      var id_flow = guarantor.application.attributes
        .requires_guarantees_identification_check
        ? 2
        : 0; // guarantor id section
      let mobile_index = 1 + id_flow + 1; // default is review and id_flow
      let mobile_pages = 3 + id_flow; // default 3 is review, terms and confirm

      return (
        <ApplicationProgressDots
          type="horizontal"
          mobile_pages={mobile_pages}
          mobile_index={mobile_index}
        />
      );
    }
    return;
  },

  render: function() {
    const {
      title,
      application,
      firstname,
      lastname,
      hasAcknowledged,
      hasApproved,
      outcome,
      location: {
        query: { g, t },
      },
      trading_name,
      supplier_logo_url,
      region,
      ...rest
    } = this.props;
    const {
      hasScrolled,
      acknowledged,
      errors,
      first_name,
      last_name,
      showPdfReaderModal,
      showAgreeModal,
      isAgreeTooltipActive,
    } = this.state;
    let acknowledgeClass = 'clickable',
      terms,
      acknowledgeElm,
      approvalElm,
      pdf_url,
      noScroll = isMobile(),
      terms_modal,
      info,
      no_top_border;

    if (acknowledged) {
      acknowledgeClass = 'clicked';
    }
    let has_Custom_Terms = false;

    let terms_button = (
      <a onClick={this.showPdfModal}>View supplier Ts and Cs</a>
    );

    let info_text =
      "To agree to the terms and conditions you must first view the guarantor's terms before selecting the 'I agree' checkbox";

    if (showAgreeModal) {
      terms_modal = (
        <ReviewAgreeModal
          handleDismiss={this.cancelAgreeModal}
          okHandler={this.showAgreePdfModal}
          title="Agree checkbox"
          value="guarantor"
        />
      );
    }

    if (application) {
      has_Custom_Terms = this.hasCustomTerms();
      if (has_Custom_Terms) {
        pdf_url = application.attributes.custom_guarantor_terms.file.url;
        if (noScroll) {
          terms = terms_button;
          info = info_text;
          no_top_border = { borderTop: 'none' };
        } else {
          terms = (
            <PdfReader url={pdf_url} onScrollToBottom={this.onScrollToBottom} />
          );
        }
      } else {
        pdf_url =
          'https://s3-ap-southeast-2.amazonaws.com/s3-1centre-production/pdfs/files/1centre_terms_document/20180112+AU+FINAL+1Centre+Guarantee+Doc+(00494982xE3943).pdf';
        if (region === 'NZ') {
          terms = (
            <GuarantorTermsAndConditionsText
              onScrollToBottom={this.onScrollToBottom}
              consumerName={application.attributes.consumer_name}
              supplierName={application.attributes.supplier_name}
              guarantor_first_name={firstname}
              guarantor_last_name={lastname}
              noScroll={noScroll}
            />
          );
        } else {
          if (noScroll) {
            terms = terms_button;
            info = info_text;
            no_top_border = { borderTop: 'none' };
          } else {
            terms = (
              <PdfReader
                url={pdf_url}
                onScrollToBottom={this.onScrollToBottom}
              />
            );
          }
        }
      }
    }

    if (showPdfReaderModal) {
      terms_modal = (
        <PdfReaderModal
          url={pdf_url}
          title={'Guarantor terms and conditions'}
          onScrollToBottom={this.onScrollToBottom}
          dismissHandler={this.cancelPdfModal}
        />
      );
    }

    acknowledgeElm = (
      <SquareCheckbox
        checked={acknowledged}
        handleChange={this.toggleAcknowledged}
        checkboxId="acknowledged"
        label="I have read the terms and conditions for guaranteeing this trade account"
      />
    );

    var color = isMobile() ? 'white' : 'grey';
    var overlay = (
      <ApplicationOverlay
        logo_url={supplier_logo_url}
        trading_name={trading_name}
        label={'Guarantor approval'}
        color={color}
      />
    );
    var dots = this.componentGetDots();

    return (
      <div className={styles.wrapper}>
        <header className={styles.header}>
          {overlay}
          {dots}
        </header>
        <div className={styles.container}>
          <section className={styles.section}>
            <div className={styles.row}>
              <div className={styles.panel}>
                <PanelTitle text={title} />
                <h3 className={styles.info}>{info}</h3>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.terms} style={no_top_border}>
                {terms}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.control + ' ' + styles.pad_bot_60}>
                <div style={{ marginTop: '1em' }}>{acknowledgeElm}</div>
                {isAgreeTooltipActive && (
                  <ToolTip
                    tip_name="ReviewSupplierTermsAgree"
                    css_style="review_supplier_terms_agree"
                  />
                )}
                <div className={styles.btn_next}>
                  <ArrowButton text="next" handleClick={this.next} />
                </div>
              </div>
            </div>
          </section>
        </div>
        {terms_modal}
      </div>
    );
  },
});

var defaults = {
  title: 'Ts and Cs for acting as a guarantor',
};

module.exports = connect((state, ownProps) => {
  let application,
    hasAcknowledged,
    hasApproved,
    identification_check,
    trading_name,
    logo_url,
    region,
    guarantor;
  if (state.guarantor.data) {
    guarantor = state.guarantor.data;
    hasAcknowledged = guarantor.attributes.acknowledged;
    hasApproved = guarantor.attributes.approved;
    application = guarantor.application;
    if (
      guarantor.application.attributes.requires_guarantees_identification_check
    ) {
      identification_check = true;
    }
    trading_name = guarantor.application.attributes.supplier_name;
    if (guarantor.application.attributes.supplier_logo) {
      logo_url = guarantor.application.attributes.supplier_logo.logo.url;
    }
    region = guarantor.supplier.attributes.region;
  }

  return {
    title: defaults.title,
    application,
    hasAcknowledged,
    hasApproved,
    firstname: state.guarantor.data
      ? state.guarantor.data.attributes.first_name
      : '',
    lastname: state.guarantor.data
      ? state.guarantor.data.attributes.last_name
      : '',
    identification_check,
    supplier_logo_url: logo_url,
    trading_name: trading_name,
    region: region,
    guarantor: guarantor,
  };
})(Terms);
