import AccordianContainer from 'modules/shared/components/containers/AccordianContainer';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import Card from 'modules/shared/components/widgets/static/Card';
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { CASH_SAMPLE_PAYLOAD as COMPANY_PAYLOAD } from '../constants/company.js';
import { CASH_SAMPLE_PAYLOAD as OTHER_PAYLOAD } from '../constants/other.js';
import { CASH_SAMPLE_PAYLOAD as PARTNERSHIP_PAYLOAD } from '../constants/partnership.js';
import { CASH_SAMPLE_PAYLOAD as PERSONAL_PAYLOAD } from '../constants/personal.js';
import { CASH_SAMPLE_PAYLOAD as SOLE_TRADER_PAYLOAD } from '../constants/sole_trader.js';
import { CASH_SAMPLE_PAYLOAD as TRUST_PAYLOAD } from '../constants/trust.js';
import { stringifyJSON } from '../containers/WebhookDocumentationContainer';
import styles from '../styles/WebhookExampleStyle';
import WebhookCodeBlock, { MergedDefinitions } from './WebhookCodeBlock';

export default class WebhookCashDocumentation extends React.Component {
  get cashDefinitions() {
    return this.props.schemaDefinitions.cash_definitions || {};
  }

  _commonDefinitionsBlock() {
    return (
      <ReviewContainer
        id="common_definitions"
        key="common_definitions"
        label="Common Definition"
      >
        <div className={styles.code_block}>
          <SyntaxHighlighter language="json" style={atomDark}>
            {stringifyJSON(this.props.schemaDefinitions.common_definitions)}
          </SyntaxHighlighter>
        </div>
      </ReviewContainer>
    );
  }

  _companyBlock() {
    return (
      <WebhookCodeBlock
        id="company"
        key="company"
        label="Company"
        schema={stringifyJSON(this.cashDefinitions.company)}
        payload={COMPANY_PAYLOAD}
      />
    );
  }

  _otherBlock() {
    return (
      <WebhookCodeBlock
        id="other"
        key="other"
        label="Other"
        schema={stringifyJSON(this.cashDefinitions.other)}
        payload={OTHER_PAYLOAD}
      />
    );
  }

  _partnershipBlock() {
    return (
      <WebhookCodeBlock
        id="partnership"
        key="partnership"
        label="Partnership"
        schema={stringifyJSON(this.cashDefinitions.partnership)}
        payload={PARTNERSHIP_PAYLOAD}
      />
    );
  }

  _personalBlock() {
    return (
      <WebhookCodeBlock
        id="personal"
        key="personal"
        label="Personal"
        schema={stringifyJSON(this.cashDefinitions.personal)}
        payload={PERSONAL_PAYLOAD}
      />
    );
  }

  _soleTraderBlock() {
    return (
      <WebhookCodeBlock
        id="sole-trader"
        key="sole-trader"
        label="Sole Trader"
        schema={stringifyJSON(this.cashDefinitions.sole_trader)}
        payload={SOLE_TRADER_PAYLOAD}
      />
    );
  }

  _trustBlock() {
    return (
      <WebhookCodeBlock
        id="trust"
        key="trust"
        label="Trust"
        schema={stringifyJSON(this.cashDefinitions.trust)}
        payload={TRUST_PAYLOAD}
      />
    );
  }

  _onUpdateIndex(index) {
    const state = this.state;

    if (state.currentIndex === index) {
      this.setState({ ...state, currentIndex: null });
      return;
    }

    this.setState({ ...state, currentIndex: index });
  }

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: null,
    };
  }

  render() {
    const { schemaDefinitions } = this.props;

    const subPages = [
      <MergedDefinitions
        id="merged-definitions"
        key="merged-definitions"
        label="Merged definitions"
        schemaDefinitions={schemaDefinitions || {}}
      />,
      this._commonDefinitionsBlock(),
      this._companyBlock(),
      this._otherBlock(),
      this._partnershipBlock(),
      this._personalBlock(),
      this._soleTraderBlock(),
      this._trustBlock(),
    ];

    return (
      <div>
        <Card>
          This page contains the schema definition and samples of 1Centre&apos;s
          webhook payload by entity type for cash applications.
        </Card>

        <AccordianContainer
          subPages={subPages}
          subsection_index={this.state.currentIndex}
          type="review"
          handleClick={this._onUpdateIndex.bind(this)}
        />
      </div>
    );
  }
}
