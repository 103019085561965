import styles from 'modules/shared/components/containers/css/TopbarContent.css';
import React from 'react';

function containerClassName(props) {
  const { withLeftmargin } = props;
  const defaultClassNames = [styles.topbar];

  if (withLeftmargin) {
    defaultClassNames.push(styles.with_left_margin);
  }

  return defaultClassNames.join(' ');
}

function TopbarIconLink(props) {
  const { Icon, IconDisabled, isActive, isDisabled, label, onClick } = props;

  let TopbarIcon = Icon;
  let linkClassName = styles.icon_link;
  if (isDisabled) {
    TopbarIcon = IconDisabled;
    linkClassName += ` ${styles.icon_link_disabled}`;
  }

  let iconBarClassName = styles.icon_bar;

  if (isActive) {
    iconBarClassName = styles.icon_bar_active;
  }

  return (
    <div className={linkClassName}>
      <div onClick={onClick}>
        <TopbarIcon className={styles.action_icon} width={40} height={40} />
        <div className={styles.icon_text}>{label}</div>
      </div>
      <div className={iconBarClassName} />
    </div>
  );
}

TopbarIconLink.defaultProps = {
  isDisabled: false,
};

export default function TopbarContent(props) {
  const { children, topbarActions } = props;

  const topbarActionComponents = topbarActions.map((action, i) => (
    <TopbarIconLink
      key={`topbar-icon-link-${i + 1}`}
      Icon={action.Icon}
      IconDisabled={action.IconDisabled}
      isActive={action.isActive}
      isDisabled={action.isDisabled}
      label={action.label}
      onClick={action.onClick}
    />
  ));

  return (
    <div className={containerClassName(props)}>
      <div className={styles.topbar_icons}>{topbarActionComponents}</div>
      {children}
    </div>
  );
}

TopbarContent.defaultProps = {
  withLeftmargin: true,
};
