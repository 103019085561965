import React from 'react';

export default function RecordHistory(props) {
  const { histories, removeNotes } = props;

  return histories.map((history, i) => (
    <div key={`history-${i + 1}`}>
      {removeNotes
        ? history.formattedContentWithoutNote
        : history.formattedContent}
    </div>
  ));
}
