import styled from 'styled-components';
import { FONT_SIZES } from 'variables/typography';

export const SectionHeader = styled.div`
  margin-bottom: 20px;
  width: 100%;
  font-size: ${FONT_SIZES.size2};
`;

export const FormWrapper = styled.form`
  width: 100%;
`;

export const EmailPrompt = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const MobileWrapper = styled.div`
  width: 100%;
`;

export const AlignedWidthButtons = styled.div`
  display: grid;
  grid-gap: 25px;
  margin-top: 10px;

  button {
    width: 100%;
  }
`;
export const FormGroupWrapper = styled.div`
  .control {
    margin-bottom: 15px;
  }
`;
