import { dashboardRedirect } from 'modules/shared/helpers/dashboardRedirect';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history3/redirect';

const authenticatingSelector = (state) => state.current_user.loading;

// Redirects to /login by default
export const LoggedIn = connectedRouterRedirect({
  authenticatedSelector: ({ current_user }) =>
    current_user !== null &&
    (current_user.loggedIn &&
      current_user.current_user_entity_link &&
      current_user.current_entity &&
      current_user.current_entity.attributes.subscription_state !==
        'deactivated'),
  authenticatingSelector,
  redirectPath: '/user/login',
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const ApplicationLoggedIn = connectedRouterRedirect({
  authenticatedSelector: ({ current_user }) =>
    current_user !== null && current_user.loggedIn,
  authenticatingSelector,
  redirectPath: '/user/login',
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const V2OnboardingUserAuthenticated = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: ({ current_user }) =>
    current_user !== null && current_user.loggedIn,
  authenticatingSelector,
  redirectPath: (_state, ownProps) => {
    const {
      location: { search },
    } = ownProps;

    return `/onboarding${search}`;
  },
  wrapperDisplayName: 'V2OnboardingUserAuthenticated',
});

export const IsActiveAccount = connectedRouterRedirect({
  authenticatedSelector: ({ current_user }) =>
    current_user !== null &&
    (current_user.loggedIn &&
      current_user.current_user_entity_link &&
      current_user.current_entity &&
      ['active', 'trial', 'free'].includes(
        current_user.current_entity.attributes.subscription_state
      )),
  authenticatingSelector,
  redirectPath: '/invalid-account',
  wrapperDisplayName: 'IsActiveAccount',
});

export const IsSupplier = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: ({ current_user }) =>
    current_user !== null &&
    (current_user.loggedIn &&
      (current_user.current_entity &&
        current_user.current_entity.attributes.supplier)),
  authenticatingSelector,
  redirectPath: '/dashboard/home',
  wrapperDisplayName: 'IsSupplier',
});

export const IsAtleastApprover = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: ({ current_user }) => {
    if (current_user === null || !current_user.current_user_entity_link) {
      return false;
    }
    return !current_user.current_user_entity_link.attributes.role_types.includes(
      'standard'
    );
  },
  authenticatingSelector,
  redirectPath: (state) => dashboardRedirect('/dashboard', state.current_user),
  wrapperDisplayName: 'IsAtleastApprover',
});

export const IsAdmin = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: ({ current_user }) => {
    if (current_user === null || !current_user.current_user_entity_link) {
      return false;
    }
    return current_user.current_user_entity_link.attributes.role_types.includes(
      'admin'
    );
  },
  authenticatingSelector,
  redirectPath: (state) => dashboardRedirect('/dashboard', state.current_user),
  wrapperDisplayName: 'IsAdmin',
});

export const IsSuperAdmin = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: ({ current_user }) => {
    if (current_user === null || !current_user.data.data) {
      return false;
    }
    return current_user.data.data.attributes.super_admin;
  },
  authenticatingSelector,
  redirectPath: '/dashboard/home',
  wrapperDisplayName: 'IsSuperAdmin',
});

export const IsTrm = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: ({ current_user }) => {
    if (current_user === null || !current_user.data.data) {
      return false;
    }
    return current_user.data.data.attributes.trm;
  },
  authenticatingSelector,
  redirectPath: '/trm/list',
  wrapperDisplayName: 'IsTrm',
});
