import get from 'lodash.get';
import {
  setAdditionalAnswers,
  setAdditionalFile,
} from 'modules/consumer-onboarding/actions/additional';
import FileWidgetSmall from 'modules/shared/components/widgets/interactive/FileWidgetSmall';
import ConsumerFromRender from 'modules/shared/components/widgets/interactive/form_builder/ConsumerFromRender';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import PDFLogo from 'modules/shared/components/widgets/static/PDFLogo';
import { paginate } from 'modules/shared/helpers/additionalSectionHelper';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './css/Additional.css';

class AdditionalDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: {
        file: 'This is mandatory.',
      },
      triggerValidation: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      handleComplete,
      validationFlags,
      validationTrigger,
      page_validation_start: pageValidationStart,
      setPageValidationStartFinish,
      isReview,
      isAdditionalReviewComplete,
    } = props;
    const isComplete = AdditionalDetails.isComplete(props);
    if (handleComplete) {
      handleComplete(isComplete, 'additional');
    }
    const triggerValidation =
      state.triggerValidation ||
      pageValidationStart ||
      validationTrigger ||
      (isReview && !isAdditionalReviewComplete);
    if (isComplete && setPageValidationStartFinish) {
      setPageValidationStartFinish();
    }
    return { triggerValidation };
  }

  static isAllValid(validationFlags) {
    return (validationFlags || [false]).reduce(
      (flag1, flag2) => flag1 && flag2
    );
  }

  static isComplete(props) {
    const { attachDocument, additional, validationFlags } = props;
    const additionalAllValid = AdditionalDetails.isAllValid(validationFlags);
    if (attachDocument && attachDocument.active && attachDocument.mandatory) {
      return additionalAllValid && !!additional.file && !!additional.file.url;
    }

    return additionalAllValid;
  }

  docReady(data) {
    const { dispatch } = this.props;
    dispatch(setAdditionalFile({ url: data }));

    this.setState({ error: null });
  }

  setAdditionalFields(params) {
    const { dispatch } = this.props;
    dispatch(setAdditionalAnswers(params));
  }

  renderTitle() {
    const { index, totalParts } = this.props;
    return (
      <PanelTitle
        text={`Additional part ${index + 1} of ${totalParts}`}
        margin_bottom="1em"
      />
    );
  }

  renderFile() {
    const {
      attachDocument,
      additional,
      supplier,
      readOnly,
      page_validation_start,
      validationTrigger,
    } = this.props;
    const fileSrc = additional.file;
    const { triggerValidation } = this.state;
    const { question, mandatory } = attachDocument;

    const isError =
      mandatory &&
      (page_validation_start || validationTrigger || triggerValidation) &&
      (!fileSrc || !fileSrc.url);
    let completeWidget;
    let viewSrc;

    if (fileSrc && fileSrc.url) {
      completeWidget = <PDFLogo alt="PDF Upload" logoStyle="small" />;
      viewSrc = fileSrc;
    }

    return (
      <div key="file">
        <div className={styles.row}>
          <FileWidgetSmall
            accept={['.pdf', 'image/jpeg', 'image/png', 'image/jpg'].join(',')}
            completeWidget={completeWidget}
            disabled={readOnly || supplier}
            filename="additional-attach-document.pdf"
            handleFileCallback={(data) => {
              this.docReady(data);
            }}
            id="additional-attach-document"
            text="Drag and drop"
            title={`${
              question || 'Please upload additional document.'
            } (PDF, PNG, JPG and JPEG)${
              mandatory ? '' : ' (This is optional)'
            }`}
            viewSrc={viewSrc}
            error={isError && this.state.error.file}
          />
        </div>
      </div>
    );
  }

  render() {
    const { attachDocument, formBuilderComponents, index, answers } =
      this.props;
    return (
      <section className={styles.section} style={{ width: '100%' }}>
        <ConsumerFromRender
          onChange={(params) => {
            this.setAdditionalFields(params);
          }}
          components={formBuilderComponents}
          answers={answers}
          validationTrigger={this.state.triggerValidation}
          reduxKey={`additional_${index}`}
          pageIndex={index}
        />
        {attachDocument && attachDocument.active && this.renderFile()}
      </section>
    );
  }
}

const defaults = {
  moduleName: 'additional',
};

export default connect((state, ownProps) => {
  const addon =
    state.cob_section.addonRules.find(
      (rule) => rule.attributes.addon_module_name === defaults.moduleName
    ) || {};
  const data = ownProps.previewAddon ||
    get(addon, 'attributes.addon_version.data.attributes.config') || {
      components: [],
    };
  const { pageSplitIndex, totalPage } = paginate(data.components);
  const index = ownProps.componentIndex || ownProps.component_index || 0;
  const totalParts = totalPage;

  let formBuilderComponents = [];

  if (index === 0) {
    formBuilderComponents = data.components.slice(0, pageSplitIndex);
  } else {
    formBuilderComponents = data.components.slice(pageSplitIndex);
  }

  if (ownProps.showAll) {
    formBuilderComponents = data.components;
  }

  return {
    additional: state.cob_additional,
    answers: state.cob_additional.answers.results,
    attachDocument: data.attachDocument || data.attach_document,
    formBuilderComponents: data.components,
    index,
    totalParts: 1,
    validationFlags: state.shared_data[`additional_${index}`],
  };
})(AdditionalDetails);
