import { processError } from './processError';

const HTTP_STATUS_CODE_FORBIDDEN = 403;

/**
 * Checks if the provided error indicates a Watchtower block and executes the appropriate callback.
 *
 * @param {any} error - The error object to be processed.
 * @param {() => void} onWatchtowerBlocked - Callback function to be executed when Watchtower blocks the request.
 * @param {(error: any) => void} onGenericError - Callback function to be executed for generic errors.
 */
export const isWatchtowerBlock = (error: any): boolean => {
  const { errorObject, httpStatusCode } = processError(error);

  const isBlockedByWatchtower =
    httpStatusCode === HTTP_STATUS_CODE_FORBIDDEN && errorObject?.blocked;

  return isBlockedByWatchtower;
};
