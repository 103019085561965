import variables from 'css/bulma-customizations/variables.scss';

const base = variables.size5;

export const FONT_SIZES = {
  base,
  heading: '1.33rem',
  hover: '0.83rem',
  size2: variables.size3,
  title: '1.167rem',
};

export const FONT_WEIGHTS = {
  black: variables.black,
  bold: variables.bold,
  extraBold: variables.extraBold,
  light: variables.light,
  normal: variables.normal,
  semiBold: variables.semiBold,
};

export const FONT_SETS = {
  description: `
    font-size: ${FONT_SIZES.base};
    font-weight: ${FONT_WEIGHTS.normal};
  `,
  pageHeader: `
    font-size: ${FONT_SIZES.heading};
    font-weight: ${FONT_WEIGHTS.normal};
    line-height: 1.2;
  `,
  sectionHeader: `
    font-size: ${FONT_SIZES.title};
    font-weight: ${FONT_WEIGHTS.normal};
  `,
};
