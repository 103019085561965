import { Alert, AlertTitle } from '@material-ui/lab';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

import { pluralizeNoun } from 'utils';

const useStyles = makeStyles({
  alertRoot: {
    background: '#dfdfdf',
  },
  iconRoot: {
    color: '#979797',
  },
});

const StyledAlert = styled(Alert)`
  background-color: #dfdfdf;
  .MuiAlert-icon {
    color: #dfdfdf;
  }
`;

type ProgressPropsType = {
  secondsRemaining: number;
};

export const Progress = (props: ProgressPropsType) => {
  const { secondsRemaining } = props;
  const roundedSeconds = Number(secondsRemaining.toFixed(0));
  const minutes = Math.floor(secondsRemaining / 60);
  const timeToDisplay =
    minutes > 0
      ? `${minutes} ${pluralizeNoun('minute', minutes)}`
      : `${roundedSeconds} ${pluralizeNoun('second', roundedSeconds)}`;
  const classes = useStyles();
  return (
    <Alert
      icon={<WatchLaterIcon classes={{ root: classes.iconRoot }} />}
      classes={{ root: classes.alertRoot }}
    >
      <AlertTitle>Import in progress</AlertTitle>
      {`It will take about ${timeToDisplay}. You can leave this page and come back
      later.`}
    </Alert>
  );
};
