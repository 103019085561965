import React from 'react';

import { FilterButton } from './FilterButton';
import { Typography } from '@material-ui/core';

type DownloadReportButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

export const DownloadReportButton = (props: DownloadReportButtonProps) => {
  const { onClick, disabled } = props;
  return (
    <FilterButton onClick={onClick} disabled={disabled}>
      <Typography>
        {'Download '}
        <span>report</span>
      </Typography>
    </FilterButton>
  );
};
