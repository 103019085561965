/* Import libraries */
import React from 'react';

import styles from './css/RangeSlider.css';

var RangeSlider = createClass({
  render: function() {
    const {
      value,
      id,
      name,
      min,
      max,
      step,
      handleChange,
      style_override,
    } = this.props;

    var style = styles.default;
    if (style_override) {
      style = style_override;
    }

    return (
      <input
        type="range"
        id={id}
        name={name}
        onChange={handleChange.bind(null, name)}
        step={step}
        min={min}
        max={max}
        className={style}
        value={value}
      />
    );
  },
});

export default RangeSlider;
