import api from 'api';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import IdentificationCheckModel from 'models/IdentificationCheckModel';
import ModuleCardholderApplicantModel from 'models/ModuleCardholderApplicantModel';
import ModuleCardholderSignatoryModel from 'models/ModuleCardholderSignatoryModel';
import RecordHistoryModel from 'models/RecordHistoryModel';
import SignatureModel from 'models/SignatureModel';
import VedaCheckModel from 'models/VedaCheckModel';
import { DEFAULT_ANTI_FRAUD_CATEGORY } from 'utils/AntiFraudCategoryRule';
import isBlank from 'utils/isBlank';

import AntiFraudCheckModel from './AntiFraudCheckModel';

const AUTHORISATION_TYPE_TO_AUTHORISATION_MAPPING = {
  applicant: 'applicant',
  cardholder: 'cardholder',
  direct_debit: 'payment',
  guarantor: 'guarantor',
  signatory: 'signatory',
};

const getIsApplicableToAuthorisationRole = ({
  agreements,
  authorisationTypes,
}) =>
  authorisationTypes.some(
    (authorisationType) =>
      agreements[AUTHORISATION_TYPE_TO_AUTHORISATION_MAPPING[authorisationType]]
  );

export default class AuthorisationModel extends BaseModel {
  get actingAs() {
    const actingAs = [];
    if (this.isApplicant) {
      actingAs.push('applicant');
    }

    if (this.isSignatory) {
      actingAs.push('signatory');
    }

    if (this.isGuarantor) {
      actingAs.push('guarantor');
    }

    if (this.isPayment) {
      actingAs.push('payment');
    }

    if (this.isCardholder) {
      actingAs.push('cardholder');
    }

    return actingAs;
  }

  get isComplete() {
    return this.status === 'complete';
  }

  set assignSignature(newSignature) {
    this.signature = newSignature;
  }

  get isPpsrEligible() {
    const agreements = this.agreements || {
      guarantor: false,
      signatory: false,
    };

    return (
      (this.isGuarantor && agreements.guarantor) ||
      (this.isSignatory && agreements.signatory)
    );
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  async updateEmailAndResend({
    currentUser,
    email,
    onSuccessCallback,
    onFailCallback,
  }) {
    const authorisationsAPI = api(
      'authorisations',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    try {
      await authorisationsAPI.updateEmailAndResend(this.id, email);
      onSuccessCallback();
    } catch (error) {
      onFailCallback();
      console.error(error);
    }
  }

  getIdentificationCheck(application) {
    if (this.identificationCheck.isPersisted) {
      return this.identificationCheck;
    }

    const vedaIdentificationCheck = application.identificationChecks.find(
      (idCheck) => idCheck.authorisationId === this.id
    ) || { isPersisted: false };
    if (vedaIdentificationCheck.isPersisted) {
      return vedaIdentificationCheck;
    }

    return {};
  }

  getApplicableAntiFraudCategory(antiFraudConfig) {
    const { category } = antiFraudConfig;
    const authorisationTypes = antiFraudConfig.authorisation_types;

    if (isBlank(authorisationTypes)) {
      return category;
    }

    const agreements = this.agreements;

    if (isBlank(agreements)) {
      return DEFAULT_ANTI_FRAUD_CATEGORY;
    }

    const agreedToRoles = authorisationTypes.some(
      (authorisationType) =>
        agreements[
          AUTHORISATION_TYPE_TO_AUTHORISATION_MAPPING[authorisationType]
        ]
    );

    return agreedToRoles ? category : DEFAULT_ANTI_FRAUD_CATEGORY;
  }

  async uploadBase64IdentificationImage({
    currentUser,
    file,
    onSuccessCallback,
    onFailCallback,
  }) {
    const authorisationsAPI = api(
      'authorisations',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    try {
      await authorisationsAPI.uploadIdentificationImage(this.id, file);
      onSuccessCallback(this.signature);
    } catch (e) {
      console.error(e);
      if (onFailCallback) {
        onFailCallback();
      }
    }
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({ key: 'signature', model: SignatureModel });
    this.assignSingleRelationship({
      key: 'identification_check',
      model: IdentificationCheckModel,
    });
    this.assignSingleRelationship({
      key: 'anti_fraud_check',
      model: AntiFraudCheckModel,
    });
    this.assignSingleRelationship({
      key: 'module_cardholder_applicant',
      model: ModuleCardholderApplicantModel,
    });
    this.assignSingleRelationship({
      key: 'veda_check',
      model: VedaCheckModel,
    });

    this.assignSingleRelationship({
      included: this.included,
      key: 'module_cardholder_signatory',
      model: ModuleCardholderSignatoryModel,
    });

    this.assignManyRelationship({
      key: 'authorisation_histories',
      model: RecordHistoryModel,
    });
  }
}
