export const ADD_ONS_LOAD_START = 'ADD_ONS_LOAD_START';
export const ADD_ONS_LOAD_SUCCESS = 'ADD_ONS_LOAD_SUCCESS';
export const ADD_ONS_LOAD_ERROR = 'ADD_ONS_LOAD_ERROR';
export const ADD_ONS_AUTO_DECISION_LOAD_START =
  'ADD_ONS_AUTO_DECISION_LOAD_START';
export const ADD_ONS_AUTO_DECISION_LOAD_SUCCESS =
  'ADD_ONS_AUTO_DECISION_LOAD_SUCCESS';
export const ADD_ONS_AUTO_DECISION_LOAD_ERROR =
  'ADD_ONS_AUTO_DECISION_LOAD_ERROR';
export const ADD_ONS_AUTO_DECISION_TOGGLE_START =
  'ADD_ONS_AUTO_DECISION_TOGGLE_START';
export const ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS =
  'ADD_ONS_AUTO_DECISION_TOGGLE_SUCCESS';
export const ADD_ONS_AUTO_DECISION_TOGGLE_ERROR =
  'ADD_ONS_AUTO_DECISION_TOGGLE_ERROR';
export const ADD_ONS_AUTO_DECISION_ADD_NEW = 'ADD_ONS_AUTO_DECISION_ADD_NEW';
export const ADD_ONS_AUTO_DECISION_UPDATE_SELECTED_RULESET_VALUE =
  'ADD_ONS_AUTO_DECISION_UPDATE_SELECTED_RULESET_VALUE';
export const ADD_ONS_AUTO_DECISION_UPDATE_SELECTED_RULESET =
  'ADD_ONS_AUTO_DECISION_UPDATE_SELECTED_RULESET';
export const BRANCHES_LOAD_START = 'BRANCHES_LOAD_START';
export const BRANCHES_LOAD_SUCCESS = 'BRANCHES_LOAD_SUCCESS';
export const BRANCHES_LOAD_END = 'BRANCHES_LOAD_END';

//
// Internal Use Fields Add-ons constants
//
export const ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR =
  'ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_ERROR';
export const ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START =
  'ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_START';
export const ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS =
  'ADD_ONS_INTERNAL_USE_FIELDS_CREATE_RULESET_SUCCESS';
export const ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR =
  'ADD_ONS_INTERNAL_USE_FIELDS_LOAD_ERROR';
export const ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START =
  'ADD_ONS_INTERNAL_USE_FIELDS_LOAD_START';
export const ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS =
  'ADD_ONS_INTERNAL_USE_FIELDS_LOAD_SUCCESS';
export const ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE =
  'ADD_ONS_INTERNAL_USE_FIELDS_SET_CONFIG_VALUE';
export const ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT =
  'ADD_ONS_INTERNAL_USE_FIELDS_SET_CURRENT';
export const ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE =
  'ADD_ONS_INTERNAL_USE_FIELDS_SET_VALUE';
export const ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR =
  'ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_ERROR';
export const ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START =
  'ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_START';
export const ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_INTERNAL_USE_FIELDS_UPDATE_RULESET_SUCCESS';
export const ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT =
  'ADD_ONS_INTERNAL_USE_FIELDS_RESET_CURRENT';

//
// Custom Lead Configuration constants
//

export const ADD_ONS_CUSTOM_LEAD_CONFIGURATION_START =
  'ADD_ONS_CUSTOM_LEAD_CONFIGURATION_START';
export const ADD_ONS_CUSTOM_LEAD_CONFIGURATION_SUCCESS =
  'ADD_ONS_CUSTOM_LEAD_CONFIGURATION_SUCCESS';
export const ADD_ONS_CUSTOM_LEAD_CONFIGURATION_ERROR =
  'ADD_ONS_CUSTOM_LEAD_CONFIGURATION_ERROR';

//
// Financials Add-ons constants
//
export const ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR =
  'ADD_ONS_FINANCIALS_CREATE_RULESET_ERROR';
export const ADD_ONS_FINANCIALS_CREATE_RULESET_START =
  'ADD_ONS_FINANCIALS_CREATE_RULESET_START';
export const ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS =
  'ADD_ONS_FINANCIALS_CREATE_RULESET_SUCCESS';
export const ADD_ONS_FINANCIALS_LOAD_ERROR = 'ADD_ONS_FINANCIALS_LOAD_ERROR';
export const ADD_ONS_FINANCIALS_LOAD_START = 'ADD_ONS_FINANCIALS_LOAD_START';
export const ADD_ONS_FINANCIALS_LOAD_SUCCESS =
  'ADD_ONS_FINANCIALS_LOAD_SUCCESS';
export const ADD_ONS_FINANCIALS_RESET_CURRENT =
  'ADD_ONS_FINANCIALS_RESET_CURRENT';
export const ADD_ONS_FINANCIALS_SET_CURRENT = 'ADD_ONS_FINANCIALS_SET_CURRENT';
export const ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS =
  'ADD_ONS_FINANCIALS_SET_MANDATORY_FIELDS';
export const ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS =
  'ADD_ONS_FINANCIALS_SET_INDICATOR_FIELDS';
export const ADD_ONS_FINANCIALS_SET_VALUE = 'ADD_ONS_FINANCIALS_SET_VALUE';
export const ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR =
  'ADD_ONS_FINANCIALS_UPDATE_RULESET_ERROR';
export const ADD_ONS_FINANCIALS_UPDATE_RULESET_START =
  'ADD_ONS_FINANCIALS_UPDATE_RULESET_START';
export const ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_FINANCIALS_UPDATE_RULESET_SUCCESS';

//
// Additional Section Add-ons constants
//
export const ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR =
  'ADD_ONS_ADDITIONAL_CREATE_RULESET_ERROR';
export const ADD_ONS_ADDITIONAL_CREATE_RULESET_START =
  'ADD_ONS_ADDITIONAL_CREATE_RULESET_START';
export const ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS =
  'ADD_ONS_ADDITIONAL_CREATE_RULESET_SUCCESS';
export const ADD_ONS_ADDITIONAL_LOAD_ERROR = 'ADD_ONS_ADDITIONAL_LOAD_ERROR';
export const ADD_ONS_ADDITIONAL_LOAD_START = 'ADD_ONS_ADDITIONAL_LOAD_START';
export const ADD_ONS_ADDITIONAL_LOAD_SUCCESS =
  'ADD_ONS_ADDITIONAL_LOAD_SUCCESS';
export const ADD_ONS_ADDITIONAL_RESET_CURRENT =
  'ADD_ONS_ADDITIONAL_RESET_CURRENT';
export const ADD_ONS_ADDITIONAL_SET_CURRENT = 'ADD_ONS_ADDITIONAL_SET_CURRENT';
export const ADD_ONS_ADDITIONAL_SET_VALUE = 'ADD_ONS_ADDITIONAL_SET_VALUE';
export const ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR =
  'ADD_ONS_ADDITIONAL_UPDATE_RULESET_ERROR';
export const ADD_ONS_ADDITIONAL_UPDATE_RULESET_START =
  'ADD_ONS_ADDITIONAL_UPDATE_RULESET_START';
export const ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_ADDITIONAL_UPDATE_RULESET_SUCCESS';
export const ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS =
  'ADD_ONS_FINANCIALS_SET_ADDITIONAL_FIELDS';

//
// Anti Fraud Add-ons constants
//
export const ADD_ONS_ANTI_FRAUD_LOAD_START = 'ADD_ONS_ANTI_FRAUD_LOAD_START';
export const ADD_ONS_ANTI_FRAUD_LOAD_SUCCESS =
  'ADD_ONS_ANTI_FRAUD_LOAD_SUCCESS';
export const ADD_ONS_ANTI_FRAUD_LOAD_ERROR = 'ADD_ONS_ANTI_FRAUD_LOAD_ERROR';

//
// Cards Add-ons constants
//
export const ADD_ONS_CARDS_CREATE_RULESET_ERROR =
  'ADD_ONS_CARDS_CREATE_RULESET_ERROR';
export const ADD_ONS_CARDS_CREATE_RULESET_START =
  'ADD_ONS_CARDS_CREATE_RULESET_START';
export const ADD_ONS_CARDS_CREATE_RULESET_SUCCESS =
  'ADD_ONS_CARDS_CREATE_RULESET_SUCCESS';
export const ADD_ONS_CARDS_LOAD_ERROR = 'ADD_ONS_CARDS_LOAD_ERROR';
export const ADD_ONS_CARDS_LOAD_START = 'ADD_ONS_CARDS_LOAD_START';
export const ADD_ONS_CARDS_LOAD_SUCCESS = 'ADD_ONS_CARDS_LOAD_SUCCESS';
export const ADD_ONS_CARDS_RESET_CURRENT = 'ADD_ONS_CARDS_RESET_CURRENT';
export const ADD_ONS_CARDS_SET_CURRENT = 'ADD_ONS_CARDS_SET_CURRENT';
export const ADD_ONS_CARDS_SET_VALUE = 'ADD_ONS_CARDS_SET_VALUE';
export const ADD_ONS_CARDS_UPDATE_RULESET_ERROR =
  'ADD_ONS_CARDS_UPDATE_RULESET_ERROR';
export const ADD_ONS_CARDS_UPDATE_RULESET_START =
  'ADD_ONS_CARDS_UPDATE_RULESET_START';
export const ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_CARDS_UPDATE_RULESET_SUCCESS';
export const ADD_ONS_PAPERLESS_LOAD_ERROR = 'ADD_ONS_PAPERLESS_LOAD_ERROR';
export const ADD_ONS_PAPERLESS_LOAD_START = 'ADD_ONS_PAPERLESS_LOAD_START';
export const ADD_ONS_PAPERLESS_LOAD_SUCCESS = 'ADD_ONS_PAPERLESS_LOAD_SUCCESS';
export const ADD_ONS_PAPERLESS_RESET_CURRENT =
  'ADD_ONS_PAPERLESS_RESET_CURRENT';
export const ADD_ONS_PAPERLESS_CREATE_RULESET_START =
  'ADD_ONS_PAPERLESS_CREATE_RULESET_START';
export const ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS =
  'ADD_ONS_PAPERLESS_CREATE_RULESET_SUCCESS';
export const ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR =
  'ADD_ONS_PAPERLESS_CREATE_RULESET_ERROR';
export const ADD_ONS_PAPERLESS_SET_VALUE = 'ADD_ONS_PAPERLESS_SET_VALUE';
export const ADD_ONS_PAPERLESS_SET_CONFIG_VALUE =
  'ADD_ONS_PAPERLESS_SET_CONFIG_VALUE';
export const ADD_ONS_PAPERLESS_SET_CURRENT = 'ADD_ONS_PAPERLESS_SET_CURRENT';
export const ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR =
  'ADD_ONS_PAPERLESS_UPDATE_RULESET_ERROR';
export const ADD_ONS_PAPERLESS_UPDATE_RULESET_START =
  'ADD_ONS_PAPERLESS_UPDATE_RULESET_START';
export const ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_PAPERLESS_UPDATE_RULESET_SUCCESS';
// alert constant
export const ADD_ONS_ALERT_LOAD_ERROR = 'ADD_ONS_ALERT_LOAD_ERROR';
export const ADD_ONS_ALERT_LOAD_START = 'ADD_ONS_ALERT_LOAD_START';
export const ADD_ONS_ALERT_LOAD_SUCCESS = 'ADD_ONS_ALERT_LOAD_SUCCESS';
export const ADD_ONS_ALERT_RESET_CURRENT = 'ADD_ONS_ALERT_RESET_CURRENT';
export const ADD_ONS_ALERT_CREATE_RULESET_START =
  'ADD_ONS_ALERT_CREATE_RULESET_START';
export const ADD_ONS_ALERT_CREATE_RULESET_SUCCESS =
  'ADD_ONS_ALERT_CREATE_RULESET_SUCCESS';
export const ADD_ONS_ALERT_CREATE_RULESET_ERROR =
  'ADD_ONS_ALERT_CREATE_RULESET_ERROR';
export const ADD_ONS_ALERT_SET_VALUE = 'ADD_ONS_ALERT_SET_VALUE';
export const ADD_ONS_ALERT_SET_CONFIG_VALUE = 'ADD_ONS_ALERT_SET_CONFIG_VALUE';
export const ADD_ONS_ALERT_SET_CURRENT = 'ADD_ONS_ALERT_SET_CURRENT';
export const ADD_ONS_ALERT_UPDATE_RULESET_ERROR =
  'ADD_ONS_ALERT_UPDATE_RULESET_ERROR';
export const ADD_ONS_ALERT_UPDATE_RULESET_START =
  'ADD_ONS_ALERT_UPDATE_RULESET_START';
export const ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_ALERT_UPDATE_RULESET_SUCCESS';

export const ADD_ONS_WEBSITE_BUTTON_LOAD_START =
  'ADD_ONS_WEBSITE_BUTTON_LOAD_START';
export const ADD_ONS_WEBSITE_BUTTON_SET_CURRENT =
  'ADD_ONS_WEBSITE_BUTTON_SET_CURRENT';
export const ADD_ONS_WEBSITE_BUTTON_LOAD_ERROR =
  'ADD_ONS_WEBSITE_BUTTON_LOAD_START';
export const ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_START =
  'ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_START';
export const ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_SUCCESS';
export const ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_ERROR =
  'ADD_ONS_WEBSITE_BUTTON_UPDATE_RULESET_ERROR';
export const ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_START =
  'ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_START';
export const ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_SUCCESS =
  'ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_SUCCESS';
export const ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_ERROR =
  'ADD_ONS_WEBSITE_BUTTON_CREATE_RULESET_ERROR';
export const ADD_ONS_WEBSITE_BUTTON_RESET_CURRENT =
  'ADD_ONS_WEBSITE_BUTTON_RESET_CURRENT';

export const ADD_ONS_QR_LOAD_START = 'ADD_ONS_QR_LOAD_START';
export const ADD_ONS_QR_LOAD_SUCCESS = 'ADD_ONS_QR_LOAD_SUCCESS';
export const ADD_ONS_QR_LOAD_ERROR = 'ADD_ONS_QR_LOAD_ERROR';
export const ADD_ONS_QR_UPDATE_RULESET_START =
  'ADD_ONS_QR_UPDATE_RULESET_START';
export const ADD_ONS_QR_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_QR_UPDATE_RULESET_SUCCESS';
export const ADD_ONS_QR_UPDATE_RULESET_ERROR =
  'ADD_ONS_QR_UPDATE_RULESET_ERROR';
export const ADD_ONS_QR_CREATE_RULESET_START =
  'ADD_ONS_QR_CREATE_RULESET_START';
export const ADD_ONS_QR_CREATE_RULESET_SUCCESS =
  'ADD_ONS_QR_CREATE_RULESET_SUCCESS';
export const ADD_ONS_QR_CREATE_RULESET_ERROR =
  'ADD_ONS_QR_CREATE_RULESET_ERROR';
export const ADD_ONS_QR_RESET_CURRENT = 'ADD_ONS_QR_RESET_CURRENT';

export const ADD_ONS_REP_CHANNEL_LOAD_START = 'ADD_ONS_REP_CHANNEL_LOAD_START';
export const ADD_ONS_REP_CHANNEL_LOAD_SUCCESS =
  'ADD_ONS_REP_CHANNEL_LOAD_SUCCESS';
export const ADD_ONS_REP_CHANNEL_LOAD_ERROR = 'ADD_ONS_REP_CHANNEL_LOAD_ERROR';
export const ADD_ONS_REP_CHANNEL_CREATE_RULESET_START =
  'ADD_ONS_REP_CHANNEL_CREATE_RULESET_START';
export const ADD_ONS_REP_CHANNEL_CREATE_RULESET_SUCCESS =
  'ADD_ONS_REP_CHANNEL_CREATE_RULESET_SUCCESS';
export const ADD_ONS_REP_CHANNEL_CREATE_RULESET_ERROR =
  'ADD_ONS_REP_CHANNEL_CREATE_RULESET_ERROR';
export const ADD_ONS_REP_CHANNEL_RESET_CURRENT =
  'ADD_ONS_REP_CHANNEL_RESET_CURRENT';

export const ADD_ONS_CALL_CENTRE_LOAD_START = 'ADD_ONS_CALL_CENTRE_LOAD_START';
export const ADD_ONS_CALL_CENTRE_LOAD_SUCCESS =
  'ADD_ONS_CALL_CENTRE_LOAD_SUCCESS';
export const ADD_ONS_CALL_CENTRE_LOAD_ERROR = 'ADD_ONS_CALL_CENTRE_LOAD_ERROR';
export const ADD_ONS_CALL_CENTRE_UPDATE_RULESET_START =
  'ADD_ONS_CALL_CENTRE_UPDATE_RULESET_START';
export const ADD_ONS_CALL_CENTRE_UPDATE_RULESET_SUCCESS =
  'ADD_ONS_CALL_CENTRE_UPDATE_RULESET_SUCCESS';
export const ADD_ONS_CALL_CENTRE_UPDATE_RULESET_ERROR =
  'ADD_ONS_CALL_CENTRE_UPDATE_RULESET_ERROR';
export const ADD_ONS_CALL_CENTRE_CREATE_RULESET_START =
  'ADD_ONS_CALL_CENTRE_CREATE_RULESET_START';
export const ADD_ONS_CALL_CENTRE_CREATE_RULESET_SUCCESS =
  'ADD_ONS_CALL_CENTRE_CREATE_RULESET_SUCCESS';
export const ADD_ONS_CALL_CENTRE_CREATE_RULESET_ERROR =
  'ADD_ONS_CALL_CENTRE_CREATE_RULESET_ERROR';
export const ADD_ONS_CALL_CENTRE_RESET_CURRENT =
  'ADD_ONS_CALL_CENTRE_RESET_CURRENT';

export const ADD_ONS_SALES_CHANNEL_STATUS_LOAD_START =
  'ADD_ONS_SALES_CHANNEL_STATUS_LOAD_START';
export const ADD_ONS_SALES_CHANNEL_STATUS_LOAD_SUCCESS =
  'ADD_ONS_SALES_CHANNEL_STATUS_LOAD_SUCCESS';
export const ADD_ONS_SALES_CHANNEL_STATUS_LOAD_ERROR =
  'ADD_ONS_SALES_CHANNEL_STATUS_LOAD_ERROR';
export const AUTO_DECISIONS_SORT = 'AUTO_DECISIONS_SORT';
