import _ from 'lodash';
import get from 'lodash.get';
import UserEntityLinkModel from 'models/UserEntityLinkModel';
import formatUsers, {
  IFormattedUser,
} from 'modules/new-applications/utils/formatUsers';
import { useState } from 'react';

interface ICurrentUser {
  accessToken: string;
  currentEntity: {
    id: string;
  };
}

interface IApplicationApproverState {
  formattedUsers: IFormattedUser[];
  onFetchApprovers: () => void;
}

const useApplicationApproversState = ({
  applicationId,
  currentUser,
}: {
  applicationId: string;
  currentUser: ICurrentUser;
}): IApplicationApproverState => {
  const [users, setUsers] = <any[]>useState([]);

  const onFetchApprovers = () => {
    (async () => {
      const users = await UserEntityLinkModel.fetchApplicationEscalation({
        accessToken: currentUser.accessToken,
        applicationId,
        entityId: get(currentUser, 'currentEntity.id'),
        levelWithApprover: '0:gte',
      });

      const rawReviewers = await UserEntityLinkModel.fetchApplicationReviewers({
        accessToken: currentUser.accessToken,
        applicationId,
        entityId: get(currentUser, 'currentEntity.id'),
      });

      const reviewers = rawReviewers.filter((user) => user.level === 0);
      const finalApprovers = _.uniqBy([...reviewers, ...users], 'id');

      setUsers(finalApprovers);
    })();
  };

  const formattedUsers = formatUsers(users);

  return { formattedUsers, onFetchApprovers };
};

export default useApplicationApproversState;
