import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

/* Import actions */
import { getMonthlyReport, getReport } from '../actions';

/* Import actions */
import { loadCurrentEntity } from 'modules/user/actions';

/* Import Colors */
import {
  STYLE_COLOR_BASE_BLUE,
  STYLE_COLOR_BASE_BLUE_LIGHT,
  STYLE_COLOR_BASE_GREY_LIGHTER,
  STYLE_COLOR_BASE_GREY_MEDIUM,
  STYLE_COLOR_BASE_RED,
} from 'constants';

/* Import components */
import AdminTitle from 'modules/shared/components/widgets/static/AdminTitle';

import SetupCard from '../components/SetupCard';
import Panel from 'modules/dashboard/components/Panel';
import TotalOverview from '../components/TotalOverview';
import MonthlyOverview from '../components/MonthlyOverview';
import SupplierApplicationsList from '../components/SupplierApplicationsList';
import StandardUserApplicationsList from '../components/StandardUserApplicationsList';
import ConsumerTodoApplicationsList from '../components/ConsumerTodoApplicationsList';
import ConsumerPendingApplicationsList from '../components/ConsumerPendingApplicationsList';
import TrendChart from '../components/TrendChart';
import DateString from 'modules/shared/components/widgets/static/DateString';
import FilterDrawer from 'modules/dashboard/components/FilterDrawer';
import { Icon } from './styles.js';

import styles from './css/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileTabs from './MobileTabs';
import {
  isMobile,
  isMobileNew,
  isTablet,
} from 'modules/shared/helpers/mobileDetect';
import { desktopPanels, mobilePanels, tabletPanels } from './panels';
import Header from './Header';
import SummaryPanel from './SummaryPanel';
import { loadFilterValues } from '../utils';
import { get } from 'lodash';
import { browserHistory } from 'react-router';
import UserModel from 'models/UserModel';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: {
        month: moment().month(),
        year: moment().year(),
      },
      show_dashboard: 'fromConsumer',
      showFilter: false,
      currentTab: this.props.isStandardUser ? 'Applications' : 'Effectiveness',
    };
  }

  componentDidMount() {
    const { dispatch, current_user, current_entity } = this.props;
    const { date } = this.state;

    let entity_type = 'consumer';

    if (current_entity.attributes.supplier) {
      entity_type = 'supplier';
    }

    dispatch(getReport(current_user.id, entity_type));
    dispatch(getMonthlyReport(current_user.id, entity_type, date));
    dispatch(loadCurrentEntity());
  }

  nextMonth() {
    const { dispatch, current_user, current_entity } = this.props;
    const { date } = this.state;

    let new_date = { ...date, month: date.month + 1 };
    let entity_type = 'consumer';
    if (current_entity.attributes.supplier) {
      entity_type = 'supplier';
    }

    if (date.month >= 11) {
      new_date = {
        month: 0,
        year: date.year + 1,
      };
    }
    this.setState({
      date: new_date,
    });
    dispatch(getMonthlyReport(current_user.id, entity_type, new_date));
  }

  prevMonth() {
    const { dispatch, current_user, current_entity } = this.props;
    const { date } = this.state;

    let new_date = { ...date, month: date.month - 1 };
    let entity_type = 'consumer';
    if (current_entity.attributes.supplier) {
      entity_type = 'supplier';
    }

    if (date.month <= 0) {
      new_date = {
        month: 11,
        year: date.year - 1,
      };
    }
    this.setState({
      date: new_date,
    });
    dispatch(getMonthlyReport(current_user.id, entity_type, new_date));
  }

  showSetupCard() {
    const { current_entity } = this.props;
    return !!(
      current_entity.attributes.supplier &&
      current_entity.attributes.supplier_onboarding_checklist
    );
  }

  showConsumerDashboard() {
    const { dispatch, current_user } = this.props;
    const { date } = this.state;
    this.setState({
      show_dashboard: 'fromConsumer',
    });
    dispatch(getReport(current_user.id, 'supplier'));
    dispatch(getMonthlyReport(current_user.id, 'supplier', date));
  }

  showSupplierDashboard() {
    const { dispatch, current_user } = this.props;
    const { date } = this.state;
    this.setState({
      show_dashboard: 'toSupplier',
    });
    dispatch(getReport(current_user.id, 'consumer'));
    dispatch(getMonthlyReport(current_user.id, 'consumer', date));
  }

  toggleFilter = () => {
    return this.setState({ showFilter: !this.state.showFilter });
  };

  render() {
    const {
      access_token,
      title,
      current_entity,
      currentRoleTypes,
      comparison,
      dispatch,
      isStandardUser,
      monthly_report,
      period,
      report,
      tier,
      loading,
      chartData,
      applications_pending,
      applications_incomplete,
      applications_pending_total,
      applications_incomplete_total,
      isTrm,
    } = this.props;
    const { currentTab, date, show_dashboard } = this.state;
    const isSupplier = !!current_entity.attributes.supplier;

    if (!isSupplier) {
      browserHistory.push('/dashboard/reporting');
    }

    if (isTrm) {
      browserHistory.push('/trm/list');
    }

    let showSupplierDashboard =
      current_entity.attributes.supplier && show_dashboard === 'fromConsumer';
    let setupCard = null;
    if (current_entity) {
      if (this.showSetupCard()) {
        let list = current_entity.attributes.supplier_onboarding_checklist;
        let setupCardList = {
          'Business details': !!list.profile,
          'Account rules': !!(
            list.admin &&
            current_entity.attributes.minimum_trade_references >= 0
          ),
          'Approval hierarchy': !!list.approval_hierarchy,
          'Supplier terms': !!list.terms,
          'Billing details': !!list.billing_details,
        };
        setupCard = <SetupCard id="setup_card" list={setupCardList} />;
      }
    }

    let panels = [];
    const panelProps = {
      accessToken: access_token,
      entityId: current_entity.id,
      filterState: {
        period_from: period.from,
        period_to: period.to,
        entity_id: tier,
        compare: {
          period_from: get(period, 'compare.from'),
          period_to: get(period, 'compare.to'),
        },
      },
      currentRoleTypes,
    };

    if (showSupplierDashboard) {
      const ApplicationList = currentRoleTypes.includes('standard')
        ? StandardUserApplicationsList
        : SupplierApplicationsList;

      panels.push(
        <Panel
          title="Actions that need taking"
          loading={loading.report}
          key={'actions'}
        >
          <ApplicationList
            data={applications_pending}
            total={applications_pending_total}
          />
        </Panel>
      );
    } else {
      panels.push(
        <Panel
          title="Outstanding applications"
          loading={loading.report}
          key={'outstanding'}
        >
          <ConsumerPendingApplicationsList
            data={applications_pending}
            total={applications_pending_total}
          />
        </Panel>
      );
      panels.push(
        <Panel
          title="Actions that need taking"
          loading={loading.report}
          key={'actions'}
        >
          <ConsumerTodoApplicationsList
            data={applications_incomplete}
            total={applications_incomplete_total}
          />
        </Panel>
      );
    }

    panels.push(
      <Panel
        title="Trade account applications by month"
        loading={loading.monthly_report}
        key={'monthly'}
      >
        <MonthlyOverview
          data={monthly_report}
          handleNext={this.nextMonth}
          handlePrev={this.prevMonth}
          date={date}
        />
      </Panel>
    );

    panels.push(
      <Panel
        title="All time application totals"
        loading={loading.report}
        key={'totals'}
      >
        <TotalOverview data={report} />
      </Panel>
    );

    if (showSupplierDashboard) {
      panels.push(
        <Panel
          title="Application trends over last quarter"
          loading={loading.report}
          key={'chart'}
        >
          <TrendChart chartData={chartData} />
        </Panel>
      );
    }

    const reportingTooltip = (
      <div className="has-text-left">
        <p>
          <span className="has-text-white has-text-weight-bold">
            BETA REPORTING:
          </span>{' '}
          We are excited to start rolling out our reporting page, it will be
          rolled out in three seperate stages over the next few months - we
          would love any feedback you may have so please send it through.
        </p>
        <br />
        <p>
          NOTE: As we are integrated with multiple different data software to
          provide you with this information, some parts will attract a cost. Any
          costs will be communicated in due course, but for now, enjoy!
        </p>
      </div>
    );

    const reportingPermission = ({ permission, phase }) =>
      // permission is defined and currentRoleTypes has at least one common item with
      // permission
      (!permission ||
        !_.isEmpty(_.intersection(permission, currentRoleTypes))) &&
      window._env_.VISIBLE_REPORTING_PHASE.includes(phase);

    const renderPanels = (panels, key) => {
      const totalColumn = 12;

      // Filter the panels with no childComponents
      // then map filter the opposite
      const filtererdActivePanels = panels.filter((Panel) => {
        const childCondition =
          Panel.childComponents &&
          Panel.childComponents.filter(reportingPermission).length > 0;
        const reportingCondition = reportingPermission(Panel);

        return childCondition || reportingCondition;
      });

      const activePanels = filtererdActivePanels.map((Panel) => {
        if (Panel.childComponents) {
          return {
            ...Panel,
            childComponents: Panel.childComponents.filter(reportingPermission),
          };
        }

        return Panel;
      });

      return (
        <div className="tile" key={`dashboard-tile-${key}`}>
          {activePanels.map(
            (
              {
                component: Panel,
                autoHeight,
                comingSoon,
                childComponents,
                size,
                sizeOverrides,
                styleOverrides,
              },
              index
            ) => {
              const columnSize = size
                ? size
                : totalColumn / activePanels.length;
              const className = [
                'tile',
                'is-parent',
                `is-${columnSize}`,
                sizeOverrides
                  ? sizeOverrides
                  : `is-${columnSize}-desktop is-12-tablet`,
              ];

              if (childComponents && childComponents.length > 0) {
                className.push('is-vertical');

                return (
                  <div
                    key={`${key}-panel-${index}`}
                    className={className.join(' ')}
                    style={styleOverrides}
                  >
                    {childComponents.map(
                      (
                        {
                          component: InnerPanel,
                          autoHeight,
                          comingSoon: innerComingSoon,
                          sizeOverrides,
                        },
                        index
                      ) =>
                        InnerPanel ? (
                          <InnerPanel
                            key={`${key}-panel-${index}`}
                            autoHeight={autoHeight}
                            size={totalColumn}
                            comingSoon={innerComingSoon}
                            sizeOverrides={sizeOverrides}
                            {...panelProps}
                            comparison={
                              InnerPanel === SummaryPanel ? comparison : null
                            }
                          />
                        ) : (
                          []
                        )
                    )}
                  </div>
                );
              }

              return Panel ? (
                <div
                  className={className.join(' ')}
                  key={`${key}-panel-${index}`}
                  style={styleOverrides}
                >
                  <Panel
                    key={`${key}-panel-${index}`}
                    autoHeight={autoHeight}
                    size={!sizeOverrides && columnSize}
                    sizeOverrides={sizeOverrides}
                    comingSoon={comingSoon}
                    {...panelProps}
                    comparison={Panel === SummaryPanel ? comparison : null}
                  />
                </div>
              ) : (
                []
              );
            }
          )}
        </div>
      );
    };

    const renderDesktopPanels = () => {
      return Object.keys(desktopPanels).map((row, index) =>
        renderPanels(desktopPanels[row], `desktop-${index}`)
      );
    };

    const renderTabletPanels = () => {
      return Object.keys(tabletPanels).map((row, index) =>
        renderPanels(tabletPanels[row], `tablet-${index}`)
      );
    };

    const renderMobilePanels = () =>
      renderPanels(mobilePanels[currentTab], 'mobile');

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.content}>
            {setupCard}
            <div className="columns is-multiline">
              <div className="column">
                <Header
                  actions={
                    <a
                      style={{ marginTop: 'auto' }}
                      onClick={this.toggleFilter}
                    >
                      Show filter
                    </a>
                  }
                >
                  <AdminTitle text={title} />
                </Header>
              </div>
            </div>
            <div className="tile is-ancestor is-vertical">
              {!isMobile() &&
                !(window.outerWidth <= 1024) &&
                renderDesktopPanels()}
              {isTablet() && renderTabletPanels()}
              {isMobileNew() && renderMobilePanels()}
              {/* {panels} */}
            </div>
            <MobileTabs
              isStandardUser={isStandardUser}
              setTab={(tab) =>
                this.setState({ ...this.state, currentTab: tab })
              }
              currentTab={currentTab}
            />
            <FilterDrawer
              isOpen={this.state.showFilter}
              isStandardUser={isStandardUser}
              dispatch={dispatch}
              period={period}
              tier={tier}
              onToggleIsOpen={this.toggleFilter}
              accessToken={access_token}
              entityId={current_entity.id}
            />
          </div>
        </div>
      </section>
    );
  }
}

const chart_props = {
  total: {
    name: 'Total',
    key: 'total_started',
    fillcolor: STYLE_COLOR_BASE_GREY_LIGHTER,
    mode: 'none',
    fill: 'tozeroy',
  },
  approved: {
    name: 'Approved',
    key: 'approved',
    mode: 'lines',
    color: STYLE_COLOR_BASE_BLUE,
  },
  declined: {
    name: 'Declined',
    key: 'declined',
    mode: 'lines',
    color: STYLE_COLOR_BASE_RED,
  },
  incomplete: {
    name: 'Incomplete',
    key: 'incomplete',
    mode: 'lines',
    color: STYLE_COLOR_BASE_GREY_MEDIUM,
  },
  pending: {
    name: 'Pending',
    key: 'pending',
    mode: 'lines',
    color: STYLE_COLOR_BASE_BLUE_LIGHT,
  },
};

function sortDataByDate(data) {
  const sorted = data.sort((a, b) => {
    const dateA = a.attributes.updated_at;
    const dateB = b.attributes.updated_at;

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });

  return sorted;
}

function formatChartData(data) {
  let formattedData = {};
  const today = new Date();

  Object.keys(chart_props).forEach((v) => {
    formattedData = {
      ...formattedData,
      [chart_props[v].key]: {
        name: chart_props[v].name,
        x: [
          new Date(today.getFullYear(), today.getMonth()),
          new Date(today.getFullYear(), today.getMonth() - 1),
          new Date(today.getFullYear(), today.getMonth() - 2),
        ],
        y: [],
        fillcolor: chart_props[v].fillcolor,
        mode: chart_props[v].mode,
        fill: chart_props[v].fill,
        line: {
          color: chart_props[v].color,
          width: 3,
        },
        hoverinfo: 'y',
      },
    };
  });

  data.forEach((v, i) => {
    Object.keys(v).forEach((k) => {
      if (formattedData.hasOwnProperty(k)) {
        formattedData[k].y.push(data[i][k]);
      }
    });
  });

  const data_array = [];
  Object.keys(formattedData).forEach((v) => {
    data_array.push(formattedData[v]);
  });

  return data_array;
}

export default connect((state, ownProps) => {
  const data = state.dashboard.report || null;
  const report_overview = data.kpis || null;

  let applications_incomplete = data.incomplete_list || [];
  let applications_pending = data.to_review_list || [];

  // applications_pending = applications_pending.filter(a => a.attributes.archived === false);
  const applications_pending_total = data.to_review_total || 0;
  // applications_incomplete = applications_incomplete.filter(a => a.attributes.archived === false);
  const applications_incomplete_total = data.incomplete_total || 0;

  applications_pending = sortDataByDate(applications_pending);
  applications_incomplete = sortDataByDate(applications_incomplete);

  // Add formatted data to what's returned from the API
  applications_incomplete.forEach((v) => {
    // Format dates
    Object.keys(v.attributes).forEach((k) => {
      if (k.endsWith('_at')) {
        v.attributes[`${k}_formatted`] = (
          <DateString
            showNull={true}
            format={'DD/MM/YY'}
            value={v.attributes[k]}
          />
        );
      }
    });
  });

  //applications_pending = applications_pending.map((v) => {
  // Format dates
  applications_pending.forEach((v) => {
    Object.keys(v.attributes).forEach((k) => {
      if (k.endsWith('_at')) {
        v.attributes[`${k}_formatted`] = (
          <DateString
            showNull={true}
            format={'DD/MM/YY'}
            value={v.attributes[k]}
          />
        );
      }
    });
  });

  let formattedData = {};
  if (state.dashboard.report.trends) {
    formattedData = formatChartData(state.dashboard.report.trends);
  }

  const { tier: reduxTier, period: reduxPeriod } = state.dashboard;
  const currentUser = new UserModel.fromCurrentUser(get(state, 'current_user'));
  const currentEntityId = get(state, 'current_user.current_entity.id');
  const tier = reduxTier || loadFilterValues('tier', currentEntityId);
  const period = loadFilterValues('period', currentEntityId) || reduxPeriod;
  const current_user_entity_link = state.current_user.current_user_entity_link;
  const currentRoleTypes = current_user_entity_link.attributes.role_types;

  return {
    title: 'Dashboard',
    loading: state.dashboard.loading,
    chartData: formattedData,
    current_user: state.current_user.data.data,
    currentRoleTypes,
    isStandardUser: currentRoleTypes.includes('standard'),
    access_token: state.current_user.access_token,
    current_entity: state.current_user.current_entity,
    current_user_entity_link,
    applications_pending,
    applications_pending_total,
    applications_incomplete,
    applications_incomplete_total,
    isTrm: currentUser.trm,
    report: report_overview,
    monthly_report: state.dashboard.monthly_report,
    colorPalette: state.manage_profile.settings_color_palette,
    period,
    comparison: state.dashboard.comparison,
    tier,
  };
})(Home);
