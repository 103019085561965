import {
  COB_ADDITIONAL_COMPONENT_COMPLETE,
  COB_ADDITIONAL_LOAD_ANSWERS,
  COB_ADDITIONAL_SET_ANSWER,
  COB_ADDITIONAL_SET_FILE,
} from '../constants/additional';

export function setAdditionalAnswers(params) {
  return (dispatch) => {
    dispatch({
      payload: params,
      type: COB_ADDITIONAL_SET_ANSWER,
    });
  };
}

export function loadAdditionalAnswers(params) {
  const data = {
    addon_rule_id: params.attributes.addon_rule_id,
    answers: params.attributes.answers,
    file: params.attributes.file,
    id: params.id,
  };
  return (dispatch) => {
    dispatch({
      payload: data,
      type: COB_ADDITIONAL_LOAD_ANSWERS,
    });
  };
}

export function setAdditionalFile(data) {
  return {
    payload: data,
    type: COB_ADDITIONAL_SET_FILE,
  };
}

export function addtionalComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: 'Consumer onboarding',
          props: {
            Component: component,
            Section: 'additional',
            distinct_id: getState().current_user.data.data.id,
          },
        },
      };
    }

    dispatch({
      meta,
      payload: {
        component,
        state,
      },
      type: COB_ADDITIONAL_COMPONENT_COMPLETE,
    });
  };
}
