import get from 'lodash.get';
import PpsrFinancingStatementModel from 'models/PpsrFinancingStatementModel';
import {
  buildCollateralsAttributes,
  buildDebtorsAttributes,
  getAlertErrorMessage,
  validateArrayFields,
} from 'modules/new-applications/components/application-actions/PPSRRegister';
import PPSRModifyForm from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRModifyForm/PPSRModifyForm';
import { getModifyFinancingStatementValidationSchema } from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/validationSchema';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import Button from 'modules/shared/components/inputs/Button';
import moment from 'moment';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import isPresent from 'utils/isPresent';

export default function PPSRRegisterModify(props) {
  const {
    application,
    currentUser,
    onClickBackToDetails,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  } = props;

  const region = application.region;

  const {
    clearError,
    control,
    errors,
    handleSubmit,
    getValues,
    register,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      ...ppsrFinancingStatement.formValues,
      expiryDate: moment(ppsrFinancingStatement.expiryDate).format(
        'DD/MM/YYYY'
      ),
    },
    validationSchema: getModifyFinancingStatementValidationSchema(region),
  });
  const debtorFieldArray = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'debtors',
  });
  const collateralFieldArray = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'collaterals',
  });

  const { isLoading, setIsLoading } = useIsLoadingState();

  const onSubmit = (event) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (
      getValues('financingStatementPin') !==
      ppsrFinancingStatement.financingStatementPin
    ) {
      setError(
        'financingStatementPin',
        'required',
        'Please provide correct financing statement PIN.'
      );
    }

    validateArrayFields({
      clearError,
      getValues,
      setError,
      status: ppsrFinancingStatement.status,
    });

    if (isPresent(errors)) {
      return;
    }

    handleSubmit((data) => {
      const {
        collaterals = [],
        debtors = [],
        expiryDate,
        financingStatementPin,
        ...attributes
      } = data;

      setIsLoading(true);

      const collateralAttributes = buildCollateralsAttributes(collaterals);
      const debtorAttributes = buildDebtorsAttributes({ debtors, region });

      // Set expiry date for auto renew to original expiry date
      if (attributes.autoRenew) {
        attributes.expiryDate = ppsrFinancingStatement.expiryDate;
      } else {
        attributes.expiryDate = moment(expiryDate, 'DD/MM/YYYY').toDate();
      }

      attributes.ppsr_financing_statement_collaterals_attributes =
        collateralAttributes;
      attributes.ppsr_financing_statement_debtors_attributes = debtorAttributes;

      ppsrFinancingStatement.modify({
        attributes,
        currentUser,
        financingStatementPin,
        onErrorCallback: (error) => {
          onSetAlert({ message: getAlertErrorMessage(error), type: 'error' });

          const responsePPSRFinancingStatement = get(
            error,
            'response.data.ppsr_financing_statement'
          );
          if (responsePPSRFinancingStatement) {
            const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
              responsePPSRFinancingStatement.data,
              responsePPSRFinancingStatement.included
            );
            onSetPPSRFinancingStatement(newPpsrFinancingStatement);
          }

          setIsLoading(false);
        },
        onSuccessCallback: (newPpsrFinancingStatement) => {
          setIsLoading(false);
          onSetPPSRFinancingStatement(newPpsrFinancingStatement);
          onSetAlert({ message: 'Successfully modified.', type: 'success' });
          onClickBackToDetails();
        },
      });
    })();
  };

  return (
    <FixedContent>
      <form onSubmit={onSubmit}>
        <PPSRModifyForm
          actionText="modification"
          application={application}
          clearError={clearError}
          collateralFieldArray={collateralFieldArray}
          control={control}
          currentUser={currentUser}
          debtorFieldArray={debtorFieldArray}
          errors={errors}
          isCollateralTypeFieldEnabled={false}
          ppsrAddonConfig={application.ppsrAddonConfig || {}}
          ppsrFinancingStatement={ppsrFinancingStatement}
          region={application.region}
          register={register}
          setValue={setValue}
          watch={watch}
        />
        <div className="field is-grouped">
          <div className="control">
            <Button white text="Back" handleClick={onClickBackToDetails} />
          </div>
          <div className="control">
            <Button
              type="submit"
              text="Save"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </FixedContent>
  );
}
