import { FEATURE_FLAGS } from 'conf';
import { addFlaggedArray } from 'conf/flaggingHelpers';
import Application from 'modules/new-applications/components/Application';
import CreditControl from 'modules/new-applications/components/application-actions/CreditControl';
import DeleteArchive from 'modules/new-applications/components/application-actions/DeleteArchive';
import EmailLog from 'modules/new-applications/components/application-actions/EmailLog';
import HistoryLog from 'modules/new-applications/components/application-actions/HistoryLog';
import Notes from 'modules/new-applications/components/application-actions/Notes';
import PPSRRegister from 'modules/new-applications/components/application-actions/PPSRRegister';
import ReallocateReassign from 'modules/new-applications/components/application-actions/ReallocateReassign';
import Watchtower from 'modules/new-applications/components/application-actions/Watchtower';
import Additional from 'modules/new-applications/components/application-sections/Additional';
import AuthorisedApplicant from 'modules/new-applications/components/application-sections/AuthorisedApplicant';
import BusinessDetails_deprecated from 'modules/new-applications/components/application-sections/BusinessDetails_deprecated';
import Cards from 'modules/new-applications/components/application-sections/Cards';
import CreditChecks from 'modules/new-applications/components/application-sections/CreditChecks';
import DirectDebit from 'modules/new-applications/components/application-sections/DirectDebit';
import Financials from 'modules/new-applications/components/application-sections/Financials';
import Guarantors from 'modules/new-applications/components/application-sections/Guarantors';
import IdentificationChecks from 'modules/new-applications/components/application-sections/IdentificationChecks';
import IUF from 'modules/new-applications/components/application-sections/IUF';
import LimitAndApprovals from 'modules/new-applications/components/application-sections/LimitAndApprovals';
import LoadedInSystem from 'modules/new-applications/components/application-sections/LoadedInSystem';
import PDFList from 'modules/new-applications/components/application-sections/PDFList/PDFList';
import Signatories from 'modules/new-applications/components/application-sections/Signatories';
import TradeReferences from 'modules/new-applications/components/application-sections/TradeReferences';
import TradingDetails from 'modules/new-applications/components/application-sections/TradingDetails';
import BusinessDetails from 'modules/new-applications/components/application-sections/BusinessDetails/BusinessDetails';

const APPLICATION_SECTIONS = [
  {
    component: Additional,
    path: 'additional',
  },
  {
    component: AuthorisedApplicant,
    path: 'authorised_applicant',
  },
  {
    component: Cards,
    path: 'cards',
  },
  {
    component: CreditChecks,
    path: 'credit_checks',
  },
  {
    component: DirectDebit,
    path: 'direct_debit',
  },
  {
    component: Financials,
    path: 'financials',
  },
  {
    component: Guarantors,
    path: 'guarantors',
  },
  {
    component: Signatories,
    path: 'signatories',
  },
  {
    component: LimitAndApprovals,
    path: 'limit_and_approvals',
  },
  {
    component: LoadedInSystem,
    path: 'loaded_in_system',
  },
  {
    component: TradeReferences,
    path: 'trade_references',
  },
  {
    component: TradingDetails,
    path: 'trading_details',
  },
  {
    component: IdentificationChecks,
    path: 'identification_checks',
  },
  {
    component: IUF,
    path: 'internal_use_fields',
  },
];

const TOPBAR_ACTIONS = [
  {
    component: DeleteArchive,
    path: 'delete_archive',
  },
  {
    component: Notes,
    path: 'notes',
  },
  {
    component: ReallocateReassign,
    path: 'reallocate_reassign',
  },
  ...(!FEATURE_FLAGS.FEATURE_FLAG_ACCOUNT_MONITORING && [
    {
      component: CreditControl,
      path: 'credit_control',
    },
  ]),
  {
    component: PPSRRegister,
    path: 'ppsr_register',
  },
  {
    component: EmailLog,
    path: 'email_log',
  },
  {
    component: HistoryLog,
    path: 'history_log',
  },
  ...addFlaggedArray('FEATURE_FLAG_WATCHTOWER', [
    {
      component: Watchtower,
      path: 'watchtower',
    },
  ]),
  {
    component: PDFList,
    path: 'pdf',
  },
];

const ApplicationRoute = {
  childRoutes: [...APPLICATION_SECTIONS, ...TOPBAR_ACTIONS],
  component: Application,
  indexRoute: {
    component: FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER
      ? BusinessDetails
      : BusinessDetails_deprecated,
  },
  path: 'applications/:application_id',
};

export default ApplicationRoute;
