import React, { Fragment } from 'react';
import styles from './report-styles.css';
import moment from 'moment';
import { formatDate } from 'utils/dateFormatter';
import { getCharacteristicsData } from './utils';

const ReportHeader = (props) => {
  let { address, characteristic, individualName, primaryMatch, productHeader } =
    props;

  let ageOfFile = getCharacteristicsData(characteristic, 'NA8904'),
    numOfCrossRef = getCharacteristicsData(characteristic, 'NA8900');

  if (address.constructor === Array) {
    address = address.find((address) => address['@type'] === 'C');
  }
  let refDesc =
    numOfCrossRef && numOfCrossRef.value > 0
      ? `(+ ${numOfCrossRef.value} cross-reference)`
      : '';
  let renderAddress = (address) => {
    return (
      <Fragment>
        {address.street_number} {address.street_name} {address.street_type},{' '}
        {address.suburb} {address.state} {address.postcode}
      </Fragment>
    );
  };
  return (
    <div className={styles.header}>
      <div className={styles.row}>
        <div className={styles.col_7}>
          <div className={styles.row}>
            <div className={styles.header_title}>Report for:</div>
            <div className={styles.header_value}>
              {individualName.first_given_name} {individualName.family_name}{' '}
              {refDesc}
              <br />
              D.O.B.{' '}
              {formatDate(primaryMatch.date_of_birth, 'DD MMM YYYY') || '-'}
              <br />
              <br />
              {address && renderAddress(address)}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.header_title}>Age of file:</div>
            <div className={styles.header_value}>
              {ageOfFile && ageOfFile.value ? Math.round(ageOfFile.value) : '-'}{' '}
              month(s)
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.header_title}>Permission type:</div>
            <div className={styles.header_value}>
              {productHeader.permission_type}
            </div>
          </div>
        </div>

        <div className={styles.col_5}>
          <div className={styles.row}>
            <div className={styles.header_title}>Data level:</div>
            <div className={styles.header_value}>
              {productHeader.product_data_level}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.header_title}>Data generated:</div>
            <div className={styles.header_value}>
              {moment(productHeader.datetime_generated)
                .parseZone()
                .format('DD MMM YYYY - HH:mm')}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.header_title}>Transaction ID:</div>
            <div className={styles.header_value}>
              {productHeader.enquiry_id}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
