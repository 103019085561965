import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import RuleSetSection from './RuleSetSection';

type Props = {
  title: string;
  text: string;
  disabled?: boolean;
};

export const RuleSetSectionTextOnly: FC<Props> = (props) => {
  const { title, text, disabled } = props;
  return (
    <RuleSetSection disabled={disabled} title={title}>
      <Typography>{text}</Typography>
    </RuleSetSection>
  );
};
