import React, { ReactElement } from 'react';
import PopperToolTip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'modules/dashboard/components/styles';

interface Props {
  children: React.ReactNode;
}

function Tooltip({ children }: Props): ReactElement {
  return (
    <span className="ml-2">
      <PopperToolTip
        extraComponent={<div className="has-text-left">{children}</div>}
      >
        <Icon>
          <FontAwesomeIcon icon={['fas', 'question-circle']} />
        </Icon>
      </PopperToolTip>
    </span>
  );
}

export default Tooltip;
