import axios from './axios';

let CreditCheckCredentials = function(config) {
  config.timeout = 20000;
  this.axios = axios(config);
};

CreditCheckCredentials.prototype.test = function(attributes, success, error) {
  return this.axios
    .post('/test_credit_check_credentials', attributes)
    .then(success)
    .catch(error);
};

export default CreditCheckCredentials;
