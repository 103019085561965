import React from 'react';

import { connect } from 'react-redux';

import Webhook from 'models/webhooks/Webhook';

import WebhookForm from '../forms/WebhookForm';

class NewWebhook extends React.Component {
  render() {
    let webhook = new Webhook();
    return <WebhookForm webhook={webhook} />;
  }
}

module.exports = connect((state, ownProps) => {
  return {};
})(NewWebhook);
