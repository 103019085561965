import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import React, { ReactElement } from 'react';

import { emailDomainsOptions, entityTypeOptions } from '../constants';
import {
  WatchtowerBoldItalicText,
  WatchtowerDynamicFieldsTitle,
} from '../styles';

const EmailDomainsInput = (props): ReactElement => {
  const { value, onChange, ...inputProps } = props;

  const { domain_list, entity_types } = value;
  const onDomainListChange = (target) => {
    onChange({
      ...value,
      domain_list: target.value,
    });
  };

  const onEntityTypeChange = (target) => {
    onChange({
      ...value,
      entity_types: target.value,
    });
  };

  return (
    <ContentContainer>
      <div className="pb-4">
        <div className={`${styles.info} pb-4`}>
          <WatchtowerDynamicFieldsTitle>
            Email client domain
          </WatchtowerDynamicFieldsTitle>
          {'Please select the email domain you would like to be alerted'}
        </div>
        <div className="columns">
          <div className="column is-6">
            <SimpleMultiSelectDropdown
              options={emailDomainsOptions}
              id="email-domains-select"
              multiple
              placeholder="Email client domain"
              onChange={onDomainListChange}
              value={domain_list}
              {...inputProps}
              disabled={props.disabled}
              bulkSelect
            />
          </div>
        </div>
      </div>
      <div className="pb-4 mt-4">
        <div className={`${styles.info} pb-4`}>
          <p>
            Please select the entity types applicable for{' '}
            <WatchtowerBoldItalicText>
              Email client domain
            </WatchtowerBoldItalicText>{' '}
            alerts.
          </p>
        </div>
        <div className="columns">
          <div className="column is-6">
            <SimpleMultiSelectDropdown
              options={entityTypeOptions}
              id="entity-type-select"
              multiple
              placeholder="Entity type"
              onChange={onEntityTypeChange}
              value={entity_types}
              {...inputProps}
              disabled={props.disabled}
              bulkSelect
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default EmailDomainsInput;
