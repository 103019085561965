import ModulePaperlessAuthorisationFlowModel from 'models/ModulePaperlessAuthorisationFlowModel';
import styles from 'modules/paperless-authorisation/css/PaperlessAuthorisation.css';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import ApplicationProgressDots from 'modules/shared/components/widgets/static/ApplicationProgressDots';
import Loader from 'modules/shared/components/widgets/static/Loader';
import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';

import { componentFactory } from '../index';

const flow = ['abstract', 'authorisation', 'complete'];
const progressDotLables = ['Abstract', 'Authorisation'];

function usePaperlessAuthorisationRecord() {
  const [paperlessAuthorisation, setPaperlessApplication] = useState(
    new ModulePaperlessAuthorisationFlowModel()
  );

  return { paperlessAuthorisation, setPaperlessApplication };
}

export default function PaperlessAuthorisation(props) {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const {
    paperlessAuthorisation,
    setPaperlessAuthorisation,
  } = usePaperlessAuthorisationRecord();

  const paperlessAuthorisationId = props.params.paperless_authorisation_id;

  const redirect = (sectionIndex) => {
    const { location } = props;
    browserHistory.push({
      pathname: `/paperless-authorisation/${paperlessAuthorisationId}/${flow[sectionIndex]}`,
      query: location.query,
    });
  };

  const links = progressDotLables.map((sectionLable) => {
    return { label: sectionLable };
  });

  const toNextSection = () => {
    setCurrentSectionIndex(currentSectionIndex + 1);
    redirect(currentSectionIndex + 1);
  };

  const toLastSection = () => {
    setCurrentSectionIndex(2);
    redirect(2);
  };

  const childComponentProps = {
    paperlessAuthorisation,
    paperlessAuthorisationId,
    toLastSection,
    toNextSection,
  };

  useEffect(() => {
    setShowLoading(true);
    (async () => {
      const result = await paperlessAuthorisation.load(
        props.params.paperless_authorisation_id
      );
      if (result.status === 200) setShowLoading(false);
    })();
  }, []);

  let contant = <Loader />;
  if (!showLoading) {
    contant = (
      <div className={styles.application_content}>
        {[0, 1].includes(currentSectionIndex) && (
          <div className={styles.controls}>
            <ApplicationOverlay
              trading_name={paperlessAuthorisation.attributes.supplier_name}
              logo_url={paperlessAuthorisation.attributes.logo_url}
              label={'Direct debit authorisation'}
            />
            <ApplicationProgressDots
              type="horizontal"
              index={currentSectionIndex}
              links={links}
            />
          </div>
        )}
        {componentFactory(flow[currentSectionIndex], childComponentProps)}
      </div>
    );
  }
  return <div className={styles.container}>{contant}</div>;
}
