/**
 * Validates whether a given value exists as a valid value in the provided enum object.
 * @template T - The type of the enum values.
 * @param {string} value - The value to be validated.
 * @param {Record<string, T>} enumObject - The enum object to compare the value against.
 * @returns {T | undefined} - The validated value if it exists in the enum, otherwise undefined.
 */

export const validateEnumValue = <T extends string>(
  value: string,
  enumObject: Record<string, T>
): T | undefined => {
  if (Object.values(enumObject).includes(value as T)) {
    return value as T;
  }
  return undefined;
};
