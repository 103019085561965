import 'c3/c3.css';

import Popover from '@material-ui/core/Popover';
import get from 'lodash.get';
import moment from 'moment';
import React, { Component } from 'react';
import C3Chart from 'react-c3js';
import { formatMoney } from 'utils/formatting';
import isBlank from 'utils/isBlank';
import objectToArray from 'utils/objectToArray';

import { DDS_SCORE_RANGE } from '../constants';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';
import styles from '../css/payment-predictor/PaymentRiskAnalysis.css';
import {
  getRiskClass,
  getRiskClassProbabilityOfLatePayment,
} from '../PaymentPredictorComponent';

export default class PaymentRiskAnalysis extends Component {
  get companyBasicDetails() {
    return get(this.props, 'response.company_basic_details') || {};
  }

  get dynamicDelinquencyScore() {
    return get(this.props, 'response.dynamic_delinquency_score') || {};
  }

  get ddsTrend() {
    return objectToArray(
      (this.dynamicDelinquencyScore.dds_trend || {}).dds_trend_month
    );
  }

  get reportDate() {
    return moment(this.props.sequence_date).format('MMM YYYY');
  }

  get keyInfluencingFactors() {
    return objectToArray(
      (this.dynamicDelinquencyScore.key_influencing_factors || {})
        .key_influencing_factor
    );
  }

  get companyRiskComparisonProbability() {
    const probability = this.dynamicDelinquencyScore.sev_delinq_pay_probability;
    const riskClassProbability = getRiskClassProbabilityOfLatePayment(
      this.dynamicDelinquencyScore.score
    );

    return riskClassProbability / parseFloat(probability);
  }

  get companyDDS() {
    const riskLevel = this.dynamicDelinquencyScore.risk_level;
    const description = `Entities in the ${riskLevel} category are ${formatMoney(
      this.companyRiskComparisonProbability,
      2
    )} times less likely to pay late in the next 12 months than average.`;

    return {
      backgroundColor: this.color,
      description,
      name: this.companyBasicDetails.organisation_name,
      probability: this.dynamicDelinquencyScore.sev_delinq_pay_probability,
      riskLevel,
      score: this.dynamicDelinquencyScore.score,
      textColor: '#fff',
    };
  }

  get industryDDS() {
    const score = this.dynamicDelinquencyScore.industry_score;
    const probability = this.dynamicDelinquencyScore
      .industry_sev_delinq_pay_probability;
    const riskClassProbability = getRiskClassProbabilityOfLatePayment(score);
    const industryRiskProbability =
      riskClassProbability / parseFloat(probability);
    const riskComparisonProbability =
      parseFloat(this.companyRiskComparisonProbability) /
      parseFloat(industryRiskProbability);
    const riskClass = getRiskClass(score);
    const riskLevel = (DDS_SCORE_RANGE[riskClass] || {}).riskLevel;
    const description = `${
      this.companyBasicDetails.organisation_name
    } is ${formatMoney(
      riskComparisonProbability,
      2
    )} times less likely to pay late in the next 12 months than the average entity in this industry`;

    return {
      backgroundColor: '#ebebeb',
      description,
      name: 'INDUSTRY AVERAGE',
      probability,
      riskLevel,
      score,
    };
  }

  get color() {
    const riskClass = getRiskClass(this.dynamicDelinquencyScore.score);

    return (DDS_SCORE_RANGE[riskClass] || {}).color;
  }

  get chartData() {
    const companyData = [];
    const industryData = [];

    for (const trend of this.ddsTrend) {
      companyData.push(trend.score || 0);
      industryData.push(trend.industry_score || 0);
    }

    return [
      [this.companyBasicDetails.organisation_name, ...companyData],
      ['Industry', ...industryData],
    ];
  }

  get chartXAxis() {
    let year = '';

    return this.ddsTrend.map((trend) => {
      const value = moment()
        .month(parseInt(trend.month) - 1)
        .format('MMM');
      if (year !== trend.year) {
        year = trend.year;
        return `${value} ${year}`;
      }

      return value;
    });
  }

  get chartYAxis() {
    const keys = ['G', 'F', 'E', 'D', 'C', 'B', 'A'];
    const values = keys.map((key) => DDS_SCORE_RANGE[key].max);

    return [DDS_SCORE_RANGE['H'].min, ...values];
  }

  constructor(props) {
    super(props);

    this.state = { popoverAnchorElement: null };
  }

  onScoreMouseOver = (event) => {
    this.setState({ popoverAnchorElement: event.currentTarget });
  };

  onScoreMouseOut = () => {
    this.setState({ popoverAnchorElement: null });
  };

  renderDDS(dds, options = { isLegendVisible: false }) {
    if (isBlank(dds)) {
      return null;
    }

    const scoreStyle = { backgroundColor: dds.backgroundColor };

    if (dds.textColor) {
      scoreStyle.color = dds.textColor;
    }

    let legend;
    let mouseOverEvent;
    let mouseOutEvent;
    if (options.isLegendVisible) {
      legend = this.renderDDSLegend();
      mouseOverEvent = this.onScoreMouseOver;
      mouseOutEvent = this.onScoreMouseOut;
    }

    return (
      <div className={styles.dds_container}>
        <div className={styles.dds_score_container}>
          <div style={scoreStyle} className={styles.dds_score}>
            <div
              className={commonStyles.emphasize}
              onMouseOver={mouseOverEvent}
              onMouseOut={mouseOutEvent}
            >
              {dds.score}
            </div>
            {legend}
          </div>
          <div className={styles.dds_score_label}>
            <div className={commonStyles.emphasize}>{dds.name}</div>
            <div>{dds.riskLevel} risk</div>
          </div>
        </div>
        <div>{dds.description}</div>
        <div className={styles.dds_probability_container}>
          <div className={styles.dds_probability_label}>
            Probability of late payments
          </div>
          <div style={scoreStyle} className={styles.dds_probability}>
            <span className={commonStyles.emphasize}>{dds.probability}%</span>
          </div>
        </div>
      </div>
    );
  }

  renderDDSLegend() {
    const { popoverAnchorElement } = this.state;

    return (
      <Popover
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className={commonStyles.popover}
        disableRestoreFocus
        open={Boolean(popoverAnchorElement)}
        transitionDuration={0}
        onClose={this.onScoreMouseOut}
      >
        {this.renderKeyToScores()}
      </Popover>
    );
  }

  renderLatePaymentRisk() {
    return (
      <div className={styles.late_payment_risk_container}>
        <div className={styles.late_payment_risk_date}>{this.reportDate}</div>
        {this.renderDDS(this.companyDDS, { isLegendVisible: true })}
        {this.renderDDS(this.industryDDS)}
      </div>
    );
  }

  renderDDSTrend() {
    if (this.ddsTrend.length === 0) {
      return;
    }

    const data = {
      columns: this.chartData,
      type: 'line',
      types: {
        Industry: 'area',
      },
    };

    const axisProperties = {
      x: {
        categories: this.chartXAxis,
        type: 'category',
      },
      y: {
        max: DDS_SCORE_RANGE['A'].max,
        min: DDS_SCORE_RANGE['H'].min,
        tick: {
          values: this.chartYAxis,
        },
      },
    };

    const gridProperties = {
      x: {
        show: true,
      },
      y: {
        show: true,
      },
    };

    const tooltipProperties = {
      show: false,
    };

    return (
      <C3Chart
        data={data}
        axis={axisProperties}
        grid={gridProperties}
        tooltip={tooltipProperties}
      />
    );
  }

  renderKeyInfluencingFactors() {
    const factors = this.keyInfluencingFactors.map((factor, i) => (
      <li key={`factor-${i}`}>{factor.desc}</li>
    ));

    return (
      <div className={styles.key_influencing_factors_container}>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Key influencing factors</p>
        </div>
        <ul className={commonStyles.list}>{factors}</ul>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className={styles.notes_container}>
        <p className={commonStyles.emphasize}>Notes</p>
        <p className={commonStyles.italics}>
          * Indications of slowness can be the result of disputes over
          merchandise, skipped invoices etc.
        </p>
        <div className={styles.notes}>
          D&amp;B&apos;s late payment score predicts the likelihood that a
          business will pay late within the next 12 months. The average entity
          has&nbsp;
          {this.dynamicDelinquencyScore.risk_level} entity has a&nbsp;
          {this.industryDDS.probability}% probability of late payment.
        </div>
      </div>
    );
  }

  renderKeyToScores() {
    const riskClasses = Object.keys(DDS_SCORE_RANGE);
    const keyToScores = riskClasses.map((riskClass, i) => {
      const score = DDS_SCORE_RANGE[riskClass] || {};

      return (
        <tr key={`key-to-score-${i}`}>
          <td
            style={{ backgroundColor: score.color, color: '#fff' }}
          >{`${score.min} - ${score.max}`}</td>
          <td>{riskClass}</td>
          <td>{score.riskLevel}</td>
          <td className={commonStyles.highlight_cell}>
            {score.probability_of_late_payment}%
          </td>
        </tr>
      );
    });

    return (
      <table className={`${commonStyles.table} ${styles.key_to_scores_table}`}>
        <thead>
          <tr>
            <th>Score range</th>
            <th>Risk class</th>
            <th>Relative risk level</th>
            <th>Probability of severe late payment</th>
          </tr>
        </thead>
        <tbody>{keyToScores}</tbody>
      </table>
    );
  }

  render() {
    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Late payment risk</p>
        </div>
        {this.renderLatePaymentRisk()}
        {this.renderDDSTrend()}
        {this.renderKeyInfluencingFactors()}
        {this.renderNotes()}
      </div>
    );
  }
}
