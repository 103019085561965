import React from 'react';
import { connect } from 'react-redux';
import StatusIndicator from '../../applications/components/StatusIndicator';
import DateString from 'modules/shared/components/widgets/static/DateString';
import { Link } from 'react-router';
import Button from 'modules/shared/components/inputs/Button';
import styles from './css/StatusOverview.css';
import { publishMessage } from '../../notifications/actions';
import get from 'lodash.get';
import isBlank from 'utils/isBlank';

function TradingNames(props) {
  const { tradingNames } = props;

  return tradingNames.map((tradingName, i) => (
    <tr key={`trading-name-${tradingName}-${i}`}>
      <td className="is-size-normal">{`T/A ${i + 1}:`}</td>
      <td colSpan={6} className="is-size-normal">
        {tradingName}
      </td>
    </tr>
  ));
}

const StatusOverview = createClass({
  informSupplier() {
    const { dispatch, application } = this.props;
    dispatch(
      publishMessage({
        text: `Trade reference checks for <strong>${application.attributes.consumer_name}</stong> have been completed.`,
        style: 'application',
        channel: 'user',
        channel_target: application.supplier_contact.id,
        intent: 'view_application',
        intent_target: application.id,
      })
    );
  },

  render() {
    let modDate;
    const { application, loading } = this.props;
    const trms = application.attributes.checks || [];

    let complete = true;
    let loader;

    modDate = application.attributes.submission_modified_at;

    const consumerTradingNames = get(
      application,
      'attributes.trading_names',
      []
    );

    const rows = consumerTradingNames.map((tradingName, i) => (
      <tr key={`trading-name-${tradingName}-${i}`}>
        <td className="is-size-normal">{`T/A ${i + 1}:`}</td>
        <td colSpan={6} className="is-size-normal">
          {tradingName}
        </td>
      </tr>
    ));

    trms.forEach((t, i) => {
      let status;

      if (t.outcome_text) {
        status = t.outcome_text;
      } else {
        status = 'pending';
        complete = false;
      }

      let startDate;
      let endDate;

      startDate = t.created_at;
      endDate = t.updated_at;

      let button;
      let link;
      let text;

      link = `/trm/review/${t.trade_reference_id}`;

      if (!t.outcome_text) {
        text = 'start';
      } else {
        if (t.outcome_text && t.outcome_text === 'deferred') {
          text = 'finish';
        } else {
          text = 'edit';
        }
      }

      button = <Link to={link}> {text}</Link>;

      rows.push(
        <tr key={i}>
          <td width="128">{button}</td>
          <td width="122">Referee {i + 1}</td>
          <td width="250">
            {t.first_name} {t.last_name}
          </td>
          <td width="200">
            <StatusIndicator status={status} long={true} />
          </td>
          <td width="120" className={styles.letter_spacing}>
            <DateString showNull={true} format={'D/M/YY'} value={modDate} />
          </td>
          <td width="120" className={styles.letter_spacing}>
            <DateString showNull={true} format={'D/M/YY'} value={startDate} />
          </td>
          <td className={styles.letter_spacing}>
            <DateString showNull={true} format={'D/M/YY'} value={endDate} />
          </td>
        </tr>
      );
    });

    if (complete) {
      rows.push(
        <tr key={'review-link'}>
          <td></td>
        </tr>
      );
    }

    return (
      <table className={styles.statusOverview}>
        <tbody>{rows}</tbody>
      </table>
    );
  },
});

module.exports = connect((state, ownProps) => {
  return {
    loading: state.notifications.messaging,
  };
})(StatusOverview);
