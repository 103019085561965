import React from 'react';
import { IHistory, TrailLogItem } from '../type';
import './EditHistory.scss';

interface IProps {
  history: IHistory;
  trailLogs: TrailLogItem[];
  activeTab: string;
}

const EditHistory = ({
  history,
  trailLogs,
  activeTab,
}: IProps): JSX.Element => {
  const { name, updatedTime } = history;

  if (!name || !updatedTime) return <></>;

  return (
    <div className="editHistory">
      <div className="trailLogs">
        <ul>
          {trailLogs
            .map(({ status_changed, updated_by_content, history_type }) => {
              return (
                <>
                  <li>{status_changed}</li>
                  <li>
                    {history_type?.includes(activeTab)
                      ? updated_by_content
                      : null}
                  </li>
                </>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default EditHistory;
