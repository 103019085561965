import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  margin-top: 40px;
  padding-bottom: 5px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const CodeBlock = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 2px solid var(--main-color);
  margin-right: 10px;
  border-radius: 4px;
  background-color: white;
`;

export const CodeBlockShadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;

export const CodeBlockError = styled.div`
  height: 0;
`;
