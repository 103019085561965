import React from 'react';
import { connect } from 'react-redux';

import Integration from 'models/integrations/Integration';

import TextInput from 'modules/shared/components/inputs/TextInput';
import TextField from '@material-ui/core/TextField';
import Button from 'modules/shared/components/inputs/Button';

import styles from '../../css/Integrations.css';
import unleashedStyles from '../../css/UnleashedIntegrations.css';

const INTEGRATION_SERVICE = 'unleashed';
const ID_NAME_MAPPING = { access_token: 'API Id', access_secret: 'API Key' };

//
// This component is directly connected to Redux instead of passing `prop`
//   from the parent component `IntegrationItem`. This is because of
//   `redux-idle-monitor` wherein it triggers sending props every time
//   and it breaks the default behaviour
//
class UnleashedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpdateButtonDisabled: true,
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleConnect = this.handleConnect.bind(this);
  }

  handleChange(e) {
    const { integration } = this.props;
    const value = e.target.value,
      id = e.target.id,
      data = { [id]: value };

    this.setState({ isUpdateButtonDisabled: false });
    integration.updateAttributeState(data);
    this.validateField(id);
  }

  _connectedDate() {
    const { integration } = this.props;
    let content = null;

    if (!integration.loading && integration.connected()) {
      content = (
        <p className={styles.metadata}>
          Connected {integration.connectedDate()}
        </p>
      );
    }

    return content;
  }

  validateField(key) {
    const { integration } = this.props;
    const { errors } = this.state,
      value = integration.attributes[key] || '';

    let isValid = true;

    if (value.trim().length > 0) {
      if (errors.hasOwnProperty(key)) delete errors[key];
    } else {
      errors[key] = `${ID_NAME_MAPPING[key]} is required`;
      isValid = false;
    }

    this.setState({ errors });
    return isValid;
  }

  isValid() {
    const keys = Object.keys(ID_NAME_MAPPING),
      isAllValid = [];

    for (const key of keys) {
      isAllValid.push(this.validateField(key));
    }

    return isAllValid.every((isValid) => {
      return isValid;
    });
  }

  handleConnect() {
    const { integration } = this.props;

    integration.attributes['service'] = INTEGRATION_SERVICE;
    this.handleSave();
  }

  handleSave() {
    const { integration } = this.props;

    if (!this.isValid()) return;

    integration.save(this._disableUpdateButton.bind(this));
  }

  _disableUpdateButton() {
    this.setState({ isUpdateButtonDisabled: true });
  }

  _connectButton() {
    const { integration, connecting } = this.props;

    return (
      <div className={unleashedStyles.connect_button}>
        <Button
          className={styles.text_button}
          text="connect"
          handleClick={this.handleSave}
          loading={connecting[integration.attributes.service]}
          loading_text="connecting"
        />
      </div>
    );
  }

  _disconnectButton() {
    const { integration, connecting } = this.props;

    return (
      <div className={unleashedStyles.connect_button}>
        <Button
          className={styles.text_button_red}
          text="disconnect"
          handleClick={integration.delete.bind(integration)}
          loading={connecting[integration.attributes.service]}
          loading_text="disconnecting"
        />
      </div>
    );
  }

  _connectionButton() {
    const { integration, loading } = this.props;
    const connected = integration.connected();

    let content = null;

    if (!loading) {
      content = connected ? this._disconnectButton() : this._connectButton();
    }

    return content;
  }

  _updateButton() {
    const { integration } = this.props;

    let content = null;

    if (integration.connected()) {
      content = (
        <div className={unleashedStyles.save_button}>
          <Button
            className={styles.text_button}
            text="update"
            handleClick={this.handleSave}
            disabled={this.state.isUpdateButtonDisabled}
          />
        </div>
      );
    }

    return content;
  }

  render() {
    const { integration } = this.props;
    const { errors } = this.state;

    const tokenTextFieldStyle = integration.attributes.access_token
      ? styles.card_shrink
      : styles.card_input;
    const secretTextFieldStyle = integration.attributes.access_secret
      ? styles.card_shrink
      : styles.card_input;

    return (
      <div className={styles.card}>
        <div className={`${styles.card_header} ${styles.unleashed_color}`}>
          <img
            src={integration.logoSrc()}
            alt={integration.attributes.service}
            className={styles.logo}
          />
        </div>
        <div className={styles.card_body}>
          <div className={styles.service_name}>Unleashed</div>
          <div>
            <div>Your API access can be found at</div>
            <div style={{ overflow: 'hidden' }}>
              <a href="https://ap.unleashedsoftware.com/v2/Integration/Api">
                https://ap.unleashedsoftware.com/v2/Integration/Api
              </a>
            </div>
          </div>
          {this._connectedDate()}
          <div>
            <TextField
              id="access_token"
              classes={{ root: tokenTextFieldStyle }}
              error={errors.access_token}
              onChange={this.handleChange}
              label="API Id"
              value={integration.attributes.access_token}
            />
            <TextField
              id="access_secret"
              classes={{ root: secretTextFieldStyle }}
              error={errors.access_secret}
              onChange={this.handleChange}
              label="API Key"
              value={integration.attributes.access_secret}
            />
          </div>
          <div className={styles.card_action}>
            {this._updateButton()}
            {this._connectionButton()}
          </div>
        </div>
      </div>
    );
  }
}

/* eslint-disable no-unused-vars */
// `ownProps` is unused
module.exports = connect((state, ownProps) => {
  let integration = state.models.integrations[INTEGRATION_SERVICE];

  if (integration === null || typeof integration === 'undefined') {
    integration = new Integration({
      attributes: { service: INTEGRATION_SERVICE },
    });
  }

  return {
    loading: state.models.integrations.loading,
    connecting: state.models.integrations.connecting,
    integration,
  };
})(UnleashedComponent);
/* eslint-enable no-unused-vars */
