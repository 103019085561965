// import Layout from "modules/user/containers/Layout";
import ApplicationDetails from 'modules/consumer-onboarding/v2/ApplicationDetails';
import Container from 'modules/consumer-onboarding/v2/Container';
import Verification from 'modules/consumer-onboarding/v2/Verification';
import { V2OnboardingUserAuthenticated } from 'modules/shared/auth';

const V2ConsumerOnboardingRoutes = {
  childRoutes: [
    {
      component: V2OnboardingUserAuthenticated(ApplicationDetails),
      path: 'application-details',
    },
  ],
  component: Container,
  indexRoute: {
    component: Verification,
  },
  path: 'onboarding',
};

export default V2ConsumerOnboardingRoutes;
