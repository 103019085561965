/* Import libraries */
import React from 'react';
import { browserHistory } from 'react-router';

import Clickable from './Clickable';

import styles from './css/Tabs.css';

class Tabs extends React.Component {
  switchTab(path) {
    const { base_path, active_path } = this.props;

    if (active_path.includes(path)) {
      return;
    }

    browserHistory.push(base_path + path);
  }

  calculateTabStyle(tab) {
    const { base_path, active_path } = this.props;

    let full_path = base_path + tab.path;

    if (active_path.indexOf(full_path) == 0) {
      return styles.tab_active;
    }
    return styles.tab_default;
  }

  tabList() {
    const { tab_list } = this.props;

    if (!tab_list) {
      return null;
    }

    let tabs = [];
    tab_list
      .filter((tab) => Boolean(tab))
      .forEach((tab) => {
        tabs.push(
          <Clickable
            key={tab.path}
            target={this.switchTab.bind(this, tab.path)}
          >
            <span className={this.calculateTabStyle(tab)}>{tab.title}</span>
          </Clickable>
        );
      });

    return tabs;
  }

  render() {
    return <div className={styles.tabs}>{this.tabList()}</div>;
  }
}

export default Tabs;
