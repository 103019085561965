/* Import actions */
import {
  guarantorLogin,
  loadGuarantorInfo,
  setApproval,
  setReviewIndex,
  submitNoIdentification,
  updateGuarantor,
} from '../actions';

/* Import libraries */
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

/* Import CSS */
import styles from './css/Review.css';

/* Import components */
import AccordianContainer from 'modules/shared/components/containers/AccordianContainer';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import ApplicationProgressDots from 'modules/shared/components/widgets/static/ApplicationProgressDots';
import ArrowButton from 'modules/shared/components/widgets/interactive/ArrowButton';
import Button from 'modules/shared/components/inputs/Button';
import Loader from 'modules/shared/components/widgets/static/Loader';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import ReviewAdditional from './ReviewAdditional';
import ReviewBusiness from './ReviewBusiness';
import ReviewFinancials from './ReviewFinancials';
import ReviewGuarantors from './ReviewGuarantors';
import ReviewPaperless from './ReviewPaperless';
import ReviewPaymentInfo from './ReviewPaymentInfo';
import ReviewQuestions from './ReviewQuestions';
import ReviewTerms from './ReviewTerms';
import ReviewTradeReferences from './ReviewTradeReferences';
import SectionCircle from 'modules/shared/components/widgets/static/SectionCircle';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import { isMobile } from 'modules/shared/helpers/mobileDetect';

var Review = createClass({
  componentWillMount: function() {
    const {
      dispatch,
      location: {
        query: { g, t },
      },
    } = this.props;
    // if (g && t) {
    //   dispatch(guarantorLogin(g, t, () => {
    //     dispatch(loadGuarantorInfo(g));
    //   }));
    // }
  },

  updateContainerIndex: function(index) {
    const { dispatch, container_index } = this.props;
    if (container_index === index) {
      index = null;
    }
    dispatch(setReviewIndex(index));
  },

  getInitialState: function() {
    return {
      showLoader: true,
    };
  },

  loadingComplete: function() {
    this.setState({
      showLoader: false,
    });
  },

  decline() {
    const { dispatch, location } = this.props;
    let nextPath = '/guarantor/complete';
    dispatch(setApproval(false));
    dispatch(
      updateGuarantor(location.query.g, false, () => {
        browserHistory.push({
          pathname: nextPath,
          query: location.query,
        });
      })
    );
  },

  next: function() {
    const { dispatch, location, guarantor } = this.props;
    if (
      guarantor.application.attributes.requires_guarantees_identification_check
    ) {
      let nextPath = '/guarantor/identity/capture';
      browserHistory.push({
        pathname: nextPath,
        query: location.query,
      });
    } else {
      dispatch(
        submitNoIdentification(location.query.g, () => {
          browserHistory.push({
            pathname: '/guarantor/terms',
            query: location.query,
          });
        })
      );
    }
  },

  _reviewButton() {
    const { guarantor } = this.props;

    let applicationIncompleteStates = ['not_started', 'in_progress'],
      application = guarantor.application.attributes,
      applicationStatus = application.status;

    if (guarantor.attributes.approved) return null;

    if (
      applicationIncompleteStates.includes(applicationStatus) &&
      !application.archived
    ) {
      return (
        <div className={styles.btn_next}>
          <Button text="decline" handleClick={this.decline} />
          <ArrowButton text="next" handleClick={this.next} icon="&darr;" />
        </div>
      );
    }

    return (
      <div>
        A decision has been made for this application and can no longer be
        approved.
      </div>
    );
  },

  componentGetDots: function() {
    const { guarantor } = this.props;
    if (isMobile() && !guarantor.attributes.approved) {
      var id_flow = guarantor.application.attributes
        .requires_guarantees_identification_check
        ? 2
        : 0; // guarantor id section
      let mobile_index = 1; // default 1 is review
      let mobile_pages = 3 + id_flow; // default 3 is review, terms and confirm

      return (
        <ApplicationProgressDots
          type="horizontal"
          mobile_pages={mobile_pages}
          mobile_index={mobile_index}
        />
      );
    }
    return;
  },

  render: function() {
    const {
      title,
      loading,
      guarantor,
      options,
      subTitle,
      container_index,
      supplier_logo_url,
      ...rest
    } = this.props;
    const { showLoader } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <SectionCircle key="1" title="">
            <SectionDescription
              text={`It's not available anymore. Please contact <a href="mailto:support@1centre.com">support@1centre.com</a>`}
            />
          </SectionCircle>
        </div>
      </div>
    );

    // if (showLoader) {
    //   return (<Loader handleComplete={this.loadingComplete} loading={!guarantor || loading} />);
    // }

    // var sections = [],
    //   components = {
    //     business: ReviewBusiness,
    //     trade_references: ReviewTradeReferences,
    //     guarantors: ReviewGuarantors,
    //     questions: ReviewQuestions,
    //     financials: ReviewFinancials,
    //     additional: ReviewAdditional,
    //     paperless: ReviewPaperless,
    //     payment_info: ReviewPaymentInfo,
    //     terms: ReviewTerms,
    //   };
    // if (guarantor.application.attributes.minimum_trade_references == 0 || guarantor.application.attributes.legal_type === "personal") {
    //   delete options.trade_references;
    // }
    // if (guarantor.application.attributes.additional_questions_applies !== "both" &&
    //   guarantor.application.attributes.additional_questions_applies !== guarantor.application.attributes.application_type) {
    //   delete options.questions;
    // }

    // Object.keys(options).forEach(function (key) {
    //   sections.push(
    //     React.createElement(components[key],
    //       {
    //         "data-accordianLabel": options[key].label,
    //         data: guarantor,
    //         id: key,
    //         key: key
    //       }
    //     )
    //   );
    // });

    // var color = isMobile() ? "white" : null;
    // var overlay = <ApplicationOverlay logo_url={supplier_logo_url} trading_name={guarantor.application.attributes.supplier_name} label={"Guarantor approval"} color={color} />;
    // var dots = this.componentGetDots();

    // return (
    //   <div className={styles.wrapper}>
    //     <header className={styles.header}>
    //       {overlay}
    //       {dots}
    //     </header>
    //     <div className={styles.container}>
    //       <section className={styles.section}>
    //         <div className={styles.row}>
    //           <div className={styles.panel}>
    //             <PanelTitle text={title} />
    //           </div>
    //         </div>
    //         <div className={styles.row}>
    //           <div className={styles.content}>
    //             <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subTitle }}></p>
    //             {guarantor.application.attributes.status == "in_progress" && <AccordianContainer type='review_edit' subPages={sections} subsection_index={container_index} handleClick={this.updateContainerIndex} />}
    //           </div>
    //         </div>
    //         <div className={styles.row}>
    //           <div className={styles.content}>
    //             {this._reviewButton()}
    //           </div>
    //         </div>
    //       </section>
    //     </div>
    //   </div>
    // );
  },
});

var defaults = {
  titlePrefix: 'Review details for trade account application between ',
  options: {
    business: {
      label: 'Business details',
    },
    trade_references: {
      label: 'Trade references',
      requiredBy: 'requires_trade_reference_check',
    },
    guarantors: {
      label: 'Guarantors',
      requiredBy: 'requires_guarantees',
    },
    questions: {
      label: 'Questions',
    },
    financials: {
      label: 'Financials',
      addon: true,
    },
    additional: {
      label: 'Additional',
      addon: true,
    },
    paperless: {
      label: 'Direct debit',
      addon: true,
    },
    payment_info: {
      label: 'Limit',
    },
    terms: {
      label: 'Trade account Ts and Cs',
    },
  },
};

module.exports = connect((state) => {
  let consumerName;
  let supplierName;
  let limit;
  const options = defaults.options;
  const guarantor = state.guarantor.data;
  let titlePrefix = defaults.titlePrefix;
  let logoUrl = null;

  if (state.guarantor.data) {
    consumerName = state.guarantor.data.application.attributes.consumer_name;
    supplierName = state.guarantor.data.application.attributes.supplier_name;
    limit = state.guarantor.data.application.attributes.trade_account_limit;
    if (guarantor.attributes.acknowledged) {
      titlePrefix = guarantor.attributes.approved
        ? 'You have already approved the trade account application between'
        : 'You have already declined the trade account application between';
    }
    if (state.guarantor.data.application.attributes.supplier_logo) {
      logoUrl =
        state.guarantor.data.application.attributes.supplier_logo.logo.url;
    }
    if (state.guarantor.data.application.attributes.legal_type === 'personal') {
      options.business.label = 'Customer details';
    }

    const addonRules = guarantor.addon_rules || null;
    const addonModuleNames = [];
    addonRules.forEach((answer) => {
      addonModuleNames.push(answer.attributes.addon_module_name);
    });

    Object.keys(options).forEach((key) => {
      if (defaults.options[key].addon && !addonModuleNames.includes(key)) {
        delete options[key];
      }
    });
  }
  return {
    title: `${titlePrefix} ${consumerName} and ${supplierName}`,
    subTitle: `${consumerName} is asking for a credit limit of <strong>$${Number(
      limit
    ).toLocaleString()}</strong>`,
    loading: state.guarantor.loading,
    guarantor,
    options,
    container_index: state.guarantor.review_container_index,
    supplier_logo_url: logoUrl,
  };
})(Review);
