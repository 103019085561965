import { Typography } from '@material-ui/core';
import Dialog from 'modules/shared/components/v2/Dialog';
import React from 'react';

type ConfirmRemoveAttachmentModal = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmRemoveAttachment = (
  props: ConfirmRemoveAttachmentModal
) => {
  const { isOpen, onConfirm, onCancel } = props;

  return (
    <Dialog
      title="Remove attachment"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Remove"
      cancelButtonText="Cancel"
      confirmButtonProps={{ red: true }}
    >
      <Typography>
        Please confirm to remove the uploaded spreadsheet.
      </Typography>
    </Dialog>
  );
};
