import React from 'react';

import styles from '../centrix.css';

import moment from 'moment';

class ConsumerInformation extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  renderName(person) {
    return (
      <span>
        {person.surname.toUpperCase()}
        {`, ${person.first_name}`}
        {person.middle_name && ` ${person.middle_name}`}
      </span>
    );
  }

  renderAddress(address) {
    return (
      <span>
        {address.address_line1}
        {address.address_line2 && `, ${address.address_line_2}`}
        {address.suburb && `, ${address.suburb}`}
        {address.city && `, ${address.city}`}
        {address.postcode && `, ${address.postcode}`}
      </span>
    );
  }

  renderAddresses(addresses) {
    let last_known = addresses.find((address) => address.last_known);
    let other_known = addresses.filter((address) => !address.last_known);

    return (
      <div className={styles.table_container}>
        <table className={styles.table + ' ' + styles.table_no_border}>
          <thead>
            <tr>
              <th className="has-text-weight-medium">Latest Known Address:</th>
              <th className="has-text-weight-medium">Date First Loaded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.renderAddress(last_known)}</td>
              <td>{moment(last_known.address_date).format('DD/MM/YYYY')}</td>
            </tr>
            <br />
            {other_known.length > 0 && (
              <tr>
                <th className="has-text-weight-medium">
                  Other Known Previous Addresses:
                </th>
                <th className="has-text-weight-medium">Date First Loaded</th>
              </tr>
            )}
            {other_known.length > 0 &&
              other_known.map((address) => (
                <tr>
                  <td>{this.renderAddress(address)}</td>
                  <td>{moment(address.address_date).format('DD/MM/YYYY')}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    const { data_sets } = data;
    const personal = data_sets.consumer_file.personal;
    return (
      <div>
        <div className={styles.table_container}>
          <table className={styles.table + ' ' + styles.table_no_border}>
            <tbody>
              <tr>
                <td className="has-text-weight-medium">Name:</td>
                <td>{this.renderName(personal)}</td>
              </tr>
              <tr>
                <td className="has-text-weight-medium">Gender:</td>
                <td>
                  {personal.gender == 'M'
                    ? 'Male'
                    : personal.gender == 'F'
                    ? 'Female'
                    : 'Unknown'}
                </td>
              </tr>
              <tr>
                <td className="has-text-weight-medium">Date of Birth:</td>
                <td>{moment(personal.date_of_birth).format('DD/MM/YYYY')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {data_sets.known_addresses &&
          this.renderAddresses(data_sets.known_addresses.known_address)}
      </div>
    );
  }
}

export default ConsumerInformation;
