/* eslint-disable max-classes-per-file */
export function termsDocuments(array, type, termsDocType) {
  let item = null;

  if (!array) return item;

  array.forEach((element) => {
    if (element.type === type) {
      if (element.attributes.terms_doc_type === termsDocType) item = element;
    }
  });

  return item;
}
/* eslint-enable max-classes-per-file */

const getTermsDocuments = (includedItems) =>
  includedItems.filter((item) => item.type === 'terms_documents');

export const getTermsDocumentVersions = ({ includedItems, termsDocType }) => {
  if (!includedItems) {
    return [];
  }
  const termsDocuments = getTermsDocuments(includedItems);
  return termsDocuments
    .filter(
      (documentItem) => documentItem.attributes.terms_doc_type === termsDocType
    )
    .sort((a, b) => b.attributes.version - a.attributes.version);
};
