import AuthorisationRequestAwaitingPermissionModel from 'models/AuthorisationRequestAwaitingPermissionModel';
import ApplicationInaccessible from 'modules/consumer-onboarding/v2/ApplicationInaccessible';
import { INACCESSIBLE_MESSAGE } from 'modules/consumer-onboarding/v2/hooks/useApplicationAccessibilityState';
import Form from 'modules/request-permission-flow/components/Review/Form';
import PermissionDeclined from 'modules/request-permission-flow/components/Review/PermissionDeclined';
import PermissionSent from 'modules/request-permission-flow/components/Review/PermissionSent';
import { Wrapper } from 'modules/request-permission-flow/components/Review/styles';
import React, { ReactElement, useEffect, useState } from 'react';

const Review = ({
  awaitingPermission,
  isInvalidToken,
  token,
}: {
  awaitingPermission: AuthorisationRequestAwaitingPermissionModel;
  isInvalidToken: boolean;
  token: string;
}): ReactElement => {
  if (isInvalidToken) {
    return (
      <ApplicationInaccessible
        header="Application inaccessible"
        message="Sorry, the link you are accessing is no longer valid."
      />
    );
  }

  const {
    applicationId,
    applicationInaccessibleReason,
    consumerName,
    id: awaitingPermissionId,
    requesteeEmail,
    requesteeId,
    requesterContactPhoneNumber,
    requesterEmail,
    requesterFirstName,
    requesterName,
    status,
    supplierName,
  } = awaitingPermission;

  const [decision, setDecision] = useState<string>('');

  useEffect(() => {
    if (status === 'approved') {
      setDecision('Yes');
    }
  }, [status]);

  let content: JSX.Element | null = null;

  if (applicationInaccessibleReason) {
    return (
      <ApplicationInaccessible
        header="Application inaccessible"
        message={`${INACCESSIBLE_MESSAGE[applicationInaccessibleReason]} Please directly get in touch with ${supplierName} to discuss any questions you may have.`}
      />
    );
  }

  if (typeof awaitingPermissionId === 'undefined') {
    content = <></>;
  } else {
    switch (decision) {
      case 'Yes':
        content = (
          <PermissionSent
            consumerName={consumerName}
            requesterContactPhoneNumber={requesterContactPhoneNumber}
            requesterEmail={requesterEmail}
            requesterName={requesterName}
            supplierName={supplierName}
          />
        );
        break;
      case 'No':
        content = (
          <PermissionDeclined
            consumerName={consumerName}
            requesterContactPhoneNumber={requesterContactPhoneNumber}
            requesterEmail={requesterEmail}
            requesterName={requesterName}
            supplierName={supplierName}
          />
        );
        break;
      default:
        content = (
          <Form
            consumerName={consumerName}
            requesterContactPhoneNumber={requesterContactPhoneNumber}
            requesterEmail={requesterEmail}
            requesterFirstName={requesterFirstName}
            requesterName={requesterName}
            setDecision={setDecision}
            supplierName={supplierName}
            token={token}
            awaitingPermissionId={awaitingPermissionId}
            requesteeEmail={requesteeEmail}
            applicationId={applicationId}
            requesteeId={requesteeId}
          />
        );
    }
  }

  return (
    <Wrapper className="columns is-centered is-mobile">
      <div className="column is-three-quarters-mobile is-two-thirds-tablet is-three-quarters-desktop is-half-widescreen">
        {content}
      </div>
    </Wrapper>
  );
};

export default Review;
