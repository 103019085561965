import React from 'react';

import styles from './css/StatusIndicator.css';

const StatusIndicator = (props) => {
  const { status, long } = props;
  let statusName = null;
  let dotStyles = styles[`${status}_simple`];

  if (long) {
    dotStyles = styles[status];
    if (status === 'not_started') {
      statusName = <span className={styles.status_name}>not started</span>;
    } else if (status === 'Archived') {
      statusName = (
        <span
          className={`${styles.achieved} has-text-danger has-text-weight-normal`}
        >
          Archived
        </span>
      );
    } else {
      statusName = (
        <span className={styles.status_name}>
          {status ? status.split('_').join(' ') : ''}
        </span>
      );
    }
  }

  return (
    <span className={dotStyles}>
      <span className={styles.dot}></span> {statusName}
    </span>
  );
};

export default StatusIndicator;
