import { Skeleton } from '@material-ui/lab';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 30px;
`;

const Button = styled(Skeleton)`
  border-radius: 20px;
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const LoadingIndicator = () => (
  <Container>
    <Button variant="rect" width={118} height={30} />
    <InnerContainer>
      <TileContainer>
        <Skeleton variant="rect" width={271} height={98} />
        <Skeleton variant="rect" width={271} height={98} />
        <Skeleton variant="rect" width={271} height={98} />
      </TileContainer>
      <Skeleton variant="rect" width={1055} height={1068} />
    </InnerContainer>
  </Container>
);
