/* Import libraries */
/* Import components */
import { withStyles } from '@material-ui/core/styles';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React from 'react';
import { connect } from 'react-redux';

import { loadAutoDecisions } from '../../actions';
/* Import actions*/
import { setValue, updateRuleSet } from '../actions';
import AutoDecisioningForm from './AutoDecisioningForm';
/* Import CSS */
import styles from './css/AutoDecisioning.css';

const UpdateAutoDecisioning = createClass({
  handleNameChange(e) {
    const { dispatch } = this.props;
    dispatch(setValue('name', e.target.value));
  },

  render() {
    const { handleDiscard, current_entity } = this.props;
    return (
      <AutoDecisioningForm
        handleDiscard={handleDiscard}
        handleSubmit={this.update}
        submitButtonText={'update ruleset'}
        current_entity={current_entity}
        read_only={!isFeatureEditEnabled('1CAD')}
      />
    );
  },

  update() {
    const { dispatch, rule_set, handleDiscard } = this.props;
    dispatch(
      updateRuleSet(rule_set.id, rule_set.attributes, () => {
        dispatch(loadAutoDecisions());
        handleDiscard();
      })
    );
  },
});

const defaults = {
  ruleset: {
    name: 'New Auto Decisioning Ruleset',
  },
};

module.exports = connect((state, ownProps) => {
  return {
    // rule_sets: state.addons.auto_decisioning.rule_sets || null,
    current_entity: state.current_user.current_entity.attributes,
    handleDiscard: ownProps.handleDiscard,
    rule_set: state.auto_decisions.current_rule_set || defaults.ruleset,
    updating: state.auto_decisions.updating,
  };
})(withStyles(styles)(UpdateAutoDecisioning));
