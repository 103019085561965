import get from 'lodash.get';
import { setIdentityValue } from 'modules/identity/actions';
import Identity from 'modules/identity/containers/Identity';
import BackAndNextButtons from 'modules/shared/components/widgets/interactive/BackAndNextButtons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import getApplicableAntiFraudRulesForAuthorisationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForAuthorisationFlow';

import { updateAuthorisationSignature } from '../actions';

class IdentityDetails extends Component {
  state = {
    complete: false,
    validationTrigger: false,
  };

  componentDidMount() {
    const { dispatch, firstName, lastName, middleName } = this.props;
    dispatch(setIdentityValue('first_name', firstName));
    dispatch(setIdentityValue('last_name', lastName));
    dispatch(setIdentityValue('middle_name', middleName));
  }

  setComplete = (complete) => {
    const currentValue = this.state.complete;
    if (currentValue !== complete) {
      this.setState({ complete });
    }
  };

  identityProps = () => {
    return {
      consumerName: this.props.consumerName,
      handleComplete: this.setComplete,
      isAuthorisationPage: true,
      isIdentificationImageRequired: this.props.isIdentificationImageRequired,
      isProofOfAddressVisible: this.props.isProofOfAddressVisible,
      needDobAddress: this.props.requireAddress,
      needIdentification: this.props.requireIdentification,
      page_validation_start: this.state.validationTrigger,
      setPageValidationStartFinish: () => {
        this.setState({ validationTrigger: false });
      },
      supplierLegalName: this.props.supplierLegalName,
      trustApplicantName: this.props.trustApplicantName,
    };
  };

  handleClickNext = () => {
    if (this.isValid()) {
      this.handleConfirm();
    } else {
      this.setState({ validationTrigger: true });
    }
  };

  handleConfirm = () => {
    const { toNextSection, params, dispatch } = this.props;
    dispatch(
      updateAuthorisationSignature(params.authorisation_id, () => {
        toNextSection();
      })
    );
  };

  isValid = () => this.state.complete;

  render() {
    const { toPreviousSection, updating } = this.props;

    return (
      <div>
        <div>
          <Identity {...this.identityProps()} />
        </div>
        <BackAndNextButtons
          updating={updating}
          disableNext={updating}
          onNextClick={this.handleClickNext}
          onBackClick={toPreviousSection}
        />
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  const requireAddress =
    get(
      state,
      'authorisation.data.authorisation.attributes.require_identification',
      false
    ) ||
    get(
      state,
      'authorisation.data.authorisation.attributes.require_signature_with_dob_and_address',
      false
    );

  const antiFraudCategoryRules =
    getApplicableAntiFraudRulesForAuthorisationFlow(state.authorisation);

  return {
    firstName: state.authorisation.firstName,
    isIdentificationImageRequired:
      antiFraudCategoryRules.requiresIdentificationImage,
    isProofOfAddressVisible: ownProps.requireProofOfAddress,
    lastName: state.authorisation.lastName,
    middleName: state.authorisation.middleName,
    requireAddress,
    requireIdentification: get(
      state,
      'authorisation.data.authorisation.attributes.require_identification',
      false
    ),
    supplierLegalName: state.authorisation.supplierLegalName,
    updating: state.authorisation.updating,
  };
})(IdentityDetails);
