import alertNotificationPath from 'documents/webhooks/v2/alert_notification.yml';
import {
  SectionHeader,
  SubSection,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import PayloadStructureDescription from 'modules/webhooks/v2/components/V2Documentation/PayloadStructureDescription';
import SyntaxHighlighter from 'modules/webhooks/v2/components/V2Documentation/SyntaxHighlighter';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';

const AlertNotificationPayloadStructure = (): ReactElement => {
  const [payloadStructure, setPayloadStructure] = useState('loading...');

  useEffect(() => {
    fetch(alertNotificationPath)
      .then((rawData) => rawData.text())
      .then((decodedText) => setPayloadStructure(decodedText));
  }, [alertNotificationPath]);

  return (
    <Fragment>
      <SectionHeader>Alert notification schema</SectionHeader>
      <SubSection>
        <PayloadStructureDescription
          fileName="1Centre-alert-notification-schema.yml"
          filePath={alertNotificationPath}
        />
        <SyntaxHighlighter language="yaml">
          {payloadStructure}
        </SyntaxHighlighter>
      </SubSection>
    </Fragment>
  );
};

export default AlertNotificationPayloadStructure;
