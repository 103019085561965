import { Alert } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  width: fit-content;
`;

export const Success = () => {
  return (
    <StyledAlert severity="success" icon={<CheckCircleIcon />}>
      Import complete
    </StyledAlert>
  );
};
