import React, { FC, ReactNode, useState } from 'react';

import './menulist.scss';
import { IMenuItem } from 'containers/next-gen/types/types';

import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem';

type MenuListProps = {
  menuItems: Array<IMenuItem>;
  activeIndex: number;
  setActiveIndex: (number) => void;
  rightIconButton?: ReactNode;
  customIndicator?: ReactNode;
  onSort?: (oldIndex: number, newIndex: number) => void;
};

const MenuList: FC<MenuListProps> = ({
  menuItems,
  activeIndex,
  setActiveIndex,
  rightIconButton = null,
  customIndicator = null,
  onSort,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      if (typeof onSort !== 'function') return;

      const oldIndex = menuItems.map((m) => m.id).indexOf(active.id);
      const newIndex = menuItems.map((m) => m.id).indexOf(over.id);

      setActiveIndex(oldIndex);
      onSort(oldIndex, newIndex);
    }
  };

  return (
    <div className="menu-list">
      <DndContext
        autoScroll={false}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={menuItems}
          strategy={verticalListSortingStrategy}
        >
          {menuItems.map((menu, index) => (
            <SortableItem
              index={index}
              key={menu.id}
              isActive={activeIndex === index}
              onClick={() => setActiveIndex(index)}
              rightIconButton={activeIndex === index ? rightIconButton : null}
              customIndicator={activeIndex === index ? customIndicator : null}
              someoneIsDragging={isDragging}
              {...menu}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default MenuList;
