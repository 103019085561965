import React, { useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';

import api from 'api';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import { Buttons } from './Buttons';
import { AccountMonitoringItemWrapper } from './AccountMonitoringItemWrapper';
import { processError } from 'utils';
import { useSnackbar } from 'notistack';
import { AccountMonitoringContext } from './AccountMonitoringContext';
import { ACCOUNT_STATUS_OPTIONS } from 'constants/accountStatusOptions';

export const AccountStatus = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { application, currentUser, onFetchApplicationRecord } = useContext(
    AccountMonitoringContext
  );

  const applicationId = application.id;
  const stopCredit = application.activeStopCredit;
  const isStopCreditActive = stopCredit.attributes.active;

  const stopCreditAPI = api('stop_credits', '', currentUser.currentEntity.id);

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [isPending, setPending] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isStopCreditActive) {
      const selectedOption = ACCOUNT_STATUS_OPTIONS.find(
        (option) => option.value === stopCredit.attributes.delinquency_period
      );
      if (selectedOption) {
        setSelectedOption(selectedOption);
      }
    } else {
      setSelectedOption(null);
    }
  }, [stopCredit]);

  const handleChange = (_, newOption) => {
    setError('');
    setSelectedOption(newOption);
  };

  const handleSave = () => {
    if (!selectedOption) {
      setError('Please select an option');
      return;
    }
    setPending(true);
    stopCreditAPI.createStopCredit(
      applicationId,
      {
        delinquency_period: selectedOption.value,
      },
      () => {
        onFetchApplicationRecord();
        enqueueSnackbar('Account status set', { variant: 'success' });
        setPending(false);
        mixpanel.track('Apply stop credit', {
          'Application ID': applicationId,
          'Entity ID': currentUser.currentEntity.id,
          distinct_id: currentUser.id,
        });
      },
      (error) => {
        setPending(false);
        const { errorMessage, errorMessages } = processError(error);

        // Let's see if there is a field level error
        const fieldError = errorMessages.find(
          (error) => error.title === 'Invalid field value'
        );

        if (fieldError) {
          // Yes, there is a field level error
          setError(fieldError.detail);
        } else if (errorMessages.length) {
          // There are multiple errors.
          // We'll just show 4 of them.
          const errorsToDisplay = errorMessages.slice(0, 4);
          errorsToDisplay.forEach((errorMessage) => {
            enqueueSnackbar(errorMessage.detail, { variant: 'error' });
          });
        } else {
          // There are no multiple errors
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }
      }
    );
  };

  const handleClear = () => {
    if (!selectedOption) {
      return;
    }
    setPending(true);
    stopCreditAPI.updateStopCredit(
      stopCredit.id,
      { active: false },
      () => {
        onFetchApplicationRecord();
        enqueueSnackbar('Account status removed', { variant: 'info' });
        setPending(false);
        mixpanel.track('Remove stop credit', {
          'Application ID': applicationId,
          'Entity ID': currentUser.currentEntity.id,
          distinct_id: currentUser.id,
        });
      },
      (error) => {
        setPending(false);
        const { errorMessage } = processError(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    );
  };

  return (
    <AccountMonitoringItemWrapper title="Account status">
      <BorderedAutocomplete
        disabled={isStopCreditActive || isPending}
        textFieldProps={{ error: Boolean(error), helperText: error }}
        options={ACCOUNT_STATUS_OPTIONS}
        className="account-monitoring-input"
        value={selectedOption}
        onChange={handleChange}
      />
      <Buttons
        applicationId={applicationId}
        handleSave={handleSave}
        handleClear={handleClear}
        isActive={isStopCreditActive}
        isPending={isPending}
        noteCategory="alert"
      />
    </AccountMonitoringItemWrapper>
  );
};
