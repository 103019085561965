import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import IconReviewDate from 'images/svgs/icon-review-date.svg';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import styles from 'modules/shared/components/inputs/css/BorderedCalendarPicker.css';
import TextInput from 'modules/shared/components/inputs/TextInput';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

const CalendarCloseBtn = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: inline-flex;
  font-size: 1.125rem;
  margin: 0;
  padding: 3px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  vertical-align: middle;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

function formatDate(date) {
  let formattedDate = null;

  if (date) {
    formattedDate = moment(date).format('DD/MM/YYYY');
    if (formattedDate === 'Invalid date') {
      formattedDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
    }

    return formattedDate;
  }

  return '';
}

function toDateObject(date) {
  if (date === null || typeof date === 'undefined') {
    return null;
  }

  if (moment.isDate(date)) {
    return date;
  }

  if (moment.isMoment(date)) {
    return date.toDate();
  }

  const formattedDate = moment(date);
  if (formattedDate.isValid()) {
    return formattedDate.toDate();
  }

  return moment(date, 'DD/MM/YYYY').toDate();
}

function useCalendarState(props) {
  const { isDisabled, isStyledUnderlined, onChange, value } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(value);

  const onOpenCalendar = (event) => {
    if (isDisabled) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const onCloseCalendar = () => {
    if (isDisabled) {
      return;
    }

    setAnchorEl(null);
  };

  const onSelectDate = (date, isStyledUnderlined) => {
    if (isDisabled) {
      return;
    }

    setSelectedDate(date);
    onCloseCalendar();

    if (onChange) {
      if (isStyledUnderlined) {
        return onChange(formatDate(date));
      }

      onChange(date);
    }
  };

  const onResetDate = () => {
    setSelectedDate(null);

    if (onChange) {
      onChange(null);
    }
  };

  const isCalendarVisible = Boolean(anchorEl);
  const calendarValue = toDateObject(selectedDate);

  return {
    anchorEl,
    calendarValue,
    isCalendarVisible,
    onCloseCalendar,
    onOpenCalendar,
    onResetDate,
    onSelectDate,
    selectedDate,
  };
}

function ResetDate(props) {
  const { onResetDate, value } = props;

  const onClick = (event) => {
    event.stopPropagation();

    onResetDate();
  };

  if (value) {
    return (
      <Fragment>
        <CalendarCloseBtn type="button" onClick={onClick}>
          <CloseIcon fontSize="small" />
        </CalendarCloseBtn>
      </Fragment>
    );
  }

  return null;
}

function EndAdornment(props) {
  const {
    disableReset,
    isDisabled,
    onCalendarIconClick,
    onResetDate,
    value,
  } = props;

  if (isDisabled) {
    return null;
  }

  return (
    <InputAdornment
      classes={{
        root: styles.calendar_icon,
      }}
      position="end"
    >
      {disableReset ? (
        ''
      ) : (
        <ResetDate value={value} onResetDate={onResetDate} />
      )}
      <IconReviewDate type="button" onClick={onCalendarIconClick} />
    </InputAdornment>
  );
}

export default function BorderedCalendarPicker(props) {
  const {
    disableReset,
    isDisabled,
    isStyledUnderlined,
    label,
    labelShrink,
    maxDate,
    minDate,
    onBlur,
    required,
    textFieldProps,
    value,
  } = props;

  const {
    anchorEl,
    calendarValue,
    isCalendarVisible,
    onCloseCalendar,
    onOpenCalendar,
    onResetDate,
    onSelectDate,
  } = useCalendarState(props);

  let textInput = (
    <BorderedTextField
      customProps={{
        endAdornment: (
          <EndAdornment
            isDisabled={isDisabled}
            onCalendarIconClick={onOpenCalendar}
            onResetDate={onResetDate}
            disableReset={disableReset}
            value={value}
          />
        ),
      }}
      disabled={isDisabled}
      label={label}
      value={formatDate(value)}
      onClick={onOpenCalendar}
      labelShrink={labelShrink}
      required={required}
      onBlur={onBlur}
      {...textFieldProps}
    />
  );

  if (isStyledUnderlined) {
    textInput = (
      <TextInput
        label={label}
        EndAdornment={
          <EndAdornment
            onCalendarIconClick={onOpenCalendar}
            onResetDate={onResetDate}
            value={value}
            isDisabled={isDisabled}
          />
        }
        disabled={isDisabled}
        onClick={onOpenCalendar}
        value={typeof value === 'string' ? value : formatDate(value)}
        required={required}
        {...textFieldProps}
        error={textFieldProps.helperText}
      />
    );
  }

  return (
    <div>
      {textInput}
      <Popover
        open={isCalendarVisible}
        onClose={onCloseCalendar}
        anchorEl={anchorEl}
      >
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          value={calendarValue || new Date()}
          onClickDay={(value) => onSelectDate(value, isStyledUnderlined)}
        />
      </Popover>
    </div>
  );
}

BorderedCalendarPicker.defaultProps = {
  isDisabled: false,
  isStyledUnderlined: false,
};
