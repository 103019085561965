/* Import libraries */
import React, { ReactElement } from 'react';

/* Import components */
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import Button from 'modules/shared/components/inputs/Button';

function ApprovalHierarchyChangedModal(props): ReactElement {
  const { okHandler, willRemoveReviewer } = props;

  const body = (
    <div>
      <p>
        1CAH change will take effect for new applications or applications that
        haven&apos;t been actioned yet.{' '}
        {willRemoveReviewer &&
          'All users assigned as a reviewer will be set to no level.'}
      </p>
      <div>
        <Button text="Ok" handleClick={okHandler} />
      </div>
    </div>
  );

  return (
    <SquareModal title="Application approval level changed!" size={'small'}>
      {body}
    </SquareModal>
  );
}

export default ApprovalHierarchyChangedModal;
