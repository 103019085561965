import get from 'lodash.get';

interface Record {
  [key: string]: any;
}

const sortDescending = ({
  a,
  b,
  dateAttribute,
}: {
  a: Record;
  b: Record;
  dateAttribute: string;
}): number => {
  const dateForRecordA = new Date(get(a, dateAttribute)).getTime();
  const dateForRecordB = new Date(get(b, dateAttribute)).getTime();

  if (dateForRecordA < dateForRecordB) {
    return 1;
  }

  if (dateForRecordA > dateForRecordB) {
    return -1;
  }

  return 0;
};

const sortAscending = ({
  a,
  b,
  dateAttribute,
}: {
  a: Record;
  b: Record;
  dateAttribute: string;
}): number => {
  const dateForRecordA = new Date(get(a, dateAttribute)).getTime();
  const dateForRecordB = new Date(get(b, dateAttribute)).getTime();

  if (dateForRecordA > dateForRecordB) {
    return 1;
  }

  if (dateForRecordA < dateForRecordB) {
    return -1;
  }

  return 0;
};

export default function sortByDate({
  records,
  dateAttribute,
  direction,
}: {
  records: Record[];
  dateAttribute: string;
  direction: 'ascending' | 'descending';
}): Record[] {
  const sortDirection = direction || 'descending';

  return records.sort((a, b) => {
    if (sortDirection === 'descending') {
      return sortDescending({ a, b, dateAttribute });
    }

    if (sortDirection === 'ascending') {
      return sortAscending({ a, b, dateAttribute });
    }

    throw `${sortDirection} is not a valid direction value. Valid values are "ascending" or "descending"`;
  });
}
