import React, { ReactElement } from 'react';

const ApprovalBlockedByIUF = ({
  application,
}: {
  application: any;
}): ReactElement | null => {
  if (application.isRequiredIUFApprovalPendingDecision) {
    let message =
      'Internal Use Fields (IUF) have been set to require approval before a decision can be made.';

    if (application.isIUFRequired) {
      message =
        'Internal Use Fields (IUF) have been set to mandatory and require approval before a decision can be made.';
    }

    return (
      <div>{message} Once these are approved, you will be able to action.</div>
    );
  }

  if (application.isRequiredIUFPendingSubmission) {
    return (
      <div>
        Internal Use Fields (IUF) have been set to mandatory before a decision
        can be made. Once submitted, you will be able to action.
      </div>
    );
  }

  return null;
};

export default ApprovalBlockedByIUF;
