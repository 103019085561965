import Modal from 'modules/dashboard/containers/components/Modal';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement } from 'react';

import { GenericModalTitle, GenericModalButtonWrapper } from './styles';

type IGenericConfirmModal = {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description: string;
};

function GenericConfirmModal(props: IGenericConfirmModal): ReactElement {
  const { onConfirm, onCancel, title, description } = props;

  return (
    <Modal
      onClose={onCancel}
      title={
        <GenericModalTitle>
          <span>{title}</span>
        </GenericModalTitle>
      }
      content={<p>{description}</p>}
      footer={
        <GenericModalButtonWrapper>
          <Button text="Confirm" handleClick={onConfirm} />
          <Button text="Cancel" white={true} handleClick={onCancel} />
        </GenericModalButtonWrapper>
      }
    />
  );
}

export default GenericConfirmModal;
