import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { displayEmpty } from 'utils/formatting';
import { enquiryResponseData } from './helpers/reportHelpers';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var AustralianBusinessNumberDetails = createClass({
  _abnStatus(detail) {
    let statusCode = detail.abn_status_code,
      statusDate = detail.abn_status_from_date;

    if (statusDate === null || statusDate === undefined)
      return displayEmpty(statusCode);

    return statusCode + ' from ' + stringToSimpleDate(statusDate);
  },

  _gstStatus(detail) {
    let gstCode = detail.gst_status_code,
      gstDate = detail.gst_status_from_date;

    if (gstDate === null || gstDate === undefined) return displayEmpty(gstCode);

    return gstCode + ' from ' + stringToSimpleDate(gstDate);
  },

  _tradingNames(detail) {
    let otherEntities = detail.other_entities || {},
      entityName = otherEntities.entity_name || [];

    if (entityName.constructor !== Array) entityName = [entityName];

    return displayEmpty(entityName.join(', '));
  },

  _details() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      organisationDetails = response.organisation_details;

    if (organisationDetails === null || organisationDetails === undefined)
      return null;

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>ABN</th>
              <td>{displayEmpty(organisationDetails.abn_code)}</td>
              <th colSpan={2}>Main Business Location</th>
            </tr>
            <tr>
              <th>Last Modified</th>
              <td>
                {stringToSimpleDate(organisationDetails.abr_last_updated_date)}
              </td>
              <th>State</th>
              <td>
                {displayEmpty(organisationDetails.business_address_state_code)}
              </td>
            </tr>
            <tr>
              <th>ABN Status</th>
              <td>{this._abnStatus(organisationDetails)}</td>
              <th>Postcode</th>
              <td>
                {displayEmpty(organisationDetails.business_address_post_code)}
              </td>
            </tr>
            <tr>
              <th>GST Status</th>
              <td>{this._gstStatus(organisationDetails)}</td>
              <th colSpan={2}>Other Registrations</th>
            </tr>
            <tr>
              <th>Entity Name</th>
              <td>{displayEmpty(organisationDetails.main_legal_name)}</td>
              <th>ACN or other</th>
              <td>{displayEmpty(organisationDetails.asic_number_code)}</td>
            </tr>
            <tr>
              <th>Entity Type</th>
              <td>
                {displayEmpty(organisationDetails.entity_type_description)}
              </td>
              <th colSpan={2}>Trading Names</th>
            </tr>
            <tr>
              <th></th>
              <td></td>
              <th>Trading Name</th>
              <td>{this._tradingNames(organisationDetails)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="Australian Business Number Details"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        {this._details()}
      </ReviewContainer>
    );
  },
});

export default AustralianBusinessNumberDetails;
