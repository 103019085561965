import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import styles from './css/ApplicationStart.css';
import {
  setKeyContactFormValue,
  setEntityFormValue,
  checkEmailisUnique,
} from '../actions/onboarding';
import { setStartComplete, getAppColorPalette } from '../actions/section';
import {
  updateStartInfoAndLoginUser,
  updateEntityContactAndRegionInfo,
} from '../actions/review';
import TextInput from 'modules/shared/components/inputs/TextInput';
import EmailInput from 'modules/shared/components/inputs/EmailInput';
import Button from 'modules/shared/components/inputs/Button';
import RegionStatesDropdown from 'modules/shared/components/widgets/static/RegionStatesDropdown';
import SectionCircle from 'modules/shared/components/widgets/static/SectionCircle';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import { countryCodeByAlpha2 } from 'utils/countryCodes';
import { checkPassStrength } from '../../../utils/password';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import { EMAIL_REGEX } from 'constants';
import get from 'lodash.get';

const determineRedirectLinks = ({ applicationId }) => {
  return `/register/consumer/${applicationId}/business/overview`;
};

var ApplicationStart = createClass({
  isComplete: function(form_values) {
    const { form_errors } = this.state;
    const { user, new_entity_with_existing_user } = this.props;
    let errors = form_errors;
    var re = EMAIL_REGEX;

    if (!form_values) {
      var { form_values } = this.props;
    }

    if (new_entity_with_existing_user) {
      if (
        form_values.key_contact_phone !== '' &&
        form_values.entity_region.length > 0 &&
        (form_values.entity_region === 'NZ' ||
          (form_values.entity_region === 'AU' &&
            form_values.entity_state &&
            form_values.entity_state.length > 0))
      ) {
        return true;
      }
    } else {
      let isEmailValid = true;
      if (form_errors.hasOwnProperty('key_contact_email')) isEmailValid = false;
      if (
        !this.props.email_valid &&
        !this.props.email_validating &&
        user.email != form_values['key_contact_email']
      )
        isEmailValid = false;
      if (
        isEmailValid &&
        form_values.key_contact_password !== '' &&
        form_values.entity_region.length > 0 &&
        (form_values.entity_region === 'NZ' ||
          (form_values.entity_region === 'AU' &&
            form_values.entity_state &&
            form_values.entity_state.length > 0))
      ) {
        return true;
      }
    }

    if (form_values.entity_region == '') {
      errors = {
        ...errors,
        entity_region: 'You must enter your company location',
      };
    } else {
      if (form_values.entity_state == '') {
        errors = { ...errors, entity_state: 'State' };
      }
    }
    if (form_values.key_contact_password == '') {
      errors = {
        ...errors,
        key_contact_password: 'You must enter your password',
      };
    }
    this.setState({
      form_errors: errors,
    });

    return false;
  },

  componentDidMount: function() {
    const {
      application_submission_status,
      consumer_account,
      dispatch,
      form_values,
      hasOneApplicableLegalTypes,
      loading_error,
      new_entity_with_existing_user,
      params,
      start_complete,
      user,
    } = this.props;

    if (!user.new_user) {
      if (application_submission_status !== 'complete' && !loading_error) {
        dispatch(setStartComplete(params.application_id));
        dispatch(getAppColorPalette(params.application_id));
      }
      const link = determineRedirectLinks({
        applicationId: params.application_id,
      });

      const locationSearch = get(this.props, 'location.search', '');

      // TODO: Need to redirect to the BC flow!
      // if (!loading_error && !new_entity_with_existing_user) {
      //   browserHistory.push(link);
      // }
      browserHistory.push(`${link}${locationSearch}`);
    }
    if (!form_values.key_contact_email) {
      dispatch(setKeyContactFormValue('key_contact_email', user.email));
    }
  },

  _emailChangeHandler(value, isValid) {
    const { dispatch, email_valid } = this.props;

    if (isValid) {
      const { key_contact_email, ...rest } = this.state.form_errors;
      this.setState({
        form_errors: { ...rest },
      });
    } else {
      this.setState({
        form_errors: { ...this.state.form_errors, key_contact_email: true },
      });
    }

    dispatch(setKeyContactFormValue('key_contact_email', value));
    this.checkEmail(value);
  },

  handleChange: function(event) {
    const { dispatch, user } = this.props;
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target);
    }
    dispatch(setKeyContactFormValue(event.target.name, event.target.value));
  },

  handleBlur: function(event) {
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target);
    }
  },

  regionStateHandleChange: function(target) {
    const { dispatch } = this.props;
    if (target) {
      this.checkValid(target);
    }
    dispatch(setEntityFormValue(target.name, target.value));

    if (target.name === 'entity_region') {
      let _countryCode = countryCodeByAlpha2(target.value);
      dispatch(
        setKeyContactFormValue('key_contact_country_code', _countryCode)
      );
    }
  },

  countryCodeHandleChange(target) {
    const { dispatch } = this.props;

    if (target) {
      this.checkValid(target);
    }
    dispatch(setKeyContactFormValue(target.name, target.value));
  },

  submit: function() {
    const {
      consumer_account,
      current_entity,
      dispatch,
      entity_values,
      form_values,
      hasOneApplicableLegalTypes,
      new_entity_with_existing_user,
      params,
      user_values,
    } = this.props;
    if (this.isComplete()) {
      const link = determineRedirectLinks({
        applicationId: params.application_id,
        consumerAccount: consumer_account,
        hasOneApplicableLegalTypes,
        region: form_values.entity_region,
      });

      if (new_entity_with_existing_user) {
        dispatch(
          updateEntityContactAndRegionInfo(
            current_entity.id,
            entity_values,
            params.application_id,
            link
          )
        );
      } else {
        dispatch(
          updateStartInfoAndLoginUser(
            user_values,
            link,
            current_entity.id,
            entity_values,
            params.application_id
          )
        );
      }
    }
  },

  checkEmail(value) {
    const { dispatch } = this.props;
    dispatch(checkEmailisUnique(value));
  },

  checkValid: function(target) {
    // validate, update progress
    const { form_errors } = this.state;

    switch (target.name) {
      case 'entity_region':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entity_region: 'You must enter your company location',
            },
          });
        } else {
          const { entity_region, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'entity_state':
        if (target.value.length < 1) {
          this.setState({
            form_errors: { ...form_errors, entity_state: 'State' },
          });
        } else {
          const { entity_state, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
      case 'key_contact_password':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              key_contact_password: 'You must enter your password',
            },
          });
        } else {
          var { key_contact_password, ...rest_errors } = form_errors;
          this.setState({
            form_errors: { ...rest_errors },
          });
        }
        break;
    }
  },

  getInitialState: function() {
    return {
      form_errors: {},
    };
  },

  render: function() {
    const {
      title,
      form_inputs,
      form_values,
      error,
      supplier_logo_url,
      email_valid,
      email_validating,
      user,
      application_status,
      loading_error,
      supplier_trading_name,
      header_title,
      application_start_submitting,
    } = this.props;
    const { form_errors } = this.state;
    var form_elements = [];
    var form_loading = { key_contact_email: email_validating };
    var content;

    let emailError = form_errors['key_contact_email'];
    if (
      !emailError &&
      !email_valid &&
      !email_validating &&
      user.email != form_values['key_contact_email']
    ) {
      emailError = 'Email already in use. Please use another';
    }

    Object.keys(form_inputs).forEach((value, index) => {
      switch (value) {
        case 'key_contact_password':
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              type="password"
              error={form_errors[value]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />
          );

          break;
        case 'key_contact_email':
          form_elements.push(
            <EmailInput
              key={index}
              id={value}
              customError={emailError}
              handleChange={this._emailChangeHandler}
              handleBlur={this._emailChangeHandler}
              loading={email_validating}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />
          );
          break;
        case 'entity_region':
          form_elements.push(
            <RegionStatesDropdown
              key={index}
              id={value}
              regionId="entity_region"
              stateId="entity_state"
              label={form_inputs[value].label}
              regionError={form_errors['entity_region']}
              stateError={form_errors['entity_state']}
              regionValue={form_values['entity_region']}
              stateValue={form_values['entity_state']}
              required={form_inputs[value].required}
              handleChange={this.regionStateHandleChange}
            />
          );
          break;
        default:
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              type={form_inputs[value].type}
              error={form_errors[value]}
              loading={form_loading[value]}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
            />
          );
          break;
      }
    });

    var content_style = styles.content;

    let heading = (
      <div className={`hero-head  has-text-centered ${styles.heading_height}`}>
        {supplier_logo_url && (
          <div className={styles.nav_logo_container}>
            <div className={styles.nav_logo}>
              <img
                src={supplier_logo_url}
                alt="Your logo"
                className={styles.logo}
              />
            </div>
          </div>
        )}

        <div
          className={`is-size-normal has-text-weight-normal ${styles.heading_title}`}
        >
          {title}
        </div>
      </div>
    );

    if (isMobile()) {
      heading = (
        <div
          className={`hero-head has-background-primary has-text-centered ${styles.heading_height}`}
        >
          {supplier_logo_url && (
            <img
              className={`${styles.mobile_logo} px-1 py-1`}
              src={supplier_logo_url}
              alt="Your logo"
            />
          )}
          <div
            className={`is-size-normal has-text-weight-normal has-text-left ${styles.mobile_heading_title}`}
          >
            {title}
          </div>
        </div>
      );

      content_style = styles.mobile_content;
    }

    let footer = (
      <div className="hero-foot has-text-centered has-text-weight-normal">
        <div className="context is-size-small my-5 has-text-black">
          <div className="">Powered by 1Centre</div>
          <div className="mt-4 mb-6">
            Please refer to 1Centre's{' '}
            <a
              target="_blank"
              href="https://www.1centre.com/Privacy/index.html"
              className="has-text-link"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            .
          </div>
        </div>
      </div>
    );

    if (isMobile()) {
      footer = (
        <div
          className={`${styles.hero_footer} has-text-centered has-text-weight-normal has-background-white`}
        >
          <div className="context is-size-small my-5 ">
            <div className="">Powered by 1Centre</div>
            <div className="my-4">
              Please refer to 1Centre's{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.1centre.com/Privacy/index.html"
                className={` ${styles.underline}`}
              >
                privacy policy
              </a>
              .
            </div>
          </div>
        </div>
      );
    }

    let password_strength = <div className={styles.strength}>&nbsp;</div>;
    var strength = 0;
    if (form_values.key_contact_password.length > 0) {
      var indicators = [];
      strength = checkPassStrength(form_values.key_contact_password);

      for (var i = 0; i < 3; i++) {
        if (parseInt(strength) <= i) {
          indicators.push(
            <div className={styles.indicator_off} key={'indicator-' + i}></div>
          );
        } else {
          indicators.push(
            <div className={styles.indicator_on} key={'indicator-' + i}></div>
          );
        }
      }
      password_strength = (
        <div className={styles.strength} key="strength">
          <span className={styles.label}>Password strength</span>{' '}
          <span className={styles.indicators}>{indicators}</span>
        </div>
      );
    }

    if (loading_error) {
      content = (
        <div>
          <SectionCircle key="1" title="">
            <SectionDescription text="oops - Your application has either already been approved or you've entered an invalid email address. If you need a hand email support@1centre.com and someone will help you out." />
          </SectionCircle>
        </div>
      );
    } else if (application_status === 'deleted') {
      content = (
        <div>
          <SectionCircle key="1" title="">
            <SectionDescription text="oops - looks like this application invite has been deleted. Please contact your supplier for more information." />
          </SectionCircle>
        </div>
      );
    } else {
      content = (
        <div className={styles.row}>
          <div className={styles.full_col}>
            <div className={styles.form}>
              {form_elements}
              {password_strength}
            </div>
            <div className={styles.centre_button}>
              <Button
                text="Get started"
                handleClick={this.submit}
                loading_text="submitting"
                disableOnLoading={true}
                loading={application_start_submitting}
              />
            </div>
          </div>
        </div>
      );
    }
    if (loading_error || application_status === 'deleted') {
      return <section className={styles.section}>{content}</section>;
    }

    let showContent = (
      <div className={`hero-body has-text-left px-8 py-0 ${styles.mt120}`}>
        <div className="container">{content}</div>
      </div>
    );

    if (isMobile()) {
      showContent = (
        <div
          className={`${styles.mt60} ${styles.px60} has-text-left has-text-centered  py-0`}
        >
          <div className="container mt-6">{content}</div>
        </div>
      );
    }

    if (isMobile()) {
      return (
        <React.Fragment>
          <div className="columns my-0">
            <div className="column px-0 py-0">
              <section
                className={`hero ${styles.mobile_fullheight} has-background-white`}
              >
                {heading}
                {showContent}
                {footer}
              </section>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="columns my-0">
          <div className="column is-3 is-hidden-touch px-0 py-0">
            <section className="hero is-primary is-fullheight " />
          </div>

          <div className="column px-0 py-0">
            <section className="hero is-fullheight  has-background-white">
              {heading}
              {showContent}
              {footer}
            </section>
          </div>

          <div className="column is-3 is-hidden-touch px-0 py-0">
            <section className="hero is-primary  is-fullheight" />
          </div>
        </div>
      </React.Fragment>
    );
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
var defaults = {
  title: 'Start your application with Application_supplier here.',
  header_title: 'Digital trade credit application',
  form_inputs: {
    entity_region: {
      label: 'Business location',
      required: true,
      type: 'text',
    },
    key_contact_email: {
      label: 'Email',
      required: true,
      type: 'email',
    },
    key_contact_password: {
      label: 'Create password',
      required: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  let application_type = '',
    application_status;
  let application_submission_status = '';
  if (state.cob_section.application) {
    application_type = state.cob_section.application.attributes.application_type.toLowerCase();
    application_submission_status =
      state.cob_section.application.attributes.submission_status;
    application_status = state.cob_section.application.attributes.status;

    if (state.cob_section.application.attributes.application_type == 'cash') {
      defaults.header_title = 'Digital Cash Account Application';
    }
  }

  // let new_entity_with_existing_user =
  //   !state.current_user.data.data.attributes.new_user &&
  //   !state.current_user.current_entity.attributes.region;
  let new_entity_with_existing_user =
    !state.current_user.data.data.attributes.new_user &&
    !get(state, 'current_user.current_entity.attributes.region');
  let form_inputs = Object.assign({}, defaults.form_inputs);

  if (new_entity_with_existing_user) {
    delete form_inputs.key_contact_email;
    delete form_inputs.key_contact_password;
  }

  // const supplier_company_name = startCase(
  // camelCase(state.cob_section.supplier.attributes.company_name || ""),
  // );

  const supplier_company_name =
    state.cob_section.supplier.attributes.company_name;

  return {
    title: defaults.title.replace(
      'Application_supplier',
      supplier_company_name
    ),
    form_inputs: form_inputs,
    form_values: {
      entity_region: state.cob_business.entity_region,
      entity_state: state.cob_business.entity_state,
      key_contact_email: state.cob_business.key_contact_email,
      key_contact_password: state.cob_business.key_contact_password,
    },
    user: state.current_user.data.data.attributes,
    email_valid: state.cob_business.email_valid,
    email_validating: state.cob_business.email_validating,
    application_submission_status: application_submission_status,
    user_values: {
      email: state.cob_business.key_contact_email,
      password: state.cob_business.key_contact_password,
    },
    entity_values: {
      key_contact_email: state.cob_business.key_contact_email,
      region: state.cob_business.entity_region,
      state: state.cob_business.entity_state,
    },
    current_entity: state.current_user.current_entity,
    application_status: application_status,
    header_title: defaults.header_title,
    new_entity_with_existing_user: new_entity_with_existing_user,
    application_start_submitting: state.cob_review.application_start_submitting,
    supplier_company_name,
  };
})(ApplicationStart);
