/* eslint-disable max-lines */
import Collapse from '@material-ui/core/Collapse';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import debounce from 'debounce';
import get from 'lodash.get';
import AddonConfigModel from 'models/AddonConfigModel';
import ReportApplicationModel from 'models/ReportApplicationModel';
import UserModel from 'models/UserModel';
import { alertTypes as alertDropdownOptions } from 'modules/addons/components/digital-onboarding/AlertModuleComponents';
import styles from 'modules/reporting/components/css/Reporting';
// import commonStyles from "modules/direct-debit-authority/css/DirectDebitAuthorityIndex.css";
import commonStyles from 'modules/reporting/components/css/Reporting.css';
import useFilterState from 'modules/reporting/hooks/useFilterState';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import StatusIndicator from 'modules/shared/components/widgets/static/StatusIndicator.js';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import useCollapsibleTableRowState from 'modules/shared/hooks/useCollapsibleTableRowState';
import { USER_UPDATE_PREFERENCE_SUCCESS } from 'modules/user/constants';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { formatDate } from 'utils/dateFormatter';
import { capitalizeSentence } from 'utils/formatting';

import ApplicationName from '../../reporting/components/Reporting/ApplicationName';

const LOCAL_STORAGE_PREFIX = 'searchParams-alertsModule-';
const DEFAULT_PER_PAGE = 25;
const DEBOUNCE_INTERVAL = 500;

function ExportApplicationPopup(props) {
  return (
    <SquareModal title="Export" size="small">
      <div class_name="has-text-centered">
        {`A link to the CSV file will be sent to ${props.email} in few minutes.`}
      </div>
      <div>
        <Button text="Ok" handleClick={props.closePopup} />
      </div>
    </SquareModal>
  );
}

function Search(props) {
  if (!props.isVisible) {
    return null;
  }

  const {
    searchParams,
    updateSearchParams,
    dynamicFilterOptions,
    currentUser,
  } = props;
  const { filter, filterParams, onToggleIsOpen } = useFilterState(searchParams);
  const [showExportPopup, setShowExportPopup] = useState(false);
  const [alertTypeOptions, setAlertTypeOptions] = useState([]);

  const onChangeSearchKeyWord = (event) => {
    const value = get(event, 'target.value');
    updateSearchParams({ key_word: value, page: 1 });
  };

  const applicationFilter = React.cloneElement(filter, {
    dynamicFilterOptions: {
      ...dynamicFilterOptions,
      alert_type: alertTypeOptions,
    },
    filterType: 'alerts',
  });

  const processAlertTypeOptions = (addonConfigs) => {
    if (!addonConfigs || addonConfigs.length === 0) {
      return;
    }

    const alertTypes = get(
      addonConfigs[0],
      'latestVersion.config.alert_types',
      []
    );

    if (alertTypes.length === 0) {
      return;
    }

    const filteredAlertTypes = Object.keys(alertTypes).filter(
      (key) => alertTypes[key]
    );
    const processedAlertTypes = alertDropdownOptions.filter((option) =>
      filteredAlertTypes.includes(option.value)
    );

    setAlertTypeOptions(processedAlertTypes);
  };

  useEffect(() => {
    updateSearchParams(filterParams);
  }, [filterParams]);

  useEffect(() => {
    (async function () {
      const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
        addonType: 'alert_module',
        entityId: currentUser.currentEntity.id,
      });
      processAlertTypeOptions(addonConfigs);
    })();
  }, []);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={styles.search_container}>
        <div className={`${styles.alert_search_input} mr-6`}>
          <BorderedTextField
            id="search"
            label="Search"
            value={searchParams.key_word}
            onChange={onChangeSearchKeyWord}
          />
        </div>
        <div className={styles.search_items}>
          <span
            className={`${styles.search_item} ${styles.clickable} ${styles.mobile_invisible}`}
            onClick={onToggleIsOpen}
            style={{ width: '30%' }}
          >
            Advanced&nbsp;
            <u>
              <b>search</b>
            </u>
          </span>
        </div>
        {showExportPopup && (
          <ExportApplicationPopup
            email={currentUser.email}
            closePopup={() => {
              setShowExportPopup(false);
            }}
          />
        )}
        <div className={styles.mobile_invisible}>{applicationFilter}</div>
      </div>
    </MuiThemeProvider>
  );
}

function ApplicationTableHeader(props) {
  return (
    <div
      className={`${styles.grid_table} ${styles.table_header} ${styles.mobile_invisible}`}
    >
      <div>Customer</div>
      <div>Status</div>
      <div>Limit</div>
      <div>Alert date</div>
      <div>Alert</div>
      <div>Tier</div>
    </div>
  );
}

function keyStatusName(statusName) {
  return statusName.toLowerCase().replace(/ /g, '-');
}

function ApplicationStatusIndicators({ id, statuses }) {
  return statuses.map((status) => (
    <StatusIndicator
      status={status.status}
      withStatusName={false}
      key={`indicator-${keyStatusName(status.name)}-${id}`}
    />
  ));
}

function ApplicationStatusDetails(props) {
  const { applicationId, canReview, isSupplier, statuses } = props;

  const applicationStatus =
    statuses.find(({ name }) => name === 'Application') || {};
  const applicationIncomplete = ['not_started', 'in_progress'].includes(
    applicationStatus.status
  );
  const buttonText = applicationIncomplete ? 'Track' : 'Review';

  function renderStatus() {
    return statuses.map((status) => (
      <div
        className={styles.status_details_grid_table}
        key={`details-${keyStatusName(status.name)}-${applicationId}`}
      >
        <div className={styles.status_details_name}>{status.name}</div>
        <div>
          <StatusIndicator status={status.status} withStatusName={true} />
        </div>
        <div className={styles.mobile_invisible}>{status.started_on}</div>
        <div className={styles.mobile_invisible}>{status.completed_on}</div>
        <div className={styles.mobile_invisible}>{status.other}</div>
      </div>
    ));
  }

  return (
    <Fragment>
      {renderStatus()}
      {isSupplier && (
        <div className={styles.review_button}>
          <Button
            text={buttonText}
            link={
              canReview ? `/dashboard/applications/${props.applicationId}` : ''
            }
            disabled={!canReview}
          />
          {!canReview && (
            <p className="mt-3">
              Please contact your team admin for authorising access to review
              virtual credit files.
            </p>
          )}
        </div>
      )}
    </Fragment>
  );
}

function getApplicationLimitColumn({ application, filterLimitType }) {
  if (filterLimitType === 'approved_limits') {
    return application.formattedAcceptedLimit;
  }

  return application.formattedRequestedLimit;
}

function ApplicationRow(props) {
  const { application, current, isSupplier, searchParams } = props;
  const { isOpen, onToggleIsOpen } = useCollapsibleTableRowState();

  useEffect(() => {
    if (!current && isOpen) onToggleIsOpen();
  }, [current]);

  const limit = getApplicationLimitColumn({
    application,
    filterLimitType: searchParams.limit_type,
  });

  return (
    <div>
      <div className={styles.grid_table}>
        <div className={commonStyles.account_name}>
          <Link to={`/dashboard/applications/${application.id}`}>
            <div className={styles.trading_name}>
              <ApplicationName
                applicationName={application.name}
                versionNumber={application.versionNumber}
                isFloating={application.isFloating}
              />
            </div>
          </Link>
        </div>
        <div className={styles.cell}>
          <ApplicationStatusIndicators
            statuses={application.statusesFormatted}
            id={application.id}
          />
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {limit}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {formatDate(application.alertDate, 'DD/MM/YY')}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.formattedAlertType}
        </div>
        <div className={`${styles.cell} ${styles.mobile_invisible}`}>
          {application.branchOrOwner}
        </div>
      </div>
      <Collapse in={current && isOpen}>
        <div className={styles.details}>
          <div className={styles.alert_text}>
            {`${application.formattedAlertType}`}
          </div>
          <ApplicationStatusDetails
            applicationId={application.id}
            canReview={application.canReview}
            isSupplier={isSupplier}
            statuses={application.statusesFormatted}
          />
        </div>
      </Collapse>
    </div>
  );
}

function ApplicationTable(props) {
  const {
    applications,
    isSupplier,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchMoreApplicationsOnMobile,
    searchParams,
  } = props;
  const [currentIndex, setCurrentIndex] = useState();

  if (loading) return <SimpleLoader />;
  const hintOnMobile = noMoreApplications
    ? 'No more records'
    : 'Click to get more';

  return (
    <div className={commonStyles.table_content}>
      <ApplicationTableHeader meta={meta} isSupplier={isSupplier} />
      {applications.map((application, index) => (
        <ApplicationRow
          application={application}
          current={currentIndex === index}
          index={index}
          isSupplier={isSupplier}
          key={`application-${index}`}
          searchParams={searchParams}
          setCurrentIndex={setCurrentIndex}
        />
      ))}
      <div
        className={`${styles.mobile_visible} ${styles.bottom_loader}`}
        onClick={
          !loadingMore && !noMoreApplications && onFetchMoreApplicationsOnMobile
        }
      >
        {loadingMore ? (
          <SimpleLoader css_class="loader_relative" />
        ) : (
          <span>{hintOnMobile}</span>
        )}
      </div>
    </div>
  );
}

function useApplicationsState(props) {
  const { currentUser, location } = props;
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreApplications, setNoMoreApplications] = useState(false);
  const [pageOfMobile, setPageOfMobile] = useState(1);
  const [applications, setApplications] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [meta, setMeta] = useState({});
  const entityId = get(currentUser, 'currentEntity.id');
  const defaultValues = {
    acting_as_supplier: currentUser.currentEntity.supplier,
    alert_date: 'All',
    alert_type: 'All',
    consumer_active_in_company_office: false,
    page: 1,
    per: get(
      currentUser,
      'preferenceByCurrentEntity.reportApplicationPerPage',
      DEFAULT_PER_PAGE
    ),
    status: 'started',
  };
  const [searchParams, setSearchParams] = useState({
    ...defaultValues,
    ...location.query,
    ...(JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${entityId}`)
    ) || {}),
  });

  const onFetchFilterOptions = () => {
    (async () => {
      const options = await ReportApplicationModel.loadApplicationFilterOptions(
        {
          accessToken: currentUser.accessToken,
          entityId,
        }
      );
      setFilterOptions(options);
    })();
  };

  const onFetchApplications = (params) => {
    params['source_page'] = 'alerts';
    (async () => {
      setLoading(true);
      const { applications, meta } =
        await ReportApplicationModel.loadApplications({
          accessToken: currentUser.accessToken,
          entityId,
          params,
        });
      browserHistory.push({
        pathname: '/dashboard/alert_applications',
        query: params,
        state: get(location, 'state'),
      });
      setNoMoreApplications(applications.length < DEFAULT_PER_PAGE);
      setApplications(applications);
      setMeta(meta);
      setLoading(false);
    })();
  };

  const onFetchMoreApplicationsOnMobile = () => {
    const existingApplications = applications;
    const page = pageOfMobile + 1;
    const params = { ...searchParams, page };

    (async () => {
      setLoadingMore(true);
      const { applications, meta } =
        await ReportApplicationModel.loadApplications({
          accessToken: currentUser.accessToken,
          entityId,
          params,
        });
      setNoMoreApplications(applications.length < DEFAULT_PER_PAGE);
      setApplications(existingApplications.concat(applications));
      setPageOfMobile(page);
      setMeta(meta);
      setLoadingMore(false);
    })();
  };

  const exportApplications = (params) => {
    (async () => {
      await ReportApplicationModel.exportApplications({
        accessToken: currentUser.accessToken,
        entityId,
        params,
      });
    })();
  };

  const resetMobilePage = () => {
    setPageOfMobile(1);
    setNoMoreApplications(false);
  };

  return {
    applications,
    exportApplications,
    filterOptions,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchApplications,
    onFetchFilterOptions,
    onFetchMoreApplicationsOnMobile,
    resetMobilePage,
    searchParams,
    setSearchParams,
  };
}

function AlertsIndex(props) {
  const { currentUser, dispatch } = props;
  const {
    applications,
    exportApplications,
    filterOptions,
    loading,
    loadingMore,
    meta,
    noMoreApplications,
    onFetchApplications,
    onFetchFilterOptions,
    onFetchMoreApplicationsOnMobile,
    resetMobilePage,
    searchParams,
    setSearchParams,
  } = useApplicationsState(props);

  const fetchApplication = useRef(
    debounce(onFetchApplications, DEBOUNCE_INTERVAL)
  ).current;

  useEffect(() => {
    onFetchFilterOptions();
  }, []);

  useEffect(() => {
    fetchApplication(searchParams);
    resetMobilePage();
  }, [searchParams]);

  const onClickPage = (page) => {
    updateSearchParams({ page });
  };

  const onClickPerPage = (per) => {
    updateSearchParams({ page: 1, per });
    currentUser.updatePreferences({ reportApplicationPerPage: per });
    dispatch({
      payload: currentUser.attributes.preference,
      type: USER_UPDATE_PREFERENCE_SUCCESS,
    });
  };

  const updateSearchParams = (params) => {
    const updatedParams = { ...searchParams, ...params };
    const newSearchParams = {};
    Object.keys(updatedParams).forEach((key) => {
      if (![null, '', 'All'].includes(updatedParams[key])) {
        newSearchParams[key] = updatedParams[key];
      }
    });
    if (!newSearchParams.alert_date) {
      delete newSearchParams.alert_date_from;
      delete newSearchParams.alert_date_to;
    }
    setSearchParams(newSearchParams);
    localStorage.setItem(
      `${LOCAL_STORAGE_PREFIX}${get(currentUser, 'currentEntity.id')}`,
      JSON.stringify(newSearchParams)
    );
  };

  return (
    <div className={styles.alert_container}>
      <div className={styles.header_container}>
        <div className={styles.section}>
          <div className={styles.header_title}>
            <AdminInnerTitle text="Alerts Module" isNormalFont={true} />
          </div>
          <Search
            searchParams={searchParams}
            updateSearchParams={updateSearchParams}
            dynamicFilterOptions={filterOptions}
            exportApplications={exportApplications}
            isVisible={searchParams.acting_as_supplier}
            currentUser={currentUser}
            dispatch={dispatch}
          />
        </div>
      </div>

      <div className={styles.table_container}>
        <div className={styles.section}>
          <ApplicationTable
            applications={applications}
            isSupplier={searchParams.acting_as_supplier}
            loading={loading}
            loadingMore={loadingMore}
            meta={meta}
            noMoreApplications={noMoreApplications}
            onFetchMoreApplicationsOnMobile={onFetchMoreApplicationsOnMobile}
            searchParams={searchParams}
          />
          <div className={styles.mobile_invisible}>
            <Pagination
              dataLength={meta.total}
              currentPage={searchParams.page}
              todosPerPage={searchParams.per}
              resourceName="Applications"
              optionViewPage={[
                { label: '25', value: DEFAULT_PER_PAGE },
                { label: '50', value: 50 },
              ]}
              handleClick={onClickPage}
              handleClickTodosPerPage={onClickPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(AlertsIndex);
