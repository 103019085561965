import React, { ReactElement } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { onSetActiveQuestionAndAnswer } from 'modules/shared/components/top/Overlay/redux/actions';
import QuestionIcon from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink/QuestionIcon';
import { HELP_TEXT } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';
import { Link } from './styles';

const QAndAHelpLink = (props): ReactElement => {
  const { activeQuestionAndAnswer, dispatch, questionKey } = props;

  const questionText = HELP_TEXT[questionKey];

  return (
    <div onClick={() => dispatch(onSetActiveQuestionAndAnswer(questionKey))}>
      <Link>{questionText}</Link>{' '}
      <QuestionIcon isActive={activeQuestionAndAnswer === questionKey} />
    </div>
  );
};

export default connect((state) => {
  return {
    activeQuestionAndAnswer: get(
      state,
      'applicationOverlayReducer.activeQuestionAndAnswer',
      ''
    ),
  };
})(QAndAHelpLink);
