import get from 'lodash.get';
import Tab from 'mui-latest/Tab';
import Tabs from 'mui-latest/Tabs';
import React, { ReactElement, useEffect, useState } from 'react';
import CallCentreLog from 'modules/new-applications/components/application-actions/CallCentreLog';
import EmailLog from 'modules/new-applications/components/application-actions/EmailLog';
import TimeLog from 'modules/new-applications/components/application-actions/TimeLog';
import Paper from '@material-ui/core/Paper';
import AddonConfigModel from 'models/AddonConfigModel';
import mixpanel from 'mixpanel-browser';

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

export default function HistoryLog(props: Props): ReactElement | null {
  const { application, currentUser } = props;

  const [currentTab, setCurrentTab] = useState(0);
  const onChangeTab = (_, newTab) => {
    setCurrentTab(newTab);

    if (newTab === 1) {
      mixpanel.track('VCF - History log - Call centre log', {
        'Entity ID': entityId,
        distinct_id: get(currentUser, 'data.data.id'),
      });
    }
  };

  const accessToken = get(currentUser, 'accessToken');
  const entityId = get(currentUser, 'currentEntity.id');
  const applicationId = get(application, 'id');

  const [isCallCentreActive, setIsCallCentreActive] = useState(false);
  useEffect(() => {
    (async () => {
      const addonConfigs = await AddonConfigModel.fetchAddonConfigByAddonType({
        accessToken,
        addonType: ['call_centre'],
        entityId,
      });
      const addonConfig = addonConfigs[0];
      setIsCallCentreActive(addonConfig && addonConfig.active);
    })();
  }, []);

  return (
    <Paper square elevation={0}>
      <Tabs
        style={{ textTransform: 'none' }}
        indicatorColor="primary"
        textColor="primary"
        value={currentTab}
        onChange={onChangeTab}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="credit check tabs"
      >
        <Tab
          label={<span>Email Log</span>}
          value={0}
          style={{ textTransform: 'none' }}
        />
        <Tab
          label={<span>Time Log</span>}
          value={1}
          style={{ textTransform: 'none' }}
        />
        {true && (
          <Tab
            label={<span>Call Centre Log</span>}
            value={2}
            style={{ textTransform: 'none' }}
          />
        )}
      </Tabs>
      <div className="pt-4">
        {currentTab === 0 && <EmailLog {...props} />}
        {currentTab === 1 && <TimeLog {...props} />}
        {currentTab === 2 && <CallCentreLog {...props} />}
      </div>
    </Paper>
  );
}
