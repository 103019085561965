export const COB_TRADE_REFERENCE_SET_REFEREE_DATA =
  'COB_TRADE_REFERENCE_SET_REFEREE_DATA';
export const COB_TRADE_REFERENCE_SECTION_COMPLETE =
  'COB_TRADE_REFERENCE_SECTION_COMPLETE';
export const COB_TRADE_REFERENCE_COMPONENT_COMPLETE =
  'COB_TRADE_REFERENCE_COMPONENT_COMPLETE';
export const COB_TRADE_REFERENCE_SET_REFEREE_DETAILS_INDEX =
  'COB_TRADE_REFERENCE_SET_REFEREE_DETAILS_INDEX';
export const COB_TRADE_REFERENCE_PRESET_REFEREE_DATA =
  'COB_TRADE_REFERENCE_PRESET_REFEREE_DATA';
