import { Alert } from '@material-ui/lab';
import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import Modal from 'modules/shared/components/v2/Modal';
import React, {
  ChangeEvent,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import CopyTextToClipboard from './CopyTextToClipboard';
import styles from './css/SAMLSettingsModal.css';

export const samlConfigDefaultValues = {
  certificate_fingerprint: '',
  sso_url: '',
};

export type SAMLSettings = typeof samlConfigDefaultValues;

type SAMLSettingsModalProps = {
  isOpen: boolean;
  entityID: string;
  baseSAMLSettings?: SAMLSettings;
  showPopup: () => void;
  handlePopupClose: () => void;
  handleClose: () => void;
};

const SAMLSettingsModalFooter = styled.div`
  gap: 20px;
`;

const SAMLIDPParamsOl = styled.ol`
  padding-left: 14px;
  margin-top: 10px;
`;

const CopyBtnContainer = styled.div`
  position: absolute;
  right: 3px;
  top: 20px;
  transform: translateY(-50%);
  text-align: center;
  width: 30px;
`;

const SAMLSetupInstructions = memo(({ entityID }: { entityID: string }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ acs_url, identifier }, setIDPParams] = useState<{
    'acs_url'?: string;
    'identifier'?: string;
  }>({});

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await api('sso_saml').getIDPParams(entityID);
        setIDPParams(get(res, 'data.data'));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Fragment>
      <div>
        To setup SAML authentication for your organization through an Identity
        Provider like Azure AD, Okta or any custom SAML 2.0 Provider,
        <SAMLIDPParamsOl className="mb-4">
          <li>Create an application in your Identity Provider for 1Centre</li>
          <li>
            For the application&apos;s SAML setup add the below fields in the
            setup when needed(Assertion consumer service URL, Identifier)
          </li>
          <li>
            Fill SSO IDP URL, X.509 Certificate Fingerprint with data provided
            by your Identity Provider
          </li>
        </SAMLIDPParamsOl>
        {/* ACS URL field */}
        <div className="mb-2">
          <label htmlFor="sso_url">Assertion consumer service URL</label>
        </div>
        <div className="mb-5 is-relative">
          <BorderedTextField
            name="sso_url"
            value={acs_url}
            readOnly
            loading={isLoading}
            disabled
            helperText={
              'Also called "Relying party service URL" or "Reply URL"'
            }
          />
          <CopyBtnContainer>
            <CopyTextToClipboard text={acs_url} />
          </CopyBtnContainer>
        </div>
        {/* Identifier field */}
        <div className="mb-2">
          <label htmlFor="sso_url">Identifier</label>
        </div>
        <div className="mb-5 is-relative">
          <BorderedTextField
            name="sso_url"
            value={identifier}
            readOnly
            loading={isLoading}
            disabled
            helperText={
              'Also called "Issuer" or "Relying party trust identifier"'
            }
          />
          <CopyBtnContainer>
            <CopyTextToClipboard text={identifier} />
          </CopyBtnContainer>
        </div>
      </div>
    </Fragment>
  );
});

SAMLSetupInstructions.displayName = 'SAMLSetupInstructions';

const SAMLSettingsModal = memo(
  (props: SAMLSettingsModalProps): JSX.Element | null => {
    const {
      isOpen,
      entityID,
      showPopup,
      handlePopupClose,
      handleClose,
      baseSAMLSettings,
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [formValues, setFormValues] = useState(
      baseSAMLSettings || samlConfigDefaultValues
    );
    const { sso_url, certificate_fingerprint } = formValues;
    const hasConfig =
      baseSAMLSettings &&
      baseSAMLSettings.certificate_fingerprint &&
      baseSAMLSettings.sso_url;

    const handleSaveClick = useCallback(async () => {
      try {
        setIsLoading(true);
        await api('sso_saml').updateDetails(entityID, formValues, hasConfig);
        setIsLoading(false);
        handleClose();

        if (!hasConfig) {
          showPopup();
        }
      } catch (error) {
        setErrors(get(error, 'response.data.errors', {}));
        setIsLoading(false);
      }
    }, [entityID, formValues, hasConfig]);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLElement>) => {
      const el = e.target as HTMLInputElement;
      setFormValues((prevState) => ({
        ...prevState,
        [el.name]: el.value || '',
      }));
      if (el.value) {
        setErrors((prevErrors) => ({ ...prevErrors, [el.name]: undefined }));
      }
    }, []);

    const handleCancelClick = useCallback(() => {
      if (!hasConfig) {
        handlePopupClose();
      }
      handleClose();
    }, [hasConfig, handlePopupClose, handleClose]);

    useEffect(() => {
      if (
        baseSAMLSettings &&
        baseSAMLSettings.certificate_fingerprint &&
        baseSAMLSettings.sso_url
      ) {
        setFormValues(baseSAMLSettings);
      }
    }, [baseSAMLSettings]);

    if (!FEATURE_FLAGS.SAML_FLOW || !isOpen) {
      return null;
    }

    const ModalFooter = (
      <SAMLSettingsModalFooter className="is-full-width is-flex is-justify-content-center">
        <Button
          disabled={isLoading}
          text="Cancel"
          white={true}
          handleClick={handleCancelClick}
        />
        <Button
          loading={isLoading}
          disableOnLoading
          text="Save"
          handleClick={handleSaveClick}
        />
      </SAMLSettingsModalFooter>
    );

    return (
      <Modal
        title="Enable SAML authentication"
        centerTitle
        onClose={handleCancelClick}
        footer={ModalFooter}
        bodyClassNames="px-7"
        headClassNames="pt-6"
        footClassNames="pb-6"
        cardClassNames={styles.saml_setting_modal_card}
      >
        {!hasConfig && (
          <Alert severity="warning" className="mb-5">
            You need to submit details before enabling SAML for your
            organization
          </Alert>
        )}

        <SAMLSetupInstructions entityID={entityID} />

        <div className="mb-2">
          <label htmlFor="sso_url">SSO IDP URL</label>
        </div>
        <div className="mb-5">
          <BorderedTextField
            placeholder="eg: https://example.com/login/saml"
            name="sso_url"
            value={sso_url}
            onChange={handleInputChange}
            disabled={isLoading}
            error={errors['sso_url']}
            helperText={
              (errors['sso_url'] || []).join(', ') ||
              'Also called "SSO Service Location", "Login URL", or "SAML Token Issuance Endpoint"'
            }
          />
        </div>

        <div className="mb-2">
          <label htmlFor="certificate_fingerprint">
            X.509 Certificate Fingerprint
          </label>
        </div>
        <BorderedTextField
          name="certificate_fingerprint"
          placeholder="eg: D2:75:81:1A:E8:0C:6C:A9:41:0F:6E:83:F6:D1:52:25:45:58:89:FB"
          value={certificate_fingerprint}
          onChange={handleInputChange}
          disabled={isLoading}
          error={errors['certificate_fingerprint']}
          helperText={
            (errors['certificate_fingerprint'] || []).join(', ') ||
            'Also called "Thumbprint"'
          }
        />
      </Modal>
    );
  }
);

export default SAMLSettingsModal;
