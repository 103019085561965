import BaseModel from 'models/BaseModel';
import RecordHistoryModel from 'models/RecordHistoryModel';

export const STOP_CREDIT_OPTIONS = [
  {
    label: 'Delinquent 60 Days',
    value: 'days_60',
  },
  {
    label: 'Delinquent 90 Days',
    value: 'days_90',
  },
  {
    label: 'Delinquent >120 Days +',
    value: 'days_120_plus',
  },
  {
    label: 'In terms',
    value: 'in_terms',
  },
  {
    label: 'Monitoring',
    value: 'monitoring',
  },
  {
    label: 'Stop credit',
    value: 'stop_credit',
  },
  {
    label: 'Undesirable',
    value: 'undesirable',
  },
  {
    label: 'Write off',
    value: 'write_off',
  },
];

export default class StopCreditModel extends BaseModel {
  get formattedDeliquencyPeriod() {
    if (this.delinquencyPeriod === 'null') {
      return null;
    }

    const selectedPeriod =
      STOP_CREDIT_OPTIONS.find(
        (option) => option.value === this.delinquencyPeriod
      ) || {};

    return selectedPeriod.label || null;
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignManyRelationship({
      key: 'stop_credit_histories',
      model: RecordHistoryModel,
    });
  }
}
