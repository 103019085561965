import Cardholder from 'modules/consumer-onboarding/components/review/Cardholder/index';
import React from 'react';
import { connect } from 'react-redux';
import { getCardsAddonConfig, getCardsAddonRule } from 'utils/cardsAddon';

function ReviewCards(props) {
  const { cardholders, config } = props;

  if (cardholders.length === 0) {
    return null;
  }

  return (
    <section>
      {cardholders.map((cardholder, index) => (
        <Cardholder
          key={`review-cardholder-${index}`}
          index={index}
          cardholder={cardholder}
          config={config}
        />
      ))}
    </section>
  );
}

export default connect((state) => {
  const cobSection = state.cob_section;
  const addonRule = getCardsAddonRule(cobSection.addonRules);
  const config = getCardsAddonConfig(addonRule);
  const cardholders = state.cob_cards.cardholders;
  const guarantors = state.cob_guarantors.form_values || [];
  const guarantorApplicant = guarantors.find(
    (guarantor) => guarantor.is_applicant
  );

  return {
    cardholders,
    config,
    guarantorApplicant,
  };
})(ReviewCards);
