import { FormControl, FormHelperText } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React from 'react';
import { Controller } from 'react-hook-form';

import styles from './css/FormRadioBox.css';

export default function FormRadioBox(props) {
  const { control, defaultValue, error, id, label, name, options } = props;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <FormControl error={!!error}>
        <div className={styles.label}>{label}</div>
        <Controller
          as={
            <RadioGroup
              name={name}
              defaultValue={defaultValue}
              defaultChecked={defaultValue}
            >
              {options.map((option) => (
                <FormControlLabel
                  name={id}
                  key={`${name}-${option.label}`}
                  value={option.value}
                  control={<Radio />}
                  label={<div className={styles.label}>{option.label}</div>}
                />
              ))}
            </RadioGroup>
          }
          name={name}
          type="radio"
          control={control}
          defaultValue={defaultValue}
          error
        />
        <FormHelperText
          error={!!error}
          className={styles.material_helper_text}
          style={
            error
              ? { fontSize: 10, fontWeight: 800 }
              : { fontSize: 10, fontWeight: 400 }
          }
        >
          {error}
        </FormHelperText>
      </FormControl>
    </MuiThemeProvider>
  );
}
