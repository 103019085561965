import { Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Conainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .account-monitoring-input {
    max-width: 450px;
  }
`;

type AccountMonitoringItemWrapperProps = {
  children: ReactNode;
  title: string;
};

export const AccountMonitoringItemWrapper = (
  props: AccountMonitoringItemWrapperProps
) => (
  <Conainer>
    <Typography>{props.title}</Typography>
    {props.children}
  </Conainer>
);
