import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import TextInput from 'modules/shared/components/inputs/TextInput';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

export default class PersonAutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuggestionsVisible: false,
      popoverAnchorElement: null,
    };
  }

  showSuggestions(anchorElement) {
    const { isSuggestionsVisible, popoverAnchorElement } = this.state;

    if (!isSuggestionsVisible || isBlank(popoverAnchorElement)) {
      this.setState({
        isSuggestionsVisible: true,
        popoverAnchorElement: anchorElement,
      });
    }
  }

  hideSuggestions() {
    this.setState({
      isSuggestionsVisible: false,
      popoverAnchorElement: null,
    });
  }

  fullName(item) {
    return [item.firstName, item.middleName, item.lastName]
      .filter((value) => isPresent(value) && value.length > 0)
      .join(' ');
  }

  onBlur = (event) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  onPopoverBlur = (event) => {
    this.hideSuggestions();

    this.onBlur(event);
  };

  onChange = (event) => {
    this.showSuggestions(event.currentTarget);

    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  onFocus = (event) => {
    if (event.target.value.length === 0) {
      this.showSuggestions(event.currentTarget);
    }
  };

  onSelectItem = (suggestion) => {
    if (this.props.onSelectSuggestion) {
      this.props.onSelectSuggestion(suggestion);
    }

    this.hideSuggestions();
  };

  filteredItems() {
    const { value } = this.props;

    if (!value) {
      return [];
    }

    if (!this.state.isSuggestionsVisible) {
      return [];
    }

    const { autosuggestItems } = this.props;

    return autosuggestItems.filter((item) => {
      const name = this.fullName(item);

      return name.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
  }

  suggestions() {
    return this.filteredItems().map((item) => {
      const name = this.fullName(item);

      return (
        <ListItem button key={name} onClick={() => this.onSelectItem(item)}>
          <ListItemText primary={name} />
        </ListItem>
      );
    });
  }

  renderSuggestions() {
    const { isSuggestionsVisible, popoverAnchorElement } = this.state;

    if (this.filteredItems().length === 0) {
      return;
    }

    return (
      <Popover
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        disableAutoFocus={true}
        open={isSuggestionsVisible}
        onClose={this.onPopoverBlur}
      >
        <Paper>
          <List component="nav">{this.suggestions()}</List>
        </Paper>
      </Popover>
    );
  }

  render() {
    const { disabled, error, id, label, name, required, value } = this.props;

    return (
      <div>
        <TextInput
          autocomplete="off"
          disabled={disabled}
          error={error}
          id={id}
          name={name}
          label={label}
          required={required}
          value={value}
          handleFocus={this.onFocus}
          handleBlur={this.onBlur}
          handleChange={this.onChange}
        />
        {this.renderSuggestions()}
      </div>
    );
  }
}
