import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import React, { ReactElement } from 'react';

import { emailAgeOptions } from '../constants';
import { WatchtowerDynamicFieldsTitle } from '../styles';

const EmailAgeInput = (props): ReactElement => {
  return (
    <ContentContainer>
      <div className="pb-4">
        <div className={`${styles.info} pb-4`}>
          <WatchtowerDynamicFieldsTitle>Email age</WatchtowerDynamicFieldsTitle>
          {'Please select the email age range you would like to be alerted'}
        </div>
        <div className="columns">
          <div className="column is-6">
            <BorderedSelect
              options={emailAgeOptions}
              id="email-age-select"
              placeholder="Email age range"
              {...props}
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

export default EmailAgeInput;
