import ReviewAdditional from 'modules/guarantor/components/ReviewAdditional';
import ReviewBusiness from 'modules/guarantor/components/ReviewBusiness';
import ReviewFinancials from 'modules/guarantor/components/ReviewFinancials';
import ReviewGuarantors from 'modules/guarantor/components/ReviewGuarantors';
import ReviewPaperless from 'modules/guarantor/components/ReviewPaperless';
import ReviewPaymentInfo from 'modules/guarantor/components/ReviewPaymentInfo';
import ReviewTradeReferences from 'modules/guarantor/components/ReviewTradeReferences';
import AccordianContainer from 'modules/shared/components/containers/AccordianContainer';
import ReviewCorporateTrust from 'modules/guarantor/components/ReviewCorporateTrust';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './css/Section.css';
import { FEATURE_FLAGS } from 'conf';

class ReviewGuarantor extends Component {
  constructor() {
    super();
    this.state = {
      containerIndex: null,
    };
  }

  updateContainerIndex(guarantorContainerIndex) {
    const { containerIndex } = this.state;
    let index = guarantorContainerIndex;
    if (containerIndex === index) {
      index = null;
    }
    this.setState({ containerIndex: index });
  }

  render() {
    const { authorisation, consumerName, limit, options, title } = this.props;

    const { containerIndex } = this.state;

    const sections = [];
    const components = {
      additional: ReviewAdditional,
      business: ReviewBusiness,
      corporate_trust: ReviewCorporateTrust,
      financials: ReviewFinancials,
      guarantors: ReviewGuarantors,
      paperless: ReviewPaperless,
      payment_info: ReviewPaymentInfo,
      trade_references: ReviewTradeReferences,
    };

    const appAttr = authorisation.application.attributes;

    if (
      appAttr.minimum_trade_references === 0 ||
      appAttr.legal_type === 'personal'
    ) {
      delete options.trade_references;
    }

    Object.keys(options).forEach((key) => {
      sections.push(
        React.createElement(components[key], {
          data: authorisation,
          'data-accordianLabel': options[key].label,
          id: key,
          key,
        })
      );
    });

    return (
      <section className={styles.section}>
        <div className={`${styles.row} mb-2`}>
          <div className={styles.panel}>
            <PanelTitle text={title} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.content}>
            <p className={`${styles.subtitle} mb-2`}>
              {consumerName} is asking for a credit limit of{' '}
              <span className="has-text-weight-normal">${Number(limit)}</span>
            </p>
            <AccordianContainer
              type="review_edit"
              subPages={sections}
              subsection_index={containerIndex}
              handleClick={(index) => this.updateContainerIndex(index)}
            />
          </div>
        </div>
      </section>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    business: {
      label: 'Business details',
    },
    corporate_trust: {
      label: 'Business details',
    },
    trade_references: {
      label: 'Trade references',
      requiredBy: 'requires_trade_reference_check',
    },
    guarantors: {
      label: 'Guarantors',
      requiredBy: 'requires_guarantees',
    },
    financials: {
      label: 'Financials',
      addon: true,
    },
    additional: {
      label: 'Additional',
      addon: true,
    },
    paperless: {
      label: 'Direct debit',
      addon: true,
    },
    payment_info: {
      label: 'Limit',
    },
  },
  titlePrefix: 'Review details for application between ',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect((state, ownProps) => {
  let limit;
  const options = defaults.options;
  const authorisation = state.authorisation.data;

  if (authorisation) {
    limit = authorisation.application.attributes.trade_account_limit;
    if (authorisation.application.attributes.legal_type === 'personal') {
      options.business.label = 'Customer details';
    }

    if (
      authorisation.application.attributes.legal_type === 'trust' &&
      FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS
    ) {
      options.business.label = `Business details of ${state.authorisation.consumerName}`;

      const corporateTrustees =
        authorisation.application.attributes.corporate_trustees || [];
      const associatedEntityId =
        authorisation.authorisation.attributes.associated_entity_id;

      let corporateTrust;
      const isCorporateTrustDirector = !!associatedEntityId;
      if (isCorporateTrustDirector) {
        corporateTrust =
          corporateTrustees.find(
            (corporateTrust) => corporateTrust.id === associatedEntityId
          ) || {};
        const corporateTrustName = corporateTrust.entity_name;
        options.corporate_trust.label = `Business details of ${corporateTrustName}`;
      } else {
        delete options.corporate_trust;
      }
    } else {
      delete options.corporate_trust;
    }

    const addonRules = authorisation.addon_rules || null;
    const addonModuleNames = [];
    addonRules.forEach((answer) => {
      addonModuleNames.push(answer.attributes.addon_module_name);
    });

    Object.keys(options).forEach((key) => {
      if (defaults.options[key].addon && !addonModuleNames.includes(key)) {
        delete options[key];
      }
    });
  }

  return {
    authorisation,
    consumerName: ownProps.consumerName,
    guarantor: state.authorisation.guarantor,
    guarantorApproved: state.authorisation.guarantorApproved,
    limit,
    options,
    title: `${defaults.titlePrefix} ${ownProps.consumerName} and ${ownProps.supplierLegalName}`,
  };
})(ReviewGuarantor);
