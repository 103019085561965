import {
  STATE_LOAD_STATE_START,
  STATE_LOAD_STATE_SUCCESS,
  STATE_LOAD_STATE_ERROR,
  STATE_SAVE_STATE_START,
  STATE_SAVE_STATE_ERROR,
  STATE_CREATE_STATE_START,
  STATE_CREATE_STATE_SUCCESS,
  STATE_CREATE_STATE_ERROR,
  STATE_UPDATE_STATE_START,
  STATE_UPDATE_STATE_SUCCESS,
  STATE_UPDATE_STATE_ERROR,
} from './constants';

var state_defaults = {
  loading: false,
  saving: false,
};

export function stateReducer(state = state_defaults, action) {
  switch (action.type) {
    case STATE_LOAD_STATE_START:
      return { ...state, loading: true };
    case STATE_LOAD_STATE_SUCCESS:
      return { ...state, loading: false };
    case STATE_LOAD_STATE_ERROR:
      return { ...state, loading: false };

    case STATE_SAVE_STATE_START:
      return { ...state, saving: true };
    case STATE_CREATE_STATE_START:
      return { ...state, saving: true };
    case STATE_UPDATE_STATE_START:
      return { ...state, saving: true };

    case STATE_SAVE_STATE_ERROR:
      return { ...state, saving: false };
    case STATE_CREATE_STATE_SUCCESS:
      return { ...state, saving: false };
    case STATE_CREATE_STATE_ERROR:
      return { ...state, saving: false };
    case STATE_UPDATE_STATE_SUCCESS:
      return { ...state, saving: false };
    case STATE_UPDATE_STATE_ERROR:
      return { ...state, saving: false };

    default:
      return { ...state };
  }
}
