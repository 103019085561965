/* eslint-disable max-classes-per-file */

//**
// Surnames always start with Capital letters.
//**
export function fullNameSplitter(fullName) {
  // replace multiple spaces with single space and eliminate the spaces around dash
  var fullName = fullName
    .replace(/ +/g, ' ')
    .replace(/ -/g, '-')
    .replace(/- /g, '-');
  let splitPoint = fullName.search(/ [A-Z]{2,}/);
  // if there is no capital letters at all, use the last space
  if (splitPoint == -1) {
    splitPoint = fullName.lastIndexOf(' ');
  }
  return {
    first_name: fullName.slice(0, splitPoint),
    last_name: fullName.slice(splitPoint + 1),
  };
}

/* eslint-enable max-classes-per-file */
