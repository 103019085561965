import React from 'react';
import {
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';
import styled from 'styled-components';

import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';

const CellWithHelp = styled.div`
  display: flex;
  gap: 8px;
`;

const HelpIcon = styled(Help)`
  color: #979797;
  position: relative;
  bottom: 1px;
`;

export const TableHead = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  if (!isDesktop) {
    return null;
  }

  const tierColumnLabel = isHeadquarter() ? 'Tier' : 'Owner';

  return (
    <MuiTableHead>
      <TableRow>
        <TableCell>Customer</TableCell>
        <TableCell>Approved limit</TableCell>
        <TableCell>
          <CellWithHelp>
            Approval/Activate date
            <PopperTooltip
              placement="top"
              title="This refers to the date when the
customer's account was opened."
            >
              <HelpIcon />
            </PopperTooltip>
          </CellWithHelp>
        </TableCell>
        <TableCell>{tierColumnLabel}</TableCell>
      </TableRow>
    </MuiTableHead>
  );
};
