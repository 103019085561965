import {
  WatchtowerButtonWrapper,
  WatchtowerModalTitle,
} from 'modules/addons/components/digital-onboarding/WatchtowerModule/styles';
import Modal from 'modules/dashboard/containers/components/Modal';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement } from 'react';

export type ModalActionType = 'block' | 'safe' | 'actioned' | 'remove_group';

type IWatchtowerConfirmModal = {
  onConfirm: () => void;
  onCancel: () => void;
  actionType: ModalActionType;
};

const modalActionType = (type: ModalActionType) => {
  const actionTypeContent = {
    actioned:
      'Confirm to mark the alert as actioned. This action cannot be reversed.',
    block:
      'Confirm to block the applicant’s email and IP address. This action cannot be reversed. Blocked accounts will be shared within the 1Centre community and you can access blocked  accounts in your Watchtower module.',
    remove_group: 'Confirm to remove this application from group.',
    safe:
      'Confirm to mark the applicant as safe. This action cannot be reversed.',
  };
  return actionTypeContent[type];
};

function ActionModal(props: IWatchtowerConfirmModal): ReactElement {
  const { onConfirm, onCancel, actionType } = props;

  return (
    <Modal
      onClose={onCancel}
      title={
        <WatchtowerModalTitle>
          <span>Confirm action</span>
        </WatchtowerModalTitle>
      }
      content={<p>{modalActionType(actionType)}</p>}
      footer={
        <WatchtowerButtonWrapper>
          <Button text="Confirm" handleClick={onConfirm} />
          <Button text="Cancel" white={true} handleClick={onCancel} />
        </WatchtowerButtonWrapper>
      }
    />
  );
}

export default ActionModal;
