import XLSX from 'xlsx';

export function exportXlsxCSV(data, filename) {
  let xlsxFile = convertArrayOfObjectsToXlsx(data);
  XLSX.writeFile(xlsxFile, filename);
}

function convertArrayOfObjectsToXlsx(args) {
  var ws = XLSX.utils.json_to_sheet(args);
  let sortedHeaders = sortHeaders(getHeaders(ws));
  var ws = XLSX.utils.json_to_sheet(args, { header: sortedHeaders });
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws);
  return wb;
}

function getHeaders(worksheet) {
  let headers = [];
  let range = XLSX.utils.decode_range(worksheet['!ref']);
  for (let colNum = 0; colNum <= range.e.c; colNum++) {
    let worksheet_address = XLSX.utils.encode_cell({ r: 0, c: colNum });
    headers.push(worksheet[worksheet_address].v);
  }
  return headers;
}

function sortHeaders(headers) {
  let sorted = headers.slice(0, 20);
  let keyWords = ['Guarantor', 'Trade', 'Director', 'Partner', 'Trustee'];
  keyWords.forEach((keyWord) => {
    sorted = sorted.concat(
      headers
        .slice(20)
        .filter((header) => header.slice(0, keyWord.length) == keyWord)
    );
  });
  return sorted;
}
