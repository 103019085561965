import AddonConfigModel from 'models/AddonConfigModel';
import isPresent from 'utils/isPresent';
import * as yup from 'yup-latest';

export const MAX_CHECKLIST_CHARACTERS = 150;
export const MAX_CUSTOMISED_MSG_CHARACTERS = 600;

export const formSchema = yup.object().shape({
  checklist: yup
    .array()
    .of(
      yup.object().shape({
        label: yup
          .string()
          .required('Please enter your checklist item')
          .trim()
          .max(
            MAX_CHECKLIST_CHARACTERS,
            `The limit is ${MAX_CHECKLIST_CHARACTERS} characters`
          ),
      })
    )
    .min(1, 'Please enter at least one item'),
  customisedMessage: yup
    .string()
    .max(
      MAX_CUSTOMISED_MSG_CHARACTERS,
      `The limit is ${MAX_CUSTOMISED_MSG_CHARACTERS} characters`
    ),
  links: yup.array().of(
    yup.object().shape({
      label: yup
        .string()
        .required('Please enter a link name')
        .trim(),
      value: yup
        .string()
        .url('Please enter a valid URL')
        .required('Please enter a URL'),
    })
  ),
});

export function getOrInitializeAddonModule(addonConfig) {
  if (isPresent(addonConfig)) {
    return addonConfig;
  }

  return new AddonConfigModel({
    attributes: { addon_module_name: 'customer_checklist' },
  });
}

export function getNewVersionAttribute(addonConfig, config) {
  const attributes = {
    addon_config_id: '',
    addon_module_name: 'customer_checklist',
    config,
    name: 'Customer checklist',
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  }

  return attributes;
}
