import { FEATURE_FLAGS } from 'conf';
import { addFlaggedArray } from 'conf/flaggingHelpers';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import React, { ReactElement } from 'react';

interface Props {
  disabled?: boolean;
  error?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  value?: number | string;
  dataCy?: string;
}

const isCat6Enabled = FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6;

const categoryOptions = [
  ...addFlaggedArray('FEATURE_FLAG_1CAF_CAT_0', [
    {
      description: `The system will prompt your customer to enter name, DOB, and residential
      address details, where you will have the minimum information to run
      personal credit checks if required. The IP address and email of the user
      will be captured in the eSignature section of the Virtual Credit File.`,
      label: 'Cat.0 No verification, eSignature (free)',
      value: '0',
    },
  ]),
  {
    description:
      'The system will prompt your customer to take a photo of\
      their drivers licence or passport, where you will have the ability to\
      manually verify. The IP address and email of the user will be captured\
      in the eSignature section of the Virtual Credit File.',
    label: FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0
      ? 'Cat.1 Manual verification, eSignature ($1.60 per signatory)'
      : 'Cat.1 Manual verification, eSignature (free)',
    value: '1',
  },
  {
    description:
      'The system will prompt your customer to enter the details of\
      their drivers licence or passport (without uploading the ID),\
      which will be validated through the appropriate local governing body.\
      The IP address and email of the user will be captured in the eSignature\
      section of the Virtual Credit File.',
    label:
      'Cat.2 Digital verification without image, eSignature ($2.45 per signatory)',
    value: '2',
  },
  {
    description:
      'The system will prompt your customer to take a photo of\
      their drivers licence or passport, which will be validated through the\
      appropriate local governing body. The IP address and email of the user\
      will be captured in the eSignature section of the Virtual Credit File.',
    label: 'Cat.3 Digital verification, eSignature ($2.45 per signatory)',
    value: '3',
  },
  {
    description:
      "In addition to Cat.3, the system will prompt your customer\
      to take a front-facing selfie, which will be compared to the verified ID,\
      which will then produce a 'Similarity Score' that will display in the\
      Virtual Credit File.",
    label:
      'Cat.4 Digital verification, eSignature, FaceMatch ($3.10 per signatory)',
    value: '4',
  },
  {
    description:
      'The system will prompt your customer to take a photo of their drivers licence\
    or passport, and proof of address. These will be checked along with PEP and sanctions.\
    The IP address and email of the user will be captured in the eSignature section\
    of the Virtual Credit File.',
    label:
      'Cat.5 AML verification, eSignature, Proof of address ($4.60 per signatory)',
    value: '5',
  },
  {
    description:
      "In addition to Cat.3, the system will prompt your customer to stream a\
       selfie video for Proof of life analysis. The information captured will be\
       compared to the verified ID, then produce a 'Confidence Score' that will\
       display in the Virtual Credit File.",
    disabled: !isCat6Enabled,
    label: `Cat.6 Digital verification, eSignature, Proof of life ($4.85 per signatory)${
      isCat6Enabled ? '' : ' (Coming soon)'
    } `,
    value: '6',
  },
];

function CategoryRadiobox({
  disabled,
  error,
  handleChange,
  helperText,
  value,
  dataCy,
}: Props): ReactElement {
  return (
    <Radiobox
      id="category"
      error={error}
      radioList={categoryOptions}
      disabled={disabled}
      handleChange={handleChange}
      display="row"
      helper_text={helperText}
      value={value}
      dataCy={dataCy}
    />
  );
}

export default CategoryRadiobox;
