import get from 'lodash.get';
import ConsumerInvite from 'modules/consumer-invite/v2/ConsumerInvite';
import { failedApplicationStart } from 'modules/dashboard/actions';
import Messages from 'modules/messages/Messages';
import {
  getPricingPlans,
  upgradeCurrentPlan,
} from 'modules/pricing-plans/actions';
import { defaults as pricing } from 'modules/pricing-plans/components/PricingPlans';
import { loadCard } from 'modules/profile/actions';
import CreditCardModal from 'modules/profile/components/CreditCardModal';
import Button from 'modules/shared/components/inputs/Button';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import SimpleDropdown from 'modules/shared/components/widgets/interactive/SimpleDropdown';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import VideoModal from 'modules/shared/components/widgets/interactive/VideoModal';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import { dashboardRedirect } from 'modules/shared/helpers/dashboardRedirect';
import {
  isBranchUser,
  isRegular,
} from 'modules/shared/helpers/headquarterDetect';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import { loadCurrentEntity } from 'modules/user/actions';
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import getAccountTypeName from 'utils/getAccountTypeName';
import { checkPopupExist, getPopup } from 'utils/tipHelper.js';
import { getTipText } from 'utils/tipHelper.js';

import {
  getCurrentPricingPlan,
  showPricingPlan,
} from '../../pricing-plans/actions';
import PricingPlanModal from '../../pricing-plans/components/PricingPlanModal';
import { getColorPalette } from '../../profile/actions';
import { deleteUserPopup } from '../../user/actions';
import { getEntityCard } from '../actions';
import AddNewCompany from '../components/AddNewCompany';
import BasicMenu from './components/BasicMenu';
import NavMenu from './components/NavMenu';
import SubMenuItem from './components/SubMenuItem';
import styles from './css/DashboardContainer.css';
import { FEATURE_FLAGS } from 'conf';
import DashboardHeader from './components/DashboardHeader/DashboardHeader';

const DashboardContainer = createClass({
  UNSAFE_componentWillMount() {
    const { current_user, dispatch, location } = this.props;

    const redirect = dashboardRedirect(location.pathname, current_user);

    dispatch(getPricingPlans());
    dispatch(loadCard());
    dispatch(getColorPalette());

    if (redirect) {
      browserHistory.push(redirect);
    }
    this.startAutoLogoff();
  },

  cancelCardInfo() {
    this.setState({ ...this.state, showCreditInfoModal: false });
  },

  componentDidMount() {
    const { current_entity, current_user } = this.props;

    const hasPendingSetup = get(
      current_entity,
      'attributes.supplier_onboarding_checklist'
    );

    const isSupplier = get(current_entity, 'attributes.supplier', false);

    userGuiding.identify(get(current_user, 'data.data.id'));
    userGuiding.track('segment', {
      has_completed_setup: !hasPendingSetup,
      supplier: isSupplier,
      user_types: get(
        current_user,
        'current_user_entity_link.attributes.role_types'
      ),
    });
  },

  componentDidUpdate(prevProps) {
    const previousLocation = get(prevProps, 'location.pathname', '');
    const currentLocation = get(this.props, 'location.pathname', '');

    if (previousLocation !== currentLocation) {
      this.setState({
        isSendApplicationModalVisible: false,
      });
    }
  },

  creditCardModal() {
    if (!this.state.showCreditInfoModal) {
      return;
    }

    return (
      <CreditCardModal
        onSave={this.saveCard}
        new_company_id={this.state.new_company_id}
        onCancel={this.cancelCardInfo}
      />
    );
  },

  entityHandleChange(e) {
    const { dispatch, current_pricing_plan, current_entity, current_user } =
      this.props;
    const that = this;
    if (e.target) {
      const id = e.target.id;
      const target_entity = current_user.data.included.find(
        (item) => item.attributes.entity_id === id
      );
      const is_supplier = target_entity
        ? target_entity.attributes.entity_supplier || false
        : false;
      if (id != current_entity.id) {
        if (!is_supplier) {
          dispatch(loadCurrentEntity(id, true));
          window.location.href = '/dashboard/home';
        } else if (
          !that.isSupplier() ||
          current_pricing_plan.attributes.entity_toggle
        ) {
          dispatch(
            getEntityCard(id, function (data) {
              if (
                data.length > 0 ||
                (that.isSupplier() &&
                  current_pricing_plan.attributes.plan_no == 0)
              ) {
                dispatch(loadCurrentEntity(id, true));
                window.location.href = '/dashboard/home';
              } else {
                that.setState({
                  new_company_id: id,
                  showCreditInfoModal: true,
                });
              }
            })
          );
        } else {
          dispatch(showPricingPlan(true, 'others'));
        }
      }
    }
  },

  getInitialState() {
    return {
      application_type: null,
      isAddNewCompanyTooltipVisible: false,
      isSendApplicationModalVisible: false,
      new_company_id: null,
      showCreditInfoModal: false,
      showToggleFeatureVideoModal: false,
      showUpgradeToSeriousBusinessPricingPlan: false,
      show_add_new_company: false,
      trading_name: '',
    };
  },

  handleDismissAddNewCompany() {
    this.setState({ show_add_new_company: false });
  },

  handleShowAddNewCompany() {
    const { current_pricing_plan } = this.props;

    if (current_pricing_plan && current_pricing_plan.attributes.entity_toggle) {
      this.setState({ ...this.state, show_add_new_company: true });
    } else {
      this.setState({
        ...this.state,
        showUpgradeToSeriousBusinessPricingPlan: true,
      });
    }
  },

  hideGenericPricingPlan() {
    const { dispatch, show_pricing_plan_redirect } = this.props;
    if (show_pricing_plan_redirect) {
      browserHistory.push(show_pricing_plan_redirect);
    }
    dispatch(showPricingPlan(false));
  },

  hideSeriousBusinessPricingPlanModal() {
    this.setState({
      ...this.state,
      showUpgradeToSeriousBusinessPricingPlan: false,
    });
  },

  isSupplier() {
    const { current_entity } = this.props;
    return !!current_entity.attributes.supplier;
  },

  onHideToggleFeatureVideoModal() {
    this.setState({ ...this.state, showToggleFeatureVideoModal: false });
  },

  onShowToggleFeatureVideoModal() {
    this.setState({ ...this.state, showToggleFeatureVideoModal: true });
  },

  onboarding_completed() {
    const { current_entity } = this.props;
    return !current_entity.attributes.supplier_onboarding_checklist;
  },

  render() {
    const {
      current_entity,
      current_user,
      location,
      username,
      show_pricing_plan,
      show_pricing_plan_type,
    } = this.props;
    const { show_add_new_company } = this.state;

    // Just wait till we're ready....
    if (!current_entity || !current_entity.attributes) {
      const error = (
        <div className={styles.error}>
          Uh oh! You seem to have logged in with an account that no longer has
          an associated company. Please contact support!
        </div>
      );

      return error;
    }

    let menu = (
      <NavMenu
        username={username}
        current_entity={current_entity}
        current_user={current_user}
      />
    );

    const currentPath = get(location, 'pathname', '');
    const isSendApplicationDisabled =
      currentPath.startsWith('/dashboard/invite');
    let start_application_btn = (
      <div
        className={`${styles.application_start}  ${styles.hidden_xs} ${
          FEATURE_FLAGS.FEATURE_FLAG_NEW_NAV_HEADER ? styles.hidden_sm : ''
        }`}
      >
        <Button
          css_style="button_icon_down_white_outer"
          text="Send application"
          disabled={isSendApplicationDisabled}
        />
        {!isSendApplicationDisabled && (
          <div className={styles.dropdown_list}>
            <SimpleDropdown type="start_application">
              <SubMenuItem
                title={getAccountTypeName('credit')}
                key="creditApplication"
                id="creditApplication"
                target={this.startApplicationClick.bind(null, 'credit')}
                hiddenxs={true}
              />
              <SubMenuItem
                title={getAccountTypeName('cash')}
                key="cashApplication"
                id="cashApplication"
                target={this.startApplicationClick.bind(null, 'cash')}
                hiddenxs={true}
              />
            </SimpleDropdown>
          </div>
        )}
      </div>
    );
    if (current_entity != null) {
      if (!current_entity.attributes.supplier || isBranchUser()) {
        start_application_btn = null;
      }
    }

    const companies = [];
    const user_entity_links = current_user.data.included;

    let brand;
    let logo;

    if (current_entity.attributes.logo) {
      logo = (
        <div className={styles.nav_logo_container}>
          <div className={styles.nav_logo}>
            <img src={current_entity.attributes.logo.url} alt="logo" />
          </div>
        </div>
      );
    }

    user_entity_links.forEach((v, index) => {
      companies.push(
        <SubMenuItem
          key={index}
          title={v.attributes.entity_trading_name || 'Your company'}
          id={v.attributes.entity_id}
          target={this.entityHandleChange}
          css_style={'company_link'}
        />
      );
    });

    if (
      current_user.current_user_entity_link.attributes.role_types.includes(
        'admin'
      ) &&
      !isMobile() &&
      this.isSupplier() &&
      isRegular()
    ) {
      companies.push(
        <div className={styles.add_new_company_link}>
          <PopperTooltip
            title={getTipText('AddNewCompany')}
            extraComponent={this.tooltipInteractiveContent()}
            leaveDelay={500}
            interactive
          >
            <div
              className={styles.company_link}
              onClick={this.handleShowAddNewCompany}
            >
              Add new account
            </div>
          </PopperTooltip>
        </div>
      );
    }

    brand = (
      <div className={styles.brand}>
        <div className={styles.company_dropdown}>
          {logo}
          <div>
            <h1
              className={
                companies.length > 1 || isBranchUser()
                  ? styles.company
                  : styles.single_company
              }
            >
              {current_entity.attributes.trading_name
                ? current_entity.attributes.trading_name
                : 'Your company'}
            </h1>
            {(companies.length > 1 || isBranchUser()) && (
              <div className={styles.dropdown_list}>
                <SimpleDropdown type="company_dropdownlist">
                  {companies}
                </SimpleDropdown>
              </div>
            )}
          </div>
        </div>
      </div>
    );

    if (current_user.data.data.attributes.trm) {
      menu = (
        <BasicMenu
          username={username}
          current_entity={current_entity}
          current_user={current_user}
        />
      );
      start_application_btn = null;
    }

    const oldHeader = (
      <header>
        <Helmet
          defaultTitle="1Centre"
          titleTemplate="1Centre - %s"
          title="Dashboard"
        />
        <div className={styles.navSpacer} />
        <div className={styles.nav_container}>
          <div className={styles.left_navigation}>
            {brand}
            {start_application_btn}
          </div>
          {menu}
        </div>
      </header>
    );

    return (
      <div className={styles.wrapper}>
        {FEATURE_FLAGS.FEATURE_FLAG_NEW_NAV_HEADER && this.isSupplier() ? (
          <DashboardHeader
            startApplicationClick={(type) => this.startApplicationClick(type)}
            entityHandleChange={this.entityHandleChange}
            {...this.props}
          >
            {oldHeader}
          </DashboardHeader>
        ) : (
          oldHeader
        )}
        <Messages theme="dashboard" />
        {this.props.children}
        {show_add_new_company && (
          <AddNewCompany handleDismiss={this.handleDismissAddNewCompany} />
        )}

        {/*
          This is for showing the generic pricing plan modal wherein user can
          choose which pricing plan to select.
          */}
        {show_pricing_plan && (
          <PricingPlanModal
            type={show_pricing_plan_type}
            onCancel={this.hideGenericPricingPlan}
          />
        )}

        {/*
          This is for showing the serious business plan upgrade instead of
          allowing the user to choose a specific pricing plan
          */}
        {this.upgradeToSeriousBusinessPlanModal()}
        {this.creditCardModal()}
        {this.toggleFeatureVideoModal()}
        {this.sendApplicationModal()}
      </div>
    );
  },

  saveCard(response) {
    if (response) {
      this.setState({ showCreditInfoModal: false });
      this.props.dispatch(loadCard());
    }
  },

  sendApplicationModal() {
    if (!this.state.isSendApplicationModalVisible) {
      return;
    }

    return (
      <ConsumerInvite
        applicationType={this.state.application_type}
        onCloseModal={() =>
          this.setState({ isSendApplicationModalVisible: false })
        }
      />
    );
  },

  startApplicationClick(type) {
    const { dispatch } = this.props;
    const that = this;

    if (this.onboarding_completed()) {
      dispatch(
        getCurrentPricingPlan(null, function (data) {
          if (
            data.attributes.plan_no == 0 ||
            data.attributes.application_tally < data.attributes.application_max
          ) {
            that.setState({
              application_type: type,
              isSendApplicationModalVisible: true,
            });
          } else {
            dispatch(showPricingPlan(true, 'application'));
          }
        })
      );
    } else {
      // toggle pulse animation
      dispatch(failedApplicationStart());
      browserHistory.push('/dashboard/home');
    }
  },

  startAutoLogoff() {
    let logoff_timer;

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.onclick = resetTimer;
    window.onscroll = resetTimer;
    window.onkeypress = resetTimer;

    function logout() {
      browserHistory.push('/user/logout');
      clearTimeout(logoff_timer);
      window.onload = null;
      window.onmousemove = null;
      window.onmousedown = null;
      window.onclick = null;
      window.onscroll = null;
      window.onkeypress = null;
    }

    function resetTimer(e) {
      clearTimeout(logoff_timer);
      logoff_timer = setTimeout(logout, 10800000); // In milliseconds
    }
    resetTimer();
  },

  toggleFeatureVideoModal() {
    if (!this.state.showToggleFeatureVideoModal) {
      return;
    }

    return (
      <div className={styles.toggleFeatureVideoModal}>
        <VideoModal
          isModalVisible={true}
          isVideoIconVisible={false}
          videoName="toggle_feature"
          onHideModal={this.onHideToggleFeatureVideoModal}
        />
      </div>
    );
  },

  tooltipInteractiveContent() {
    return (
      <Button
        small
        css_style="button_white_outer"
        text="Play video"
        handleClick={this.onShowToggleFeatureVideoModal}
      />
    );
  },

  upgradeToSeriousBusinessPlan() {
    const plan3 = pricing.pricing_plans.Plan_4;
    const { card, dispatch, pricingPlansList } = this.props;

    if (card === undefined) {
      this.setState({ ...this.state, showCreditInfoModal: true });
      return;
    }

    const plan = pricingPlansList.find(
      (plan) => plan.attributes.plan_no === plan3.no
    );

    dispatch(upgradeCurrentPlan(plan));
    this.setState({
      ...this.state,
      showUpgradeToSeriousBusinessPricingPlan: false,
      show_add_new_company: true,
    });
  },

  upgradeToSeriousBusinessPlanModal() {
    if (!this.state.showUpgradeToSeriousBusinessPricingPlan) {
      return;
    }

    const planName = pricing.pricing_plans.Plan_4.name;

    return (
      <CircleModal
        title={`Please confirm your upgrade to '${planName}' plan`}
        dismissHandler={this.hideSeriousBusinessPricingPlanModal}
      >
        <Button
          text="Yes"
          style={{ width: '100px' }}
          handleClick={this.upgradeToSeriousBusinessPlan}
        />
        <Button
          text="No"
          white
          style={{ marginLeft: '10px', width: '100px' }}
          handleClick={this.hideSeriousBusinessPricingPlanModal}
        />
      </CircleModal>
    );
  },
});

export default connect((state, _) => {
  const current_user = state.current_user;

  let username;
  let entity = {};

  let show_pricing_plan;
  let show_pricing_plan_type;
  let show_pricing_plan_redirect;

  if (current_user.current_entity) {
    entity = current_user.current_entity;
  }

  if (current_user.data.hasOwnProperty('data')) {
    username = current_user.data.data.attributes.first_name;
  }

  if (!username) {
    username = current_user.data.data.attributes.full_name;
  }

  if (current_user.data.data.attributes.trm) {
    entity = {
      attributes: {
        trading_name: 'Total risk management',
      },
    };
  }

  const user_routes = state.user_routes.visited;
  const current_path = user_routes[0];
  const last_path = user_routes[1];

  const current_pricing_plan = state.pricing_plans.current_pricing_plan;

  const currentUserProfile = get(
    state,
    'manage_profile.current_user_profile.attributes'
  );
  const profileNoData = get(currentUserProfile, 'noData', null);

  if (
    current_path === '/dashboard/map' &&
    current_pricing_plan &&
    !current_pricing_plan.attributes.mapping
  ) {
    show_pricing_plan = true;
    show_pricing_plan_type = 'others';
    show_pricing_plan_redirect = last_path;
  }

  return {
    card: state.manage_profile.current_entity_card,
    current_entity: entity,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    current_user,
    pricingPlansList: state.pricing_plans.pricingPlansList,
    show_pricing_plan:
      show_pricing_plan || state.pricing_plans.show_pricing_plan,
    show_pricing_plan_redirect:
      show_pricing_plan_redirect ||
      state.pricing_plans.show_pricing_plan_redirect ||
      current_path,
    show_pricing_plan_type:
      show_pricing_plan_type || state.pricing_plans.show_pricing_plan_type,
    username,
    displayUserProfile: !!(
      currentUserProfile && typeof profileNoData !== 'boolean'
    ),
  };
})(DashboardContainer);
