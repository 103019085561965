import axios from './axios';

const ModulePaperlessAuthorisationFlows = function(config) {
  config.timeout = 5 * 60 * 1000;
  this.axios = axios(config);
  this.type = 'module_paperless_authorisation_flows';
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
};

//
// Await-able functions
// The following functions allows the use of async/await
//
ModulePaperlessAuthorisationFlows.prototype.getDetails = function(id, options) {
  return this.axios.get(`/${this.type}/${id}`, options);
};

ModulePaperlessAuthorisationFlows.prototype.update = function(id, options) {
  return this.axios.patch(`/${this.type}/${id}`, options);
};

export default ModulePaperlessAuthorisationFlows;
