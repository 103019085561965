/* Import libraries */
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import isReviewerEnabled from 'modules/shared/helpers/isReviewerEnabled';
import React from 'react';
import { connect } from 'react-redux';

import EmailInput from '../../shared/components/inputs/EmailInput';
/* Import components */
import TextInput from '../../shared/components/inputs/TextInput';
import OptionsDropdown from '../../shared/components/widgets/interactive/OptionsDropdown';
import ToolTip from '../../shared/components/widgets/interactive/ToolTip';
/* Import CSS */
import styles from './css/UserForm.css';

const form_inputs = {
  user_first_name: {
    label: 'First name',
    required: true,
  },
  user_last_name: {
    label: 'Last name',
    required: true,
  },
  user_type: {
    default_title: '',
    label: 'Select user type',
    options: [
      {
        id: 'user_type',
        label: 'Administrator',
        value: 'admin',
      },
      {
        id: 'user_type',
        label: 'Manager',
        value: 'approver',
      },
      {
        id: 'user_type',
        label: 'Standard',
        value: 'standard',
      },
    ],
  },
};

function NewUserForm(props) {
  const {
    cssClass,
    fields_theme,
    handleChange,
    handleBlur,
    hideUserTypeTooltip,
    form_errors,
    form_inputs,
    form_loading,
    form_values,
    existing_member,
    exists_user,
    isUserTypeTooltipActive,
    onEmailBlurHandler,
    onEmailChangeHandler,
  } = props;

  function _emailBlurHandler(value, isValid) {
    onEmailBlurHandler(value, isValid);
  }

  function _emailChangeHandler(value, isValid) {
    onEmailChangeHandler(value, isValid);
  }

  const form_elements = [];
  let style = styles.default;

  if (cssClass) {
    style = styles[cssClass];
  }

  Object.keys(form_inputs).forEach((value, index) => {
    let loading = false;
    if (form_loading && form_loading.hasOwnProperty(value)) {
      loading = form_loading[value];
    }
    switch (value) {
      case 'user_first_name':
        if (!existing_member) {
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              error={form_errors[value]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              loading={loading}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
              theme={fields_theme}
            />
          );
        } else {
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              label={form_inputs[value].label}
              value={exists_user.first_name}
              theme={fields_theme}
              disabled={true}
              required={true}
            />
          );
        }
        break;
      case 'user_last_name':
        if (!existing_member) {
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              error={form_errors[value]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              loading={loading}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
              theme={fields_theme}
            />
          );
        } else {
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              label={form_inputs[value].label}
              value={exists_user.last_name}
              theme={fields_theme}
              disabled={true}
              required={true}
            />
          );
        }
        break;
      case 'user_type':
        form_elements.push(
          <OptionsDropdown
            key={index}
            id={value}
            error={form_errors[value]}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={form_values[value]}
            label={form_inputs[value].label}
            required={true}
            options={form_inputs[value].options}
          />
        );
        if (isUserTypeTooltipActive) {
          form_elements.push(
            <ToolTip
              tip_name={form_values[value]}
              css_style="add_new_team_member_user_type"
              dismissHandler={hideUserTypeTooltip}
            />
          );
        }
        break;
      case 'user_level':
        if (
          form_values.user_type !== 'standard' &&
          isFeatureEditEnabled('1CAH')
        ) {
          form_elements.push(
            <OptionsDropdown
              key={index}
              id={value}
              error={form_errors[value]}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={form_values[value]}
              label={form_inputs[value].label}
              options={form_inputs[value].options}
            />
          );
        }
        break;
      case 'user_email':
        if (existing_member) {
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              label={form_inputs[value].label}
              value={exists_user.email}
              theme={fields_theme}
              disabled={true}
              required={true}
              error={form_errors[value]}
            />
          );
        } else {
          form_elements.push(
            <EmailInput
              key={index}
              id={value}
              type="email"
              customError={form_errors[value]}
              handleChange={_emailChangeHandler}
              handleBlur={_emailBlurHandler}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
              theme={fields_theme}
            />
          );
        }
        break;
      case 'user_contact_phone_number':
        if (!existing_member) {
          form_elements.push(
            <TextInput
              key={index}
              id={value}
              error={form_errors[value]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              loading={loading}
              label={form_inputs[value].label}
              required={form_inputs[value].required}
              value={form_values[value]}
              theme={fields_theme}
            />
          );
        } else {
          if (
            exists_user.contact_country_code &&
            exists_user.user_contact_phone_number
          ) {
            form_elements.push(
              <TextInput
                key={index}
                id={value}
                label={form_inputs[value].label}
                value={
                  exists_user.contact_country_code +
                  exists_user.user_contact_phone_number
                }
                theme={fields_theme}
                disabled={true}
                required={true}
              />
            );
          }
        }
        break;
    }
  });

  return <div className={style}>{form_elements}</div>;
}

export default connect((state) => {
  const approval_levels_options = [
    {
      id: 'na',
      label: 'N/A',
      title: 'Cannot approve an appliation.',
      value: null,
    },
  ];

  if (isReviewerEnabled()) {
    approval_levels_options.push({
      id: 'level_0',
      label: 'Reviewer',
      title: 'Can review an application.',
      value: 0,
    });
  }

  (state.manage_profile.current_entity_approval_levels || []).forEach(
    (approval_level, level) => {
      approval_levels_options.push({
        id: `level_${approval_level.level}`,
        label: `Level ${level + 1}`,
        title: `Can approve credit limits ≤ $${approval_level.credit_limit}.`,
        value: approval_level.level,
      });
    }
  );

  const approval_levels = {
    default_title: '',
    label: 'Select user level',
    options: approval_levels_options,
  };

  form_inputs.user_level = approval_levels;

  form_inputs.user_email = {
    label: 'Email',
    required: true,
  };
  form_inputs.user_contact_phone_number = {
    label: 'Phone',
    required: true,
  };

  return {
    form_inputs,
  };
})(NewUserForm);
