import React, { Fragment, ReactElement } from 'react';

const AustraliaBankAccountDetails = ({
  bankAccountName,
  bankAccountNumber,
  bankNumber,
}: {
  bankAccountName: string;
  bankAccountNumber: string;
  bankNumber: string;
}): ReactElement => (
  <Fragment>
    <div className="has-text-weight-medium">
      Bank account name: {bankAccountName}
    </div>
    <div className="has-text-weight-medium">BSB: {bankNumber}</div>
    <div className="has-text-weight-medium">
      Account number: {bankAccountNumber}
    </div>
  </Fragment>
);

export default AustraliaBankAccountDetails;
