export const USER_AUTHENTICATION_ERROR = 'USER_AUTHENTICATION_ERROR';

export const USER_LOGIN_FORM_SET_EMAIL = 'USER_LOGIN_FORM_SET_EMAIL';
export const USER_LOGIN_FORM_SET_PASSWORD = 'USER_LOGIN_FORM_SET_PASSWORD';

export const USER_REGISTER_FORM_SET_FIRST_NAME =
  'USER_REGISTER_FORM_SET_FIRST_NAME';
export const USER_REGISTER_FORM_SET_LAST_NAME =
  'USER_REGISTER_FORM_SET_LAST_NAME';
export const USER_REGISTER_FORM_SET_PHONE_NUMBER =
  'USER_REGISTER_FORM_SET_PHONE_NUMBER';
export const USER_REGISTER_FORM_SET_EMAIL = 'USER_REGISTER_FORM_SET_EMAIL';
export const USER_REGISTER_FORM_SET_PASSWORD =
  'USER_REGISTER_FORM_SET_PASSWORD';
export const USER_REGISTER_FORM_SET_PASSWORD_CONFIRM =
  'USER_REGISTER_FORM_SET_PASSWORD_CONFIRM';
export const USER_REGISTER_FORM_SET_REGION = 'USER_REGISTER_FORM_SET_REGION';
export const USER_REGISTER_FORM_SET_STATE = 'USER_REGISTER_FORM_SET_STATE';
export const USER_REGISTER_FORM_SET_COUNTRY_CODE =
  'USER_REGISTER_FORM_SET_COUNTRY_CODE';
export const USER_REGISTER_FORM_SET_VOUCHER_CODE =
  'USER_REGISTER_FORM_SET_VOUCHER_CODE';
export const USER_REGISTER_FORM_SET_VOUCHER_CODE_VALID =
  'USER_REGISTER_FORM_SET_VOUCHER_CODE_VALID';

export const USER_REGISTER_FORM_SET_STEP = 'USER_REGISTER_FORM_SET_STEP';
export const USER_REGISTER_FORM_SET_STRENGTH =
  'USER_REGISTER_FORM_SET_STRENGTH';

export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';
export const USER_REGISTER_CREATE_REGISTRATION_START =
  'USER_REGISTER_CREATE_REGISTRATION_START';
export const USER_REGISTER_CREATE_REGISTRATION_SUCCESS =
  'USER_REGISTER_CREATE_REGISTRATION_SUCCESS';
export const USER_REGISTER_CREATE_REGISTRATION_ERROR =
  'USER_REGISTER_CREATE_REGISTRATION_ERROR';

export const USER_GET_TOKEN_BEGIN = 'USER_GET_TOKEN_BEGIN';
export const USER_GET_TOKEN_SUCCESS = 'USER_GET_TOKEN_SUCCESS';
export const USER_GET_TOKEN_ERROR = 'USER_GET_TOKEN_ERROR';

export const USER_PASSWORD_RESET_START = 'USER_PASSWORD_RESET_BEGIN';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_ERROR = 'USER_PASSWORD_RESET_ERROR';
export const USER_PASSWORD_RESET_TOKEN_NOT_FOUND_ERROR =
  'USER_PASSWORD_RESET_TOKEN_NOT_FOUND_ERROR';

export const USER_TOKEN_AUTH_SET_QUERY_PARAMS =
  'USER_TOKEN_AUTH_SET_QUERY_PARAMS';

export const USER_LOGIN_BEGIN = 'USER_LOGIN_BEGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGIN_CREDENTIALS_ERROR = 'USER_LOGIN_CREDENTIALS_ERROR';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const EMAIL_LOCKED_ERROR = 'EMAIL_LOCKED_ERROR';
export const USER_NETWORK_BREAK = 'USER_NETWORK_BREAK';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';

export const USER_CHECK_EMAIL_START = 'USER_CHECK_EMAIL_START';
export const USER_CHECK_EMAIL_SUCCESS = 'USER_CHECK_EMAIL_SUCCESS';
export const USER_CHECK_EMAIL_ERROR = 'USER_CHECK_EMAIL_ERROR';

export const USER_CHECK_VOUCHER_CODE_ERROR = 'USER_CHECK_VOUCHER_CODE_ERROR';
export const USER_CHECK_VOUCHER_CODE_START = 'USER_CHECK_VOUCHER_CODE_START';
export const USER_CHECK_VOUCHER_CODE_SUCCESS =
  'USER_CHECK_VOUCHER_CODE_SUCCESS';

export const USER_LOAD_CURRENT_USER_BEGIN = 'USER_LOAD_CURRENT_USER_BEGIN';
export const USER_LOAD_CURRENT_USER_SUCCESS = 'USER_LOAD_CURRENT_USER_SUCCESS';
export const USER_LOAD_CURRENT_USER_ERROR = 'USER_LOAD_CURRENT_USER_ERROR';
export const USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR =
  'USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR';

export const USER_FORGOT_PASSWORD_FORM_SET_EMAIL =
  'USER_FORGOT_PASSWORD_FORM_SET_EMAIL';

export const USER_SEND_PASSWORD_RESET_BEGIN = 'USER_SEND_PASSWORD_RESET_BEGIN';
export const USER_SEND_PASSWORD_RESET_SUCCESS =
  'USER_SEND_PASSWORD_RESET_SUCCESS';
export const USER_SEND_PASSWORD_RESET_ERROR = 'USER_SEND_PASSWORD_RESET_ERROR';

export const USER_LOAD_CURRENT_ENTITY_START = 'USER_LOAD_CURRENT_ENTITY_START';
export const USER_LOAD_CURRENT_ENTITY_SUCCESS =
  'USER_LOAD_CURRENT_ENTITY_SUCCESS';
export const USER_LOAD_CURRENT_ENTITY_ERROR = 'USER_LOAD_CURRENT_ENTITY_ERROR';

export const USER_ADD_ENTITY_START = 'USER_ADD_ENTITY_START';
export const USER_ADD_ENTITY_SUCCESS = 'USER_ADD_ENTITY_SUCCESS';
export const USER_ADD_ENTITY_ERROR = 'USER_ADD_ENTITY_ERROR';

export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';

export const USER_LOAD_CURRENT_USER_POPUPS_START =
  'USER_LOAD_CURRENT_USER_POPUPS_START';
export const USER_LOAD_CURRENT_USER_POPUPS_SUCCESS =
  'USER_LOAD_CURRENT_USER_POPUPS_SUCCESS';
export const USER_LOAD_CURRENT_USER_POPUPS_ERROR =
  'USER_LOAD_CURRENT_USER_POPUPS_ERROR';

export const USER_DELETE_CURRENT_USER_POPUP_START =
  'USER_DELETE_CURRENT_USER_POPUP_START';
export const USER_DELETE_CURRENT_USER_POPUP_SUCCESS =
  'USER_DELETE_CURRENT_USER_POPUP_SUCCESS';
export const USER_DELETE_CURRENT_USER_POPUP_ERROR =
  'USER_DELETE_CURRENT_USER_POPUP_ERROR';

export const USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_START =
  'USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_START';
export const USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_SUCCESS =
  'USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_SUCCESS';
export const USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_ERROR =
  'USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_ERROR';

export const USER_LOAD_USER_HEADQUARTER_LINK_START =
  'USER_LOAD_USER_HEADQUARTER_LINK_START';
export const USER_LOAD_USER_HEADQUARTER_LINK_SUCCESS =
  'USER_LOAD_USER_HEADQUARTER_LINK_SUCCESS';
export const USER_LOAD_USER_HEADQUARTER_LINK_ERROR =
  'USER_LOAD_USER_HEADQUARTER_LINK_ERROR';

export const USER_CURRENT_ENTITY_LEAD_NAME_SET =
  'USER_CURRENT_ENTITY_LEAD_NAME_SET';

export const USER_SET_CURRENT_ENTITY_STORE_NAME_ALIAS =
  'USER_SET_CURRENT_ENTITY_STORE_NAME_ALIAS';

export const USER_UPDATE_FIRST_LAST_NAME_FROM_SIGNATURE_SUCCESS =
  'USER_UPDATE_FIRST_LAST_NAME_FROM_SIGNATURE_SUCCESS';

export const USER_UPDATE_PREFERENCE_ERROR = 'USER_UPDATE_PREFERENCE_ERROR';
export const USER_UPDATE_PREFERENCE_START = 'USER_UPDATE_PREFERENCE_START';
export const USER_UPDATE_PREFERENCE_SUCCESS = 'USER_UPDATE_PREFERENCE_SUCCESS';
export const USER_UPDATE_LOADING_END = 'USER_UPDATE_LOADING_END';

export const USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_START =
  'USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_START';
export const USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_ERROR =
  'USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_ERROR';
export const USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_SUCCESS =
  'USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_SUCCESS';
