import React from 'react';
import { FilterButton } from './FilterButton';
import { Typography } from '@material-ui/core';

type AdvancedSearchButtonProps = { onClick: () => void };

export const AdvancedSearchButton = ({
  onClick,
}: AdvancedSearchButtonProps) => (
  <FilterButton onClick={onClick}>
    <Typography>
      Advanced <span>search</span>
    </Typography>
  </FilterButton>
);
