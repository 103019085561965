export const CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS =
  'CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS';
export const CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR =
  'CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR';
export const CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START =
  'CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START';
export const CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS =
  'CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS';
export const CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START =
  'CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START';
export const CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS =
  'CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS';
export const CNS_INVITE_RESEND_APPLICATION_START =
  'CNS_INVITE_RESEND_APPLICATION_START';
export const CNS_INVITE_RESEND_APPLICATION_SUCCESS =
  'CNS_INVITE_RESEND_APPLICATION_SUCCESS';
export const CNS_INVITE_RESEND_APPLICATION_ERROR =
  'CNS_INVITE_RESEND_APPLICATION_ERROR';
export const CNS_INVITE_RESEND_AUTHORISATION_START =
  'CNS_INVITE_RESEND_AUTHORISATION_START';
export const CNS_INVITE_RESEND_AUTHORISATION_SUCCESS =
  'CNS_INVITE_RESEND_AUTHORISATION_SUCCESS';
export const CNS_INVITE_RESEND_AUTHORISATION_ERROR =
  'CNS_INVITE_RESEND_AUTHORISATION_ERROR';
export const CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME =
  'CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME';
export const CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME =
  'CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME';
export const CNS_INVITE_SET_NEW_CONSUMER_EMAIL =
  'CNS_INVITE_SET_NEW_CONSUMER_EMAIL';
export const CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME =
  'CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME';
export const CNS_INVITE_SET_IUF_ANSWERS = 'CNS_INVITE_SET_IUF_ANSWERS';
export const CNS_INVITE_SET_TYPE = 'CNS_INVITE_SET_TYPE';
