import Alert from 'modules/cardholder/components/Alert';
import CardholderIdentityCapture from 'modules/cardholder/components/CardholderIdentityCapture';
import CardholderIdentityConfirm from 'modules/cardholder/components/CardholderIdentityConfirm';
import Complete from 'modules/cardholder/components/Complete';
import Terms from 'modules/cardholder/components/Terms';
import Layout from 'modules/cardholder/containers/Layout';

const CardholderFlow = [
  {
    component: Alert,
    path: 'alert',
  },
  {
    component: CardholderIdentityCapture,
    path: 'identity/capture',
  },
  {
    component: CardholderIdentityConfirm,
    path: 'identity/confirm',
  },
  {
    component: Complete,
    path: 'complete',
  },
  {
    component: Terms,
    path: 'terms',
  },
];

export default {
  childRoutes: CardholderFlow,
  component: Layout,
  path: 'cardholder',
};
