import { IRuleSetAttributes } from 'modules/addons/auto_decisioning/v2/RulesetContent/type';

/**
 * Get matching auto-decision rulesets based on anti-fraud settings.
 *
 * @param {Object} antiFraudSettings - Anti-fraud settings object.
 * @param {Object[]} autoDecisionRulesets - List of auto-decision rulesets.
 * @param {IRuleSetAttributes} autoDecisionRulesets[].attributes - Attributes of a ruleset.
 * @returns {Object[]} - Matching auto-decision rulesets.
 */
export const getMatchingAutoDecisionRulesets = (
  antiFraudSettings,
  autoDecisionRulesets: { attributes: IRuleSetAttributes }[]
) => {
  if (!antiFraudSettings) {
    // There is no anti fraud config that exists.
    // So no auto decision ruleset will be affected.
    return [];
  }

  const antiFraudAccountTypes = antiFraudSettings?.config?.account_types || [];
  const {
    legal_types: antiFraudEntityTypes,
    min_credit_value: antiFraudMinCreditValue,
    max_credit_value: antiFraudMaxCreditValue,
  } = antiFraudSettings;

  const matchingAutoDecisionRulesets = autoDecisionRulesets.filter(
    (ruleset) => {
      const {
        attributes: {
          require_anti_fraud: isAntiFraudRequired,
          application_types: [rulesetApplicationType],
          legal_types: rulesetEntityTypes,
          min_credit_value: rulesetMinLimit,
          max_credit_value: rulesetMaxLimit,
        },
      } = ruleset;

      // Skip rulesets that don't require anti-fraud
      if (!isAntiFraudRequired) {
        return false;
      }

      // Check for application type overlap
      const hasApplicationTypeOverlap =
        !rulesetApplicationType ||
        antiFraudAccountTypes.includes(rulesetApplicationType);

      // Check for entity type overlap
      const hasEntityTypeOverlap = rulesetEntityTypes.every(
        (rulesetEntityType) => antiFraudEntityTypes.includes(rulesetEntityType)
      );

      // Check for credit limit overlap
      let hasCreditLimitOverlap = true;
      if (
        antiFraudMinCreditValue !== null &&
        antiFraudMaxCreditValue !== null
      ) {
        hasCreditLimitOverlap =
          rulesetMinLimit >= antiFraudMinCreditValue &&
          rulesetMaxLimit <= antiFraudMaxCreditValue;
      }

      // Return true if all criteria overlap, indicating a matching rule
      return (
        hasApplicationTypeOverlap &&
        hasEntityTypeOverlap &&
        hasCreditLimitOverlap
      );
    }
  );

  return matchingAutoDecisionRulesets;
};
