/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

/* Import actions */
import { setValue } from '../actions';

/* Import CSS */
import styles from './css/AutoDecisioning.css';
/* Import components */
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import ExpandableSection from './ExpandableSection';

var Guarantors = createClass({
  handleChange: function(e) {
    const { dispatch } = this.props;
    dispatch(setValue('guarantors_count', e.value));
  },

  render: function() {
    const {
      loading,
      rule_set,
      current_entity,
      options,
      selectedOption,
      error,
      count_error,
      read_only,
    } = this.props;
    let minimum_guarantees = read_only
      ? selectedOption
      : current_entity.minimum_guarantees;
    let invalid_value =
      selectedOption > minimum_guarantees || selectedOption < 1;
    return (
      <div className={styles.section}>
        <ExpandableSection
          id={'guarantors'}
          title={'Guarantees'}
          info_text={
            'For incorporated entities (companies) - do you require signatures from all guarantor/s? \
          <br/>*Please note: The nominated guarantors have to agree \
          to guarantee the account first. Delays will occur.'
          }
          disabled={read_only}
          account_rule_on={current_entity.requires_guarantees}
          hide_setting_message={read_only}
          error={error}
        >
          {invalid_value && current_entity.requires_guarantees && (
            <div className={styles.disabled_info}>
              Please resolve this setting to be inline with your{' '}
              <Link to="/dashboard/profile?active_tab=account_rules">
                account settings
              </Link>
            </div>
          )}
          {count_error && invalid_value && (
            <div className={styles.error}>{count_error}</div>
          )}
          <OptionsDropdown
            id={'guarantors_count'}
            name={'guarantors_count'}
            label={'Number of Guarantors'}
            value={selectedOption}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            required={true}
            options={options.slice(0, minimum_guarantees)}
            disabled={read_only}
          />
        </ExpandableSection>
      </div>
    );
  },
});

var defaults = {
  options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
    return { label: i, value: i };
  }),
  title: 'Number of guarantors',
};

module.exports = connect((state, ownProps) => {
  return {
    ...defaults,
    rule_set: ownProps.rule_set,
    options: defaults.options,
    selectedOption:
      state.auto_decisions.current_rule_set.attributes.guarantors_count || '',
    current_entity: state.current_user.current_entity
      ? state.current_user.current_entity.attributes
      : null,
  };
})(Guarantors);
