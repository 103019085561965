import { createRegistration, setRegisterFormField } from '../actions';

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import RegisterForm from '../forms/RegisterForm';
import Terms from './Terms';
import { checkPassStrength } from '../../../utils/password';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import styles from './css/Registration.css';
import get from 'lodash.get';

class Registration extends React.Component {
  constructor() {
    super();
    this.state = { step: 1 };
  }

  handleChange = (event) => {
    const { dispatch } = this.props;
    dispatch(setRegisterFormField(event.target.name, event.target.value));
  };

  toTerms = (event) => {
    event.preventDefault();
    this.setState({ step: 2 });
  };

  resetForm = () => {
    const { dispatch } = this.props;
    dispatch(setRegisterFormField('password', ''));
    this.setState({ step: 1 });
  };

  submit = () => {
    const {
      dispatch,
      first_name,
      last_name,
      email_address,
      password,
      region,
      state,
      voucher_code,
    } = this.props;
    const { step } = this.state;

    if (step !== 2) {
      return false;
    }

    dispatch(
      createRegistration({
        first_name,
        last_name,
        email: email_address,
        password,
        region,
        state,
        voucher_code,
      })
    );
  };

  renderRegisterForm() {
    const { strength } = this.props;

    return (
      <section className={styles.section}>
        <div className={styles.content}>
          <div className={styles.row}>
            <div className={styles.prevbtn}>
              <div className={styles.block}>
                <h1 className={styles.header}>
                  Welcome to 1Centre.
                  <br />
                  Let's get you on your way.
                </h1>
                <ReactCSSTransitionGroup
                  transitionName={{
                    appear: styles.appear,
                    appearActive: styles.appearActive,
                  }}
                  transitionEnterTimeout={200}
                  transitionLeaveTimeout={200}
                  transitionAppear={true}
                  transitionAppearTimeout={200}
                >
                  <RegisterForm
                    submitHandler={this.toTerms}
                    changeHandler={this.handleChange}
                    blurHandler={this.handleChange}
                    strength={strength}
                  />
                </ReactCSSTransitionGroup>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderTerms() {
    const { submitting, region } = this.props;

    return (
      <section className={styles.section}>
        <div className={styles.terms_content}>
          <div className={styles.row}>
            <div className={styles.prevbtn}>
              <div className={styles.block}>
                <Terms
                  handleAgree={this.submit}
                  handleBail={this.resetForm}
                  loading={submitting}
                  region={region}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderDecoration() {
    return (
      <div className={styles.decoration}>
        <div className={styles.float_center}>
          <img className={styles.photo} src={require('images/building.svg')} />
          <div className={styles.header}>Create your business profile</div>
          <div className={styles.statment}>
            Simply create your profile by adding your business
            <br />
            details, team members, and application requirements
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className={styles.moblie_section}>
        <div className={styles.mobile_wrap}>
          <div className={styles.mobile_header}>
            <h1>Please sign up from a desktop.</h1>
          </div>
          <div className={styles.mobile_body}>
            <h1>See you soon!</h1>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { step } = this.state;
    const { loggedIn } = this.props;

    if (loggedIn) {
      browserHistory.push('/dashboard');
    }

    let content = null;
    if (isMobile()) {
      content = this.renderMobile();
    } else if (step === 1) {
      content = [this.renderDecoration(), this.renderRegisterForm()];
    } else {
      content = [this.renderDecoration(), this.renderTerms()];
    }

    return <div className={styles.container}>{content}</div>;
  }
}

export default connect((state) => {
  let strength = 0;
  const currentUser = get(state, 'current_user', {});
  if (state.register.password.length > 0) {
    strength = checkPassStrength(state.register.password);
  }

  return {
    email_address: state.register.email_address,
    first_name: state.register.first_name,
    last_name: state.register.last_name,
    password: state.register.password,
    region: state.register.region,
    state: state.register.state,
    strength,
    submitting: state.register.submitting || false,
    voucher_code: state.register.voucher_code,
    loggedIn: currentUser.loggedIn,
  };
})(Registration);
