/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/* Import components */

import CloseButton from 'modules/shared/components/inputs/CloseButton';

import styles from './css/Modal.css';

var Modal = createClass({
  render: function() {
    const { title, bodyText, dismissHandler, css_close_button } = this.props;
    return (
      <div className={styles.modal}>
        <CloseButton
          text="Close"
          css_class={css_close_button}
          handleClick={dismissHandler}
        />
        <div className={styles.fade}>
          <div className={styles.content}>
            <div className={styles.container}>
              <div className={styles.col}>
                <div className={styles.body}>
                  <h2 className={styles.title}>{title}</h2>
                  {bodyText && <p className={styles.bodyText}>{bodyText}</p>}
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

module.exports = connect((state, ownProps) => {
  //const { onCancel} = this.props;
  return {};
})(Modal);
