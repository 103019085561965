import api from 'api';
import mixpanel from 'mixpanel-browser';
import AddonRuleModel from 'models/AddonRuleModel';
import AntiFraudAddonVersionModel from 'models/AntiFraudAddonVersionModel';
import { setIdentity } from 'modules/identity/actions.js';
import { setToken } from 'modules/shared/auth/actions';
import { setTheme } from 'modules/shared/helpers/colorPalettes';
import {
  signatureDataFormatter,
  signatureLoaderFormatter,
} from 'modules/shared/helpers/signatureDataFormatter.js';
import buildNoIdentificationSignatureAttributes from 'utils/anti-fraud/buildNoIdentificationSignatureAttributes';
import getApplicableAntiFraudRulesForAuthorisationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForAuthorisationFlow';
import { loadingLiveChat } from 'utils/liveChat.js';

import {
  AUTHORISATION_LOAD_DATA_ERROR,
  AUTHORISATION_LOAD_DATA_START,
  AUTHORISATION_LOAD_DATA_SUCCESS,
  AUTHORISATION_LOGIN_ERROR,
  AUTHORISATION_LOGIN_START,
  AUTHORISATION_LOGIN_SUCCESS,
  AUTHORISATION_SET_VALUE,
  AUTHORISATION_UPDATE_DATA_ERROR,
  AUTHORISATION_UPDATE_DATA_START,
  AUTHORISATION_UPDATE_DATA_SUCCESS,
  AUTHORISATION_UPDATE_WATCHTOWER_ERROR,
  AUTHORISATION_UPDATE_WATCHTOWER_ERROR_RESET,
} from './constants';
import { isWatchtowerBlock } from 'utils';
import { FEATURE_FLAGS } from 'conf';

export function setValue(key, value) {
  return (dispatch) => {
    dispatch({
      payload: { key, value },
      type: AUTHORISATION_SET_VALUE,
    });
  };
}

export function updateAuthorisation(id, callback, queryParams) {
  return async (dispatch, getState) => {
    dispatch({ type: AUTHORISATION_UPDATE_DATA_START });
    dispatch({ type: AUTHORISATION_UPDATE_WATCHTOWER_ERROR_RESET });

    const accessToken = getState().current_user.access_token;
    const authorisation = getState().authorisation;

    try {
      const authorisationAPI = api('authorisations', accessToken);
      const attributes = {
        cardholder_approved: authorisation.cardholderApproved,
        guarantor_approved: authorisation.guarantorApproved,
        payment_approved: authorisation.paymentApproved,
        signatory_approved: authorisation.signatoryApproved,
      };

      const antiFraudCategoryRules =
        getApplicableAntiFraudRulesForAuthorisationFlow(authorisation);

      // This is needed to be able to create a Signature record if the ID
      // section is skipped
      if (!antiFraudCategoryRules.requiresIdentificationSection) {
        const signatureAttributes = buildNoIdentificationSignatureAttributes({
          firstName: authorisation.firstName,
          lastName: authorisation.lastName,
        });

        attributes['signature'] = signatureAttributes;
      }

      await authorisationAPI.updateAuthorisation(id, attributes, queryParams);

      dispatch({
        meta: {
          mixpanel: {
            event: 'Authorisation',
            props: {
              distinct_id: id,
              email: getState().authorisation.email,
            },
          },
        },
        type: AUTHORISATION_UPDATE_DATA_SUCCESS,
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      if (isWatchtowerBlock(error)) {
        dispatch({
          payload: true,
          type: AUTHORISATION_UPDATE_WATCHTOWER_ERROR,
        });
      } else {
        dispatch({ type: AUTHORISATION_UPDATE_DATA_ERROR });
      }
    }
  };
}

export function loadAuthorisation(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTHORISATION_LOAD_DATA_START,
    });

    const accessToken = getState().current_user.access_token;
    const authorisationAPI = api('authorisations', accessToken);

    try {
      const response = await authorisationAPI.getAuthorisation(id);
      const authorisation = response.data.data;

      const consumerName = authorisation.application.attributes.consumer_name;
      const supplierName = authorisation.application.attributes.supplier_name;
      const supplierLegalName =
        authorisation.application.attributes.supplier_legal_name;

      const addonRules = authorisation.addon_rules.map(
        (addonRule) => new AddonRuleModel(addonRule)
      );
      const antiFraudData = addonRules.find((addonRule) =>
        addonRule.addonModuleName.includes('anti_fraud_')
      );

      const authoriser = authorisation.authorisation.attributes;

      const hasGuarantor = !!authoriser.guarantor_id;
      const hasCardholder = !!authoriser.cardholder_id;
      const hasPaperless = !!authoriser.payment_id;
      const hasSignatory = !!authoriser.signatory_id;
      const firstName = authoriser.first_name;
      const lastName = authoriser.last_name;
      const middleName = authoriser.middle_name;
      const fullName = authoriser.full_name;
      const email = authoriser.email;

      dispatch(setValue('consumerName', consumerName));
      dispatch(setValue('supplierName', supplierName));
      dispatch(setValue('supplierLegalName', supplierLegalName));
      dispatch(setValue('hasGuarantor', hasGuarantor));
      dispatch(setValue('hasCardholder', hasCardholder));
      dispatch(setValue('hasPaperless', hasPaperless));
      dispatch(setValue('hasSignatory', hasSignatory));
      dispatch(setValue('firstName', firstName));
      dispatch(setValue('lastName', lastName));
      dispatch(setValue('middleName', middleName));
      dispatch(setValue('fullName', fullName));
      dispatch(setValue('email', email));

      if (antiFraudData) {
        const antiFraud = new AntiFraudAddonVersionModel(
          antiFraudData.addonVersion.data
        );
        dispatch(setValue('antiFraud', antiFraud));
      }

      if (hasGuarantor) {
        const guarantorID = authoriser.guarantor_id;
        const guarantor = authorisation.guarantors.find(
          (guarantor) => guarantor.id === guarantorID
        );
        dispatch(setValue('guarantor', guarantor));
        dispatch(setValue('guarantorApproved', guarantor.attributes.approved));
      }
      if (hasCardholder) {
        const cardholderID = authoriser.cardholder_id;
        const cardholder = authorisation.cardholders.find(
          (cardholder) => cardholder.id === cardholderID
        );
        dispatch(setValue('cardholder', cardholder));
        dispatch(
          setValue('cardholderApproved', cardholder.attributes.approved)
        );
      }
      if (hasPaperless) {
        const paymentID = authoriser.payment_id;
        const payment = authorisation.payments.find(
          (payment) => payment.id === paymentID
        );
        dispatch(setValue('paperless', payment));
        dispatch(setValue('paymentApproved', payment.attributes.approved));
      }
      if (hasSignatory) {
        const signatoryID = authoriser.signatory_id;
        const signatory = authorisation.signatories.find(
          (signatory) => signatory.id === signatoryID
        );
        dispatch(setValue('signatory', signatory));
        dispatch(setValue('signatoryApproved', signatory.attributes.approved));
      }

      if (authorisation.signature) {
        dispatch(
          setIdentity(signatureLoaderFormatter(authorisation.signature))
        );
      }

      if (authorisation.theme) {
        dispatch(setValue('theme', authorisation.theme));
        setTheme(authorisation.theme);
      }

      const userAttributes = {
        email,
        firstName,
        id: authorisation.authorisation.id,
        lastName,
        restoreId: authoriser.freshchat_restore_id,
      };

      if (!FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT) {
        loadingLiveChat('authorisation', userAttributes);
      }

      mixpanel.identify(id);
      mixpanel.register({
        'User ID': id,
        email,
      });
      mixpanel.people.set({
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        Role: 'Authorisation',
      });

      dispatch({
        meta: {
          mixpanel: {
            event: 'Authorisation',
            props: {
              distinct_id: id,
              email,
            },
          },
        },
        payload: authorisation,
        type: AUTHORISATION_LOAD_DATA_SUCCESS,
      });
    } catch (error) {
      dispatch({ payload: error, type: AUTHORISATION_LOAD_DATA_ERROR });
    }
  };
}

export function authorisationLogin(authorisation, token, callback) {
  return async (dispatch, getState) => {
    dispatch({ type: AUTHORISATION_LOGIN_START });

    const tokenAPI = api('tokens');

    try {
      const response = await tokenAPI.requestToken({
        auth: {
          authorisation,
          password: token,
        },
      });

      // Add token to storage.
      setToken(response.data.jwt);

      // Update state
      dispatch({
        payload: response.data,
        type: AUTHORISATION_LOGIN_SUCCESS,
      });

      dispatch({ type: AUTHORISATION_LOGIN_SUCCESS });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({ payload: error, type: AUTHORISATION_LOGIN_ERROR });
    }
  };
}

export function updateAuthorisationSignature(id, callback) {
  return async (dispatch, getState) => {
    dispatch({ type: AUTHORISATION_UPDATE_DATA_START });

    const accessToken = getState().current_user.access_token;

    try {
      const authorisationAPI = api('authorisations', accessToken);

      const signature = signatureDataFormatter();

      if (
        getState().identity.address &&
        getState().identity.address.full_address
      ) {
        signature.address = getState().identity.address;
      }

      const authorisation = getState().authorisation;
      const antiFraudCategoryRules =
        getApplicableAntiFraudRulesForAuthorisationFlow(authorisation);

      let noIdentificationSignatureAttributes = {};
      // This is needed to be able to create a Signature record if the ID
      // section is skipped
      if (!antiFraudCategoryRules.requiresIdentificationSection) {
        noIdentificationSignatureAttributes =
          buildNoIdentificationSignatureAttributes({
            firstName: authorisation.firstName,
            lastName: authorisation.lastName,
          });
      }

      const attributes = {
        signature: {
          ...signature,
          ...noIdentificationSignatureAttributes,
        },
      };

      await authorisationAPI.updateAuthorisation(id, attributes);

      dispatch({
        meta: {
          mixpanel: {
            event: 'Authorisation',
            props: {
              distinct_id: id,
              email: getState().authorisation.email,
            },
          },
        },
        type: AUTHORISATION_UPDATE_DATA_SUCCESS,
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({ type: AUTHORISATION_UPDATE_DATA_ERROR });
    }
  };
}
