import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 80px auto 70px;
  height: 100vh;

  input {
    background-color: white;
  }
`;
