import React from 'react';
import styled from 'styled-components';

const Highlight = styled.span`
  font-weight: bold !important;
  font-style: italic;
`;

export const ExclusionDescription = () => {
  return (
    <p>
      Please {<Highlight>exclude</Highlight>} Watchtower checks on applications
      that fall into this criteria. This means Watchtower will not scan or
      notify you of any matches against these applications.
    </p>
  );
};
