import React, { useState, useEffect } from 'react';
import { LogoText } from 'modules/ShareTheLove/styles';

import './BarLoader.scss';

const BarLoader = ({ text, quickLoad }) => {
  const [progress, setProgress] = useState(0);
  const max = 100;
  let updateProgressInterval;

  useEffect(() => {
    if (quickLoad) {
      setTimeout(() => setProgress(100), 100);
    } else {
      const frequency = 50;
      updateProgressInterval = setInterval(() => {
        if (progress !== max) {
          const nextProgress =
            Math.floor(Math.random() * (max - progress + 1)) + progress;
          setProgress((prev) =>
            prev + nextProgress < max ? prev + nextProgress : max
          );
        }
      }, frequency);
    }

    return () => {
      clearInterval(updateProgressInterval);
    };
  }, []);

  useEffect(() => {
    if (progress === max) {
      clearInterval(updateProgressInterval);
    }
  }, [progress]);

  return (
    <div className="bar-loader__container">
      <div className="bar-loader__logo">
        <LogoText />
      </div>
      <div className="bar-loader__loader">
        <div className="bar-loader__background">
          <div
            className="bar-loader__progress"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <div className="bar-loader__text">{text}</div>
    </div>
  );
};

export default BarLoader;
