import styled from 'styled-components';
import { COLORS } from 'variables/theme';

export const DrawerPanel = styled.div`
  padding-top: 20px;
`;

export const QuestionAndAnswerItemWrapper = styled.div`
  padding: 0 40px;

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.mediumGrey};
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  a {
    color: var(--main-color) !important;
    text-decoration: underline;

    :hover {
      color: var(--main-color-active) !important;
    }
  }
`;
