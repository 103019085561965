import get from 'lodash.get';
import { QUESTION_AND_ANSWER_CONTENTS } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';
import {
  DrawerPanel,
  QuestionAndAnswerItemWrapper,
} from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/styles';
import {
  Description,
  Header,
} from 'modules/shared/components/top/Overlay/SectionDrawer/styles';
import React from 'react';
import { connect } from 'react-redux';
import REGION from 'types/region';
import isBlank from 'utils/isBlank';

const getApplicableContents = ({
  activeQuestionAndAnswer,
  region,
}: {
  activeQuestionAndAnswer: string;
  region: REGION;
}) => {
  if (activeQuestionAndAnswer) {
    return QUESTION_AND_ANSWER_CONTENTS.filter(
      (content) =>
        content.key === activeQuestionAndAnswer &&
        (region ? region === content.region || content.region === 'all' : true)
    );
  }

  if (isBlank(region)) {
    return QUESTION_AND_ANSWER_CONTENTS;
  }

  const regionalContent = QUESTION_AND_ANSWER_CONTENTS.filter((content) =>
    ['all', region].includes(content.region)
  );

  return regionalContent;
};

const PanelContainer = (props) => {
  const { title, description } = props;

  return (
    <QuestionAndAnswerItemWrapper>
      <Header>{title}</Header>
      <Description>{description}</Description>
    </QuestionAndAnswerItemWrapper>
  );
};

const QuestionsPanel = (props) => {
  const { activeQuestionAndAnswer, region } = props;
  const applicableContents = getApplicableContents({
    activeQuestionAndAnswer,
    region,
  });

  return (
    <DrawerPanel>
      {applicableContents.map(({ title, description }) => (
        <PanelContainer title={title} description={description} key={title} />
      ))}
    </DrawerPanel>
  );
};

export default connect((state) => {
  return {
    activeQuestionAndAnswer: get(
      state,
      'applicationOverlayReducer.activeQuestionAndAnswer',
      ''
    ),
    region: get(state, 'cob_business.entity_region'),
  };
})(QuestionsPanel);
