import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import { FONT_COLORS } from 'variables/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled(Typography)`
  color: ${FONT_COLORS.gray};
  font-size: 14px;
`;

const Value = styled(Typography)``;

type LimitProps = {
  label: string;
  value: string;
};

export const Limit = (props: LimitProps) => {
  const { label, value } = props;

  return (
    <Container>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  );
};
