import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { OptionType } from '../types';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'var(--main-color)',
    color: '#fff',
  },
  deleteIcon: {
    color: '#fff',
  },
});

type TagsProps = {
  mainValue: string | null;
  selectedOptions: OptionType[];
  onDelete: (value: string) => void;
};

export const Tags: FC<TagsProps> = (props) => {
  const classes = useStyles();

  const { mainValue, selectedOptions, onDelete } = props;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gridColumnGap={5}
      gridRowGap={5}
      component="ul"
    >
      {selectedOptions.map((option, index) => (
        <li key={option.value}>
          <Chip
            key={option.value}
            label={option.value}
            onDelete={() => {
              onDelete(option.value);
            }}
            classes={option.value === mainValue ? { ...classes } : undefined}
          />
        </li>
      ))}
    </Box>
  );
};
