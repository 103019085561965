import styled from 'styled-components';
import { COLORS } from 'variables/theme';

export const ModalCloseIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  color: ${COLORS.darkGrey};
`;

export const ContentWrapper = styled.div`
  margin: 25px 0 5px 0;
  min-height: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  .square-modal-body {
    padding: 35px 30px;
  }
`;
