import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router';

import LoveIcon from '../../shared/components/svg/LoveIcon';
import { Description, FormActions, SuccessWrapper } from './styles';

const Success = ({ setSent, userIsLoggedIn }) => (
  <SuccessWrapper>
    <LoveIcon />
    <Description>All done! Thank you for sharing the love!</Description>

    <FormActions>
      <button
        className="button is-primary is-rounded"
        type="button"
        tabIndex={0}
        onClick={() => setSent(false)}
      >
        Share more love
      </button>

      {!userIsLoggedIn && (
        <Fragment>
          <Link className="button is-primary is-outlined is-rounded" to="/">
            Log in to 1Centre
          </Link>
          <a
            className="button is-primary is-outlined is-rounded"
            href="https://www.1centre.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit our site
          </a>
        </Fragment>
      )}
    </FormActions>
  </SuccessWrapper>
);

Success.propTypes = {
  userIsLoggedIn: PropTypes.bool,
};

Success.defaultProps = {
  userIsLoggedIn: false,
};

export default Success;
