import styled from 'styled-components';
import { COLORS } from 'variables/theme';

export const NameContent = styled.span`
  font-weight: 800 !important;
`;

export const SuffixContent = styled.span`
  font-weight: 800 !important;
  color: ${COLORS.cerulean};
`;

export const PrivacyPolicyLink = styled.a`
  color: ${COLORS.cerulean};
`;
