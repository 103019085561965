import { displayEmpty } from 'utils/formatting';

import {
  ACCOUNT_TYPE_CODES,
  COURT_TYPE_CODES,
  ORGANISATION_STATUS_TYPE_CODES,
  ORGANISATION_TYPE_CODES,
  PAYMENT_STATUS_CODES,
  REASON_TO_REPORT_CODES,
  ROLE_TYPE_CODES,
} from './codeTableConstants';

export function accountType(type) {
  return displayEmpty(ACCOUNT_TYPE_CODES[type]);
}

export function courtType(type) {
  return displayEmpty(COURT_TYPE_CODES[type]);
}

export function organisationStatusType(type) {
  return displayEmpty(ORGANISATION_STATUS_TYPE_CODES[type]);
}

export function organisationType(type) {
  if (type === null || type === undefined) type = '';

  return displayEmpty(ORGANISATION_TYPE_CODES[type]);
}

export function paymentStatus(type) {
  return displayEmpty(PAYMENT_STATUS_CODES[type]);
}

export function reportCode(type) {
  return displayEmpty(REASON_TO_REPORT_CODES[type]);
}

export function role(type) {
  return displayEmpty(ROLE_TYPE_CODES[type]);
}
