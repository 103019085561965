import React, { Component, Fragment } from 'react';

import Appendix from './consumer-risk-score-sections/Appendix';
import Button from 'modules/shared/components/inputs/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DefaultsAndEnquiries from './consumer-risk-score-sections/DefaultsAndEnquiries';
import FileNotes from './consumer-risk-score-sections/FileNotes';
import PublicRecords from './consumer-risk-score-sections/PublicRecords';
import ReportSummary from './consumer-risk-score-sections/ReportSummary';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import get from 'lodash.get';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import logo from 'images/illion.png';
import objectToArray from 'utils/objectToArray';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from './css/consumer-risk-score/ConsumerRiskScoreComponent.css';

export function extractText(object, key, defaultValue) {
  return get(object, `${key}.text`, defaultValue);
}

export default class ConsumerRiskScore extends Component {
  get response() {
    return get(
      this.props.data,
      'envelope.body.credit_check_response.credit_report_response',
      {}
    );
  }

  get requestSummary() {
    return get(this.response, 'request_summary', {});
  }

  get creditReport() {
    return get(this.response, 'credit_report', {});
  }

  get consumerSummary() {
    return get(this.creditReport, 'consumer_summary', {});
  }

  get individualDetails() {
    return objectToArray(this.creditReport.individual_details);
  }

  get individual() {
    const consumerId = extractText(this.consumerSummary, 'consumer_id');
    const individual = this.individualDetails.find(
      (detail) => get(detail, 'consumer_id.text') === consumerId
    );

    return individual || {};
  }

  get personName() {
    const name = this.individual.person_name || {};

    return [
      extractText(name, 'first_name'),
      extractText(name, 'other_name'),
      extractText(name, 'surname'),
    ].join(' ');
  }

  get address() {
    const addresses = objectToArray(this.individual.address || {});

    const address = addresses.find(
      (a) =>
        extractText(a, 'address_recency') === '0' &&
        extractText(a, 'is_mailing_address') === 'false'
    );

    if (isBlank(address)) {
      return '';
    }

    const addressDetails = [
      extractText(address, 'building_name'),
      extractText(address, 'unit_number'),
      extractText(address, 'street_number'),
      extractText(address, 'street_name'),
      extractText(address, 'street_type'),
      extractText(address, 'suburb_town'),
      extractText(address, 'state'),
      extractText(address, 'postcode'),
    ];

    return addressDetails.filter((a) => isPresent(a)).join(', ');
  }

  constructor(props) {
    super(props);

    this.state = { isAllExpanded: false };
  }

  renderHeader() {
    return (
      <div className={`${styles.header} ${styles.consumer_risk_score_header}`}>
        <div>
          <h4 className={styles.emphasize}>
            <DateRangeIcon style={{ fontSize: 14 }} />
            <span className={styles.report_date}>
              {stringToSimpleDate(
                extractText(this.requestSummary, 'enquiry_date')
              )}
            </span>
          </h4>
          <h2>Negative Report with illion</h2>
          <h2>Consumer Risk Score</h2>
          <h4 className={styles.emphasize}>
            For consumer purpose with commercial information
          </h4>
        </div>
        {this.renderPersonDetails()}
      </div>
    );
  }

  renderPersonDetails() {
    return (
      <div>
        <h4>Personal details:</h4>
        <div className={styles.personal_details}>
          <div>Consumer ID:</div>
          <div>{extractText(this.individual, 'consumer_id')}</div>

          <div>Name:</div>
          <div>{this.personName}</div>

          <div>Date of birth:</div>
          <div>
            {stringToSimpleDate(extractText(this.individual, 'date_of_birth'))}
          </div>

          <div>Gender:</div>
          <div>{extractText(this.individual, 'gender')}</div>

          <div>Driver&apos;s licence no.</div>
          <div>
            {extractText(
              this.individual,
              'drivers_licence.drivers_licence_number'
            )}
          </div>

          <div>Employer name:</div>
          <div>{extractText(this.individual, 'employment.employer')}</div>

          <div>Current address:</div>
          <div>{this.address}</div>
        </div>
      </div>
    );
  }

  onToggleExpandAll = () => {
    this.setState({ isAllExpanded: !this.state.isAllExpanded });
  };

  renderExpandAllButton() {
    let message = '+ expand all';
    if (this.state.isAllExpanded) {
      message = '- collapse all';
    }

    return (
      <Button
        small
        style={{ fontSize: '10px', margin: '1em 0 1em 66px' }}
        text={message}
        handleClick={this.onToggleExpandAll}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <div className={styles.illion_report}>
          <img src={logo} alt="Illion" className={styles.logo} />
          {this.renderHeader()}
          <ReportSummary
            creditReport={this.creditReport}
            response={this.response}
            {...this.props}
          />
          {this.renderExpandAllButton()}
          <DefaultsAndEnquiries
            creditReport={this.creditReport}
            isAllExpanded={this.state.isAllExpanded}
            response={this.response}
          />
          <PublicRecords
            creditReport={this.creditReport}
            isAllExpanded={this.state.isAllExpanded}
            response={this.response}
          />
          <FileNotes
            creditReport={this.creditReport}
            isAllExpanded={this.state.isAllExpanded}
            response={this.response}
          />
          <Appendix
            response={this.response}
            creditReport={this.creditReport}
            isAllExpanded={this.state.isAllExpanded}
          />
          {this.renderExpandAllButton()}
        </div>
      </Fragment>
    );
  }
}
