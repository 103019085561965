import React, { useState } from 'react';
import MenuList from '../../shared/MenuList';

import './sidemenu.scss';

const SideMenu = ({ menuItems }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="side-menu side-menu__left">
      <MenuList
        menuItems={menuItems}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {menuItems[activeIndex] && menuItems[activeIndex].content}
    </div>
  );
};

export default SideMenu;
