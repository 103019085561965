import { IsTrm } from '../modules/shared/auth';
import Check from '../modules/trade-references/components/Check';
import TrmApplicationWrapper from '../modules/trade-references/components/TrmApplicationWrapper';
import TrmContainer from '../modules/trade-references/containers/TrmContainer';

module.exports = {
  path: 'trm',
  component: IsTrm(TrmContainer),
  childRoutes: [
    {
      path: 'list',
      component: TrmApplicationWrapper,
    },
    {
      path: 'review/:id',
      component: Check,
    },
  ],
};
