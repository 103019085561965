import { amountTypeValue, countTypeValue } from './helpers/summary';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import {
  getShareholderAddress,
  getShareholderName,
  ynToYesNo,
} from 'utils/formatting';
import { enquiryResponseData } from './helpers/reportHelpers';
import styles from '../../../css/CommonReviewBusiness.css';
import { get } from 'lodash';

const CurrentShareholders = ({ data, entityType, isAllExpanded }) => {
  const response = enquiryResponseData(data, entityType);
  let documents = get(
    response,
    'ownership_officers.share_holder_list.share_holder',
    []
  );
  if (documents.constructor === Object) documents = [documents];

  const documentRows = documents.map((document, index) => {
    const shareHolderName = getShareholderName(document);
    const shareHolderAddress = getShareholderAddress(document);

    return (
      <tr key={index}>
        <td>{shareHolderName}</td>
        <td>{shareHolderAddress}</td>
        <td>{document.shares_held}</td>
        <td>{document.share_class_code}</td>
        <td>{ynToYesNo(document.beneficial_ownership)}</td>
      </tr>
    );
  });

  return (
    <ReviewContainer
      subHeading="Current Shareholders"
      content_class="content_wide"
      css_class="block_noborder"
      collapsible={true}
      isAllExpanded={isAllExpanded}
      fontSize="medium"
    >
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Shares Held</th>
              <th>Class</th>
              <th>Beneficial</th>
            </tr>
          </thead>
          <tbody>{documentRows}</tbody>
        </table>
      </div>
    </ReviewContainer>
  );
};

export default CurrentShareholders;
