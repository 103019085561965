import { setIdentityValue } from 'modules/identity/actions';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import { isMobileNew } from 'modules/shared/helpers/mobileDetect';
import React, { ReactElement } from 'react';

import { IDENTITY_REGION_DROPDOWN_PROPERTIES } from '../../../../constants';

interface IIdentityRegionDropdown {
  id: string;
  region: string;
  dispatch: any;
  error?: boolean;
  disabled?: boolean;
}

const IdentityRegionDropdown = (
  props: IIdentityRegionDropdown
): ReactElement => {
  const { region, error, dispatch, disabled } = props;
  const {
    label,
    required,
    options,
    name,
    errorMessage,
  } = IDENTITY_REGION_DROPDOWN_PROPERTIES;

  return (
    <div
      key={`${label}-${region}`}
      style={{ minHeight: '85px', width: isMobileNew() ? '100%' : '25%' }}
    >
      <OptionsDropdown
        handleChange={(event) => dispatch(setIdentityValue(name, event.value))}
        error={error ? errorMessage : ''}
        label={label}
        value={region || ''}
        required={required}
        options={options}
        disabled={disabled}
      />
    </div>
  );
};

export default IdentityRegionDropdown;
