import leadSubmittedPath from 'documents/webhooks/v2/lead_submitted.yml';
import {
  SectionHeader,
  SubSection,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import PayloadStructureDescription from 'modules/webhooks/v2/components/V2Documentation/PayloadStructureDescription';
import SyntaxHighlighter from 'modules/webhooks/v2/components/V2Documentation/SyntaxHighlighter';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';

const LeadSubmittedPayloadStructure = (): ReactElement => {
  const [payloadStructure, setPayloadStructure] = useState('loading...');

  useEffect(() => {
    fetch(leadSubmittedPath)
      .then((rawData) => rawData.text())
      .then((decodedText) => setPayloadStructure(decodedText));
  }, [leadSubmittedPath]);

  return (
    <Fragment>
      <SectionHeader>Lead submitted schema</SectionHeader>
      <SubSection>
        <PayloadStructureDescription
          fileName="1Centre-lead-submitted-schema.yml"
          filePath={leadSubmittedPath}
        />
        <SyntaxHighlighter language="yaml">
          {payloadStructure}
        </SyntaxHighlighter>
      </SubSection>
    </Fragment>
  );
};

export default LeadSubmittedPayloadStructure;
