import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from './axios';

export default class CallCentreActivities {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = 'call_centre_activities';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  get(applicationId) {
    return this.axios.get(
      `/applications/${applicationId}/call_centre_activities`,
      { params: { all_versions: true } }
    );
  }
}
