import {
  checkIfEmailExists,
  setRegisterFormField,
  validateVoucherCode,
} from '../actions';

import Button from '../../shared/components/inputs/Button';
import EmailInput from '../../shared/components/inputs/EmailInput';
import React from 'react';
import RegionStatesDropdown from 'modules/shared/components/widgets/static/RegionStatesDropdown';
import TextInput from '../../shared/components/inputs/TextInput';
import { connect } from 'react-redux';
import { countryCodeByAlpha2 } from 'utils/countryCodes';
import { debounce } from 'throttle-debounce';
import styles from './css/RegisterForm.css';

/* RegisterStart */
class RegisterStart extends React.Component {
  componentWillMount() {
    this.checkEmail = debounce(500, false, this.checkEmail);
    this.validateVoucherCode = debounce(500, false, this.validateVoucherCode);
  }

  checkEmail() {
    const { dispatch, email_address } = this.props;
    dispatch(checkIfEmailExists(email_address));
  }

  validateVoucherCode(code) {
    const { dispatch } = this.props;

    if (code === '') {
      dispatch(setRegisterFormField('voucher_code_valid', true));
    } else {
      dispatch(validateVoucherCode(code));
    }
  }

  isValid() {
    const {
      first_name,
      last_name,
      email_address,
      password,
      region,
      state,
    } = this.props;

    if (!first_name || !last_name) {
      return false;
    }
    if (!password || password.length < 8) {
      return false;
    }
    if (!region || (region === 'AU' && !state)) {
      return false;
    }
    if (this.state.form_errors.hasOwnProperty('email_address')) return false;
    if (this.state.form_errors.hasOwnProperty('voucher_code')) return false;

    return true;
  }

  constructor() {
    super();
    this.state = {
      form_errors: {},
    };
  }

  checkValid(target) {
    const { error_messages } = this.props;
    const { form_errors } = this.state;
    switch (target.id) {
      case 'first_name':
      case 'last_name':
      case 'region':
      case 'state':
        let error_message = null;

        if (target.value.length < 1) {
          error_message = error_messages[target.id];
        }

        this.setState({
          form_errors: { ...form_errors, [target.id]: error_message },
        });

        break;
      case 'password':
        if (target.value.length < 8) {
          this.setState({
            password_error: 'Password must be atleast 8 characters long',
          });
        } else {
          const { password_error, ...rest } = this.state;
          this.setState({
            ...this.state,
            password_error: null,
          });
        }
        break;
    }
  }

  blurHandler = (event) => {
    const { blurHandler } = this.props;
    this.checkValid(event.target);
    blurHandler(event);
  };

  changeHandler = (event) => {
    const { changeHandler } = this.props;
    this.checkValid(event.target);
    changeHandler(event);
  };

  voucherCodeChangeHandler = (event) => {
    const { dispatch } = this.props;
    const value = event.target.value;

    this.validateVoucherCode(value);
    dispatch(setRegisterFormField('voucher_code', value));
  };

  emailChangeHandler = (value, isValid) => {
    const { dispatch } = this.props;
    const { form_errors } = this.state;

    dispatch(setRegisterFormField('email_address', value));
    this.checkEmail(value);

    if (isValid) {
      const { email_address, ...rest } = form_errors;
      this.setState({
        form_errors: { ...rest },
      });
    } else {
      this.setState({
        form_errors: { ...form_errors, email_address: true },
      });
    }
  };

  regionStateHandleChange = (target) => {
    const { dispatch } = this.props;
    if (target) {
      this.checkValid(target);
    }
    dispatch(setRegisterFormField(target.name, target.value));

    if (target.name === 'region') {
      const _countryCode = countryCodeByAlpha2(target.value);
      dispatch(setRegisterFormField('country_code', _countryCode));
    }
  };

  render() {
    const {
      submitHandler,
      changeHandler,
      blurHandler,
      first_name,
      last_name,
      phone_number,
      email_address,
      email_valid,
      email_validating,
      password,
      region,
      state,
      countryCode,
      strength,
      css_class,
      instructions,
      password_text,
      confirm_text,
      voucher_code,
      voucher_code_valid,
      voucher_code_validating,
    } = this.props;

    const { form_errors } = this.state;

    const validating = email_validating || voucher_code_validating;
    const emailVoucherValid = email_valid && voucher_code_valid;

    const valid = this.isValid() && !validating && emailVoucherValid;
    let button_text = 'more info required';

    if (valid) {
      button_text = 'get started';
    }

    var first_name_error = form_errors['first_name'];
    var last_name_error = form_errors['last_name'];
    var phone_number_error = form_errors['phone_number'];
    var country_code_error = form_errors['country_code'];
    var email_error = form_errors['email_address'];
    var voucher_error = null;

    if (!email_error && email_valid === false && !email_validating) {
      email_error = 'Email already in use. Please use another';
    }

    let password_strength = <div className={styles.strength}>&nbsp;</div>,
      style = styles.form,
      instructions_text = 'Passwords must be 8 characters long',
      password_label = 'Password';

    if (css_class) {
      style = styles[css_class];
    }

    if (instructions) {
      instructions_text = instructions;
    }

    if (password_text) {
      password_label = password_text;
    }

    if (password.length > 0) {
      var indicators = [];
      for (var i = 0; i < 3; i++) {
        if (parseInt(strength) <= i) {
          indicators.push(
            <div className={styles.indicator_off} key={'indicator-' + i}></div>
          );
        } else {
          indicators.push(
            <div className={styles.indicator_on} key={'indicator-' + i}></div>
          );
        }
      }
      password_strength = (
        <div className={styles.strength} key="strength">
          <span className={styles.label}>Password strength</span>{' '}
          <span className={styles.indicators}>{indicators}</span>
        </div>
      );
    }

    return (
      <form
        className={styles.form}
        onSubmit={submitHandler}
        method="post"
        noValidate
      >
        <fieldset className={styles.fieldset}>
          <TextInput
            id="first_name"
            key="first_name"
            label="First Name"
            type="text"
            value={first_name}
            error={first_name_error}
            required={true}
            handleChange={this.changeHandler}
            handleBlur={this.blurHandler}
            dataCy="register-first-name-text-field"
          />

          <TextInput
            id="last_name"
            key="last_name"
            label="Last Name"
            type="text"
            value={last_name}
            error={last_name_error}
            required={true}
            handleChange={this.changeHandler}
            handleBlur={this.blurHandler}
            dataCy="register-last-name-text-field"
          />

          <RegionStatesDropdown
            id={'location'}
            regionId="region"
            stateId="state"
            label={'Business location'}
            regionError={this.state.region_error}
            stateError={this.state.state_error}
            regionValue={region}
            stateValue={state}
            required={true}
            handleChange={this.regionStateHandleChange}
            dataCy={{
                region: "register-business-region-dropdown",
                state: "register-business-state-dropdown"
              }}
          />

          <TextInput
            id="voucher_code"
            key="voucher_code"
            type="text"
            label={'Voucher code'}
            value={voucher_code}
            error={!voucher_code_valid && options.error_messages.voucher_code}
            handleChange={this.voucherCodeChangeHandler}
            handleBlur={this.voucherCodeChangeHandler}
            dataCy="register-voucher-code-text-field"
          />

          <div className={styles.email}>
            <EmailInput
              id="email_address"
              key="email_address"
              label="Email Address"
              type="email"
              value={email_address}
              customError={email_error}
              required={true}
              loading={email_validating}
              handleChange={this.emailChangeHandler}
              handleBlur={this.emailChangeHandler}
              dataCy="register-email-text-field"
            />
          </div>

          <TextInput
            id="password"
            key="password"
            type="password"
            label={password_label}
            value={password}
            error={this.state.password_error}
            placeholder={password_label}
            required={true}
            handleChange={this.changeHandler}
            handleBlur={this.blurHandler}
            dataCy="register-password-text-field"
          />
          {password_strength}
        </fieldset>

        <div className={styles.policy}>
          Please refer to 1Centre's{' '}
          <a target="_blank" href="https://www.1centre.com/Privacy/index.html">
            privacy policy
          </a>
          .
        </div>

        <div className={styles.button}>
          <Button
            type="submit"
            key="submit_next"
            id="next"
            text={button_text}
            disabled={!valid}
            data-cy="register-submit-button"
          />
        </div>
      </form>
    );
  }
}

const options = {
  error_messages: {
    email_address: 'You must enter a valid email address',
    first_name: 'You must enter your first name',
    last_name: 'You must enter your last name',
    region: 'You must enter your company location',
    state: 'State',
    voucher_code: 'It is not a valid code',
  },
};

module.exports = connect((state) => {
  return {
    email_address: state.register.email_address,
    email_valid: state.register.email_valid,
    email_validating: state.register.email_validating,
    error_messages: options.error_messages,
    first_name: state.register.first_name,
    last_name: state.register.last_name,
    password: state.register.password,
    region: state.register.region,
    state: state.register.state,
    voucher_code: state.register.voucher_code,
    voucher_code_valid: state.register.voucher_code_valid,
    voucher_code_validating: state.register.voucher_code_validating,
  };
})(RegisterStart);
