import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import integerise from 'modules/shared/helpers/integerise';
import React from 'react';
import InputMask from 'react-input-mask';

import styles from './css/TextInput.css';

class TextInput extends React.Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
    };
  }

  formatCommaInteger(text) {
    const { number_only } = this.props;
    if (number_only) {
      const integer = integerise(text);
      return integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return text;
  }

  applyHighlights(text) {
    const { mentions } = this.props;
    // TODO: refactor to remove ESLint disable
    // eslint-disable-next-line no-param-reassign
    text = text.replace(/\n$/g, '\n\n');
    mentions.forEach((mention) => {
      // TODO: refactor to remove ESLint disable
      // eslint-disable-next-line no-param-reassign
      text = text.replace(
        new RegExp(mention, 'g'),
        `<mark style="color: var(--main-font-color)">${mention}</mark>`
      );
    });

    return text;
  }

  passwordVisiblityToggle() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  startAdornment() {
    const { showSearchIcon } = this.props;
    return (
      <React.Fragment>
        {showSearchIcon && <SearchIcon className={styles.search_icon} />}
      </React.Fragment>
    );
  }

  endAdornment() {
    const { loading, type, resetF, EndAdornment } = this.props;

    if (EndAdornment) {
      return EndAdornment;
    }

    if (loading) {
      return (
        <InputAdornment position="end">
          <CircularProgress size={18} thickness={5} />
        </InputAdornment>
      );
    }
    switch (type) {
      case 'password':
        return this.state.showPassword ? (
          <VisibilityOutlinedIcon
            onClick={this.passwordVisiblityToggle.bind(this)}
          />
        ) : (
          <VisibilityOffOutlinedIcon
            onClick={this.passwordVisiblityToggle.bind(this)}
          />
        );
      case 'dropdown':
        return (
          <ArrowDropDownIcon
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              right: '1rem',
            }}
          />
        );
    }
    return (
      <React.Fragment>
        {resetF && (
          <InputAdornment position="end" style={{ cursor: 'pointer' }}>
            <CancelIcon onClick={resetF} />
          </InputAdornment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      autocomplete,
      disabled,
      displayHelperText,
      error,
      handleBlur,
      handleChange,
      handleFocus,
      helper_text,
      hidden,
      hide_helper_text,
      id,
      dataCy,
      inputRef,
      label,
      mask,
      max,
      mentions,
      multiline,
      onBlur,
      onChange,
      onClick,
      placeholder,
      required,
      type,
      value,
      variant,
    } = this.props;

    // TODO: Refactor to remove ESLint disable
    let helper_message = null;

    if (!required) {
      helper_message = 'This field is optional';
    }

    if (helper_text) {
      helper_message = helper_text;
    }
    const formated_value = this.formatCommaInteger(value);
    const inputType = this.state.showPassword ? 'text' : type;

    const hasHighlights =
      mentions && mentions.length > 0 && mentions.every((mention) => mention);

    return (
      hidden || (
        <div className={styles.material_container}>
          <FormControl
            error={!!error}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            className={styles.material_form_control}
            variant={variant}
          >
            <InputLabel
              classes={{
                root: styles.material_label,
                shrink: styles.material_label_shrink,
              }}
              htmlFor={id}
            >
              {label}
            </InputLabel>
            {mentions && (
              <div
                className={styles.backdrop}
                id={`${id}-backdrop`}
                onClick={onClick}
              >
                <div
                  className={`${styles.highlights} ${hasHighlights &&
                    styles.visible}`}
                  dangerouslySetInnerHTML={{
                    __html: this.applyHighlights(formated_value),
                  }}
                ></div>
              </div>
            )}
            <Input
              value={formated_value || ''}
              id={id}
              data-cy={dataCy}
              name={id}
              type={inputType}
              placeholder={placeholder}
              className={`${styles.material_input} ${hasHighlights &&
                styles.material_input_highlighted}`}
              inputProps={{
                mask,
                max,
                onBlur,
                onChange: handleChange || onChange,
                onClick,
              }}
              inputComponent={mask && InputMask}
              inputRef={inputRef}
              autoComplete={autocomplete}
              multiline={multiline}
              rows={multiline ? 4 : null}
              startAdornment={this.startAdornment()}
              endAdornment={this.endAdornment()}
            />
          </FormControl>
          {displayHelperText && (
            <FormHelperText
              id={`${id}-helper-text`}
              error={!!error}
              className={styles.material_helper_text}
              style={
                error
                  ? { fontSize: 10, fontWeight: 800 }
                  : { fontSize: 10, fontWeight: 400 }
              }
            >
              {hide_helper_text ? '' : error || helper_message}
            </FormHelperText>
          )}
        </div>
      )
    );
  }
}

TextInput.defaultProps = {
  displayHelperText: true,
  value: '',
};

export default TextInput;
