import React, { ReactElement } from 'react';
import AustraliaBusinessOverview from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/AustraliaBusinessOverview';
import NewZealandBusinessOverview from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/NewZealandBusinessOverview';
import { connect } from 'react-redux';
import get from 'lodash.get';
import RequestAccess from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess';
import useBusinessOverviewState from 'modules/consumer-onboarding/components/onboarding/v2/hooks/useBusinessOverviewState';
import UserModel from 'models/UserModel';

const retrieveOverviewForm = (region) => {
  if (region === 'AU') {
    return AustraliaBusinessOverview;
  }

  if (region === 'NZ') {
    return NewZealandBusinessOverview;
  }

  throw `Unable to load BusinessOverview. Invalid region: ${region}`;
};

const BusinessOverview = (props): ReactElement => {
  const { application, currentUser, region } = props;

  const businessOverviewState = useBusinessOverviewState({
    application,
    currentUser,
  });
  const Form = retrieveOverviewForm(region);

  return (
    <>
      <Form businessOverviewState={businessOverviewState} {...props} />
      <RequestAccess businessOverviewState={businessOverviewState} {...props} />
    </>
  );
};

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
    region: get(state, 'cob_business.entity_region'),
  };
})(BusinessOverview);
