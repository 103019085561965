import api from 'api';
import { useCallback, useEffect, useState } from 'react';
import { BulkUploadJobType } from 'types/BulkUploadJobType';
import { processError } from 'utils';

const ITEMS_PER_PAGE = 10;
const INITIAL_PAGE_NUMBER = 1;

export const useHistoryLogs = (entityId: string) => {
  const [historyLogItems, setHistoryLogItems] = useState<BulkUploadJobType[]>(
    []
  );
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(INITIAL_PAGE_NUMBER);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const pageCount = Math.ceil(total / ITEMS_PER_PAGE);
  const hasMorePages = page < pageCount;

  const fetchHistoryItems = useCallback(() => {
    setLoading(true);
    const applicationApi = api('applications', '', entityId);
    const params = { entity_id: entityId, page, per: ITEMS_PER_PAGE };
    applicationApi
      .getBulkUploads(params)
      .then((response) => {
        const bulkUploadJobItems = response.data.data.map(
          (jobItem) => jobItem.attributes
        );
        setHistoryLogItems([...historyLogItems, ...bulkUploadJobItems]);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        const { errorMessage } = processError(error);
        setError(errorMessage);
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchHistoryItems();
  }, [fetchHistoryItems]);

  const loadMore = () => setPage((currentPage) => currentPage + 1);

  const refresh = () => {
    // Reset array of history log items
    setHistoryLogItems([]);

    if (page === 1) {
      fetchHistoryItems();
    } else {
      // Reset page number, this will trigger a fetch too.
      setPage(INITIAL_PAGE_NUMBER);
    }
  };

  return {
    hasMorePages,
    historyLogItems,
    isLoading,
    loadMore,
    refresh,
    error,
  };
};
