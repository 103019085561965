import axios from './axios';

export default class Abn {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'abn';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );

    this.path = `au/${this.type}`;
  }

  getDetails(abn) {
    return this.axios.get(`${this.path}/${abn}`);
  }
}
