import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';

const COMPANY_CREDIT_CHECK_TYPES = {
  au_equifax: 'AustraliaEquifax',
  au_illion_payment_predictor: 'AustraliaIllionPaymentPredictor',
  nz_credit_works: 'CreditWorks',
};

const PERSONAL_CREDIT_CHECK_TYPES = {
  au_equifax: 'AustraliaEquifaxPersonal',
  au_illion_consumer_risk_score: 'AustraliaIllionConsumerRiskScore',
  nz_centrix: 'Centrix',
};

function getSTIType(creditCheckType, selectedCreditCheck) {
  if (creditCheckType === 'company') {
    return COMPANY_CREDIT_CHECK_TYPES[selectedCreditCheck];
  }

  return PERSONAL_CREDIT_CHECK_TYPES[selectedCreditCheck];
}

export default class CreditCheckModel extends BaseModel {
  static runCreditCheck({
    application,
    creditCheckType,
    currentUser,
    person,
    selectedCreditCheck,
    onSuccess,
    corporateTrustId,
  }) {
    const attributes = {
      status: 'created',
      type: getSTIType(creditCheckType, selectedCreditCheck),
    };

    if (creditCheckType === 'person') {
      attributes.authorisation_id = person.authorisationId;
      attributes.signature_id = person.signatureId;
    }

    const creditCheck = new CreditCheckModel({
      attributes,
      type: 'credit_checks',
    });

    const onSuccessWrapper = (response) => {
      onSuccess(response, creditCheck);
    };

    creditCheck.initiating();
    CreditCheckModel.create({
      application,
      creditCheckType,
      currentUser,
      onSuccess: onSuccessWrapper,
      person,
      corporateTrustId,
    });

    return creditCheck;
  }

  static create({
    application,
    creditCheckType,
    currentUser,
    person,
    onSuccess,
    corporateTrustId,
  }) {
    const creditCheckAPI = api(
      'credit_checks',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id'),
      { headers: { 'Content-Type': 'application/json' }, version: '/v2' }
    );

    const attributes = {
      action_type: creditCheckType,
      application_id: application.id,
      ...(corporateTrustId && FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS
        ? { associated_entity_id: corporateTrustId }
        : {}),
    };

    if (creditCheckType === 'person') {
      attributes.signature_id = person.signatureId;
    }

    creditCheckAPI.create(attributes, onSuccess);
  }

  get isCompanyCheck() {
    return Object.values(COMPANY_CREDIT_CHECK_TYPES).includes(this.type);
  }

  get canBeRendered() {
    return ['completed', 'errored'].includes(this.status);
  }

  get isLoading() {
    return this.status === 'started' || this.isInitiating;
  }

  errored() {
    this.status = 'errored';
    this.isInitiating = false;
  }

  fatal() {
    this.status = 'fatal';
    this.isInitiating = false;
  }

  initiating() {
    this.isInitiating = true;
  }

  constructor(data = {}, included = []) {
    super(data, included);
    this.isInitiating = false;
  }
}
