import {
  COB_BUSINESS_COMPANY_DETAILS_ERROR,
  COB_BUSINESS_COMPANY_DETAILS_START,
  COB_BUSINESS_COMPANY_DETAILS_SUCCESS,
  COB_BUSINESS_COMPANY_MANUAL_SET,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_CLEAR,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_START,
  COB_BUSINESS_COMPANY_NAME_LOOKUP_SUCCESS,
  COB_BUSINESS_COMPANY_NAME_SELECT,
  COB_BUSINESS_COMPANY_SET_NAME,
  COB_BUSINESS_COMPONENT_COMPLETE,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_START,
  COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_PENDING_APPLICATION_ERROR,
  COB_BUSINESS_PENDING_APPLICATION_START,
  COB_BUSINESS_PENDING_APPLICATION_SUCCESS,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_PRESET_PHYSICAL_ADDRESS,
  COB_BUSINESS_PRESET_POSTAL_ADDRESS,
  COB_BUSINESS_REMOVE_ENTITY_PARTY_DETAILS,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_CLEAR,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_ERROR,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_START,
  COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_SUCCESS,
  COB_BUSINESS_SECTION_COMPLETE,
  COB_BUSINESS_SELECT_ENTITY_PARTY_COUNT,
  COB_BUSINESS_SELECT_ENTITY_TYPE,
  COB_BUSINESS_SELECT_PHYSICAL_ADDRESS,
  COB_BUSINESS_SELECT_POSTAL_ADDRESS,
  COB_BUSINESS_SET_ADDRESS,
  COB_BUSINESS_SET_DEFAULT_ENTITY_DETAILS,
  COB_BUSINESS_SET_DELIVERY_INSTRUCTION,
  COB_BUSINESS_SET_DIRECTORS_CONTAINER_INDEX,
  COB_BUSINESS_SET_ENTITY_ID,
  COB_BUSINESS_SET_ENTITY_LOGO,
  COB_BUSINESS_SET_ENTITY_NAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_COUNTRY_CODE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_PHONE_NUMBER,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMPLOYER,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_FIRSTNAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_IS_APPLICANT,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_LASTNAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_MIDDLENAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS_API_ID,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_POSITION,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PREVIOUS_LASTNAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_ADDRESS,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_API_ID,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_TYPE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_INDEX,
  COB_BUSINESS_SET_INCORPORATED_NUMBER,
  COB_BUSINESS_SET_APPLICATION_TYPE,
  COB_BUSINESS_SET_KEY_CONTACT_COUNTRY_CODE,
  COB_BUSINESS_SET_KEY_CONTACT_EMAIL,
  COB_BUSINESS_SET_KEY_CONTACT_FIRST_NAME,
  COB_BUSINESS_SET_KEY_CONTACT_LAST_NAME,
  COB_BUSINESS_SET_KEY_CONTACT_PASSWORD,
  COB_BUSINESS_SET_KEY_CONTACT_PHONE,
  COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT,
  COB_BUSINESS_SET_PHONE,
  COB_BUSINESS_SET_PHYSICAL_ADDRESS,
  COB_BUSINESS_SET_POSTAL,
  COB_BUSINESS_SET_POSTAL_ADDRESS,
  COB_BUSINESS_SET_RECOVERY_EMAIL,
  COB_BUSINESS_SET_REGION,
  COB_BUSINESS_SET_SECTION_INDEX,
  COB_BUSINESS_SET_STAFF_COUNT,
  COB_BUSINESS_SET_STATE,
  COB_BUSINESS_SET_SUPPLIER_TRADING_NAME_ID,
  COB_BUSINESS_SET_WEBSITE,
  COB_BUSINESS_SHOW_WARNING,
  COB_BUSINESS_UNIQUE_EMAIL_ERROR,
  COB_BUSINESS_UNIQUE_EMAIL_START,
  COB_BUSINESS_UNIQUE_EMAIL_SUCCESS,
  COB_BUSINESS_UPDATE_KEY_CONTACT_PERCENTAGE,
  COB_BUSINESS_SET_ENTITY_BUSINESS_API_DETAILS,
  COB_BUSINESS_SET_ENTITY_DIRECTORS_API_LOADING_STATUS,
  COB_BUSINESS_LOAD_COMPANY_GUARANTORS_SUCCESS,
  COB_BUSINESS_LOAD_COMPANY_GUARANTORS_START,
  COB_BUSINESS_LOAD_COMPANY_GUARANTORS_ERROR,
  COB_BUSINESS_REMOVE_GUARANTOR_FROM_DELETE_LIST,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_TRUSTEE_TYPE,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_COMPANY_NAME,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_COMPANY_NUMBER,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_BUSINESS_NUMBER,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_ID,
  COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_DIRECTOR_COUNT,
  COB_BUSINESS_SET_CORPORATE_TRUSTEE_DIRECTORS,
  COB_BUSINESS_SET_CORPORATE_TRUSTEE_DIRECTORS_OF_ASSOCIATED_ENTITY,
  COB_BUSINESS_SET_CORPORATE_TRUSTEE_DIRECTOR_DETAILS,
  COB_BUSINESS_SET_CORPORATE_TRUSTEE_SHAREHOLDERS,
} from '../constants/onboarding';
import {
  IP_GEOLOCATION_LOAD_ERROR,
  IP_GEOLOCATION_LOAD_START,
  IP_GEOLOCATION_LOAD_SUCCESS,
} from '../../ip-geolocation/constants.js';

import { STATE_LOAD_STATE_SUCCESS } from '../../state/constants.js';

const trusteeTypeToLegalTypeMapping = {
  individual: 'trustee',
  independent: 'independednt_trustee',
  corporate: null,
};

/* Default state for consumer onboarding */
var cob_business_default = {
  section_index: 0,
  section_data: {},
  section_complete: false,
  completed: {},
  entity_type: null,
  entity_type_title: null,
  entity_name: '',
  logo: '',
  entity_staff_count: '',
  entity_phone: '',
  entity_website_url: '',
  entity_address: '',
  entity_postal: '',
  entity_party_count: '',
  entity_party_details_total_percent: 0,
  entity_party_details_values: [],
  entity_party_details_container_index: 0,
  entity_region: '',
  entity_state: '',
  application_type: 'credit',
  key_contact_percentage: 0,
  key_contact_first_name: '',
  key_contact_last_name: '',
  key_contact_phone: '',
  key_contact_country_code: '',
  key_contact_email: '',
  key_contact_password: '',
  email_valid: true,
  email_validating: false,
  company_details: {},
  company_autosuggest_field_value: '',
  company_autosuggest_lookup_loading: false,
  company_autosuggest_list: {},
  company_autosuggest_selected_name: '',
  company_autosuggest_selected_details: {},
  company_nzbn_details_loading: false,
  company_directors_container_index: 0,

  // Possible values null, 'error', 'loading', 'success'
  company_directors_from_api_loading_status: null,

  // Raw Response from API
  // We want to save the raw response in the database
  // to determine who is the API provider, i.e. NZPost, Google Places
  physical_address_raw_list: {},
  postal_address_raw_list: {},
  delivery_address_raw_list: {},

  // List to be displayed in the dropdown
  physical_address_list: {},
  postal_address_list: {},
  delivery_address_list: {},

  postal_address: { full_address: '' },
  physical_address: { full_address: '' },
  postal_api_id: null,
  physical_api_id: null,
  postal_address_loading: false,
  physical_address_loading: false,
  delivery_address_loading: false,
  recovery_email: '',
  user_entity_links: [],
  supplier_trading_name_id: null,
  entity_id: null,

  delivery_instruction: '',
  incorporated_number: '',
  legal_entity_agreement: null,

  // This a list of pending applications when coming from the website button
  pendingApplication: [],

  // List of entities within the same email domain
  entitiesByDomain: [],

  businessApiDetails: {},
  company_data_from_api: false,

  corporateTrusteeDirectors: {},
  corporateTrusteeShareholders: {},
};

export function consumerOnboardingBusinessReducer(
  state = cob_business_default,
  action
) {
  switch (action.type) {
    case COB_BUSINESS_SET_POSTAL_ADDRESS:
      return { ...state, postal_address: action.payload, postal_api_id: null };
    case COB_BUSINESS_SET_PHYSICAL_ADDRESS:
      return {
        ...state,
        physical_address: action.payload,
        physical_api_id: null,
      };

    case COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        postal_address_list: {},
        postal_address_raw_list: {},
        postal_api_id: null,
      };
    case COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        physical_address_list: {},
        physical_address_raw_list: {},
        physical_api_id: null,
      };
    case COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_CLEAR:
      return {
        ...state,
        delivery_address_list: {},
        delivery_address_raw_list: {},
      };
    case COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_START:
      return { ...state, postal_address_loading: true, postal_api_id: null };
    case COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_START:
      return {
        ...state,
        physical_address_loading: true,
        physical_api_id: null,
      };
    case COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, postal_address_loading: false };
    case COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_ERROR:
      return { ...state, physical_address_loading: false };
    case COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_ERROR:
      return { ...state, delivery_address_loading: false };
    case COB_BUSINESS_POSTAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        postal_address_loading: false,
        postal_address_raw_list: action.payload.rawData,
        postal_address_list: action.payload.dropdownData,
      };
    case COB_BUSINESS_PHYSICAL_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        physical_address_loading: false,
        physical_address_raw_list: action.payload.rawData,
        physical_address_list: action.payload.dropdownData,
      };
    case COB_BUSINESS_DELIVERY_ADDRESS_LOOKUP_SUCCESS:
      return {
        ...state,
        delivery_address_loading: false,
        delivery_address_raw_list: action.payload.rawData,
        delivery_address_list: action.payload.dropdownData,
      };
    case COB_BUSINESS_SELECT_POSTAL_ADDRESS:
      return {
        ...state,
        postal_api_id: action.payload,
        postal_address_loading: false,
        postal_address_list: {},
        postal_address_raw_list: {},
        postal_address:
          state.postal_address_raw_list[action.payload] || state.postal_address,
      };
    case COB_BUSINESS_SELECT_PHYSICAL_ADDRESS:
      return {
        ...state,
        physical_api_id: action.payload,
        physical_address_loading: false,
        // physical_address_list: {},
        // physical_address_raw_list: {},
        physical_address:
          state.physical_address_raw_list[action.payload] ||
          state.physical_address,
      };

    case COB_BUSINESS_PRESET_POSTAL_ADDRESS:
      return {
        ...state,
        postal_api_id: action.payload.attributes.api_id,
        postal_address_loading: false,
        postal_address_list: {},
        postal_address_raw_list: {},
        postal_address: {
          api_id: action.payload.attributes.api_id,
          api_provider: action.payload.attributes.api_provider,
          full_address: action.payload.attributes.full_address,
          raw_data: action.payload.attributes.raw_data,
        },
      };
    case COB_BUSINESS_PRESET_PHYSICAL_ADDRESS:
      return {
        ...state,
        physical_api_id: action.payload.attributes.api_id,
        physical_address_loading: false,
        physical_address_list: {},
        physical_address_raw_list: {},
        physical_address: {
          api_id: action.payload.attributes.api_id,
          api_provider: action.payload.attributes.api_provider,
          full_address: action.payload.attributes.full_address,
          raw_data: action.payload.attributes.raw_data,
        },
      };

    case COB_BUSINESS_UPDATE_KEY_CONTACT_PERCENTAGE:
      return { ...state, key_contact_percentage: action.payload };
    case COB_BUSINESS_SET_KEY_CONTACT_FIRST_NAME:
      return { ...state, key_contact_first_name: action.payload };
    case COB_BUSINESS_SET_KEY_CONTACT_LAST_NAME:
      return { ...state, key_contact_last_name: action.payload };
    case COB_BUSINESS_SET_KEY_CONTACT_PHONE:
      return { ...state, key_contact_phone: action.payload };
    case COB_BUSINESS_SET_KEY_CONTACT_COUNTRY_CODE:
      return { ...state, key_contact_country_code: action.payload };
    case COB_BUSINESS_SET_KEY_CONTACT_EMAIL:
      return { ...state, key_contact_email: action.payload };
    case COB_BUSINESS_SET_KEY_CONTACT_PASSWORD:
      return { ...state, key_contact_password: action.payload };

    case COB_BUSINESS_COMPONENT_COMPLETE:
      var component_state = new Object();
      component_state[action.payload.component] = action.payload.state;
      return {
        ...state,
        completed: { ...state.completed, ...component_state },
      };

    case COB_BUSINESS_SECTION_COMPLETE:
      return {
        ...state,
        section_data: action.payload.data,
        section_complete: action.payload.complete,
      };

    case COB_BUSINESS_SET_ENTITY_LOGO:
      return { ...state, logo: action.payload };

    case COB_BUSINESS_REMOVE_ENTITY_PARTY_DETAILS:
      var form_values = state.entity_party_details_values.slice(
        0,
        action.payload
      );
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_FIRSTNAME:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          first_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          first_name: action.payload.value,
        };
      }
      return {
        ...state,
        entity_party_details_values: form_values,
        guarantorsToDelete: action.payload.guarantorsToDelete,
      };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_LASTNAME:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          last_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          last_name: action.payload.value,
        };
      }
      return {
        ...state,
        entity_party_details_values: form_values,
        guarantorsToDelete: action.payload.guarantorsToDelete,
      };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_MIDDLENAME:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          middle_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          middle_name: action.payload.value,
        };
      }
      return {
        ...state,
        entity_party_details_values: form_values,
        guarantorsToDelete: action.payload.guarantorsToDelete,
      };

    case COB_BUSINESS_REMOVE_GUARANTOR_FROM_DELETE_LIST:
      const currentGuarantorsToDelete = state.guarantorsToDelete || [];
      return {
        ...state,
        guarantorsToDelete: currentGuarantorsToDelete.filter(
          (guarantorId) => guarantorId !== action.payload.id
        ),
      };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMAIL:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          email: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          email: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_SHARE:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      var total_percent = 0;
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          percentage_share: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          percentage_share: action.payload.value,
        };
      }

      // Work out the total percent
      for (var i = 0; i < form_values.length; i++) {
        if (form_values[i].hasOwnProperty('percent')) {
          total_percent += parseInt(form_values[i].percent);
        }
      }

      return {
        ...state,
        entity_party_details_values: form_values,
        entity_party_details_total_percent: total_percent,
      };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PREVIOUS_LASTNAME:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          previous_last_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          previous_last_name: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_POSITION:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          position: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          position: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_ADDRESS:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_address: action.payload.value,
          residential_api_id: null,
        };
      } else {
        form_values[action.payload.index] = {
          residential_address: action.payload.value,
          residential_api_id: null,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_API_ID:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_api_id: action.payload.value,
          residential_address_loading: false,
          // residential_address_raw_list: {},
          // residential_address_list: {},
          residential_address:
            form_values[action.payload.index].residential_address_raw_list[
              action.payload.value
            ] || form_values[action.payload.index].residential_address,
        };
      } else {
        form_values[action.payload.index] = {
          residential_api_id: action.payload.value,
          residential_address_loading: false,
          residential_address_raw_list: {},
          residential_address_list: {},
          residential_address:
            form_values[action.payload.index].residential_address_raw_list[
              action.payload.value
            ] || form_values[action.payload.index].residential_address,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_RESIDENTIAL_TYPE:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_type: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          residential_type: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_IS_APPLICANT:
      const formValues = state.entity_party_details_values.slice(); // Copy array
      if (formValues[action.payload.index]) {
        formValues[action.payload.index] = {
          ...formValues[action.payload.index],
          is_applicant: action.payload.value,
        };
      } else {
        formValues[action.payload.index] = {
          is_applicant: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: formValues };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_EMPLOYER:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          employer: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          employer: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_PHONE_NUMBER:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          contact_phone_number: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          contact_phone_number: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CONTACT_COUNTRY_CODE:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          contact_country_code: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          contact_country_code: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_TRUSTEE_TYPE:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          trustee_type: action.payload.value,
          email:
            action.payload.value !== 'individual'
              ? null
              : form_values[action.payload.index].email,
          is_applicant:
            action.payload.value !== 'individual'
              ? null
              : form_values[action.payload.index].is_applicant,
          legal_type: trusteeTypeToLegalTypeMapping[action.payload.value],
        };
      } else {
        form_values[action.payload.index] = {
          trustee_type: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_COMPANY_NUMBER:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          corporate_trustee_company_number: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          corporate_trustee_company_number: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_BUSINESS_NUMBER:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          corporate_trustee_business_number: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          corporate_trustee_business_number: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_COMPANY_NAME:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          corporate_trustee_company_name: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          corporate_trustee_company_name: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_ID:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          corporate_trustee_id: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          corporate_trustee_id: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };
    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_CORPORATE_TRUSTEE_DIRECTOR_COUNT:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          corporate_trustee_director_count: action.payload.value,
        };
      } else {
        form_values[action.payload.index] = {
          corporate_trustee_director_count: action.payload.value,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_CLEAR:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_address_list: {},
          residential_address_raw_list: {},
          residential_api_id: null,
        };
      } else {
        form_values[action.payload.index] = {
          residential_address_list: {},
          residential_address_raw_list: {},
          residential_api_id: null,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_START:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_address_loading: true,
          residential_api_id: null,
        };
      } else {
        form_values[action.payload.index] = {
          residential_address_loading: true,
          residential_api_id: null,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_SUCCESS:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_address_loading: false,
          residential_address_raw_list: action.payload.rawData,
          residential_address_list: action.payload.dropdownData,
        };
      } else {
        form_values[action.payload.index] = {
          residential_address_loading: false,
          residential_address_raw_list: action.payload.rawData,
          residential_address_list: action.payload.dropdownData,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_RESIDENTIAL_ADDRESS_LOOKUP_ERROR:
      var form_values = state.entity_party_details_values.slice(); // Copy array
      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          residential_address_loading: false,
        };
      } else {
        form_values[action.payload.index] = {
          residential_address_loading: false,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS:
      return {
        ...state,
        entity_party_details_values: action.payload,
        persisted_entity_party_details_values: action.payload,
        entity_party_details_total_percent: 100,
      };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_INDEX:
      return { ...state, entity_party_details_container_index: action.payload };

    case COB_BUSINESS_SET_APPLICATION_TYPE:
      return { ...state, application_type: action.payload };

    case COB_BUSINESS_SET_ENTITY_NAME:
      return { ...state, entity_name: action.payload };

    case COB_BUSINESS_SET_STAFF_COUNT:
      return {
        ...state,
        entity_staff_count: parseInt(action.payload) === 0 ? 1 : action.payload,
      };

    case COB_BUSINESS_SET_PHONE:
      return { ...state, entity_phone: action.payload };

    case COB_BUSINESS_SET_WEBSITE:
      return { ...state, entity_website_url: action.payload };

    case COB_BUSINESS_SET_ADDRESS:
      return { ...state, entity_address: action.payload };

    case COB_BUSINESS_SET_POSTAL:
      return { ...state, entity_postal: action.payload };

    case COB_BUSINESS_SET_SECTION_INDEX:
      return { ...state, section_index: action.payload };

    case COB_BUSINESS_SELECT_ENTITY_TYPE:
      return {
        ...state,
        entity_type: action.payload.type,
        entity_type_title: action.payload.title,
      };

    case COB_BUSINESS_SELECT_ENTITY_PARTY_COUNT:
      return { ...state, entity_party_count: action.payload };

    case COB_BUSINESS_SET_DIRECTORS_CONTAINER_INDEX:
      return { ...state, company_directors_container_index: action.payload };

    case COB_BUSINESS_SET_ENTITY_DIRECTORS_API_LOADING_STATUS:
      return {
        ...state,
        company_directors_from_api_loading_status: action.payload || null,
      };

    case COB_BUSINESS_COMPANY_NAME_LOOKUP_START:
      return {
        ...state,
        company_autosuggest_selected_details: action.payload,
        company_autosuggest_lookup_loading: true,
      };

    case COB_BUSINESS_COMPANY_NAME_LOOKUP_SUCCESS:
      return {
        ...state,
        company_autosuggest_list: action.payload,
        company_autosuggest_lookup_loading: false,
      };

    case COB_BUSINESS_COMPANY_SET_NAME:
      return { ...state, company_autosuggest_field_value: action.payload };

    case COB_BUSINESS_COMPANY_NAME_SELECT:
      return {
        ...state,
        company_details: {},
        company_autosuggest_selected_details: action.payload,
        company_autosuggest_field_value: action.payload.name,
        company_autosuggest_selected_name: action.payload.name,
      };

    case COB_BUSINESS_COMPANY_NAME_LOOKUP_CLEAR:
      return {
        ...state,
        company_autosuggest_list: [],
        company_autosuggest_selected_details: null,
      };

    case COB_BUSINESS_COMPANY_DETAILS_START:
      return { ...state, company_nzbn_details_loading: true };

    case COB_BUSINESS_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        company_nzbn_details_loading: false,
        company_details: action.payload,
      };

    case COB_BUSINESS_LOAD_COMPANY_GUARANTORS_START:
      return { ...state, company_directors_from_api_loading_status: 'loading' };

    case COB_BUSINESS_LOAD_COMPANY_GUARANTORS_SUCCESS: {
      const { people, shareholder } = action.payload || {};
      const hasGuarantors =
        (people && people.length) || (shareholder && shareholder.length);
      return {
        ...state,
        company_nzbn_details_loading: false,
        company_details: {
          ...state.company_details,
          ...action.payload,
        },
        company_directors_from_api_loading_status: hasGuarantors
          ? 'success'
          : 'error',
      };
    }

    case COB_BUSINESS_LOAD_COMPANY_GUARANTORS_ERROR:
      return {
        ...state,
        company_directors_from_api_loading_status: 'error',
      };

    case COB_BUSINESS_COMPANY_DETAILS_ERROR:
      return { ...state, company_nzbn_details_loading: false };

    case STATE_LOAD_STATE_SUCCESS:
      if (action.payload['cob_business']) {
        return { ...state, ...action.payload.cob_business };
      } else {
        return { ...state };
      }

    case COB_BUSINESS_SET_REGION:
      return { ...state, entity_region: action.payload };

    case COB_BUSINESS_SET_STATE:
      return { ...state, entity_state: action.payload };

    case IP_GEOLOCATION_LOAD_START:
      return { ...state, ip_geolocation_loading: true };
    case IP_GEOLOCATION_LOAD_SUCCESS:
      return {
        ...state,
        ip_geolocation_loading: false,
        ip_geolocation: action.payload,
      };
    case IP_GEOLOCATION_LOAD_ERROR:
      return { ...state, ip_geolocation_loading: false };

    case COB_BUSINESS_UNIQUE_EMAIL_START:
      return { ...state, email_validating: true };
    case COB_BUSINESS_UNIQUE_EMAIL_ERROR:
      return { ...state, email_validating: false };
    case COB_BUSINESS_UNIQUE_EMAIL_SUCCESS:
      return {
        ...state,
        email_valid: !action.payload.user_exists || !action.payload.has_entity,
        email_validating: false,
        user_entity_links: action.payload.user_entity_links,
        entitiesByDomain: action.payload.entities_by_domain,
      };

    case COB_BUSINESS_COMPANY_MANUAL_SET:
      return {
        ...state,
        company_details: { ...state.company_details, ...action.payload },
      };

    case COB_BUSINESS_SET_DEFAULT_ENTITY_DETAILS:
      return {
        ...state,
        entity_name: action.payload.entity_name,
        entity_website_url: action.payload.entity_website_url,
        entity_phone: action.payload.entity_phone,
        entity_region: action.payload.entity_region,
        entity_state: action.payload.entity_state,
        key_contact_first_name: action.payload.key_contact_first_name,
        key_contact_last_name: action.payload.key_contact_last_name,
        key_contact_email: action.payload.key_contact_email,
        key_contact_phone: action.payload.key_contact_phone,
        key_contact_country_code: action.payload.key_contact_country_code,
      };
    case COB_BUSINESS_SET_RECOVERY_EMAIL:
      return { ...state, recovery_email: action.payload };

    case COB_BUSINESS_SHOW_WARNING:
      return { ...state, warning: action.payload };

    case COB_BUSINESS_SET_ENTITY_ID:
      return { ...state, entity_id: action.payload };

    case COB_BUSINESS_SET_DELIVERY_INSTRUCTION:
      return { ...state, delivery_instruction: action.payload };

    case COB_BUSINESS_SET_INCORPORATED_NUMBER:
      return { ...state, incorporated_number: action.payload };

    case COB_BUSINESS_SET_SUPPLIER_TRADING_NAME_ID:
      return { ...state, supplier_trading_name_id: action.payload };

    case COB_BUSINESS_SET_LEGAL_ENTITY_AGREEMENT:
      return { ...state, legal_entity_agreement: action.payload };

    case COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_CLEAR:
      var form_values = state.entity_party_details_values.slice(); // Copy array

      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          person_postal_address_list: {},
          person_postal_address_raw_list: {},
          person_postal_api_id: null,
        };
      } else {
        form_values[action.payload.index] = {
          person_postal_address_list: {},
          person_postal_address_raw_list: {},
          person_postal_address_api_id: null,
        };
      }

      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_START:
      var form_values = state.entity_party_details_values.slice(); // Copy array

      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          person_postal_address_loading: true,
          person_postal_address_api_id: null,
        };
      } else {
        form_values[action.payload.index] = {
          person_postal_address_loading: true,
          person_postal_address_api_id: null,
        };
      }

      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_SUCCESS:
      var form_values = state.entity_party_details_values.slice(); // Copy array

      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          person_postal_address_loading: false,
          person_postal_address_raw_list: action.payload.rawData,
          person_postal_address_list: action.payload.dropdownData,
        };
      } else {
        form_values[action.payload.index] = {
          person_postal_address_loading: false,
          person_postal_address_raw_list: action.payload.rawData,
          person_postal_address_list: action.payload.dropdownData,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_PERSON_POSTAL_ADDRESS_LOOKUP_ERROR:
      var form_values = state.entity_party_details_values.slice(); // Copy array

      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          person_postal_address_loading: false,
        };
      } else {
        form_values[action.payload.index] = {
          person_postal_address_loading: false,
        };
      }
      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS:
      var form_values = state.entity_party_details_values.slice(); // Copy array

      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          person_postal_address: action.payload.value,
          person_postal_address_api_id: null,
        };
      } else {
        form_values[action.payload.index] = {
          person_postal_address: action.payload.value,
          person_postal_address_api_id: null,
        };
      }

      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_SET_ENTITY_PARTY_DETAILS_FORM_DATA_PERSON_POSTAL_ADDRESS_API_ID:
      var form_values = state.entity_party_details_values.slice(); // Copy array

      if (form_values[action.payload.index]) {
        form_values[action.payload.index] = {
          ...form_values[action.payload.index],
          person_postal_address_api_id: action.payload.value,
          person_postal_address_loading: false,
          person_postal_address:
            form_values[action.payload.index].person_postal_address_raw_list[
              action.payload.value
            ] || form_values[action.payload.index].person_postal_address,
        };
      } else {
        form_values[action.payload.index] = {
          person_postal_address_api_id: action.payload.value,
          person_postal_address_loading: false,
          person_postal_address_raw_list: {},
          person_postal_address_list: {},
          person_postal_address:
            form_values[action.payload.index].person_postal_address_raw_list[
              action.payload.value
            ] || form_values[action.payload.index].person_postal_address,
        };
      }

      return { ...state, entity_party_details_values: form_values };

    case COB_BUSINESS_PENDING_APPLICATION_ERROR:
      return { ...state };
    case COB_BUSINESS_PENDING_APPLICATION_START:
      return { ...state };
    case COB_BUSINESS_PENDING_APPLICATION_SUCCESS:
      return { ...state, pendingApplication: action.payload };

    case COB_BUSINESS_SET_ENTITY_BUSINESS_API_DETAILS:
      return {
        ...state,
        businessApiDetails: action.payload,
        company_data_from_api: true,
      };
    case COB_BUSINESS_SET_CORPORATE_TRUSTEE_DIRECTORS:
      return { ...state, corporateTrusteeDirectors: action.payload };
    case COB_BUSINESS_SET_CORPORATE_TRUSTEE_DIRECTORS_OF_ASSOCIATED_ENTITY:
      return {
        ...state,
        corporateTrusteeDirectors: {
          ...state.corporateTrusteeDirectors,
          [action.payload.associatedEntityId]: action.payload.directors,
        },
      };
    case COB_BUSINESS_SET_CORPORATE_TRUSTEE_DIRECTOR_DETAILS:
      const { corporateTrusteeId, subComponentIndex, key, value } =
        action.payload;
      if (state.corporateTrusteeDirectors[corporateTrusteeId]) {
        const existingDirectorsCount =
          state.corporateTrusteeDirectors[corporateTrusteeId].length;

        if (subComponentIndex < existingDirectorsCount) {
          // modifying existing director details
          return {
            ...state,
            corporateTrusteeDirectors: {
              ...state.corporateTrusteeDirectors,
              [corporateTrusteeId]: state.corporateTrusteeDirectors[
                corporateTrusteeId
              ].map((director, index) => {
                if (index === subComponentIndex) {
                  return {
                    ...director,
                    [key]: value,
                  };
                } else {
                  return director;
                }
              }),
            },
          };
        } else {
          return {
            ...state,
            corporateTrusteeDirectors: {
              ...state.corporateTrusteeDirectors,
              [corporateTrusteeId]: [
                ...state.corporateTrusteeDirectors[corporateTrusteeId],
                { [key]: value },
              ],
            },
          };
        }
      } else {
        const director = {
          ...state.corporateTrusteeDirectors[corporateTrusteeId],
          [key]: value,
        };
        return {
          ...state,
          corporateTrusteeDirectors: {
            ...state.corporateTrusteeDirectors,
            [corporateTrusteeId]: [director],
          },
        };
      }
    case COB_BUSINESS_SET_CORPORATE_TRUSTEE_SHAREHOLDERS: {
      const { corporateTrusteeId, shareholders } = action.payload;
      return {
        ...state,
        corporateTrusteeShareholders: {
          ...state.corporateTrusteeShareholders,
          [corporateTrusteeId]: shareholders,
        },
      };
    }
    default:
      return { ...state };
  }
}
