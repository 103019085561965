import SearchableEntityName from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/EntityName/SearchableEntityName';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import React, { ReactElement } from 'react';
import REGION from 'types/region';

const EntityName = ({
  businessNumber,
  control,
  currentUser,
  entityName,
  name,
  label,
  labelShrink,
  placeholder,
  onBlur,
  onFocus,
  onReset,
  onSetBusinessNumber,
  readOnly,
  region,
  isSearchable,
}: {
  businessNumber: string;
  control: any;
  currentUser: { accessToken: string };
  entityName: string;
  name: string;
  label: string;
  labelShrink: boolean;
  placeholder: string;
  onBlur: () => void;
  onFocus: () => void;
  onReset: () => void;
  onSetBusinessNumber: (businessNumber: string) => void;
  readOnly: boolean;
  region: REGION;
  isSearchable: boolean;
}): ReactElement => {
  if (isSearchable) {
    return (
      <SearchableEntityName
        businessNumber={businessNumber}
        currentUser={currentUser}
        entityName={entityName}
        label={label}
        name={name}
        onReset={onReset}
        onSetBusinessNumber={onSetBusinessNumber}
        placeholder={placeholder}
        readOnly={readOnly}
        region={region}
      />
    );
  }

  return (
    <RHFBorderedTextField
      control={control}
      name={name}
      label={label}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      labelShrink={labelShrink}
      readOnly={readOnly}
    />
  );
};

export default EntityName;
