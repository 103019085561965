import React from 'react';

type ModalProps = {
  children: React.ReactNode | undefined;
  footer?: React.ReactNode | undefined;
  onClose: () => void | undefined;
  title: string | undefined;
  rootClassName?: string | undefined;
  centerTitle?: boolean;
  // NOTE: added these props because with CSS module we can't have nested style classes
  // and we have to style each component inside card with a separate style class
  bodyClassNames?: string;
  headClassNames?: string;
  footClassNames?: string;
  cardClassNames?: string;
};

type HeaderCloseButtonProps = {
  onClose: () => void | undefined;
};

function HeaderCloseButton(
  props: HeaderCloseButtonProps
): React.ReactElement<HeaderCloseButtonProps> | null {
  const { onClose } = props;

  if (onClose) {
    return <button className="delete" aria-label="close" onClick={onClose} />;
  }

  return null;
}

export default function Modal(
  props: ModalProps
): React.ReactElement<ModalProps> {
  const {
    children,
    footer,
    onClose,
    title,
    rootClassName,
    centerTitle,
    bodyClassNames = '',
    headClassNames = '',
    footClassNames = '',
    cardClassNames = '',
  } = props;

  return (
    <div className={'modal is-active ' + rootClassName}>
      <div className="modal-background"></div>
      <div className={`modal-card ${cardClassNames}`}>
        <header className={`modal-card-head ${headClassNames}`}>
          <p
            className={`modal-card-title ${
              centerTitle ? 'has-text-centered' : ''
            }`}
          >
            {title}
          </p>
          <HeaderCloseButton onClose={onClose} />
        </header>
        <section className={`modal-card-body ${bodyClassNames}`}>
          {children}
        </section>
        <div className={`modal-card-foot ${footClassNames}`}>{footer}</div>
      </div>
    </div>
  );
}
