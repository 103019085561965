import React from 'react';

import styles from './css/Position.css';

var Position = createClass({
  render: function() {
    const { children, position, absolute, style } = this.props;
    var pos = styles.top_left_absolute;

    if (position && !absolute) {
      pos = styles[position];
    } else if (position && absolute) {
      pos = styles[position + '_absolute'];
    } else if (!position && absolute) {
      pos = styles['top_left_absolute'];
    }

    if (style) {
      return <div className={styles[style]}>{children}</div>;
    } else {
      return <div className={pos}>{children}</div>;
    }
  },
});

module.exports = Position;
