import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import React, { useState } from 'react';

const TierOptions = (props) => {
  const { onFetchApplication, tierOptions } = props;

  const options = [{ label: 'All', value: 'All' }];
  for (const option of tierOptions) {
    options.push({ label: option.trading_name, value: option.id });
  }

  const [selectedTier, setSelectedTier] = useState('All');

  const onChange = (selectedOption) => {
    const { value } = selectedOption;

    onFetchApplication({ supplier_id: value });
    setSelectedTier(value);
  };

  return (
    <OptionsDropdown
      label="Tier"
      required={true}
      value={selectedTier}
      options={options}
      handleChange={onChange}
    />
  );
};

export default TierOptions;
