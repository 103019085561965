import {
  GUARANTOR_LOGIN_BEGIN,
  GUARANTOR_INFO_LOAD_START,
  GUARANTOR_INFO_LOAD_SUCCESS,
  GUARANTOR_INFO_LOAD_ERROR,
  GUARANTOR_UPDATE_SUCCESS,
  GUARANTOR_SET_APPROVAL,
  GUARANTOR_SET_GUARANTORFIRSTNAME,
  GUARANTOR_SET_GUARANTORLASTNAME,
  GUARANTOR_SET_REVIEW_INDEX,
  GUARANTOR_IDENTIFICATION_SUBMIT_START,
  GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS,
  GUARANTOR_IDENTIFICATION_SUBMIT_ERROR,
  GUARANTOR_SHOW_WARNING,
} from './constants';

var guarantor_defaults = {
  data: null,
  loading: false,
  review_container_index: null,
  guarantor_firstname: '',
  guarantor_lastname: '',
};

export function guarantorReducer(state = guarantor_defaults, action) {
  switch (action.type) {
    case GUARANTOR_LOGIN_BEGIN:
      return { ...state, loading: true };

    case GUARANTOR_INFO_LOAD_START:
      return { ...state, loading: true };

    case GUARANTOR_INFO_LOAD_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case GUARANTOR_SET_REVIEW_INDEX:
      return { ...state, review_container_index: action.payload };

    case GUARANTOR_SET_APPROVAL:
      return { ...state, approved: action.payload };

    case GUARANTOR_SET_GUARANTORFIRSTNAME:
      return { ...state, guarantor_firstname: action.payload };

    case GUARANTOR_SET_GUARANTORLASTNAME:
      return { ...state, guarantor_lastname: action.payload };

    case GUARANTOR_IDENTIFICATION_SUBMIT_START:
      return { ...state, submitting: true };

    case GUARANTOR_IDENTIFICATION_SUBMIT_SUCCESS:
      return { ...state, submitting: false };

    case GUARANTOR_IDENTIFICATION_SUBMIT_ERROR:
      return { ...state, submitting: false };

    case GUARANTOR_SHOW_WARNING:
      return { ...state, warning: action.payload };
    default:
      return { ...state };
  }
}
