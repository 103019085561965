export const CREDIT_SAMPLE_PAYLOAD = `{
  "data": {
    "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "attributes": {
      "application_status": "Accepted",
      "application_type": "Credit",
      "approved_discount": 10,
      "approved_limit": 500000,
      "approver_name": "Joe Blogs",
      "approver_notes": "Application approved",
      "authorisations": [
        {
          "email": "john.doe@email.com",
          "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
          "person": {
            "first_name": "John",
            "last_name": "Doe"
          },
          "roles": {
            "cardholder": {
              "agreed": "Yes",
              "contact_phone_number": "xxx-xxxx"
            }
          },
          "signature": {
            "date_signed": "2019-01-01T10:10:00.000Z",
            "date_of_birth": "1960-01-01",
            "identification": {
              "driver_licence_number": "AB1234",
              "driver_licence_version": "123",
              "identification_type": "Driver Licence"
            },
            "ip_address": "127.0.0.1",
            "location": "Auckland, New Zealand",
            "physical_address": "130 Saint Georges Bay Rd, Parnell, Auckland, 1052",
            "timezone": "Pacific/Auckland"
          }
        },
        {
          "email": "ken.tucky@email.com",
          "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
          "person": {
            "first_name": "Ken",
            "last_name": "Tucky"
          },
          "roles": {
            "applicant": {
              "agreed": "Yes"
            },
            "cardholder": {
              "agreed": "Yes",
              "contact_phone_number": "xxx-xxxx"
            },
            "payment": {
              "agreed": "Yes",
              "bank_account_number": "xx-xxxx-xxxxxxx-xx",
              "debit_account_name": "My bank account"
            }
          },
          "signature": {
            "date_signed": "2019-01-01T10:10:00.000Z",
            "date_of_birth": "1990-01-01",
            "identification": {
              "driver_licence_number": "CD456",
              "driver_licence_version": "456",
              "identification_type": "Driver Licence"
            },
            "ip_address": "127.0.0.1",
            "location": "Auckland, New Zealand",
            "physical_address": "Auckland, New Zealand",
            "timezone": "Pacific/Auckland"
          }
        }
      ],
      "branch_name": "N/A",
      "business_location": "New Zealand",
      "business_phone_number": "xxx-xxxx",
      "consumer_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      "currency": "NZD",
      "date_account_opened": "2019-01-01T10:10:00.000Z",
      "delivery_instruction": "Please drop packages at Gate 2",
      "entity_name": "Rugby Club",
      "entity_type": "Club",
      "incorporation_number": "xxxxxxxxxx",
      "number_of_staff": 100,
      "physical_address": "125 Saint Georges Bay Rd, Parnell, Auckland, 1052",
      "postal_address": "125 Saint Georges Bay Rd, Parnell, Auckland, 1052",
      "requested_limit": 550000,
      "trade_references": [
        {
          "business_name": "Awesome Corp",
          "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
          "person": {
            "first_name": "Jane",
            "last_name": "Doe"
          },
          "phone_number": "xxx-xxxx"
        }
      ],
      "years_in_operation": 20
    }
  }
}`;

export const CASH_SAMPLE_PAYLOAD = `{
  "data": {
    "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
    "attributes": {
      "application_status": "Accepted",
      "application_type": "Cash",
      "approver_name": "Joe Blogs",
      "approver_notes": "Application approved",
      "authorisations": [
        {
          "email": "ken.tucky@email.com",
          "id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
          "person": {
            "first_name": "Ken",
            "last_name": "Tucky"
          },
          "roles": {
            "applicant": {
              "agreed": "Yes"
            }
          },
          "signature": {
            "date_signed": "2019-01-01T10:10:00.000Z",
            "ip_address": "127.0.0.1",
            "location": "Auckland, New Zealand",
            "timezone": "Pacific/Auckland"
          }
        }
      ],
      "branch_name": "N/A",
      "business_location": "New Zealand",
      "business_phone_number": "xxx-xxxx",
      "consumer_id": "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
      "currency": "NZD",
      "date_account_opened": "2019-01-01T10:10:00.000Z",
      "delivery_instruction": "Please drop packages at Gate 2",
      "entity_name": "Rugby Club",
      "entity_type": "Club",
      "incorporation_number": "xxxxxxxxxx",
      "physical_address": "125 Saint Georges Bay Rd, Parnell, Auckland, 1052",
      "postal_address": "125 Saint Georges Bay Rd, Parnell, Auckland, 1052",
      "years_in_operation": 20
    }
  }
}`;
