import React, { ReactElement } from 'react';
import { Wrapper } from 'modules/consumer-onboarding/v2/ButtonGroupWrapper/style';

const ButtonGroupWrapper = (props): ReactElement => (
  <Wrapper className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    {props.children}
  </Wrapper>
);

export default ButtonGroupWrapper;
