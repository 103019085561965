export function downloadCSV(data, filename) {
  let csv = convertArrayOfObjectsToCSV({
    data: data,
  });
  const link = document.createElement('a');
  link.href = `data:attachment/csv;charset=utf-8,${window.encodeURIComponent(
    csv
  )}`;
  link.target = '_blank';
  link.download = filename;
  document.body.appendChild(link);
  link.click();
}

function convertArrayOfObjectsToCSV(args) {
  let result;
  let ctr;
  let keys;
  let columnDelimiter;
  let lineDelimiter;
  let data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function(item) {
    ctr = 0;
    keys.forEach(function(key) {
      if (ctr > 0) result += columnDelimiter;
      let value = item[key] ? item[key].toString().replace(/,/g, '') : '';
      result += value;
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}
