import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { defaultTimeZone } from 'utils/dateFormatter';

const DEFAULT_FORMAT = 'YYYY-MM-DD';

const PERIODS_OPTIONS = [
  {
    label: 'All',
    period: {
      from: moment('2016-11-25').format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'All',
  },
  {
    label: 'Today',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Today',
  },
  {
    label: 'Yesterday',
    period: {
      from: moment()
        .subtract(1, 'days')
        .format(DEFAULT_FORMAT),
      to: moment()
        .subtract(1, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Yesterday',
  },
  {
    label: 'This week',
    period: {
      from: moment()
        .startOf('week')
        .format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'This week',
  },
  {
    label: 'Last 7 days',
    period: {
      from: moment()
        .subtract(7, 'days')
        .format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Last 7 days',
  },
  {
    label: 'Last 30 days',
    period: {
      from: moment()
        .subtract(30, 'days')
        .format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Last 30 days',
  },
  {
    label: 'Last 60 days',
    period: {
      from: moment()
        .subtract(60, 'days')
        .format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Last 60 days',
  },
  {
    label: 'Last 90 days',
    period: {
      from: moment()
        .subtract(90, 'days')
        .format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Last 90 days',
  },
  {
    label: 'Custom date range',
    period: {
      from: moment('2016-11-25').format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Custom date range',
  },
];

const FUTURE_PERIODS_OPTIONS = [
  {
    label: 'All',
    period: {
      from: null,
      to: null,
    },
    value: 'All',
  },
  {
    label: 'Today',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment().format(DEFAULT_FORMAT),
    },
    value: 'Today',
  },
  {
    label: 'Tomorrow',
    period: {
      from: moment()
        .add(1, 'days')
        .format(DEFAULT_FORMAT),
      to: moment()
        .add(1, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Yesterday',
  },
  {
    label: 'This week',
    period: {
      from: moment()
        .startOf('week')
        .format(DEFAULT_FORMAT),
      to: moment()
        .endOf('week')
        .format(DEFAULT_FORMAT),
    },
    value: 'This week',
  },
  {
    label: 'Next 7 days',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment()
        .add(7, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Next 7 days',
  },
  {
    label: 'Next 30 days',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment()
        .add(30, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Next 30 days',
  },
  {
    label: 'Next 60 days',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment()
        .add(60, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Next 60 days',
  },
  {
    label: 'Next 90 days',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment()
        .add(90, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Next 90 days',
  },
  {
    label: 'Custom date range',
    period: {
      from: moment().format(DEFAULT_FORMAT),
      to: moment()
        .add(90, 'days')
        .format(DEFAULT_FORMAT),
    },
    value: 'Custom date range',
  },
];

function CustomPeriodFilter(props) {
  const { isVisible, label, period, updatePeriod, periodValue } = props;

  if (!isVisible) {
    return null;
  }

  const onChangeDateFrom = (value) => {
    const newPeriod = {
      value: periodValue,
      ...period,
      ...{ from: moment(value).format(DEFAULT_FORMAT) },
    };
    updatePeriod(newPeriod);
  };
  const onChangeDateTo = (value) => {
    const newPeriod = {
      value: periodValue,
      ...period,
      ...{ to: moment(value).format(DEFAULT_FORMAT) },
    };
    updatePeriod(newPeriod);
  };

  return (
    <div>
      <BorderedCalendarPicker
        name="from"
        label={`${label} from`}
        value={moment(period.from)}
        onChange={onChangeDateFrom}
        disableReset
        maxDate={moment(period.to).toDate()}
      />
      <BorderedCalendarPicker
        name="to"
        label={`${label} to`}
        value={moment(period.to)}
        onChange={onChangeDateTo}
        disableReset
        minDate={moment(period.from).toDate()}
      />
    </div>
  );
}

export default function BorderedPeriodSelect(props) {
  const {
    label,
    periodValue,
    periodFromToValues,
    handleChange,
    isFutureDate,
    ...selectProps
  } = props;

  const [period, setPeriod] = useState(periodFromToValues);

  const options = isFutureDate ? FUTURE_PERIODS_OPTIONS : PERIODS_OPTIONS;

  const updatePeriod = (keyValues) => {
    setPeriod(keyValues);
    handleChange(keyValues);
  };

  const onChangePeriodValue = (event) => {
    const selectedOption = options.find(
      (option) => option.value === event.target.value
    );
    const { period, value } = selectedOption;
    const keyValues = period;
    keyValues.value = value;
    setPeriod(period);
    handleChange(keyValues);
  };

  return (
    <Fragment>
      <BorderedSelect
        label={label}
        value={periodValue}
        options={options}
        onChange={onChangePeriodValue}
        {...selectProps}
      />
      <CustomPeriodFilter
        isVisible={periodValue === 'Custom date range'}
        period={period}
        periodValue={periodValue}
        updatePeriod={updatePeriod}
        label={label}
      />
    </Fragment>
  );
}
