import { SET, SET_CARD, RESET } from './constants';

var initialState = {
  card: {
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    focused: null,
  },
  errorText: '',
  errorType: '',
  token: '',
  submitDisabled: true,
  totalCost: null,
  numUsers: null,
  cards: [],
  updateCard: false,
  subscription: null,
  transactions: [],
  showSuccessCard: false,
  invoices: [],
};

export function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, ...action.payload };
    case SET_CARD:
      return { ...state, card: { ...state.card, ...action.payload } };
    case RESET:
      return { ...initialState };
    default:
      return { ...state };
  }
}
