export const REGION_TEXT =
  'Allow Business entities from the selected countries below to onboard with you.';

export const CHECKBOX_REGION_OPTIONS = Object.freeze({
  AU: { label: 'Australia' },
  NZ: { label: 'New Zealand' },
});

export const WEBSITE_BUTTON = 'website_button';
export const QR_CODE = 'qr_code';

const applicationLabel = Object.freeze({
  [WEBSITE_BUTTON]: 'website channel',
  [QR_CODE]: 'location channel',
});

export const applicationTypeLabel = (addonModuleName) =>
  `Apply ${applicationLabel[addonModuleName]} to the application types below.`;