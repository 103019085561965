import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import ApplicationModel from 'models/ApplicationModel';
import IUFApprovalModel from 'models/IUFApprovalModel';
import LeadModel from 'models/LeadModel';
import UserEntityLinkModel from 'models/UserEntityLinkModel';
import UserModel from 'models/UserModel';
import formatUsers, {
  IFormattedUser,
} from 'modules/new-applications/utils/formatUsers';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Owner, ownerType } from '../IUFEdit/useProcessedAddonAnswers';

type SubmitType = 'tag' | 'approve';

type HandleSubmitType = (submitType: SubmitType) => void;

export interface IApplicationApproverState {
  approvalNotes: string;
  currentUserIsSelectedApprover: boolean;
  handleSubmit: HandleSubmitType;
  handleApproverChange: (_: any, value: IFormattedUser) => void;
  formattedUsers: IFormattedUser[];
  isApproving: boolean;
  isTagging: boolean;
  isIUFApproved: boolean;
  onFetchApprovers: () => void;
  error?: string;
  savedApprover: IFormattedUser | null;
  selectedApprover: IFormattedUser | null;
  setApprovalNotes: Dispatch<SetStateAction<string>>;
}

type Props = {
  application: ApplicationModel;
  owner: LeadModel | ApplicationModel;
  currentUser: UserModel;
  dispatch?: any;
  onFetchApplicationRecord: () => void;
};

function getOrInitData(owner: Owner): IUFApprovalModel {
  const iufApproval = get(owner, 'iufApproval');
  if (!iufApproval.id) {
    return new IUFApprovalModel({
      attributes: {
        owner_id: owner.id,
        owner_type: ownerType(owner),
      },
    });
  }

  return iufApproval;
}

function useIUFApproverState({
  currentUser,
  dispatch,
  onFetchApplicationRecord,
  owner,
}: Props): IApplicationApproverState {
  const entityId = get(currentUser, 'currentEntity.id');
  const iufApproval = getOrInitData(owner);
  const iufApproverId = get(iufApproval, 'approverId');
  const [users, setUsers] = useState([]);
  const [savedApprover, setSavedApprover] = useState<IFormattedUser | null>(
    null
  );
  const [
    selectedApprover,
    setSelectedApprover,
  ] = useState<IFormattedUser | null>(null);
  const [approvalNotes, setApprovalNotes] = useState<string>('');
  const [error, setError] = useState<string | undefined>();
  const currentUserIsSelectedApprover =
    !!savedApprover && savedApprover.value === currentUser.id;
  const isIUFApproved = !!get(iufApproval, 'approvedAt');
  const [loading, setIsLoading] = useState<SubmitType | null>(null);

  const onFetchApprovers = () => {
    (async () => {
      const ownerTypes = {
        applications: 'Application',
        leads: 'Lead',
      };

      const users = await UserEntityLinkModel.fetchIUFApprovers({
        entityId,
        ownerId: owner.id,
        ownerType: ownerTypes[owner.modelType],
      });

      setUsers(users);

      const formattedUsers = formatUsers(users);
      const iufApprover = formattedUsers.find(
        ({ value }) => value === iufApproverId
      );

      if (!iufApprover) {
        return;
      }

      setSavedApprover(iufApprover);
      setSelectedApprover(iufApprover);
    })();
  };

  useEffect(onFetchApprovers, []);
  useEffect(onFetchApprovers, [owner]);

  function onErrorCallback() {
    onFetchApplicationRecord();
    setIsLoading(null);
    setError(
      'Oops! Something went wrong, please try again or contact us if the problem persists'
    );
  }

  function onSuccessCallback(submitType?: SubmitType) {
    onFetchApplicationRecord();
    setIsLoading(null);

    if (submitType !== 'approve') {
      return;
    }

    mixpanel.track('IUF approved', {
      'Entity ID': entityId,
      Ruleset: owner.iufAddonRule.addonVersion,
      distinct_id: get(currentUser, 'id'),
    });
  }

  function handleSubmit(submitType: SubmitType) {
    const params = {
      onErrorCallback,
      onSuccessCallback: () => onSuccessCallback(submitType),
    };
    setIsLoading(submitType);
    setError('');

    if (!selectedApprover) {
      return;
    }

    if (
      submitType === 'tag' &&
      savedApprover &&
      savedApprover.value === selectedApprover.value
    ) {
      setIsLoading(null);
      return setError('Selected approver has already been tagged.');
    }

    iufApproval.setAttribute('approver_id', get(selectedApprover, 'value'));

    if (submitType === 'approve') {
      iufApproval.setAttribute('approved_at', moment.utc().format());
      iufApproval.setAttribute(
        'approval_notes',
        approvalNotes && approvalNotes
      );
    }

    if (iufApproval.id) {
      iufApproval.update(params);
    } else {
      iufApproval.create(params);
    }
  }

  function handleApproverChange(_, value) {
    setSelectedApprover(value);
  }

  return {
    approvalNotes,
    currentUserIsSelectedApprover,
    error,
    formattedUsers: formatUsers(users),
    handleApproverChange,
    handleSubmit,
    isApproving: loading === 'approve',
    isIUFApproved,
    isTagging: loading === 'tag',
    onFetchApprovers,
    savedApprover,
    selectedApprover,
    setApprovalNotes,
  };
}

export default useIUFApproverState;
