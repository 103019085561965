/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

/* Import CSS */
import styles from './css/Business.css';

/* Import actions */
import { manualSetCompany } from '../../actions/onboarding';

/* Import components */
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import TextInput from 'modules/shared/components/inputs/TextInput';
import IllustrationDoor from 'modules/shared/components/svg/IllustrationDoor';
import DateField from 'modules/shared/components/widgets/interactive/DateField';

/* Helpers */
import {
  isValidBusinessNumber,
  isValidCompanyNumber,
} from 'utils/companyAndBusinessNumberValidator';

var BusinessCompanyDetails = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { handleComplete, section } = this.props;
    handleComplete(this.isComplete(nextProps.form_values), section);
    if (nextProps.page_validation_start) {
      this.checkValidAll();
    }
  },

  checkValidAll: function() {
    const {
      form_inputs,
      form_values,
      setPageValidationStartFinish,
    } = this.props;

    Object.keys(form_inputs).forEach((value, index) => {
      let target = {
        id: value,
        value: form_values[value],
      };
      setTimeout(() => {
        this.checkValid(target);
      }, 500);
    });
    setPageValidationStartFinish();
  },

  isComplete: function(form_values) {
    const {
      entity_type,
      consumer_account,
      entity_region,
      form_inputs,
    } = this.props;
    if (!form_values) {
      var { form_values } = this.props;
    }
    if (
      entity_type === 'company' &&
      (moment(
        form_values.registered_at.month +
          '/' +
          form_values.registered_at.day +
          '/' +
          form_values.registered_at.year,
        'MM/DD/YYYY'
      ).format('YYYY-MM-DD') === 'Invalid date' ||
        form_values.registered_at.month.length < 1 ||
        form_values.registered_at.month == 0 ||
        form_values.registered_at.day.length !== 2 ||
        form_values.registered_at.day == '00' ||
        form_values.registered_at.year.length !== 4 ||
        form_values.registered_at.year < new Date().getFullYear() - 200 ||
        form_values.registered_at.year > new Date().getFullYear())
    ) {
      return false;
    }

    if (
      form_values.company_number.length < 1 &&
      form_inputs.company_number.required
    ) {
      return false;
    }
    if (
      form_values.company_number.length > 0 &&
      !isValidCompanyNumber(form_values.company_number, entity_region)
    ) {
      return false;
    }

    if (
      form_values.business_number.length < 1 &&
      form_inputs.business_number.required
    ) {
      return false;
    }
    if (
      form_values.business_number.length > 0 &&
      !isValidBusinessNumber(form_values.business_number, entity_region)
    ) {
      return false;
    }

    return true;
  },

  componentDidMount: function() {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete());
  },

  handleChange: function(event) {
    const { dispatch } = this.props;
    if (
      event.target.name === 'company_number' ||
      event.target.name === 'business_number'
    ) {
      dispatch(manualSetCompany(event.target.name, event.target.value.trim()));
    } else {
      dispatch(manualSetCompany(event.target.name, event.target.value));
    }
  },

  handleBlur: function(event) {
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target);
    }
  },

  checkValid: function(target) {
    const { form_values, entity_region, form_inputs } = this.props;
    const { form_errors } = this.state;
    switch (target.id) {
      case 'company_number':
        if (form_inputs[target.id].required && target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              company_number: 'You must enter your company number',
            },
          });
        } else {
          const { company_number, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }

        if (target.value.length > 0) {
          if (isValidCompanyNumber(target.value, entity_region)) {
            const { company_number, ...rest } = this.state.form_errors;
            this.setState({
              form_errors: { ...rest },
            });
          } else {
            this.setState({
              form_errors: {
                ...form_errors,
                company_number: 'You must enter a valid number without spaces',
              },
            });
          }
        }

        break;
      case 'business_number':
        if (form_inputs[target.id].required && target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              business_number: 'You must enter your business number',
            },
          });
        } else {
          const { business_number, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }

        if (target.value.length > 0) {
          if (isValidBusinessNumber(target.value, entity_region)) {
            const { business_number, ...rest } = this.state.form_errors;
            this.setState({
              form_errors: { ...rest },
            });
          } else {
            this.setState({
              form_errors: {
                ...form_errors,
                business_number: 'You must enter a valid number without spaces',
              },
            });
          }
        }

        break;

      case 'registered_at':
        switch (target.field) {
          case 'month':
            if (target.value.month.length < 1 || target.value.month == '0') {
              var registered_at_errors = form_errors.registered_at || {};
              var {
                month,
                main,
                ...registered_at_errors
              } = registered_at_errors;
              this.setState({
                form_errors: {
                  ...form_errors,
                  registered_at: {
                    ...registered_at_errors,
                    month: 'Invalid month',
                  },
                },
              });
            } else {
              const { registered_at, ...rest } = form_errors;
              const { month, main, ...registered_at_errors } =
                registered_at || {};
              this.setState({
                form_errors: {
                  ...rest,
                  registered_at: { ...registered_at_errors },
                },
              });
            }
            break;
          case 'day':
            if (target.value.day.length !== 2 || target.value.day == '00') {
              var registered_at_errors = form_errors.registered_at || {};
              var { day, main, ...registered_at_errors } = registered_at_errors;
              this.setState({
                form_errors: {
                  ...form_errors,
                  registered_at: {
                    ...registered_at_errors,
                    day: 'Invalid day',
                  },
                },
              });
            } else {
              const { registered_at, ...rest } = form_errors;
              const { day, main, ...registered_at_errors } =
                registered_at || {};
              this.setState({
                form_errors: {
                  ...rest,
                  registered_at: { ...registered_at_errors },
                },
              });
            }
            break;
          case 'year':
            if (
              target.value.year.length !== 4 ||
              target.value.year < new Date().getFullYear() - 200 ||
              target.value.year > new Date().getFullYear()
            ) {
              var registered_at_errors = form_errors.registered_at || {};
              var {
                year,
                main,
                ...registered_at_errors
              } = registered_at_errors;
              this.setState({
                form_errors: {
                  ...form_errors,
                  registered_at: {
                    ...registered_at_errors,
                    year: 'Invalid year',
                  },
                },
              });
            } else {
              const { registered_at, ...rest } = form_errors;
              const { year, main, ...registered_at_errors } =
                registered_at || {};
              this.setState({
                form_errors: {
                  ...rest,
                  registered_at: { ...registered_at_errors },
                },
              });
            }
            break;
          default:
            this.getDateErrors(target.value);
            break;
        }
        break;
    }
  },

  getDateErrors: function(date) {
    const days_in_month = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const { day, month, year } = date || { day: '00', month: 0, year: 0 };

    const { form_errors } = this.state;

    var errors = {};

    if (month.length < 1 || month == 0) {
      errors = { ...errors, month: 'Invalid month' };
    }

    if (day.length !== 2 || day == '00') {
      errors = { ...errors, day: 'Invalid day' };
    }

    if (
      year.length !== 4 ||
      year < new Date().getFullYear() - 200 ||
      year > new Date().getFullYear()
    ) {
      errors = { ...errors, year: 'Invalid year' };
    }

    this.setState({
      form_errors: { ...form_errors, registered_at: errors },
    });
    //return Object.keys(errors).length !== 0 ? errors : null;
  },

  getInitialState: function() {
    return {
      form_errors: {},
    };
  },

  render: function() {
    const {
      title,
      entity_type,
      form_inputs,
      form_values,
      entity_region,
    } = this.props;
    const { form_errors } = this.state;

    let help;
    var left_elements = [];
    var right_elements = [];
    Object.keys(form_inputs).forEach((value, index) => {
      var form_elements = [];
      switch (value) {
        case 'registered_at':
          if (entity_type === 'company') {
            form_elements.push(
              <div className={styles.left_col}>
                <div className={styles.form}>
                  <h3 style={{ marginBottom: '1em' }}>
                    {form_inputs[value].label}
                  </h3>
                  <DateField
                    key={index}
                    id={value}
                    name={value}
                    form_values={form_values[value]}
                    errors={form_errors[value]}
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                  />
                </div>
              </div>
            );
          }
          break;
        default:
          form_elements.push(
            <div className={styles.left_col}>
              <div className={styles.form}>
                <TextInput
                  key={index}
                  id={value}
                  type={form_inputs[value].type}
                  error={form_errors[value]}
                  handleChange={this.handleChange}
                  handleBlur={this.handleBlur}
                  label={form_inputs[value].label}
                  required={form_inputs[value].required}
                  value={form_values[value]}
                />
              </div>
            </div>
          );
          break;
      }
      left_elements.push(form_elements);
    });

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.full_col}>
            <PanelTitle text={title} margin_bottom="1em" />
          </div>
        </div>
        <div className={styles.row}>{left_elements}</div>
        {help}
      </section>
    );
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
var defaults = {
  title: 'Now for some easy ones!',
  form_inputs: {
    company_number: {
      label: 'Company number',
      required: true,
      type: 'tel',
    },
    business_number: {
      label: 'Business number',
      required: true,
      type: 'tel',
    },
    registered_at: {
      label: 'Business start date',
      required: true,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  if (state.cob_business.entity_region == 'AU') {
    defaults.form_inputs.company_number.label = 'ACN';
    defaults.form_inputs.business_number.label = 'ABN';

    switch (state.cob_business.entity_type) {
      case 'company':
        defaults.form_inputs.company_number.required = true;
        defaults.form_inputs.business_number.required = false;
        break;
      case 'partnership':
      case 'trust':
      case 'sole_trader':
      case 'education':
      case 'society':
      case 'association':
      case 'club':
      case 'government':
      case 'other':
        defaults.form_inputs.company_number.required = false;
        defaults.form_inputs.business_number.required = true;
        break;
      case 'personal':
        defaults.form_inputs.company_number.required = false;
        defaults.form_inputs.business_number.required = false;
        break;
      default:
        defaults.form_inputs.company_number.required = true;
        defaults.form_inputs.business_number.required = true;
        break;
    }
  }

  return {
    title: defaults.title,
    entity_type: state.cob_business.entity_type,
    form_inputs: defaults.form_inputs,
    form_values: {
      company_number: state.cob_business.company_details.company_number || '',
      business_number: state.cob_business.company_details.business_number || '',
      registered_at: state.cob_business.company_details.registered_at || {
        month: '',
        day: '',
        year: '',
      },
    },
    entity_region: state.cob_business.entity_region,
  };
})(BusinessCompanyDetails);
