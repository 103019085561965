import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React from 'react';

const getPinLabels = (region) => {
  if (region === 'AU') {
    return { header: 'token', label: 'Token' };
  }

  return { header: 'PIN', label: 'PIN' };
};

export function FinancingStatementPin(props) {
  const { actionText, errors, region, register } = props;

  const pinLabel = getPinLabels(region);

  return (
    <GridContent>
      <div>
        <div className="mb-4 has-text-weight-normal">
          Please enter financing statement {pinLabel.header} to confirm{' '}
          {actionText}.
        </div>
        <BorderedTextField
          label={pinLabel.label}
          placeholder={pinLabel.label}
          inputRef={register}
          name="financingStatementPin"
          error={Boolean(errors.financingStatementPin)}
          helperText={get(errors, 'financingStatementPin.message', ' ')}
        />
      </div>
    </GridContent>
  );
}
