import styled from 'styled-components';
import { COLORS, CREDITWORKS_COLORS } from 'variables/theme';
import { FONT_WEIGHTS } from 'variables/typography';

export const Content = styled.div`
  margin-left: 2rem;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Score = styled.div`
  align-items: center;
  border: 2px solid ${CREDITWORKS_COLORS.blue};
  border-radius: 100%;
  color: ${CREDITWORKS_COLORS.blue};
  display: inline-flex;
  font-size: 3rem;
  font-weight: ${FONT_WEIGHTS.normal};
  height: 10rem;
  justify-content: center;
  min-width: 10rem;
  width: 10rem;
`;

export const Wrapper = styled.div`
  border: 1px solid ${COLORS.darkGrey};
  border-radius: 0.5rem;
  padding: 1rem;
`;
