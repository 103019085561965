import React, { ReactElement } from 'react';
import ApproverContent from 'modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent/ApproverContent';
import ReviewerContent from 'modules/new-applications/components/application-sections/LimitAndApprovals/ReviewContent/ReviewerContent';
import RevertDecision from 'modules/new-applications/components/application-sections/LimitAndApprovals/RevertDecision';
import UserModel from 'models/UserModel';

const ReviewContent = ({
  application,
  currentUser,
  onRevertDecisionCallback,
  onSetAlert,
  review,
  escalationId,
}: {
  application: { [key: string]: any };
  currentUser: UserModel;
  onRevertDecisionCallback: () => void;
  onSetAlert: () => void;
  review: { [key: string]: any };
  escalationId?: string;
}): ReactElement => {
  let content: ReactElement | null = null;

  if (review.isReviewerLevel) {
    content = <ReviewerContent application={application} review={review} />;
  } else {
    content = <ApproverContent application={application} review={review} />;
  }

  const mostRecentReviewAction =
    review.id === application.mostRecentActionedReview.id;
  const nonStandardUser = !currentUser.isStandard;
  const isCloned = review.isCloned;
  const notOriginallyEscalated = review.originalDecision === 'escalated';
  const disabledRevert = review.disableRevert;

  const shouldShowRevertDecision =
    mostRecentReviewAction &&
    nonStandardUser &&
    (!isCloned || notOriginallyEscalated) &&
    !disabledRevert &&
    application.isMostRecent;

  return (
    <>
      {content}
      {shouldShowRevertDecision && (
        <RevertDecision
          review={review}
          application={application}
          currentUser={currentUser}
          onRevertDecisionCallback={onRevertDecisionCallback}
          onSetAlert={onSetAlert}
          escalationId={escalationId}
        />
      )}
    </>
  );
};

export default ReviewContent;
