import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from './axios';

export default class AwaitingPermissions {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = 'awaiting_permissions';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  fetchAwaitingPermission(token: string): Promise<void> {
    return this.axios.get(`${this.path}/${token}`);
  }

  saveDecision({
    status,
    token,
  }: {
    status: string;
    token: string;
  }): Promise<void> {
    return this.axios.post(`${this.path}/decision`, {
      requestee_token: token,
      status,
    });
  }
}
