import styled from 'styled-components';
import { BORDERS } from 'variables/theme';

export const Section = styled.section`
  > .columns:not(:last-child) {
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    position: relative;

    &::after {
      border-bottom: ${BORDERS.generic};
      bottom: 0;
      content: '';
      display: inline-block;
      margin: 0 0.75rem;
      position: absolute;
      width: 100%;
    }
  }
`;
