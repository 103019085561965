import React, { useRef, useEffect, useState, MutableRefObject } from 'react';
import styled from 'styled-components';

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Clear = styled.span`
  color: var(--main-color);
  cursor: pointer;
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 160px;
  background-color: #fff;
  margin-top: 8px;
`;

const Canvas = styled.canvas`
  cursor: crosshair;
  width: 100%;
  height: 100%;
  touch-action: none;
`;

type DrawProps = {
  canvasRef: MutableRefObject<HTMLCanvasElement | null>;
  setDrawn: (flag: boolean) => void;
};

export const Draw = (props: DrawProps) => {
  const { canvasRef, setDrawn } = props;

  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [startPos, setStartPos] = useState<{ x: number; y: number } | null>(
    null
  );

  // Track if it's a click, to determine if we need to draw a dot or a path
  const [isClick, setIsClick] = useState<boolean>(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        setContext(ctx);
        resizeCanvas();

        const handleResize = () => {
          resizeCanvas();
        };

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }
    }
    return undefined;
  }, []);

  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      canvas.width = rect.width;
      canvas.height = rect.height;
      const ctx = context;
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  const startDrawing = (
    e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>
  ) => {
    setDrawn(true);
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const x =
        'touches' in e
          ? e.touches[0].clientX - rect.left
          : e.clientX - rect.left;
      const y =
        'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

      // Store the starting position
      setStartPos({ x, y });
      setIsDrawing(true);
      setIsClick(true); // Reset to true when starting
    }
  };

  const endDrawing = () => {
    if (startPos && isClick) {
      // Only draw a dot if it was a click
      drawDot(startPos.x, startPos.y);
    }
    setIsDrawing(false);
    context?.beginPath();
    setStartPos(null);
    setIsClick(true); // Reset isClick
  };

  const drawDot = (x: number, y: number) => {
    if (context) {
      context.beginPath();
      context.arc(x, y, 1, 0, Math.PI * 2); // Change the radius to adjust the dot size
      context.fillStyle = 'black'; // Change color if needed
      context.fill();
      context.closePath();
    }
  };

  const draw = (
    e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>
  ) => {
    if (!isDrawing || !context) return;

    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const x =
        'touches' in e
          ? e.touches[0].clientX - rect.left
          : e.clientX - rect.left;
      const y =
        'touches' in e ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

      // Check if the movement is small enough to be considered a click
      const distance = Math.sqrt(
        Math.pow(x - (startPos?.x || 0), 2) +
          Math.pow(y - (startPos?.y || 0), 2)
      );

      if (distance > 5) {
        // If moved beyond threshold, set isClick to false
        setIsClick(false);
        context.lineTo(x, y);
        context.stroke();
        context.beginPath();
        context.moveTo(x, y);
      }
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) =>
    startDrawing(e);
  const handleMouseUp = () => endDrawing();
  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => draw(e);

  const handleTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) =>
    startDrawing(e);
  const handleTouchEnd = () => endDrawing();
  const handleTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => draw(e);

  const saveImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'drawing.png';
      link.click();
    }
  };

  const clearCanvas = () => {
    setDrawn(false);
    const canvas = canvasRef.current;
    if (canvas && context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  return (
    <>
      <ControlsContainer>
        <span>Draw your signature</span>
        <Clear onClick={clearCanvas}>Clear</Clear>
      </ControlsContainer>
      <CanvasContainer>
        <Canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        />
      </CanvasContainer>
    </>
  );
};
