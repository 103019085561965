import React, { useEffect, useRef, useState } from 'react';
import { DialogContentText } from 'mui-latest';
import styled from 'styled-components';
// @ts-ignore
import { browserHistory } from 'react-router';

import Button from 'modules/shared/components/inputs/Button';
import Modal from './Modal';

const StyledContentText = styled(DialogContentText)`
  &.MuiDialogContentText-root {
    font-size: 12px;
    color: black;
    margin-bottom: 6px;
  }
`;

const ActionsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: 30,
});

const ExitConfirmation = (): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const nextRouteRef = useRef<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (location) => {
      const { pathname, search } = location;
      const searchParams = new URLSearchParams(search);
      const activeTab = searchParams.get('active_tab');
      if (nextRouteRef.current) {
        return true;
      }
      if (
        pathname !== '/dashboard/addons' ||
        activeTab !== 'auto_decisioning'
      ) {
        const nextRoute = `${pathname}${search}`;
        nextRouteRef.current = nextRoute;
        setOpen(true);
        return false;
      }
      return true;
    };
    const unlisten = browserHistory.listenBefore(handleRouteChange);
    return () => {
      unlisten();
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleConfirmNavigateAway = () => {
    browserHistory.push(nextRouteRef.current);
  };

  const handleCancelNavigateAway = () => {
    nextRouteRef.current = null;
    setOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Setup incomplete"
      onClose={handleCancelNavigateAway}
    >
      <StyledContentText className="mb-5">
        The ruleset is not completed. Your progress won't be saved if you leave
        this page now.
      </StyledContentText>
      <ActionsContainer>
        <Button onClick={handleCancelNavigateAway} text="Stay on page" />
        <Button onClick={handleConfirmNavigateAway} text="Leave page" red />
      </ActionsContainer>
    </Modal>
  );
};

export default ExitConfirmation;
