import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './css/BlueModal.css';

import Button from 'modules/shared/components/inputs/Button';
import SectionTitle from 'modules/shared/components/widgets/static/SectionTitle';
import SectionBadge from 'modules/shared/components/widgets/static/SectionBadge';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import WorkflowOverlay from 'modules/shared/components/top/WorkflowOverlay';

var BlueModal = createClass({
  render: function() {
    const {
      dispatch,
      section_title,
      section_description,
      icon,
      hide,
    } = this.props;

    return (
      <section className={styles.section}>
        <main className={styles.centered}>
          <div>
            <div className={styles.row}>
              <div className={styles.panel}>
                <SectionTitle white={true} text={section_title} />
                <SectionBadge icon={icon} state="section_complete" />
              </div>
            </div>
            <div className={styles.description_row}>
              <div className={styles.description}>
                <SectionDescription text={section_description} />
                <div className={styles.button_outer}>
                  <Button
                    css_style="button_white_outer"
                    text="OK"
                    handleClick={hide}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    );
  },
});

export default BlueModal;
