import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';

import Modal from 'modules/shared/components/v2/Modal';
import Button from 'modules/shared/components/inputs/Button';

import './confirmationmodal.scss';

const ConfirmationModal = ({
  isActive,
  companyName,
  showModal,
  cancelCloseModal,
  confirmModal,
  type,
  pricingPlan,
}) => {
  const TYPES = {
    'website_button': 'Website Channel',
    'qr_code': 'Location Channel',
    'call_centre': 'Call Centre Channel',
  };

  const additionalActivationText = () => {
    switch (type) {
      case 'website_button':
      case 'qr_code':
        return 'This will attract a monthly per user cost per your plan.';
      default:
        return '';
    }
  };

  const appendText = () => {
    return isActive && pricingPlan !== 0 ? additionalActivationText() : '';
  };

  const onOffLabel = isActive ? 'on' : 'off';
  if (!showModal) return null;

  return (
    <Modal
      rootClassName="confirmation-modal"
      children={`Please confirm you're turning ${onOffLabel} the ${
        TYPES[type]
      } for ${companyName}. ${appendText()}`}
      onClose={cancelCloseModal}
      title={`Confirm turning ${onOffLabel} ${TYPES[type]}`}
      footer={
        <div className="modal-button-container">
          <Button type="button" text="Confirm" onClick={confirmModal} />

          <Button
            css_style="default"
            text="Cancel"
            onClick={cancelCloseModal}
          />
        </div>
      }
    />
  );
};

export default connect((state) => {
  const pricingPlan = get(
    state,
    'pricing_plans.current_pricing_plan.attributes.plan_no',
    1
  );

  return {
    pricingPlan,
  };
})(ConfirmationModal);
