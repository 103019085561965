import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import AMLFile from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v1/AMLCheckOutcome/AMLFile';
import SignatureDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/SignatureDetails';
import getIdentificationCheckOutcomeDescription from 'modules/new-applications/components/application-sections/IdentificationCheck/utils/getIdentificationCheckOutcomeDescription';
import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { Fragment } from 'react';

const idVerificationTypes = {
  driver_licence: {
    label: 'Driver licence verification',
    source: 'NZTADriverLicence',
  },
  passport: {
    label: 'Passport verification',
    source: 'DIAPassport',
  },
};

const getVerificationStatus = (identificationCheck, identification) => {
  const dataSourceMatchResult = get(
    identificationCheck,
    'data.data_sets.smart_id.data_source_match_results.data_source_match_result'
  );

  if (!dataSourceMatchResult) {
    return null;
  }

  const idVerificationKey = Object.keys(dataSourceMatchResult).filter(
    (key) =>
      dataSourceMatchResult[key].data_source_name === identification.source
  )[0];
  const idVerification = dataSourceMatchResult[idVerificationKey];

  return !Object.keys(idVerification).some(
    (key) => idVerification[key] === 'NoMatch'
  );
};

const getAmlOutcomeData = (identificationCheck) => {
  if (identificationCheck.isCentrixAml) {
    const smartId = get(identificationCheck, 'data.data_sets.smart_id', {});
    return {
      address_verified: getIdentificationCheckOutcomeDescription(
        get(smartId, 'is_address_verified', false)
      ),
      aml_verified: getIdentificationCheckOutcomeDescription(
        get(smartId, 'is_verified', false)
      ),
      dob_verified: getIdentificationCheckOutcomeDescription(
        get(smartId, 'is_date_of_birth_verified', false)
      ),
      is_aml: true,
      name_verified: getIdentificationCheckOutcomeDescription(
        get(smartId, 'is_name_verified', false)
      ),
    };
  }
  return identificationCheck.formattedDetails || {};
};

const getIdentityOutcomeData = (identificationCheck, idType) => {
  if (identificationCheck.isCentrixAml) {
    return getIdentificationCheckOutcomeDescription(
      getVerificationStatus(identificationCheck, idVerificationTypes[idType])
    );
  }
  return get(
    identificationCheck.formattedDetails,
    `${idType}_verified`,
    'Fail'
  );
};

const AMLCheckOutcome = (props) => {
  const { authorisation, identificationCheck, signature } = props;

  const identificationCheckData = getAmlOutcomeData(identificationCheck);
  const result = identificationCheckData.aml_verified;
  const color = result === 'Pass' ? 'green' : 'red';
  const idType = get(authorisation, 'signature.attributes.identification_type');
  const identification = idVerificationTypes[idType];

  const authenticityScore = get(
    signature,
    'attributes.formatted_scraped_details.authenticity_score'
  );

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">AML outcome</span>:
        <span style={{ color: `${color}` }}>{` ${result}`}</span>
      </div>

      {identification && (
        <SignatureDetails
          label={identification.label}
          content={
            getIdentityOutcomeData(identificationCheck, idType) || 'Fail'
          }
        />
      )}
      <SignatureDetails
        label="Name verification"
        content={identificationCheckData.name_verified || 'Fail'}
      />
      <SignatureDetails
        label="DOB verification"
        content={identificationCheckData.dob_verified || 'Fail'}
      />
      <SignatureDetails
        label="Address verification"
        content={identificationCheckData.address_verified || 'Fail'}
      />
      <SignatureDetails
        label="PEP watchlist"
        content={identificationCheck.amlPEPWatchlistOutcome}
      />
      <AMLFile identificationCheck={identificationCheck} />
    </Fragment>
  );
};

export default AMLCheckOutcome;
