import api from 'api';
import get from 'lodash.get';

interface IDeescalationReview {
  escalationId: string | null;
  notes: string | null;
  onSuccessCallback?: () => void;
  onFailCallback?: () => void;
}

interface IEscalationReview {
  selectedUserEscalationId: string | null;
  notes: string | null;
  onSuccessCallback?: () => void;
  onFailCallback?: () => void;
}

interface IReviewEscalations {
  escalateReview: ({
    notes,
    selectedUserEscalationId,
  }: IEscalationReview) => void;
  deescalateReview: ({ escalationId, notes }: IDeescalationReview) => void;
}

const useReviewEscalations = ({
  reviewId,
  currentUser,
}: {
  reviewId: string;
  currentUser: any;
}): IReviewEscalations => {
  const accessToken = currentUser.accessToken;
  const entityId = get(currentUser, 'currentEntity.id');
  const reviewEscalationApi = api('review_escalations', accessToken, entityId);

  const escalateReview = ({
    notes,
    selectedUserEscalationId,
    onSuccessCallback,
    onFailCallback,
  }: IEscalationReview) => {
    reviewEscalationApi
      .escalate(reviewId, notes, selectedUserEscalationId)
      .then(() => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      })
      .catch((e) => {
        console.error(e);
        if (onFailCallback) {
          onFailCallback();
        }
      });
  };

  const deescalateReview = ({
    escalationId,
    notes,
    onSuccessCallback,
    onFailCallback,
  }: IDeescalationReview) => {
    reviewEscalationApi
      .deescalate(reviewId, escalationId, notes)
      .then(() => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      })
      .catch((e) => {
        console.error(e);
        if (onFailCallback) {
          onFailCallback();
        }
      });
  };

  return { escalateReview, deescalateReview };
};

export default useReviewEscalations;
