import React, { ReactElement } from 'react';
import { Column } from '@ant-design/charts';
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getThemeColors } from '../../Panel/utils';

function RealTimeLagTime(): ReactElement {
  const data = [
    {
      name: '1CAH',
      value: 30,
      process: 'Application initiated',
    },
    {
      name: '1CAH',
      value: 30,
      process: 'Application started',
    },
    {
      name: '1CAH',
      value: 30,
      process: 'Application complete',
    },
    {
      name: '1CAH',
      value: 30,
      process: 'L1 approval = 9min',
    },
    {
      name: '1CAH',
      value: 30,
      process: 'L2 approval = 12min',
    },
  ];

  const config = {
    data,
    height: 225,
    autoFit: true,
    isStack: true,
    xField: 'name',
    yField: 'value',
    isGroup: true,
    seriesField: 'process',
    groupField: 'name',
    columnStyle: {
      lineOpacity: 0,
    },
  };

  return (
    <div>
      <Column
        {...config}
        xAxis={{
          grid: {
            line: {
              style: {
                stroke: 'transparent',
              },
            },
          },
          label: {
            style: {
              opacity: 0,
            },
          },
        }}
        isStack
        legend={false}
        color={getThemeColors()}
      />
    </div>
  );
}

export default RealTimeLagTime;
