import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import ApplicationModel from 'models/ApplicationModel';

function useIUFState(owner) {
  const { iufAddonAnswers, legalType } = owner;
  const isApplicationAnswersEmpty = isEmpty(iufAddonAnswers);
  const isCompany = legalType === 'company';
  const noAnswer = isApplicationAnswersEmpty;

  function isIUFCompleted(): boolean {
    if (isApplicationAnswersEmpty) {
      return false;
    }

    const iufAnswers = [iufAddonAnswers];

    if (owner instanceof ApplicationModel) {
      // @ts-ignore-next-line
      const { applicationTradingNameLinks, iufAddonRule } = owner;
      const isTradingNameIUFEnabled = get(
        iufAddonRule,
        'config.trading_entity.enabled',
        false
      );

      let tradingNameIUFAddonAnswers = [];
      if (
        isTradingNameIUFEnabled &&
        isCompany &&
        applicationTradingNameLinks.length > 0
      ) {
        tradingNameIUFAddonAnswers = applicationTradingNameLinks.map(
          ({ addonAnswer }) => addonAnswer
        );
      }

      const isTradingNamesAnswerEmpty = !tradingNameIUFAddonAnswers.some(
        ({ answers }) => !!answers
      );

      if (isCompany && isTradingNameIUFEnabled && isTradingNamesAnswerEmpty) {
        return false;
      }

      iufAnswers.push(...tradingNameIUFAddonAnswers);
    }

    return iufAnswers.every(({ status }) => status === 'completed');
  }

  return {
    isCompany,
    isIUFCompleted: isIUFCompleted(),
    noAnswer,
  };
}

export default useIUFState;
