import { Skeleton } from '@material-ui/lab';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';
import React from 'react';

function TableListSkeleton(): React.ReactElement {
  return (
    <React.Fragment>
      {arrayFromNumber(10, (number) => (
        <Skeleton
          key={`reporting-list-skeleton-${number}`}
          animation="wave"
          className="mb-4"
          height={25}
          variant="rect"
        />
      ))}
    </React.Fragment>
  );
}

export default TableListSkeleton;
