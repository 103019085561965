/* Import libraries */
import React from 'react';
import { Link } from 'react-router';

/* Import styles */
import styles from './css/CloseButton.css';

function CloseButton(props) {
  const { link, text, type, css_class, handleClick, theme, ...rest } = props;
  let style = styles.button;

  if (theme) {
    style = styles[theme];
  }

  if (css_class) {
    style = styles[css_class];
  }

  if (handleClick) {
    return (
      <button type="button" className={style} onClick={handleClick} {...rest}>
        {text}
        <div className={styles.cross}></div>
      </button>
    );
  }
  if (type === 'submit' || type === 'button') {
    return (
      <button className={style} type={type} {...rest}>
        {text}
        <div className={styles.cross}></div>
      </button>
    );
  }
  return (
    <Link className={style} to={link} {...rest}>
      {text}
    </Link>
  );
}

export default CloseButton;
