import ExistingPasswordForm from 'modules/consumer-onboarding/v2/Verification/PasswordForm/ExistingPasswordForm';
import NewPasswordForm from 'modules/consumer-onboarding/v2/Verification/PasswordForm/NewPasswordForm';
import React, { ReactElement, useContext } from 'react';
import ColumnWrapper from 'modules/consumer-onboarding/v2/ColumnWrapper';
import get from 'lodash.get';
import isBlank from 'utils/isBlank';
import { UserContext } from 'modules/consumer-onboarding/v2/hooks/UserContext';

const PasswordForm = (props): ReactElement | null => {
  const { router } = props;
  const searchQueries = get(router, 'location.search');
  const redirectPath = `/onboarding/application-details${searchQueries}`;

  const userState = useContext(UserContext);
  const { registrationStatus, userId } = userState;

  if (isBlank(registrationStatus)) {
    return null;
  }

  let content: ReactElement | null = null;
  if (registrationStatus === 'existing') {
    content = (
      <ExistingPasswordForm
        redirectPath={redirectPath}
        userId={userId}
        userState={userState}
        {...props}
      />
    );
  } else {
    content = (
      <NewPasswordForm
        redirectPath={redirectPath}
        userId={userId}
        userState={userState}
        {...props}
      />
    );
  }

  return <ColumnWrapper>{content}</ColumnWrapper>;
};

export default PasswordForm;
