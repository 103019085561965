import './website-channel-content.scss';

import HistoryPanelSection from 'containers/next-gen/components/shared/HistorySection';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import AddonConfigModel from 'models/AddonConfigModel';
import UserModel from 'models/UserModel';
import {
  createAddonVersion,
  loadWebsiteAddon,
  updateAddonRuleset,
} from 'modules/addons/actions';
import CheckboxGroup from 'modules/addons/sales_channels/shared/CheckboxGroup';
import ConfirmationModal from 'modules/addons/sales_channels/shared/ConfirmationModal';
import EmbeddedComponents from 'modules/addons/sales_channels/shared/EmbeddedComponents';
import getAccountTypeCheckboxOptions from 'modules/addons/sales_channels/shared/getAccountTypeCheckboxOptions';
import Button from 'modules/shared/components/inputs/Button';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import {
  CHECKBOX_REGION_OPTIONS,
  REGION_TEXT,
  WEBSITE_BUTTON,
  applicationTypeLabel,
} from '../shared/constants';
import {
  ApplicationRegions,
  ApplicationTypes,
  AddonAttributes,
  NewChanges,
} from '../shared/types';

const addon_module_name = WEBSITE_BUTTON;
const applicationText = applicationTypeLabel(addon_module_name);

const instructionsText = `Turning on your website channel will enable you to embed the below link onto any customised image on your website,
which once clicked will take your customer to your application workflow. Embedding the 'Button Widget' will display
the preset 'Start application' per the below page.`;

const instructionSet = {
  main: 'Follow the below instructions to set up your website channel.',
  steps: [
    'Copy the relevant embed link from the box below',
    "Log into your website editor, find the section where you can edit your site's HTML",
    'Paste the link into the relevant section for your website, save and publish your changes',
    'Refresh your website',
  ],
};

function initializeAddonModule() {
  return new AddonConfigModel({
    attributes: { addon_module_name },
  });
}

function getNewVersionAttribute(
  addonConfig: any,
  applicationTypes: ApplicationTypes,
  config: any = {}
) {
  const attributes: AddonAttributes = {
    application_types: applicationTypes,
    config: config,
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = 'Website button';
    attributes.addon_module_name = addon_module_name;
  }

  return attributes;
}

const WebsiteChannelContent = ({
  applicationType,
  companyName,
  currentUser,
  dispatch,
  entityId,
  isReadonly,
  logo,
  websiteButtonActive,
  websiteButtonData,
  websiteButtonHistory,
  websiteButtonRegions,
}) => {
  const processedCurrentUser = UserModel.fromCurrentUser(currentUser);
  const [addonConfig, setAddonConfig] = React.useState(initializeAddonModule());
  const checkboxApplicationTypeOptions = useMemo(
    getAccountTypeCheckboxOptions,
    []
  );

  const trackMixPanel = (eventName) => {
    mixpanel.track(eventName, {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  };

  useEffect(() => {
    setAddonConfig(websiteButtonData);
  }, [websiteButtonData]);

  useEffect(() => {
    trackMixPanel('Sales channels - Website channel');

    dispatch(loadWebsiteAddon());
  }, []);

  const websiteButtonLink = `${window.location.origin}/onboarding?supplier_id=${entityId}`;

  const [withChanges, setWithChanges] = React.useState(false);
  const [active, setActive] = React.useState(websiteButtonActive);
  const [newChanges, setNewChanges] = React.useState({} as NewChanges);
  const [showModal, setShowModal] = React.useState(false);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  useEffect(() => {
    setActive(websiteButtonActive);
  }, [websiteButtonActive]);

  const onErrorCallback = (error) => {
    console.error(error);
  };

  const reloadData = () => {
    // We could remove this if we could make the response of the other two api calls above same with this.
    dispatch(loadWebsiteAddon());

    setWithChanges(false);
    setShowModal(false);
  };

  const saveChanges = (applicationTypeParam?: any, regionsParam?: any) => {
    const newVersion = addonConfig.generateNewVersion();
    const defaultRegions = { config: { regions: regionsParam } } || {};
    const newVersionApplicationTypes =
      applicationTypeParam || newChanges.application_types || applicationType;
    const newVersionConfig = newChanges.config
      ? newChanges.config
      : defaultRegions;

    const attributes = getNewVersionAttribute(
      addonConfig,
      newVersionApplicationTypes,
      newVersionConfig
    );
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser: processedCurrentUser,
      onErrorCallback,
      onSuccessCallback: reloadData,
    });
  };

  const handleSwitchChange = (newValue) => {
    trackMixPanel(
      `Sales channels - Website channel - Toggle ${newValue ? 'on' : 'off'}`
    );
    setActive(newValue);
    setWithChanges(true);
    setShowModal(true);
  };

  const confirmModal = () => {
    const updateCheckbox = () => {
      // We need to force the following values to enable/disable the form
      if (active) {
        saveChanges(['credit'], websiteButtonRegions);
      } else {
        saveChanges([], websiteButtonRegions);
      }
    };

    if (websiteButtonData.id) {
      dispatch(
        updateAddonRuleset(
          websiteButtonData.id,
          websiteButtonData.attributes,
          addon_module_name,
          updateCheckbox
        )
      );
    } else {
      dispatch(
        createAddonVersion(
          getNewVersionAttribute(initializeAddonModule(), ['credit']),
          addon_module_name,
          reloadData
        )
      );
    }

    trackMixPanel('Sales channels - Website channel - Saved changes');
  };

  const cancelCloseModal = () => {
    setActive(!active);
    setWithChanges(false);
    setShowModal(false);
  };

  const handleApplicationTypeChange = (newApplicationTypes) => {
    if (!newApplicationTypes.length && active) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        applicationTypes: 'At least one application type needs to be selected',
      }));
    } else {
      setErrors((currentErrors) => {
        const { applicationTypes, ...restErrors } = currentErrors;

        return restErrors;
      });
    }
    setNewChanges((changes) => ({
      ...changes,
      application_types: newApplicationTypes as ApplicationTypes,
    }));
    setWithChanges(true);
  };

  const handleRegionChange = (newRegion) => {
    if (!newRegion.length) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        regions: 'At least one country needs to be selected',
      }));
    } else {
      setErrors((currentErrors) => {
        const { regions, ...restErrors } = currentErrors;

        return restErrors;
      });
    }
    setNewChanges((changes) => ({
      ...changes,
      config: {
        regions: newRegion as ApplicationRegions,
      },
    }));
    setWithChanges(true);
  };

  return (
    <SideMenuPanel>
      <PanelSection>
        <ConfirmationModal
          companyName={companyName}
          isActive={active}
          showModal={showModal}
          cancelCloseModal={cancelCloseModal}
          confirmModal={confirmModal}
          type={addon_module_name}
        />
        {instructionsText}
        <div className="switch-container">
          <Switch3
            label={active ? 'On' : 'Off'}
            value={active}
            onClick={handleSwitchChange}
            isDisabled={isReadonly}
          />
        </div>
      </PanelSection>
      <PanelSection>
        {REGION_TEXT}
        <CheckboxGroup
          options={CHECKBOX_REGION_OPTIONS}
          passedValue={websiteButtonRegions}
          handleChange={handleRegionChange}
          disabled={isReadonly || !active}
          errorMessage={errors.regions}
        />
      </PanelSection>
      <PanelSection>
        {applicationText}
        <CheckboxGroup
          options={checkboxApplicationTypeOptions}
          passedValue={applicationType}
          handleChange={handleApplicationTypeChange}
          disabled={isReadonly || !active}
          errorMessage={errors.applicationTypes}
        />
      </PanelSection>
      {!isReadonly && (
        <>
          <PanelSection>
            {instructionSet.main}
            <ul className="integration-steps">
              {instructionSet.steps.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <div className="integration-components-container">
              <EmbeddedComponents.RawLink
                currentUser={currentUser}
                link={websiteButtonLink}
                title="Link"
              />
              <EmbeddedComponents.ButtonComponent
                currentUser={currentUser}
                link={websiteButtonLink}
                logo={logo}
                text="Start application"
                title="Button widget"
              />
            </div>
            <Button
              type="button"
              text="Save"
              disabled={
                isReadonly ||
                (!active && !withChanges) ||
                !withChanges ||
                Object.keys(errors).length
              }
              onClick={() => saveChanges()}
            />
          </PanelSection>
          <HistoryPanelSection
            historyList={websiteButtonHistory}
            historyCount={5}
          />
        </>
      )}
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');
  const companyName = get(
    currentUser,
    'current_entity.attributes.company_name'
  );
  const websiteButtonData = get(state, 'add_ons.website_button');
  const websiteButtonHistory = get(
    websiteButtonData,
    'attributes.history_version.data',
    []
  );
  const websiteButtonActive = get(
    websiteButtonData,
    'attributes.active',
    false
  );
  const applicationType = get(
    websiteButtonHistory[0],
    'attributes.application_types',
    []
  );
  const websiteButtonRegions = get(
    websiteButtonHistory[0],
    'attributes.config.regions',
    ['NZ', 'AU']
  );
  const logo = extractAttachmentUrl(
    state.current_user.current_entity.attributes.logo,
    'logo'
  );

  return {
    applicationType,
    companyName,
    currentUser,
    entityId,
    isReadonly: !isFeatureEditEnabled('WebsiteButton'),
    logo,
    websiteButtonActive,
    websiteButtonData,
    websiteButtonHistory,
    websiteButtonRegions,
  };
})(WebsiteChannelContent);
