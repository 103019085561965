import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#supplier-terms-creating-a-supplier-terms-ruleset
 */
let Signatures = function(config) {
  config.timeout = 90000;
  this.axios = axios(config);
  this.type = 'signatures';
};

Signatures.prototype.createSignatureForGuarantor = function(
  guarantorId,
  attributes,
  success,
  error
) {
  return this.createSignature(
    guarantorId,
    'guarantors',
    attributes,
    success,
    error
  );
};

Signatures.prototype.createSignatureForApplication = function(
  applicationId,
  attributes,
  success,
  error
) {
  return this.createSignature(
    applicationId,
    'applications',
    attributes,
    success,
    error
  );
};

Signatures.prototype.createSignature = function(
  signeeId,
  type,
  attributes,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          signee: {
            data: {
              type: type,
              id: signeeId,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

Signatures.prototype.updateSignature = function(
  id,
  attributes,
  success,
  error
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

Signatures.prototype.getSignature = function(success, error, options = {}) {
  return this.axios
    .get(`/${this.type}`, options)
    .then(success)
    .catch(error);
};

export default Signatures;
