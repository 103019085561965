import React, { useContext } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { browserHistory } from 'react-router';

import { CustomersContext } from '../CustomersContext';
import { MobileStatisticsBlock } from 'modules/reporting/components/Reporting';
import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';

const Container = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  div {
    padding: 10px;
    height: initial;
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  display: none;
  @media screen and (min-width: 992px) {
    display: flex;
    gap: 17px;
  }
`;

export const Statistics = () => {
  const {
    isLoading,
    meta,
    params: { status },
  } = useContext(CustomersContext);
  const { started, active, archive, limits } = meta;

  const statItems = [
    {
      name: 'Total customers',
      title: 'Total customers',
      value: started,
      color: 'blue',
      key: 'all',
    },
    {
      name: 'Active',
      title: 'Active',
      value: active,
      color: 'light-blue',
      key: 'active',
    },
    {
      name: 'Archive',
      title: 'Archive',
      value: archive,
      color: 'medium-grey',
      key: 'archive',
    },
    {
      name: 'Limits',
      title: 'Limits',
      value: Number(limits),
      color: 'blue',
      currencyMode: true,
      key: 'limits',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);

  const handleClick = (key: string) => {
    if (key === 'limits') {
      return;
    }
    urlParams.set('status', key);
    browserHistory.push(`/dashboard/customers?${urlParams}`);
  };

  return (
    <>
      <Container>
        {statItems.map((statItem) => (
          <MobileStatisticsBlock
            {...statItem}
            isLoading={isLoading}
            isCompact
            wide
            onClick={() => handleClick(statItem.key)}
            active={statItem.key === status}
          />
        ))}
      </Container>

      <DesktopContainer>
        {statItems.map((statItem) => (
          <NumApplicationsBlock
            {...statItem}
            isLoading={isLoading}
            isCompact
            wide
            onClick={() => handleClick(statItem.key)}
            active={statItem.key === status}
          />
        ))}
      </DesktopContainer>
    </>
  );
};
