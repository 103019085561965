import React, { Fragment } from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import { FlexContent } from './People';

const BulkUploadGuarantors = ({ guarantors }) => {
  const Guarantors = guarantors.map((guarantor, index) => (
    <Fragment>
      <LabeledContent
        label={`Guarantor ${index + 1}`}
        content={guarantor.name}
      />
    </Fragment>
  ));
  return (
    <FixedContent header="Guarantors">
      <FlexContent>{Guarantors}</FlexContent>
    </FixedContent>
  );
};

const BulkUploadContact = ({ consumer }) => {
  if (!consumer) return null;
  return (
    <FixedContent header="Contact">
      <GridContent>
        <LabeledContent label="Email" content={consumer.keyContactEmail} />
        <LabeledContent
          label="Phone number"
          content={consumer.keyContactPhoneNumber}
        />
      </GridContent>
    </FixedContent>
  );
};

const BulkUploadPeople = ({
  hasBulkuploadGuarantors,
  bulkUploadGuarantors,
  consumer,
}) => {
  return (
    <Fragment>
      {hasBulkuploadGuarantors && (
        <BulkUploadGuarantors guarantors={bulkUploadGuarantors} />
      )}
      {consumer && <BulkUploadContact consumer={consumer} />}
    </Fragment>
  );
};

export default BulkUploadPeople;
