import styles from 'modules/shared/components/containers/css/ContentWithFooter.css';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React from 'react';

function containerClassName(props) {
  const { withBottomPadding, withLeftPadding } = props;
  const defaultClassNames = [styles.container];

  if (withBottomPadding) {
    defaultClassNames.push(styles.with_bottom_padding);
  }

  if (withLeftPadding) {
    defaultClassNames.push(styles.with_left_padding);
  }

  return defaultClassNames.join(' ');
}

function bodyClassName(props) {
  const { withBodyBottomPadding, withBodyRightPadding } = props;
  const defaultClassNames = [styles.body];

  if (withBodyBottomPadding) {
    defaultClassNames.push(styles.with_bottom_padding);
  }

  if (withBodyRightPadding) {
    defaultClassNames.push(styles.with_body_right_padding);
  }

  return defaultClassNames.join(' ');
}

function footerBorderClassName(props) {
  const { withFooterBorderRightMargin } = props;
  const defaultClassNames = [styles.border];

  if (withFooterBorderRightMargin) {
    defaultClassNames.push(styles.with_footer_border_right_margin);
  }

  return defaultClassNames.join(' ');
}

export default function ContentWithFooter(props) {
  const { children, footer } = props;

  return (
    <div className={containerClassName(props)}>
      <div className={bodyClassName(props)}>{children}</div>
      <div className={styles.footer}>
        <div className={styles.border_container}>
          <div className={footerBorderClassName(props)}></div>
        </div>
        <ScrollableContent>{footer}</ScrollableContent>
      </div>
    </div>
  );
}

ContentWithFooter.defaultProps = {
  withBodyBottomPadding: false,
  withBodyRightPadding: true,
  withBottomPadding: false,
  withFooterBorderRightMargin: true,
  withLeftPadding: false,
};
