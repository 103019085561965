import { Typography } from '@material-ui/core';
import React from 'react';

type AddonTitleProps = {
  children: string;
};

export const AddonTitle = ({ children }: AddonTitleProps) => (
  <Typography variant="h1">{children}</Typography>
);
