import { FEATURE_FLAGS } from 'conf';
import { MAXIMUM_RETRY_ATTEMPTS } from 'models/IdentificationCheckModel.js';
import React, { ReactElement } from 'react';

const ErrorDetails = ({
  retryAttempts,
}: {
  retryAttempts: number;
}): ReactElement => {
  let message = '';

  if (FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES) {
    message = 'Requires Manual verification as an API error happened.';
  } else {
    if (retryAttempts >= MAXIMUM_RETRY_ATTEMPTS) {
      message =
        'Due to technical issues we have been unsuccessful in verifying this ID via the appropriate agency.';
    } else {
      message = "We'll be trying again 3x over the next 24hours.";
    }
  }

  return <div>{message}</div>;
};

export default ErrorDetails;
