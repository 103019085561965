import React, { useContext } from 'react';
// @ts-ignore
import { browserHistory } from 'react-router';
import styled from 'styled-components';

import { CustomersContext } from '../CustomersContext';
import { Typography } from '@material-ui/core';

const StyledText = styled(Typography)`
  text-align: center;
`;

export const LoadMore = () => {
  const {
    meta: { total },
    params: { page, per },
  } = useContext(CustomersContext);

  const pageCount = Math.ceil(total / per);
  const isLastPage = page === pageCount;

  const urlParams = new URLSearchParams(window.location.search);

  const handleLoadMore = () => {
    if (isLastPage) {
      return;
    }
    urlParams.set('page', String(page + 1));
    urlParams.set('isMobile', 'true');
    browserHistory.push(`/dashboard/customers?${urlParams}`);
  };

  const text = isLastPage ? 'No more records' : 'Click to get more';

  return <StyledText onClick={handleLoadMore}>{text}</StyledText>;
};
