import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import MenuItem from './MenuItem';

const SortableItem = ({
  onClick,
  index,
  isActive,
  rightIconButton,
  customIndicator,
  someoneIsDragging,
  ...props
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={() => {
        if (someoneIsDragging || isDragging) {
          return;
        }

        onClick();
      }}
    >
      <MenuItem
        key={index}
        isActive={isActive}
        rightIconButton={rightIconButton}
        customIndicator={customIndicator}
        id={props.id}
        {...props}
      />
    </div>
  );
};

export default SortableItem;
