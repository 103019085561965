import DefaultSquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form-latest';

type Props = {
  control: any;
  disabled?: boolean;
  label: string;
  name: string;
  dataCy?: string;
};

function SquareCheckbox(props: Props): ReactElement {
  const { disabled, label, name, dataCy } = props;

  return (
    <Controller
      {...props}
      render={({ field: { ref, onChange, value } }) => (
        <DefaultSquareCheckbox
          handleChange={(e) => onChange(e.target.checked)}
          checkboxId={name}
          disabled={disabled}
          inputRef={ref}
          label={label}
          checked={value}
          no_float
          dataCy={dataCy}
        />
      )}
    />
  );
}

export default SquareCheckbox;
