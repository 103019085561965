import AuthorisationRequestModel, {
  RequestStatus,
} from 'models/AuthorisationRequestModel';
import RequestApproved from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/StatusInformation/RequestApproved';
import RequestRejected from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/StatusInformation/RequestRejected';
import RequestSent from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/StatusInformation/RequestSent';
import React, { ReactElement } from 'react';

const determineComponent = (requestStatus: RequestStatus) => {
  switch (requestStatus) {
    case 'approved':
      return RequestApproved;
    case 'rejected':
      return RequestRejected;
    case 'request_sent':
      return RequestSent;
    default:
      throw `AuthorisationRequestModel status: ${requestStatus} is not handled.`;
  }
};

const StatusInformation = ({
  authorisationRequest,
}: {
  authorisationRequest: AuthorisationRequestModel;
}): ReactElement | null => {
  if (authorisationRequest.status === 'not_started') {
    return null;
  }

  const Component = determineComponent(authorisationRequest.status);

  return (
    <div className="mb-6">
      <Component authorisationRequest={authorisationRequest} />
    </div>
  );
};

export default StatusInformation;
