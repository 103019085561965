/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

import styles from './css/SelectKeyContact.css';

/* Import actions */
import { updateSupplierConnectionKeyContact } from '../actions';
import { loadUsersByEntity } from 'modules/manage-users/actions';
import { publishMessage } from 'modules/notifications/actions';

/* Import components */
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import Button from 'modules/shared/components/inputs/Button';
import Loader from 'modules/shared/components/widgets/static/Loader';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';

var SelectKeyContact = createClass({
  componentWillMount: function() {
    const { dispatch } = this.props;
    dispatch(loadUsersByEntity());
  },

  notifyCounterParty: function() {
    const {
      dispatch,
      connectionId,
      ourEntityName,
      contactEntityId,
    } = this.props;
    var contactName = this.state.selectedContact.label;
    dispatch(
      publishMessage({
        text: `<strong>${contactName}</strong> has been assigned as the new key contact for your trade account with <strong>${ourEntityName}</strong>`,
        style: 'connection',
        channel: 'entity',
        channel_target: contactEntityId,
        intent: 'view_supplier_connection',
        intent_target: connectionId,
      })
    );
  },

  update: function() {
    const { dispatch, connectionId, connectionType } = this.props;

    var notify = this.state.notify;
    var contactId = this.state.selectedContact.value;
    var that = this;

    dispatch(
      updateSupplierConnectionKeyContact(connectionId, contactId, (data) => {
        // Individual contacts are notified via the API

        if (notify) {
          that.notifyCounterParty();
        }
        this.props.hide(true);
      })
    );
  },

  cancel: function() {
    const { location, connectionId } = this.props;
    this.props.hide();
  },

  getInitialState: function() {
    const sc = this.props.supplierContact;
    return {
      selectedContact: { value: sc.id, label: sc.attributes.full_name },
      notify: false,
      showLoader: true,
    };
  },

  handleChange: function(v) {
    this.setState({ selectedContact: v });
  },

  toggleNotify: function(v) {
    if (this.state.notify) {
      this.setState({ notify: false });
    } else {
      this.setState({ notify: true });
    }
  },

  loadingComplete: function() {
    this.setState({
      showLoader: false,
    });
  },

  render: function() {
    const {
      title,
      button_label,
      loading,
      contacts,
      connectionType,
    } = this.props;
    const { loadingComplete } = this.state;

    let toggle_state = styles.default;

    if (loadingComplete) {
      return (
        <div className={styles.backdrop}>
          <Loader loader={loader} handleComplete={this.loadingComplete} />
        </div>
      );
    }

    if (this.state.notify) {
      toggle_state = styles.active;
    }

    return (
      <CircleModal title={title} dismissHandler={this.cancel}>
        <div className={styles.fieldset}>
          <OptionsDropdown
            handleChange={this.handleChange}
            label="Select new key contact"
            value={this.state.selectedContact}
            required={true}
            options={contacts}
          />

          <div className={toggle_state} onClick={this.toggleNotify}>
            {`inform ${connectionType} of change?`}
          </div>
        </div>
        <div className={styles.button}>
          <Button text={button_label} handleClick={this.update} />
        </div>
      </CircleModal>
    );
  },
});

var defaults = {
  title: 'Select a new key contact?',
  button_label: 'assign key contact',
};

module.exports = connect((state, ownProps) => {
  let currentUserId = state.current_user.data.data.id,
    connectionId = ownProps.connectionId,
    contacts = state.manage_users.user_list_for_assign_key_contact || [];

  contacts = contacts.map((v) => {
    return {
      value: v.id,
      label: v.attributes.full_name,
    };
  });

  let mapping = state.mapping,
    c = ownProps.connection,
    connectionType = c.relation_type || 'consumer',
    ourEntityName = ownProps.supplierEntity.attributes.trading_name,
    contactEntityId = ownProps.consumerEntity.id,
    loading = contacts.length < 1 || state.manage_users.user_list_loading;

  return {
    title: defaults.title,
    button_label: defaults.button_label,
    currentUserId,
    connectionId,
    loading,
    contacts,
    connectionType,
    ourEntityName,
    contactEntityId,
  };
})(SelectKeyContact);
