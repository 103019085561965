import React, { useEffect, useState } from 'react';
import logo from 'images/logo-equifax.png';
import get from 'lodash.get';

import styles from './shared/report-styles.css';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import ReportHeader from './shared/ReportHeader';
import ReportSection from './shared/ReportSection';
import ReportScore from './shared/ReportScore';
import ReportHeadline from './shared/ReportHeadline';
import ReportEnquiriesDefaults from './shared/ReportEnquiriesDefaults';
import ReportInsolvenciesCourtActions from './shared/ReportInsolvenciesCourtActions';
import ReportBusinessRelationships from './shared/ReportBusinessRelationships';
import ReportIdentity from './shared/ReportIdentity';
import ReportCreditInquiries from './shared/ReportCreditInquiries';
import ReportDictatorship from './shared/ReportDictatorship';
import ReportFileNotes from './shared/ReportFileNotes';
import ReportPdf from './shared/ReportPdf';
import ReportDefaultAccounts from './shared/ReportDefaultAccounts';

const ErrorPage = ({ type, data, mainHeader }) => {
  let message = '';

  switch (type) {
    case 'fault':
      {
        const faultString = get(data, 'fault.faultstring');
        const faultError = get(data, 'fault.detail.error.description');
        message = faultError || faultString;
      }
      break;
    case 'errors':
      {
        message = get(data, 'response.errors.error.fault_string');
      }
      break;
  }

  return (
    <div>
      <ReviewContainer
        subHeading={mainHeader}
        content_class="content_wide"
        css_class="block_noborder"
        fontSize="large"
      >
        <p>{message}</p>
        <p>
          Contact <a href="mailto:support@1centre.com">support@1centre.com</a>{' '}
          or live chat with us.
        </p>
      </ReviewContainer>
    </div>
  );
};

const parseData = (data) => {
  let productHeader = get(data, 'response.product_header', {}),
    productData = get(data, 'response.product_data', {}),
    scoreData = get(productData, 'score_data', {}),
    enquiryReport = get(productData, 'enquiry_report', {}),
    creditEnquiriesCommercial = get(
      enquiryReport,
      'commercial_credit_file.credit_enquiries',
      {}
    ),
    creditEnquiryCommercial = get(
      creditEnquiriesCommercial,
      'credit_enquiry',
      {}
    ),
    creditEnquiriesConsumer = get(
      enquiryReport,
      'consumer_credit_file.credit_enquiries',
      {}
    ),
    creditEnquiryConsumer = get(creditEnquiriesConsumer, 'credit_enquiry', {}),
    primaryMatch = get(enquiryReport, 'primary_match', {}),
    individualName = get(primaryMatch, 'individual_name', {}),
    employment = get(enquiryReport, 'employment', {}),
    employer = get(employment, 'employer', {}),
    employerList = Array.isArray(employer) ? employer : [employer],
    addresses = get(enquiryReport, 'addresses', {}),
    address = get(addresses, 'address', []),
    characteristic = get(
      productData,
      'summary_data.data_block.characteristic',
      []
    ),
    directorships = get(
      enquiryReport,
      'public_record_or_consumer_credit_information.directorships',
      {}
    ),
    directorship = get(directorships, 'directorship', []),
    disqualifiedDirectorships = get(
      enquiryReport,
      'public_record_or_consumer_credit_information.disqualified_directorships',
      {}
    ),
    disqualifiedDirectorship = get(
      disqualifiedDirectorships,
      'disqualified_directorship',
      []
    ),
    businessNames = get(
      enquiryReport,
      'public_record_or_consumer_credit_information.business_names',
      {}
    ),
    businessName = get(businessNames, 'business_name', []),
    consumerAccountsEnquiry = get(
      enquiryReport,
      'consumer_credit_file.accounts.account',
      []
    ),
    consumerAccounts = Array.isArray(consumerAccountsEnquiry)
      ? consumerAccountsEnquiry
      : [consumerAccountsEnquiry],
    commercialAccountsEnquiry = get(
      enquiryReport,
      'commercial_credit_file.accounts.account',
      []
    ),
    commercialAccounts = Array.isArray(commercialAccountsEnquiry)
      ? commercialAccountsEnquiry
      : [commercialAccountsEnquiry];

  return {
    productHeader,
    productData,
    scoreData,
    enquiryReport,
    creditEnquiriesCommercial,
    creditEnquiryCommercial,
    creditEnquiriesConsumer,
    creditEnquiryConsumer,
    primaryMatch,
    individualName,
    addresses,
    address,
    characteristic,
    directorships,
    directorship,
    employment,
    employer,
    employerList,
    businessNames,
    businessName,
    consumerAccounts,
    commercialAccounts,
    disqualifiedDirectorships,
    disqualifiedDirectorship,
  };
};

const EquifaxPersonalReport = (props) => {
  const { data, mainHeader, pdf, signed_pdf } = props;

  const [reportData, setReportData] = useState<any>();

  useEffect(() => {
    setReportData(parseData(data));
  }, [data]);

  const hasParsedData = !!Object.keys(reportData || {}).length;

  if (!data || !data.response || !hasParsedData) {
    if (data && data.fault) {
      return <ErrorPage type="fault" data={data} mainHeader={mainHeader} />;
    }
    return null;
  } else if (data.response.errors) {
    return <ErrorPage type="errors" data={data} mainHeader={mainHeader} />;
  }

  return (
    <div className="pt-6">
      <img
        className={styles.veda_logo}
        src={logo}
        alt="Equifax"
        width="277.9"
        height="79.1"
      />
      <h2 className={styles.heading_large}>{mainHeader}</h2>
      <ReportSection fontSize="default">
        <ReportHeader {...reportData} />
      </ReportSection>
      <ReportSection sectionHeader="Summary">
        <ReportScore
          productData={get(reportData, 'productData')}
          scoreData={get(reportData, 'scoreData')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Headlines">
        <ReportHeadline characteristic={get(reportData, 'characteristic')} />
      </ReportSection>
      <ReportSection sectionHeader="Credit enquiries &amp; defaults">
        <ReportEnquiriesDefaults
          enquiryType="commercial"
          characteristic={get(reportData, 'characteristic')}
        />
        <ReportEnquiriesDefaults
          enquiryType="consumer"
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Personal insolvencies &amp; court actions">
        <ReportInsolvenciesCourtActions
          insolvencyType="personal"
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Business relationships">
        <ReportBusinessRelationships
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Identity details">
        <ReportIdentity {...reportData} />
      </ReportSection>
      <ReportSection sectionHeader="Credit enquiries">
        <ReportCreditInquiries
          creditEnquiries={get(reportData, 'creditEnquiryCommercial')}
          creditEnquiry={get(reportData, 'creditEnquiryCommercial')}
          inquiryType={'Commercial'}
        />
        <ReportFileNotes characteristic={get(reportData, 'characteristic')} />
        <ReportCreditInquiries
          creditEnquiries={get(reportData, 'creditEnquiryConsumer')}
          creditEnquiry={get(reportData, 'creditEnquiryConsumer')}
          inquiryType={'Consumer'}
        />
      </ReportSection>
      <ReportSection sectionHeader="Insolvencies &amp; court actions">
        <ReportInsolvenciesCourtActions
          characteristic={get(reportData, 'characteristic')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Defaults &amp; CCPs">
        <ReportDefaultAccounts
          type="commercial"
          accounts={get(reportData, 'commercialAccounts')}
        />
        <ReportDefaultAccounts
          type="consumer"
          accounts={get(reportData, 'consumerAccounts')}
        />
      </ReportSection>
      <ReportSection sectionHeader="Business relationships">
        <ReportDictatorship
          characteristic={get(reportData, 'characteristic')}
          directorship={get(reportData, 'directorship')}
          disqualifiedDirectorship={get(reportData, 'disqualifiedDirectorship')}
          businessName={get(reportData, 'businessName')}
        />
      </ReportSection>
      <ReportSection>
        <ReportPdf pdf={pdf} signed_pdf={signed_pdf} />
      </ReportSection>
    </div>
  );
};

export default EquifaxPersonalReport;
