import Tabs from 'modules/shared/components/widgets/interactive/Tabs';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import React from 'react';

import styles from './components/css/commonStyles.css';

type ConnectedAppsProps = {
  children: JSX.Element;
};

const ConnectedApps = (props: ConnectedAppsProps) => {
  if (location.pathname.includes('rapid-transfer')) {
    window.location.replace('webhooks');
    return null;
  }

  const tabs = [
    {
      path: '/webhooks',
      title: 'Webhooks',
    },
  ];

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <AdminInnerTitle text={'Integrations'} isNormalFont={true} />
      </div>
      <Tabs
        tab_list={tabs}
        base_path="/dashboard/connected-apps"
        active_path={location.pathname}
      />
      {props.children}
    </section>
  );
};

export default ConnectedApps;
