import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { get } from 'lodash';
import AddonRuleModel from 'models/AddonRuleModel';
import AddonVersionModel from 'models/AddonVersionModel';
import ApplicationModel from 'models/ApplicationModel';
import ApplicationTradingNameLinkModel from 'models/ApplicationTradingNameLinkModel';
import TradingNameModel from 'models/TradingNameModel';
import {
  createAddonVersion,
  resetCurrentAddonRuleset,
} from 'modules/addons/actions';
import AddOnsNotSavePopup from 'modules/addons/addons_form/components/AddOnsNotSavePopup';
import PreviewSaveButtons from 'modules/addons/addons_form/components/PreviewSaveButtons';
import styles from 'modules/addons/components/css/AddOnsDetails.css';
import BorderedTextField from 'modules/profile/components/AppValuesApprovalLevels/ReactHookFormInputs/BorderedTextField';

import SquareCheckbox from 'modules/profile/components/AppValuesApprovalLevels/ReactHookFormInputs/SquareCheckbox';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import AccountTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/AccountTypeSelectDropdown';
import EntityTypeSelectDropdown from 'modules/shared/components/v2/Form/SelectDropdown/EntityTypeSelectDropdown';
import FormBuilder from 'modules/shared/components/widgets/interactive/form_builder/FormBuilder';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
import getSchema from './schema';
import { Props } from './types';

const ADDON_NAME = 'internal_use_fields';

const MandatoryOptions = [
  { label: 'Mandatory', value: true },
  { label: 'Optional', value: false },
];

function InternalUseFieldsDetails(props: Props) {
  const {
    currentData,
    dispatch,
    handleDiscard,
    internalUseFields,
    readOnly,
    reloadAddon,
    updating,
  } = props;

  const [showDirtyModal, setShowDirtyModal] = useState(false);

  const defaultValues = currentData
    ? currentData.attributes
    : {
        addon_module_name: ADDON_NAME,
        application_types: [],
        legal_types: [],
        config: {
          account_level: {
            attachment: {
              active: false,
              mandatory: false,
              question: '',
            },
            components: [],
          },
          trading_entity: {
            enabled: false,
            components: [],
          },
          pricing: {
            approval_enabled: false,
            components: [],
          },
        },
        name: 'Internal Use Fields Ruleset',
      };

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: useYupValidationResolver(getSchema, {
      allConfigs: internalUseFields,
    }),
  });

  const values = watch();
  const currentApplicationTypes = values.application_types;
  const hasError = Object.keys(errors).length > 0;
  const selectedCashOrCredit = ['credit', 'cash'].some((applicationType) =>
    currentApplicationTypes.includes(applicationType)
  );
  const selectedCompany = values.legal_types.includes('company');
  const attachmentActive = get(
    values,
    'config.account_level.attachment.active',
    false
  );

  useEffect(() => {
    return () => {
      dispatch(resetCurrentAddonRuleset(ADDON_NAME));
    };
  }, []);

  function handleSelectDropdownChange(
    e: { value: string[] },
    type: 'application_types' | 'legal_types'
  ) {
    setValue(type, e.value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }

  useEffect(() => {
    const isApprovalEnabled = get(values, 'config.pricing.approval_enabled');
    if (typeof isApprovalEnabled === 'boolean' && !isApprovalEnabled) {
      setValue('config.pricing.approval_required', false);
    }
  }, [values.config.pricing.approval_enabled]);

  function handleAttachmentMandatoryChange(value: string) {
    setValue('config.account_level.attachment.mandatory', value === 'true');
  }

  function handleFormBuilderChange(
    params,
    field: 'account_level' | 'trading_entity' | 'pricing'
  ) {
    setValue(`config.${field}.components`, params, { shouldDirty: true });
  }

  function submit(data) {
    dispatch(
      createAddonVersion(data, ADDON_NAME, () => {
        handleDiscard();
        reloadAddon();
      })
    );
  }

  function discard() {
    if (isDirty) {
      return setShowDirtyModal(true);
    }

    handleDiscard();
  }

  function getError(fieldName) {
    return get(errors, fieldName, { message: '' }).message;
  }

  const formLabelOverridesProps = {
    mandatory: 'Make this question mandatory before application approval',
  };

  const formBuilderProps = {
    formLabelOverrides: formLabelOverridesProps,
    extraFields: {},
    editable: !readOnly,
  };

  if (selectedCashOrCredit) {
    formBuilderProps['extraFields'] = {
      search_send_applied: false,
      search_send_mandatory: false,
    };
  }

  function getPreviewApplicationData(): ApplicationModel {
    const applicationTradingNameLinks = arrayFromNumber(
      2,
      (_, index) =>
        new ApplicationTradingNameLinkModel({
          attributes: {
            id: index,
            tradingNameId: index,
          },
        })
    );

    const consumerTradingNames = arrayFromNumber(
      2,
      (_, index) =>
        new TradingNameModel({
          attributes: {
            id: index,
            tradingName: `Trading name ${index + 1}`,
          },
        })
    );

    const addonVersion = new AddonVersionModel({
      attributes: {
        config: values.config,
      },
    });

    const iufAddonRule = new AddonRuleModel({
      attributes: {
        addon_module_name: 'internal_use_fields',
        addonVersion,
      },
    });

    const attributes = {
      iufAddonRule,
      applicationTradingNameLinks,
      consumerTradingNames,
      preview: true,
    };

    return new ApplicationModel({ attributes });
  }

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <section className={styles.container}>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <div className="column">
            <div>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    classes={{
                      input: styles.name_text_compact,
                      root: styles.input_parent,
                    }}
                    placeholder="Name your ruleset here - eg: Company"
                    disabled={readOnly}
                  />
                )}
              />
              <CloseButton handleClick={discard} />
            </div>
            <div className="mt-6">
              <ContentContainer header="Account type">
                <div className="columns pb-4">
                  <div className="column is-4">
                    <AccountTypeSelectDropdown
                      handleChange={(e) =>
                        handleSelectDropdownChange(e, 'application_types')
                      }
                      value={currentApplicationTypes}
                      multiple
                      bulkSelect
                      disabled={readOnly}
                      error={getError('application_types')}
                    />
                  </div>
                </div>
              </ContentContainer>
              {/* <ContentContainer
                header="Enable in search & send"
                description={`Enabling this step will populate your IUFs during the send application process
                  and apply to all application regardless of its entity type. If not enabled, your IUF questions
                  will populate in the credit file for completion by the account owner.`}
                visible={selectedCashOrCredit}
              >
                <SquareCheckbox
                  control={control}
                  name="config.search_send_enabled"
                  label="Enable in search & send"
                  disabled={readOnly}
                />
              </ContentContainer> */}
              <ContentContainer
                header="Entity type"
                // description={
                //   values.config.search_send_enabled &&
                //   `
                //   Internal use fields will be applied across all entity types through search and send.
                // `
                // }
              >
                <div className="columns pb-4">
                  <div className="column is-4">
                    <EntityTypeSelectDropdown
                      handleChange={(e) =>
                        handleSelectDropdownChange(e, 'legal_types')
                      }
                      value={values.legal_types}
                      multiple
                      bulkSelect
                      disabled={readOnly}
                      error={getError('legal_types')}
                    />
                  </div>
                </div>
              </ContentContainer>
              <ContentContainer
                header="Account level IUFs"
                description="These will sit in the credit file and the additional fields can be applied before sending application.
                  The account owner will be prompted if any mandatory questions were not completed."
              >
                <SquareCheckbox
                  control={control}
                  name="config.account_level.attachment.active"
                  label="Attachment required"
                  disabled={readOnly}
                />
                {attachmentActive && (
                  <Fragment>
                    <div className="my-5">
                      <RadioGroup>
                        {MandatoryOptions.map(({ label, value }) => (
                          <FormControlLabel
                            name={'mandatory'}
                            disabled={readOnly}
                            key={`radio-${label}`}
                            value={value}
                            control={<Radio />}
                            label={<div>{label}</div>}
                            checked={
                              get(
                                values,
                                'config.account_level.attachment.mandatory',
                                false
                              ) === value
                            }
                            onChange={(e) =>
                              // @ts-ignore-next-line
                              handleAttachmentMandatoryChange(e.target.value)
                            }
                          />
                        ))}
                      </RadioGroup>
                    </div>
                    <div>
                      <BorderedTextField
                        control={control}
                        name="config.account_level.attachment.question"
                        label="Type your question/section name here."
                        readOnly={readOnly}
                      />
                    </div>
                  </Fragment>
                )}
                <div className="mt-5">
                  <FormBuilder
                    components={values.config.account_level.components}
                    module="iufAccountLevel"
                    keyNamePrefix="iuf"
                    onChange={(params) =>
                      handleFormBuilderChange(params, 'account_level')
                    }
                    borderedStyle
                    showFormTitle={false}
                    {...formBuilderProps}
                  />
                </div>
              </ContentContainer>
              <ContentContainer
                header="Trading entity IUFs"
                description="These will sit in the credit file. The account
                  owner will be prompted if any mandatory questions were not completed."
                visible={selectedCashOrCredit && selectedCompany}
              >
                <SquareCheckbox
                  control={control}
                  name="config.trading_entity.enabled"
                  label="Enabled trading entity IUFs"
                  disabled={readOnly}
                />
                {values.config.trading_entity.enabled && (
                  <div className="mt-5">
                    <FormBuilder
                      components={values.config.trading_entity.components}
                      module="iufTradingEntity"
                      keyNamePrefix="iuf"
                      onChange={(params) =>
                        handleFormBuilderChange(params, 'trading_entity')
                      }
                      borderedStyle
                      showFormTitle={false}
                      formLabelOverrides={formLabelOverridesProps}
                      editable={!readOnly}
                    />
                  </div>
                )}
              </ContentContainer>
              <ContentContainer
                header="Pricing"
                description={`Create any pricing specific questions here. You also have the ability to nominate
                  an approver of IUFs. This IUF approver can only be a Manager or Admin user, but they can also
                    be a reviewer or approver user.`}
              >
                <SquareCheckbox
                  control={control}
                  name="config.pricing.approval_enabled"
                  label="Enable approval"
                  disabled={readOnly}
                />
                {values.config.pricing.approval_enabled && (
                  <SquareCheckbox
                    control={control}
                    name="config.pricing.approval_required"
                    label="IUF approval required before application approval"
                    disabled={readOnly}
                  />
                )}
                <div className="mt-5">
                  <FormBuilder
                    components={values.config.pricing.components}
                    module="iufPricing"
                    keyNamePrefix="iuf"
                    onChange={(params) =>
                      handleFormBuilderChange(params, 'pricing')
                    }
                    borderedStyle
                    showFormTitle={false}
                    {...formBuilderProps}
                  />
                </div>
              </ContentContainer>
            </div>
          </div>
          <PreviewSaveButtons
            moduleName={ADDON_NAME}
            previewAddon={{
              application: getPreviewApplicationData(),
              section: ADDON_NAME,
            }}
            handleSubmit={() => {}}
            loading={updating}
            // TODO - Research why setValue on array doesn't
            // dirty form even with shouldDirty is set to true
            // readOnly={readOnly || !isDirty || hasError}
            readOnly={readOnly || hasError}
          />
          {showDirtyModal && (
            <AddOnsNotSavePopup
              handleLeave={handleDiscard}
              handleSave={handleSubmit(submit)}
              hidePopup={() => setShowDirtyModal(false)}
            />
          )}
        </form>
      </section>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  const addOns = state.add_ons;

  return {
    currentData: addOns.current_internal_use_fields,
    internalUseFields: addOns.internal_use_fields,
    updating: addOns.internal_use_fields_updating,
  };
})(InternalUseFieldsDetails);
