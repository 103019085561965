import 'react-table/react-table.css';

import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import Container from 'modules/applications/components/credit-checks/credit-works/v2/Container';
import SuppliersByIndustryPieChart from 'modules/applications/components/credit-checks/credit-works/v2/SuppliersByIndustryPieChart';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import moment from 'moment';
import React, { Fragment, ReactElement } from 'react';

type Props = {
  companyDetails: any;
  data: any;
  dsoSummary: any;
  sequenceDate: string;
};

function CreditDetails(props: Props): ReactElement | null {
  const { companyDetails, data, dsoSummary } = props;

  if (isEmpty(data)) {
    return null;
  }

  const {
    addresses,
    agedBalanceGroupIndustrySummaries,
    combinedCreditLimit,
    companyName,
    companyNumber,
    nzbn,
    status,
    numberOfSuppliersLastEom,
  } = data;

  const getDirectors = () => {
    const { directors } = companyDetails || {};

    if (!directors) {
      return '';
    }

    const activeDirectors = directors.filter((director) => !director.endDate);

    const names = activeDirectors.map((director) => director.name);

    return names.join(', ');
  };

  const getDSOInformation = () => {
    const creditDetails = dsoSummary.splice(-3);

    return creditDetails
      .reverse()
      .map(({ balanceMonth, dso }, index: number) => {
        const month = moment().month(balanceMonth);

        return (
          <tr key={`${dso}-${month}`}>
            <th>
              DSO {month.format('MMMM')}
              {index === 0 && ' (to date)'}
            </th>
            <td>{dso}</td>
          </tr>
        );
      });
  };

  return (
    <Container subHeading="Credit Details">
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            {data && Object.keys(data).length > 0 && (
              <Fragment>
                <tr>
                  <th>Legal Name</th>
                  <td>{companyName}</td>
                </tr>
                <tr>
                  <th>NZBN</th>
                  <td>{nzbn}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{get(addresses, 'registeredAddress.address')}</td>
                </tr>
                <tr>
                  <th>Company number</th>
                  <td>{companyNumber}</td>
                </tr>
                <tr>
                  <th>Company Status</th>
                  <td>{get(status, 'description')}</td>
                </tr>
                <tr>
                  <th>Directors</th>
                  <td>{getDirectors()}</td>
                </tr>
                <tr>
                  <th>Combined Credit Limit</th>
                  <td>
                    {parseInt(combinedCreditLimit).toLocaleString('en', {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <th>Current number of suppliers</th>
                  <td>{numberOfSuppliersLastEom}</td>
                </tr>
              </Fragment>
            )}

            {dsoSummary && getDSOInformation()}
          </tbody>
        </table>
      </div>
      <SuppliersByIndustryPieChart data={agedBalanceGroupIndustrySummaries} />
    </Container>
  );
}

export default CreditDetails;
