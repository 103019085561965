import { RoleType } from 'modules/user/components/UserProfile/types';

type UserType = {
  id: string;
  description: string;
  label: string;
  value: RoleType;
  type: string;
};

const USER_TYPES: UserType[] = [
  {
    description:
      'This user type has overall responsibility for this 1Centre account, including user management and visibility of data across all tiers. This user type has editing rights of all functionality.',
    id: 'user_type_admin',
    label: 'Administrator',
    type: 'user_type',
    value: RoleType.Admin,
  },
  {
    description:
      'This user type has visibility of all data within the tier they are in, including the ability to be given approver rights. However this user type does not have access to settings or configurations.',
    id: 'user_type_approver',
    label: 'Manager',
    type: 'user_type',
    value: RoleType.Approver,
  },
  {
    description:
      'This user type is typically your sales person who can send applications and view their own customers.',
    id: 'user_type_stardard',
    label: 'Standard',
    type: 'user_type',
    value: RoleType.Standard,
  },
  {
    description:
      'This user type can apply for trade accounts on behalf of their business.',
    id: 'user_type_consumer_manager',
    label: 'Consumer',
    type: 'user_type_consumer_manager',
    value: RoleType.ConsumerManager,
  },
];

export default USER_TYPES;
