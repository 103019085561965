export const INTERNAL_ALERTS_COLUMNS = [
  {
    Header: 'Alert',
    accessor: 'table_alert_name',
  },
  {
    Header: 'Alert date',
    accessor: 'table_alert_date',
  },
  {
    Header: 'Linked application',
    accessor: 'table_linked_application',
  },
  {
    Header: 'Tier',
    accessor: 'table_tier',
  },
  {
    Header: 'Action',
    accessor: 'table_action',
  },
];

export const EXTERNAL_ALERTS_COLUMNS = [
  {
    Header: 'Alert',
    accessor: 'table_alert_name',
  },
  {
    Header: 'Alert date',
    accessor: 'table_alert_date',
  },
  {
    Header: 'Action',
    accessor: 'table_action',
  },
];

export const ALERTS_NAME_MAP = {
  nz_company_persons_changed: 'Change of key person/s',
  au_company_persons_changed: 'Change of key person/s',
  credit_score_decrease: 'Credit score decrease',
  nz_company_deregistered: 'Deregistered',
  au_company_deregistered: 'Deregistered',
  au_company_director_mismatch: 'Director mismatch (AU)',
  nz_company_director_mismatch: 'Director mismatch (NZ)',
  au_company_gst_registered: 'GST registered (AU)',
  nz_company_gst_registered: 'GST registered (NZ)',
  au_company_insolvency: 'Insolvency',
  nz_company_insolvency: 'Insolvency',
  nz_company_liquidation: 'Liquidation (NZ)',
  au_company_liquidation: 'Liquidation/Receivership (AU)',
  au_company_receivership: 'Liquidation/Receivership (AU)',
  nz_company_receivership: 'Receivership (NZ)',
  au_company_voluntary_administration: 'Voluntary administration',
  nz_company_voluntary_administration: 'Voluntary administration',
  application_velocity: 'Application velocity',
  director_liquidated_company: 'Director from Liquidated company',
  email_age: 'Email age',
  blocked_geo_ips: 'Foreign IP addresses',
  identical_company_numbers: 'Identical ABN/ACN/NZBN/NZCN',
  identical_directors: 'Identical directors',
  identical_emails: 'Identical emails',
  identical_identifications: 'Identical IDs',
  identical_ip_addresses: 'Identical IP addresses',
  repeat_application: 'Repeat applications',
  unusual_application_times: 'Unusual application time',
  email_domains: 'Email client domain',
};

export const irregularAlerts = [
  'au_company_deregistered',
  'nz_company_deregistered',
  'au_company_insolvency',
  'nz_company_insolvency',
  'nz_company_liquidation',
  'nz_company_receivership',
  'au_company_liquidation',
  'au_company_receivership',
  'au_company_voluntary_administration',
  'nz_company_voluntary_administration',
];

export const watchingAlerts = [
  'nz_company_persons_changed',
  'au_company_persons_changed',
  'credit_score_decrease',
  'au_company_director_mismatch',
  'nz_company_director_mismatch',
  'au_company_gst_registered',
  'nz_company_gst_registered',
];
