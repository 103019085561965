import React from 'react';
import moment from 'moment';
import styles from './centrix.css';

class EquiryDetails extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  renderName(person) {
    return (
      <span className="has-text-weight-semibold is-uppercase">
        {person.surname.toUpperCase()}
        {`, ${person.first_name}`}
        {person.middle_name && ` ${person.middle_name}`}
      </span>
    );
  }

  render() {
    const { data } = this.props;
    const personal = data.data_sets.consumer_file.personal;

    return (
      <div>
        <h4 className={styles.report_name + ' mt-4'}>Consumer Credit Report</h4>
        <div style={{ marginBottom: '1em' }}>{this.renderName(personal)}</div>

        <div className="has-text-weight-medium">
          Subscriber Reference:
          <span className="has-text-grey-darker has-text-weight-medium ml-6">
            {data.entered_request_details.subscriber_reference || 'REF20201019'}
          </span>
        </div>
        <div className="has-text-weight-medium">
          Enquiry Number:
          <span
            className={
              styles.ml_enquiry + ' has-text-weight-medium has-text-grey-dark'
            }
          >
            {data.response_details.enquiry_number || '-'}
          </span>
        </div>
        <div className="has-text-weight-medium">
          Issue Date and Time:
          <span
            className={
              styles.ml_datetime + ' has-text-weight-medium has-text-grey-dark'
            }
          >
            {moment(data.response_details.enquiry_date).format(
              'DD/MM/YYYY HH:MM:SS'
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default EquiryDetails;
