import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from './axios';

export type ZendeskAuthParams = {
  user_id?: string;
  authorisation_id?: string;
};

export default class ZendeskChat {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = '';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  getAuthentication(params: ZendeskAuthParams): Promise<void> {
    return this.axios.post(`/zendesk_auth/generate_token`, null, { params });
  }
}
