import React, { ReactElement } from 'react';
import Helmet from 'react-helmet';

import PopperTooltip from '../interactive/PopperToolTip';
import styles from './css/AdminTitle.css';

interface IAdminTitle {
  children?: any;
  tooltip?: any;
  text?: string;
  white?: boolean;
  className?: string;
}

function AdminTitle({
  children,
  tooltip,
  text,
  white,
  className,
}: IAdminTitle): ReactElement {
  let textStyle = styles.title;

  if (white) {
    textStyle = styles.title_white;
  }

  return (
    <div className={styles.outer + (className ? ` ${className}` : '')}>
      <Helmet title={text} />
      <h1 className={textStyle}>
        {text}
        {tooltip && (
          <span style={{ marginLeft: '0.5rem' }}>
            {' '}
            <PopperTooltip {...tooltip}>{tooltip.element}</PopperTooltip>
          </span>
        )}
      </h1>
      {children}
    </div>
  );
}

export default AdminTitle;
