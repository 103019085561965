import Input from '@material-ui/core/Input';
import isEmpty from 'lodash.isempty';
import {
  createAddonVersion,
  loadAddons,
  resetCurrentAddonRuleset,
  setAddonAccountType,
  setAddonEntityType,
  setAddonValue,
} from 'modules/addons/actions';
import AttachDocument from 'modules/addons/addons_form/AttachDocument';
import AccountTypeCheckbox from 'modules/addons/addons_form/components/AccountTypeCheckbox/index.tsx';
import AddOnsNotSavePopup from 'modules/addons/addons_form/components/AddOnsNotSavePopup';
import styles from 'modules/addons/components/css/AddOnsDetails.css';
import {
  getConflictingRulesetNames,
  getLegalTypesError,
  getRulesetConflicts,
} from 'modules/addons/helpers';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import FormBuilder from 'modules/shared/components/widgets/interactive/form_builder/FormBuilder';
import { paginate } from 'modules/shared/helpers/additionalSectionHelper';
import React from 'react';
import { connect } from 'react-redux';

import LegalTypeCheckbox from '../../addons_form/components/LegalTypeCheckbox';
import LimitRange from '../../addons_form/components/LimitRange';
import PreviewSaveButtons from '../../addons_form/components/PreviewSaveButtons';
import { FEATURE_FLAGS } from 'conf';

class AdditionalDetails extends React.Component {
  constructor(props) {
    super(props);

    const { components, attachDocument, attach_document } =
      props.data.attributes.config;

    this.state = {
      attachDocument: attachDocument || attach_document,
      components,
      errors: {},
      notSaved: false,
      notSavedPopup: false,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCurrentAddonRuleset(addonType));
  }

  addonContentChanged() {
    this.setState({ notSaved: true });
  }

  showNotSavedPopup() {
    this.setState({ notSavedPopup: true });
  }

  hideNotSavedPopup() {
    this.setState({ notSavedPopup: false });
  }

  selectType(event) {
    const { dispatch } = this.props;
    dispatch(setAddonEntityType(event.target.id, addonType));
    this.addonContentChanged();
  }

  selectAccountType(event) {
    const { dispatch } = this.props;
    dispatch(setAddonAccountType(event.target.id, addonType));
    this.addonContentChanged();
  }

  setValue(name, value) {
    const { dispatch } = this.props;
    dispatch(setAddonValue(name, value, addonType));
    this.addonContentChanged();
  }

  componentsChange(components) {
    const { dispatch } = this.props;
    const { attachDocument } = this.state;
    this.setState({ components });
    dispatch(
      setAddonValue('config', { attachDocument, components }, addonType)
    );
    this.addonContentChanged();
  }

  attachDocumentChange(params) {
    const { dispatch } = this.props;
    const { attachDocument, components } = this.state;
    const updatedAttachDocument = {
      ...attachDocument,
      ...params,
    };
    this.setState({ attachDocument: updatedAttachDocument });
    dispatch(
      setAddonValue(
        'config',
        {
          attach_document: updatedAttachDocument,
          components,
        },
        addonType
      )
    );
  }

  handleSubmit() {
    const { data, dispatch, handleDiscard } = this.props;

    if (this.checkValidAll()) {
      dispatch(
        createAddonVersion(data.attributes, addonType, () => {
          handleDiscard();
          dispatch(loadAddons(addonType));
        })
      );
    } else {
      throw 'Paramter is invalid';
    }
  }

  handleDiscard() {
    const { handleDiscard } = this.props;
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      handleDiscard();
    }
  }

  checkValidAll() {
    let errors = {};
    const { data, additional } = this.props;
    const att = data.attributes;
    const {
      application_types,
      config,
      legal_types,
      max_credit_value,
      min_credit_value,
    } = att;
    const { attach_document } = config;

    const definedApplicationType = Boolean(application_types);
    const applicationTypeLength = application_types.length;
    const hasCreditApplication =
      definedApplicationType && application_types.includes('credit');

    if (!legal_types.length) {
      errors.legal_types = 'Please select at least one legal type';
    }
    if (!definedApplicationType || !applicationTypeLength) {
      errors.application_types = 'Please select at least one account type';
    }
    if (!config.components.length) {
      errors.components = "Content can't be empty";
    }

    if (
      attach_document &&
      attach_document.active &&
      isEmpty(attach_document.question)
    ) {
      errors.attach_document = 'Please enter your question here.';
    }

    if (hasCreditApplication) {
      if (max_credit_value === 0) {
        errors.credit_limit = 'Please select a max credit value';
      }
      if (max_credit_value < min_credit_value === 0) {
        errors.credit_limit = 'Max limit must be greater than the min limit';
      }
    }

    const conflictingRulesets = getRulesetConflicts(additional, data);
    let entityError = {};
    if (conflictingRulesets) {
      const conflictingRulesetNames =
        getConflictingRulesetNames(conflictingRulesets);
      entityError = {
        legal_types: `The selected entity types conflict with another Additional ruleset ${conflictingRulesetNames.join(
          ', '
        )}.`,
      };
    }

    const legalTypesError = getLegalTypesError(att.legal_types);
    errors = {
      ...errors,
      ...legalTypesError,
      ...entityError,
    };

    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  RenderMandatory() {
    const items = this.state.components.map(
      ({ field_description, mandatory }) => {
        if (mandatory) {
          return <p key={field_description}>{field_description}</p>;
        }
      }
    );

    return (
      <div className={styles.section}>
        <h3>Mandatory Fields</h3>
        <div className={styles.row}>{items}</div>
      </div>
    );
  }

  render() {
    const { data, handleDiscard, readOnly, updating } = this.props;
    const { components, notSavedPopup } = this.state;
    const { errors } = this.state;

    return (
      <section className={styles.container}>
        <div>
          {data.serial_number && (
            <span className={styles.serial}>#{data.serial_number}</span>
          )}
          <Input
            classes={{ input: styles.name_text, root: styles.input_parent }}
            value={
              readOnly
                ? `${data.attributes.name} (Version ${data.attributes.version})`
                : data.attributes.name
            }
            placeholder={'Name your template here - eg: Company'}
            onChange={(e) => {
              this.setValue('name', e.target.value);
            }}
            disabled={readOnly}
            required
          />
          <CloseButton handleClick={this.handleDiscard.bind(this)} />
        </div>
        <AccountTypeCheckbox
          label="Apply additional questions to the forms below:"
          selected={data.attributes.application_types}
          handleChange={(e) => this.selectAccountType(e)}
          error={errors.application_types}
          readOnly={readOnly}
        />
        <LegalTypeCheckbox
          selected={data.attributes.legal_types}
          handleChange={(e) => {
            this.selectType(e);
          }}
          error={errors.legal_types}
          readOnly={readOnly}
        />
        {data.attributes.application_types &&
          data.attributes.application_types.includes('credit') && (
            <LimitRange
              addOn={{
                max_credit_value: data.attributes.max_credit_value,
                min_credit_value: data.attributes.min_credit_value,
              }}
              handleChange={(name, value) => {
                this.setValue(name, value);
              }}
              error={errors.credit_limit}
              readOnly={readOnly}
            />
          )}
        {this.RenderMandatory()}
        <div className={styles.section}>
          <div className="mb-4">
            <h3>Content</h3>
          </div>
          {errors.components && (
            <div className={styles.error}>{errors.components}</div>
          )}
          <FormBuilder
            borderedStyle={FEATURE_FLAGS.FEATURE_FLAG_ADDITIONAL_FORM_FIX}
            components={components}
            editable={!readOnly}
            module={'additional'}
            keyNamePrefix={'additional'}
            onChange={(params) => {
              this.componentsChange(params);
            }}
          />
        </div>
        <AttachDocument
          readOnly={readOnly}
          config={this.state.attachDocument || {}}
          errors={errors.attach_document}
          onChange={(params) => this.attachDocumentChange(params)}
          borderedStyle={FEATURE_FLAGS.FEATURE_FLAG_ADDITIONAL_FORM_FIX}
        />
        <PreviewSaveButtons
          moduleName={'additional'}
          previewAddon={{
            attachDocument: this.state.attachDocument,
            components: this.state.components,
            section: 'additional',
          }}
          handleSubmit={this.handleSubmit.bind(this)}
          loading={updating}
          readOnly={readOnly}
          totalPage={paginate(components).totalPage}
        />
        {notSavedPopup && (
          <AddOnsNotSavePopup
            handleSave={this.handleSubmit.bind(this)}
            handleLeave={handleDiscard}
            hidePopup={this.hideNotSavedPopup.bind(this)}
          />
        )}
      </section>
    );
  }
}

const addonType = 'additional';
const description = {
  main: 'In Additional questions, first select entity type(s) & credit limit range and then set up additional \
         questions that will apply for applications that fall within the set criteria.',
};

export default connect((state) => {
  const data = state.add_ons.current_additional;
  const additional = state.add_ons.additional;
  const updating = state.add_ons.additional_updating;
  return {
    additional,
    data,
    updating,
  };
})(AdditionalDetails);
