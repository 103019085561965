import React, { Fragment, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from '../css/material-select.css';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';

function OptionsDropdown(props) {
  const {
    disabled,
    error,
    handleBlur,
    handleChange,
    hidden,
    id,
    label,
    options,
    placeholder,
    rawOptions,
    required,
    tip_css_style,
    tip_name,
    under_label,
    value,
  } = props;

  const [isTooltipActive, setIsTooltipActive] = useState(false);

  function _showTooltip() {
    setIsTooltipActive(true);
  }

  function _hideTooltip() {
    setIsTooltipActive(false);
  }

  function process_result(e) {
    const target_info = e.target;
    const option =
      options.find((option) => option.value === target_info.value) ||
      target_info;

    return { ...target_info, ...option, id: id };
  }

  let tool_tip;
  if (tip_name && isTooltipActive) {
    tool_tip = <ToolTip tip_name={tip_name} css_style={tip_css_style} />;
  }

  return (
    hidden || (
      <div className={styles.material_container}>
        <FormControl
          className={`${styles.material_form_control}`}
          error={Boolean(error)}
          disabled={disabled}
        >
          <InputLabel
            htmlFor={id}
            classes={{
              root: styles.material_label,
              shrink: styles.material_label_shrink,
            }}
          >
            {label}
          </InputLabel>
          <Select
            disabled={disabled}
            name={id}
            value={value === Object(value) ? value.value : value}
            id={id}
            className={styles.material_select}
            onBlur={handleBlur}
            onChange={(e) => handleChange(process_result(e))}
            displayEmpty={!!placeholder}
            classes={{ select: styles.input }}
            onMouseEnter={_showTooltip}
            onMouseLeave={_hideTooltip}
            startAdornment={<span />}
            renderValue={
              (options.find(({ optValue }) => optValue === value) || {}).label
            }
          >
            {!!placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {!rawOptions &&
              options.map((option, i) => (
                <MenuItem key={i} name={option.value} value={option.value}>
                  <div className={styles.material_menuitem}>{option.label}</div>
                </MenuItem>
              ))}
            {Boolean(rawOptions) && rawOptions}
          </Select>
        </FormControl>
        {tool_tip}
        <FormHelperText
          error={Boolean(error)}
          className={styles.material_helper_text}
          style={
            error
              ? { fontSize: 10, fontWeight: 800 }
              : { fontSize: 10, fontWeight: 400 }
          }
        >
          {error}
          {under_label}
        </FormHelperText>
      </div>
    )
  );
}

export default OptionsDropdown;
