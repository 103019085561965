import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragSource, DropTarget, DropTargetMonitor } from 'react-dnd';

import { CustomerViewContext } from './CustomerViewContext';
import { T3 } from './T3';
import { DND_TYPE } from './constants';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .MuiFormControl-root {
    margin-top: 10px;
  }
`;

const T3List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 16px;
  min-height: 500px;
`;

const externalNodeSpec = {
  beginDrag: (componentProps: any) => ({ node: { ...componentProps.node } }),
};

const externalNodeCollect = (connect: any) => ({
  connectDragSource: connect.dragSource(),
});

const DraggableT3 = DragSource(
  DND_TYPE,
  externalNodeSpec,
  externalNodeCollect
)(T3);

const dropAreaSpec = {
  canDrop: (_, monitor: DropTargetMonitor) =>
    monitor.getItem().node.type === 't3',
};

const dropAreaCollect = (connect: any, monitor: any) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver({ shallow: true }),
});

const DropBase = (props: any) => {
  const { connectDropTarget, children }: any = props;

  return connectDropTarget(<div>{children}</div>);
};

const DropAreaComponent = DropTarget(
  DND_TYPE,
  dropAreaSpec,
  dropAreaCollect
)(DropBase);

export const StoreList = () => {
  const { t3s, t3Name } = useContext(CustomerViewContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredT3s, setFilteredT3s] = useState(t3s);

  const handleChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchTerm) {
      setFilteredT3s(
        t3s.filter((t3) =>
          t3.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredT3s(t3s);
    }
  }, [t3s, searchTerm]);

  return (
    <Container>
      List of T3 entities
      <BorderedTextField
        placeholder={`Search ${t3Name}`}
        onChange={handleChangeSearchTerm}
        customProps={{ withBottomMargin: false }}
      />
      <DropAreaComponent>
        <T3List>
          {filteredT3s.map((t3, index) => (
            <DraggableT3 node={t3} key={index} />
          ))}
        </T3List>
      </DropAreaComponent>
    </Container>
  );
};
