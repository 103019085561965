import { Typography } from '@material-ui/core';
import React from 'react';
import styles from './css/Section.css';
import * as yup from 'yup';
// @ts-ignore-next-line - Should be imported
import Checklist from './Checklist';

const agreement1Centre = (region) => {
  const termsUrl = defaults.t_and_c_url[region];

  return [
    {
      label: (
        <p>
          I have read and agree to the{' '}
          <a href={termsUrl} target="_blank">
            1Centre Terms & Conditions
          </a>{' '}
          individually and on behalf of the Business Customer
        </p>
      ),
      name: 'agree_1c',
    },
    {
      label: (
        <p>
          {' '}
          I acknowledge&nbsp;
          <a href={defaults.policy} target="_blank">
            1Centre’s privacy policy
          </a>
          &nbsp;and consent to receiving relevant communications electronically
        </p>
      ),
      name: 'acknowledge_1c',
    },
  ];
};

export const agreements1CYupSchema = {
  acknowledge_1c: yup
    .boolean()
    .test('acknowledge_1c', 'Please check to agree.', (value) => value),
  agree_1c: yup
    .boolean()
    .test('agree_1c', 'Please check to agree.', (value) => value),
};

const defaults = {
  title: '1Centre Acknowledgement and Agreement',

  t_and_c_url: {
    AU: 'https://s3-ap-southeast-2.amazonaws.com/s3-1centre-production/pdfs/files/1centre_terms_document/20180112+FINAL+1Centre+Business+Customer+T+Cs+(00494980-2xE3943).pdf',
    NZ: 'https://s3-1centre-production.s3-ap-southeast-2.amazonaws.com/pdfs/files/1centre_terms_document/FINAL+2021+-+1Centre+-+NZ+Business+Customer+-+Terms+of+Use.pdf',
  },
  policy: 'https://www.1centre.com/Privacy',
};

const Agreements1Centre = (props) => {
  const { application } = props;
  const region = application.attributes.supplier_region;
  const agreements1C = agreement1Centre(region);

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {defaults.title}
      </Typography>
      <div className={styles.checklist_container}>
        <div className={styles.check_list}>
          <Checklist {...props} checklist={agreements1C} />
        </div>
      </div>
    </div>
  );
};

export default Agreements1Centre;
