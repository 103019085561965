import AddonRuleModel from 'models/AddonRuleModel';
import AddonAnswer from 'modules/new-applications/components/AddonAnswer';
import CardholderField from 'modules/new-applications/components/application-sections/Cardholder/CardholderField';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { ReactElement } from 'react';

const Cardholder = ({
  addonRule,
  cardholder,
  index,
  isSignatureRequired,
}: {
  addonRule: AddonRuleModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardholder: { [key: string]: any };
  index: number;
  isSignatureRequired: boolean;
}): ReactElement => (
  <FixedContent header={`Cardholder ${index}`}>
    <GridContent>
      <LabeledContent
        label="Name to appear on card"
        content={cardholder.formattedName}
      />
      <CardholderField
        content={cardholder.formattedCardLimit}
        isVisible={addonRule.isCardLimitEnabled}
        label="Monthly card limit"
      />
      <CardholderField
        content={cardholder.email}
        isVisible={isSignatureRequired}
        label="Email"
      />
      <CardholderField
        content={cardholder.mobile}
        isVisible={isSignatureRequired}
        label="Phone"
      />
      <CardholderField
        content={cardholder.fullDeliveryAddress}
        isVisible={isSignatureRequired}
        label="Card delivery address"
      />
      <CardholderField
        content={cardholder.approvedString}
        isVisible={isSignatureRequired}
        label="Agreed to be a cardholder"
      />
      <AddonAnswer
        answers={cardholder.answers}
        components={addonRule.additionalFields}
        isContained={false}
      />
    </GridContent>
  </FixedContent>
);

export default Cardholder;
