import {} from './constants';

var branch_invite_defaults = {};

export function branchInviteReducers(state = branch_invite_defaults, action) {
  switch (action.type) {
    default:
      return { ...state };
  }
}
