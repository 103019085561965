const inputProperties = {
  account: {
    label: 'Account name to be debited',
    require: true,
  },
  accountNumber: {
    label: 'Bank account number',
    require: true,
  },
};

export default inputProperties;
