/* Routes */
import Home from 'modules/shared/Home';

import Authorisation from './routes/Authorisation';
import Cardholder from './routes/Cardholder';
import CardManagement from './routes/CardManagement';
import CardManagementAuthorisation from './routes/CardManagementAuthorisation';
import Dashboard from './routes/Dashboard';
import Guarantor from './routes/Guarantor';
import InvalidAccount from './routes/InvalidAccount';
import Paperless from './routes/Paperless';
import PaperlessAuthorisation from './routes/PaperlessAuthorisation';
import Register from './routes/Register';
import RequestPermissionFlowRoute from './routes/RequestPermissionFlowRoute';
import ShareTheLove from './routes/ShareTheLove';
import Trm from './routes/Trm';
import User from './routes/User';
import V2ConsumerOnboardingRoutes from './routes/v2/ConsumerOnboardingRoutes';
import WebhookDocumentation from './routes/WebhookDocumentation';
import WebhookV2DocumentationRoute from './routes/WebhookV2DocumentationRoute';
import WebsiteButton from './routes/WebsiteButton';

const Application = {
  childRoutes: [
    {
      childRoutes: [
        Authorisation,
        Cardholder,
        CardManagement,
        CardManagementAuthorisation,
        Dashboard,
        Guarantor,
        InvalidAccount,
        Paperless,
        PaperlessAuthorisation,
        V2ConsumerOnboardingRoutes,
        Register,
        RequestPermissionFlowRoute,
        ShareTheLove,
        Trm,
        User,
        WebhookDocumentation,
        WebhookV2DocumentationRoute,
        WebsiteButton,
      ],
      component: require('./containers/Layout'),
      indexRoute: {
        component: Home,
      },
      path: '/',
    },
  ],
};

export default Application;
