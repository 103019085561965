/* Components */
import React from 'react';
import { Link } from 'react-router';

import Button from '../../shared/components/inputs/Button';
import Password from '../../shared/components/inputs/Password';
import EmailInput from '../../shared/components/inputs/EmailInput';
import TextInput from '../../shared/components/inputs/TextInput';
import isPresent from 'utils/isPresent';

/* Import CSS */
import styles from './css/LoginForm.css';

/* LoginForm */
var LoginForm = createClass({
  /**
   * Render
   *
   * @returns {XML}
   */
  render: function() {
    const {
      blurHandler,
      button_label,
      changeHandler,
      email_address,
      emailChangeHandler,
      password,
      redirectPath,
      submitHandler,
    } = this.props;

    let forgotPasswordPath = '/user/forgot-password';
    if (isPresent(redirectPath)) {
      forgotPasswordPath = `${forgotPasswordPath}?redirect=${redirectPath}`;
    }

    return (
      <form
        className={styles.form}
        onSubmit={submitHandler}
        method="post"
        noValidate
      >
        <fieldset className={styles.fieldset}>
          <EmailInput
            id="email_address"
            label="Email Address"
            value={email_address}
            required={true}
            type="email"
            handleChange={emailChangeHandler}
            handleBlur={emailChangeHandler}
          />
          <TextInput
            id="password"
            label="Password"
            type="password"
            value={password}
            required={true}
            handleChange={changeHandler}
            handleBlur={blurHandler}
          />
          <p>
            <Link className={styles.link} to={forgotPasswordPath}>
              Forgotten your password?
            </Link>
          </p>
          {/* <p><Link className={styles.link} to="/user/register">Need to register?</Link></p> */}
        </fieldset>
        <div className={styles.buttons}>
          <Button
            type="submit"
            key="login_submit"
            id="login"
            text={button_label}
          />
        </div>
      </form>
    );
  },
});

export default LoginForm;
