import {
  AUTO_DECISIONS_SET_COMPANYS,
  AUTO_DECISIONS_SET_TIME_IN_BUSINESS,
  AUTO_DECISIONS_SET_VALUE,
  AUTO_DECISIONS_CREATE_RULESET,
  AUTO_DECISIONS_CREATE_RULESET_SUCCESS,
  AUTO_DECISIONS_CREATE_RULESET_ERROR,
  AUTO_DECISIONS_UPDATE_RULESET,
  AUTO_DECISIONS_UPDATE_RULESET_SUCCESS,
  AUTO_DECISIONS_UPDATE_RULESET_ERROR,
  AUTO_DECISIONS_SET_RULESET,
} from './constants';

export const initialAutoDecistionsState = {
  current_rule_set: {
    is_new: true,
    attributes: {
      legal_types: [],
      trade_reference_count: null,
      min_credit_value: 0,
      max_credit_value: 0,
      application_types: [],
      regions: [],
      time_in_business: '',
    },
  },
};

export function autoDecisionReducer(
  state = initialAutoDecistionsState,
  action
) {
  switch (action.type) {
    case AUTO_DECISIONS_SET_COMPANYS:
      return {
        ...state,
        current_rule_set: {
          ...state.current_rule_set,
          attributes: {
            ...state.current_rule_set.attributes,
            legal_types: action.payload,
          },
        },
      };
    case AUTO_DECISIONS_SET_TIME_IN_BUSINESS:
      return {
        ...state,
        current_rule_set: {
          ...state.current_rule_set,
          attributes: {
            ...state.current_rule_set.attributes,
            time_in_business: action.payload,
          },
        },
      };
    case AUTO_DECISIONS_SET_VALUE:
      return {
        ...state,
        current_rule_set: {
          ...state.current_rule_set,
          attributes: {
            ...state.current_rule_set.attributes,
            [action.payload.property]: action.payload.value,
          },
        },
      };
    case AUTO_DECISIONS_SET_RULESET:
      return {
        ...state,
        current_rule_set: action.payload,
      };
    case AUTO_DECISIONS_CREATE_RULESET:
      return { ...state, updating: true };
    case AUTO_DECISIONS_CREATE_RULESET_SUCCESS:
      return { ...state, updating: false };
    case AUTO_DECISIONS_CREATE_RULESET_ERROR:
      return { ...state, updating: false, errors: action.payload };
    case AUTO_DECISIONS_UPDATE_RULESET:
      return { ...state, updating: true };
    case AUTO_DECISIONS_UPDATE_RULESET_SUCCESS:
      return { ...state, updating: false };
    case AUTO_DECISIONS_UPDATE_RULESET_ERROR:
      return { ...state, updating: false, errors: action.payload };
    default:
      return { ...state };
  }
}
