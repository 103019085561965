export const formConstants = {
  TRADE_REFERENCE_CHECKS_REQUIRED: {
    name: 'tradeReferenceChecksRequired' as const,
    label:
      'Do you require trade reference checks? Once checks have passed, 1CAD will apply.',
  },
  TRADE_REFERENCE_COUNT: {
    name: 'tradeReferenceCount' as const,
    label: 'Number of trade references',
  },
  PAPERLESS_DIRECT_DEBIT: {
    name: 'paperlessDirectDebit' as const,
    label:
      'Do you require a paperless direct debit? This requires your customer to complete their bank account details and agree to the direct debit terms.',
  },
};
