import React from 'react';
import { connect } from 'react-redux';
import styles from './css/Layout.css';
import { userLogin, setQueryParamsForTokenAuth } from '../actions';
import get from 'lodash.get';
import api from 'api';
import * as Sentry from '@sentry/browser';
import SectionCircle from 'modules/shared/components/widgets/static/SectionCircle';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import { browserHistory } from 'react-router';
import Loader from 'modules/shared/components/widgets/static/Loader';

const isRedirectToConsumerOnboarding = (redirect) =>
  redirect && redirect.startsWith('/register/consumer');

class Layout extends React.Component {
  constructor() {
    super();
    this.state = {
      hasRedirectConsumerOnboardingError: false,
      isRedirectingConsumerOnboarding: false,
    };
  }

  async UNSAFE_componentWillMount() {
    const {
      dispatch,
      location: {
        query: { t, e, redirect, referrer, ...rest },
      },
    } = this.props;

    let attributes = rest;

    const entity = get(this.props, 'location.query.entity', null);

    if (isRedirectToConsumerOnboarding(redirect)) {
      this.setState({ isRedirectingConsumerOnboarding: true });

      const indexOfApplicationId = 3;
      const applicationId = redirect.split('/')[indexOfApplicationId];
      const onboardingAPI = api('onboarding');

      try {
        const result = await onboardingAPI.applicationRedirectLink({
          applicationId: applicationId,
          consumerId: entity,
          email: e,
        });

        const redirectPath = get(result, 'data.onboarding_path');

        if (redirectPath) {
          browserHistory.push(redirectPath);
        } else {
          this.setState({ hasRedirectError: true });
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);

        this.setState({ hasRedirectError: true });
      } finally {
        this.setState({ isRedirectingConsumerOnboarding: false });
      }
    } else if (e && t) {
      if (!redirect) {
        return;
      }
      // Fix for spaces, since it seems react-router replaces '+' with spaces.
      // https://github.com/ReactTraining/react-router/issues/2426
      const email = e.replace(' ', '+');
      if (attributes.application) {
        attributes = { ...rest, onboarding: true };
      }
      dispatch(setQueryParamsForTokenAuth(attributes));
      dispatch(userLogin(email, t, redirect, () => null, entity, referrer));
    }
  }

  render() {
    if (this.state.isRedirectingConsumerOnboarding) {
      return (
        <Loader
          message="Please wait while we load your progress."
          loading={true}
        />
      );
    }

    if (this.state.hasRedirectError) {
      return (
        <div>
          <SectionCircle key="1" title="">
            <SectionDescription text="We have been unsuccessful in retrieving your application. Please contact support@1centre.com. Provide your business name, with the name of the Supplier you are wanting to open a trade account with. Thank you for your patience as we work with you to get you back up and running." />
          </SectionCircle>
        </div>
      );
    }

    const { children, withParentDiv, parentDivProps } = this.props;

    if (withParentDiv) {
      return <div {...parentDivProps}>{children}</div>;
    }

    return children;
  }
}

Layout.defaultProps = {
  withParentDiv: true,
};

export default connect()(Layout);
