import React from 'react';
import { useParams } from 'react-router-dom';

import ShareTheLove from './index';
import { PageWrapper } from './styles';

const ShareTheLovePage = ({ location }) => {
  const { query } = location;

  return (
    <PageWrapper>
      <ShareTheLove source={query.from} />
    </PageWrapper>
  );
};

export default ShareTheLovePage;
