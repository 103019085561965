import React, { Fragment, ReactElement } from 'react';
import ManualPassContent from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/ManualVerification/ManualPassContent';
import SignatureHelpTooltip from '../../../../SignatureTooltip';
import { getOtherTypeTooltipLabel } from '../../../../utils/utils';

const getColorStyle = ({
  idVerified,
  pass,
  status,
  isOtherIdentificationType,
}: {
  idVerified: 'Pass' | 'Fail' | 'Unknown';
  pass: boolean | null;
  status: 'completed' | 'errored';
  isOtherIdentificationType;
}): { color?: 'red' | 'green' | 'black' } => {
  if (isOtherIdentificationType) {
    return { color: 'black' };
  }

  if (status === 'errored') {
    return { color: 'red' };
  }

  if (idVerified !== 'Unknown') {
    return { color: pass ? 'green' : 'red' };
  }

  return {};
};

const getOutcomeMessage = (
  idVerified,
  pass,
  status,
  isOtherIdentificationType
) => {
  if (isOtherIdentificationType) {
    return (
      <span>
        N/A{' '}
        <SignatureHelpTooltip>
          {getOtherTypeTooltipLabel('id_check')}
        </SignatureHelpTooltip>
      </span>
    );
  }

  if (status === 'errored') {
    return 'API fail';
  }

  if (!pass && typeof idVerified !== 'undefined' && idVerified !== 'Unknown') {
    return 'Fail';
  }

  return idVerified;
};

const Outcome = ({
  idVerified,
  pass,
  manuallyApproved,
  status,
  isAml,
  amlVerified,
  isOtherIdentificationType,
}: {
  idVerified: 'Pass' | 'Fail' | 'Unknown';
  pass: boolean | null;
  manuallyApproved: boolean | null;
  status: 'completed' | 'errored';
  isAml: boolean | null;
  amlVerified: 'Pass' | 'Fail' | 'Unknown';
  isOtherIdentificationType: boolean;
}): ReactElement => {
  if (manuallyApproved) {
    return <ManualPassContent />;
  }

  const style = getColorStyle({
    idVerified: isAml ? amlVerified : idVerified,
    pass,
    status,
    isOtherIdentificationType,
  });
  const outcomeMessage = getOutcomeMessage(
    isAml ? amlVerified : idVerified,
    pass,
    status,
    isOtherIdentificationType
  );

  return (
    <Fragment>
      <span className="underlined">
        {isAml ? 'AML Outcome:' : 'ID check outcome:'}
      </span>
      <span style={style}>&nbsp;{outcomeMessage}</span>
    </Fragment>
  );
};

export default Outcome;
