import { LOADER_HIDE_LOADER, LOADER_SHOW_LOADER } from './constants';

const loader_defaults = {
  state: 'none',
  active: false,
  message: '',
};

export function loaderReducer(state = loader_defaults, action) {
  switch (action.type) {
    case LOADER_SHOW_LOADER:
      return { ...state };
    case LOADER_SHOW_LOADER:
      return { ...state };
    default:
      return { ...state };
  }
}
