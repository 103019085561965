import styled from 'styled-components';

export const FormWrapper = styled.form`
  width: 328px;
  .MuiFormHelperText-root {
    text-align: left;
  }
`;

export const ButtonWrapper = styled.span`
  button {
    margin-top: 10px !important;
  }
`;
