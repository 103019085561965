import './location-channel-content.scss';
import HistoryPanelSection from 'containers/next-gen/components/shared/HistorySection';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import mixpanel from 'mixpanel-browser';
import AddonConfigModel from 'models/AddonConfigModel';
import UserModel from 'models/UserModel';
import {
  createAddonVersion,
  loadQrAddon,
  updateAddonRuleset,
} from 'modules/addons/actions';
import CheckboxGroup from 'modules/addons/sales_channels/shared/CheckboxGroup';
import ConfirmationModal from 'modules/addons/sales_channels/shared/ConfirmationModal';
import EmbeddedComponents from 'modules/addons/sales_channels/shared/EmbeddedComponents';
import getAccountTypeCheckboxOptions from 'modules/addons/sales_channels/shared/getAccountTypeCheckboxOptions';
import Button from 'modules/shared/components/inputs/Button';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  CHECKBOX_REGION_OPTIONS,
  QR_CODE,
  REGION_TEXT,
  applicationTypeLabel,
} from '../shared/constants';
import {
  ApplicationTypes,
  AddonAttributes,
  NewChanges,
  ApplicationRegions,
} from '../shared/types';

const addon_module_name = QR_CODE;
const applicationText = applicationTypeLabel(addon_module_name);

const instructionsText = `Turning on your Location channel will enable you to attach the below QR code onto any collateral,
such as ‘Location posters’, magazines, flyers or any other marketing material.
You can also embed the ‘Image widget’ onto your digital media platforms.`;

const instructionSet = {
  main: 'Follow the below instructions to set up your location channel.',
  steps: [
    'Copy the QR Code',
    'Paste onto any collateral',
    <span>
      {"If you would like a 'Location Poster', please reach out to "}
      <a href="mailto:success@1centre.com">success@1centre.com</a>
    </span>,
  ],
};

const instructionSet2 = {
  main: '',
  steps: [
    'Copy the ‘Image widget’ link from the box below',
    'Embed on your digital media platforms',
    'Find the section where you can edit your platform’s HTML',
    'Paste the link into the relevant section, save and publish',
  ],
};

function initializeAddonModule() {
  return new AddonConfigModel({
    attributes: { addon_module_name },
  });
}

function getNewVersionAttribute(
  addonConfig: any,
  applicationTypes: ApplicationTypes,
  config: any = {}
) {
  const attributes: AddonAttributes = {
    application_types: applicationTypes,
    config: config,
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = 'Location button';
    attributes.addon_module_name = addon_module_name;
  }

  return attributes;
}

const LocationChannelContent = ({
  applicationType,
  companyName,
  currentUser,
  dispatch,
  entityId,
  isReadonly,
  locationButtonActive,
  locationButtonData,
  locationButtonHistory,
  locationButtonRegions,
}) => {
  const processedCurrentUser = UserModel.fromCurrentUser(currentUser);
  const [addonConfig, setAddonConfig] = React.useState(initializeAddonModule());
  const checkboxApplicationTypeOptions = useMemo(
    getAccountTypeCheckboxOptions,
    []
  );

  const trackMixPanel = (eventName) => {
    mixpanel.track(eventName, {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  };

  useEffect(() => {
    if (!isEmpty(locationButtonData)) {
      setAddonConfig(locationButtonData);
    }
  }, [locationButtonData]);

  React.useEffect(() => {
    trackMixPanel('Sales channels - Location channel');

    dispatch(loadQrAddon());
  }, []);

  const locationButtonLink = `${window.location.origin}/onboarding?supplier_id=${entityId}&channel=qr`;

  const [withChanges, setWithChanges] = React.useState(false);
  const [active, setActive] = React.useState(locationButtonActive);
  const [newChanges, setNewChanges] = React.useState({} as NewChanges);
  const [showModal, setShowModal] = React.useState(false);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  useEffect(() => {
    setActive(locationButtonActive);
  }, [locationButtonActive]);

  const onErrorCallback = (error) => {
    console.error(error);
  };

  const reloadData = () => {
    // We could remove this if we could make the response of the other two api calls above same with this.
    dispatch(loadQrAddon());

    setWithChanges(false);
    setShowModal(false);
  };

  const saveChanges = (applicationTypeParam?: any, regionsParam?: any) => {
    const newVersion = addonConfig.generateNewVersion();
    const defaultRegions = { config: { regions: regionsParam } } || {};
    const newVersionApplicationTypes =
      applicationTypeParam || newChanges.application_types || applicationType;
    const newVersionConfig = newChanges.config
      ? newChanges.config
      : defaultRegions;

    const attributes = getNewVersionAttribute(
      addonConfig,
      newVersionApplicationTypes,
      newVersionConfig
    );
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser: processedCurrentUser,
      onErrorCallback,
      onSuccessCallback: reloadData,
    });
  };

  const handleSwitchChange = (newValue: boolean) => {
    trackMixPanel(
      `Sales channels - Location channel - Toggle ${newValue ? 'on' : 'off'}`
    );

    setActive(newValue);
    setWithChanges(true);
    setShowModal(true);
  };

  const confirmModal = () => {
    const updateCheckbox = () => {
      // We need to force the following values to enable/disable the form
      if (active) {
        saveChanges(['credit'], locationButtonRegions);
      } else {
        saveChanges([], locationButtonRegions);
      }
    };

    if (locationButtonData.id) {
      dispatch(
        updateAddonRuleset(
          locationButtonData.id,
          locationButtonData.attributes,
          addon_module_name,
          updateCheckbox
        )
      );
    } else {
      dispatch(
        createAddonVersion(
          getNewVersionAttribute(initializeAddonModule(), ['credit']),
          addon_module_name,
          reloadData
        )
      );
    }

    trackMixPanel('Sales channels - Location channel - Saved changes');
  };

  const cancelCloseModal = () => {
    setActive(!active);
    setWithChanges(false);
    setShowModal(false);
  };

  const handleApplicationTypeChange = (newApplicationTypes) => {
    if (!newApplicationTypes.length && active) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        applicationTypes: 'At least one application type needs to be selected',
      }));
    } else {
      setErrors((currentErrors) => {
        const { applicationTypes, ...restErrors } = currentErrors;

        return restErrors;
      });
    }
    setNewChanges((changes) => {
      return {
        ...changes,
        application_types: newApplicationTypes as ApplicationTypes,
      };
    });
    setWithChanges(true);
  };

  const handleRegionChange = (newRegion) => {
    if (!newRegion.length) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        regions: 'At least one country needs to be selected',
      }));
    } else {
      setErrors((currentErrors) => {
        const { regions, ...restErrors } = currentErrors;

        return restErrors;
      });
    }
    setNewChanges((changes) => ({
      ...changes,
      config: {
        regions: newRegion as ApplicationRegions,
      },
    }));
    setWithChanges(true);
  };

  return (
    <SideMenuPanel>
      <PanelSection>
        <ConfirmationModal
          companyName={companyName}
          isActive={active}
          showModal={showModal}
          cancelCloseModal={cancelCloseModal}
          confirmModal={confirmModal}
          type={addon_module_name}
        />
        {instructionsText}
        <div className="switch-container">
          <Switch3
            label={active ? 'On' : 'Off'}
            value={active}
            onClick={handleSwitchChange}
            isDisabled={isReadonly}
          />
        </div>
      </PanelSection>
      <PanelSection>
        {REGION_TEXT}
        <CheckboxGroup
          options={CHECKBOX_REGION_OPTIONS}
          passedValue={locationButtonRegions}
          handleChange={handleRegionChange}
          disabled={isReadonly || !active}
          errorMessage={errors.regions}
        />
      </PanelSection>
      <PanelSection>
        {applicationText}
        <CheckboxGroup
          options={checkboxApplicationTypeOptions}
          passedValue={applicationType}
          handleChange={handleApplicationTypeChange}
          disabled={isReadonly || !active}
          errorMessage={errors.applicationTypes}
        />
      </PanelSection>
      {!isReadonly && (
        <>
          <PanelSection>
            {instructionSet.main}
            <div className="integration-components-container">
              <ul className="integration-steps">
                {instructionSet.steps.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <ul className="integration-steps">
                {instructionSet2.steps.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="integration-components-container">
              <EmbeddedComponents.QRCode
                currentUser={currentUser}
                link={locationButtonLink}
                title="Digital QR Code"
              />
              <EmbeddedComponents.QRWidget
                currentUser={currentUser}
                link={locationButtonLink}
                title="Image Widget"
              />
            </div>
            <Button
              type="button"
              text="Save"
              disabled={
                isReadonly ||
                (!active && !withChanges) ||
                !withChanges ||
                Object.keys(errors).length
              }
              onClick={() => saveChanges()}
            />
          </PanelSection>
          <HistoryPanelSection
            historyList={locationButtonHistory}
            historyCount={5}
          />
        </>
      )}
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');
  const companyName = get(
    currentUser,
    'current_entity.attributes.company_name'
  );
  const locationButtonData = get(state, 'add_ons.qr_code', {});

  const locationButtonHistory = get(
    locationButtonData,
    'attributes.history_version.data',
    []
  );
  const locationButtonActive = get(
    locationButtonData,
    'attributes.active',
    false
  );
  const applicationType = get(
    locationButtonHistory[0],
    'attributes.application_types',
    []
  );
  const locationButtonRegions = get(
    locationButtonHistory[0],
    'attributes.config.regions',
    ['NZ', 'AU']
  );

  return {
    applicationType,
    companyName,
    currentUser,
    entityId,
    isReadonly: !isFeatureEditEnabled('Location'),
    locationButtonActive,
    locationButtonData,
    locationButtonHistory,
    locationButtonRegions,
  };
})(LocationChannelContent);
