import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css, keyframes } from 'styled-components';
import CancelIcon from '@material-ui/icons/Cancel';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { ActionLink } from './ActionLink';
import { IconButton } from '@material-ui/core';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { RapidTransferContext } from './RapidTransferContext';
import { ConfirmRemoveAttachment } from './ConfirmRemoveAttachment';

const isFilePresent = (file: File | null) => Boolean(file);

const isCsvFile = (file: File) => {
  if (!file) {
    return false;
  }
  const { name, type } = file;
  const nameParts = name.split('.');
  const namePartsLength = nameParts.length;
  const extension = nameParts[namePartsLength - 1];
  return extension === 'csv' && type === 'text/csv';
};

const downloadAttachment = (file: File | null) => {
  if (!file) {
    return;
  }
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = file.name;
  link.click();
};

const slide = keyframes`
from {
  opacity: 0;
  transform: translateY(5px);
}

to {
  opacity: 1;
  transform: translateY(0)
}`;

const Container = styled.div<{ $animate?: boolean }>`
  display: flex;
  align-items: center;
  height: 20px;
  animation: ${(props) =>
    props.$animate
      ? css`
          ${slide} 100ms ease-out
        `
      : 'unset'};
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledIconButton = styled(IconButton)`
  height: 16px;
  width: 16px;
`;

type UploadButtonProps = {
  region: 'au' | 'nz';
};

export const UploadButton = (props: UploadButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isConfirmationDialogVisible, setConfirmationDialogVisible] =
    useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const { auFile, setAuFile, nzFile, setNzFile, isFileSelectionDisabled } =
    useContext(RapidTransferContext);

  const { region } = props;

  const regionFileMap = {
    au: {
      hasFile: isFilePresent(auFile),
      fileSetterFunction: setAuFile,
      viewAttachment: () => downloadAttachment(auFile),
    },
    nz: {
      hasFile: isFilePresent(nzFile),
      fileSetterFunction: setNzFile,
      viewAttachment: () => downloadAttachment(nzFile),
    },
  };

  const hasFile = regionFileMap[region].hasFile;

  const fileSetterFunction = regionFileMap[region].fileSetterFunction;

  const handleViewAttachment = () => regionFileMap[region].viewAttachment();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    if (isCsvFile(file)) {
      fileSetterFunction && fileSetterFunction(file);
      return;
    }

    enqueueSnackbar('Please select a CSV file', { variant: 'error' });
  };

  const triggerFileSelection = () => {
    if (inputRef) {
      inputRef.current?.click();
    }
  };

  const removeFile = () => setConfirmationDialogVisible(true);

  const confirmRemoveFile = () => {
    fileSetterFunction && fileSetterFunction(null);
    setConfirmationDialogVisible(false);
  };

  const cancelRemoveFile = () => setConfirmationDialogVisible(false);

  useEffect(() => {
    setShouldAnimate(true);
  }, [hasFile]);

  if (hasFile) {
    return (
      <MuiThemeProvider theme={muiTheme()}>
        <Container $animate={shouldAnimate}>
          <ActionLink
            onClick={handleViewAttachment}
            text={`View attachment`}
            disabled={isFileSelectionDisabled}
          />
          <StyledIconButton
            onClick={removeFile}
            disabled={isFileSelectionDisabled}
          >
            <CancelIcon />
          </StyledIconButton>
        </Container>
        <ConfirmRemoveAttachment
          isOpen={isConfirmationDialogVisible}
          onConfirm={confirmRemoveFile}
          onCancel={cancelRemoveFile}
        />
      </MuiThemeProvider>
    );
  }

  return (
    <Container $animate={shouldAnimate}>
      <StyledInput
        type="file"
        accept=".csv, text/csv"
        ref={inputRef}
        onChange={handleFileChange}
      />
      <ActionLink
        onClick={triggerFileSelection}
        text={`Upload ${region.toUpperCase()} CSV`}
        icon="upload"
        disabled={isFileSelectionDisabled}
      />
    </Container>
  );
};
