import ConsumerOnBoarding from '../modules/consumer-onboarding/containers/ConsumerOnBoarding';
import Layout from '../modules/user/containers/Layout';

const ConsumerOnBoardingRoute = {
  component: ConsumerOnBoarding,
  path: 'consumer/:application_id/:section(/**)',
};

module.exports = {
  childRoutes: [ConsumerOnBoardingRoute],
  component: Layout,
  path: 'register',
};
