import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from 'api';
import get from 'lodash.get';
import useRequestQuery from 'modules/dashboard/hooks/useRequestQuery';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React, { Fragment, ReactElement, useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { formatLocalTime } from 'utils/dateFormatter';

import {
  Container,
  EmailLogRowContainer,
  EventCol,
  Subject,
  SubjectCol,
  TableHeader,
  TableRow,
  TableRowDetails,
} from './styles';
import basicSort from 'utils/basicSort';

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

type EmailLogProps = {
  attributes: {
    action: string;
    created_at: string;
    event: string;
    from: string;
    id: string;
    reason?: string;
    resource_id: string;
    resource_type: string;
    sg_message_id: string;
    subject: string;
    timestamp: string;
    to: string;
    updated_at: string;
  };
};

type ReduceGroup = {
  [key: string]: EmailLogProps[];
};

type EmailLogRowProps = {
  key: string;
  index: number;
  expanded: number | null;
  data: EmailLogProps[];
  setExpanded: React.Dispatch<React.SetStateAction<number | null>>;
};

function formatDateTime(timestamp: string) {
  return formatLocalTime(timestamp, 'minute');
}

function EmailLogRow({
  data,
  index,
  expanded,
  setExpanded,
}: EmailLogRowProps): ReactElement {
  const isOpen = index === expanded;
  const iconDirection = isOpen ? 'chevron-down' : 'chevron-right';

  const { event, to, subject, timestamp } = data[0].attributes;

  function findEventTotal(targetEvent: string) {
    return data.filter(({ attributes }) => attributes.event === targetEvent)
      .length;
  }

  function toggleExpand() {
    if (expanded === index) {
      return setExpanded(null);
    }

    return setExpanded(index);
  }

  const totalClicks = findEventTotal('click');
  const totalOpens = findEventTotal('open');

  return (
    <EmailLogRowContainer>
      <TableRow>
        <SubjectCol>
          <Subject onClick={toggleExpand} className="mr-2">
            <FontAwesomeIcon icon={['fas', iconDirection]} />
          </Subject>
          <div>
            <Subject onClick={toggleExpand}>
              {subject ? subject : '(No subject)'}
            </Subject>
            <div>{to}</div>
          </div>
        </SubjectCol>
        <EventCol event={event}>{event}</EventCol>
        <div>{formatDateTime(timestamp)}</div>
        <div>{totalOpens}</div>
        <div>{totalClicks}</div>
      </TableRow>
      <TableRowDetails in={isOpen}>
        {data.map(({ attributes: { id, event, timestamp } }) => (
          <TableRow key={`${event}-${id}-${timestamp}`}>
            <div></div>
            <EventCol event={event}>{event}</EventCol>
            <div>{formatDateTime(timestamp)}</div>
          </TableRow>
        ))}
      </TableRowDetails>
    </EmailLogRowContainer>
  );
}

function EmailLogs(props: Props): ReactElement | null {
  const { application, currentUser } = props;
  const accessToken = get(currentUser, 'accessToken');
  const entityId = get(currentUser, 'currentEntity.id');
  const applicationId = get(application, 'id');

  if (!applicationId) {
    return null;
  }

  const [expanded, setExpanded] = useState({});
  const setVersionExpanded = (index, currentExpanded) => {
    setExpanded({
      ...expanded,
      [index]: currentExpanded,
    });
  };
  const [allVersionEmailActivities, setAllVersionEmailActivities] = useState(
    []
  );

  useEffect(() => {
    api('email_activities', accessToken, entityId)
      .get(applicationId)
      .then((response) => {
        const responseData = get(response, 'data', []);
        const filteredData = responseData.filter(
          (callCentreData) => callCentreData.data.length
        );
        setAllVersionEmailActivities(filteredData.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [applicationId, currentUser, entityId]);

  const reducer = (newObject: ReduceGroup, data: EmailLogProps) => {
    const { subject, to } = data.attributes;
    const subjectKey = (subject || '').replace(/ /g, '-').toLowerCase();
    const key = `${subjectKey}-${to}`;
    newObject[key] = newObject[key] || [];
    newObject[key].push(data);

    return newObject;
  };

  const hasEmailActivity =
    allVersionEmailActivities && allVersionEmailActivities.length > 0;

  return (
    <ScrollableContent>
      <FixedContent>
        {!hasEmailActivity && (
          <span style={{ fontSize: '16px', marginBottom: '10px' }}>
            No log available
          </span>
        )}
        {hasEmailActivity &&
          allVersionEmailActivities.map(
            (emailActivities: any, versionIndex) => {
              let version_number = 1;
              const filteredData = emailActivities.data
                ? emailActivities.data.filter(({ attributes }) => {
                    version_number = attributes.version_number;
                    return attributes.event !== 'processed';
                  })
                : [];

              const processedData = [...filteredData].reduce(
                reducer,
                Object.create(null)
              );
              const dataKeys = Object.keys(processedData);

              return (
                <React.Fragment>
                  <span
                    style={{ fontSize: '16px', marginBottom: '10px' }}
                  >{`Application Version ${version_number}`}</span>
                  <Container>
                    <TableHeader>
                      <div>Subject</div>
                      <div>Status</div>
                      <div>Last activity received</div>
                      <div>Opens</div>
                      <div>Clicks</div>
                    </TableHeader>
                    {dataKeys.map((key, index) => {
                      const logData = processedData[key];

                      return (
                        <EmailLogRow
                          key={`log-${logData.email}-${index}`}
                          data={logData}
                          index={index}
                          expanded={expanded[versionIndex]}
                          setExpanded={(value) =>
                            setVersionExpanded(versionIndex, value)
                          }
                        />
                      );
                    })}
                  </Container>
                </React.Fragment>
              );
            }
          )}
      </FixedContent>
    </ScrollableContent>
  );
}

export default EmailLogs;
