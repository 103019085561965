import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import LeadModel from 'models/LeadModel';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import React, { ReactElement, ReactNode } from 'react';

import AddonAnswers from '../../AddonAnswer';

type Props = {
  answersKey: string;
  owner: ApplicationModel | LeadModel;
  children?: ReactNode;
  header: string;
  isIUFCompleted?: boolean;
};

function ApplicationIUFInformation({
  answersKey,
  owner,
  children,
  header,
}: Props): ReactElement | null {
  const addonAnswers = get(
    owner,
    `iufAddonAnswers.answers.${answersKey}.answers`,
    []
  );
  const components = get(
    owner,
    `iufAddonRule.config.${answersKey}.components`,
    []
  );

  if (addonAnswers.length === 0 && components.length === 0 && !children) {
    return null;
  }

  return (
    <FixedContent header={header} bottomSeparatorOffset={1}>
      <AddonAnswers answers={addonAnswers} components={components} />
      {children}
    </FixedContent>
  );
}

export default ApplicationIUFInformation;
