import api from 'api';
import SideMenuPanel from 'containers/next-gen/components/shared/Panel';
import PanelSection from 'containers/next-gen/components/shared/Section';
import { capitalize } from 'lodash';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import Table from 'modules/shared/components/table/Table';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
// @ts-ignore-next-line: TS is not able to find `Link` as an exported value of react-router but definitely there is
import { Link } from 'react-router';
import isPresent from 'utils/isPresent';
import CheckboxGroup from '../shared/CheckboxGroup';
import { CHECKBOX_REGION_OPTIONS, REGION_TEXT } from '../shared/constants';
import Button from 'modules/shared/components/inputs/Button';
import HistoryPanelSection from 'containers/next-gen/components/shared/HistorySection';
import AddonConfigModel from 'models/AddonConfigModel';
import UserModel from 'models/UserModel';
import {
  createAddonVersion,
  loadRepchannelAddon,
} from 'modules/addons/actions';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

const TYPE_WITH_TIER_COLUMN = ['Headquarter', 'Region', 'Branch'];

const DATA_COLUMNS = [
  {
    Header: 'User',
    accessor: 'name',
    filter: 'text',
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableFilters: true,
  },
  {
    Header: 'User type',
    accessor: 'roleTypes',
    disableFilters: true,
  },
];

const TIER_DATA_COLUMN_INDEX = 2;
const TIER_DATA_COLUMN = {
  Header: 'Tier',
  accessor: 'tier',
  disableFilters: true,
};

const transformTierData = (attributes) => {
  const { tier, trading_name: tradingName } = attributes;
  if (tier === 'T1') {
    return tier;
  }

  return `${tier} - ${tradingName}`;
};

const transformNameData = (attributes) => {
  const { email, first_name: firstName, last_name: lastName } = attributes;

  const name = `${firstName || ''} ${lastName || ''}`.trim();
  if (isPresent(name)) {
    return name;
  }

  return email.split('@')[0];
};

const transformData = (attributes) => {
  const roleTypes = attributes.role_types
    .map((roleType) =>
      roleType === 'approver' ? capitalize('manager') : capitalize(roleType)
    )
    .join(', ');

  return {
    email: attributes.email,
    name: transformNameData(attributes),
    roleTypes,
    tier: transformTierData(attributes),
  };
};

const addon_module_name = 'rep_channel';

function initializeAddonModule() {
  return new AddonConfigModel({
    attributes: { addon_module_name },
  });
}

function getNewVersionAttribute(addonConfig: any, config: any = {}) {
  const attributes: any = {
    config,
  };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = 'Rep channel';
    attributes.addon_module_name = addon_module_name;
  }

  return attributes;
}

const fetchRepChannel = async ({
  accessToken,
  entityId,
  fetchParams,
  successCallback,
  errorCallback,
}: {
  accessToken: string;
  entityId: string;
  setUsers: any;
  fetchParams?: any;
  successCallback?: (res: any) => void;
  errorCallback?: (res: any) => void;
}) => {
  const salesChannelsAPI = api('sales_channels', accessToken);

  try {
    const response = await salesChannelsAPI.fetchRepChannel(entityId, {
      params: fetchParams,
    });

    if (successCallback) {
      successCallback(response);
    }
  } catch (error) {
    if (errorCallback) {
      errorCallback(error);
    }
    console.error(error);
  }
};

const downloadBlob = (response) => {
  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', 'rep_channel.csv');

  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const RepChannelContent = ({
  currentUser,
  entityId,
  isTierColumnVisible,
  repChannelData,
  repChannelHistory,
  repChannelRegions,
  isReadonly,
  dispatch,
}: {
  currentUser: any;
  entityId: string;
  isTierColumnVisible: boolean;
  repChannelData: any;
  repChannelRegions: string[];
  repChannelHistory: any[];
  isReadonly: boolean;
  dispatch: any;
}): ReactElement => {
  const processedCurrentUser = UserModel.fromCurrentUser(currentUser);
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [addonConfig, setAddonConfig] = React.useState(initializeAddonModule());
  const [regionChanges, setRegionChanges] = React.useState([]);
  const [withChanges, setWithChanges] = React.useState(false);
  const [regionErrorMessage, setRegionErrorMessage] = React.useState<
    string | undefined
  >(undefined);

  const columns = [...DATA_COLUMNS];
  if (isTierColumnVisible) {
    columns.splice(TIER_DATA_COLUMN_INDEX, 0, TIER_DATA_COLUMN);
  }

  useEffect(() => {
    if (entityId) {
      fetchData(loadData);
    }

    mixpanel.track('Sales channels - Rep channel', {
      'Entity ID': entityId,
      distinct_id: get(currentUser, 'data.data.id'),
    });
  }, []);

  const loadData = (response) => {
    const responseData = get(response, 'data.data', []);
    const { total } = get(response, 'data.meta', {});

    const transformedData = responseData.map((data) =>
      transformData(get(data, 'attributes', {}))
    );
    setUsers(transformedData);

    if (totalRecords !== total) {
      setTotalRecords(total);
    }
  };

  const fetchData = (
    successCallback: (res: any) => void,
    fetchParams?: any
  ) => {
    setTableLoading(true);
    (async () => {
      fetchRepChannel({
        accessToken: currentUser.accessToken,
        entityId,
        errorCallback: () => setTableLoading(false),
        fetchParams,
        setUsers,
        successCallback: (res) => {
          successCallback(res);
          setTableLoading(false);
        },
      });
    })();
  };

  const handleTableChange = React.useCallback((tableInfo) => {
    const params = {
      page: tableInfo.pageIndex + 1,
      per_page: tableInfo.pageSize,
      ...(tableInfo.searchFilter ? { keyword: tableInfo.searchFilter } : {}),
    };

    fetchData(loadData, params);
  }, []);

  // Table Download

  const downloadTableDataFn = async () => {
    const salesChannelsAPI = api('sales_channels', currentUser.accessToken);

    try {
      const response = await salesChannelsAPI.downloadRepChannel(entityId);
      downloadBlob(response);

      mixpanel.track('Sales channels - Rep channel - Download user list', {
        'Entity ID': entityId,
        distinct_id: get(currentUser, 'data.data.id'),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const DownloadTableData = () => (
    <span className="span-as-link" onClick={downloadTableDataFn}>
      Download user list
    </span>
  );

  useEffect(() => {
    setAddonConfig(repChannelData);
  }, [repChannelData]);

  useEffect(() => {
    dispatch(loadRepchannelAddon());
  }, []);

  const handleRegionChange = (newRegion) => {
    if (!newRegion.length) {
      setRegionErrorMessage('At least one country needs to be selected');
    } else {
      setRegionErrorMessage(undefined);
    }
    setRegionChanges(newRegion);
    if (!withChanges) {
      setWithChanges(true);
    }
  };

  const onErrorCallback = (error) => {
    console.error(error);
  };

  const reloadData = () => {
    dispatch(loadRepchannelAddon());

    setWithChanges(false);
  };

  const saveChanges = () => {
    const defaultRegions = { regions: repChannelRegions } || {};
    const newVersionConfig = regionChanges
      ? { regions: regionChanges }
      : defaultRegions;

    if (addonConfig) {
      const newVersion = addonConfig.generateNewVersion();

      const attributes = getNewVersionAttribute(addonConfig, newVersionConfig);
      newVersion.setAttributes(attributes);
      newVersion.save({
        addonConfig,
        currentUser: processedCurrentUser,
        onErrorCallback,
        onSuccessCallback: reloadData,
      });
    } else {
      dispatch(
        createAddonVersion(
          getNewVersionAttribute(initializeAddonModule(), newVersionConfig),
          addon_module_name,
          reloadData
        )
      );
    }
  };
  return (
    <SideMenuPanel>
      <PanelSection>
        {REGION_TEXT}
        <CheckboxGroup
          options={CHECKBOX_REGION_OPTIONS}
          disabled={isReadonly}
          passedValue={repChannelRegions}
          handleChange={handleRegionChange}
          errorMessage={regionErrorMessage}
        />
        {!isReadonly && (
          <div style={{ marginTop: '20px' }}>
            <Button
              type="button"
              text="Save"
              disabled={!withChanges || regionErrorMessage}
              onClick={() => saveChanges()}
            />
          </div>
        )}
      </PanelSection>
      {!!repChannelHistory.length && (
        <HistoryPanelSection historyList={repChannelHistory} historyCount={5} />
      )}
      <PanelSection>
        Your rep channel defaults to active as the core channel on your account.
        Below are the active users, which will automatically populate as you add
        them into{' '}
        <Link to="/dashboard/profile?active_tab=your_team">Your Team.</Link>{' '}
        Search users below to see where they are in your organisation.
        <div className="switch-container">
          <Switch3 label="On" value={true} isDisabled={true} />
        </div>
      </PanelSection>
      <PanelSection>
        <Table
          columns={columns}
          data={users}
          toolbarComponents={<DownloadTableData />}
          dataLabel="users"
          fetchData={handleTableChange}
          withBackendFilter
          totalRecords={totalRecords}
          loading={tableLoading}
        />
      </PanelSection>
    </SideMenuPanel>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');
  const supplierType = get(currentUser, 'current_entity.attributes.type');

  const isTierColumnVisible = TYPE_WITH_TIER_COLUMN.includes(supplierType);
  const repChannelData = get(state, 'add_ons.rep_channel');
  const repChannelHistory = get(
    repChannelData,
    'attributes.history_version.data',
    []
  );
  const repChannelRegions = get(
    repChannelHistory[0],
    'attributes.config.regions',
    ['NZ', 'AU']
  );
  return {
    currentUser,
    entityId,
    isTierColumnVisible,
    isReadonly: !isFeatureEditEnabled('RepChannel'),
    repChannelData,
    repChannelHistory,
    repChannelRegions,
  };
})(RepChannelContent);
