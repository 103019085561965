import React from 'react';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import LegalEntityAgreementUploader from 'modules/legal-entity-agreement-uploader/components/LegalEntityAgreementUploader';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';

const Attachments = (props) => {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const { isLoading, setIsLoading } = useIsLoadingState();

  function attachmentLabel(application) {
    const { legalType } = application;

    if (legalType === 'trust') {
      return 'Trust deed';
    }

    if (legalType === 'partnership') {
      return 'Partnership agreement';
    }

    return '';
  }

  if (!['partnership', 'trust'].includes(application.legalType)) {
    return null;
  }

  const {
    legalEntityAgreement,
    partnershipAgreementUploadVisible,
    trustDeedUploadVisible,
  } = application;
  const isAttachmentVisible =
    trustDeedUploadVisible || partnershipAgreementUploadVisible;

  if (!isAttachmentVisible) {
    return null;
  }

  const onSuccessCallback = () => {
    onFetchApplicationRecord(() => setIsLoading(false));
  };

  const onUpload = (data) => {
    setIsLoading(true);

    application.update({
      attributes: {
        legal_entity_agreement: data,
      },
      currentUser,
      onSuccessCallback,
    });
  };

  return (
    <FixedContent header={attachmentLabel(application)}>
      <LegalEntityAgreementUploader
        entityType={application.legalType}
        partnershipAgreementUploadVisible={partnershipAgreementUploadVisible}
        trustDeedUploadVisible={trustDeedUploadVisible}
        legalEntityAgreement={legalEntityAgreement}
        disabled={application.isFated}
        isLoading={isLoading}
        onUpload={onUpload}
      />
    </FixedContent>
  );
};

export default Attachments;
