import { FEATURE_FLAGS } from 'conf';
import AddonVersionModel from 'models/AddonVersionModel';
import Header from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Header';
import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { Fragment } from 'react';
import AntiFraudCategoryRule from 'utils/AntiFraudCategoryRule';
import SignatureHelpTooltip from '../../../SignatureTooltip';
import { getOtherTypeTooltipLabel } from '../../../utils/utils';

// Front-facing selfie only applies to Category 3 and if the authorisation
// roles that have been agreed upon are in the configuration
function getAntiFraudRule(application) {
  return application.addonRules.find((rule) =>
    rule.addonModuleName.includes('anti_fraud_')
  );
}

function getAntiFraudAddonVersion(addonRule) {
  const addonVersion = addonRule.addonVersion;

  if (addonVersion.data) {
    return new AddonVersionModel(addonVersion.data);
  }

  return addonVersion;
}

function isWithinLimit(application) {
  const applicationAntiFraudAddonVersion =
    getAntiFraudRule(application).addonVersion;

  const { max_credit_value, min_credit_value } =
    applicationAntiFraudAddonVersion;

  if (!max_credit_value) {
    return true;
  }

  const { tradeAccountLimit } = application;
  const maxCreditValue = parseInt(max_credit_value);
  const minCreditValue = parseInt(min_credit_value || '0');

  return (
    tradeAccountLimit >= minCreditValue && tradeAccountLimit <= maxCreditValue
  );
}

const getIsVisible = (props) => {
  const { application, authorisation } = props;

  if (!application.isSubmitted) {
    return false;
  }

  if (!application.antiFraudEnabled) {
    return false;
  }

  const { antiFraudAddonRule } = application;

  const applicableCategory = authorisation.getApplicableAntiFraudCategory(
    antiFraudAddonRule.config
  );
  const antiFraudCategoryRules = new AntiFraudCategoryRule(applicableCategory);

  return (
    isWithinLimit(application) &&
    antiFraudCategoryRules.isFrontFaceImageRequired
  );
};

const FrontFaceImageWidget = (props) => {
  const {
    application,
    antiFraudCheck,
    authorisation,
    isVisibleWhenEmpty,
    signature,
  } = props;

  if (!signature.frontFaceImageUrl) {
    return null;
  }

  const isVisible = getIsVisible({ application, authorisation });
  const antiFraudRule = getAntiFraudRule(application);
  const addonVersion = getAntiFraudAddonVersion(antiFraudRule);
  const minimumScore = antiFraudRule
    ? addonVersion.config.auto_pass_min_score
    : 80;

  if (!isVisible) {
    return null;
  }

  const score = antiFraudCheck.score;
  const pass = score >= minimumScore;

  const flaggedOtherIdType =
    FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE &&
    signature.isOtherIdentificationType;

  const similarityScoreOutcome = pass ? 'Pass' : 'Fail';
  const similarityScoreLabel = flaggedOtherIdType
    ? 'N/A'
    : similarityScoreOutcome;

  const spanStyle = flaggedOtherIdType
    ? {}
    : { color: pass ? 'inherit' : 'red' };
  const spanDisplay = flaggedOtherIdType ? (
    <SignatureHelpTooltip>
      <span>{getOtherTypeTooltipLabel('face_match')}</span>
    </SignatureHelpTooltip>
  ) : (
    `(${score}/${minimumScore})`
  );
  const title = (
    <Fragment>
      FaceMatch similarity score: {similarityScoreLabel}
      <span style={spanStyle}>{spanDisplay}</span>
    </Fragment>
  );

  return (
    <div>
      <Header title={title} />
      <div className={styles.signature_image_container}>
        <img
          src={signature.frontFaceImageUrl}
          alt="front-face-image"
          className={styles.signature_image}
        />
      </div>
    </div>
  );
};

export default FrontFaceImageWidget;
