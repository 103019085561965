import logo from 'images/credit-works-logo.png';
import { get } from 'lodash';
import AgedBalances from 'modules/applications/components/credit-checks/credit-works/v2/AgedBalances';
import AgingSummaryGraph from 'modules/applications/components/credit-checks/credit-works/v2/AgingSummaryGraph';
import CreditDetails from 'modules/applications/components/credit-checks/credit-works/v2/CreditDetails';
import ExecutiveSummary from 'modules/applications/components/credit-checks/credit-works/v2/ExecutiveSummary';
import History from 'modules/applications/components/credit-checks/credit-works/v2/History';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import React from 'react';

import CreditScore from './CreditScore';
import DSOSalesOutstandingTrend from './DSOSalesOutstandingTrend';

function CreditWorksComponent(props) {
  const { data, consumer, sequence_date, status } = props;

  const executiveSummary = get(data, 'executiveSummary', {});
  const companyDetails = get(data, 'companyDetails', {});
  const scoreCard = get(data, 'scorecard', {});
  const industrySummary = get(data, 'industrySummary', {});
  const dsoHistory = get(data, 'dsoHistory', {});

  function _errorPage() {
    return (
      <div>
        <ReviewContainer
          subHeading={'CreditWorks'}
          content_class="content_wide"
          css_class="block_noborder"
        >
          <p>
            Oops! Something went wrong, please contact{' '}
            <a href="mailto:support@1centre.com">support@1centre.com</a> for
            more details.
          </p>
        </ReviewContainer>
      </div>
    );
  }

  if (status === 'errored') return _errorPage();
  return (
    <div>
      <img className={styles.veda_logo} src={logo} alt="CreditWorks" />
      <h2 className={styles.heading_large}>CREDIT REPORT</h2>
      <ReviewContainer
        subHeading={get(executiveSummary, 'companyName', '')}
        content_class="content_wide"
        css_class="block_noborder"
      />
      {executiveSummary && (
        <ExecutiveSummary
          data={{
            ...executiveSummary,
            addresses: get(companyDetails, 'addresses'),
            status: get(companyDetails, 'status'),
          }}
          sequenceDate={sequence_date}
          consumer={consumer}
        />
      )}
      <CreditScore
        data={{
          ...scoreCard,
          ...companyDetails,
        }}
      />
      <DSOSalesOutstandingTrend data={get(data, 'dsoHistory.dsoSummary', [])} />
      <AgingSummaryGraph data={get(data, 'agedBalances.agedBalances', [])} />
      <CreditDetails
        data={{
          ...executiveSummary,
          ...companyDetails,
          ...industrySummary,
        }}
        companyDetails={companyDetails}
        dsoSummary={get(dsoHistory, 'dsoSummary')}
        sequenceDate={sequence_date}
      />
      <History data={data} />
      <AgedBalances data={get(data, 'agedBalances.agedBalances', [])} />
    </div>
  );
}

export default CreditWorksComponent;
