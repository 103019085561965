import get from 'lodash.get';
import styles from 'modules/consumer-onboarding/components/paperless/css/Paperless.css';
import inputProperties from 'modules/consumer-onboarding/components/paperless/utils/inputProperties';
import TextInput from 'modules/shared/components/inputs/TextInput';
import React, { Fragment } from 'react';
import { AUSTRALIA_ACCOUNT_NUMBER_FORMAT } from 'utils/direct-debit/accountNumberFormat';
import { AUSTRALIA_BANK_NUMBER_FORMAT } from 'utils/direct-debit/bankNumberFormat';
import bankNumberLabel from 'utils/direct-debit/bankNumberLabel';
import { AU_ACCOUNT_NUMBER_RULE } from 'utils/direct-debit/validateAccountNumber';

const AustraliaPaperlessDetails = (props) => {
  const { answers, error, onSetState, onSetValue } = props;

  return (
    <Fragment>
      <div className={styles.half_col}>
        <TextInput
          id="account"
          label={inputProperties.account.label}
          required={inputProperties.account.require}
          onChange={(event) => onSetValue(event.target.value, 'account')}
          handleBlur={(_) => onSetState({ triggerValidation: true })}
          value={answers.account}
          error={error.account}
        />
      </div>
      <div className={styles.quarter_col}>
        <TextInput
          id="bankNumber"
          label={bankNumberLabel.AU}
          required={true}
          onChange={(event) => onSetValue(event.target.value, 'bankNumber')}
          handleBlur={(_) => onSetState({ triggerValidation: true })}
          value={answers.bankNumber}
          error={error.bankNumber}
          placeholder="xxxxxx"
          mask={AUSTRALIA_BANK_NUMBER_FORMAT}
        />
      </div>
      <div className={styles.quarter_col}>
        <TextInput
          id="accountNumber"
          label={inputProperties.accountNumber.label}
          required={inputProperties.accountNumber.require}
          onChange={(event) => {
            const value = get(event, 'target.value');
            const processedValue = value.replace(/[^0-9]/g, '');

            if (processedValue.length <= AU_ACCOUNT_NUMBER_RULE.maximum) {
              onSetValue(processedValue, 'accountNumber');
            }
          }}
          handleBlur={(_) => onSetState({ triggerValidation: true })}
          value={answers.accountNumber}
          error={error.accountNumber}
          placeholder="xxxxxxxxxx"
        />
      </div>
    </Fragment>
  );
};

export default AustraliaPaperlessDetails;
