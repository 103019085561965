import React, { Component } from 'react';
import {
  getDerivationByIndustry,
  getMeasurementDetailsByTimePeriod,
} from './DefaultsAndEnquiries';

import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { displayEmpty } from 'utils/formatting';
import { extractText } from '../ConsumerRiskScoreComponent';
import get from 'lodash.get';
import objectToArray from 'utils/objectToArray';
import styles from '../css/consumer-risk-score/ReportSummaryOverview.css';

export default class ReportSummaryOverview extends Component {
  get consumerSummary() {
    return get(this.props.creditReport, 'consumer_summary');
  }

  get derivations() {
    return objectToArray(get(this.props.creditReport, 'derivations'));
  }

  get industryTypeAndPeriod() {
    return (
      this.derivations.find(
        (derivation) =>
          extractText(derivation, 'derivation_type', '') ===
          'IndustryTypeAndPeriod'
      ) || {}
    );
  }

  get periodDerivations() {
    return (
      this.derivations.find(
        (derivation) =>
          extractText(derivation, 'derivation_type', '') === 'Period'
      ) || {}
    );
  }

  get overviewIndustryTypeAndPeriodMeasurements() {
    const derivations = getDerivationByIndustry(
      this.industryTypeAndPeriod.derivation,
      'All'
    );

    return getMeasurementDetailsByTimePeriod(derivations, 'P0D');
  }

  get overviewPeriodMeasurements() {
    const derivations = objectToArray(this.periodDerivations.derivation);

    return getMeasurementDetailsByTimePeriod(derivations, 'P0D');
  }

  renderDefaultsDetails() {
    const numberOfDefaults = this.overviewIndustryTypeAndPeriodMeasurements
      .numberOfDefaults;

    if (numberOfDefaults === '-') {
      return '-';
    }

    return `${numberOfDefaults} (${this.overviewIndustryTypeAndPeriodMeasurements.defaultAmount})`;
  }

  renderEnquiriesDetails() {
    const numberOfEnquiries = this.overviewIndustryTypeAndPeriodMeasurements
      .numberOfEnquiries;

    if (numberOfEnquiries === '-') {
      return '-';
    }

    return `${numberOfEnquiries} (${this.overviewIndustryTypeAndPeriodMeasurements.enquiryAmount})`;
  }

  render() {
    return (
      <div className={styles.report_summary_overview_container}>
        <table className={commonStyles.table}>
          <thead>
            <tr>
              <th colSpan={2}>Overview</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Adverse</td>
              <td>{this.overviewPeriodMeasurements.numberOfAdverse}</td>
            </tr>
            <tr>
              <td>Defaults (Total amount)</td>
              <td>{this.renderDefaultsDetails()}</td>
            </tr>
            <tr>
              <td>Enquiries (Total amount)</td>
              <td>{this.renderEnquiriesDetails()}</td>
            </tr>
          </tbody>
        </table>
        <table className={commonStyles.table}>
          <thead>
            <tr>
              <th colSpan={2} className={styles.overview_column_header}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bankruptcies</td>
              <td>{this.overviewPeriodMeasurements.numberOfBankruptcies}</td>
            </tr>
            <tr>
              <td>Judgements</td>
              <td>{this.overviewPeriodMeasurements.numberOfJudgements}</td>
            </tr>
            <tr>
              <td>Age of credit file</td>
              <td>
                {displayEmpty(
                  extractText(this.consumerSummary, 'age_of_bureau_file')
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
