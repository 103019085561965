import api from 'api';
import debounce from 'debounce';
import get from 'lodash.get';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import React, { useEffect, useRef, useState } from 'react';

const DEBOUNCE_INTERVAL = 500;

function AddressFinder(props) {
  const {
    currentUser,
    disabled,
    errors,
    fieldName,
    label,
    rawAddress,
    region,
    onChange,
    onBlur,
  } = props;

  const [addressOptions, setAddressOptions] = useState([rawAddress]);
  const [inputValue, setInputValue] = useState('');
  const addressSearchAPI = api('address_search', currentUser.accessToken);
  const onSearchSuccessCallback = (result) => {
    const addressSearchResult = get(result, 'data.data');

    setAddressOptions(addressSearchResult);
  };

  const searchAddress = (params) => {
    addressSearchAPI.addressSearch(
      onSearchSuccessCallback,
      (error) => console.error(error),
      { params }
    );
  };
  const debouncedSearchAddress = useRef(
    debounce(searchAddress, DEBOUNCE_INTERVAL)
  ).current;

  const onClick = (event, newValue) => {
    setAddressOptions(
      newValue ? [newValue, ...addressOptions] : addressOptions
    );

    onChange(event, newValue);
  };

  useEffect(() => {
    if (inputValue === '') {
      setAddressOptions(rawAddress ? [rawAddress] : []);
      return;
    }

    debouncedSearchAddress({
      address: inputValue,
      region,
      type: 'All',
    });
  }, [inputValue]);

  return (
    <BorderedAutocomplete
      textFieldProps={{
        error: Boolean(errors[fieldName]),
        helperText: get(errors, `${fieldName}.message`, ' '),
        label: label || 'Address',
        name: fieldName,
        InputLabelProps: {
          shrink: true,
        },
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }

        return option.full_address;
      }}
      disabled={disabled}
      getOptionSelected={(option) => option}
      filterOptions={(option) => option}
      options={addressOptions}
      value={rawAddress}
      onChange={onClick}
      onBlur={onBlur}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
    />
  );
}

export default AddressFinder;
