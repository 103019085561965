import { InputAdornment } from '@material-ui/core';
import DefaultBorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form-latest';
import { formatMoney } from 'utils/formatting';

import { Error } from './types';

type Props = {
  control: any;
  customProps?: any;
  error?: any;
  isMoney?: boolean;
  label?: string;
  name: string;
  onBlur?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  value?: any;
  dataCy?: string;
};

function _formatMoney(money: string | number): string {
  const isNumber = typeof money === 'number';

  if (!isNumber && isNaN(parseInt(money))) {
    return '';
  }

  const processedMoney = typeof money === 'string' ? parseInt(money) : money;

  return formatMoney(processedMoney, null, null, null, null);
}

function BorderedTextField(props: Props): ReactElement {
  const {
    customProps = {},
    error,
    isMoney,
    placeholder,
    label,
    onBlur,
    readOnly,
    value: passedValue,
    required,
    dataCy,
    ...controllerProps
  } = props;

  if (isMoney) {
    customProps['startAdornment'] = (
      <InputAdornment position="start">$</InputAdornment>
    );
  }

  const processedLabel = required ? `${label}*` : label;

  return (
    <Controller
      {...controllerProps}
      render={({
        field: { ref, onChange, onBlur: defaultOnBlur, value, ...inputProps },
        fieldState: { error: fieldError },
      }) => {
        const targetValue = passedValue || value;
        const processedError = error || fieldError;
        const processedValue =
          isMoney && targetValue ? _formatMoney(targetValue) : targetValue;

        return (
          <DefaultBorderedTextField
            {...inputProps}
            customProps={customProps}
            error={!!processedError}
            helperText={processedError && processedError.message}
            onChange={(e) => {
              const value = e.target.value;
              const processedValue =
                isMoney && value
                  ? parseInt(value.replace(/\D/g, '')) || ''
                  : value;

              onChange(processedValue);
            }}
            disabled={readOnly}
            onBlur={onBlur || defaultOnBlur}
            inputRef={ref}
            placeholder={placeholder}
            label={processedLabel}
            value={processedValue}
            data-cy={dataCy}
          />
        );
      }}
    />
  );
}

export default BorderedTextField;
