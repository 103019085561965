import React, { ReactElement } from 'react';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';

const RequestSent = ({
  authorisationRequest,
}: {
  authorisationRequest: AuthorisationRequestModel;
}): ReactElement => {
  const { awaitingPermissions } = authorisationRequest;

  const sentToNames = awaitingPermissions.map(
    (permission) => permission.requesteeFullName
  );

  return (
    <>
      <span className="has-text-success icon mr-2">
        <i className="fas fa-check-circle"></i>
      </span>
      Authorisation sent to {sentToNames.join(', ')}.
    </>
  );
};

export default RequestSent;
