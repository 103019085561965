import { setTheme } from 'modules/shared/helpers/colorPalettes';

import {
  PERMISSIONS_UPDATE_COLOR_PALETTE_ERROR,
  PERMISSIONS_UPDATE_COLOR_PALETTE_START,
  PERMISSIONS_UPDATE_COLOR_PALETTE_SUCCESS,
} from './constants';

export function setPermissionsTheme(data) {
  return async (dispatch) => {
    dispatch({ type: PERMISSIONS_UPDATE_COLOR_PALETTE_START });
    try {
      if (!data) throw 'No supplier palette data';
      dispatch({
        payload: data,
        type: PERMISSIONS_UPDATE_COLOR_PALETTE_SUCCESS,
      });
      setTheme(data.theme);
    } catch (error) {
      dispatch({
        payload: error,
        type: PERMISSIONS_UPDATE_COLOR_PALETTE_ERROR,
      });
    }
  };
}
