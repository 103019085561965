/* Import libraries */
/* Import components */
import CreditCardInfo from 'modules/payment/components/CreditCardInfo';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import styles from './css/CreditCardModal.css';

const CreditCardModal = (props) => {
  const { onSave, onCancel, new_company_id } = props;
  return (
    <div className={styles.modal}>
      <CloseButton
        css_class="button_position_top106x40"
        text="Close"
        handleClick={onCancel}
      />
      <div className={styles.fade}>
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.col}>
              <CreditCardInfo
                onSave={onSave}
                noAboutText={true}
                new_company_id={new_company_id}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state, ownProps) => {
  //const { onCancel} = this.props;
  return {};
})(CreditCardModal);
