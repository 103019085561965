/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

import DateString from 'modules/shared/components/widgets/static/DateString';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import AdminTable from 'modules/shared/components/widgets/interactive/AdminTable';
import PDFLogo from 'modules/shared/components/widgets/static/PDFLogo';
/**import css**/
import styles from './css/NotesTable.css';

function NotesTable(props) {
  const { data, config, actions, ...rest } = props;

  return (
    <div className={styles.table_wrapper}>
      <AdminTable data={data} config={config} actions={actions} {...rest} />
    </div>
  );
}

const options = {
  columns: [
    {
      type: 'data',
      label: 'File',
      key: 'attributes.fileIcon',
      widthDefault: 80,
      widthAdminView: 80,
    },
    {
      type: 'data',
      label: 'Notes',
      key: 'attributes.text',
      widthDefault: 250,
      widthAdminView: 250,
    },
    {
      type: 'data',
      label: 'Date',
      key: 'attributes.created_at_formatted',
      widthDefault: 120,
      widthAdminView: 120,
    },
    {
      type: 'action',
      label: 'delete',
      actionKey: 'delete',
      key: 'key',
    },
  ],
};

export default connect((state, ownProps) => {
  let data = ownProps.data,
    config = options.columns,
    actions = {
      delete: ownProps.handleDelete,
    };

  // clone data and format dates
  let formatted_data = JSON.parse(JSON.stringify(data));
  formatted_data = formatted_data.map((note, index) => {
    note.attributes.updated_at_formatted = (
      <DateString
        showNull={true}
        format={'D/M/YY'}
        value={note.attributes.updated_at}
      />
    );

    note.attributes.created_at_formatted = (
      <DateString
        showNull={true}
        format={'D/M/YY'}
        value={note.attributes.created_at}
      />
    );

    if (note.attributes.file) {
      let file_link = extractAttachmentUrl(note.attributes, 'file').url;
      note.attributes.fileIcon = (
        <PDFLogo
          css_style={styles.icon}
          link={file_link}
          iconSize={{
            width: 50,
            height: 50,
          }}
        />
      );
    }
    note.index = index;

    return note;
  });

  return {
    location: ownProps.location,
    data: formatted_data,
    config,
    actions,
  };
})(NotesTable);
