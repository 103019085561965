import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

function validateAddress(value) {
  const { status } = this.parent;

  if (status === 'draft') {
    return true;
  }

  if (isBlank(value)) {
    return false;
  }

  const {
    api_id: apiId,
    api_provider: apiProvider,
    full_address: fullAddress,
    raw_data: rawData,
  } = value.rawData;

  return (
    isPresent(apiId) &&
    isPresent(apiProvider) &&
    isPresent(fullAddress) &&
    isPresent(rawData)
  );
}

export function validateOnRegister(value) {
  const { status } = this.parent;

  if (status === 'draft') {
    return true;
  }

  return isPresent(value);
}

export const COMMON_COLLATERAL_VALIDATION_SCHEMA = {
  collateralDescription: yup
    .string()
    .trim()
    .required('Please enter a collateral description.'),
  collateralType: yup
    .string()
    .nullable()
    .required('Please select a collateral type.'),
};

export const COMMON_DEBTOR_VALIDATION_SCHEMA = {
  debtorAddress: yup
    .object()
    .test('debtor-address', 'Please select debtor address', validateAddress),
  debtorEmail: yup
    .string()
    .trim()
    .email('Please enter correct email')
    .test('debtor-email', 'Please enter debtor email', validateOnRegister),
  debtorPhone: yup
    .string()
    .trim()
    .test(
      'debtor-phone',
      'Please enter debtor phone number.',
      validateOnRegister
    ),
  debtorReference: yup
    .string()
    .trim()
    .test(
      'debtor-reference',
      'Please enter debtor reference.',
      validateOnRegister
    ),
};

export const COMMON_PERSON_VALIDATION_SCHEMA = {
  personDateOfBirth: yup
    .string()
    .trim()
    .test(
      'person-date-of-birth',
      'Please pick debtor date of birth.',
      validateOnRegister
    ),
  personFirstName: yup
    .string()
    .trim()
    .test(
      'person-first-name',
      'Please enter debtor first name.',
      validateOnRegister
    ),
  personLastName: yup
    .string()
    .trim()
    .test(
      'person-last-name',
      'Please enter debtor last name.',
      validateOnRegister
    ),
};

export const COMMON_ORGANISATION_VALIDATION_SCHEMA = {
  organisationName: yup
    .string()
    .trim()
    .test(
      'organisation-name',
      'Please enter debtor organisation name.',
      validateOnRegister
    ),
  organisationNumber: yup
    .string()
    .trim()
    .test(
      'organisation-number',
      'Please enter a ARSN/ACN/ARBN/ABN',
      validateOnRegister
    ),
};
