/* CSS */
import 'babel-polyfill';
import 'normalize-css';
// Base
import './css/base/base.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// Typography
import './css/typography/fonts.css';
import './css/typography/font-awesome.css';
import './css/typography/typography.css';
import 'css/bulma-custom.scss';
import 'font-awesome/css/font-awesome.min.css';

import { Router, browserHistory } from 'react-router';
import { getUserData, setTokenState } from './modules/user/actions';

import Application from './application';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { actions as idleActions } from './modules/redux-idle-monitor';
import { init } from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';
import { mixpanelLocationTracking } from './utils/mixpanelLocationTracking';
import { render } from 'react-dom';
import store from './stores/store';
import { syncHistoryWithStore } from 'react-router-redux';
import ErrorBoundary from 'containers/ErrorBoundary';
import useFontawesome from 'hooks/useFontawesome';
import { getToken } from 'modules/shared/auth/actions';
import Loader from 'modules/shared/components/widgets/static/Loader';
import get from 'lodash.get';
import { SnackbarProvider } from 'notistack';
import ZendeskChat from 'modules/shared/components/widgets/interactive/ZendeskChat';
import { FEATURE_FLAGS } from 'conf';
import { hideLiveChat } from 'utils/liveChat';

// const initialState = {};
// const store = configureStore(initialState);
const history = syncHistoryWithStore(browserHistory, store);

//Google Analytics Tag Component
const GoogleAnalyticsTag = () => (
  <Helmet>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-NVX4QB4MQJ"
    ></script>
    <script>
      {`window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-NVX4QB4MQJ');`}
    </script>
  </Helmet>
);

history.listen((location) => mixpanelLocationTracking(location, store));

// Removed IE support in this demo for the sake of simplicity

module.exports.store = store;

const mainColor = getComputedStyle(document.documentElement)
  .getPropertyValue('--main-color')
  .trim();

const mainColorActive = getComputedStyle(document.documentElement)
  .getPropertyValue('--main-color-active')
  .trim();

const iufFixEnabled = FEATURE_FLAGS.FEATURE_FLAG_IUF_PERFORMANCE_FIX;

const primaryPalette = iufFixEnabled
  ? {
      light: mainColor,
      main: mainColor,
      dark: mainColor,
      contrastText: '#fff',
    }
  : {
      light: 'var(--main-color)',
      main: 'var(--main-color)',
      dark: 'var(--main-color)',
      contrastText: '#fff',
    };

const secondaryPalette = iufFixEnabled
  ? {
      light: mainColorActive,
      main: mainColorActive,
      dark: mainColorActive,
      contrastText: '#fff',
    }
  : {
      light: getComputedStyle(document.documentElement).getPropertyValue(
        '--main-color-active'
      ),
      main: getComputedStyle(document.documentElement).getPropertyValue(
        '--main-color-active'
      ),
      dark: getComputedStyle(document.documentElement).getPropertyValue(
        '--main-color-active'
      ),
      contrastText: '#fff',
    };

let theme = createMuiTheme({
  palette: {
    primary: primaryPalette,
    secondary: secondaryPalette,
    error: {
      main: '#F44335',
    },
  },
  typography: {
    useNextVariants: true,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 992,
    },
  },
});

theme = createMuiTheme(theme, {
  overrides: {
    MuiTabs: {
      root: {
        borderBottom: '1px solid #c9c9c9',
      },
    },
    MuiTab: {
      root: {
        padding: '6px 0',
        marginRight: '16px',
        fontSize: '14px',
        textTransform: 'none',
        '&$selected': {
          '& span': {
            color: theme.palette.secondary.main,
          },
        },
      },
      textColorPrimary: {
        color: theme.palette.primary.main,
      },
      wrapper: {
        flexDirection: 'row',
        justifyContent: 'start',
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: '1.67rem', // ~20px according to current html font size
};

init({
  dsn: window._env_.SENTRY_DSN,
  maxBreadcrumbs: 20,
});

if (window._env_.DATADOG_TOKEN) {
  datadogLogs.init({
    clientToken: window._env_.DATADOG_TOKEN,
    site: window._env_.DATADOG_SITE,
    env: window._env_.DATADOG_ENV,
    forwardConsoleLogs: ['info'],
  });
}

if (FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT) {
  hideLiveChat();
}

useFontawesome();

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async function authInit() {
      const token = await getToken();
      const location = history.getCurrentLocation();

      if (token !== null) {
        store.dispatch(setTokenState(token));
        store.dispatch(
          getUserData(token, null, null, get(location, 'query.entity'))
        );
      }
      store.dispatch(idleActions.start());

      setIsLoaded(true);

      datadogLogs.logger.log('App loaded', {}, 'info');
    })();
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Router
              onUpdate={() => {
                if (
                  location.pathname.indexOf('/dashboard/reporting') < 0 &&
                  location.pathname.indexOf('/trm/list') < 0 &&
                  location.pathname.indexOf('/dashboard/leads/list') < 0
                ) {
                  window.scrollTo(0, 0);
                }
              }}
              history={history}
              routes={Application}
            />
            {window._env_.NODE_ENV !== 'production' && <GoogleAnalyticsTag />}
            {FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT && <ZendeskChat />}
          </SnackbarProvider>
        </MuiThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

render(<App />, document.getElementById('Application'));
