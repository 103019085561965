/* Color constants */
export const STYLE_COLOR_BASE = '#00afef';
export const STYLE_COLOR_BASE_ALT = '#7fcef3';
export const STYLE_COLOR_SHADE_DARKEST = '#1a1a1a';
export const STYLE_COLOR_SHADE_DARK = '#5e5e5e';
export const STYLE_COLOR_SHADE = '#a5a5a5';
export const STYLE_COLOR_SHADE_LIGHT = '#c9c9c9';
export const STYLE_COLOR_SHADE_LIGHTEST = '#f6f6f6';
export const STYLE_COLOR_WHITE = '#ffffff';
export const STYLE_COLOR_HIGHLIGHT = '#F44335';

export const STYLE_COLOR_BASE_BLUE = '#00afef';
export const STYLE_COLOR_BASE_BLUE_LIGHT = '#7fcef3';
export const STYLE_COLOR_BASE_GREY_MEDIUM = '#5e5e5e';
export const STYLE_COLOR_BASE_GREY_LIGHT = '#c9c9c9';
export const STYLE_COLOR_BASE_GREY_LIGHTER = '#e9e9e9';
export const STYLE_COLOR_BASE_RED = '#F44335';

export const STYLE_FONTS_SIZE = '18px';
export const STYLE_FONTS_FAMILY = 'Arial, sans-serif';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NZ_DRIVER_LICENCE_VERSION_REGEX = /^\d{3}$/;
export const NZ_DRIVER_LICENCE_NUMBER_REGEX = /^[a-zA-Z]{2}\d{6}$/;
export const NZ_PASSPORT_NUMBER_REGEX = /^[a-zA-Z]{1,2}\d{6}$/;

export const ANTI_FRAUD_WEBSITE = 'anti_fraud_website';
export const ANTI_FRAUD_QR = 'anti_fraud_qr';
export const ANTI_FRAUD_SALES = 'anti_fraud_sales';

export const CHANNEL_TYPE_WEBSITE = 'website_button';
export const CHANNEL_TYPE_QR = 'qr_code';

export const THIRD_PARTY_CONSENT_LABEL =
  'I am authorised to provide the identity documentation \
and I consent to this information being checked with third party verification partners, \
which include verification by the document issuer, authorised record holder, \
or credit reporting agency, for the purpose of identity verification.';

export const ANTI_FRAUD_TYPES = Object.freeze([
  ANTI_FRAUD_WEBSITE,
  ANTI_FRAUD_QR,
  ANTI_FRAUD_SALES,
]);

export const TERMS_AND_CONDITIONS = {
  AU: {
    guarantor:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/20180112+AU+FINAL+1Centre+Guarantee+Doc+(00494982xE3943).pdf',
    paperless:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/AU_Paperless_T%26C.pdf',
    supplier:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/20180112+FINAL+AU+Generic+Supplier+-+Business+Customer+T+Cs+(00494970xE3....pdf',
  },
  NZ: {
    guarantor:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/NZ_Guarantor_T%26C.pdf',
    paperless:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/NZ_Paperless_T%26C.pdf',
    supplier:
      'https://d20chkok2cesbo.cloudfront.net/pdfs/files/1centre_terms_document/NZ_Supplier_T%26C.pdf',
  },
};

export const ACCOUNT_UPDATE_REASONS = Object.freeze([
  { label: 'Limit change', value: 'Limit change' },
  { label: 'Change of directors', value: 'Change of directors' },
  { label: 'Change application type', value: 'Change application type' },
  { label: 'Add new trading entity', value: 'Add new trading entity' },
  { label: 'Update account information', value: 'Update account information' },
]);

export const IDENTITY_TYPE_DROPDOWN_PROPERTIES = Object.freeze({
  errorMessage: 'You need to enter a identification type.',
  label: 'Identification type',
  name: 'identification_type',
  options: [
    {
      label: 'Driver Licence',
      value: 'driver_licence',
    },
    {
      label: 'Passport',
      value: 'passport',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
  required: true,
});

export const IDENTITY_REGION_DROPDOWN_PROPERTIES = Object.freeze({
  errorMessage: 'You need to enter a country of identification.',
  label: 'Country of identification',
  name: 'region',
  options: [
    {
      label: 'New Zealand',
      value: 'NZ',
    },
    {
      label: 'Australia',
      value: 'AU',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
  required: true,
});

export const MAX_UPLOAD_RETRIES = 3;
