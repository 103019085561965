/* Import libraries */
import React from 'react';
import { Link } from 'react-router';

/* Import styles */
import styles from './css/HomeButton.css';

import Icon from 'modules/shared/components/svg/Icon';

var HomeButton = createClass({
  render: function() {
    const { link, ...rest } = this.props;
    var style = styles.button;

    return (
      <div className={styles.break}>
        <Link className={style} to={link} {...rest}>
          <Icon icon="home" />
        </Link>
      </div>
    );
  },
});

module.exports = HomeButton;
