import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import Table from 'modules/shared/components/table/Table';
import api from 'api';
import get from 'lodash.get';
import { formatLocalTime } from 'utils/dateFormatter';

const DATA_COLUMNS = [
  {
    Header: 'Activity Started',
    accessor: 'created_at',
  },
  {
    Header: 'User Name',
    accessor: 'real_user_full_name',
  },
  {
    Header: 'Email',
    accessor: 'real_user_email',
  },
];

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

export default function CallCentreLog(props: Props): ReactElement | null {
  const { application, currentUser } = props;
  const accessToken = get(currentUser, 'accessToken');
  const entityId = get(currentUser, 'currentEntity.id');
  const applicationId = get(application, 'id');

  if (!applicationId) {
    return null;
  }

  const [allVersionCallCenterActivities, setAllVersionCallCenterActivities] =
    useState([]);

  useEffect(() => {
    api('call_centre_activities', accessToken, entityId)
      .get(applicationId)
      .then((response) => {
        const responseData = get(response, 'data', []);
        const filteredData = responseData.filter(
          (callCentreData) => callCentreData.data.length
        );
        setAllVersionCallCenterActivities(filteredData.reverse());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [applicationId, currentUser, entityId]);

  const hasCallCenterActivities =
    allVersionCallCenterActivities && allVersionCallCenterActivities.length > 0;

  return (
    <ScrollableContent>
      {!hasCallCenterActivities && (
        <span style={{ fontSize: '16px', marginBottom: '10px' }}>
          No log available
        </span>
      )}
      {hasCallCenterActivities &&
        allVersionCallCenterActivities.map((callCenterActivities: any) => {
          let version_number = 1;
          const currentCallCenterActivities = callCenterActivities.data.map(
            (activity) => {
              version_number = activity.attributes.version_number;
              return {
                ...activity.attributes,
                created_at: formatLocalTime(
                  activity.attributes.created_at,
                  'minute'
                ),
              };
            }
          );

          return (
            <React.Fragment>
              <span
                style={{ fontSize: '16px', marginBottom: '10px' }}
              >{`Application Version ${version_number}`}</span>
              <Table
                columns={DATA_COLUMNS}
                data={currentCallCenterActivities}
                dataLabel="activities"
                withPagination={true}
                hidePageSizeControl={true}
                perPage={[10, 10]}
              />
            </React.Fragment>
          );
        })}
    </ScrollableContent>
  );
}
