export const COB_GUARANTOR_COMPONENT_COMPLETE =
  'COB_GUARANTOR_COMPONENT_COMPLETE';
export const COB_GUARANTOR_PRESET_DATA = 'COB_GUARANTOR_PRESET_DATA';
export const COB_GUARANTOR_RESET_TO_DEFAULT = 'COB_GUARANTOR_RESET_TO_DEFAULT';
export const COB_GUARANTOR_SECTION_COMPLETE = ' COB_GUARANTOR_SECTION_COMPLETE';
export const COB_GUARANTOR_SET_GUARANTOR = 'COB_GUARANTOR_SET_GUARANTOR';
export const COB_GUARANTOR_TOGGLE_IS_APPLICANT =
  'COB_GUARANTOR_TOGGLE_IS_APPLICANT';
export const COB_GUARANTOR_SET_BLOCKED_EMAILS =
  'COB_GUARANTOR_SET_BLOCKED_EMAILS';
export const COB_GUARANTOR_REMOVE_GUARANTOR = 'COB_GUARANTOR_REMOVE_GUARANTOR';
