import React, { ReactElement } from 'react';
import { formatLocalTime } from 'utils/dateFormatter';

import { Wrapper } from './styles';

function GuarantorHistoryLogs({ guarantorHistory }): ReactElement {
  return (
    <Wrapper className="mt-5">
      {guarantorHistory.map(({ attributes, id }) => {
        const { content, created_at } = attributes;
        const time = formatLocalTime(created_at, 'minute');
        const message = content.replace('${date}', time);

        return (
          <p className="mb-2" key={id}>
            {message}
          </p>
        );
      })}
    </Wrapper>
  );
}

export default GuarantorHistoryLogs;
