import { Carousel } from 'modules/shared/components';
import React from 'react';
import { ProofOfLifeDetailsContainer, ProofOfLifeOutcome } from './style';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import HelpIcon from '@material-ui/icons/Help';
import { getOtherTypeTooltipLabel } from '../utils/utils';
import { TooltipTitle } from './TooltipTitle';

const ProofOfLife = ({
  proofOfLifeDetails,
  proofOfLifeThreshold,
  isOtherIdentificationType,
}) => {
  const { antiFraudSimilarityScore, antiFraudConfidenceScore, livenessImages } =
    proofOfLifeDetails || {};
  const { auto_pass_min_confidence_score, auto_pass_min_score } =
    proofOfLifeThreshold || {};

  const similarityResult =
    parseFloat(antiFraudSimilarityScore) >= auto_pass_min_score;
  const confidenceResult =
    parseFloat(antiFraudConfidenceScore) >= auto_pass_min_confidence_score;
  const proofOfLifeResult = similarityResult && confidenceResult;
  const proofOfLifeOutcome = proofOfLifeResult ? 'Pass' : 'Fail';
  const proofOfLifeLabel = isOtherIdentificationType
    ? 'N/A'
    : proofOfLifeOutcome;

  const proofOfLifeColor = proofOfLifeResult ? 'green' : 'red';
  const proofOfLifeStyle = {
    color: isOtherIdentificationType ? 'black' : proofOfLifeColor,
  };

  const confidenceScoreLabel = `${antiFraudConfidenceScore}/${auto_pass_min_confidence_score} (${
    confidenceResult ? 'Pass' : 'Fail'
  })`;
  const confidenceScoreOutcome = isOtherIdentificationType
    ? 'N/A'
    : confidenceScoreLabel;
  const similartyScoreLabel = `${antiFraudSimilarityScore}/${auto_pass_min_score} (${
    similarityResult ? 'Pass' : 'Fail'
  })`;
  const similartyScoreOutcome = isOtherIdentificationType
    ? 'N/A'
    : similartyScoreLabel;

  const tooltipLabel = isOtherIdentificationType ? (
    getOtherTypeTooltipLabel('proof_of_life')
  ) : (
    <TooltipTitle />
  );

  return (
    <ProofOfLifeDetailsContainer>
      <div>
        <ProofOfLifeOutcome outcome={proofOfLifeResult}>
          <span>Proof of life:</span>
          <span style={proofOfLifeStyle}> {proofOfLifeLabel}</span>
          <PopperTooltip placement="top" title={tooltipLabel}>
            <HelpIcon color="primary" fontSize="small" />
          </PopperTooltip>
        </ProofOfLifeOutcome>
        <div>{`Confidence score: ${confidenceScoreOutcome}`}</div>
        <div>{`Similarity score: ${similartyScoreOutcome}`}</div>
      </div>
      <Carousel items={livenessImages} />
    </ProofOfLifeDetailsContainer>
  );
};

export default ProofOfLife;
