import DirectDebitAuthorityIndex from 'modules/direct-debit-authority/components/DirectDebitAuthorityIndex';
import DirectDebitAuthorityShow from 'modules/direct-debit-authority/components/DirectDebitAuthorityShow';

const DirectDebitAuthorityRoute = {
  childRoutes: [
    {
      component: DirectDebitAuthorityShow,
      path: ':id',
    },
  ],
  indexRoute: {
    component: DirectDebitAuthorityIndex,
  },
  path: 'direct_debit_authorisations',
};

export default DirectDebitAuthorityRoute;
