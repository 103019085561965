import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#payment-terms-creating-payment-terms
 */
let PaymentTerms = function(config) {
  this.axios = axios(config);
  this.type = 'payment_terms';
};

PaymentTerms.prototype.createPaymentTerms = function(
  attributes,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

PaymentTerms.prototype.updatePaymentTerms = function(
  id,
  attributes,
  success,
  error
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default PaymentTerms;
