import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CarouselContent } from './CarouselContent';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from 'mui-latest';

const FullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6d9;
  z-index: 1;
`;

const CloseIconContainer = styled(IconButton)`
  left: 920px;
  top: -282px;
  z-index: 1;
`;

type CarouselProps = {
  items: string[];
};

export const Carousel = (props: CarouselProps) => {
  const { items } = props;

  const [isFullScreen, setFullScreen] = useState(false);

  const openFullScreen = () => setFullScreen(true);

  const closeFullScreen = () => setFullScreen(false);

  useEffect(() => {
    const detectKeyPress = (e) => {
      if (e.key === 'Escape') {
        closeFullScreen();
      }
    };
    if (isFullScreen) {
      window.addEventListener('keydown', detectKeyPress);
    }

    return () => window.removeEventListener('keydown', detectKeyPress);
  }, [isFullScreen]);

  return (
    <>
      {isFullScreen && (
        <FullScreenContainer>
          <CloseIconContainer onClick={closeFullScreen}>
            <CloseIcon color="primary" />
          </CloseIconContainer>
          <CarouselContent isFullScreen items={items} />
        </FullScreenContainer>
      )}
      <CarouselContent
        isFullScreen={false}
        items={items}
        onClickImage={openFullScreen}
      />
    </>
  );
};
