import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Webhook from 'models/webhooks/Webhook';
import Buttons from 'modules/shared/components/containers/Buttons';
import Button from 'modules/shared/components/inputs/Button';
import EmailInput from 'modules/shared/components/inputs/EmailInput';
import TextInput from 'modules/shared/components/inputs/TextInput';
import Header from 'modules/shared/components/text/Header';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import Card from 'modules/shared/components/widgets/static/Card';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import styles from '../styles.css';
import {FEATURE_FLAGS} from "../../../conf";

const EVENT_TYPE_OPTIONS = FEATURE_FLAGS.NEW_LEAD_EVENT ? [
  {label: 'Application approved', value: 'application_approved'},
  {label: 'Application completed', value: 'completed_application'},
  {label: 'Application declined', value: 'declined_application'},
  {label: 'Alert notification', value: 'alert_notification'},
  {label: 'Incomplete application', value: 'incomplete_application'},
  {label: 'Lead submitted', value: 'lead_submitted'},
] : [
  {label: 'Application approved', value: 'application_approved'},
  {label: 'Application completed', value: 'completed_application'},
  {label: 'Application declined', value: 'declined_application'},
  {label: 'Alert notification', value: 'alert_notification'},
  {label: 'Incomplete application', value: 'incomplete_application'},
];

class WebhookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formChanged: props.formChanged,
      formJustSaved: props.formJustSaved,
      showRemoveModal: false,
      showRemoveSecret: false,
      testMessageJustSent: props.testMessageJustSent,
      webhook: props.webhook,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeWebhook = this.removeWebhook.bind(this);
    this.onClickSendTest = this.onClickSendTest.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeEventType = this.onChangeEventType.bind(this);
    this.onChangeApiVersion = this.onChangeApiVersion.bind(this);
    this.onChangeWorkMode = this.onChangeWorkMode.bind(this);
    this.onChangeTestModelId = this.onChangeTestModelId.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
  }

  onChangeEmail(email) {
    const { webhook } = this.state;

    webhook.update({ email });
    this.setState({ formChanged: true, formJustSaved: false, webhook });
  }

  onChangeEventType(target) {
    const { webhook } = this.state;

    webhook.update({ event_type: target.value });
    this.setState({ formChanged: true, formJustSaved: false, webhook });
  }

  onChangeApiVersion(target) {
    const { webhook } = this.state;

    webhook.update({ api_version: target.value });
    this.setState({ formChanged: true, formJustSaved: false, webhook });
  }

  onChangeWorkMode(target) {
    const { webhook } = this.state;

    webhook.update({ work_mode: target.value });
    this.setState({ formChanged: true, formJustSaved: false, webhook });
  }

  onChangeTestModelId(event) {
    this.setState({
      testMessageJustSent: false,
      testModelId: event.target.value,
    });
  }

  handleChange(e) {
    const data = {};
    const { webhook } = this.state;

    data[e.target.id] = e.target.value;
    webhook.update(data);
    this.setState({ formChanged: true, formJustSaved: false, webhook });
  }

  handleBlur() {
    const { webhook } = this.state;
    webhook.validate();
    this.setState({ webhook });
  }

  handleSubmit() {
    const { webhook } = this.state;
    webhook.validate();
    if (webhook.isValid()) {
      webhook.save(this.handleCommit);
    } else {
      this.setState({ webhook });
    }
  }

  removeWebhook() {
    const { webhook } = this.state;
    webhook.delete(() => {
      browserHistory.push('/dashboard/connected-apps/webhooks');
    });
  }

  handleCommit(response) {
    this.setState({ formChanged: false, formJustSaved: true });
    const { webhook } = this.state;
    if (webhook.isNew()) {
      browserHistory.push(`/dashboard/connected-apps/webhooks/${response}`);
    }
  }

  showRemoveModal(showRemoveModal) {
    this.setState({ showRemoveModal });
  }

  removeModal() {
    const { showRemoveModal } = this.state;
    if (showRemoveModal) {
      return (
        <CircleModal
          title="Remove webhook"
          dismissHandler={this.showRemoveModal.bind(this, false)}
        >
          <p>
            By deleting this web hook no further information will be sent to
            this app.
          </p>
          <Button small blue text="remove" handleClick={this.removeWebhook} />
        </CircleModal>
      );
    }
  }

  removeButton() {
    const { webhook } = this.state;
    if (webhook.isNew()) {
      return null;
    }
    return (
      <Button
        small
        red
        text="remove"
        handleClick={this.showRemoveModal.bind(this, true)}
      />
    );
  }

  onClickSendTest() {
    const { webhook, testModelId } = this.state;
    const testModelIdTrimmed = (testModelId || '').trim();
    if (testModelIdTrimmed === '') {
      this.setState({ testModelIdError: "Can't be blank" });
      return;
    }
    this.setState({ testModelIdError: null, testing: true });
    webhook.sendTest(
      testModelId,
      (_response) => {
        this.setState({ testMessageJustSent: true, testing: false });
      },
      (error) => {
        if (error.response.status === 403) {
          this.setState({
            testModelIdError:
              "Can't send the test message. Probably Application ID is wrong.",
            testing: false,
          });
          return;
        }

        switch (error.response.data.error) {
          case 'application_not_found':
            this.setState({
              testModelIdError:
                "Can't send the test message. Probably Application/Lead ID is wrong.",
              testing: false,
            });
            break;
          case 'resource_not_found':
            this.setState({
              testModelIdError:
                  "Can't send the test message. Probably Application/Lead ID is wrong.",
              testing: false,
            });
            break;
          case 'connection_not_found':
            this.setState({
              testModelIdError:
                "Can't send the test message. Application is found but it " +
                'is not Approved.',
              testing: false,
            });
            break;
          case 'application_invalid_state':
            this.setState({
              testModelIdError:
                "Can't send the test message. Application is not in " +
                'a valid state for the given event.',
              testing: false,
            });
            break;
          case 'lead_invalid_state':
            this.setState({
              testModelIdError:
                  "Can't send the test message. Lead is not in " +
                  'a valid state for the given event.',
              testing: false,
            });
            break;
          default:
            this.setState({
              testModelIdError:
                "Can't send the test message. The reason is unknown.",
              testing: false,
            });
        }
      }
    );
  }

  sandboxPanel() {
    const {
      webhook,
      testModelId,
      testModelIdError,
      testing,
      formChanged,
      testMessageJustSent,
    } = this.state;

    if (webhook.attributes.work_mode !== 'sandbox') {
      return (
        <Card>
          <div className={styles.row}>
            <div className="has-text-info">
              You can test a Webhook only if it is in Sandbox mode. Please
              switch the Work mode from Production to Sandbox and then click the
              Save button to enable Sandbox panel.
            </div>
          </div>
        </Card>
      );
    }

    if (webhook.isNew() || formChanged) {
      return (
        <Card>
          <div className={styles.row}>
            <div className="has-text-info">
              Please save the Webhook to test it.
            </div>
          </div>
        </Card>
      );
    }
    return (
      <Card>
        <div className={styles.row}>
          <div className={styles.header_col}>
            <Header>Sandbox</Header>
          </div>
          <form
            className={styles.two_column_form}
            onSubmit={(e) => e.preventDefault()}
          >
            <TextInput
              id="testModelId"
              onChange={this.onChangeTestModelId}
              handleBlur={this.handleBlur}
              label="Enter application/lead GUID"
              value={testModelId}
              error={testModelIdError}
              helper_text={
                'To get an application/lead GUID just copy the GUID from the URL in the Virtual Credit File. or lead details page ' +
                "E.g. for the following URL 'https://my.1centre.com/dashboard/applications/ae21ef5d-e6bd-47bf-bed0-2841f2746fdc' " +
                "the GUID is: 'ae21ef5d-e6bd-47bf-bed0-2841f2746fdc'"
              }
            />
            <Buttons>
              <Button
                small
                grey
                text="send a test message"
                loading={testing}
                disableOnLoading
                loading_text="testing"
                handleClick={this.onClickSendTest}
              />
            </Buttons>
            {testMessageJustSent && (
              <div className="mb-4">
                <FontAwesomeIcon icon="check-circle" />
                Test message has been sent
              </div>
            )}
          </form>
        </div>
      </Card>
    );
  }

  render() {
    const { webhook, formChanged, formJustSaved } = this.state;
    const { updating } = this.props;

    let title = 'Update webhook';
    let saveBtnLabel = 'save';
    let backBtnLabel = 'back';

    if (webhook.isNew()) {
      title = 'Create webhook';
      saveBtnLabel = 'save';
      backBtnLabel = 'back';
    }

    return (
      <div>
        <Card>
          <div className={styles.row}>
            <div className={styles.header_col}>
              <Header>{title}</Header>
            </div>
          </div>
          <form
            className={styles.two_column_form}
            onSubmit={(e) => e.preventDefault()}
          >
            <TextInput
              id="url"
              error={webhook.errors.url}
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="App URL"
              required={true}
              value={webhook.attributes.url}
            />
            <TextInput
              id="name"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="App name"
              required={true}
              value={webhook.attributes.name}
            />
            <OptionsDropdown
              id="event_type"
              name="event_type"
              label="Event type"
              error={webhook.errors.event_type}
              value={webhook.attributes.event_type}
              handleChange={this.onChangeEventType}
              handleBlur={this.handleBlur}
              required={true}
              options={EVENT_TYPE_OPTIONS}
            />
            <OptionsDropdown
              id="api_version"
              name="api_version"
              label="API version"
              error={webhook.errors.api_version}
              value={webhook.attributes.api_version}
              handleChange={this.onChangeApiVersion}
              handleBlur={this.handleBlur}
              required={true}
              options={Webhook.API_VERSIONS}
            />
            <EmailInput
              id="email"
              label="Email"
              name="email"
              type="email"
              value={webhook.attributes.email}
              helper_text="Exception notifications will be sent to this email. (This field is optional)"
              handleChange={this.onChangeEmail}
              handleBlur={this.handleBlur}
            />
            <TextInput
              id="secret"
              label="Signing secret"
              readOnly
              value={webhook.attributes.secret}
              type={'password'}
            />
            <TextInput
              id="hook_user_name"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="X-Hook-Username"
              required={false}
              value={webhook.attributes.hook_user_name}
            />
            <TextInput
              type={'password'}
              id="hook_user_password"
              handleChange={this.handleChange}
              handleBlur={this.handleBlur}
              label="X-Hook-Password"
              required={false}
              value={webhook.attributes.hook_user_password}
            />
            <OptionsDropdown
              id="work_mode"
              name="work_mode"
              label="Work mode"
              error={webhook.errors.work_mode}
              value={webhook.attributes.work_mode}
              handleChange={this.onChangeWorkMode}
              handleBlur={this.handleBlur}
              required={true}
              options={Webhook.WORK_MODES}
            />
          </form>
          <div className={styles.row}>
            <div className={styles.header_btn_col}>
              <Buttons>
                <Button
                  small
                  text={saveBtnLabel}
                  loading_text="saving"
                  loading={updating}
                  disableOnLoading
                  handleClick={this.handleSubmit}
                  disabled={!formChanged}
                />
                <Button
                  small
                  grey
                  text={backBtnLabel}
                  link="/dashboard/connected-apps/webhooks"
                />
                {this.removeButton()}
                {formJustSaved && (
                  <div className="mb-4">
                    <FontAwesomeIcon icon="check-circle" />
                    Webhook saved
                  </div>
                )}
              </Buttons>
            </div>
          </div>
        </Card>
        {this.sandboxPanel()}
        {this.removeModal()}
      </div>
    );
  }
}

module.exports = connect((state) => {
  return {
    formChanged: false,
    formJustSaved: false,
    testMessageJustSent: false,
    testModelId: '',
    testModelIdError: null,
    testing: state.models.webhooks.testing,
    updating: state.models.webhooks.updating,
  };
})(WebhookForm);
