export type RoleType = 'admin' | 'approver' | 'standard';

interface IUser {
  fullName: string;
  level: number;
  roleTypes: RoleType[];
  id: string;
}

export interface IFormattedUser {
  label: string;
  level: number;
  roleTypes: RoleType[];
  value: string;
}

const formatUsers = (users: IUser[]): IFormattedUser[] =>
  users
    .sort((a, b) => {
      if (a.fullName < b.fullName) {
        return -1;
      }

      if (a.fullName > b.fullName) {
        return 1;
      }

      return 0;
    })
    .map((user) => {
      return {
        label: user.fullName,
        level: user.level,
        roleTypes: user.roleTypes,
        value: user.id,
      };
    });

export default formatUsers;
