import flattenArray from 'utils/flattenArray';
import { Tier3, TreeData } from './types';

export const getUnusedT3s = (treeData: TreeData, t3s: Tier3[]) => {
  // Array of T3s already added under any of the groups
  const t3sUsed =
    treeData.length && treeData[0].children && treeData[0].children.length
      ? flattenArray(treeData[0].children.map((group) => group.children || []))
      : [];

  // @ts-ignore
  const t3sUsedNames = t3sUsed.map((t3) => t3.title);

  // Array of T3s not added under any group
  const t3sUnused = t3s.filter((t3) => t3sUsedNames.indexOf(t3.title) === -1);

  return t3sUnused;
};
