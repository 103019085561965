import React from 'react';

import styles from '../centrix.css';

import moment from 'moment';

class DriverLicenceVerification extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const { data_sets } = data;

    return (
      <div>
        <div className={styles.table_container}>
          <table className={styles.table + ' ' + styles.table_no_border}>
            <thead>
              <tr>
                <th className="has-text-weight-medium">
                  Driver Licence Verification:
                </th>
                <th className="has-text-weight-medium">Data Supplied:</th>
                <th className="has-text-weight-medium">Match?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>D/L No. & Version:</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Surname:</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>First Name:</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Middle Name:</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Date of Birth:</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DriverLicenceVerification;
