import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';


const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: `${theme.spacing(2)}px`,
  '& .MuiTextField-root': {
    paddingBottom: '0 !important',
  },
}));

const LeftContainer = styled(Box)({
  minWidth: 165,
});

import React from 'react';

type InputRowProps = {
  left: React.ReactNode;
  center: React.ReactNode;
  right?: React.ReactNode;
};

const InputRow: React.FC<InputRowProps> = (props) => {
  const { left, center, right } = props;
  return (
    <StyledContainer>
      <LeftContainer>{left}</LeftContainer>
      <Box>{center}</Box>
      {right && <Box>{right}</Box>}
    </StyledContainer>
  );
};

export default InputRow;
