import { amountTypeValue, countTypeValue } from './helpers/summary';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { displayEmpty, formatMoney } from 'utils/formatting';
import { enquiryResponseData, isBusiness } from './helpers/reportHelpers';
import styles from '../../../css/CommonReviewBusiness.css';
import { get } from 'lodash';
import { stringToSimpleDate } from 'utils/dateFormatter';
import { accountType, role } from './helpers/codeTable';

const BrokerEnquiries = ({ data, entityType, isAllExpanded }) => {
  const response = enquiryResponseData(data, entityType);

  let documents = get(
    response,
    'organisation_credit_history.all_credit_enquiry_list.broker_agent_enquiry_list.broker_agent_enquiry',
    []
  );
  if (documents.constructor === Object) documents = [documents];

  const co_borrower = (co_borrower) => {
    if (isBusiness(entityType)) return null;

    return (
      <tr>
        <th>Co-Borrower</th>
        <td>{displayEmpty(co_borrower)}</td>
      </tr>
    );
  };

  const documentRows = documents.map((document, index) => {
    return (
      <div className={styles.table_wrapper} key={`${index}-credit`}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Enquiry Date</th>
              <td>{stringToSimpleDate(document.enquiry_date)}</td>
              <th>Amount</th>
              <td>
                $
                {formatMoney(
                  parseFloat(document.amount),
                  undefined,
                  undefined,
                  undefined,
                  undefined
                )}
              </td>
            </tr>
            <tr>
              <th>Credit Enquirer</th>
              <td>{displayEmpty(document.enquirer)}</td>
              <th>Account Type</th>
              <td>{accountType((document.account_type || {}).type)}</td>
            </tr>
            <tr>
              <th>Reference Number</th>
              <td>{displayEmpty(document.ref_number)}</td>
              <th>Role in Enquiry</th>
              <td>{role((document.role || {}).type)}</td>
            </tr>
            {co_borrower(document.co_borrower)}
          </tbody>
        </table>
      </div>
    );
  });

  const summaryEntry = get(response, 'summary_data.summary_entry', []);

  return (
    <ReviewContainer
      subHeading="Broker Enquiries"
      content_class="content_wide"
      css_class="block_noborder"
      collapsible={true}
      isAllExpanded={isAllExpanded}
      fontSize="medium"
    >
      <p>
        <strong>
          Total: {countTypeValue(summaryEntry, 'Broker_Dealer_Enquiries')}
        </strong>
      </p>
      <p>
        <strong>
          Amount:{' '}
          {amountTypeValue(summaryEntry, 'Broker_Dealer_Enquiries_Value')}
        </strong>
      </p>
      {documentRows}
    </ReviewContainer>
  );
};

export default BrokerEnquiries;
