import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import AddonConfigModel from 'models/AddonConfigModel';
import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import { DigitalOnboardingItem } from 'modules/addons/components/DigitalOnboarding';
import React, { useContext } from 'react';
import isPresent from 'utils/isPresent';

function Item(props) {
  const {
    addonConfigs,
    currentUser,
    isIUFAddonConfigured,
    onUpdateModuleItem,
  } = props;

  const activeAddonConfig =
    addonConfigs.find((addonConfig) => addonConfig.active) ||
    new AddonConfigModel();

  if (addonConfigs.length === 0) {
    addonConfigs.push(
      new AddonConfigModel({
        attributes: {
          addon_module_name: 'internal_use_fields',
        },
      })
    );
  }

  // @ts-ignore-next-line
  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfigs);

  const onSuccessCallback = (updatedAddonConfig) => {
    mixpanel.track('Update IUF ruleset', {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Ruleset: updatedAddonConfig.data,
      'Ruleset ID': updatedAddonConfig.id,
      distinct_id: currentUser.id,
    });

    onUpdateModuleItem(updatedAddonConfig);
  };

  return (
    <DigitalOnboardingItem
      activeState={activeAddonConfig.active}
      areActionButtonsVisible={isIUFAddonConfigured}
      body="Set up and customise your own rulesets for internal communication, which will display on the VCFs for easy reference."
      formattedState={activeAddonConfig.formattedActiveState}
      header="Internal use fields"
      isConfigured={activeAddonConfig.isConfigured}
      isEnabled
      onClickEdit={onClickEdit}
    />
  );
}

export default Item;
