import styled from 'styled-components';
import { THEME_COLORS } from 'variables/theme';
import { FONT_SETS } from 'variables/typography';

export const Wrapper = styled.div`
  padding: 30px;
  position: relative;
`;

export const Header = styled.div`
  ${FONT_SETS.pageHeader};
  margin-bottom: 25px;
`;

export const SectionWrapper = styled.div`
  margin-top: 20px;
  &:not(:first-child) {
    margin-top: 30px;
  }

  &:first-child {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${THEME_COLORS.sectionBorder};
  }
`;

export const InlineIcon = styled.span`
  svg {
    fill: var(--main-color);
    vertical-align: middle;
    margin: -3px 5px 0px;

    :hover {
      fill: var(--main-color-active);
    }
  }
`;

export const SectionTitle = styled.div`
  ${FONT_SETS.sectionHeader}
  margin-bottom: 10px;
`;

export const SectionDescription = styled.div`
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;

    div:first-child {
      margin-right: 10px;
    }
  }

  @media (max-width: 1023px) {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;

    div:first-child {
      margin-top: 27px;
    }
  }
`;

export const MessageWrapper = styled.div`
  margin-top: 20px;
`;
