import React, { ReactElement } from 'react';
import { Bar } from '@ant-design/charts';
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getThemeColors } from '../../Panel/utils';
import { Wrapper } from './styles';

function ConversionRate(): ReactElement {
  const data = [
    {
      label: 'Application initiated',
      type: 'series1',
      value: 100,
    },
    {
      label: 'Application started',
      type: 'series1',
      value: 90,
    },
    {
      label: 'Application started',
      type: 'series2',
      value: 90,
    },
    {
      label: 'Application complete',
      type: 'series1',
      value: 81,
    },
    {
      label: 'Application complete',
      type: 'series2',
      value: 90,
    },
    {
      label: 'Signatories complete',
      type: 'series1',
      value: 65,
    },
    {
      label: 'Signatories complete',
      type: 'series2',
      value: 80,
    },
    {
      label: 'L1 approval',
      type: 'series1',
      value: 62,
    },
    {
      label: 'L1 approval',
      type: 'series2',
      value: 95,
    },
    {
      label: 'L2 approval',
      type: 'series1',
      value: 56,
    },
    {
      label: 'L2 approval',
      type: 'series2',
      value: 90,
    },
  ];

  const config = {
    data,
    height: 225,
    autoFit: true,
    isGroup: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    marginRatio: 0,
  };

  return (
    <Wrapper>
      <Bar
        {...config}
        xAxis={{
          grid: {
            line: {
              style: {
                stroke: 'transparent',
              },
            },
          },
          label: {
            style: {
              opacity: 0,
              fillOpacity: 0,
            },
          },
        }}
        yAxis={{
          nice: false,
          tickCount: 1,
          tickInterval: 1,
        }}
        legend={false}
        color={getThemeColors()}
        label={{
          position: 'middle',
          layout: [
            { type: 'interval-adjust-position' },
            { type: 'interval-hide-overlap' },
            { type: 'adjust-color' },
          ],
        }}
      />
    </Wrapper>
  );
}

export default ConversionRate;
