import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from './axios';

export default class Onboarding {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = 'onboarding';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  applicationRedirectLink({
    applicationId,
    consumerId,
    email,
  }: {
    applicationId: string;
    consumerId: string;
    email: string;
  }): Promise<void> {
    return this.axios.get(`${this.path}/application_redirect_link`, {
      params: {
        application_id: applicationId,
        consumer_id: consumerId,
        email,
      },
    });
  }

  applicationAccessibility({
    applicationId,
    token,
  }: {
    applicationId: string;
    token: string;
  }): Promise<void> {
    return this.axios.get(`${this.path}/application_accessibility`, {
      params: { application_id: applicationId, token },
    });
  }

  fetchApplicationDetails(applicationId: string): Promise<void> {
    return this.axios.get(`${this.path}/fetch_application_details`, {
      params: { application_id: applicationId },
    });
  }

  applicationDetails(params): Promise<void> {
    return this.axios.post(`${this.path}/application_details`, params);
  }

  entityDetails(params): Promise<void> {
    return this.axios.post(`${this.path}/entity_details`, params);
  }

  requestVerificationCode({
    applicationId,
    email,
    supplierId,
  }: {
    applicationId?: string;
    email: string;
    supplierId: string;
  }): Promise<void> {
    return this.axios.post(`/${this.type}/request_verification_code`, {
      application_id: applicationId,
      email,
      supplier_id: supplierId,
    });
  }
}
