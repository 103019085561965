import styled from 'styled-components';

export const Actions = styled.div`
  margin: -1rem;

  > * {
    margin: 1rem;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
