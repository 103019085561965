import 'cropperjs/dist/cropper.css';

import React, { Component } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import Cropper from 'cropperjs';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import PopperToolTip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import PropTypes from 'prop-types';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import isBlank from 'utils/isBlank';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import styles from './css/ImageEditor.css';

export default class ImageEditor extends Component {
  get imageMaxHeight() {
    const { imageMaxHeight } = this.props;

    if (isBlank(imageMaxHeight)) {
      return {};
    }

    return { maxHeight: imageMaxHeight };
  }

  constructor(props) {
    super(props);

    this.state = {
      isEditMode: false,
    };
  }

  componentDidMount() {
    if (this.props.autoEnableEdit) {
      this.onStartEdit();
    }
  }

  componentWillUnmount() {
    this.onCleanupCropper();

    if (this.img) {
      this.img = null;
    }
  }

  onCleanupCropper() {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
  }

  onStartEdit() {
    if (this.cropper) {
      return;
    }

    const { cropperOptions } = this.props;
    this.cropper = new Cropper(this.img, {
      ...cropperOptions,
      crop: () => {
        this.onCropping(this.cropper);
      },
      ready: () => {
        this.onCropperReady(this.cropper);
      },
    });
    this.setState({ isEditMode: true });
  }

  /**
   * Fired whenever there is an interaction with the editor
   */
  onCropping(cropper) {
    if (this.props.onCropping) {
      this.props.onCropping(cropper);
    }
  }

  /**
   * Fired when the cropper instance is ready
   */
  onCropperReady(cropper) {
    if (this.props.onCropperReady) {
      this.props.onCropperReady(cropper);
    }
  }

  /**
   * Fired when the user stops editing, either by completing the crop process
   * or discarding the changes
   */
  onStopEdit() {
    this.setState({ isEditMode: false });

    if (this.props.onStopEdit) {
      this.props.onStopEdit();
    }

    this.onCleanupCropper();
  }

  onRotate(degree) {
    if (this.cropper) {
      this.cropper.rotate(degree);
    }
  }

  onZoom(ratio) {
    if (this.cropper) {
      this.cropper.zoom(ratio);
    }
  }

  /**
   * Fired when the user completes the editing process and proceed with the
   * changes
   */
  onCropImage() {
    if (!this.cropper) {
      return;
    }

    const croppedImage = this.cropper.getCroppedCanvas().toDataURL('image/png');
    const onCrop = this.props.onCropImage;
    if (onCrop) {
      onCrop(croppedImage);
    }

    this.onStopEdit();
  }

  renderControls() {
    if (this.state.isEditMode) {
      return this.renderEditModeControls();
    }

    return this.renderViewModeControls();
  }

  renderViewModeControls() {
    return (
      <PopperToolTip title="Edit image">
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={this.onStartEdit.bind(this)}
        >
          <EditIcon />
        </IconButton>
      </PopperToolTip>
    );
  }

  renderEditModeControls() {
    return (
      <div className={styles.button_controls}>
        <PopperToolTip title="Rotate left">
          <IconButton
            aria-label="rotate-left"
            color="primary"
            onClick={this.onRotate.bind(this, -90)}
          >
            <RotateLeftIcon />
          </IconButton>
        </PopperToolTip>
        <PopperToolTip title="Rotate right">
          <IconButton
            aria-label="rotate-right"
            color="primary"
            onClick={this.onRotate.bind(this, 90)}
          >
            <RotateRightIcon />
          </IconButton>
        </PopperToolTip>
        <PopperToolTip title="Zoom in">
          <IconButton
            aria-label="zoom-in"
            color="primary"
            onClick={this.onZoom.bind(this, 0.1)}
          >
            <ZoomInIcon />
          </IconButton>
        </PopperToolTip>
        <PopperToolTip title="Zoom out">
          <IconButton
            aria-label="zoom-out"
            color="primary"
            onClick={this.onZoom.bind(this, -0.1)}
          >
            <ZoomOutIcon />
          </IconButton>
        </PopperToolTip>
        <PopperToolTip title="Done">
          <IconButton
            aria-label="done"
            color="primary"
            onClick={this.onCropImage.bind(this)}
          >
            <DoneIcon />
          </IconButton>
        </PopperToolTip>
        <PopperToolTip title="Discard changes">
          <IconButton
            aria-label="cancel"
            color="primary"
            onClick={this.onStopEdit.bind(this)}
          >
            <CancelIcon />
          </IconButton>
        </PopperToolTip>
      </div>
    );
  }

  render() {
    const { imageName, imageSrc } = this.props;

    return (
      <MuiThemeProvider theme={muiTheme()}>
        <div className={styles.image_container}>
          <img
            alt={imageName}
            ref={(img) => {
              this.img = img;
            }}
            src={imageSrc}
            style={this.imageMaxHeight}
          />
        </div>
        {this.renderControls()}
      </MuiThemeProvider>
    );
  }
}

ImageEditor.propTypes = {
  autoEnableEdit: PropTypes.bool,
  cropperOptions: PropTypes.object.isRequired,
  imageMaxHeight: PropTypes.string,
  imageName: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  onCropImage: PropTypes.func,
  onCropperReady: PropTypes.func,
  onCropping: PropTypes.func,
  onStopEdit: PropTypes.func,
};

ImageEditor.defaultProps = {
  autoEnableEdit: false,
  cropperOptions: {},
};
