import React, { Fragment } from 'react';
import ReactTimeout from 'react-timeout';

import { debounce } from 'throttle-debounce';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import { identityComponentComplete } from 'modules/identity/actions';

/* Import actions */
import { submitIdentification } from '../actions';

import ArrowButton from 'modules/shared/components/widgets/interactive/ArrowButton';
import Loader from 'modules/shared/components/widgets/static/Loader';
import Button from 'modules/shared/components/inputs/Button';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import styles from './css/GuarantorOnBoarding.css';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import MaterialButton from '@material-ui/core/Button';

import {
  loadGuarantorInfo,
  guarantorLogin,
  showWarning,
} from 'modules/guarantor/actions';

import { isMobile } from 'modules/shared/helpers/mobileDetect';

import IdentityCapture from 'modules/identity/components/IdentityCapture/IdentityCapture';
import IdentityConfirm from 'modules/identity/components/IdentityConfirm';
import ApplicationProgressDots from 'modules/shared/components/widgets/static/ApplicationProgressDots';
import ZendeskChat from 'modules/shared/components/widgets/interactive/ZendeskChat';
import { FEATURE_FLAGS } from 'conf';

const TIME_DELAY_FOR_REVIEW = 1500;

var GuarantorOnBoarding = createClass({
  componentWillMount: function () {
    const {
      dispatch,
      location: {
        query: { g, t },
      },
      component,
    } = this.props;
    if (g && t) {
      dispatch(
        guarantorLogin(g, t, () => {
          dispatch(loadGuarantorInfo(g));
        })
      );
    }
  },

  submitIdentification: function () {
    const { dispatch, location, identity_image_64, guarantor } = this.props;
    const submit = () => {
      dispatch(
        submitIdentification(guarantor.id, () => {
          browserHistory.push({
            pathname: '/guarantor/terms',
            query: location.query,
          });
        })
      );
    };
    if (!identity_image_64) {
      dispatch(
        showWarning({
          title: 'Important',
          size: 'small',
          details:
            "We noticed your ID hasn't been uploaded. This could delay your application. Do you wish to proceed anyway?",
          yesHandler: () => {
            submit();
            dispatch(showWarning(null));
          },
          noHandler: () => {
            dispatch(showWarning(null));
          },
        })
      );
    } else {
      submit();
    }
  },

  /**
   * Helper function used to format the path to this section/component
   */
  formatLink: function (section, component) {
    const { location } = this.props;
    let query = '?g=' + location.query.g + '&t=' + location.query.t;
    var link = `/guarantor/${section}`;
    if (component) {
      link = `${link}/${component}${query}`;
    } else {
      link = `${link}${query}`;
    }
    return link;
  },

  setPageValidationStartFinish: function () {
    this.setState({
      page_validation_start: false,
    });
  },

  /**
   * Returns the properties for the current section and component.
   */
  getComponentProps: function () {
    const { section, component, location, guarantor } = this.props;
    const { page_validation_start } = this.state;
    const { setPageValidationStartFinish } = this;
    var complete = false;
    return {
      ...section,
      location,
      page_validation_start,
      handleComplete: this.updateCompleteState,
      component: component,
      complete: complete,
      isGuarantor: true,
      setPageValidationStartFinish,
      guarantor: guarantor,
    };
  },

  /**
   * Helper function that returns links and buttons for the given
   * section/component.
   */
  getArrowLinks: function () {
    const { component, section, sections_complete, submitting } = this.props;
    const { page_complete } = this.state;

    var current_index = 0;
    var links = [];
    var flow = this.getSectionLinks();
    if (component) {
      // Lower level component pages.
      flow.forEach((item, index) => {
        if (index == flow.length - 1) {
          if (component === item.component && section === item.section) {
            if (page_complete === false) {
              links.push(
                <ArrowButton
                  down={false}
                  next={false}
                  text="Next"
                  disabled={true}
                  handleClick={() =>
                    this.setState({ page_validation_start: true })
                  }
                />
              );
            } else {
              links.push(
                <Button
                  text="Next"
                  handleClick={this.submitIdentification}
                  loading_text="saving"
                  disableOnLoading={true}
                  loading={submitting}
                />
              );
            }
          }
        } else {
          if (component === item.component && section === item.section) {
            if (page_complete === false) {
              links.push(
                <ArrowButton
                  down={false}
                  next={false}
                  text="Next"
                  disabled={true}
                  handleClick={() =>
                    this.setState({ page_validation_start: true })
                  }
                />
              );
            } else {
              links.push(
                <ArrowButton
                  down={false}
                  next={false}
                  text="Next"
                  link={this.formatLink(section, flow[index + 1].component)}
                />
              );
            }
          }
        }
      });
    }
    return links;
  },

  isComponentComplete: function (section, component) {
    const { completed } = this.props;
    if (completed.hasOwnProperty(section)) {
      if (completed[section].hasOwnProperty(component)) {
        return completed[section][component];
      }
    }
    return false;
  },

  getSectionLinkArray: function () {
    var links = this.getSectionLinks();
    var link_array = [];
    links.forEach((item) => {
      link_array.push(this.formatLink(item.section, item.component));
    });
    return link_array;
  },

  /**
   * Get the button links for this section and flow.
   */
  getSectionLinks: function () {
    const { section, no_identification, guarantor } = this.props;

    var links = [];
    if (
      guarantor &&
      guarantor.application.attributes.requires_guarantees_identification_check
    ) {
      links.push({
        required: true,
        section: section,
        component: 'capture',
        complete: this.isComponentComplete(section, 'capture'),
      });
      links.push({
        required: true,
        section: section,
        component: 'confirm',
        complete: this.isComponentComplete(section, 'confirm'),
      });
    }
    return links;
  },

  /**
   * Helper function to get the dots along the top or the right.
   *
   * TODO: Make this check for completion.
   */
  componentGetDots: function () {
    const { section, component } = this.props;
    if (isMobile()) {
      var flow = this.getSectionLinks(section);
      let id_index = flow.findIndex((i) => i.component === component) + 1;
      let mobile_index = 1 + id_index; // default 1 is review
      let mobile_pages = 3 + flow.length; // default 3 is review, terms and confirm

      return (
        <ApplicationProgressDots
          type="horizontal"
          mobile_pages={mobile_pages}
          mobile_index={mobile_index}
        />
      );
    }
    return;
  },

  getInitialState: function () {
    return {
      animation: 'scroll-down',
      page_complete: false,
      showLoader: true,
      page_validation_start: false,
    };
  },

  componentWillReceiveProps: function (newProps) {
    const { dispatch, section, component, location } = this.props;

    // Run section complete check, but only if we're on the section start page.
    if (component && !newProps.component) {
      this.isSectionComplete();
    }

    if (location.pathname != newProps.location.pathname) {
      var current_location = location.pathname;
      var new_location = newProps.location.pathname;
      var links = this.getSectionLinkArray();

      if (component) {
        if (links.indexOf(current_location) > links.indexOf(new_location)) {
          if (links.indexOf(new_location) < 0) {
            this.setState({
              animation: 'rotate-up',
            });
          } else {
            this.setState({
              animation: 'scroll-up',
            });
          }
        } else {
          this.setState({
            animation: 'scroll-down',
          });
        }
      }
    }
  },

  scroll: function (e) {
    const { section, component, location } = this.props;
    const { page_complete } = this.state;

    var current_location = location.pathname;
    let query = '?g=' + location.query.g + '&t=' + location.query.t;
    var section_links = this.getSectionLinks();
    var links = this.getSectionLinkArray();

    var current_index = links.indexOf(current_location + query);

    // Reduce sensitiviity for scroll on mac.
    if (Math.abs(e.deltaY) < 50.0) {
      return;
    }

    if (component) {
      if (e.deltaY > 0) {
        if (page_complete === false) {
          // ALERT???
          return;
        }
        this.scrollToRoute(links[current_index + 1]);
      } else {
        // scrollup
        if (current_index > 0) {
          this.scrollToRoute(links[current_index - 1]);
        } else {
          //this.scrollToRoute(this.formatLink(section));
        }
      }
    }
  },

  scrollToRoute: function (route) {
    browserHistory.push(route);
  },

  componentDidMount: function () {
    const { dispatch, component, location, guarantor } = this.props;
    if (
      component != 'capture' &&
      guarantor &&
      guarantor.application.attributes.requires_guarantees_identification_check
    ) {
      browserHistory.push({
        pathname: '/guarantor/identity/capture',
        query: location.query,
      });
    }
    this.scrollToRoute = debounce(200, true, this.scrollToRoute);
  },

  loadingComplete: function () {
    this.setState({
      showLoader: false,
    });
  },

  updateCompleteState: function (state) {
    const { section, component, completed, dispatch } = this.props;
    if (completed.identity[component] !== state) {
      dispatch(identityComponentComplete(component, state));
    }
    this.setState({
      page_complete: state,
    });
  },

  getBackLinks: function () {
    const {
      section,
      component,
      component_index,
      submitting,
      consumer_account,
    } = this.props;
    var links = [];
    var flow = this.getSectionLinks(section);
    var index = flow.findIndex((i) => i.component === component);

    if (submitting) {
      return;
    }
    if (index > 0) {
      links.push(
        <ArrowButton
          prev={false}
          text="Back"
          link={this.formatLink(section, flow[index - 1].component)}
        />
      );
    } else {
      links.push(
        <ArrowButton
          prev={false}
          text="Back"
          link={this.formatLink('review', null)}
        />
      );
    }
    return links;
  },

  /**
   * React render
   */
  render: function () {
    const {
      loading,
      guarantor,
      section,
      component,
      config,
      supplier_logo_url,
      warning,
    } = this.props;
    const { animation, showLoader, page_complete } = this.state;
    var enter, enterActive, leave, leaveActive;
    let flow_wrap_styles = styles.flow_wrap;

    switch (animation) {
      case 'rotate-left':
      case 'rotate-up':
        enter = 'enterRotateUp';
        enterActive = 'enterRotateUpActive';
        leave = 'leaveRotateUp';
        leaveActive = 'leaveRotateUpActive';
        break;
      case 'rotate-right':
      case 'rotate-down':
        enter = 'enterRotateDown';
        enterActive = 'enterRotateDownActive';
        leave = 'leaveRotateDown';
        leaveActive = 'leaveRotateDownActive';
        break;
      case 'scroll-up':
        enter = 'enterScrollUp';
        enterActive = 'enterActiveScrollUp';
        leave = 'leaveScrollUp';
        leaveActive = 'leaveActiveScrollUp';
        break;
      case 'scroll-down':
      default:
        enter = 'enterScrollDown';
        enterActive = 'enterActiveScrollDown';
        leave = 'leaveScrollDown';
        leaveActive = 'leaveActiveScrollDown';
        break;
    }

    var props = this.getComponentProps();
    var child_component;
    var links;
    var color = isMobile() ? 'white' : 'grey';
    var dots = this.componentGetDots();

    if (showLoader) {
      return (
        <Loader
          handleComplete={this.loadingComplete}
          loading={!guarantor || loading}
        />
      );
    }

    if (component === 'capture') {
      child_component = <IdentityCapture {...props} />;
    }
    if (component === 'confirm') {
      child_component = <IdentityConfirm {...props} />;
    }

    let warning_modal;
    if (warning) {
      warning_modal = (
        <SquareModal title={warning.title} size={warning.size}>
          <div>
            <p>{warning.details}</p>
            <div>
              <MaterialButton color="primary" onClick={warning.yesHandler}>
                Yes
              </MaterialButton>
              <MaterialButton color="primary" onClick={warning.noHandler}>
                No
              </MaterialButton>
            </div>
          </div>
        </SquareModal>
      );
    }

    links = (
      <div className={styles.bottom_buttons}>
        <div>{this.getArrowLinks()}</div>
        <div>{this.getBackLinks()}</div>
      </div>
    );

    var overlay = (
      <Fragment>
        <ApplicationOverlay
          trading_name={guarantor.application.attributes.supplier_name}
          logo_url={supplier_logo_url}
          label={'Guarantor approval'}
          color={color}
        />
      </Fragment>
    );

    let content = null;

    if (!isMobile()) {
      content = (
        <div className={styles.outer}>
          <div className={styles.flow}>
            <div className={flow_wrap_styles}>
              <div className={styles.page} key={this.props.location.pathname}>
                <div className={styles.container}>
                  <div className={styles.application_content}>
                    {child_component}
                    {warning_modal}
                  </div>
                  {links}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.controls}>{overlay}</div>
        </div>
      );
    } else {
      content = (
        <div>
          <div className={styles.page}>
            <div className={styles.container}>
              <div className={styles.application_content}>
                {child_component}
                {warning_modal}
              </div>
              {links}
            </div>
          </div>
          <div className={styles.controls}>
            {overlay}
            {dots}
          </div>
        </div>
      );
    }

    return <div>{content}</div>;
  },
});
const config = {
  title: 'Guarantor approval',
};

module.exports = connect((state, ownProps) => {
  var component, guarantor, logo_url;

  if (ownProps.params.component !== undefined) {
    component = ownProps.params.component;
  }
  if (state.guarantor.data) {
    guarantor = state.guarantor.data;
    if (state.guarantor.data.application.attributes.supplier_logo) {
      logo_url =
        state.guarantor.data.application.attributes.supplier_logo.logo.url;
    }
  }

  return {
    completed: {
      identity: state.identity.completed,
    },
    loading: state.guarantor.loading,
    guarantor: guarantor,
    sections_complete: {
      identity: state.identity.section_complete,
    },
    config: config,
    section: 'identity',
    component: component,
    no_identification: state.identity.noIdentification,
    submitting: state.guarantor.submitting,
    supplier_logo_url: logo_url,
    identity_image_64: state.identity.image_64,
    warning: state.guarantor.warning,
  };
})(ReactTimeout(GuarantorOnBoarding));
