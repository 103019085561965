import { setColorPalette } from 'modules/profile/actions.js';
import styles from 'modules/profile/components/css/CommonEditProfile.css';
import SketchButton from 'modules/profile/components/SketchButton.js';
import VideoModal from 'modules/shared/components/widgets/interactive/VideoModal';
import { defaultTheme, setTheme } from 'modules/shared/helpers/colorPalettes';
import React from 'react';
import { connect } from 'react-redux';

import { Description, Header } from './styles';

class EntityColorPaletteTemplates extends React.Component {
  handleChange(color) {
    const { dispatch, colorPalette } = this.props;
    const newColorPalette = Object.assign(colorPalette, color);
    if (color.accept) {
      dispatch(setColorPalette(newColorPalette));
    }
    setTheme(newColorPalette);
  }

  renderColorItem(colorItem) {
    return (
      <li key={colorItem.name} className={styles.item}>
        {colorItem.description}
        <SketchButton
          name={colorItem.name}
          color={this.props.colorPalette[colorItem.name]}
          usedColor={this.props.usedColor[colorItem.name] || []}
          onChange={(color) => this.handleChange(color)}
        />
      </li>
    );
  }

  render() {
    return (
      <div>
        <div className={styles.row}>
          <div className={styles.block_wide}>
            <div className={styles.liner}>
              <Header>
                {defaults.title}
                <VideoModal videoName={'color_palette'} />
              </Header>
              <Description>{defaults.description}</Description>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.block_wide}>
            <div className={styles.liner}>
              <ul className={styles.list}>
                {supplierSideColorPalette.map((colorItem) =>
                  this.renderColorItem(colorItem)
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const defaults = {
  description:
    'Click the colour blocks below, doing this will change the colour automatically. If you’d like to undo - simply click back in and click cancel.',
  title: 'Customise your colour palette',
};

const supplierSideColorPalette = [
  {
    description: 'Main colour',
    name: 'main_color',
  },
  {
    description: 'Main colour active',
    name: 'main_color_active',
  },
  {
    description: 'Main font colour',
    name: 'main_font_color',
  },
];

module.exports = connect((state) => {
  const colorPaletteSetting = state.manage_profile.settings_color_palette || {};
  const colorPalette = colorPaletteSetting.theme || defaultTheme;
  const usedColor = colorPaletteSetting.used_color || {};

  return {
    colorPalette,
    usedColor,
  };
})(EntityColorPaletteTemplates);
