/* Import libraries */
import React from "react";

import Clickable from "modules/shared/components/widgets/interactive/Clickable";

/* Import CSS */
import styles from "./css/Switch.css";

type Props = {
  buttonCssOverrides?: any,
  current?: string,
  cssOverrides?: any,
  targetRight?: () => void,
  targetLeft?: () => void,
  leftOption: string,
  isLeftOptionActive?: boolean,
  leftLabel?: string,
  rightOption: string,
  isRightOptionActive?: boolean,
  rightLabel?: string,
  name?: string,
  handleChange: (value: string) => void,
  grey?: string,
};

function Switch(props: Props) {
  let {
    buttonCssOverrides,
    current,
    cssOverrides,
    targetRight,
    targetLeft,
    leftOption,
    leftLabel,
    isLeftOptionActive,
    rightOption,
    rightLabel,
    isRightOptionActive,
    name,
    handleChange,
    grey,
  } = props;

  let button1 = "button";
  let button2 = "button";
  let switchState = "";

  isLeftOptionActive = isLeftOptionActive ?? true;
  isRightOptionActive = isRightOptionActive ?? true;

  if (current === leftOption) {
    button1 = "current";
    switchState = "";
  }

  if (current === rightOption) {
    button2 = "current";
    switchState = styles.switchOn;
  }

  let switchStyles = [styles.switch, switchState].join(" ");
  let button1Styles = styles[button1];
  let button2Styles = styles[button2];

  if (grey) {
    switchStyles = [styles.grey_switch, switchState].join(" ");
    button1Styles = styles[`grey_${button1}`];
    button2Styles = styles[`grey_${button2}`];
  }

  const sharedButtonStyle: React.CSSProperties = {
    textAlign: 'center',
    width: '100%',
  };

  const button1Component = <Clickable
      onClick={
          (handleChange && handleChange.bind(null, leftOption)) || targetLeft
      }
      target={targetLeft}
      inline={true}
      buttonCssOverrides={buttonCssOverrides}
  >
      <div
          className={button1Styles}
          style={sharedButtonStyle}
      >
          {leftLabel}
      </div>
  </Clickable>;

  const button2Component = <Clickable
      onClick={
        (handleChange && handleChange.bind(null, rightOption)) ||
        targetRight
      }
      target={targetRight}
      inline={true}
      buttonCssOverrides={buttonCssOverrides}
  >
    <div
        className={button2Styles}
        style={sharedButtonStyle}
    >
      {rightLabel}
    </div>
  </Clickable>;

  return (
    <div className={switchStyles} style={cssOverrides}>
      {isLeftOptionActive && (button1Component)}
      {isRightOptionActive && (button2Component)}
    </div>
  );
}

export default Switch;
