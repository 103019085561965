import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';

type Plaintiff = {
  companyName: string;
  tradingAs: string | null;
  familyName: string | null;
  firstName: string | null;
  secondName: string | null;
  address: string | null;
  suburb: string | null;
  city: string | null;
  occupation: string | null;
};

type Judgment = {
  judgmentID: string;
  procType: string | null;
  npNo: string | null;
  npYear: string | null;
  court: string;
  npVariant: string | null;
  civNo: string;
  folioNo: number;
  folioYear: string;
  sealedDate: string;
  type: string;
  amount: number;
  multipleDefendants: boolean;
  plaintiffs: Plaintiff[];
  statusDate: string;
  status: string;
  statusSource: string;
};

type Props = {
  data: Judgment[];
};

function Judgments(props: Props): ReactElement {
  const { data } = props;

  const getJudgments = () =>
    data.map(
      ({
        procType,
        npNo,
        npYear,
        court,
        npVariant,
        civNo,
        folioNo,
        folioYear,
        sealedDate,
        type,
        amount,
        multipleDefendants,
      }) => (
        <div key={`${npNo}`}>
          <h4>Judgment</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Proceeding Type</th>
                <td>{procType}</td>
              </tr>
              <tr>
                <th>Nop Number</th>
                <td>{npNo}</td>
              </tr>
              <tr>
                <th>Nop Year</th>
                <td>{npYear}</td>
              </tr>
              <tr>
                <th>Nop Court</th>
                <td>{court}</td>
              </tr>
              <tr>
                <th>Nop Variant</th>
                <td>{npVariant}</td>
              </tr>
              <tr>
                <th>Civ Number</th>
                <td>{civNo}</td>
              </tr>
              <tr>
                <th>Folio Number</th>
                <td>{folioNo}</td>
              </tr>
              <tr>
                <th>Folio Year</th>
                <td>{folioYear}</td>
              </tr>
              <tr>
                <th>Sealed Date</th>
                <td>{moment(sealedDate).format('DD-MM-YYYY')}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{type}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>{amount}</td>
              </tr>
              <tr>
                <th>Has Multiple Defendants</th>
                <td>{multipleDefendants ? 'Yes' : 'No'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    );

  return (
    <div className={historyStyles.container}>
      <div>
        <SubHeading>Judgments</SubHeading>
        <em>Judgments search is limited to the last seven years.</em>
      </div>
      {data.length === 0 && (
        <p>There have been no judgments filed against this company. </p>
      )}
      {data.length > 0 && getJudgments()}
    </div>
  );
}

export default Judgments;
