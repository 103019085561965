import { enquiryResponseData, isCompany } from './helpers/reportHelpers';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { countTypeValue } from './helpers/summary';
import styles from '../../../css/CommonReviewBusiness.css';

var FileMessages = createClass({
  _companyFileMessages() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      organisationLegal = response.organisation_legal || {},
      fileMessageList = organisationLegal.file_message_list || {},
      fileMessages = fileMessageList.file_message || [];

    if (fileMessages.constructor === Object) fileMessages = [fileMessages];

    return fileMessages;
  },

  _businessFileMessages() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      corePublicRecords = response.core_public_records || {},
      fileMessageList = corePublicRecords.file_message_list || {},
      fileMessages = fileMessageList.file_message || [];

    if (fileMessages.constructor === Object) fileMessages = [fileMessages];

    return fileMessages;
  },

  _fileMessages() {
    const { entityType } = this.props;

    if (isCompany(entityType)) return this._companyFileMessages();

    return this._businessFileMessages();
  },

  _messages() {
    return this._fileMessages().map((message, index) => {
      return <p key={index}>{message.narrative}</p>;
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="File Messages"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <strong>Total: {countTypeValue(summaryEntry, 'File_Messages')}</strong>
        {this._messages()}
      </ReviewContainer>
    );
  },
});

export default FileMessages;
