import get from 'lodash.get';
import {
  CollateralsForm,
  DebtorsForm,
} from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/NZPPSRForm';
import { FinancingStatementPin } from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterForm';
import { ExpiryDate } from 'modules/new-applications/components/application-actions/PPSRRegister/shared/RegisterDetails';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import FormCheckBox from 'modules/shared/components/inputs/FormCheckBox';
import moment from 'moment';
import React, { Fragment, useState } from 'react';

function ExpiryDateCalendarPicker(props) {
  const {
    defaultExpiryDate,
    errors,
    isVisible,
    onSelectDate,
    register,
    selectedDate,
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <GridContent>
      <BorderedCalendarPicker
        textFieldProps={{
          error: Boolean(errors.expiryDate),
          helperText: get(errors, 'expiryDate.message', ' '),
          id: 'renew-expiry-date-datepicker',
          inputRef: register,
          name: 'expiryDate',
        }}
        minDate={new Date()}
        maxDate={defaultExpiryDate}
        value={selectedDate}
        onChange={onSelectDate}
        withBottomMargin={false}
      />
    </GridContent>
  );
}

function ExpiryDateForm(props) {
  const {
    control,
    errors,
    ppsrFinancingStatement,
    register,
    setValue,
    watch,
  } = props;

  const defaultExpiryDate = moment(ppsrFinancingStatement.expiryDate).toDate();

  const [selectedDate, setSelectedDate] = useState(defaultExpiryDate);
  const onSelectDate = (date) => {
    setSelectedDate(date);
    setValue('expiryDate', date);
  };

  const isAutoRenew = watch(
    'autoRenew',
    ppsrFinancingStatement.autoRenew || false
  );

  return (
    <FixedContent>
      <ExpiryDate ppsrFinancingStatement={ppsrFinancingStatement} />
      <div className="mt-4">
        <GridContent>
          <div className={styles.checkbox_container}>
            <FormCheckBox
              id="autoRenew"
              label="Auto renewal"
              control={control}
              value={true}
              register={register}
              defaultValue={ppsrFinancingStatement.autoRenew || false}
              error={get(errors, 'autoRenew.message', '')}
              isCompact={true}
            />
          </div>
        </GridContent>
        <ExpiryDateCalendarPicker
          defaultExpiryDate={defaultExpiryDate}
          errors={errors}
          isVisible={!isAutoRenew}
          onSelectDate={onSelectDate}
          register={register}
          selectedDate={selectedDate}
        />
      </div>
    </FixedContent>
  );
}

export default function NZPPSRModifyForm(props) {
  const {
    application,
    clearError,
    collateralFieldArray,
    control,
    currentUser,
    debtorFieldArray,
    errors,
    ppsrFinancingStatement,
    register,
    setValue,
    watch,
  } = props;

  return (
    <Fragment>
      <ExpiryDateForm
        control={control}
        errors={errors}
        ppsrFinancingStatement={ppsrFinancingStatement}
        register={register}
        setValue={setValue}
        watch={watch}
      />
      <DebtorsForm
        debtorFieldArray={debtorFieldArray}
        application={application}
        currentUser={currentUser}
        errors={errors}
        register={register}
        setValue={setValue}
      />
      <CollateralsForm
        clearError={clearError}
        collateralFieldArray={collateralFieldArray}
        currentUser={currentUser}
        errors={errors}
        isCollateralTypeFieldEnabled={false}
        ppsrAddonConfig={application.ppsrAddonConfig || {}}
        register={register}
        setValue={setValue}
      />
      <FinancingStatementPin
        actionText="modification"
        errors={errors}
        region="NZ"
        register={register}
      />
    </Fragment>
  );
}
