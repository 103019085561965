import axios from './axios';

export default class ModuleCardholderApplications {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'module_cardholder_applications';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
    this.path = `/${this.type}`;
  }

  getModuleCardholderApplications(params) {
    return this.axios.get(this.path, { params });
  }

  getModuleCardholderApplication(id, params) {
    return this.axios.get(`${this.path}/${id}`, { params });
  }

  update(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, attributes);
  }

  loadedInSystem(id) {
    return this.axios.patch(`${this.path}/${id}/loaded_in_system`);
  }

  export(params) {
    return this.axios.get(`${this.path}/export`, { params });
  }
}
