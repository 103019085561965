import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import get from 'lodash.get';

import CloseButton from 'modules/shared/components/inputs/CloseButton';
import Switch3 from 'modules/shared/components/widgets/interactive/Switch3';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import Button from 'modules/shared/components/inputs/Button';
import GuarantorHistoryLogs from './GuarantorHistoryLogs';
import { ImpactAutoDecisionRulesetPopup } from 'modules/shared/components/widgets/static/ImpactAutoDecisionRulesetPopup/ImpactAutoDecisionRulesetPopup';
import { Wrapper } from 'modules/shared/components/v2/SupplierSetup/styles';
import {
  ActionsWrapper,
  ButtonContainer,
  CheckboxWithMessageWrapper,
  DropdownWrapper,
  Section,
  SubTextWrapper,
  SubsectionTitle,
  Title,
} from './styles';

import {
  setGuarantorValues,
  updateMandatoryChecks,
} from 'modules/profile/actions';
import { updateRuleSet } from 'modules/addons/auto_decisioning/actions';
import { IRuleSetAttributes } from 'modules/addons/auto_decisioning/v2/RulesetContent/type';
import { IGuarantorModal, ILocalGuaranteeSettings } from './types';
import { getRulesetNames } from 'utils/auto-decision';
import {
  ADD_CREDIT_CARD_STRING,
  DEFAULT_1CAD_STATE,
  DEFAULT_GUARANTOR_SETTINGS,
  DIRECTOR_CREDIT_CHECK_SECTION,
  DISABLED_GUARANTEES_OPTIONS,
  ENTITY_TYPES_OPTIONS,
  GUARANTEES_OPTIONS,
  GUARANTOR_SETTINGS_DESCRIPTION,
  GUARANTOR_TITLE,
} from './constants';
import { FEATURE_FLAGS } from 'conf';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { useSnackbar } from 'notistack';
import isEqual from 'lodash.isequal';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

const isTrustGuarantorsEnabled =
  FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

function GuarantorModal(props: IGuarantorModal): ReactElement {
  const isFeatureDisabled = !isFeatureEditEnabled('Credit');

  const { enqueueSnackbar } = useSnackbar();
  const {
    closeModal,
    dispatch,
    requiresGuarantees: currentRequireGuarantees,
    directorFromCreditCheckEnabled: currentDirectorFromCreditCheckEnabled,
    disableDirectorFromCreditCheck,
    guarantorHistory,
  } = props;

  const currentMinimumGuarantees =
    props.minimumGuarantees || props.minimumGuaranteesTrust;

  const currentEntityTypes: string[] = [];
  if (props.minimumGuarantees) {
    currentEntityTypes.push('company');
  }
  if (props.minimumGuaranteesTrust) {
    currentEntityTypes.push('trust');
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);

  const defaultMinimumValues = !currentRequireGuarantees
    ? DEFAULT_GUARANTOR_SETTINGS.minimum_guarantees
    : currentMinimumGuarantees || DEFAULT_GUARANTOR_SETTINGS.minimum_guarantees;
  const [minimumGuarantees, setMinimumGuaranteesState] =
    useState<number>(defaultMinimumValues);

  const [requiresGuarantees, setRequiresGuarantees] = useState<boolean>(
    currentRequireGuarantees || DEFAULT_GUARANTOR_SETTINGS.requires_guarantees
  );
  const [
    directorFromCreditCheckEnabled,
    setDirectorFromCreditCheckEnabledState,
  ] = useState<boolean>(
    currentDirectorFromCreditCheckEnabled ||
      DEFAULT_GUARANTOR_SETTINGS.director_from_credit_check_enabled
  );
  const [entityTypes, setEntityTypes] = useState<string[]>(currentEntityTypes);
  const [autoDecisionImpact, setAutoDecisionImpact] = useState<{
    isAutoDecisionImpactModalVisible: boolean;
    onConfirm: (() => void) | null;
    rulesetsThatWillBeImpacted: {
      attributes: IRuleSetAttributes;
      id?: string;
    }[];
    tabStatusToBePaused: string | null;
  }>(DEFAULT_1CAD_STATE);

  const getUpdatedDataFromResponse = (response): ILocalGuaranteeSettings => {
    const attributes = get(response, 'data.data.attributes', {});
    const {
      minimum_guarantees,
      minimum_guarantees_trust,
      requires_guarantees,
      director_from_credit_check_enabled,
      guarantors_account_rule_changed_histories,
    } = attributes;

    return {
      settings_requires_guarantees: !!requires_guarantees,
      settings_director_from_credit_check_enabled:
        !!director_from_credit_check_enabled,
      settings_minimum_guarantees: minimum_guarantees,
      settings_minimum_guarantees_trust: minimum_guarantees_trust,
      settings_guarantors_account_rule_changed_histories:
        guarantors_account_rule_changed_histories,
    };
  };

  const handleSaveSuccess = (response): void => {
    const localSettingsState: ILocalGuaranteeSettings =
      getUpdatedDataFromResponse(response);

    dispatch(setGuarantorValues(localSettingsState));

    setIsLoading(false);
    setDirty(false);
  };

  useEffect(() => {
    if (
      !requiresGuarantees &&
      requiresGuarantees !== currentRequireGuarantees
    ) {
      // Auto save when toggled off
      handleSave();
    }
  }, [requiresGuarantees]);

  const handleSave = (): void => {
    setIsLoading(true);

    const isCompanySelected = entityTypes.includes('company');
    const istrustSelected = entityTypes.includes('trust');

    const attributes = {
      minimum_guarantees:
        isTrustGuarantorsEnabled && !isCompanySelected ? 0 : minimumGuarantees,
      minimum_guarantees_trust:
        isTrustGuarantorsEnabled && !istrustSelected ? 0 : minimumGuarantees,
      requires_guarantees: requiresGuarantees,
      director_from_credit_check_enabled: directorFromCreditCheckEnabled,
    };

    dispatch(updateMandatoryChecks(attributes, handleSaveSuccess));
  };

  const handleGuarantorToggle = (): void => {
    if (isFeatureDisabled) {
      return;
    }
    const toggleGuarantorValue = (): void => {
      setRequiresGuarantees((prevState) => {
        const newState = !prevState;

        // Update other state when turned off
        if (!newState) {
          setDirectorFromCreditCheckEnabledState(false);
          setMinimumGuaranteesState(
            DEFAULT_GUARANTOR_SETTINGS.minimum_guarantees
          );
          setDirty(false);
        }

        return newState;
      });
    };
    if (
      // Check 1cad effects when turning off requireGuarantees
      currentRequireGuarantees
    ) {
      checkGuarantorChangesImpactOn1CAD(toggleGuarantorValue, true);
    } else {
      toggleGuarantorValue();
    }
  };

  const handleMinimumGuaranteesChange = (event): void => {
    setMinimumGuaranteesState(event.target.value);
    setDirty(true);
  };

  const handleChangeEntityTypes = ({ value }) => {
    setEntityTypes(value);
    setDirty(true);
  };

  const handleConfirmAutoDecisionImpact = (): void => {
    autoDecisionImpact.onConfirm && autoDecisionImpact.onConfirm();

    const { rulesetsThatWillBeImpacted, tabStatusToBePaused } =
      autoDecisionImpact;
    rulesetsThatWillBeImpacted.forEach((ruleset) => {
      // Let's pause the auto decision rules
      const { attributes, id } = ruleset;

      const updatedAttributes = {
        ...attributes,
        ...(tabStatusToBePaused !== null
          ? { [tabStatusToBePaused]: 'paused' }
          : {}),
      };
      dispatch(updateRuleSet(id, updatedAttributes));
    });

    setAutoDecisionImpact(DEFAULT_1CAD_STATE);
  };

  const handleCancelAutoDecisionImpact = (): void => {
    setAutoDecisionImpact(DEFAULT_1CAD_STATE);
  };

  const checkGuarantorChangesImpactOn1CAD = (
    callback: () => void,
    isGoingToTurnOff?: boolean
  ): void => {
    const { autoDecisionRulesets = [] } = props;

    // An auto decision ruleset will be affected if that one requries guarantors,
    // and a guarantor count other than zero is set,
    // and the new guarantor count in the entity settings is smaller than the
    // count in the ruleset
    const rulesetsThatWillBeImpacted = autoDecisionRulesets.filter(
      (ruleset) => {
        const turnedOnGuarantors =
          ruleset.attributes.guarantors === true &&
          ruleset.attributes.guarantors_count > 0;

        if (!turnedOnGuarantors) {
          return false;
        }

        const decreasedMinimumGuarantors = minimumGuarantees
          ? minimumGuarantees < ruleset.attributes.guarantors_count
          : false;

        const hasEntityTypeOverlap = isTrustGuarantorsEnabled
          ? entityTypes.some((entityType) =>
              ruleset.attributes.legal_types.includes(entityType)
            )
          : true;

        return (
          isGoingToTurnOff ||
          decreasedMinimumGuarantors ||
          !hasEntityTypeOverlap
        );
      }
    );

    if (!rulesetsThatWillBeImpacted.length) {
      // We didn't find any ruleset that will be impacted
      // We'll just proced to update the settings
      callback();
      return;
    }

    setAutoDecisionImpact({
      rulesetsThatWillBeImpacted,
      isAutoDecisionImpactModalVisible: true,
      onConfirm: () => callback(),
      tabStatusToBePaused: 'validation_status',
    });
  };

  const isEntityTypesEmpty =
    isTrustGuarantorsEnabled && entityTypes.length === 0;

  const onSaveClick = () => {
    if (isFeatureDisabled) {
      return;
    }
    if (isEntityTypesEmpty) {
      enqueueSnackbar('Please select at least one entity type', {
        variant: 'warning',
      });
      return;
    }

    const hasCountChanged = minimumGuarantees !== currentMinimumGuarantees;
    const hasEntityTypesChanged = !isEqual(entityTypes, currentEntityTypes);

    if (hasCountChanged || hasEntityTypesChanged) {
      // Check 1cad impact if minimum guarantees changes
      // or entity types changes
      checkGuarantorChangesImpactOn1CAD(handleSave);
    } else {
      handleSave();
    }
  };

  return (
    <Wrapper>
      {autoDecisionImpact.isAutoDecisionImpactModalVisible && (
        <ImpactAutoDecisionRulesetPopup
          onConfirm={handleConfirmAutoDecisionImpact}
          onCancel={handleCancelAutoDecisionImpact}
          rulesetNames={getRulesetNames(
            autoDecisionImpact.rulesetsThatWillBeImpacted
          )}
        />
      )}
      <div className="container">
        <div className="column">
          <Section>
            <Title>{GUARANTOR_TITLE}</Title>
            <p>{GUARANTOR_SETTINGS_DESCRIPTION}</p>
            <div className="switch-container">
              <Switch3
                label={requiresGuarantees ? 'On' : 'Off'}
                value={requiresGuarantees}
                onClick={handleGuarantorToggle}
                forceValue
                isDisabled={isFeatureDisabled}
              />
            </div>
            <CloseButton
              handleClick={closeModal}
              style={{ right: 0, top: 0 }}
            />
          </Section>
          {requiresGuarantees && (
            <Fragment>
              {isTrustGuarantorsEnabled && (
                <Section>
                  <SubsectionTitle>Entity type</SubsectionTitle>
                  <DropdownWrapper>
                    <SimpleMultiSelectDropdown
                      id="entityTypes"
                      bulkSelect
                      multiple
                      value={entityTypes}
                      options={ENTITY_TYPES_OPTIONS}
                      onChange={handleChangeEntityTypes}
                      disabled={!requiresGuarantees || isFeatureDisabled}
                    />
                  </DropdownWrapper>
                </Section>
              )}
              <Section>
                <SubsectionTitle>
                  How many guarantors are required per application?
                </SubsectionTitle>
                <DropdownWrapper>
                  <BorderedSelect
                    id="minimumGuarantors"
                    value={minimumGuarantees}
                    options={
                      requiresGuarantees
                        ? GUARANTEES_OPTIONS
                        : DISABLED_GUARANTEES_OPTIONS
                    }
                    formControlCustomProps={{ withBottomMargin: false }}
                    onChange={handleMinimumGuaranteesChange}
                    disabled={!requiresGuarantees || isFeatureDisabled}
                  />
                </DropdownWrapper>
              </Section>
              <Section>
                <SubsectionTitle>
                  {DIRECTOR_CREDIT_CHECK_SECTION.header}
                  <SubTextWrapper>
                    {DIRECTOR_CREDIT_CHECK_SECTION.headerSubtext}
                  </SubTextWrapper>
                </SubsectionTitle>
                <p>{DIRECTOR_CREDIT_CHECK_SECTION.description}</p>
                <ActionsWrapper>
                  <CheckboxWithMessageWrapper>
                    <SquareCheckbox
                      checkboxId="directorFromCreditCheckEnabled"
                      checkboxName="directorFromCreditCheckEnabled"
                      checked={directorFromCreditCheckEnabled}
                      disabled={
                        disableDirectorFromCreditCheck || isFeatureDisabled
                      }
                      handleChange={() => {
                        setDirectorFromCreditCheckEnabledState(
                          (prevState) => !prevState
                        );
                        if (requiresGuarantees && !!minimumGuarantees)
                          setDirty(true);
                      }}
                      label={DIRECTOR_CREDIT_CHECK_SECTION.label}
                    />
                    {disableDirectorFromCreditCheck && (
                      <span>{ADD_CREDIT_CARD_STRING}</span>
                    )}
                  </CheckboxWithMessageWrapper>

                  <ButtonContainer>
                    <Button
                      text="save"
                      type="submit"
                      onClick={onSaveClick}
                      disabled={
                        !dirty ||
                        isLoading ||
                        isEntityTypesEmpty ||
                        !minimumGuarantees ||
                        isFeatureDisabled
                      }
                      loading={isLoading}
                    />
                  </ButtonContainer>
                </ActionsWrapper>
              </Section>
            </Fragment>
          )}
          <GuarantorHistoryLogs guarantorHistory={guarantorHistory} />
        </div>
      </div>
    </Wrapper>
  );
}

export default GuarantorModal;
