import { Typography } from '@material-ui/core';
import { watchtowerAlertTheme } from 'modules/shared/helpers/colorPalettes';
import styled from 'styled-components';

export const TableLabel = styled(Typography)`
  margin-top: 1em;
  padding: 10px 0px;
`;

const statusColorMap = {
  irregular: watchtowerAlertTheme.status_irregular,
  watching: watchtowerAlertTheme.status_watching,
};

export const StatusWrapper = styled.div`
  background-color: ${(props: { variant: string }) =>
    statusColorMap[props.variant]};

  padding: 1em 2em;
  margin: 1em 0;
  border-radius: 7px;
`;

export const ActionTextWrapper = styled.span`
  color: ${(props: { status: string }) =>
    props.status === 'blocked'
      ? watchtowerAlertTheme.blocked_text
      : 'rgba(0, 0, 0, 0.87)'};
`;

export const ActionWrapper = styled.span`
  a {
    color: var(--main-color-active) !important;
    text-decoration: underline;
    margin-right: 2em;
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const LinkedApplicationWrapper = styled.span`
  a {
    color: var(--main-color-active) !important;
    text-decoration: underline;
    margin-right: 1em;
  }
  a:hover {
    text-decoration: underline;
  }
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
`;

const rowStatusColorMap = {
  internal_open: watchtowerAlertTheme.internal_alert,
  external_open: watchtowerAlertTheme.external_alert,
};

type TableRowProps = {
  rowStatus?: string;
  alertType?: string;
  firstGroupMember?: boolean;
  lastGroupMember?: boolean;
};
export const TableRowWrapper = styled.tr`
  background-color: ${(props: TableRowProps) =>
    props.rowStatus ? rowStatusColorMap[props.rowStatus] : 'initial'};

  ${(props: TableRowProps) => {
    if (props.alertType === 'internal') {
      return `
        border: none !important;
        td {
          border: 2px solid #f6f6f6;
        }

        td:first-child {
          border-top: ${props.firstGroupMember ? '2px solid #f6f6f6' : 'none'};
          border-bottom: ${
            props.lastGroupMember ? '2px solid #f6f6f6' : 'none'
          };
        }
      `;
    }
    return '';
  }}
`;
