import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user-entity-link
 */
const UserEntityLinks = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = 'user_entity_links';
};

UserEntityLinks.prototype.create = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

UserEntityLinks.prototype.get = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

UserEntityLinks.prototype.getPopup = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/user_pop_ups`)
    .then(success)
    .catch(error);
};

UserEntityLinks.prototype.getUserList = function(success, error, options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .get(`/${this.type}/`, options)
    .then(success)
    .catch(error);
};

UserEntityLinks.prototype.getAllUsers = function() {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/all_users`);
};

UserEntityLinks.prototype.getUsersByEntity = function(entityId) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/users_by_entity`, {
    params: { entity_id: entityId },
  });
};

UserEntityLinks.prototype.getWebsiteButtonList = function(success, error) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios
    .get(`/${this.type}/website_buttons`)
    .then(success)
    .catch(error);
};

UserEntityLinks.prototype.getApplicationApprovers = function(applicationId) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/application_approvers`, {
    params: { application_id: applicationId },
  });
};

UserEntityLinks.prototype.getIUFApprovers = function({
  entityId,
  ownerId,
  ownerType,
}) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/iuf_approvers`, {
    params: {
      entity_id: entityId,
      owner_id: ownerId,
      owner_type: ownerType,
    },
  });
};

UserEntityLinks.prototype.retrieveByUserAndEntity = function(entityId, userId) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/retrieve_by_user_and_entity`, {
    params: { entity_id: entityId, user_id: userId },
  });
};

UserEntityLinks.prototype.getCallCentreUsers = function(options) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}`, {
    params: {
      page_permission: 'can_call_centre_sudo',
      ...options,
    },
  });
};

UserEntityLinks.prototype.getApplicationEscalation = function(
  applicationId,
  levelWithOperator
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/application_approvers`, {
    params: {
      application_id: applicationId,
      level_with_operator: levelWithOperator,
      for: 'escalation',
    },
  });
};

UserEntityLinks.prototype.getApplicationReviewers = function(applicationId) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.get(`/${this.type}/application_approvers`, {
    params: {
      application_id: applicationId,
    },
  });
};

export default UserEntityLinks;
