import * as PPSRModifyFormComponents from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRModifyForm/index';
import React from 'react';
import isBlank from 'utils/isBlank';

export default function PPSRModifyForm(props) {
  const { region } = props;

  if (isBlank(region)) {
    return null;
  }

  const FormComponent = PPSRModifyFormComponents[`${region}PPSRModifyForm`];

  return <FormComponent {...props} />;
}
