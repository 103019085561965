import {
  COMMON_VALIDATION_SCHEMA,
  validateSPGCredentials,
} from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/validationSchema/commonValidationSchema';
import * as yup from 'yup';

function validateFunctionRegister(value) {
  if (value) {
    return true;
  }

  const {
    functionRegisterEightToTwentyFive,
    functionRegisterNoEndTime,
    functionRegisterZeroToSevenYears,
  } = this.parent;

  return [
    functionRegisterEightToTwentyFive,
    functionRegisterNoEndTime,
    functionRegisterZeroToSevenYears,
  ].some((ppsrFunction) => ppsrFunction);
}

const FUNCTION_VALIDATION_SCHEMA = {
  functionRegisterZeroToSevenYears: yup
    .boolean()
    .test(
      'function-register',
      'Please select at least one registration type',
      validateFunctionRegister
    ),
};

const AUValidationSchema = {
  ...COMMON_VALIDATION_SCHEMA,
  ...FUNCTION_VALIDATION_SCHEMA,
  spgPwd: yup
    .string()
    .test('spg-pwd', 'Please access code', validateSPGCredentials),
};

export default AUValidationSchema;
