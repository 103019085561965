import React, { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 20px;
  & > div {
    background: white;
    height: 600px;
    overflow-y: scroll;
    padding: 12px 28px;
    position: relative;
  }
`;

type InteractionContainerProps = { children: ReactNode };

export const InteractionContainer = ({
  children,
}: InteractionContainerProps) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Container>{children}</Container>
    </DndProvider>
  );
};
