import { getEighteenYearsAgo } from 'modules/card-management-shared/utils';
import { AustralianDriversLicencesRules } from 'modules/identity/components/utils';
import moment from 'moment';
import * as yup from 'yup';

import {
  NZ_DRIVER_LICENCE_NUMBER_REGEX,
  NZ_DRIVER_LICENCE_VERSION_REGEX,
  NZ_PASSPORT_NUMBER_REGEX,
} from '../constants';
import { NAME_REGEX } from './validators';

// Rules
export const str = yup.string().trim().nullable();
export const required_str = (message) => str.required(message);

export const date_expiry = yup
  .string()
  .required('Please select expiry date.')
  .test(
    'vaild till today',
    'Please enter valid expiry date.',
    (value) => moment(value, 'DD/MM/YYYY') >= moment()
  );

export const address = yup
  .object()
  .required('Please input and select residential address.')
  .nullable();

export const dob = yup
  .string()
  .required('Please select date of birth.')
  .test(
    'over 18',
    'Please enter valid date of birth. You Must be over 18.',
    (value) =>
      moment(value, 'DD/MM/YYYY').toDate().getTime() <=
      getEighteenYearsAgo().getTime()
  )
  .nullable();

// Specifics

export const au_date_expiry = yup.string().when('region', {
  is: 'AU',
  then: date_expiry,
});

export const identification_expiry = yup
  .string()
  .when('identification_type', {
    is: 'passport',
    then: date_expiry,
  })
  .when('identification_type', {
    is: 'driver_licence',
    then: au_date_expiry,
  })
  .nullable();

export const identification_number = yup
  .string()
  .required('Please input identification number.')
  .when('region', {
    is: 'NZ',
    then: yup
      .string()
      .when('identification_type', {
        is: 'driver_licence',
        then: yup
          .string()
          .required('Please input driver licence number.')
          .test(
            'version',
            'Please input a correct driver licence number.',
            (value) => NZ_DRIVER_LICENCE_NUMBER_REGEX.test(value)
          ),
      })
      .when('identification_type', {
        is: 'passport',
        then: yup
          .string()
          .required('Please input passport number.')
          .test('version', 'Please input a correct passport number.', (value) =>
            NZ_PASSPORT_NUMBER_REGEX.test(value)
          ),
      }),
  })
  .nullable();

export const identification_version = yup
  .string()
  .when('region', {
    is: 'NZ',
    then: yup.string().when('identification_type', {
      is: 'driver_licence',
      then: yup
        .string()
        .required('Please input identification version.')
        .test('version', 'Please input a correct version.', (value) =>
          NZ_DRIVER_LICENCE_VERSION_REGEX.test(value)
        ),
    }),
  })
  .nullable();

export const identification_state = yup.string().when('region', {
  is: 'AU',
  then: yup.string().when('identification_type', {
    is: 'driver_licence',
    then: yup
      .string()
      .required('Please input drivers licence state')
      .test('au_state', 'Please input valid australian state', (val) =>
        Object.keys(AustralianDriversLicencesRules).includes(val)
      ),
  }),
});

export const identification_card_number = (signature) =>
  yup.string().when('region', {
    is: 'AU',
    then: yup.string().when('identification_type', {
      is: 'driver_licence',
      then: yup
        .string()
        .required('Please input drivers licence card number.')
        .test(
          'card_num',
          'Please input a valid australian drivers license card number',
          (val) => {
            if (!signature.identification_state) return false;

            const AU_STATE_REGEX =
              AustralianDriversLicencesRules[signature.identification_state];

            return AU_STATE_REGEX.driversLicenceCardNo.test(val);
          }
        ),
    }),
  });

const name = (message: string) =>
  str.matches(NAME_REGEX, {
    excludeEmptyString: true,
    message,
  });

const required_name = (required_message, valid_message) =>
  yup.string().required(required_message).matches(NAME_REGEX, {
    excludeEmptyString: true,
    message: valid_message,
  });

// Package

const FieldValidators = {
  address,
  au_date_expiry,
  date_expiry,
  dob,
  identification_card_number,
  identification_expiry,
  identification_number,
  identification_state,
  identification_version,
  required_str,
  str,
  name,
  required_name,
};

export default FieldValidators;
