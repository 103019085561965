import { humanize } from 'utils/humanize';

import api from '../../api';
import {
  CONNECT_INTEGRATION_ERROR,
  CONNECT_INTEGRATION_SAVE_SUCCESS,
  CONNECT_INTEGRATION_START,
  CONNECT_INTEGRATION_SUCCESS,
  LOAD_INTEGRATIONS_ERROR,
  LOAD_INTEGRATIONS_START,
  LOAD_INTEGRATIONS_SUCCESS,
  REMOVE_INTEGRATION_ERROR,
  REMOVE_INTEGRATION_START,
  REMOVE_INTEGRATION_SUCCESS,
  UPDATE_INTEGRATION,
} from './constants';
import Integration from './Integration';

export function getIntegrations() {
  return (dispatch, getState) => {
    dispatch({ type: LOAD_INTEGRATIONS_START });

    const currentEntiyId = getState().current_user.current_entity.id;
    const entities = api(
      'entities',
      getState().current_user.access_token,
      currentEntiyId
    );

    entities.getEntityIntegrations(
      currentEntiyId,
      (response) => {
        const integrationData = Integration.parseData(response);
        const integrations = {};

        for (let i = 0, l = integrationData.length; i < l; i += 1) {
          const integration = integrationData[i];

          integration.loading = false;
          integrations[integration.attributes.service] = integration;
        }

        dispatch({
          payload: integrations,
          type: LOAD_INTEGRATIONS_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LOAD_INTEGRATIONS_ERROR,
        });
      }
    );
  };
}

export function connectIntegration(service, success) {
  return (dispatch, getState) => {
    dispatch({
      payload: { service },
      type: CONNECT_INTEGRATION_START,
    });

    const currentEntityId = getState().current_user.current_entity.id;
    const integrations = api(
      'integrations',
      getState().current_user.access_token,
      currentEntityId
    );

    integrations.connectIntegration(
      currentEntityId,
      service,
      (response) => {
        const url = response.data.authorize_url;

        dispatch({
          meta: {
            mixpanel: {
              event: `${humanize(service, true)} integration connected.`,
              props: {
                'Entity ID': currentEntityId,
              },
            },
          },
          payload: { service, url },
          type: CONNECT_INTEGRATION_SUCCESS,
        });

        success(url);
      },
      (error) => {
        dispatch({
          payload: { error, service },
          type: CONNECT_INTEGRATION_ERROR,
        });
      }
    );
  };
}

export function deleteIntegration(integration) {
  return (dispatch, getState) => {
    const service = integration.attributes.service;

    dispatch({
      payload: { service },
      type: REMOVE_INTEGRATION_START,
    });

    const integrations = api(
      'integrations',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    integrations.deleteIntegration(
      integration,
      () => {
        const removedIntegration = {};

        removedIntegration[service] = new Integration({
          attributes: {
            access_secret: '',
            access_token: '',
            service,
          },
        });
        dispatch({
          meta: {
            mixpanel: {
              event: `${humanize(service, true)} integration disconnected.`,
              props: {
                'Entity ID': getState().current_user.current_entity.id,
              },
            },
          },
          payload: { removedIntegration, service },
          type: REMOVE_INTEGRATION_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: { error, service },
          type: REMOVE_INTEGRATION_ERROR,
        });
      }
    );
  };
}

export function saveIntegration(integration, success) {
  return (dispatch, getState) => {
    dispatch({
      payload: integration.attributes.service,
      type: CONNECT_INTEGRATION_START,
    });

    const integrationsAPI = api(
      'integrations',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    integrationsAPI.save(
      integration.attributes,
      (response) => {
        const data = {};
        const attributes = response.data.data.attributes;

        attributes['id'] = response.data.data.id;

        const service = attributes.service;
        const _integration = new Integration({ attributes });

        data[service] = _integration;

        dispatch({
          meta: {
            mixpanel: {
              event: `${humanize(service, true)} integration connected.`,
              props: {
                'Entity ID': getState().current_user.current_entity.id,
              },
            },
          },
          payload: data,
          type: CONNECT_INTEGRATION_SAVE_SUCCESS,
        });

        data[response.data.data.attributes.service] =
          response.data.data.attibutes;

        success(response.data.data.id);
      },
      (error) => {
        dispatch({
          payload: error,
          type: CONNECT_INTEGRATION_ERROR,
        });
      }
    );
  };
}

export function updateIntegrationAttributes(integration) {
  const service = integration.attributes.service;

  /* eslint-disable no-unused-vars */
  // `state` is unused
  return (dispatch, getState) => {
    dispatch({
      meta: {
        mixpanel: {
          event: `${humanize(service, true)} integration updated.`,
          props: {
            'Entity ID': getState().current_user.current_entity.id,
          },
        },
      },
      payload: integration,
      type: UPDATE_INTEGRATION,
    });
  };
  /* eslint-enable no-unused-vars */
}
