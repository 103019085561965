import Button from 'modules/shared/components/inputs/Button';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import React, { ReactElement } from 'react';
import CustomerChecklist from './CustomerChecklist';
import { FormValues } from './types';

type Props = {
  values: FormValues;
  hidePreview: () => void;
};

function SupplierChecklistPreview({
  values,
  hidePreview,
}: Props): ReactElement {
  return (
    <ContentContainer
      header="Preview"
      description="The checklist will default to open on the first page of the application."
      listType="none"
    >
      <CustomerChecklist {...values} />
      <div className="has-text-right mt-4">
        <Button type="button" text="Back" white onClick={hidePreview} />
      </div>
    </ContentContainer>
  );
}

export default SupplierChecklistPreview;
