import React from 'react';
import Helmet from 'react-helmet';
import DOMPurify from 'dompurify';

/* Import CSS */
import styles from './css/PanelTitle.css';

function SectionTitle(props) {
  const { text, margin_bottom, margin_top, text_align } = props;

  return (
    <div className={styles.outer}>
      <Helmet title={text} />
      <h2
        className={styles.title}
        style={{
          marginBottom: margin_bottom,
          marginTop: margin_top,
          textAlign: text_align,
        }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
      >
        {props.children}
      </h2>
    </div>
  );
}

export default SectionTitle;
