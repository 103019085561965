import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ $textAlign?: string }>`
  text-align: ${({ $textAlign }) => $textAlign || 'center'};
`;

const icons = {
  danger: {
    icon: 'times-circle' as const,
  },
  loading: {
    icon: 'spinner' as const,
    spin: true,
  },
  success: {
    icon: 'check-circle' as const,
  },
};

type UploadStatusProps = {
  type: 'danger' | 'success';
  message: string;
  textAlign?: string;
};

export const UploadStatus = (props: UploadStatusProps) => {
  const { type, message, textAlign } = props;
  return (
    <Container className={`has-text-${type}`} $textAlign={textAlign}>
      <FontAwesomeIcon {...icons[type]} /> {message}
    </Container>
  );
};
