import ConsumerWebsitebuttonApplication from 'modules/consumer-onboarding/containers/ConsumerWebsitebuttonApplication';
import Layout from 'modules/user/containers/Layout';
import isBlank from 'utils/isBlank';

const ConsumerWebsitebuttonRoute = {
  component: ConsumerWebsitebuttonApplication,
  indexRoute: {
    onEnter: (nextState, replace) => {
      // old path
      // /websitebutton/entity_id/start?channel=website
      // /websitebutton/entity_id/start?channel=qr
      // new path
      // /onboarding?supplier_id=entity_id&channel=website
      const {
        params: { entity_id },
        location: {
          query: { channel: queryChannel },
        },
      } = nextState;

      let channel = queryChannel;
      if (isBlank(channel)) {
        channel = 'website';
      }

      replace(`/onboarding?supplier_id=${entity_id}&channel=${channel}`);
    },
  },
  path: ':entity_id/start',
};

module.exports = {
  childRoutes: [ConsumerWebsitebuttonRoute],
  component: Layout,
  path: 'websitebutton',
};
