import { accountType, role } from './helpers/codeTable';
import { amountTypeValue, countTypeValue } from './helpers/summary';
import { displayEmpty, formatMoney } from 'utils/formatting';
import { enquiryResponseData, isBusiness } from './helpers/reportHelpers';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var CreditEnquiries = createClass({
  _co_borrower(co_borrower) {
    const { entityType } = this.props;

    if (isBusiness(entityType)) return null;

    return (
      <tr>
        <th>Co-Borrower</th>
        <td>{displayEmpty(co_borrower)}</td>
      </tr>
    );
  },

  _creditEnquiries() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      allCreditEnquiryList =
        response.organisation_credit_history.all_credit_enquiry_list || {},
      creditEnquiryList = allCreditEnquiryList.credit_enquiry_list || {},
      creditEnquiries = creditEnquiryList.credit_enquiry || [];

    if (creditEnquiries.constructor === Object)
      creditEnquiries = [creditEnquiries];

    return creditEnquiries.map((enquiry, index) => {
      return (
        <div className={styles.table_wrapper} key={index}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Enquiry Date</th>
                <td>{stringToSimpleDate(enquiry.enquiry_date)}</td>
                <th>Amount</th>
                <td>${formatMoney(parseFloat(enquiry.amount))}</td>
              </tr>
              <tr>
                <th>Credit Enquirer</th>
                <td>{displayEmpty(enquiry.enquirer)}</td>
                <th>Account Type</th>
                <td>{accountType((enquiry.account_type || {}).type)}</td>
              </tr>
              <tr>
                <th>Reference Number</th>
                <td>{displayEmpty(enquiry.ref_number)}</td>
                <th>Role in Enquiry</th>
                <td>{role((enquiry.role || {}).type)}</td>
              </tr>
              {this._co_borrower(enquiry.co_borrower)}
            </tbody>
          </table>
        </div>
      );
    });
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Credit Enquiries"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, 'Credit_Enquiries')}
          </strong>
        </p>
        <p>
          <strong>
            Amount: {amountTypeValue(summaryEntry, 'Credit_Enquiries_Value')}
          </strong>
        </p>
        {this._creditEnquiries()}
      </ReviewContainer>
    );
  },
});

export default CreditEnquiries;
