import { Typography } from '@material-ui/core';
import Dialog from 'modules/shared/components/v2/Dialog';
import React from 'react';

type ConfirmRemoveGroupModal = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmTurnOff = (props: ConfirmRemoveGroupModal) => {
  const { isOpen, onConfirm, onCancel } = props;

  return (
    <Dialog
      title="Turn off Customer View"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      cancelButtonProps={{ white: true }}
    >
      <Typography>
        Please confirm to turn off 1Account Customer View.
      </Typography>
      <Typography>
        The display in your customers' applications will be
      </Typography>
      <Typography>default back to 1Account Organisation Structure.</Typography>
    </Dialog>
  );
};
