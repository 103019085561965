import React, { ReactElement } from 'react';
import Button from 'modules/shared/components/inputs/Button';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';

const SubmitButton = ({
  authorisationRequest,
  disabled,
  loading,
}: {
  authorisationRequest: AuthorisationRequestModel;
  disabled: boolean;
  loading: boolean;
}): ReactElement => {
  let buttonText = 'Request access';
  if (authorisationRequest.status === 'request_sent') {
    buttonText = 'Resend request';
  }

  return (
    <div className="control">
      <Button
        text={buttonText}
        type="submit"
        disabled={disabled || loading}
        loading={loading}
        loading_text={'Sending...'}
      />
    </div>
  );
};

export default SubmitButton;
