import { SET_TOKEN } from './constants';

const authData = {
  jwt: null,
};

export function authDataReducers(state = authData, action) {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, jwt: action.payload };
    default:
      return { ...state };
  }
}
