import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form-latest';
import { FEATURE_FLAGS } from 'conf';
import RuleSetSection from '../../RuleSetSection';
import Button from 'modules/shared/components/inputs/Button';
import { loadAutoDecisions } from 'modules/addons/actions';
import { loadCurrentEntity } from 'modules/profile/actions.js';
import { IAdditions, IRuleSetAttributes } from '../../type';
import FormFieldSection from '../../FormFieldSection';
import YesOrNoRadioBoxGroup, {
  YesOrNo,
  getYesOrNo,
} from '../../YesOrNoRadioBoxGroup';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import { getNumberOptions } from '../../constant';
import { RuleSetSectionTextOnly } from '../../RuleSetSectionTextOnly';
import { formConstants } from './constants';
import { processDirectDebitAddons } from '../../utils';
import get from 'lodash.get';
import { getAdditionTabPauseReasons } from '../../getPauseReasons';

interface IFormFields {
  tradeReferenceChecksRequired: YesOrNo;
  tradeReferenceCount: number | null;
  paperlessDirectDebit: YesOrNo;
}
interface AdditionsFormProps {
  attributes: IRuleSetAttributes;
  isReadyToSubmitForm: () => boolean;
  isUpdating: boolean;
  actions: {
    loadCurrentEntity: () => void;
    loadAutoDecisions: () => void;
  };
  rulesetIndex: number;
  nameModified: boolean;
  creditCheckApplicationTypes: string[];
  directDebitAddons: any;
  minTradeReferences: number;
  submitButtonLabel: string;
  onSubmitTabData: (data: IAdditions) => void;
  disabled?: boolean;
  hideSaveButton?: boolean;
  rulesetName: IRuleSetAttributes['name'];
  setDirty: (flag: boolean) => void;
}

const AdditionsForm = ({
  attributes,
  isReadyToSubmitForm,
  isUpdating,
  nameModified,
  actions,
  directDebitAddons,
  minTradeReferences,
  submitButtonLabel,
  onSubmitTabData,
  disabled = false,
  hideSaveButton = false,
  rulesetName,
  setDirty,
}: AdditionsFormProps): JSX.Element => {
  useEffect(() => {
    actions.loadCurrentEntity();
  }, []);

  const pauseReasons = getAdditionTabPauseReasons(
    attributes,
    minTradeReferences,
    directDebitAddons
  );

  const isTradeReferencesEditable = minTradeReferences > 0;

  const isPaperlessDirectDebitEditable = processDirectDebitAddons(
    directDebitAddons,
    attributes
  );

  const defaultValues = {
    tradeReferenceChecksRequired:
      attributes.trade_reference === undefined ||
      attributes.trade_reference === null
        ? undefined
        : getYesOrNo(attributes.trade_reference),
    tradeReferenceCount: attributes.trade_reference_count,
    paperlessDirectDebit: getYesOrNo(attributes.paperless_check),
  };

  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IFormFields>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (pauseReasons.tradeReference && !isTradeReferencesEditable) {
      // If the reason why this ruleset was paused is due to change in
      // trade reference settings, AND trader references are currently disabled
      // in settings, we'll set the field value to NO.
      // We set this, and not the default value, so that isDirty will be true,
      // allowing user to save the ruleset.
      setValue('tradeReferenceChecksRequired', YesOrNo.NO);
    }
  }, [pauseReasons.tradeReference, isTradeReferencesEditable]);

  useEffect(() => {
    if (pauseReasons.directDebit) {
      // If the reason why this ruleset was paused is due to change in direct debit
      // addons, we'll set the field value to NO.
      // We set this, and not the default value, so that isDirty will be true,
      // allowing user to save the ruleset.
      setValue('paperlessDirectDebit', YesOrNo.NO);
    }
  }, [pauseReasons.directDebit]);

  const onSubmit = (): void => {
    const isReady = isReadyToSubmitForm();
    const values = getValues();

    if (isReady) {
      const rulesetToSubmit = {
        name: rulesetName,
        trade_reference: values.tradeReferenceChecksRequired === YesOrNo.YES,
        trade_reference_count:
          values.tradeReferenceChecksRequired === YesOrNo.YES
            ? values.tradeReferenceCount
            : null,
        paperless_check: values.paperlessDirectDebit === YesOrNo.YES,
        additions_status: 'complete' as const,
      };
      onSubmitTabData(rulesetToSubmit);
    }
  };

  const currentValues = watch();

  const isTradeChecksSelected =
    currentValues.tradeReferenceChecksRequired === YesOrNo.YES ||
    currentValues.tradeReferenceChecksRequired === YesOrNo.NO;
  const isTradeChecksRequired =
    currentValues.tradeReferenceChecksRequired === YesOrNo.YES;
  const tradeReferenceCount = currentValues.tradeReferenceCount;
  const paperlessDirectDebit = currentValues.paperlessDirectDebit;

  // Check if trade reference field is empty.
  // The field is empty if:
  // it is editable BUT,
  // either no response (Yes/No) is selected, OR
  // a response is selected, but a count is not selected
  const isTradeCheckEmpty =
    isTradeReferencesEditable &&
    (!isTradeChecksSelected || (isTradeChecksRequired && !tradeReferenceCount));

  // Check if paperless direct debit field is empty.
  // The field is empty if:
  // The field is editable BUT,
  // no response (Yes/No) is selected.

  const isDirectDebitEmpty =
    isPaperlessDirectDebitEditable &&
    paperlessDirectDebit === YesOrNo.NOT_SELECTED;

  const hasEmptyFields = isTradeCheckEmpty || isDirectDebitEmpty;

  const isDirty =
    JSON.stringify(defaultValues) !== JSON.stringify(currentValues);

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  const isSaveButtonDisabled =
    (!isDirty && !nameModified) || hasEmptyFields || disabled;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RuleSetSection title="Trade reference check/s">
        <>
          <FormFieldSection
            hasPausedMessage={pauseReasons.tradeReference}
            id={formConstants.TRADE_REFERENCE_CHECKS_REQUIRED.name}
            label={formConstants.TRADE_REFERENCE_CHECKS_REQUIRED.label}
          >
            <YesOrNoRadioBoxGroup
              name={formConstants.TRADE_REFERENCE_CHECKS_REQUIRED.name}
              control={control}
              rules={{
                validate: (value) =>
                  (value !== undefined && value !== null && value !== '') ||
                  'Please select an option',
              }}
              disabled={!isTradeReferencesEditable || disabled}
            />
          </FormFieldSection>

          {isTradeChecksRequired && (
            <FormFieldSection
              id={formConstants.TRADE_REFERENCE_COUNT.name}
              label={formConstants.TRADE_REFERENCE_COUNT.label}
            >
              <BorderedSelect
                options={getNumberOptions(minTradeReferences)}
                placeholder={
                  pauseReasons.tradeReference
                    ? defaultValues.tradeReferenceCount
                    : ''
                }
                register={register(formConstants.TRADE_REFERENCE_COUNT.name, {
                  required: {
                    value: true,
                    message:
                      'Please select number of trade reference checks required',
                  },
                })}
                error={!!errors.tradeReferenceCount}
                helperText={errors.tradeReferenceCount?.message}
                disabled={disabled}
                value={tradeReferenceCount}
              />
            </FormFieldSection>
          )}
        </>
      </RuleSetSection>

      <RuleSetSection
        title={['Paperless direct debit', '(Completed DD form only)']}
      >
        <FormFieldSection
          hasPausedMessage={pauseReasons.directDebit}
          id={formConstants.PAPERLESS_DIRECT_DEBIT.name}
          label={formConstants.PAPERLESS_DIRECT_DEBIT.label}
        >
          <YesOrNoRadioBoxGroup
            name={formConstants.PAPERLESS_DIRECT_DEBIT.name}
            control={control}
            rules={{
              required: { value: true, message: 'Please select an option' },
            }}
            disabled={!isPaperlessDirectDebitEditable || disabled}
          />
        </FormFieldSection>
      </RuleSetSection>

      <RuleSetSectionTextOnly
        title="Internal use fields (IUFs)"
        text="If IUFs have mandatory completion or approval in place, the
          application will not run through 1CAD until completed."
      />

      {!FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER && (
          <RuleSetSectionTextOnly
              disabled
              title="Watchtower (coming soon)"
              text="Any application with an alert triggered will be deferred."
          />
      )}

      {FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER && (
          <RuleSetSectionTextOnly
              title="Watchtower"
              text="Any application with an alert triggered will be deferred."
          />
      )}

      {!hideSaveButton ? (
        <Button
          type="submit"
          className="mt-6"
          text={submitButtonLabel}
          disabled={isSaveButtonDisabled}
          loading={isUpdating}
          loading_text="Saving..."
        />
      ) : null}
    </form>
  );
};

const mapStateToProps = (state, props) => ({
  currentRuleSet: state.add_ons.auto_decisions[props.rulesetIndex],
  minTradeReferences:
    state.current_user.current_entity.attributes.minimum_trade_references || 0,
  directDebitAddons: get(state, 'add_ons.paperless', []),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCurrentEntity,
      loadAutoDecisions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionsForm);
