/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/* Import actions*/
import { createRuleSet } from '../actions';

import { loadAutoDecisions } from '../../actions';

/* Import CSS */
import styles from './css/AutoDecisioning.css';

/* Import components */
import { withStyles } from '@material-ui/core/styles';
import AutoDecisioningForm from './AutoDecisioningForm';

function CreateAutoDecisioning(props) {
  const { current_entity, dispatch, handleDiscard } = props;

  function create(rule_set) {
    dispatch(
      createRuleSet(rule_set.attributes, () => {
        dispatch(loadAutoDecisions());
        handleDiscard();
      })
    );
  }

  return (
    <AutoDecisioningForm
      handleDiscard={handleDiscard}
      handleSubmit={create}
      submitButtonText={'create ruleset'}
      current_entity={current_entity}
    />
  );
}

const defaults = {
  ruleset: {
    name: 'New Auto Decisioning Ruleset',
  },
};

export default connect((state, ownProps) => {
  return {
    // rule_sets: state.addons.auto_decisioning.rule_sets || null,
    updating: state.auto_decisions.updating,
    rule_set: state.auto_decisions.current_rule_set || defaults.ruleset,
    current_entity: state.current_user.current_entity.attributes,
    handleDiscard: ownProps.handleDiscard,
  };
})(withStyles(styles)(CreateAutoDecisioning));
