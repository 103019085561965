import React from 'react';

import { Statistics } from './Statistics';
import { CustomerSearch } from './CustomerSearch';
import { CustomersFilterTrigger } from './CustomersFilterTrigger';
import { ToggleView } from './ToggleView';
import { DownloadReport } from './DownloadReport';
import { PipelinePageHeaderComponents } from 'modules/shared/components';

const { Container, SearchWrapper, FilterContainer, ButtonsContainer } =
  PipelinePageHeaderComponents;

export const CustomersHeader = () => {
  return (
    <Container>
      <Statistics />
      <FilterContainer>
        <SearchWrapper>
          <CustomerSearch />
        </SearchWrapper>
        <ButtonsContainer>
          <CustomersFilterTrigger />
          <ToggleView />
          <DownloadReport />
        </ButtonsContainer>
      </FilterContainer>
    </Container>
  );
};
