import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader'
import Button from 'modules/shared/components/inputs/Button';

import styles from './css/DeactivateAccount.css';

class DeactivateAccount extends Component {
  //componentWillMount(){
  //  const { dispatch } = this.props
  //  dispatch(paymentActions.loadTransactions())
  //}
  render() {
    return (
      <div>
        <article className={styles.block}>
          <div className={styles.liner}>
            <div className={styles.row}>
              <h2 className={styles.header}>Deactivate entity account</h2>
              <div className={styles.button}>
                <Button
                  disabled={this.props.isLoading}
                  handleClick={() => this.props.deactivate()}
                  text="deactivate account"
                />
              </div>
            </div>
            <section className={styles.main_text}>
              <p>
                If you choose to deactivate your account a support ticket will
                be logged with 1Centre support and we will get in touch to
                discuss closure of your account.
              </p>
            </section>
          </div>
        </article>
      </div>
    );
  }
}

export default DeactivateAccount;
