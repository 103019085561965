import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect } from 'react';
import get from 'lodash.get';

import { PipelinePageHeaderComponents } from 'modules/shared/components';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import useFilterState from 'modules/reporting/hooks/useFilterState';

const {
  FilterContainer,
  SearchWrapper,
  ButtonsContainer,
  AdvancedSearchButton,
  FilterButton,
  DownloadReportButton,
} = PipelinePageHeaderComponents;

export const Search = (props) => {
  if (!props.isVisible) {
    return null;
  }

  const { enqueueSnackbar } = useSnackbar();

  const {
    searchParams,
    updateSearchParams,
    dynamicFilterOptions,
    exportApplications,
    currentUser,
    isCallCentreActive,
    isDownloadDisabled,
  } = props;

  const { filter, filterParams, onToggleIsOpen } = useFilterState(
    searchParams,
    { isCallCentreActive }
  );

  const onChangeSearchKeyWord = (event) => {
    const value = get(event, 'target.value');
    updateSearchParams({ key_word: value, page: 1 });
  };

  const onToggleShowMyApplication = () => {
    const value = !searchParams.only_current_user;
    updateSearchParams({ only_current_user: value });
  };

  const applicationFilter = React.cloneElement(filter, {
    dynamicFilterOptions,
  });

  const onExportApplications = () => {
    const message = `A link to the CSV file will be sent to ${currentUser.email} in few minutes.`;
    enqueueSnackbar(message, { variant: 'info' });
    exportApplications(searchParams);
  };

  useEffect(() => {
    updateSearchParams(filterParams);
  }, [filterParams]);

  return (
    <Fragment>
      <FilterContainer>
        <SearchWrapper>
          <BorderedTextField
            id="search"
            label="Search"
            value={searchParams.key_word}
            onChange={onChangeSearchKeyWord}
          />
        </SearchWrapper>
        <ButtonsContainer>
          <AdvancedSearchButton onClick={onToggleIsOpen} />
          <PopperTooltip
            title="View your own application list here if you are either the owner or tagged approver."
            placement="bottom"
            isVisibleOnMobile={false}
          >
            <FilterButton onClick={onToggleShowMyApplication}>
              {'Show '}
              <span>{`${searchParams.only_current_user ? 'all' : 'my'}`}</span>
              {' applications'}
            </FilterButton>
          </PopperTooltip>
          {(currentUser.isAdmin || currentUser.isApprover) && (
            <DownloadReportButton
              onClick={onExportApplications}
              disabled={isDownloadDisabled}
            />
          )}
        </ButtonsContainer>
      </FilterContainer>
      {applicationFilter}
    </Fragment>
  );
};
