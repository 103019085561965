import React, { useState, ReactElement, useMemo, useCallback } from 'react';
import { FEATURE_FLAGS } from 'conf';
import isPresent from 'utils/isPresent';
import AutoCompleteSelectField from '../AutocompleteSelectField';
import SelectField from 'modules/consumer-onboarding/v2/ApplicationDetails/SelectField';

let LABEL = "Help us find who you'd like to trade with";
let LABEL_LOCAL_STORE = "Please select who you'd like to trade with";

export type Field = {
  label: string;
  value: string;
  entity_id?: string;
};

const TradingNameField = (props): ReactElement | null => {
  const {
    errors,
    loadedSupplierTradingNameId,
    loadedSupplierName,
    name,
    onChange,
    options,
    rawOptions,
    watch,
    isCustomerViewActive,
  } = props;

  const [selectedRegion, setSelectedRegion] = useState<Field | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<Field | null>(null);

  const handleRegionChange = useCallback((e, value) => {
    e.preventDefault();
    setSelectedBranch(null);

    if (value?.value) {
      onChange({
        target: {
          name,
          value: value.value,
        },
      });
    }

    setSelectedRegion(value);
  }, []);

  const handleBranchChange = useCallback((e, value) => {
    e.preventDefault();
    setSelectedBranch(value);

    if (value?.value) {
      onChange({
        target: {
          name,
          value: value.value,
        },
      });
    }
  }, []);

  const regions = useMemo(() => {
    const filteredOptions = options
      .filter((r) => r.type === 'Region')
      .map((o) => {
        return {
          label: o.trading_name,
          value: o.id,
          entity_id: o.entity_id,
        };
      });

    if (filteredOptions.length === 1) {
      setSelectedRegion(filteredOptions[0]);
      onChange({
        target: {
          name,
          value: filteredOptions[0].value,
        },
      });
    }

    return filteredOptions;
  }, [options]);

  const branches = useMemo(() => {
    if (!selectedRegion) return [];

    let filteredOptions = options
      .filter(
        (o) => o.type === 'Branch' && o.parent_id === selectedRegion.entity_id
      )
      .map((o) => {
        return {
          label: o.trading_name,
          value: o.id,
        };
      });
    if (filteredOptions.length === 0) {
      return filteredOptions;
    }

    return isCustomerViewActive
      ? filteredOptions
      : [
          {
            label: selectedRegion.label,
            value: selectedRegion.value,
          },
          ...filteredOptions,
        ];
  }, [options, selectedRegion, isCustomerViewActive]);

  const getDefaultValue = useCallback(
    (options) => {
      if (options.length === 1) {
        return options[0];
      }
    },
    [regions]
  );

  const hasTradingNameError = errors.tradingNameId;

  if (isPresent(loadedSupplierTradingNameId)) {
    return FEATURE_FLAGS.FEATURE_FLAG_DROPDOWN_TIER ? (
      <>
        <AutoCompleteSelectField
          id="regions"
          errors={errors}
          isDisabled={true}
          isVisible={isPresent(options)}
          label={LABEL}
          name="tradingNameRegion"
          onChange={onChange}
          options={regions}
          value={{
            label: loadedSupplierName,
            value: loadedSupplierTradingNameId,
          }}
        />
      </>
    ) : (
      <SelectField
        errors={errors}
        isDisabled={true}
        isVisible={true}
        label={LABEL}
        name={name}
        onChange={onChange}
        options={[
          {
            label: loadedSupplierName,
            value: loadedSupplierTradingNameId,
          },
        ]}
        watch={watch}
      />
    );
  }

  return FEATURE_FLAGS.FEATURE_FLAG_DROPDOWN_TIER ? (
    <>
      <AutoCompleteSelectField
        id="regions"
        errors={errors}
        isDisabled={regions.length <= 1}
        isVisible={isPresent(regions)}
        label={LABEL}
        name="tradingNameRegion"
        onChange={handleRegionChange}
        options={regions}
        value={selectedRegion}
        defaultValue={getDefaultValue(regions)}
      />

      <AutoCompleteSelectField
        id="branches"
        errors={
          isCustomerViewActive && hasTradingNameError && !selectedBranch
            ? { tradingNameBranch: { message: 'This field is mandatory' } }
            : errors
        }
        isDisabled={isCustomerViewActive ? false : branches.length <= 1}
        isVisible={isPresent(branches)}
        label={LABEL_LOCAL_STORE}
        name="tradingNameBranch"
        onChange={handleBranchChange}
        options={branches}
        value={selectedBranch}
        defaultValue={getDefaultValue(branches)}
      />
    </>
  ) : (
    <SelectField
      errors={errors}
      isDisabled={false}
      isVisible={isPresent(options)}
      label={LABEL}
      name={name}
      onChange={onChange}
      options={options}
      rawOptions={rawOptions}
      watch={watch}
    />
  );
};

export default TradingNameField;
