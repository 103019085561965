import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Collapse, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomerViewContext } from './CustomerViewContext';

export const StyledAlert = styled(Alert)`
  margin-top: 20px;
  width: fit-content;
`;

export const EmptyAlert = () => {
  const { showEmptyGroupAlert, showEmptyRootAlert } =
    useContext(CustomerViewContext);
  return (
    <Collapse in={showEmptyGroupAlert || showEmptyRootAlert}>
      <StyledAlert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        {showEmptyGroupAlert ? (
          <>
            <Typography>You have groups without T3 children.</Typography>
            <Typography>
              Please ensure that all the groups have one or more T3 children, or
              remove groups that don't have T3 children.
            </Typography>
          </>
        ) : (
          <>
            <Typography>You don't have any groups.</Typography>
            <Typography>
              Please add at least one group with at least one T3 under it.
            </Typography>
          </>
        )}
      </StyledAlert>
    </Collapse>
  );
};
