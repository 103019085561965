import { useEffect } from 'react';

// default poll interval
const POLL_SPEED = 30000;

const usePolling = (polledFunction: any, pollSpeed = POLL_SPEED): void => {
  useEffect(() => {
    const interval = setInterval(() => {
      polledFunction();
    }, pollSpeed);

    return () => clearInterval(interval);
  }, []);
};

export default usePolling;
