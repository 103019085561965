import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import TopLeft from 'containers/position/TopLeft';
import BottomLeft from 'containers/position/BottomLeft';

/* Import CSS */
import styles from './css/WorkflowOverlay.css';

var WorkflowOverlay = createClass({
  render: function() {
    const { position, absolute, section_title, label, color } = this.props;

    if (section_title) {
      var main_section = (
        <li
          className={
            color === 'white'
              ? styles.main_section + ' ' + styles.white
              : styles.main_section
          }
        >
          {section_title}
        </li>
      );
    }

    if (label) {
      var sub_section = <li className={styles.sub_section}>{label}</li>;
    }

    let results = (
      <BottomLeft absolute={absolute}>
        <div
          className={
            color === 'white'
              ? styles.navbar + ' ' + styles.white
              : styles.navbar
          }
          role="navigation"
        >
          <div className={styles.navbar_header}>
            <Link to="/">1Centre.com</Link>
          </div>
          <div className={styles.nav}>
            <ul>
              {main_section}
              {sub_section}
            </ul>
          </div>
        </div>
      </BottomLeft>
    );

    if (position == 'top') {
      results = (
        <TopLeft absolute={absolute}>
          <div
            className={
              color === 'white'
                ? styles.navbar + ' ' + styles.white
                : styles.navbar
            }
            role="navigation"
          >
            <div className={styles.navbar_header}>
              <Link to="/">1Centre.com</Link>
            </div>
            <div className={styles.nav}>
              <ul>
                {main_section}
                {sub_section}
              </ul>
            </div>
          </div>
        </TopLeft>
      );
    }

    return <div>{results}</div>;
  },
});

export default WorkflowOverlay;
