import React from 'react';

import styles from '../../css/CommonReviewBusiness.css';
import historyStyles from './css/History.css';

var Shareholders = createClass({
  _shareholders() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyShareParcel =
        enquiryResult.company_details.company_share_parcels || {},
      shareParcels = companyShareParcel.company_share_parcel_ex_ws_dto || [];

    if (shareParcels.constructor === Object) shareParcels = [shareParcels];

    return shareParcels.map((parcel, index) => {
      let shareholders = parcel.company_shareholders || {},
        companyShareholders = shareholders.company_shareholder_ws_dto || [];

      return (
        <div key={index}>
          <h4>Share parcel</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name &amp; Address</th>
                <td>{this._address(companyShareholders)}</td>
              </tr>
              <tr>
                <th>Number of shares</th>
                <td>
                  {parseFloat(parcel.number_of_shares || 0).toLocaleString(
                    'en',
                    { maximumFractionDigits: 2 }
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    });
  },

  _address(shareholders) {
    if (shareholders.constructor === Object) {
      shareholders = [shareholders];
    }

    return shareholders.map((shareholder, index) => {
      return <p key={index}>{shareholder.address}</p>;
    });
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Shareholders</strong>
        </p>
        {this._shareholders()}
      </div>
    );
  },
});

export default Shareholders;
