/* Components */
import React from 'react';
import { connect } from 'react-redux';

import Button from '../../shared/components/inputs/Button';
import Password from '../../shared/components/inputs/Password';

import styles from './css/PasswordForm.css';

/* LoginForm */
var PasswordForm = createClass({
  getInitialState: function() {
    return {};
  },

  isValid: function() {
    const { password, confirm_password } = this.props;
    if (!password || password.length < 8) {
      return false;
    }
    if (confirm_password != password) {
      return false;
    }
    return true;
  },

  checkValid: function(target) {
    const { password, confirm_password } = this.props;

    switch (target.name) {
      case 'password':
        if (target.value.length < 8) {
          this.setState({
            password_error: 'Password must be atleast 8 characters long',
          });
        } else {
          const { password_error, ...rest } = this.state;
          this.setState({
            ...this.state,
            password_error: null,
          });
        }
        break;
      case 'confirm_password':
        if (target.value !== password) {
          this.setState({
            confirm_password_error: 'Passwords must match',
          });
        } else {
          this.setState({
            ...this.state,
            confirm_password_error: null,
          });
        }
        break;
    }
  },

  changeHandler: function(event) {
    const { changeHandler } = this.props;
    this.checkValid(event.target);
    changeHandler(event);
  },

  blurHandler: function(event) {
    const { blurHandler } = this.props;
    this.checkValid(event.target);
    blurHandler(event);
  },

  /**
   * Render
   *
   * @returns {XML}
   */
  render: function() {
    const {
      submitHandler,
      password,
      confirm_password,
      strength,
      css_class,
      instructions,
      password_text,
      confirm_text,
    } = this.props;
    let password_strength = <div className={styles.strength}>&nbsp;</div>,
      style = styles.form,
      instructions_text = 'Passwords must be 8 characters long',
      password_label = 'Password',
      confirm_label = 'Confirm Password',
      valid = this.isValid();

    if (css_class) {
      style = styles[css_class];
    }

    if (instructions) {
      instructions_text = instructions;
    }

    if (password_text) {
      password_label = password_text;
    }

    if (confirm_text) {
      confirm_label = confirm_text;
    }

    if (password.length > 0) {
      var indicators = [];
      for (var i = 0; i < 3; i++) {
        if (parseInt(strength) <= i) {
          indicators.push(
            <div className={styles.indicator_off} key={'indicator-' + i}></div>
          );
        } else {
          indicators.push(
            <div className={styles.indicator_on} key={'indicator-' + i}></div>
          );
        }
      }
      password_strength = (
        <div className={styles.strength} key="strength">
          <span className={styles.label}>Password strength</span>{' '}
          <span className={styles.indicators}>{indicators}</span>
        </div>
      );
    }

    let submit_text = 'confirm';
    if (!valid) {
      submit_text = 'more info required';
    }

    return (
      <form className={style} onSubmit={submitHandler} method="post" noValidate>
        <p className={styles.instructions}>{instructions_text}</p>
        <fieldset className={styles.fieldset}>
          <Password
            id="password"
            key="password"
            label={password_label}
            value={password}
            error={this.state.password_error}
            placeholder={password_label}
            handleChange={this.changeHandler}
            handleBlur={this.blurHandler}
          />
          <Password
            id="confirm_password"
            key="confirm_password"
            label={confirm_label}
            value={confirm_password}
            error={this.state.confirm_password_error}
            placeholder={confirm_label}
            handleChange={this.changeHandler}
            handleBlur={this.blurHandler}
          />
          {password_strength}
        </fieldset>
        <div className={styles.button}>
          <Button
            type="submit"
            key="submit_confirm"
            id="confirm"
            text={submit_text}
            disabled={!valid}
          />
        </div>
      </form>
    );
  },
});

module.exports = connect((state) => {
  return {
    password: state.register.password,
    confirm_password: state.register.confirm_password,
  };
})(PasswordForm);
