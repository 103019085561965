import { StepLabel } from 'mui-latest';
import styled from 'styled-components';
import { COLORS } from 'variables/theme';

export const Connector = styled.div`
  border-color: #bdbdbd !important;
  border-left-style: solid !important;
  border-left-width: 1px !important;
  display: block !important;
  margin-left: 8.5px !important;
  min-height: 25px !important;
  padding: 0px !important;

  @media (max-width: 767px) {
    min-height: 40px !important;
    border-color: #ffffff !important;
  }
`;

export const DrawerPanel = styled.div`
  font-size: 16px;
  padding: 32px 0px 0px 40px;
`;

export const StyledStepLabel = styled(StepLabel)`
  &.MuiStepLabel-root {
    .MuiStepLabel-labelContainer {
      .MuiTypography-root.MuiStepLabel-label {
        color: black !important;
        font-size: 1em;

        &.MuiStepLabel-active {
          color: var(--main-color) !important;
        }
      }
    }
    .MuiStepLabel-iconContainer {
      .MuiSvgIcon-root.MuiStepIcon-root {
        color: #b1b1b1 !important;

        .MuiStepIcon-text {
          fill: #b1b1b1 !important;
        }

        &.MuiStepIcon-completed {
          color: var(--main-color) !important;
        }

        &.MuiStepIcon-active {
          color: ${COLORS.darkGrey} !important;

          .MuiStepIcon-text {
            fill: ${COLORS.darkGrey} !important;
          }
        }
      }
    }
  }
`;
