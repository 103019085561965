import api from 'api';
import {
  ContentWrapper,
  ModalCloseIcon,
  ModalWrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/Modal/style';
import ButtonGroupWrapper from 'modules/consumer-onboarding/v2/ButtonGroupWrapper';
import FormFieldWrapper from 'modules/consumer-onboarding/v2/FormFieldWrapper';
import {
  EmailPrompt,
  FormWrapper,
  MobileWrapper,
  SectionHeader,
} from 'modules/consumer-onboarding/v2/styles';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal.js';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import { userLoginV2 } from 'modules/user/actions';
import { InputAdornment } from 'mui-latest';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
// @ts-ignore-next-line
import { browserHistory } from 'react-router';
import isBlank from 'utils/isBlank';
import * as yup from 'yup';

type FormValues = {
  password: string;
};

const validationSchema = () =>
  yup.object().shape({
    password: yup.string().required('Please enter your password'),
  });

const ExistingPasswordForm = (props): ReactElement => {
  const {
    dispatch,
    onBack,
    redirectPath,
    userState,
    router: { location },
  } = props;
  const { email } = userState;

  const {
    query: { xtoken, sudo_token: sudoToken },
  } = location;

  const [isToBeRedirected, setIsToBeRedirected] = useState(false);
  const [sudoLoginError, setSudoLoginError] = useState<string | null>(null);

  // This is for super admin users who has the appropriate tokens to get into
  // the BC flow
  useEffect(() => {
    if (isBlank(xtoken)) {
      return;
    }

    const decodedToken = atob(xtoken);
    const [token, bypass] = decodedToken.split('|');

    if (bypass !== 'bypass') {
      return;
    }

    onSubmit({ email, password: token });
  }, [xtoken]);

  const onSudoLoginError = (status) => {
    if (status === 404) {
      setSudoLoginError(
        'Your Call Centre access has expired or you have no access to the application anymore.' +
          ' Please close this tab and request the Call Centre access again.'
      );
    }
  };

  if (!isBlank(sudoToken)) {
    const onCloseModal = () => {
      setIsToBeRedirected(true);
      dispatch(
        // @ts-ignore-next-line
        userLoginV2({
          loginErrorCallback: onSudoLoginError,
          redirect: redirectPath,
          sudoToken,
        })
      );
    };
    return (
      <ModalWrapper>
        <SquareModal
          title={sudoLoginError ? 'Error' : 'Confirm you are authorised'}
          size="small"
        >
          <ContentWrapper>
            {sudoLoginError ? (
              sudoLoginError
            ) : (
              <>
                <p>
                  Please confirm you&apos;re authorised to fill out this
                  application on behalf of the customer.
                  <br />
                  Once confirmed, the system will keep a permanent record of
                  your activity regarding this application.
                </p>
                <div className="is-flex is-justify-content-center">
                  <Button
                    small={true}
                    text="Confirm"
                    handleClick={onCloseModal}
                    loading_text="You'll be redirected shortly..."
                    loading={isToBeRedirected}
                  />
                </div>
              </>
            )}
          </ContentWrapper>
        </SquareModal>
      </ModalWrapper>
    );
  }

  const { control, handleSubmit, setError } = useForm<FormValues>({
    defaultValues: {
      password: '',
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const onLoginError = (status) => {
    if (status === 404) {
      setError('password', { message: 'Incorrect password', type: 'custom' });
    } else if (status === 403) {
      setError('password', {
        message:
          'Too many incorrect login attempts. The account is locked for 30 minutes',
        type: 'custom',
      });
    } else {
      setError('password', {
        message: 'Something went wrong. Please refresh your browser',
      });
    }
  };

  const onSubmit = (data) => {
    const { email, password } = data;

    dispatch(
      // @ts-ignore-next-line
      userLoginV2({
        email,
        loginErrorCallback: onLoginError,
        password,
        redirect: redirectPath,
      })
    );
  };

  const onClickResetPassword = () => {
    const { pathname, search } = location;
    const redirectPath = encodeURIComponent(`${pathname}${search}`);

    browserHistory.push(`/user/forgot-password?redirect=${redirectPath}`);
  };

  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      {isMobileScreen && (
        <MobileWrapper>
          <SectionHeader>Start your application with 1Centre</SectionHeader>
          <EmailPrompt>Please enter your password</EmailPrompt>
        </MobileWrapper>
      )}
      {!isMobileScreen && (
        <SectionHeader>Please enter your password to log in</SectionHeader>
      )}
      <FormFieldWrapper>
        <RHFBorderedEmailField
          control={control}
          name="email"
          disabled={true}
          defaultValue={email}
          label="Email address"
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <RHFBorderedTextField
          control={control}
          customProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span className="icon is-small is-left ">
                  <i className="fas fa-key"></i>
                </span>
              </InputAdornment>
            ),
          }}
          inputProps={{
            type: 'password',
          }}
          name="password"
          placeholder="Password"
        />
        <div className="is-full-width mt-2">
          <a className="is-link" onClick={onClickResetPassword}>
            Reset password
          </a>
        </div>
      </FormFieldWrapper>

      <ButtonGroupWrapper>
        <FormFieldWrapper>
          <Button text="Next" type="submit" />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Button text="Back" handleClick={onBack} white />
        </FormFieldWrapper>
      </ButtonGroupWrapper>
    </FormWrapper>
  );
};

export default connect(() => {
  return {};
})(ExistingPasswordForm);
