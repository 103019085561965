import api from 'api';
import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import NoteModel from 'models/NoteModel';
import { formatLocalTime } from 'utils/dateFormatter';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

import AddonAnswerModel from './AddonAnswerModel';
import AddonRuleModel from './AddonRuleModel';
import IUFApprovalModel from './IUFApprovalModel';

const IN_PROGRESS_STATUS = 0;

export default class LeadModel extends BaseModel {
  get formattedKeyContact() {
    if (isPresent(this.consumerContactName)) {
      return this.consumerContactName;
    }

    if (isPresent(this.consumerContactEmail)) {
      return this.consumerContactEmail;
    }

    return '-';
  }

  get formattedCreatedAt() {
    return formatLocalTime(this.createdAt, 'date');
  }

  get formattedSubmittedAt() {
    if (isPresent(this.submittedAt)) {
      return formatLocalTime(this.submittedAt, 'date');
    }

    return '-';
  }

  get formattedLegalType() {
    return capitalize(this.legalType || '').replace('_', ' ');
  }

  get formattedRegion() {
    if (this.region === 'NZ') {
      return 'New Zealand';
    }

    if (this.region === 'AU') {
      return 'Australia';
    }

    return '';
  }

  get businessNumberLabel() {
    if (this.region === 'NZ') {
      return 'Business number';
    }

    return 'ABN';
  }

  get companyNumberLabel() {
    if (this.region === 'NZ') {
      return 'Company number';
    }

    return 'ACN';
  }

  get mainNotes() {
    return this.notes.filter((note) => isBlank(note.parentId));
  }

  get isInProgress() {
    return this.status === IN_PROGRESS_STATUS;
  }

  get isLoadedInSystem() {
    return this.status === 'loaded_in_system';
  }

  get iufAddonAnswers() {
    return this.addonAnswer || {};
  }

  get iufAddonRule() {
    return this.addonRule;
  }

  get isIUFApprovalEnabled() {
    return get(this.iufAddonRule, 'config.pricing.approval_enabled', false);
  }

  get isIUFApprovalRequired() {
    return get(this.iufAddonRule, 'config.pricing.approval_required', false);
  }

  get loadedInSystemStatus() {
    return this.loadedInSystemAt ? 'complete' : 'not_started';
  }

  get internalUseFieldsStatus() {
    if (!this.hasIUFAddonRule) {
      return 'complete';
    }

    if (!this.iufAddonAnswers) {
      return 'not_started';
    }

    if (this.isIUFApprovalRequired && !this.iufApproval.approvedAt) {
      return 'in_progress';
    }

    return this.iufAddonAnswers.status === 'completed'
      ? 'complete'
      : 'in_progress';
  }

  get hasIUFAddonRule() {
    return isPresent(this.iufAddonRule.attributes);
  }

  get formattedBusinessName() {
    let name = '';
    if (this.legalType === 'company') {
      name = this.companyName || '';
    }

    name = this.tradingName || '';

    return name.toUpperCase();
  }

  get isIUFVisible() {
    const hasOlderIUF = Array.from(Array(4).keys()).some((number) =>
      Boolean(get(this, `internalField${number}`))
    );

    return hasOlderIUF || this.hasIUFAddonRule;
  }

  initiator(isHeadquarter) {
    return isHeadquarter ? this.supplierName : this.supplierContactName;
  }

  addNote(note, index) {
    if (typeof index === 'undefined' || index < 0) {
      this.notes.unshift(note);
    } else {
      this.notes.splice(index, 1, note);
    }

    this.refreshNoteRelationship(note);
    this.refreshNoteIncluded(note);
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  async onLoadedInSystem({ currentUser, onErrorCallback, onSuccessCallback }) {
    const leadsAPI = api(
      'leads',
      currentUser.accessToken,
      currentUser.currentEntity.id
    );

    try {
      await leadsAPI.loadedInSystem(this.id);

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    } catch (error) {
      console.error(error);

      if (onErrorCallback) {
        onErrorCallback();
      }
    }
  }

  /** Private functions */

  assignRelationships() {
    this.assignManyRelationship({
      key: 'notes',
      model: NoteModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'addon_rule',
      model: AddonRuleModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'addon_answer',
      model: AddonAnswerModel,
    });
    this.assignSingleRelationship({
      included: this.included,
      key: 'iuf_approval',
      model: IUFApprovalModel,
    });
  }

  refreshNoteRelationship(note) {
    const noteRelationships = get(this.relationships, 'notes.data', []);

    const index = noteRelationships.findIndex(
      (relationship) => relationship.id === note.id
    );

    if (index < 0) {
      this.addNoteRelationship({ note, noteRelationships });
    } else {
      this.updateNoteRelationship({ index, note, noteRelationships });
    }
  }

  addNoteRelationship({ note, noteRelationships }) {
    noteRelationships.unshift({ id: note.id, type: 'notes' });

    this.relationships = {
      ...this.relationships,
      notes: { data: noteRelationships },
    };

    this.data = { ...this.data, relationships: this.relationships };
  }

  updateNoteRelationship({ index, note, noteRelationships }) {
    noteRelationships.splice(index, 1, { id: note.id, type: 'notes' });

    this.relationships = {
      ...this.relationships,
      notes: { data: noteRelationships },
    };

    this.data = { ...this.data, relationships: this.relationships };
  }

  refreshNoteIncluded(note) {
    const index = this.included.findIndex(
      (included) => included.type === 'notes' && included.id === note.id
    );

    if (index < 0) {
      this.included.unshift(note.data);
    } else {
      this.included.splice(index, 1, note.data);
    }
  }
}
