import { FEATURE_FLAGS } from 'conf';
import React, { ReactElement } from 'react';

const UnknownDetails = ({
  idVerified,
}: {
  idVerified: 'Pass' | 'Fail' | 'Unknown';
}): ReactElement | null => {
  if (idVerified !== 'Unknown') {
    return null;
  }

  return (
    <div>
      {FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES
        ? 'Requires Manual verification as the ID cannot be verified by the local governing body'
        : 'We have not been able to recognise this ID, so cannot verify it via the appropriate agency.'}
    </div>
  );
};

export default UnknownDetails;
