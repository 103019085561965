import AuthorisationRequestModel from 'models/AuthorisationRequestModel';
import AuthorisationRequestRequesteeModel from 'models/AuthorisationRequestRequesteeModel';
import UserModel from 'models/UserModel';
import { useEffect, useState } from 'react';
import isPresent from 'utils/isPresent';

interface IRequesteeOption {
  label: string;
  requesteeId: string;
  requesteeType: string;
  value: string;
}

interface IBusinessOverview {
  authorisationRequest: AuthorisationRequestModel | null;
  consumerIdToJoin: string | null;
  isAuthorisationRequestLoaded: boolean;
  isRequestAccessFormVisible: boolean;
  onRetrieveAuthorisationRequestForApplication: () => void;
  onSetConsumerIdToJoin: (
    consumerId: string | null,
    belongsToEntity: boolean
  ) => void;
  requesteesOptions: IRequesteeOption[];
  setAuthorisationRequest: (
    authorisationRequest: AuthorisationRequestModel
  ) => void;
  isLoading: boolean;
}

const buildRequesteesOptions = (
  requestees: AuthorisationRequestRequesteeModel[]
): IRequesteeOption[] =>
  requestees.map((requestee) => {
    return {
      brief: requestee.fullName,
      label: requestee.fullName,
      requesteeId: requestee.id,
      requesteeType: requestee.modelType,
      value: `${requestee.modelType}|${requestee.id}`,
    };
  });

const getIsRequestAccessFormVisible = ({
  authorisationRequest,
  belongsToEntity,
  consumerIdToJoin,
}: {
  authorisationRequest: AuthorisationRequestModel;
  belongsToEntity: boolean;
  consumerIdToJoin: string | null;
}): boolean => {
  if (authorisationRequest.isApproved) {
    return false;
  }

  return isPresent(consumerIdToJoin) && !belongsToEntity;
};

const useBusinessOverviewState = ({
  application,
  currentUser,
}: {
  application: any;
  currentUser: UserModel;
}): IBusinessOverview => {
  const [authorisationRequest, setAuthorisationRequest] =
    useState<AuthorisationRequestModel>(new AuthorisationRequestModel());
  const [belongsToEntity, setBelongsToEntity] = useState(false);
  const [consumerIdToJoin, setConsumerIdToJoin] = useState<string | null>(null);
  const [isAuthorisationRequestLoaded, setIsAuthorisationRequestLoaded] =
    useState(false);
  const [isLoading, setLoading] = useState(false);

  const isRequestAccessFormVisible = getIsRequestAccessFormVisible({
    authorisationRequest,
    belongsToEntity,
    consumerIdToJoin,
  });

  const onSetConsumerIdToJoin = async (
    consumerId: string | null,
    userBelongsToEntity: boolean
  ) => {
    setConsumerIdToJoin(consumerId);
    setBelongsToEntity(userBelongsToEntity);

    if (consumerId) {
      setLoading(true);
      const retrievedRecord =
        await AuthorisationRequestModel.retrieveForConsumerAndRequester({
          accessToken: currentUser.accessToken,
          applicationId: application.id,
          consumerId,
          requesterId: currentUser.id,
        });

      setAuthorisationRequest(retrievedRecord);
      setLoading(false);
    } else {
      if (authorisationRequest.isPersisted) {
        setAuthorisationRequest(new AuthorisationRequestModel());
      }
    }
  };

  const onRetrieveAuthorisationRequestForApplication = async () => {
    const retrievedRecord =
      await AuthorisationRequestModel.retrieveForApplication({
        accessToken: currentUser.accessToken,
        applicationId: application.id,
      });

    setAuthorisationRequest(retrievedRecord);
    setIsAuthorisationRequestLoaded(true);
  };

  useEffect(() => {
    onRetrieveAuthorisationRequestForApplication();
  }, [application.id, currentUser.id]);

  let requesteesOptions: IRequesteeOption[] = [];

  if (authorisationRequest) {
    requesteesOptions = buildRequesteesOptions(authorisationRequest.requestees);
  }

  return {
    authorisationRequest,
    consumerIdToJoin,
    isAuthorisationRequestLoaded,
    isRequestAccessFormVisible,
    onRetrieveAuthorisationRequestForApplication,
    onSetConsumerIdToJoin,
    requesteesOptions,
    setAuthorisationRequest,
    isLoading,
  };
};

export default useBusinessOverviewState;
