import React, { ReactElement } from 'react';
import ControlWrapper from 'modules/consumer-onboarding/v2/ControlWrapper';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const Error = styled(Typography)`
  position: relative;
  top: -18px;
`;

const FormField = (props): ReactElement => {
  const { children, label, error } = props;

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <ControlWrapper>{children}</ControlWrapper>
      {error ? <Error color="error">{error}</Error> : null}
    </div>
  );
};

export default FormField;
