import React, { ReactElement } from 'react';
import Button from 'modules/shared/components/inputs/Button';

const V2Documentation = (): ReactElement => (
  <div>
    <Button
      small
      grey
      text="webhook documentation"
      link="/webhook_documentation/v2"
      target="_blank"
    />
  </div>
);

export default V2Documentation;
