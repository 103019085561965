export enum EntityType {
  Association = 'association',
  Club = 'club',
  Company = 'company',
  Education = 'education',
  Government = 'government',
  Other = 'other',
  Partnership = 'partnership',
  Personal = 'personal',
  Society = 'society',
  SoleTrader = 'sole_trader',
  Trust = 'trust',
}
