import React from 'react';

/* Import CSS */
import styles from './css/Pagination.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

var Pagination = createClass({
  getDefaultProps() {
    return {
      resourceName: 'Applications',
    };
  },
  onClick: function(event) {
    const { handleClick } = this.props;
    handleClick(Number(event.target.id));
  },
  onClickTodosPerPage: function(event) {
    const { handleClickTodosPerPage } = this.props;
    handleClickTodosPerPage(Number(event.target.id));
  },
  onClickPrev: function() {
    const { currentPage, handleClick } = this.props;
    if (currentPage > 1) {
      handleClick(currentPage - 1);
    }
  },
  onClickNext: function() {
    const { dataLength, currentPage, todosPerPage, handleClick } = this.props;
    if (currentPage < Math.ceil(dataLength / todosPerPage)) {
      handleClick(currentPage + 1);
    }
  },

  render: function() {
    const {
      dataLength,
      currentPage,
      todosPerPage,
      optionViewPage,
      resourceName,
      handleClick,
    } = this.props;

    // Logic for displaying page numbers
    const pageNumbers = Math.ceil(dataLength / todosPerPage);

    var current = currentPage,
      last = pageNumbers,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    if (current < delta + 1) {
      delta = delta * 2 + 2 - current;
      right = current + delta;
    }
    if (current > last - (delta + 1)) {
      delta = delta * 2 - (last - current);
      left = current - delta;
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push('...');
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    const renderPageNumbers = rangeWithDots.map((number, index) => {
      return (
        <li
          key={`${number}_${index}`}
          className={
            Number.isInteger(number)
              ? number == currentPage
                ? styles.active
                : ''
              : styles.noclickable
          }
          id={number}
          onClick={Number.isInteger(number) ? this.onClick : ''}
        >
          {number}
        </li>
      );
    });

    const renderOptionViewpages = optionViewPage.map((viewPage) => {
      if (viewPage.value == todosPerPage) {
        return (
          <li
            key={viewPage.value}
            id={viewPage.value}
            onClick={this.onClickTodosPerPage}
          >
            {`${viewPage.label} ${resourceName.charAt(0).toUpperCase() +
              resourceName.slice(1)}`}
          </li>
        );
      } else {
        return (
          <li key={viewPage.value}>
            <span id={viewPage.value} onClick={this.onClickTodosPerPage}>
              {`View ${viewPage.label} per page`}
            </span>
          </li>
        );
      }
    });

    var style_prev = styles.prev;
    var style_next = styles.next;
    if (currentPage <= 1) {
      style_prev = styles.prev + ' ' + styles.noclickable;
    }
    if (currentPage >= pageNumbers) {
      style_next = styles.next + ' ' + styles.noclickable;
    }

    return (
      <div>
        <ul className={styles.view}>{renderOptionViewpages}</ul>

        <ul className={styles.default_style}>
          <li>
            <ChevronLeftIcon
              className={styles.icon}
              onClick={this.onClickPrev}
            />
          </li>
          {renderPageNumbers}
          <li>
            <ChevronRightIcon
              className={styles.icon}
              onClick={this.onClickNext}
            />
          </li>
        </ul>
      </div>
    );
  },
});

export default Pagination;
