import React from 'react';

/* Import CSS */
import styles from './css/Review.css';

import LabeledInformation from '../../shared/components/widgets/static/LabeledInformation';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';

var ReviewPaymentInfo = createClass({
  render: function() {
    const { data } = this.props;
    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <ReviewContainer
          content_class="content_full"
          subHeading="Trade account limit"
        >
          <LabeledInformation
            label="Limit"
            data={`$${Number(
              data.application.attributes.trade_account_limit
            ).toLocaleString()}`}
          />
        </ReviewContainer>
      </section>
    );
  },
});

module.exports = ReviewPaymentInfo;
