import * as Sentry from '@sentry/browser';
import api from 'api';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import UserModel from 'models/UserModel';

export default class UserEntityLinkModel extends BaseModel {
  static async fetchUsers({ accessToken, entityId }) {
    const userEntityLinkAPI = api('user_entity_links', accessToken, entityId);

    try {
      const result = await userEntityLinkAPI.getAllUsers();
      const data = get(result, 'data.data', []);

      return data.map((datum) => new UserModel(datum));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      return [];
    }
  }

  static async fetchApplicationApprovers({
    accessToken,
    applicationId,
    entityId,
  }) {
    const userEntityLinkAPI = api('user_entity_links', accessToken, entityId);

    try {
      const result = await userEntityLinkAPI.getApplicationApprovers(
        applicationId
      );
      const data = get(result, 'data.data', []);

      return data.map((datum) => new UserModel(datum));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      return [];
    }
  }

  static async fetchApplicationEscalation({
    accessToken,
    applicationId,
    entityId,
    levelWithApprover,
  }) {
    const userEntityLinkAPI = api('user_entity_links', accessToken, entityId);

    try {
      const result = await userEntityLinkAPI.getApplicationEscalation(
        applicationId,
        levelWithApprover
      );
      const data = get(result, 'data.data', []);

      return data.map((datum) => new UserModel(datum));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      return [];
    }
  }

  static async fetchApplicationReviewers({
    accessToken,
    applicationId,
    entityId,
  }) {
    const userEntityLinkAPI = api('user_entity_links', accessToken, entityId);

    try {
      const result = await userEntityLinkAPI.getApplicationReviewers(
        applicationId
      );
      const data = get(result, 'data.data', []);

      return data.map((datum) => new UserModel(datum));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      return [];
    }
  }

  static async fetchIUFApprovers(params) {
    const userEntityLinkAPI = api('user_entity_links');

    try {
      const result = await userEntityLinkAPI.getIUFApprovers(params);
      const data = get(result, 'data.data', []);

      return data.map((datum) => new UserEntityLinkModel(datum));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);

      return [];
    }
  }

  static async retrieveByUserAndEntity({ accessToken, entityId, userId }) {
    const userEntityLinkAPI = api('user_entity_links', accessToken, entityId);

    try {
      const result = await userEntityLinkAPI.retrieveByUserAndEntity(
        entityId,
        userId
      );
      const data = get(result, 'data.data', []);

      return new UserEntityLinkModel(data);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  }
}
