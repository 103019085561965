import api from '../../api';
import {
  CREATE_WEBHOOK_ERROR,
  CREATE_WEBHOOK_START,
  CREATE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_ERROR,
  DELETE_WEBHOOK_START,
  DELETE_WEBHOOK_SUCCESS,
  LOAD_WEBHOOKS_ERROR,
  LOAD_WEBHOOKS_START,
  LOAD_WEBHOOKS_SUCCESS,
  SAVE_WEBHOOK_ERROR,
  SAVE_WEBHOOK_START,
  SAVE_WEBHOOK_SUCCESS,
  TEST_WEBHOOK_ERROR,
  TEST_WEBHOOK_START,
  TEST_WEBHOOK_SUCCESS,
} from './constants';
import Webhook from './Webhook';

export function getWebhooks() {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_WEBHOOKS_START,
    });

    const entities = api(
      'entities',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    entities.getEntityWebhooks(
      getState().current_user.current_entity.id,
      (response) => {
        const webhooks = Webhook.parseData(response);
        dispatch({
          payload: webhooks,
          type: LOAD_WEBHOOKS_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LOAD_WEBHOOKS_ERROR,
        });
      }
    );
  };
}

export function getWebhook(id) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_WEBHOOKS_START,
    });

    const webhooks = api(
      'webhooks',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    webhooks.getWebhook(
      id,
      (response) => {
        const webhooks = Webhook.parseData(response);
        dispatch({
          payload: webhooks,
          type: LOAD_WEBHOOKS_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: LOAD_WEBHOOKS_ERROR,
        });
      }
    );
  };
}

export function saveWebhook(webhook, success) {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_WEBHOOK_START,
    });

    const webhooks = api(
      'webhooks',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    webhooks.saveWebhook(
      webhook.attributes,
      (response) => {
        dispatch({
          type: CREATE_WEBHOOK_SUCCESS,
        });
        success(response.data.data.id);
      },
      (error) => {
        dispatch({
          payload: error,
          type: CREATE_WEBHOOK_ERROR,
        });
      }
    );
  };
}

export function deleteWebhook(webhook, success) {
  return (dispatch, getState) => {
    dispatch({
      type: DELETE_WEBHOOK_START,
    });

    const webhooks = api(
      'webhooks',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    webhooks.deleteWebhook(
      webhook.attributes.id,
      (response) => {
        dispatch({
          type: DELETE_WEBHOOK_SUCCESS,
        });
        success(response);
      },
      (error) => {
        dispatch({
          payload: error,
          type: DELETE_WEBHOOK_ERROR,
        });
      }
    );
  };
}

export function sendTest(webhook, applicationId, success, error) {
  return (dispatch, getState) => {
    dispatch({
      type: TEST_WEBHOOK_START,
    });

    const webhooks = api(
      'webhooks',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    webhooks.sendTest(
      webhook.attributes.id,
      applicationId,
      (response) => {
        dispatch({
          type: TEST_WEBHOOK_SUCCESS,
        });
        if (success) {
          success(response);
        }
      },
      (response) => {
        dispatch({
          payload: response,
          type: TEST_WEBHOOK_ERROR,
        });
        if (error) {
          error(response);
        }
      }
    );
  };
}
