import React, { ReactElement } from 'react';
import SentTo from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/StatusInformation';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';
import QAndAHelpLink from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink';
import { AUTHORISATION_REQUEST } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';

const InformationContent = ({
  authorisationRequest,
}: {
  authorisationRequest: AuthorisationRequestModel;
}): ReactElement => (
  <>
    <div className="mb-4 is-flex">
      <span className="has-text-warning icon mr-2">
        <i className="fas fa-exclamation-circle"></i>
      </span>

      <span className="mr-1">
        Authorisation is required from any of the below individuals for you to
        progress this application.
      </span>
      <QAndAHelpLink questionKey={AUTHORISATION_REQUEST} />
    </div>
    <SentTo authorisationRequest={authorisationRequest} />
  </>
);

export default InformationContent;
