import { createApplicationForNewConsumer } from 'modules/consumer-invite/actions/invite';
import {
  ButtonWrapper,
  FormWrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/Modal/Form/styles';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedEmailField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedEmailField';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form-latest';
import { getWatchtowerBlockedMessage, processError } from 'utils';
// Refer to https://github.com/jquense/yup/issues/507
import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';

type FormValues = {
  email: string;
};

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .required('Please enter a valid email')
      .email('Please enter a valid email')
      .test('is-valid', 'Please enter a valid email', (value) =>
        isEmail(value)
      ),
  });

const Form = (props): ReactElement => {
  const { applicationType, dispatch, onErrorCallback, onSuccessSendCallback } =
    props;
  const { control, handleSubmit, setError } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const handleError = (error) => {
    // Here we check if the error is due to the email blocked by Watchtower or not.

    const { errorObject, httpStatusCode } = processError(error);

    // Check whether the email is blocked by Watchtower
    if (httpStatusCode === 403 && errorObject?.blocked) {
      // The email is blocked by Watchtower. Let's display the
      // corresponding error message.
      setError('email', {
        type: 'custom',
        message: getWatchtowerBlockedMessage('email'),
      });
    } else {
      // The email is not blocked by watchtower. The error is something else.
      // Let's pass on the error to the error handler function.
      onErrorCallback(error);
    }
  };

  const onSubmit = (data) => {
    const { email } = data;

    dispatch(
      createApplicationForNewConsumer({
        application_type: applicationType,
        email,
        errorCallback: handleError,
        iufAnswers: null,
        overriddenRules: {},
        successCallback: () => onSuccessSendCallback(email),
      })
    );
  };

  return (
    <>
      <div className="mb-4 has-text-centered">
        Please enter the email of the recipient.
      </div>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <div className="control">
            <RHFBorderedEmailField
              control={control}
              name="email"
              withIcon={false}
              noValidationOnEmpty
              label="Email address"
              dataCy="send-invite-email-input"
            />
          </div>
        </div>

        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div className="field">
            <div className="control">
              <ButtonWrapper>
                <Button
                  text="Send"
                  type="submit"
                  dataCy="send-invite-submit-button"
                />
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </FormWrapper>
    </>
  );
};

export default Form;
