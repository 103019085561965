import Autocomplete from '@material-ui/lab/Autocomplete';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import styles from 'modules/shared/components/inputs/css/BorderedAutocomplete.css';
import React from 'react';

export default function BorderedAutocomplete(props) {
  const { textFieldProps, withBottomMargin, ...autocompleteProps } = props;

  const styleClasses = {
    inputRoot: styles.input_root,
    listbox: styles.listbox,
    root: styles.root,
    popper: styles.popper,
  };

  let getOptionSelected = (option) => (option || {}).value;

  if (props.disabled) {
    styleClasses.inputRoot = styles.input_root_disabled;
    styleClasses.root = styles.root_disabled;
  }

  if (props.getOptionSelected) {
    getOptionSelected = props.getOptionSelected;
  }

  return (
    <Autocomplete
      getOptionLabel={(option) => (option || {}).label || ''}
      getOptionSelected={getOptionSelected}
      size="small"
      renderInput={(params) => {
        const textFieldParams = { ...textFieldProps, ...params };
        return (
          <BorderedTextField
            customProps={{
              withBottomMargin,
            }}
            {...textFieldParams}
            InputLabelProps={{
              ...textFieldProps.InputLabelProps,
              ...params.InputLabelProps,
            }}
          />
        );
      }}
      classes={{ ...styleClasses }}
      {...autocompleteProps}
    />
  );
}

BorderedAutocomplete.defaultProps = {
  textFieldProps: {},
  withBottomMargin: true,
};
