import { useState } from 'react';

const useSupplierLoadedState = () => {
  const [isSupplierAPIComplete, setIsSupplierAPIComplete] = useState(false);
  const [
    isSupplierLoadedSuccessfully,
    setIsSupplierLoadedSuccessfully,
  ] = useState(false);

  const onLoadSupplierSuccess = () => {
    setIsSupplierAPIComplete(true);
    setIsSupplierLoadedSuccessfully(true);
  };

  const onLoadSupplierError = () => {
    setIsSupplierAPIComplete(true);
    setIsSupplierLoadedSuccessfully(false);
  };

  return {
    isSupplierAPIComplete,
    isSupplierLoadedSuccessfully,
    onLoadSupplierError,
    onLoadSupplierSuccess,
  };
};

export default useSupplierLoadedState;
