import get from "lodash.get";
import snakecase from "lodash.snakecase";
import useIsLoadingState from "modules/new-applications/hooks/useIsLoadingState";
import { updateApplicationTypeNames } from "modules/profile/actions";
import styles from "modules/profile/components/css/CommonEditProfile.css";
import Button from "modules/shared/components/inputs/Button";
import TextInput from "modules/shared/components/inputs/TextInput";
import useYupValidationResolver from "modules/shared/hooks/useYupValidationResolver";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form-latest";
import { connect } from "react-redux";
import * as yup from "yup";

const MAX_CHARACTERS = 15;

type TFieldNames = "creditApplicationTypeName" | "cashApplicationTypeName";

const Form = ({
  dispatch,
  fieldName,
  fieldValue,
  isDisabled,
  label,
}: {
  dispatch: any;
  fieldName: TFieldNames;
  fieldValue: string;
  isDisabled: boolean;
  label: string;
}): JSX.Element => {
  const { isLoading, setIsLoading } = useIsLoadingState();

  const validationSchema = () =>
    yup.object().shape({
      [fieldName]: yup
        .string()
        .required("Please enter a value.")
        .max(
          MAX_CHARACTERS,
          attributes => `Please enter a up to ${attributes.max} characters.`
        ),
    });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<Record<TFieldNames, string>>({
    defaultValues: { [fieldName]: fieldValue },
    mode: "onSubmit",
    resolver: useYupValidationResolver(validationSchema),
  });

  const currentFieldValue = watch(fieldName);

  useEffect(() => {
    register(fieldName);
  }, [register, fieldName]);

  const onChange = e =>
    setValue(fieldName, e.target.value.substring(0, MAX_CHARACTERS));

  const onBlur = e => setValue(fieldName, e.target.value.trim());

  const onSubmit = (data: { [key: string]: string }) => {
    setIsLoading(true);

    dispatch(
      updateApplicationTypeNames({
        errorCallback: () => setIsLoading(false),
        key: snakecase(fieldName),
        successCallback: () => setIsLoading(false),
        value: data[fieldName],
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`${styles.items} is-justify-content-start`}>
        <div className="mr-6">
          <p className="mb-4">{label}</p>
          <TextInput
            disabled={isDisabled}
            error={get(errors, `${fieldName}.message`, "")}
            handleChange={onChange}
            handleBlur={onBlur}
            id={fieldName}
            required={true}
            value={currentFieldValue}
          />
        </div>
        <div className="is-align-self-center">
          <Button
            disabled={isDisabled}
            disableOnLoading={true}
            loading={isLoading}
            loading_text="Updating..."
            text="Update"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default connect()(Form);
