import Button from 'modules/shared/components/inputs/Button';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import { TFormSubmissionStatusRender } from 'modules/shared/components/v2/Form/useFormSubmissionStatus';
import RHFBorderedTextArea from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextArea';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React, { ReactElement } from 'react';
import {
  Control,
  useFieldArray,
  UseFormHandleSubmit,
} from 'react-hook-form-latest';

import {
  MAX_CHECKLIST_CHARACTERS,
  MAX_CUSTOMISED_MSG_CHARACTERS,
} from './helper';
import {
  Buttons,
  CharacterCount,
  CustomisedMessageWrapper,
  DeleteBtn,
} from './styles';
import { FormValues } from './types';

type Props = {
  clearStatus: () => void;
  control: Control<FormValues, any> | any;
  displayPreview: () => void;
  handleSubmit: UseFormHandleSubmit<FormValues>;
  isDirty: boolean;
  isValid: boolean;
  loading: boolean;
  status: TFormSubmissionStatusRender;
  submit: (data: FormValues) => void;
  values: FormValues;
};

function Links({ control }): ReactElement {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'links',
  });

  const disableBranch = isFeatureEditEnabled('Business');

  return (
    <>
      {fields.map((field, index) => (
        <div className="columns is-mobile" key={`link-edit-${field.id}`}>
          <div className="column">
            <RHFBorderedTextField
              control={control}
              placeholder="Link"
              name={`links.${index}.value`}
              readOnly={!disableBranch}
            />
          </div>
          <div className="column">
            <RHFBorderedTextField
              control={control}
              placeholder="Name of link"
              name={`links.${index}.label`}
              inputProps={{ maxLength: MAX_CHECKLIST_CHARACTERS }}
              readOnly={!disableBranch}
            />
          </div>
          {disableBranch ? (
            <div className="column is-narrow">
              <DeleteBtn
                type="button"
                disabled={!isFeatureEditEnabled('Business')}
                className="delete is-medium"
                onClick={() => remove(index)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ))}
      {disableBranch ? (
        <a onClick={() => append({ label: '', value: '' })}>+ Add new</a>
      ) : (
        ''
      )}
    </>
  );
}

function Checklist({ control }): ReactElement {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'checklist',
  });

  const disableBranch = isFeatureEditEnabled('Business');

  return (
    <>
      {fields.map((field, index) => (
        <div className="columns is-mobile" key={`checklist-${field.id}`}>
          <div className="column">
            <RHFBorderedTextField
              control={control}
              placeholder="Type your entry here"
              name={`checklist.${index}.label`}
              inputProps={{ maxLength: MAX_CHECKLIST_CHARACTERS }}
              readOnly={!disableBranch}
            />
          </div>
          {disableBranch && fields.length > 1 ? (
            <div className="column is-narrow">
              <DeleteBtn
                type="button"
                className="delete is-medium"
                onClick={() => remove(index)}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      ))}
      {disableBranch ? (
        <a onClick={() => append({ label: '', value: '' })}>+ Add new</a>
      ) : (
        ''
      )}
    </>
  );
}

function SupplierChecklistForm({
  clearStatus,
  control,
  displayPreview,
  handleSubmit,
  isDirty,
  isValid,
  loading,
  status,
  submit,
  values,
}: Props): ReactElement {
  const remainingCharacters =
    MAX_CUSTOMISED_MSG_CHARACTERS - values.customisedMessage.length;
  const characterCountClasses = [
    'has-text-right',
    remainingCharacters < 0 ? 'has-text-danger' : '',
  ];

  return (
    <form onSubmit={handleSubmit(submit)} onChange={() => clearStatus()}>
      <ContentContainer
        header="Customised message"
        description={`This could be a welcome, or an overview of the application.
          This helps your customers to understand the expectations of the application
          process. Maximum ${MAX_CUSTOMISED_MSG_CHARACTERS} characters.
        `}
        listType="none"
      >
        <CustomisedMessageWrapper>
          <RHFBorderedTextArea
            control={control}
            name="customisedMessage"
            placeholder="Type your message here"
            inputProps={{ maxLength: MAX_CUSTOMISED_MSG_CHARACTERS }}
            readOnly={!isFeatureEditEnabled('Business')}
          />
          <CharacterCount className={characterCountClasses.join(' ')}>
            <small>{remainingCharacters} characters left</small>
          </CharacterCount>
        </CustomisedMessageWrapper>
      </ContentContainer>
      <ContentContainer
        header="Checklist"
        description={`This will appear as a list format. Maximum ${MAX_CHECKLIST_CHARACTERS} characters
          each entry. Click 'add new' to add another entry.`}
        listType="none"
      >
        <Checklist control={control} />
      </ContentContainer>
      <ContentContainer
        header="Add links (optional)"
        description={`You can add hyperlinks here. This will direct the
          customer to the linked page when clicked on.`}
        listType="none"
      >
        <Links control={control} />
      </ContentContainer>
      {status}
      <Buttons className="has-text-right">
        <Button
          onClick={displayPreview}
          disabled={loading || !isValid}
          type="button"
          text="Preview"
          white
        />
        <Button
          type="submit"
          text="Save"
          loading={loading}
          disabled={loading || !isDirty}
        />
      </Buttons>
    </form>
  );
}

export default SupplierChecklistForm;
