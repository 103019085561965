import * as React from 'react';

import OverlayChecklist from '../../OverlayChecklist';
import { DrawerPanel, Header } from '../styles';

export const ChecklistPanel = (props) => {
  const { applicationChecklist, applicationId } = props;

  return (
    <DrawerPanel>
      <Header>Checklist</Header>
      <OverlayChecklist
        {...applicationChecklist}
        applicationId={applicationId}
      />
    </DrawerPanel>
  );
};
