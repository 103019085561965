type Value =
  | { main: string | null; other: Array<string> }
  | string
  | Array<string>;

export const getInitialValues = (
  value: Value,
  multiple: boolean,
  hasMainOption: boolean
): { main: string | null; other: Array<string> } => {
  if (
    value &&
    hasMainOption &&
    typeof value !== 'string' &&
    !Array.isArray(value)
  ) {
    return {
      main: value.main,
      other: value.other || [],
    };
  }
  if (value && multiple && Array.isArray(value)) {
    return { main: null, other: value || [] };
  }
  if (value && typeof value === 'string') {
    return { main: null, other: [value] };
  }
  return { main: null, other: [] };
};
