import React, { Component } from 'react';

import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { extractText } from '../ConsumerRiskScoreComponent';
import { formatMoney } from 'utils/formatting';
import get from 'lodash.get';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import objectToArray from 'utils/objectToArray';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../css/consumer-risk-score/PublicRecords.css';

export default class PublicRecords extends Component {
  get courtRecords() {
    return objectToArray(
      get(this.props.creditReport, 'court_records.court_record')
    );
  }

  get judgements() {
    return this.courtRecords.filter(
      (courtRecord) =>
        extractText(courtRecord, 'court_proceeding_type', '') === 'Judgment'
    );
  }

  get summonses() {
    return this.courtRecords.filter(
      (courtRecord) =>
        extractText(courtRecord, 'court_proceeding_type', '') === 'Summons'
    );
  }

  get bankruptcies() {
    return this.courtRecords.filter(
      (courtRecord) =>
        extractText(courtRecord, 'court_proceeding_type', '') === 'Bankruptcy'
    );
  }

  get otherCourtNotices() {
    return this.courtRecords.filter(
      (courtRecord) =>
        extractText(courtRecord, 'court_proceeding_type', '') ===
        'OtherCourtNotice'
    );
  }

  get directorships() {
    return objectToArray(
      get(this.props.creditReport, 'directorships.directorship')
    );
  }

  get currentDirectorships() {
    return this.directorships.filter(
      (directorship) => extractText(directorship, 'details_type') === 'C'
    );
  }

  get previousDirectorships() {
    return this.directorships.filter((directorship) =>
      ['E', 'P'].includes(extractText(directorship, 'details_type'))
    );
  }

  renderCourtRecords(records, title) {
    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={5}>{title}</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Action/court details</th>
            <th>Amount/creditor</th>
            <th>Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>{this.renderCourtRecordsRow(records, title)}</tbody>
      </table>
    );
  }

  renderCourtRecordsRow(records, title) {
    const courtRecords = records.map((record, i) =>
      this.renderCourtRecord(record, i, title)
    );

    if (isPresent(courtRecords)) {
      return courtRecords;
    }

    return (
      <tr>
        <td colSpan={5} className={commonStyles.centered}>
          No {title.toLowerCase()} recorded.
        </td>
      </tr>
    );
  }

  renderCourtRecord(record, i, title) {
    return (
      <tr key={`court-record-${i}`} className={`${title}-${i}`}>
        <td>{stringToSimpleDate(extractText(record, 'date_lodged'))}</td>
        <td>{this.renderCourtDetails(record, i, title)}</td>
        <td>{this.renderAmount(record, i, title)}</td>
        <td>{this.renderStatus(record, i, title)}</td>
        <td>{extractText(record, 'comments', '-')}</td>
      </tr>
    );
  }

  renderCourtDetails(record, i, title) {
    return (
      <div
        key={`${title}-court-details-${i}`}
        className={styles.court_details_container}
      >
        <div className={commonStyles.emphasize}>Action type:</div>
        <div>{get(record, 'action.value_desc', '-')}</div>

        <div className={commonStyles.emphasize}>Court:</div>
        <div>{extractText(record, 'court', '-')}</div>

        <div className={commonStyles.emphasize}>Plaintiff name:</div>
        <div>{extractText(record, 'plantiff_name')}</div>

        <div className={commonStyles.emphasize}>Plaintiff number:</div>
        <div>{extractText(record, 'plantiff_number')}</div>
      </div>
    );
  }

  renderAmount(record, i, title) {
    return (
      <div
        key={`${title}-amount-${i}`}
        className={styles.court_details_container}
      >
        <div className={commonStyles.emphasize}>Amount:</div>
        <div>
          ${formatMoney(parseFloat(extractText(record, 'amount', 0), 0))}
        </div>

        <div className={commonStyles.emphasize}>Creditor:</div>
        <div>{extractText(record, 'creditor', '-')}</div>
      </div>
    );
  }

  renderStatus(record, i, title) {
    return (
      <div key={`${title}-status-${i}`}>
        <div>{extractText(record, 'status_code', '')}</div>
        <div>{stringToSimpleDate(extractText(record, 'date_paid'))}</div>
      </div>
    );
  }

  renderJudgements() {
    return this.renderCourtRecords(this.judgements, 'Judgements');
  }

  renderSummonses() {
    return this.renderCourtRecords(this.summonses, 'Summonses');
  }

  renderBankruptcies() {
    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={4}>Personal insolvency information</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Action/court details</th>
            <th>Status</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>{this.renderBankruptcyRows()}</tbody>
      </table>
    );
  }

  renderBankruptcyRows() {
    const bankruptcies = this.bankruptcies.map((bankruptcy, i) =>
      this.renderBankruptcy(bankruptcy, i)
    );

    if (isPresent(bankruptcies)) {
      return bankruptcies;
    }

    return (
      <tr>
        <td colSpan={4} className={commonStyles.centered}>
          No personal insolvency information recorded.
        </td>
      </tr>
    );
  }

  renderBankruptcy(bankruptcy, i) {
    return (
      <tr key={`bankruptcy-${i}`}>
        <td>{stringToSimpleDate(extractText(bankruptcy, 'date_declared'))}</td>
        <td>{this.renderBankruptcyDetails(bankruptcy, i)}</td>
        <td>{extractText(bankruptcy, 'status_code', '-')}</td>
        <td>{extractText(bankruptcy, 'comments', '-')}</td>
      </tr>
    );
  }

  renderBankruptcyDetails(bankruptcy, i) {
    return (
      <div
        key={`bankruptcy-details-${i}`}
        className={styles.court_details_container}
      >
        <div className={commonStyles.emphasize}>Action type:</div>
        <div>{get(bankruptcy, 'action.value_desc', '-')}</div>

        <div className={commonStyles.emphasize}>Court:</div>
        <div>{extractText(bankruptcy, 'court', '-')}</div>

        <div className={commonStyles.emphasize}>Proceeding number:</div>
        <div>{extractText(bankruptcy, 'proceedings_number')}</div>

        <div className={commonStyles.emphasize}>Proceeding date:</div>
        <div>
          {stringToSimpleDate(extractText(bankruptcy, 'proceedings_date'))}
        </div>
      </div>
    );
  }

  renderOtherPublicRecords() {
    return this.renderCourtRecords(
      this.otherCourtNotices,
      'Other public records'
    );
  }

  renderCompanyDirectorships() {
    if (isBlank(this.directorships)) {
      return;
    }

    return (
      <table
        className={`${commonStyles.table} ${commonStyles.full_width} ${styles.company_directorships_table}`}
      >
        <thead>
          <tr>
            <th>Company directorship</th>
          </tr>
        </thead>
      </table>
    );
  }

  renderCurrentDirectorships() {
    if (isBlank(this.currentDirectorships)) {
      return;
    }

    const directorships = this.currentDirectorships.map((directorship, i) =>
      this.renderCurrentDirectorship(directorship, i)
    );

    return (
      <table
        className={`${commonStyles.table} ${commonStyles.full_width} ${styles.company_directorships_table}`}
      >
        <thead>
          <tr>
            <th colSpan={6}>Current boards</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>ACN</th>
            <th>DUNS</th>
            <th>Status</th>
            <th>Appointed</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>{directorships}</tbody>
      </table>
    );
  }

  renderCurrentDirectorship(directorship, i) {
    return (
      <tr key={`current-directorship-${i}`}>
        <td>{extractText(directorship, 'company_name', '-')}</td>
        <td>{extractText(directorship, 'company_number', '-')}</td>
        <td>{extractText(directorship, 'duns_number', '-')}</td>
        <td>{extractText(directorship, 'company_status', '-')}</td>
        <td>
          {stringToSimpleDate(extractText(directorship, 'date_appointed'))}
        </td>
        <td>
          {this.renderDirectorshipSource(directorship, i, 'current-boards')}
        </td>
      </tr>
    );
  }

  renderDirectorshipSource(directorship, i, directorshipType) {
    return (
      <div
        key={`${directorshipType}-source-${i}`}
        className={styles.court_details_container}
      >
        <div className={commonStyles.emphasize}>Extract date:</div>
        <div>
          {stringToSimpleDate(extractText(directorship, 'extract_date'))}
        </div>

        <div className={commonStyles.emphasize}>Data source:</div>
        <div>{extractText(directorship, 'data_source', '-')}</div>
      </div>
    );
  }

  renderPreviousDirectorships() {
    if (isBlank(this.previousDirectorships)) {
      return;
    }

    const directorships = this.previousDirectorships.map((directorship, i) =>
      this.renderPreviousDirectorship(directorship, i)
    );

    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={7}>Current boards</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>ACN</th>
            <th>DUNS</th>
            <th>Status</th>
            <th>Appointed</th>
            <th>Ceased</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>{directorships}</tbody>
      </table>
    );
  }

  renderPreviousDirectorship(directorship, i) {
    return (
      <tr key={`current-directorship-${i}`}>
        <td>{extractText(directorship, 'company_name', '-')}</td>
        <td>{extractText(directorship, 'company_number', '-')}</td>
        <td>{extractText(directorship, 'duns_number', '-')}</td>
        <td>{extractText(directorship, 'company_status', '-')}</td>
        <td>
          {stringToSimpleDate(extractText(directorship, 'date_appointed'))}
        </td>
        <td>{stringToSimpleDate(extractText(directorship, 'date_ceased'))}</td>
        <td>
          {this.renderDirectorshipSource(directorship, i, 'current-boards')}
        </td>
      </tr>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Public records"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderJudgements()}
        {this.renderSummonses()}
        {this.renderBankruptcies()}
        {this.renderOtherPublicRecords()}
        {this.renderCompanyDirectorships()}
        {this.renderCurrentDirectorships()}
        {this.renderPreviousDirectorships()}
      </ReviewContainer>
    );
  }
}
