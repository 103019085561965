import styled from 'styled-components';
import { BORDERS } from 'variables/theme';

export const HeaderWrapper = styled.div`
  border-bottom: ${BORDERS.generic};
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

export const WatchtowerButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
`;

export const WatchtowerModalTitle = styled.div`
  display: flex;
  justify-content: center;
  span {
    position: absolute;
    margin-top: 10px;
  }
`;

export const WatchtowerDynamicFieldsTitle = styled.p`
  font-weight: bold !important;
  font-style: italic;
  padding-bottom: 10px;
`;

export const WatchtowerBoldItalicText = styled.span`
  font-weight: bold !important;
  font-style: italic;
`;

export const UnusualApplicationTimeInputFields = styled.div`
  display: flex;
  gap: 10px;
`;

export const UnusualApplicationText = styled.span`
  margin-top: 8px;
`;
