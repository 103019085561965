import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import React, { ReactElement, useEffect, useState } from 'react';
import Table from 'modules/shared/components/table/Table';
import api from 'api';
import get from 'lodash.get';
import sortByDate from 'utils/sortByDate';
import { formatLocalTime } from 'utils/dateFormatter';

const renderPdfDownloadLink = (tableData) => {
  const { url, label } = tableData.cell.value;

  const downloadPdf = () => {
    window.open(url, 'pdf_window');
  };

  return <a onClick={downloadPdf}>{label}</a>;
};

const DATA_COLUMNS = [
  {
    Header: 'Application version',
    accessor: 'pdf_data',
    Cell: renderPdfDownloadLink,
  },
  {
    Header: 'Application name',
    accessor: 'application_name',
  },
  {
    Header: 'PDF Generated time',
    accessor: 'pdf_generation_time',
  },
];

type CurrentUser = {
  accessToken: string;
  currentEntity: {
    id: string;
  };
};

type Application = {
  id: string;
};

type Props = {
  application: Application;
  currentUser: CurrentUser;
};

type pdfData = {
  url: string;
  label: string;
};

interface IPdfTableData {
  pdf_data: pdfData;
  application_name: string;
  pdf_generation_time: string;
}

export default function PDFList(props: Props): ReactElement | null {
  const { application, currentUser } = props;
  const accessToken = get(currentUser, 'accessToken');
  const applicationId = get(application, 'id');

  const applicationAPI = api('applications', accessToken);

  if (!applicationId) {
    return null;
  }

  const [allVersionPdf, setAllVersionPdf] = useState<IPdfTableData[] | []>([]);

  const createPdfTableData = (allPdfs): IPdfTableData[] | [] => {
    if (!allPdfs || !allPdfs.length) return [];

    const filterdPdf = allPdfs.filter((pdf) => !!pdf.data.attributes.url);

    const sortedPdf = sortByDate({
      records: filterdPdf,
      dateAttribute: 'data.attributes.pdf_generation_time',
      direction: 'descending',
    });

    return sortedPdf.map((pdfData) => {
      const {
        version_number,
        change_reason,
        url,
        application_name,
        pdf_generation_time: rawPdfGenerationTime,
      } = pdfData.data.attributes;
      const baseString = `Version ${version_number}`;
      const changeReason = change_reason ? `: ${change_reason}` : '';
      const isCurrent = pdfData.data.id === application.id;
      const isCurrentString = isCurrent ? ' (current)' : '';
      const displayString = `${baseString}${changeReason}`;
      const pdf_generation_time = !!rawPdfGenerationTime
        ? formatLocalTime(rawPdfGenerationTime, 'minute')
        : '';

      return {
        pdf_data: {
          label: displayString + isCurrentString,
          url,
        },
        application_name,
        pdf_generation_time,
      } as IPdfTableData;
    });
  };

  useEffect(() => {
    applicationAPI
      .allApplicationPdf(applicationId)
      .then((response) => {
        setAllVersionPdf(createPdfTableData(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [applicationId, currentUser]);

  const hasAvailablePdf = allVersionPdf && allVersionPdf.length > 0;
  return (
    <ScrollableContent>
      {!hasAvailablePdf && (
        <span style={{ fontSize: '16px', marginBottom: '10px' }}>
          No available PDF
        </span>
      )}
      {hasAvailablePdf && (
        <React.Fragment>
          <span style={{ fontSize: '16px', marginBottom: '10px' }}>PDF</span>
          <Table
            columns={DATA_COLUMNS}
            data={allVersionPdf}
            dataLabel="pdfs"
            withPagination={false}
          />
        </React.Fragment>
      )}
    </ScrollableContent>
  );
}
