import { Typography } from '@material-ui/core';
import Dialog from 'modules/shared/components/v2/Dialog';
import React from 'react';

type AddonNoticeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddonNoticeModal = (props: AddonNoticeModalProps) => {
  const { isOpen, onClose } = props;

  const handleClickContact = () => {
    const link = document.createElement('a');
    link.href = 'mailto:support@1centre.com';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog
      title=""
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleClickContact}
      confirmButtonText="Contact us"
    >
      <Typography>
        Add on functions are not activated. Please contact
      </Typography>
      <Typography>
        <a href="mailto:support@1centre.com">support@1centre.com</a> to activate
        add ons for you.
      </Typography>
    </Dialog>
  );
};
