/* Import libraries */
import React from 'react';

import styles from './css/PercentageWidget.css';

/* Import componenets */
import RangeSlider from './RangeSlider';

var PercentageWidget = createClass({
  render: function() {
    const { value, name, id, handleChange, title, max } = this.props;
    return (
      <div className={styles.percentage}>
        <div className={styles.percentage_title}>{title}</div>
        <div className={styles.percentage_value}>{value}%</div>
        <div className={styles.percentage_range}>
          <RangeSlider
            type="range"
            id={id}
            name={name}
            handleChange={handleChange}
            value={value}
            step={1}
            min={1}
            max={max}
          />
        </div>
      </div>
    );
  },
});

export default PercentageWidget;
