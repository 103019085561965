import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import React from 'react';
import styles from './css/Review.css';

//Temporary finding the model using name matching
//since there's no IDs that can be matched between
//PersonModel and related models (guarantor, signatories, authorisations)
const findModelFromName = (person, models) => {
  const model = models.find((model) => {
    const { first_name, last_name } = person;

    return (
      model.attributes.first_name.toLowerCase() === first_name.toLowerCase() &&
      model.attributes.last_name.toLowerCase() === last_name.toLowerCase()
    );
  });

  return model;
};

const getEmailFromLinkedModels = (person, signatories, guarantors) => {
  const signatoriesModel = findModelFromName(person, signatories);
  if (signatoriesModel && signatoriesModel.attributes.email) {
    return signatoriesModel.attributes.email;
  }

  const guarantorModel = findModelFromName(person, guarantors);
  if (guarantorModel && guarantorModel.attributes.email) {
    return guarantorModel.attributes.email;
  }

  return undefined;
};

const ReviewCorporateTrust = (props) => {
  const { data } = props;
  const application = data.application.attributes;
  const authorisation = data.authorisation.attributes;
  const associatedEntityId = authorisation.associated_entity_id;

  if (!associatedEntityId) {
    return null;
  }

  const corporateTrust = application.corporate_trustees.find(
    (corporateTrust) => corporateTrust.id === associatedEntityId
  );

  const directors = application.trustee_directors.filter(
    (trustee) => trustee.associated_entity_id === associatedEntityId
  );

  const directorsWithEmail = directors.map((director) => {
    if (director.email) return director;
    const guarantors = data.guarantors;
    const signatories = data.signatories;

    return {
      ...director,
      email: getEmailFromLinkedModels(director, signatories, guarantors),
    };
  });

  const personLabel = 'Director';
  const isNz = corporateTrust.region === 'NZ';
  const companyNumberLabel = isNz ? 'NZCN' : 'ACN';
  const businessNumberLabel = isNz ? 'NZBN' : 'ABN';

  let people: any = [];
  if (!!directorsWithEmail.length) {
    directorsWithEmail.forEach((director, i) => {
      people.push(
        <div className={styles.people_col} key={`person-${i}`}>
          <LabeledInformation
            label={`${personLabel} ${i + 1}`}
            data={director.name}
          />
          <LabeledInformation
            label="Email"
            data={director.email || '-'}
            showEmpty={true}
            noHumanize
          />
        </div>
      );
    });
  }

  return (
    <section className={styles.expandable}>
      <span className={styles.shadow} />
      <ReviewContainer
        key="business-information"
        subHeading="Business information"
        css_class="block_noborder"
      >
        <div className={styles.col}>
          <LabeledInformation
            label="Company/Business name"
            noHumanize={true}
            data={corporateTrust.entity_name || '-'}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label="Business type"
            data="Company"
            noHumanize={true}
          />
        </div>
      </ReviewContainer>
      <ReviewContainer key="business-numbers" css_class="block_bottom_border">
        <div className={styles.col}>
          <LabeledInformation
            label={businessNumberLabel}
            noHumanize={true}
            data={corporateTrust.business_number}
          />
        </div>
        <div className={styles.col}>
          <LabeledInformation
            label={companyNumberLabel}
            data={corporateTrust.company_number}
            noHumanize={true}
          />
        </div>
      </ReviewContainer>
      <ReviewContainer
        css_class="block_noborder"
        content_class="content_people"
        subHeading={personLabel}
      >
        {people}
      </ReviewContainer>
    </section>
  );
};

export default ReviewCorporateTrust;
