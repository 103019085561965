import React from 'react';
import UserAvatar from 'react-user-avatar';

import styles from './css/ConnectionLogo.css';

var ConnectionLogo = createClass({
  render: function() {
    const { connection } = this.props;
    let logo,
      name = connection.attributes.consumer_name;
    if (connection.showparty.attributes.logo) {
      logo = connection.showparty.attributes.logo.url;
    }

    return (
      <div className={styles.logo_block}>
        <UserAvatar
          className={styles.image}
          size="156"
          name={name}
          src={logo}
        />
      </div>
    );
  },
});

module.exports = ConnectionLogo;
