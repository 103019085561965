import { Document, Page } from 'react-pdf/dist/entry.webpack';
import isBlank from 'utils/isBlank';
import React, { useState } from 'react';
import styles from 'modules/shared/components/widgets/static/css/PdfThumbnail.css';

export default function PdfThumbnail(props) {
  const { url, onError } = props;

  if (isBlank(url)) {
    return null;
  }

  const [numPages, setNumPages] = useState(null);
  const onLoadSuccess = (loadedNumPages) => setNumPages(loadedNumPages);

  const onPageRenderError = () => {
    if (onError) {
      onError();
    }
  };

  return (
    <Document
      className={styles.document}
      file={url}
      onLoadSuccess={onLoadSuccess}
    >
      <Page
        className={styles.page}
        pageNumber={1}
        onRenderError={onPageRenderError}
      />
    </Document>
  );
}
