import React from 'react';
import FormFieldWrapper from 'modules/consumer-onboarding/v2/FormFieldWrapper';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import get from 'lodash.get';
import { Field } from '../TradingNameField';

type Props = {
  id: string;
  errors: Record<PropertyKey, unknown>;
  isDisabled: boolean;
  isVisible: boolean;
  label: string;
  name: string;
  onChange: (
    e: React.FormEvent<HTMLInputElement>,
    value: { value: string }
  ) => void | null;
  options: Field[];
  value: Field | null;
  defaultValue?: Field | null;
  getOptionDisabled?: Field | null;
};

const AutoCompleteSelectField = ({
  id,
  errors,
  isDisabled,
  isVisible,
  label,
  name,
  onChange,
  options,
  value,
  defaultValue,
}: Props) => {
  if (!isVisible) {
    return null;
  }

  const error = get(errors, `${name}.message`);

  return (
    <>
      <MuiThemeProvider theme={muiTheme()}>
        <FormFieldWrapper label={label} error={error}>
          <BorderedAutocomplete
            id={id}
            disabled={isDisabled}
            options={options}
            onChange={onChange}
            textFieldProps={{
              name,
              error,
            }}
            value={value}
            defaultValue={defaultValue}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => option.value === value?.value}
          />
        </FormFieldWrapper>
      </MuiThemeProvider>
    </>
  );
};

export default AutoCompleteSelectField;
