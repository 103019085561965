import get from 'lodash.get';
import { lookupAddress } from 'modules/consumer-onboarding/actions/onboarding';
import StateDropdown from 'modules/identity/components/IdentityConfirm/StateDropdown';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import TextInput from 'modules/shared/components/inputs/TextInput';
import moment from 'moment';
import React, { Fragment, useState } from 'react';

import AddressPicker from './AddressPicker';
import commonStyles from './css/Section.css';
import { get100YearsAgo, getEighteenYearsAgo } from './utils';

const idNumberLabelMap = {
  driver_licence: 'Driver licence number',
  passport: 'Passport number',
  other: 'ID number',
};

function AddressAndIdDetails(props) {
  const {
    deliveryAddressLoading,
    deliveryAddressOptions,
    deliveryAddressData,
    dispatch,
    errors,
    isVisible,
    region,
    signature,
    updateSignature,
  } = props;

  const [addressKeyword, setAddressKeyword] = useState(
    get(signature, 'address.full_address')
  );

  const [addressRegion, setAddressRegion] = useState(
    get(signature, 'address.alpha2', region)
  );

  if (!isVisible) return null;

  const lookupDeliveryAddress = (keyword) => {
    dispatch(lookupAddress(keyword, 'Delivery', addressRegion));
  };

  const handleDeliveryAddressChange = (event) => {
    const keyword = event.target.value;
    setAddressKeyword(keyword);
    lookupDeliveryAddress(keyword);
  };

  const handleDeliveryAddressClick = (value) => {
    const addressValue = deliveryAddressData[value];
    setAddressKeyword(addressValue.full_address);
    updateSignature({ address: addressValue });
  };

  const showVersionInput =
    signature.region === 'NZ' &&
    signature.identification_type === 'driver_licence';
  const showExpiryDate = signature.identification_type === 'passport';
  const showAuDriversLicenseFields =
    signature.region === 'AU' &&
    signature.identification_type === 'driver_licence';

  const idNumberLabel = signature.identification_type
    ? idNumberLabelMap[signature.identification_type]
    : 'ID number';

  return (
    <Fragment>
      <div className={commonStyles.row}>
        <div className={commonStyles.col_4}>
          <BorderedCalendarPicker
            textFieldProps={{
              error: Boolean(errors.dob),
              helperText: get(errors, 'dob', ''),
              id: 'dob',
              name: 'dob',
              required: true,
            }}
            minDate={get100YearsAgo()}
            maxDate={getEighteenYearsAgo()}
            value={signature.dob}
            label="Date of birth"
            isStyledUnderlined
            onChange={(value) => updateSignature({ dob: value })}
          />
        </div>
      </div>
      <div className={commonStyles.row}>
        {showAuDriversLicenseFields && (
          <div className={commonStyles.col_4}>
            <StateDropdown
              handleChange={(event) =>
                updateSignature({ identification_state: event.value })
              }
              handleBlur={(event) =>
                updateSignature({ identification_state: event.target.value })
              }
              error={get(errors, 'identification_state', '')}
              value={signature.identification_state || ''}
              required={true}
            />
          </div>
        )}
        <div className={commonStyles.col_4}>
          <TextInput
            label={idNumberLabel}
            handleChange={(event) =>
              updateSignature({ identification_number: event.target.value })
            }
            required={true}
            value={signature.identification_number}
            error={get(errors, 'identification_number', '')}
          />
        </div>
        {(showExpiryDate || showAuDriversLicenseFields) && (
          <div className={commonStyles.col_4}>
            <BorderedCalendarPicker
              textFieldProps={{
                error: Boolean(errors.identification_expiry_date),
                helperText: get(errors, 'identification_expiry_date', ''),
                id: 'identification_expiry_date',
                name: 'identification_expiry_date',
                required: true,
              }}
              minDate={moment(moment().format('DD/MM/YYYY')).toDate()}
              maxDate={moment(
                moment().add(50, 'y').endOf('year').format('DD/MM/YYYY')
              ).toDate()}
              value={signature.identification_expiry_date}
              label={`${showExpiryDate ? 'Passport' : 'Licence '} expiry date`}
              onChange={(value) =>
                updateSignature({ identification_expiry_date: value })
              }
              isStyledUnderlined
            />
          </div>
        )}
        {showVersionInput && (
          <div className={commonStyles.col_4}>
            <TextInput
              label="Driver licence version"
              handleChange={(event) =>
                updateSignature({ identification_version: event.target.value })
              }
              required={true}
              value={signature.identification_version}
              error={get(errors, 'identification_version', '')}
            />
          </div>
        )}
        {showAuDriversLicenseFields && (
          <div className={commonStyles.col_4}>
            <TextInput
              label="Driver licence card number"
              handleChange={(event) =>
                updateSignature({
                  identification_card_number: event.target.value,
                })
              }
              required={true}
              value={signature.identification_card_number}
              error={get(errors, 'identification_card_number', '')}
            />
          </div>
        )}
      </div>
      <div>
        <AddressPicker
          addressKeyword={addressKeyword}
          addressRegion={addressRegion}
          deliveryAddressLoading={deliveryAddressLoading}
          deliveryAddressOptions={deliveryAddressOptions || []}
          errors={errors}
          handleDeliveryAddressChange={handleDeliveryAddressChange}
          handleDeliveryAddressClick={handleDeliveryAddressClick}
          onChangeAddressRegion={(selectedOption) => {
            setAddressRegion(selectedOption.value);
          }}
        />
      </div>
    </Fragment>
  );
}

export default AddressAndIdDetails;
