import get from 'lodash.get';
import store from 'stores/store';

export const getIsCustomConfigABNHidden = (): boolean => {
  const customLeadConfiguration = get(
    store.getState(),
    'add_ons.custom_lead_configurations',
    []
  )[0];

  let isCustomConfigABNHidden = false;

  if (customLeadConfiguration) {
    isCustomConfigABNHidden = get(
      customLeadConfiguration,
      'latestVersion.config.abn_field_hidden',
      false
    );
  }

  return isCustomConfigABNHidden;
};
