import { Region } from 'utils/region';

const REG = /^\d+$/;
const ACN_REG_EX = /^\d{9}$/;
const ABN_REG_EX = /^\d{11}$/;

//**
//
// This ONLY checks if value string is a digit
// This DOES NOT check the length of the value or if it is null or undefined
//
//**
export function isValidBusinessNumber(
  value: string,
  region: Region = Region.NZ
): boolean {
  switch (region) {
    case Region.AU:
      return ABN_REG_EX.test(value);
    default:
      return REG.test(value);
  }
}

export function isValidCompanyNumber(
  value: string,
  region: Region = Region.NZ
): boolean {
  switch (region) {
    case Region.AU:
      return ACN_REG_EX.test(value);
    default:
      return REG.test(value);
  }
}
