/* Import libraries */
import React, { useEffect } from 'react';
import { browserHistory } from 'react-router';
import { getToken } from './auth/actions';

function Home(props) {
  useEffect(() => {
    (async function() {
      const { location } = props;
      let token = await getToken();

      if (location.pathname === '/') {
        if (token !== null) {
          browserHistory.push(`/dashboard/home`);
        } else {
          browserHistory.push(`/user/login`);
        }
      }
    })();
  }, []);

  return <div />;
}

export default Home;
