import React, { useContext } from 'react';

import PreviewModal from 'modules/addons/addons_form/components/PreviewModal';
import { CustomerViewContext } from './CustomerViewContext';

export const Preview = () => {
  const { isPreviewShown, hidePreview, previewData } =
    useContext(CustomerViewContext);

  return isPreviewShown ? (
    <PreviewModal
      moduleName="customer_view"
      previewAddon={{ tradingNameOptions: previewData }}
      dismissHandler={hidePreview}
    />
  ) : null;
};
