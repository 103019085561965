import get from 'lodash.get';
import { FormValues } from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/type';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import React, { ReactElement } from 'react';
import { UseFormSetValue } from 'react-hook-form-latest';
import { IOption } from 'types/generalType';

const LegalTypeField = ({
  entityTypeOptions,
  errorMessage,
  isDisabled,
  name,
  onReset,
  setValue,
  value,
}: {
  entityTypeOptions: IOption[];
  errorMessage: string;
  isDisabled: boolean;
  name: string;
  onReset: () => void;
  setValue: UseFormSetValue<FormValues>;
  value: string;
}): ReactElement => {
  const onSelectLegalType = (event) => {
    const fieldName = get(event, 'target.name');
    const value = get(event, 'target.value');

    setValue(fieldName, value);

    if (onReset) {
      onReset();
    }
  };

  // A scenario can happen where an entity type is not in the option because
  // an entity can be searched using the business number
  const availableOptions = entityTypeOptions.map((option) => option.value);
  let selectedValue = '';
  if (availableOptions.includes(value)) {
    selectedValue = value;
  }

  return (
    <div className="column">
      <BorderedSelect
        error={Boolean(errorMessage)}
        disabled={isDisabled}
        helperText={errorMessage}
        name={name}
        label="Entity type"
        options={entityTypeOptions}
        onChange={onSelectLegalType}
        value={selectedValue}
      />
    </div>
  );
};

export default LegalTypeField;
