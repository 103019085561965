/* Components */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import { isMobile } from 'modules/shared/helpers/mobileDetect';

/* Import CSS */
import styles from './css/PasswordApplication.css';

/* Actions */
import {
  forgotPasswordUpdateUserPassword,
  setRegisterFormField,
} from '../actions';

import { checkPassStrength } from '../../../utils/password';

/* Import components */
import SectionRect from 'modules/shared/components/widgets/static/SectionRect';

import Password from '../forms/PasswordForm';

/* SetPassword */
var PasswordApplication = createClass({
  handleChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setRegisterFormField(event.target.name, event.target.value));
  },

  submit: function(event) {
    event.preventDefault();
    const { dispatch, password, confirm_password, email } = this.props;
    let path = '/user/logout';

    if (email) {
      path = path + `?e=${email}`;
    }

    if (password.length > 0 && password === confirm_password) {
      // Patch user's password,
      dispatch(
        forgotPasswordUpdateUserPassword(password, () => {
          browserHistory.push(path);
        })
      );
    }
  },

  /**
   * Render
   *
   * @returns {XML}
   */
  render: function() {
    const { password, confirm_password, strength } = this.props;
    var title =
      'Start tracking your application on <strong>1Centre</strong> now!';
    var instr = "It's as easy as picking a password";

    if (isMobile) {
      title = '';
      instr =
        "Start tracking your application now - it's as easy as picking a password!";
    }

    return (
      <section className={styles.section}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.content}>
              <SectionRect title={title} css_class={'block_form'}>
                <p className={styles.info}>
                  <a
                    href="http://www.1centre.com"
                    className={styles.arrow_link}
                  >
                    What’s 1Centre?
                  </a>
                </p>
                <Password
                  submitHandler={this.submit}
                  changeHandler={this.handleChange}
                  blurHandler={this.handleChange}
                  password={password}
                  confirm_password={confirm_password}
                  strength={strength}
                  password_text="Pick a password"
                  confirm_text="and confirm it"
                  instructions={instr}
                  css_class="form_inline"
                />
              </SectionRect>
            </div>
          </div>
        </div>
      </section>
    );
  },
});

export default connect((state, ownProps) => {
  var strength = 0,
    email = null;
  if (state.register.password.length > 0) {
    strength = checkPassStrength(state.register.password);
  }

  if (state.current_user.data.data) {
    if (state.current_user.data.data.attributes.email) {
      email = state.current_user.data.data.attributes.email;
    }
  }
  return {
    password: state.register.password,
    confirm_password: state.register.confirm_password,
    strength: strength,
    email,
  };
})(PasswordApplication);
