/* eslint-disabled max-lines */
import get from 'lodash.get';
import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import { DigitalOnboardingItem } from 'modules/addons/components/DigitalOnboarding';
import React, { useContext } from 'react';

import { isFeatureAvailable } from 'modules/shared/helpers/headquarterDetect';

const RapidTransferItemComponent = (props) => {
  const { addonConfig } = props;

  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfig);

  const isRapidTransferAvailable = isFeatureAvailable('rapid_transfer');
  const isRapidTransferFormattedActiveState = isRapidTransferAvailable
    ? 'Active'
    : 'Paused';

  return (
    <React.Fragment>
      <DigitalOnboardingItem
        activeState={isRapidTransferAvailable}
        areActionButtonsVisible={isRapidTransferAvailable}
        body="Manage and monitor your entire customer base using our Rapid Transfer feature which will allow you to do a bulk upload for your AU & NZ customers. Virtual Credit files will be created for each and stored on your Customer Pipeline."
        formattedState={isRapidTransferFormattedActiveState}
        header="Rapid transfer module"
        isConfigured={isRapidTransferAvailable}
        isEnabled={isRapidTransferAvailable}
        onClickEdit={isRapidTransferAvailable ? onClickEdit : null}
        longContent
      />
    </React.Fragment>
  );
};

export default RapidTransferItemComponent;
