import { get } from 'lodash';
import userTypes from 'modules/shared/users/userTypes';
import { formatMoney } from 'utils/formatting';

import { ROLE_TYPE_CONSUMER_MANAGER } from './constants';
import { ApprovalLevelOptionType } from './types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { BoldItalicLabel } from 'modules/identity/components/IdentityCapture/styles';
import { addFlaggedArray } from 'conf/flaggingHelpers';

export const getFormValues = (user): any => {
  const { userType, userTypeConsumerManager } =
    evalUserTypeAndUserTypeConsumerManager(user.attributes);

  const isMandatoryPermissionValue = get(user, 'attributes.permissions', 'n');
  const isMandatoryPermission =
    isMandatoryPermissionValue === 'm' ? ['mandatory_checks'] : [];
  const pagePermissionsValue = get(user, 'attributes.page_permissions', {});
  const pagePermissions =
    Object.keys(pagePermissionsValue).filter(
      (key) => pagePermissionsValue[key]
    ) || [];
  const userCanOverrideValue = get(
    user,
    'attributes.can_override_limit',
    false
  );
  const userCanOverride = userCanOverrideValue ? ['can_override_limit'] : [];

  const consolidatedPermissions = [
    ...isMandatoryPermission,
    ...pagePermissions,
    ...userCanOverride,
  ];

  const userLevel = user.attributes ? user.attributes.level : undefined;

  const approvalLevel =
    userLevel || [null, 0].includes(userLevel) ? userLevel : '';

  const formValues = {
    all_permissions: consolidatedPermissions,
    user_approval_level: approvalLevel,
    user_contact_phone_number: get(user, 'attributes.contact_phone_number', ''),
    user_email: get(user, 'attributes.email', ''),
    user_first_name: get(user, 'attributes.first_name', ''),
    user_last_name: get(user, 'attributes.last_name', ''),
    user_receive_email_notifications: get(
      user,
      'attributes.receive_email_notifications',
      false
    ),
    user_type: userType || '',
    user_type_consumer_manager: userTypeConsumerManager,
  };

  return formValues;
};

const evalUserTypeAndUserTypeConsumerManager = (userAttributes) => {
  if (!userAttributes) return {};
  return {
    userType:
      userAttributes.role_types.find(
        (item) => item !== ROLE_TYPE_CONSUMER_MANAGER
      ) || null,
    userTypeConsumerManager: !!(
      userAttributes.role_types.find(
        (item) => item === ROLE_TYPE_CONSUMER_MANAGER
      ) || false
    ),
  };
};

export const getApprovalLevelOptions = (
  approvalLevelData,
  isReviewerEnabled
): ApprovalLevelOptionType[] => {
  const approvalLevelOptions: ApprovalLevelOptionType[] = [
    {
      description: 'Cannot approve an application.',
      id: 'no_level',
      label: 'N/A',
      type: 'approval_level',
      value: null,
    },
  ];

  if (isReviewerEnabled) {
    approvalLevelOptions.push({
      description:
        "Can review the credit file prior to approval. The user type doesn't have any credit limit approval but is responsible for ensuring all elements of the credit file are complete prior to it going to the approvers.",
      id: 'level_0',
      label: 'Reviewer',
      type: 'approval_level',
      value: 0,
    });
  }

  (approvalLevelData || []).forEach((approval_level, level) => {
    approvalLevelOptions.push({
      description:
        'Can approve credit limits ≤ $' +
        // @ts-ignore - this is still in a js file
        formatMoney(approval_level.credit_limit) +
        '.',
      id: 'level_' + approval_level.level,
      label: 'Level ' + (level + 1),
      type: 'approval_level',
      value: approval_level.level,
    });
  });

  return approvalLevelOptions;
};

export const getUserTypes = (currentCheckboxValue) => {
  const currentUserTypes = userTypes
    .filter(({ type }) => type === 'user_type')
    .map((userTypeData) => ({ showRadio: true, ...userTypeData }));

  return [
    ...currentUserTypes,
    {
      customOption: true,
      description:
        'This user type can apply for trade accounts on behalf of your business.',
      label: 'Consumer',
      value: !currentCheckboxValue,
    },
  ];
};

export const getPermissionOptions = (isStandardUser, isCallCentreActive) => {
  return [
    {
      brief: 'Virtual credit file',
      dropdownItemHeight: 45,
      label: 'Virtual credit file',
      secondary_text:
        "Allow the user to view the Virtual credit file after the Loaded in system checkbox has been checked. All users will default to 'can' view.",
      value: 'can_review_virtual_credit_file',
    },
    {
      brief: 'Credit checks',
      dropdownItemHeight: 45,
      label: 'Credit checks',
      secondary_text:
        'Allow the user to view credit checks in the Virtual credit file.',
      value: 'can_review_credit_checks',
    },
    ...addFlaggedArray('FEATURE_FLAG_DIRECT_DEBIT_TOGGLE', [
      {
        brief: 'Override Direct Debit',
        dropdownItemHeight: 45,
        label: <Typography component="p">Override Direct Debit</Typography>,
        secondary_text: (
          <Typography component="span" variant="subtitle2">
            <span style={{ color: '#5e5e5e' }}>
              Allow the user to override the Direct Debit rule{' '}
              <BoldItalicLabel>after</BoldItalicLabel> an application has been
              sent/started.
            </span>
          </Typography>
        ),
        value: 'can_override_direct_debit',
        customComponent: true,
      },
    ]),
    {
      brief: 'Override limit amount',
      disabled: isStandardUser,
      dropdownItemHeight: 45,
      label: 'Override limit amount',
      secondary_text:
        'Allow the user to override the limit amount requested from the applicant.',
      value: 'can_override_limit',
    },
    {
      brief: 'PPSR',
      disabled: isStandardUser,
      dropdownItemHeight: 45,
      label: 'PPSR',
      secondary_text:
        'Allow the user to access PPSR functionalities, including registration, renewal, modification, and discharge if applicable.',
      value: 'can_access_ppsr',
    },
    ...addFlaggedArray('FEATURE_FLAG_SHADOW_VCF', [
      {
        brief: 'Create Shadow VCF',
        dropdownItemHeight: 45,
        label: 'Create Shadow VCF',
        value: 'can_create_shadow_vcf',
        secondary_text: `Allow the user to leave a non-actionable mirror of the application on the tier it's initiated from, when reallocating it to another tier.`,
      },
    ]),
    {
      brief: 'IUF approval',
      disabled: isStandardUser,
      dropdownItemHeight: 45,
      label: 'IUF approval',
      secondary_text: 'Allow the user to approve Internal Use Field (IUF).',
      value: 'can_approve_iuf',
    },
    {
      brief: 'Access tokenised document',
      dropdownItemHeight: 45,
      label: 'Access tokenised document',
      secondary_text:
        'Allow the user to access tokenised Anti-Fraud identification prior to the final approval.',
      value: 'can_access_tokenised_document',
    },
    {
      brief: 'Mandatory checks',
      dropdownItemHeight: 60,
      label: 'Mandatory checks',
      secondary_text:
        "Allow the user to tailor mandatory checks for individual applications. The checks will always default to the company level for every application but these can be altered (turned on/off) by this user. Changing an application's settings will disable it for auto-decisioning.",
      value: 'mandatory_checks',
    },
    {
      brief: 'Call centre',
      disabled: !isCallCentreActive,
      dropdownItemHeight: 45,
      label: 'Call centre',
      secondary_text:
        'This user can fill in applications on behalf of customers.',
      value: 'can_call_centre_sudo',
    },
  ];
};
