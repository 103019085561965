import IconIdFail from 'images/svgs/icon-id-fail.svg';
import IconIdPass from 'images/svgs/icon-id-pass.svg';
import IconId from 'images/svgs/icon-id.svg';
import { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import Header from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Header';
import { IconWrapper } from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/Tokenised/styles';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import { canUserAccessTokenisedDocument } from 'modules/shared/helpers/currentUserHelper';
import React, { Fragment, ReactElement } from 'react';

import useIdCheckDetails from '../hooks/useIdCheckDetails';
import { FEATURE_FLAGS } from 'conf';

type OnIconClickFn = () => void;

const getIcon = (identificationCheckStatus) => {
  if (identificationCheckStatus === 'Pass') {
    return IconIdPass;
  }

  if (identificationCheckStatus === 'Fail') {
    return IconIdFail;
  }

  return IconId;
};

function getGovernmentBody(signature) {
  const governmentBody = {
    AU: 'DVS',
    NZ: {
      driver_licence: 'NZTA',
      passport: 'DIA',
    },
  };

  const regionBody = governmentBody[signature.region];

  if (typeof regionBody === 'object') {
    return regionBody[signature.identificationType];
  }

  return regionBody;
}

const Tokenised = (props): ReactElement => {
  const {
    application,
    currentUser,
    identificationCheck,
    signature,
    onShowImageModal,
  } = props;
  const identificationImageUrl = signature.identificationImageUrl;
  const identificationBackImageUrl = signature.identificationBackImageUrl;
  const { verificationToken, pass } = useIdCheckDetails(
    identificationCheck,
    signature
  );

  const canAccess = canUserAccessTokenisedDocument(currentUser, application);
  let onIconClick: OnIconClickFn | null = null;

  if (application.isSubmitted && canAccess) {
    const modalTitle = verificationToken && `Verified ID: ${verificationToken}`;

    onIconClick = () => {
      onShowImageModal(
        [identificationImageUrl, identificationBackImageUrl],
        modalTitle
      );

      mixpanel.track('1CAF Token viewed', {
        'Application ID': application.id,
        'Entity ID': get(currentUser, 'currentEntity.id'),
        distinct_id: currentUser.id,
      });
    };
  }

  const governmentBody = getGovernmentBody(signature);
  const result = pass ? 'Pass' : 'Fail';
  const Icon = getIcon(typeof pass === 'boolean' ? result : null);

  const title = pass ? `${governmentBody} Verified Token` : 'Failed ID Token';

  const IconContent = (
    <IconWrapper canAccess={canAccess}>
      <Icon onClick={onIconClick} />
    </IconWrapper>
  );

  let Content: ReactElement;

  if (verificationToken) {
    Content = (
      <PopperTooltip
        title={title}
        extraComponent={verificationToken}
        placement="right"
        isVisibleOnMobile={false}
      >
        {IconContent}
      </PopperTooltip>
    );
  } else {
    Content = IconContent;
  }

  return (
    <Fragment>
      <Header
        title={
          FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6
            ? 'Identification'
            : 'ID verification'
        }
        underlined
      />
      {Content}
    </Fragment>
  );
};

export default Tokenised;
