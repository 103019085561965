import axios from './axios';

let EntityConsumerApplications = function(config) {
  this.axios = axios(config);
};

EntityConsumerApplications.prototype.getEntityConsumerApplications = function(
  success,
  error
) {
  return this.axios
    .get('/entity_consumer_applications')
    .then(success)
    .catch(error);
};

export default EntityConsumerApplications;
