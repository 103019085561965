import { useMediaQuery } from 'hooks/useMediaQuery';
import { isMobile } from './mobileDetect';

const maxRowsInOnePage = 5;
const linesPerRow = 2;

function getBaseRows({ editing, options, display, width, main_option }) {
  const displayInRow = display === 'row';

  if (displayInRow) {
    return 2;
  }

  const maxCharNarrow = editing || width === 'half';
  const maxCharPerLine = maxCharNarrow ? 12 : 25;
  const minimumRows = 2;
  const questionRow = 1;

  const charSplitGroup = options.split(/[\n,:,|]/g);
  const charLengths = charSplitGroup.map((charSplit) => charSplit.length);
  const maxChar = Math.max(...charLengths);
  const minimumBaseRows = maxChar / maxCharPerLine / linesPerRow + questionRow;
  const isHalf = width === 'half';
  const moreThanMax = maxChar > maxCharPerLine;
  const additionalRow = (isHalf && moreThanMax) || main_option ? 1 : 0;

  const baseRows =
    minimumBaseRows < minimumRows ? minimumRows : minimumBaseRows;

  return baseRows + additionalRow;
}

function getColumnRatio() {
  if (useMediaQuery('xxl', 'xl')) return 8;
  if (useMediaQuery('lg', 'md')) return 9;
  return 6;
}

function getRowLinebreak(rowOptions, charactersPerRow) {
  let currentRowLineBreak = 0;

  rowOptions.forEach((option) => {
    const autoLineBreak = Math.floor(option.length / charactersPerRow);
    if (autoLineBreak > 1) {
      currentRowLineBreak =
        autoLineBreak > currentRowLineBreak
          ? autoLineBreak
          : currentRowLineBreak;
    }
  });

  return currentRowLineBreak;
}

function getAutolineBreakNum(options) {
  const optionsArray = options.split('|');
  const itemsPerRow = isMobile() ? 2 : 6;
  const columnWidth = window.innerWidth / 6;
  const charactersPerRow = isMobile()
    ? 20
    : Math.floor(columnWidth / getColumnRatio());
  let finalAutoLineBreak = 0;

  for (let i = 0; i < optionsArray.length; i += itemsPerRow) {
    const currentRow = optionsArray.slice(i, i + itemsPerRow);

    finalAutoLineBreak += getRowLinebreak(currentRow, charactersPerRow);
  }

  return finalAutoLineBreak;
}

function getDefaultOptionRows(component) {
  const { display, options } = component;

  const displayInRow = display === 'row';
  const optionNum = (options.split('|') || []).length;
  const itemPerRow = isMobile() ? 2 : 6;
  const rowRatio = 1.5;

  const rowCount = displayInRow
    ? optionNum
    : Math.floor(optionNum / itemPerRow);
  return rowCount / rowRatio;
}

export function size(component) {
  let cols = { full: 4, half: 2, quarter: 1 }[component.width || 'full'];
  cols = component.editing ? 4 : cols;
  switch (component.component_type) {
    case 'pair_question':
      return { cols, rows: component.editing ? 14 : 2 };
    case 'short_question':
      return { cols, rows: component.editing ? 14 : 2 };
    case 'long_question':
      return { cols, rows: component.editing ? 14 : 3.2 };
    case 'check_boxes': {
      // Category, linebreaks, number of lines from label
      const { editing, options } = component;
      const autoLineBreakNum = getAutolineBreakNum(options);
      const linebreakNum = (options.match(/\n/g) || []).length;
      const categoryNum = (options.match(/\:/g) || []).length;

      const baseRows = getBaseRows(component) + getDefaultOptionRows(component);
      const finalRows =
        baseRows + categoryNum + linebreakNum + autoLineBreakNum / 1.5;
      const rows = editing ? 8 + finalRows : finalRows;

      const size = {
        cols,
        rows,
      };

      return size;
    }
    case 'dropdown_menu':
      return { cols, rows: component.editing ? 14 : 2 };
    case 'section_title':
      return { cols, rows: component.editing ? 14 : 1 };
  }
}

export function paginate(components = []) {
  let currentRow = 1;
  let currentCol = 0;
  let pageSplitIndex = components.length;
  let totalPage = 1;
  let setup = false;

  components.forEach((component, index) => {
    const componentRequireCols = { full: 4, half: 2, quarter: 1 }[
      component.width || 'full'
    ];
    const isTitle = component.component_type === 'section_title';
    const notEnoughSpace = !isTitle && currentCol + componentRequireCols > 4;

    currentCol = currentCol + componentRequireCols;
    if (isTitle) {
      currentCol = 4;
      if (index === 0) currentRow = 0;
      if (currentRow === maxRowsInOnePage && !setup) {
        pageSplitIndex = index;
        totalPage = 2;
        setup = true;
      }
    }

    if (notEnoughSpace) {
      currentCol = componentRequireCols;
      currentRow = currentRow + 1;
      if (currentRow === maxRowsInOnePage + 1 && !setup) {
        pageSplitIndex = index;
        totalPage = 2;
        setup = true;
      }
    }
  });
  return { pageSplitIndex, totalPage: 1 };
}
