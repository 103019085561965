export const LOAD_INTEGRATIONS_START = 'LOAD_INTEGRATIONS_START';
export const LOAD_INTEGRATIONS_SUCCESS = 'LOAD_INTEGRATIONS_SUCCESS';
export const LOAD_INTEGRATIONS_ERROR = 'LOAD_INTEGRATIONS_ERROR';

export const CONNECT_INTEGRATION_START = 'CONNECT_INTEGRATIONS_START';
export const CONNECT_INTEGRATION_SUCCESS = 'CONNECT_INTEGRATIONS_SUCCESS';
export const CONNECT_INTEGRATION_ERROR = 'CONNECT_INTEGRATIONS_ERROR';
export const CONNECT_INTEGRATION_SAVE_SUCCESS =
  'CONNECT_INTEGRATION_SAVE_SUCCESS';

export const REMOVE_INTEGRATION_START = 'REMOVE_INTEGRATION_START';
export const REMOVE_INTEGRATION_SUCCESS = 'REMOVE_INTEGRATION_SUCCESS';
export const REMOVE_INTEGRATION_ERROR = 'REMOVE_INTEGRATION_ERROR';

export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
