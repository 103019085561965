import React from 'react';

import './section.scss';

interface PanelSection {
  children: any;
  borderless?: boolean;
}

const PanelSection = ({ children, borderless }: PanelSection) => {
  return (
    <div className={`panel-section ${borderless ? 'borderless' : ''}`}>
      {children}
    </div>
  );
};

export default PanelSection;
