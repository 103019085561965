import React from 'react';
import { Geography, useGeographies } from 'react-simple-maps/dist/index.es';

import { auTopojson, nzTopojson } from './topology-data';

export const COLOR_RANGE = [
  '#f1f835',
  '#c6e1ad',
  '#a4cd99',
  '#80b783',
  '#6f9c4d',
];

export const MIN_SCORE_VALUE = 0;
export const MAX_SCORE_VALUE = 10;

export type MapScoreData = {
  au: { [key: string]: number };
  nz: { [key: string]: number };
};

const colorScale = (value: number) => {
  if (value >= 0 && value < 2) {
    return COLOR_RANGE[0];
  } else if (value >= 2 && value < 4) {
    return COLOR_RANGE[1];
  } else if (value >= 4 && value < 6) {
    return COLOR_RANGE[2];
  } else if (value >= 6 && value < 8) {
    return COLOR_RANGE[3];
  } else {
    return COLOR_RANGE[4];
  }
};

const getTooltipLabel = (name: string, value?: number) => {
  if (!value) {
    return name;
  }
  return `${name}: ${value.toFixed(2)}`;
};

type CustomGeographyProps = {
  data: MapScoreData;
  setTooltipContent: (label: string) => void;
};

export const CustomGeography: React.FC<CustomGeographyProps> = ({
  data,
  setTooltipContent,
}) => {
  const { geographies: nzGeo } = useGeographies({ geography: nzTopojson });
  const { geographies: auGeo } = useGeographies({ geography: auTopojson });

  const showGreyMap =
    Object.keys(data.nz).length === 0 && Object.keys(data.au).length === 0;

  return (
    <g>
      {showGreyMap || Object.keys(data.nz).length > 0
        ? nzGeo.map((geo) => {
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  setTooltipContent(
                    getTooltipLabel(
                      geo.properties.NAME_1,
                      data.nz[geo.properties['ISO_1']]
                    )
                  );
                }}
                onMouseLeave={() => setTooltipContent('')}
                fill={
                  data.nz[geo.properties['ISO_1']] !== undefined
                    ? colorScale(data.nz[geo.properties['ISO_1']])
                    : '#F5F4F6'
                }
                stroke="#000"
                strokeWidth={0.05}
              />
            );
          })
        : null}
      {showGreyMap || Object.keys(data.au).length > 0
        ? auGeo.map((geo) => {
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  setTooltipContent(
                    getTooltipLabel(
                      geo.properties.NAME_1,
                      data.au[geo.properties['ISO_1']]
                    )
                  );
                }}
                onMouseLeave={() => setTooltipContent('')}
                fill={
                  data.au[geo.properties['ISO_1']] !== undefined
                    ? colorScale(data.au[geo.properties['ISO_1']])
                    : '#F5F4F6'
                }
                stroke="#000"
                strokeWidth={0.05}
              />
            );
          })
        : null}
    </g>
  );
};
