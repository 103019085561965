import axios from './axios';

export default class Suppliers {
  constructor(config) {
    const configNew = Object.assign({}, config, { timeout: 60000 });
    this.axios = axios(configNew);
    this.type = 'suppliers';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  getSuppliers() {
    return this.axios.get(this.path);
  }
}
