import React from 'react';
import styles from './css/CircleModal.css';

import CloseButton from 'modules/shared/components/inputs/CloseButton';

var CircleModal = createClass({
  render: function() {
    const { size, title, title_css, dismissHandler } = this.props;
    let dismissButton = null,
      modal_title = null,
      content_style = styles.content,
      title_style = styles.title;

    if (size) {
      content_style = styles['content_' + size];
    }

    if (title_css) {
      title_style = styles[title_css];
    }

    if (dismissHandler) {
      dismissButton = (
        <CloseButton
          css_class="button_position_top106x40"
          text="Close"
          handleClick={dismissHandler}
        />
      );
    }

    if (title) {
      modal_title = <h2 className={title_style}>{title}</h2>;
    }

    return (
      <div className={styles.modal}>
        {dismissButton}
        <div className={styles.fade}>
          <div className={content_style}>
            <div className={styles.body}>
              {modal_title}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default CircleModal;
