import api from '../../api';

export function declineInvite(headquarter_id) {
  return (dispatch, getState) => {
    var entity = api(
        'entities',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      entityId = getState().current_user.current_entity.id;
    entity.declineBranchInvite(entityId, (result) => {}, (error) => {}, {
      headquarter_id: headquarter_id,
    });
  };
}

export function acceptInvite(headquarter_id) {
  return (dispatch, getState) => {
    var entity = api(
        'entities',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      entityId = getState().current_user.current_entity.id;
    entity.acceptBranchInvite(entityId, (result) => {}, (error) => {}, {
      headquarter_id: headquarter_id,
    });
  };
}
