import React, { ReactElement } from 'react';
import { Header } from 'modules/request-permission-flow/components/Review/styles';

const PermissionSent = ({
  consumerName,
  requesterContactPhoneNumber,
  requesterEmail,
  requesterName,
  supplierName,
}: {
  consumerName: string;
  requesterContactPhoneNumber: string;
  requesterEmail: string;
  requesterName: string;
  supplierName: string;
}): ReactElement => (
  <>
    <Header>Permission sent</Header>
    <div className="content">
      <p>
        Thank you! The applicant {requesterName} ({requesterEmail},{' '}
        {requesterContactPhoneNumber}) can now proceed with the credit
        application for {consumerName} with {supplierName}. If you have done
        this by mistake please get in touch with{' '}
        <a href="mailto:support@1centre.com">support@1centre.com</a>.
      </p>
    </div>
  </>
);

export default PermissionSent;
