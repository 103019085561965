// @ts-nocheck TS compilation is raising error on the following:
// messages
//   ? ([] as string[]).concat(messages as string[], error.message)
//   : error.message
// However, this file is based on https://github.com/react-hook-form/resolvers/blob/master/yup/src/yup.ts
// hence having the TS no check annotation

import { appendErrors, FieldError } from 'react-hook-form-latest';
import Yup from 'yup-latest';

import { toNestError, validateFieldsNatively } from './resolver';
import { Resolver } from './types';

/**
 * Why `path!` ? because it could be `undefined` in some case
 * https://github.com/jquense/yup#validationerrorerrors-string--arraystring-value-any-path-string
 */
const parseErrorSchema = (
  error: Yup.ValidationError,
  validateAllFieldCriteria: boolean
) =>
  (error.inner || []).reduce<Record<string, FieldError>>((previous, error) => {
    if (!previous[error.path!]) {
      previous[error.path!] = { message: error.message, type: error.type! };
    }

    if (validateAllFieldCriteria) {
      const types = previous[error.path!].types;
      const messages = types && types[error.type!];

      previous[error.path!] = appendErrors(
        error.path!,
        validateAllFieldCriteria,
        previous,
        error.type!,
        messages
          ? ([] as string[]).concat(messages as string[], error.message)
          : error.message
      ) as FieldError;
    }

    return previous;
  }, {});

// TODO: `Resolver` type has been changed to `any` due to a breaking change
// and looks to be from react-hook-form-latest 7.43.1. The TS errors is
// `Type instantiation is excessively deep and possibly infinite.`
export const yupResolver: any = (
  schema,
  schemaOptions = {},
  resolverOptions = {}
) => async (values, context, options) => {
  try {
    if (schemaOptions.context && window._env_.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        "You should not used the yup options context. Please, use the 'useForm' context object instead"
      );
    }

    const result = await schema[
      resolverOptions.mode === 'sync' ? 'validateSync' : 'validate'
    ](values, Object.assign({ abortEarly: false }, schemaOptions, { context }));

    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    return {
      errors: {},
      values: result,
    };
  } catch (e) {
    if (!e.inner) {
      throw e;
    }

    return {
      errors: toNestError(
        parseErrorSchema(
          e,
          !options.shouldUseNativeValidation && options.criteriaMode === 'all'
        ),
        options
      ),
      values: {},
    };
  }
};
