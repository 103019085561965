import get from 'lodash.get';

interface IBusinessNames {
  companyName: string;
  entityName: string;
  tradingName: string;
}

const getAustraliaBusinessNames = ({
  applicationAttributes,
  legalType,
}: {
  applicationAttributes: { [key: string]: any };
  legalType: string;
}): IBusinessNames => {
  if (legalType === 'personal') {
    const entityName = get(applicationAttributes, 'trading_name', '');

    return { companyName: '', entityName, tradingName: entityName };
  }

  const companyName = get(applicationAttributes, 'company_name', '');

  return {
    companyName,
    entityName: companyName,
    tradingName: get(applicationAttributes, 'trading_name', ''),
  };
};

const getNewZealandBusinessNames = ({
  applicationAttributes,
  legalType,
}: {
  applicationAttributes: { [key: string]: any };
  legalType: string;
}): IBusinessNames => {
  if (legalType === 'company') {
    const companyName = get(applicationAttributes, 'company_name', '');

    return { companyName, entityName: companyName, tradingName: '' };
  }

  const tradingName = get(applicationAttributes, 'trading_name', '');

  return { companyName: '', entityName: tradingName, tradingName };
};

export const getBusinessNamesForRegion = ({
  applicationAttributes,
  legalType,
  region,
}: {
  applicationAttributes: { [key: string]: any };
  legalType: string;
  region: string;
}): IBusinessNames => {
  if (region === 'AU') {
    return getAustraliaBusinessNames({
      applicationAttributes,
      legalType,
    });
  }

  if (region === 'NZ') {
    return getNewZealandBusinessNames({
      applicationAttributes,
      legalType,
    });
  }

  throw `Invalid region: ${region}`;
};
