import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import { getCustomConfigurationByEntityType } from 'modules/consumer-onboarding/components/onboarding/utils/disablePersonalEntityType';
import CircleIconButton from 'modules/shared/components/widgets/interactive/CircleIconButton';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { selectEntityType, setEntityFormValue } from '../../actions/onboarding';
import styles from './css/Business.css';

class BusinessEntityType extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.selected_type));
  }

  componentDidMount() {
    const {
      application,
      currentUser,
      dispatch,
      handleComplete,
      selected_type,
      entity_region,
    } = this.props;

    mixpanel.track('Consumer Onboarding: Business Entity Type page loaded', {
      Application: application.id,
      Component: 'BusinessEntityType',
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });

    if (!entity_region) {
      dispatch(setEntityFormValue('entity_region', 'NZ'));
    }
    handleComplete(this.isComplete(selected_type));
  }

  isComplete = (entity_legal_type) => {
    const { setPageValidationStartFinish } = this.props;
    if (entity_legal_type) {
      setPageValidationStartFinish();
      return true;
    }
    return false;
  };

  /**
   * Sets the selected entity state.
   */
  selectItem = (type) => {
    const { dispatch, options, application } = this.props;
    let link = `/register/consumer/${application.id}/business/other-details`;

    if (type === 'other') {
      dispatch(selectEntityType(null, null));
      link = `/register/consumer/${application.id}/business/other-type`;
      browserHistory.push(link);
      return;
    }
    dispatch(selectEntityType(type, options[type].title));
    if (application) {
      browserHistory.push(link);
    }
  };

  render() {
    const { options, supplier, title } = this.props;

    // Create the button list
    const button_components = Object.keys(options).map((item, index) => {
      const current_button = options[item];

      const customConfigurationByEntityType = getCustomConfigurationByEntityType(
        get(supplier, 'attributes.custom_entity_type_configuration', []),
        item
      );
      const isDisabled = get(
        customConfigurationByEntityType,
        'disabled',
        false
      );
      const customMessage = get(customConfigurationByEntityType, 'message');

      return (
        <CircleIconButton
          customTipMessage={customMessage}
          disabled={isDisabled}
          handleClick={this.selectItem}
          icon={current_button.icon}
          key={`${item}-${index}`}
          small={!!isMobile()}
          text={current_button.title}
          tip_name={current_button.title}
          type={item}
          value={item}
        />
      );
    });

    return (
      <section className={styles.section}>
        <div className={`${styles.row} ${styles.mobile_margin}`}>
          <div className={styles.full_col}>
            <PanelTitle text={title} margin_top={isMobile() ? '-40px' : null} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.options}>{button_components}</div>
        </div>
      </section>
    );
  }
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    company: {
      icon: 'entity_x3plus',
      title: 'Company',
    },
    partnership: {
      icon: 'entity_x2',
      title: 'Partnership',
    },
    trust: {
      icon: 'entity_x3',
      title: 'Trust',
    },
    sole_trader: {
      icon: 'entity_x1',
      title: 'Sole trader',
    },
    personal: {
      icon: 'entity_x2',
      title: 'Personal',
    },
    other: {
      icon: 'entity_x3',
      title: 'Other',
    },
  },
  title: 'Select your entity type',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect((state) => {
  const supplier = get(state, 'cob_section.supplier');
  let selected_type = null;

  if (state.cob_business.entity_type !== null) {
    selected_type = state.cob_business.entity_type;
  }

  return {
    currentUser: state.current_user,
    entity_region: state.cob_business.entity_region,
    options: defaults.options,
    selected_type,
    supplier,
    title: defaults.title,
  };
})(BusinessEntityType);
