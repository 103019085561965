import axios from './axios';

export default class ColorPalette {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'color_palettes';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  getColorPalette(id) {
    return this.axios.get(`${this.path}/${id}`);
  }

  updateColorPalette(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, {
      data: {
        theme: {
          ...attributes,
        },
      },
    });
  }
}
