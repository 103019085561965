// eslint-disable-next-line no-useless-escape
const EMAIL_REG_EX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_REG = /^\d+$/;
export const NAME_REGEX = /^([A-Za-z\s]+['-])?[A-Za-z\s]+$/;

export const isValidEmail = (email: string): boolean => {
  return EMAIL_REG_EX.test(email);
};

export const isAllNumber = (numberStr: string): boolean => {
  return NUMBER_REG.test(numberStr);
};

// Names are allowed with a-zA-Z, "-" and "'" in the middle
// Only leading and ending letter is allowed in the name
export const isValidName = (name: string): boolean => {
  return NAME_REGEX.test(name);
};
