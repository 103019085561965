import React from 'react';
import moment from 'moment';

import styles from '../centrix.css';

class Insolvencies extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (
      !data.data_sets.insolvencies ||
      !data.data_sets.insolvencies.insolvency
    ) {
      return [];
    }
    let insolvencies = data.data_sets.insolvencies.insolvency;
    if (insolvencies.constructor !== Array) {
      return [insolvencies];
    }
    return insolvencies;
  }

  render() {
    const { data } = this.props;
    let arrayfied_data = this.getArrayifiedData(data);

    if (arrayfied_data.length < 1) {
      return (
        <div>
          <p className="is-size-6	has-text-weight-semibold has-text-grey-dark mb-3">
            Data sourced from the Insolvency and Trustee Service - As this data
            is subject to change, please visit www.insolvency.govt.nz to confirm
            current details and for further information specific to the data
            held.
          </p>
          <p className="is-size-6	is-italic has-text-weight-semibold has-text-grey-dark mb-3">
            The subject has a clear credit history in relation to Insolvencies
            searched on Centrix and/or our data source provider.
          </p>
          <p className="is-size-6	is-italic has-text-weight-semibold has-text-grey-dark">
            We have no Insolvencies listed against the subject.
          </p>
        </div>
      );
    }

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Adjudication Date</th>
              <th>Type</th>
              <th>Insolvency Reference</th>
              <th>Status</th>
              <th>Discharge Date</th>
            </tr>
          </thead>
          <tbody>
            {arrayfied_data.map((item) => (
              <tr>
                <td>{moment(item.adjudication_date).format('DD/MM/YYYY')}</td>
                <td>{item.insolvency_type}</td>
                <td>{item.insolvency_number}</td>
                <td>{item.insolvency_status}</td>
                <td>
                  {item.discharge_date &&
                    item.discharge_date.constructor === String &&
                    moment(item.discharge_date).format('DD/MM/YYYY')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Insolvencies;
