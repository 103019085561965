import * as PPSRRenewFormComponents from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRenewForm/index';
import React from 'react';
import isBlank from 'utils/isBlank';

export default function PPSRRenewForm(props) {
  const { region } = props;

  if (isBlank(region)) {
    return null;
  }

  const FormComponent = PPSRRenewFormComponents[`${region}PPSRRenewForm`];

  return <FormComponent {...props} />;
}
