import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import styles from '../../../css/CommonReviewBusiness.css';

var PPSRRegistrationSummaryAndFilter = createClass({
  render() {
    const { isAllExpanded } = this.props;

    return (
      <ReviewContainer
        subHeading="PPSR Registration Summary and Filter"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>Not present on file</p>
      </ReviewContainer>
    );
  },
});

export default PPSRRegistrationSummaryAndFilter;
