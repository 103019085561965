export enum RoleType {
  Admin = 'admin',
  Approver = 'approver',
  Standard = 'standard',
  ConsumerManager = 'consumer_manager',
}

type UserData = {
  can_override_limit: string;
  contact_phone_number: string;
  email: string;
  first_name: string;
  full_name: string;
  last_name: string;
  level: string;
  noData?: boolean;
  permissions: string;
  page_permissions?: { [key: string]: boolean };
  receive_email_notifications: boolean;
  role_types?: RoleType[];
};

export type Props = {
  currentUser: any;
  dispatch: any;
  isFetching: boolean;
  isLoading?: boolean;
  isSupplier: boolean;
  setIsLoading: (value: boolean) => void;
  userId: string;
  userData: UserData;
};

// Merge with task 7310
export const icons = {
  danger: {
    icon: 'times-circle',
  },
  success: {
    icon: 'check-circle',
  },
};

// Merge with task 7310
export type Status = {
  message: string;
  type: string;
};
