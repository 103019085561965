import Switch from 'modules/shared/components/widgets/interactive/Switch';
import React from 'react';

type Props = {
  current: string;
  handleChange: (value: string) => void;
};

function ActionSwitch({ current, handleChange }: Props) {
  return (
    <Switch
      leftLabel="mine"
      rightLabel="all"
      leftOption="owner"
      rightOption="all"
      current={current}
      handleChange={handleChange}
      cssOverrides={{
        marginRight: 0,
        marginTop: '-0.5rem',
      }}
      buttonCssOverrides={{
        display: 'inline-block',
        width: '68px',
      }}
    />
  );
}

export default ActionSwitch;
