export const getOtherTypeTooltipLabel = (
  type: 'authenticity' | 'id_check' | 'proof_of_life' | 'face_match'
): string => {
  const idTypeMap = {
    authenticity: 'Authenticity check',
    id_check: 'ID Check',
    proof_of_life: 'Proof of life check',
    face_match: 'Face match check',
  };
  const idTypeString = idTypeMap[type || 'id_check'];
  return `${idTypeString} doesn’t apply to ‘Other’ ID type. The supported ID types include Driver licence and Passport.`;
};
