import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';

const Description = styled(Typography)({
  marginBottom: 7,
});

interface RuleSetSectionProps {
  children: React.ReactElement;
  description?: string;
  title?: string | string[];
  tooltipTitle?: string;
  optional?: boolean;
  disabled?: boolean;
}

const RuleSetSection = ({
  children,
  description,
  title,
  tooltipTitle,
  optional,
  disabled,
}: RuleSetSectionProps): JSX.Element => {
  return (
    <div
      className={`rulesetContent__ruleSection${disabled ? ' disabled' : ''}`}
    >
      <div className="rulesetContent__ruleSection__header">
        {title && (
          <div className="rulesetContent__ruleSection__header__title">
            {typeof title === 'string' && title}
            {Array.isArray(title) &&
              title.map((titlePart, index) => (
                <span
                  className={
                    index > 0
                      ? 'rulesetContent__ruleSection__header__title__emphasized'
                      : undefined
                  }
                >
                  {titlePart}
                </span>
              ))}
            {optional && (
              <span className="rulesetContent__ruleSection__header__title__optional">{` (optional)`}</span>
            )}
          </div>
        )}

        {tooltipTitle && (
          <PopperTooltip title={tooltipTitle} placement="top">
            <HelpIcon />
          </PopperTooltip>
        )}
      </div>
      {description && <Description>{description}</Description>}
      {children}
    </div>
  );
};

export default RuleSetSection;
