import { call, apply, put, select } from 'redux-saga/effects';
import api from '../../api';
import { browserHistory } from 'react-router';

import {
  TRADE_REFERENCES_CREATE_CHECK_START,
  TRADE_REFERENCES_CREATE_CHECK_ERROR,
  TRADE_REFERENCES_CREATE_CHECK_SUCCESS,
  TRADE_REFERENCES_CREATE_NOTES_START,
  TRADE_REFERENCES_CREATE_NOTES_ERROR,
  TRADE_REFERENCES_CREATE_NOTES_SUCCESS,
  TRADE_REFERENCES_UPDATE_CHECK_SUCCESS,
  TRADE_REFERENCES_UPDATE_CHECK_ERROR,
} from './constants';

export function* createTradeReferenceCheck(action) {
  const { tradeReferenceId, checkAttributes, notes } = action.payload;
  const state = yield select();
  const checks_api = api(
    'trade_reference_checks',
    state.current_user.access_token
  );
  const notes_api = api('notes', state.current_user.access_token);

  try {
    const check = yield apply(checks_api, checks_api.createCheck, [
      tradeReferenceId,
      checkAttributes,
    ]);

    if (notes && check) {
      let notePromises = [];
      notes.forEach((note) => {
        notePromises.push(
          apply(notes_api, notes_api.createNote, [
            check.data.data.id,
            note.attributes,
            'trade_reference_checks',
          ])
        );
      });

      try {
        const noteResponses = yield notePromises;
        yield put({
          type: TRADE_REFERENCES_CREATE_NOTES_SUCCESS,
          payload: noteResponses,
        });
      } catch (e) {
        yield put({
          type: TRADE_REFERENCES_CREATE_NOTES_ERROR,
          payload: e,
        });
      }
    }
    yield put({
      type: TRADE_REFERENCES_CREATE_CHECK_SUCCESS,
      payload: check,
    });
    browserHistory.push('/trm/list');
  } catch (e) {
    yield put({
      type: TRADE_REFERENCES_CREATE_CHECK_ERROR,
      payload: e,
    });
  }
}

export function* updateTradeReferenceCheck(action) {
  const { id, checkAttributes, newNotes, deleteNotes } = action.payload;
  const state = yield select();
  const checks_api = api(
    'trade_reference_checks',
    state.current_user.access_token
  );
  const notes_api = api('notes', state.current_user.access_token);

  try {
    const check = yield apply(checks_api, checks_api.updateCheck, [
      id,
      checkAttributes,
    ]);

    if (newNotes && check) {
      let notePromises = [];
      newNotes.forEach((note) => {
        notePromises.push(
          apply(notes_api, notes_api.createNote, [
            check.data.data.id,
            note.attributes,
            'trade_reference_checks',
          ])
        );
      });
      deleteNotes.forEach((id) => {
        notePromises.push(apply(notes_api, notes_api.deleteNote, [id]));
      });
      try {
        const noteResponses = yield notePromises;
        yield put({
          type: TRADE_REFERENCES_CREATE_NOTES_SUCCESS,
          payload: noteResponses,
        });
      } catch (e) {
        yield put({
          type: TRADE_REFERENCES_CREATE_NOTES_ERROR,
          payload: e,
        });
      }
    }
    yield put({
      type: TRADE_REFERENCES_UPDATE_CHECK_SUCCESS,
      payload: check,
    });
    browserHistory.push('/trm/list');
  } catch (e) {
    yield put({
      type: TRADE_REFERENCES_UPDATE_CHECK_ERROR,
      payload: e,
    });
  }
}
