import api from 'api';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import RecordHistoryModel from 'models/RecordHistoryModel';
import SignatureModel from 'models/SignatureModel';
import UserInformationModel from 'models/UserInformationModel';

export default class UserRoleModel extends BaseModel {
  async resend({ currentUser, onSuccessCallback }) {
    const userRoleAPI = api(
      'user_roles',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    try {
      await userRoleAPI.resend(this.id);
      onSuccessCallback();
    } catch (error) {
      console.error(error);
    }
  }

  get isApplicant() {
    return Object.keys(this.actingAs).includes('applicant');
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: 'signature',
      model: SignatureModel,
    });

    this.assignSingleRelationship({
      key: 'user_information',
      model: UserInformationModel,
    });

    this.assignManyRelationship({
      key: 'user_role_histories',
      model: RecordHistoryModel,
    });
  }
}
