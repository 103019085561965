import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React from 'react';
import { Controller } from 'react-hook-form-latest';

type InputProps = {
  controllerProps: {
    name: string;
    control: any;
  };
  inputProps: any;
};

function Input({ controllerProps, inputProps }: InputProps) {
  const { onBlur: handleBlur, ...restInputProps } = inputProps;
  return (
    <Controller
      {...controllerProps}
      render={({ field }) => (
        <BorderedTextField
          {...field}
          {...restInputProps}
          onBlur={(e) => {
            field.onBlur();
            if (handleBlur) {
              handleBlur(e);
            }
          }}
        />
      )}
    />
  );
}

export default Input;
