import * as yup from 'yup';

const australiaValidationSchema = {
  bank_account_number: yup
    .string()
    .min(6, 'Account number must be between 6 to 10 digits.')
    .max(10, 'Account number must be between 6 to 10 digits.')
    .trim(),
  bank_number: yup
    .string()
    .length(6, 'BSB must be 6 digits.')
    .trim(),
};

export default australiaValidationSchema;
