import React from 'react';
import get from 'lodash.get';
import styled from 'styled-components';
import PanToolIcon from '@material-ui/icons/PanTool';
import { Box, DialogContentText } from 'mui-latest';
import { connect } from 'react-redux';

import Button from 'modules/shared/components/inputs/Button';
import Modal from './Modal';
import { IRuleSetAttributes } from '../type';
import { createRuleSet } from 'modules/addons/auto_decisioning/actions';

const StyledContentText = styled(DialogContentText)`
  &.MuiDialogContentText-root {
    font-size: 12px;
    color: black;
    margin-bottom: 6px;
    text-align: center;
  }
`;

const ActionsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: 30,
});

type SetupCompleteProps = {
  isOpen: boolean;
  ruleset: IRuleSetAttributes;
  createRuleSet: (
    ruleset: IRuleSetAttributes,
    successCallback: () => void
  ) => void;
  onCancel: () => void;
  onSuccess: () => void;
  updating: boolean;
};

const SetupComplete: React.FC<SetupCompleteProps> = (props) => {
  const { isOpen, ruleset, createRuleSet, onCancel, onSuccess, updating } =
    props;

  const handleConfirm = () => {
    createRuleSet(ruleset, onSuccess);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={
        <Box display="flex" gridGap={6} justifyContent="center">
          {'High five'}
          <PanToolIcon />
          {'Setup complete!'}
        </Box>
      }
      onClose={onCancel}
    >
      <StyledContentText className="mb-5">
        Please confirm to turn on this ruleset.
      </StyledContentText>
      <ActionsContainer>
        <Button
          disabled={updating}
          loading={updating}
          onClick={handleConfirm}
          text="Confirm"
        />
        <Button disabled={updating} onClick={onCancel} text="Cancel" red />
      </ActionsContainer>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  updating: get(state, 'auto_decisioning.updating'),
});

const mapDispatchToProps = {
  createRuleSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupComplete);
