import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import isBlank from 'utils/isBlank';

import { stringifyJSON } from '../containers/WebhookDocumentationContainer';
import styles from '../styles/WebhookExampleStyle.css';

export function MergedDefinitions(props) {
  const { schemaDefinitions } = props;

  return <WebhookCodeBlock schema={stringifyJSON(schemaDefinitions.all)} />;
}

function containerClassName(props) {
  const { withMargin } = props;
  const defaultClassNames = [];

  if (withMargin) {
    defaultClassNames.push(styles.code_block_container);
  }

  return defaultClassNames.join(' ');
}

function SamplePayload(props) {
  const { payload } = props;

  if (isBlank(payload)) {
    return null;
  }

  return (
    <div>
      <h4>Sample payload</h4>
      <div className={styles.code_block}>
        <SyntaxHighlighter language="json" style={atomDark}>
          {payload}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

export default function WebhookCodeBlock(props) {
  const { payload, schema } = props;

  return (
    <div className={containerClassName(props)}>
      <div>
        <h4>Schema Definition</h4>
        <div className={styles.code_block}>
          <SyntaxHighlighter language="json" style={atomDark}>
            {schema}
          </SyntaxHighlighter>
        </div>
      </div>

      <SamplePayload payload={payload} />
    </div>
  );
}

WebhookCodeBlock.defaultProps = {
  withMargin: true,
};
