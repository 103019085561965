import * as Sentry from '@sentry/browser';
import styles from 'containers/css/ErrorBoundary.css';
import IconErrorBubble from 'images/svgs/icon-error-bubble.svg';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.error_container}>
          <div className={styles.error_header}>
            <IconErrorBubble width={145} height={150} />
            <div className={styles.error_header_text}>
              Hmmm... something went wrong
            </div>
          </div>
          <div className={styles.error_body}>
            Try this
            <ul className={styles.error_body_details}>
              <li>Refresh the page</li>
              <li>Check your network settings</li>
              <li>
                Contact us at{' '}
                <a href="mailto:support@1centre.com">support@1centre.com</a>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
