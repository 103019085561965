/* eslint-disable max-lines */
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import IconDeleteArchive from 'images/svgs/icon-delete-archive.svg';
import IconNotes from 'images/svgs/icon-notes.svg';
import IconPDFDisabled from 'images/svgs/icon-pdf-disabled.svg';
import IconPDF from 'images/svgs/icon-pdf.svg';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import ModuleCardholderApplicationModel from 'models/ModuleCardholderApplicationModel';
import UserModel from 'models/UserModel';
import { getCardManagementModuleLink } from 'modules/addons/helpers';
import StatusIndicator from 'modules/applications/components/StatusIndicator';
import CardholderWithSignatureDetails from 'modules/card-management-applications/components/CardholderWithSignatureDetails';
import Notes from 'modules/card-management-applications/components/Notes';
import styles from 'modules/card-management-applications/css/CardOrdersShow.css';
import commonStyles from 'modules/direct-debit-authority/css/DIrectDebitAuthorityShow.css';
import AddonAnswer from 'modules/new-applications/components/AddonAnswer';
import {
  ActingAsCheckbox,
  ACTION_COMPONENTS,
  DigitalSignature,
  getResendLabel,
  PassFailAction,
  SuperAdminCopyLink,
  useImageModalState,
} from 'modules/new-applications/components/application-sections/IdentificationChecks';
import RecordHistory from 'modules/new-applications/components/RecordHistory';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import ScrollableContent from 'modules/shared/components/containers/ScrollableContent';
import TopbarContent from 'modules/shared/components/containers/TopbarContent';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import useCollapsibleContentState from 'modules/shared/hooks/useCollapsibleContentState';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { formatLocalTime } from 'utils/dateFormatter';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

const DEFAULT_INTERVAL = 1000;

const emailSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email'),
});

function useApplicationState(props) {
  const { currentUser } = props;
  const id = get(props, 'params.id');
  const [application, setApplication] = useState({});
  const [meta, setMeta] = useState({});

  const onFetchModuleCardholderApplication = () => {
    (async function () {
      const { meta, moduleCardholderApplication } =
        await ModuleCardholderApplicationModel.fetchModuleCardholderApplication(
          {
            accessToken: currentUser.accessToken,
            entityId: get(currentUser, 'currentEntity.id'),
            id,
            params: { include: 'notes' },
          }
        );
      setApplication(moduleCardholderApplication);
      setMeta(meta);
    })();
  };

  const onRefreshModuleCardholderApplicationState = ({
    meta,
    moduleCardholderApplication,
  }) => {
    setApplication(moduleCardholderApplication);
    setMeta(meta);
  };

  return {
    application,
    meta,
    onFetchModuleCardholderApplication,
    onRefreshModuleCardholderApplicationState,
  };
}

function topbarStatus(application) {
  if (application.archived) {
    return 'Archived';
  }

  if (['completed', 'loaded_in_system'].includes(application.status)) {
    return 'complete';
  }

  return application.status;
}

function pdfTopbarAction(application) {
  const pdfUrl = get(application, 'pdf.url');

  const onClick = () => {
    if (isPresent(pdfUrl)) {
      window.open(pdfUrl, 'card_order_pdf_wundow');
    }
  };

  return {
    Icon: IconPDF,
    IconDisabled: IconPDFDisabled,
    isDisabled: isBlank(pdfUrl),
    label: 'PDF Form',
    onClick,
  };
}

function Topbar(props) {
  const { application, visibleSection, setVisibleSection } = props;
  const pdfTopbarActionComponent = pdfTopbarAction(application);

  const topbarActions = [
    {
      Icon: IconNotes,
      isActive: visibleSection === 'notes',
      label: 'Notes',
      onClick: () => setVisibleSection('notes'),
    },
    {
      Icon: IconDeleteArchive,
      isActive: visibleSection === 'delete_archive',
      label: `Delete / ${application.archived ? 'Unarchive' : 'Archive'}`,
      onClick: () => setVisibleSection('delete_archive'),
    },
    pdfTopbarActionComponent,
  ];

  return (
    <TopbarContent topbarActions={topbarActions}>
      <div className={commonStyles.status_indicator}>
        <StatusIndicator status={topbarStatus(application)} long={true} />
      </div>
    </TopbarContent>
  );
}

function DeleteSection(props) {
  const { application, currentUser, onSubmit } = props;

  if (application.status === 'completed') {
    return null;
  }

  const { errors, handleSubmit, register } = useForm({
    mode: 'onBlur',
  });

  const onSubmitForm = (data) => {
    mixpanel.track('Card management module', {
      'Card order form ID': application.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });

    onSubmit({
      delete_note: data.note,
      status: 'deleted',
    });
  };

  return (
    <FixedContent header="Delete application" withSideMargin={true}>
      <GridContent gridColumnTemplate="two_thirds">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Button text="Delete" type="submit" red />
        </form>
      </GridContent>
    </FixedContent>
  );
}

function ArchiveSection(props) {
  const { application, currentUser, onSubmit } = props;
  const { errors, handleSubmit, register } = useForm({
    mode: 'onBlur',
  });

  const onSubmitForm = (data) => {
    mixpanel.track(
      `${application.archived ? 'Unarchive' : 'Archive'} card order form`,
      {
        'Card order form ID': application.id,
        'Entity ID': get(currentUser, 'currentEntity.id'),
        distinct_id: currentUser.id,
      }
    );

    onSubmit({
      archive_note: data.note,
      archived: !application.archived,
    });
  };

  return (
    <FixedContent
      header={`${application.archived ? 'Unarchive' : 'Archive'} application`}
      withSideMargin={true}
    >
      <GridContent gridColumnTemplate="two_thirds">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Button
            text={application.archived ? 'Unarchive' : 'Archive'}
            type="submit"
          />
        </form>
      </GridContent>
    </FixedContent>
  );
}

function DeleteArchive(props) {
  const { application, currentUser, router, setVisibleSection } = props;

  const onClickCloseButton = () => setVisibleSection('show');
  const onSuccessCallback = () => router.push('/dashboard/card_orders');
  const onSubmit = (attributes) => {
    application.setAttributes(attributes);
    application.update({ currentUser, onSuccessCallback });
  };

  return (
    <div className={commonStyles.details_container}>
      <CloseButton
        handleClick={onClickCloseButton}
        style={{ right: 10, top: 10 }}
      />
      <DeleteSection onSubmit={onSubmit} {...props} />
      <ArchiveSection onSubmit={onSubmit} {...props} />
    </div>
  );
}

function CardOrderDetails(props) {
  const { application, meta } = props;
  const addonAnswer = application.addonAnswer || {
    additionalFieldsGeneral: [],
  };

  return (
    <FixedContent header="Card order details">
      <GridContent>
        <LabeledContent
          label={meta.account_number_label}
          content={application.accountNumber}
        />
        <LabeledContent
          label={meta.account_name_label}
          content={application.accountName}
        />
        <AddonAnswer
          answers={application.answers}
          components={addonAnswer.additionalFieldsGeneral}
          isContained={false}
        />
      </GridContent>
    </FixedContent>
  );
}

function Cardholder(props) {
  const { application, authorisation, index } = props;
  const { moduleCardholderSignatory } = authorisation;
  const addonAnswer = moduleCardholderSignatory.addonAnswer || {
    additionalFields: [],
  };

  return (
    <FixedContent header={`Cardholder ${index}`}>
      <GridContent>
        <LabeledContent
          label="Name to appear on card"
          content={moduleCardholderSignatory.fullName}
        />
        <LabeledContent
          label="Card issue reason"
          content={moduleCardholderSignatory.cardIssuingReason}
        />
        {application.isCardLimitEnabled && (
          <LabeledContent
            label="Monthly card limit"
            content={moduleCardholderSignatory.formattedCardLimit}
          />
        )}
        {application.isSignatureRequired && (
          <CardholderWithSignatureDetails
            moduleCardholderSignatory={moduleCardholderSignatory}
          />
        )}
        <AddonAnswer
          answers={moduleCardholderSignatory.answers}
          components={addonAnswer.additionalFields}
          isContained={false}
        />
      </GridContent>
    </FixedContent>
  );
}

function getIsEmailDisabled({
  application,
  identificationCheck,
  isApplicant,
  signature,
}) {
  if (application.isViewOnly) {
    return true;
  }

  if (isApplicant) {
    return application.isComplete;
  }

  return (
    identificationCheck.isIdentificationCheckSuccess ||
    signature.isManuallyApproved
  );
}

function isResendVisible({ application, isApplicant }) {
  if (application.isViewOnly || application.isComplete) {
    return false;
  }

  if (isApplicant) {
    return !application.isSubmitted;
  }

  return application.isSubmitted;
}

function ResendAction(props) {
  const { application, isApplicant, isSending, onClick } = props;

  if (!isResendVisible({ application, isApplicant })) {
    return null;
  }

  const label = getResendLabel({
    isApplicant,
    isMobileScreen: false,
    isSending,
  });
  const ResendActionComponent = ACTION_COMPONENTS.desktop.ResendAction;

  return (
    <ResendActionComponent
      isSending={isSending}
      label={label}
      onClick={onClick}
    />
  );
}

function CopyLink(props) {
  const { application, currentUser, isApplicant } = props;

  if (
    !isApplicant ||
    currentUser.isStandard ||
    application.isViewOnly ||
    application.isComplete
  ) {
    return null;
  }

  const [isCopied, setIsCopied] = useState(false);
  const link = getCardManagementModuleLink(
    get(currentUser, 'currentEntity.id', '')
  );

  const onCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), DEFAULT_INTERVAL);
    } catch (error) {
      setIsCopied(false);
      console.error(error);
    }
  };

  const label = isCopied ? 'Copied!' : 'Copy link';
  const CopyLinkActionComponent = ACTION_COMPONENTS.desktop.CopyLinkAction;

  return (
    <CopyLinkActionComponent
      isCopied={isCopied}
      label={label}
      onClick={onCopyToClipboard}
    />
  );
}

function retrieveIdentificationCheck(authorisation) {
  const { identificationCheck, vedaCheck } = authorisation;

  if (identificationCheck.isPersisted) {
    return identificationCheck;
  }

  if (vedaCheck.isPersisted) {
    return vedaCheck;
  }

  return {};
}

function isSuperAdminLinkVisible({ application, isApplicant }) {
  if (isApplicant) {
    return false;
  }

  return application.isSubmitted;
}

function SignatureRow(props) {
  const {
    application,
    authorisation,
    currentUser,
    onFetchModuleCardholderApplication,
    onShowImageModal,
    onSetAlert,
  } = props;

  const { moduleCardholderApplicant, moduleCardholderSignatory, signature } =
    authorisation;
  const { icon, isOpen, onToggleIsOpen } = useCollapsibleContentState();
  const { isLoading: isSending, setIsLoading: setIsSending } =
    useIsLoadingState();
  const { errors, getValues, register } = useForm({
    mode: 'onBlur',
    validationSchema: emailSchema,
  });

  const isApplicant =
    moduleCardholderSignatory.isApplicant ||
    moduleCardholderApplicant.isPersisted;

  const identificationCheck = retrieveIdentificationCheck(authorisation);
  const isEmailDisabled = getIsEmailDisabled({
    application,
    identificationCheck,
    isApplicant,
    signature,
  });

  const signatureDetails = {
    email: moduleCardholderSignatory.email || moduleCardholderApplicant.email,
    fullName:
      signature.fullName ||
      moduleCardholderSignatory.fullName ||
      moduleCardholderApplicant.email,
  };

  const onResendCallback = () => {
    onFetchModuleCardholderApplication();
    setIsSending(false);
  };

  const onFailCallback = () => {
    onSetAlert({
      message:
        'The user already exists, please send another application instead of modifying the email.',
      type: 'error',
    });
  };

  const onResend = () => {
    const errorMessage = get(errors, 'email.message');
    if (isPresent(errorMessage)) {
      return;
    }

    setIsSending(true);

    authorisation.updateEmailAndResend({
      currentUser,
      email: getValues('email'),
      onFailCallback,
      onSuccessCallback: onResendCallback,
    });
  };

  let emailField = (
    <div className={styles.grid_table_column}>{signatureDetails.email}</div>
  );
  if (!isApplicant) {
    emailField = (
      <div className={styles.email_field}>
        <BorderedTextField
          label=""
          placeholder=""
          defaultValue={signatureDetails.email}
          disabled={isEmailDisabled}
          name="email"
          inputRef={register}
          customProps={{
            isCompact: true,
            withBottomMargin: false,
          }}
          error={Boolean(errors.email)}
          helperText={get(errors, 'email.message', ' ')}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.grid_table}>
        <div
          className={`${styles.first_column} ${styles.grid_table_column}`}
          onClick={onToggleIsOpen}
        >
          {icon}
          <div className={styles.authorisation_name}>
            {signatureDetails.fullName}
          </div>
        </div>
        {emailField}
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox
            authorisation={authorisation}
            actingAs="applicant"
          />
        </div>
        <div className={styles.grid_table_column}>
          <ActingAsCheckbox
            authorisation={authorisation}
            actingAs="cardholder"
          />
        </div>
        <div className={styles.grid_table_column}>
          <StatusIndicator long={true} status={authorisation.status} />
        </div>
        <div className={`${styles.actions_column} ${styles.grid_table_column}`}>
          <ResendAction
            application={application}
            currentUser={currentUser}
            isApplicant={isApplicant}
            isSending={isSending}
            onClick={onResend}
          />
          <CopyLink
            application={application}
            currentUser={currentUser}
            isApplicant={isApplicant}
          />
          <SuperAdminCopyLink
            application={application}
            authorisation={authorisation}
            currentUser={currentUser}
            isApplicant={isApplicant}
            isVisible={isSuperAdminLinkVisible({ application, isApplicant })}
          />
          <PassFailAction
            PassActionComponent={ACTION_COMPONENTS.desktop.PassAction}
            FailActionComponent={ACTION_COMPONENTS.desktop.FailAction}
            application={application}
            authorisation={authorisation}
            currentUser={currentUser}
            identificationCheck={identificationCheck}
            onFetchApplicationRecord={onFetchModuleCardholderApplication}
          />
        </div>
      </div>

      <Collapse in={isOpen}>
        <DigitalSignature
          application={application}
          authorisation={authorisation}
          currentUser={currentUser}
          identificationCheck={identificationCheck}
          signature={signature}
          onFetchApplicationRecord={onFetchModuleCardholderApplication}
          onShowImageModal={onShowImageModal}
          isCardManagementApplication
        />
      </Collapse>
    </div>
  );
}

const getAuthorisationsFromRules = (authorisations, isSignatureRequired) => {
  if (isSignatureRequired) {
    return authorisations;
  }

  return authorisations.filter((authorisation) => authorisation.isApplicant);
};

function IdentificationChecks(props) {
  const {
    application,
    authorisations,
    currentUser,
    onFetchModuleCardholderApplication,
    onShowImageModal,
  } = props;

  const authorisationsToShow = getAuthorisationsFromRules(
    authorisations,
    application.isSignatureRequired
  );

  const rows = authorisationsToShow.map((authorisation, i) => (
    <SignatureRow
      key={`signature-row-${i + 1}`}
      application={application}
      authorisation={authorisation}
      currentUser={currentUser}
      onFetchModuleCardholderApplication={onFetchModuleCardholderApplication}
      onShowImageModal={onShowImageModal}
    />
  ));

  return (
    <FixedContent header="Identification checks">
      <div className={`${styles.grid_table} ${styles.header}`}>
        <div />
        <div />
        <div>Applicant</div>
        <div>Cardholder</div>
        <div />
      </div>
      {rows}
    </FixedContent>
  );
}

function LoadedInSystemCheckbox(props) {
  const { isLoadedInSystem, onLoadedInSystem } = props;

  if (typeof isLoadedInSystem === 'undefined') {
    return null;
  }

  return (
    <Checkbox
      size="small"
      checked={isLoadedInSystem}
      disabled={isLoadedInSystem}
      onChange={onLoadedInSystem}
    />
  );
}

function LoadedInSystemBy(props) {
  const { application } = props;

  if (!application.isLoadedInSystem) {
    return null;
  }

  let formattedLoadedInSystemAt = '-';
  if (isPresent(application.loadedInSystemAt)) {
    formattedLoadedInSystemAt = formatLocalTime(
      application.loadedInSystemAt,
      'minute'
    );
  }

  return (
    <div className={commonStyles.reviewed_by}>
      (Loaded in system by {get(application, 'loadedInSystemBy.fullName')} on{' '}
      {formattedLoadedInSystemAt})
    </div>
  );
}

function isReviewVisible(application) {
  if (application.archived) {
    return false;
  }

  return ['completed', 'loaded_in_system'].includes(application.status);
}

function Review(props) {
  const {
    application,
    currentUser,
    onRefreshModuleCardholderApplicationState,
  } = props;

  if (!isReviewVisible(application)) {
    return null;
  }

  const [checked, setChecked] = useState(false);

  const onSuccessCallback = ({ meta, moduleCardholderApplication }) => {
    mixpanel.track('Loaded in system card order', {
      'Card order application ID': moduleCardholderApplication.id,
      'Entity ID': get(currentUser, 'currentEntity.id'),
      distinct_id: currentUser.id,
    });

    onRefreshModuleCardholderApplicationState({
      meta,
      moduleCardholderApplication,
    });
  };

  const onLoadedInSystem = () => {
    setChecked(true);
    application.onLoadedInSystem({ currentUser, onSuccessCallback });
  };

  return (
    <FixedContent>
      <div className={commonStyles.reviewed_container}>
        <FormControlLabel
          classes={{
            label: commonStyles.review_checkbox_label,
            root: commonStyles.review_checkbox_root,
          }}
          control={
            <LoadedInSystemCheckbox
              isLoadedInSystem={application.isLoadedInSystem || checked}
              onLoadedInSystem={onLoadedInSystem}
            />
          }
          disabled={
            application.isLoadedInSystem || application.isLoading || checked
          }
          label="Is this form loaded in system?"
          labelPlacement="start"
        />
        <LoadedInSystemBy application={application} />
      </div>
    </FixedContent>
  );
}

function Details(props) {
  const { application, router } = props;
  const authorisationHistories = application.authorisationHistories || [];
  const authorisations = application.authorisations || [];
  const cardholderAuthorisations = application.cardholderAuthorisations || [];

  const onClickCloseButton = () => router.push('/dashboard/card_orders');
  const cardholders = cardholderAuthorisations.map((authorisation, i) => (
    <Cardholder
      key={`cardholder-${i + 1}`}
      index={i + 1}
      application={application}
      authorisation={authorisation}
    />
  ));

  const contents = (
    <Fragment>
      <CloseButton
        handleClick={onClickCloseButton}
        style={{ right: 10, top: 10, zIndex: 15000 }}
      />
      <CardOrderDetails {...props} />
      {cardholders}
      <IdentificationChecks authorisations={authorisations} {...props} />
      <Review {...props} />
    </Fragment>
  );

  if (authorisationHistories.length > 0) {
    return (
      <ContentWithFooter
        withBodyRightPadding={false}
        withBottomPadding={true}
        withLeftPadding={true}
        footer={<RecordHistory histories={authorisationHistories} />}
      >
        <div
          className={`${commonStyles.details_container} ${styles.details_container_with_footer}`}
        >
          {contents}
        </div>
      </ContentWithFooter>
    );
  }

  return (
    <ScrollableContent withRightPadding={false} withWhiteBackground={true}>
      <div
        className={`${commonStyles.details_container} ${styles.details_container}`}
      >
        {contents}
      </div>
    </ScrollableContent>
  );
}

function VisibleComponent(props) {
  const { visibleSection, selectedNoteCategory, setSelectedNoteCategory } =
    props;

  if (visibleSection === 'show') {
    return <Details {...props} />;
  }

  if (visibleSection === 'delete_archive') {
    return <DeleteArchive {...props} />;
  }

  if (visibleSection === 'notes') {
    return (
      <Notes
        selectedNoteCategory={selectedNoteCategory}
        setSelectedNoteCategory={setSelectedNoteCategory}
        className={commonStyles.details_container}
        {...props}
      />
    );
  }
}

function Navigation(props) {
  const { meta, router } = props;
  const next = meta.next;

  if (isBlank(next)) {
    return null;
  }

  const path = `/dashboard/card_orders/${next}`;

  const onClick = (event) => {
    event.preventDefault();
    router.push(path);
  };

  return (
    <div className={commonStyles.navigations}>
      <Button href={path} text="Next" onClick={onClick} />
    </div>
  );
}

function CardOrdersShow(props) {
  const { dispatch, task } = props;

  const {
    application,
    meta,
    onFetchModuleCardholderApplication,
    onRefreshModuleCardholderApplicationState,
  } = useApplicationState(props);
  const [visibleSection, setVisibleSection] = useState('show');
  const { modal, onShowImageModal } = useImageModalState();
  const [selectedNoteCategory, setSelectedNoteCategory] = useState('customer');

  useEffect(() => {
    onFetchModuleCardholderApplication();
  }, [get(props, 'params.id')]);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={commonStyles.section}>
        <div className={commonStyles.content}>
          <Topbar
            application={application}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
          />
          <VisibleComponent
            application={application}
            visibleSection={visibleSection}
            setVisibleSection={setVisibleSection}
            onFetchModuleCardholderApplication={
              onFetchModuleCardholderApplication
            }
            onRefreshModuleCardholderApplicationState={
              onRefreshModuleCardholderApplicationState
            }
            onShowImageModal={onShowImageModal}
            selectedNoteCategory={selectedNoteCategory}
            setSelectedNoteCategory={setSelectedNoteCategory}
            meta={meta}
            {...props}
          />
          {visibleSection === 'show' && (
            <Navigation meta={meta} router={props.router} />
          )}
        </div>
        {modal}
      </div>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(CardOrdersShow);
