/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/* Import actions */
import { setValue } from '../actions';

/* Import CSS */
import styles from './css/AutoDecisioning.css';
/* Import components */
import Checkbox from 'modules/shared/components/inputs/Checkbox';

var OptionsSection = createClass({
  render: function() {
    const {
      options,
      loading,
      rule_set,
      selected,
      info_text,
      title,
      expand_on,
      children,
      id,
      dispatch,
      error,
    } = this.props;
    return (
      <div className={styles.expandable}>
        <h3>{title}</h3>
        <div>
          {error && <div className={styles.error}>{error}</div>}
          <div className={styles.info}>{info_text}</div>
          <div className={styles.checkboxes}>
            {options.map((option, i) => {
              return (
                <Checkbox
                  key={option.id}
                  checkboxId={`${option.id}${id}`}
                  checked={selected === option.id}
                  handleChange={(e) => {
                    dispatch(setValue(id, option.id));
                  }}
                  label={option.label}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  },
});

var defaults = {
  expand_on: true,
};

module.exports = connect((state, ownProps) => {
  return {
    ...defaults,
    ...ownProps,
    id: ownProps.id,
    rule_set: ownProps.rule_set,
    children: ownProps.children,
    title: ownProps.title || '',
    options: ownProps.options || [],
    info_text: ownProps.info_text,
    selected: state.auto_decisions.current_rule_set.attributes[ownProps.id],
    current_entity: state.current_user.current_entity
      ? state.current_user.current_entity.attributes
      : null,
  };
})(OptionsSection);
