import React, { useEffect, useRef, useState, ReactElement } from 'react';
import { useTable, usePagination } from 'react-table-7';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import debounce from 'debounce';
import get from 'lodash.get';

import './table.scss';
import { Skeleton } from '@material-ui/lab';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';

const MINIMUM_PER_PAGE = 25;
const MAXIMUM_PER_PAGE = 50;
const DEBOUNCE_RATE = 500;

interface IColumn {
  Header: string;
  accessor: string;
  disableFilters?: boolean;
  filter?: string;
}

interface ITableProps {
  columns: IColumn[];
  data: any[];
  totalRecords?: number;
  fetchData?: (tableData: any) => void;
  loading?: boolean;
  perPage?: number[];
  toolbarComponents?: ReactElement | ReactElement[];
  dataLabel?: string;
  withUiFilter?: boolean;
  withBackendFilter?: boolean;
  withPagination?: boolean;
  hidePageSizeControl?: boolean;
}

const Table = ({
  dataLabel,
  columns,
  data,
  fetchData,
  loading,
  totalRecords,
  perPage,
  withUiFilter,
  withBackendFilter,
  toolbarComponents,
  withPagination,
  hidePageSizeControl,
}: ITableProps) => {
  if (!columns || !data) return null;

  const [currentPageSize, setCurrentPageSize] = useState(MINIMUM_PER_PAGE);

  const getTotalPageCount = () =>
    Math.ceil((totalRecords || MINIMUM_PER_PAGE) / currentPageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25 },
      manualPagination: true,
      pageCount: getTotalPageCount(),
    },
    usePagination
  );

  const [beSearchFilter, setBEsearchFilter] = useState('');

  useEffect(() => {
    setCurrentPageSize(pageSize);

    if (fetchData) {
      fetchData({ pageIndex, pageSize, searchFilter: beSearchFilter });
    }
  }, [fetchData, pageIndex, pageSize]);

  const fetchDataByKeyword = (keyword) => {
    if (fetchData) {
      fetchData({ searchFilter: keyword, pageIndex: 0, pageSize });
      gotoPage(0);
    }
  };

  const debouncedFetchDataByKeyword = useRef(
    debounce(fetchDataByKeyword, DEBOUNCE_RATE)
  ).current;

  const onSearch = (event) => {
    const keyword = get(event, 'target.value', '');
    setBEsearchFilter(keyword);

    debouncedFetchDataByKeyword(keyword);
  };

  const tableFilters = [] as any;
  const generatePaginationArray = (set = 3) => {
    const initialArray = Array.from(Array(pageCount || 1).keys());
    if (pageCount < set) return initialArray;

    const firstSet = initialArray.slice(0, set);
    const lastSet = initialArray.slice(
      initialArray.length - set,
      initialArray.length
    );

    if (
      firstSet.includes(pageIndex) &&
      pageIndex !== firstSet[firstSet.length - 1]
    ) {
      return firstSet as any[];
    }

    if (lastSet.includes(pageIndex) && pageIndex !== lastSet[0]) {
      return lastSet as any[];
    }

    return initialArray.slice(pageIndex - 1, pageIndex + 2) as any[];
  };

  const fillFilter = (filterComponent, accessor) => {
    tableFilters.push(
      <div key={accessor} className="column-filter">
        {filterComponent}
      </div>
    );

    return null;
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    gotoPage(0);
  };

  return (
    <div className="table-container">
      <div className="table-toolbar">
        {withUiFilter && tableFilters}
        {withBackendFilter && (
          <div key="backend-filter" className="table-search">
            <BorderedTextField
              value={beSearchFilter || ''}
              onChange={onSearch}
              label="Search user"
              key="backend-search-field"
            />
          </div>
        )}
        {toolbarComponents}
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={`${headerGroup}-${column}`}
                  {...column.getHeaderProps()}
                >
                  {column.canFilter
                    ? fillFilter(column.render('Filter'), column.accessor)
                    : null}
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading &&
            arrayFromNumber(10, (rows) => (
              <tr key={`${dataLabel}-skeleton-${rows}`}>
                {arrayFromNumber(columns.length, (cols) => (
                  <td key={`${dataLabel}-skeleton-${rows}-${cols}`}>
                    <Skeleton
                      key={`${dataLabel}-list-skeleton-${rows}-${cols}`}
                      animation="wave"
                      height={25}
                      variant="rect"
                    />
                  </td>
                ))}
              </tr>
            ))}
          {!loading &&
            page.map((row, i) => {
              prepareRow(row);
              const RowComponentRender = row.original.RowComponent
                ? row.original.RowComponent
                : (props) => <tr>{props.children}</tr>;

              return (
                <RowComponentRender key={i} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td key={cell} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </RowComponentRender>
              );
            })}
        </tbody>
      </table>
      {withPagination && (
        <div
          className={
            'table-pagination' +
            `${hidePageSizeControl ? ' table-pagination_pagination-only' : ''}`
          }
        >
          {!hidePageSizeControl && (
            <div>
              {perPage!.map((number, index) => {
                return (
                  <>
                    {index !== 0 && ' / '}
                    <span
                      className={
                        'table-pagesize-selector' +
                        (number === pageSize ? ' active' : '')
                      }
                      onClick={() => handlePageSizeChange(number)}
                      key={`${number}-pagesize-btn`}
                    >
                      {number === pageSize
                        ? `${number} ${dataLabel}`
                        : `View ${number} per page`}
                    </span>
                  </>
                );
              })}
            </div>
          )}
          <div className="table-pagination-controls">
            {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button> */}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>
            {generatePaginationArray().map((index) => (
              <span
                key={index}
                className={
                  'span-as-link' + (index === pageIndex ? ' active' : '')
                }
                onClick={() => gotoPage(index)}
              >
                {index + 1}
              </span>
            ))}

            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>
            {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  dataLabel: 'records',
  perPage: [MINIMUM_PER_PAGE, MAXIMUM_PER_PAGE],
  withPagination: true,
};

export default Table;
