import get from 'lodash.get';

const PERMISSION_ALLOW_OVERRIDE = 'm';

const getCanSetAccountRules = ({
  applicationType,
  currentEntity,
  currentUserProfile,
}) => {
  if (applicationType !== 'credit') {
    return false;
  }

  if (
    get(currentUserProfile, 'attributes.permissions', '') !==
    PERMISSION_ALLOW_OVERRIDE
  ) {
    return false;
  }

  const requiresCreditCheck =
    get(currentEntity, 'attributes.requires_credit_check') || false;
  const requiresGuarantees =
    get(currentEntity, 'attributes.requires_guarantees') || false;
  const requiresTradeReferences =
    (get(currentEntity, 'attributes.minimum_trade_references') || 0) > 0;

  return requiresCreditCheck || requiresGuarantees || requiresTradeReferences;
};

export default getCanSetAccountRules;
