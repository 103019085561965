import axios from './axios';

let TradeReferenceChecks = function(config) {
  this.axios = axios(config);
  this.type = 'trade_reference_checks';
};

TradeReferenceChecks.prototype.createCheck = function(
  tradeReferenceId,
  attributes,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
        relationships: {
          trade_reference: {
            data: {
              type: 'trade_references',
              id: tradeReferenceId,
            },
          },
        },
      },
    })
    .then(success)
    .catch(error);
};

TradeReferenceChecks.prototype.getNotes = function(id, success, error) {
  return this.axios
    .get(`/${this.type}/${id}/notes`)
    .then(success)
    .catch(error);
};

TradeReferenceChecks.prototype.updateCheck = function(
  id,
  attributes,
  success,
  error
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default TradeReferenceChecks;
