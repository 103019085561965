import 'css/bulma-custom.scss';

import * as React from 'react';
import { ProgressPanel } from '../SectionDrawer/ProgressPanel';
import { HamburgerButtonDrawer } from './styles';

export const MobileProgressDrawer = (props) => {
  const { activeButton, currentSection, entityType, progressSections } = props;

  const isOpen = activeButton != false;

  return (
    <HamburgerButtonDrawer open={isOpen} variant="temporary" anchor="right">
      <ProgressPanel
        currentSection={currentSection}
        entityType={entityType}
        progressSections={progressSections}
      ></ProgressPanel>
    </HamburgerButtonDrawer>
  );
};
