import get from 'lodash.get';
import {
  addApplicantCardholder,
  addCardholders,
  removeApplicantCardholder,
} from 'modules/consumer-onboarding/actions/cards';
import { MAX_CARDS } from 'modules/consumer-onboarding/constants/cards';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCardsAddonConfig, getCardsAddonRule } from 'utils/cardsAddon';
import isBlank from 'utils/isBlank';
import isInteger from 'utils/isInteger';
import isPresent from 'utils/isPresent';

import { getCardholderDetailsErrorMessages } from '../CardholderFormWrapper';
import CardsDetails from '../CardsDetails';
import styles from '../css/Cards';
import isSignatureRequired from '../helpers/isSignatureRequired';
import { Subtitle, Wrapper } from './styles';

const convertRadioValue = (value) => {
  if (typeof value === 'undefined') {
    return null;
  }

  return value ? 'Yes' : 'No';
};

const getCardholdersAreValid = (cardholders, config) => {
  if (cardholders.length === 0 && !config.mandatory) {
    return true;
  }

  return cardholders.every((cardholder) => {
    const errorMessages = getCardholderDetailsErrorMessages(
      cardholder,
      cardholders,
      config
    );
    return Object.keys(errorMessages).every((key) => errorMessages[key] === '');
  });
};

function getCardholdersCountErrorMessage(value, isMandatory) {
  if (isMandatory && (!isInteger(value) || value <= 0)) {
    return 'Please enter a valid number for cards requested';
  }

  return '';
}

class CardOrderForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      addonsQuestionCompleted,
      cardholders,
      config,
      handleComplete,
      isPreview,
      page_validation_start: pageValidationStart,
      setPageValidationStartFinish,
    } = nextProps;

    if (isPreview) {
      return;
    }

    const cardholdersNoErrors = getCardholdersAreValid(cardholders, config);

    const formErrors = { ...prevState.formErrors };

    const cardholdersCountErrorMessage = getCardholdersCountErrorMessage(
      cardholders.length,
      config.mandatory
    );
    formErrors['cardholdersCount'] = cardholdersCountErrorMessage;

    if (
      config.signatureRequired &&
      config.mandatory &&
      typeof prevState.cardholderApplicant === 'undefined'
    ) {
      formErrors['cardholderApplicant'] = 'Please select Yes or No';
    }

    const sectionHasNoErrors =
      isBlank(cardholdersCountErrorMessage) &&
      cardholdersNoErrors &&
      addonsQuestionCompleted;

    handleComplete(sectionHasNoErrors, 'cards');

    if (pageValidationStart) {
      return { formErrors, triggerValidation: true };
    }

    setPageValidationStartFinish();

    return { formErrors: prevState.formErrors };
  }

  static isComplete(formErrors) {
    const hasErrors = Object.values(formErrors).some(
      (error) => error.length > 0
    );

    return !hasErrors;
  }

  constructor(props) {
    super(props);

    this.state = {
      cardholderApplicant: this.cardholderApplicant(),
      formErrors: {
        cardholdersCount: '',
      },
    };

    this.addCardHoldersCount = this.addCardHoldersCount.bind(this);
  }

  componentDidMount() {
    const { cardholders, config } = this.props;

    if (cardholders.length === 0 && config.mandatory) {
      this.addCardHoldersCount();
    }
  }

  addCardHoldersCount() {
    const { config, dispatch, cardholders } = this.props;
    dispatch(
      addCardholders(
        this.cardholderApplicant()
          ? cardholders.length
            ? cardholders.length
            : 1
          : cardholders.length + 1,
        config
      )
    );
  }

  onHandleChangeValue(key, event) {
    this.props.onHandleChangeValue(key, event.target.value);
  }

  onHandleChangeIsApplicant(event) {
    const value = event.target.value;
    const { dispatch, guarantorApplicant, signatoryApplicant } = this.props;

    if (value === 'Yes') {
      const targetApplicant = guarantorApplicant || signatoryApplicant;
      const fieldsToMap = {
        email: 'email',
        first_name: 'firstName',
        last_name: 'lastName',
        middle_name: 'middleName',
      };

      const applicantAttributes = {};
      if (targetApplicant && targetApplicant.is_applicant) {
        Object.keys(targetApplicant).forEach((key) => {
          if (fieldsToMap[key]) {
            applicantAttributes[fieldsToMap[key]] = targetApplicant[key];
          }
        });
      }
      dispatch(addApplicantCardholder(applicantAttributes));
      this.setState({ cardholderApplicant: true });
    }

    if (value === 'No') {
      dispatch(removeApplicantCardholder());
      this.setState({ cardholderApplicant: false });
    }
  }

  isSignatoryApplicant() {
    const { guarantorApplicant, signatoryApplicant } = this.props;
    const targetApplicant = guarantorApplicant || signatoryApplicant;

    return targetApplicant ? targetApplicant.is_applicant : false;
  }

  onHandleBlur() {
    this.validateCardholderCountChange();
  }

  cardholderApplicant = () => {
    const { cardholders } = this.props;

    if (!cardholders || cardholders.length === 0) {
      return;
    }

    return cardholders.find(({ attributes }) => attributes.isApplicant);
  };

  otherCardholders() {
    const { cardholders } = this.props;
    return cardholders.filter(({ attributes }) => !attributes.isApplicant);
  }

  validateCardholderCountChange() {
    const { config } = this.props;

    if (this.cardholderApplicant()) {
      return this.setState({
        formErrors: {
          ...this.state.formErrors,
          cardholdersCount: '',
        },
      });
    }

    const cardholdersCountErrorMessage = getCardholdersCountErrorMessage(
      this.otherCardholders().length,
      config.mandatory
    );

    this.setState({
      formErrors: {
        ...this.state.formErrors,
        cardholdersCount: cardholdersCountErrorMessage,
      },
    });
  }

  render() {
    const { application, config, cardholders } = this.props;
    const { formErrors, triggerValidation } = this.state;
    const cardholderApplicant = this.cardholderApplicant();
    const otherCardholders = this.otherCardholders();
    const maxCardsReached = cardholders.length === MAX_CARDS;

    return (
      <Wrapper className={styles.section}>
        <div className={styles.row}>
          <div className={styles.full_width}>
            <PanelTitle text="Cards" margin_bottom="1rem" />
            <p
              style={{ marginBottom: '2rem' }}
            >{`You can order cards for yourself and other people. Up to ${MAX_CARDS} cards can be ordered.`}</p>
          </div>
        </div>
        {config.signatureRequired && (
          <div className={styles.row}>
            <div className={styles.half_width}>
              <p>Are you ordering a card for yourself?</p>
              <Radiobox
                id="isApplicant"
                name="isApplicant"
                radioList={['Yes', 'No']}
                value={convertRadioValue(this.state.cardholderApplicant)}
                handleChange={this.onHandleChangeIsApplicant.bind(this)}
                error={formErrors.cardholderApplicant}
                disabled={
                  get(application, 'attributes.submission_status', '') ===
                    'complete' ||
                  (maxCardsReached && !cardholderApplicant)
                }
                helper_text={
                  maxCardsReached && !cardholderApplicant
                    ? 'Please remove 1 card holder'
                    : undefined
                }
              />
            </div>
          </div>
        )}
        {cardholderApplicant && (
          <CardsDetails
            key={cardholderApplicant.attributes.id || 'cardholder-applicant'}
            cardholder={cardholderApplicant}
            isSignatoryApplicant={this.isSignatoryApplicant()}
            {...this.props}
            triggerValidation={triggerValidation}
            index={0}
            title="Card 1"
          />
        )}
        {otherCardholders.map((cardholder, index) => (
          <CardsDetails
            key={cardholder.attributes.id || `cardholder-${index}`}
            cardholder={cardholder}
            displayDeleteBtn
            {...this.props}
            triggerValidation={triggerValidation}
            title={`Card ${index + (cardholderApplicant ? 2 : 1)}`}
            onHandleChangeValue={this.onHandleChangeValue}
            index={cardholderApplicant ? index + 1 : index}
          />
        ))}
        {!maxCardsReached && (
          <p onClick={this.addCardHoldersCount}>+ Add Card</p>
        )}
      </Wrapper>
    );
  }
}

export default connect((state, ownProps) => {
  const previewAddon = ownProps.previewAddon;
  const sharedData = state.shared_data;
  const guarantors = state.cob_guarantors.form_values || [];
  const signatories = state.cob_business.entity_party_details_values || [];
  const cardholdersAddonQuestionKeys = Object.keys(sharedData).filter((key) =>
    key.includes('cardholder_')
  );
  let config;

  const cobSection = state.cob_section;

  const addonsQuestionCompleted = cardholdersAddonQuestionKeys.every((key) =>
    sharedData[key].flat().every((status) => status)
  );

  if (isPresent(previewAddon)) {
    config = previewAddon.config;
  } else {
    const addonRule = getCardsAddonRule(cobSection.addonRules);
    config = getCardsAddonConfig(addonRule);
  }

  const guarantorApplicant = guarantors.find(
    (guarantor) => guarantor.is_applicant
  );
  const signatoryApplicant = signatories.find(
    (signatory) => signatory.is_applicant
  );

  const configSignatureRequired = get(config, 'signature_required') === 'on';
  const signatureRequired = isSignatureRequired(state, configSignatureRequired);

  return {
    addonsQuestionCompleted,
    cardholders: state.cob_cards.cardholders,
    cardholdersCount:
      state.cob_section.application.attributes.cardholders_count,
    config: {
      ...config,
      cardLimitEnabled: config.card_limit_enabled === 'on',
      signatureRequired,
    },
    guarantorApplicant,
    signatoryApplicant,
  };
})(CardOrderForm);
