import { IRuleSetAttributes } from '../type';
import { processFinancialAddons } from '../utils';

export const getFinancialTabPauseReasons = (
  rulesetAttributes: IRuleSetAttributes,
  financialAddons: any
) => {
  const {
    financials_check: isFinancialsRequiredInRuleset,
    financials_status: status,
  } = rulesetAttributes;

  if (status !== 'paused') {
    return {
      financial: false,
    };
  }

  // Find out whether financials are applicable for this ruleset or not.
  const isFinancialsApplicable = processFinancialAddons(
    financialAddons,
    rulesetAttributes
  ).isFinancialsEditable;

  // If financial checks are required in the auto decision ruleset,
  // check if financials are in fact applicable. If they are not applicable,
  // this means that a change in financial addons paused the ruleset.
  const financial = isFinancialsRequiredInRuleset
    ? !isFinancialsApplicable
    : false;

  return {
    financial,
  };
};
