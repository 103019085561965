import api from 'api';
import GoogleMap from 'google-map-react';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import useParamsCompare from '../../hooks/useParamsCompare';
import useRequestQuery from '../../hooks/useRequestQuery';
import { Props } from '../Panel/model';
import styles from './ChannelMapChart.css';
import { ErrorState } from './ErrorState';

const NZ_CENTRE = [-32.5877, 154.3306];
const DEFAULT_ZOOM = 4;

const CHANNEL_MARKER_COLOR = {
  Location: '#ff6337',
  Rep: '#ffb800',
  Website: '#00afef',
};

const Marker: React.FC<{
  lng?: number;
  lat?: number;
  color: string;
  isLegend?: boolean;
}> = ({ color, isLegend = false }) => (
  <div
    className={isLegend ? styles.markerLegend : styles.marker}
    style={{ backgroundColor: color }}
  />
);

export const ChannelMapChart: React.FC<Props> = ({
  accessToken,
  entityId,
  filterState,
}) => {
  const [apiLoaded, setApiLoaded] = React.useState(false);

  const apiAction = () =>
    api('reporting', accessToken, entityId).get({
      api: 'omni_channel_distribution',
      params: filterState,
    });
  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const paramString = JSON.stringify(filterState);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [filterState]);

  if (loading && !error) {
    return <Skeleton count={5} />;
  }

  if (!loading && error) {
    return <ErrorState refresh={forceUpdate} />;
  }

  return (
    <React.Fragment>
      <div className={styles.legendWrapper}>
        <span className={styles.legend}>
          <Marker color={CHANNEL_MARKER_COLOR.Website} isLegend /> Website
        </span>
        <span className={styles.legend}>
          <Marker color={CHANNEL_MARKER_COLOR.Rep} isLegend /> Rep
        </span>
        <span className={styles.legend}>
          <Marker color={CHANNEL_MARKER_COLOR.Location} isLegend /> Location
        </span>
      </div>
      <GoogleMap
        defaultCenter={NZ_CENTRE}
        defaultZoom={DEFAULT_ZOOM}
        bootstrapURLKeys={{
          key: window._env_.GOOGLE_CLIENT,
        }}
        style={{
          height: '100%',
          margin: 0,
          minHeight: '45rem',
          padding: 0,
          position: 'relative',
          width: '100%',
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={() => setApiLoaded(true)}
        options={() => ({
          styles: [
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        })}
      >
        {apiLoaded && !loading && !error && data.length > 0
          ? data.map(({ channel, latitude, longitude }, i) => (
              <Marker
                key={i + channel}
                lat={latitude}
                lng={longitude}
                color={CHANNEL_MARKER_COLOR[channel]}
              />
            ))
          : null}
      </GoogleMap>
    </React.Fragment>
  );
};
