import axios from './axios';

export default class EmailActivities {
  constructor(config) {
    this.axios = axios(config);
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
  }

  get(applicationId) {
    return this.axios.get(`/applications/${applicationId}/email_activities`, {
      params: { all_versions: true },
    });
  }
}
