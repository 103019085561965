import { Typography } from '@material-ui/core';
import Dialog from 'modules/shared/components/v2/Dialog';
import React from 'react';

type ConfirmRemoveGroupModal = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmTurnOn = (props: ConfirmRemoveGroupModal) => {
  const { isOpen, onConfirm, onCancel } = props;

  return (
    <Dialog
      title="Turn on Customer View"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      cancelButtonProps={{ white: true }}
    >
      <Typography>Please confirm to turn on 1Account Customer View.</Typography>
      <Typography>
        This structure will be displayed to your customers in
      </Typography>
      <Typography>
        the applications, to assist with their store selection.
      </Typography>
    </Dialog>
  );
};
