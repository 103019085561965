import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './css/Bye.css';

import Button from 'modules/shared/components/inputs/Button';
import SectionTitle from 'modules/shared/components/widgets/static/SectionTitle';
import SectionBadge from 'modules/shared/components/widgets/static/SectionBadge';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import WorkflowOverlay from 'modules/shared/components/top/WorkflowOverlay';

var Bye = createClass({
  render: function() {
    const { dispatch, section_title } = this.props;

    return (
      <section className={styles.section}>
        <main className={styles.centered}>
          <div>
            <div className={styles.row}>
              <div className={styles.panel}>
                <SectionTitle white={true} text={section_title} />
                <SectionBadge icon="sad_card" state="section_complete" />
              </div>
            </div>
            <div className={styles.description_row}>
              <div className={styles.description}>
                <SectionDescription
                  text={`1Centre customer support will be in touch soon to discuss the closure of your account.`}
                />
                <div className={styles.button_outer}>
                  <Button
                    css_style="button_white_outer"
                    text="OK"
                    handleClick={(e) => {
                      this.props.hide();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    );
  },
});

var defaults = {
  section_title: 'Sorry to see you go!',
};

module.exports = connect((state, ownProps) => {
  return {
    section_title: defaults.section_title,
  };
})(Bye);
