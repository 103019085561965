import React from 'react';
import styles from './report-styles.css';
import { formatDate } from 'utils/dateFormatter';

const formatTime = (data) => {
  let hm = data.split(':');
  return `${hm[0]}:${hm[1]}`;
};

const renderCurrDDetails = (directorship) => {
  if (directorship.length < 1)
    return <p className="mt-2">Information Unavailable</p>;

  return directorship.map((presentDirectorship, index) => (
    <div key={index}>
      <h6 className="my-4 has-text-weight-semibold">
        {!presentDirectorship['organisation']
          ? '-'
          : presentDirectorship['organisation'].name}
      </h6>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_8}>Data</div>
          <div className={styles.th + ' ' + styles.col_4}>Detail</div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>Company Status</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['organisation']
              ? '-'
              : presentDirectorship['organisation'].status}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Australian Company Number
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['organisation']
              ? '-'
              : presentDirectorship['organisation'].organisation_number}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Australian Business Number
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['organisation']
              ? '-'
              : presentDirectorship['organisation'].abn}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>Appointment date</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['date_appointed']
              ? '-'
              : formatDate(
                  presentDirectorship['date_appointed'],
                  'DD MMM YYYY'
                )}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>File Number</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['organisation']
              ? '-'
              : presentDirectorship['organisation'].bureau_reference}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            ASIC extract date &amp; time (1)
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['@last_extract_date']
              ? '-'
              : `${formatDate(
                  presentDirectorship['@last_extract_date'],
                  'DD MMM YYYY'
                )}`}
            {!presentDirectorship['@last_extract_time']
              ? '-'
              : `- ${formatTime(presentDirectorship['@last_extract_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Last ASIC update date &amp; time (2)
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!presentDirectorship['@last_update_date']
              ? '-'
              : `${formatDate(
                  presentDirectorship['@last_update_date'],
                  'DD MMM YYYY'
                )}`}
            {!presentDirectorship['@last_update_time']
              ? '-'
              : `- ${formatTime(presentDirectorship['@last_update_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>ID Source</div>
          <div className={styles.td + ' ' + styles.col_4}>P</div>
        </div>
      </div>
      <div className="mt-2 is-size-6">
        <p>
          1. Details were sourced from ASIC's Business Names Record on the give
          date & time.
        </p>
        <p>
          2. Updates to the Business Name, Business Registration Number &
          Business Registration State sources from ASIC on the given date &
          time.
        </p>
      </div>
    </div>
  ));
};

const renderPrevDDetails = (directorship) => {
  if (directorship.length < 1)
    return <p className="mt-2">Information Unavailable</p>;
  return directorship.map((prevDirectorship, index) => (
    <div key={index}>
      <h6 className="my-4 has-text-weight-semibold">
        {!prevDirectorship['organisation']
          ? '-'
          : prevDirectorship['organisation'].name}
      </h6>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_8}>Data</div>
          <div className={styles.th + ' ' + styles.col_4}>Detail</div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Australian Company Number
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['organisation']
              ? '-'
              : prevDirectorship['organisation'].organisation_number}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Australian Business Number
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['organisation']
              ? '-'
              : prevDirectorship['organisation'].abn}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>Appointment date</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['date_appointed']
              ? '-'
              : formatDate(prevDirectorship['date_appointed'], 'DD MMM YYYY')}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>Cease date</div>
          <div className={styles.td + ' ' + styles.col_4}></div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Last Known As Director
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['date_last_known_as_director']
              ? '-'
              : formatDate(
                  prevDirectorship['date_last_known_as_director'],
                  'DD MMM YYYY'
                )}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>File Number</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['organisation']
              ? '-'
              : prevDirectorship['organisation'].bureau_reference}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            ASIC extract date &amp; time (1)
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['@last_extract_date']
              ? '-'
              : `${formatDate(
                  prevDirectorship['@last_extract_date'],
                  'DD MMM YYYY'
                )}`}
            {!prevDirectorship['@last_extract_time']
              ? '-'
              : `- ${formatTime(prevDirectorship['@last_extract_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Last ASIC update date &amp; time (2)
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!prevDirectorship['@last_update_date']
              ? '-'
              : `${formatDate(
                  prevDirectorship['@last_update_date'],
                  'DD MMM YYYY'
                )}`}
            {!prevDirectorship['@last_update_time']
              ? '-'
              : `- ${formatTime(prevDirectorship['@last_update_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>ID Source</div>
          <div className={styles.td + ' ' + styles.col_4}>P</div>
        </div>
      </div>
      <div className="mt-2 is-size-6">
        <p>
          1. Details were sourced from ASIC's Business Names Record on the give
          date & time.
        </p>
        <p>
          2. Updates to the Organisation Name, Company Status, ACN & ABN sources
          from ASIC on the given date & time.
        </p>
      </div>
    </div>
  ));
};

const renderDisqDDetails = (disqualifiedDirectorship) => {
  if (disqualifiedDirectorship.length < 1)
    return <p className="mt-2">Information Unavailable</p>;

  return disqualifiedDirectorship.map((disqDirectorship, index) => (
    <div key={index}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_8}>Data</div>
          <div className={styles.th + ' ' + styles.col_4}>Detail</div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Disqualified date
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {formatDate(disqDirectorship.date_disqualified, 'DD MMM YYYY')}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Disqualified until date
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {formatDate(
              disqDirectorship.date_disqualified_until,
              'DD MMM YYYY'
            )}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            ASIC extract date & time
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!disqDirectorship['@last_extract_date']
              ? '-'
              : `${formatDate(
                  disqDirectorship['@last_extract_date'],
                  'DD MMM YYYY'
                )}`}
            {!disqDirectorship['@last_extract_time']
              ? '-'
              : `- ${formatTime(disqDirectorship['@last_extract_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>ID source</div>
          <div className={styles.td + ' ' + styles.col_4}>P</div>
        </div>
      </div>
      <div className="mt-2 is-size-6">
        <p>
          1. Disqualified Director details were obtained from ASIC's system
          under Section 3 Sub 243 on the given date & time.
        </p>
      </div>
    </div>
  ));
};

const renderBusinessDetails = (businessName) => {
  if (businessName.length < 1)
    return <p className="mt-2">Not present on file</p>;

  return businessName.map((businessData, index) => (
    <div key={index}>
      <h6 className="my-4 has-text-weight-semibold">
        {!businessData['business'] ? '-' : businessData['business'].name}
      </h6>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_8}>Data</div>
          <div className={styles.th + ' ' + styles.col_4}>Detail</div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Australian Business Number
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['business'].abn ? '-' : businessData['business'].abn}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Business Registration Number
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['business'].previous_state_or_territory_registration
              ? '-'
              : businessData['business']
                  .previous_state_or_territory_registration.number}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Registration State
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['business'].previous_state_or_territory_registration
              ? '-'
              : businessData['business']
                  .previous_state_or_territory_registration.state}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Registration Date
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['start_date']
              ? '-'
              : `${formatDate(businessData['start_date'], 'DD MMM YYYY')}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>File Number</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['business']
              ? '-'
              : businessData['business'].bureau_reference}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            ASIC extract date &amp; time (1)
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['@last_extract_date']
              ? '-'
              : `${formatDate(
                  businessData['@last_extract_date'],
                  'DD MMM YYYY'
                )}`}
            {!businessData['@last_extract_time']
              ? '-'
              : `- ${formatTime(businessData['@last_extract_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>
            Last ASIC update date &amp; time (2)
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!businessData['@last_update_date']
              ? '-'
              : `${formatDate(
                  businessData['@last_update_date'],
                  'DD MMM YYYY'
                )}`}
            {!businessData['@last_update_time']
              ? '-'
              : `- ${formatTime(businessData['@last_update_time'])}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_8}>ID Source</div>
          <div className={styles.td + ' ' + styles.col_4}>P</div>
        </div>
      </div>
      <div className="mt-2 is-size-6">
        <p>
          1. Details were sourced from ASIC's Business Names Record on the given
          date & time.
        </p>
        <p>
          2. Updates to the Business Name, Business Registration Number &
          Business Registration State sourced from ASIC on the given date &
          time.
        </p>
      </div>
    </div>
  ));
};

const ReportDictatorship = ({
  characteristic,
  directorship,
  businessName,
  disqualifiedDirectorship,
}) => {
  let businessNames =
      characteristic.find((obj) => obj['@id'] === 'NP8508') || {},
    currentDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8500') || {},
    previousDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8509') || {},
    disqDirectorships =
      characteristic.find((obj) => obj['@id'] === 'NP8503') || {};

  if (!Array.isArray(directorship)) {
    directorship = [directorship];
  }

  if (!Array.isArray(businessName)) {
    businessName = [businessName];
  }

  if (!Array.isArray(disqualifiedDirectorship)) {
    disqualifiedDirectorship = [disqualifiedDirectorship];
  }

  const getCurrentDirectorships = () => {
    return directorship.filter(
      (directorshipDetails) => !directorshipDetails.date_last_known_as_director
    );
  };

  const getPreviousDirectorships = () => {
    return directorship.filter(
      (directorshipDetails) => directorshipDetails.date_last_known_as_director
    );
  };

  const curentDirectorships = getCurrentDirectorships(),
    prevDirectorship = getPreviousDirectorships();

  const currentDirectorshipDetails = curentDirectorships.length
    ? renderCurrDDetails(curentDirectorships)
    : null;
  const prevDirectorshipDetails = prevDirectorship.length
    ? renderPrevDDetails(prevDirectorship)
    : null;

  const disqDictatorshipDetails = disqualifiedDirectorship.length
    ? renderDisqDDetails(disqualifiedDirectorship)
    : null;

  const businessDetails = businessName.length
    ? renderBusinessDetails(businessName)
    : null;

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            Business names
          </label>
        </div>
        <div className={styles.col_4}>
          Total number: &nbsp;
          {!businessNames || businessNames.value < 0
            ? '-'
            : businessNames.value}
        </div>
      </div>
      {businessDetails ? businessDetails : <p>Not present on file</p>}
      <br />
      <div className={styles.row + ' mt-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            Current directorships
          </label>
        </div>
        <div className={styles.col_4}>
          Total number: &nbsp;
          {!currentDirectorships || currentDirectorships.value < 0
            ? '-'
            : currentDirectorships.value}
        </div>
      </div>
      {currentDirectorshipDetails ? (
        currentDirectorshipDetails
      ) : (
        <p>Not present on file</p>
      )}
      <br />
      <div className={styles.row + ' mt-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            Previous directorships
          </label>
        </div>
        <div className={styles.col_4}>
          Total number: &nbsp;
          {!previousDirectorships || previousDirectorships.value < 0
            ? '-'
            : previousDirectorships.value}
        </div>
      </div>
      {prevDirectorshipDetails ? (
        prevDirectorshipDetails
      ) : (
        <p>Not present on file</p>
      )}
      <br />
      <div className={styles.row + ' mt-4 mb-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            Disqualified directorships
          </label>
        </div>
        <div className={styles.col_4}>
          Total number: &nbsp;
          {!disqDirectorships || disqDirectorships.value < 0
            ? '-'
            : disqDirectorships.value}
        </div>
      </div>
      {disqDictatorshipDetails ? (
        disqDictatorshipDetails
      ) : (
        <p>Not present on file</p>
      )}
      <br />
    </div>
  );
};

export default ReportDictatorship;
