import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form-latest';
import HelpIcon from '@material-ui/icons/Help';

import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import isPresent from 'utils/isPresent';
import { onRetrieveDetailsWithCompanyNumber } from '../v2/BusinessOverview/AustraliaBusinessOverview/utils/retrieveDetails';
import styled from 'styled-components';

import { onSetActiveQuestionAndAnswer } from 'modules/shared/components/top/Overlay/redux/actions';
import { COMPANY_NUMBER } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';
import api from 'api';
import { useSnackbar } from 'notistack';
import { processError } from 'utils';
import { resetGuarantors } from 'modules/consumer-onboarding/actions/guarantors';
import { debounce } from 'debounce';

const NZ_COMPANY_NUMBER_LENGTHS = [6, 7];
const DEBOUNCE_DELAY = 1000;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: end;
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: 10px;
`;

type CorporateTrusteeDetailsProps = {
  region: 'AU' | 'NZ';
  companyName: string;
  companyNumber: string;
  handleChange: (event: { target: { name: string; value: string } }) => void;
  companyNumberError?: string;
  companyNameError?: string;
  setCompanyNumberInvalidError: () => void;
  isCorporateTrusteeLocked: boolean;
  dispatch: any;
};

const LABELS = {
  'AU': {
    description: `Please provide the trustee's ACN.`,
    label: 'ACN',
    inputPlaceholder: 'ACN',
  },
  'NZ': {
    description: `Please provide the trustee's NZCN.`,
    label: 'NZCN',
    inputPlaceholder: 'NZCN',
  },
};

export const CorporateTrusteeDetails = (
  props: CorporateTrusteeDetailsProps
) => {
  const {
    region,
    companyName,
    companyNumber,
    handleChange,
    companyNumberError,
    companyNameError,
    setCompanyNumberInvalidError,
    isCorporateTrusteeLocked,
    dispatch,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const regionSpecificLabels = LABELS[region];

  const [isLoading, setLoading] = useState(false);

  const { control, register, setValue, watch } = useForm({
    defaultValues: {
      corporate_trustee_company_number: companyNumber,
      corporate_trustee_company_name: companyName,
    },
  });

  const { corporate_trustee_company_number, corporate_trustee_company_name } =
    watch();

  useEffect(() => {
    register('corporate_trustee_company_number');
    register('corporate_trustee_company_name');
  }, []);

  const onStartEvent = () => setLoading(true);

  const onEndEvent = () => setLoading(false);

  const onSetValuesCallback = ({
    businessNumber,
    companyName,
  }: {
    businessNumber?: string;
    companyName: string;
  }) => {
    setValue('corporate_trustee_company_name', companyName);
    handleChange({
      target: { name: 'corporate_trustee_company_name', value: companyName },
    });
    handleChange({
      target: { name: 'corporate_trustee_id', value: '' },
    });
    dispatch(resetGuarantors());
    if (businessNumber) {
      handleChange({
        target: {
          name: 'corporate_trustee_business_number',
          value: businessNumber,
        },
      });
    }
  };

  const onEmptyResultCallback = () => setCompanyNumberInvalidError();

  const onError = (errorMessage: string) => {
    enqueueSnackbar(errorMessage, { variant: 'error' });
  };

  const auRetrieveDetails = (companyNumber: string) => {
    const fetchOptions = {
      params: {
        acn_status_check_only: true,
      },
    };

    onRetrieveDetailsWithCompanyNumber(
      { accessToken: '' },
      {
        companyNumber,
        onEndEvent,
        onError,
        onSetValuesCallback,
        onStartEvent,
        onEmptyResultCallback,
        fetchOptions,
      }
    );
  };

  const nzRetrieveDetails = (companyNumber) => {
    setLoading(true);
    const companySearchApi = api('company_search');
    companySearchApi.companySearch(
      (response) => {
        const { data } = response.data;
        if (data.length === 0) {
          setCompanyNumberInvalidError();
        }
        const matchingCompany = data.find(
          (company) => company.attributes.company_number === companyNumber
        );
        if (!matchingCompany) {
          setLoading(false);
          return;
        }
        const { business_number, name } = matchingCompany.attributes;
        setValue('corporate_trustee_company_name', name);
        handleChange({
          target: {
            name: 'corporate_trustee_business_number',
            value: business_number,
          },
        });
        handleChange({
          target: {
            name: 'corporate_trustee_company_name',
            value: name,
          },
        });
        handleChange({
          target: {
            name: 'corporate_trustee_id',
            value: '',
          },
        });
        dispatch(resetGuarantors());
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        const { errorMessage } = processError(error);
        onError(errorMessage);
      },
      {
        params: {
          company_number: companyNumber,
          region: 'NZ',
        },
      }
    );
  };

  const debouncedNzRetrieveDetails = useRef(
    debounce(nzRetrieveDetails, DEBOUNCE_DELAY)
  ).current;

  const handleChangeCompanyNumber = (_, value) => {
    handleChange({
      target: { name: 'corporate_trustee_company_number', value },
    });
    handleChange({
      target: { name: 'corporate_trustee_company_name', value: '' },
    });
    setValue('corporate_trustee_company_name', '');
    if (region === 'AU') {
      auRetrieveDetails(value);
    } else if (
      region === 'NZ' &&
      NZ_COMPANY_NUMBER_LENGTHS.includes(value.length)
    ) {
      debouncedNzRetrieveDetails(value);
    }
  };

  const handlClickHelp = () =>
    dispatch(onSetActiveQuestionAndAnswer(COMPANY_NUMBER));

  return (
    <Container>
      <DescriptionContainer>
        <Typography>{regionSpecificLabels.description}</Typography>
        <StyledIconButton onClick={handlClickHelp}>
          <HelpIcon color="primary" fontSize="small" />
        </StyledIconButton>
      </DescriptionContainer>

      <RHFBorderedTextField
        control={control}
        name="corporate_trustee_company_number"
        label={regionSpecificLabels.label}
        labelShrink={isPresent(corporate_trustee_company_number)}
        noWhiteSpaces
        onChange={handleChangeCompanyNumber}
        placeholder={regionSpecificLabels.inputPlaceholder}
        isLoading={isLoading}
        readOnly={isLoading || isCorporateTrusteeLocked}
        error={
          companyNumberError
            ? { message: companyNumberError, type: '' }
            : undefined
        }
      />
      <RHFBorderedTextField
        readOnly
        control={control}
        name="corporate_trustee_company_name"
        label="Trustee name"
        labelShrink={isPresent(corporate_trustee_company_name)}
        placeholder="Company name"
        onChange={handleChange}
        error={
          companyNameError ? { message: companyNameError, type: '' } : undefined
        }
      />
    </Container>
  );
};
