import get from 'lodash.get';
import PpsrFinancingStatementModel from 'models/PpsrFinancingStatementModel';
import { getAlertErrorMessage } from 'modules/new-applications/components/application-actions/PPSRRegister';
import { FinancingStatementPin } from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRegisterForm';
import PPSRRenewForm from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRRenewForm/PPSRRenewForm';
import defaultExpiryDateByRegion, {
  defaultMaxDateByRegion,
} from 'modules/new-applications/components/application-actions/PPSRRegister/shared/defaultExpiryDateByRegion';
import { ExpiryDate } from 'modules/new-applications/components/application-actions/PPSRRegister/shared/RegisterDetails';
import { getRenewFinancingStatementValidationSchema } from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/validationSchema';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import Button from 'modules/shared/components/inputs/Button';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isPresent from 'utils/isPresent';

function RenewExpiredFinancingStatement(props) {
  const {
    application,
    currentUser,
    onClickBackToDetails,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  } = props;

  const region = application.region;
  const expiryDate = defaultExpiryDateByRegion(region);
  const maxDate = defaultMaxDateByRegion(region);
  // let defaultValues = { expiryDuration: ppsrFinancingStatement.expiryDuration };
  let defaultValues = {};

  if (isPresent(expiryDate)) {
    defaultValues = { expiryDate: moment(expiryDate).format('DD/MM/YYYY') };
  }

  const {
    clearError,
    errors,
    handleSubmit,
    register,
    setError,
    setValue,
  } = useForm({
    defaultValues,
    validationSchema: getRenewFinancingStatementValidationSchema(region),
  });

  const { isLoading, setIsLoading } = useIsLoadingState();
  const [selectedDate, setSelectedDate] = useState(expiryDate);
  const onSelectDate = (date) => {
    setSelectedDate(date);
    setValue('expiryDate', date);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    handleSubmit((data) => {
      const { expiryDate, financingStatementPin } = data;

      if (
        financingStatementPin !== ppsrFinancingStatement.financingStatementPin
      ) {
        setError(
          'financingStatementPin',
          'required',
          'Please provide correct financing statement PIN.'
        );
        return;
      }

      setIsLoading(true);

      ppsrFinancingStatement.renew({
        attributes: {
          expiryDate: moment(expiryDate, 'DD/MM/YYYY').toDate(),
        },
        currentUser,
        financingStatementPin,
        onErrorCallback: (error) => {
          onSetAlert({ message: getAlertErrorMessage(error), type: 'error' });

          const responsePPSRFinancingStatement = get(
            error,
            'response.data.ppsr_financing_statement'
          );
          if (responsePPSRFinancingStatement) {
            const newPpsrFinancingStatement = new PpsrFinancingStatementModel(
              responsePPSRFinancingStatement.data,
              responsePPSRFinancingStatement.included
            );
            onSetPPSRFinancingStatement(newPpsrFinancingStatement);
          }

          setIsLoading(false);
        },
        onSuccessCallback: (newPpsrFinancingStatement) => {
          setIsLoading(false);
          onSetPPSRFinancingStatement(newPpsrFinancingStatement);
          onSetAlert({ message: 'Successfully renewed.', type: 'success' });
          onClickBackToDetails();
        },
      });
    })();
  };

  return (
    <form className={styles.renew_form} onSubmit={onSubmit}>
      <PPSRRenewForm
        application={application}
        clearError={clearError}
        errors={errors}
        isLoading={isLoading}
        maxDate={maxDate}
        onSelectDate={onSelectDate}
        ppsrFinancingStatement={ppsrFinancingStatement}
        region={region}
        register={register}
        selectedDate={selectedDate}
        setValue={setValue}
      />
      <FinancingStatementPin
        actionText="renewal"
        errors={errors}
        region={region}
        register={register}
      />
      <div className="field is-grouped">
        <div className="control">
          <Button white text="Back" handleClick={onClickBackToDetails} />
        </div>
        <div className="control">
          <Button
            type="submit"
            text="Renew"
            loading={isLoading}
            disabled={isLoading}
          />
        </div>
      </div>
    </form>
  );
}

export default function PPSRRegisterRenew(props) {
  const {
    application,
    currentUser,
    onClickBackToDetails,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
  } = props;

  return (
    <FixedContent>
      <ExpiryDate ppsrFinancingStatement={ppsrFinancingStatement} />
      <RenewExpiredFinancingStatement
        application={application}
        currentUser={currentUser}
        ppsrFinancingStatement={ppsrFinancingStatement}
        onClickBackToDetails={onClickBackToDetails}
        onSetAlert={onSetAlert}
        onSetPPSRFinancingStatement={onSetPPSRFinancingStatement}
      />
    </FixedContent>
  );
}
