import styled, { css } from 'styled-components';

type Props = {
  absolute?: boolean;
  spacingDirection: 'left' | 'right';
};

export const Wrapper = styled.div<Props>`
  ${({ absolute, spacingDirection }) =>
    absolute
      ? css`
          position: absolute;
          right: 30px;
          top: 30px;
          composes: buttons;
        `
      : css`
      > * {
        margin-${spacingDirection}: 15px;
      }
    `}
`;
