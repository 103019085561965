export const nzTopojson = {
  'arcs': [
    [[1465938, 81800], [63, -43], [-36, -47], [-27, 90]],
    [
      [1466907, 82138],
      [-13, -55],
      [37, -20],
      [73, -2],
      [27, -59],
      [-35, -67],
      [-62, 25],
      [-56, -24],
      [-49, -46],
      [40, -94],
      [-7, -124],
      [-32, -16],
      [-40, -79],
      [-56, 116],
      [-66, -110],
      [-16, 86],
      [-31, 28],
      [-71, -72],
      [-67, 8],
      [-4, 85],
      [-155, 9],
      [63, 64],
      [-41, 51],
      [-46, -3],
      [-73, 37],
      [-19, -59],
      [-68, -49],
      [17, 145],
      [40, 65],
      [38, -13],
      [23, -63],
      [157, 49],
      [50, -54],
      [79, -12],
      [87, 31],
      [-14, 66],
      [61, 29],
      [43, -28],
      [87, 31],
      [6, 64],
      [93, 60],
    ],
    [
      [1465849, 82177],
      [65, -55],
      [-19, -29],
      [31, -61],
      [-24, -36],
      [-10, -91],
      [-104, -28],
      [-99, -85],
      [-130, -17],
      [-80, 93],
      [5, 65],
      [40, 58],
      [115, 24],
      [51, -38],
      [42, 4],
      [39, 78],
      [67, 40],
      [11, 78],
    ],
    [[1465988, 82194], [-34, 2], [8, 98], [37, -20], [-11, -80]],
    [
      [1465225, 81713],
      [48, 48],
      [-66, 65],
      [-68, -111],
      [-88, -2],
      [-46, -21],
      [-72, 10],
      [-11, 56],
      [-32, 27],
      [-27, 111],
      [-42, 6],
      [-19, 72],
      [-58, 17],
      [-73, -15],
      [81, -107],
      [78, 17],
    ],
    [
      [1464830, 81886],
      [-24, -102],
      [-72, 26],
      [-21, -52],
      [0, -68],
      [68, -27],
      [4, -56],
      [-38, -125],
      [20, -70],
      [61, -71],
      [-26, 139],
      [71, -29],
      [54, 0],
      [33, 100],
      [36, -21],
      [56, 64],
      [56, 19],
      [53, -18],
      [87, 20],
      [112, -68],
      [59, 7],
      [40, 40],
      [99, -34],
      [67, 36],
      [70, -45],
      [16, -87],
      [-69, -96],
      [3, -74],
      [115, 114],
      [39, 23],
      [-29, 73],
      [24, 14],
      [55, -95],
      [32, -12],
      [106, -82],
      [7, -89],
      [81, 21],
      [96, 3],
      [-38, 44],
      [14, 48],
      [-25, 42],
      [107, 21],
      [33, -20],
      [84, 38],
      [25, -33],
      [58, -22],
      [32, -90],
      [36, -15],
      [10, -77],
      [40, -89],
      [-48, -67],
      [29, -15],
      [48, 39],
      [74, 0],
      [38, 31],
      [96, 9],
      [63, -68],
      [52, 20],
      [20, 48],
      [60, 33],
      [64, -52],
      [50, -13],
      [60, -59],
      [68, -11],
      [6, -54],
      [75, -76],
      [60, -29],
      [2, -66],
      [29, -40],
      [3, -78],
    ],
    [
      [1467396, 80663],
      [-98, -18],
      [-249, -13],
      [-21, -31],
      [-74, -25],
      [-38, 69],
      [-61, 60],
      [-36, -29],
      [14, -131],
      [-31, -35],
      [5, -89],
      [-22, -61],
      [3, -97],
      [32, -135],
      [-1, -80],
      [-24, -49],
      [-22, -120],
      [-43, -56],
      [-41, -17],
      [-65, 43],
      [-76, -5],
      [1, -52],
      [-55, -12],
      [-32, 66],
      [-74, -2],
      [-13, -30],
      [-66, -4],
      [2, -38],
      [-67, -7],
      [-25, -39],
      [-71, -59],
      [-63, 80],
      [-110, 16],
      [-77, -43],
      [26, -43],
      [-14, -88],
      [-56, -26],
      [-24, 24],
      [-111, 7],
      [-74, -74],
      [-56, 4],
      [-51, 46],
      [-114, 34],
      [-95, -30],
      [-147, -88],
      [-75, -85],
      [-68, 51],
      [-94, -18],
      [-105, -72],
      [-68, -34],
      [4, -54],
    ],
    [
      [1464776, 79274],
      [-91, 205],
      [-103, 247],
      [-167, 379],
      [-95, 181],
      [-45, 113],
      [-8, 111],
      [35, 32],
      [130, 24],
      [82, -10],
      [35, -22],
      [53, 18],
      [41, 45],
      [47, -31],
      [71, 2],
      [45, -70],
      [-3, -31],
      [-55, -70],
      [0, -67],
      [48, -99],
      [26, -103],
      [-53, -46],
      [133, -66],
      [15, -23],
      [-21, -64],
      [38, -148],
      [41, -78],
      [49, -34],
      [-18, -58],
      [19, -48],
      [59, 37],
    ],
    [[1465084, 79600], [21, 14]],
    [
      [1465105, 79614],
      [-35, 9],
      [-61, 165],
      [38, 24],
      [-39, 40],
      [-2, 76],
      [-45, 46],
      [85, 26],
      [97, -89],
      [-14, 72],
      [-76, 31],
      [-88, -8],
      [-10, 89],
      [63, 2],
      [104, 48],
      [65, 74],
      [68, 108],
    ],
    [
      [1465255, 80327],
      [71, -13],
      [21, -24],
      [69, 64],
      [37, 3],
      [66, 54],
      [18, 49],
      [52, 39],
      [23, -36],
      [101, -25],
      [108, 42],
      [-36, 78],
      [-62, -59],
      [-66, 36],
      [-58, -13],
      [-21, 116],
      [-37, 0],
      [-85, 101],
      [-58, 17],
      [-32, -30],
      [-117, -7],
      [-41, 39],
      [-82, 42],
      [2, 68],
      [40, 37],
      [57, 17],
      [-18, 40],
      [-77, -22],
      [18, 52],
      [103, -29],
      [9, 33],
      [-78, 52],
      [3, 43],
      [108, 10],
      [55, -14],
      [14, -37],
      [122, 45],
      [-4, 50],
      [-167, -2],
      [-62, 34],
      [-69, -30],
      [-145, -35],
      [-65, 55],
      [-41, -32],
      [-63, -6],
      [-67, -53],
      [-57, -104],
      [-70, -45],
      [-71, -1],
      [-2, -83],
      [-54, -20],
      [0, -77],
      [-128, -9],
      [-27, 55],
      [-31, -107],
      [-170, -95],
      [-27, -42],
      [-73, -33],
      [-45, 24],
      [-26, 89],
      [0, 147],
      [-23, 137],
      [-28, 32],
      [16, 64],
      [-7, 57],
      [-41, 78],
      [-2, 69],
      [-35, 7],
      [-1, 83],
      [-41, 100],
      [-31, 12],
      [22, 59],
      [-29, 59],
      [13, 51],
      [-30, 113],
      [-167, 323],
      [-217, 371],
      [-47, 86],
      [-139, 224],
      [-55, 98],
      [-414, 574],
      [-40, 70],
      [-7, 121],
      [26, 60],
      [59, 53],
      [70, -62],
      [50, 80],
      [100, 52],
      [63, -113],
      [37, -27],
      [76, -175],
      [60, -76],
      [27, -134],
      [228, -83],
      [4, -35],
      [87, -58],
      [8, -57],
      [-67, -53],
      [6, -41],
      [-45, -22],
      [25, -55],
      [-36, -51],
      [106, -12],
      [-35, 54],
      [34, 24],
      [60, -66],
      [62, -35],
      [57, 27],
      [53, -78],
      [24, 87],
      [3, 62],
      [28, 53],
      [-36, 36],
      [-110, 18],
      [45, 109],
      [88, 24],
      [-34, 90],
      [-43, 30],
      [18, 70],
      [-14, 106],
      [-59, 81],
      [-11, 66],
      [24, 33],
      [-7, 91],
      [89, 119],
      [-83, 85],
      [10, 32],
      [-71, 34],
      [-15, 42],
      [101, 41],
      [14, 25],
      [-26, 96],
      [-38, 41],
      [-13, 63],
      [-45, -15],
      [-10, -41],
      [50, -56],
      [-2, -41],
      [56, -39],
      [-54, -36],
      [-37, 0],
      [-42, -58],
      [-6, -68],
      [-57, 33],
      [-16, -51],
      [-55, 32],
      [-73, 79],
      [-56, 28],
      [-55, 67],
      [-19, -57],
      [11, -47],
      [-97, 12],
      [-28, -19],
      [-70, 53],
      [-16, 36],
      [21, 54],
      [-4, 194],
      [28, 64],
      [55, 39],
      [81, -11],
      [49, -63],
      [4, -37],
      [201, 44],
      [51, 27],
      [73, 11],
      [77, 87],
      [27, 71],
      [53, -10],
      [110, 123],
      [35, -18],
      [81, 5],
      [-23, 43],
      [-11, 75],
      [37, 56],
    ],
    [
      [1464040, 84780],
      [69, 21],
      [111, 82],
      [-11, 57],
      [44, 65],
      [78, 37],
      [46, -8],
      [34, 67],
      [37, -5],
      [28, 49],
      [-4, 80],
      [53, 10],
      [32, 39],
      [-2, 57],
      [45, 21],
    ],
    [
      [1464600, 85352],
      [123, -212],
      [49, -103],
      [93, -124],
      [123, -141],
      [147, -141],
      [89, -20],
      [48, -46],
      [83, 5],
      [96, -49],
      [-25, -56],
      [-89, -75],
      [23, -88],
      [-46, -4],
      [-56, 56],
      [-72, -47],
      [46, -106],
      [22, 70],
      [62, -124],
      [60, 11],
      [58, -63],
      [93, -47],
      [30, -42],
      [-104, 12],
      [-75, -36],
      [-36, 21],
      [-42, -47],
      [-85, 3],
      [-92, -54],
      [-41, 55],
      [31, 61],
      [-29, 24],
      [-41, -31],
      [26, -64],
    ],
    [
      [1465069, 83950],
      [21, -75],
      [-23, -46],
      [13, -67],
      [44, -38],
      [81, -42],
      [7, -104],
      [-40, -74],
      [-17, -97],
      [-45, 2],
      [35, 103],
      [-17, 64],
      [-39, -37],
      [-11, 88],
      [-34, 43],
      [3, 49],
      [-131, 41],
      [67, -98],
      [6, -43],
      [-62, -62],
      [-12, -61],
      [-42, -40],
      [105, -9],
      [44, -52],
      [19, -88],
      [-19, -76],
      [-43, -102],
      [8, -40],
      [-54, -35],
      [-17, -72],
      [32, -97],
      [71, -91],
      [91, -64],
      [89, -18],
      [42, 51],
      [-4, 50],
      [117, 38],
      [62, -1],
      [38, 42],
      [61, -6],
      [14, -38],
      [-17, -76],
      [-75, 6],
      [-45, 38],
      [-11, -88],
      [-36, -25],
      [-31, 32],
      [-63, -3],
      [-29, -65],
      [-71, -16],
      [-37, -43],
      [-77, 46],
      [40, -118],
      [48, 6],
      [38, -145],
      [38, -35],
      [-42, -42],
      [6, -149],
      [51, -82],
      [5, -83],
      [39, -48],
      [2, -81],
      [67, -58],
      [61, -75],
      [1, -68],
      [-48, -25],
      [-118, 62],
    ],
    [
      [1466967, 81641],
      [61, 1],
      [-33, -137],
      [50, -90],
      [-23, -82],
      [-43, -36],
      [-70, 54],
      [-8, 54],
      [-42, 73],
      [66, 60],
      [-11, 89],
      [53, 14],
    ],
    [
      [1467838, 85702],
      [61, -60],
      [-40, -93],
      [16, -67],
      [54, 40],
      [23, -28],
      [-6, -79],
      [19, -32],
      [-25, -91],
      [-59, -64],
      [62, -28],
      [48, 32],
      [21, -40],
      [90, -52],
      [62, -21],
      [29, -35],
      [42, 12],
      [8, -107],
      [-37, 6],
      [-30, -121],
      [38, -35],
      [-41, -39],
      [2, -104],
      [58, -111],
      [82, -8],
      [57, -63],
      [98, -178],
      [-48, -23],
      [-40, -146],
      [-115, 29],
      [-106, 39],
      [15, 69],
      [44, -12],
      [17, 46],
      [-25, 60],
      [-84, -2],
      [-130, -38],
      [-17, 76],
      [4, 118],
      [36, 36],
      [-17, 33],
      [-52, -8],
      [-29, -43],
      [-81, 27],
      [10, 65],
      [-99, -15],
      [-62, 43],
      [-51, 88],
      [-113, 47],
      [-44, 2],
      [-5, 129],
      [73, 6],
      [22, -67],
      [55, 53],
      [-1, 56],
      [77, -2],
      [-41, 93],
      [-41, 37],
      [7, 41],
      [-89, 72],
      [-45, -22],
      [-18, 68],
      [45, 16],
      [21, -42],
      [55, 34],
      [60, -16],
      [52, 79],
      [-68, 29],
      [-38, 89],
      [22, 47],
      [-23, 68],
      [108, 23],
      [8, 53],
      [119, 31],
    ],
    [[1468247, 85360], [60, -22], [-29, -53], [-58, -38], [-16, 43], [43, 70]],
    [
      [1467595, 85112],
      [7, -77],
      [-39, -54],
      [-48, 15],
      [-46, 47],
      [21, 33],
      [87, 1],
      [18, 35],
    ],
    [[1465334, 83485], [-2, -1]],
    [[1465332, 83484], [2, 1]],
    [[1465334, 83486], [0, -1]],
    [[1465334, 83485], [0, 1]],
    [
      [1465524, 83931],
      [126, -79],
      [28, -42],
      [4, -132],
      [-166, -31],
      [-63, 99],
      [43, 38],
      [-35, 70],
      [63, 77],
    ],
    [[1465707, 82897], [34, -13], [29, -55], [-36, -17], [-39, 59], [12, 26]],
    [
      [1466569, 85100],
      [52, -32],
      [40, -50],
      [-22, -49],
      [10, -70],
      [-13, -101],
      [-36, -36],
      [-86, 7],
      [-112, 60],
      [-13, 125],
      [11, 49],
      [65, 32],
      [41, -2],
      [63, 67],
    ],
    [
      [1474982, 72593],
      [-113, -178],
      [-35, -12],
      [-29, 49],
      [-81, -57],
      [-63, 0],
      [-41, -37],
      [-53, -105],
      [-4, -57],
      [-122, -171],
      [-75, -53],
      [-41, 34],
      [-83, 13],
      [-63, -14],
      [-27, 39],
      [-96, 44],
      [-22, 59],
      [-61, 16],
      [-69, -67],
      [-61, 41],
      [-61, -41],
      [-50, -58],
      [-89, 56],
      [-48, -2],
      [-86, -44],
      [2, -57],
      [-35, -46],
      [-60, -19],
      [-8, -79],
      [-56, 30],
      [-48, -11],
      [-40, -164],
      [9, -66],
      [-24, -70],
      [13, -38],
      [-44, -107],
      [-2, -57],
      [34, -33],
      [-16, -53],
      [16, -34],
      [-27, -58],
    ],
    [
      [1473223, 71186],
      [-205, 377],
      [-45, 102],
      [-54, 47],
      [-28, 274],
      [118, 493],
      [-117, 58],
      [-50, -72],
      [-222, 144],
      [-54, 140],
      [-39, 49],
      [15, 47],
      [-126, 45],
      [-3, 82],
      [-107, 3],
      [0, -120],
      [-86, 28],
      [-285, 111],
      [-115, -322],
      [-236, 81],
      [-15, 74],
      [53, 60],
      [46, 100],
      [-57, 60],
      [-19, 49],
      [-59, 7],
      [-53, -18],
      [-54, -85],
      [-86, -25],
      [-50, -46],
      [-72, -2],
      [-47, -21],
      [-42, 31],
      [-61, -2],
      [-28, 109],
      [-95, 49],
      [-6, 123],
      [-34, 134],
      [-81, 126],
      [-85, 3],
      [-38, 29],
      [-63, -4],
      [-43, 60],
      [-134, 87],
      [-31, 72],
      [78, 43],
      [85, 90],
      [44, 67],
      [82, 48],
      [95, 14],
      [42, 31],
      [46, 98],
      [-17, 29],
      [-79, 20],
      [62, 38],
      [-36, 27],
      [123, 257],
      [-167, 266],
      [-88, 13],
      [-126, 1],
      [-115, -13],
      [1, 142],
      [-44, -16],
      [-148, 1],
      [60, 55],
      [239, 488],
      [23, 53],
      [-19, 27],
      [-94, 62],
      [-50, 56],
      [-61, 37],
      [-152, 27],
      [1, 79],
      [-51, 19],
      [-41, 56],
      [-35, 141],
      [24, 35],
      [126, 47],
      [-95, 60],
      [27, 65],
      [-61, 57],
      [-59, 87],
      [-16, 74],
      [29, 24],
      [-121, 73],
      [91, 272],
      [-135, 268],
      [7, 64],
      [-30, 55],
      [-42, 9],
      [-59, 162],
      [-55, 31],
      [-43, 119],
      [-33, 60],
      [32, 73],
      [-76, 159],
      [-6, 57],
      [-41, 38],
      [11, 55],
      [45, 35],
      [24, 48],
      [-42, 119],
      [-47, 4],
      [-4, 66],
      [34, 29],
      [-14, 55],
      [80, 68],
      [45, -79],
      [58, -53],
      [173, 8],
      [-8, 36],
      [-70, 99],
      [-35, -20],
      [-46, 64],
      [74, 77],
      [50, 13],
      [94, 93],
      [-28, 49],
      [-7, 63],
      [50, 6],
      [16, 82],
      [-18, 40],
      [97, 32],
    ],
    [
      [1470084, 78857],
      [-7, -119],
      [104, -193],
      [114, -164],
      [-55, -15],
      [-16, 88],
      [-26, 27],
      [-46, -18],
      [-76, -83],
      [-4, -61],
      [32, -48],
      [-56, -41],
      [46, -29],
      [66, 38],
      [78, -88],
      [3, -26],
      [-79, -29],
      [-56, -51],
      [-116, -22],
      [21, -38],
      [53, 22],
      [48, -28],
      [27, -58],
      [-42, -8],
      [-82, -101],
      [90, -40],
      [27, -61],
      [60, 57],
      [53, -14],
      [-48, -57],
      [-20, -64],
      [77, 9],
      [-24, -83],
      [65, -35],
      [102, 33],
      [1, -82],
      [87, 10],
      [-4, -58],
      [59, -78],
      [97, 35],
      [80, -28],
      [38, -72],
      [36, -8],
      [30, 73],
      [39, 27],
      [124, -37],
      [72, 42],
      [-17, -156],
      [-18, -37],
      [-4, -106],
      [54, 4],
      [27, -55],
      [60, 26],
      [37, 48],
      [70, -16],
      [47, 80],
      [-21, 33],
      [-97, 12],
      [-6, -37],
      [-67, -38],
      [-38, 69],
      [23, 23],
      [51, -31],
      [21, 82],
      [-45, -14],
      [-51, 60],
      [9, 99],
      [-12, 62],
      [-57, 32],
      [31, 32],
      [124, -131],
      [192, -153],
      [181, -99],
      [220, -93],
      [194, -118],
      [85, -39],
      [70, -78],
      [96, 27],
      [59, 78],
      [40, -85],
      [-32, -37],
      [36, -57],
      [53, 4],
      [17, 36],
      [79, -63],
      [215, -155],
      [145, -94],
      [239, -125],
      [317, -134],
      [158, -46],
      [183, -27],
      [255, -78],
      [214, -42],
      [123, -53],
      [109, -58],
      [95, -30],
      [59, -71],
      [144, -66],
      [201, -51],
      [130, -17],
      [30, -45],
      [-117, 22],
      [-71, 33],
      [-148, 3],
      [7, -92],
      [84, 3],
      [4, -62],
      [85, 44],
      [93, -180],
      [52, 13],
      [-25, 68],
      [20, 56],
      [84, 33],
      [-11, 85],
      [282, -22],
      [167, -3],
      [285, 20],
      [191, 24],
      [33, -6],
      [96, 35],
      [52, 53],
      [45, -3],
      [52, 34],
      [151, 63],
      [-1, 74],
      [146, 60],
      [102, 84],
      [-3, 59],
      [34, 66],
      [93, 50],
      [36, 41],
      [0, 111],
      [87, 33],
      [36, 66],
      [9, 86],
      [40, -31],
      [95, 43],
      [27, 46],
      [-18, 52],
      [36, 52],
      [58, 31],
      [38, 58],
      [-39, 80],
      [51, 69],
      [90, 50],
      [35, 58],
      [44, 156],
      [216, 22],
      [33, -26],
      [55, 41],
      [3, 39],
      [62, 39],
      [94, -31],
      [121, 55],
      [43, 76],
      [52, 56],
      [16, 81],
      [142, -44],
      [101, 27],
      [52, 108],
      [76, 61],
      [31, 46],
      [-47, 101],
      [10, 93],
      [96, -86],
      [173, 57],
      [128, -13],
      [25, 11],
    ],
    [
      [1478973, 77981],
      [54, -225],
      [39, -66],
      [-281, 6],
      [-39, -34],
      [-181, -24],
      [-28, -23],
      [-19, -90],
      [59, -74],
      [21, -258],
      [-40, -46],
      [31, -80],
      [-58, -60],
      [-1, -71],
      [58, -44],
      [18, -40],
      [174, 143],
      [142, -158],
      [-417, -421],
      [-230, -706],
      [-231, -674],
      [-50, 2],
      [-316, 267],
      [-136, 91],
      [-64, 101],
      [-102, 4],
      [-41, 41],
      [-68, -27],
      [5, -40],
      [80, -137],
      [-34, -157],
      [36, -81],
      [-12, -48],
      [-53, -28],
      [-41, 5],
      [-28, -78],
      [-46, -68],
      [10, -89],
      [-46, -28],
      [-61, 15],
      [-42, -50],
      [-63, -3],
      [10, -80],
      [-87, -36],
      [-44, 5],
      [-69, -62],
      [-95, -24],
      [-1, -47],
      [-66, 20],
      [-75, -20],
      [-23, -31],
      [-16, -124],
      [9, -73],
      [-32, -62],
      [8, -113],
      [-94, -193],
      [-486, -251],
      [-453, -229],
      [85, -424],
      [-70, -10],
      [-11, -48],
      [-50, -75],
      [-88, 50],
      [-64, -65],
      [-51, -124],
      [-43, -34],
      [-58, -84],
      [-50, 32],
      [-76, -33],
    ],
    [[1475242, 78139], [38, -64], [-61, -26], [-50, 62], [73, 28]],
    [[1470764, 77557], [51, 13], [33, -78], [-71, -12], [-13, 77]],
    [
      [1470439, 78189],
      [140, -214],
      [153, -198],
      [132, -138],
      [64, -50],
      [64, -96],
      [-34, -39],
      [-97, 87],
      [-26, 68],
      [-64, 22],
      [-34, 54],
      [-51, 0],
      [-9, -44],
      [47, -73],
      [-87, 1],
      [-38, 76],
      [-82, 12],
      [-67, 90],
      [7, 87],
      [99, 26],
      [-67, 92],
      [-76, 58],
      [-26, 56],
      [-78, 114],
      [-43, 35],
      [-5, 112],
      [49, 6],
      [64, -41],
      [65, -103],
    ],
    [
      [1472055, 77668],
      [85, -64],
      [-2, -43],
      [-48, -91],
      [-56, -7],
      [-13, 87],
      [40, 47],
      [-6, 71],
    ],
    [
      [1471434, 79403],
      [33, -11],
      [12, -65],
      [-72, -142],
      [-99, 68],
      [-15, 93],
      [30, 32],
      [111, 25],
    ],
    [
      [1448481, 36456],
      [-46, 51],
      [-327, -2],
      [-92, -81],
      [-70, 32],
      [-74, 9],
      [-29, 67],
      [-46, 4],
      [3, 80],
      [-25, 52],
      [-15, 98],
      [-295, 1],
      [-21, -123],
      [-188, 51],
      [-49, 23],
      [-131, -183],
      [-191, 43],
      [38, 91],
      [35, 36],
      [-204, 121],
      [-102, 2],
      [-49, -42],
      [-266, -6],
      [-1, 128],
      [73, 0],
      [144, 134],
      [17, 62],
      [80, -1],
      [-2, 490],
      [168, -67],
      [0, 199],
      [290, -20],
      [58, 43],
      [49, 60],
      [11, 65],
      [-52, 78],
      [-69, 61],
      [-334, 1],
      [0, 134],
      [62, 30],
      [88, -25],
      [18, 64],
      [120, 86],
      [-6, 55],
      [-45, 81],
      [49, 64],
      [105, -23],
      [31, 76],
      [83, 98],
      [15, 58],
      [-146, 56],
      [19, 105],
      [-47, 48],
      [2, 62],
      [-50, 16],
      [-21, 89],
      [-45, 25],
      [-27, 97],
      [-57, 80],
      [10, 72],
      [-18, 94],
      [93, 90],
      [14, 101],
      [-32, 65],
      [-35, 6],
      [-29, 162],
      [-41, 19],
      [-86, 5],
      [-61, -39],
      [-133, -57],
      [-86, 14],
      [-41, -61],
      [-130, -20],
      [-43, -23],
      [-173, -24],
      [-130, 13],
      [-65, -30],
      [-72, 36],
      [-38, 65],
      [-67, 22],
      [55, 57],
      [-52, 64],
      [-23, 83],
      [-86, 36],
      [-56, 45],
      [-46, 64],
      [-91, 177],
      [9, 52],
      [-23, 136],
      [-45, 91],
      [-5, 67],
      [-45, 54],
      [-28, 183],
      [-80, 283],
      [-219, 51],
      [-228, -128],
      [-127, 39],
      [-210, 116],
      [-5, 107],
      [14, 32],
      [-39, 62],
      [-6, 73],
      [-65, 108],
      [-50, -43],
      [-22, -127],
      [-102, -24],
      [-28, 25],
      [-102, 22],
      [-61, -9],
      [9, 198],
      [73, 102],
      [-47, 14],
      [-22, 79],
      [-31, 37],
      [7, 80],
      [31, 82],
      [-57, 95],
      [-77, 10],
      [-42, -17],
      [-60, 24],
      [-48, -15],
      [-69, 10],
      [-81, 60],
      [56, 56],
      [-42, 78],
      [85, 131],
      [-40, 86],
      [34, 38],
      [57, 183],
      [-32, 28],
      [-11, 74],
      [26, 94],
      [-6, 105],
      [30, 144],
      [-42, 79],
      [-91, 48],
      [-20, 57],
      [13, 57],
      [55, -1],
      [7, 59],
      [39, 88],
      [60, 53],
      [84, 43],
      [-26, 41],
      [65, 37],
      [40, 109],
      [48, 5],
      [11, 102],
      [97, 86],
      [-20, 35],
      [37, 53],
      [64, -1],
      [-5, 102],
      [23, 36],
      [121, 16],
      [17, 21],
      [-49, 87],
      [-8, 74],
      [21, 44],
      [-46, 72],
      [-3, 90],
    ],
    [
      [1444223, 44763],
      [85, 19],
      [33, 60],
      [62, 39],
      [20, 50],
      [45, -13],
      [30, 135],
      [21, 41],
      [100, 43],
      [32, 64],
      [47, 32],
      [100, 32],
      [0, 64],
      [122, 114],
      [85, -22],
      [50, 121],
      [136, 64],
      [42, 57],
      [88, -11],
      [46, 52],
      [-20, 42],
      [59, 73],
      [-15, 70],
      [104, 26],
      [30, 46],
      [66, 39],
      [-27, 96],
      [22, 76],
      [54, 44],
      [77, 20],
      [131, 96],
      [40, 108],
      [-31, 67],
      [17, 77],
      [-23, 25],
      [25, 58],
      [110, 43],
      [63, -12],
      [27, 79],
      [93, 73],
      [59, -5],
      [83, 61],
      [-1, 41],
      [75, 87],
      [72, 56],
      [113, 4],
      [143, 60],
      [86, 49],
      [146, -74],
      [168, 46],
      [59, 49],
      [31, 53],
      [71, 66],
      [100, 28],
      [4, 42],
      [61, 94],
      [89, 34],
      [58, -10],
      [75, 94],
      [75, -61],
      [40, -10],
      [64, 25],
      [48, -15],
      [32, -61],
      [94, 8],
      [7, 77],
      [80, 84],
      [-9, 22],
      [98, 51],
      [4, 50],
      [-49, 36],
      [167, 126],
      [114, 7],
      [14, 57],
      [-47, 68],
      [103, 28],
      [131, -12],
      [126, 95],
      [56, -27],
      [61, 27],
      [40, 81],
      [124, 11],
      [42, 44],
      [-21, 42],
      [43, 64],
      [-10, 24],
      [83, 52],
      [69, 61],
      [13, 51],
      [65, 49],
      [78, 5],
      [-14, -82],
      [19, -30],
      [128, 24],
      [56, 38],
      [46, 104],
      [35, 13],
      [31, 94],
      [112, 56],
      [-39, 67],
      [15, 57],
      [114, 73],
      [-25, 37],
      [53, 32],
      [15, 52],
      [65, 7],
      [51, -54],
      [33, 61],
      [4, 68],
      [57, 27],
      [44, -7],
      [144, 42],
      [33, 50],
      [59, -49],
      [89, 48],
      [22, 114],
      [64, 52],
      [-12, 50],
      [27, 34],
      [-24, 73],
      [68, 26],
      [33, 68],
      [148, 93],
      [77, 97],
      [82, 22],
      [26, -33],
      [81, 65],
      [94, -42],
      [39, 11],
      [17, -60],
      [57, 21],
      [58, 79],
      [62, 26],
      [-27, 45],
      [62, 65],
      [67, -32],
      [76, 50],
      [30, 50],
      [70, -32],
      [40, -61],
      [71, 47],
      [130, -4],
      [33, 32],
      [69, 19],
      [158, -37],
      [65, 25],
      [87, 61],
      [104, -75],
      [16, -39],
      [67, 65],
      [67, 39],
      [80, 71],
      [-54, 59],
      [16, 83],
      [73, 11],
      [34, 85],
      [-33, 54],
      [38, 136],
      [47, 7],
      [57, 39],
      [22, -26],
      [135, 34],
      [61, 35],
      [25, 65],
      [67, 60],
      [47, 10],
      [36, 52],
      [4, 99],
      [96, 76],
      [31, 118],
      [40, 33],
      [10, 52],
      [54, 7],
      [76, 54],
      [57, 2],
      [7, 73],
      [184, 36],
      [38, -24],
      [205, 75],
      [71, 12],
      [34, 73],
      [-18, 64],
      [143, 198],
      [95, 11],
      [92, 90],
      [19, 50],
      [-8, 64],
      [60, 87],
      [104, 60],
      [45, 58],
      [19, 91],
      [79, 26],
      [48, -18],
      [112, 76],
      [25, 92],
      [75, 13],
      [67, 60],
      [56, 85],
      [192, 72],
      [96, -30],
      [52, 60],
      [59, 28],
      [24, 66],
      [-26, 48],
      [24, 84],
      [72, 46],
      [-19, 62],
      [54, 56],
      [18, 102],
    ],
    [
      [1455742, 53488],
      [32, 58],
      [64, -13],
      [135, 141],
      [43, 13],
      [58, 95],
      [42, 27],
      [60, -9],
      [91, 66],
      [47, 56],
      [-23, 67],
      [2, 116],
      [67, 91],
      [52, 44],
      [-38, 50],
      [5, 73],
      [61, 37],
      [140, 63],
      [23, -82],
      [63, 16],
      [81, -11],
    ],
    [
      [1456747, 54386],
      [-12, -60],
      [26, -38],
      [4, -64],
      [-30, -25],
      [12, -98],
      [31, -35],
      [83, -36],
      [111, 26],
      [42, 25],
      [54, -12],
      [-14, -75],
      [47, -53],
      [57, 34],
      [57, -37],
      [-46, -75],
      [-5, -97],
      [18, -129],
      [114, -25],
      [31, -65],
      [-43, -85],
      [-36, -37],
      [18, -96],
      [96, -51],
      [183, 46],
      [33, -88],
      [-13, -95],
      [23, -32],
      [75, -3],
      [-5, -140],
      [112, -79],
      [41, -13],
      [52, 21],
      [8, -63],
      [-68, -154],
      [-15, -79],
      [119, -13],
      [54, 26],
      [77, -60],
      [25, -73],
      [-14, -59],
      [73, -24],
      [64, 37],
      [34, 80],
      [94, 34],
      [50, 67],
      [56, -27],
      [111, 7],
      [73, 178],
      [86, 27],
      [51, 62],
      [26, 78],
      [51, 23],
      [-11, 64],
      [30, 103],
      [-188, 0],
      [20, 58],
      [90, 21],
      [0, 73],
      [155, 72],
      [62, 68],
      [153, 127],
      [63, 78],
      [90, 19],
      [27, 93],
      [48, -73],
      [69, 37],
      [3, 60],
      [60, 34],
      [33, -23],
      [53, 13],
      [57, 48],
      [58, -18],
      [39, 50],
      [97, 44],
      [-5, 73],
      [89, 14],
      [-46, 145],
      [-8, 90],
      [-20, 42],
      [88, 94],
      [-18, 39],
      [63, 107],
      [77, 48],
      [121, -2],
      [-9, 44],
      [43, 79],
      [116, 88],
      [47, -2],
      [62, 128],
      [67, -32],
      [51, 7],
      [104, 71],
      [0, 70],
      [79, 41],
      [75, 12],
      [83, 58],
      [-26, 49],
      [1, 56],
      [40, 46],
      [20, 67],
      [65, 58],
      [93, -75],
      [94, -188],
      [75, 70],
      [40, 61],
      [134, 6],
      [269, 85],
      [72, -27],
      [48, 42],
      [69, -7],
      [71, -101],
      [109, 6],
      [66, -38],
      [38, 35],
      [86, 19],
      [49, -82],
      [-4, -49],
      [-47, -71],
    ],
    [
      [1462247, 55086],
      [-111, -105],
      [-43, -84],
      [-56, -50],
      [-132, -159],
      [-68, -102],
      [-44, -93],
      [-59, -206],
      [0, -80],
      [61, -105],
      [-45, -74],
      [-151, -86],
      [-66, -71],
      [-13, -58],
      [-65, -26],
      [-112, -148],
      [-88, -41],
      [-8, -50],
      [-75, -49],
      [-83, -24],
      [-58, -52],
      [-33, -71],
      [11, -83],
      [-216, -164],
      [-66, -156],
      [-2, -87],
      [41, -84],
      [45, 3],
      [42, -71],
      [-43, -20],
      [-77, 27],
      [-49, 68],
      [-88, -1],
      [-231, -79],
      [-47, -100],
      [-110, -56],
      [-37, -3],
      [-81, -107],
      [-44, -103],
      [-35, -31],
      [-28, -105],
      [-2, -117],
      [63, -37],
      [-88, -73],
      [-48, -70],
      [-59, -160],
      [-69, -130],
      [-129, -300],
      [-108, -235],
      [-26, -70],
      [-59, -87],
      [-31, -132],
      [-75, -93],
      [-124, -132],
      [-43, -89],
      [0, -75],
      [37, -53],
      [-54, -27],
      [-275, -336],
      [-77, -52],
      [-144, -67],
      [-68, -2],
      [-96, -43],
      [-51, -41],
      [-150, -177],
      [-85, -111],
      [-124, 19],
      [-146, -72],
      [-75, -64],
      [-147, -61],
      [-82, -11],
      [-53, -35],
      [-184, -46],
      [-153, -155],
      [-116, -8],
      [-40, -43],
      [-123, -184],
      [-122, -254],
      [-60, -149],
      [-64, -230],
      [-24, -117],
      [-15, -160],
      [10, -193],
      [-29, 0],
      [-9, -162],
      [35, 1],
      [50, -303],
      [41, -147],
      [99, -274],
      [-34, 8],
      [-27, 105],
      [-62, 74],
      [-48, -117],
      [46, -63],
      [50, 26],
      [15, -48],
      [77, -12],
      [72, -42],
      [1, -50],
      [82, 17],
      [26, -61],
      [-99, -25],
      [-122, 1],
      [-117, -40],
    ],
    [
      [1456751, 46616],
      [-67, -31],
      [-53, 30],
      [-56, -9],
      [-78, -64],
      [-41, -10],
      [-18, -67],
      [49, -50],
      [23, -84],
      [35, 1],
      [38, 72],
      [67, -51],
      [57, 67],
      [26, 94],
      [134, -5],
      [101, 54],
      [230, 46],
      [-39, -155],
      [-3, -71],
      [-33, -33],
      [40, -34],
      [69, 91],
      [-18, 27],
      [79, 189],
      [165, -53],
      [52, -106],
      [-37, -72],
      [11, -33],
      [-35, -69],
      [40, -43],
      [58, 103],
      [9, 74],
      [38, 33],
      [26, 62],
      [56, -59],
      [70, -30],
      [64, 42],
      [21, -34],
      [65, -26],
      [-26, -78],
      [43, -71],
      [71, 102],
      [83, -7],
      [29, -45],
      [74, -68],
      [-5, -103],
      [49, 0],
      [43, 57],
      [87, -79],
      [1, -64],
      [42, -53],
      [-18, -28],
      [-75, -19],
      [-10, -33],
      [131, 7],
      [25, -129],
      [-34, -68],
      [-53, 10],
      [11, -72],
      [-10, -76],
      [-56, -29],
      [44, -41],
      [-52, -34],
      [-44, -82],
      [-2, -47],
      [-91, -17],
      [-63, -44],
      [-40, -69],
      [-59, -15],
      [-100, -60],
      [-121, 8],
      [0, 61],
      [-105, 63],
      [-10, 50],
      [19, 170],
      [82, 67],
      [-3, 46],
      [-63, -14],
      [-24, 36],
      [14, 47],
      [70, -7],
      [11, 31],
      [-28, 91],
      [-70, -9],
      [-8, 57],
      [-59, 5],
      [-66, -60],
      [-6, -70],
      [50, -37],
      [17, -62],
      [-41, -74],
      [-46, -25],
      [-1, -59],
      [48, -131],
      [-26, -29],
      [41, -68],
      [96, -61],
      [29, -73],
      [-79, -27],
      [-88, 58],
      [-72, -7],
      [-34, -32],
      [-60, 45],
      [-134, -2],
      [-45, 15],
      [-16, 73],
      [-88, 3],
      [-67, 20],
      [-112, 89],
      [-39, -18],
      [-35, 69],
      [15, 21],
      [-107, 49],
      [-70, 3],
      [-434, -28],
      [-473, -69],
      [-202, -19],
      [-343, -58],
      [-245, -68],
      [-307, -131],
      [-126, -33],
      [-269, -101],
      [-389, -173],
      [-679, -357],
      [-296, -163],
      [-462, -231],
      [-73, -32],
      [-201, -113],
      [-402, -261],
      [-392, -260],
      [-294, -249],
      [-78, -76],
      [-220, -244],
      [-147, -203],
      [74, -72],
      [-9, -46],
      [22, -56],
      [-39, -70],
      [18, -17],
      [-26, -105],
      [-180, -263],
      [-64, -121],
      [-109, -260],
      [-25, -117],
      [-17, -146],
      [27, -280],
      [17, -237],
      [19, -474],
      [-8, -154],
      [-19, -140],
      [-32, -143],
      [-91, -250],
      [-89, -163],
      [-63, -94],
      [-171, -190],
      [-331, -274],
      [-31, -88],
      [41, -23],
      [-25, -64],
      [-86, -23],
      [-153, -142],
      [-61, -89],
      [14, -43],
      [-56, -63],
      [-40, -80],
      [17, -29],
      [-60, -50],
      [-53, -111],
      [-3, -117],
      [-56, -63],
      [-65, -122],
      [-24, -90],
      [10, -139],
      [44, -58],
      [98, 18],
      [27, -167],
      [-92, -27],
      [-87, -93],
      [-58, -138],
      [-8, -92],
      [75, -49],
      [13, -34],
      [-48, -30],
      [-166, -194],
      [-98, -87],
      [-61, -113],
      [-59, -72],
    ],
    [[10977, 42835], [43, -29], [-3, -74], [-71, 37], [31, 66]],
    [
      [10795, 43414],
      [100, -38],
      [33, -62],
      [121, -121],
      [-1, -32],
      [-109, -78],
      [-44, -57],
      [-24, -143],
      [-173, -125],
      [-46, 55],
      [37, 31],
      [-58, 43],
      [35, 87],
      [-36, 47],
      [25, 30],
      [-9, 90],
      [62, 15],
      [16, 49],
      [-68, 80],
      [27, 50],
      [53, -3],
      [59, 82],
    ],
    [
      [9116, 46160],
      [64, -45],
      [66, -20],
      [84, -5],
      [137, -72],
      [88, -14],
      [83, 5],
      [71, -87],
      [196, -44],
      [97, 5],
      [85, 23],
      [61, -15],
      [100, 26],
      [16, 32],
      [90, -35],
      [27, -32],
      [87, -5],
      [86, 25],
      [36, 52],
      [49, -16],
      [23, 37],
      [86, -8],
      [36, -23],
      [75, 36],
      [24, -50],
      [-97, -15],
      [-60, -55],
      [-46, -66],
      [-65, 20],
      [-65, -7],
      [-84, -31],
      [-87, -57],
      [-68, -61],
      [-135, -171],
      [-59, -99],
      [-94, -219],
      [-42, -184],
      [-47, 17],
      [47, 123],
      [-9, 148],
      [-26, 77],
      [73, 48],
      [31, 99],
      [95, 184],
      [-49, 78],
      [-39, 29],
      [-172, 81],
      [-58, -9],
      [-92, 23],
      [-95, -87],
      [-17, 56],
      [-81, 65],
      [-103, 12],
      [-5, -74],
      [-45, -43],
      [-5, -65],
      [84, -101],
      [78, -33],
      [102, 9],
      [75, -32],
      [123, 3],
      [14, -26],
      [-82, -41],
      [-37, -41],
      [-72, 6],
      [-47, -87],
      [-76, -54],
      [-6, -84],
      [51, -116],
      [157, -142],
      [10, -59],
      [-59, -91],
      [13, -41],
      [125, -65],
      [95, 15],
      [-5, 112],
      [55, 33],
      [11, -120],
      [36, -160],
      [67, -137],
      [63, -49],
      [95, -2],
      [22, -36],
      [74, -32],
      [12, -56],
      [-86, -12],
      [-49, -36],
      [-80, 0],
      [-66, 24],
      [-103, -41],
      [-129, -18],
      [-113, -52],
      [-66, -68],
      [-28, 5],
      [-62, -59],
      [-87, -38],
      [-30, -76],
      [-146, -51],
      [-39, 22],
      [-121, 12],
      [-83, 29],
      [17, 37],
      [-56, 96],
      [-6, 89],
      [-51, 26],
      [16, 70],
      [-7, 68],
      [-52, 55],
      [-46, 128],
      [110, 49],
      [249, 173],
      [64, 20],
      [33, 78],
      [57, -35],
      [70, 37],
      [26, 45],
      [5, 78],
      [-55, 205],
      [-104, 181],
      [-155, 187],
      [-252, 21],
      [-77, 40],
      [6, -67],
      [-94, 16],
      [33, -60],
      [-48, -35],
      [-150, -4],
      [-193, 37],
      [25, -78],
      [-115, -23],
      [-76, 7],
      [-68, -17],
      [-91, 68],
      [-77, 34],
      [70, 27],
      [-11, 47],
      [34, 85],
      [65, 23],
      [93, 1],
      [89, 20],
      [-6, 105],
      [-35, 37],
      [21, 51],
      [134, -74],
      [98, -18],
      [134, 5],
      [89, 34],
      [196, 49],
      [53, 41],
      [59, 95],
      [-33, 39],
      [54, 106],
    ],
    [
      [1478973, 77981],
      [106, -30],
      [50, 5],
      [108, -22],
      [76, 11],
      [31, 77],
      [143, -3],
      [31, -61],
      [177, -18],
      [77, -25],
      [54, 7],
      [98, -12],
      [-18, -63],
      [-64, -7],
      [-10, -39],
      [37, -98],
      [65, -23],
      [98, -109],
      [95, -48],
      [59, -9],
      [52, 22],
      [68, -21],
      [37, 15],
      [184, -61],
      [39, 16],
      [55, -15],
      [12, -32],
      [137, -119],
      [93, -28],
      [38, -70],
      [-27, -67],
      [-84, -98],
      [0, -65],
      [-102, -105],
      [-89, -118],
      [-129, -223],
      [22, -67],
      [-76, -55],
      [-104, -118],
      [-14, -133],
      [-27, -80],
      [-40, -50],
      [6, -80],
      [38, -43],
      [-34, -105],
      [-55, -23],
      [-39, -101],
      [-67, -23],
      [-52, -79],
      [-23, -98],
      [10, -67],
      [43, -38],
      [96, -23],
      [-35, -105],
      [45, -16],
      [4, -116],
      [-58, -79],
      [-76, 2],
      [-81, -67],
      [-16, -89],
      [49, -89],
      [121, -114],
      [-29, -41],
      [-58, -31],
      [-17, -53],
      [-5, -94],
      [-28, 8],
      [-59, -47],
      [3, -67],
      [85, -93],
      [-11, -70],
      [42, -68],
      [54, 4],
      [1, -54],
      [-84, -13],
      [-44, -85],
      [33, -33],
      [5, -52],
      [-31, -16],
      [17, -103],
      [-95, -43],
      [-1, -52],
      [55, -53],
      [75, 27],
      [15, -59],
      [-16, -81],
      [33, -71],
      [-135, -80],
      [-46, -55],
      [-79, -143],
      [21, -111],
      [-15, -89],
      [41, -83],
      [-38, -20],
      [-12, -48],
      [-54, -1],
      [-24, -46],
      [-57, -16],
      [-59, -50],
      [-9, -53],
      [-77, -37],
      [-56, -66],
      [-4, -70],
      [-58, -15],
      [-49, -103],
      [-59, -3],
      [-54, -84],
      [-11, -44],
      [-73, 17],
      [-72, -48],
      [-13, -33],
      [-62, -29],
      [-61, -68],
      [-32, -111],
      [-73, 21],
      [-47, 84],
      [-75, 69],
      [-65, 10],
      [-134, -55],
      [-86, -84],
      [-68, -116],
      [19, -132],
      [-8, -38],
      [138, -14],
      [-87, -47],
      [-46, -97],
      [-39, -15],
      [-36, -89],
      [9, -92],
      [-48, -34],
      [-1, -124],
      [23, -104],
      [-59, -96],
      [27, -80],
      [-2, -96],
      [-25, -40],
      [-3, -122],
      [-13, -83],
    ],
    [
      [1478201, 70586],
      [-158, 35],
      [-76, 30],
      [-32, 34],
      [23, 99],
      [21, 27],
      [-48, 81],
      [-52, -37],
      [-59, 57],
      [-33, 75],
      [-103, -95],
      [-103, 83],
      [-45, -41],
      [-69, 33],
      [-144, -27],
      [-54, 23],
      [-57, -29],
      [0, 270],
      [-31, 3],
      [-64, -64],
      [-53, 102],
      [-103, -38],
      [-21, 128],
      [12, 104],
      [-18, 20],
      [-85, 4],
      [-96, -17],
      [-52, 29],
      [-93, 133],
      [-84, -19],
      [-44, 119],
      [-57, -2],
      [25, 122],
      [-57, 140],
      [-93, 3],
      [-22, 54],
      [-106, -25],
      [47, 132],
      [45, 20],
      [85, 151],
      [-65, 41],
      [-82, -2],
      [-98, 54],
      [-124, 1],
      [-37, -14],
      [-185, 143],
      [-6, -325],
      [-768, 392],
    ],
    [
      [1471062, 69696],
      [27, 75],
      [104, 54],
      [100, 75],
      [6, 34],
      [444, -389],
      [222, 58],
      [-33, 83],
      [3, 48],
      [-59, 30],
      [183, 75],
      [111, -23],
      [-97, 304],
      [121, 312],
      [405, -1],
      [-25, 21],
      [287, -2],
      [11, 45],
      [-53, 69],
      [-10, 78],
      [3, 144],
      [411, 400],
    ],
    [
      [1478201, 70586],
      [-8, -111],
      [-21, -88],
      [-22, -26],
      [2, -84],
      [19, -90],
      [42, -124],
      [51, -61],
      [126, -55],
      [53, 33],
      [43, -50],
      [46, 7],
      [90, -26],
      [1, -51],
      [-120, -99],
      [-72, -35],
      [-41, -88],
      [-85, -80],
      [5, -67],
      [-47, -148],
      [-32, -73],
      [-121, -128],
      [-22, -45],
      [-43, 12],
      [8, 62],
      [-47, 79],
      [-3, 106],
      [-28, 68],
      [-4, 60],
      [-77, 118],
      [60, 51],
      [4, 65],
      [25, 18],
      [55, 116],
      [47, 135],
      [1, 55],
      [-50, 60],
      [-47, 27],
      [-57, 3],
      [-27, -58],
      [-60, -14],
      [-30, 23],
      [-206, 54],
      [-257, 33],
      [-237, 10],
      [-308, -22],
      [-199, -1],
      [-266, -20],
      [-254, -50],
      [-237, -67],
      [-301, -100],
      [-415, -186],
      [-103, -52],
      [-169, -101],
      [-197, -145],
      [-29, -43],
      [-17, -98],
      [5, -56],
      [-59, -71],
      [-15, -78],
      [-49, -68],
      [-37, -22],
      [-33, -81],
      [-48, -31],
      [-50, -71],
      [-49, -23],
      [-54, -91],
      [-89, -21],
      [-88, -156],
      [-80, -231],
      [-26, -143],
      [2, -72],
      [48, -108],
      [28, -16],
      [136, 22],
      [-21, -168],
      [4, -135],
      [18, -117],
      [60, -149],
      [60, -120],
      [52, -73],
      [57, -45],
      [220, -92],
      [121, 33],
      [62, 40],
      [73, -36],
      [-79, -158],
      [-148, -142],
      [-104, -163],
      [-15, -55],
      [-25, -180],
      [-43, -87],
      [-5, -84],
      [20, -84],
      [28, -47],
      [-35, -32],
      [-66, -107],
      [4, -19],
      [-62, -80],
      [-16, -112],
      [-54, -92],
      [-73, -72],
      [-21, -119],
      [-77, -116],
      [-27, -135],
      [-58, -98],
      [-36, -124],
      [13, -89],
      [51, -53],
      [-22, -43],
      [-79, -67],
      [13, -114],
      [-17, -54],
      [-99, -62],
      [-1, -61],
      [-74, -69],
      [-37, -100],
      [-99, -92],
      [-27, -64],
      [-85, -3],
      [-181, -85],
      [-153, -113],
      [-36, -48],
      [32, -27],
      [-79, -156],
      [-1, -73],
      [25, -36],
      [-81, -151],
      [-12, -69],
      [-59, -125],
      [-2, -98],
      [-54, -105],
    ],
    [
      [1472912, 63053],
      [-136, 28],
      [-46, 42],
      [-82, 38],
      [-37, -72],
      [-236, 109],
      [-2, -60],
      [-101, 2],
      [-103, 20],
      [-39, 23],
      [13, 57],
      [-80, -2],
      [-44, 71],
      [-83, 24],
      [20, 63],
      [-88, 16],
      [146, 133],
      [-56, 20],
      [-7, 95],
      [35, 130],
      [-27, 45],
      [-50, 2],
      [-1, 202],
      [-41, 17],
      [-1, 102],
      [-35, -5],
      [-7, 97],
      [26, 44],
      [-11, 38],
      [62, 117],
      [-37, 34],
      [-110, 32],
      [-56, 119],
      [-85, 17],
      [59, 132],
      [-10, 39],
      [-88, 52],
      [-10, 41],
      [-71, 2],
      [-70, 31],
      [-4, 86],
      [-98, 35],
      [1, 57],
      [-87, -8],
      [-18, 57],
      [-35, -6],
      [-178, 10],
      [-33, 25],
      [-73, -84],
      [-49, 49],
      [2, 29],
      [-130, 93],
      [39, 94],
      [54, 40],
      [3, 61],
      [29, 51],
      [-59, 31],
      [24, 65],
      [-36, 63],
      [80, 64],
      [3, 68],
      [-40, 25],
      [40, 131],
      [34, 56],
      [12, 139],
      [161, 413],
      [-32, 135],
      [47, 35],
      [16, 141],
      [69, 56],
      [-21, 49],
      [-128, 77],
      [67, 164],
      [-9, 61],
      [-109, 36],
      [-41, 70],
      [98, 10],
      [69, 63],
      [27, 57],
      [40, 15],
      [2, 52],
      [45, 69],
      [69, 1],
      [75, 45],
      [108, 88],
      [82, 30],
      [100, -9],
      [30, -18],
      [61, 19],
      [-24, 78],
      [-52, 4],
      [-49, 124],
      [28, 52],
      [-37, 48],
      [3, 73],
      [61, 96],
      [50, 141],
      [-22, 80],
      [-61, -11],
      [-45, 72],
      [-41, -32],
      [-84, 20],
      [-22, 46],
      [1, 68],
      [39, 34],
      [17, 91],
      [80, 42],
      [24, 67],
      [-44, 75],
      [4, 54],
      [-23, 106],
      [-55, 33],
      [-78, -24],
      [-34, 49],
      [-126, 21],
      [-76, 26],
      [-30, 83],
      [8, 61],
      [-65, 68],
      [10, 58],
      [-22, 87],
      [-3, 68],
    ],
    [
      [1472912, 63053],
      [3, -81],
      [39, -44],
      [-42, -198],
      [-34, -20],
      [-52, 16],
      [-96, -7],
      [-113, -34],
      [-240, -162],
      [-59, -71],
      [-28, -70],
      [-9, -71],
      [-76, -83],
      [-123, -88],
      [-29, -90],
      [-68, -39],
      [-86, -131],
      [-6, -66],
      [-58, -53],
      [-36, 0],
      [-47, -73],
      [-73, -53],
      [-63, -73],
      [-28, -85],
      [-63, -84],
      [3, -67],
      [-75, -98],
    ],
    [
      [1471453, 61228],
      [-51, 109],
      [49, 53],
      [-15, 48],
      [-64, -18],
      [-60, 111],
      [-10, 72],
      [-48, 27],
      [-78, -4],
      [-77, 34],
      [-40, -18],
      [-75, 45],
      [21, 51],
      [-38, 27],
      [-89, -31],
      [-157, 41],
      [-48, -171],
      [-31, -26],
      [-79, -19],
      [-138, 28],
      [-49, -9],
      [-63, -61],
      [-65, 14],
      [-17, -83],
      [-83, -26],
      [-50, 14],
      [-35, -135],
      [-104, 82],
      [-101, -116],
      [-58, -24],
      [-91, 85],
      [-30, -39],
      [-69, 36],
      [-61, -91],
      [-62, 34],
      [-130, 26],
      [13, 77],
      [31, 12],
      [64, 130],
      [-69, 35],
      [29, 59],
      [-45, 45],
      [-54, -42],
      [-20, 50],
      [-256, 4],
      [13, -102],
      [-74, -68],
      [-59, 21],
      [-35, -89],
      [-102, 0],
      [-17, -49],
      [-111, 69],
      [-154, 51],
      [-361, 183],
      [-106, -164],
      [-34, -98],
      [-71, -6],
      [-62, 49],
      [-45, -7],
      [-32, 32],
      [-72, 21],
      [-80, -36],
      [3, -64],
      [-32, -30],
      [-75, 15],
      [-80, -5],
      [-76, -27],
      [-54, -58],
      [-41, -11],
      [-94, 53],
      [-62, 10],
      [-66, -35],
      [-83, 31],
      [-19, 62],
      [43, 10],
      [32, 58],
      [-148, 111],
      [-142, 53],
    ],
    [
      [1466754, 61644],
      [74, 189],
      [17, 73],
      [93, 270],
      [91, 376],
      [50, 250],
      [-1, 81],
      [51, 325],
      [7, 351],
      [-18, 155],
      [-40, 286],
      [-34, 201],
      [-35, 153],
      [-40, 120],
      [-107, 239],
      [-79, 126],
      [-128, 157],
      [-52, 50],
      [-66, 105],
      [-160, 192],
      [-122, 118],
      [-98, 62],
      [-39, 9],
      [-54, 87],
      [-92, 108],
      [-82, 61],
      [-122, 72],
      [-119, 57],
      [-262, 80],
      [-93, 5],
    ],
    [
      [1465294, 66002],
      [-74, 26],
      [54, 51],
      [100, 209],
      [129, 12],
      [72, 58],
      [-8, 60],
      [84, 22],
      [91, 127],
      [71, 31],
      [37, -20],
      [51, 115],
      [-36, 107],
      [5, 51],
      [92, 92],
      [52, -16],
      [87, 58],
      [19, 38],
      [-32, 60],
      [-24, 122],
      [9, 64],
      [-17, 41],
      [25, 71],
      [-11, 48],
      [23, 118],
      [38, 15],
      [23, 85],
      [-52, 30],
      [-38, 51],
      [-6, 110],
      [-21, 48],
      [16, 39],
      [-161, 52],
      [-162, 211],
      [-9, 110],
      [-65, 68],
      [-18, 45],
      [51, 11],
      [35, 78],
      [16, 87],
      [-61, 23],
      [-26, 36],
      [-87, 53],
      [-115, 25],
      [-28, 71],
      [14, 71],
      [80, 86],
      [33, 111],
      [67, 56],
      [12, 44],
      [-23, 72],
      [38, 6],
      [80, -48],
      [21, 61],
      [71, -24],
      [43, 64],
      [57, 116],
      [67, 82],
      [-21, 47],
      [52, 13],
      [17, 46],
      [89, 39],
      [-13, 86],
      [38, -7],
      [9, 75],
      [54, 10],
      [18, 36],
      [86, 77],
      [116, -54],
      [38, 55],
      [-99, 94],
      [17, 68],
      [-146, 23],
      [-60, -34],
      [-89, 50],
      [-66, -1],
      [9, 55],
      [57, 24],
      [-11, 63],
      [-45, 41],
      [-13, 135],
      [-78, 30],
      [-107, -78],
      [7, 59],
      [-41, 56],
      [-67, -21],
      [-63, 9],
      [32, 107],
      [-24, 38],
      [30, 72],
      [-32, 18],
      [-20, 72],
      [52, 98],
      [-55, 114],
      [-38, -22],
      [-86, -14],
      [-38, -36],
      [-57, 88],
      [-44, 29],
      [-4, 64],
      [-32, 22],
      [3, 168],
      [-54, 61],
      [88, 5],
      [33, 86],
      [17, 84],
      [110, 42],
      [51, -16],
      [17, 39],
    ],
    [
      [1465620, 71607],
      [14, 40],
      [95, 30],
      [25, 30],
      [177, 26],
      [-12, 98],
      [43, 70],
      [36, 13],
      [-3, 72],
      [46, 23],
      [-28, 55],
      [-14, 97],
      [54, -1],
      [57, 30],
      [68, -16],
      [175, 52],
      [121, 87],
      [23, 43],
      [54, -31],
      [45, 10],
      [45, 48],
      [110, -9],
      [13, -28],
      [142, 22],
      [95, -14],
      [25, -37],
      [46, -11],
      [87, 37],
      [59, 87],
      [70, 10],
      [82, 132],
      [62, -65],
      [12, 151],
      [61, 79],
      [70, -17],
      [-12, -56],
      [16, -58],
      [175, 45],
      [17, 78],
      [75, -9],
      [4, 43],
      [82, 1],
      [5, -28],
      [100, -43],
      [109, 6],
      [117, 23],
      [56, -123],
      [93, -74],
      [96, 9],
      [191, 54],
      [56, 71],
      [60, 26],
      [-6, -132],
      [-74, -137],
      [-59, -83],
      [-101, -81],
      [14, -14],
      [-16, -103],
      [22, -26],
      [-32, -67],
      [-34, -26],
      [-52, -107],
      [-74, -96],
      [-46, -79],
      [30, -129],
      [37, -48],
      [58, -27],
      [87, -139],
      [10, -76],
      [-64, -39],
      [-69, -139],
      [66, -97],
      [0, -34],
      [80, -103],
      [-60, -105],
      [2, -25],
      [-50, -103],
      [22, -78],
      [24, -9],
      [-10, -74],
      [181, 26],
      [85, 40],
      [60, -32],
      [169, 3],
      [-141, -180],
      [-2, -73],
      [-49, -87],
      [-73, -16],
      [26, -59],
      [-27, -111],
      [81, -46],
      [22, -73],
      [-23, -128],
      [-11, -152],
      [-318, -608],
      [461, -108],
      [148, 44],
      [150, -47],
      [45, 49],
      [74, -36],
      [43, 8],
      [45, -33],
      [90, -3],
      [66, 59],
      [58, 94],
      [87, 24],
      [22, 36],
      [-48, 35],
      [0, 41],
      [49, 19],
      [97, 89],
      [4, 39],
      [-31, 53],
      [67, 28],
      [73, 10],
      [-3, 40],
      [145, 19],
      [45, 45],
      [-4, 97],
      [50, 50],
      [282, -36],
      [153, -2],
      [127, 27],
      [19, 26],
      [76, 22],
      [52, 43],
      [61, -10],
      [59, -39],
    ],
    [
      [1456747, 54386],
      [24, 59],
      [-15, 45],
      [34, 77],
      [4, 173],
      [26, 16],
      [74, -21],
      [66, 70],
      [43, 70],
      [-22, 36],
      [41, 55],
      [59, 27],
      [30, 59],
      [-5, 87],
      [59, 37],
      [102, 41],
      [13, 56],
      [45, 49],
      [15, 70],
      [-18, 57],
      [-65, 37],
      [12, 86],
      [85, 43],
      [2, 61],
      [50, 113],
      [48, 75],
      [66, 48],
      [91, 109],
      [65, 33],
      [-53, 61],
      [-28, 59],
      [18, 72],
      [80, -33],
      [223, 132],
      [223, 291],
      [-15, 166],
      [24, 58],
      [123, 5],
      [68, 76],
      [62, 6],
      [35, 85],
      [93, 33],
      [8, 63],
      [49, 121],
      [39, 44],
      [-25, 52],
      [14, 35],
      [58, -21],
      [79, 46],
      [113, 2],
      [32, -20],
      [103, 8],
      [61, 27],
      [78, 5],
      [22, 50],
      [-55, 60],
      [-70, 46],
      [-95, 98],
      [58, 81],
      [36, 10],
      [16, 54],
      [73, 66],
      [-25, 44],
      [-11, 83],
      [46, 41],
    ],
    [
      [1459133, 58060],
      [100, 32],
      [66, 8],
      [14, 38],
      [68, 89],
      [15, 40],
      [63, 56],
      [127, 59],
      [33, 41],
      [26, 120],
      [-23, 56],
      [101, 99],
      [133, 145],
      [18, 66],
      [45, 37],
      [133, 59],
      [16, 65],
      [87, 42],
      [101, 1],
      [48, 132],
      [6, 82],
      [-6, 142],
      [42, 70],
      [-8, 37],
      [35, 56],
      [-18, 61],
      [26, 34],
      [-19, 103],
    ],
    [
      [1460362, 59830],
      [114, -122],
      [63, -23],
      [-64, -43],
      [-28, -70],
      [110, 46],
      [74, -34],
      [1, -67],
      [59, 40],
      [-52, 78],
      [10, 54],
      [183, 32],
      [55, 53],
      [136, 39],
      [-41, 99],
      [-178, -103],
      [-59, 36],
      [-60, 61],
      [156, 146],
      [61, -26],
      [89, 38],
      [48, 68],
      [19, 78],
      [65, -65],
      [43, 37],
      [-9, 78],
      [27, 22],
      [45, -44],
      [31, 34],
      [-10, 53],
      [91, 49],
      [36, 51],
      [-15, 61],
      [75, -30],
      [-33, -85],
      [-48, -74],
      [21, -19],
      [-29, -86],
      [83, -47],
      [74, 43],
      [-3, 34],
      [83, 80],
      [38, 0],
      [54, 78],
      [-19, 81],
      [38, 29],
      [111, 30],
      [88, 109],
      [125, -73],
      [79, 12],
      [2, -148],
      [-58, -9],
      [-39, 141],
      [-80, -7],
      [-41, -52],
      [45, -18],
      [-93, -108],
      [36, -56],
      [-7, -34],
      [-126, 17],
      [-114, -82],
      [6, -54],
      [93, 48],
      [25, -34],
      [-40, -42],
      [-99, -31],
      [-19, -46],
      [-51, 1],
      [-69, -60],
      [-49, -15],
      [-57, 33],
      [-54, -9],
      [15, 81],
      [30, 27],
      [-24, 43],
      [-64, -72],
      [-55, -40],
      [-109, -49],
      [70, -26],
      [7, -95],
      [-76, -42],
      [49, -27],
      [-26, -70],
      [15, -54],
      [-38, -71],
      [-13, -63],
      [85, 15],
      [24, 46],
      [78, 81],
      [-4, 38],
      [30, 69],
      [108, 19],
      [21, -36],
      [60, 41],
      [70, -44],
      [105, 34],
      [41, -41],
      [-95, -95],
      [-45, 37],
      [-66, -23],
      [-32, -103],
      [83, 3],
      [26, -56],
      [-49, -66],
      [-62, 61],
      [-24, -21],
      [-7, -95],
      [-90, -12],
      [-54, -33],
      [-15, -58],
      [-82, -14],
      [-58, 14],
      [0, -50],
      [-29, -48],
      [42, -27],
      [60, 71],
      [123, 14],
      [79, 42],
      [5, -49],
      [66, -76],
      [-4, -104],
      [-58, -59],
      [-10, -77],
      [7, -98],
      [-113, -45],
      [-51, 24],
      [-53, -69],
      [-68, 29],
      [-189, -55],
      [33, -35],
      [92, -23],
      [25, -61],
      [35, 22],
      [16, 97],
      [45, -39],
      [34, -63],
      [81, -47],
      [101, 13],
      [22, 48],
      [-100, -25],
      [-67, 45],
      [-17, 42],
      [49, 40],
      [85, 14],
      [41, -14],
      [92, 33],
      [146, 125],
      [-58, 65],
      [65, 54],
      [58, -15],
      [107, 50],
      [-5, -72],
      [238, 39],
      [51, 45],
      [67, -13],
      [77, 56],
      [61, -3],
      [129, 23],
      [99, 75],
      [-41, 21],
      [-54, -27],
      [-45, 10],
      [-38, -32],
      [-97, -8],
      [-55, 12],
      [35, 63],
      [-16, 34],
      [-93, -57],
      [-43, 8],
      [-31, -45],
      [-46, -20],
      [-23, -65],
      [-77, -1],
      [17, 48],
      [-19, 54],
      [-49, -20],
      [-46, -49],
      [-60, -16],
      [-52, -52],
      [-140, 38],
      [-16, 80],
      [62, 45],
      [-114, 33],
      [-12, 95],
      [38, 36],
      [41, -9],
      [96, 42],
      [-22, 39],
      [18, 67],
      [85, -27],
      [20, 66],
      [-21, 50],
      [36, 45],
      [91, 20],
      [47, -39],
      [-57, -121],
      [-29, -22],
      [30, -57],
      [-19, -38],
      [51, -22],
      [110, 22],
      [-14, 67],
      [42, 120],
    ],
    [
      [1462038, 59593],
      [59, 6],
      [76, -53],
      [30, -43],
      [39, 49],
      [-64, 47],
      [-7, 74],
      [-49, 65],
      [86, 77],
      [53, 101],
      [-18, 72],
      [-160, 16],
      [-75, -108],
      [-141, -32],
      [-60, 144],
      [55, -26],
      [31, 22],
      [104, 9],
      [-82, 63],
      [18, 63],
      [58, -26],
      [61, 38],
      [-46, 37],
      [22, 67],
      [75, 20],
      [35, -62],
      [-34, -76],
      [-24, -95],
      [49, -30],
      [137, 52],
      [106, -54],
      [75, -128],
      [37, 13],
      [-26, 115],
      [19, 121],
      [44, -56],
      [41, -20],
      [28, -59],
      [65, 14],
      [-15, 86],
      [50, 12],
      [8, 45],
      [-35, 73],
      [59, 25],
      [51, -151],
      [-13, -45],
      [91, -54],
      [19, -113],
      [-40, -12],
      [-21, 68],
      [-65, -105],
      [56, -50],
      [77, -21],
      [43, -34],
      [63, 8],
      [-8, 71],
      [58, 25],
      [12, 46],
      [77, -17],
      [28, 64],
      [83, -15],
      [-54, -44],
      [9, -85],
      [-18, -50],
      [-42, -43],
      [-46, 2],
      [0, -58],
      [-64, -11],
      [51, -69],
      [5, -64],
      [-49, -21],
      [-82, 26],
      [-25, -93],
      [-71, -18],
      [-37, 51],
      [-16, 64],
      [22, 118],
      [-41, 19],
      [-26, -107],
      [-85, -74],
      [55, -44],
      [29, -97],
      [41, -40],
      [86, 20],
      [-65, -90],
      [32, -57],
      [-22, -59],
      [-78, 51],
      [-20, 39],
      [-49, 1],
      [15, -111],
      [-175, -47],
      [42, -47],
      [-49, -38],
      [-39, 20],
      [14, 52],
      [-50, 40],
      [-30, -109],
      [-82, 15],
      [34, 48],
      [-123, -8],
      [-30, -51],
      [-77, -56],
      [-101, -13],
      [-156, -112],
      [-78, -7],
      [-60, -35],
      [-39, 4],
      [-27, -74],
      [126, 49],
      [80, 7],
      [37, -33],
      [129, 77],
      [7, -53],
      [46, -22],
      [-7, -78],
    ],
    [[1462080, 58601], [1, -1]],
    [
      [1462081, 58600],
      [25, 60],
      [49, 54],
      [84, -4],
      [28, 41],
      [86, 28],
      [42, 43],
      [54, -4],
      [66, 51],
      [46, 3],
      [54, -56],
      [61, -16],
      [42, -47],
      [27, 41],
      [70, -72],
      [9, 75],
      [35, 33],
      [91, -46],
      [115, 46],
      [55, -5],
      [57, 65],
      [68, 6],
      [-5, -76],
      [-57, -48],
      [32, -68],
      [-62, -23],
      [-45, -42],
      [-115, -28],
      [-25, -29],
      [77, -91],
      [-20, -32],
      [-61, 3],
      [-87, 27],
      [-36, -75],
      [-2, -62],
      [-46, 16],
      [-121, -44],
      [-44, 6],
      [4, 70],
      [66, -3],
      [48, 45],
      [3, 56],
      [83, 80],
      [-47, 35],
      [-84, -38],
      [-51, -102],
      [-32, 43],
      [38, 34],
      [9, 74],
      [-70, -8],
      [-5, -49],
      [-68, -13],
      [4, -104],
      [-113, -188],
      [-75, -15],
      [24, -60],
      [-69, -92],
      [-38, -13],
      [-56, -142],
      [-34, -192],
      [23, -129],
      [48, -70],
      [43, -21],
      [17, -91],
      [47, -53],
      [-12, -46],
      [-55, -20],
      [15, -81],
      [64, 10],
    ],
    [
      [1462355, 57247],
      [98, -41],
      [53, 8],
      [45, 51],
      [109, -53],
      [-1, -89],
      [19, -72],
      [53, -62],
      [-36, -245],
      [12, -123],
      [53, -140],
      [51, -83],
      [56, -46],
      [65, -25],
      [37, 26],
      [38, -25],
      [148, -31],
      [29, -46],
      [-80, -91],
      [-58, -45],
      [-45, -80],
      [-70, -72],
      [-5, -77],
      [-78, -71],
      [-9, -53],
      [-44, -65],
      [-55, -35],
      [-27, -76],
      [-224, -212],
      [-42, -90],
      [-60, -77],
      [-140, -121],
    ],
    [[1461658, 60005], [-92, -94], [-34, 38], [64, 59], [62, -3]],
    [
      [1461863, 61681],
      [30, -96],
      [-18, -133],
      [-19, -36],
      [28, -45],
      [-5, -58],
      [-52, -24],
      [-33, -95],
      [24, -38],
      [-13, -47],
      [-47, -44],
      [-10, -87],
      [-45, -20],
      [-38, -61],
      [-14, -61],
      [-136, -83],
      [-12, -67],
      [-56, -68],
      [-72, -22],
      [-28, -53],
      [-89, -93],
      [-29, 14],
      [-42, -56],
      [-69, -3],
      [-11, 55],
      [40, 23],
      [25, 85],
      [27, 22],
      [-89, 155],
      [-9, 63],
      [31, 99],
      [47, 11],
      [0, -87],
      [121, 6],
      [-46, 203],
      [-50, 45],
      [35, 29],
      [89, 122],
      [57, 54],
      [18, 63],
      [40, 41],
      [27, 62],
      [71, -17],
      [-61, -137],
      [41, -46],
      [-16, -106],
      [73, 33],
      [36, 58],
      [52, -40],
      [61, 11],
      [36, 84],
      [-46, 6],
      [-69, -39],
      [-68, 61],
      [151, 68],
      [51, 8],
      [31, 110],
      [49, 23],
      [12, 61],
      [-29, 40],
      [18, 47],
    ],
    [[1463035, 59270], [64, -103], [-106, -31], [-13, 59], [55, 75]],
    [
      [1463629, 59638],
      [37, -27],
      [-33, -243],
      [-55, -98],
      [19, -122],
      [-3, -57],
      [-40, -31],
      [-89, 42],
      [-81, -98],
      [-75, 24],
      [-87, -10],
      [-64, -59],
      [-61, -88],
      [-73, 6],
      [-31, 71],
      [-44, -50],
      [-58, -30],
      [-65, 8],
      [-15, -28],
      [-93, -11],
      [13, 73],
      [123, 102],
      [80, 14],
      [71, -55],
      [54, 77],
      [41, -7],
      [30, 109],
      [71, 16],
      [78, 68],
      [87, 17],
      [-34, -63],
      [132, -2],
      [-33, 47],
      [125, 55],
      [42, 95],
      [-16, 69],
      [-73, -96],
      [-100, -23],
      [-4, 70],
      [87, 88],
      [129, 93],
      [8, 54],
    ],
    [
      [1462383, 60396],
      [-14, -138],
      [-34, -90],
      [-53, 10],
      [2, 78],
      [68, 52],
      [-33, 89],
      [64, -1],
    ],
    [
      [1459133, 58060],
      [-104, 75],
      [-66, -18],
      [-46, 75],
      [-114, 67],
      [6, 30],
      [-84, 95],
    ],
    [
      [1458725, 58384],
      [43, 23],
      [39, 88],
      [13, 83],
      [55, 78],
      [69, -4],
      [100, 116],
      [60, -34],
      [84, 63],
      [54, 148],
      [-48, 32],
      [86, 96],
      [137, 77],
      [30, 44],
      [67, 33],
      [53, 51],
      [67, 5],
      [-26, 75],
      [29, 31],
      [61, -9],
      [25, -71],
      [-4, -44],
      [-39, -31],
      [14, -48],
      [78, 57],
      [42, -12],
      [128, 90],
      [68, 104],
      [1, 89],
      [29, 45],
      [52, 1],
      [71, 31],
      [55, 65],
      [-1, 50],
      [49, 56],
      [71, 7],
      [25, 61],
    ],
    [
      [1454382, 95560],
      [12, -46],
      [-53, -92],
      [-42, 32],
      [-15, 65],
      [60, 5],
      [38, 36],
    ],
    [[1670, 115834], [28, -35], [-61, -57], [-39, 59], [25, 31], [47, 2]],
    [
      [3650, 120924],
      [70, -4],
      [21, -20],
      [106, -30],
      [47, -81],
      [6, -52],
      [-107, -2],
      [-41, -41],
      [-4, -52],
      [-152, 19],
      [68, 113],
      [-62, 94],
      [-103, 38],
      [3, 18],
      [114, 17],
      [34, -17],
    ],
    [
      [1464969, 86189],
      [93, -10],
      [40, -40],
      [-50, -35],
      [-79, 12],
      [-40, 51],
      [36, 22],
    ],
    [[1465049, 86549], [40, 4], [16, -51], [-63, 4], [7, 43]],
    [
      [1463989, 88584],
      [-20, -130],
      [-21, -63],
      [75, -143],
      [128, -52],
      [58, -5],
      [14, -46],
      [47, -38],
      [-4, -91],
      [-33, -31],
      [13, -51],
      [44, -9],
      [0, -78],
      [-71, 2],
      [-44, 41],
      [-59, -45],
      [-63, -7],
      [3, -40],
      [70, -15],
      [42, -44],
      [-21, -38],
      [5, -73],
      [29, -115],
      [27, -53],
      [-17, -31],
      [-57, 0],
      [-4, -46],
      [132, 49],
      [-31, -63],
      [49, -4],
      [86, 63],
      [-23, -144],
      [9, -74],
      [-16, -37],
      [15, -59],
      [-28, -53],
      [49, -170],
      [55, -95],
      [43, -40],
      [1, -42],
      [-42, -27],
      [-71, -4],
      [-137, 39],
      [44, 45],
      [-29, 85],
      [-80, 2],
      [-29, -20],
      [-39, 60],
      [46, 31],
      [-24, 80],
      [-36, 55],
      [-73, -34],
      [-23, 37],
      [13, 87],
      [-51, 2],
      [-81, -42],
      [-30, -38],
      [-109, 34],
      [-46, 68],
      [-64, 43],
      [-59, -96],
      [-37, 33],
      [38, 64],
      [-47, 43],
      [37, 48],
      [-55, 13],
      [13, -112],
      [-108, -11],
      [-48, -55],
      [4, -37],
      [91, -72],
      [-32, -130],
      [48, -13],
      [-69, -76],
      [45, -56],
      [87, 105],
      [2, 61],
      [55, 9],
      [45, -22],
      [102, -78],
      [75, 18],
      [47, -29],
      [83, 77],
      [79, -109],
      [42, 40],
      [58, -73],
      [-77, -84],
      [-41, -83],
      [-37, -188],
      [16, -177],
      [32, -117],
      [93, -210],
      [65, -97],
      [85, -48],
      [39, -46],
      [64, 16],
      [47, -17],
      [33, 30],
      [64, -27],
      [-1, -57],
      [25, -113],
      [-26, -15],
      [5, -72],
      [25, -53],
      [-18, -43],
      [-66, -15],
      [23, -40],
      [82, 68],
      [7, 85],
      [-38, 32],
      [16, 39],
      [77, -175],
    ],
    [
      [1464040, 84780],
      [-48, -37],
      [10, -44],
      [-18, -73],
      [-41, 5],
      [-18, 40],
      [-117, -116],
      [-45, -5],
      [-70, -80],
      [-60, 12],
      [-40, 53],
      [-84, -114],
      [12, -54],
      [-72, -46],
      [-37, 26],
      [-72, -9],
      [-18, 65],
      [-51, 61],
      [-56, 6],
      [-43, 78],
      [3, 71],
      [76, 56],
      [68, 24],
      [58, -73],
      [134, -55],
      [-27, 52],
      [-54, 5],
      [-49, 122],
      [83, 49],
      [-9, 26],
      [71, 33],
      [77, 99],
      [-14, 40],
      [48, 49],
      [-17, 46],
      [100, 111],
      [-81, 1],
      [2, -47],
      [-67, -69],
      [-35, -178],
      [-109, -39],
      [-48, -32],
      [-27, 54],
    ],
    [
      [1463355, 84893],
      [-38, 23],
      [-24, -45],
      [-97, 83],
      [-15, 37],
      [39, 50],
      [-87, 38],
      [-28, -60],
      [-83, 56],
      [-10, 81],
      [23, 90],
      [64, 65],
      [-53, 8],
      [-47, -69],
      [-23, -112],
      [-34, 62],
      [28, 89],
      [-32, 14],
      [-42, -52],
      [-22, 55],
    ],
    [
      [1462874, 85306],
      [-43, -79],
      [0, -120],
      [36, -20],
      [87, 15],
      [-19, -88],
      [45, -77],
      [33, -22],
      [51, 9],
    ],
    [
      [1463064, 84924],
      [58, 36],
      [5, -100],
      [26, -32],
      [75, 18],
      [28, -118],
      [-78, 22],
      [-29, -32],
    ],
    [
      [1463149, 84718],
      [-75, -49],
      [6, -49],
      [-69, -23],
      [-67, 29],
      [-54, 57],
      [10, 28],
      [-37, 50],
      [-54, -21],
      [-67, 109],
      [-59, 2],
      [-77, 125],
      [-28, 86],
      [-88, 69],
      [-88, 2],
      [-104, -53],
      [-62, 0],
      [-91, 154],
      [-46, 41],
      [-96, 46],
      [-19, -47],
      [60, -53],
      [90, -117],
      [75, -130],
      [91, -28],
      [45, -52],
      [80, -150],
      [20, -59],
      [58, -56],
      [92, -17],
      [96, -85],
      [22, -182],
      [32, -14],
      [49, -108],
      [-8, -105],
      [10, -45],
      [-71, -74],
      [-128, -69],
      [-94, -15],
      [-99, 7],
      [-93, -12],
      [-98, 18],
      [-40, 72],
      [-7, 84],
      [25, 22],
      [-116, 407],
      [-98, 238],
      [-104, 171],
      [-33, 28],
      [-125, 201],
      [-226, 338],
      [-229, 322],
      [-492, 685],
      [-242, 324],
      [-250, 330],
      [-70, 45],
      [-19, 55],
      [-58, 97],
      [-232, 299],
      [-96, 117],
      [-72, 67],
      [-6, 59],
      [-44, 50],
      [-21, 66],
      [-142, 175],
      [-56, 41],
      [1, 28],
      [-47, 54],
      [22, 59],
      [62, 1],
      [24, 161],
      [42, 15],
      [70, 162],
      [-18, 87],
      [44, 17],
      [16, 158],
      [30, -16],
      [16, -99],
      [39, 1],
      [-15, 86],
      [-30, 60],
      [71, 8],
      [55, 43],
      [59, -53],
      [55, -27],
      [29, 32],
      [-12, 61],
      [38, 12],
      [64, -35],
      [26, 20],
      [0, 74],
      [35, 39],
      [44, -28],
      [39, 53],
      [-10, 89],
      [47, 25],
      [67, -37],
      [115, 74],
      [-14, 58],
      [52, 81],
      [-37, 41],
      [-29, -62],
      [-49, -10],
      [-42, -75],
      [-28, -13],
      [-92, 7],
      [-41, 31],
      [7, -130],
      [-77, -140],
      [-37, 14],
      [-41, 61],
      [-68, -23],
      [-39, 12],
      [-11, -133],
      [-51, 35],
      [-62, -47],
      [-112, 40],
      [-66, -5],
      [-47, -76],
      [-58, 107],
      [-9, -97],
      [55, -26],
      [40, -55],
      [-78, -45],
      [1, -80],
      [-40, -47],
      [14, -28],
      [-23, -87],
      [42, -42],
      [-52, -30],
      [4, -112],
      [-22, -39],
      [-49, -1],
      [-64, 78],
      [-240, 343],
      [-56, 67],
      [-119, 170],
      [-70, 87],
      [14, 88],
      [76, 62],
      [31, -4],
      [91, 65],
      [-41, 24],
      [-54, -46],
      [-77, -22],
      [-8, 41],
      [87, 21],
      [-22, 31],
      [-104, -39],
      [43, -100],
      [-29, -26],
      [-14, -75],
      [-28, -12],
      [-21, 70],
      [-51, 83],
      [-28, 5],
      [-16, 67],
      [-70, 58],
      [-58, 111],
      [59, 2],
      [70, 31],
      [70, 96],
      [-108, -22],
      [-2, -41],
      [-64, -22],
      [-125, 160],
      [-74, 67],
      [-86, 133],
      [-116, 124],
      [-49, 40],
      [30, 107],
      [38, 33],
      [114, -47],
      [33, 9],
      [48, -33],
      [52, -5],
      [52, 35],
      [52, 74],
      [57, 114],
      [18, 96],
      [11, 127],
      [-10, 154],
      [-22, 123],
      [-63, 186],
      [-72, 177],
      [-201, 353],
      [-96, 143],
      [-459, 662],
      [-140, 192],
      [-88, 111],
      [-53, 101],
      [-143, 193],
      [-283, 315],
      [-186, 183],
      [-54, 36],
      [-26, -15],
      [-69, 56],
      [1, 75],
      [-73, 87],
      [-66, 14],
      [-45, 41],
      [78, 124],
      [11, 157],
      [58, -30],
      [59, 0],
      [19, -36],
      [141, -17],
      [142, -72],
      [127, -1],
      [106, 44],
      [66, 56],
      [32, 63],
      [37, 34],
      [69, -25],
      [53, 19],
      [50, -20],
      [86, -8],
      [20, -32],
      [186, 31],
      [83, 98],
      [6, 32],
      [51, 17],
      [64, -23],
      [65, -72],
      [-91, -29],
      [-38, -35],
      [-43, -133],
      [-11, -129],
      [18, -33],
      [-26, -63],
      [16, -81],
      [-25, -40],
      [-96, -10],
      [-9, 119],
      [-114, -25],
      [-32, -31],
      [-54, 97],
      [10, 63],
      [-27, 26],
      [-114, -9],
      [-4, -28],
      [64, -20],
      [12, -93],
      [58, -90],
      [-56, -9],
      [-14, -71],
      [-57, 31],
      [-19, -64],
      [-82, -3],
      [-15, -42],
      [95, -20],
      [37, 40],
      [62, -38],
      [62, 95],
      [54, 6],
      [-35, -79],
      [12, -40],
      [-60, -10],
      [76, -96],
      [44, 115],
      [60, -31],
      [36, -81],
      [-67, -14],
      [-28, -32],
      [63, -29],
      [-12, -67],
      [28, -100],
      [35, 1],
      [13, 74],
      [32, 40],
      [3, 143],
      [-48, 36],
      [-60, 75],
      [-21, 82],
      [28, 40],
      [86, -12],
      [26, -39],
      [4, -79],
      [50, -262],
      [87, -270],
      [65, -132],
      [73, -121],
      [79, -81],
      [161, -98],
      [22, -77],
      [42, -52],
      [69, -43],
      [-10, -32],
      [31, -63],
      [49, 0],
      [27, -62],
      [-29, -20],
      [15, -107],
      [-74, 32],
      [-72, 64],
      [12, 57],
      [-49, 99],
      [-108, 56],
      [1, -52],
      [30, -85],
      [52, -60],
      [8, -67],
      [70, -51],
      [69, 15],
      [17, -94],
      [72, -78],
      [121, -84],
      [115, -37],
      [124, -9],
      [78, -55],
      [-5, -68],
      [-39, -10],
      [-9, -71],
      [-40, -8],
      [-52, -49],
      [-47, -14],
      [-20, -96],
      [-54, -14],
      [8, -138],
      [34, -23],
      [-7, -48],
      [36, -85],
      [36, 58],
      [126, -16],
      [67, 28],
      [20, -60],
      [83, 80],
      [39, 5],
      [30, 120],
      [38, 67],
      [-25, 103],
      [-42, 123],
      [-51, 82],
      [-45, 19],
      [-14, 71],
      [-33, 16],
      [-9, 78],
      [157, 14],
      [178, 64],
      [66, 48],
      [54, 91],
      [-45, 106],
      [104, 15],
      [-19, -69],
      [42, -27],
      [-42, -52],
      [30, -39],
      [57, 46],
      [102, -19],
      [-37, -40],
      [109, -65],
      [-30, -47],
      [-62, -22],
      [-27, 14],
      [-64, -77],
      [-95, -36],
      [-20, 23],
      [-70, 13],
      [-32, -34],
      [-10, -127],
      [28, -136],
      [52, -109],
      [73, -85],
      [110, -83],
      [88, 4],
      [43, -43],
      [31, 28],
      [46, -13],
      [79, 14],
      [57, -7],
      [39, 45],
      [34, -70],
      [46, -28],
      [59, 12],
      [-16, 70],
      [-38, 47],
    ],
    [
      [1460167, 91265],
      [-66, 75],
      [-18, 72],
      [22, 30],
      [102, 55],
      [26, 42],
      [49, -49],
      [-8, -69],
      [63, 22],
      [37, 33],
      [34, -31],
      [68, 3],
      [-8, -58],
      [47, -5],
      [55, -76],
      [52, 1],
      [27, 36],
      [38, -16],
      [59, 67],
      [60, -17],
      [20, -43],
      [-3, -71],
      [43, -34],
      [-24, -54],
      [79, -49],
      [-12, -44],
      [95, -21],
      [-9, -64],
      [-31, -63],
      [-71, -15],
      [-79, -72],
      [62, -31],
      [10, -123],
      [74, -7],
      [29, 58],
      [-24, 102],
      [41, 64],
      [56, 20],
      [64, -6],
      [-23, 113],
      [-72, 20],
      [31, 43],
      [60, -12],
      [153, 82],
      [58, -49],
      [57, 40],
      [48, -8],
    ],
    [
      [1461438, 91156],
      [-12, -73],
      [54, -60],
      [39, 22],
      [50, -26],
      [67, 4],
      [49, -32],
      [-1, -49],
      [91, -70],
      [-32, -80],
      [36, -121],
      [36, -71],
      [77, -81],
      [66, -16],
      [68, 24],
      [74, -25],
      [93, 30],
      [177, 1],
      [63, -106],
      [35, -85],
      [99, -17],
      [-20, -79],
      [-41, 23],
      [-66, -10],
      [-98, -59],
      [-44, 30],
      [-59, 97],
      [-47, -50],
      [-91, 120],
      [-104, -19],
      [-27, -52],
      [122, -60],
      [64, -59],
      [16, -41],
      [-34, -36],
      [-61, 25],
      [-64, -57],
      [29, -38],
      [165, -7],
      [107, -28],
      [-30, -45],
      [9, -43],
      [75, -52],
      [27, -131],
      [-55, 3],
      [-27, -56],
      [68, 6],
      [80, -67],
      [-8, -24],
      [82, -130],
      [-70, -47],
      [90, -42],
      [0, 70],
      [44, 38],
      [16, -51],
      [48, -13],
      [37, 75],
      [88, -37],
      [67, 10],
      [91, -54],
      [-22, -55],
      [73, 16],
      [-2, 55],
      [-32, 47],
      [-94, 33],
      [1, 53],
      [-99, -25],
      [-29, 64],
      [-45, -51],
      [-78, -15],
    ],
    [
      [1462619, 89557],
      [-62, -18],
      [-34, 133],
      [59, 1],
      [42, -45],
      [27, 49],
      [-55, 25],
      [-49, 50],
      [-38, 88],
      [37, 40],
    ],
    [
      [1462546, 89880],
      [38, -38],
      [5, -44],
      [110, -34],
      [41, -62],
      [32, -1],
      [9, 61],
      [75, -32],
      [40, -63],
      [33, 76],
      [-1, 96],
      [43, 21],
      [65, -44],
      [92, -37],
      [-21, 51],
    ],
    [
      [1463107, 89830],
      [-34, 82],
      [51, 2],
      [106, 75],
      [14, 79],
      [63, 30],
      [-44, 71],
      [77, 32],
      [106, -21],
      [-43, -68],
      [-38, 13],
      [-51, -117],
      [-62, -86],
      [31, -14],
      [12, -86],
      [-21, -11],
      [-29, -100],
      [18, -47],
      [79, -81],
      [50, -95],
      [106, -78],
      [4, -52],
      [93, -30],
      [26, -74],
      [-44, -72],
      [-36, 10],
      [-3, 104],
      [-19, 33],
      [-73, -5],
      [-6, 57],
      [-75, -31],
      [5, -81],
      [40, 24],
      [45, -53],
      [16, -121],
      [34, -27],
      [32, -184],
      [19, -38],
      [104, 22],
      [21, -41],
      [57, -12],
      [55, 34],
      [-14, 63],
      [53, -13],
      [-21, -83],
      [7, -64],
      [31, -40],
      [49, -13],
      [32, -119],
      [59, -20],
    ],
    [[1463005, 90071], [47, -61], [-55, -8], [8, 69]],
    [[1461856, 91203], [-15, -70], [-36, -57], [-46, 53], [19, 39], [78, 35]],
    [
      [1442482, 31008],
      [-21, 57],
      [-52, 67],
      [14, 65],
      [-82, 55],
      [-61, 12],
      [-122, 63],
      [101, 55],
      [80, 105],
      [10, 102],
      [-60, -7],
      [-140, 91],
      [-103, 33],
      [20, 37],
      [-72, 75],
      [16, 53],
      [55, 18],
      [76, 69],
      [-36, 58],
      [48, 52],
      [-17, 70],
      [-70, 81],
      [-73, 34],
      [-70, -3],
      [-42, 36],
      [51, 84],
      [8, 45],
      [54, 29],
      [122, 96],
      [-55, 23],
      [81, 91],
      [-50, 27],
      [-34, 69],
      [51, 30],
      [-14, 130],
      [41, 93],
      [-38, 70],
      [48, 55],
      [1, 63],
      [-47, 56],
      [-79, 11],
      [-34, 61],
      [40, 77],
      [-44, 47],
      [-108, 6],
      [3, 99],
      [120, 67],
      [-18, 61],
      [22, 57],
      [-62, 18],
      [-43, 73],
      [63, 24],
      [20, 58],
      [-19, 70],
      [-62, 1],
      [-80, 60],
      [-68, 17],
      [-87, 100],
      [-37, 66],
      [-23, 109],
      [58, 79],
      [55, 30],
      [22, 59],
      [-107, 116],
      [45, 47],
      [28, 176],
      [-76, 91],
      [30, 35],
      [-99, 42],
      [-26, -72],
      [-187, 2],
      [1, 81],
      [27, 71],
      [56, 63],
      [14, 67],
      [-105, 69],
      [4, 239],
      [-46, 108],
      [44, 28],
      [5, 116],
      [57, 57],
      [-1, 63],
      [25, 93],
      [80, 37],
      [61, 59],
      [61, 189],
      [98, 109],
      [66, 224],
      [71, 80],
      [-5, 22],
      [50, 240],
      [-3, 44],
      [34, 86],
      [51, 48],
      [47, -2],
      [114, 29],
      [47, 91],
      [-16, 90],
      [-44, 57],
      [-65, -10],
      [-114, 190],
      [-21, 77],
      [-78, 6],
      [-125, 91],
      [-60, 203],
      [-80, 58],
      [-218, -176],
      [-221, -288],
      [-219, -467],
      [-82, -69],
      [-115, 54],
      [4, 69],
      [-26, 36],
      [-76, -19],
      [-43, -53],
      [-120, 65],
      [-57, -19],
      [-57, 173],
      [21, 56],
      [-5, 152],
      [-87, -21],
      [-132, 95],
      [-114, 49],
      [23, 139],
      [-43, 159],
      [10, 45],
      [-40, 86],
      [13, 48],
      [-38, 23],
      [-103, -14],
      [-67, -83],
      [-137, -95],
      [-70, 1],
      [-75, -42],
      [-106, -110],
      [-326, -82],
      [-570, -12],
      [-85, 165],
      [0, 81],
      [18, 101],
      [-78, 80],
      [52, 105],
      [-61, 59],
      [-122, 48],
      [-24, 52],
      [17, 45],
      [-40, 76],
      [-38, 19],
      [14, 64],
      [-93, 52],
      [118, 95],
      [85, 37],
      [75, 66],
      [5, 118],
      [35, 47],
      [1, 98],
      [18, 35],
      [-29, 42],
      [76, 75],
      [-41, 57],
      [-56, -10],
      [-46, -46],
      [-117, -7],
      [-107, 57],
      [-3, 53],
      [-48, 22],
      [-143, 108],
      [8, 73],
      [-64, 73],
      [-67, 54],
      [-44, 3],
      [2, 188],
      [-34, 110],
      [-38, 5],
      [22, 149],
      [27, 84],
      [-6, 48],
      [166, 2],
      [23, 102],
      [42, 55],
      [6, 71],
      [34, 39],
      [-8, 51],
      [59, 106],
      [-44, 30],
      [-42, -24],
      [-62, 118],
      [12, 92],
      [84, 129],
      [-31, 47],
      [25, 76],
      [-26, 50],
      [50, 51],
      [72, 170],
      [42, 21],
      [2, 86],
      [51, 46],
      [-11, 158],
      [50, 58],
      [116, 61],
      [62, 123],
      [71, -17],
      [49, 25],
      [66, -9],
    ],
    [
      [1438568, 42005],
      [99, 25],
      [130, -37],
      [107, 8],
      [133, 28],
      [51, -17],
      [127, 92],
      [42, 11],
      [25, 45],
      [69, 33],
      [96, 19],
      [34, 23],
      [122, -10],
      [36, 62],
      [58, 31],
      [77, 12],
      [66, 90],
      [88, 71],
      [101, 56],
      [53, -67],
      [118, -76],
      [66, 17],
      [-11, 59],
      [64, 77],
      [88, 12],
      [35, 52],
      [129, 111],
      [48, 18],
      [27, 66],
      [-17, 70],
      [40, 83],
      [4, 133],
      [61, 80],
      [-32, 120],
      [89, 37],
      [26, 83],
      [71, 28],
      [46, -3],
      [23, 42],
      [216, 47],
      [-8, 92],
      [18, 50],
      [90, 22],
      [-51, 129],
      [76, 15],
      [94, 54],
      [-12, 82],
      [123, -12],
      [58, 39],
      [65, 1],
      [27, 58],
      [127, 24],
      [155, 70],
      [48, -126],
      [125, -31],
      [70, 18],
      [55, -12],
      [92, 16],
      [64, -10],
      [77, 50],
      [62, 127],
      [4, 59],
      [74, 10],
      [49, -16],
      [40, -80],
      [-3, -50],
      [43, -75],
      [57, -18],
      [79, -53],
      [147, 52],
      [5, 26],
      [138, 88],
      [29, 90],
      [60, 46],
      [91, 17],
      [101, -15],
      [78, 105],
      [47, 24],
      [157, -5],
      [13, 61],
      [39, 52],
      [0, 115],
      [-35, 27],
      [15, 100],
      [26, 39],
      [132, 4],
      [34, -33],
      [96, 37],
      [72, 5],
      [82, -14],
      [25, -27],
    ],
    [
      [1448481, 36456],
      [8, -99],
      [-14, -39],
      [-75, -80],
      [-134, -18],
      [-91, -83],
      [32, -57],
      [-26, -45],
      [1, -77],
      [-105, -107],
      [-49, -15],
      [-45, -64],
      [-43, -100],
      [-31, 43],
      [-46, -13],
      [-34, -62],
      [-4, -91],
      [50, -11],
      [114, 70],
      [77, -49],
      [49, 22],
      [83, -105],
      [40, -16],
      [115, 12],
      [21, -56],
      [45, -28],
      [-55, -54],
      [-73, 11],
      [-218, -64],
      [-18, -60],
      [24, -45],
      [-101, -21],
      [-104, -129],
      [-26, -55],
      [-64, -50],
      [-162, -42],
      [-51, -48],
      [58, -40],
      [59, 62],
      [163, 1],
      [93, 51],
      [4, 77],
      [32, 48],
      [39, -22],
      [117, 57],
      [64, 2],
      [49, 40],
      [-10, 74],
      [60, 49],
      [156, 41],
      [24, 35],
      [12, 92],
      [47, -28],
      [13, -180],
      [-24, -10],
      [-31, -99],
      [10, -43],
      [-61, -7],
      [-94, 35],
      [-52, -47],
      [50, -38],
      [105, 39],
      [94, -11],
      [-6, -53],
      [38, -44],
      [-47, -59],
      [-162, 17],
      [-75, -29],
      [-34, 44],
      [56, 18],
      [-4, 39],
      [-110, 55],
      [-8, -67],
      [41, -33],
      [64, -96],
      [-81, -74],
      [-62, 31],
      [-71, -15],
      [-110, 14],
      [-156, -61],
      [-15, 12],
      [-216, -10],
      [-97, -21],
      [-31, -30],
      [-84, -5],
      [-136, -46],
      [-103, -9],
      [-252, -63],
      [-27, -34],
      [-158, -67],
      [-119, -129],
      [-24, -44],
      [-76, -67],
      [-103, -151],
      [-31, -71],
      [-47, -3],
      [-17, -54],
      [17, -56],
      [-18, -166],
      [-56, -83],
      [9, -44],
      [-61, -120],
      [-59, -24],
      [-37, -50],
      [-149, -121],
      [-56, -16],
      [-161, -93],
      [-78, -68],
      [-85, -40],
      [-67, -50],
      [-14, -42],
      [-67, -46],
      [-48, -64],
      [-68, -16],
      [-100, -55],
      [-33, -65],
      [-117, -62],
      [-230, -100],
      [-174, -130],
      [-61, -98],
      [-13, -124],
      [81, -75],
      [-24, -116],
      [12, -87],
      [-75, -19],
      [12, -52],
      [-121, -46],
      [-127, -27],
      [-78, 35],
      [-57, -34],
      [-42, 39],
      [-172, 12],
      [-11, -34],
      [-83, -22],
      [75, -46],
      [128, 91],
      [54, -18],
      [24, -47],
      [52, -3],
      [47, 28],
      [41, -62],
      [-33, -18],
      [-1, -62],
      [-63, -19],
      [24, -47],
      [-66, -32],
      [-96, -15],
      [-28, -54],
      [-150, -8],
      [-29, -75],
      [-93, -57],
      [-147, 29],
      [-188, 19],
      [-116, -24],
      [-44, -92],
      [-102, -15],
      [-45, -54],
      [-17, -79],
      [-48, 24],
      [-50, -49],
      [-106, 28],
      [-98, -74],
      [37, -45],
      [-46, -42],
      [-134, 56],
      [-36, -24],
      [-42, 36],
      [-96, 13],
    ],
    [
      [1428588, 9167],
      [180, -94],
      [78, -20],
      [48, 22],
      [108, -16],
      [81, 32],
      [41, -22],
      [72, 76],
      [71, -29],
      [191, -36],
      [53, 0],
      [177, -60],
      [42, -67],
      [-85, 5],
      [-2, -85],
      [-178, -77],
      [-58, 4],
      [-39, -47],
      [-177, -27],
      [-9, 24],
      [-104, 60],
      [-78, -13],
      [-96, 6],
      [-68, 50],
      [-123, 4],
      [-27, 48],
      [-143, 41],
      [9, 75],
      [-24, 57],
      [-57, 25],
      [94, 108],
      [23, -44],
    ],
    [
      [1429658, 10880],
      [90, -31],
      [58, 31],
      [50, -25],
      [-73, -90],
      [7, -55],
      [-74, -59],
      [101, 3],
      [184, 142],
      [23, -49],
      [58, -14],
      [-44, -78],
      [19, -103],
      [-26, -33],
      [-87, -4],
      [-81, 24],
      [-83, -10],
      [42, -47],
      [-51, -72],
      [122, -33],
      [-110, -104],
      [-171, 127],
      [-25, -42],
      [58, -25],
      [94, -94],
      [-53, -71],
      [76, -24],
      [-41, -36],
      [-67, 16],
      [-49, -36],
      [83, -49],
      [-34, -36],
      [-88, 9],
      [-7, -41],
      [72, -14],
      [110, -46],
      [-11, -76],
      [-62, -19],
      [-76, 33],
      [-35, -87],
      [51, -10],
      [90, -54],
      [19, -55],
      [80, -64],
      [13, -49],
      [-36, -30],
      [57, -67],
      [-37, -29],
      [60, -46],
      [-84, -43],
      [91, -26],
      [51, -48],
      [-14, -96],
      [-78, -67],
      [-122, 5],
      [-87, 17],
      [-132, 52],
      [-184, 111],
      [-16, 59],
      [-90, 29],
      [4, -89],
      [-53, 8],
      [-25, -40],
      [-69, 42],
      [104, 74],
      [-19, 147],
      [9, 111],
      [-103, -8],
      [-60, 42],
      [-34, -16],
      [-21, -72],
      [99, -64],
      [39, -66],
      [-51, -61],
      [-69, 45],
      [-54, 113],
      [-47, -63],
      [43, -19],
      [21, -50],
      [49, -29],
      [-3, -69],
      [-25, -59],
      [58, -51],
      [-26, -53],
      [-154, -16],
      [-74, 60],
      [-124, 32],
      [2, 44],
      [-53, 18],
      [-36, -46],
      [-63, -18],
      [-65, 68],
      [21, 33],
      [-55, 106],
      [43, 18],
      [51, 140],
      [104, 48],
      [81, 17],
      [90, 83],
      [45, 7],
      [25, 50],
      [53, 2],
      [91, 55],
      [71, 7],
      [228, 221],
      [-60, 138],
      [21, 102],
      [67, 78],
      [41, 142],
      [-11, 84],
      [-140, -18],
      [-9, 47],
      [116, 19],
      [12, 33],
      [-97, 58],
      [-121, -28],
      [82, 98],
      [120, 25],
      [67, -20],
      [57, 20],
      [49, 70],
      [69, -21],
      [59, -45],
      [99, 45],
    ],
    [
      [1430170, 11016],
      [62, -36],
      [-10, -66],
      [-117, 9],
      [-44, 24],
      [-60, -49],
      [-11, 82],
      [71, 33],
      [109, 3],
    ],
    [[1431409, 23803], [27, -33], [-150, -79], [-16, 44], [79, 16], [60, 52]],
    [
      [1442203, 772],
      [69, -22],
      [-51, -111],
      [25, -118],
      [78, 26],
      [11, -80],
      [-44, -32],
      [22, -58],
      [110, -25],
      [-12, -76],
      [-97, -3],
      [-124, 94],
      [-202, 4],
      [35, -52],
      [147, -19],
      [159, -83],
      [-96, -98],
      [-156, -51],
      [-113, -17],
      [-27, 27],
      [-85, -13],
      [9, 53],
      [-69, 31],
      [-116, 86],
      [-24, 39],
      [-161, -4],
      [-101, 103],
      [130, 64],
      [43, -49],
      [58, 13],
      [51, -48],
      [66, -9],
      [124, 110],
      [34, 116],
      [57, 49],
      [40, 88],
      [59, 27],
      [151, 38],
    ],
    [
      [1481938, 15266],
      [30, -6],
      [1, -85],
      [-40, -62],
      [-52, -22],
      [-28, -79],
      [-81, 0],
      [-91, 54],
      [28, 122],
      [126, 82],
      [107, -4],
    ],
    [
      [1439381, 30422],
      [41, -69],
      [5, -48],
      [-22, -87],
      [-81, -51],
      [-129, 66],
      [153, 138],
      [33, 51],
    ],
    [[1438185, 29558], [36, -59], [-76, -5], [40, 64]],
    [
      [1436822, 41102],
      [39, 6],
      [-32, 99],
      [-8, 143],
      [-47, -31],
      [-72, 39],
      [-45, 56],
      [-73, 33],
      [-160, 28],
      [17, 82],
      [-3, 126],
      [38, 93],
      [-7, 173],
      [-50, 63],
      [76, 23],
      [103, 153],
      [-2, 42],
      [58, 98],
      [140, 102],
      [13, 47],
      [51, 15],
      [38, 63],
      [107, 45],
      [68, -26],
      [57, 46],
      [50, 96],
      [26, -61],
      [37, -7],
      [3, -60],
      [86, -130],
      [-29, -59],
      [9, -81],
      [77, -78],
      [24, -306],
      [74, -45],
      [28, 40],
      [-40, 108],
      [9, 94],
      [-15, 106],
      [-52, 105],
      [6, 102],
      [-82, 108],
      [-91, 59],
      [21, 41],
      [-61, 23],
      [7, 151],
      [-45, 46],
      [47, 21],
      [59, -9],
      [131, 11],
      [68, -36],
      [58, -3],
      [53, 26],
      [74, 154],
      [10, 44],
      [-54, 50],
      [-128, 71],
      [-47, 0],
      [-43, 36],
    ],
    [
      [1437398, 43237],
      [108, -13],
      [222, -104],
      [109, -5],
      [47, 35],
      [45, -10],
      [66, 19],
      [25, 36],
      [94, 51],
      [105, 9],
      [47, -39],
      [59, 15],
      [39, -17],
      [83, -83],
      [70, 7],
      [88, -18],
      [41, -121],
      [-33, -96],
      [25, -51],
      [66, -39],
      [4, -72],
      [177, -34],
      [-15, -73],
      [-207, -124],
      [-24, -42],
      [60, -61],
      [-21, -105],
      [-51, -70],
      [16, -42],
      [-57, -68],
      [-18, -117],
    ],
    [
      [1442482, 31008],
      [-64, -21],
      [-57, -73],
      [-42, 24],
      [-85, -104],
      [-169, 21],
      [-56, -18],
      [-56, 51],
      [-7, 56],
      [64, 56],
      [-81, 45],
      [-61, -14],
      [44, -63],
      [-28, -55],
      [-92, -19],
      [-44, -59],
      [20, -36],
      [-123, -13],
      [-84, -33],
      [-17, 52],
      [-64, 4],
      [-36, -36],
      [-94, -31],
      [-61, 43],
      [-57, -20],
      [-63, 9],
      [-99, 54],
      [-177, 13],
      [-147, -30],
      [-53, 10],
      [-15, 48],
      [-57, 31],
      [-16, 97],
      [27, 69],
      [-27, 43],
      [-79, 47],
      [-58, 67],
      [-129, 40],
      [-241, 23],
      [-126, -10],
      [-104, 21],
      [-66, 37],
      [-35, -8],
      [-87, 47],
      [-180, -42],
      [4, -59],
      [60, 2],
      [43, -56],
      [-107, -67],
      [-143, -54],
      [-129, -26],
      [-147, 18],
      [-107, 3],
      [-88, 39],
      [-100, 20],
      [-110, -28],
      [62, 86],
      [161, 22],
      [102, -40],
      [51, 2],
      [131, -23],
      [132, -35],
      [61, 31],
      [-53, 52],
      [-5, 39],
      [-87, 7],
      [-61, 42],
      [-122, 24],
      [-141, 2],
      [-174, -49],
      [-49, 72],
      [-39, 98],
      [-93, -53],
      [-23, 13],
      [-103, -34],
      [-1, -85],
      [34, -60],
      [4, -63],
      [32, -16],
      [60, 28],
      [102, -87],
      [17, -35],
      [-53, -59],
      [-81, -4],
      [-72, 157],
      [-108, 21],
      [-48, 58],
      [13, 46],
      [-41, 72],
      [-148, 33],
      [27, 33],
      [-16, 58],
      [-37, 44],
      [43, 50],
      [110, -16],
      [41, 31],
      [64, -5],
      [-47, -132],
      [83, 15],
      [-35, 86],
      [58, 32],
      [67, 6],
      [128, 70],
      [56, 10],
      [39, 85],
      [-65, 81],
      [-33, 192],
      [-52, 34],
      [-94, -53],
      [65, -103],
      [-140, -109],
      [45, -94],
      [-2, -32],
      [-108, -18],
      [-50, 23],
      [-128, 217],
      [-40, 117],
      [-145, 227],
      [-112, 104],
      [-87, 61],
      [-148, 60],
      [-76, 16],
      [-96, -2],
      [-82, -24],
      [-98, -69],
      [-15, 109],
      [-60, 6],
      [-91, -22],
      [48, -41],
      [121, -67],
      [31, -64],
      [-1, -61],
      [-57, 2],
      [-148, 44],
      [-136, 74],
      [-151, 20],
      [-93, -27],
      [-44, -51],
      [40, -48],
      [-15, -51],
      [-86, 23],
      [-60, 79],
      [-57, 18],
      [-69, -8],
      [-80, -88],
      [-65, -11],
      [-85, 57],
      [-21, 48],
      [-40, 3],
      [-64, 42],
      [28, 88],
      [-81, 66],
      [-85, -12],
      [-34, 19],
      [-13, 72],
      [40, 36],
      [100, 24],
      [35, 23],
      [11, 81],
      [-37, 80],
      [-47, 52],
      [-221, 184],
      [-33, 42],
      [-164, 117],
      [-325, 150],
      [-160, 50],
      [-178, 22],
      [-140, -5],
      [-61, -14],
      [-46, -136],
      [-27, -24],
      [-123, -46],
      [-19, -47],
      [45, -55],
      [-7, -131],
      [-50, -20],
      [-26, -48],
      [-88, -22],
      [-70, 34],
      [-77, 14],
      [-115, -8],
      [-102, -38],
      [-65, -44],
      [-39, 19],
      [-75, -9],
      [-248, 54],
      [-98, -20],
      [-173, 73],
      [-190, 66],
      [-151, -22],
      [-117, 42],
      [-90, -3],
      [-28, 37],
      [-45, -12],
      [-130, 5],
      [-43, 42],
      [-84, 8],
      [-124, -34],
      [-81, 0],
      [-101, -37],
      [-56, 40],
      [-148, 7],
      [-32, 37],
      [-96, -8],
      [-26, -25],
      [-143, 49],
      [-71, 99],
      [-4, 60],
      [-49, 59],
      [-57, 9],
      [29, 87],
      [30, -4],
      [225, 142],
      [106, 0],
      [33, 29],
      [-33, 47],
      [13, 77],
      [88, 92],
      [152, 47],
      [16, 58],
      [36, 28],
      [-60, 170],
      [29, 42],
      [213, 46],
      [106, 51],
      [89, 68],
      [22, 63],
      [-63, 26],
      [-49, -84],
      [-257, -46],
      [-11, -48],
      [-133, -48],
      [-67, -93],
      [118, -150],
      [-7, -44],
      [-98, -44],
      [28, 108],
      [-52, -8],
      [-8, -58],
      [-32, -24],
      [-40, -97],
      [-73, 88],
      [6, 51],
      [64, 54],
      [37, 103],
      [-189, -201],
      [-83, -32],
      [-16, -61],
      [-165, 76],
      [-43, -73],
      [-66, -19],
      [4, -54],
      [-74, -6],
      [-13, 84],
      [118, 164],
      [58, 217],
      [33, 64],
      [84, 48],
      [98, 12],
      [85, 32],
      [147, -3],
      [123, 34],
      [83, 95],
      [-111, 7],
      [-151, -69],
      [-106, 8],
      [-134, 33],
      [-7, 51],
      [70, 103],
      [53, 32],
      [39, 84],
      [-7, 108],
      [-50, -48],
      [-5, -59],
      [-109, -90],
      [-112, -213],
      [-63, -33],
      [-77, -67],
      [-79, -16],
      [-34, 16],
      [-132, -108],
      [-32, 12],
      [-70, -44],
      [-103, 33],
      [-23, -79],
      [-30, 6],
      [-50, 77],
      [-1, 63],
      [25, 40],
      [-24, 38],
      [8, 122],
      [-38, 102],
      [-67, 101],
      [38, 53],
      [-8, 97],
      [19, 66],
      [-31, 60],
      [21, 61],
      [38, 8],
      [32, 78],
      [86, 32],
      [22, 40],
      [165, 75],
      [146, 3],
      [73, 26],
      [21, -41],
      [83, 3],
      [-11, -59],
      [85, 45],
      [24, 33],
      [247, 56],
      [84, 27],
      [191, 36],
      [33, 20],
      [208, 30],
      [96, -16],
      [99, 15],
      [148, 118],
      [186, 8],
      [112, 44],
      [-45, 48],
      [62, 90],
      [-59, 18],
      [-35, -69],
      [-5, -61],
      [-48, 13],
      [-93, -28],
      [-117, 52],
      [-54, -11],
      [-81, 29],
      [-22, 28],
      [-84, 7],
      [-85, -51],
      [-279, -70],
      [-5, 127],
      [31, 62],
      [14, 74],
      [47, 49],
      [80, 23],
      [186, 34],
      [101, 44],
      [147, 21],
      [58, 30],
      [68, -8],
      [46, 42],
      [-19, 28],
      [-181, -43],
      [-53, 26],
      [-155, -72],
      [-135, -51],
      [-183, -33],
      [-41, 12],
      [-11, 78],
      [5, 142],
      [-28, 73],
      [29, 31],
      [108, 15],
      [46, -10],
      [75, 86],
      [130, 43],
      [79, 51],
      [82, -2],
      [57, 37],
      [80, -48],
      [144, -11],
      [73, 42],
      [-196, -3],
      [-41, 25],
      [2, 62],
      [92, 45],
      [163, 32],
      [62, 82],
      [-100, -25],
      [-52, -40],
      [-208, -67],
      [-16, -47],
      [-75, -34],
      [-52, 8],
      [-130, -22],
      [-273, -136],
      [-234, -21],
      [-62, 41],
      [-9, 88],
      [75, 258],
      [52, 30],
      [74, -13],
      [50, 45],
      [-79, 57],
      [0, 36],
      [45, 48],
      [5, 66],
      [36, 45],
      [33, 110],
      [133, 246],
      [4, 100],
      [23, 61],
      [72, 92],
      [43, 86],
      [104, 77],
      [45, 108],
      [71, -11],
      [30, -37],
      [96, -63],
      [115, -55],
      [50, 5],
      [81, -43],
      [86, -23],
      [56, -62],
      [185, -98],
      [-38, -73],
      [-35, -6],
      [9, -96],
      [35, -76],
      [43, 35],
      [-41, 67],
      [25, 101],
      [43, 36],
      [122, -38],
      [97, -131],
      [10, -59],
      [46, -56],
      [47, -110],
      [-1, -84],
      [-24, -42],
      [-114, -72],
      [-40, -40],
      [11, -44],
      [84, 76],
      [107, 64],
      [5, 46],
      [73, 42],
      [-49, 82],
      [16, 30],
      [-132, 225],
      [-60, 77],
      [-63, 52],
      [-106, 20],
      [-82, 63],
      [-71, 74],
      [-1, 33],
      [85, 61],
      [34, 49],
      [52, 10],
      [84, -13],
      [45, 21],
      [199, 34],
      [73, -42],
      [16, -68],
      [76, -74],
      [97, -8],
      [-12, 37],
      [-68, -6],
      [-61, 59],
      [6, 44],
      [-64, 94],
      [51, 31],
      [61, 78],
      [-32, 11],
      [-55, -58],
      [-111, 12],
      [-11, -47],
      [-237, -59],
      [-71, 6],
      [-78, -26],
      [-87, 65],
      [-17, 165],
      [-31, 11],
      [-23, 63],
      [-51, 44],
      [4, 36],
      [-41, 99],
      [47, 18],
      [1, 65],
      [61, 66],
      [-82, 110],
      [118, 88],
      [27, 82],
      [74, 36],
      [55, 69],
      [17, 63],
      [64, 49],
      [19, 40],
      [116, 118],
      [51, 21],
      [-24, 52],
      [53, 14],
      [104, 143],
      [40, 87],
      [100, 39],
      [31, 50],
      [113, 107],
      [26, 53],
      [131, 23],
      [-75, 52],
      [100, 57],
      [43, 88],
      [37, 21],
      [19, 59],
      [106, 28],
      [172, 101],
      [10, -113],
      [18, -45],
      [83, -80],
      [8, -63],
      [33, -41],
      [-4, -62],
      [-37, -36],
      [0, -76],
      [40, -34],
      [73, 5],
      [45, -96],
      [-104, -84],
      [67, -11],
      [21, 44],
      [52, 31],
      [82, -30],
      [4, 46],
      [-58, 37],
      [-58, 85],
      [-73, 37],
      [-23, 36],
      [43, 57],
      [-2, 77],
      [-26, 98],
      [-48, 57],
      [-34, 103],
      [-42, 41],
      [-6, 106],
      [21, 28],
      [132, 78],
      [53, 57],
      [63, 28],
      [131, 85],
      [154, -16],
      [50, -53],
      [116, -66],
      [17, -38],
      [-74, -66],
      [-108, -139],
      [12, -80],
      [67, -56],
      [83, -45],
      [26, 21],
      [-112, 80],
      [-27, 43],
      [45, 33],
      [30, 68],
      [108, 139],
      [6, 44],
      [-71, 28],
      [-23, 50],
      [-68, 73],
      [-29, 116],
      [78, 74],
      [53, 14],
      [94, -40],
      [65, 15],
      [92, -17],
      [125, -185],
      [44, 8],
      [-49, 85],
      [2, 31],
      [-96, 65],
      [-43, 5],
      [-40, 60],
      [-76, 41],
      [46, 165],
      [65, 87],
      [105, 47],
      [49, -9],
      [117, -125],
      [60, 35],
      [-69, 114],
      [-72, 77],
      [64, 4],
      [85, 44],
      [5, 22],
      [155, 116],
      [82, 1],
      [53, 23],
      [45, 109],
      [66, -1],
      [31, 29],
      [62, -25],
      [-10, -34],
      [62, -21],
      [15, -63],
      [114, -20],
      [75, -31],
      [102, -125],
      [88, -72],
      [5, -49],
      [61, -57],
    ],
    [
      [1434837, 27893],
      [30, -29],
      [-196, -158],
      [-69, 57],
      [84, 91],
      [39, 20],
      [112, 19],
    ],
    [
      [1435749, 30153],
      [-120, -37],
      [-32, 22],
      [-31, 88],
      [-44, 27],
      [15, 66],
      [169, 9],
      [-7, -48],
      [68, -10],
      [19, -81],
      [-37, -36],
    ],
    [[1435953, 28128], [84, -42], [-42, -88], [-92, 67], [50, 63]],
    [
      [1437573, 29197],
      [-67, -71],
      [2, -23],
      [111, -13],
      [57, 9],
      [96, 44],
      [-3, 34],
      [101, 14],
      [40, 36],
      [-32, 41],
      [23, 157],
      [35, 5],
      [38, -55],
      [-48, -14],
      [-23, -60],
      [43, -30],
      [-27, -82],
      [71, -63],
      [85, -37],
      [-54, -67],
      [69, -89],
      [-63, -41],
      [18, -79],
      [-43, -24],
      [5, -71],
      [-95, 60],
      [-49, -37],
      [30, -67],
      [98, -47],
      [62, -2],
      [7, -44],
      [-35, -35],
      [-115, 67],
      [-128, 5],
      [48, -102],
      [-71, -26],
      [-73, -1],
      [36, -67],
      [-163, 53],
      [-78, 39],
      [-43, -56],
      [-63, -47],
      [-64, -20],
      [-112, 35],
      [-75, -37],
      [-16, -29],
      [-89, -27],
      [-25, 52],
      [-94, -62],
      [-18, -57],
      [-69, -3],
      [-51, -75],
      [-136, -71],
      [-125, -19],
      [-65, -38],
      [-181, 42],
      [-61, 43],
      [-143, -38],
      [-57, 116],
      [-137, -51],
      [-55, -46],
      [28, -27],
      [-16, -53],
      [-76, -49],
      [-40, 41],
      [-41, -82],
      [-57, 21],
      [-56, -14],
      [-39, -95],
      [-46, 8],
      [-6, -62],
      [65, 27],
      [72, -18],
      [69, 12],
      [25, 75],
      [52, -1],
      [43, -48],
      [-64, -170],
      [-85, -6],
      [-63, -32],
      [-24, 116],
      [-48, 16],
      [-24, -61],
      [-64, -74],
      [-151, -80],
      [-88, 23],
      [-32, -15],
      [-66, 34],
      [-98, -20],
      [-39, 17],
      [-38, 106],
      [50, 23],
      [-6, 65],
      [32, 133],
      [91, 33],
      [20, 49],
      [135, 18],
      [-5, 46],
      [59, 21],
      [23, 38],
      [89, 0],
      [41, 57],
      [-2, 56],
      [-70, 6],
      [65, 67],
      [-4, 60],
      [24, 97],
      [-76, 56],
      [-4, 46],
      [81, 27],
      [17, 37],
      [62, 44],
      [9, 60],
      [47, 44],
      [93, 56],
      [64, 12],
      [228, -67],
      [34, 29],
      [-109, 152],
      [55, 49],
      [-58, 67],
      [-6, 58],
      [-48, 17],
      [27, 67],
      [67, -15],
      [104, 25],
      [104, 69],
      [55, 63],
      [39, 144],
      [-8, 115],
      [-60, 54],
      [24, 59],
      [-36, 97],
      [-41, 20],
      [-81, 185],
      [-79, 60],
      [77, 55],
      [-41, 85],
      [-17, 71],
      [49, 50],
      [33, 87],
      [-5, 91],
      [80, 10],
      [48, 71],
      [36, -11],
      [96, 49],
      [88, -26],
      [116, -55],
      [80, 31],
      [42, 66],
      [174, -83],
      [78, 0],
      [44, -49],
      [155, -67],
      [44, -195],
      [30, -53],
      [54, -20],
      [-14, -61],
      [73, -78],
      [57, -10],
      [26, -75],
      [132, -34],
      [46, -70],
      [-2, -83],
      [56, 11],
      [112, -58],
      [40, 44],
      [52, -77],
      [-76, -30],
      [55, -76],
      [-45, -41],
      [62, -23],
      [-32, -39],
      [-74, 34],
      [-75, -27],
      [-125, 37],
      [-40, -38],
      [-62, 29],
      [-41, -47],
      [-52, 2],
      [36, 79],
      [-69, 57],
      [9, 33],
      [-57, 28],
      [-41, -87],
      [-63, -7],
      [-36, -41],
      [40, -150],
      [-119, -49],
      [-39, -66],
      [-103, -76],
      [80, 1],
      [26, 32],
      [92, 26],
      [9, 65],
      [57, 0],
      [32, 67],
      [75, 24],
      [72, -37],
      [48, -76],
      [118, -14],
      [55, -40],
      [129, -6],
      [82, -24],
      [-40, -36],
      [-95, -17],
    ],
    [
      [1431729, 36288],
      [56, -35],
      [78, -83],
      [-14, -75],
      [14, -145],
      [14, -49],
      [-17, -58],
      [33, -59],
      [-28, -100],
      [-72, -102],
      [-62, -51],
      [-90, -21],
      [-15, 54],
      [-50, 11],
      [-128, -60],
      [-91, -22],
      [-11, 42],
      [-53, 18],
      [2, 49],
      [37, 71],
      [-89, 10],
      [40, 51],
      [-17, 42],
      [-73, -3],
      [35, 83],
      [-35, 11],
      [-7, 60],
      [-272, -235],
      [-62, -70],
      [-104, -48],
      [13, 107],
      [115, 101],
      [48, 76],
      [41, 15],
      [92, 134],
      [-6, 57],
      [119, 93],
      [63, 10],
      [74, 69],
      [49, -24],
      [47, 69],
      [68, -40],
      [94, 26],
      [95, 1],
      [69, 20],
    ],
    [
      [1432888, 38631],
      [29, -44],
      [-24, -99],
      [-56, -55],
      [-9, -83],
      [23, -61],
      [66, -113],
      [15, -52],
      [55, -60],
      [4, -94],
      [59, -110],
      [-41, -14],
      [-189, 34],
      [-19, 46],
      [-134, 69],
      [-14, 34],
      [-137, 144],
      [16, 59],
      [85, 79],
      [6, 57],
      [71, 79],
      [28, 55],
      [92, 68],
      [8, 42],
      [66, 19],
    ],
    [
      [1465294, 66002],
      [-39, -29],
      [-169, -27],
      [-72, 8],
      [-110, 69],
      [-8, 18],
      [-160, 78],
      [-148, 48],
      [-89, 17],
      [-52, 26],
      [-88, 3],
      [-97, 66],
      [-68, 84],
      [-151, 102],
      [-114, 60],
      [-142, 127],
      [-131, 161],
      [-36, 62],
      [-113, 156],
      [-47, 46],
      [-186, 125],
      [-116, 42],
      [-33, -2],
      [-111, 86],
      [-133, 70],
      [-167, 14],
      [-64, 28],
      [-287, 14],
      [-97, 72],
      [-133, 27],
      [-69, 33],
      [-72, -12],
      [-123, 80],
      [-41, 4],
      [-66, 85],
      [-66, 10],
      [-59, 93],
      [-77, 74],
      [-72, 113],
      [-40, 43],
      [-93, 55],
      [-55, 87],
      [-81, 53],
      [-38, 52],
      [-76, 202],
      [-15, 164],
      [-40, 99],
      [-34, 198],
      [22, 80],
      [78, 119],
      [-10, 63],
      [20, 76],
      [68, 121],
      [88, 64],
      [19, 42],
      [48, 16],
      [56, 78],
      [155, 98],
      [55, 14],
      [62, 62],
      [123, 16],
      [164, 100],
      [43, 43],
      [53, 21],
      [51, 63],
      [12, 63],
      [42, 32],
      [51, -30],
      [200, 51],
      [72, 75],
      [73, 41],
      [56, 52],
      [61, 7],
      [77, 60],
      [28, 42],
      [93, 46],
      [112, 2],
      [58, 32],
      [103, -26],
      [187, 21],
      [42, -38],
      [65, -18],
      [111, 5],
      [72, 28],
      [34, -8],
      [81, 37],
      [108, 95],
      [109, 138],
      [62, 94],
      [92, 116],
      [103, 41],
      [150, 166],
      [109, 244],
      [72, 263],
    ],
    [[1464546, 71693], [22, 138], [2, 83], [24, 44]],
    [
      [1464594, 71958],
      [7, -84],
      [96, 10],
      [63, -64],
      [49, 23],
      [84, 6],
      [159, -39],
      [47, 6],
      [46, -51],
      [33, -5],
      [64, 46],
      [84, 22],
      [63, -21],
      [74, -69],
      [14, -61],
      [78, -53],
      [65, -17],
    ],
    [
      [1455742, 53488],
      [-105, 11],
      [-34, -51],
      [-28, -88],
      [-32, -20],
      [-120, 38],
      [-134, 91],
      [-90, 21],
      [34, 44],
      [-84, 175],
      [50, 47],
      [-32, 135],
      [19, 124],
      [-49, 125],
      [-70, 16],
      [-228, -2],
      [-38, 20],
      [-18, 62],
      [-110, 4],
      [-11, 81],
      [-52, 6],
      [4, 85],
      [-93, -35],
      [-204, 6],
      [-22, 60],
      [11, 61],
      [-40, 21],
      [-75, 146],
      [56, 101],
      [10, 65],
      [-163, 43],
      [-42, 48],
      [22, 59],
      [-30, 73],
      [1, 47],
      [-69, 30],
      [-3, 68],
      [104, 126],
      [-57, 113],
      [122, 36],
      [1, 83],
      [36, 34],
      [58, 5],
      [2, 410],
      [-45, -2],
      [-34, 80],
      [12, 34],
      [74, 60],
      [26, 106],
      [-35, 19],
      [56, 92],
      [38, -10],
      [96, 67],
      [56, 1],
      [52, 53],
      [98, -30],
      [99, 30],
      [66, -54],
      [35, 44],
      [-52, 53],
      [28, 80],
      [-61, 84],
      [-4, 67],
      [106, 63],
      [68, -47],
      [47, 9],
      [7, 56],
      [114, 70],
      [40, 55],
      [17, 85],
      [-32, 119],
      [74, 26],
      [8, 119],
      [-17, 92],
      [122, 106],
      [69, 17],
      [27, 55],
      [95, 43],
      [27, 91],
      [-9, 80],
      [33, 22],
      [-5, 47],
      [-46, 19],
      [4, 57],
      [70, 9],
      [83, 64],
      [31, -42],
      [142, -13],
      [61, -29],
      [167, 76],
      [23, 72],
      [64, 37],
      [43, 3],
      [7, 121],
      [-17, 46],
      [33, 52],
      [-7, 59],
      [42, 63],
      [-7, 93],
      [-42, 20],
      [-19, 87],
      [-37, 46],
      [29, 46],
      [104, 3],
      [84, 53],
      [46, -10],
      [90, 31],
      [17, 36],
      [117, 105],
      [8, 62],
      [-78, 60],
      [-151, -38],
      [-60, 82],
      [-9, 90],
      [-94, 71],
      [-24, 57],
      [-45, 7],
      [-31, 59],
      [-56, 48],
      [-28, 67],
      [-145, 55],
      [18, 56],
      [-45, 79],
      [-81, 6],
      [-19, 65],
      [33, 47],
      [-8, 100],
      [-70, -7],
      [-156, 23],
      [-11, -32],
      [-73, -34],
      [-45, 30],
      [-104, 27],
      [-132, -161],
      [0, -41],
      [-106, 8],
      [-6, 33],
      [41, 90],
      [-97, 118],
      [65, 118],
      [44, 33],
      [57, 5],
      [82, 65],
      [67, 110],
      [-40, 73],
      [-105, 17],
      [-93, -22],
      [-55, 62],
      [-43, -6],
      [-47, 31],
      [30, 45],
      [-23, 38],
      [-58, 23],
      [34, 67],
      [-23, 63],
      [-58, 10],
      [-63, 62],
      [-69, -59],
      [-23, 16],
      [-10, 94],
      [41, 54],
      [-9, 52],
      [33, 55],
      [9, 71],
      [-98, 102],
      [-29, 56],
    ],
    [
      [1454659, 61260],
      [92, 12],
      [88, 41],
      [54, 58],
      [75, 31],
      [130, 135],
      [179, 112],
      [55, 57],
      [38, 75],
      [93, 82],
      [138, 141],
      [98, 57],
      [53, 17],
      [41, 52],
      [76, 40],
      [104, 83],
      [20, -52],
      [-16, -72],
      [-103, -53],
      [22, -45],
      [72, 16],
      [32, 54],
      [90, 33],
      [54, 75],
      [76, 17],
      [119, 9],
      [28, 63],
      [-56, 36],
      [-128, -25],
      [-36, 26],
      [-26, -75],
      [-71, -37],
      [-16, 43],
      [13, 63],
      [32, 34],
      [156, 116],
      [41, 47],
      [190, 129],
      [41, -12],
      [73, 17],
      [32, 31],
      [121, -24],
      [69, -34],
      [89, 3],
      [145, 24],
      [80, 1],
      [106, -23],
      [261, -26],
      [183, -40],
      [152, -47],
      [-55, -22],
      [-199, 55],
      [-308, 40],
      [-327, 13],
      [-53, -14],
      [-99, -67],
      [-17, -67],
      [-70, -84],
      [-86, -130],
      [12, -61],
      [-36, -110],
      [-5, -116],
      [-45, 0],
      [-81, -87],
      [68, -46],
      [-14, -49],
    ],
    [
      [1456503, 61780],
      [49, -32],
      [51, 6],
      [8, -173],
      [-52, -67],
      [24, -47],
      [44, 35],
      [35, -18],
      [113, -186],
      [105, -89],
      [54, -19],
      [28, -39],
      [64, -23],
      [2, -36],
      [54, -57],
      [41, -1],
      [115, -76],
      [190, 3],
      [154, 144],
      [74, -42],
      [46, 40],
      [17, 82],
      [77, 12],
      [65, 29],
      [23, -66],
      [68, -67],
      [16, -41],
    ],
    [
      [1457968, 61052],
      [-48, -41],
      [39, -127],
      [38, -49],
      [56, -14],
      [62, 23],
      [37, -36],
      [21, -69],
      [-80, -67],
      [69, -118],
      [-33, -51],
      [9, -69],
      [-23, -54],
      [20, -99],
      [-57, -47],
      [-29, -84],
      [-97, -15],
      [-20, -104],
      [40, -43],
      [19, -67],
      [-14, -87],
      [-44, -5],
      [-29, -40],
      [52, -94],
      [84, -60],
      [-20, -38],
      [4, -86],
      [-37, -91],
      [-34, 7],
      [-13, -77],
      [40, -76],
      [113, -119],
      [30, 41],
      [50, -53],
      [18, -53],
      [61, -91],
      [-6, -82],
      [72, -117],
    ],
    [
      [1458318, 58800],
      [-47, -46],
      [-11, -43],
      [49, -38],
      [20, -79],
      [50, 25],
      [112, -34],
      [57, -68],
      [36, -74],
      [141, -59],
    ],
    [[1458532, 58619], [109, -13], [-17, -59], [-92, 72]],
    [
      [1458436, 58761],
      [240, -91],
      [-27, -31],
      [-111, -10],
      [-100, 59],
      [-105, 44],
      [30, 58],
      [73, -29],
    ],
    [
      [1464594, 71958],
      [10, 171],
      [57, 530],
      [-3, 58],
      [19, 132],
      [-18, 157],
      [29, 55],
      [-2, 59],
      [29, 81],
      [-12, 88],
      [4, 110],
      [-26, 64],
      [8, 71],
      [-14, 81],
      [80, 3],
      [43, 102],
      [97, 115],
      [38, 79],
      [45, 23],
      [31, 89],
      [-3, 252],
      [-32, 145],
      [36, 41],
      [-13, 112],
      [-30, 129],
      [-74, 240],
      [14, 60],
      [182, -32],
      [89, 62],
      [37, 54],
      [21, -87],
      [43, -60],
      [105, 2],
      [55, -88],
      [65, 69],
      [-31, 68],
      [26, 65],
      [53, 0],
      [51, 37],
      [8, -69],
      [60, -9],
      [-25, 103],
      [112, 48],
      [-15, 36],
      [12, 96],
      [-53, 23],
      [-33, 40],
      [-59, -39],
      [-81, -12],
      [1, 39],
      [-53, 31],
      [-3, -88],
      [-75, -93],
      [-131, -13],
      [2, 75],
      [31, 183],
      [51, 69],
      [67, 53],
      [55, 8],
      [35, -84],
      [70, 57],
      [32, 119],
      [52, 104],
      [30, 21],
      [-47, 92],
      [-66, -24],
      [-109, -7],
      [-52, -59],
      [24, -56],
      [59, -64],
      [-72, -40],
      [-78, -66],
      [-25, 7],
      [-19, 123],
      [-31, 33],
      [25, 102],
      [-10, 83],
      [-82, 271],
      [-2, 104],
      [-23, 15],
      [37, 114],
      [31, 54],
      [79, 53],
      [131, 8],
      [38, 70],
      [40, 22],
      [61, -4],
      [46, -67],
      [21, 49],
      [-28, 54],
      [46, 14],
      [73, -55],
      [96, 24],
      [116, 64],
      [61, -47],
      [28, 51],
      [-80, 44],
      [-137, -47],
      [6, 52],
      [56, 16],
      [79, -14],
      [50, 26],
      [-45, 37],
      [-3, 74],
      [-89, -17],
      [-52, 48],
      [-31, -114],
      [-26, -31],
      [-35, -88],
      [-61, 59],
      [-102, -97],
      [-57, -4],
      [-21, 26],
      [-12, 112],
      [-37, 159],
      [-21, 31],
      [4, 76],
      [-39, 122],
      [4, 52],
      [-62, 160],
      [-18, 114],
      [-81, 173],
      [-14, 59],
      [-7, 147],
      [-53, 146],
      [-36, 65],
      [-9, 61],
      [-64, 157],
      [-51, 93],
      [-16, 77],
      [-35, 37],
      [-9, 170],
      [32, 35],
      [-19, 88],
      [97, -39],
      [44, 14],
      [53, 88],
      [45, 32],
      [49, 92],
      [-144, 17],
      [21, -95],
      [-8, -45],
      [-61, -55],
      [-41, 1],
      [-42, 68],
      [-73, 46],
      [-134, 335],
    ],
    [[1467396, 80663], [49, -161], [-25, -67], [16, -84]],
    [
      [1467436, 80351],
      [19, -38],
      [-27, -42],
      [-16, -108],
      [63, -195],
      [38, -61],
      [14, -106],
      [86, -97],
      [96, -43],
      [88, -16],
      [45, -34],
      [48, 37],
      [103, 8],
      [136, 67],
      [171, 95],
      [61, 83],
      [81, 26],
      [4, 46],
      [-68, 194],
      [-40, 31],
      [-19, 110],
      [42, 161],
      [-29, 77],
      [10, 65],
      [-83, 246],
      [-1, 63],
      [-35, 62],
      [-91, 112],
      [-173, 228],
      [-19, 136],
    ],
    [
      [1467940, 81458],
      [-62, 79],
      [49, 28],
      [67, -25],
      [27, 20],
      [94, -13],
      [21, 45],
      [-46, 24],
      [-47, -13],
      [-77, 22],
      [12, 87],
      [26, 56],
      [65, 13],
      [70, -17],
      [37, 45],
      [44, -30],
      [63, 73],
      [-57, 85],
      [12, 82],
      [-73, 79],
      [-17, 51],
      [-70, 63],
      [-56, -28],
      [-44, 65],
      [-8, 91],
      [9, 64],
      [28, 40],
      [34, 102],
      [-5, 67],
      [-29, 57],
      [-4, 53],
      [70, -19],
      [72, 36],
      [-28, 55],
      [-35, -22],
      [-86, 31],
      [-126, 153],
      [-72, 26],
      [-48, 41],
      [-52, 13],
      [-29, 42],
      [-53, 20],
      [-34, 60],
      [-38, 111],
      [13, 127],
      [-5, 62],
      [79, 21],
      [11, 45],
      [83, 10],
      [76, -36],
      [78, -8],
      [26, -71],
      [41, -32],
      [-11, -69],
      [68, 38],
      [85, -101],
      [56, -32],
      [28, 22],
      [-39, 70],
      [105, 2],
      [57, -63],
      [15, -56],
      [34, -36],
      [63, -12],
      [-2, -41],
      [-61, -15],
      [34, -149],
      [-24, -48],
      [43, -56],
      [51, -3],
      [38, 28],
      [31, -73],
      [49, -8],
      [40, -70],
      [-42, -74],
      [-2, -67],
      [45, -37],
      [-28, -50],
      [-80, 18],
      [-14, -58],
      [23, -41],
      [65, -2],
      [51, 37],
      [84, -41],
      [-18, -28],
      [18, -70],
      [79, -59],
      [-47, -27],
      [-39, -159],
      [72, 9],
      [40, -60],
      [49, 20],
      [-33, 73],
      [28, 16],
      [64, -20],
      [15, 33],
      [-68, 22],
      [-13, 58],
      [92, -19],
      [179, 9],
      [65, 23],
      [59, 79],
      [95, -5],
      [80, 76],
      [93, -140],
      [75, 1],
      [18, -99],
      [-81, 7],
      [-62, -15],
      [-60, -49],
      [-111, -68],
      [-10, -136],
      [-32, -23],
      [-52, 11],
      [-12, -71],
      [-95, -5],
      [-3, -62],
      [32, -71],
      [-30, -48],
      [-41, -16],
      [-71, 47],
      [-27, -25],
      [71, -107],
      [-66, -44],
      [22, -60],
      [48, 25],
      [15, -75],
      [47, -32],
      [66, 127],
      [-82, 48],
      [11, 65],
      [48, 46],
      [-3, 41],
      [82, 37],
      [66, -53],
      [50, 13],
      [24, 73],
      [71, -8],
      [49, -54],
      [87, -50],
      [56, -108],
      [-45, -49],
      [31, -81],
      [30, -1],
      [23, -85],
      [75, -86],
      [3, -96],
      [-57, -37],
      [4, -88],
      [51, -62],
      [8, -111],
      [-25, -57],
      [-69, -100],
      [85, 11],
      [-2, 67],
      [38, 40],
      [39, -117],
      [34, -3],
      [28, -54],
      [-14, -62],
      [18, -26],
      [-13, -176],
      [7, -89],
      [-30, -25],
      [48, -41],
      [-29, -41],
      [-25, -116],
      [16, -139],
      [40, -93],
      [-32, -40],
      [-67, 94],
      [-23, 97],
      [-52, -51],
      [19, -57],
      [42, -46],
      [53, -22],
      [-5, -52],
      [31, -35],
      [4, -73],
      [55, -215],
      [68, -155],
      [39, -46],
      [2, -57],
      [66, -104],
      [9, -49],
      [-24, -53],
      [12, -56],
    ],
    [[1470106, 80591], [5, -99], [-50, 28], [9, 49], [36, 22]],
    [[1470078, 82758], [15, -51], [-68, -1], [3, 65], [50, -13]],
    [
      [1469453, 82954],
      [-21, -63],
      [45, -10],
      [-14, -52],
      [97, -51],
      [35, 1],
      [41, -42],
      [13, -65],
      [-45, -22],
      [-150, 8],
      [-50, 11],
      [49, 57],
      [1, 65],
      [-22, 49],
      [-72, -9],
      [-61, 90],
      [89, 6],
      [65, 27],
    ],
    [[1469389, 83740], [21, -63], [-52, 3], [-11, 42], [42, 18]],
    [[1465305, 58725], [2, -5]],
    [[1465307, 58720], [-2, 5]],
    [[1465305, 58725], [13, -85], [-14, -54], [67, -3], [14, -58], [-36, -45]],
    [
      [1465349, 58480],
      [51, -19],
      [68, 163],
      [41, -51],
      [-34, -107],
      [46, -54],
      [-96, -98],
      [-44, 41],
      [-58, 18],
      [-13, -65],
      [-69, 5],
      [-23, -24],
      [-93, 11],
      [-67, -54],
      [-92, 1],
      [-35, 47],
      [-58, 6],
      [-23, 36],
      [-67, -3],
      [-59, 81],
      [-65, 52],
      [6, 72],
      [-51, 24],
      [-28, 55],
      [40, 131],
      [100, 114],
      [44, -4],
      [26, -41],
      [105, 74],
      [17, 61],
      [41, 22],
      [45, -14],
      [56, 132],
      [47, 68],
      [8, 45],
      [83, 31],
      [30, 66],
      [78, 85],
      [36, 67],
      [153, 119],
      [87, 57],
      [38, -49],
      [-35, -61],
      [-56, -32],
      [20, -52],
      [96, 124],
      [-7, 86],
      [-67, 108],
      [-49, 21],
      [30, 62],
      [70, 39],
      [43, 65],
      [101, 2],
      [123, 124],
      [46, 26],
      [87, 141],
      [37, 87],
      [38, 197],
      [5, 131],
      [31, 61],
      [139, 93],
      [63, 59],
      [97, 128],
      [115, 220],
      [152, 304],
      [55, 130],
    ],
    [
      [1471453, 61228],
      [-50, -130],
      [-4, -84],
      [-51, -121],
      [-19, -76],
      [-60, -56],
      [-26, -79],
      [22, -78],
      [-16, -48],
      [-59, -10],
      [-42, -47],
      [-211, -184],
      [-56, -120],
      [-18, -94],
      [-88, -86],
      [-51, -222],
      [-74, -133],
      [-30, -83],
      [3, -58],
      [-32, -103],
      [-66, -60],
      [-118, -84],
      [-81, -97],
      [-65, -210],
      [-53, -61],
      [-34, -76],
      [-128, -31],
      [-115, -54],
      [-86, -68],
      [-66, -94],
      [-15, -127],
      [-84, -64],
      [-128, -157],
      [-171, -54],
      [-14, -44],
      [-94, -65],
      [-61, -10],
      [-81, -63],
      [-83, -14],
      [-60, -30],
      [-114, -147],
      [-57, -57],
      [-108, -65],
      [-20, -46],
      [-76, -64],
      [-39, 15],
      [-37, -38],
      [-90, -30],
      [-156, -69],
      [-37, -84],
      [-78, -51],
      [-61, -78],
      [-36, -3],
      [-61, -72],
      [-240, -7],
      [-76, -21],
      [-68, -106],
      [-49, -15],
      [-48, -56],
      [-145, -39],
      [-65, 9],
      [-75, 45],
      [-84, -9],
      [-59, 177],
      [-1, 98],
      [-20, 63],
      [-90, 82],
      [56, 134],
      [-5, 118],
      [56, 114],
      [-24, 62],
      [6, 49],
      [-84, 65],
      [-313, 153],
      [-124, 36],
      [-198, 47],
      [-119, -85],
      [-84, -3],
      [-49, -64],
      [-106, -52],
      [-136, -114],
      [-34, -9],
      [-52, 85],
      [-146, 56],
      [-9, 43],
      [37, 43],
      [-2, 47],
      [-110, 125],
      [61, 143],
      [39, 69],
      [68, 72],
      [18, 62],
      [56, 56],
      [8, 107],
      [-30, 99],
      [-63, 57],
      [-117, 31],
      [-55, -21],
      [-238, -185],
    ],
    [[1465288, 59692], [29, -66], [-78, -12], [49, 78]],
    [
      [1465955, 61014],
      [1, -56],
      [-43, -91],
      [-64, -91],
      [-129, -113],
      [-68, 16],
      [-6, 52],
      [64, 48],
      [31, 51],
      [43, 22],
      [53, 79],
      [20, 83],
      [98, 0],
    ],
    [
      [1437398, 43237],
      [32, 37],
      [67, 30],
      [114, 12],
      [173, 139],
      [33, 73],
      [135, 67],
      [156, 127],
      [100, 41],
      [71, 95],
      [7, 66],
      [82, 122],
      [156, 86],
      [-11, 77],
      [27, 42],
      [-1, 68],
      [136, 92],
      [40, 74],
      [-1, 47],
      [82, 30],
      [234, -30],
      [111, 23],
      [33, -10],
      [132, 20],
      [59, 37],
      [83, 11],
      [79, 84],
      [62, 23],
      [62, -4],
      [87, 77],
      [28, -12],
      [-34, -87],
      [61, -69],
      [95, -28],
      [231, 7],
      [329, 91],
      [113, 48],
      [85, 59],
      [100, 102],
      [117, 160],
      [107, 58],
      [228, 81],
      [203, 134],
      [57, 60],
      [294, 225],
      [137, 146],
      [60, 83],
      [125, 91],
      [59, 28],
      [60, 57],
      [99, 38],
      [142, 90],
      [37, -2],
      [123, 79],
      [170, 52],
      [52, 50],
      [41, 80],
      [97, 50],
      [54, 5],
      [86, 44],
      [21, -35],
      [68, 12],
      [37, 32],
      [124, 8],
      [40, -32],
      [73, 35],
      [188, 168],
      [11, -56],
      [36, -14],
      [85, 18],
      [95, 57],
      [88, 123],
      [59, 57],
      [71, -18],
      [54, 29],
      [79, 81],
      [100, 154],
      [18, 50],
      [68, 64],
      [75, 106],
      [47, 86],
      [35, 24],
      [50, 106],
      [76, 9],
      [122, 125],
      [137, -2],
      [74, 28],
      [113, 9],
      [138, 79],
      [125, 88],
      [153, 154],
      [28, 59],
      [98, 121],
      [16, 37],
      [57, 7],
      [65, 46],
      [70, 75],
      [58, 40],
      [79, 112],
      [130, 54],
      [83, -32],
      [78, 4],
      [34, 45],
      [-16, 41],
      [83, 40],
      [-8, 39],
      [92, 36],
      [13, 47],
      [53, 25],
      [-34, 84],
      [-111, -32],
      [-64, -70],
      [-7, -70],
      [-93, -32],
      [110, 173],
      [45, 91],
      [36, 21],
      [87, 114],
      [14, 57],
      [110, -6],
      [225, 64],
      [49, 27],
      [104, 94],
      [174, 192],
      [50, 7],
      [108, 63],
      [73, 6],
      [68, 30],
      [46, -16],
      [85, 7],
      [101, 45],
      [95, 26],
      [185, 107],
      [194, 154],
      [240, 120],
      [180, 161],
      [70, 33],
      [72, 63],
      [156, 181],
      [96, 75],
      [35, 41],
      [142, 254],
      [190, 262],
      [66, 50],
      [115, 112],
      [115, 135],
      [33, 2],
      [99, 77],
      [187, 247],
      [87, 162],
      [93, 123],
      [114, 206],
      [48, 124],
      [61, 215],
      [46, 83],
      [46, 167],
      [9, 67],
      [67, 12],
      [36, 46],
      [72, 183],
      [83, 110],
      [49, 157],
      [51, 80],
      [18, 112],
      [25, 271],
      [17, 80],
      [9, 127],
      [24, 65],
      [13, 113],
      [46, 98],
      [-13, 38],
      [91, 103],
      [22, 68],
      [3, 124],
      [72, 26],
      [34, 48],
      [22, 128],
      [25, 79],
      [48, 217],
      [5, 91],
      [90, 134],
      [29, -7],
      [48, 76],
      [21, 214],
      [-22, 397],
      [35, 32],
      [-8, 43],
      [181, 23],
      [85, -36],
      [122, 21],
      [131, 87],
      [142, -55],
      [125, 13],
      [11, -12],
      [113, 40],
      [87, 46],
      [167, 139],
      [40, 43],
      [142, 107],
      [159, 107],
      [134, 125],
      [98, 120],
      [109, 171],
      [47, 86],
      [54, 131],
      [96, 144],
      [131, 273],
      [49, 23],
      [112, 137],
      [43, 31],
      [32, 56],
      [80, 72],
      [68, 239],
      [43, 257],
      [44, -8],
      [29, 73],
      [0, 88],
      [-40, 98],
      [23, 253],
      [9, 247],
      [-23, 239],
      [19, 13],
      [-9, 115],
      [37, 191],
      [-12, 88],
      [4, 98],
      [-36, 144],
      [-1, 82],
      [-17, 69],
      [-2, 89],
      [17, 150],
      [43, 72],
      [81, 44],
      [51, 139],
      [128, 183],
      [115, 97],
      [48, 16],
      [21, 77],
    ],
  ],
  'objects': {
    'gadm41_NZL_1': {
      'geometries': [
        {
          'arcs': [
            [[0]],
            [[1]],
            [[2]],
            [[3]],
            [[4, 5, 6, 7, 8, 9, 10, 11, 12, 13]],
            [[14]],
            [[15]],
            [[16]],
            [[17]],
            [[22]],
            [[23]],
            [[24]],
          ],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '02',
            'GID_0': 'NZL',
            'GID_1': 'NZL.1_1',
            'HASC_1': 'NZ.AU',
            'ISO_1': 'NZ-AUK',
            'NAME_1': 'Auckland',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[[25, 26, 27, 28]], [[29]], [[30]], [[31]], [[32]], [[33]]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '04',
            'GID_0': 'NZL',
            'GID_1': 'NZL.2_1',
            'HASC_1': 'NZ.BP',
            'ISO_1': 'NZ-BOP',
            'NAME_1': 'Bay of Plenty',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[34, 35, 36, 37, 38, 39]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '13',
            'GID_0': 'NZL',
            'GID_1': 'NZL.3_1',
            'HASC_1': 'NZ.CA',
            'ISO_1': 'NZ-CAN',
            'NAME_1': 'Canterbury',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[[40]], [[41]], [[42]]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Territory',
            'CC_1': '99',
            'GID_0': 'NZL',
            'GID_1': 'NZL.4_1',
            'HASC_1': 'NZ.CI',
            'ISO_1': 'NA',
            'NAME_1': 'Chatham Islands',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[43, 44, -29]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '05',
            'GID_0': 'NZL',
            'GID_1': 'NZL.5_1',
            'HASC_1': 'NZ.GI',
            'ISO_1': 'NZ-GIS',
            'NAME_1': 'Gisborne',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[45, -26, -45, 46, 47]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '06',
            'GID_0': 'NZL',
            'GID_1': 'NZL.6_1',
            'HASC_1': 'NZ.HB',
            'ISO_1': 'NZ-HKB',
            'NAME_1': "Hawke's Bay",
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[48, 49, 50, 51, 52, -48]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '08',
            'GID_0': 'NZL',
            'GID_1': 'NZL.7_1',
            'HASC_1': 'NZ.MW',
            'ISO_1': 'NZ-MWT',
            'NAME_1': 'Manawatu-Wanganui',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [
            [[53, 54, 55, 56, 57, 58, 59, -38]],
            [[60]],
            [[61]],
            [[62]],
            [[63]],
            [[64]],
          ],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '18',
            'GID_0': 'NZL',
            'GID_1': 'NZL.8_1',
            'HASC_1': 'NZ.MA',
            'ISO_1': 'NZ-MBH',
            'NAME_1': 'Marlborough',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[65, 66, -55]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '17',
            'GID_0': 'NZL',
            'GID_1': 'NZL.9_1',
            'HASC_1': 'NZ.NE',
            'ISO_1': 'NZ-NSN',
            'NAME_1': 'Nelson',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[[67]], [[68]], [[69]]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Group of islands',
            'CC_1': 'NA',
            'GID_0': 'NZL',
            'GID_1': 'NZL.10_1',
            'HASC_1': 'NZ.01',
            'ISO_1': 'NA',
            'NAME_1': 'Northern Islands',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Group of islands',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[70]],
            [[71]],
            [[72, -12, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82]],
            [[83]],
            [[84]],
          ],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '01',
            'GID_0': 'NZL',
            'GID_1': 'NZL.11_1',
            'HASC_1': 'NZ.NO',
            'ISO_1': 'NZ-NTL',
            'NAME_1': 'Northland',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[85, 86, -35, 87]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '14',
            'GID_0': 'NZL',
            'GID_1': 'NZL.12_1',
            'HASC_1': 'NZ.OT',
            'ISO_1': 'NZ-OTA',
            'NAME_1': 'Otago',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[[88]], [[89]], [[90]], [[91]], [[92]], [[93]]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Group of islands',
            'CC_1': 'NA',
            'GID_0': 'NZL',
            'GID_1': 'NZL.13_1',
            'HASC_1': 'NZ.02',
            'ISO_1': 'NA',
            'NAME_1': 'Southern Islands',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Group of islands',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[94]],
            [[95]],
            [[96, 97, -86, 98]],
            [[99]],
            [[100]],
            [[101]],
            [[102]],
            [[103]],
            [[104]],
          ],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '15',
            'GID_0': 'NZL',
            'GID_1': 'NZL.14_1',
            'HASC_1': 'NZ.SO',
            'ISO_1': 'NZ-STL',
            'NAME_1': 'Southland',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[105, 106, 107, -52]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '07',
            'GID_0': 'NZL',
            'GID_1': 'NZL.15_1',
            'HASC_1': 'NZ.TK',
            'ISO_1': 'NZ-TKI',
            'NAME_1': 'Taranaki',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [
            [[108, 109, 110, 111, 112, -66, -54, -37]],
            [[113]],
            [[114]],
          ],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '16',
            'GID_0': 'NZL',
            'GID_1': 'NZL.16_1',
            'HASC_1': 'NZ.TS',
            'ISO_1': 'NZ-TAS',
            'NAME_1': 'Tasman',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[-46, -53, -108, 115, -7, 116, 117, 118, -27]],
            [[119]],
            [[120]],
            [[121]],
            [[122]],
          ],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '03',
            'GID_0': 'NZL',
            'GID_1': 'NZL.17_1',
            'HASC_1': 'NZ.WK',
            'ISO_1': 'NZ-WKO',
            'NAME_1': 'Waikato',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[[125, 126, -50, 127]], [[128]], [[129]]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '09',
            'GID_0': 'NZL',
            'GID_1': 'NZL.18_1',
            'HASC_1': 'NZ.WG',
            'ISO_1': 'NZ-WGN',
            'NAME_1': 'Wellington',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[-87, -98, 130, -109, -36]],
          'properties': {
            'COUNTRY': 'New Zealand',
            'ENGTYPE_1': 'Region',
            'CC_1': '12',
            'GID_0': 'NZL',
            'GID_1': 'NZL.19_1',
            'HASC_1': 'NZ.WC',
            'ISO_1': 'NZ-WTC',
            'NAME_1': 'West Coast',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Region',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
      ],
      'type': 'GeometryCollection',
    },
  },
  'transform': {
    'scale': [0.00024132395628441463, 0.00019330292132890289],
    'translate': [-178.82638499999996, -52.61638999899997],
  },
  'type': 'Topology',
};

export const auTopojson = {
  'arcs': [
    [[35428, 142184], [61, -56], [-83, -11], [22, 67]],
    [[35717, 142194], [12, -59], [-120, -5], [37, 66], [71, -2]],
    [
      [127065, 66051],
      [43, -49],
      [90, -6],
      [-14, -61],
      [130, -101],
      [94, -4],
      [173, -65],
      [9, -25],
      [116, -24],
      [47, -59],
      [-164, -90],
      [-49, 38],
      [-128, 23],
      [-64, -17],
      [-113, 27],
      [-64, -42],
      [-92, -15],
      [-64, -94],
      [-143, -137],
      [-34, -122],
      [53, -120],
      [-3, -69],
      [-62, -129],
      [28, -129],
      [-195, 25],
      [-23, -19],
      [33, -176],
      [28, -3],
      [-2, -121],
      [51, -65],
      [-66, -229],
      [36, -131],
      [-51, -143],
      [-87, -105],
      [-45, -135],
      [-128, 69],
      [-188, 26],
      [-161, 121],
      [-19, 86],
      [-72, 64],
      [39, 51],
      [-29, 239],
      [-89, -37],
      [-12, -63],
      [-91, 41],
      [-24, 48],
      [-109, 55],
      [-29, 39],
      [36, 102],
      [-88, 38],
      [-9, 52],
      [44, 38],
      [-53, 84],
      [71, 49],
      [-55, 68],
      [-2, 128],
      [-34, 112],
      [41, 35],
      [-26, 46],
      [28, 97],
      [49, 51],
      [-11, 59],
      [80, 87],
      [-54, 144],
      [59, 105],
      [1089, 607],
      [152, -58],
      [-6, -41],
      [93, -38],
      [40, -132],
    ],
    [
      [145274, 113263],
      [9, -33],
      [1, -49],
      [-3, -15],
      [-17, -21],
      [-6, -17],
      [-11, -2],
      [29, 43],
      [1, 56],
      [-3, 38],
    ],
    [[131945, 66119], [45, 136], [107, -9], [83, 96], [142, -17]],
    [
      [132322, 66325],
      [129, -47],
      [89, 19],
      [-15, -106],
      [-78, -56],
      [-48, 15],
      [-92, -81],
      [-21, 97],
      [-159, 23],
      [-123, -30],
      [-59, -40],
    ],
    [
      [98353, 69978],
      [0, 834],
      [-1, 1107],
      [-1, 696],
      [-1, 980],
      [0, 689],
      [-2, 1678],
      [0, 1330],
      [-1, 928],
      [-1, 812],
      [-1, 664],
      [1, 1075],
      [-1, 973],
      [0, 629],
      [-1, 780],
      [-1, 1056],
      [0, 741],
      [-1, 1093],
      [-1, 599],
    ],
    [
      [98341, 86642],
      [711, 0],
      [1172, 1],
      [1341, -1],
      [749, 0],
      [806, 4],
      [838, -4],
      [888, 2],
      [936, -2],
      [1149, 0],
      [1251, 0],
      [915, 0],
      [1000, 1],
      [779, -1],
      [1036, 0],
      [1037, 0],
      [855, 0],
      [884, 0],
      [1404, 1],
      [1078, 0],
      [727, 0],
      [1268, -1],
      [1009, 0],
      [1284, 0],
      [1211, 0],
      [1455, 0],
      [1244, 0],
      [847, 0],
      [65, 76],
      [68, 10],
      [31, 40],
      [80, 22],
      [52, 50],
      [56, 96],
      [-13, 83],
      [85, 88],
      [48, 82],
      [135, 7],
      [29, 66],
      [105, 14],
      [60, 45],
      [8, 57],
      [88, 45],
      [112, 81],
      [119, 27],
      [46, 62],
      [124, 18],
      [106, 75],
      [114, -25],
      [122, 140],
      [57, 96],
      [-10, 30],
      [75, 67],
      [48, -8],
      [97, 58],
      [94, 7],
      [96, -14],
      [108, 19],
      [92, -89],
      [91, 5],
      [74, -38],
      [63, -76],
      [93, 5],
      [-8, 40],
      [63, 33],
      [50, -24],
      [129, 25],
      [48, -16],
      [154, 32],
      [52, -22],
      [102, 24],
      [56, -51],
      [96, 35],
      [70, -21],
      [138, 11],
      [128, 93],
      [133, -7],
      [29, 23],
      [117, -1],
      [269, 91],
      [41, -46],
      [167, -9],
      [24, 46],
      [127, 48],
      [61, 8],
      [105, -65],
      [35, -51],
      [77, -14],
      [63, -76],
      [2, -71],
      [62, -45],
      [99, -4],
      [-6, -48],
      [170, -60],
      [85, 35],
      [97, -15],
      [71, -34],
      [168, 53],
      [77, -48],
      [96, -20],
      [33, 40],
      [97, 36],
      [35, -24],
      [176, 89],
      [76, 2],
      [102, -35],
      [135, -75],
      [252, -90],
      [46, 22],
      [93, -61],
      [6, -58],
      [55, -38],
      [77, 9],
      [140, -30],
      [57, -49],
      [-6, -124],
      [57, -17],
      [-12, -68],
      [30, -88],
      [70, 0],
      [53, 33],
      [74, -4],
      [108, -80],
      [89, -15],
      [55, -50],
      [91, -15],
      [27, -54],
      [108, -32],
      [9, -37],
      [116, -57],
      [28, -140],
      [2, -184],
      [19, -49],
      [-44, -83],
      [17, -90],
      [79, -44],
      [45, -69],
      [-16, -76],
      [89, -54],
      [86, 12],
      [114, -16],
      [52, 78],
      [73, 28],
      [68, 56],
      [46, 97],
      [82, 36],
      [56, 78],
      [-34, 24],
      [21, 126],
      [134, 135],
      [44, 90],
      [220, 96],
      [87, 5],
      [104, 55],
      [141, 111],
      [70, 17],
      [70, -161],
      [91, -76],
      [20, -66],
      [98, 45],
      [26, -49],
      [72, 7],
      [9, 123],
      [37, 38],
      [169, -17],
      [111, -39],
      [143, 30],
      [110, 67],
      [84, 17],
      [47, 131],
      [-77, 4],
      [64, 74],
      [23, 143],
      [-12, 93],
      [25, 88],
      [114, 74],
      [-28, 77],
      [-55, 10],
      [-46, 58],
      [-89, -4],
      [-81, 137],
      [11, 44],
      [-28, 98],
      [-99, 60],
      [-7, 145],
      [80, 55],
      [33, -71],
      [101, -2],
      [101, 101],
      [76, 33],
      [7, 53],
      [87, -1],
      [97, 29],
      [71, 60],
      [80, 32],
      [139, -18],
      [44, -37],
      [47, 65],
      [130, 132],
      [60, -14],
      [96, 104],
      [103, -17],
      [50, 22],
      [102, -25],
      [112, 117],
      [-22, 77],
      [22, 45],
      [90, 18],
      [73, 106],
      [182, 25],
      [74, -46],
      [-41, -127],
      [82, -57],
      [83, -15],
      [23, -43],
      [79, 101],
      [-11, 57],
      [69, 26],
      [72, -92],
      [101, -3],
      [23, -47],
      [172, -60],
      [62, -52],
      [62, -10],
      [62, 54],
      [61, -25],
      [132, 112],
      [62, 26],
      [117, 1],
      [59, -42],
      [138, -35],
      [121, 12],
      [37, -21],
      [288, -21],
      [34, -27],
      [130, -6],
      [60, 83],
      [-20, 25],
      [107, 86],
      [60, 118],
      [43, 47],
      [171, -63],
      [39, 58],
      [122, 46],
      [96, -19],
      [84, 8],
      [33, -44],
      [60, 2],
      [115, 49],
      [60, 82],
      [106, 78],
      [71, 21],
      [40, 59],
      [61, 19],
      [158, -61],
      [74, 36],
    ],
    [
      [142306, 89411],
      [-80, -86],
      [141, -29],
      [-9, -106],
      [15, -53],
      [52, -38],
      [-48, -184],
      [20, -167],
      [-36, -101],
      [-49, -219],
      [-4, -223],
      [63, -222],
      [68, -96],
      [65, -50],
      [99, -6],
    ],
    [
      [142603, 87831],
      [-68, -124],
      [-14, -78],
      [16, -62],
      [-78, -182],
      [1, -85],
      [35, -25],
      [3, -115],
      [-61, -104],
      [-46, 15],
      [-35, -81],
      [-240, -256],
      [-117, -155],
      [-81, -157],
      [-26, -81],
      [3, -75],
      [58, -21],
      [-11, -105],
      [-103, -90],
      [-63, -77],
      [-175, -262],
      [-38, -135],
      [39, -115],
      [87, -37],
      [-45, -184],
      [17, -55],
      [-34, -90],
      [25, -57],
      [-24, -138],
      [-72, -137],
      [-23, -93],
      [34, -55],
      [-65, -114],
      [-5, -77],
      [44, -23],
      [-100, -136],
      [-46, -125],
      [39, -75],
      [-53, -75],
      [22, -88],
      [-59, -55],
      [-35, -79],
      [15, -178],
      [-63, -111],
      [-72, -91],
      [-125, -196],
      [24, -104],
      [-18, -96],
      [44, -31],
      [-38, -86],
      [-1, -77],
      [-118, -134],
      [-46, -121],
      [-55, -93],
      [16, -41],
      [-8, -117],
      [-95, -101],
      [-46, -117],
      [-76, -95],
      [-8, -90],
      [-89, -95],
      [-67, -128],
      [-24, -115],
      [16, -43],
      [-43, -58],
      [-24, -168],
      [23, -96],
      [-58, -97],
      [-41, -150],
      [7, -74],
      [38, -27],
      [-15, -125],
      [27, -173],
      [64, -116],
      [113, -45],
      [74, 27],
    ],
    [[140640, 80406], [42, -109]],
    [
      [140682, 80297],
      [-12, -47],
      [-104, -119],
      [-52, -113],
      [-8, -123],
      [20, -45],
      [73, -34],
      [-55, -75],
      [-179, -182],
      [-73, -124],
      [-5, -83],
      [-49, -101],
      [27, -83],
      [-18, -176],
      [30, -44],
      [-67, -67],
      [-98, -141],
      [-37, -90],
      [73, -176],
      [-4, -49],
      [-203, -178],
      [-87, -103],
      [-39, -74],
      [25, -54],
      [-48, -82],
      [33, -61],
      [-99, -86],
      [-71, -106],
      [12, -108],
      [-189, -202],
      [-41, -98],
      [42, -76],
      [-190, -105],
      [-192, -142],
      [-95, -89],
      [-208, -260],
      [-27, -75],
      [22, -77],
    ],
    [
      [138789, 76449],
      [-90, -91],
      [-46, -94],
      [-11, -124],
      [24, -43],
      [81, -18],
      [25, -62],
      [86, -33],
      [-4, -57],
      [-84, -71],
      [-56, -99],
      [-11, -106],
      [76, -85],
      [-101, -240],
      [30, -118],
      [-59, -30],
      [-210, -54],
      [-242, -102],
      [-114, -60],
      [-121, -94],
      [-94, -155],
      [-167, -59],
      [-81, -50],
      [-81, -96],
      [-17, -87],
      [-155, 11],
      [-74, 58],
      [-47, -62],
      [-136, -30],
      [-23, 131],
      [-94, -68],
      [-219, 40],
      [-71, -78],
      [69, -28],
      [21, -51],
      [-106, -31],
      [-3, -77],
      [135, 40],
      [127, 20],
      [70, -29],
      [172, 3],
      [76, 40],
      [60, -13],
      [103, 33],
      [93, -29],
      [-37, -117],
      [-59, -36],
      [-91, 3],
      [-37, -68],
      [-147, -15],
      [-22, 19],
      [-174, -27],
      [-163, -44],
      [-236, -77],
      [-201, -85],
      [-110, -58],
      [-97, -74],
      [0, -109],
      [-195, -122],
      [-38, -50],
      [6, -108],
      [-166, -99],
      [-77, -87],
      [-21, 117],
      [-69, 9],
      [-20, 57],
      [56, 122],
      [-46, 3],
      [-81, -74],
      [-10, -73],
      [-47, -28],
      [14, -69],
      [-72, -88],
      [-100, 29],
      [-43, -68],
      [-76, -22],
      [45, -50],
      [65, 39],
      [75, -17],
      [-12, -60],
      [-85, 13],
      [-62, -38],
      [3, -51],
      [51, -21],
      [90, -82],
      [39, 51],
      [-67, 39],
      [111, 64],
      [12, -78],
      [59, 9],
      [45, 154],
      [72, 50],
      [82, -26],
      [-51, -60],
      [-20, -103],
      [-60, -180],
      [-85, -46],
      [-98, -85],
      [-35, -88],
      [30, -88],
      [-116, -57],
      [-54, 17],
      [72, 62],
      [-18, 53],
      [58, 75],
      [-14, 35],
      [-145, 36],
      [37, -129],
      [-88, -9],
      [-79, -103],
      [-68, -11],
      [-46, -141],
      [57, -59],
      [68, -19],
      [86, 85],
      [20, -50],
      [-55, -59],
      [-7, -69],
      [-140, -129],
      [19, -64],
      [-41, -23],
      [12, -109],
      [-56, -12],
      [-24, -77],
      [-55, -36],
      [-64, 14],
      [-136, -52],
      [-42, 82],
      [-62, -36],
      [-60, -102],
      [-87, 19],
      [-66, -63],
      [-43, 48],
      [33, 58],
      [-158, 82],
      [-35, 34],
      [-104, 4],
      [-56, -59],
      [-52, -5],
      [28, -76],
      [105, 86],
      [75, -77],
      [114, 8],
      [-11, -51],
      [55, -72],
      [-39, -59],
      [48, -28],
      [126, 80],
      [98, -3],
      [-63, -89],
      [36, -49],
      [-100, -43],
      [78, -83],
      [107, 143],
      [55, -28],
      [-67, -96],
      [7, -24],
      [-102, -170],
      [36, -89],
      [-15, -92],
      [-52, -86],
      [-127, -109],
      [3, -66],
      [-163, -11],
      [-111, 66],
      [-97, -44],
      [79, -55],
      [41, 34],
      [237, -79],
      [121, 30],
      [9, -99],
      [-98, -101],
      [-2, -95],
      [30, -26],
      [-54, -127],
      [-152, 119],
      [-109, 22],
      [-61, -12],
      [-64, -148],
      [-95, -7],
      [86, -105],
      [54, -3],
      [35, 49],
      [63, -9],
      [64, 51],
      [83, 30],
      [32, -47],
      [-35, -75],
      [-128, 12],
      [-70, -21],
      [-36, -48],
      [5, -54],
      [-176, -1],
      [-4, -26],
      [200, -6],
      [34, -16],
      [-152, -169],
      [-291, -162],
      [-240, -198],
      [-124, -189],
      [-41, -39],
      [-34, -129],
      [36, -56],
      [-97, -115],
      [18, -59],
      [-41, -96],
      [23, -69],
      [53, -62],
      [-80, -39],
      [-69, 11],
      [-67, 51],
      [-156, -79],
      [7, -86],
      [-63, -71],
      [129, -5],
      [17, 46],
      [84, 19],
      [31, -25],
      [-5, -93],
      [54, -111],
      [-100, -31],
      [-2, -202],
      [19, -41],
      [-53, -125],
      [-35, -27],
      [-24, -115],
      [-129, -45],
      [-102, -114],
      [-45, -124],
      [7, -119],
      [50, -64],
      [66, -255],
      [47, -57],
      [80, -36],
      [99, 7],
      [-52, -101],
      [23, -52],
      [-119, -117],
      [-117, 96],
      [40, 114],
      [-62, 52],
      [-24, 76],
      [-117, -13],
      [-140, -70],
      [-50, -59],
      [14, -113],
      [59, -25],
      [5, -116],
      [23, -38],
    ],
    [
      [131945, 66119],
      [-74, -27],
      [-117, -78],
      [-46, -136],
      [-163, -107],
      [-27, -89],
      [10, -129],
      [40, -13],
      [-134, -115],
      [-127, -134],
      [-73, -117],
      [5, -114],
      [53, -84],
      [-79, -55],
      [-32, -86],
      [-39, 4],
      [-148, -143],
      [-28, -71],
      [-64, -65],
      [29, -52],
      [-97, -134],
      [-57, -20],
      [-83, 43],
      [-133, 9],
      [1, -94],
      [52, -32],
      [-1, -75],
      [79, -58],
      [-39, -162],
      [-133, -15],
      [-30, -69],
      [-99, -124],
      [37, -140],
      [-34, -152],
      [36, -49],
      [-9, -60],
      [-61, -124],
      [-31, -111],
      [17, -48],
      [-40, -107],
      [-4, -95],
      [28, -54],
      [-7, -85],
      [57, -125],
    ],
    [
      [130380, 62627],
      [5, -94],
      [-62, -76],
      [25, -52],
      [-184, -184],
      [-55, -103],
      [-10, -83],
    ],
    [[130099, 62035], [24, -19]],
    [
      [130123, 62016],
      [12, -41],
      [-72, -154],
      [13, -148],
      [-15, -55],
      [44, -36],
      [-163, -244],
      [-96, -169],
      [-11, -65],
      [-43, -48],
      [18, -121],
      [-49, -116],
      [-99, -85],
      [-27, -90],
      [-4, -172],
      [-81, -54],
      [-21, -51],
      [19, -73],
      [103, -34],
      [-27, -45],
      [-7, -137],
      [49, -38],
      [-14, -72],
      [-52, -23],
      [-39, -79],
    ],
    [
      [129561, 59866],
      [-141, -41],
      [139, -99],
      [140, 35],
      [62, -86],
      [122, -50],
      [23, -126],
      [68, -192],
      [70, -82],
    ],
    [
      [130044, 59225],
      [-259, 66],
      [-84, -65],
      [-36, -66],
      [92, -88],
      [10, -64],
      [-47, -64],
      [-31, -90],
      [24, -163],
      [71, -110],
      [-1, -159],
    ],
    [
      [129783, 58422],
      [-667, 244],
      [-985, 364],
      [-644, 241],
      [-1109, 417],
      [-1137, 434],
      [-1034, 395],
      [-664, 256],
      [-136, 21],
      [-166, -23],
      [-14, 55],
      [120, 160],
      [115, 73],
      [62, 116],
      [109, 164],
      [-60, 123],
      [-135, 35],
      [-56, 54],
      [-55, -17],
      [-32, 55],
      [55, 82],
      [-41, 76],
      [-24, 138],
      [-119, 31],
      [-2, 65],
      [-36, 46],
      [-64, 14],
      [-71, 160],
      [60, 110],
      [-69, 132],
      [12, 104],
      [-42, 118],
      [48, 62],
      [-56, 74],
      [15, 149],
      [-94, 16],
      [-34, 69],
      [19, 34],
      [-35, 74],
      [12, 59],
      [-24, 61],
      [-209, 10],
      [-44, 52],
      [1, 106],
      [-97, -12],
      [-123, 31],
      [-134, 74],
      [-120, -5],
      [-68, 46],
      [-127, 26],
      [-45, 61],
      [-93, -46],
      [-99, -6],
      [-56, -59],
      [-130, -57],
      [-31, 31],
      [-128, -111],
      [-141, 89],
      [-57, 99],
      [-121, 8],
      [-28, -45],
      [-182, 47],
      [-49, -20],
      [-23, -75],
      [-76, -53],
      [-55, -119],
      [24, -25],
      [-83, -75],
      [-64, -23],
      [-38, 53],
      [-203, 22],
      [-132, -38],
      [-151, 63],
      [-85, 8],
      [-89, 69],
      [-43, -55],
      [-14, -93],
      [-37, 0],
      [-89, -72],
      [-37, -99],
      [-48, -36],
      [-67, 84],
      [-153, 25],
      [-71, -35],
      [-23, -76],
      [-106, 10],
      [-47, 98],
      [-75, -15],
      [-27, 46],
      [-84, -43],
      [-76, 28],
      [-81, 67],
      [-153, -1],
      [-104, 44],
      [-49, 61],
      [-104, -47],
      [-28, 63],
      [-81, -15],
      [-53, 56],
      [-115, 78],
      [14, 46],
      [-148, -23],
      [-67, -44],
      [-86, 74],
      [-86, 24],
      [-50, -80],
      [-77, 20],
      [-30, 47],
      [-148, -29],
      [-50, -96],
      [-69, -30],
      [25, -69],
      [-71, -54],
      [-74, 70],
      [-162, -61],
      [-64, 79],
      [-69, 27],
      [-67, -44],
      [-110, -1],
      [-80, -41],
      [-46, 68],
      [-266, 26],
      [-20, -26],
      [-122, 25],
      [-91, 62],
      [-162, -61],
      [-118, 71],
      [-5, 51],
      [-152, 76],
      [-131, -6],
      [-76, -46],
      [-16, -45],
      [-178, 0],
      [-54, 75],
      [-117, -12],
      [-78, 85],
      [-213, 86],
      [-111, 106],
      [-75, 33],
      [-154, 119],
      [-26, 48],
      [-105, 44],
      [-20, -39],
      [-85, 32],
      [-68, -6],
      [-57, -55],
      [-174, -14],
      [0, -50],
      [-130, -43],
      [-116, -9],
      [-65, 37],
      [-54, -31],
      [-146, 26],
      [-68, 74],
      [-117, -18],
      [-106, 2],
      [-89, -23],
      [-152, 71],
      [-81, -50],
      [-63, 27],
      [-90, -31],
      [-118, -69],
      [-66, 18],
      [-82, -28],
      [-23, -116],
      [-55, -120],
      [27, -99],
      [-90, -22],
      [-26, -68],
      [135, -101],
      [12, -60],
      [58, -36],
      [0, -79],
      [-125, -34],
      [-48, 51],
      [-155, 11],
      [-53, 20],
      [-110, -85],
      [-6, -57],
      [-56, -7],
      [-65, -71],
      [-218, 50],
      [-88, -16],
      [-10, 91],
      [-108, -32],
      [15, 114],
      [-92, -50],
      [-33, 78],
      [-139, 6],
      [-120, 100],
      [-79, 25],
      [-96, 65],
      [-42, 55],
      [-84, 31],
      [-62, 68],
      [-128, 55],
      [-124, 167],
      [2, 69],
      [-80, 25],
      [-20, 79],
      [-38, 29],
      [38, 62],
      [-81, 49],
      [-83, 104],
      [-154, 59],
      [-59, -55],
      [-69, 107],
      [-117, 57],
      [-16, 56],
      [-81, 55],
      [-70, 0],
      [-169, 144],
      [-46, 119],
      [-153, 49],
      [-50, 69],
      [-205, 10],
      [9, 63],
      [-61, 6],
      [-41, 58],
      [26, 47],
      [-182, -1],
      [-135, 45],
      [-185, 107],
      [-31, 58],
      [-98, 50],
      [-87, -3],
      [9, 74],
      [-75, 42],
      [-133, -10],
      [-88, 60],
      [-143, -92],
      [-81, 61],
      [-86, 108],
      [-105, 27],
      [-4, 86],
      [95, 114],
      [-52, 54],
      [-40, 98],
      [52, 73],
      [-54, 38],
      [-123, -58],
      [-32, 24],
      [-169, -22],
      [-23, 53],
      [-65, 45],
      [-175, -11],
      [-51, 67],
      [31, 94],
      [-58, 72],
      [-95, 60],
      [-20, 74],
      [24, 66],
      [-46, 32],
      [3, 166],
      [-63, 55],
      [13, 145],
      [74, 34],
      [-74, 146],
      [62, 23],
      [33, 57],
      [-24, 63],
      [35, 24],
      [-16, 110],
      [-139, 83],
      [-82, -51],
      [-37, 61],
      [-68, 34],
      [-39, 66],
      [-50, -3],
      [-94, 44],
      [-36, 72],
      [-76, -24],
      [-127, 63],
      [-153, -1],
      [-86, 43],
      [-99, -43],
      [-78, 21],
      [-7, 79],
      [-78, 12],
      [-50, -89],
      [-45, 58],
      [-136, 89],
      [-83, -19],
      [-6, -43],
      [-88, -16],
      [-44, 150],
      [-77, 16],
      [-14, 41],
      [-106, 8],
      [-65, 81],
      [82, 78],
      [-77, 8],
      [-33, -52],
      [-64, -19],
      [11, -56],
      [-224, -21],
      [28, -53],
      [-65, -111],
      [68, -32],
      [-104, -60],
      [43, -78],
      [-45, -63],
      [-163, -36],
      [-44, -70],
      [95, -28],
      [-49, -70],
      [-67, -1],
      [-54, 34],
      [-251, 48],
      [-61, 32],
      [-24, 58],
      [43, 138],
      [-56, 62],
      [-93, 18],
      [2, 89],
      [-47, 68],
      [49, 147],
      [-149, 2],
      [-68, 70],
      [-115, 39],
      [-33, 49],
      [83, 115],
      [-108, 15],
      [-26, 57],
      [39, 78],
      [-43, 93],
      [-2, 81],
      [65, 38],
      [-7, 45],
      [66, 20],
      [-11, 47],
      [43, 38],
      [-99, 37],
      [-70, -32],
      [-94, -1],
      [-89, 41],
      [-43, -7],
      [-41, 76],
      [-119, -14],
      [36, 71],
      [-16, 233],
      [-52, 28],
      [-31, 87],
      [-76, -6],
      [-138, 40],
      [23, 63],
      [-72, 10],
      [-87, -24],
      [2, -58],
      [-136, 4],
      [-59, 85],
      [32, 56],
      [-161, 32],
      [-53, -15],
      [-104, 43],
      [-123, -29],
      [-158, 32],
      [-63, -11],
      [-18, -55],
      [-114, 17],
      [-18, -21],
      [-142, 55],
      [-75, -2],
      [-98, 27],
      [-60, 51],
      [-203, -27],
      [-83, -53],
      [-66, -11],
      [-10, -65],
      [-146, -133],
      [-202, -105],
      [-53, 18],
      [-24, 95],
      [-56, 77],
      [-42, -30],
      [-106, 7],
      [-30, -21],
      [-185, 109],
      [-25, 36],
      [-93, 44],
      [-39, -45],
      [-81, -29],
      [-28, 71],
      [-108, 25],
      [-82, 83],
      [-102, 20],
      [-86, 40],
      [-98, -94],
      [-91, 26],
      [0, 57],
      [-160, 10],
      [-87, -32],
      [-71, 55],
      [-81, 5],
      [-128, 41],
      [0, 62],
    ],
    [
      [161588, 78275],
      [51, 11],
      [44, -85],
      [72, -69],
      [-109, -103],
      [11, 171],
      [-69, 75],
    ],
    [
      [87833, 96602],
      [-1459, -1],
      [-1168, -1],
      [-875, 0],
      [-1064, -1],
      [-1134, -1],
      [-721, -1],
      [-1284, 0],
      [-1398, 0],
      [-1466, 0],
      [-726, 0],
      [-2189, 0],
      [-2262, 0],
      [-1111, 0],
      [-675, 0],
      [-2027, 0],
      [-1609, 0],
      [-714, -3],
      [-436, 3],
      [-895, 0],
      [-1131, 0],
      [-1070, 0],
      [-853, 0],
      [-1380, 0],
      [-1065, 0],
      [-1044, 0],
      [-1024, 0],
      [-732, 0],
    ],
    [
      [56321, 96597],
      [-5, 553],
      [1, 829],
      [0, 829],
      [0, 830],
      [2, 276],
      [-2, 553],
      [0, 830],
      [0, 829],
      [2, 1106],
      [-2, 1106],
      [2, 774],
      [-1, 1364],
      [1, 695],
      [0, 1868],
      [-1, 829],
      [-2, 2543],
      [3, 774],
      [0, 3318],
      [-3, 3034],
      [0, 1332],
      [0, 1466],
      [0, 803],
      [0, 1097],
      [0, 655],
      [0, 839],
      [0, 791],
      [0, 657],
      [0, 981],
      [0, 949],
      [0, 1129],
      [0, 831],
      [0, 734],
      [2, 784],
      [1, 901],
    ],
    [
      [56319, 133486],
      [106, -23],
      [43, -51],
      [77, 36],
      [91, -52],
      [73, -92],
      [-8, -87],
      [71, 39],
      [77, -60],
      [125, -65],
      [-26, -118],
      [-39, -45],
      [-1, -52],
      [67, -160],
      [42, -72],
      [-93, -91],
      [3, -58],
      [-64, -65],
      [51, -33],
      [80, 133],
      [76, 9],
      [78, 80],
      [-10, 67],
      [42, 43],
      [-37, 93],
      [52, 39],
      [9, 54],
      [43, 22],
      [-105, 64],
      [16, 111],
      [-30, 98],
      [9, 122],
      [-21, 147],
      [-25, 47],
      [56, 54],
      [88, -36],
      [-19, -50],
      [164, 28],
      [173, -65],
      [48, -89],
      [146, -52],
      [115, -17],
      [116, -42],
      [166, -229],
      [8, -75],
      [45, -19],
      [118, -100],
      [115, -146],
      [-20, -82],
      [-134, -74],
      [181, 5],
      [51, 52],
      [107, -5],
      [49, -30],
      [52, 58],
      [-117, 105],
      [-37, 67],
      [-2, 124],
      [-70, 143],
      [10, 142],
      [-54, 27],
      [-64, -48],
      [-23, 67],
      [-129, 205],
      [-37, 37],
      [-21, 84],
      [-105, 134],
      [20, 48],
      [97, -59],
      [146, -165],
      [58, -115],
      [91, 31],
      [-3, 41],
      [-103, 91],
      [-48, 117],
      [20, 28],
      [147, -103],
      [36, -48],
      [92, 25],
      [91, -41],
      [207, -7],
      [71, 21],
      [129, -46],
      [151, 50],
      [9, 47],
      [109, 72],
      [90, 113],
      [-67, 8],
      [-97, -50],
      [-122, -107],
      [-129, -30],
      [-93, 9],
      [-13, 71],
      [66, 17],
      [68, 69],
      [-203, -56],
      [-86, 51],
      [-106, 39],
      [-48, 38],
      [-5, 97],
      [-69, 36],
      [-96, 122],
      [-69, 14],
      [-58, 110],
      [14, 91],
      [163, 9],
      [212, -6],
      [88, 96],
      [83, 16],
      [110, 97],
      [-25, 22],
      [-225, -68],
      [-111, -56],
      [-208, 42],
      [-152, 18],
      [-37, 36],
      [-121, 16],
      [-25, 56],
      [-91, 92],
      [-53, 27],
      [-78, -32],
      [-3, 72],
      [-58, 17],
      [-54, 60],
      [-40, 87],
      [-79, 42],
      [-100, 4],
      [18, 120],
      [46, 92],
      [-25, 50],
      [94, 76],
      [73, 114],
      [-18, 155],
      [45, 10],
      [69, 65],
      [24, 52],
      [51, 15],
      [106, 205],
      [31, 97],
      [-15, 137],
      [56, -32],
      [69, 45],
      [29, -110],
      [-41, -81],
      [-1, -67],
      [42, -33],
      [51, 8],
      [-8, 79],
      [16, 76],
      [42, 47],
      [84, -33],
      [59, 125],
      [-71, 65],
      [16, 26],
      [114, -66],
      [95, 10],
      [221, 93],
      [95, 132],
      [5, 185],
      [-42, 72],
      [-71, 14],
      [26, 151],
      [61, 70],
      [78, 51],
      [111, 107],
      [-30, 51],
      [21, 118],
      [-26, 27],
      [22, 176],
      [64, 125],
      [69, 165],
      [14, 76],
      [-18, 137],
      [166, 54],
      [-18, 64],
      [63, 102],
      [46, -36],
      [2, -136],
      [96, -90],
      [117, -26],
      [163, 9],
      [119, 59],
      [87, 61],
      [137, 135],
      [39, -32],
      [85, 29],
      [74, 71],
      [78, 106],
      [43, 94],
      [60, 24],
      [74, 95],
      [51, 19],
      [97, -3],
      [22, 97],
      [-104, 44],
      [-69, 51],
      [-28, 80],
      [-233, 258],
      [-104, -5],
      [-50, 41],
      [9, 102],
      [53, 96],
      [-49, 53],
      [33, 183],
      [36, 89],
      [-6, 71],
      [-31, 62],
      [42, 110],
      [63, 14],
      [59, 63],
      [60, -9],
      [-2, -49],
      [45, -49],
      [60, -22],
      [134, 24],
      [148, 84],
      [40, 47],
      [62, 24],
      [3, 44],
      [51, 112],
      [38, 136],
      [-20, 39],
      [-2, 107],
      [14, 94],
      [-34, 65],
      [20, 149],
      [44, -25],
      [111, -8],
      [-1, 64],
      [51, 18],
      [82, 65],
      [89, -9],
      [114, -95],
      [59, 9],
      [34, -54],
      [6, -76],
      [55, -32],
      [65, 36],
      [101, -56],
      [74, 4],
      [81, -29],
      [47, 48],
      [146, -15],
      [-100, 82],
      [-73, 20],
      [-38, 46],
      [-88, 5],
      [-45, -33],
      [-39, 95],
      [-96, 75],
      [122, 24],
      [26, -24],
      [62, 82],
      [-73, 50],
      [-40, -23],
      [-27, 100],
      [80, 217],
      [54, 44],
      [-81, 84],
      [128, 62],
      [-32, 47],
      [-73, 14],
      [13, 72],
      [91, 56],
      [131, -25],
      [102, -72],
      [76, 7],
      [72, -46],
      [94, -3],
      [47, -35],
      [-32, -142],
      [74, -91],
      [-31, -58],
      [40, -116],
      [66, 75],
      [57, -62],
      [91, 6],
      [3, -36],
      [88, -114],
      [50, -16],
      [120, 0],
      [58, -16],
      [43, -89],
      [38, 61],
      [-96, 118],
      [-77, 35],
      [-75, -6],
      [-49, 200],
      [47, -19],
      [132, 38],
      [21, -82],
      [65, -25],
      [-13, 121],
      [-43, 71],
      [-133, 6],
      [-51, 61],
      [-102, 73],
    ],
    [
      [62792, 141575],
      [9, -96],
      [-86, 69],
      [38, 126],
      [113, 187],
      [83, 80],
      [104, -39],
      [106, -13],
      [100, 16],
      [134, -60],
      [67, 8],
      [-97, 119],
      [46, 165],
      [19, 111],
      [-33, 74],
      [-59, 24],
      [-36, 76],
      [31, 85],
      [147, 59],
      [70, -80],
      [78, 44],
      [90, -5],
      [7, -67],
      [36, -24],
      [80, 7],
      [32, 39],
      [82, -94],
      [141, -96],
      [35, 38],
      [5, 65],
      [74, 107],
      [-2, 104],
      [61, 79],
      [-15, 94],
      [81, 110],
      [49, -23],
      [-14, -54],
      [71, -66],
      [-20, -78],
      [0, -85],
      [31, -30],
      [-31, -67],
      [48, -116],
      [137, -141],
      [103, -64],
      [171, -53],
      [95, -12],
      [239, 16],
      [214, -15],
      [280, -3],
      [31, 12],
      [225, 23],
      [266, 96],
      [143, 95],
      [72, 3],
      [45, -32],
      [45, -102],
      [76, -57],
      [287, -79],
      [170, -22],
      [7, 42],
      [207, 105],
      [86, 69],
      [62, 88],
      [50, 1],
      [138, 63],
      [20, 54],
      [105, 4],
      [6, -93],
      [50, -70],
      [194, 75],
      [68, 9],
      [126, -125],
      [98, 47],
      [-4, 69],
      [98, 186],
      [136, 26],
      [161, 73],
      [124, 92],
      [49, -58],
      [179, 66],
      [-37, 224],
      [70, 250],
      [7, 137],
      [51, 38],
      [-34, 49],
      [8, 47],
      [-34, 115],
      [-67, 62],
      [-85, 8],
      [-48, 56],
      [59, 70],
      [79, 30],
      [9, 36],
      [90, 83],
      [21, 122],
      [-41, 13],
      [-43, -129],
      [-127, -32],
      [-191, 40],
      [-74, 101],
      [-67, 18],
      [17, 148],
      [186, 4],
      [58, -62],
      [148, 1],
      [-184, 127],
      [-45, 17],
      [-52, 103],
      [63, 69],
      [-103, 48],
      [25, 81],
      [-57, 0],
      [-63, 44],
      [-13, 83],
      [-47, -6],
      [-82, 88],
      [-145, 19],
      [-58, -10],
      [-160, 45],
      [-172, -78],
      [-118, 7],
      [-62, 25],
      [-69, -5],
      [-82, -41],
      [-57, -70],
      [-73, 1],
      [-80, -45],
      [-13, -42],
      [-138, -51],
      [-101, 7],
      [-69, 55],
      [-10, 54],
      [-76, 48],
      [-12, 77],
      [72, -4],
      [26, 44],
      [-60, 88],
      [-118, -35],
      [-75, 27],
      [-5, 68],
      [-78, -30],
      [-73, 67],
      [116, 54],
      [-64, 107],
      [-55, -23],
      [-45, -53],
      [-38, 26],
      [48, 110],
      [-56, -1],
      [-107, 73],
      [-86, 33],
      [-61, -66],
      [-241, -41],
      [-4, 77],
      [27, 35],
      [69, -27],
      [37, 110],
      [51, -41],
      [91, -9],
      [-69, 193],
      [136, 6],
      [114, 85],
      [62, -126],
      [92, -36],
      [63, -2],
      [76, 79],
      [-87, 140],
      [88, 28],
      [-2, 65],
      [-54, 56],
      [83, 33],
      [60, -21],
      [62, -160],
      [54, -64],
      [74, 45],
      [44, -128],
      [30, -32],
      [87, -10],
      [-55, -87],
      [61, -86],
      [-52, -49],
      [31, -42],
      [62, 8],
      [105, -28],
      [-33, -116],
      [38, -129],
      [182, -60],
      [-89, 159],
      [27, 49],
      [101, -63],
      [42, 22],
      [18, 77],
      [-32, 58],
      [-122, 83],
      [18, 93],
      [-32, 92],
      [40, 57],
      [-29, 64],
      [-74, 63],
      [-26, 79],
      [-77, 29],
      [8, 65],
      [139, 20],
      [35, -107],
      [64, -76],
      [68, -46],
      [78, -124],
      [25, -74],
      [82, -67],
      [-4, 121],
      [-62, 93],
      [31, 39],
      [-30, 83],
      [80, 35],
      [62, -27],
      [129, 125],
      [51, -45],
      [28, -148],
      [59, -43],
      [-11, -233],
      [-11, -65],
      [76, -5],
      [42, 111],
      [45, 7],
      [-30, 81],
      [70, 81],
      [40, -2],
      [65, -66],
      [54, -85],
      [98, -42],
      [-18, -191],
      [66, -47],
      [85, 11],
      [44, -81],
      [45, -25],
      [89, -99],
      [92, -26],
      [42, -72],
      [13, -151],
      [104, -124],
      [95, 2],
      [52, -27],
      [82, 11],
      [64, 88],
      [93, 72],
      [24, 83],
      [73, 9],
      [67, 67],
      [-4, 61],
      [87, 39],
      [54, -77],
      [63, -42],
      [73, 30],
      [11, 106],
      [-52, 98],
      [-90, 60],
      [25, 34],
      [96, -33],
      [61, 27],
      [58, -106],
      [41, -13],
      [14, -58],
      [60, -98],
      [146, -61],
      [39, -88],
      [94, -144],
      [108, -60],
      [-25, -91],
      [117, -213],
      [9, -68],
      [89, -103],
      [47, -100],
      [51, -52],
      [146, -55],
      [106, -20],
      [113, 13],
      [72, 36],
      [39, 49],
      [144, 34],
      [45, -18],
      [19, -65],
      [-46, -29],
      [-57, 12],
      [-58, -72],
      [29, -72],
      [128, 5],
      [58, -38],
      [111, 146],
      [50, -24],
      [-14, -133],
      [94, 18],
      [67, -91],
      [97, 74],
      [55, -6],
      [115, 69],
      [20, -33],
      [-38, -69],
      [5, -73],
      [83, -42],
      [41, 63],
      [128, -8],
      [-12, -81],
      [96, -12],
      [93, 41],
      [89, 120],
      [44, 19],
      [167, 1],
      [141, 91],
      [-6, 47],
      [218, 53],
      [14, -44],
      [67, -49],
      [134, -2],
      [67, 24],
      [33, -64],
      [-83, -64],
      [-19, -72],
      [-35, -19],
      [-102, 21],
      [-50, -25],
      [-84, -97],
      [68, -123],
      [57, 6],
      [104, -99],
      [87, -5],
      [63, -30],
      [187, 78],
      [69, 101],
      [125, 30],
      [84, -46],
      [19, -152],
      [27, -62],
      [92, 36],
      [5, 34],
      [98, 36],
      [63, -123],
      [65, -58],
      [-67, -87],
      [86, -65],
      [60, -99],
      [-38, -66],
      [-5, -58],
      [-59, -103],
      [21, -47],
      [69, 31],
      [20, 103],
      [102, 90],
      [19, 36],
      [79, 0],
      [-6, 112],
      [34, 44],
      [93, 20],
      [74, -83],
      [145, -102],
      [168, -43],
      [71, -41],
      [179, -25],
      [90, -2],
      [175, 31],
      [94, 29],
      [116, 16],
      [186, 115],
      [110, 162],
      [192, 24],
      [11, 32],
      [86, -30],
      [-11, -107],
      [45, -43],
      [65, -147],
      [44, -1],
      [117, -45],
      [-5, -174],
      [49, -56],
      [100, -11],
      [68, -63],
      [79, 34],
      [71, -7],
      [112, -63],
      [76, -147],
      [153, -126],
      [39, -57],
      [102, 25],
      [136, 62],
      [70, -2],
      [57, -39],
      [21, -53],
      [164, -98],
      [21, 22],
      [-55, 75],
      [-60, 33],
      [-28, 92],
      [122, -19],
      [182, 32],
      [64, -94],
      [100, -16],
      [1, 53],
      [-87, 47],
      [-52, 64],
      [-79, 11],
      [-82, 64],
      [149, 19],
      [54, 53],
      [77, 12],
      [36, 46],
      [-38, 73],
      [178, 71],
      [49, 64],
      [35, -30],
      [57, 36],
      [64, -3],
      [-34, -64],
      [53, -45],
      [150, 12],
      [155, 87],
      [46, 81],
      [62, -10],
      [22, -77],
      [88, -44],
      [138, 92],
      [118, 61],
      [37, 51],
      [107, 16],
      [151, 84],
      [79, 0],
      [113, 58],
      [127, 42],
      [158, 39],
      [167, 25],
      [-118, -87],
      [-45, 21],
      [-115, -15],
      [-29, -70],
      [-101, -95],
      [-77, -33],
      [-104, -156],
      [-103, -43],
      [-58, -81],
      [-156, -122],
      [0, -125],
      [85, -129],
      [117, -69],
      [70, -103],
      [56, 103],
      [68, 28],
      [38, -17],
      [9, 118],
      [123, 72],
      [33, 54],
      [45, -14],
      [113, 66],
      [92, -50],
      [76, 121],
      [42, -45],
      [107, 36],
      [71, 72],
      [82, 47],
      [-20, 81],
      [121, 49],
      [-42, -115],
      [-59, -58],
      [-89, -117],
      [-14, -72],
      [-109, -47],
      [-50, -75],
      [18, -50],
      [72, -33],
      [3, -46],
      [56, -42],
      [107, 18],
      [35, 86],
      [134, 35],
      [40, -47],
      [-23, -77],
      [-71, -6],
      [-19, -64],
      [-121, -234],
      [-75, -55],
      [11, -59],
      [71, -17],
      [66, 13],
      [-30, -88],
      [-45, -60],
      [38, -48],
      [96, -62],
      [51, 2],
      [4, 67],
      [55, 62],
      [45, -1],
      [35, -75],
      [168, 40],
      [62, 33],
      [31, -89],
      [126, -18],
      [94, 19],
      [182, 113],
      [104, 96],
      [24, 54],
      [-25, 57],
      [75, 75],
      [27, 91],
      [102, 192],
      [-13, 90],
      [-30, 60],
      [-60, 33],
      [-72, -28],
      [-92, 17],
      [-206, -22],
      [-29, 90],
      [-101, -34],
      [-65, 11],
      [3, 113],
      [74, -17],
      [59, 42],
      [127, 170],
      [70, 114],
      [125, 55],
      [114, -34],
      [47, 49],
      [-10, 39],
      [84, 15],
      [52, 60],
      [-10, 37],
      [121, 135],
      [50, -44],
      [73, 104],
      [58, -41],
      [85, -2],
      [108, 44],
      [30, 73],
      [78, -15],
      [3, -51],
      [-101, -42],
      [-193, -38],
      [-8, -127],
      [30, -62],
      [85, 7],
      [37, 94],
      [66, -12],
      [17, -74],
      [68, -169],
      [41, -65],
      [54, -8],
      [16, -129],
      [-31, -78],
      [65, -118],
      [9, -58],
      [108, -84],
      [57, -27],
      [4, -113],
      [182, -55],
      [44, 2],
      [127, 63],
      [23, 59],
      [-26, 111],
      [-56, -15],
      [-100, 109],
      [72, 13],
      [18, 37],
      [188, 43],
      [30, -86],
      [96, -88],
      [100, 4],
      [92, -31],
      [33, -73],
      [70, -17],
      [-9, -101],
      [99, -18],
      [23, -165],
      [-67, -40],
      [131, -44],
      [-26, -40],
      [-147, -64],
      [-48, -82],
      [-67, -37],
      [-93, -92],
      [-153, -182],
      [-129, -106],
      [-11, 46],
      [53, 97],
      [72, 17],
      [-13, 132],
      [-29, 11],
      [-132, -76],
      [-88, -70],
      [57, -58],
      [24, -87],
      [-23, -62],
      [-63, 2],
      [-20, -60],
      [72, 4],
      [-155, -204],
      [-42, -122],
      [11, -123],
      [-102, -17],
      [-75, 36],
      [-70, -48],
      [-36, -84],
      [71, -64],
      [12, -48],
      [-77, -173],
      [-54, 15],
      [-23, 77],
      [16, 80],
      [-112, 77],
      [-78, 15],
      [-16, -86],
      [30, -66],
      [-60, -49],
      [-30, 52],
      [-99, 77],
      [-53, -46],
      [9, -40],
      [64, -19],
      [-34, -101],
      [15, -45],
      [86, -26],
      [43, -79],
      [111, -23],
      [-23, -90],
      [32, -35],
      [70, 0],
      [184, -108],
      [33, 0],
      [77, -205],
      [-90, -10],
      [16, 53],
      [-41, 106],
      [-91, -30],
      [2, -38],
      [-68, -53],
      [-144, 107],
      [-127, -27],
      [-33, -35],
      [-105, 64],
      [-35, -36],
      [40, -39],
      [158, -9],
      [16, -63],
      [55, -17],
      [11, -91],
      [34, -27],
      [88, -2],
      [-97, -172],
      [-45, -139],
      [-53, 44],
      [-70, -39],
      [-41, -62],
      [-22, -121],
      [9, -93],
      [-69, -68],
      [-5, 79],
      [-167, -25],
      [-51, -50],
      [-66, -125],
      [-106, -115],
      [-31, 6],
      [-19, 102],
      [32, 82],
      [-26, 59],
      [70, 16],
      [77, -13],
      [52, 65],
      [-32, 86],
      [-42, 25],
      [12, 100],
      [-47, 63],
      [51, 23],
      [-31, 64],
      [27, 59],
      [-25, 37],
      [31, 73],
      [-20, 43],
      [-117, 41],
      [-82, -87],
      [-11, -73],
      [50, -116],
      [-47, -59],
      [6, -64],
      [-161, 41],
      [-66, 53],
      [-35, -69],
      [16, -159],
      [-83, -10],
      [-3, -80],
      [-45, -47],
      [-90, 47],
      [-50, -36],
      [-51, 23],
      [6, 60],
      [-37, 21],
      [-1, 60],
      [46, 36],
      [10, 126],
      [-37, 68],
      [-152, -111],
      [26, -36],
      [-102, -154],
      [-96, -84],
      [42, -66],
      [-33, -77],
      [-37, -16],
      [-40, 152],
      [-36, 67],
      [-63, 33],
      [-91, -63],
      [-22, -116],
      [-41, -50],
      [-35, 95],
      [-105, -56],
      [-16, -79],
      [-81, -74],
      [-26, -53],
      [-13, -96],
      [132, -61],
      [30, -55],
      [3, -71],
      [149, 24],
      [-28, -116],
      [-67, 35],
      [-103, -30],
      [-104, 64],
      [-70, -35],
      [-18, -39],
      [22, -73],
      [-32, -169],
      [40, -57],
      [-1, -62],
      [-92, -35],
      [22, -162],
      [67, -23],
      [24, -121],
      [102, -71],
      [-82, -68],
      [41, -92],
      [82, -55],
      [152, 5],
      [77, 39],
      [35, 47],
      [63, 24],
      [17, 55],
      [79, 37],
      [-5, 65],
      [69, 59],
      [75, -38],
      [12, -46],
      [-77, -34],
      [-5, -54],
      [-67, -65],
      [3, -101],
      [-53, -19],
      [-47, -129],
      [30, -131],
      [-84, -96],
      [-7, -120],
      [-67, -58],
      [-46, -110],
      [-138, -103],
      [-31, -59],
      [22, -43],
      [-6, -113],
      [-28, -106],
      [-49, -66],
      [-4, -89],
      [-30, -199],
      [-161, -99],
      [-69, -59],
      [-94, -10],
      [-80, -96],
      [-64, -54],
      [-99, -30],
      [37, -35],
      [-80, -145],
      [-98, -151],
      [-41, -44],
      [17, -51],
      [-189, -47],
      [-70, -93],
      [-34, -104],
      [1, -59],
      [-49, -11],
      [-108, -131],
      [-41, -76],
      [-31, -106],
      [5, -116],
      [-21, -49],
      [-86, -72],
      [-30, 25],
      [-74, -28],
      [-82, -85],
      [-83, -108],
      [-46, -6],
      [-40, 55],
      [-39, -21],
      [41, -154],
      [1, -125],
      [64, -129],
      [-11, -52],
      [83, -123],
      [15, -47],
      [117, -182],
      [113, -60],
      [76, -67],
      [183, -100],
      [124, -44],
      [96, 25],
      [96, -52],
      [10, -38],
      [70, -21],
      [120, -127],
      [66, 8],
      [216, -132],
      [204, -97],
      [129, -109],
      [89, -111],
      [145, -77],
      [52, -49],
      [218, -91],
      [98, -82],
      [231, -100],
      [95, -76],
      [134, 3],
      [129, -82],
      [6, -108],
      [43, -116],
      [34, -47],
      [24, -126],
      [69, -120],
      [95, -59],
      [74, -72],
      [132, -14],
      [54, -38],
      [72, 3],
      [79, -67],
      [120, -66],
      [155, -153],
      [89, -62],
      [163, 20],
      [160, 91],
      [47, 84],
      [132, -41],
      [53, 4],
      [90, -42],
      [-30, -100],
      [-96, -125],
      [-11, -43],
      [-124, 15],
      [18, -65],
      [61, -7],
      [22, -69],
      [64, -52],
      [62, -81],
      [6, -74],
      [-81, -66],
      [52, -113],
      [81, -4],
      [140, 142],
      [13, -55],
      [178, 22],
      [48, -30],
      [60, 6],
      [55, -53],
      [125, -14],
      [37, 43],
      [-10, 53],
      [143, 79],
      [81, 77],
      [41, -84],
      [51, 6],
      [154, -158],
      [124, -73],
      [126, -31],
      [135, -53],
      [173, -103],
      [151, -31],
      [123, -166],
      [67, -59],
      [13, -41],
      [76, -49],
      [130, -56],
      [74, -16],
      [73, -59],
      [58, 17],
      [99, -24],
      [110, -57],
      [76, -4],
      [135, -34],
      [20, 20],
      [175, -53],
      [277, -124],
      [172, -33],
      [69, -108],
      [68, -69],
      [74, -134],
      [85, -106],
      [55, -46],
      [113, -168],
      [83, -75],
      [189, -131],
      [42, -61],
      [173, -138],
    ],
    [
      [87840, 127962],
      [0, -404],
      [0, -1613],
      [0, -809],
      [0, -713],
      [0, -1284],
      [0, -608],
      [0, -1542],
      [-16, -736],
      [-2, -432],
      [0, -978],
      [1, -1025],
      [0, -829],
      [1, -1235],
      [1, -936],
      [0, -792],
      [0, -646],
      [1, -1050],
      [1, -829],
      [0, -583],
      [1, -1061],
      [1, -1383],
      [0, -1158],
      [1, -770],
      [0, -1383],
      [0, -553],
      [1, -1265],
      [0, -946],
      [1, -1936],
      [0, -548],
      [0, -1940],
      [1, -1373],
    ],
    [
      [79947, 133629],
      [41, -37],
      [31, -91],
      [-2, -65],
      [-100, -14],
      [-20, 21],
      [-89, -67],
      [-62, 46],
      [94, 56],
      [-4, 103],
      [35, 54],
      [76, -6],
    ],
    [[79490, 134703], [-55, 28], [6, 47], [76, 6], [-27, -81]],
    [[58783, 133711], [-118, 74], [119, -3], [94, -46], [-95, -25]],
    [
      [68243, 142778],
      [31, -44],
      [26, -94],
      [-38, -59],
      [-83, -2],
      [-128, 99],
      [-52, 77],
      [87, 9],
      [58, 34],
      [99, -20],
    ],
    [
      [76951, 142828],
      [93, -38],
      [37, -78],
      [-58, -104],
      [-44, -29],
      [-99, 38],
      [-28, 57],
      [4, 140],
      [-63, 68],
      [24, 22],
      [69, -54],
      [65, -22],
    ],
    [
      [77238, 142938],
      [9, -39],
      [-114, -67],
      [-46, 65],
      [-70, 30],
      [3, 40],
      [149, 25],
      [69, -54],
    ],
    [[76809, 142999], [48, -39], [-42, -43], [-84, 30], [22, 69], [56, -17]],
    [
      [77747, 143287],
      [-22, -46],
      [-75, -6],
      [-122, -44],
      [-35, 45],
      [21, 61],
      [102, 21],
      [131, -31],
    ],
    [
      [59986, 139219],
      [33, -1],
      [61, -64],
      [-25, -37],
      [-81, 19],
      [-81, -12],
      [-11, 48],
      [40, 43],
      [64, 4],
    ],
    [
      [61630, 141081],
      [-52, -53],
      [23, -28],
      [37, -128],
      [78, -92],
      [-66, -51],
      [2, 115],
      [-87, 54],
      [-40, 67],
      [25, 96],
      [-35, 41],
      [81, 33],
      [34, -54],
    ],
    [[61197, 140964], [-21, -55], [-67, -2], [-11, 62], [40, 24], [59, -29]],
    [[61236, 141125], [76, 36], [26, -45], [-55, -40], [-47, 49]],
    [[83721, 130662], [-70, -50], [-21, 56], [91, -6]],
    [
      [84378, 131130],
      [60, -101],
      [8, -46],
      [98, -21],
      [74, -79],
      [-13, -61],
      [26, -102],
      [-23, -74],
      [45, -106],
      [52, -16],
      [-31, -125],
      [-47, -55],
      [-101, -48],
      [-55, 22],
      [14, 70],
      [-25, 48],
      [-69, -22],
      [-93, 85],
      [-42, 153],
      [-151, 98],
      [4, 70],
      [84, 141],
      [36, -7],
      [106, 73],
      [-28, 122],
      [71, -19],
    ],
    [
      [83576, 130940],
      [75, -11],
      [80, -39],
      [18, -57],
      [-68, -70],
      [-70, -7],
      [38, -71],
      [-115, -73],
      [-152, -18],
      [-1, 52],
      [53, 61],
      [-26, 40],
      [-2, 147],
      [27, 47],
      [56, 20],
      [87, -21],
    ],
    [[83658, 131061], [-73, 8], [17, 58], [77, -18], [-21, -48]],
    [
      [83902, 131325],
      [63, -224],
      [-80, -40],
      [25, -61],
      [-41, -36],
      [-71, 31],
      [-27, 56],
      [-1, 73],
      [59, 37],
      [-50, 76],
      [6, 59],
      [117, 29],
    ],
    [[83748, 131213], [10, -67], [-123, 11], [-41, 65], [65, 13], [89, -22]],
    [[83222, 137536], [-29, -72], [-46, 21], [15, 66], [60, -15]],
    [
      [82948, 131354],
      [30, -22],
      [9, -85],
      [-67, -89],
      [16, -106],
      [-40, -106],
      [-135, 18],
      [-54, -43],
      [-76, 0],
      [-66, 43],
      [56, 45],
      [2, 70],
      [-25, 43],
      [44, 36],
      [18, 61],
      [-36, 65],
      [86, 30],
      [118, 17],
      [10, 48],
      [110, -25],
    ],
    [
      [82262, 136949],
      [49, 67],
      [43, 2],
      [94, -58],
      [116, -20],
      [107, 61],
      [-5, 95],
      [62, 13],
      [-8, -88],
      [53, -19],
      [159, 42],
      [-20, 67],
      [106, -13],
      [26, 78],
      [-59, 20],
      [-15, 96],
      [-75, 25],
      [11, 94],
      [88, 59],
      [37, -38],
      [-31, -60],
      [149, 14],
      [52, 22],
      [13, 66],
      [60, 47],
      [105, -11],
      [12, -67],
      [-39, -102],
      [-51, 2],
      [-84, -74],
      [56, -118],
      [61, -44],
      [6, -70],
      [44, -33],
      [-18, -72],
      [105, 28],
      [40, -29],
      [163, -48],
      [96, 53],
      [22, 87],
      [-45, 37],
      [43, 176],
      [92, -24],
      [62, 9],
      [58, -34],
      [30, -84],
      [-7, -60],
      [-98, -117],
      [-89, -167],
      [-4, -59],
      [-157, 22],
      [-75, -40],
      [-71, -116],
      [-32, -157],
      [60, -84],
      [95, 10],
      [6, -50],
      [-145, -24],
      [-52, -49],
      [-65, -24],
      [-106, -138],
      [-8, -58],
      [49, -93],
      [-31, -39],
      [73, -83],
      [93, 9],
      [36, -31],
      [89, 31],
      [-62, 56],
      [36, 61],
      [74, -10],
      [142, -96],
      [44, -82],
      [88, -5],
      [53, 26],
      [79, 128],
      [95, 19],
      [50, -25],
      [-19, -94],
      [-34, -67],
      [41, -111],
      [-54, -45],
      [21, -58],
      [-68, -54],
      [-62, 30],
      [-66, -76],
      [-83, 80],
      [-49, 16],
      [-36, -54],
      [-53, 59],
      [-155, 57],
      [-166, 2],
      [-181, -80],
      [-121, -41],
      [-52, 39],
      [-9, 55],
      [-322, 45],
      [-55, 68],
      [-119, -11],
      [-123, 63],
      [-64, 72],
      [-146, -20],
      [-137, -59],
      [-104, -78],
      [-8, 101],
      [65, 36],
      [192, 81],
      [67, 49],
      [6, 58],
      [84, 101],
      [-48, 79],
      [-31, 88],
      [6, 61],
      [-22, 104],
      [4, 79],
      [-30, 50],
      [44, 86],
      [54, 189],
      [-25, 80],
      [-71, 76],
      [-1, 35],
    ],
    [[82123, 136929], [-122, 9], [67, 92], [49, 0], [6, -101]],
    [
      [82691, 137387],
      [26, -116],
      [-23, -39],
      [34, -76],
      [-77, -64],
      [-75, 35],
      [-21, 66],
      [-68, 39],
      [-22, 43],
      [18, 58],
      [150, 5],
      [58, 49],
    ],
    [
      [81625, 137517],
      [93, -42],
      [-3, -52],
      [59, -3],
      [20, -40],
      [63, -6],
      [13, -70],
      [-43, -39],
      [-86, -3],
      [-17, -60],
      [67, -1],
      [14, -79],
      [-63, -2],
      [-19, -159],
      [-49, -49],
      [-126, -33],
      [-23, 23],
      [43, 68],
      [-14, 61],
      [-61, 54],
      [7, 77],
      [-77, 8],
      [-85, -54],
      [86, -117],
      [-4, -46],
      [-141, -4],
      [-75, 61],
      [44, 120],
      [-27, 35],
      [-8, 95],
      [36, 65],
      [91, -83],
      [94, -6],
      [18, 134],
      [45, 44],
      [-9, 68],
      [42, 33],
      [95, 2],
    ],
    [
      [81225, 138537],
      [82, -62],
      [144, -205],
      [64, -52],
      [-96, -61],
      [-59, 11],
      [-27, -60],
      [-49, -18],
      [-19, 65],
      [33, 33],
      [-6, 117],
      [36, 26],
      [-50, 97],
      [-2, 51],
      [-51, 58],
    ],
    [[81315, 142477], [45, -21], [2, -62], [-145, -15], [-15, 59], [113, 39]],
    [
      [81897, 143063],
      [32, -47],
      [-146, -89],
      [-101, -26],
      [-45, -36],
      [-120, -36],
      [-62, -44],
      [-45, 34],
      [-129, -24],
      [-7, 52],
      [80, 9],
      [85, 99],
      [90, 32],
      [59, -60],
      [177, 93],
      [67, 106],
      [75, -29],
      [-10, -34],
    ],
    [
      [83714, 142720],
      [15, -111],
      [-43, -37],
      [-48, 33],
      [-15, 140],
      [82, 42],
      [9, -67],
    ],
    [[82287, 143473], [39, -9], [17, -74], [-116, 15], [60, 68]],
    [
      [82552, 143647],
      [15, -30],
      [-62, -69],
      [29, -124],
      [-74, -66],
      [-15, 128],
      [32, 21],
      [-1, 104],
      [76, 36],
    ],
    [
      [82990, 143868],
      [-104, -64],
      [9, -43],
      [-70, -32],
      [-76, 1],
      [-92, -30],
      [-18, 48],
      [94, 25],
      [56, -7],
      [46, 53],
      [54, 2],
      [5, 59],
      [96, -12],
    ],
    [
      [81027, 144191],
      [-51, -37],
      [-154, -174],
      [-56, 5],
      [-109, 63],
      [35, 85],
      [228, 105],
      [107, -47],
    ],
    [
      [82126, 144461],
      [22, -51],
      [-208, -101],
      [-73, -69],
      [-22, 42],
      [-68, -6],
      [-20, -106],
      [-108, -28],
      [-34, 49],
      [-153, -87],
      [-34, 99],
      [129, 2],
      [52, 23],
      [84, 103],
      [-21, 31],
      [80, 90],
      [88, -104],
      [26, 85],
      [54, 27],
      [36, -46],
      [61, 3],
      [22, 65],
      [67, 40],
      [20, -61],
    ],
    [
      [82473, 144738],
      [-9, -57],
      [-55, -84],
      [-131, -39],
      [-37, -41],
      [-99, -36],
      [-26, 45],
      [57, 25],
      [18, 66],
      [64, 1],
      [55, 58],
      [175, 133],
      [-12, -71],
    ],
    [
      [83514, 146242],
      [-66, -131],
      [-28, -87],
      [-8, -90],
      [-53, -77],
      [-25, -85],
      [19, -39],
      [-38, -62],
      [-130, -42],
      [-3, -88],
      [-32, -60],
      [-98, -102],
      [-34, -112],
      [-73, -56],
      [-59, -126],
      [-83, -49],
      [0, -31],
      [-86, -103],
      [-62, 26],
      [-73, -36],
      [-25, -63],
      [-47, 33],
      [79, 86],
      [-23, 61],
      [71, 43],
      [108, 91],
      [77, 100],
      [68, 189],
      [128, 162],
      [-9, 53],
      [81, 20],
      [22, 57],
      [74, -20],
      [48, 12],
      [72, 84],
      [-20, 97],
      [57, 49],
      [71, 155],
      [-10, 52],
      [39, 115],
      [71, -26],
    ],
    [
      [63494, 142720],
      [76, -72],
      [-43, -29],
      [-101, -10],
      [-69, 22],
      [6, 39],
      [69, 54],
      [62, -4],
    ],
    [
      [80486, 143845],
      [84, -47],
      [44, -103],
      [-106, -70],
      [-25, -93],
      [-132, -73],
      [-81, -95],
      [-39, -88],
      [-109, -119],
      [-84, -39],
      [-111, 3],
      [-27, -88],
      [-110, -1],
      [-230, -52],
      [-43, -36],
      [-68, -14],
      [-116, -111],
      [-63, 60],
      [33, 55],
      [3, 93],
      [69, 72],
      [30, 98],
      [126, -75],
      [98, 34],
      [73, 107],
      [56, -22],
      [82, 21],
      [59, -21],
      [76, 57],
      [126, 69],
      [123, 124],
      [32, 75],
      [43, -24],
      [59, 25],
      [80, 132],
      [-85, 95],
      [42, 55],
      [91, -4],
    ],
    [[80769, 143520], [-89, -145], [-55, 59], [144, 86]],
    [[80748, 143895], [-82, -70], [-55, 47], [95, 56], [42, -33]],
    [
      [71878, 144392],
      [105, -9],
      [28, -69],
      [-123, -4],
      [-82, -80],
      [-41, 52],
      [-86, -50],
      [54, -64],
      [-112, -72],
      [-40, 55],
      [22, 67],
      [-52, 73],
      [149, 65],
      [42, 51],
      [102, 4],
      [34, -19],
    ],
    [[67936, 144384], [-79, 27], [21, 71], [79, -57], [-21, -41]],
    [[63760, 142851], [-11, -44], [-59, -17], [-123, 7], [97, 68], [96, -14]],
    [
      [63540, 142920],
      [-17, -45],
      [-96, -29],
      [-100, 23],
      [74, 51],
      [98, 19],
      [41, -19],
    ],
    [[63744, 142970], [-59, -8], [-72, 29], [152, 45], [-21, -66]],
    [
      [67216, 144292],
      [24, 82],
      [4, 105],
      [50, -9],
      [18, -82],
      [33, -42],
      [-54, -69],
      [-75, 15],
    ],
    [
      [61043, 145228],
      [74, -183],
      [101, -98],
      [-6, -89],
      [-73, -105],
      [-5, -78],
      [91, -108],
      [78, -23],
      [140, -98],
      [-15, -135],
      [29, -164],
      [58, -12],
      [170, -81],
      [56, 15],
      [52, -57],
      [72, -6],
      [32, -76],
      [82, -8],
      [83, -69],
      [-77, -178],
      [-44, -60],
      [-81, -3],
      [-58, 48],
      [-238, -54],
      [-156, 52],
      [-214, 41],
      [-134, 71],
      [-150, 30],
      [-332, -99],
      [-126, -24],
      [-221, -64],
      [-76, 22],
      [-111, 7],
      [-56, -19],
      [-63, 60],
      [-35, 106],
      [43, 59],
      [83, -32],
      [50, 149],
      [-8, 116],
      [57, 56],
      [146, -114],
      [107, -5],
      [100, 112],
      [33, 55],
      [45, 5],
      [147, -150],
      [26, 89],
      [-72, 33],
      [-31, 65],
      [-132, 8],
      [36, 180],
      [-91, 92],
      [57, 64],
      [41, 92],
      [-62, 87],
      [-100, -22],
      [-27, 48],
      [71, 46],
      [37, 109],
      [159, 57],
      [48, 81],
      [57, 44],
      [-26, 68],
      [56, 39],
      [139, 14],
      [129, 66],
      [35, -72],
    ],
    [
      [61275, 145750],
      [94, -70],
      [112, -143],
      [91, -80],
      [103, -45],
      [45, 5],
      [30, 55],
      [150, -74],
      [31, -104],
      [-75, -71],
      [-54, 4],
      [-44, -74],
      [8, -67],
      [87, -64],
      [66, 51],
      [67, -1],
      [78, 60],
      [-2, 43],
      [61, 50],
      [11, -170],
      [-15, -27],
      [83, -88],
      [12, -54],
      [53, -40],
      [-13, -43],
      [63, -71],
      [29, 66],
      [120, -35],
      [-1, -35],
      [101, -61],
      [18, 55],
      [-84, 28],
      [12, 62],
      [-53, 62],
      [-55, 21],
      [-53, 67],
      [-90, -6],
      [23, 123],
      [205, 32],
      [33, 37],
      [115, 2],
      [135, 19],
      [144, 109],
      [36, 53],
      [105, -27],
      [214, -73],
      [14, -50],
      [145, -42],
      [68, 90],
      [-35, 74],
      [87, 26],
      [160, 98],
      [52, 88],
      [75, 13],
      [62, -52],
      [23, -83],
      [-18, -65],
      [77, -75],
      [38, 70],
      [-31, 51],
      [42, 40],
      [-15, 102],
      [39, 80],
      [132, 39],
      [15, 84],
      [98, 2],
      [95, -84],
      [32, -49],
      [-55, -61],
      [-14, -59],
      [84, -29],
      [30, 69],
      [52, 24],
      [109, -51],
      [-10, -79],
      [81, -25],
      [28, 42],
      [-26, 52],
      [75, 31],
      [66, -27],
      [23, -69],
      [-46, -50],
      [112, -82],
      [-22, -82],
      [56, -63],
      [-21, -51],
      [56, -41],
      [79, 43],
      [71, -30],
      [49, -69],
      [11, -146],
      [-38, -48],
      [-66, 26],
      [-46, -11],
      [-82, -78],
      [-48, -77],
      [-21, -89],
      [2, -115],
      [46, -28],
      [-13, -83],
      [-163, 9],
      [-55, 63],
      [-83, -11],
      [-78, -156],
      [-55, -68],
      [-36, -80],
      [-179, -185],
      [-53, 24],
      [-226, -132],
      [-64, -65],
      [-132, -50],
      [-127, -75],
      [-91, -23],
      [-120, -151],
      [-70, 0],
      [-100, -76],
      [-79, -95],
      [-51, -21],
      [-64, 9],
      [-77, 119],
      [-208, 154],
      [-208, 31],
      [-157, 70],
      [-116, 71],
      [-135, 21],
      [-51, 112],
      [-108, 50],
      [-80, 84],
      [-72, 17],
      [-140, 139],
      [-88, -16],
      [-140, 84],
      [-56, 113],
      [6, 92],
      [50, 97],
      [-18, 48],
      [-89, 42],
      [-46, 76],
      [-60, 37],
      [8, 78],
      [-55, 76],
      [26, 102],
      [-50, 57],
      [-7, 136],
      [-69, 9],
      [-3, 44],
      [33, 110],
      [38, 44],
      [-67, 100],
      [-70, 33],
      [-23, 45],
      [31, 197],
      [1, 69],
      [106, 14],
      [47, -54],
    ],
    [
      [72044, 144689],
      [8, -36],
      [-120, -9],
      [-69, 38],
      [-76, -69],
      [-63, 25],
      [2, 56],
      [176, 80],
      [85, 20],
      [24, -83],
      [33, -22],
    ],
    [
      [68898, 146424],
      [-48, -82],
      [21, -144],
      [43, -87],
      [17, -144],
      [42, -42],
      [-42, -42],
      [-54, 9],
      [-26, -55],
      [29, -67],
      [58, 22],
      [69, -17],
      [-74, -97],
      [47, -88],
      [11, -177],
      [-69, -51],
      [-42, -145],
      [-106, 91],
      [-42, 136],
      [-8, 76],
      [-42, 28],
      [-65, 105],
      [-44, 27],
      [-37, 97],
      [8, 65],
      [70, 69],
      [5, 140],
      [-151, 115],
      [45, 34],
      [75, -34],
      [78, 48],
      [66, 3],
      [51, 44],
      [-11, 115],
      [48, 70],
      [78, -22],
    ],
    [
      [69949, 145889],
      [76, -7],
      [-3, -73],
      [-58, 18],
      [-65, -34],
      [-7, 78],
      [57, 18],
    ],
    [[70292, 146089], [-11, 94], [93, -55], [-31, -58], [-51, 19]],
    [
      [98341, 86642],
      [0, 585],
      [-1, 943],
      [-12, 1557],
      [4, 1329],
      [0, 1272],
      [1, 532],
      [3, 1205],
      [5, 92],
      [0, 1105],
      [0, 1342],
      [-1380, 0],
      [-876, 0],
      [-1691, 0],
      [-1332, -1],
      [-1476, 0],
      [-950, 0],
      [-672, 0],
      [-964, 0],
      [-1167, -1],
    ],
    [
      [87840, 127962],
      [143, -97],
      [64, -79],
      [69, -50],
      [129, -117],
      [130, -66],
      [144, -94],
      [250, -70],
      [79, -53],
      [121, -57],
      [34, 40],
      [135, -16],
      [197, -113],
      [79, 14],
      [93, -34],
      [60, 13],
      [160, -8],
      [235, 24],
      [69, 21],
      [101, -29],
      [64, -64],
      [275, -100],
      [118, -57],
      [129, -30],
      [49, -38],
      [217, -66],
      [60, 27],
      [102, -43],
      [119, 5],
      [74, -16],
      [135, -67],
      [-15, -44],
      [31, -82],
      [118, -90],
      [11, -46],
      [81, -49],
      [191, -80],
      [-58, -142],
      [48, -302],
      [58, -151],
      [43, -65],
      [84, -175],
      [52, -75],
      [87, -76],
      [137, -79],
      [192, -78],
      [183, 8],
      [83, -8],
      [102, -53],
      [107, -34],
      [123, -98],
      [70, -28],
      [71, -71],
      [-16, -23],
      [156, -54],
      [81, -111],
      [123, -67],
      [236, -36],
      [122, -92],
      [197, -8],
      [44, 25],
      [90, -84],
      [106, -46],
      [161, -46],
      [62, -46],
      [-2, -74],
      [41, -61],
      [107, -88],
      [127, -44],
      [98, -13],
      [162, -2],
      [399, 66],
      [43, -12],
      [312, 58],
      [243, 30],
      [125, 52],
      [120, 74],
      [50, -24],
      [189, 61],
      [174, 97],
      [77, 15],
      [149, 73],
      [88, 64],
      [54, 63],
      [136, 88],
      [90, -7],
      [30, 45],
      [89, 69],
      [202, 64],
      [184, 243],
      [48, 119],
      [24, 161],
      [24, 40],
      [20, 189],
      [41, 105],
      [73, 138],
      [40, 224],
      [-35, 168],
      [39, 140],
      [55, 94],
      [131, 97],
      [99, 113],
      [89, 121],
      [49, 112],
      [5, 99],
      [120, 51],
      [92, 74],
      [153, 160],
      [70, 95],
      [59, 119],
      [41, 226],
      [77, 110],
      [66, 40],
      [79, 155],
      [30, 120],
      [-11, 54],
      [-51, 60],
      [-4, 58],
      [37, 36],
      [72, 148],
      [92, 273],
      [11, 134],
      [26, 53],
      [163, 233],
      [74, 128],
      [35, 91],
      [-4, 52],
      [-71, 120],
      [-62, 248],
      [-67, 114],
      [68, 12],
      [58, 139],
      [22, 111],
      [33, 396],
      [82, 363],
      [111, 368],
      [60, 63],
      [125, 317],
      [45, 174],
      [61, 74],
      [44, 143],
      [18, 187],
      [47, 66],
      [86, 75],
      [47, 17],
      [33, 130],
      [-32, 55],
      [39, 114],
      [57, 56],
      [26, 116],
      [-39, 143],
      [-93, 101],
      [-97, 200],
      [-45, 114],
      [-15, 122],
      [-63, 247],
      [-4, 111],
      [-43, 214],
      [0, 165],
      [-50, 195],
      [-48, 155],
      [30, 157],
      [63, 214],
      [56, 146],
      [59, 110],
      [47, 132],
      [17, 94],
      [-5, 105],
      [-27, 153],
      [23, 61],
      [-7, 80],
      [-44, 106],
      [-69, 97],
      [-191, 179],
      [-58, 68],
      [-94, 166],
      [-5, 199],
      [44, 198],
      [35, 110],
      [34, 194],
      [14, 183],
      [70, 284],
      [96, 216],
      [42, 31],
      [95, 110],
      [52, 94],
      [65, 179],
      [206, 264],
      [49, 126],
      [-19, 93],
      [-38, 63],
      [-77, 45],
      [-35, 99],
      [-64, 49],
      [-27, 160],
      [-28, 64],
      [19, 67],
      [-20, 139],
      [-63, 48],
      [-43, 62],
      [63, 148],
      [96, 120],
      [73, -8],
      [71, 79],
      [56, 37],
      [85, 15],
      [90, 71],
      [79, 109],
      [130, 317],
      [36, 169],
      [51, 21],
      [28, -49],
      [122, -23],
      [51, 73],
      [-64, 23],
      [-68, -7],
      [-27, 82],
      [101, 18],
      [98, 75],
      [16, 68],
      [-72, 11],
      [-178, -38],
      [-31, 51],
      [-119, 40],
      [-86, 126],
      [-159, 57],
      [-48, -49],
      [-31, -91],
      [-91, -1],
      [-129, -69],
      [-113, 61],
      [89, 229],
      [111, 212],
      [137, 340],
      [46, 149],
      [7, 62],
      [46, 104],
      [55, 34],
      [75, 144],
      [235, 493],
      [52, 53],
      [53, 102],
      [116, 106],
      [80, -3],
      [-70, -99],
      [50, -107],
      [26, -8],
      [35, -101],
      [-9, -29],
      [24, -167],
      [23, 12],
      [12, 110],
      [89, 94],
      [127, -57],
      [63, 68],
      [-37, 84],
      [-105, 112],
      [-101, 12],
      [-2, 102],
      [27, 202],
      [42, 212],
      [54, 146],
      [73, 55],
      [62, 289],
      [29, 17],
      [8, 80],
      [59, 174],
      [28, 122],
      [56, 99],
      [105, 258],
      [69, 243],
      [31, 206],
      [-1, 213],
      [-22, 70],
      [70, 79],
      [29, 214],
      [34, 54],
      [-43, 193],
      [-1, 82],
      [-65, 191],
      [32, 106],
      [74, 57],
      [131, 57],
      [74, -22],
      [110, 39],
      [134, -26],
      [84, 54],
      [36, -15],
      [54, 53],
      [59, 22],
      [50, 123],
      [96, 81],
      [56, 112],
      [6, 53],
      [53, 111],
      [-6, 43],
      [50, 59],
      [33, -33],
      [77, -7],
      [100, 15],
      [104, 69],
      [107, -109],
      [104, -18],
      [63, -49],
      [-95, -136],
      [-135, -171],
      [-77, -26],
      [-18, -40],
      [10, -163],
      [-34, -17],
      [-32, -75],
      [8, -63],
      [151, 157],
      [13, 56],
      [101, 74],
      [49, 5],
      [96, -80],
      [8, -80],
      [-34, -58],
      [48, -32],
      [53, 21],
      [105, -25],
      [45, -89],
      [56, -14],
      [51, 22],
      [120, -81],
      [25, -55],
      [-12, -56],
      [61, -100],
      [68, -57],
      [6, -179],
      [38, -84],
      [-11, -113],
      [-44, -116],
      [41, -118],
      [-6, -61],
      [33, -50],
      [64, -6],
      [29, -70],
      [40, -16],
      [81, -131],
      [17, -85],
      [-126, -168],
      [-20, -146],
      [45, -45],
      [-14, -225],
      [60, -91],
      [-2, -108],
      [26, -51],
      [6, -128],
      [-7, -155],
      [12, -86],
      [-34, -144],
      [12, -183],
      [69, -74],
      [84, -13],
      [32, -61],
      [128, -34],
      [86, -101],
      [94, -18],
      [150, 10],
      [87, 32],
      [87, 55],
      [111, 7],
      [92, -48],
      [3, -74],
      [85, -110],
      [182, 69],
      [72, -64],
      [-37, -37],
      [-86, 31],
      [-86, -52],
      [-180, -331],
      [-35, -39],
      [-105, -178],
      [-24, -140],
      [33, -70],
      [-38, -96],
      [27, -16],
      [-44, -119],
      [-13, -140],
      [51, -39],
      [265, -13],
      [89, -12],
      [73, -38],
      [57, -67],
      [128, -42],
      [18, -33],
      [-5, -107],
      [25, -52],
      [-10, -186],
      [83, -73],
      [43, -80],
      [91, -37],
      [60, 7],
      [139, -105],
      [76, 5],
      [78, -86],
      [-122, -140],
      [7, -59],
      [-51, -116],
      [-31, -123],
      [-37, -39],
      [-46, -120],
      [8, -110],
      [-32, -184],
      [88, -36],
      [56, 8],
      [57, 103],
      [148, 56],
      [57, -45],
      [126, 13],
      [52, 47],
      [94, 6],
      [-100, -180],
      [-55, -127],
      [-4, -59],
      [50, -36],
      [-31, -75],
      [39, -76],
      [-26, -100],
      [20, -64],
      [-36, -170],
      [8, -117],
      [57, -91],
      [-18, -154],
      [-3, -133],
      [24, -53],
      [13, -157],
      [43, -93],
      [65, -49],
      [84, -26],
      [26, -107],
      [26, -25],
      [-1, -104],
      [-55, -165],
      [23, -169],
      [-94, -139],
      [-48, -171],
      [7, -42],
      [-21, -238],
      [-36, -112],
      [74, -184],
      [67, -55],
      [67, -96],
      [32, -84],
      [3, -89],
      [24, -31],
      [51, -147],
      [32, -48],
      [76, -42],
      [93, -73],
      [18, -112],
      [27, -61],
      [0, -101],
      [37, -105],
      [-18, -177],
      [41, -154],
      [71, -133],
      [44, -192],
      [81, -171],
      [113, -155],
      [170, -133],
      [88, -49],
      [81, -22],
      [130, 2],
      [56, -40],
      [33, 32],
      [75, -8],
      [135, 24],
      [151, 53],
      [121, 66],
      [129, 145],
      [127, 67],
      [69, 106],
      [37, 86],
      [8, 89],
      [-17, 118],
      [75, 22],
      [51, -48],
      [72, -6],
      [29, -61],
      [192, -65],
      [90, 12],
      [138, 57],
      [45, 43],
      [144, 51],
      [107, 106],
      [71, 124],
      [21, 80],
      [86, -2],
      [69, -71],
      [10, -43],
      [70, -93],
      [132, -108],
      [-53, -147],
      [15, -25],
      [0, -136],
      [51, -35],
      [145, 39],
      [37, -38],
      [-41, -53],
      [-68, -163],
      [-7, -151],
      [17, -29],
      [147, -99],
      [65, -129],
      [107, -14],
      [211, 30],
      [21, -147],
      [96, -21],
      [106, -52],
      [191, 15],
      [59, -14],
      [49, -89],
      [-1, -74],
      [66, -6],
      [32, -70],
      [-4, -139],
      [64, -64],
      [137, -26],
      [-19, -40],
      [66, -51],
      [9, -42],
      [231, -25],
      [83, -76],
      [60, -24],
      [111, -12],
      [55, -22],
      [154, 21],
      [-14, -103],
      [16, -54],
      [69, -81],
      [78, -61],
      [61, -94],
      [50, -26],
      [62, 33],
      [132, 7],
      [8, -92],
      [-176, -156],
      [-95, -116],
      [-99, -163],
      [-47, -111],
      [11, -173],
      [76, -169],
      [40, -39],
      [137, -6],
      [62, 40],
      [16, 66],
      [70, -134],
      [-80, -32],
      [-143, -110],
      [-23, -40],
      [47, -81],
      [-39, -51],
      [9, -41],
      [-58, -41],
      [-82, -94],
      [-33, -139],
      [64, 5],
      [52, -54],
      [21, -115],
      [-26, -95],
      [58, -74],
      [60, 24],
      [50, -48],
      [16, -82],
      [-27, -75],
      [11, -133],
      [54, -32],
      [2, -41],
      [97, -148],
      [21, -164],
      [-54, -125],
      [23, -78],
      [64, -43],
      [-71, -156],
      [5, -70],
      [163, -96],
      [82, -108],
      [23, -84],
      [56, -36],
      [46, -89],
      [1, -91],
      [39, -58],
      [-56, -104],
      [3, -47],
      [-65, -161],
      [13, -86],
      [85, -34],
      [4, -83],
      [38, -16],
      [6, -101],
      [-82, -62],
      [-42, 1],
      [-30, -72],
      [-80, -113],
      [-14, -94],
      [27, -47],
      [-43, -145],
      [20, -114],
      [48, -28],
      [137, -10],
      [14, -123],
      [181, -241],
      [4, -45],
      [142, -135],
      [60, -145],
      [149, -52],
      [104, -99],
      [67, -28],
      [6, -70],
      [77, -52],
      [68, -89],
      [24, 7],
      [82, -151],
      [104, -80],
      [-35, -58],
      [0, -65],
      [74, -55],
      [24, 44],
      [64, 8],
      [100, 99],
      [47, -5],
      [33, -80],
      [78, -10],
      [83, 60],
      [69, 76],
      [93, -85],
      [-89, -122],
      [22, -44],
      [-97, -99],
      [-40, -84],
      [-20, -111],
      [4, -113],
      [84, -75],
      [45, -101],
      [67, -40],
      [14, -86],
      [44, -49],
      [-11, -67],
      [32, -93],
      [10, -92],
      [40, -19],
      [33, -68],
      [51, -168],
      [42, -79],
      [52, -43],
      [39, -68],
      [93, -56],
      [-30, -164],
      [9, -69],
      [37, -36],
      [-29, -147],
      [75, -123],
      [86, -63],
      [69, -96],
      [18, -93],
      [30, -43],
      [-62, -52],
      [-63, -96],
      [-31, -116],
      [26, -136],
      [-57, -63],
      [-15, -56],
      [51, -42],
      [-20, -44],
      [51, -88],
      [-50, -102],
      [-11, -140],
      [-21, -62],
      [5, -86],
      [-78, -75],
      [-50, -100],
      [-64, -78],
      [-27, -108],
      [-78, -122],
      [-2, -174],
      [37, -208],
      [29, -89],
      [81, -66],
      [187, -220],
      [118, -64],
      [80, -96],
      [40, -170],
      [77, 11],
      [-12, -96],
      [78, 6],
      [50, -88],
      [110, 11],
      [37, -34],
      [95, -9],
      [65, -46],
      [84, -13],
      [26, -94],
      [-45, -288],
      [-106, -214],
      [-85, -329],
      [-5, -165],
      [51, -128],
      [89, -80],
      [78, -135],
      [89, -34],
      [23, -67],
      [91, -34],
      [145, -188],
      [66, -66],
      [45, 1],
      [74, -68],
      [91, -15],
      [86, -55],
      [78, -88],
      [112, -14],
      [83, -59],
      [245, -95],
      [85, 4],
      [42, 36],
      [149, -11],
      [25, -18],
      [15, -104],
      [38, -60],
      [52, 4],
      [106, -107],
      [58, -18],
      [105, -90],
      [53, -8],
      [223, 41],
      [57, 31],
      [152, 218],
      [-7, 89],
      [77, -52],
      [18, -103],
      [50, -54],
      [7, -74],
      [-53, 6],
      [7, -84],
      [67, -142],
      [162, -148],
      [-13, -32],
      [56, -84],
      [30, 51],
      [74, 0],
      [141, -28],
      [59, -28],
      [76, 18],
      [43, -46],
      [123, 38],
      [77, 44],
      [197, -39],
      [106, 5],
      [70, -26],
      [107, 16],
      [49, 102],
      [46, -41],
      [22, -101],
      [51, -111],
      [105, -108],
      [66, -92],
      [30, -1],
      [65, -119],
      [72, -284],
      [66, -6],
      [41, -80],
      [-50, -53],
      [-52, -115],
      [51, -107],
      [102, -102],
      [115, -72],
      [46, -69],
      [216, -52],
      [91, 30],
      [45, 96],
      [-30, 80],
      [-81, 49],
      [53, 151],
      [-24, 54],
      [91, -10],
      [154, 3],
      [78, -59],
      [24, -59],
      [-6, -139],
      [39, -191],
      [81, -109],
      [43, -17],
      [56, -69],
      [87, -49],
      [220, -21],
      [57, 38],
      [34, 65],
      [196, 23],
      [49, -127],
      [52, -55],
      [184, -54],
      [84, 41],
      [123, -49],
      [55, -81],
      [98, -17],
      [6, -81],
      [-102, -26],
      [-42, -45],
      [18, -48],
      [158, -79],
      [-2, -85],
      [90, -37],
      [62, -84],
      [65, -16],
      [9, -41],
      [172, -35],
      [132, -10],
      [67, 73],
      [43, 3],
      [51, 94],
      [-101, 6],
      [-19, 133],
      [40, 47],
      [79, -17],
      [93, -86],
      [101, -11],
      [65, 71],
      [60, -10],
      [-40, -62],
      [12, -77],
      [71, -79],
      [-3, -51],
      [56, -64],
      [-32, -68],
      [50, -18],
      [58, -62],
      [67, 20],
      [-19, 65],
      [61, 69],
      [-2, -125],
      [54, -14],
      [24, -58],
      [47, 8],
      [-13, -175],
      [117, 69],
      [76, -26],
      [73, 44],
      [-3, 72],
      [73, -22],
      [9, -89],
      [86, -30],
      [-110, -70],
      [162, -71],
      [89, -103],
      [-40, -48],
      [41, -99],
      [-11, -72],
      [75, -107],
      [81, -74],
      [6, -51],
      [140, -103],
      [-14, -44],
      [-96, -28],
      [-20, 52],
      [-107, -9],
      [-38, 79],
      [-74, 9],
      [7, 93],
      [-30, 38],
      [37, 68],
      [-50, 66],
      [-81, 20],
      [-35, -83],
      [6, -78],
      [-68, -46],
      [-96, -14],
      [-75, 69],
      [-61, -24],
      [58, -51],
      [-30, -123],
      [-88, -102],
      [-59, -119],
      [81, -42],
      [6, -76],
      [30, -46],
      [78, -19],
      [-3, -68],
      [-40, -27],
      [92, -61],
      [-7, -90],
      [165, -52],
      [-1, -77],
      [86, -67],
      [97, -3],
      [-65, -125],
      [94, -46],
      [37, -65],
      [-13, -93],
      [143, 60],
      [16, -67],
      [87, -47],
      [101, 22],
      [107, -52],
      [29, 30],
      [79, -68],
      [83, 49],
      [64, -22],
      [-2, -55],
      [-95, -20],
      [18, -56],
      [-17, -106],
      [76, -45],
      [26, 34],
      [140, 10],
      [60, -66],
      [78, -44],
      [22, 47],
      [86, -21],
      [9, -88],
      [87, -21],
      [30, -74],
      [53, -68],
      [69, -4],
      [-29, -176],
      [-64, -78],
      [-34, -101],
      [9, -74],
      [-36, -29],
      [84, -51],
      [14, -33],
      [-52, -88],
      [15, -63],
      [75, 78],
      [115, 61],
      [28, -85],
      [104, -49],
      [6, -183],
      [63, -46],
      [4, -187],
      [-13, -53],
      [-68, -47],
      [-23, -153],
      [46, -73],
      [68, 62],
      [88, 49],
      [61, -94],
      [102, 34],
      [-40, -137],
      [208, -67],
      [33, -72],
      [70, 140],
      [58, 3],
      [5, -122],
      [-45, -62],
      [-70, -45],
      [-46, -97],
      [18, -118],
      [37, -39],
      [13, -162],
      [-86, -78],
      [-10, -150],
      [24, -44],
      [84, -39],
      [31, -59],
      [-34, -120],
      [14, -173],
      [52, -93],
      [27, -101],
      [76, -66],
      [46, -77],
      [-23, -151],
      [92, -195],
      [36, -21],
      [14, -142],
      [54, -159],
      [97, -100],
      [-12, -67],
      [96, -50],
      [98, -110],
      [20, -84],
      [119, 25],
      [73, -30],
      [63, 29],
      [118, -88],
      [-61, -37],
      [-11, -139],
      [27, -30],
      [69, 53],
      [44, 133],
      [94, -6],
      [61, -72],
      [78, -47],
      [67, -160],
      [66, -89],
      [117, -53],
      [18, -42],
      [124, -59],
      [100, -157],
      [89, -160],
      [49, 54],
      [-69, 119],
      [-31, 121],
      [3, 54],
      [-64, 77],
      [-39, 135],
      [-37, 34],
      [-81, 185],
      [-30, 37],
      [-64, 143],
      [82, 6],
      [-67, 70],
      [18, 60],
      [-17, 96],
      [49, 72],
      [37, 134],
      [76, 132],
      [80, 35],
      [64, 69],
      [70, -14],
      [37, 59],
      [85, -24],
      [14, -48],
      [68, -24],
      [50, -195],
      [45, -53],
      [145, -89],
      [20, -150],
      [122, -67],
      [-47, -52],
      [-5, -65],
      [77, -34],
      [79, -65],
      [267, -53],
      [10, -50],
      [74, -18],
      [40, -48],
      [243, -133],
      [7, -50],
      [68, -8],
      [76, -79],
      [90, -12],
      [106, -39],
      [150, -78],
      [76, -19],
      [90, 8],
      [-91, 150],
      [9, 49],
      [-57, 64],
      [13, 28],
      [-81, 90],
      [24, 148],
      [-24, 80],
      [23, 101],
      [92, 115],
      [70, -44],
      [140, -8],
      [69, -52],
      [-28, -115],
      [70, -107],
      [42, 44],
      [-67, 107],
      [103, 16],
      [27, -164],
      [55, -86],
      [152, -22],
      [41, -74],
      [-65, -19],
      [-37, -47],
      [-9, -86],
      [-99, 25],
      [-44, 47],
      [-102, 15],
      [61, -149],
      [61, -69],
      [103, -9],
      [19, -79],
      [-26, -58],
      [19, -51],
      [68, -12],
      [4, 111],
      [46, 38],
      [15, 64],
      [-28, 96],
      [101, -18],
      [17, -87],
      [-29, -61],
      [-17, -134],
      [10, -83],
      [82, -21],
      [11, -87],
      [93, -44],
      [-87, -344],
      [2, -58],
      [-47, -134],
      [-23, -155],
      [15, -167],
      [-48, -62],
      [-19, -300],
      [-69, -174],
      [-5, -102],
      [48, -28],
      [36, -70],
      [62, 10],
      [3, -127],
      [-15, -27],
      [62, -71],
      [36, 28],
      [44, -110],
      [-18, -69],
      [-121, -113],
      [9, -151],
      [27, -91],
      [57, -119],
      [65, -81],
      [127, -124],
      [-36, -72],
      [-38, -17],
      [-159, 19],
      [-43, -32],
      [113, -66],
      [187, -71],
      [-42, -81],
      [85, 15],
      [149, 91],
      [92, -29],
      [-2, -156],
      [46, 31],
      [7, 114],
      [165, -37],
      [81, -191],
      [101, -50],
      [81, -59],
      [91, -46],
      [43, -75],
      [-35, -52],
      [81, -43],
      [42, -80],
      [-53, -67],
      [67, -81],
      [30, -74],
      [76, -36],
      [76, 5],
      [197, -45],
      [9, -38],
      [82, -38],
      [56, 22],
      [91, -200],
      [54, -72],
      [174, -102],
      [78, -71],
      [30, -127],
      [-34, -68],
      [31, -34],
      [75, 28],
      [79, 1],
      [-140, 130],
      [12, 55],
      [90, -8],
      [117, -61],
      [150, -54],
      [-128, -37],
      [70, -77],
      [-7, -86],
      [105, 5],
      [14, 108],
      [65, 31],
      [123, 9],
      [89, -88],
      [89, -47],
      [18, 107],
      [-26, 46],
      [-84, 61],
      [-87, 40],
      [-8, 94],
      [125, 3],
      [94, -36],
      [55, -55],
      [130, -80],
      [60, -21],
      [16, 76],
      [76, -51],
      [-4, -104],
      [68, -209],
      [61, -75],
      [92, -70],
      [181, -29],
      [204, -202],
      [44, -170],
      [49, -106],
      [56, -181],
      [63, -168],
      [128, -229],
      [68, -62],
      [144, -203],
      [196, -193],
      [111, -65],
      [65, -16],
      [96, -65],
      [62, -64],
      [157, -92],
      [204, -60],
      [85, 33],
      [275, -237],
      [80, -126],
      [54, -247],
      [-30, -103],
      [21, -115],
      [64, -118],
      [102, -139],
      [76, -145],
      [89, -80],
      [164, -86],
      [-62, -72],
      [-130, 22],
      [-30, -49],
      [90, -19],
      [69, 14],
      [93, -117],
      [93, -91],
      [209, -70],
      [144, -7],
      [129, 20],
      [29, 65],
      [49, -14],
      [20, -93],
      [69, -24],
      [131, 8],
      [83, -43],
      [-44, -68],
      [21, -98],
      [36, -66],
      [-28, -46],
      [21, -75],
      [-69, -112],
      [-95, -41],
      [-53, -89],
      [37, -50],
      [48, 70],
      [62, 36],
      [82, 9],
      [52, 71],
      [76, -5],
      [-33, -93],
      [-71, -28],
      [-92, -85],
      [32, -101],
      [-24, -53],
      [-102, -52],
      [18, -60],
      [-3, -128],
      [52, -17],
      [31, -91],
      [-28, -144],
      [103, -70],
      [-36, -40],
      [25, -59],
      [67, -20],
      [-12, -42],
      [118, -7],
      [25, -54],
      [-44, -113],
      [93, 20],
      [107, -77],
      [19, -67],
      [-46, -31],
      [66, -65],
      [-20, -60],
      [-51, -4],
      [-17, -66],
      [33, -58],
      [-44, -59],
      [14, -85],
      [104, 48],
      [30, 118],
      [-53, 15],
      [-13, 54],
      [110, 26],
      [26, 59],
      [-65, 67],
      [2, 52],
      [45, 77],
      [43, 30],
      [32, -210],
      [92, -149],
      [104, -75],
      [92, -16],
      [36, -36],
      [-88, -173],
      [-132, -316],
      [-78, -230],
      [-85, -337],
      [-13, -170],
      [18, -85],
      [45, -81],
      [131, 0],
      [-62, -305],
      [-23, -198],
      [35, -49],
      [-18, -157],
      [13, -150],
      [26, -104],
      [102, -39],
      [-11, -176],
      [16, -156],
      [43, -67],
      [-93, -13],
      [-44, -209],
      [-72, -87],
      [-34, -1],
      [-69, -68],
      [-13, -58],
      [-54, -41],
      [52, -54],
      [-40, -27],
      [16, -66],
      [71, -32],
      [34, -84],
      [75, -32],
      [35, -59],
      [110, -76],
      [-48, -37],
      [-77, -2],
      [-156, -88],
      [-120, -215],
      [28, -59],
      [45, 6],
      [143, -32],
      [89, 21],
      [-30, -210],
      [-52, 18],
      [-94, -84],
      [-4, -70],
      [61, -104],
      [196, -91],
      [63, -9],
      [14, -47],
      [99, 108],
      [47, -24],
      [-80, -109],
      [-35, -134],
      [88, -110],
      [33, -69],
      [197, -11],
      [6, -108],
      [91, 5],
      [-35, -87],
      [41, -79],
      [80, -84],
      [-32, -66],
      [38, -103],
      [-20, -54],
      [40, -53],
      [10, -87],
      [90, 12],
      [28, -54],
      [93, 7],
      [17, 2],
      [12, 7],
      [38, -43],
      [13, -48],
      [33, -25],
      [74, -8],
      [-3, -62],
      [-37, -55],
      [-142, -16],
      [-13, -56],
      [-50, 17],
      [25, -77],
      [24, -7],
      [2, -69],
      [-18, 1],
      [-6, -25],
      [26, -35],
      [94, -172],
      [7, -143],
      [47, -69],
      [88, -346],
      [84, -130],
      [46, -23],
      [53, -74],
      [77, -58],
      [102, 5],
    ],
    [[141722, 90485], [57, -73], [-38, -41], [-53, 101], [34, 13]],
    [
      [141924, 90776],
      [7, -11],
      [-63, -372],
      [-57, 36],
      [-6, 116],
      [57, 45],
      [62, 186],
    ],
    [
      [141802, 90673],
      [-3, -68],
      [-45, -47],
      [-62, 3],
      [-12, 28],
      [62, 46],
      [-20, 33],
      [80, 5],
    ],
    [
      [141795, 91157],
      [-63, -68],
      [23, -110],
      [-16, -42],
      [-19, -16],
      [-38, 9],
      [-18, 12],
      [-13, 23],
      [45, 63],
      [-43, 24],
      [18, 79],
      [124, 26],
    ],
    [
      [141997, 91959],
      [67, -63],
      [159, -35],
      [48, -36],
      [-53, -82],
      [-89, -204],
      [-101, -277],
      [-40, -163],
      [-27, -162],
      [1, -80],
      [-77, 2],
      [-24, -41],
      [-48, 7],
      [-22, 54],
      [-42, 51],
      [31, 47],
      [-8, 103],
      [77, 64],
      [-52, 103],
      [24, 56],
      [-16, 187],
      [12, 70],
      [-50, 67],
      [38, 68],
      [48, 20],
      [58, 188],
      [-2, 64],
      [88, -8],
    ],
    [
      [141979, 93190],
      [34, -11],
      [-82, -210],
      [-73, -266],
      [-22, -147],
      [-5, -148],
      [25, -139],
      [37, -49],
      [-44, -51],
      [-58, 110],
      [-26, 90],
      [-72, 123],
      [-41, 113],
      [23, 109],
      [-36, 115],
      [27, 80],
      [-8, 119],
      [-20, 44],
      [100, 62],
      [77, 9],
      [164, 47],
    ],
    [[141290, 92135], [-51, -23], [-43, 34], [40, 64], [71, -36], [-17, -39]],
    [
      [140889, 93476],
      [48, -152],
      [66, -141],
      [92, -154],
      [-31, -74],
      [-107, -12],
      [-42, 90],
      [-56, 42],
      [-26, 80],
      [-114, 74],
      [-29, 116],
      [-38, 38],
      [37, 80],
      [-54, 76],
      [14, 42],
      [82, 33],
      [96, 79],
      [62, -217],
    ],
    [
      [140154, 98191],
      [-79, -38],
      [-28, 77],
      [85, 79],
      [50, 12],
      [35, -53],
      [-63, -77],
    ],
    [
      [134549, 104013],
      [45, -109],
      [81, -54],
      [66, -181],
      [-43, -45],
      [-43, 17],
      [-18, 147],
      [-92, 21],
      [-39, 170],
      [43, 34],
    ],
    [
      [141278, 100913],
      [29, -36],
      [-25, -330],
      [4, -144],
      [32, -140],
      [93, -163],
      [98, -70],
      [90, -3],
      [0, -223],
      [-87, -158],
      [-210, -432],
      [-196, -382],
      [-236, -500],
      [-121, -285],
      [-51, -142],
      [-51, -205],
      [6, -185],
      [32, -119],
      [-41, -115],
      [-100, -13],
      [-69, 33],
      [-119, 181],
      [-23, 69],
      [4, 73],
      [-26, 40],
      [-43, 139],
      [13, 41],
      [-31, 63],
      [-72, 58],
      [22, 114],
      [125, 137],
      [11, 86],
      [-12, 116],
      [76, 128],
      [92, 88],
      [60, 130],
      [-4, 84],
      [-36, 151],
      [27, 63],
      [-26, 102],
      [-84, -19],
      [-69, 14],
      [10, 59],
      [109, 109],
      [139, 61],
      [191, 136],
      [135, 118],
      [119, 148],
      [112, 183],
      [16, 159],
      [-29, 73],
      [-171, 238],
      [-74, 56],
      [-116, 60],
      [189, 201],
      [129, 95],
      [159, 58],
    ],
    [
      [133629, 104982],
      [91, -57],
      [78, -8],
      [124, -64],
      [144, -1],
      [125, 56],
      [1, -124],
      [-35, -118],
      [0, -143],
      [31, -30],
      [17, -82],
      [57, -66],
      [59, 1],
      [49, -70],
      [53, -238],
      [-50, -39],
      [-16, -85],
      [-57, 43],
      [-74, 1],
      [-82, -65],
      [-83, 67],
      [-90, 116],
      [-126, 220],
      [-56, 54],
      [-83, 14],
      [-15, 35],
      [-161, 85],
      [-49, 169],
      [-70, 55],
      [-116, 215],
      [107, 41],
      [31, 81],
      [85, -14],
      [59, 24],
      [52, -73],
    ],
    [[133292, 106017], [9, -83], [-161, -18], [11, 54], [74, 44], [67, 3]],
    [
      [131659, 109131],
      [26, -15],
      [17, -108],
      [36, -59],
      [69, -54],
      [-48, -79],
      [-47, -11],
      [-61, -63],
      [-95, -38],
      [-25, 47],
      [36, 80],
      [-47, 53],
      [-12, 93],
      [80, 8],
      [-21, 71],
      [34, 26],
      [-89, 47],
      [34, 51],
      [113, -49],
    ],
    [[131458, 109083], [30, -61], [-61, -42], [-54, 46], [39, 70], [46, -13]],
    [[130514, 109933], [-57, -65], [-32, 69], [89, -4]],
    [[127948, 113483], [-1, -81], [-83, -25], [-12, 113], [96, -7]],
    [[127843, 113433], [-102, 30], [73, 43], [29, -73]],
    [[128522, 113564], [-78, 0], [-38, 76], [149, -24], [-33, -52]],
    [[127407, 113854], [-76, 12], [-11, 48], [70, 8], [17, -68]],
    [[126673, 114999], [27, -124], [-100, -28], [36, 138], [37, 14]],
    [[126551, 115045], [8, -41], [-78, -33], [-8, 72], [78, 2]],
    [[125844, 115380], [-4, -36], [75, -92], [-59, -42], [-34, 115], [22, 55]],
    [
      [126294, 115891],
      [-11, -74],
      [68, 0],
      [-26, -62],
      [51, -39],
      [2, -65],
      [71, -13],
      [100, -128],
      [-89, -28],
      [-77, -1],
      [-117, -68],
      [-35, 77],
      [-72, 6],
      [-88, 48],
      [51, 59],
      [40, 5],
      [39, 66],
      [-5, 100],
      [26, 101],
      [-14, 99],
      [73, -8],
      [13, -75],
    ],
    [
      [126233, 116268],
      [-43, -38],
      [6, -84],
      [-30, -68],
      [7, -104],
      [-69, -38],
      [-79, 23],
      [40, 84],
      [-112, -44],
      [22, 130],
      [51, 5],
      [28, 72],
      [97, 62],
      [82, 0],
    ],
    [
      [124530, 116503],
      [-3, -160],
      [-28, -17],
      [-82, 28],
      [-23, 74],
      [22, 47],
      [-16, 78],
      [63, 29],
      [67, -79],
    ],
    [
      [118882, 119473],
      [58, -38],
      [-25, -103],
      [-80, -47],
      [-49, -72],
      [-114, 68],
      [-80, 124],
      [48, 33],
      [70, -12],
      [89, 12],
      [57, -14],
      [26, 49],
    ],
    [[116461, 123241], [-122, 99], [29, 38], [94, -65], [-1, -72]],
    [[114015, 134211], [-13, -53], [-79, -15], [-11, 45], [46, 69], [57, -46]],
    [
      [129580, 109456],
      [39, -37],
      [-32, -69],
      [9, -71],
      [-17, -96],
      [-55, -88],
      [-42, -11],
      [-80, 33],
      [34, 52],
      [-56, 147],
      [35, 60],
      [-7, 93],
      [48, 137],
      [-6, 31],
      [95, 48],
      [-14, -83],
      [49, -146],
    ],
    [
      [129981, 109504],
      [-37, -51],
      [-139, -75],
      [-27, -42],
      [-105, -44],
      [-42, 65],
      [58, 59],
      [67, 0],
      [16, 43],
      [123, 66],
      [86, -21],
    ],
    [
      [117934, 120848],
      [84, -55],
      [110, -150],
      [139, 35],
      [-5, -64],
      [-124, -54],
      [-67, 43],
      [-160, 14],
      [-65, -15],
      [35, 126],
      [53, 120],
    ],
    [[117700, 120900], [-8, -100], [-56, 19], [64, 81]],
    [[117607, 121115], [-67, 86], [40, 32], [27, -118]],
    [[116465, 121899], [16, -125], [-45, 23], [-72, 145], [101, -43]],
    [
      [116654, 122443],
      [79, -96],
      [146, 4],
      [14, -45],
      [-70, -170],
      [-3, -56],
      [51, -27],
      [56, -98],
      [89, -24],
      [-29, -111],
      [106, -68],
      [-88, -105],
      [-6, -123],
      [-75, -28],
      [-54, 20],
      [-138, -5],
      [-100, 41],
      [-33, 86],
      [-40, 44],
      [22, 110],
      [-20, 72],
      [-87, 94],
      [-88, 137],
      [-111, 46],
      [-69, 50],
      [-104, 122],
      [131, 45],
      [67, -3],
      [76, -34],
      [59, -55],
      [59, -12],
      [49, -42],
      [97, -21],
      [32, 101],
      [-42, 84],
      [24, 67],
    ],
    [[116473, 122534], [-67, 13], [11, 82], [75, -16], [24, -40], [-43, -39]],
    [
      [131119, 110750],
      [-43, -41],
      [2, -64],
      [-51, -25],
      [-71, 45],
      [-29, 51],
      [98, -3],
      [94, 37],
    ],
    [
      [130866, 111045],
      [56, -72],
      [-95, -51],
      [-115, 56],
      [20, 113],
      [57, -38],
      [77, -8],
    ],
    [[93578, 126195], [-98, -154], [8, 104], [38, 46], [52, 4]],
    [
      [93259, 126338],
      [26, -113],
      [50, -47],
      [-64, -58],
      [-116, -17],
      [-19, -45],
      [-124, 9],
      [-19, 65],
      [-125, -18],
      [-39, -72],
      [-34, -12],
      [-52, 70],
      [-23, 80],
      [63, -1],
      [133, 89],
      [48, 100],
      [79, 16],
      [66, 113],
      [43, -69],
      [107, -90],
    ],
    [[91850, 127069], [-166, -108], [-25, 48], [12, 64], [132, 49], [47, -53]],
    [
      [91999, 127383],
      [-95, -68],
      [-50, 24],
      [-25, 87],
      [21, 47],
      [88, 70],
      [61, -35],
      [-21, -56],
      [21, -69],
    ],
    [[92999, 127509], [-72, -87], [-53, 43], [49, 67], [76, -23]],
    [
      [93345, 128446],
      [116, -19],
      [45, -60],
      [110, -89],
      [91, -4],
      [78, -94],
      [5, -106],
      [40, -71],
      [-76, -9],
      [-37, 53],
      [-59, 0],
      [-163, -71],
      [-67, -46],
      [-59, 90],
      [-11, 87],
      [-64, 34],
      [-58, -9],
      [-44, -79],
      [-110, 8],
      [-67, -55],
      [24, -36],
      [92, -2],
      [15, -41],
      [-72, -17],
      [-75, 10],
      [-30, -234],
      [-75, 0],
      [25, -125],
      [-123, -21],
      [-31, 88],
      [-50, 27],
      [-84, -111],
      [-8, -63],
      [-57, 2],
      [-91, -70],
      [-40, -67],
      [-40, 18],
      [-95, -29],
      [-71, 33],
      [-74, -25],
      [-46, 73],
      [-71, 5],
      [-25, 134],
      [-92, -2],
      [-53, 111],
      [33, 80],
      [163, 119],
      [38, 56],
      [7, 77],
      [61, 31],
      [62, 94],
      [129, 65],
      [82, 58],
      [80, -16],
      [89, 40],
      [199, 25],
      [157, -4],
      [84, 59],
      [137, 52],
      [78, 73],
      [78, -27],
    ],
    [[93895, 128246], [17, -64], [-35, -45], [-79, 44], [52, 93], [45, -28]],
    [[109829, 135787], [-65, -34], [-71, 15], [31, 94], [90, 8], [15, -83]],
    [
      [102578, 147663],
      [10, -40],
      [117, -95],
      [15, -66],
      [84, -103],
      [-24, -94],
      [-48, 45],
      [-80, -88],
      [-26, 54],
      [-56, -11],
      [-77, -82],
      [-25, -65],
      [-97, 27],
      [-44, 87],
      [-86, 55],
      [-7, 186],
      [67, 79],
      [131, 53],
      [101, 99],
      [45, -41],
    ],
    [
      [102651, 147897],
      [-37, -83],
      [-77, -42],
      [-62, 41],
      [73, 46],
      [37, 78],
      [66, -40],
    ],
    [
      [104257, 146616],
      [49, -42],
      [10, -72],
      [-48, -4],
      [-17, 68],
      [-64, 2],
      [-56, 37],
      [0, 38],
      [94, -4],
      [32, -23],
    ],
    [[104180, 147588], [-28, -61], [-85, 74], [31, 22], [82, -35]],
    [
      [102957, 147723],
      [32, -81],
      [-107, -83],
      [-99, -15],
      [-38, 21],
      [-73, 90],
      [55, 87],
      [79, 28],
      [114, 11],
      [37, -58],
    ],
    [
      [102888, 149255],
      [118, -57],
      [3, -59],
      [41, -24],
      [-43, -101],
      [-58, -41],
      [-47, -79],
      [-97, -75],
      [-39, 48],
      [-170, 50],
      [-60, 53],
      [-44, 78],
      [74, 75],
      [21, 90],
      [88, 60],
      [119, 15],
      [94, -33],
    ],
    [
      [102492, 149438],
      [28, -114],
      [-17, -141],
      [-57, -30],
      [-21, -65],
      [-112, 3],
      [-25, 74],
      [-61, 16],
      [-69, 59],
      [-11, 63],
      [119, 134],
      [219, 39],
      [7, -38],
    ],
    [[104847, 149591], [38, -51], [-58, -32], [-82, 62], [4, 37], [98, -16]],
    [[105137, 150162], [-95, -45], [-52, 44], [88, 34], [59, -33]],
    [[102457, 152253], [22, -54], [-69, -25], [-23, 69], [70, 10]],
    [[102911, 151147], [9, -60], [-140, 33], [7, 51], [124, -24]],
    [
      [104457, 151608],
      [-134, -36],
      [-111, 11],
      [-61, -13],
      [-113, 24],
      [-99, 46],
      [3, 59],
      [161, 82],
      [82, -29],
      [137, -26],
      [119, 51],
      [187, -15],
      [22, -37],
      [-163, -61],
      [-30, -56],
    ],
    [
      [102640, 152219],
      [63, -74],
      [75, 19],
      [101, -40],
      [-39, -76],
      [-120, -18],
      [-201, 11],
      [-188, 43],
      [-22, 44],
      [144, 41],
      [65, 39],
      [122, 11],
    ],
    [
      [98223, 56594],
      [-223, 26],
      [-99, -10],
      [-130, 33],
      [-101, -25],
      [-63, 11],
      [-78, -38],
      [-180, 17],
      [-196, -17],
      [-64, 52],
      [-180, 56],
      [-81, 52],
      [-176, 62],
      [-95, 100],
      [-125, 79],
      [-118, 19],
      [-152, 106],
      [-82, 162],
      [-93, 128],
      [-27, 69],
      [-184, 266],
      [-148, 161],
      [-140, 129],
      [-167, 99],
      [-74, 75],
      [50, 33],
      [-5, 60],
      [-78, 77],
      [-107, 55],
      [-58, 61],
      [-96, 31],
      [-74, -60],
      [-107, 87],
      [-120, 125],
      [-57, 38],
      [-69, 94],
      [-73, 48],
      [-68, 108],
      [-204, 203],
      [-100, 165],
      [-90, 122],
      [-60, 49],
      [45, 59],
      [82, -12],
      [61, 63],
      [4, 69],
      [-45, 98],
      [-56, 53],
      [-76, 18],
      [-18, 94],
      [11, 73],
      [-72, 78],
      [-101, 64],
      [-81, 77],
      [19, 53],
      [73, 6],
      [179, 102],
      [111, 34],
      [139, 103],
      [111, 149],
      [40, 184],
      [-1, 263],
      [-55, 197],
      [-86, 240],
      [-118, 292],
      [-274, 519],
      [-190, 301],
      [-225, 309],
      [-141, 173],
      [-234, 255],
      [-246, 241],
      [-291, 254],
      [-217, 174],
      [-198, 144],
      [-383, 249],
      [-248, 146],
      [-283, 147],
      [-214, 94],
      [-202, 72],
      [-226, 65],
      [-197, 27],
      [-72, -64],
      [-208, -36],
      [-113, -113],
      [21, -38],
      [-128, -60],
      [-151, -113],
      [-147, 30],
      [-185, 10],
      [-73, -37],
      [-385, 7],
      [-121, -10],
      [-150, -68],
      [-110, -11],
      [-93, 13],
      [-127, 53],
      [-115, 71],
      [-6, 68],
      [75, 71],
      [28, 70],
      [161, 141],
      [86, -17],
      [105, 44],
      [232, 125],
      [179, 264],
      [142, 73],
      [100, 15],
      [105, 51],
      [23, 63],
      [-11, 202],
      [48, 24],
      [44, 90],
      [-16, 211],
      [16, 29],
      [-9, 143],
      [48, 89],
      [44, 34],
      [52, 134],
      [31, 37],
      [-28, 147],
      [3, 64],
      [-35, 82],
      [-85, 291],
      [47, 129],
      [-5, 72],
      [-35, 44],
      [94, 66],
      [38, -30],
      [-20, -94],
      [21, -35],
      [91, -20],
      [42, 64],
      [-44, 150],
      [-89, 73],
      [-82, 31],
      [-58, 75],
      [-36, 4],
      [-126, 109],
      [48, 54],
      [-130, 163],
      [-147, 69],
      [-41, 50],
      [-72, 143],
      [-97, 48],
      [-145, 112],
      [-18, 129],
      [-62, 157],
      [-52, 66],
      [20, 73],
      [-37, 122],
      [-89, 109],
      [-127, 6],
      [30, 113],
      [-29, 97],
      [-46, 58],
      [-197, 196],
      [-75, -28],
      [-76, -98],
      [-54, -106],
      [-13, -67],
      [-70, -52],
      [-21, -77],
      [69, -77],
      [-26, -157],
      [-23, -56],
      [-74, -70],
      [-142, -97],
      [-103, -105],
      [-60, -207],
      [-47, -72],
      [-3, -173],
      [-30, -104],
      [110, -56],
      [-107, -176],
      [13, -89],
      [-41, -91],
      [-39, -163],
      [18, -29],
      [-110, -107],
      [-110, -215],
      [-16, -55],
      [23, -68],
      [-39, -23],
      [-118, -254],
      [23, -141],
      [-130, -28],
      [77, -119],
      [-19, -61],
      [54, -38],
      [-93, -54],
      [-155, -57],
      [-38, -66],
      [-139, 0],
      [-95, 72],
      [-194, 96],
      [-119, 13],
      [-35, -18],
      [-151, 62],
      [-152, 8],
      [-178, -75],
      [-75, -45],
      [-63, -113],
      [-119, -1],
      [-141, 31],
      [-78, -18],
      [-93, -63],
      [-73, -84],
      [-8, -72],
      [-165, -44],
      [-323, 70],
      [-174, -5],
      [-102, -92],
      [-11, -50],
      [-73, -6],
      [-7, -44],
      [-155, -12],
      [-33, -43],
      [-102, 35],
      [-110, 126],
      [58, 69],
      [-2, 129],
      [78, -11],
      [77, 32],
      [27, 89],
      [126, 59],
      [47, 112],
      [50, 65],
      [-23, 76],
      [-65, 104],
      [46, 72],
      [76, 48],
      [-7, 66],
      [25, 112],
      [128, 72],
      [26, 68],
      [97, -13],
      [102, -49],
      [285, 10],
      [145, 49],
      [78, -13],
      [191, 36],
      [48, -21],
      [47, -79],
      [59, -24],
      [83, 7],
      [58, -69],
      [46, -12],
      [125, 39],
      [137, 104],
      [29, 69],
      [9, 270],
      [59, 150],
      [38, 163],
      [20, 150],
      [45, 70],
      [13, 80],
      [-9, 136],
      [-110, 210],
      [39, 128],
      [-23, 149],
      [-45, 30],
      [-74, -34],
      [-63, -109],
      [-48, 70],
      [83, 69],
      [32, 100],
      [87, 1],
      [35, 87],
      [6, 93],
      [52, 82],
      [-6, 119],
      [9, 189],
      [-57, 113],
      [-67, 71],
      [-52, 103],
      [233, 14],
      [36, 88],
      [40, 33],
      [105, 182],
      [-53, 97],
      [-66, 30],
      [23, 42],
      [65, 12],
      [18, 58],
      [133, 120],
      [101, 45],
      [-5, 64],
      [-98, 83],
      [53, 39],
      [91, 112],
      [135, 101],
      [139, 89],
      [50, 110],
      [64, 82],
      [81, 73],
      [75, 31],
      [129, 143],
      [95, 50],
      [61, 101],
      [47, 30],
      [94, 2],
      [61, 42],
      [99, 1],
      [40, 81],
      [-127, 4],
      [13, 139],
      [-136, 232],
      [-120, 133],
      [12, 105],
      [39, 40],
      [-16, 101],
      [-86, -20],
      [-136, 140],
      [-22, 67],
      [96, 196],
      [145, 79],
      [111, 37],
      [61, -8],
      [40, 55],
      [64, -15],
      [65, 57],
      [85, 4],
      [57, -57],
      [106, 85],
      [17, 77],
      [-58, 32],
      [33, 58],
      [-94, 165],
      [-67, 41],
      [-120, 21],
      [-47, 36],
      [22, 54],
      [-64, 138],
      [-59, 255],
      [-38, 59],
      [-36, 172],
      [4, 82],
      [117, 34],
      [27, 31],
      [-85, 43],
      [-114, 96],
      [-164, 57],
      [68, 30],
      [30, 47],
      [-69, 121],
      [-44, 3],
      [-105, 81],
      [-12, 35],
      [115, 92],
      [-30, 40],
      [-149, 35],
      [2, 79],
      [-82, 64],
      [-43, -74],
      [32, -82],
      [22, -134],
      [-8, -83],
      [-42, -185],
      [21, -60],
      [-15, -83],
      [94, 25],
      [105, -115],
      [-43, -99],
      [47, -227],
      [-65, -83],
      [22, -75],
      [-40, -72],
      [-110, 24],
      [-20, -44],
      [35, -94],
      [71, -101],
      [-14, -40],
      [-103, -10],
      [-89, 16],
      [-31, 55],
      [-73, 47],
      [-106, 29],
      [-93, -26],
      [-80, -52],
      [-58, -80],
      [23, -54],
      [-20, -68],
      [-79, -31],
      [-160, -171],
      [-108, -69],
      [-122, -39],
      [-69, -78],
      [-14, -219],
      [-110, -182],
      [-83, -78],
      [-21, -158],
      [24, -110],
      [-82, -124],
      [-1, -79],
      [-123, -69],
      [-103, -153],
      [-68, -210],
      [-44, -29],
      [-41, -102],
      [-150, -183],
      [-101, -37],
      [-44, -52],
      [-153, -35],
      [-170, 9],
      [-144, -39],
      [-32, -33],
      [-124, -21],
      [-139, -99],
      [-327, -141],
      [-201, -16],
      [-76, -92],
      [-166, -13],
      [-138, -102],
      [-163, -72],
      [-179, -39],
      [-75, -83],
      [17, -58],
      [-228, -130],
      [-147, -69],
      [-78, -11],
      [-296, -214],
      [-58, -102],
      [26, -66],
      [-79, -93],
      [-27, -113],
      [-77, -43],
      [-96, -120],
      [-51, -125],
      [-139, -132],
      [-113, -86],
      [-207, -34],
      [-58, -48],
      [-31, -67],
      [57, -91],
      [-97, -75],
      [97, -14],
      [-19, -83],
      [27, -28],
      [-41, -163],
      [-38, -18],
      [-83, 53],
      [4, 65],
      [-51, 22],
      [-119, -7],
      [-40, -87],
      [-120, -20],
      [-87, 13],
      [-92, -64],
      [-90, -171],
      [-2, -75],
      [92, -18],
      [5, -83],
      [-72, 15],
      [-62, 47],
      [-119, -67],
      [-23, -98],
      [-2, -165],
      [92, -19],
      [-57, -114],
      [-68, 2],
      [-120, -92],
      [-83, -93],
      [169, -30],
      [115, 36],
      [105, 13],
      [77, 62],
      [38, 58],
      [107, 4],
      [1, -56],
      [74, -1],
      [32, 104],
      [77, -32],
      [-36, -47],
      [5, -74],
      [-35, -93],
      [-120, -70],
      [12, -95],
      [45, -99],
      [59, -78],
      [-16, -75],
      [51, -79],
      [-67, -43],
      [-83, -8],
      [-66, 21],
      [-44, 123],
      [-68, 39],
      [-160, 57],
      [-59, 128],
      [-192, 66],
      [-141, 16],
      [-137, -30],
      [-32, -123],
      [-38, -29],
      [-29, -115],
      [-103, 30],
      [-125, -8],
      [16, 108],
      [-68, 92],
      [-183, 159],
      [-40, 55],
      [-104, 73],
      [-59, 86],
      [-96, 95],
      [-203, 125],
      [-102, 43],
      [-121, 21],
      [13, 102],
      [-25, 69],
      [-64, 66],
      [-71, 26],
      [-93, 109],
      [-85, 56],
      [-84, 27],
      [-90, -39],
      [-102, 1],
      [-95, -59],
      [-74, -12],
      [31, 107],
      [-15, 53],
      [48, 28],
      [8, 60],
      [96, 123],
      [40, 11],
      [36, 91],
      [71, -54],
      [3, -148],
      [158, -51],
      [132, 0],
      [60, -35],
      [-60, -88],
      [19, -42],
      [159, -52],
      [27, -44],
      [-25, -49],
      [42, -58],
      [102, -7],
      [69, -49],
      [121, 23],
      [18, 48],
      [93, -3],
      [138, 21],
      [-63, 39],
      [-133, 41],
      [-102, -54],
      [43, 111],
      [6, 108],
      [-152, 65],
      [-24, -20],
      [63, -213],
      [-86, -20],
      [36, 91],
      [-13, 64],
      [-59, 121],
      [57, 38],
      [-23, 192],
      [-102, 69],
      [21, 127],
      [-31, 50],
      [12, 77],
      [-29, 185],
      [-26, 89],
      [-101, 198],
      [-81, 89],
      [-90, 24],
      [-89, 66],
      [119, 108],
      [0, 102],
      [21, 41],
      [-42, 124],
      [5, 63],
      [-59, 106],
      [-86, 105],
      [-103, 97],
      [-61, 150],
      [-68, 0],
      [-40, 65],
      [-78, 74],
      [-148, 94],
      [-149, 161],
      [-224, 152],
      [-151, 71],
      [-63, 49],
      [-260, 148],
      [-16, 131],
      [78, 143],
      [-7, 148],
      [-61, 247],
      [-59, 163],
      [-87, 176],
      [-147, 69],
      [-70, 105],
      [-70, 38],
      [-81, 4],
      [-116, 101],
      [98, 12],
      [95, 86],
      [-66, 102],
      [-116, 39],
      [-115, -4],
      [-114, 70],
      [-150, -67],
      [63, -93],
      [104, -27],
      [62, 7],
      [71, -93],
      [-41, -69],
      [-83, 89],
      [-122, 84],
      [-64, -1],
      [-173, 103],
      [-197, 55],
      [-122, -14],
      [-107, -80],
      [-55, -9],
      [-32, 161],
      [-70, 49],
      [-29, 54],
      [-87, 25],
      [-44, 53],
      [7, 101],
      [-118, -60],
      [2, -76],
      [114, -116],
      [60, 21],
      [48, -67],
      [87, -22],
      [-21, -76],
      [15, -96],
      [-74, -30],
      [-38, 42],
      [-200, 114],
      [23, 47],
      [-25, 93],
      [-79, 196],
      [-49, 39],
      [-82, 15],
      [-101, -61],
      [-114, 116],
      [101, 30],
      [81, -21],
      [54, 92],
      [-14, 80],
      [-80, 105],
      [-61, 24],
      [-120, -31],
      [-28, 66],
      [-98, -39],
      [-76, 56],
      [27, 89],
      [77, 23],
      [113, 62],
      [24, 82],
      [-65, 157],
      [-101, 114],
      [-107, 93],
      [88, 40],
      [76, -36],
      [133, 5],
      [68, -57],
      [110, -53],
      [-24, -57],
      [27, -84],
      [53, 1],
      [66, 47],
      [2, 62],
      [115, 107],
      [28, 66],
      [53, 33],
      [-13, 49],
      [37, 78],
      [-40, 27],
      [-17, 148],
      [-41, 117],
      [-56, 78],
      [-235, 226],
      [-71, 44],
      [-155, 50],
      [-125, -27],
      [-145, 9],
      [-121, -53],
      [-54, -46],
      [-3, -52],
      [-160, 38],
      [-90, -9],
      [-89, -106],
      [-94, -30],
      [-69, 97],
      [52, 83],
      [-40, 69],
      [58, 138],
      [4, 74],
      [69, 1],
      [-3, -83],
      [103, -18],
      [112, 65],
      [12, 57],
      [-61, 32],
      [-115, 211],
      [-75, 44],
      [-46, 86],
      [-146, 137],
      [-66, 14],
      [-34, -63],
      [-83, -36],
      [-63, 93],
      [23, 35],
      [-31, 70],
      [-134, 29],
      [-112, 7],
      [37, 122],
      [-81, 70],
      [4, 93],
      [-248, 75],
      [-62, -34],
      [-37, -76],
      [40, -80],
      [-170, -81],
      [-48, 49],
      [-97, 17],
      [27, 57],
      [-93, 93],
      [-51, -7],
      [-40, -54],
      [-178, -62],
      [-13, -70],
      [58, -55],
      [65, -10],
      [-14, -55],
      [-77, -8],
      [-239, 58],
      [-200, -11],
      [-72, -76],
      [-86, 46],
      [-190, 69],
      [-113, -10],
      [-44, 22],
      [-121, 110],
      [-158, 101],
      [-164, 69],
      [-69, -42],
      [-48, 79],
      [-95, 61],
      [-44, -1],
      [-304, 201],
      [-242, 114],
      [-118, 35],
      [-34, -19],
      [-278, 21],
      [-213, 47],
      [-223, -2],
      [-187, -39],
      [-81, -47],
      [-62, -108],
      [-139, -23],
      [-56, -33],
      [-134, -14],
      [-28, -48],
      [-179, 21],
      [-46, -33],
      [-332, 47],
      [-105, 80],
      [-103, 110],
      [-16, 49],
      [-138, 95],
      [-250, 134],
      [-144, 101],
      [-179, 108],
      [-186, 93],
      [-15, 24],
      [-172, 113],
      [-420, 211],
      [-396, 178],
      [-314, 132],
      [-824, 317],
      [-203, 68],
      [-134, 27],
      [-67, -2],
      [-167, -88],
      [-85, -102],
      [-109, -53],
      [-304, -98],
      [-130, -32],
      [-198, -70],
      [-191, -30],
      [-200, 3],
      [-216, 18],
      [-367, 49],
      [-149, -22],
      [-128, -2],
      [-209, 31],
      [-295, 0],
      [-245, 33],
      [-185, -20],
      [-226, 11],
      [-130, -33],
      [-285, -5],
      [-67, 7],
      [-174, -17],
      [-112, 8],
      [-184, -23],
      [-69, -28],
      [-116, -15],
      [-221, 1],
      [-312, -29],
      [-229, 1],
      [-307, -20],
      [-333, -49],
      [-219, -10],
      [-284, -38],
      [-331, -23],
      [-193, -30],
      [-314, -24],
      [-170, -40],
    ],
    [
      [56319, 77724],
      [0, 841],
      [1, 701],
      [-1, 742],
      [0, 781],
      [0, 1519],
      [1, 1105],
      [0, 830],
      [0, 1106],
      [0, 829],
      [0, 554],
      [0, 828],
      [0, 830],
      [-1, 1182],
      [1, 477],
      [-1, 1052],
      [0, 1159],
      [0, 1383],
      [2, 284],
      [0, 1098],
      [0, 741],
      [0, 831],
    ],
    [
      [98353, 69978],
      [-60, -19],
      [-19, 93],
      [-42, 12],
      [-23, -331],
      [9, -1138],
      [2, -660],
      [3, -1230],
      [2, -1263],
      [-11, -1192],
      [3, -822],
      [12, -1253],
      [3, -589],
      [4, -802],
      [9, -907],
      [-3, -539],
      [7, -269],
      [3, -505],
      [-10, -1092],
      [-19, -878],
    ],
    [
      [86546, 64861],
      [-6, -93],
      [-78, 5],
      [-133, -93],
      [0, -46],
      [89, -33],
      [75, 21],
      [86, -14],
      [7, -48],
      [-131, -79],
      [-8, -46],
      [-88, -70],
      [-1, -41],
      [86, -33],
      [85, 14],
      [145, -26],
      [224, 13],
      [169, 39],
      [80, -113],
      [-200, -141],
      [11, -111],
      [82, 37],
      [77, -36],
      [65, 29],
      [-53, 49],
      [-115, -1],
      [44, 80],
      [156, -46],
      [104, 17],
      [141, 122],
      [4, 100],
      [43, 37],
      [236, -9],
      [255, -77],
      [76, -56],
      [-3, -68],
      [75, -77],
      [116, -11],
      [7, -91],
      [-43, -47],
      [-161, -108],
      [-73, -73],
      [-77, 1],
      [-296, 125],
      [-98, 17],
      [-95, -35],
      [-120, 42],
      [-156, 21],
      [-214, -4],
      [-58, -80],
      [-222, -24],
      [-166, -85],
      [-60, -110],
      [15, -60],
      [53, -47],
      [-5, -49],
      [-104, -99],
      [-173, -23],
      [-116, -97],
      [-64, -4],
      [-78, -51],
      [-279, 190],
      [-44, 55],
      [-241, 25],
      [-86, -6],
      [-86, 37],
      [-193, 22],
      [-51, -21],
      [-25, -95],
      [-108, -36],
      [-50, -43],
      [-101, 52],
      [-36, -23],
      [-86, 16],
      [-67, -22],
      [-177, 31],
      [-116, -31],
      [-77, 1],
      [-99, -56],
      [-72, 41],
      [-141, 48],
      [-78, -10],
      [-56, -56],
      [-116, 17],
      [-89, -61],
      [-82, 21],
      [-72, -41],
      [-18, 61],
      [-68, 110],
      [-126, 130],
      [-97, 35],
      [-73, -21],
      [-83, 40],
      [0, 57],
      [-133, 118],
      [-28, 47],
      [20, 73],
      [84, 36],
      [-13, 69],
      [64, 111],
      [-16, 97],
      [47, 55],
      [143, 1],
      [240, 30],
      [74, 58],
      [143, -2],
      [87, 75],
      [264, 20],
      [313, 64],
      [218, 3],
      [203, 19],
      [166, 45],
      [92, 83],
      [72, -10],
      [78, 38],
      [68, -11],
      [103, 58],
      [116, 12],
      [124, 46],
      [35, 33],
      [251, -37],
      [78, 10],
      [156, -27],
      [154, 49],
      [58, -47],
      [125, 29],
      [55, 47],
      [169, 9],
      [72, 18],
    ],
    [[82436, 66279], [126, -83], [-9, -45], [-175, 63], [58, 65]],
    [
      [81242, 66910],
      [100, -123],
      [170, -108],
      [-7, -93],
      [-92, -43],
      [-68, 102],
      [38, 61],
      [-160, 103],
      [-97, 34],
      [-5, 71],
      [92, 38],
      [29, -42],
    ],
    [[89659, 67532], [90, -130], [-104, -5], [14, 135]],
    [[80544, 67808], [87, -62], [-17, -84], [-58, 19], [-33, 60], [21, 67]],
    [
      [75687, 71081],
      [23, -77],
      [-70, -18],
      [-63, -70],
      [-126, -95],
      [18, 242],
      [206, 56],
      [12, -38],
    ],
    [[85626, 68506], [40, -105], [-58, -109], [-78, 12], [-14, 109], [110, 93]],
    [[71335, 75050], [78, -55], [-83, -25], [-60, 67], [65, 13]],
    [
      [72598, 75895],
      [-95, -42],
      [-10, -75],
      [-40, -91],
      [-96, -18],
      [-60, -35],
      [-64, 62],
      [-11, 68],
      [32, 47],
      [111, -15],
      [144, 96],
      [89, 3],
    ],
    [
      [161201, 2059],
      [-44, -40],
      [15, -79],
      [-80, -124],
      [-4, -138],
      [-93, -197],
      [-99, -109],
      [-31, -66],
      [0, -102],
      [-67, -76],
      [-80, -6],
      [-120, 30],
      [84, 45],
      [41, 157],
      [-35, 66],
      [93, 33],
      [-31, 82],
      [23, 150],
      [150, 184],
      [-58, 67],
      [89, 73],
      [0, 44],
      [193, -19],
      [54, 25],
    ],
    [
      [123096, 41101],
      [-194, -12],
      [97, 153],
      [79, -1],
      [12, 46],
      [-97, 68],
      [-31, 82],
      [133, 178],
      [78, -47],
      [108, 0],
      [61, -75],
      [-6, -52],
      [80, -89],
      [-25, -21],
    ],
    [
      [123391, 41331],
      [-123, -39],
      [-117, 24],
      [-52, -54],
      [65, -39],
      [23, -104],
      [-91, -18],
    ],
    [
      [123902, 42538],
      [69, -37],
      [30, -97],
      [-41, -40],
      [-130, 68],
      [-86, -13],
      [-57, 74],
      [60, 42],
      [112, -20],
      [43, 23],
    ],
    [
      [120084, 38808],
      [-16, -105],
      [-77, -137],
      [-169, 32],
      [11, 73],
      [-154, 128],
      [84, 28],
      [160, -178],
      [46, 52],
      [1, 180],
      [44, 118],
      [111, -57],
      [11, 121],
      [67, 42],
      [4, 61],
      [100, 45],
      [0, 61],
      [53, 37],
      [72, -92],
      [94, 30],
      [88, 123],
      [-40, 71],
      [20, 42],
      [79, -5],
      [38, 61],
      [-30, 66],
      [-106, -32],
      [-166, 48],
      [-41, 95],
      [76, 24],
      [69, -22],
      [55, 70],
      [-121, 11],
      [55, 110],
      [90, 71],
      [32, -71],
      [81, -67],
      [33, -106],
      [-3, -90],
      [71, -56],
      [-10, -64],
      [52, -17],
      [25, -61],
      [-53, -57],
      [-88, -14],
      [-130, -66],
      [-68, -60],
      [-55, -88],
      [8, -127],
      [89, -68],
      [65, 61],
      [54, -73],
      [-123, -146],
      [-6, -47],
      [-63, -63],
      [-23, -84],
      [23, -74],
      [-90, -45],
      [-67, 15],
      [-125, 101],
      [31, 66],
      [-73, 140],
      [-95, -11],
    ],
    [[120510, 40138], [41, -90]],
    [
      [120551, 40048],
      [-29, -51],
      [-65, -3],
      [-83, 115],
      [-48, -40],
      [-66, -109],
      [150, -50],
      [-113, -124],
      [-66, -13],
    ],
    [
      [120231, 39773],
      [-37, -93],
      [2, -76],
      [47, -41],
      [26, -83],
      [-17, -79],
      [-82, -125],
      [-44, -17],
      [-132, 14],
      [-49, -22],
      [-89, 58],
      [5, 53],
      [-72, 24],
      [-80, -29],
      [-13, 119],
      [59, 4],
      [-67, 151],
      [-62, -132],
      [-77, -39],
      [-150, 86],
      [-96, 17],
      [35, 54],
      [9, 85],
      [38, 38],
      [51, 150],
      [-146, -137],
      [0, -89],
      [-46, -59],
      [12, -86],
      [90, -17],
      [144, -109],
      [90, -26],
      [15, -50],
      [104, -55],
      [-66, -52],
      [-34, -94],
      [-95, 39],
      [-190, -50],
      [-25, -31],
      [231, -35],
      [16, -85],
      [-67, -50],
      [-68, -109],
      [47, -42],
      [-222, -51],
      [120, -45],
      [-186, -50],
      [-50, -63],
      [79, -47],
      [15, -51],
      [-131, -30],
      [-99, -2],
      [6, -158],
      [79, 46],
      [22, -101],
      [-126, -68],
      [-164, -61],
      [-25, 79],
      [-130, 41],
      [-142, -19],
      [-101, -73],
      [-94, 20],
      [-41, 92],
      [-117, 1],
      [-54, 60],
      [-218, 179],
      [10, 135],
      [-102, 27],
      [12, -122],
      [-71, -64],
      [-81, 25],
      [-72, -57],
      [-406, 23],
    ],
    [
      [117159, 38419],
      [-92, 65],
      [-68, -22],
      [-33, -51],
      [-92, -27],
      [-79, 59],
      [19, 73],
    ],
    [
      [116814, 38516],
      [-83, 50],
      [-127, -20],
      [-5, -100],
      [-100, -14],
      [-25, 65],
      [-71, 30],
      [-36, -56],
      [-132, -27],
      [-1, -102],
      [-191, 21],
      [-72, -23],
      [34, 164],
      [-52, 65],
      [14, 69],
      [-105, 100],
      [-31, 107],
      [-71, 4],
      [-39, 75],
      [-109, -25],
      [8, 65],
      [148, 69],
      [98, 14],
      [346, -4],
      [101, -31],
      [90, -122],
      [33, 101],
      [142, -7],
      [75, 27],
      [-14, 102],
      [-92, 24],
      [-196, -14],
      [-18, -78],
      [-78, -11],
      [-149, 72],
      [-44, 75],
      [-101, -125],
      [-171, 70],
      [1, 54],
      [-80, 43],
      [50, 64],
      [8, 122],
      [-101, 19],
      [-66, 86],
      [-48, -21],
      [51, -91],
      [-80, -39],
      [-24, -46],
      [-92, 17],
      [-28, -45],
      [56, -59],
      [94, 81],
      [58, -7],
      [-53, -104],
      [-171, -65],
      [-115, 124],
      [-64, 104],
      [-15, 135],
      [-79, 72],
      [-80, -13],
      [-4, 86],
      [-92, 88],
    ],
    [[114916, 39731], [-20, 46]],
    [[114896, 39777], [-19, 51], [-104, -14]],
    [[114773, 39814], [-36, 39]],
    [[114737, 39853], [-38, 68], [59, 77], [-164, 109], [-105, 54], [-37, 78]],
    [
      [114452, 40239],
      [-29, 35],
      [-102, 13],
      [-105, -41],
      [-127, 28],
      [-5, 105],
      [-75, 122],
      [-49, 4],
      [-133, 226],
      [-7, 117],
      [-44, 72],
      [-93, 75],
      [34, 53],
      [-82, 173],
      [0, 109],
      [-117, 12],
      [1, 78],
      [-182, 49],
      [68, 53],
      [4, 93],
      [-47, 5],
      [-100, 153],
      [-51, 195],
      [26, 81],
      [-52, 73],
      [-1, 75],
      [-50, 133],
      [-94, 92],
      [-2, 49],
      [61, 49],
      [-24, 141],
      [-60, 43],
      [8, 105],
      [81, -1],
      [62, -105],
      [18, -68],
      [62, -16],
      [-9, -64],
      [133, 14],
      [77, -25],
      [-8, -98],
      [141, -73],
      [165, -108],
      [52, 15],
      [132, -111],
      [-12, -106],
      [159, 45],
      [77, -44],
      [110, 148],
      [7, 127],
      [-76, -40],
      [-45, 98],
      [-48, 10],
      [-110, -48],
      [-15, 77],
      [-109, 30],
      [-21, 69],
      [-81, 43],
      [-148, 151],
      [37, 47],
      [-55, 59],
      [13, 86],
      [-118, 50],
      [-26, -37],
      [-86, -19],
      [-89, -82],
      [68, -29],
      [-48, -68],
      [-177, 60],
      [116, 122],
      [23, 115],
      [-9, 168],
      [-32, 109],
      [-129, 249],
      [-94, 143],
      [-126, 93],
      [-69, 30],
    ],
    [
      [112816, 43825],
      [-38, 48],
      [-174, 118],
      [-56, 102],
      [-113, 64],
      [-115, 187],
      [-99, 92],
      [-98, -12],
      [-52, 37],
      [-28, 78],
      [55, 45],
      [-67, 66],
      [-8, 90],
      [-57, 119],
    ],
    [[111966, 44859], [-167, 239], [-97, 102]],
    [[111702, 45200], [-37, 55]],
    [[111665, 45255], [-54, 78]],
    [
      [111611, 45333],
      [-147, 95],
      [87, 34],
      [37, 46],
      [-25, 122],
      [-58, 73],
      [-69, 46],
      [11, 45],
      [-76, 33],
      [-37, 134],
      [-87, 88],
      [37, 109],
      [-77, 141],
      [-10, 74],
      [24, 130],
      [-13, 56],
      [-95, 115],
    ],
    [
      [111113, 46674],
      [-48, 23],
      [-59, 99],
      [53, 29],
      [12, 109],
      [-42, 76],
      [47, 79],
    ],
    [
      [111076, 47089],
      [79, 56],
      [66, -19],
      [50, 35],
      [77, 155],
      [-4, 61],
      [-51, 77],
      [30, 50],
      [4, 142],
      [-69, 13],
      [2, 131],
      [30, 77],
      [-41, 25],
      [40, 56],
      [63, 19],
      [96, -49],
      [16, -50],
      [90, -67],
      [106, -19],
      [43, -35],
      [85, 4],
      [10, -53],
      [139, -40],
      [194, -21],
      [117, 18],
      [88, -17],
      [10, -38],
      [142, -46],
      [130, -91],
      [174, -79],
      [29, 43],
      [131, 50],
      [195, 18],
      [112, -24],
      [28, 54],
      [9, 101],
      [-63, 123],
      [108, -55],
      [53, -66],
      [-85, -104],
      [-15, -69],
      [104, -24],
      [103, -89],
      [103, -25],
      [89, 9],
      [219, -71],
      [-34, -63],
    ],
    [
      [113878, 47192],
      [41, 52],
      [118, 17],
      [104, 51],
      [32, -100],
      [92, -84],
      [117, -15],
      [51, -32],
      [210, -56],
      [91, 7],
      [182, -29],
      [-26, -73],
      [64, -70],
      [90, -23],
      [58, -62],
      [118, -60],
      [273, -35],
      [56, -54],
      [67, -23],
      [84, 13],
      [153, -46],
      [36, -31],
      [237, -96],
      [141, -15],
      [28, -43],
      [124, -31],
      [-21, -48],
      [-114, -2],
    ],
    [
      [116284, 46304],
      [96, -28],
      [67, 53],
      [109, -3],
      [98, -29],
      [95, 26],
      [114, -29],
      [124, 22],
      [149, -40],
      [-13, -33],
      [17, -56],
      [1, -89],
      [48, -40],
      [65, 14],
    ],
    [
      [117254, 46072],
      [-92, 70],
      [-28, 95],
      [65, 28],
      [55, -10],
      [89, 33],
      [132, 7],
      [287, 110],
      [-2, -101],
      [87, -67],
      [-18, -50],
      [23, -73],
      [93, -8],
      [-53, 74],
      [8, 87],
      [-58, 16],
      [-10, 52],
      [151, 27],
      [92, 50],
      [78, 105],
      [62, -26],
      [139, 87],
      [168, -11],
      [103, -57],
      [70, -76],
      [0, -67],
      [46, -38],
      [20, -72],
    ],
    [
      [118761, 46257],
      [104, -1],
      [64, 73],
      [93, -29],
      [86, -67],
      [-65, -34],
      [-35, -63],
      [117, -78],
      [46, -94],
      [110, -43],
      [-29, -70],
      [13, -58],
      [110, -32],
      [87, -80],
      [57, -1],
      [-8, 83],
      [-155, 14],
      [-73, 59],
      [129, 73],
      [-141, 131],
      [-81, 14],
      [-22, 62],
      [-87, -17],
      [12, 105],
      [76, 38],
      [-221, 149],
      [-140, -25],
      [-89, 49],
      [-8, 96],
      [-34, 105],
      [73, 61],
      [194, 84],
      [23, -27],
      [108, -9],
      [119, 25],
      [131, 98],
      [112, 41],
      [102, -16],
      [76, -45],
      [131, 15],
      [140, -60],
      [89, 13],
      [166, 66],
      [115, 122],
      [73, 29],
      [54, -41],
      [95, 6],
      [91, 49],
      [49, -116],
      [108, -34],
      [29, -79],
      [149, 19],
      [145, 64],
      [148, 109],
      [100, 106],
      [158, 208],
      [219, 103],
      [84, -36],
      [86, -85],
      [162, 13],
      [94, -73],
      [185, -24],
      [156, 60],
      [138, 121],
      [97, 135],
      [46, 151],
      [47, 18],
      [130, -16],
      [167, -87],
      [73, 7],
      [75, -61],
      [51, -88],
      [-5, -54],
      [209, -26],
      [41, 24],
      [133, -54],
      [-6, -72],
      [51, -53],
      [82, -19],
      [51, -98],
      [96, -70],
      [16, -78],
      [113, -98],
      [-60, -43],
      [-90, -105],
      [-50, 20],
      [-76, -40],
      [133, -60],
      [-90, -172],
      [-5, -99],
      [50, -75],
      [-81, -55],
      [68, -92],
      [7, -52],
      [89, -72],
    ],
    [
      [123941, 45999],
      [103, -17],
      [-18, -55],
      [-145, -111],
      [13, 73],
      [-53, 5],
      [-39, -131],
      [15, -60],
      [189, 107],
      [-47, -130],
      [-64, -85],
      [-19, -91],
      [-78, -188],
      [0, -157],
      [24, -1],
      [38, -161],
      [104, -68],
      [18, -164],
      [-94, -122],
      [-26, -161],
      [11, -150],
      [-86, -165],
      [11, -115],
      [28, -54],
    ],
    [[123826, 43998], [68, -53]],
    [[123894, 43945], [48, -35]],
    [
      [123942, 43910],
      [-11, -51],
      [62, -75],
      [-74, -66],
      [26, -81],
      [-57, -34],
      [-34, -107],
      [-1, -100],
      [50, -76],
      [45, -6],
      [61, -138],
      [56, -75],
      [-80, -30],
      [-1, -133],
      [105, -41],
      [-17, -130],
      [-81, -52],
      [-11, -85],
      [-54, -54],
      [-81, 87],
      [-69, 2],
      [-37, 78],
      [65, 18],
      [77, 116],
      [-48, 58],
      [-62, 22],
      [56, 66],
      [68, 22],
      [2, 49],
      [-84, 37],
      [-75, -2],
      [-33, 77],
      [-115, 46],
      [-176, 5],
      [50, 107],
      [123, 15],
      [122, 119],
      [-45, 63],
      [-114, -121],
      [-83, 39],
      [-102, -27],
      [-15, -111],
      [-44, -98],
      [82, 19],
      [279, -55],
      [2, -28],
      [-229, 27],
      [-134, -2],
      [-137, -30],
      [-54, -39],
      [21, -167],
      [-45, -98],
      [-58, -23],
      [-126, -133],
      [39, -49],
      [-32, -103],
      [-67, -79],
      [-52, -4],
      [-39, -75],
      [-86, -44],
    ],
    [
      [122670, 42360],
      [105, 33],
      [51, 71],
      [72, -6],
      [48, -86],
      [-126, -153],
      [-28, -55],
      [102, -116],
      [-2, -69],
      [-44, -50],
      [24, -103],
      [-30, -64],
      [-69, 35],
      [-60, -55],
      [-10, -62],
      [-234, 20],
      [-22, -72],
      [88, -7],
      [35, -69],
      [66, -46],
      [-16, -90],
      [83, -46],
      [26, -80],
      [-18, -54],
      [-66, -56],
      [27, -81],
      [-40, -34],
      [-122, -24],
      [-62, -66],
      [-44, -113],
      [2, -81],
      [-92, -78],
      [-29, -64],
      [-74, -77],
      [-184, -45],
      [-141, 51],
      [-178, -50],
      [-94, 82],
      [-116, 42],
      [35, 72],
      [-55, 40],
      [-83, 106],
      [-180, 27],
      [-64, 24],
      [-91, -65],
      [-44, 33],
    ],
    [
      [121016, 40879],
      [-128, 23],
      [155, -136],
      [75, 25],
      [69, -29],
      [178, 11],
      [-21, -42],
      [-155, -44],
      [-70, -54],
      [28, -30],
      [-56, -117],
      [108, -50],
      [37, -51],
      [-97, -78],
      [49, -65],
      [96, 11],
    ],
    [
      [121284, 40253],
      [-88, -35],
      [-11, -95],
      [-90, -8],
      [-305, -79],
      [17, 102],
      [-59, 31],
      [27, 57],
      [83, -82],
      [1, -40],
      [82, -21],
      [72, 31],
      [-14, 80],
      [37, 47],
      [-93, 43],
      [20, 200],
      [-74, 12],
      [-57, -107],
      [-45, 17],
      [32, 91],
      [-49, 81],
      [-141, 16],
      [-42, 27],
      [-109, 179],
      [-134, 51],
      [-99, -19],
      [73, -61],
      [82, 22],
      [86, -45],
      [-8, -77],
      [60, -34],
      [15, -55],
      [-32, -60],
      [82, -27],
      [22, -58],
      [-21, -84],
      [-95, -88],
      [1, -127],
    ],
    [
      [122408, 39655],
      [-62, -66],
      [-162, -93],
      [-75, -90],
      [-28, 74],
      [-198, 70],
      [-111, 183],
      [49, 53],
      [113, 28],
      [-118, 52],
      [-65, -12],
      [-48, 48],
      [-148, 49],
      [3, 107],
      [-34, 105],
      [31, 34],
      [182, 21],
      [13, 59],
      [-49, 41],
      [-12, 76],
      [47, 14],
      [164, -49],
      [14, -71],
      [-78, -119],
      [146, -122],
      [84, -37],
      [235, -19],
      [-16, 64],
      [95, 18],
      [24, 82],
      [-106, 29],
      [52, 54],
      [-101, 33],
      [86, 57],
      [-7, 52],
      [-117, 37],
      [28, 42],
      [-18, 79],
      [125, 18],
      [154, -23],
      [24, 48],
      [-107, 25],
      [21, 71],
      [89, 37],
      [106, -109],
      [165, -51],
      [58, -79],
      [-43, -120],
      [45, -45],
      [-165, -125],
      [-61, -17],
      [-19, -76],
      [83, -35],
      [-26, -67],
      [71, -82],
      [22, -122],
      [108, -75],
      [-70, -40],
      [-63, -87],
      [6, -47],
      [90, -61],
      [-47, -23],
      [-104, 23],
      [-15, 69],
      [-175, 21],
      [1, 112],
      [-27, 87],
      [-126, 9],
      [67, -119],
    ],
    [[116868, 38146], [-9, -4]],
    [[116859, 38142], [9, 4]],
    [[117037, 38225], [-2, 2]],
    [[117035, 38227], [2, -2]],
    [
      [123641, 48517],
      [43, -137],
      [-92, -72],
      [-120, -123],
      [-90, 84],
      [-125, 3],
      [29, 90],
      [-71, 5],
    ],
    [
      [123215, 48367],
      [55, 88],
      [85, 31],
      [114, 7],
      [39, 25],
      [103, -42],
      [30, 41],
    ],
    [[122836, 48874], [30, 23]],
    [
      [122866, 48897],
      [107, 48],
      [104, 12],
      [60, 50],
      [168, 17],
      [137, -34],
      [139, 11],
      [71, 53],
      [146, -25],
      [54, 56],
      [55, -9],
      [99, 65],
    ],
    [
      [124006, 49141],
      [66, -4],
      [73, -107],
      [167, -125],
      [105, -20],
      [15, -85],
      [73, -85],
      [-112, -29],
      [-24, -85],
      [-62, 9],
      [-41, -65],
      [-111, 11],
      [-65, -46],
    ],
    [
      [124090, 48510],
      [-47, 25],
      [-7, 68],
      [-68, 106],
      [-107, 15],
      [-126, -88],
      [-83, -37],
      [-70, 81],
      [-219, -40],
      [-72, 46],
      [-142, -51],
      [-137, 32],
      [0, 42],
      [-89, 61],
      [-103, -23],
      [16, 127],
    ],
    [[120548, 51965], [62, -99], [-99, -36], [-84, 60], [58, 73], [63, 2]],
    [[122528, 49172], [-14, -87], [-70, -22], [-97, 67], [119, 7], [62, 35]],
    [[122023, 49992], [-17, -163], [-71, -23], [88, 186]],
    [[123952, 49263], [34, -59], [-53, -31], [-71, 11], [31, 102], [59, -23]],
    [
      [122218, 50440],
      [-199, 68],
      [-27, 57],
      [60, 22],
      [148, 108],
      [118, 6],
      [-13, 64],
      [93, 77],
      [39, 92],
      [194, 97],
      [79, 16],
      [19, -117],
      [81, 1],
      [75, -56],
      [110, -117],
      [203, -174],
      [165, -168],
      [113, -77],
      [105, -18],
      [190, -67],
      [100, -10],
      [-34, -150],
      [25, -175],
      [-57, -73],
      [-106, -36],
      [-56, -61],
      [107, -18],
      [132, 64],
      [95, -259],
      [52, -87],
      [-174, -72],
      [-85, 70],
      [-174, -2],
      [-69, -46],
      [-21, -85],
      [-91, -41],
      [-157, -28],
      [-60, 30],
      [-107, 13],
      [-81, 39],
      [-6, 50],
      [-60, 39],
      [62, 37],
      [-9, 45],
      [-82, 106],
      [4, 95],
      [-32, 93],
      [-66, 48],
      [-117, 26],
      [-13, 76],
      [-45, 75],
      [-76, 28],
      [-89, -13],
      [-63, 43],
      [43, 67],
      [60, 29],
      [-6, 144],
      [-57, 99],
      [-88, 70],
      [-152, -44],
    ],
    [[122514, 51196], [104, -12], [9, -57], [-152, 15], [39, 54]],
    [[122810, 51339], [85, -39], [-68, -38], [-17, 77]],
    [[112600, 47579], [-107, 0], [18, 74], [89, -74]],
    [[111146, 48909], [-11, -29]],
    [[111135, 48880], [11, 29]],
    [
      [112104, 48071],
      [16, -67],
      [162, -52],
      [122, -70],
      [79, -8],
      [72, -74],
      [-45, -37],
      [-59, 40],
      [-93, -23],
      [-87, 16],
      [-138, -80],
      [-104, -20],
      [-87, 8],
      [-53, 43],
      [26, 49],
      [-11, 97],
      [138, 101],
      [62, 77],
    ],
    [[112128, 48196], [49, -112], [-105, 27], [56, 85]],
    [
      [111981, 48854],
      [165, -29],
      [93, -98],
      [-65, -32],
      [-53, -108],
      [-143, -46],
      [-121, 47],
      [-77, 99],
      [101, 24],
      [68, 61],
      [32, 82],
    ],
    [
      [111602, 48818],
      [24, -262],
      [-10, -93],
      [-63, -87],
      [15, -160],
      [-65, -82],
      [-80, 47],
      [-32, 85],
      [26, 50],
      [-78, 221],
      [153, -8],
      [64, 75],
      [-25, 42],
      [71, 172],
    ],
    [[108413, 49765], [-2, 51]],
    [
      [108411, 49816],
      [-31, 98],
      [84, 51],
      [-19, 80],
      [26, 56],
      [-9, 91],
      [-35, 61],
      [-139, 102],
      [-14, 114],
      [107, 204],
      [-10, 169],
      [-46, 91],
      [16, 82],
      [-34, 78],
      [169, 58],
      [118, 113],
      [27, 81],
      [-33, 87],
      [87, 108],
      [114, -68],
      [76, 6],
      [31, -37],
      [134, -54],
      [206, -166],
      [-33, -204],
      [12, -133],
      [79, -140],
      [-8, -60],
      [-65, -104],
      [19, -150],
      [94, -66],
      [-40, -201],
      [-86, -153],
      [-114, -38],
      [-116, -114],
      [-114, -44],
      [-50, 4],
      [-134, -45],
      [-96, -61],
      [-141, 31],
    ],
    [[108443, 49743], [-30, 22]],
    [
      [117640, 54393],
      [83, -65],
      [30, -56],
      [134, 69],
      [61, -81],
      [-163, -101],
      [-200, 52],
      [-103, 116],
      [23, 36],
      [93, 1],
      [42, 29],
    ],
    [
      [118021, 54463],
      [188, -14],
      [8, -38],
      [-131, -26],
      [-139, -7],
      [-40, 36],
      [114, 49],
    ],
    [
      [111422, 55611],
      [-253, 139],
      [64, 17],
      [206, -64],
      [36, -59],
      [88, -57],
      [219, -36],
      [293, 67],
      [130, 49],
      [101, 57],
      [-13, 73],
      [149, 142],
      [0, 79],
      [30, 49],
      [97, 45],
      [90, 123],
      [87, 40],
      [32, 62],
      [3, 169],
      [-33, 101],
      [-66, 109],
      [-149, 108],
      [-73, -26],
      [-132, 168],
      [-26, 58],
      [1, 113],
      [-77, 121],
      [-99, 44],
      [-135, 0],
      [28, -64],
      [-96, -18],
      [-32, 29],
      [-210, -72],
      [-37, -83],
      [4, -58],
      [-193, -55],
      [-157, -112],
      [-38, -2],
      [-128, -93],
      [-208, -9],
      [-106, -95],
      [-163, -42],
      [-29, -146],
      [-70, 38],
      [-105, 0],
      [-33, -28],
      [-123, 8],
      [-52, 35],
      [-57, -78],
      [-56, -27],
      [-33, -109],
      [101, -17],
      [61, -40],
      [106, 52],
      [69, -53],
      [99, -29],
      [160, 14],
      [134, 29],
      [68, 51],
      [167, 96],
      [130, -18],
      [131, -58],
      [39, -62],
      [8, -81],
      [-57, -94],
      [-36, 24],
      [-95, -53],
      [-146, -155],
      [-35, -116],
      [-228, 64],
      [-130, -3],
      [-43, -56],
      [-225, 30],
      [-201, -69],
      [-28, -30],
      [-144, -47],
      [-41, -66],
      [-49, -10],
      [-62, -64],
      [-120, -69],
      [-128, -44],
      [-122, -59],
      [-2, -33],
      [-168, -21],
      [-94, -111],
      [-131, 0],
      [-108, -22],
      [-199, -187],
      [25, -53],
      [-48, -60],
      [-163, -80],
      [-64, -118],
      [-59, -30],
      [-173, -146],
      [-17, -45],
      [-92, -2],
      [-168, -39],
      [-298, -119],
      [-57, -139],
      [-58, -30],
      [-102, -13],
      [-92, -75],
      [-39, -62],
      [-80, -60],
      [-155, -9],
      [-160, 157],
      [-128, 89],
      [-73, 3],
      [-42, 36],
      [-135, 58],
      [-107, -9],
      [-83, 23],
      [-160, -68],
      [-132, 34],
      [-130, 96],
      [-210, 181],
      [-152, 85],
      [-134, 40],
      [-108, 51],
      [-254, -8],
      [-120, 50],
      [-158, 25],
      [-21, 38],
      [-93, 23],
      [-65, 46],
      [-56, 0],
      [-403, 268],
      [-257, 141],
      [-140, 87],
      [-126, 53],
      [-140, 42],
      [-98, -13],
      [-134, 87],
      [-171, 71],
      [-193, -35],
      [-169, -9],
      [-71, -31],
      [-55, -67],
      [-120, -9],
      [-177, 18],
      [-260, 112],
      [-208, 129],
      [-285, 125],
      [-169, 43],
      [-500, 44],
      [-133, -21],
      [-141, -53],
      [-195, -105],
      [-31, -48],
      [85, -67],
      [5, -67],
      [88, -101],
      [-124, -6],
      [-146, 15],
      [-53, -53],
      [-1, -55],
      [-65, -29],
      [-62, 54],
      [-9, 82],
      [-202, 92],
      [-91, 7],
      [-99, -31],
      [26, -84],
      [-176, 59],
      [27, 66],
      [91, 62],
      [-35, 88],
      [-106, 124],
      [-303, 243],
      [-151, 102],
      [-156, 87],
      [-192, 93],
      [-317, 136],
      [-165, 51],
      [-88, 13],
    ],
    [
      [129783, 58422],
      [-143, -55],
      [-102, -71],
      [-137, 8],
      [-208, -20],
      [-164, -66],
      [-132, -128],
      [-58, -124],
      [-89, -76],
      [-17, -61],
      [-211, -56],
      [-65, -80],
      [-129, -2],
      [-125, -40],
      [-102, -54],
      [-27, -68],
      [-53, 12],
      [-223, -9],
      [-85, -16],
      [-139, 6],
      [-272, -53],
      [-59, 35],
      [-446, 15],
      [-371, -12],
      [-288, -1],
      [-182, -29],
      [-148, 18],
      [-116, -7],
      [-216, -38],
      [-57, -44],
      [-92, 31],
      [-265, -18],
      [-163, 18],
      [-427, 2],
      [-330, -9],
      [-372, -37],
      [-283, -55],
      [-369, -107],
      [-76, -11],
      [-255, -74],
      [-102, -17],
      [-288, -94],
      [-278, -107],
      [-289, -142],
      [-224, -129],
      [-348, -221],
      [-281, -195],
      [-205, -161],
      [-577, -432],
      [-222, -159],
      [-380, -286],
      [-321, -262],
      [-153, -139],
      [-157, -80],
      [-51, 38],
      [-69, 9],
      [-35, -33],
      [-95, 14],
      [-102, -69],
      [-6, -46],
      [-283, -61],
      [-39, -36],
      [-78, 36],
      [-291, -39],
      [-96, -26],
      [-32, -64],
      [-163, 38],
      [-39, -14],
      [-191, -11],
      [-190, -67],
      [-13, 114],
      [-104, 42],
      [-72, -48],
      [-135, -26],
      [-90, 14],
      [-77, -16],
      [-165, -73],
      [-41, -85],
      [130, -114],
      [14, -66],
      [46, -44],
      [90, 19],
      [19, -182],
      [54, -19],
      [19, -127],
      [112, 3],
      [43, 75],
      [114, 68],
      [170, 37],
      [12, 127],
      [62, 84],
      [104, 14],
      [39, -41],
      [-29, -124],
      [14, -41],
      [-24, -100],
      [65, -65],
      [10, -58],
      [-56, -23],
      [-102, -142],
      [-28, -70],
      [35, -55],
      [-4, -66],
      [86, -19],
      [-32, -44],
      [58, -40],
      [-162, -56],
      [-19, -60],
      [43, -32],
      [-59, -107],
      [-157, -32],
      [-106, 33],
      [-74, 104],
      [63, 113],
      [-241, 212],
      [-15, 76],
      [-125, 181],
      [-174, 163],
      [-214, 126],
      [-210, 66],
      [-107, -6],
      [-83, -43],
      [-41, -105],
      [32, -66],
      [-95, -57],
      [-148, -7],
      [-88, 18],
      [11, 65],
      [-152, 308],
      [-135, 168],
      [-139, 136],
      [-109, 77],
      [-169, 64],
      [-93, -23],
      [-131, -82],
      [-79, 13],
      [-71, -19],
      [-163, 95],
      [-63, 106],
      [-173, 180],
      [-68, 42],
      [-82, 9],
      [-93, 40],
      [-181, 0],
      [-29, 52],
      [191, 24],
      [55, 68],
      [-52, 71],
      [36, 49],
      [12, 93],
      [-34, 54],
      [204, 18],
      [27, -26],
      [102, 3],
      [84, 91],
      [36, 106],
      [-36, 57],
      [-65, 10],
      [-29, 55],
      [23, 63],
      [-66, 55],
      [-71, 167],
      [-105, 38],
      [-103, 14],
      [-40, -38],
      [-242, 42],
      [-6, -48],
      [-107, -60],
      [-108, 3],
      [-30, 74],
      [-58, 5],
      [-13, -79],
      [-84, -82],
      [-15, -116],
      [-78, -17],
      [-21, -54],
      [83, -74],
      [-18, -27],
      [16, -118],
      [-108, -18],
      [-199, 29],
      [-118, -39],
      [-100, -75],
      [-62, -17],
      [-87, -150],
      [-179, -51],
      [-51, 13],
      [-122, -35],
      [-141, 22],
      [8, 44],
      [-152, 142],
      [-183, 137],
      [-199, 136],
    ],
    [
      [113202, 55296],
      [235, -41],
      [-66, -69],
      [87, -98],
      [147, 5],
      [-66, -115],
      [-107, 81],
      [-74, 28],
      [-161, 12],
      [-68, -71],
      [-105, 44],
      [-167, 30],
      [80, 131],
      [167, 58],
      [98, 5],
    ],
    [
      [113418, 55842],
      [94, -34],
      [58, -57],
      [94, -13],
      [128, 32],
      [133, -6],
      [85, -88],
      [106, -68],
      [-97, -44],
      [-227, -14],
      [-64, -40],
      [-1, -46],
      [-99, -30],
      [-53, -47],
      [-133, 59],
      [-120, 34],
      [-11, 114],
      [29, 79],
      [29, 166],
      [49, 3],
    ],
    [
      [118740, 54742],
      [70, -11],
      [23, 16],
      [106, -13],
      [-7, -31],
      [-210, -70],
      [-87, 7],
      [31, 79],
      [74, 23],
    ],
    [[32632, 69687], [31, -15], [32, -96], [-61, 5], [-2, 106]],
    [[36054, 69695], [-202, -11], [57, 68], [98, -10], [47, -47]],
    [[7254, 69727], [14, 27]],
    [[7268, 69754], [14, 128]],
    [
      [7282, 69882],
      [-2, 131],
      [-59, 166],
      [34, 36],
      [-32, 227],
      [-18, 39],
      [57, 78],
      [19, 175],
      [-40, 105],
      [15, 30],
      [-63, 168],
      [55, 21],
      [59, 123],
      [42, 9],
      [31, 74],
      [-62, 191],
      [-9, 155],
      [100, -48],
      [68, 0],
      [41, -50],
      [74, -30],
      [44, -52],
      [30, -92],
      [47, -40],
      [295, -90],
      [197, -19],
      [159, 10],
      [276, 73],
      [85, 43],
      [103, 79],
      [380, 397],
      [56, 43],
      [112, 169],
      [110, 290],
      [84, 16],
      [59, 42],
      [32, 72],
      [13, 197],
      [0, 161],
      [19, 187],
      [-29, 251],
      [-39, 222],
      [-37, 142],
      [-20, 133],
      [-68, 192],
      [-89, 566],
      [87, 239],
      [134, 107],
      [108, 142],
      [54, -62],
      [-8, -69],
      [-144, -46],
      [-112, -134],
      [17, -115],
      [87, -179],
      [3, -87],
      [65, -87],
      [5, -44],
      [107, -50],
      [14, 92],
      [-58, 44],
      [10, 46],
      [-57, 124],
      [-43, 44],
      [-1, 79],
      [-61, 63],
      [-3, 62],
      [134, -93],
      [87, 30],
      [54, -39],
      [102, 38],
      [9, 136],
      [-51, 74],
      [-51, 29],
      [-85, -16],
      [-4, 94],
      [-36, 60],
      [55, 15],
      [42, 60],
      [37, 192],
      [-15, 108],
      [-50, 71],
      [-65, 50],
      [91, 43],
      [17, 72],
      [-49, 91],
      [-98, 14],
      [-10, 92],
      [94, 5],
      [106, 87],
      [-1, 88],
      [46, 54],
      [22, 82],
      [-41, 62],
      [4, 82],
      [-20, 142],
      [-40, 118],
      [64, 116],
      [41, -9],
      [30, 56],
      [135, -41],
      [14, 58],
      [92, 36],
      [-26, 54],
      [-91, 5],
      [-50, -80],
      [-53, -10],
      [-66, 51],
      [2, -117],
      [-82, 1],
      [13, 166],
      [4, 241],
      [-23, 222],
      [-74, 95],
      [16, 100],
      [-50, 156],
      [-93, 177],
      [-19, 74],
      [-76, 102],
      [-96, 180],
      [-99, 122],
      [-17, 41],
      [-83, 77],
      [-56, 104],
      [-47, 125],
      [-114, 166],
      [-50, 48],
      [-221, 286],
      [-58, 155],
      [-88, 168],
      [-54, 190],
      [-45, 66],
      [-133, 239],
      [-25, 100],
      [-41, 83],
      [-160, 208],
      [4, 29],
      [-64, 100],
      [-111, 136],
      [-104, 25],
      [-5, 134],
      [-125, 262],
      [-73, 175],
      [-154, 246],
      [26, 88],
      [-68, 106],
      [-67, 53],
      [51, 86],
      [-74, 187],
      [5, 87],
      [-33, 192],
      [-75, 68],
      [64, 56],
      [14, 59],
      [-20, 97],
      [-43, 61],
      [-91, 36],
      [-14, 123],
      [40, 92],
      [-16, 90],
      [-63, 177],
      [-71, 22],
      [-29, 79],
      [50, 136],
      [-23, 109],
      [36, 55],
      [32, 125],
      [-15, 190],
      [13, 37],
      [-59, 143],
      [-20, 134],
      [0, 328],
      [65, 284],
      [-2, 87],
      [72, 154],
      [-6, 78],
      [-131, 283],
      [0, 123],
      [-42, 103],
      [-69, 71],
      [-44, 118],
      [23, 69],
      [-68, 148],
      [-50, 182],
      [-53, 134],
      [-139, 167],
      [-166, 155],
      [-237, 208],
      [-102, 79],
      [-189, 197],
      [-35, 104],
      [15, 39],
      [-62, 121],
      [-48, 9],
      [73, 139],
      [2, 108],
      [-44, 98],
      [23, 74],
      [-56, 178],
      [-73, 109],
      [-69, 149],
      [-147, 200],
      [-81, 89],
      [-197, 167],
      [-21, 58],
      [-70, 89],
      [-11, 55],
      [-180, 177],
      [-70, 86],
      [-38, 76],
      [-185, 85],
      [-117, 74],
      [-134, 187],
      [-84, 59],
      [-66, 310],
      [-86, 313],
      [-91, 242],
      [47, 210],
      [72, 139],
      [43, 39],
      [28, 125],
      [-30, 153],
      [-49, 153],
      [-77, 195],
      [-38, 138],
      [-213, 429],
      [-95, 176],
      [-84, 124],
      [-177, 303],
      [-283, 437],
      [-95, 136],
      [-62, 132],
      [-110, 159],
      [-169, 216],
      [-99, 110],
      [-210, 254],
      [-21, 44],
      [-203, 239],
      [-283, 275],
      [-204, 164],
      [-223, 194],
      [-108, 84],
      [-191, 187],
      [42, 39],
      [-55, 99],
      [25, 50],
      [-78, 161],
      [-163, 219],
      [-186, 159],
      [68, 20],
      [93, -15],
      [132, -44],
      [22, -157],
      [43, 42],
      [-7, 106],
      [34, 139],
      [13, 223],
      [-24, 22],
      [0, 128],
      [46, 64],
      [46, -155],
      [29, -164],
      [10, -208],
      [-12, -197],
      [52, -240],
      [-24, -86],
      [43, -34],
      [51, 54],
      [42, -76],
      [-29, -64],
      [98, -65],
      [-9, 106],
      [-84, 110],
      [-15, 171],
      [14, 109],
      [28, 20],
      [-6, 89],
      [64, 32],
      [-14, 157],
      [28, 150],
      [-42, 33],
      [7, 70],
      [-59, 47],
      [69, 37],
      [29, -48],
      [-8, -85],
      [39, -72],
      [104, -88],
      [-17, -52],
      [79, -34],
      [14, -153],
      [40, -31],
      [9, -105],
      [62, -58],
      [-1, -131],
      [67, -88],
      [-30, -80],
      [53, -3],
      [9, -62],
      [-70, 9],
      [-10, -47],
      [58, -71],
      [-59, -64],
      [-31, -137],
      [81, -10],
      [4, 82],
      [30, 37],
      [-14, 56],
      [82, 221],
      [-53, 66],
      [19, 177],
      [60, 2],
      [34, -69],
      [-22, -32],
      [55, -124],
      [-21, -97],
      [45, -72],
      [-3, -197],
      [-29, -23],
      [40, -85],
      [47, -33],
      [-62, -152],
      [-2, -74],
      [90, -78],
      [15, 117],
      [36, 54],
      [-13, 105],
      [81, 1],
      [68, 33],
      [-21, -245],
      [11, -122],
      [80, 7],
      [28, -111],
      [-24, -78],
      [61, 1],
      [44, 68],
      [-8, 45],
      [90, 88],
      [86, -34],
      [56, 31],
      [37, 64],
      [84, -53],
      [92, 14],
      [-9, 78],
      [76, 86],
      [107, 48],
      [76, 114],
      [12, 87],
      [-23, 186],
      [-41, 61],
      [52, 101],
      [-6, 101],
      [-66, 102],
      [-99, 97],
      [-121, 86],
      [-170, 72],
      [-196, 39],
      [-49, 77],
      [-3, 66],
      [-68, 43],
      [-72, 97],
      [-11, 47],
      [-73, 42],
      [-18, 38],
      [-107, 8],
      [-2, 71],
      [-53, 117],
      [25, 98],
      [-48, 35],
      [-17, 110],
      [11, 64],
      [-47, 43],
      [-98, 46],
      [27, 121],
      [-43, 103],
      [-176, 135],
      [56, 93],
      [-42, 158],
      [24, 28],
      [-52, 81],
      [-43, -67],
      [49, -58],
      [-19, -85],
      [-62, 42],
      [-80, 95],
      [14, 148],
      [47, 181],
      [60, 49],
      [83, 175],
      [47, 24],
      [44, 80],
      [63, 34],
      [-5, -130],
      [35, -173],
      [44, -59],
      [115, -41],
      [51, 33],
      [16, -139],
      [28, -97],
      [55, -79],
      [142, -74],
      [66, -89],
      [95, -72],
      [74, 2],
      [11, -87],
      [-17, -70],
      [18, -80],
      [131, -46],
      [-12, -88],
      [-94, -148],
      [9, -212],
      [-120, -104],
      [16, -134],
      [-18, -20],
      [29, -194],
      [71, -101],
      [-9, -80],
      [132, -29],
      [94, 19],
      [140, 178],
      [54, 113],
      [121, 161],
      [22, 91],
      [-72, 300],
      [140, -20],
      [31, -80],
      [-28, -28],
      [-44, -115],
      [53, -106],
      [-16, -101],
      [11, -81],
      [43, -62],
      [62, 7],
      [33, -39],
      [-46, -159],
      [30, -119],
      [55, -52],
      [-27, -88],
      [59, -87],
      [0, -92],
      [28, -85],
      [275, -239],
      [113, -163],
      [153, 140],
      [94, 53],
      [34, 67],
      [97, 28],
      [82, 59],
      [-5, 74],
      [76, 95],
      [1, 96],
      [-119, 130],
      [5, 69],
      [-47, 67],
      [-3, 118],
      [48, 15],
      [128, -7],
      [44, 35],
      [-25, 94],
      [-46, 74],
      [20, 106],
      [-106, 136],
      [-38, 86],
      [-1, 91],
      [76, 31],
      [15, -50],
      [74, -28],
      [47, 31],
      [-40, 91],
      [4, 107],
      [57, 90],
      [-4, 42],
      [-105, 27],
      [-72, 59],
      [-36, 57],
      [-85, 71],
      [-19, 39],
      [-185, 169],
      [-2, 94],
      [-99, 105],
      [-161, 91],
      [-85, 135],
      [-59, 33],
      [-49, 95],
      [-13, 116],
      [-97, 170],
      [-48, 58],
      [-14, 103],
      [-125, 139],
      [-3, 60],
      [-47, 73],
      [1, 120],
      [-106, 136],
      [9, 46],
      [-58, 40],
      [4, 38],
      [-40, 160],
      [-124, 177],
      [-43, 8],
      [-68, 60],
      [-104, 30],
      [-148, 135],
      [-111, 212],
      [-15, 159],
      [56, 166],
      [-30, 75],
      [-81, 88],
      [-7, 36],
      [-96, 25],
      [52, 153],
      [-8, 169],
      [-42, 79],
      [-272, 322],
      [-73, 70],
      [-101, 141],
      [-128, 98],
      [-95, 113],
      [-15, 53],
      [-52, 40],
      [-26, 241],
      [26, 202],
      [-14, 64],
      [8, 123],
      [-31, 98],
      [-19, 126],
      [63, 11],
      [72, 63],
      [48, 76],
      [-9, 166],
      [-75, 140],
      [31, 29],
      [-21, 119],
      [38, 44],
      [53, 2],
      [37, 57],
      [43, 157],
      [-20, 146],
      [17, 76],
      [94, 87],
      [75, 139],
      [7, 116],
      [38, 68],
      [83, 34],
      [43, 44],
      [61, 152],
      [22, 20],
      [20, 123],
      [50, 37],
      [8, 71],
      [69, 31],
      [59, 69],
      [183, 89],
      [66, 80],
      [121, 79],
      [35, 107],
      [50, 77],
      [9, 224],
      [-20, 38],
      [34, 69],
      [20, 289],
      [-64, 202],
      [-7, 122],
      [-25, 19],
      [20, 172],
      [148, 151],
      [59, 173],
      [0, 119],
      [-33, 55],
      [22, 59],
      [-31, 170],
      [-54, 69],
      [-29, 152],
      [-57, 105],
      [-21, 86],
      [-108, 155],
      [-109, 77],
      [-115, -26],
      [-20, 73],
      [55, 103],
      [-64, 137],
      [19, 106],
      [-69, 44],
      [39, 111],
      [112, 41],
      [68, 84],
      [100, 200],
      [15, 145],
      [56, 80],
      [106, 104],
      [88, 156],
      [42, 104],
      [10, 109],
      [40, 56],
      [69, 245],
      [54, 90],
      [33, 116],
      [117, 272],
      [26, 22],
      [4, 90],
      [50, 74],
      [58, 47],
      [18, 55],
      [79, 99],
      [10, 49],
      [293, 197],
      [106, 42],
      [99, 1],
      [81, 55],
      [108, -93],
      [-40, -28],
      [-108, -145],
      [-34, -180],
      [6, -73],
      [-87, -292],
      [-2, -49],
      [-47, -94],
      [-76, -272],
      [15, -117],
      [101, -112],
      [9, -63],
      [59, -58],
      [-32, -66],
      [9, -108],
      [100, 44],
      [79, -8],
      [-11, -59],
      [28, -75],
      [-79, -78],
      [-49, -77],
      [-79, -75],
      [18, -95],
      [-22, -109],
      [94, -144],
      [40, -24],
      [76, 6],
      [128, 86],
      [29, 86],
      [123, 102],
      [72, 23],
      [93, -4],
      [47, -93],
      [-13, -86],
      [136, -18],
      [82, 44],
      [38, 80],
      [-37, 37],
      [41, 77],
      [-12, 58],
      [40, 150],
      [32, 33],
      [-1, 83],
      [80, 99],
      [11, 98],
      [102, 58],
      [-53, 71],
      [20, 65],
      [111, 23],
      [-110, 134],
      [84, 17],
      [71, -30],
      [28, 41],
      [-23, 57],
      [56, 79],
      [64, 147],
      [52, 26],
      [10, 103],
      [78, 44],
      [76, 80],
      [52, 16],
      [10, 124],
      [88, 100],
      [12, 227],
      [277, 167],
      [164, 12],
      [194, 130],
      [60, 16],
      [117, 100],
      [54, 22],
      [83, 80],
      [75, -2],
      [116, 52],
      [190, -45],
      [111, 30],
      [192, 158],
      [74, -43],
      [56, 14],
      [80, 80],
      [60, 14],
      [60, 47],
      [50, -21],
      [56, 51],
      [182, -22],
      [73, 14],
      [127, 80],
      [115, 54],
      [94, -6],
      [34, 93],
      [85, -19],
      [71, 74],
      [82, -83],
      [68, 93],
      [22, 138],
      [85, 89],
      [75, 7],
      [-11, 60],
      [137, 116],
      [83, 95],
      [146, 126],
      [90, 3],
      [84, 115],
      [57, -25],
      [150, 51],
      [63, 59],
      [79, 29],
      [65, -21],
      [45, 158],
      [56, 40],
      [103, 220],
      [135, 155],
      [69, 14],
      [180, 114],
      [269, 73],
      [255, 82],
      [92, 82],
      [80, 3],
      [10, 264],
      [32, 79],
      [11, 96],
      [73, -33],
      [43, -49],
      [191, -58],
      [134, 32],
      [60, 97],
      [48, -13],
      [151, 45],
      [212, 25],
      [184, 153],
      [34, 75],
      [226, 42],
      [78, 66],
      [75, 20],
      [70, 71],
      [123, 38],
      [126, 134],
      [103, 34],
      [-14, 51],
      [71, 89],
      [48, -2],
      [48, 46],
      [-42, 40],
      [69, 29],
      [8, 77],
      [144, 30],
      [64, -18],
      [-167, -179],
      [17, -27],
      [-104, -195],
      [-3, -48],
      [40, -70],
      [78, -77],
      [147, -49],
      [92, -4],
      [192, 74],
      [-16, 26],
      [154, 117],
      [157, 33],
      [74, -24],
      [35, 52],
      [65, 13],
      [101, -65],
      [59, 6],
      [38, 100],
      [85, 68],
      [89, -35],
      [25, -63],
      [-90, -61],
      [38, -106],
      [85, -52],
      [54, 11],
      [94, -34],
      [84, -59],
      [38, 10],
      [220, -51],
      [124, -50],
      [54, 69],
      [155, -3],
      [115, 15],
      [181, 40],
      [162, 67],
      [76, 48],
      [125, 52],
      [38, -4],
      [18, -93],
      [66, 67],
      [52, 21],
      [57, -71],
      [146, 31],
      [126, 47],
      [168, 126],
      [132, 74],
      [45, 81],
      [3, 71],
      [99, 19],
      [89, 39],
      [55, 143],
      [-14, 53],
      [164, 25],
      [139, 69],
      [86, 104],
      [70, 21],
      [237, 152],
      [123, 63],
      [15, -51],
      [-39, -63],
      [207, 61],
      [95, -9],
      [35, 41],
      [124, 19],
      [159, 69],
      [30, -91],
      [235, 59],
      [235, -20],
      [82, 22],
      [15, 50],
      [71, 41],
      [-7, 31],
      [129, 29],
      [-19, -117],
      [45, -28],
      [103, 22],
      [-111, 46],
      [17, 30],
      [126, 8],
      [64, 25],
      [31, -46],
      [122, -48],
      [263, 131],
      [68, 20],
      [176, -1],
      [71, 166],
      [65, 16],
      [25, 58],
      [102, 138],
      [162, 81],
      [57, 54],
      [69, 123],
      [23, 115],
      [35, 69],
      [204, 37],
      [90, 62],
      [42, 68],
      [19, 78],
      [77, -4],
      [173, -100],
      [20, 113],
      [41, 21],
      [115, -6],
      [140, -62],
      [217, -55],
      [79, 8],
      [107, -18],
      [43, -24],
      [122, 10],
      [83, -71],
      [90, 20],
      [69, -21],
      [17, -44],
      [118, -84],
      [163, -65],
      [167, 42],
      [102, 47],
      [73, 55],
      [28, 93],
      [76, 29],
      [19, -99],
      [123, 41],
      [9, 154],
      [96, -16],
      [239, 8],
      [217, 66],
      [246, 53],
      [130, -2],
      [155, 18],
      [263, 51],
      [233, -3],
      [445, 126],
      [214, 83],
      [179, 54],
      [465, 160],
      [221, 54],
      [386, 163],
      [280, 113],
      [492, 182],
      [384, 218],
      [270, 174],
      [216, 158],
      [167, 150],
      [295, 298],
      [128, 156],
      [209, 273],
      [256, 358],
      [49, 136],
      [-22, 23],
      [62, 61],
      [60, 96],
      [38, 98],
      [-13, 47],
      [59, 38],
      [75, 128],
      [12, 119],
      [67, 38],
      [45, 102],
      [66, 62],
      [-27, 29],
      [23, 88],
      [-9, 51],
      [-53, 47],
      [-53, 6],
      [-7, 72],
      [29, 35],
      [99, 11],
      [54, -43],
      [88, 44],
      [87, -1],
      [70, 22],
      [34, 71],
      [62, 17],
      [95, 64],
      [-24, 113],
      [-52, 78],
      [-146, 82],
      [120, 61],
      [71, 142],
      [59, -19],
      [-34, 111],
      [109, 118],
      [56, 10],
      [52, -66],
      [62, -26],
      [128, 34],
      [181, 108],
      [79, 79],
      [62, 7],
      [60, 41],
      [100, 110],
      [61, 105],
      [76, -3],
      [50, 38],
      [134, 45],
      [119, 117],
      [40, 89],
      [102, 52],
      [8, 66],
      [94, 15],
      [56, 43],
      [100, 23],
      [116, 47],
      [152, 123],
      [44, 82],
      [21, 109],
      [-6, 151],
      [-23, 104],
      [-67, 59],
      [-182, 8],
      [-190, 69],
      [-43, -64],
      [-85, -57],
      [-20, -60],
      [-83, 91],
      [76, 99],
      [16, 52],
      [16, 270],
      [-47, 264],
      [3, 294],
      [-55, 57],
      [-31, 199],
      [-30, 63],
      [-45, 29],
      [-30, 65],
      [8, 77],
      [-18, 160],
      [26, 26],
      [1, 417],
      [51, 86],
      [30, 219],
      [54, 79],
      [49, -20],
      [84, 197],
      [52, 60],
      [131, 73],
      [-94, 60],
      [15, 51],
      [96, 113],
      [71, 60],
      [143, 57],
      [88, 117],
      [-22, 24],
      [213, 135],
      [84, 22],
      [94, -77],
      [36, 131],
      [-27, 98],
      [147, -86],
      [34, -49],
      [78, -39],
      [139, 18],
      [-74, 85],
      [-3, 86],
      [-68, 76],
      [7, 49],
      [-52, 47],
      [2, 67],
      [107, -5],
      [11, 34],
      [-63, 57],
      [84, 84],
      [13, 55],
      [101, -7],
      [49, -107],
      [47, -10],
      [97, 22],
      [100, 56],
      [72, -29],
      [81, 38],
      [71, 95],
      [110, -58],
      [36, 14],
      [107, -89],
      [-30, 108],
      [-49, 12],
      [-58, 54],
      [-53, -6],
      [-45, 83],
      [-66, 34],
      [-7, 56],
      [114, 216],
      [20, 131],
      [110, 68],
      [70, 2],
      [67, 47],
      [65, 112],
      [97, 32],
      [31, 48],
      [-27, 73],
      [6, 76],
      [67, 98],
      [22, 72],
      [74, -42],
      [97, 22],
      [40, 43],
      [128, 58],
      [30, -63],
      [-43, -82],
      [64, -90],
      [69, -26],
      [-31, -64],
      [-92, 8],
      [-36, 27],
      [-93, -42],
      [40, -42],
      [-13, -55],
      [-55, -69],
      [85, -40],
      [-107, -114],
      [-41, -89],
      [94, -18],
      [33, -85],
      [46, -43],
      [29, 68],
      [80, -32],
      [-22, -82],
      [30, -31],
      [-9, -67],
      [42, -50],
      [107, 5],
      [43, -63],
      [76, -18],
      [77, 41],
      [28, -23],
      [-41, -96],
      [-51, 19],
      [-54, -46],
      [26, -128],
      [84, -22],
      [81, -56],
      [6, -81],
      [49, -90],
      [-44, -10],
      [-37, -85],
      [-8, -108],
      [72, -16],
      [234, -152],
      [21, -54],
      [128, -161],
      [44, -239],
      [38, -27],
      [151, -156],
      [60, -146],
      [90, -141],
      [22, -87],
      [-3, -135],
      [52, -32],
      [61, 1],
      [62, -66],
      [58, -17],
      [90, -159],
      [102, -66],
      [109, -172],
      [-1, -87],
      [80, -39],
      [34, -65],
      [-18, -93],
      [33, -76],
      [40, 17],
      [-7, 147],
      [30, 49],
      [-15, 49],
      [-52, 43],
      [12, 45],
      [-14, 170],
      [-44, 27],
      [27, 93],
      [-5, 62],
      [73, 32],
      [29, 60],
      [0, 104],
      [45, 135],
      [11, 148],
      [22, 90],
      [73, 39],
      [-54, 79],
      [-70, 59],
      [-81, 164],
      [-14, 198],
      [60, 75],
      [152, 42],
      [51, -35],
      [5, -103],
      [120, -56],
      [32, 46],
      [131, -86],
      [71, -64],
      [48, -133],
      [113, -137],
      [80, -31],
      [73, -53],
      [41, -73],
      [92, -17],
      [-11, 116],
      [-196, 173],
      [-3, 79],
      [-38, 186],
      [-66, 119],
      [-9, 49],
      [104, 150],
      [228, 171],
      [39, 65],
      [48, 7],
      [50, 50],
      [74, 10],
      [26, 47],
      [-47, 26],
      [-132, -59],
      [-182, -97],
      [-84, -11],
      [-41, -36],
      [-80, -18],
      [-91, 68],
      [-46, 77],
      [0, 102],
      [-39, 23],
      [-104, 152],
      [-21, 107],
      [-84, -10],
      [-151, 133],
      [-33, 66],
      [-168, 89],
      [-33, -18],
      [-86, 34],
      [-145, 4],
      [17, 70],
      [61, 26],
      [103, 14],
      [-27, 56],
      [38, 88],
      [46, -27],
      [123, 135],
      [-87, 50],
      [-39, -37],
      [-170, 8],
      [42, -82],
      [-81, 15],
      [-42, -84],
      [-37, 42],
      [-19, 113],
      [-69, 15],
      [-39, 39],
      [21, 43],
      [81, 1],
      [45, 42],
      [-4, 58],
      [239, -99],
      [258, -46],
      [-27, 44],
      [-178, 65],
      [131, 18],
      [125, -14],
      [11, 37],
      [-181, 100],
      [-336, 102],
      [-18, 55],
      [157, -17],
      [30, 50],
      [129, -27],
      [13, -51],
      [68, -30],
      [29, 24],
      [149, -39],
      [76, 44],
      [-19, 55],
      [-74, 20],
      [-99, 73],
      [44, 32],
      [105, -27],
      [124, 12],
      [-68, 113],
      [-132, 79],
      [-123, 7],
      [-100, -21],
      [-111, 66],
      [-53, 54],
      [75, 19],
      [95, 73],
      [-75, 105],
      [57, 35],
      [-92, 38],
      [26, 88],
      [138, 44],
      [140, -20],
      [59, 54],
      [74, -14],
      [21, -41],
      [58, -2],
      [78, 51],
      [121, -61],
      [70, -99],
      [17, -60],
      [-20, -46],
      [34, -59],
      [-78, -5],
      [-94, -67],
      [115, -41],
      [101, -100],
      [65, -33],
      [47, 31],
      [49, -18],
      [8, -61],
      [66, -46],
      [-34, -44],
      [-63, -19],
      [-16, -117],
      [36, -69],
      [53, 10],
      [89, 57],
      [-24, 27],
      [15, 114],
      [38, 99],
      [242, -145],
      [41, 16],
      [-22, 88],
      [-69, 56],
      [-42, 69],
      [-53, 42],
      [136, 81],
      [-75, 36],
      [-49, 103],
      [186, -92],
      [109, 3],
      [79, -52],
      [154, 4],
      [128, -27],
      [182, -106],
      [-4, -53],
      [69, -48],
      [43, -171],
      [40, -61],
      [52, -22],
      [82, 32],
      [43, -18],
      [192, -11],
      [27, -72],
      [-28, -73],
      [92, 12],
      [82, 52],
      [119, -119],
      [-22, -62],
      [50, -50],
      [-32, -48],
      [70, -16],
      [13, 110],
      [132, 10],
      [-115, 52],
      [-55, -18],
      [-63, 62],
      [0, 137],
      [-43, 33],
      [-169, -30],
      [29, 117],
      [136, 144],
      [52, -22],
      [4, -61],
      [168, -128],
      [137, 10],
      [240, -3],
      [67, -18],
      [116, 2],
      [47, 22],
      [275, 13],
      [166, 36],
      [150, -61],
      [72, -57],
      [86, -22],
      [72, 18],
      [101, -2],
      [-37, 60],
      [-102, -18],
      [-52, 19],
      [-71, -20],
      [-90, 99],
      [-219, 45],
      [-35, 54],
      [-72, 36],
      [-74, -18],
      [-184, 48],
      [-175, 22],
      [-225, -48],
      [-92, -42],
      [-56, 51],
      [-40, -44],
      [-78, -16],
      [-81, 26],
      [-37, 68],
      [29, 99],
      [-39, 65],
      [48, 102],
      [-12, 33],
      [-75, 45],
      [60, 85],
      [-37, 56],
      [127, 27],
      [38, 44],
      [45, 142],
      [-23, 61],
      [-2, 88],
      [113, 1],
      [19, -62],
      [-23, -52],
      [124, -150],
      [69, -25],
      [123, 142],
      [60, -21],
      [29, 42],
      [-28, 275],
      [21, 62],
      [-27, 37],
      [62, 79],
      [-1, 112],
      [85, 85],
      [-55, 65],
      [84, 54],
      [93, 90],
      [70, -28],
      [55, 67],
      [71, 19],
      [-31, 63],
      [43, 19],
      [-56, 106],
      [-41, -20],
      [-70, 31],
      [-118, -45],
      [-113, -91],
      [-73, -128],
      [-27, -18],
      [-74, -141],
      [-71, -175],
      [-76, -103],
      [-65, 25],
      [17, 115],
      [-60, 69],
      [-7, 205],
      [-113, 36],
      [-21, -59],
      [-104, 22],
      [-8, 91],
      [11, 101],
      [-36, 88],
      [7, 69],
      [-33, 97],
      [35, 146],
      [34, 62],
      [117, -4],
      [-46, 228],
      [-65, 24],
      [57, 58],
      [-3, 44],
      [59, 107],
      [61, 3],
      [1, 62],
      [-41, 48],
      [115, 64],
      [113, 23],
      [52, -58],
      [62, -11],
      [14, -42],
      [94, 4],
      [31, -75],
      [43, 61],
      [74, 10],
      [-8, 57],
      [42, 155],
      [91, -132],
      [75, -8],
      [18, 34],
      [-47, 57],
      [-10, 81],
      [52, 38],
      [-33, 39],
      [31, 45],
      [130, 50],
      [23, 54],
      [-112, 18],
      [-108, -16],
      [-14, 75],
      [112, 14],
      [53, 40],
      [-85, 75],
      [-35, 101],
      [95, -35],
      [6, 71],
      [173, -87],
      [60, -93],
      [95, 48],
      [126, -97],
      [80, -83],
      [189, -34],
      [94, -74],
      [62, -9],
      [10, -61],
      [39, -40],
      [83, -30],
      [44, -75],
      [-66, -70],
      [46, -38],
      [68, 34],
      [31, -42],
      [67, 22],
      [60, 50],
      [94, -5],
      [100, -54],
      [203, -177],
      [47, 36],
      [-91, 40],
      [-86, 87],
      [-129, 71],
      [-111, 91],
      [49, 102],
      [26, 163],
      [-8, 64],
      [53, 108],
      [-101, -42],
      [-83, 45],
      [-87, 6],
      [-89, -38],
      [-70, -54],
      [-43, -6],
      [-170, -88],
      [-78, 55],
      [-76, 115],
      [39, 85],
      [113, 86],
      [2, 36],
      [67, 62],
      [91, 38],
      [105, -83],
      [38, 48],
      [67, 25],
      [43, 62],
      [76, 64],
      [-49, 62],
      [-108, -56],
      [-72, -73],
      [-79, 50],
      [-57, -75],
      [-160, -87],
      [-8, -50],
      [-86, -43],
      [-58, 79],
      [6, 106],
      [64, 106],
      [-109, -38],
      [-63, 10],
      [17, 75],
      [91, 91],
      [89, 53],
      [132, 3],
      [31, -38],
      [84, 12],
      [54, -41],
      [24, 56],
      [113, 56],
      [50, -30],
      [52, 103],
      [-62, 39],
      [-30, 87],
      [-4, 121],
      [74, 63],
      [37, -26],
      [80, 55],
      [34, -52],
      [-8, -47],
      [-79, -75],
      [62, -66],
      [-10, -60],
      [75, -6],
      [36, 83],
      [70, -2],
      [-11, -161],
      [56, -36],
      [23, -105],
      [64, -56],
      [49, 75],
      [36, -11],
      [86, 46],
      [45, 48],
      [123, 1],
      [108, -131],
      [85, -39],
      [111, 30],
      [58, -26],
      [67, 35],
      [102, -48],
      [110, 29],
      [134, -20],
      [-1, 61],
      [-94, 20],
      [-200, -4],
      [-83, 73],
      [94, 68],
      [0, 54],
      [-83, 6],
      [-4, 61],
      [95, 32],
      [120, 68],
      [-87, 13],
      [-91, -46],
      [-52, 119],
      [-27, -31],
      [-93, 14],
      [-64, -14],
      [-124, 37],
      [3, 67],
      [-94, 93],
      [-55, 5],
      [-14, -55],
      [-85, -26],
      [-17, 71],
      [52, 20],
      [27, 74],
      [80, 40],
      [64, -79],
      [121, 23],
      [5, 68],
      [33, 34],
      [-142, 105],
      [-64, 24],
      [-27, -30],
      [-102, 19],
      [-81, 70],
      [-36, 188],
      [-132, 18],
      [-29, 76],
      [53, 58],
      [84, -13],
      [4, 103],
      [122, 6],
      [-25, 45],
      [12, 76],
      [51, 20],
      [101, -49],
      [34, 73],
      [145, 20],
      [-66, 70],
      [-37, -34],
      [-62, 21],
      [-27, 86],
      [17, 45],
      [57, 12],
      [67, 49],
      [63, 4],
      [114, 76],
      [51, -10],
      [70, -63],
      [83, 15],
      [30, 41],
      [45, -147],
      [111, 0],
      [-8, 75],
      [71, 51],
      [-29, 44],
      [110, 108],
      [179, 22],
      [34, -69],
      [-48, -27],
      [36, -61],
      [66, 23],
      [39, 81],
      [36, -33],
      [43, 68],
      [-42, 169],
      [23, 102],
      [66, 67],
      [-60, 121],
      [-51, 23],
      [41, 107],
      [-66, 72],
      [62, 129],
      [-53, 56],
      [22, 42],
      [59, -8],
      [46, 51],
      [110, -53],
      [54, -83],
      [56, -42],
      [144, -74],
      [-44, -54],
      [34, -55],
      [-66, -15],
      [-109, 56],
      [11, -97],
      [-45, -132],
      [-8, -138],
      [60, -30],
      [3, -52],
      [39, -90],
      [-35, -176],
      [-97, -239],
      [65, 31],
      [42, 142],
      [69, 148],
      [47, 132],
      [55, 16],
      [37, -28],
      [94, 45],
      [95, 19],
      [13, 72],
      [50, -7],
      [-37, -85],
      [49, -6],
      [127, 57],
      [20, -69],
      [-123, -26],
      [-17, -41],
      [62, -85],
      [-15, -85],
      [51, -23],
      [-52, -63],
      [54, -50],
      [36, -77],
      [68, 24],
      [22, -58],
      [64, -26],
      [28, -76],
      [68, 30],
      [22, 96],
      [-51, 108],
      [-63, 49],
      [63, 100],
      [177, 55],
      [5, -74],
      [77, -34],
      [47, 26],
      [33, 93],
      [92, -59],
      [12, 215],
      [-54, 7],
      [-23, 129],
      [-29, 49],
      [-102, -13],
      [-27, 73],
      [85, -7],
      [148, 137],
      [82, -11],
      [34, 33],
      [-31, 60],
      [63, 14],
      [-18, 88],
      [-61, 79],
      [-29, 80],
      [72, 60],
      [74, -7],
      [101, 22],
      [73, 84],
      [30, 97],
      [-53, 62],
      [53, 26],
      [-19, 88],
      [-65, 25],
      [-25, 70],
      [-87, 73],
      [-47, -38],
      [-83, 43],
      [-104, -73],
      [-145, 90],
      [-4, 60],
      [-38, 59],
      [-1, 85],
      [47, -8],
      [71, -54],
      [-29, -96],
      [100, 77],
      [74, 15],
      [-62, 90],
      [9, 53],
      [86, -16],
      [31, -138],
      [52, 25],
      [27, 79],
      [-119, 185],
      [11, 54],
      [74, 19],
      [164, -69],
      [4, 76],
      [93, -19],
      [-71, -117],
      [63, -29],
      [-83, -67],
      [146, 2],
      [-91, -72],
      [120, -10],
      [46, 22],
      [93, -74],
      [-27, -48],
      [-101, -11],
      [-77, -71],
      [80, -2],
      [110, -145],
      [-59, -28],
      [13, -55],
      [-64, -87],
      [29, -45],
      [125, 1],
      [45, -44],
      [-48, -49],
      [13, -65],
      [74, -46],
      [57, 4],
      [31, 50],
      [62, -31],
      [22, 70],
      [165, 83],
      [-108, 122],
      [-76, 22],
      [43, 76],
      [4, 70],
      [106, 5],
      [-11, 168],
      [153, -6],
      [39, 107],
      [88, 11],
      [33, 73],
      [97, -56],
      [96, 38],
      [62, 97],
      [39, 11],
      [58, 71],
      [36, -70],
      [-122, -157],
      [-42, 9],
      [-40, -135],
      [-103, -97],
      [5, -75],
      [51, -21],
      [77, 23],
      [25, -105],
      [98, -85],
      [37, 29],
      [112, 25],
      [18, -50],
      [-40, -42],
      [24, -95],
      [-19, -69],
      [37, -39],
      [104, -14],
      [-6, 82],
      [24, 32],
      [122, 44],
      [24, 89],
      [-17, 69],
      [46, 83],
      [-54, 118],
      [23, 13],
      [136, -115],
      [-4, -83],
      [55, 7],
      [55, 51],
      [-24, 70],
      [62, 14],
      [19, 76],
      [61, 57],
      [-55, 119],
      [-37, -42],
      [-89, 16],
      [-19, 60],
      [30, 73],
      [55, -41],
      [81, -7],
      [11, 59],
      [173, 20],
      [45, -58],
      [90, 60],
      [70, 1],
      [81, -35],
      [16, -46],
      [77, 35],
      [-111, 104],
      [-120, 87],
      [-121, 0],
      [16, 36],
      [-40, 110],
      [-88, 76],
      [-51, 115],
      [8, 60],
      [-58, 60],
      [165, 1],
      [92, 70],
      [45, -53],
      [-12, -81],
      [105, 41],
      [-8, 132],
      [97, 25],
      [152, -85],
      [22, 111],
      [90, -1],
      [94, -82],
      [6, -56],
      [115, -68],
      [-15, -69],
      [119, 36],
      [13, -33],
      [-35, -71],
      [47, -11],
      [52, 43],
      [37, -63],
      [-41, -138],
      [117, 39],
      [15, -195],
      [24, -61],
      [111, -59],
      [9, 77],
      [38, 81],
      [127, 30],
      [-32, -54],
      [15, -40],
      [121, 25],
      [42, 120],
      [93, 19],
      [65, -55],
      [24, -49],
      [89, -90],
      [70, -11],
      [48, 50],
      [-78, 64],
      [-5, 40],
      [80, 34],
      [132, -99],
      [37, -10],
      [62, -73],
      [91, -35],
      [90, -76],
      [6, -34],
      [-63, -65],
      [37, -34],
      [-35, -63],
      [99, -38],
      [43, 2],
      [169, -75],
      [-8, -38],
      [74, -19],
      [142, -137],
      [56, 10],
      [117, -120],
      [82, -41],
      [-5, -73],
      [59, -19],
      [14, -83],
      [76, -39],
      [134, -137],
      [47, -21],
      [58, -98],
      [29, -182],
      [55, 15],
      [31, -54],
      [57, -11],
      [-31, -124],
      [47, -38],
      [59, -108],
      [106, 45],
      [26, -66],
      [47, -41],
      [98, 1],
      [50, -64],
      [-42, -115],
      [58, -33],
      [51, -78],
      [131, 67],
      [55, -2],
      [63, -43],
      [125, -123],
      [-8, -87],
      [69, 2],
      [155, -59],
      [57, -65],
      [32, -70],
      [97, -33],
      [46, 27],
      [58, -15],
      [7, -144],
      [-53, 56],
      [-59, -22],
      [-31, -151],
      [59, -157],
      [-120, -11],
      [-137, -69],
      [72, -32],
      [-54, -103],
      [-55, -149],
      [22, -60],
      [-5, -80],
      [-46, -78],
      [-17, -102],
      [28, -72],
      [-79, -77],
      [-18, -63],
      [27, -68],
      [8, -91],
      [48, -38],
      [-25, -76],
      [-2, -87],
      [-28, -154],
      [33, -59],
      [-93, -107],
      [7, -83],
      [65, -96],
      [10, -80],
      [-42, -145],
      [-74, -132],
      [-66, -42],
      [57, -41],
      [117, 72],
      [111, 204],
      [21, 133],
      [12, 278],
      [-52, 116],
      [-9, 160],
      [56, 82],
      [61, -54],
      [68, -28],
      [93, -75],
      [75, -98],
      [150, -138],
      [6, -47],
      [89, -98],
      [-13, -51],
      [16, -116],
      [49, -130],
      [30, 84],
      [-34, 29],
      [-10, 132],
      [29, 139],
      [-79, 136],
      [-129, 96],
      [-74, 153],
      [14, 127],
      [-11, 114],
      [-94, 113],
      [34, 126],
      [92, 193],
      [119, 58],
      [90, 28],
      [26, 129],
      [88, 15],
      [20, -141],
      [51, -110],
      [71, -197],
      [28, 25],
      [-38, 131],
      [-2, 80],
      [-27, 75],
      [12, 92],
      [4, 196],
      [43, -13],
      [67, -106],
      [109, -95],
      [25, -77],
      [79, -94],
      [26, 21],
      [-63, 70],
      [-79, 200],
      [37, 90],
      [53, -88],
      [115, -143],
      [-15, 99],
      [20, 59],
      [-29, 67],
      [-98, 123],
      [-78, 62],
      [-129, 56],
      [47, 39],
      [84, 24],
      [70, -44],
      [236, 97],
      [54, -10],
      [79, 46],
      [115, -72],
      [96, -40],
      [37, 56],
      [224, -27],
      [145, -71],
      [2, -34],
      [66, -40],
      [106, 9],
      [80, -71],
      [143, -12],
      [273, 18],
      [77, -49],
      [105, -20],
      [137, -9],
    ],
    [
      [56319, 77724],
      [-133, -23],
      [-235, -76],
      [-156, -75],
      [-169, -61],
      [-171, -98],
      [-168, -70],
      [-159, -84],
      [-66, -57],
      [-160, -40],
      [-23, -28],
      [-164, -33],
      [-47, -35],
      [-222, -89],
      [-108, -53],
      [-86, -11],
      [-457, -174],
      [-470, -133],
      [-323, -113],
      [-530, -120],
      [-84, -4],
      [-283, -57],
      [-31, 2],
      [-293, -60],
      [-231, -64],
      [-68, -38],
      [-122, -25],
      [-129, -52],
      [-104, -78],
      [-130, -8],
      [-480, -105],
      [-66, -27],
      [-307, -44],
      [-87, -22],
      [-155, 1],
      [-218, -20],
      [-174, -6],
      [-391, -48],
      [-344, 0],
      [-262, 20],
      [-147, -46],
      [-202, -7],
      [-268, 25],
      [-142, 3],
      [-347, 38],
      [-197, 28],
      [-198, 59],
      [-237, 39],
      [-166, 49],
      [-90, 11],
      [-172, -27],
      [-140, -80],
      [-140, -40],
      [-130, 27],
      [-43, -20],
      [-161, -25],
      [-97, -28],
      [-327, -163],
      [-198, -90],
      [-352, -220],
      [-75, -36],
      [-126, -105],
      [-99, -49],
      [-204, -151],
      [-87, -44],
      [-176, -41],
      [-30, -29],
      [-189, -58],
      [-178, -15],
      [-261, -79],
      [-189, -76],
      [-410, -207],
      [-71, -47],
      [-267, -44],
      [-72, -84],
      [-167, -69],
      [-105, -64],
      [-98, -114],
      [-81, -48],
      [-116, -38],
      [-82, -52],
      [-252, -94],
      [-219, -37],
      [-243, -31],
      [-554, -55],
      [-227, -45],
      [-137, -36],
      [-172, -69],
      [-244, -130],
      [-233, -199],
      [-160, -183],
      [-78, -136],
      [-68, -219],
      [-102, -214],
      [-67, -109],
      [-121, -279],
      [-43, -185],
      [-23, -234],
      [-63, -67],
      [-93, -23],
      [-24, -34],
      [-127, -13],
      [-85, -58],
      [15, -84],
      [-68, -21],
      [-150, -81],
      [-126, -111],
      [-61, -83],
      [-35, -170],
      [30, -62],
      [-39, -49],
      [-94, -15],
      [-90, -66],
      [-124, -49],
      [-81, -108],
      [-151, -5],
      [-103, -72],
      [-49, -107],
      [-121, -25],
      [-13, 65],
      [-76, 41],
      [-261, 56],
      [-189, -18],
      [-98, -65],
      [-13, -54],
      [-119, -129],
      [-60, -6],
      [-70, -67],
      [-99, 23],
      [-41, -31],
      [-171, -60],
      [13, 101],
      [-42, 32],
      [9, 101],
      [-120, 160],
      [-83, 52],
      [-130, 57],
      [-172, 23],
      [-39, -78],
      [-119, -33],
      [-99, 18],
      [-132, -11],
      [-263, -68],
      [-63, 67],
      [-158, 39],
      [-114, -47],
      [-279, 10],
      [-193, -38],
      [-71, -63],
      [36, -81],
      [-47, -28],
      [-67, 52],
      [-179, -13],
      [-218, 70],
      [-158, 25],
      [-140, -10],
      [-250, -113],
      [-69, -64],
      [44, -72],
      [-110, -49],
      [-87, 32],
      [-98, -40],
      [-142, -22],
      [-52, 18],
      [-96, -30],
      [-14, 82],
      [32, 42],
      [-117, 256],
      [-191, 192],
      [-79, 44],
      [-71, 11],
      [-66, -27],
      [-126, 7],
      [-107, -42],
      [-54, -118],
      [-180, -15],
      [-175, -70],
      [-153, 100],
      [-145, 52],
      [-80, -1],
      [-56, -71],
      [-54, -11],
      [-48, 47],
      [-166, 95],
      [-116, 50],
      [-73, 13],
      [-94, -46],
      [-75, 38],
      [-167, 26],
      [-31, -22],
      [-96, 27],
      [-133, 11],
      [-46, -41],
      [-67, 17],
      [-106, -8],
      [-8, -47],
      [-108, -37],
      [-157, 26],
      [-69, -4],
      [-73, -74],
      [-77, 4],
      [-122, 47],
      [-109, 7],
      [-51, -46],
      [-101, 18],
      [-123, -13],
      [-18, -33],
      [-242, 28],
      [-59, -25],
      [-58, 28],
      [-131, 17],
      [-100, -33],
      [-105, -71],
      [-114, 6],
      [-233, -24],
      [-107, 25],
      [-145, -20],
      [-128, 19],
      [-117, -23],
      [-57, -36],
      [9, -69],
      [-90, -21],
      [-92, -54],
      [-195, -54],
      [-38, -27],
      [-246, 61],
      [-238, 39],
      [-239, 30],
      [-189, -8],
      [-203, -58],
      [-20, 25],
      [-242, 72],
      [-116, -45],
      [-75, -3],
      [-26, -57],
      [-128, -72],
      [-69, 23],
      [-264, -2],
      [-151, -62],
      [-68, -55],
      [-23, -72],
      [-107, -46],
      [-62, -67],
      [-78, -2],
      [-83, -56],
      [-131, -31],
      [-100, -103],
      [-83, -52],
      [-56, -61],
      [22, -59],
      [-118, -93],
      [-126, -168],
      [-50, -128],
      [-16, -139],
      [29, -97],
      [35, -1],
      [78, -74],
      [102, 17],
      [-2, -44],
      [-110, -11],
      [-74, -57],
      [-124, 17],
      [42, 41],
      [-34, 59],
      [-100, -12],
      [-16, 35],
      [-146, -31],
      [-6, -53],
      [-48, -66],
      [17, -89],
      [38, -60],
      [-70, -11],
      [-2, -62],
      [-56, -14],
      [-55, 114],
      [-74, 19],
      [-145, -20],
      [-76, -54],
      [28, -34],
      [14, -107],
      [-80, -27],
      [-31, -51],
      [-87, 98],
      [-164, 57],
      [-36, -31],
      [-182, 82],
      [-172, 39],
      [-38, -15],
      [-192, 37],
      [-155, 3],
      [-177, -45],
      [-7, -112],
      [-207, -10],
      [-138, -32],
      [-24, -31],
      [-135, -61],
      [-63, -68],
      [-19, -56],
      [61, -90],
      [-6, -57],
      [-114, -120],
      [-73, 7],
      [-124, -43],
      [-71, -77],
      [-110, 10],
      [-47, -33],
      [-159, -24],
      [-278, -103],
      [-81, -46],
      [-100, -92],
      [-73, -128],
      [-28, -91],
      [6, -111],
      [89, -16],
      [-11, -59],
      [-101, -27],
      [-217, 32],
      [-100, -43],
      [-144, 9],
      [-135, -43],
      [-77, -65],
      [-82, 3],
      [-16, -80],
      [139, -46],
      [-34, -70],
      [-83, -48],
      [-29, 59],
      [-95, 23],
      [-138, 10],
      [-139, -43],
      [-66, -59],
      [-117, 0],
      [-34, 22],
      [-136, -10],
      [-37, 55],
      [101, 20],
      [-22, 97],
      [15, 24],
      [3, 48],
    ],
    [
      [17714, 66933],
      [-7, -41],
      [-98, 28],
      [-9, -121],
      [-40, -77],
      [-59, -47],
      [5, -61],
      [-214, 28],
      [-34, -42],
      [138, -124],
      [74, -23],
      [66, 38],
      [40, -52],
      [139, -26],
      [-91, -102],
      [-53, 31],
      [-248, 32],
      [-55, 44],
      [-247, 112],
      [-103, 32],
      [-266, 24],
      [-104, -54],
      [20, -73],
      [-51, -20],
      [-30, -79],
      [37, -59],
      [-111, 6],
      [-3, 40],
      [-155, 93],
      [-133, 47],
      [-111, -53],
      [-78, 71],
      [-221, 106],
      [-159, 57],
      [12, 57],
      [114, -17],
      [110, -47],
      [92, 16],
      [84, 67],
      [-37, 79],
      [-48, 19],
      [-118, -58],
      [-58, 37],
      [-115, 16],
      [-49, -73],
      [-77, -16],
      [-34, -67],
      [37, -63],
      [-48, -31],
      [-72, 40],
      [-134, 37],
      [-60, -27],
      [-85, 30],
      [-140, 3],
      [-73, -35],
      [-1, -49],
      [-53, -40],
      [-227, 87],
      [-49, -25],
      [-120, 42],
      [-60, -24],
      [-175, 43],
      [-105, -44],
      [-41, -102],
      [-231, 41],
      [-159, 78],
      [-130, 40],
      [-68, -2],
      [-46, -52],
    ],
    [
      [13389, 66628],
      [-39, -28],
      [-87, 37],
      [-175, -75],
      [-101, 18],
      [-82, 49],
      [-50, -7],
      [-205, 96],
      [-112, -15],
      [-78, 17],
      [-148, 99],
      [-201, 97],
      [-205, 115],
      [-107, 40],
      [-62, 44],
      [-156, 53],
      [-110, 19],
      [-208, 61],
      [-139, -12],
      [-228, 37],
      [-107, -15],
      [10, 78],
      [-63, 102],
      [-86, 84],
      [-28, 113],
      [-351, 312],
      [-187, 158],
      [-228, 179],
      [-302, 218],
      [-202, 123],
      [-116, 48],
      [-52, -3],
      [-81, 64],
      [-119, 39],
      [-116, 79],
      [-234, 109],
      [-156, 43],
      [-276, 29],
      [-157, -11],
      [-171, -39],
      [1, -83],
      [-46, -75],
      [-77, 0],
      [-47, 86],
      [-103, 74],
      [-31, 49],
      [-194, 165],
      [19, 63],
      [-30, 49],
      [53, 72],
      [-71, 206],
      [-94, 138],
    ],
    [[19346, 67049], [146, -55], [-34, -53], [-140, 86], [28, 22]],
    [[9625, 76166], [50, -165], [42, -46], [-52, -79], [-71, 236], [31, 54]],
    [[9178, 76702], [33, -57], [-219, -24], [17, 78], [63, 28], [106, -25]],
    [
      [3463, 97196],
      [30, -101],
      [12, -118],
      [-49, -47],
      [-98, 37],
      [-88, 139],
      [4, 114],
      [87, 33],
      [102, -57],
    ],
    [[8829, 111442], [-77, 13], [73, 67], [4, -80]],
    [[16876, 114415], [-23, -86], [-76, 36], [25, 117], [74, -67]],
    [
      [30897, 135943],
      [18, -73],
      [78, -74],
      [110, -40],
      [74, 3],
      [175, 67],
      [45, -16],
      [75, 30],
      [120, 77],
      [76, 84],
      [15, 55],
      [-20, 134],
      [67, -74],
      [-28, -128],
      [-80, -84],
      [-264, -142],
      [-151, -56],
      [-92, 4],
      [-141, 43],
      [-164, 129],
      [-1, 126],
      [32, 100],
      [74, 38],
      [-33, -165],
      [15, -38],
    ],
    [
      [31369, 136345],
      [152, 89],
      [49, 53],
      [0, 66],
      [-116, 108],
      [21, 20],
      [134, -142],
      [-30, -108],
      [-186, -116],
      [-24, 30],
    ],
    [
      [31379, 136730],
      [-64, 54],
      [-27, -58],
      [-9, -94],
      [-74, -94],
      [-12, -87],
      [-64, 41],
      [24, 75],
      [82, 59],
      [1, 156],
      [102, 33],
      [93, -46],
      [-52, -39],
    ],
    [
      [36550, 127978],
      [30, -51],
      [90, -91],
      [-31, -65],
      [-84, 91],
      [-32, 84],
      [27, 32],
    ],
    [[35973, 128457], [37, -61], [-59, -63], [-64, 22], [86, 102]],
    [[37144, 127725], [-74, -82], [-153, 60], [96, 24], [46, -27], [85, 25]],
    [[39595, 128585], [33, -46], [83, -47], [-69, -58], [-57, 71], [10, 80]],
    [[41226, 128515], [60, 60], [41, -61], [-101, 1]],
    [[42421, 131976], [-60, -130], [-69, -7], [-21, 42], [119, 145], [31, -50]],
    [[38226, 128948], [-50, -65], [-43, 13], [2, 63], [91, -11]],
    [
      [53323, 132929],
      [62, -166],
      [130, -112],
      [-22, -105],
      [5, -102],
      [-84, 58],
      [-160, 66],
      [-41, 105],
      [38, 97],
      [-10, 28],
      [25, 153],
      [57, -22],
    ],
    [[54364, 133463], [84, -40], [-48, -47], [-36, 87]],
    [[53895, 133964], [77, -56], [-71, -34], [-46, 55], [40, 35]],
    [[52370, 134923], [-77, -109], [-46, 73], [42, 16], [45, 69], [36, -49]],
    [
      [43211, 134255],
      [-87, 18],
      [-38, -60],
      [-65, 27],
      [97, 97],
      [48, 14],
      [45, -96],
    ],
    [
      [42927, 134903],
      [49, -84],
      [83, -65],
      [-12, -66],
      [-58, -145],
      [26, -94],
      [-90, -138],
      [-30, 38],
      [-65, -59],
      [-14, -63],
      [-87, 83],
      [-70, -6],
      [-20, 158],
      [20, 63],
      [-19, 48],
      [156, 9],
      [-72, 177],
      [75, 77],
      [68, 8],
      [-24, 55],
      [84, 4],
    ],
    [[39135, 129400], [-32, -18], [-76, 55], [88, 42], [40, -31], [-20, -48]],
    [
      [39502, 130021],
      [34, -92],
      [83, -3],
      [-3, -62],
      [-109, 21],
      [-50, 113],
      [45, 23],
    ],
    [[40430, 130100], [15, -119], [-63, -9], [19, 89], [29, 39]],
    [
      [40869, 130027],
      [-11, -64],
      [-132, -87],
      [-61, 11],
      [51, 94],
      [99, 46],
      [54, 0],
    ],
    [[40087, 131815], [-87, 25], [38, 50], [59, -33], [-10, -42]],
    [
      [40764, 132249],
      [29, -48],
      [0, -71],
      [-33, -59],
      [28, -47],
      [71, 5],
      [6, -62],
      [63, -20],
      [68, -104],
      [-12, -49],
      [112, -33],
      [-162, -71],
      [-128, -70],
      [-128, -19],
      [-44, 38],
      [114, 141],
      [-18, 64],
      [-81, 23],
      [-53, -105],
      [-32, 73],
      [-68, -27],
      [-80, 58],
      [44, 43],
      [39, 98],
      [55, 20],
      [-25, 76],
      [41, 49],
      [117, 39],
      [77, 58],
    ],
    [[40023, 131983], [-55, -43], [-93, -19], [134, 127], [14, -65]],
    [[39803, 132076], [-26, -26], [-102, 14], [-58, 36], [153, 59], [33, -83]],
    [
      [40399, 132190],
      [-21, -60],
      [3, -76],
      [-65, -5],
      [-77, 45],
      [-93, -70],
      [-89, -16],
      [-54, 51],
      [25, 47],
      [99, 32],
      [75, -12],
      [90, 108],
      [69, -3],
      [38, -41],
    ],
    [[41040, 132147], [-79, -23], [11, 80], [68, -57]],
    [
      [41702, 132285],
      [81, -15],
      [22, -33],
      [-30, -62],
      [-119, -63],
      [-84, 21],
      [49, 159],
      [81, -7],
    ],
    [[42194, 132790], [-28, 70], [31, 87], [55, -45], [-58, -112]],
    [
      [42909, 132955],
      [11, -84],
      [181, 0],
      [9, -64],
      [-48, -68],
      [-101, -62],
      [-57, 13],
      [15, 73],
      [-19, 56],
      [-64, 57],
      [7, 82],
      [66, -3],
    ],
    [
      [42090, 133242],
      [13, -119],
      [-38, -45],
      [95, -98],
      [-38, -40],
      [-127, 75],
      [-48, 54],
      [1, 101],
      [77, 16],
      [9, 55],
      [56, 1],
    ],
    [
      [355, 98254],
      [-52, -98],
      [4, -97],
      [104, -251],
      [134, -448],
      [72, -68],
      [9, -70],
      [52, -95],
      [-17, -97],
      [39, -41],
      [123, -25],
      [-62, -47],
      [-42, -68],
      [36, -182],
      [106, -8],
      [94, -71],
      [29, -75],
      [70, -96],
      [50, -128],
      [-44, -110],
      [18, -59],
      [-171, 74],
      [-86, 225],
      [-154, 194],
      [-174, 163],
      [-108, 168],
      [-28, 111],
      [-173, 221],
      [21, 147],
      [-37, 101],
      [-39, 48],
      [-73, 192],
      [-26, 27],
      [-30, 167],
      [27, 80],
      [-2, 118],
      [152, 160],
      [52, -56],
      [126, -6],
    ],
    [
      [693, 99814],
      [32, -103],
      [-61, -155],
      [-2, -118],
      [-21, -108],
      [-46, -44],
      [-10, -173],
      [-67, 8],
      [64, 152],
      [26, 235],
      [-25, 170],
      [49, 123],
      [61, 13],
    ],
    [[2520, 100265], [-55, 55], [66, 17], [-11, -72]],
    [
      [859, 100637],
      [-5, -79],
      [-66, -181],
      [-28, -36],
      [20, -186],
      [21, -30],
      [-57, -57],
      [-33, 44],
      [23, 75],
      [-22, 90],
      [12, 150],
      [35, 74],
      [23, 140],
      [77, -4],
    ],
    [[5654, 109857], [35, -142], [-58, -27], [-99, 134], [19, 51], [103, -16]],
    [[5068, 111019], [33, 81], [38, -25], [-71, -56]],
    [
      [8855, 114274],
      [62, -31],
      [-21, -85],
      [55, -94],
      [-20, -141],
      [-46, -57],
      [-37, -118],
      [-80, -30],
      [-29, -97],
      [-70, -60],
      [-78, -17],
      [-60, 78],
      [-84, -3],
      [-12, -65],
      [-76, 62],
      [1, 71],
      [67, 159],
      [86, 90],
      [28, 63],
      [52, 20],
      [164, 225],
      [50, 46],
      [48, -16],
    ],
    [
      [13025, 114190],
      [-138, -110],
      [-91, 65],
      [13, 54],
      [55, 21],
      [120, 10],
      [41, -40],
    ],
    [[13146, 114475], [-25, -62], [-85, 37], [53, 80], [57, -55]],
    [
      [12735, 114556],
      [-23, -51],
      [9, -65],
      [-178, -4],
      [-88, 32],
      [2, 57],
      [124, 39],
      [67, -53],
      [34, 80],
      [53, -35],
    ],
    [
      [13114, 114644],
      [17, -22],
      [-86, -63],
      [-31, -72],
      [-112, -22],
      [-30, 29],
      [63, 68],
      [102, 77],
      [77, 5],
    ],
    [[13639, 114870], [-66, -97], [-35, 36], [68, 112], [33, -51]],
    [[12934, 114946], [-28, -74], [-69, -37], [-36, 60], [60, 73], [73, -22]],
    [
      [13808, 114977],
      [20, -79],
      [61, 2],
      [-41, -97],
      [-143, -31],
      [-51, 18],
      [23, 69],
      [88, 149],
      [43, -31],
    ],
    [[9150, 115062], [-29, -68], [24, -109], [-58, 9], [13, 118], [50, 50]],
    [
      [36948, 129057],
      [60, -33],
      [13, -115],
      [52, -28],
      [-42, -57],
      [-50, 78],
      [-23, 81],
      [-50, 11],
      [-38, 53],
      [78, 10],
    ],
    [
      [37864, 129396],
      [157, -44],
      [63, -49],
      [-21, -33],
      [-89, 7],
      [-235, 111],
      [36, 30],
      [89, -22],
    ],
    [[37214, 129537], [-7, -54], [-73, 24], [5, 56], [75, -26]],
    [[37393, 129481], [131, -40], [-26, -25], [-105, 65]],
    [[37155, 129689], [37, -57], [-31, -26], [-53, 64], [47, 19]],
    [
      [44196, 134913],
      [52, -94],
      [1, -45],
      [-95, 6],
      [-15, 74],
      [-44, 43],
      [50, 85],
      [51, -69],
    ],
    [
      [44924, 135039],
      [-26, -63],
      [35, -54],
      [-1, -66],
      [-104, -42],
      [-15, 53],
      [54, 183],
      [57, -11],
    ],
    [[45668, 135240], [-13, -77], [-42, -48], [-41, 46], [40, 60], [56, 19]],
    [
      [45871, 135386],
      [88, -36],
      [-2, -65],
      [-68, 30],
      [-6, -68],
      [-99, 44],
      [10, 57],
      [-34, 94],
      [111, -56],
    ],
    [[45958, 135630], [-67, -38], [-19, 65], [86, -27]],
    [[47216, 136419], [-63, -53], [-76, 96], [49, 27], [90, -70]],
    [[48830, 136526], [-151, -12], [19, 55], [132, -43]],
    [
      [47682, 136819],
      [80, -3],
      [131, -78],
      [-175, 19],
      [-114, -41],
      [-36, 65],
      [8, 58],
      [51, 9],
      [55, -29],
    ],
  ],
  'objects': {
    'gadm41_AUS_1': {
      'geometries': [
        {
          'arcs': [[[0]], [[1]]],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'Territory',
            'CC_1': '12',
            'GID_0': 'AUS',
            'GID_1': 'AUS.1_1',
            'HASC_1': 'AU.AS',
            'ISO_1': 'NA',
            'NAME_1': 'Ashmore and Cartier Islands',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Territory',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [[2]],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'Territory',
            'CC_1': '8',
            'GID_0': 'AUS',
            'GID_1': 'AUS.2_1',
            'HASC_1': 'AU.AC',
            'ISO_1': 'AU-ACT',
            'NAME_1': 'Australian Capital Territory',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Territory',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[3]],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'Territory',
            'CC_1': '11',
            'GID_0': 'AUS',
            'GID_1': 'AUS.3_1',
            'HASC_1': 'AU.CR',
            'ISO_1': 'NA',
            'NAME_1': 'Coral Sea Islands Territory',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Territory',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [[4, 5]],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'Territory',
            'CC_1': '10',
            'GID_0': 'AUS',
            'GID_1': 'AUS.4_1',
            'HASC_1': 'AU.JB',
            'ISO_1': 'NA',
            'NAME_1': 'Jervis Bay Territory',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Territory',
            'VARNAME_1': 'NA',
          },
          'type': 'Polygon',
        },
        {
          'arcs': [
            [[6, 7, 8, 9, 10, 11, 12, -5, 13, 14, 15, 16, 17, 18, 19], [-3]],
            [[20]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'State',
            'CC_1': '1',
            'GID_0': 'AUS',
            'GID_1': 'AUS.5_1',
            'HASC_1': 'AU.NS',
            'ISO_1': 'AU-NSW',
            'NAME_1': 'New South Wales',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'State',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[21, 22, 23, 24, 25]],
            [[26]],
            [[27]],
            [[28]],
            [[29]],
            [[30]],
            [[31]],
            [[32]],
            [[33]],
            [[34]],
            [[35]],
            [[36]],
            [[37]],
            [[38]],
            [[39]],
            [[40]],
            [[41]],
            [[42]],
            [[43]],
            [[44]],
            [[45]],
            [[46]],
            [[47]],
            [[48]],
            [[49]],
            [[50]],
            [[51]],
            [[52]],
            [[53]],
            [[54]],
            [[55]],
            [[56]],
            [[57]],
            [[58]],
            [[59]],
            [[60]],
            [[61]],
            [[62]],
            [[63]],
            [[64]],
            [[65]],
            [[66]],
            [[67]],
            [[68]],
            [[69]],
            [[70]],
            [[71]],
            [[72]],
            [[73]],
            [[74]],
            [[75]],
            [[76]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'Territory',
            'CC_1': '7',
            'GID_0': 'AUS',
            'GID_1': 'AUS.6_1',
            'HASC_1': 'AU.NT',
            'ISO_1': 'AU-NT',
            'NAME_1': 'Northern Territory',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'Territory',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[77, -26, 78, -8]],
            [[79]],
            [[80]],
            [[81]],
            [[82]],
            [[83]],
            [[84]],
            [[85]],
            [[86]],
            [[87]],
            [[88]],
            [[89]],
            [[90]],
            [[91]],
            [[92]],
            [[93]],
            [[94]],
            [[95]],
            [[96]],
            [[97]],
            [[98]],
            [[99]],
            [[100]],
            [[101]],
            [[102]],
            [[103]],
            [[104]],
            [[105]],
            [[106]],
            [[107]],
            [[108]],
            [[109]],
            [[110]],
            [[111]],
            [[112]],
            [[113]],
            [[114]],
            [[115]],
            [[116]],
            [[117]],
            [[118]],
            [[119]],
            [[120]],
            [[121]],
            [[122]],
            [[123]],
            [[124]],
            [[125]],
            [[126]],
            [[127]],
            [[128]],
            [[129]],
            [[130]],
            [[131]],
            [[132]],
            [[133]],
            [[134]],
            [[135]],
            [[136]],
            [[137]],
            [[138]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'State',
            'CC_1': '3',
            'GID_0': 'AUS',
            'GID_1': 'AUS.7_1',
            'HASC_1': 'AU.QL',
            'ISO_1': 'AU-QLD',
            'NAME_1': 'Queensland',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'State',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[139, 140, -22, -78, -7, 141]],
            [[142]],
            [[143]],
            [[144]],
            [[145]],
            [[146]],
            [[147]],
            [[148]],
            [[149]],
            [[150]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'State',
            'CC_1': '4',
            'GID_0': 'AUS',
            'GID_1': 'AUS.8_1',
            'HASC_1': 'AU.SA',
            'ISO_1': 'AU-SA',
            'NAME_1': 'South Australia',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'State',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[151]],
            [[152, 153]],
            [[154]],
            [[155]],
            [
              [
                156,
                157,
                158,
                159,
                160,
                161,
                162,
                163,
                164,
                165,
                166,
                167,
                168,
                169,
                170,
                171,
                172,
                173,
                174,
                175,
                176,
                177,
                178,
                179,
                180,
                181,
                182,
                183,
              ],
            ],
            [[184]],
            [[189, 190]],
            [[191, 192, 193, 194]],
            [[195]],
            [[196]],
            [[197]],
            [[198]],
            [[199]],
            [[200]],
            [[201]],
            [[202]],
            [[205]],
            [[206]],
            [[207]],
            [[208]],
            [[209, 210, 211]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'State',
            'CC_1': '6',
            'GID_0': 'AUS',
            'GID_1': 'AUS.9_1',
            'HASC_1': 'AU.TS',
            'ISO_1': 'AU-TAS',
            'NAME_1': 'Tasmania',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'State',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[212]],
            [[213]],
            [[214, -142, -20, 215]],
            [[216]],
            [[217]],
            [[218]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'State',
            'CC_1': '2',
            'GID_0': 'AUS',
            'GID_1': 'AUS.10_1',
            'HASC_1': 'AU.VI',
            'ISO_1': 'AU-VIC',
            'NAME_1': 'Victoria',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'State',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
        {
          'arcs': [
            [[219]],
            [[220]],
            [[221, 222, 223, -23, -141, 224, 225, 226]],
            [[227]],
            [[228]],
            [[229]],
            [[230]],
            [[231]],
            [[232]],
            [[233]],
            [[234]],
            [[235]],
            [[236]],
            [[237]],
            [[238]],
            [[239]],
            [[240]],
            [[241]],
            [[242]],
            [[243]],
            [[244]],
            [[245]],
            [[246]],
            [[247]],
            [[248]],
            [[249]],
            [[250]],
            [[251]],
            [[252]],
            [[253]],
            [[254]],
            [[255]],
            [[256]],
            [[257]],
            [[258]],
            [[259]],
            [[260]],
            [[261]],
            [[262]],
            [[263]],
            [[264]],
            [[265]],
            [[266]],
            [[267]],
            [[268]],
            [[269]],
            [[270]],
            [[271]],
            [[272]],
            [[273]],
            [[274]],
            [[275]],
            [[276]],
            [[277]],
            [[278]],
            [[279]],
            [[280]],
            [[281]],
            [[282]],
            [[283]],
            [[284]],
            [[285]],
            [[286]],
            [[287]],
            [[288]],
            [[289]],
            [[290]],
          ],
          'properties': {
            'COUNTRY': 'Australia',
            'ENGTYPE_1': 'State',
            'CC_1': '5',
            'GID_0': 'AUS',
            'GID_1': 'AUS.11_1',
            'HASC_1': 'AU.WA',
            'ISO_1': 'AU-WA',
            'NAME_1': 'Western Australia',
            'NL_NAME_1': 'NA',
            'TYPE_1': 'State',
            'VARNAME_1': 'NA',
          },
          'type': 'MultiPolygon',
        },
      ],
      'type': 'GeometryCollection',
    },
  },
  'transform': {
    'scale': [0.00028551947578277047, 0.0003014145489116185],
    'translate': [112.92112700000018, -55.11424299999993],
  },
  'type': 'Topology',
};
