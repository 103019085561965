import { get } from 'lodash';

const basicSort = (
  array: any[],
  property: string,
  direction: 'ascending' | 'descending' = 'ascending'
) => {
  if (!Array.isArray(array)) return array;

  return array.sort((a, b) => {
    const ascending = direction === 'ascending';
    let propertyA = get(a, property);
    let propertyB = get(b, property);
    propertyA =
      typeof propertyA === 'string' ? propertyA.toLowerCase() : propertyA;
    propertyB =
      typeof propertyB === 'string' ? propertyB.toLowerCase() : propertyB;

    const check1 = ascending ? propertyA < propertyB : propertyA > propertyB;
    const check2 = ascending ? propertyA > propertyB : propertyA < propertyB;

    if (check1) {
      return -1;
    }
    if (check2) {
      return 1;
    }
    return 0;
  });
};

export default basicSort;
