import { Typography } from '@material-ui/core';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form-latest';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  @media screen and (min-width: 991px) {
    width: 50%;
  }
`;

const Title = styled(Typography)`
  font-size: 20px !important;
  font-weight: 400 !important;
`;

type DirectorCountProps = {
  directorCount: number;
  error: string;
  handleComplete: (flag: boolean) => void;
  handleChange: (event: { target: { name: string; value: number } }) => void;
};

export const DirectorCount = (props: DirectorCountProps) => {
  const { directorCount, error, handleChange } = props;

  const { control, register, watch } = useForm({
    defaultValues: { corporate_trustee_director_count: directorCount },
  });

  useEffect(() => {
    register('corporate_trustee_director_count');
  }, []);

  const handleChangeDirectorCount = (_, value) => {
    handleChange({
      target: {
        name: 'corporate_trustee_director_count',
        value: value.replace(/[^0-9]+/, '').replace(/^0+/, ''),
      },
    });
  };

  return (
    <Container>
      <Title>Director details for corporate trustee</Title>
      <Typography>
        Please provide the No. of directors for company legal entity name.{' '}
      </Typography>
      <RHFBorderedTextField
        control={control}
        name="corporate_trustee_director_count"
        label={'No. of directors'}
        labelShrink={true}
        noWhiteSpaces
        onChange={handleChangeDirectorCount}
        placeholder={'No. of directors'}
        error={error ? { message: error, type: '' } : undefined}
        number_only
      />
    </Container>
  );
};
