import get from 'lodash.get';
import Webhook from 'models/webhooks/Webhook';
import AdminTable from 'modules/shared/components/widgets/interactive/AdminTable';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

class WebhookList extends React.Component {
  componentDidMount() {
    Webhook.loadAll();
  }

  edit(id) {
    browserHistory.push(`/dashboard/connected-apps/webhooks/${id}`);
  }

  render() {
    const { loading, webhooks, options, ...rest } = this.props;

    let loadingElement;
    const actions = {
      edit: this.edit,
    };

    if (loading) {
      loadingElement = <SimpleLoader css_class={'loader_relative'} />;
    }

    return (
      <div>
        <AdminTable
          data={webhooks}
          config={options.columns}
          actions={actions}
          {...rest}
        />
        {loadingElement}
      </div>
    );
  }
}

const defaults = {
  options: {
    columns: [
      {
        key: 'attributes.name',
        label: 'Name',
        type: 'data',
      },
      {
        key: 'attributes.url',
        label: 'URL',
        type: 'data',
      },
      {
        key: 'attributes.api_version',
        label: 'API Version',
        onTransformValue: (value) =>
          get(
            Webhook.API_VERSIONS.find((item) => item.value === value),
            'label'
          ),
        type: 'data',
      },
      {
        actionKey: 'edit',
        key: 'attributes.id',
        label: 'edit',
        type: 'action',
      },
    ],
  },
};

module.exports = connect((state, ownProps) => {
  return {
    loading: state.models.webhooks.loading,
    options: defaults.options,
    webhooks: state.models.webhooks.webhooks,
  };
})(WebhookList);
