import {
  COB_REVIEW_SET_REVIEW_INDEX,
  COB_REVIEW_SUBMIT_DATA_START,
  COB_REVIEW_SUBMIT_DATA_SUCCESS,
  COB_REVIEW_SUBMIT_DATA_ERROR,
  COB_REVIEW_SUBMIT_WATCHTOWER_ERROR,
  COB_REVIEW_SET_DECLARATION_FIRST_NAME,
  COB_REVIEW_SET_DECLARATION_EMAIL,
  COB_REVIEW_SET_DECLARATION_DOB,
  COB_REVIEW_SUBMIT_NETWORK_BREAK,
  COB_REVIEW_ADDRESS_LOOKUP_CLEAR,
  COB_REVIEW_ADDRESS_LOOKUP_START,
  COB_REVIEW_ADDRESS_LOOKUP_SUCCESS,
  COB_REVIEW_ADDRESS_LOOKUP_ERROR,
  COB_REVIEW_SET_ADDRESS,
  COB_REVIEW_SELECT_ADDRESS,
  COB_REVIEW_REDIRECT,
  COB_REVIEW_SET_TERMS_REJECT_REASON_TEXT,
  COB_REVIEW_SET_COMPLETE,
  COB_REVIEW_UPDATE_AUTHORISATION_ERROR,
  COB_REVIEW_UPDATE_AUTHORISATION_START,
  COB_REVIEW_UPDATE_AUTHORISATION_SUCCESS,
  COB_REVIEW_SUPPLIER_TERMS_REDIRECT,
  COB_REVIEW_UPDATE_TRADING_NAMES_ERROR,
  COB_REVIEW_UPDATE_TRADING_NAMES_START,
  COB_REVIEW_UPDATE_TRADING_NAMES_SUCCESS,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_START,
  COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_SUCCESS,
  COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_ERROR,
  COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_START,
  COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_SUCCESS,
  COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_ERROR,
  COB_REVIEW_SUBMIT_APPLICATION_DATA_START,
  COB_REVIEW_SUBMIT_APPLICATION_DATA_SUCCESS,
  COB_REVIEW_SUBMIT_APPLICATION_DATA_ERROR,
  ENTITY_UPDATE_START,
  ENTITY_UPDATE_SUCCESS,
  ENTITY_UPDATE_ERROR,
  USER_UPDATE_IN_START_APPLICATION_START,
  USER_UPDATE_IN_START_APPLICATION_SUCCESS,
  USER_UPDATE_IN_START_APPLICATION_ERROR,
} from '../constants/review';

/* Default state for supplier onboarding */
var cob_review_default = {
  in_review: false,
  review_container_index: null,
  declaration_first_name: '',
  declaration_last_name: '',
  declaration_dob: {
    month: 0,
    day: '',
    year: '',
  },
  terms_reject_reason_text: '',

  address_list: {},
  address: '',
  declaration_dpid: null,
  address_loading: false,
  completed: {},
  websitebutton_saving: false,
  application_start_submitting: false,
  isSubmissionBlockedByWatchtower: false,
};

export function consumerOnboardingReviewReducer(
  state = cob_review_default,
  action
) {
  switch (action.type) {
    case COB_REVIEW_REDIRECT:
      return { ...state, in_review: true };

    case COB_REVIEW_SET_ADDRESS:
      return { ...state, address: action.payload, declaration_dpid: null };

    case COB_REVIEW_ADDRESS_LOOKUP_CLEAR:
      return { ...state, address_list: {}, declaration_dpid: null };

    case COB_REVIEW_ADDRESS_LOOKUP_START:
      return { ...state, address_loading: true, declaration_dpid: null };

    case COB_REVIEW_ADDRESS_LOOKUP_ERROR:
      return { ...state, address_loading: false };

    case COB_REVIEW_ADDRESS_LOOKUP_SUCCESS:
      return { ...state, address_loading: false, address_list: action.payload };

    case COB_REVIEW_SELECT_ADDRESS:
      return {
        ...state,
        declaration_dpid: action.payload,
        address_loading: false,
        address_list: {},
        address: state.address_list[action.payload],
      };

    case COB_REVIEW_SET_TERMS_REJECT_REASON_TEXT:
      return { ...state, terms_reject_reason_text: action.payload };
    case COB_REVIEW_SET_REVIEW_INDEX:
      return { ...state, review_container_index: action.payload };
    case COB_REVIEW_SET_DECLARATION_FIRST_NAME:
      return { ...state, declaration_first_name: action.payload };
    case COB_REVIEW_SET_DECLARATION_EMAIL:
      return { ...state, declaration_last_name: action.payload };
    case COB_REVIEW_SET_DECLARATION_DOB:
      return { ...state, declaration_dob: action.payload };
    case COB_REVIEW_SUBMIT_DATA_START:
      return { ...state, submitting: true };
    case COB_REVIEW_SUBMIT_DATA_SUCCESS:
      return { ...state, submitting: false };
    case COB_REVIEW_SUBMIT_DATA_ERROR:
      return { ...state, submitting: false };
    case COB_REVIEW_SUBMIT_NETWORK_BREAK:
      return { ...state, submitting: false };
    case COB_REVIEW_UPDATE_TRADING_NAMES_ERROR:
      return { ...state, submitting: false };
    case COB_REVIEW_SET_COMPLETE:
      var section_state = new Object();
      section_state[action.payload.section] = action.payload.state;
      return { ...state, completed: { ...state.completed, ...section_state } };
    case COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_START:
      return { ...state, websitebutton_saving: true };
    case COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_SUCCESS:
      return { ...state, websitebutton_saving: false };
    case COB_WEBSITEBUTTON_CREATE_FOR_NEW_CONSUMER_ERROR:
      return { ...state, websitebutton_saving: false };
    case COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_START:
      return { ...state, websitebutton_saving: true };
    case COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_SUCCESS:
      return { ...state };
    case COB_WEBSITEBUTTON_CREATE_FOR_CONSUMER_ERROR:
      return { ...state, websitebutton_saving: false };
    case COB_REVIEW_SUBMIT_APPLICATION_DATA_START:
      return { ...state, application_submitting: true };
    case COB_REVIEW_SUBMIT_APPLICATION_DATA_SUCCESS:
      return { ...state, application_submitting: false };
    case COB_REVIEW_SUBMIT_APPLICATION_DATA_ERROR:
      return { ...state, application_submitting: false };
    case COB_REVIEW_SUBMIT_WATCHTOWER_ERROR:
      return {
        ...state,
        isSubmissionBlockedByWatchtower: action.payload,
        submitting: false,
      };
    case ENTITY_UPDATE_START:
    case USER_UPDATE_IN_START_APPLICATION_START:
      return { ...state, application_start_submitting: true };
    case ENTITY_UPDATE_SUCCESS:
    case ENTITY_UPDATE_ERROR:
    case USER_UPDATE_IN_START_APPLICATION_ERROR:
      return { ...state, application_start_submitting: false };
    case COB_REVIEW_UPDATE_AUTHORISATION_ERROR:
      return { ...state, application_submitting: true };
    case COB_REVIEW_UPDATE_AUTHORISATION_START:
      return { ...state, application_submitting: false };
    case COB_REVIEW_UPDATE_AUTHORISATION_SUCCESS:
      return { ...state, application_submitting: false };
    case COB_REVIEW_SUPPLIER_TERMS_REDIRECT:
      return { ...state };
    default:
      return { ...state };
  }
}
