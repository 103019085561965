import React from 'react';
import moment from 'moment';

import styles from '../../css/CommonReviewBusiness.css';
import historyStyles from './css/History.css';

var CompanyRegistration = createClass({
  render() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details.company;

    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Company Registration</strong>
        </p>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Company Number</th>
              <td>{companyDetails.company_number}</td>
            </tr>
            <tr>
              <th>Company Name</th>
              <td>{companyDetails.company_name}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                {enquiryResult.company_details.company_status.description}
              </td>
            </tr>
            <tr>
              <th>Date Incorporated</th>
              <td>
                {moment(companyDetails.incorporation_date).format(
                  'DD-MMM-YYYY'
                )}
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{companyDetails.body_type_description}</td>
            </tr>
            <tr>
              <th>Constitution Filed</th>
              <td>{companyDetails.constitution_filed ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Listed Company</th>
              <td>{companyDetails.listed_company ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>Number of Shares</th>
              <td>
                {parseInt(
                  companyDetails.total_number_of_shares
                ).toLocaleString()}
              </td>
            </tr>
            <tr>
              <th>Nominal Capital</th>
              <td>{companyDetails.nominal_capital}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },
});

export default CompanyRegistration;
