import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import AuthorisationRequestAwaitingPermissionModel from 'models/AuthorisationRequestAwaitingPermissionModel';
import {
  ActionContent,
  ButtonContainer,
  Content,
  Header,
} from 'modules/request-permission-flow/components/Review/styles';
import Button from 'modules/shared/components/inputs/Button';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form-latest';
import * as yup from 'yup';

const DECISION_FIELD_NAME = 'decision';

type FormValues = {
  [DECISION_FIELD_NAME]: string;
};

const validationSchema = () =>
  yup.object().shape({
    [DECISION_FIELD_NAME]: yup.string().required('Please select a decision.'),
  });

const sanitizeDecision = (decision: string): string => {
  switch (decision.toLowerCase()) {
    case 'yes':
      return 'approved';
    case 'no':
      return 'rejected';
    default:
      return '';
  }
};

const Form = ({
  consumerName,
  requesterContactPhoneNumber,
  requesterEmail,
  requesterFirstName,
  requesterName,
  setDecision,
  supplierName,
  token,
  awaitingPermissionId,
  requesteeEmail,
  applicationId,
  requesteeId,
}: {
  consumerName: string;
  requesterContactPhoneNumber: string;
  requesterEmail: string;
  requesterFirstName: string;
  requesterName: string;
  setDecision: (decision: string) => void;
  supplierName: string;
  token: string;
  awaitingPermissionId: string;
  requesteeEmail: string;
  applicationId: string;
  requesteeId: string;
}): ReactElement => {
  const {
    formState,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: { decision: '' },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  register(DECISION_FIELD_NAME);

  const { errors } = formState;

  const onSubmit = async (data) => {
    const status = sanitizeDecision(data.decision);

    if (status === '') {
      setError(DECISION_FIELD_NAME, {
        message: 'Please select a decision.',
        type: 'invalid_decision',
      });
      return;
    }

    const result = await AuthorisationRequestAwaitingPermissionModel.saveDecision(
      {
        status,
        token,
      }
    );

    if (result === 'ok') {
      setDecision(data.decision);
      trackDecision(status);
    } else {
      setError(DECISION_FIELD_NAME, {
        message: 'Something went wrong, please refresh and try again.',
        type: 'save_error',
      });
    }
  };

  const decisionError = get(errors, 'decision.message');

  const trackDecision = (decision) => {
    if (!decision) return;

    mixpanel.track(`Request Permission - ${decision}`, {
      applicationId,
      awaitingPermissionId,
      distinct_id: requesteeId,
      requesteeEmail,
      requesterEmail,
    });
  };

  return (
    <>
      <Header>Permissioning</Header>

      <Content className="content">
        <p>Hello,</p>
        <p>
          {requesterName}, of {consumerName} with details: {requesterEmail} and{' '}
          {requesterContactPhoneNumber}, is applying for a trade account with{' '}
          {supplierName}.
        </p>
        <p>
          You are listed as being a signatory for {consumerName}. As such we
          require your permission for {requesterFirstName} to complete this
          credit application.
        </p>
        <p>
          If you require further details please contact {requesterFirstName}{' '}
          directly. If this looks suspicious, please contact {supplierName} and
          report.
        </p>
      </Content>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ActionContent>
          <div className="mb-4 mt-6">
            I give permission for {requesterName} to apply for trade credit with{' '}
            {supplierName}.
          </div>
          <Radiobox
            name={DECISION_FIELD_NAME}
            label=""
            radioList={['Yes', 'No']}
            value={watch(DECISION_FIELD_NAME)}
            handleChange={(event) =>
              setValue(DECISION_FIELD_NAME, get(event, 'target.value'))
            }
            error={decisionError}
          />
        </ActionContent>
        <ButtonContainer>
          <Button text="Confirm" type="submit" />
        </ButtonContainer>
      </form>
    </>
  );
};

export default Form;
