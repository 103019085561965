import React from 'react';

import styles from './css/ConnectionDetails.css';

import ConnectionKeyContact from './ConnectionKeyContact';
import ConnectionLogo from './ConnectionLogo';
import ConnectionIntegrations from './ConnectionIntegrations';
import ConnectionInformation from './ConnectionInformation';

import Button from '../../shared/components/inputs/Button';
import DateString from '../../shared/components/widgets/static/DateString';

var ConnectionDetails = createClass({
  render: function() {
    const { connection, role, isSupplier, consumerRelationType } = this.props;
    let created_at,
      final_notes,
      monthly_credit_limit,
      day_of_invoice,
      day_invoice_is_due,
      account_type;
    if (connection.attributes.created_at) {
      created_at = (
        <div>
          <h2 className={styles.heading}>Connected since</h2>
          <p className={styles.details_large}>
            <DateString value={connection.attributes.created_at} />
          </p>
        </div>
      );
    }

    account_type = (
      <div>
        <h2 className={styles.heading}>Account type</h2>
        <p className={styles.details_large}>
          {connection.application.attributes.application_type}
        </p>
      </div>
    );

    if (isSupplier && connection.attributes.final_notes) {
      final_notes = (
        <div>
          <h2 className={styles.heading}>Acceptance message</h2>
          <p className={styles.details}>{connection.attributes.final_notes}</p>
        </div>
      );
    }

    if (connection.attributes.monthly_credit_limit) {
      monthly_credit_limit = (
        <div>
          <h2 className={styles.heading}>Credit limit</h2>
          <p className={styles.details_large}>
            {connection.application.attributes.consumer_region} $
            {connection.attributes.monthly_credit_limit.toLocaleString()}
          </p>
        </div>
      );
    }

    if (connection.attributes.day_of_invoice) {
      day_of_invoice = (
        <div>
          <h2 className={styles.heading}>Day of invoice</h2>
          <p className={styles.details_large}>
            {connection.attributes.day_of_invoice}
          </p>
        </div>
      );
    }

    if (connection.attributes.day_invoice_is_due) {
      day_invoice_is_due = (
        <div>
          <h2 className={styles.heading}>Day invoice is due</h2>
          <p className={styles.details_large}>
            {connection.attributes.day_invoice_is_due}
          </p>
        </div>
      );
    }

    return (
      <div className={styles.row}>
        <div className={styles.aside}>
          <ConnectionLogo connection={connection} />
          <ConnectionKeyContact role={role} connection={connection} />
          <ConnectionIntegrations role={role} connection={connection} />
        </div>
        <div className={styles.tab_content}>
          {created_at}
          {account_type}
          {final_notes}
          {monthly_credit_limit}
          {day_of_invoice}
          {day_invoice_is_due}
          <ConnectionInformation
            connection={connection}
            isSupplier={isSupplier}
            consumerRelationType={consumerRelationType}
          />
        </div>
      </div>
    );
  },
});

module.exports = ConnectionDetails;
