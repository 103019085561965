import get from 'lodash.get';
import PaymentModel from 'models/PaymentModel';
import BankNumber from 'modules/new-applications/components/application-sections/DirectDebit/BankNumber';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React from 'react';
import isBlank from 'utils/isBlank';
import DirectDebitToggle from './DirectDebit/DirectDebitToggle';
import { FEATURE_FLAGS } from 'conf';
import RecordHistory from '../RecordHistory';

function DirectDebitAccount(props) {
  const { index, payment, region } = props;

  return (
    <FixedContent header={`Acc Signature ${index}`}>
      <GridContent>
        <LabeledContent label="Name" content={payment.fullName} />
        <LabeledContent label="Email" content={payment.email} />

        <LabeledContent
          label="Account to be debited"
          content={payment.debitAccountName}
        />
        <BankNumber bankNumber={payment.bankNumber} region={region} />
        <LabeledContent
          label="Bank account number"
          content={payment.bankAccountNumber}
        />
        <LabeledContent
          label="Agreed to be a signatory"
          content={payment.approvedString}
        />
      </GridContent>
    </FixedContent>
  );
}

function DirectDebitEmptyPlaceholder(props) {
  const { region } = props;
  const payment = new PaymentModel();

  return <DirectDebitAccount payment={payment} index={1} region={region} />;
}

export default function DirectDebit(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const {
    directDebitIsMandatoryHistories,
    paperlessAddonRule,
    payments,
    region,
  } = application;

  const content = payments.map((payment, i) => (
    <DirectDebitAccount
      key={`direct-debit-account-${i + 1}`}
      index={i + 1}
      payment={payment}
      region={region}
    />
  ));

  if (isBlank(content)) {
    content.push(
      <DirectDebitEmptyPlaceholder
        key="direct-debit-empty-placeholder"
        region={region}
      />
    );
  }

  return (
    <ContentWithFooter
      footer={
        FEATURE_FLAGS.FEATURE_FLAG_DIRECT_DEBIT_TOGGLE ? (
          <RecordHistory histories={directDebitIsMandatoryHistories} />
        ) : (
          get(paperlessAddonRule, 'formattedRuleLabel')
        )
      }
    >
      {FEATURE_FLAGS.FEATURE_FLAG_DIRECT_DEBIT_TOGGLE && (
        <FixedContent withBottomSeparator={false} header="Direct Debit">
          <DirectDebitToggle
            paperlessAddonRule={paperlessAddonRule}
            directDebitIsMandatory={application.directDebitIsMandatory}
            application={application}
            currentUser={currentUser}
            onFetchApplicationRecord={onFetchApplicationRecord}
          />
        </FixedContent>
      )}
      {content}
    </ContentWithFooter>
  );
}
