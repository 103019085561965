import React, { ReactElement, ReactNode } from 'react';

import PopperTooltip from '../../widgets/interactive/PopperToolTip';
import {
  Description,
  Header,
  Heading,
  CustomStepHeading,
  Wrapper,
} from './styles';

interface Props {
  children: ReactNode;
  description?: ReactNode | string;
  header?: string | ReactNode;
  listType?: 'none' | 'number';
  tooltip?: {
    element: ReactNode;
    extraComponent: ReactNode;
  };
  visible?: boolean;
  customStep?: string | boolean;
  isOptional?: boolean;
}

function ContentContainer({
  children,
  description,
  header,
  listType = 'number',
  visible = true,
  tooltip,
  customStep,
  isOptional,
}: Props): ReactElement | null {
  if (!visible) {
    return null;
  }

  const HeaderComponent = (props) =>
    customStep ? <CustomStepHeading {...props} /> : <Heading {...props} />;

  return (
    <Wrapper>
      <Header>
        <HeaderComponent header={header} listType={listType}>
          {header && `${customStep ? customStep + ' ' : ''}${header}`}
          {isOptional && <span className="optional"> (optional)</span>}
          {tooltip && (
            <span>
              {' '}
              <PopperTooltip {...tooltip}>{tooltip.element}</PopperTooltip>
            </span>
          )}
        </HeaderComponent>
        {description && <Description>{description}</Description>}
      </Header>
      {children}
    </Wrapper>
  );
}

export default ContentContainer;
