import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';

enum CATEGORY_NUMBER {
  CATEGORY_0 = 0,
  CATEGORY_1 = 1,
  CATEGORY_2 = 2,
  CATEGORY_3 = 3,
  CATEGORY_4 = 4,
  CATEGORY_6 = 6,
  CATEGORY_5 = 5,
}

const CATEGORY_RULES = {
  [CATEGORY_NUMBER.CATEGORY_0]: {
    requiresFrontFaceImage: false,
    requiresGovernmentVerification: false,
    requiresIdentificationImage: false,
    requiresIdentificationSection: true,
    requiresProofOfLife: false,
  },
  [CATEGORY_NUMBER.CATEGORY_1]: {
    requiresFrontFaceImage: false,
    requiresGovernmentVerification: false,
    requiresIdentificationImage: true,
    requiresIdentificationSection: true,
    requiresProofOfLife: false,
  },
  [CATEGORY_NUMBER.CATEGORY_2]: {
    requiresFrontFaceImage: false,
    requiresGovernmentVerification: true,
    requiresIdentificationImage: false,
    requiresIdentificationSection: true,
    requiresProofOfLife: false,
  },
  [CATEGORY_NUMBER.CATEGORY_3]: {
    requiresFrontFaceImage: false,
    requiresGovernmentVerification: true,
    requiresIdentificationImage: true,
    requiresIdentificationSection: true,
    requiresProofOfLife: false,
  },
  [CATEGORY_NUMBER.CATEGORY_4]: {
    requiresFrontFaceImage: true,
    requiresGovernmentVerification: true,
    requiresIdentificationImage: true,
    requiresIdentificationSection: true,
    requiresProofOfLife: false,
  },
  [CATEGORY_NUMBER.CATEGORY_5]: {
    requiresFrontFaceImage: false,
    requiresGovernmentVerification: false,
    requiresIdentificationImage: true,
    requiresIdentificationSection: true,
    requiresProofOfLife: false,
    requiresProofOfAddress: true,
  },
  [CATEGORY_NUMBER.CATEGORY_6]: {
    requiresFrontFaceImage: false,
    requiresGovernmentVerification: true,
    requiresIdentificationImage: true,
    requiresIdentificationSection: true,
    requiresProofOfLife: true,
  },
};

export const DEFAULT_ANTI_FRAUD_CATEGORY = FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0
  ? CATEGORY_NUMBER.CATEGORY_0
  : CATEGORY_NUMBER.CATEGORY_1;

export const getCategoriesRequiringFrontFaceImage = (): string[] =>
  Object.keys(CATEGORY_RULES).filter(
    (rule) => CATEGORY_RULES[rule].requiresFrontFaceImage
  );

export const getCategoriesRequiringProofOfLife = (): string[] =>
  Object.keys(CATEGORY_RULES).filter(
    (rule) => CATEGORY_RULES[rule].requiresProofOfLife
  );

export default class AntiFraudCategoryRule {
  categoryNumber: CATEGORY_NUMBER;

  constructor(categoryNumber: CATEGORY_NUMBER) {
    this.categoryNumber = categoryNumber;
  }

  get rulesForCategory() {
    return CATEGORY_RULES[this.categoryNumber];
  }

  get isIdentificationImageRequired(): boolean {
    return get(this, 'rulesForCategory.requiresIdentificationImage', false);
  }

  get isFrontFaceImageRequired(): boolean {
    return get(this, 'rulesForCategory.requiresFrontFaceImage', false);
  }

  get isProofOfLifeRequired(): boolean {
    return get(this, 'rulesForCategory.requiresProofOfLife', false);
  }

  get isGovernmentVerificationRequired(): boolean {
    return get(this, 'rulesForCategory.requiresGovernmentVerification', false);
  }
}
