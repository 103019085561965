import React from 'react';

import Abstract from './components/Abstract';
import Authorisation from './components/Authorisation';
import Complete from './components/Complete';
import Terms from './components/Terms';

export function componentFactory(section, props = {}) {
  switch (section) {
    case 'abstract':
      return <Abstract {...props} />;
    case 'authorisation':
      return <Authorisation {...props} />;
    case 'complete':
      return <Complete {...props} />;
    case 'terms':
      return <Terms {...props} />;
  }
}
