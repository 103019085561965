import get from 'lodash.get';
import { ContentContainer } from 'modules/addons/components//DigitalOnboarding';
import commonStyles from 'modules/addons/components/css/PPSRRegistration/EditComponent.css';
import FormCheckBox from 'modules/shared/components/inputs/FormCheckBox';
import React from 'react';

export default function AUFunctions(props) {
  const { control, errors, register, versionConfig } = props;

  return (
    <ContentContainer header="Step 2: PPSR Functions.">
      <div className={commonStyles.sub_header}>
        There is a cost to registering and maintaining PPSR via the Australian
        PPSR. Please turn on the functions you would like via your 1Centre
        account.
      </div>
      <div>
        <FormCheckBox
          id="functionRegisterZeroToSevenYears"
          label="0 - 7 years registration ($10.00)"
          control={control}
          value={false}
          register={register}
          defaultValue={get(
            versionConfig,
            'functions.register_zero_to_seven_years',
            false
          )}
          error={get(errors, 'functionRegisterZeroToSevenYears.message', '')}
        />
        <FormCheckBox
          id="functionRegisterEightToTwentyFive"
          label="8 - 25 years registration ($29.00)"
          control={control}
          value={false}
          register={register}
          defaultValue={get(
            versionConfig,
            'functions.register_eight_to_twenty_five',
            false
          )}
        />
        <FormCheckBox
          id="functionRegisterNoEndTime"
          label="No stated end time registration ($119.00)"
          control={control}
          value={false}
          register={register}
          defaultValue={get(
            versionConfig,
            'functions.register_no_end_time',
            false
          )}
        />
        <FormCheckBox
          id="functionRenew"
          label="Renew ($10.00 for 0 - 7 years / $29 for 8 - 25 years"
          control={control}
          value={false}
          register={register}
          defaultValue={get(versionConfig, 'functions.renew', false)}
        />
        <FormCheckBox
          id="financingStatementAutoRenew"
          label="Auto renewal ($10.00 for 0 - 7 years / $29 for 8 - 25 years)"
          control={control}
          value={false}
          register={register}
          defaultValue={get(versionConfig, 'functions.auto_renew', false)}
        />
        <FormCheckBox
          id="functionModify"
          label="Modify $1 +gst"
          control={control}
          value={false}
          register={register}
          defaultValue={get(versionConfig, 'functions.modify', false)}
        />
        <FormCheckBox
          id="functionDischarge"
          label="Discharge $1 + gst"
          control={control}
          value={false}
          register={register}
          defaultValue={get(versionConfig, 'functions.discharge', false)}
        />
      </div>
    </ContentContainer>
  );
}
