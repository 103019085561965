import IAccountTypeOptions from 'types/accountTypeOptions';
import getAccountTypeName from 'utils/getAccountTypeName';

const getAccountTypeCheckboxOptions = (): IAccountTypeOptions => {
  return {
    cash: { label: getAccountTypeName('cash') },
    credit: { label: getAccountTypeName('credit') },
  };
};

export default getAccountTypeCheckboxOptions;
