import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Alert } from '@material-ui/lab';
import * as Sentry from '@sentry/browser';
import UserModel from 'models/UserModel';
import AdminTitle from 'modules/shared/components/widgets/static/AdminTitle';
import { Paper, Typography } from 'mui-latest';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  bulkReassignKeyContacts,
  loadUserApplicationsCount,
  loadUsersByEntity,
} from '../../../actions';
import BulkReassignModal from '../../BulkReassignModal';
import styles from '../../css/UpdateUser.css';
import { BULK_REASSIGN_ERROR_MESSAGE } from '../constants';
import useBulkReassignForm from './useBulkReassignForm';

function BulkReassign(props) {
  const {
    dispatch,
    total_applications,
    user_id,
    currentUser,
    userProcessing,
    userFullName,
    bulkReassignModalOpen,
    setBulkReassignModalOpen,
    updateBeforeBulkReassign,
    shouldUpdateUser,
    loading,
    setShouldBulkReassign,
  } = props;

  const [selectedUser, setSelectedUser] = useState<null | any>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<null | any>(null);
  const [processing, setProcessing] = useState(userProcessing);
  const [error, setError] = useState<string | null>(null);

  const { component: bulkReassignForm } = useBulkReassignForm({
    ...props,
    assignKeyContact,
    assignKeySupplier,
  });

  useEffect(() => {
    dispatch(loadUserApplicationsCount(user_id));
    dispatch(loadUsersByEntity());
  }, []);

  function errorCallback(error) {
    setError(BULK_REASSIGN_ERROR_MESSAGE);
    Sentry.captureException(error);
  }

  function successCallback() {
    setProcessing(true);
    setError(null);
    setShouldBulkReassign(false);
  }

  function updateAssignKeyContact() {
    const newSupplierId =
      (selectedSupplier && selectedSupplier.value) ||
      currentUser.currentEntity.id;

    dispatch(
      bulkReassignKeyContacts({
        errorCallback,
        initiatorId: currentUser.id,
        newSupplierId: newSupplierId,
        newUserId: selectedUser.value,
        oldUserId: user_id,
        successCallback,
      })
    );

    handleModalDismiss();
  }

  function assignKeyContact(userId) {
    setSelectedUser(userId);
  }

  function assignKeySupplier(supplierId) {
    setSelectedSupplier(supplierId);
  }

  function handleModalDismiss() {
    setBulkReassignModalOpen(false);
  }

  const hasApplications = total_applications > 0;
  const isPlural = total_applications > 1;

  const handleBulkReassignConfirm = () => {
    setBulkReassignModalOpen(false);
    if (shouldUpdateUser) {
      updateBeforeBulkReassign(updateAssignKeyContact);
    } else {
      updateAssignKeyContact();
    }
  };

  if (loading) return null;

  const selectedUserLabel = selectedUser ? selectedUser.label : '';

  return (
    <div className={styles.row}>
      {bulkReassignModalOpen && (
        <BulkReassignModal
          onCancel={handleModalDismiss}
          onConfirm={handleBulkReassignConfirm}
          selectedUser={selectedUserLabel}
          currentUser={userFullName}
        />
      )}
      <div className={styles.columns}>
        <AdminTitle text="Bulk reassign" />
        <Typography
          variant="body1"
          className={styles.bulk_reassign_description}
        >
          You can bulk reassign applications to another user from the same or a
          different tier. Once the bulk reassign is completed, you will be able
          to remove this user if required.
        </Typography>
        <Typography
          variant="body1"
          className={styles.bulk_reassign_key_contact}
        >
          This user is a key contact of {total_applications} application
          {isPlural && 's'}.
        </Typography>
        {error && <p className="has-text-danger">{error}</p>}
        {hasApplications && bulkReassignForm}
        {processing && (
          <Paper className={styles.bulk_reassign_success} elevation={3}>
            <CheckCircleIcon className={styles.bulk_reassign_success_icon} />
            <Typography variant="body1">Bulk reassign complete.</Typography>
          </Paper>
        )}
      </div>
    </div>
  );
}

export default connect((state) => {
  const users = state.manage_users.user_list_for_assign_key_contact.map(
    (user) => {
      return { label: user.attributes.full_name, value: user.id };
    }
  );

  return {
    applications: state.manage_users.user_applications,
    connections: state.manage_users.user_connections,
    currentUser: UserModel.fromCurrentUser(state.current_user),
    loading:
      state.manage_users.user_applications_loading ||
      state.manage_users.user_connections_loading,
    supplierId: state.current_user.current_entity.id,
    total_applications: state.manage_users.total_user_applications,
    userProcessing: state.manage_users.user_processing,
    users,
  };
})(BulkReassign);
