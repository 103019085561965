import React, { Component } from 'react';
import { displayEmpty, formatMoney } from 'utils/formatting';

import { CCR_PURPOSE } from '../constants';
import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { extractText } from '../ConsumerRiskScoreComponent';
import get from 'lodash.get';
import isPresent from 'utils/isPresent';
import objectToArray from 'utils/objectToArray';
import { stringToSimpleDate } from 'utils/dateFormatter';

export default class Enquiries extends Component {
  get enquiries() {
    return objectToArray(get(this.props.creditReport, 'enquiries.enquiry'));
  }

  get authorisedAgentEnquiries() {
    return objectToArray(get(this.props.creditReport, 'aaes.aae'));
  }

  renderEnquiries() {
    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={7}>Enquiries</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Organisation</th>
            <th>Cust. Ref.</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Obligation</th>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody>{this.renderEnquiryRows()}</tbody>
      </table>
    );
  }

  renderEnquiryRows() {
    const enquiries = this.enquiries.map((enquiry, i) =>
      this.renderEnquiry(enquiry, i)
    );

    if (isPresent(enquiries)) {
      return enquiries;
    }

    return (
      <tr>
        <td colSpan={7} className={commonStyles.centered}>
          No enquiries recorded.
        </td>
      </tr>
    );
  }

  renderEnquiry(enquiry, i) {
    return (
      <tr key={`enquiry-${i}`}>
        <td>{stringToSimpleDate(extractText(enquiry, 'date'))}</td>
        <td>{extractText(enquiry, 'organisation', '-')}</td>
        <td>{extractText(enquiry, 'unique_customer_reference', '-')}</td>
        <td>{get(enquiry, 'account_type.value_desc', '-')}</td>
        <td>
          ${formatMoney(parseFloat(extractText(enquiry, 'amount', 0)), 0)}
        </td>
        <td>{get(enquiry, 'credit_obligation.value_desc', '-')}</td>
        <td>
          {displayEmpty(CCR_PURPOSE[extractText(enquiry, 'purpose')], '-')}
        </td>
      </tr>
    );
  }

  renderAuthorisedAgentEnquiries() {
    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={7}>Authorised agent enquiries</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Organisation</th>
            <th>Cust. Ref.</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Obligation</th>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody>{this.renderAuthorisedAgentEnquiryRows()}</tbody>
      </table>
    );
  }

  renderAuthorisedAgentEnquiryRows() {
    const aaes = this.authorisedAgentEnquiries.map((aae, i) =>
      this.renderAuthorisedAgentEnquiry(aae, i)
    );

    if (isPresent(aaes)) {
      return aaes;
    }

    return (
      <tr>
        <td colSpan={7} className={commonStyles.centered}>
          No authorised agent enquiries recorded.
        </td>
      </tr>
    );
  }

  renderAuthorisedAgentEnquiry(aae, i) {
    return (
      <tr key={`aae-${i}`}>
        <td>{stringToSimpleDate(extractText(aae, 'enquiry_date'))}</td>
        <td>{extractText(aae, 'organisation', '-')}</td>
        <td>{extractText(aae, 'unique_customer_reference', '-')}</td>
        <td>{get(aae, 'account_type.value_desc', '-')}</td>
        <td>
          ${formatMoney(parseFloat(extractText(aae, 'enquiry_amount', 0)), 0)}
        </td>
        <td>{get(aae, 'credit_obligation.value_desc', '-')}</td>
        <td>{displayEmpty(CCR_PURPOSE[extractText(aae, 'purpose')], '-')}</td>
      </tr>
    );
  }

  render() {
    return (
      <div>
        {this.renderEnquiries()}
        {this.renderAuthorisedAgentEnquiries()}
      </div>
    );
  }
}
