import get from 'lodash.get';
import EntityModel from 'models/EntityModel';
import UserModel from 'models/UserModel';
import { UserContext } from 'modules/consumer-onboarding/v2/hooks/UserContext';
import PasswordForm from 'modules/consumer-onboarding/v2/Verification/PasswordForm';
import StartApplicationForm from 'modules/consumer-onboarding/v2/Verification/StartApplicationForm';
import VerificationCode from 'modules/consumer-onboarding/v2/Verification/VerificationCode';
import React, { ReactElement, useContext, useState } from 'react';
import { connect } from 'react-redux';

type TSections =
  | 'passwordFormSection'
  | 'startApplicationSection'
  | 'verificationSection';

const FORM_SEQUENCE = {
  passwordFormSection: PasswordForm,
  startApplicationSection: StartApplicationForm,
  verificationSection: VerificationCode,
};

const determineNextSection = ({
  currentSection,
  payload = {},
  setCurrentSection,
  setVerifiedStatePayload,
}: {
  currentSection: TSections;
  payload?: any;
  setCurrentSection: (section: TSections) => void;
  setVerifiedStatePayload: ({
    registrationStatus,
    registrationToken,
    userId,
  }: {
    registrationStatus: string;
    registrationToken: string;
    userId: string;
  }) => void;
}) => {
  const {
    registration_status: registrationStatus,
    registration_token: registrationToken,
    user_id: userId,
    verified,
  } = payload;

  switch (currentSection) {
    case 'passwordFormSection':
      // Do nothing, this should redirect to the Application details
      break;
    case 'startApplicationSection':
      if (verified && userId) {
        setCurrentSection('passwordFormSection');
        setVerifiedStatePayload({
          registrationStatus,
          registrationToken,
          userId,
        });
      } else {
        setCurrentSection('verificationSection');
      }

      break;
    case 'verificationSection':
      setCurrentSection('passwordFormSection');

      break;
    default:
      throw `Invalid section: ${currentSection}`;
  }
};

const determinePreviousSection = ({
  currentSection,
  setCurrentSection,
}: {
  currentSection: TSections;
  setCurrentSection: (section: TSections) => void;
}) => {
  switch (currentSection) {
    case 'passwordFormSection':
    case 'verificationSection':
      setCurrentSection('startApplicationSection');
      break;
    case 'startApplicationSection':
      // Do nothing as this is the first section
      break;
    default:
      throw `Invalid section: ${currentSection}`;
  }
};

const useCurrentFormState = () => {
  const [currentSection, setCurrentSection] = useState<TSections>(
    'startApplicationSection'
  );
  const userState = useContext(UserContext);
  const { setVerifiedStatePayload } = userState;

  const CurrentFormComponent = FORM_SEQUENCE[currentSection];

  const onNext = (payload: any) => {
    determineNextSection({
      currentSection,
      payload,
      setCurrentSection,
      setVerifiedStatePayload,
    });
  };

  const onBack = () => {
    determinePreviousSection({ currentSection, setCurrentSection });
  };

  return { CurrentFormComponent, onBack, onNext };
};

const Verification = (props): ReactElement => {
  const { CurrentFormComponent, onBack, onNext } = useCurrentFormState();
  const { location, router, supplier } = props;

  const {
    query: { application_id: applicationId },
  } = location;

  return (
    <div className="columns is-mobile is-tablet is-centered">
      <CurrentFormComponent
        applicationId={applicationId}
        onBack={onBack}
        onNext={onNext}
        router={router}
        supplier={supplier}
      />
    </div>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});

  return {
    currentUser: UserModel.fromCurrentUser(currentUser),
    supplier: new EntityModel(get(state, 'cob_section.supplier', {})),
  };
})(Verification);
