import HelpIcon from '@material-ui/icons/Help';
import {
  InlineIcon,
  SectionDescription,
  SectionTitle,
  SectionWrapper,
} from 'modules/consumer-invite/v2/ConsumerInvite/FullPage/styles';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React, { ReactElement } from 'react';

export const OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME =
  'overrideRequiresCreditCheck';
export const OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME =
  'overrideRequiresGuarantees';
export const OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME =
  'overrideRequiresTradeReferences';

interface IApplicationRules {
  requiresCreditCheck: boolean;
  requiresGuarantees: boolean;
  requiresTradeReferences: boolean;
}

interface IOverriddenRules {
  overrideRequiresCreditCheckValue: boolean;
  overrideRequiresGuaranteesValue: boolean;
  overrideRequiresTradeReferencesValue: boolean;
}

const getAreRulesOverridden = ({
  applicationRules,
  overriddenRules,
}: {
  applicationRules: IApplicationRules;
  overriddenRules: IOverriddenRules;
}): boolean => {
  const {
    requiresCreditCheck,
    requiresGuarantees,
    requiresTradeReferences,
  } = applicationRules;

  const {
    overrideRequiresCreditCheckValue,
    overrideRequiresGuaranteesValue,
    overrideRequiresTradeReferencesValue,
  } = overriddenRules;

  const isRequiresCreditCheckOverridden =
    requiresCreditCheck !== overrideRequiresCreditCheckValue;
  const isRequiresGuaranteesOverridden =
    requiresGuarantees !== overrideRequiresGuaranteesValue;
  const isRequiresTradeReferencesOverridden =
    requiresTradeReferences !== overrideRequiresTradeReferencesValue;

  return (
    isRequiresCreditCheckOverridden ||
    isRequiresGuaranteesOverridden ||
    isRequiresTradeReferencesOverridden
  );
};

const ConfirmChecks = (props): ReactElement => {
  const {
    onToggleCheckBox,
    overrideRequiresCreditCheckValue,
    overrideRequiresGuaranteesValue,
    overrideRequiresTradeReferencesValue,
    requiresCreditCheck,
    requiresGuarantees,
    requiresTradeReferences,
  } = props;

  const areRulesOverridden = getAreRulesOverridden({
    applicationRules: {
      requiresCreditCheck,
      requiresGuarantees,
      requiresTradeReferences,
    },
    overriddenRules: {
      overrideRequiresCreditCheckValue,
      overrideRequiresGuaranteesValue,
      overrideRequiresTradeReferencesValue,
    },
  });

  return (
    <SectionWrapper>
      <SectionTitle>Confirm application checks</SectionTitle>
      <SectionDescription>
        <p>
          You have been given permission to turn off these rules. Untick to
          remove these question/s for this application.
          <span className="is-hidden-touch">
            <PopperTooltip
              title={
                'Unticking these will make your application differ from your standard application profile. It may cause your auto-decisioning rulesets to defer this application to a manual review.'
              }
              placement={'top'}
            >
              <InlineIcon>
                <HelpIcon />
              </InlineIcon>
            </PopperTooltip>
          </span>
        </p>
      </SectionDescription>

      <div className="columns is-multiline is-mobile">
        {requiresCreditCheck && (
          <div className="column is-full">
            <SquareCheckbox
              checkboxId={OVERRIDE_REQUIRES_CREDIT_CHECK_FIELD_NAME}
              checked={overrideRequiresCreditCheckValue}
              handleChange={onToggleCheckBox}
              label="Credit checks"
            />
          </div>
        )}
        {requiresGuarantees && (
          <div className="column is-full">
            <SquareCheckbox
              checkboxId={OVERRIDE_REQUIRES_GUARANTEES_FIELD_NAME}
              checked={overrideRequiresGuaranteesValue}
              handleChange={onToggleCheckBox}
              label="Guarantors"
            />
          </div>
        )}
        {requiresTradeReferences && (
          <div className="column is-full">
            <SquareCheckbox
              checkboxId={OVERRIDE_REQUIRES_TRADE_REFERENCES_FIELD_NAME}
              checked={overrideRequiresTradeReferencesValue}
              handleChange={onToggleCheckBox}
              label="Trade references"
            />
          </div>
        )}
      </div>
    </SectionWrapper>
  );
};

export default ConfirmChecks;
