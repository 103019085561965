import DefaultSwitch from '@material-ui/core/Switch';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form-latest';

import { Wrapper } from './styles';

type Props = {
  control: any;
  name: string;
  disabled: boolean;
};

function Switch(props: Props): ReactElement {
  const { disabled } = props;

  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => (
        <Wrapper>
          <DefaultSwitch
            color="primary"
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            disabled={disabled}
          />
          {value ? 'On' : 'Off'}
        </Wrapper>
      )}
    />
  );
}

export default Switch;
