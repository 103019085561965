import { SET, RESET } from './constants';

var initialState = {
  filterType: null,
  userFilter: null,
  zoom: null,
  centre: null,
  sidebarOpen: true,
  sidebarDocked: true,
  users: [],
  entities: [],
  contactCandidates: [],
  usersLoading: false,
  connectionsLoading: false,
  entitiesLoading: false,
};

export function mappingReducer(state = initialState, action) {
  switch (action.type) {
    case SET:
      return { ...state, ...action.payload };
    case RESET:
      return { ...initialState };
    default:
      return { ...state };
  }
}
