import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import SideMenu from 'containers/next-gen/components/layouts/side-menu/SideMenu';
import RepChannelContent from './rep_channel/RepChannelContent';
import WebsiteChannelContent from './website_channel/WebsiteChannelContent';
import LocationChannelContent from './location_channel/LocationChannelContent';
import SalesChannelOverview from 'modules/addons/sales_channels/sales_channel_overview/SalesChannelOverview';
import CallCentreChannelContent from './callcentre_channel/CallCentreChannelContent';
import { IMenuItem } from 'containers/next-gen/types/types';
import api from 'api';
import { loadOverviewStatus } from '../actions';

const TYPE_WITH_SALES_CHANNEL_OVERVIEW = ['Headquarter', 'Region'];
const TYPE_WITH_CALL_CENTRE_CHANNEL_OVERVIEW = [
  'Headquarter',
  'Region',
  'Branch',
];

const fetchSalesChannels = async ({
  accessToken,
  entityId,
  successCallback,
  errorCallback,
}: {
  accessToken: string;
  entityId: string;
  successCallback?: (response: any) => void;
  errorCallback?: (error: any) => void;
}) => {
  const salesChannelsAPI = api('sales_channels', accessToken);

  try {
    const response = await salesChannelsAPI.fetchOverview(entityId);
    if (successCallback) successCallback(response);
  } catch (error) {
    if (errorCallback) errorCallback(error);
    console.error(error);
  }
};

const SalesChannels = ({
  callCentreChannelStatus,
  currentUser,
  dispatch,
  entityId,
  isCallCentreChannelVisible,
  isSalesChannelOverviewVisible,
  locationChannelStatus,
  websiteChannelStatus,
}: {
  callCentreChannelStatus: boolean;
  currentUser: any;
  dispatch: any;
  entityId: string;
  isCallCentreChannelVisible: boolean;
  isSalesChannelOverviewVisible: boolean;
  locationChannelStatus: boolean;
  websiteChannelStatus: boolean;
}) => {
  React.useEffect(() => {
    dispatch(loadOverviewStatus('START'));

    if (!isSalesChannelOverviewVisible) {
      fetchSalesChannels({
        accessToken: currentUser.accessToken,
        entityId,
        successCallback: (response) =>
          dispatch(loadOverviewStatus('SUCCESS', response, entityId)),
        errorCallback: (error) => dispatch('ERROR', error),
      });
    }
  }, []);

  const menuItems: IMenuItem[] = [
    {
      id: 'rep-channel',
      content: <RepChannelContent />,
      status: 'blue',
      statusName: 'On',
      title: 'Rep channel',
    },
    {
      id: 'website-channel',
      content: <WebsiteChannelContent />,
      status: websiteChannelStatus ? 'blue' : 'off',
      statusName: websiteChannelStatus ? 'On' : 'Off',
      title: 'Website channel',
    },
    {
      id: 'location-channel',
      content: <LocationChannelContent />,
      status: locationChannelStatus ? 'blue' : 'off',
      statusName: locationChannelStatus ? 'On' : 'Off',
      title: 'Location channel',
    },
  ];

  if (isSalesChannelOverviewVisible) {
    menuItems.unshift({
      content: <SalesChannelOverview />,
      indicator: false,
      isIndicatorVisible: false,
      title: 'Sales channels overview',
      id: 'sales-channels-overview',
    });
  }

  if (isCallCentreChannelVisible) {
    menuItems.push({
      content: <CallCentreChannelContent />,
      status: callCentreChannelStatus ? 'blue' : 'off',
      statusName: callCentreChannelStatus ? 'On' : 'Off',
      title: 'Call Centre channel',
      id: 'call-centre-overview',
    });
  }

  return <SideMenu menuItems={menuItems} />;
};

export default connect((state) => {
  //const repChannelStatus = get(state.add_ons.rep_channel)
  const websiteChannelStatus = get(
    state,
    'add_ons.website_button.attributes.active',
    false
  );
  const locationChannelStatus = get(
    state,
    'add_ons.qr_code.attributes.active',
    false
  );
  const callCentreChannelStatus = get(
    state,
    'add_ons.call_centre.attributes.active',
    false
  );
  const currentUser = get(state, 'current_user', {});
  const entityId = get(currentUser, 'current_entity.id');

  const supplierType = get(
    state,
    'current_user.current_entity.attributes.type',
    ''
  );

  const isSalesChannelOverviewVisible = TYPE_WITH_SALES_CHANNEL_OVERVIEW.includes(
    supplierType
  );

  const isCallCentreChannelVisible = TYPE_WITH_CALL_CENTRE_CHANNEL_OVERVIEW.includes(
    supplierType
  );

  return {
    callCentreChannelStatus,
    currentUser,
    entityId,
    isCallCentreChannelVisible,
    isSalesChannelOverviewVisible,
    locationChannelStatus,
    websiteChannelStatus,
  };
})(SalesChannels);
