import React, { ReactElement } from 'react';
import { IconWrapper } from '../styles';

const QuestionIcon = ({ isActive }: { isActive: boolean }): ReactElement => {
  const iconClass = ['icon', 'is-clickable', 'inactive'];

  if (isActive) {
    iconClass.pop();
  }

  if (!isActive && !iconClass.includes('inactive')) {
    iconClass.push('inactive');
  }

  return (
    <IconWrapper className={iconClass.join(' ')}>
      <i className="fas fa-question-circle" />
    </IconWrapper>
  );
};

export default QuestionIcon;
