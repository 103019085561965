import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React, { ReactElement } from 'react';

type handleArgs = {
  value: boolean;
};

interface Props {
  handleCheck: (args: handleArgs) => void;
  isChecked: boolean;
  userType: string;
}

function OverrideLimitAmount({
  handleCheck,
  isChecked,
  userType,
}: Props): ReactElement | null {
  if (userType === 'standard') {
    return null;
  }

  const item = {
    description:
      'Allow this user to override the limit amount requested from the applicant.',
    id: 'user_can_override_limit',
    label: 'Override limit amount',
    type: 'user_can_override_limit',
  };

  const onClick = () => handleCheck({ value: !isChecked, ...item });

  return (
    <Checkbox
      checkboxId="user_can_override_limit"
      checked={isChecked}
      handleChange={onClick}
      label={item.label}
      description={item.description}
    />
  );
}

export default OverrideLimitAmount;
