import React, { Component } from 'react';

import Defaults from './Defaults';
import Enquiries from './Enquiries';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { extractText } from '../ConsumerRiskScoreComponent';
import { formatMoney } from 'utils/formatting';
import get from 'lodash.get';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import objectToArray from 'utils/objectToArray';

const SMALL_AND_MEDIUM_LOANS = [
  'SmallAmountLoan',
  'MediumAmountLoan',
  'SubPrime',
];

function findMeasurement(measurements, description) {
  return (
    measurements.find(
      (measurement) => measurement.measurement_group_desc === description
    ) || {}
  );
}

function findNumericValue(measurement, code, defaultValue) {
  const numericValues = objectToArray(measurement.numeric_value);
  const value = parseInt(
    (numericValues.find((numericValue) => numericValue.code === code) || {})
      .text
  );

  if (isPresent(defaultValue)) {
    return value || defaultValue;
  }

  return value;
}

function findDecimalValue(measurement, code) {
  const decimalValues = objectToArray(measurement.decimal_value);
  const value = (
    decimalValues.find((decimalValue) => decimalValue.code === code) || {}
  ).text;

  return parseFloat(value) || 0;
}

function findTextValue(measurement, code, defaultValue) {
  const textValues = objectToArray(measurement.text_value);
  const value = (textValues.find((textValue) => textValue.code === code) || {})
    .text;

  if (isPresent(defaultValue)) {
    return value || defaultValue;
  }

  return value;
}

function findDerivationByTimePeriod(derivations, timePeriod) {
  return (
    derivations.find(
      (derivation) =>
        extractText(derivation, 'dimensions.duration_value') === timePeriod
    ) || {}
  );
}

export function getDerivationByIndustry(derivations, industry) {
  return objectToArray(derivations).filter(
    (derivation) =>
      extractText(derivation, 'dimensions.text_enum_value') === industry
  );
}

export function getMeasurementDetailsByTimePeriod(derivations, timePeriod) {
  const derivation = findDerivationByTimePeriod(derivations, timePeriod);
  const industryName = get(
    derivation,
    'dimensions.text_enum_value.value_desc',
    '-'
  );
  const measurements = objectToArray(derivation.measurements);

  // Credit Impairment - Defaults, Adverse, Judgements, Bankruptcies, Summonses
  const creditImpairment = findMeasurement(measurements, 'Credit Impairment');
  const numberOfDefaults = findNumericValue(creditImpairment, 'MSR345');
  const numberOfUnpaidDefaults = findNumericValue(creditImpairment, 'MSR346');
  const defaultAmount = findDecimalValue(creditImpairment, 'MSR348');
  const numberOfAdverse = findNumericValue(creditImpairment, 'MSR351', '-');
  const numberOfJudgements = findNumericValue(creditImpairment, 'MSR352', '-');
  const numberOfBankruptcies = findNumericValue(
    creditImpairment,
    'MSR353',
    '-'
  );
  const numberOfSummonses = findNumericValue(creditImpairment, 'MSR354', '-');

  let formattedDefaultAmount = '-';
  if (isPresent(defaultAmount) && defaultAmount !== 0) {
    formattedDefaultAmount = `$${formatMoney(defaultAmount, 2)}`;
  }

  let numberOfPaidDefaults;
  if (isPresent(numberOfDefaults) || isPresent(numberOfUnpaidDefaults)) {
    numberOfPaidDefaults =
      (numberOfDefaults || 0) - (numberOfUnpaidDefaults || 0);
  }

  if (isBlank(numberOfPaidDefaults) || numberOfPaidDefaults === 0) {
    numberOfPaidDefaults = '-';
  }

  // Credit Demand - Enquiries
  const enquiries = findMeasurement(measurements, 'Credit Demand');
  const numberOfEnquiries = findNumericValue(enquiries, 'MSR398', '-');
  const timeSinceLastEnquiry = findNumericValue(enquiries, 'VAL400');
  const enquiryAmount = findDecimalValue(enquiries, 'MSR399');

  let formattedEnquiryAmount = '-';
  if (isPresent(enquiryAmount) && enquiryAmount !== 0) {
    formattedEnquiryAmount = `$${formatMoney(enquiryAmount, 2)}`;
  }

  // Credit Holdings - Accounts Opened
  const accountsOpened = findMeasurement(measurements, 'Credit Holdings');
  const numberOfAccountsOpened = findNumericValue(
    accountsOpened,
    'MSR314',
    '-'
  );

  // Credit Exposure - Limit of Open Accounts
  const limitOfOpenAccounts = findMeasurement(measurements, 'Credit Exposure');
  const numberOfLimitOpenAccounts = findNumericValue(
    limitOfOpenAccounts,
    'MSR325',
    '-'
  );

  // Payment Risk - Worst payments status
  const paymentRisks = findMeasurement(measurements, 'Payment Risk');
  const worstPaymentStatus = findTextValue(paymentRisks, 'VAL335', '-');

  return {
    defaultAmount: formattedDefaultAmount,
    enquiryAmount: formattedEnquiryAmount,
    industryName,
    numberOfAccountsOpened,
    numberOfAdverse,
    numberOfBankruptcies,
    numberOfDefaults: numberOfDefaults || '-',
    numberOfEnquiries,
    numberOfJudgements,
    numberOfLimitOpenAccounts,
    numberOfPaidDefaults,
    numberOfSummonses,
    numberOfUnpaidDefaults: numberOfUnpaidDefaults || '-',
    timeSinceLastEnquiry,
    worstPaymentStatus,
  };
}

export default class DefaultsAndEnquiries extends Component {
  get derivations() {
    return objectToArray(get(this.props.creditReport, 'derivations'));
  }

  get industryTypeAndPeriod() {
    return (
      this.derivations.find(
        (derivation) =>
          extractText(derivation, 'derivation_type', '') ===
          'IndustryTypeAndPeriod'
      ) || {}
    );
  }

  get industries() {
    const derivations = objectToArray(
      get(this.industryTypeAndPeriod, 'derivation')
    );
    const industries = derivations.map((derivation) =>
      extractText(derivation, 'dimensions.text_enum_value')
    );

    return [...new Set(industries)];
  }

  renderDefaultAndEnquiriesByIndustries() {
    return this.industries
      .filter((industry) => !SMALL_AND_MEDIUM_LOANS.includes(industry))
      .map((industry, i) =>
        this.renderDefaultAndEnquiriesByIndustry(industry, i)
      );
  }

  renderDefaultAndEnquiriesByIndustry(industry, i) {
    const derivations = getDerivationByIndustry(
      this.industryTypeAndPeriod.derivation,
      industry
    );
    const current = getMeasurementDetailsByTimePeriod(derivations, 'P0D');

    let timeSince = '-';
    if (isPresent(current.timeSinceLastEnquiry)) {
      timeSince = `${current.timeSinceLastEnquiry} months`;
    }

    const last3Months = getMeasurementDetailsByTimePeriod(derivations, 'P3M');
    const last24Months = getMeasurementDetailsByTimePeriod(derivations, 'P24M');

    return (
      <tr key={`industry-${i}`}>
        <td>{current.industryName}</td>
        <td>{current.numberOfDefaults}</td>
        <td>{current.numberOfPaidDefaults}</td>
        <td>{current.numberOfEnquiries}</td>
        <td>{last3Months.enquiryAmount}</td>
        <td>{last24Months.enquiryAmount}</td>
        <td>{timeSince}</td>
      </tr>
    );
  }

  renderDefaultsAndEnquiries() {
    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={7}>Defaults and enquiries</th>
          </tr>
          <tr>
            <th rowSpan={2}></th>
            <th rowSpan={2}>No. of defaults</th>
            <th rowSpan={2}>Paid defaults</th>
            <th rowSpan={2}>No. of enquiries</th>
            <th colSpan={2}>Value of enquiries in</th>
            <th rowSpan={2}>Time since last enquiry</th>
          </tr>
          <tr>
            <th>Last 3 months</th>
            <th>Last 24 months</th>
          </tr>
        </thead>
        <tbody>{this.renderDefaultAndEnquiriesByIndustries()}</tbody>
      </table>
    );
  }

  renderSmallAndMediumAmountLoans() {
    const loans = this.industries
      .filter((industry) => SMALL_AND_MEDIUM_LOANS.includes(industry))
      .map((industry, i) =>
        this.renderSmallAndMediumAmountLoanByIndustry(industry, i)
      );

    if (isBlank(loans)) {
      return;
    }

    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={11}>Small and Medium amount loans</th>
          </tr>
          <tr>
            <th rowSpan={2}>Loan type</th>
            <th colSpan={3}>No. of enquiries made</th>
            <th colSpan={3}>No. of accounts opened</th>
            <th rowSpan={2}>Limit of open accounts</th>
            <th rowSpan={2}>Worst payments status (all accounts)</th>
            <th rowSpan={2}>Total defaults</th>
            <th rowSpan={2}>Paid defaults</th>
          </tr>
          <tr>
            <th>Total</th>
            <th>Within 0-14 days</th>
            <th>Within 0-30 days</th>
            <th>Total</th>
            <th>Within 0-14 days</th>
            <th>Within 0-30 days</th>
          </tr>
        </thead>
        <tbody>{loans}</tbody>
      </table>
    );
  }

  renderSmallAndMediumAmountLoanByIndustry(industry, i) {
    const derivations = getDerivationByIndustry(
      this.industryTypeAndPeriod.derivation,
      industry
    );
    const current = getMeasurementDetailsByTimePeriod(derivations, 'P0D');
    const last14Days = getMeasurementDetailsByTimePeriod(derivations, 'P14D');
    const last30Days = getMeasurementDetailsByTimePeriod(derivations, 'P1M');

    return (
      <tr key={`${industry}-${i}`}>
        <td>{current.industryName}</td>
        <td>{current.numberOfEnquiries}</td>
        <td>{last14Days.numberOfEnquiries}</td>
        <td>{last30Days.numberOfEnquiries}</td>
        <td>{current.numberOfAccountsOpened}</td>
        <td>{last14Days.numberOfAccountsOpened}</td>
        <td>{last30Days.numberOfAccountsOpened}</td>
        <td>{current.numberOfLimitOpenAccounts}</td>
        <td>{current.worstPaymentStatus}</td>
        <td>{current.numberOfDefaults}</td>
        <td>{current.numberOfPaidDefaults}</td>
      </tr>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Defaults and Enquiries"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderDefaultsAndEnquiries()}
        {this.renderSmallAndMediumAmountLoans()}
        <Enquiries {...this.props} />
        <Defaults {...this.props} />
      </ReviewContainer>
    );
  }
}
