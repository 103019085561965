import React, { Component } from 'react';

import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { extractText } from '../ConsumerRiskScoreComponent';
import get from 'lodash.get';
import isPresent from 'utils/isPresent';
import objectToArray from 'utils/objectToArray';
import { stringToSimpleDate } from 'utils/dateFormatter';

export default class FileNotes extends Component {
  get fileNotes() {
    return objectToArray(get(this.props.creditReport, 'file_notes.file_note'));
  }

  renderFileNotes() {
    const fileNotes = this.fileNotes.map((fileNote, i) =>
      this.renderFileNote(fileNote, i)
    );

    if (isPresent(fileNotes)) {
      return fileNotes;
    }

    return (
      <tr>
        <td colSpan={3} className={commonStyles.centered}>
          No file notes recorded.
        </td>
      </tr>
    );
  }

  renderFileNote(fileNote, i) {
    return (
      <tr key={`file-note-${i}`}>
        <td>{stringToSimpleDate(extractText(fileNote, 'date_recorded'))}</td>
        <td>{extractText(fileNote, 'notice_type')}</td>
        <td>{extractText(fileNote, 'text')}</td>
      </tr>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="File notes"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Content</th>
            </tr>
          </thead>
          <tbody>{this.renderFileNotes()}</tbody>
        </table>
      </ReviewContainer>
    );
  }
}
