import { Step, Stepper } from '@material-ui/core';
import useIsMobileScreenState, {
  MEDIA_QUERIES,
} from 'modules/new-applications/hooks/useIsMobileScreenState';
import { connect } from 'react-redux';
import * as React from 'react';

import { Description, DrawerPanel, Header } from '../styles';
import { Connector, StyledStepLabel } from './styles';
import get from 'lodash.get';

interface ISectionStep {
  label: string;
  section?: string;
  show: boolean;
}

const determineActiveStep = ({
  activeSectionSteps,
  currentSection,
}: {
  activeSectionSteps: ISectionStep[];
  currentSection: string;
}) => {
  const totalSteps = activeSectionSteps.length;

  // If the BC is in the "Review" section, then the person has gone through
  // all the steps in the progress bar.
  if (currentSection === 'review') {
    return totalSteps;
  }

  return activeSectionSteps.findIndex(
    (step) => step.section === currentSection
  );
};

export const ProgressPanel = connect((state) => ({
  applicationType: get(
    state,
    'cob_section.application.attributes.application_type'
  ),
}))((props) => {
  const { progressSections = [], currentSection, applicationType } = props;

  function isActiveSection(section) {
    if (section) {
      return Object.keys(progressSections).includes(section);
    }
    return false;
  }

  const sectionSteps = [
    { label: 'Verify email', show: true },
    { label: 'Entity details', section: 'business', show: true },
    // TO DO: Refactor sections to be able to determine trading details
    // { label: "Trading Details", show: entityType === "company"},
    {
      label: 'Trade Reference',
      section: 'trade-reference',
      show: isActiveSection('trade-reference'),
    },
    {
      label: 'Guarantors',
      section: 'guarantors',
      show: isActiveSection('guarantors'),
    },

    { label: 'Limit', section: 'extras', show: applicationType === 'credit' },
    { label: 'Cards', section: 'cards', show: isActiveSection('cards') },
    {
      label: 'Financials',
      section: 'financials',
      show: isActiveSection('financials'),
    },
    {
      label: 'Additional',
      section: 'additional',
      show: isActiveSection('additional'),
    },
    {
      label: 'Direct debit',
      section: 'paperless',
      show: isActiveSection('paperless'),
    },
    { label: 'Authorised applicant', section: 'identity', show: true },
  ];

  const activeSectionSteps = sectionSteps.filter((step) => step.show);

  const activeStep = determineActiveStep({
    activeSectionSteps,
    currentSection,
  });

  const { isMobileScreen } = useIsMobileScreenState(
    MEDIA_QUERIES.max_width_991
  );

  return (
    <DrawerPanel>
      <Header> Application progress</Header>
      {isMobileScreen && (
        <Description>
          Your application is automatically saved up to the latest completed
          page.
        </Description>
      )}
      <Stepper
        activeStep={activeStep}
        connector={<Connector />}
        orientation="vertical"
      >
        {activeSectionSteps.map((step) => (
          <Step key={step.label}>
            <StyledStepLabel>{step.label}</StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    </DrawerPanel>
  );
});
