import '!style-loader!css-loader!./css/ReactSortableTree.css';

import { Typography } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import intersection from 'lodash.intersection';
import {
  deleteEntities,
  loadConfig,
  loadHistory,
  updateConfig,
} from 'modules/addons/entity_structure/actions';
import Modal from 'modules/dashboard/containers/components/Modal';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { loadCurrentEntity } from 'modules/user/actions';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import SortableTree from 'react-sortable-tree';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import Dialog from 'modules/shared/components/v2/Dialog';

import commonStyles from './css/CommonAddOns.css';
import styles from './css/EntityStructure.css';
import EntityLabel from './EnittyLabel';
import { FEATURE_FLAGS } from 'conf';

const UnsavedChanges = (props) => {
  const { isOpen, onCancel, onConfirm, onClose } = props;
  return (
    <Dialog
      title="Unsaved changes"
      isOpen={isOpen}
      onConfirm={onCancel}
      onCancel={onConfirm}
      onClose={onClose}
      confirmButtonText="Save"
      cancelButtonText="Leave page"
      cancelButtonProps={{ red: true }}
    >
      <Typography>
        You have unsaved changes on this page. Please click
      </Typography>
      <Typography>
        save if you'd like to save the changes you've made.
      </Typography>
    </Dialog>
  );
};

const isCustomerViewEnabled = FEATURE_FLAGS.FEATURE_FLAG_1ACCOUNT_CUSTOMER_VIEW;

const DESCRIPTION =
  'All functions are centralised by default. To decentralise a function for a branch please select from the dropdown located at each row of the branch. You can also unselect the functions to reverse the decentralised status. (Tips: Drag and Drop around the handler to make restructure)';

const FUNCTION_OPTIONS = [
  {
    availableIn: ['Region'],
    brief: ' 1CAD',
    label: 'Addons - Auto decisioning (1CAD)',
    value: '1CAD',
  },
  {
    availableIn: ['Region', 'Branch'],
    brief: ' Rep Channel',
    label: 'Addons - Rep Channel',
    value: 'RepChannel',
  },
  {
    availableIn: ['Region', 'Branch'],
    brief: ' Website Channel',
    label: 'Addons - Website Channel',
    value: 'WebsiteButton',
  },
  {
    availableIn: ['Region'],
    brief: ' Location Channel',
    label: 'Addons - Location Channel',
    value: 'Location',
  },
  {
    availableIn: ['Region'],
    brief: ' Call Centre Channel',
    label: 'Addons - Call Centre Channel',
    value: 'CallCentre',
  },
  {
    availableIn: ['Region'],
    brief: ' Direct Debit',
    label: 'Addons - Direct Debit',
    value: 'DirectDebit',
  },
  {
    availableIn: ['Region'],
    brief: ' Financial',
    label: 'Addons - Financial',
    value: 'Financial',
  },
  {
    availableIn: ['Region', 'Branch'],
    brief: ' Additional',
    label: 'Addons - Additional',
    value: 'Additional',
  },
  {
    availableIn: ['Region'],
    brief: ' Card',
    label: 'Addons - Cards',
    value: 'Cards',
  },
  {
    availableIn: ['Region', 'Branch'],
    brief: ' IUF',
    label: 'Addons - Internal Use Fields (IUF)',
    value: 'IUF',
  },
  {
    availableIn: ['Region'],
    brief: ' PPSR',
    label: 'Addons - PPSR',
    value: 'PPSR',
  },
  {
    availableIn: ['Region'],
    brief: ' Alerts',
    label: 'Addons - Alerts',
    value: 'Alerts',
  },
  {
    availableIn: ['Region'],
    brief: ' 1CAH',
    label: 'Profile - Approval hierarchy (1CAH)',
    value: '1CAH',
  },
  {
    availableIn: FEATURE_FLAGS.FEATURE_FLAG_EMAIL_T3
      ? ['Region', 'Branch']
      : ['Region'],
    brief: ' Emails',
    label: 'Profile - Email customisation',
    value: 'Email',
  },
  {
    availableIn: ['Region'],
    brief: ' Colour',
    label: 'Profile - Colour palette',
    value: 'Color',
  },
  {
    availableIn: ['Region'],
    brief: ' Account rules',
    label: 'Profile - Account rules',
    value: 'Credit',
  },
  {
    availableIn: ['Branch', 'Region'],
    brief: ' T&Cs',
    label: 'Profile - Supplier terms',
    value: 'TC',
  },
  {
    availableIn: ['Region', 'Branch'],
    brief: ' Business details',
    label: 'Profile - Business details',
    value: 'Business',
  },
  {
    availableIn: ['Region'],
    brief: ' Invoice',
    label: 'Account - Invoice',
    value: 'Invoice',
  },
].sort((x, y) => (x.label > y.label ? 1 : -1));

const getAvailableOptions = (node) => {
  if (node.type === 'Region') {
    return FUNCTION_OPTIONS;
  }

  return FUNCTION_OPTIONS.filter(
    (option) =>
      option.availableIn.includes(node.type) &&
      node.parentDList.includes(option.value)
  );
};

const getRegions = (treeData) => {
  if (treeData && treeData[0]) {
    return treeData[0].children.map((x) => {
      return { label: x.title, value: x.title };
    });
  }

  return [];
};

const getTotalChildren = (name, item) =>
  `Total ${name}: ${(item.children || []).length}`;

const getTreeItemClassName = (isDirty) => {
  if (isDirty) {
    return 'rst__moveHandle_another';
  }

  return 'rst__moveHandle';
};

const sanitizeTier3DecentralisedFunctions = ({ tier2, tier3 }) => {
  const tier2DecentralisedFunctions = tier2.d_list;
  const tier3DecentralisedFunctions = tier3.d_list;

  return intersection(tier2DecentralisedFunctions, tier3DecentralisedFunctions);
};

const sanitizeTreeDataToSave = (treeData) => {
  const { children: tier1Children, ...tier1Attributes } = treeData[0] || {};
  const sanitizedTier1Children = tier1Children.map((tier2) => {
    // _parentId and _parentDList are not used. They need to be assigned to a
    // variable for the object destructuring.
    const {
      parentId: _parentId,
      parentDList: _parentDList,
      children: tier2Children = [],
      ...tier2Attributes
    } = tier2;

    const sanitizedTier2Children = tier2Children.map((tier3) => {
      // _parentId and _parentDList are not used. They need to be assigned to a
      // variable for the object destructuring.
      const {
        parentId: _parentId,
        parentDList: _parentDList,
        ...tier3Attributes
      } = tier3;

      return tier3Attributes;
    });

    return {
      children: sanitizedTier2Children,
      ...tier2Attributes,
    };
  });

  tier1Attributes.children = sanitizedTier1Children;

  return [tier1Attributes];
};

const EntityStructure = (props) => {
  const [modal, setModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [listData, setListData] = useState([]);
  const [filterMode, setFilterMode] = useState(false);
  const [filter1, setFilter1] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const [locationF, setLocationF] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [height, setHeight] = useState(0);
  const [url, setUrl] = useState(null);
  const [errorRemove, setErrorRemove] = useState(false);
  const [saveHistories, setSaveHistories] = useState([]);
  const [menuTitle, setMenuTitle] = useState('Add');
  const [tierName, setTierName] = useState(null);

  const { dispatch, loadingConfig } = props;

  useEffect(() => {
    dispatch(loadConfig(setBranchTree));
  }, []);

  useEffect(() => {
    if (filter1 || filter2) {
      setFilterMode(true);

      setListData([...filter()]);
    }
    if (!filter1 && (filter2 || []).length === 0) {
      setFilterMode(false);
    }
  }, [filter2, filter1]);

  useEffect(() => {
    const unlisten = browserHistory.listen((location) => {
      const newLocation = `${location.pathname}${location.search}`;
      if (
        menuTitle === 'Save' &&
        !modal &&
        newLocation !== '/dashboard/addons?active_tab=1account'
      ) {
        setModal(true);

        setUrl(newLocation);

        browserHistory.replace('/dashboard/addons?active_tab=1account');
      }
    });
    return unlisten;
  }, [menuTitle, modal]);

  useEffect(() => {
    setLocationF(getRegions(treeData));
    if (treeData && treeData[0]) {
      let h = 0;
      treeData[0].children.forEach((item) => {
        if (item.expanded && item.children) {
          h += item.children.length;
        }
      });

      setHeight((treeData[0].children.length + h + 1) * 62 + 20);
    }
  }, [treeData]);

  const filter = () => {
    const orgTree = [];

    const headquarter = treeData[0];

    const filterFunc = (x) => {
      if (filter1 && !filter2) {
        return x.title === filter1;
      }
      if (filter1 && filter2) {
        return x.title === filter1 && filter2.some((d) => x.d_list.includes(d));
      }
      if (!filter1 && filter2) {
        return filter2.some((d) => x.d_list.includes(d));
      }
    };

    const getT1Subtitle = () => {
      if (
        (headquarter.children || []).filter((x) => filterFunc(x)).length === 0
      ) {
        return null;
      }

      return `Total ${getTierName(null, 'tier_2')}: ${
        (headquarter.children || []).filter((x) => filterFunc(x)).length
      }`;
    };

    const getChildrenSubtitle = (item) => {
      if ((item.children || []).length === 0) {
        return null;
      }

      return getTotalChildren(getTierName(), item);
    };

    const buildChildren = () =>
      (headquarter.children || [])
        .filter((x) => filterFunc(x))
        .map((tier2) => {
          return {
            children: tier2.children,
            d_list: tier2.d_list || [],
            dirty: isDirty(tier2),
            expanded: tier2.expanded,
            id: tier2.id,
            subtitle: getChildrenSubtitle(tier2),
            title: tier2.title,
            type: 'Region',
          };
        });

    orgTree.push({
      children: buildChildren(),
      d_list: headquarter.d_list || [],
      dirty: false,
      expanded: true,
      id: headquarter.id,
      subtitle: getT1Subtitle(),
      title: headquarter.title,
      type: 'Headquarter',
    });

    return orgTree;
  };

  function deleteNodeCallback(entityId, result) {
    if (result === 'ok') {
      const orgTree = updateNodeTree(treeData, (node) => node.id !== entityId);

      setTreeData([...orgTree]);
      setErrorRemove(false);
    } else {
      setErrorRemove(true);
    }
    dispatch(loadHistory(setSaveHistories));
  }

  function deleteNode(node) {
    if (node.id === '') {
      const orgTree = updateNodeTree(
        treeData,
        (x) => x.title !== node.title && x.id !== ''
      );

      setTreeData([...orgTree]);
      setErrorRemove(false);
    } else {
      dispatch(
        deleteEntities({ data: node.id, title: node.title }, deleteNodeCallback)
      );
    }
    setError(null);
  }

  function setBranchTree(entities) {
    const orgTree = updateNodeTree([entities.data], (x) => true, entities.cfg);

    setSaveHistories(entities.history);
    setTierName(entities.cfg);
    setTreeData([...orgTree]);
  }

  function addBranch() {
    const orgTree = treeData;
    orgTree[0].children.push({
      d_list: [],
      dirty: false,
      expanded: true,
      id: '',
      title: '',
      type: 'Region',
    });
    setTreeData([...orgTree]);
    setError(null);
    dispatch(loadHistory(setSaveHistories));
  }

  function redirectToBranch(id) {
    if (menuTitle === 'Save' && !modal) {
      setModal(true);
      setUrl(`BRANCH=${id}`);
      browserHistory.replace('/dashboard/addons?active_tab=1account');
      return;
    }

    dispatch(loadCurrentEntity(id, false));
    window.location.href = '/dashboard/home';
  }

  function updateNode(node, value) {
    node.dirty = value && value.length > 0;
    node.d_list = value;
    const orgTree = updateNodeTree(treeData);

    setTreeData([...orgTree]);
  }

  function renderGoToButton(node, edit = false, readOnly = false) {
    const availableOptions = getAvailableOptions(node);

    switch (node.type) {
      case 'Branch':
      case 'Region':
        return (
          <Fragment>
            <EntityLabel node={node} />
            <div className={styles.container}>
              {edit && (
                <div className={styles.edit}>
                  <BorderedTextField
                    name="branch"
                    placeholder="Please enter a name"
                    error={error}
                    onChange={(v) => {
                      node.title = v.target.value;
                    }}
                    onBlur={(v) => {
                      if (isBlank(v.target.value)) {
                        setMenuTitle('Add');
                        setError('Please enter a name');
                      } else {
                        setMenuTitle('Save');
                        setError(null);
                      }
                      setTreeData([...treeData]);
                    }}
                    autoFocus
                    customProps={{
                      outlineError: error,
                      startAdornment: <span />,
                      withBottomMargin: false,
                    }}
                  />
                </div>
              )}
              {!edit && (
                <div className={styles.dropdown}>
                  <SimpleMultiSelectDropdown
                    onChange={(target) => {
                      updateNode(node, target.value);
                      setMenuTitle('Save');
                    }}
                    multiple
                    options={availableOptions}
                    disabled={readOnly || isBlank(availableOptions)}
                    value={node.d_list || []}
                    placeholder="Functionality"
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      case 'Regular':
        return <Button grey disabled text="to be approved" />;
      default:
        return '';
    }
  }

  function onSave() {
    setIsLoading(true);

    const sanitizedTreeData = sanitizeTreeDataToSave(treeData);

    dispatch(
      updateConfig({ data: sanitizedTreeData }, [
        loadConfig((entities) => {
          setBranchTree(entities);
          setMenuTitle('Add');
          setIsLoading(false);
        }),
      ])
    );
  }

  const cancelNavigateAway = () => {
    setModal(false);
    onSave();
  };

  const confirmNavigateAway = () => {
    setModal(false);
    if (url.includes('BRANCH')) {
      const id = url.split('=')[1];
      dispatch(
        loadCurrentEntity(id, false, () => {
          window.location.href = '/dashboard/home';
        })
      );
    } else {
      browserHistory.push(url, { force: true });
    }
  };

  const closeNavigateAwayModal = () => {
    setModal(false);
    setUrl(null);
  };

  function checkRemoveError() {
    return errorRemove ? (
      <Modal
        onClose={() => setErrorRemove(false)}
        content={
          <span className="has-text-weight-normal">
            As you have applications in this Branch, they need to be reallocated
            to another. Please go to your Sales pipeline tab in this Branch
            click on the application that needs to be reallocated to another
            branch. Once you’re done you can then delete this Branch.
          </span>
        }
        footer={<React.Fragment></React.Fragment>}
      />
    ) : null;
  }

  function isDirty(entity) {
    return (entity.d_list || []).length > 0;
  }

  const getTierName = (nominatedName = null, level = 'tier_3') => {
    const defaults = {
      tier_1: 'HQ',
      tier_2: 'region',
      tier_3: 'branch',
    };

    if (nominatedName) {
      return nominatedName[level] ? nominatedName[level] : defaults[level];
    }

    return tierName && tierName[level] ? tierName[level] : defaults[level];
  };

  function updateNodeTree(
    treeData,
    filterFunc = () => true,
    nominatedName = null
  ) {
    const orgTree = [];
    const headquarter = treeData[0];

    const getT1Subtitle = () => {
      if ((headquarter.children || []).length === 0) {
        return null;
      }

      return getTotalChildren(
        getTierName(nominatedName, 'tier_2'),
        headquarter
      );
    };

    const getT2Subtitle = (item) => {
      if ((item.children || []).length === 0) {
        return null;
      }

      return getTotalChildren(getTierName(nominatedName), item);
    };

    const buildT3 = (item) =>
      (item.children || []).filter(filterFunc).map((tier3) => {
        const tier3Functions = sanitizeTier3DecentralisedFunctions({
          tier2: item,
          tier3,
        });

        tier3.d_list = tier3Functions;

        return {
          d_list: tier3.d_list || [],
          dirty: isDirty(tier3),
          id: tier3.id,
          parentDList: item.d_list,
          parentId: item.id,
          title: tier3.title,
          type: 'Branch',
        };
      });

    const buildT2 = () =>
      (headquarter.children || []).filter(filterFunc).map((tier2) => {
        return {
          children: buildT3(tier2),
          d_list: tier2.d_list || [],
          dirty: isDirty(tier2),
          expanded: tier2.expanded,
          id: tier2.id,
          parentId: headquarter.id,
          subtitle: getT2Subtitle(tier2),
          title: tier2.title,
          type: 'Region',
        };
      });

    orgTree.push({
      children: buildT2(),
      d_list: headquarter.d_list || [],
      dirty: false,
      expanded: true,
      id: headquarter.id,
      subtitle: getT1Subtitle(),
      title: headquarter.title,
      type: 'Headquarter',
    });

    return orgTree;
  }

  const deleteAction = ({ isVisible, node, path }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <a
        className={`delete is-medium ${styles.delete_20}`}
        onClick={() => {
          setShowDelete(false);
          deleteNode(node);
        }}
      />
    );
  };

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <UnsavedChanges
        isOpen={modal}
        onConfirm={confirmNavigateAway}
        onCancel={cancelNavigateAway}
        onClose={closeNavigateAwayModal}
      />
      {checkRemoveError()}
      {isCustomerViewEnabled && DESCRIPTION}
      <div
        className={`${commonStyles.row} ${
          isCustomerViewEnabled && styles.wrapper
        }`}
      >
        <div className={commonStyles.block_wide}>
          {!isCustomerViewEnabled && (
            <div className={commonStyles.header_container}>
              <span>
                <span className={commonStyles.header}>1Account</span>
              </span>
            </div>
          )}
          <div
            className={
              isCustomerViewEnabled
                ? styles.entity_structure_container_new
                : styles.entity_structure_container
            }
          >
            <div className="is-size-normal mb-6">
              {!isCustomerViewEnabled && DESCRIPTION}

              <div className="container columns my-3">
                <span className="pt-5 mx-3">Tier name</span>
                <div className="is-3 column">
                  <BorderedTextField
                    label="Tier 1"
                    InputLabelProps={{ shrink: true }}
                    placeholder="e.g. Support office/HQ"
                    value={tierName ? tierName['tier_1'] : null}
                    onChange={(event) => {
                      setTierName({
                        ...tierName,
                        tier_1: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="is-3 column">
                  <BorderedTextField
                    label="Tier 2"
                    placeholder="e.g. State/Region"
                    InputLabelProps={{ shrink: true }}
                    value={tierName ? tierName['tier_2'] : null}
                    onChange={(event) => {
                      setTierName({
                        ...tierName,
                        tier_2: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="is-3 column">
                  <BorderedTextField
                    label="Tier 3"
                    placeholder="e.g. Branch/Store"
                    InputLabelProps={{ shrink: true }}
                    value={tierName ? tierName['tier_3'] : null}
                    onChange={(event) => {
                      setTierName({
                        ...tierName,
                        tier_3: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="is-1 column">
                  <button
                    className={`mx-5 button is-size-normal has-text-weight-medium is-primary is-rounded ${styles.w_85}`}
                    onClick={() => {
                      setTierName({ ...tierName });
                      dispatch(
                        updateConfig({ tier_name: tierName }, [
                          loadConfig(setBranchTree),
                        ])
                      );
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>

            <hr className={styles.hr} />
            <div className="container columns">
              <span className="pt-5 mx-3">Filtered by</span>
              <div className="is-3 column">
                <BorderedAutocomplete
                  textFieldProps={{
                    disabled: false,
                    label: 'Tier',
                    name: 'filterLocation',
                  }}
                  id="filterLocation"
                  name="filterLocation"
                  options={locationF}
                  onChange={(_, target, reason) => {
                    if (target) {
                      setFilter1(target.value);
                    }
                    if (reason === 'clear') {
                      setFilter1(null);
                    }
                  }}
                />
              </div>

              <div className="is-3 column">
                <SimpleMultiSelectDropdown
                  onChange={(target) => {
                    if (target) {
                      setFilter2(target.value);
                    }
                  }}
                  multiple
                  options={FUNCTION_OPTIONS}
                  disabled={false}
                  value={filter2 || []}
                  clearFunc={() => setFilter2(null)}
                  placeholder="Functionality"
                />
              </div>
            </div>
            {filterMode && (
              <div style={{ height: 800 }}>
                <SortableTree
                  treeData={[...listData]}
                  onChange={(orgTree) => setListData([...orgTree])}
                  generateNodeProps={({ node }) => {
                    return {
                      buttons: [renderGoToButton(node, false, true)],
                      itemClassName: getTreeItemClassName(node.dirty),
                    };
                  }}
                />
              </div>
            )}
            {loadingConfig && isBlank(treeData) && (
              <Typography variant="h2">
                <Skeleton animation="wave" />
                <div style={{ paddingLeft: '2rem' }}>
                  <Skeleton animation="wave" />
                </div>
                <div style={{ paddingLeft: '2rem' }}>
                  <Skeleton animation="wave" />
                </div>
              </Typography>
            )}
            {height !== 0 && !filterMode && (
              <div style={{ height: `${height}px` }}>
                <SortableTree
                  treeData={[...treeData]}
                  maxDepth={3}
                  onChange={(orgTree) => {
                    const updatedOrgTree = updateNodeTree(orgTree);
                    setTreeData([...updatedOrgTree]);
                    setMenuTitle('Save');
                  }}
                  canNodeHaveChildren={(node) => node.type !== 'Branch'}
                  canDrag={(e) => e.node.type !== 'Headquarter'}
                  canDrop={(e) => e.nextPath.length > 1}
                  generateNodeProps={({ node, path }) => {
                    const buttons = renderGoToButton(node, node.title === '');

                    const options = {
                      buttons: [buttons],
                      hasEndStaff: deleteAction({
                        isVisible:
                          showDelete &&
                          (node.type === 'Branch' ||
                            (node.type === 'Region' &&
                              (node.children || []).length === 0)),
                        node,
                        path,
                      }),
                      itemClassName: getTreeItemClassName(node.dirty),
                    };

                    if (isPresent(node.id)) {
                      options.onClick = (e) => {
                        if (e._dispatchListeners.length === 1) {
                          redirectToBranch(node.id);
                        }
                      };
                    }

                    return options;
                  }}
                />
              </div>
            )}
            {!filterMode && isPresent(treeData) && (
              <div className="container pl-6 ">
                <Button
                  handleClick={menuTitle === 'Add' ? addBranch : onSave}
                  disabled={error}
                  text={menuTitle}
                  loading={isLoading || loadingConfig}
                />

                <button
                  className={`mx-5 button is-size-normal has-text-weight-medium is-danger is-outlined is-rounded ${styles.w_85}`}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  Remove
                </button>
              </div>
            )}
            {saveHistories && saveHistories.length > 0 && (
              <div className="mt-6">
                <ContentWithFooter
                  footer={saveHistories.map((item, index) => (
                    <div key={`h-${index}`}>{item}</div>
                  ))}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default connect((state) => {
  const loadingConfig = state.entity_structure.loadingConfig;

  return {
    branches: state.add_ons.branches,
    currentUser: state.current_user,
    loadingConfig,
    loading_branches: state.add_ons.loading_branches,
  };
})(EntityStructure);
