import api from '../../api';
import {
  NOTIFICATIONS_LOAD_NOTIFICATIONS_ERROR,
  NOTIFICATIONS_LOAD_NOTIFICATIONS_START,
  NOTIFICATIONS_LOAD_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_MARK_ACTIONED_ERROR,
  NOTIFICATIONS_MARK_ACTIONED_START,
  NOTIFICATIONS_MARK_ACTIONED_SUCCESS,
  NOTIFICATIONS_PUBLISH_MESSAGE_ERROR,
  NOTIFICATIONS_PUBLISH_MESSAGE_START,
  NOTIFICATIONS_PUBLISH_MESSAGE_SUCCESS,
} from './constants';

export function publishMessage(attributes) {
  return (dispatch, getState) => {
    dispatch({
      payload: attributes,
      type: NOTIFICATIONS_PUBLISH_MESSAGE_START,
    });

    const messages = api(
      'messages',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    messages.createMessage(
      attributes,
      (result) => {
        dispatch({
          payload: result.data.data,
          type: NOTIFICATIONS_PUBLISH_MESSAGE_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          payload: error,
          type: NOTIFICATIONS_PUBLISH_MESSAGE_ERROR,
        });
      }
    );
  };
}

export function markNotificationActioned(id) {
  return (dispatch, getState) => {
    dispatch({
      payload: id,
      type: NOTIFICATIONS_MARK_ACTIONED_START,
    });

    const notifications = api(
      'notifications',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    notifications.markActioned(
      id,
      (result) => {
        dispatch({
          type: NOTIFICATIONS_MARK_ACTIONED_SUCCESS,
        });
        dispatch(loadNotifications());
      },
      (error) => {
        dispatch({
          payload: error,
          type: NOTIFICATIONS_MARK_ACTIONED_ERROR,
        });
      }
    );
  };
}

export function loadNotifications(options) {
  return (dispatch, getState) => {
    if (getState().current_user.access_token) {
      dispatch({
        type: NOTIFICATIONS_LOAD_NOTIFICATIONS_START,
      });

      const notifications = api(
        'notifications',
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
        { headers: { 'Content-Type': 'application/json' }, version: '/v2' }
      );
      let data = [];
      const userId = getState().current_user.data.data.id;

      const initParams = {
        'filters[user_id]': userId,
        'page[size]': 100,
        sort: '-created_at',
      };

      const params = options
        ? { ...initParams, ...options }
        : { ...initParams };

      notifications.getNotifications(
        (result) => {
          if (result.data.data.length) {
            data = result.data.data;
          }

          dispatch({
            payload: data,
            type: NOTIFICATIONS_LOAD_NOTIFICATIONS_SUCCESS,
          });
        },
        (error) => {
          dispatch({
            payload: error,
            type: NOTIFICATIONS_LOAD_NOTIFICATIONS_ERROR,
          });
        },
        {
          params,
        }
      );
    }
  };
}

export function clearNotifications() {
  return (dispatch, getState) => {
    const notifications = api(
      'notifications',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    notifications.clear(
      (result) => {
        dispatch({
          type: NOTIFICATIONS_MARK_ACTIONED_SUCCESS,
        });
        dispatch(loadNotifications());
      },
      (error) => {
        dispatch({
          payload: error,
          type: NOTIFICATIONS_MARK_ACTIONED_ERROR,
        });
      }
    );
  };
}
