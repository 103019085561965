import React, { FC } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import styles from 'modules/shared/components/widgets/interactive/css/MulitSelectDropdown.css';
import Switch2 from 'modules/shared/components/widgets/interactive/Switch2';
import { OptionType, Row } from '../types';
import ListItem from 'mui-latest/ListItem';

type OptionProps = {
  multiple: boolean;
  rows: Row[];
  onClick: (value: string) => void;
  onToggleMain: (checked: boolean, optionValue: string) => void;
  mainValue: string | null;
  hasMainOption: boolean;
  option: OptionType;
};

export const Option: FC<OptionProps> = (props) => {
  const {
    multiple,
    rows,
    onClick,
    onToggleMain,
    mainValue,
    hasMainOption,
    option,
  } = props;

  const currentRow = rows.find((row) => row.value === option.value) || {
    selected: false,
    main: false,
    label: '',
    value: '',
    description: '',
  };

  const handleClick = () => onClick(option.value);

  return (
    <ListItem onClick={handleClick}>
      {multiple && <Checkbox checked={!!currentRow.selected} />}
      <ListItemText
        className={currentRow.main ? styles.main_option : ''}
        primary={currentRow.label}
        secondary={currentRow.description}
      />

      {currentRow.selected && hasMainOption && (
        <div>
          <Switch2
            label="Main Option"
            checked={mainValue === currentRow.value}
            callback={(e) => onToggleMain(e, currentRow.value)}
            prevent="true"
          />
        </div>
      )}
    </ListItem>
  );
};
