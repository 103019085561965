/* eslint-disable max-lines */
import Input from '@material-ui/core/Input';
import debounce from 'debounce';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import LeadModel from 'models/LeadModel';
import UserModel from 'models/UserModel';
import {
  loadInternalUseFieldsAddon,
  loadCustomLeadConfiguration,
} from 'modules/addons/actions';
import { loadUsers } from 'modules/profile/actions';
import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import { MobileStatisticsBlock } from 'modules/reporting/components/Reporting';
import useFilterState from 'modules/reporting/hooks/useFilterState';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import {
  isFeatureEditEnabled,
  isHeadquarter,
} from 'modules/shared/helpers/headquarterDetect';
import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { capitalizeSentence, displayEmpty } from 'utils/formatting';
import TableListSkeleton from 'modules/shared/components/v2/Skeletons/TableListSkeleton';

import {
  loadLeadReport,
  loadLeads,
  loadLocalStorage,
  reset,
  resetLeadForm,
  set,
  setLeadFormValue,
  setLocation,
  updateLeadName,
} from '../actions';
import CreateLead from './CreateLead';
import styles from './css/Leads.css';
import LeadBarGraph from './LeadBarGraph';
import {
  DashboardPageContainer,
  PipelinePageHeaderComponents,
} from 'modules/shared/components';

const DEBOUNCE_INTERVAL = 500;

const LEAD_STATISTICS = [
  { color: 'blue', key: 'total_count', label: 'Started', tab: 'total' },
  {
    color: 'grey',
    key: 'in_progress_count',
    label: 'Incomplete',
    tab: 'in_progress',
  },
  {
    color: 'blue',
    key: 'submitted_count',
    label: 'Complete',
    tab: 'submitted',
  },
  {
    color: 'green',
    key: 'loaded_in_system_count',
    label: 'Loaded in system',
    tab: 'loaded_in_system',
  },
  {
    color: 'medium-grey',
    key: 'archived_count',
    label: 'Archive',
    tab: 'archived',
  },
];

function LeadBlock(props) {
  const { color, isActive, label, setActiveTab, tab, value } = props;
  const onClick = () => setActiveTab(tab);

  return (
    <NumApplicationsBlock
      color={color}
      title={label}
      value={value}
      active={isActive}
      isCompact={true}
      onClick={onClick}
    />
  );
}

function LeadBlocks(props) {
  const { activeTab, data, setActiveTab } = props;

  const blocks = LEAD_STATISTICS.map((block) => {
    const { color, key, label, tab } = block;
    return (
      <LeadBlock
        key={`lead-block-${tab}`}
        color={color}
        label={label}
        value={data[key]}
        isActive={activeTab === tab}
        tab={tab}
        setActiveTab={setActiveTab}
      />
    );
  });

  return <div className={styles.NumApplications}>{blocks}</div>;
}

function MobileStatisticBlocks(props) {
  const { activeTab, data, setActiveTab } = props;

  return LEAD_STATISTICS.map((block) => {
    const { key, label, tab } = block;
    const onClick = () => setActiveTab(tab);

    return (
      <MobileStatisticsBlock
        key={key}
        name={label}
        active={activeTab === tab}
        value={data[key]}
        onClick={onClick}
      />
    );
  });
}

function Search(props) {
  const { fetchData, leads, handleSet } = props;
  const [search, setSearch] = useState(leads.search || '');
  const { filter, filterParams, onToggleIsOpen } = useFilterState({
    branch: leads.branch,
    entity_type: leads.entity_type,
    initiator: leads.initiator,
    region: leads.region,
    start_date: leads.start_date,
    start_date_from: leads.start_date_from,
    start_date_to: leads.start_date_to,
  });

  useEffect(() => {
    handleSet({
      branch: filterParams.branch,
      entity_type: filterParams.entity_type,
      initiator: filterParams.initiator,
      region: filterParams.region,
      start_date: filterParams.start_date,
      start_date_from: filterParams.start_date_from,
      start_date_to: filterParams.start_date_to,
    });
    fetchData();
  }, [filterParams, leads.search]);

  const setSearchReducer = (searchValue) => handleSet({ search: searchValue });
  const debouncedSearchFilter = useRef(
    debounce(setSearchReducer, DEBOUNCE_INTERVAL)
  ).current;

  const leadFilter = React.cloneElement(filter, {
    dynamicFilterOptions: {
      branch_options: get(leads, 'leadsMeta.branch_options'),
      user_options: get(leads, 'leadsMeta.user_options'),
      call_centre_user_options: get(
        leads,
        'leadsMeta.call_centre_user_options'
      ),
    },
    filterType: 'lead',
    isStatesOptionsVisible: false,
  });

  const onChange = (event) => {
    const value = get(event, 'target.value');
    setSearch(value);
    debouncedSearchFilter(value);
  };

  return (
    <Fragment>
      <div className={styles.search_container}>
        <div className={styles.search_input}>
          <BorderedTextField
            id="search"
            label="Search"
            value={search}
            onChange={onChange}
          />
        </div>
        <div className={styles.advanced_search_container}>
          <PipelinePageHeaderComponents.AdvancedSearchButton
            onClick={onToggleIsOpen}
          />
        </div>
      </div>
      {leadFilter}
    </Fragment>
  );
}

function LeadRow(props) {
  const { lead, onShowForm } = props;
  const consumerName = capitalizeSentence(displayEmpty(lead.consumerName));

  let firstColumn;
  if (lead.isInProgress) {
    firstColumn = (
      <div
        className={`${styles.consumer_name} ${styles.consumer_name_clickable}`}
        onClick={() => onShowForm(lead)}
      >
        {consumerName}
      </div>
    );
  } else {
    firstColumn = (
      <div className={styles.consumer_name}>
        <Link to={`/dashboard/leads/${lead.id}`}>{consumerName}</Link>
      </div>
    );
  }

  return (
    <div className={styles.lead_table_row}>
      {firstColumn}
      <div className={styles.mobile_hidden}>{lead.formattedKeyContact}</div>
      <div className={styles.mobile_hidden}>{lead.formattedCreatedAt}</div>
      <div className={styles.mobile_hidden}>{lead.formattedSubmittedAt}</div>
      <div className={styles.mobile_hidden}>
        {lead.initiator(isHeadquarter())}
      </div>
    </div>
  );
}

function MobileLoadMore(props) {
  const { isLoading, noMoreRecords, onLoadMobileLeads } = props;

  if (noMoreRecords) {
    return (
      <div className={`${styles.mobile_visible} ${styles.click_more}`}>
        No more records
      </div>
    );
  }

  let content = <span>Click to get more</span>;
  if (isLoading) {
    content = <SimpleLoader css_class="loader_relative" />;
  }

  return (
    <div
      className={`${styles.mobile_visible} ${styles.click_more}`}
      onClick={onLoadMobileLeads}
    >
      {content}
    </div>
  );
}

function LeadList(props) {
  const { data, loading, noMoreRecords, onShowForm, onLoadMobileLeads } = props;

  if (loading) {
    return <TableListSkeleton />;
  }

  const rows = data.map((lead, i) => (
    <LeadRow
      key={`lead-row-${i + 1}`}
      lead={new LeadModel(lead)}
      onShowForm={onShowForm}
    />
  ));

  rows.push(
    <MobileLoadMore
      key="lead-row-load-more"
      isLoading={loading}
      noMoreRecords={noMoreRecords}
      onLoadMobileLeads={onLoadMobileLeads}
    />
  );

  return (
    <div>
      <div className={`${styles.lead_table_header} ${styles.mobile_hidden}`}>
        <div>Customer</div>
        <div>Key contact</div>
        <div>Started</div>
        <div>Completed</div>
        <div>Initiator/Branch</div>
      </div>
      {rows}
    </div>
  );
}

class LeadWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      editableLead: new LeadModel({}),
      showCreateLead: false,
    };
  }

  componentDidMount() {
    const { dispatch, entityLeadName } = this.props;

    dispatch(loadLocalStorage());
    dispatch(loadLeads());
    dispatch(loadLeadReport());
    dispatch(setLeadFormValue('lead_name', entityLeadName));
    dispatch(loadUsers());
    dispatch(loadInternalUseFieldsAddon());
    dispatch(loadCustomLeadConfiguration());
  }

  _fetchData() {
    const { dispatch, location } = this.props;
    dispatch(loadLeads(location.query));
  }

  _resetFilters() {
    const { dispatch, entityLeadName } = this.props;
    dispatch(reset());
    dispatch(setLeadFormValue('lead_name', entityLeadName));
  }

  _handleLeadNameChange(e) {
    const { dispatch } = this.props;
    const str = e.target.value;
    const start = 0;
    const end = 10;
    const value = str.substring(start, end);
    dispatch(setLeadFormValue('lead_name', value));
  }

  updateLeadName() {
    const { dispatch, formValues } = this.props;
    dispatch(updateLeadName(formValues));
  }

  onSetPerPage(attr) {
    const { currentUserModel, dispatch } = this.props;
    const { leadsPerPage } = attr;

    currentUserModel.updatePreferences({ leadsPerPage });
    dispatch(set(attr));
  }

  _handleSet(attr) {
    const { dispatch } = this.props;
    dispatch(set(attr));
  }

  _setLocation(key, value) {
    const { dispatch } = this.props;
    dispatch(setLocation(key, value));
  }

  _setActiveTab(activeTab) {
    const { dispatch, currentUser } = this.props;
    dispatch(set({ activeTab, leadsPage: 1 }));
    this._fetchData();

    mixpanel.track('Leads page - tab clicked', {
      'Tab Name': activeTab,
      distinct_id: currentUser.id,
    });
  }

  showCreateLead(editableLead = null) {
    const { currentUser, currentEntity } = this.props;
    this.setState({ editableLead, showCreateLead: true });
    mixpanel.track('Leads page - Add button clicked', {
      'Entity ID': currentEntity.id,
      distinct_id: currentUser.id,
    });
  }

  cancelCreatedLead() {
    const { dispatch } = this.props;
    dispatch(resetLeadForm());
    this.setState({ editableLead: null, showCreateLead: false });
  }

  onLoadMobileLeads = () => {
    const { dispatch, location } = this.props;

    dispatch(loadLeads(location.query, false, true));
  };

  render() {
    const {
      changesMade,
      entityLeadName,
      formValues,
      isAdmin,
      leadNameUpdating,
      leadReportLoading,
      leads,
      leadsPerPage,
      loading,
      noMoreRecords,
      report,
      ...rest
    } = this.props;
    const { drawerOpen } = this.state;

    let updateButton = null;

    if (changesMade) {
      updateButton = (
        <div className={styles.button}>
          <Button
            handleClick={() => {
              this.updateLeadName();
            }}
            text="update"
            loading={leadNameUpdating}
            loading_text={'updating'}
            disableOnLoading={true}
          />
        </div>
      );
    }

    const isDisabled =
      !isAdmin || !isFeatureEditEnabled('1CAH') || leadNameUpdating;

    return (
      <DashboardPageContainer
        headerComponents={
          <Fragment>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.title}>
                  <Input
                    classes={{ input: styles.name_text }}
                    value={formValues.lead_name}
                    placeholder={'Lead'}
                    onChange={(e) => {
                      this._handleLeadNameChange(e);
                    }}
                    disabled={isDisabled}
                    required
                  />
                  {updateButton}
                </div>
              </div>
              {this.state.showCreateLead && (
                <CreateLead
                  editableLead={this.state.editableLead}
                  dismissHandler={() => {
                    this.cancelCreatedLead();
                  }}
                />
              )}
              <div className={`${styles.mobile_hidden}`}>
                <LeadBlocks
                  data={leads.leadsMeta}
                  activeTab={leads.activeTab}
                  setActiveTab={(v) => {
                    this._setActiveTab(v);
                  }}
                />
              </div>
              <div className={`${styles.panel} ${styles.mobile_hidden}`}>
                <div className={styles.liner}>
                  <LeadBarGraph data={report} loading={leadReportLoading} />
                </div>
              </div>
            </div>
            <Search
              fetchData={this._fetchData.bind(this)}
              handleSet={this._handleSet.bind(this)}
              leads={leads}
              onToggleFilters={() => this.setState({ drawerOpen: !drawerOpen })}
            />
            <div className={`${styles.add_lead} ${styles.mobile_visible}`}>
              <Button
                text="+ Add customer"
                handleClick={() => this.showCreateLead(null)}
              />
            </div>
          </Fragment>
        }
        contentComponents={
          <Fragment>
            <div className={`${styles.add_lead} ${styles.mobile_hidden}`}>
              <Button
                text="+ Add customer"
                handleClick={() => this.showCreateLead(null)}
              />
            </div>
            <LeadList
              data={leads.lead_list}
              loading={loading}
              noMoreRecords={noMoreRecords}
              onShowForm={(editableLead) => this.showCreateLead(editableLead)}
              handleRemoteSort={() => this._fetchData()}
              onLoadMobileLeads={this.onLoadMobileLeads}
              {...rest}
            />
            <div className={styles.mobile_hidden}>
              <Pagination
                dataLength={leads.leadsMeta.current_total_count}
                currentPage={leads.leadsPage}
                todosPerPage={leadsPerPage}
                resourceName={entityLeadName || 'Leads'}
                optionViewPage={[
                  { label: '25', value: 25 },
                  { label: '50', value: 50 },
                ]}
                handleClick={(value) => {
                  this._handleSet({ leadsPage: value });
                  this._fetchData();
                }}
                handleClickTodosPerPage={(value) => {
                  this.onSetPerPage({ leadsPage: 1, leadsPerPage: value });
                  this._fetchData();
                }}
              />
            </div>
            <div className={styles.footer_container}>
              <div
                className={`${styles.mobile_visible} ${styles.mobile_bottom_menu}`}
              >
                <MobileStatisticBlocks
                  data={leads.leadsMeta}
                  activeTab={leads.activeTab}
                  setActiveTab={(v) => {
                    this._setActiveTab(v);
                  }}
                />
              </div>
            </div>
          </Fragment>
        }
        scrollingHeader
      />
    );
  }
}

module.exports = connect((state) => {
  const entityLeadName =
    state.current_user.current_entity.attributes.lead_name || '';
  const formValues = { lead_name: state.leads.lead_name || '' };
  const currentUser = state.current_user.data.data;
  const currentUserModel = UserModel.fromCurrentUser(state.current_user);
  const leads = state.leads;
  const leadsPerPage = get(
    currentUserModel,
    'preferenceByCurrentEntity.leadsPerPage',
    leads.leadsPerPage
  );

  return {
    all_users: state.manage_profile.current_entity_users || [],
    changesMade: formValues.lead_name !== entityLeadName,
    currentEntity: state.current_user.current_entity,
    currentUser,
    currentUserModel,
    entityLeadName,
    formValues,
    isAdmin:
      state.current_user.current_user_entity_link.attributes.role_types.includes(
        'admin'
      ),
    leadNameUpdating: state.leads.lead_name_updating,
    leadReportLoading: state.leads.lead_report_loading,
    leads,
    leadsPerPage,
    loading: state.leads.leadsLoading,
    noMoreRecords: state.leads.noMoreRecords,
    report: state.leads.report,
  };
})(LeadWrapper);
