import styled from 'styled-components';

import { FilterButton } from './FilterButton';
import { AdvancedSearchButton } from './AdvancedSearchButton';
import { DownloadReportButton } from './DownloadReportButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 48px;
`;

const SearchWrapper = styled.div`
  flex: 1;
`;

const ButtonsContainer = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: flex;
    flex: 1;
    gap: 20px;
    position: relative;
    top: 8px;
  }
`;

export const PipelinePageHeaderComponents = {
  Container,
  FilterButton,
  FilterContainer,
  SearchWrapper,
  ButtonsContainer,
  AdvancedSearchButton,
  DownloadReportButton,
};
