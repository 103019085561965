import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from './axios';

type AssociatedEntityPayload = {
  attributes: {
    business_number: string;
    company_number: string;
    entity_name: string;
    legal_type: 'company';
    association_type: 'corporate_trustee';
    application_id;
  };
};

export default class AssociatedEntities {
  axios: AxiosInstance;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );
  }

  createAssociatedEntity(entityId: string, payload: AssociatedEntityPayload) {
    return this.axios.post(
      `entities/${entityId}/associated_entity_details`,
      payload
    );
  }

  createPeople(entityId: string, payload: any) {
    return this.axios.post(`associated_entities/${entityId}/people`, payload);
  }
}
