import FixedContent from 'modules/shared/components/containers/FixedContent';
import React, { Fragment } from 'react';
import { FlexContent, PEOPLE_COMPONENT } from './People';
import { PEOPLE_HEADER } from './constants';
import get from 'lodash.get';
import { pluralizeNoun } from 'utils';

//Temporary finding the model using name matching
//since there's no IDs that can be matched between
//PersonModel and related models (guarantor, signatories, authorisations)
const findModelFromName = (person, models) => {
  const model = models.find((model) => {
    const { first_name, last_name } = person;

    if (!first_name || !last_name || !model.firstName || !model.lastName) {
      return false;
    }

    return (
      model.firstName.toLowerCase() === first_name.toLowerCase() &&
      model.lastName.toLowerCase() === last_name.toLowerCase()
    );
  });

  return model;
};

export const getEmailFromLinkedModels = (person, application) => {
  const { guarantors, authorisations, signatories } = application;

  const authorisationModel = findModelFromName(person, authorisations);
  if (authorisationModel && authorisationModel.email) {
    return authorisationModel.email;
  }

  const guarantorModel = findModelFromName(person, guarantors);
  if (guarantorModel && guarantorModel.email) {
    return guarantorModel.email;
  }

  const signatoriesModel = findModelFromName(person, signatories);
  if (signatoriesModel && signatoriesModel.email) {
    return signatoriesModel.email;
  }

  return undefined;
};

const CorporateTrustPeople = ({ application }) => {
  const people = get(application, 'trusteeDirectors', []).filter(
    (person) => person.legal_type !== 'trustee'
  );

  const updatedPeople = people.map((person) => {
    if (person.email) return person;

    return {
      ...person,
      email: getEmailFromLinkedModels(person, application),
    };
  });

  const label = PEOPLE_HEADER['corporate_trust'];
  const Component = PEOPLE_COMPONENT['corporate_trust'];
  const corporateTrustPeople = updatedPeople.map((person, index) => (
    <Component
      key={`person-${index + 1}`}
      index={index + 1}
      application={application}
      label={label}
      person={person}
    />
  ));
  return (
    <Fragment>
      <FixedContent header={pluralizeNoun(label, (updatedPeople || []).length)}>
        <FlexContent>{corporateTrustPeople}</FlexContent>
      </FixedContent>
    </Fragment>
  );
};

export default CorporateTrustPeople;
