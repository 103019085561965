import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { get } from 'lodash';
import UserModel from 'models/UserModel';
import {
  Section,
  Title,
  Wrapper,
} from 'modules/shared/components/v2/SupplierSetup/styles';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import USER_TYPES from 'modules/shared/users/userTypes';
import React, { Fragment, ReactElement, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

import ProfileForm from './ProfileForm';
import ResetPasswordForm from './ResetPasswordForm';
import {
  CurrentUserType,
  CurrentUserTypes,
  FontAwesomeIcon,
  Role,
  SubHeading,
} from './styles';
import { Props, RoleType } from './types';

function UserType({ roleType }: { roleType: RoleType }): ReactElement | null {
  const targetedRoleType = USER_TYPES.find(
    (userType) => userType.value === roleType
  );

  if (!targetedRoleType) {
    return null;
  }

  const { description, label } = targetedRoleType;

  return (
    <CurrentUserType>
      <SubHeading>
        User type - <Role>{label}</Role>
      </SubHeading>
      <p>{description}</p>
    </CurrentUserType>
  );
}

function UserProfile(props: Props): ReactElement | null {
  const { currentUser, isFetching, isSupplier, userData } = props;
  const { role_types } = userData;
  const [isLoading, setIsLoading] = useState(false);

  const parentProps = {
    ...props,
    isLoading,
    setIsLoading,
  };

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Wrapper>
        <div className="container">
          {isFetching && !userData && (
            <Fragment>
              <Skeleton />
              <Skeleton count={5} />
            </Fragment>
          )}
          {!isFetching && (
            <div className="column">
              <Section noBorder>
                <Title>
                  Your profile{' '}
                  {isLoading && <FontAwesomeIcon icon="circle-notch" spin />}
                </Title>
              </Section>
              <ProfileForm {...parentProps} />
              {isSupplier && (
                <CurrentUserTypes>
                  {role_types &&
                    role_types.map((roleType) => (
                      <UserType key={roleType} roleType={roleType} />
                    ))}
                </CurrentUserTypes>
              )}
              {currentUser.ssoProvider === 'no_provider' && (
                <ResetPasswordForm {...parentProps} />
              )}
            </div>
          )}
        </div>
      </Wrapper>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  const currentUser = UserModel.fromCurrentUser(state.current_user);
  const userData = get(
    state,
    'manage_profile.current_user_profile.attributes',
    {}
  );
  const isFetching = get(
    state,
    'manage_profile.current_user_profile_loading',
    false
  );

  return {
    currentUser,
    isFetching,
    isSupplier: get(currentUser, 'currentEntity.supplier', false),
    userData,
    userId: currentUser.id,
  };
})(UserProfile);
