import { amountTypeValue, countTypeValue } from './helpers/summary';

import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { enquiryResponseData } from './helpers/reportHelpers';
import styles from '../../../css/CommonReviewBusiness.css';

var DirectorsAdverse = createClass({
  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Directors Adverse"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <div className={styles.table_wrapper}>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Company Director's Adverse</th>
                <td>{countTypeValue(summaryEntry, 'Directors_Adverse')}</td>
              </tr>
              <tr>
                <th>Company Director's Related Entity Adverse*</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Directors_Related_Entity_Adverse'
                  )}
                </td>
              </tr>
              <tr>
                <th colSpan={2}>
                  <strong>Including</strong>
                </th>
              </tr>
              <tr>
                <th>Current &amp; Prior External Adminstration Count</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Directors_Total_External_Administrations_Count'
                  )}
                </td>
              </tr>
              <tr>
                <th>Current &amp; Prior Deregistration/Strike-offs count</th>
                <td>
                  {countTypeValue(
                    summaryEntry,
                    'Directors_Total_De_registrations_and_Struck_Offs_Count'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          *Company Director's Related Entity Adverse refers to current &amp;
          prior external administrations and / or deregistrations
        </div>
      </ReviewContainer>
    );
  },
});

export default DirectorsAdverse;
