import styles from 'modules/authorisation/components/css/Section.css';
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import React, { ReactElement } from 'react';
import REGION from 'types/region';
import bankNumberLabel from 'utils/direct-debit/bankNumberLabel';
import isBankNumberRequired from 'utils/direct-debit/isBankNumberRequired';

const BankNumber = ({
  region,
  bankNumber,
}: {
  region: REGION;
  bankNumber: string;
}): ReactElement | null => {
  if (!isBankNumberRequired(region)) {
    return null;
  }

  return (
    <div className={styles.half_width}>
      <LabeledInformation
        label={bankNumberLabel[region]}
        data={bankNumber}
        noHumanize
        showEmpty={true}
      />
    </div>
  );
};

export default BankNumber;
