import React from 'react';
import moment from 'moment';

import styles from '../../css/CommonReviewBusiness.css';
import historyStyles from './css/History.css';

var Directors = createClass({
  _directorsList() {
    const { data } = this.props;
    let creditCheck = data,
      enquiryResult = creditCheck.enquiry_response.enquiry_result,
      companyDetails = enquiryResult.company_details,
      companyDirectors = companyDetails.company_directors || {},
      directors = companyDirectors.company_director_ws_dto || [];

    if (directors.constructor === Object) {
      directors = [directors];
    }

    return directors;
  },

  _activeDirectors() {
    return this._directorsList().filter((director) => {
      return director.date_deleted === null;
    });
  },

  _inactiveDirectors() {
    return this._directorsList().filter((director) => {
      return director.date_deleted !== null;
    });
  },

  _directorView(directors) {
    return directors.map((director, index) => {
      return (
        <div key={index}>
          <h4>{this._header(director)}</h4>
          <table className={styles.table_w20}>
            <tbody>
              <tr>
                <th>Name</th>
                <td>
                  {director.first_names} {director.surname}
                </td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{director.address}</td>
              </tr>
              <tr>
                <th>Date appointed</th>
                <td>{moment(director.date_appointed).format('DD-MMM-YYYY')}</td>
              </tr>
              {this._dateDeleted(director)}
            </tbody>
          </table>
        </div>
      );
    });
  },

  _header(director) {
    if (director.date_deleted === null) {
      return 'Director';
    }

    return 'Past Director';
  },

  _dateDeleted(director) {
    if (director.date_deleted === null) {
      return null;
    }

    return (
      <tr>
        <th>Date Deleted</th>
        <td>{director.date_deleted}</td>
      </tr>
    );
  },

  render() {
    return (
      <div className={historyStyles.container}>
        <p>
          <strong>Directors</strong>
        </p>
        {this._directorView(this._activeDirectors())}
        {this._directorView(this._inactiveDirectors())}
      </div>
    );
  },
});

export default Directors;
