import React, { useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { browserHistory } from 'react-router';
import { Typography } from '@material-ui/core';

import Dialog from 'modules/shared/components/v2/Dialog';
import { CustomerViewContext } from './CustomerViewContext';

export const UnsavedChanges = () => {
  const { save } = useContext(CustomerViewContext);

  const [isOpen, setOpen] = useState(false);
  const nextRouteRef = useRef<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (location) => {
      const { pathname, search } = location;
      if (nextRouteRef.current) {
        return true;
      }
      const nextRoute = `${pathname}${search}`;
      nextRouteRef.current = nextRoute;
      setOpen(true);
      return false;
    };
    const unlisten = browserHistory.listenBefore(handleRouteChange);
    return () => {
      unlisten();
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleConfirmNavigateAway = () => {
    browserHistory.push(nextRouteRef.current);
  };

  const handleCancelNavigateAway = () => {
    nextRouteRef.current = null;
    setOpen(false);
    save();
  };

  const handleClose = () => {
    nextRouteRef.current = null;
    setOpen(false);
  };

  return (
    <Dialog
      title="Unsaved changes"
      isOpen={isOpen}
      onConfirm={handleCancelNavigateAway}
      onCancel={handleConfirmNavigateAway}
      onClose={handleClose}
      confirmButtonText="Save"
      cancelButtonText="Leave page"
      cancelButtonProps={{ red: true }}
    >
      <Typography>
        You have unsaved changes on this page. Please click
      </Typography>
      <Typography>
        save if you'd like to save the changes you've made.
      </Typography>
    </Dialog>
  );
};
