import mixpanel from 'mixpanel-browser';
import { applyMiddleware, createStore } from 'redux';
import MixpanelMiddleware from 'redux-mixpanel-middleware';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import ApplicationReducer from '../modules/reducers';
import { middleware as idleMiddleware } from '../modules/redux-idle-monitor';
import rootSaga from '../sagas';

export default function configureStore(initialState) {
  mixpanel.init(window._env_.MIXPANEL);
  const mixpanelMiddleware = new MixpanelMiddleware(mixpanel);
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    ApplicationReducer,
    initialState,
    applyMiddleware(mixpanelMiddleware, thunk, sagaMiddleware)
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
