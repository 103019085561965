import api from 'api';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';

export default class BranchModel extends BaseModel {
  static fetchBranches({ accessToken, entityId, onSuccessCallback, params }) {
    const headquartersAPI = api('headquarters', accessToken, entityId);

    const successCallback = (result) => {
      const data = get(result, 'data.data', []);
      const branches = data.map((datum) => new BranchModel(datum));

      onSuccessCallback(branches);
    };

    headquartersAPI.getBranches(
      entityId,
      successCallback,
      (error) => console.error(error),
      { params }
    );
  }
}
