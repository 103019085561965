import React, { Component } from 'react';
import styles from './css/Dot.css';

class Dot extends Component {
  render() {
    const { color, text, ...rest } = this.props;

    return (
      <span {...rest}>
        <span className={`${styles.dot} ${styles[color]}`}></span>
        <span className={styles[`${color}_text`]}>{text}</span>
      </span>
    );
  }
}

export default Dot;
