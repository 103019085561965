import React from 'react';

/* Import CSS */
import styles from './css/LargeScrollingDropdown.css';

import ScrollArea from 'react-scrollbar';

var LargeScrollingDropdown = createClass({
  getDefaultState: function() {
    return {
      isOpen: false,
    };
  },

  getInitialState: function() {
    return this.getDefaultState();
  },

  componentWillMount: function() {
    const { startOpen } = this.props;
    if (startOpen) {
      this.setState({
        isOpen: true,
      });
    }
  },

  componentWillReceiveProps: function(nextProps) {
    if (nextProps.options.length < 1) {
      this.setState({ isOpen: false });
    }
    if (nextProps.forceOpen) {
      this.setState({
        isOpen: true,
      });
    }
  },

  selectElement: function(value) {
    const { handleClick } = this.props;
    this.setState({ isOpen: false });
    handleClick(value);
  },

  handleOpen: function() {
    const { isOpen } = this.state;
    if (isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: true });
    }
  },

  render: function() {
    const {
      first,
      options,
      value,
      handleClick,
      label,
      z_index,
      admin,
    } = this.props;
    const { isOpen } = this.state;

    let labelContent = label,
      labelHeader = <span>{labelContent}</span>,
      optionElement = null,
      option_elements = [],
      container_class = styles.lookup,
      label_class = styles.label;

    if (options.length < 1) {
      return <div />;
    }

    if (value) {
      labelContent = value.title;
      labelHeader = <span>{label}</span>;
      container_class = styles.lookup_complete;
    }

    if (first) {
      container_class = styles.lookup_first;
    }

    if (first && value) {
      container_class = styles.lookup_complete_first;
    }

    if (isOpen) {
      label_class = styles.label_active;
      container_class = `${styles.focus} ${container_class}`;
      options.forEach((value, index) => {
        option_elements.push(
          <li
            className={styles.option}
            key={`option-${index}`}
            onClick={this.selectElement.bind(this, value)}
          >
            {value.title}
          </li>
        );
      });

      var verticalContainerCSS = {
        backgroundColor: '#c9c9c9',
        opacity: 1,
        width: '6px',
        zIndex: 1,
        borderRadius: '3px',
      };

      var verticalScrollbarCSS = {
        borderRadius: '3px',
        width: '6px',
        margin: '0px',
        zIndex: 2,
      };

      optionElement = (
        <div className={styles.dropdown}>
          <ScrollArea
            speed={0.8}
            className={styles.scroll_section}
            contentClassName="options"
            horizontal={false}
            verticalContainerStyle={verticalContainerCSS}
            verticalScrollbarStyle={verticalScrollbarCSS}
          >
            <ul className={styles.options}>{option_elements}</ul>
          </ScrollArea>
        </div>
      );
    }

    if (admin) {
      container_class = `${styles.admin} ${container_class}`;
    }

    return (
      <div className={container_class}>
        <h1 className={styles.heading}>{labelHeader}</h1>
        <div className={styles.shadow}>
          <label className={label_class} onClick={this.handleOpen}>
            {labelContent}
          </label>
        </div>
        {optionElement}
      </div>
    );
  },
});

export default LargeScrollingDropdown;
