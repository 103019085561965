import styled, { css } from 'styled-components';
import { SPACING } from 'variables/spacing';
import { THEME_COLORS } from 'variables/theme';
import { GLOBAL_ANIMATION } from 'variables/utils';

export const InnerWrapper = styled.div<{ $bgImage?: string }>`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
  background-image: ${(props) => props.$bgImage && `url(${props.$bgImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.sm};
  }
`;

export const Wrapper = styled.div<{ hasError: boolean; hasFile: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30rem;
  min-height: 15rem;
  text-align: center;
  border-radius: 25px;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.sm};
  }

  ${(props) =>
    !props.hasFile &&
    css`
      ${InnerWrapper} {
        outline: 2px dashed grey;
        outline-offset: -1.5rem;
        padding: 4rem;
        border-radius: 25px;
      }
    `}

  ${(props) =>
    props.hasError &&
    css`
      ${InnerWrapper} {
        outline-color: ${THEME_COLORS.danger};
      }
    `}
`;
