import styled from 'styled-components';

export const NavButton = styled.button`
  background-color: var(--main-color) !important;
  border-color: var(--main-font-color) !important;
  border-width: 2px;
  color: var(--main-font-color) !important;
  height: 60px;
  margin-right: 35px;
  width: 60px;

  &.active-button {
    background-color: var(--main-color-active) !important;
    border-color: var(--main-color-active) !important;
    color: var(--main-font-color) !important;
  }
`;
