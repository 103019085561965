import styled from 'styled-components';
import { FONT_SIZES, FONT_WEIGHTS } from 'variables/typography';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;

  > * {
    margin-bottom: 1rem;
    flex: 1;
  }
`;

export const ActionWrapper = styled(Wrapper)`
  max-height: 48rem;
  overflow-y: scroll;
`;

export const Table = styled.table`
  width: 100%;

  th {
    font-size: ${FONT_SIZES.title};
    font-weight: ${FONT_WEIGHTS.normal};
  }

  th,
  td {
    width: 100%;
  }

  td {
    padding: 0 0 1rem;
  }

  td:first-child {
    padding-right: 1rem;
  }

  td:last-child {
    min-width: 9rem;
  }
`;
