import mixpanel from 'mixpanel-browser';
import styles from 'modules/paperless-onboarding/css/Complete.css';
import SectionBadge from 'modules/shared/components/widgets/static/SectionBadge';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import SectionTitle from 'modules/shared/components/widgets/static/SectionTitle';
import React, { useEffect } from 'react';

export default function Complete(props) {
  const { paperlessApplication } = props;

  useEffect(() => {
    mixpanel.track('DDM completed', {
      distinct_id: paperlessApplication.userInformationId,
    });
  }, []);

  return (
    <div className={styles.containerFlex}>
      <section className={styles.blue_section}>
        <div className={styles.row}>
          <div className={styles.panel}>
            <SectionTitle white={true} text={'Form sent'} />
            <SectionBadge icon="email" />
          </div>
        </div>
        <div className={styles.description_row}>
          <div className={styles.description}>
            <SectionDescription
              text={`Your form has been sent to ${paperlessApplication.attributes.supplier_name}. They will be in contact if they have any additional questions. You will receive confirmation and a copy of your authorisation shortly.`}
            />
          </div>
        </div>
      </section>
    </div>
  );
}
