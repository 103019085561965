import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#messages-create-a-message-notifying-a-single-user
 */
let Messages = function(config) {
  this.axios = axios(config);
  this.type = 'messages';
};

Messages.prototype.createMessage = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default Messages;
