export type Tier3 = {
  entityId: string;
  title: string;
  isChannelDecentralised: boolean;
  type: 't3';
};

export type Group = { title: string; type: 'group'; children?: Tier3[] };

export type TreeData = {
  children: Group[];
  expanded: true;
  type: 'hq';
}[];

export type PreviewData = {
  id: string;
  entity_id: string;
  trading_name: string;
  parent_id: string | null;
  type: 'Region' | 'Branch';
}[];

export enum ConfigStatus {
  Absent = 'Absent',
  Draft = 'Draft',
  On = 'On',
  Off = 'Off',
}

export enum ActionStatus {
  None = 'None',
  SavingDraft = 'SavingDraft',
  Saving = 'Saving',
  TurningOn = 'TurningOn',
  TurningOff = 'TurningOff',
}

export type CustomerViewContextType = {
  t3s: Tier3[];
  t3Name: string;
  treeData: TreeData;
  setTreeData: (data: TreeData) => void;
  addNode: () => void;
  removeGroup: (path: number[]) => void;
  editPath: number[];
  setEditPath: (path: number[]) => void;
  editGroup: (group: Group) => void;
  isDirty: boolean;
  isSaveDisabled: boolean;
  isTurnOnDisabled: boolean;
  isAddDisabled: boolean;

  isPreviewShown: boolean;
  showPreview: () => void;
  hidePreview: () => void;
  previewData: PreviewData;

  saveDraft: () => void;
  save: () => void;
  turnOn: () => void;
  turnOff: () => void;

  isCustomerViewEnabled: boolean;

  configStatus: ConfigStatus;
  actionStatus: ActionStatus;

  showEmptyRootAlert: boolean;
  showEmptyGroupAlert: boolean;

  history: string[];
};
