import get from 'lodash.get';
import { ContentContainer } from 'modules/addons/components//DigitalOnboarding';
import commonStyles from 'modules/addons/components/css/PPSRRegistration/EditComponent.css';
import FormCheckBox from 'modules/shared/components/inputs/FormCheckBox';
import React, { Fragment } from 'react';

export default function NewZealandFunctions(props) {
  const { control, errors, register, versionConfig } = props;

  return (
    <Fragment>
      <ContentContainer header="Step 2: PPSR Functions.">
        <div className={commonStyles.sub_header}>
          There is a cost to registering and maintaining PPSR via the NZ
          Companies office. Please turn on the functions you would like via your
          1Centre account.
        </div>
        <div className={commonStyles.function_checkbox_container}>
          <FormCheckBox
            id="functionRegister"
            label="Register $11 +gst"
            control={control}
            value={false}
            register={register}
            defaultValue={get(versionConfig, 'functions.register', false)}
            error={get(errors, 'functionRegister.message', '')}
          />
          <FormCheckBox
            id="functionModify"
            label="Modify $1 +gst"
            control={control}
            value={false}
            register={register}
            defaultValue={get(versionConfig, 'functions.modify', false)}
          />
        </div>
        <div className={commonStyles.function_checkbox_container}>
          <FormCheckBox
            id="functionRenew"
            label="Renew $11 +gst"
            control={control}
            value={false}
            register={register}
            defaultValue={get(versionConfig, 'functions.renew', false)}
          />
          <FormCheckBox
            id="functionDischarge"
            label="Discharge $1 + gst"
            control={control}
            value={false}
            register={register}
            defaultValue={get(versionConfig, 'functions.discharge', false)}
          />
        </div>
      </ContentContainer>
      <ContentContainer header="Step 3: Renew financing statement.">
        <div className={commonStyles.sub_header}>
          You can turn on an automatic renewal here. Alternatively you can set
          an expiry date when setting up your registration.
        </div>
        <div>
          <FormCheckBox
            id="financingStatementAutoRenew"
            label="Auto renewal: automatically renewed after 5 years"
            control={control}
            value={false}
            register={register}
            defaultValue={get(versionConfig, 'financing.auto_renew', false)}
          />
        </div>
      </ContentContainer>
    </Fragment>
  );
}
