import { useState } from 'react';

export const INACCESSIBLE_MESSAGE = {
  accepted:
    'The application you’re trying to access has been accepted, which means you’re not able to make any further modifications.',
  approval_in_progress:
    'The application you’re trying to access is currently under review, which means you’re not able to make any further modifications.',
  archived: "The application you're trying to access has been archived.",
  declined:
    'The application you’re trying to access has been declined, which means you’re not able to make any further modifications.',
  deleted: "The application you're trying to access has been deleted.",
};

const useApplicationAccessibilityState = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
  const [isApplicationAccessible, setIsApplicationAccessible] = useState<
    boolean
  >(true);
  const [inaccessibleReason, setInaccessibleReason] = useState<string>('');
  const [
    isApplicationAccessibleLoaded,
    setIsApplicationAccessibleLoaded,
  ] = useState<boolean>(false);

  const onSetApplicationAccessibility = (isAccessible, reason) => {
    setIsApplicationAccessible(isAccessible);
    setInaccessibleReason(reason || '');
    setIsApplicationAccessibleLoaded(true);
    setIsAuthorized(true);
  };

  const message = INACCESSIBLE_MESSAGE[inaccessibleReason];

  return {
    isApplicationAccessible,
    isApplicationAccessibleLoaded,
    isAuthorized,
    message,
    onSetApplicationAccessibility,
    setIsApplicationAccessibleLoaded,
    setIsAuthorized,
  };
};

export default useApplicationAccessibilityState;
