import React from 'react';
import styles from './report-styles.css';
import { getCharacteristicsData } from './utils';

const ReportInsolvenciesCourtActions = ({
  characteristic,
  insolvencyType,
}: {
  characteristic: any;
  insolvencyType?: 'personal';
}) => {
  if (insolvencyType === 'personal') {
    let numInsolvency = getCharacteristicsData(characteristic, 'NP8510'),
      numDefaultJudgement = getCharacteristicsData(characteristic, 'NP7504'),
      numOtherCourtActions = getCharacteristicsData(characteristic, 'NP7511'),
      numInsolvencyValue = parseInt(numInsolvency.value) || 0,
      numDefaultJudgementValue = parseInt(numDefaultJudgement.value) || 0,
      numOtherCourtActionsValue = parseInt(numOtherCourtActions.value) || 0;

    if (characteristic.length < 1) return <p>Information Unavailable</p>;

    return (
      <div>
        <p className="is-pulled-right">
          No. of actions:{' '}
          {numInsolvencyValue +
            numDefaultJudgementValue +
            numOtherCourtActionsValue}
        </p>
        <br />
        <div className={styles.table + ' mt-2'}>
          <div className={styles.row}>
            <div className={styles.th + ' ' + styles.col_4}>Data</div>
            <div className={styles.th + ' ' + styles.col_8}>No. of actions</div>
          </div>
          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_4}>
              Personal insolvency
            </div>
            <div className={styles.td + ' ' + styles.col_8}>
              {numInsolvency.value}
            </div>
          </div>
          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_4}>
              Default judgement
            </div>
            <div className={styles.td + ' ' + styles.col_8}>
              {numDefaultJudgement.value}
            </div>
          </div>
          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_4}>
              Other court actions
            </div>
            <div className={styles.td + ' ' + styles.col_8}>
              {numOtherCourtActions.value}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    let personalInsolvencies =
        characteristic.find((obj) => obj['@id'] === 'NP8510') || {},
      defaultJudgements =
        characteristic.find((obj) => obj['@id'] === 'NP7504') || {},
      numOtherCourtActions =
        characteristic.find((obj) => obj['@id'] === 'NP7511') || {};

    return (
      <div>
        <div className={styles.row}>
          <div className={styles.col_4}>Personal Insolvencies</div>
          <div className={styles.col_4}>
            Total number: &nbsp;
            {!personalInsolvencies || personalInsolvencies.value < 0
              ? '-'
              : personalInsolvencies.value}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col_4}>Default Judgement</div>
          <div className={styles.col_4}>
            Total number: &nbsp;
            {!defaultJudgements || defaultJudgements.value < 0
              ? '-'
              : defaultJudgements.value}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col_4}>Other court actions</div>
          <div className={styles.col_4}>
            Total number: &nbsp;
            {!numOtherCourtActions || numOtherCourtActions.value < 0
              ? '-'
              : numOtherCourtActions.value}
          </div>
        </div>
      </div>
    );
  }
};

export default ReportInsolvenciesCourtActions;
