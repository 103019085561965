import Layout from 'modules/request-permission-flow/containers/Layout';
import Review from 'modules/request-permission-flow/components/Review';

const RequestPermissionFlowRoutes = {
  childRoutes: [
    {
      component: Review,
      path: ':token',
    },
  ],
  component: Layout,
  path: 'request_permission',
};

export default RequestPermissionFlowRoutes;
