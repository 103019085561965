import { Typography } from '@material-ui/core';
import Dialog from 'modules/shared/components/v2/Dialog';
import React from 'react';

type ConfirmRemoveGroupModal = {
  groupName: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmRemoveGroup = (props: ConfirmRemoveGroupModal) => {
  const { groupName, isOpen, onConfirm, onCancel } = props;

  return (
    <Dialog
      title="Remove group"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      cancelButtonProps={{ white: true }}
    >
      <Typography>
        {`Please confirm to remove the ${
          groupName ? `'${groupName}' ` : ''
        }group.`}
      </Typography>
      <Typography>
        {`All Tier 3s under the group will be released back to the`}
      </Typography>
      <Typography>{`Tier 3 list after removal.`}</Typography>
    </Dialog>
  );
};
