import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'variables/theme';

const Container = styled.div<{ $x: number; $y: number }>`
  padding: 10px;
  position: fixed;
  left: ${(props) => props.$x - 40 + 'px'};
  top: ${(props) => props.$y + 20 + 'px'};
  background: ${COLORS.darkGrey};
  color: #eee;
  border-radius: 4px;
  transition: all 200ms;
`;

type TooltipProps = {
  children: string;
  x: number;
  y: number;
};

export const Tooltip = (props: TooltipProps) => {
  const { children, x, y } = props;

  return children ? (
    <Container $x={x} $y={y}>
      {children}
    </Container>
  ) : null;
};
