import React from 'react';
import { browserHistory } from 'react-router';

import Button from '../../shared/components/inputs/Button';
import styles from './css/ConnectionKeyContact.css';

const ConnectionKeyContact = createClass({
  contact() {
    const { connection, location } = this.props;

    browserHistory.push({
      pathname: `/dashboard/connections/details/${connection.id}/contact`,
      query: location.query,
    });
  },

  render() {
    const { connection, role } = this.props;
    let showcontact_full_name;
    let showcontact_contact_phone_number;
    let showcontact_email;
    let ourcontact_full_name;
    let ourcontact_contact_phone_number;
    let ourcontact_email;

    if (connection.showcontact.attributes.full_name) {
      showcontact_full_name = (
        <span className={styles.full_name}>
          {connection.showcontact.attributes.full_name}
          <br />
        </span>
      );
    }

    if (connection.showcontact.attributes.contact_phone_number) {
      showcontact_contact_phone_number = (
        <span className={styles.phone_number}>
          {connection.showcontact.attributes.contact_phone_number}
          <br />
        </span>
      );
    }

    if (
      connection.showcontact.attributes.email &&
      connection.showcontact.attributes.email !==
        `${connection.supplier.id}_websitebutton@1centre.com`
    ) {
      showcontact_email = (
        <span className={styles.email}>
          {connection.showcontact.attributes.email}
        </span>
      );
    }

    if (connection.ourcontact.attributes.full_name) {
      ourcontact_full_name = (
        <span className={styles.contact_full_name}>
          {connection.ourcontact.attributes.full_name}
          <br />
        </span>
      );
    }

    if (connection.ourcontact.attributes.contact_phone_number) {
      ourcontact_contact_phone_number = (
        <span className={styles.contact_phone_number}>
          {connection.ourcontact.attributes.contact_phone_number}
          <br />
        </span>
      );
    }

    if (
      connection.ourcontact.attributes.email &&
      !connection.ourcontact.attributes.role_type_by_entity_context.includes(
        'websitebutton'
      )
    ) {
      ourcontact_email = (
        <span className={styles.contact_email}>
          {connection.ourcontact.attributes.email}
        </span>
      );
    }

    let button;
    if (role === 'admin') {
      button = (
        <Button
          text="edit key contact"
          css_style="button_white_block"
          handleClick={this.contact}
        />
      );
    }

    let showparty_trading_name = connection.attributes.consumer_name;
    let ourparty_trading_name = connection.application.attributes.supplier_name;
    if (!connection.ourparty.attributes.supplier) {
      [showparty_trading_name, ourparty_trading_name] = [
        ourparty_trading_name,
        showparty_trading_name,
      ];
    }

    return (
      <div className={styles.contacts_block}>
        <h2 className={styles.heading}>Key contacts</h2>
        <h3 className={styles.sub_heading}>{showparty_trading_name}</h3>
        <p className={styles.details}>
          {showcontact_full_name}
          {showcontact_contact_phone_number}
          {showcontact_email}
        </p>

        <h3 className={styles.sub_heading}>{ourparty_trading_name}</h3>
        <p className={styles.details}>
          {ourcontact_full_name}
          {ourcontact_contact_phone_number}
          {ourcontact_email}
        </p>

        {button}
      </div>
    );
  },
});

module.exports = ConnectionKeyContact;
