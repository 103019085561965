import styled, { css } from 'styled-components';
import { FORM_SPACING } from 'variables/spacing';
import { THEME_COLORS } from 'variables/theme';

export const InputElement = styled.input`
  padding: ${FORM_SPACING.inputPadding};

  ${(props) =>
    props.disableThemeOverride &&
    css`
      border-color: ${THEME_COLORS.primary};

      &:hover {
        border-color: ${THEME_COLORS.primary};
      }
    `}
`;
