import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CameraAlt as CameraIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

import api from 'api';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import Button from 'modules/shared/components/inputs/Button';
import { InnerWrapper, Wrapper } from 'modules/FileUpload/styles';
import { processError } from 'utils';
import { setIdentityValue, setMaxRetryReached } from 'modules/identity/actions';
import { IDENTITY_SET_ANTI_FRAUD_RESULT } from '../constants';
import { LIVENESS_ERROR } from './utils';
import { UploadStatus } from 'modules/shared/components';
import { FEATURE_FLAGS } from 'conf';

const TIME_DELAY_TO_INITIATE_STATUS_CHECK = 10000;
const STATUS_CHECK_INTERVAL = 4000;
const RETRY_COUNT_WHEN_API_ERROR = 3;

const Container = styled.div`
  margin-top: 20px;
`;

const livenessAppUrl = window._env_.LIVENESS_APP_URL;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const IframeWrapper = styled.div`
  width: 100%;
  height: 700px;

  @media screen and (min-width: 991px) {
    width: 500px;
  }
`;

const MessageContainer = styled.div`
  margin-top: 12px;
`;

type ProofOfLifeProps = {
  authorisationID: string;
  error: boolean;
  hasIdImage: boolean;
  imageUrl: string;
  dispatch: any;
  isAntiFraudPassing: boolean;
  isOtherIdType: boolean;
};

const checkIfBlocked = (access_denied?: string) =>
  access_denied && access_denied === 'maximum-retried';

export const ProofOfLife = connect(null)((props: ProofOfLifeProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    error,
    authorisationID,
    hasIdImage,
    imageUrl,
    dispatch,
    isAntiFraudPassing,
    isOtherIdType,
  } = props;

  const [sessionId, setSessionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  const [isBlocked, setBlocked] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Wait, we already have an imageUrl coming from props. Then, why do we need this one?
  // Well, imageUrl will be avaiable when anti fraud check has passed.
  // Regardless of whether anti fraud check passes or not, we need to display
  // liveness reference image. This variable will hold the reference image url
  // when anti fraud check fails. We need not touch the other one in this scenario
  // becuase then we will have to change validation logic in the parent component too.
  const [image, setImage] = useState<string>('');

  const authorisationsApi = api('authorisations');

  const initiateSession = async () => {
    if (isBlocked || !livenessAppUrl) {
      return;
    }

    if (!hasIdImage) {
      enqueueSnackbar('Please upload ID image before taking video', {
        variant: 'warning',
      });
      return;
    }

    setLoading(true);
    setSessionId('');
    setInvalid(false);
    setApiError(false);
    setSuccess(false);
    setImage('');
    try {
      const response =
        await authorisationsApi.createLivenessSession(authorisationID);
      const { access_denied, session_id } = response.data;
      if (checkIfBlocked(access_denied)) {
        setBlocked(true);
        if (FEATURE_FLAGS.FEATURE_FLAG_MAX_RETRY_CAT6) {
          dispatch(setMaxRetryReached());
        }
      } else if (session_id) {
        setSessionId(response.data.session_id);
      }
    } catch (error: any) {
      const { errorMessage } = processError(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    try {
      setSuccess(false);
      setLoading(true);
      await authorisationsApi.removeLivenessImage(authorisationID);
      dispatch(setIdentityValue('proof_of_life_image', null));
    } catch (error: any) {
      const { errorMessage } = processError(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const getReferenceImage = async () => {
    try {
      const response =
        await authorisationsApi.getAuthorisation(authorisationID);
      dispatch(
        setIdentityValue(
          'proof_of_life_image',
          response.data.data.signature.attributes.liveness_reference_image.url
        )
      );
    } catch (error) {}
  };

  useEffect(() => {
    let intervalId;
    let timeoutId;
    let apiErrorCount = 0;
    //[UXD-102/BT-16193] Force pass if otherIdType is selected
    const flaggedOtherIdType =
      FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdType;

    if (sessionId) {
      timeoutId = setTimeout(() => {
        intervalId = setInterval(async () => {
          try {
            const { data } = await authorisationsApi.getLivenessSessionResult(
              authorisationID,
              sessionId
            );

            const {
              access_denied,
              liveness_result,
              liveness_reference_image,
              anti_fraud_check_pass,
            } = data;

            if (checkIfBlocked(access_denied)) {
              setBlocked(true);
              setSessionId('');
              clearInterval(intervalId);
              if (FEATURE_FLAGS.FEATURE_FLAG_MAX_RETRY_CAT6) {
                dispatch(setMaxRetryReached());
              }
              return;
            }

            if (
              liveness_result === 'CREATED' ||
              liveness_result === 'IN_PROGRESS'
            ) {
              return;
            }

            setSessionId('');
            clearInterval(intervalId);

            if (liveness_result === 'SUCCEEDED' || flaggedOtherIdType) {
              // liveness_result SUCCEEDED means that the liveness information was captured.

              //[UXD-102/BT-16193] Force pass if otherIdType is selected
              const antiFraudCheck = flaggedOtherIdType
                ? true
                : anti_fraud_check_pass;

              if (antiFraudCheck) {
                getReferenceImage();
                setSuccess(true);
              } else {
                if (liveness_reference_image) {
                  setImage(liveness_reference_image);
                }
                setInvalid(true);
              }
              dispatch({
                type: IDENTITY_SET_ANTI_FRAUD_RESULT,
                payload: { pass: antiFraudCheck },
              });
            } else if (
              liveness_result === 'FAILED' ||
              liveness_result === 'EXPIRED'
            ) {
              setInvalid(true);
            }
          } catch (error) {
            //[UXD-102/BT-16193] Force pass if otherIdType is selected
            if (flaggedOtherIdType) {
              setSessionId('');
              clearInterval(intervalId);
              getReferenceImage();
              setSuccess(true);
              dispatch({
                type: IDENTITY_SET_ANTI_FRAUD_RESULT,
                payload: { pass: true },
              });
            } else {
              apiErrorCount++;
              if (apiErrorCount === RETRY_COUNT_WHEN_API_ERROR) {
                setApiError(true);
                setSessionId('');
                clearInterval(intervalId);
              }
            }
          }
        }, STATUS_CHECK_INTERVAL);
      }, TIME_DELAY_TO_INITIATE_STATUS_CHECK);
    }
    return () => {
      intervalId && clearInterval(intervalId);
      timeoutId && clearTimeout(timeoutId);
    };
  }, [sessionId]);

  return (
    <SectionHeader title="Short selfie video">
      <p>
        Please take a short video facing the camera following the instructions.
        This step is to verify your identity is whom you claim to be.
      </p>
      {sessionId ? (
        <Container>
          <IframeWrapper>
            <StyledIframe
              allow="camera"
              src={`${livenessAppUrl}?s=${sessionId}`}
            />
          </IframeWrapper>
        </Container>
      ) : (
        <Container>
          <Wrapper hasError={error} hasFile={false}>
            <InnerWrapper $bgImage={imageUrl || image}>
              {imageUrl ? (
                <Button
                  handleClick={handleRemove}
                  text="Remove"
                  white
                  loading={loading}
                />
              ) : (
                <>
                  <CameraIcon fontSize="large" />
                  <Button
                    disabled={isBlocked || !livenessAppUrl}
                    handleClick={initiateSession}
                    loading={loading}
                    text="Take video"
                    white
                  />
                </>
              )}
            </InnerWrapper>
          </Wrapper>
        </Container>
      )}
      <MessageContainer>
        {error && !isBlocked && !apiError && (
          <Typography color="error">
            Please take a selfie video to proceed
          </Typography>
        )}
        {isInvalid && (
          <Typography color="error">Invalid video. Please retake.</Typography>
        )}
        {apiError && (
          <Typography color="error">
            Error in verification. Please try again.
          </Typography>
        )}
        {!isAntiFraudPassing && imageUrl && (
          <Typography color="error">{LIVENESS_ERROR}</Typography>
        )}
        {!livenessAppUrl && (
          <Typography color="error">
            Configuration error. Please contact{' '}
            <a href={`mailto:support@1centre.com`}>support@1centre.com</a>
          </Typography>
        )}
        {success && !error && (
          <UploadStatus
            type="success"
            message="Upload successful"
            textAlign="left"
          />
        )}
      </MessageContainer>
    </SectionHeader>
  );
});
