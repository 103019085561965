import React from 'react';

import CompanyAddresses from './CompanyAddresses';
import BusinessAddresses from './BusinessAddresses';

import { isCompany } from './helpers/reportHelpers';

var Address = createClass({
  render() {
    const { data, isAllExpanded, entityType } = this.props;

    if (isCompany(entityType))
      return (
        <CompanyAddresses
          data={data}
          entityType={entityType}
          isAllExpanded={isAllExpanded}
        />
      );

    return (
      <BusinessAddresses
        data={data}
        entityType={entityType}
        isAllExpanded={isAllExpanded}
      />
    );
  },
});

export default Address;
