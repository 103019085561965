import React, { Component } from 'react';

import SectionBadge from 'modules/shared/components/widgets/static/SectionBadge';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import SectionTitle from 'modules/shared/components/widgets/static/SectionTitle';
import { connect } from 'react-redux';
import styles from '../css/Complete.css';

class Complete extends Component {
  get title() {
    if (this.props.approved) {
      return 'Cardholder form completed!';
    }

    return "No worries, You can't win 'em all!";
  }

  get icon() {
    return this.props.approved ? 'thumbsup' : 'thumbsdown';
  }

  get subText() {
    const { approved, consumerName } = this.props;

    if (approved) {
      return null;
    }

    const subText = `We'll let ${consumerName} know that you chose to decline to be a cardholder.`;

    return (
      <div className={styles.descriptionRow}>
        <div className={styles.description}>
          <SectionDescription text={subText} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.page}>
        <div className={styles.containerFlex}>
          <section className={styles.blueSection}>
            <div className={styles.row}>
              <div className={styles.bluePanel}>
                <SectionTitle white={true} text={this.title} />
                <SectionBadge icon={this.icon} state="section_complete" />
              </div>
            </div>
            {this.subText}
          </section>
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    approved: true,
    consumerName: 'Consumer',
  };
})(Complete);
