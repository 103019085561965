import {
  CREATE_WEBHOOK_ERROR,
  CREATE_WEBHOOK_START,
  CREATE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_ERROR,
  DELETE_WEBHOOK_START,
  DELETE_WEBHOOK_SUCCESS,
  LOAD_WEBHOOKS_ERROR,
  LOAD_WEBHOOKS_START,
  LOAD_WEBHOOKS_SUCCESS,
  SAVE_WEBHOOK_ERROR,
  SAVE_WEBHOOK_START,
  SAVE_WEBHOOK_SUCCESS,
  TEST_WEBHOOK_ERROR,
  TEST_WEBHOOK_START,
  TEST_WEBHOOK_SUCCESS,
} from './constants';

const initialState = {
  deleting: false,
  loading: false,
  testing: false,
  updating: false,
  webhooks: [],
};

export function webhookReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WEBHOOKS_START:
      return { ...state, loading: true };
    case LOAD_WEBHOOKS_SUCCESS:
      return { ...state, loading: false, webhooks: action.payload };
    case LOAD_WEBHOOKS_ERROR:
      return { ...state, loading: false };
    case CREATE_WEBHOOK_START:
      return { ...state, saving: true };
    case CREATE_WEBHOOK_SUCCESS:
      return { ...state, saving: false };
    case CREATE_WEBHOOK_ERROR:
      return { ...state, saving: false };
    case SAVE_WEBHOOK_START:
      return { ...state, saving: true };
    case SAVE_WEBHOOK_SUCCESS:
      return { ...state, saving: false };
    case SAVE_WEBHOOK_ERROR:
      return { ...state, saving: false };
    case DELETE_WEBHOOK_START:
      return { ...state, saving: true };
    case DELETE_WEBHOOK_SUCCESS:
      return { ...state, saving: false };
    case DELETE_WEBHOOK_ERROR:
      return { ...state, saving: false };
    case TEST_WEBHOOK_START:
      return { ...state, testing: true };
    case TEST_WEBHOOK_SUCCESS:
      return { ...state, testWebhooksSuccess: true, testing: false };
    case TEST_WEBHOOK_ERROR:
      return { ...state, testWebhooksSuccess: false, testing: false };
    default:
      return { ...state };
  }
}
