import React, { useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { Buttons } from './Buttons';
import { AccountMonitoringItemWrapper } from './AccountMonitoringItemWrapper';
import { AccountMonitoringContext } from './AccountMonitoringContext';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import { useSnackbar } from 'notistack';
import { processError } from 'utils';

export const ReviewDate = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { application, currentUser, onFetchApplicationRecord } = useContext(
    AccountMonitoringContext
  );

  const applicationId = application.id;
  const reviewDate = application.reviewDate;

  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [isPending, setPending] = useState(false);

  const isReviewDateActive = Boolean(reviewDate);

  useEffect(() => {
    if (reviewDate) {
      setSelectedDate(moment(reviewDate));
    } else {
      setSelectedDate(null);
    }
  }, [reviewDate]);

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const update = (date: Moment | null) => {
    setPending(true);

    try {
      application.update({
        attributes: {
          review_date: date ? moment(date, 'DD/MM/YYYY').toDate() : null,
        },
        currentUser,
        onSuccessCallback: () => {
          onFetchApplicationRecord();
          setPending(false);
          if (date) {
            enqueueSnackbar('Review date set', { variant: 'success' });
          } else {
            enqueueSnackbar('Review date removed', { variant: 'info' });
          }
        },
      });
    } catch (error: any) {
      const { errorMessage } = processError(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setPending(false);
    }
  };

  const handleSave = () => {
    if (!selectedDate) {
      return;
    }
    update(selectedDate);
  };

  const handleClear = () => {
    update(null);
  };

  return (
    <AccountMonitoringItemWrapper title="Review date">
      <BorderedCalendarPicker
        isDisabled={isPending || isReviewDateActive}
        onChange={handleChange}
        minDate={new Date()}
        value={selectedDate}
        textFieldProps={{
          className: 'account-monitoring-input',
        }}
      />
      <Buttons
        applicationId={applicationId}
        handleSave={handleSave}
        handleClear={handleClear}
        isActive={isReviewDateActive}
        isPending={isPending}
        noteCategory="review_date"
      />
    </AccountMonitoringItemWrapper>
  );
};
