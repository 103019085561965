/* Import libraries */
/* Import components */

import React, { useState } from 'react';
import { browserHistory } from 'react-router';

/* Import actions */
/* Import CSS */
import styles from './css/NotificationIndicator.css';
import NotificationPanel from './NotificationPanel';

const NotificationIndicator = (props) => {
  const [toggled, setToggled] = useState(true);

  const showAll = () => {
    setToggled(false);
    browserHistory.push('/dashboard/notifications');
  };

  let notificationsPanel;
  let style = styles.default;

  let badgeClickHandler = () => setToggled(!toggled);

  if (toggled) {
    style = styles.active;
    // Rely only on the handleClickOutside logic of the panel
    badgeClickHandler = null;
    notificationsPanel = <NotificationPanel allHandler={showAll} />;
  }

  return (
    <div className={style}>
      {notificationsPanel}
      {props.username}
    </div>
  );
};

export default NotificationIndicator;
