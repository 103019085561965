import get from 'lodash.get';
import IdentificationImageV1 from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v1';
import IdentificationImageV2 from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2';
import React, { useState } from 'react';
import getApplicableAntiFraudRulesForCreditFile from 'utils/anti-fraud/getApplicableAntiFraudRulesForCreditFile';

const IdentificationImage = (props) => {
  const { application, authorisation } = props;

  const antiFraudCategoryRules = getApplicableAntiFraudRulesForCreditFile({
    application,
    authorisation,
  });

  if (!antiFraudCategoryRules.requiresIdentificationImage) {
    return null;
  }

  const [hasPDFError, setHasPDFError] = useState(false);
  const onSetPDFError = () => setHasPDFError(true);

  if (application.antiFraudEnabled) {
    return (
      <IdentificationImageV2
        hasPDFError={hasPDFError}
        onSetPDFError={onSetPDFError}
        {...props}
      />
    );
  }

  return (
    <IdentificationImageV1
      hasPDFError={hasPDFError}
      onSetPDFError={onSetPDFError}
      {...props}
    />
  );
};

IdentificationImage.defaultProps = {
  isViewOnly: true,
  isVisibleWhenEmpty: true,
};

export default IdentificationImage;
