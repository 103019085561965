import { LoggedIn } from 'modules/shared/auth';
import WebhookAlertNotificationDocumentation from 'modules/webhooks/components/WebhookAlertNotificationDocumentation';
import WebhookCashDocumentation from 'modules/webhooks/components/WebhookCashDocumentation';
import WebhookCreditDocumentation from 'modules/webhooks/components/WebhookCreditDocumentation';
import WebhookDocumentationContainer from 'modules/webhooks/containers/WebhookDocumentationContainer';

module.exports = {
  childRoutes: [
    {
      component: WebhookCreditDocumentation,
      path: 'credit',
    },
    {
      component: WebhookCashDocumentation,
      path: 'cash',
    },
    {
      component: WebhookAlertNotificationDocumentation,
      path: 'alert_notification',
    },
  ],
  component: LoggedIn(WebhookDocumentationContainer),
  path: 'webhook_documentation',
};
