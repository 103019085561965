import api from 'api';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ComposableMap, ZoomableGroup } from 'react-simple-maps/dist/index.es';
import styled from 'styled-components';

import useParamsCompare from '../../hooks/useParamsCompare';
import useRequestQuery from '../../hooks/useRequestQuery';
import { Props } from '../Panel/model';
import { COLOR_RANGE, CustomGeography, MapScoreData } from './CustomGeography';
import { ErrorState } from './ErrorState';
import { Tooltip } from './Tooltip';
import { useMousePosition } from 'hooks';
import { useTooltip } from './useTooltip';
import { FEATURE_FLAGS } from 'conf';

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`;

const Legend = styled.div`
  height: 10px;
  background: linear-gradient(
    90deg,
    #f1f835 0%,
    #c6e1ad 25%,
    #a4cd99 54%,
    #80b783 75%,
    #6f9c4d 100%
  );
`;

const Score = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExperienceHeatMap: React.FC<Props> = ({
  accessToken,
  entityId,
  filterState,
}) => {
  const apiAction = () =>
    api('reporting', accessToken, entityId).get({
      api: 'experience_score_distribution',
      params: filterState,
    });
  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const { tooltipContent, setTooltipContent, tooltipX, tooltipY } =
    useTooltip();

  const paramString = JSON.stringify(filterState);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
    }
  }, [filterState]);

  const mapScoreByRegion: MapScoreData = useMemo(() => {
    if (loading || error || !data) {
      return { au: {}, nz: {} };
    }
    const accumulatedScore: { [key: string]: number[] } = data.reduce(
      (acc, currData) => {
        if (currData['iso_code'] && currData.score !== null) {
          if (acc[currData['iso_code']]) {
            acc[currData['iso_code']].push(currData.score);
          } else {
            acc[currData['iso_code']] = [currData.score];
          }
        }
        return acc;
      },
      {}
    );
    return Object.entries(accumulatedScore)
      .map(([key, scores]: [string, number[]]) => {
        const totalNumber = scores.length;
        const sumOfScores = scores.reduce((a, c) => a + c, 0);
        return [key, (sumOfScores * 1.0) / totalNumber];
      })
      .reduce(
        (acc, [k, v]) => {
          acc[(k as string).split('-')[0].toLowerCase()][k] = v;
          return acc;
        },
        { au: {}, nz: {} }
      );
  }, [data, loading, error]);

  const projectionConfig = React.useMemo(() => {
    const isAuDataExist = Object.keys(mapScoreByRegion.au).length > 0;
    const isNzDataExist = Object.keys(mapScoreByRegion.nz).length > 0;
    if (isAuDataExist && isNzDataExist) {
      return { pj: { center: [145, -27], scale: 500 }, zoom: 1 };
    }
    if (isNzDataExist) {
      return { pj: { center: [173, -41], scale: 500 }, zoom: 3.2 };
    }
    if (isAuDataExist) {
      return { pj: { center: [134, -28], scale: 500 }, zoom: 1.5 };
    }
    return { pj: { center: [145, -27], scale: 500 }, zoom: 1 };
  }, [mapScoreByRegion.au, mapScoreByRegion.nz]);

  if (loading && !error) {
    return <Skeleton count={5} />;
  }

  if (!loading && error) {
    return <ErrorState refresh={forceUpdate} />;
  }

  return (
    <>
      <LegendWrapper>
        <Score>
          {COLOR_RANGE.map((c, i) => (
            <span key={c}>{2 * i}</span>
          ))}
          <span>10</span>
        </Score>
        <Legend />
      </LegendWrapper>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={projectionConfig.pj}
        style={{
          height: '100%',
          minHeight: '45rem',
        }}
      >
        <ZoomableGroup
          center={projectionConfig.pj.center}
          zoom={projectionConfig.zoom}
        >
          <CustomGeography
            data={mapScoreByRegion}
            setTooltipContent={setTooltipContent}
          />
        </ZoomableGroup>
      </ComposableMap>
      {FEATURE_FLAGS.FEATURE_FLAG_EXPERIENCE_MAP_TOOLTIPS && (
        <Tooltip x={tooltipX} y={tooltipY}>
          {tooltipContent}
        </Tooltip>
      )}
    </>
  );
};

export default ExperienceHeatMap;
