import React from 'react';

import Position from './Position';

var BottomLeft = createClass({
  render: function() {
    const { children, absolute, css_class } = this.props;
    return (
      <Position absolute={absolute} position="bottom_left" style={css_class}>
        {children}
      </Position>
    );
  },
});

module.exports = BottomLeft;
