import axios from './axios';

export default class ModulePaperlessApplications {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'module_paperless_applications';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
    this.path = `/${this.type}`;
  }

  getModulePaperlessApplications(params) {
    return this.axios.get(this.path, { params });
  }

  getModulePaperlessApplication(id) {
    return this.axios.get(`${this.path}/${id}`);
  }

  getDetails(params) {
    return this.axios.get(this.path, params);
  }

  update(id, attributes) {
    return this.axios.patch(`${this.path}/${id}`, attributes);
  }

  review(id) {
    return this.axios.patch(`${this.path}/${id}/review`);
  }

  export(params) {
    return this.axios.get(`${this.path}/export`, { params });
  }
}
