import { CONSUMER_RISK_SCORE, GREY } from '../constants';
import React, { Component } from 'react';

import C3Chart from 'react-c3js';
import Popover from '@material-ui/core/Popover';
import ReportSummaryOverview from './ReportSummaryOverview';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { extractText } from '../ConsumerRiskScoreComponent';
import get from 'lodash.get';
import isBlank from 'utils/isBlank';
import objectToArray from 'utils/objectToArray';
import styles from '../css/consumer-risk-score/ReportSummary.css';

const DONUT_DATA = [
  ['A', 1],
  ['B', 1],
  ['C', 1],
  ['D', 1],
  ['E', 1],
  ['F', 1],
  ['G', 1],
  ['H', 1],
  ['I', 1],
  ['J', 1],
];

function getColors(scoreBand) {
  const colors = {};
  const grades = Object.keys(CONSUMER_RISK_SCORE);

  for (const grade of grades) {
    let color = GREY;

    if (scoreBand >= grade) {
      color = CONSUMER_RISK_SCORE[grade].color;
    }

    colors[grade] = color;
  }

  return colors;
}

export default class ReportSummary extends Component {
  get scores() {
    return get(this.props.creditReport, 'scores.score', {});
  }

  get measures() {
    return get(this.scores, 'measures', {});
  }

  get textEnumValue() {
    return objectToArray(get(this.measures, 'text_enum_value'));
  }

  get percentageValue() {
    return objectToArray(get(this.measures, 'percentage_value'));
  }

  get keyInfluencingFactors() {
    return objectToArray(get(this.scores, 'ki_fs.text_enum_value'));
  }

  get score() {
    return extractText(this.scores, 'score');
  }

  get percentileRank() {
    const percentageValue = this.percentageValue.find(
      (p) => p.desc === 'Percentile Rank'
    );

    if (isBlank(percentageValue)) {
      return null;
    }

    return percentageValue.text;
  }

  get portfolioPercentileRank() {
    const percentageValue = this.percentageValue.find(
      (p) => p.desc === 'Portfolio Percentile Rank'
    );

    if (isBlank(percentageValue)) {
      return null;
    }

    return percentageValue.text;
  }

  get portfolioType() {
    const portfolioType = this.textEnumValue.find(
      (t) => t.desc === 'Portfolio Type'
    );

    if (isBlank(portfolioType)) {
      return '';
    }

    return portfolioType.text;
  }

  constructor(props) {
    super(props);

    this.state = { popoverAnchorElement: null };
  }

  onDonutMouseOver = (event) => {
    this.setState({ popoverAnchorElement: event.currentTarget });
  };

  onDonutMouseOut = () => {
    this.setState({ popoverAnchorElement: null });
  };

  renderConsumerRiskScore() {
    const { popoverAnchorElement } = this.state;

    return (
      <div>
        <div
          className={styles.consumer_risk_score_container}
          onMouseOver={this.onDonutMouseOver}
          onMouseOut={this.onDonutMouseOut}
        >
          {this.renderDonut()}
          {this.renderDescription()}
        </div>
        <Popover
          anchorEl={popoverAnchorElement}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          className={commonStyles.popover}
          disableRestoreFocus
          open={Boolean(popoverAnchorElement)}
          transitionDuration={0}
          onClose={this.onDonutMouseOut}
        >
          {this.renderDonutLegend()}
        </Popover>
      </div>
    );
  }

  renderDonut() {
    const scoreBandMeasure = this.textEnumValue.find(
      (m) => m.desc === 'Score Band'
    );
    if (isBlank(scoreBandMeasure)) {
      return;
    }

    const scoreBand = scoreBandMeasure.text;
    if (isBlank(scoreBand)) {
      return;
    }

    const colors = getColors(scoreBand);
    const title = `${this.score} (${scoreBand})`;

    const data = {
      colors,
      columns: DONUT_DATA,
      type: 'donut',
    };

    const donutProperties = {
      expand: false,
      label: {
        show: false,
      },
      title,
    };

    const tooltipProperties = {
      show: false,
    };

    return (
      <C3Chart
        data={data}
        size={{ height: 200, width: 200 }}
        interaction={{ enabled: false }}
        legend={{ hide: true }}
        donut={donutProperties}
        tooltip={tooltipProperties}
      />
    );
  }

  renderDescription() {
    const percentileRank = this.percentileRank;
    if (isBlank(percentileRank)) {
      return;
    }

    return (
      <div className={styles.consumer_risk_score_description}>
        <h2>Illion Consumer Risk Score</h2>
        <div>
          A score of{' '}
          <span className={commonStyles.emphasize}>{this.score}</span> indicates
          that the applicant has a score that is better than or equal to{' '}
          <span className={commonStyles.emphasize}>{percentileRank}%</span> of
          all consumers on the illion database.
        </div>
      </div>
    );
  }

  renderDonutLegend() {
    return (
      <table className={`${commonStyles.table} ${styles.scoreGradeTable}`}>
        <thead>
          <tr>
            <th>Grade</th>
            <th>Score</th>
            <th>Probability of Default</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.A.color,
                color: '#fff',
              }}
            >
              A
            </td>
            <td>SCORE &gt; 745</td>
            <td>&lt;= 2%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.B.color,
                color: '#fff',
              }}
            >
              B
            </td>
            <td>SCORE &gt; 715 and SCORE &lt;= 745</td>
            <td>2% to &lt;= 3%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.C.color,
                color: '#fff',
              }}
            >
              C
            </td>
            <td>SCORE &gt; 675 and SCORE &lt;= 715</td>
            <td>3% to &lt;= 4%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.D.color,
                color: '#fff',
              }}
            >
              D
            </td>
            <td>SCORE &gt; 632.5 and SCORE &lt;= 675</td>
            <td>4% to &lt;= 5%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.E.color,
                color: '#fff',
              }}
            >
              E
            </td>
            <td>SCORE &gt; 607.5 and SCORE &lt;= 632.5</td>
            <td>5% to &lt;= 6%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.F.color,
                color: '#fff',
              }}
            >
              F
            </td>
            <td>SCORE &gt; 527.5 and SCORE &lt;= 607.5</td>
            <td>6% to &lt;= 9%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.G.color,
                color: '#fff',
              }}
            >
              G
            </td>
            <td>SCORE &gt; 435 and SCORE &lt;= 527.5</td>
            <td>9% to &lt;= 16%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.H.color,
                color: '#fff',
              }}
            >
              H
            </td>
            <td>SCORE &gt; 80 and SCORE &lt;= 435</td>
            <td>16% to &lt;= 70%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.I.color,
                color: '#fff',
              }}
            >
              I
            </td>
            <td>SCORE &gt; -515 and SCORE &lt;= 80</td>
            <td>70% to &lt;= 99%</td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: CONSUMER_RISK_SCORE.J.color,
                color: '#fff',
              }}
            >
              J
            </td>
            <td>SCORE &lt;= -515</td>
            <td>&lt; 99%</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderKeyInfluencingFactors() {
    const factors = this.keyInfluencingFactors.map((factor, i) => (
      <li key={`kifs-${i}`}>{factor.value_desc}</li>
    ));

    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Key influencing factors</p>
        </div>
        <ul className={commonStyles.list}>{factors}</ul>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className={styles.notes}>
        A score of <strong>{extractText(this.score)}</strong> indicates that the
        applicant has a score that is better than or equal to{' '}
        <strong>{this.percentileRank}%</strong> of all consumers on the illion
        database, and better than or equal to{' '}
        <strong>{this.portfolioPercentileRank}%</strong> of the applicants for{' '}
        <strong>{this.portfolioType}</strong> on the illion database.
      </div>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Report summary"
        content_class="content_wide"
        css_class="block_noborder"
      >
        {this.renderConsumerRiskScore()}
        {this.renderKeyInfluencingFactors()}
        {this.renderNotes()}
        <ReportSummaryOverview {...this.props} />
      </ReviewContainer>
    );
  }
}
