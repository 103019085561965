import UserModel from 'models/UserModel';
import { getLabelForValue } from 'modules/consumer-onboarding/components/onboarding/utils/disablePersonalEntityType';
import {
  ABN_LENGTH,
  ACN_LENGTH,
  onRetrieveDetailsWithBusinessNumber,
  onRetrieveDetailsWithCompanyNumber,
} from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/AustraliaBusinessOverview/utils/retrieveDetails';
import OverviewForm from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';
import * as yup from 'yup';

const validationSchema = ({ validEntityTypes }) =>
  yup.object().shape({
    businessNumber: yup
      .string()
      .when('legalType', {
        is: (legalType) =>
          isBlank(legalType) || ['company', 'personal'].includes(legalType),
        otherwise: (schema) => schema.required('This field is mandatory'),
        then: (schema) => schema,
      })
      .test('abn-length', 'Please enter a valid ABN', function(value) {
        if (isBlank(value)) {
          return true;
        }

        return value.length === ABN_LENGTH;
      }),
    companyNumber: yup
      .string()
      .when('legalType', {
        is: (legalType) => legalType === 'company',
        otherwise: (schema) => schema,
        then: (schema) => schema.required('This field is mandatory'),
      })
      .test('acn-length', 'Please enter a valid ACN', function(value) {
        if (isBlank(value)) {
          return true;
        }

        return value.length === ACN_LENGTH;
      }),
    entityName: yup.string().required('This field is mandatory'),
    legalType: yup.string().oneOf(validEntityTypes, (message) => {
      const { value } = message;

      if (isBlank(value)) {
        return 'This field is mandatory';
      }

      const label = getLabelForValue(message.value);
      return `The type of the selected entity is ${label} and is not a valid option.`;
    }),
  });

const AustraliaBusinessOverview = (props): ReactElement => {
  const { currentUser } = props;

  return (
    <OverviewForm
      businessNumberLabel="ABN"
      companyNumberLabel="ACN"
      onRetrieveDetailsWithBusinessNumber={(params) =>
        onRetrieveDetailsWithBusinessNumber(currentUser, params)
      }
      onRetrieveDetailsWithCompanyNumber={(params) =>
        onRetrieveDetailsWithCompanyNumber(currentUser, params)
      }
      validationSchema={validationSchema}
      {...props}
    />
  );
};

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(AustraliaBusinessOverview);
