import {
  IDLESTATUS_AWAY,
  IDLESTATUS_INACTIVE,
  IDLESTATUS_EXPIRED,
} from './constants';
import { browserHistory } from 'react-router';

export const idleStatusDelay = (idleStatus) => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) return 10800000; // User becomes away after 3 hours inactivity
  if (idleStatus === IDLESTATUS_INACTIVE) return 28800000; // Call database to look up the users delay time
  if (idleStatus === IDLESTATUS_EXPIRED) return 28800000; // Log them out after another minute after they enter the inactive status
};

export const activeStatusAction = (dispatch, getState) => {};

export const idleStatusAction = (idleStatus) => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) {
    var state = getState();

    var pathname = state.routing.locationBeforeTransitions.pathname;
    var filter = ['/user/login', '/register/consumer/', '/guarantor/'];

    if (!filter.some((s) => pathname.includes(s))) {
      //log out
      browserHistory.push('/user/logout');
    }
  }
};
