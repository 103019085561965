import api from 'api';
import get from 'lodash.get';
import UserModel from 'models/UserModel';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import React, { Fragment, ReactElement, useState } from 'react';

interface IVehicleDetails {
  chassis: string;
  colour: string;
  make: string;
  model: string;
  registrationPlate: string;
  year: number;
  vin: string;
}

const VEHICLE_LOOKUP_TYPE_OPTIONS = [
  { label: 'Registration plate', value: 'plate' },
  { label: 'VIN', value: 'vin' },
];

const VehicleLookup = (props: {
  currentUser: UserModel;
  fieldNamePrefix: string;
  isVehicleDetailsVisible: boolean;
  onSetAlert: ({ message, type }: { message: string; type: string }) => null;
  setIsVehicleDetailsVisible: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (key: string, value: any) => null;
}): ReactElement => {
  const {
    currentUser,
    fieldNamePrefix,
    isVehicleDetailsVisible,
    onSetAlert,
    setIsVehicleDetailsVisible,
    setValue,
  } = props;

  const { isLoading, setIsLoading } = useIsLoadingState();
  const [vehicleLookupType, setVehicleLookupType] = useState('plate');
  const [vehicleLookupValue, setVehicleLookupValue] = useState('');

  const onSearchVehicleSuccessCallback = (vehicleDetails: IVehicleDetails) => {
    setValue(`${fieldNamePrefix}.vin`, vehicleDetails.vin);
    setValue(
      `${fieldNamePrefix}.registrationPlate`,
      vehicleDetails.registrationPlate
    );
    setValue(`${fieldNamePrefix}.make`, vehicleDetails.make);
    setValue(`${fieldNamePrefix}.model`, vehicleDetails.model);
    setValue(`${fieldNamePrefix}.year`, vehicleDetails.year);
    setValue(`${fieldNamePrefix}.colour`, vehicleDetails.colour);
    setValue(`${fieldNamePrefix}.chassis`, vehicleDetails.chassis);
  };

  const onSetLookupType = (event) =>
    setVehicleLookupType(get(event, 'target.value'));
  const onSetLookupValue = (event) =>
    setVehicleLookupValue(get(event, 'target.value'));
  const onSearchVehicleDetails = async () => {
    const vehicleAPI = api('vehicles', currentUser.accessToken);
    setIsLoading(true);

    try {
      const response = await vehicleAPI.getVehicle({
        region: 'nz',
        vehicleId: vehicleLookupValue,
        vehicleIdType: vehicleLookupType,
      });

      onSearchVehicleSuccessCallback(response.data);
    } catch (error) {
      console.error(error);
      onSetAlert({
        message:
          'Unable to retrieve the vehicle details. Please try again in awhile or enter the details manually.',
        type: 'error',
      });
    } finally {
      setIsVehicleDetailsVisible();
      setVehicleLookupValue('');
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div className={styles.collateral_detail_item_row}>
        <BorderedSelect
          name="Lookup type"
          value={vehicleLookupType}
          options={VEHICLE_LOOKUP_TYPE_OPTIONS}
          onChange={onSetLookupType}
        />
        <BorderedTextField
          label="Lookup value"
          placeholder="Lookup value"
          value={vehicleLookupValue}
          onChange={onSetLookupValue}
        />
      </div>
      <div className={`${styles.collateral_detail_item_row} mb-6`}>
        <div className="is-flex">
          <div className="mr-2">
            <Button
              handleClick={onSearchVehicleDetails}
              loading_text="Searching..."
              loading={isLoading}
              text="Search"
              type="button"
            />
          </div>
          {!isVehicleDetailsVisible && (
            <Button
              handleClick={setIsVehicleDetailsVisible}
              text="Enter details manually"
              type="button"
              white
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default VehicleLookup;
