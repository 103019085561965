import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import React from 'react';
import { connect } from 'react-redux';
import { formatMoney } from 'utils/formatting';
import styles from './css/FormBuilder.css';

class ReviewFormComponentRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialValues(props);
  }

  initialValues(props) {
    const answer = props.answer || { value: '-', value_1: '-', value_2: '0' };
    return {
      value: answer.value || '-',
      value_1: answer.value_1 || '-',
      value_2: answer.value_2 || '0',
    };
  }

  renderFormComponent() {
    const { formParams } = this.props;
    const { value, value_1, value_2 } = this.state;
    switch (formParams.component_type) {
      case 'pair_question':
        return (
          <div>
            <div className={styles.half_width}>
              <LabeledInformation
                key={formParams.field_description_1}
                label={formParams.field_description_1}
                data={value_1}
                noHumanize
              />
            </div>
            <div className={styles.half_width}>
              <LabeledInformation
                key={formParams.field_description_2}
                label={formParams.field_description_2}
                data={`$${formatMoney(parseInt(value_2) || 0)}`}
                noHumanize
              />
            </div>
          </div>
        );
      case 'section_title':
        return (
          <div className={styles.full_width}>
            <p>{formParams.field_description}</p>
          </div>
        );
      case 'dropdown_menu':
      case 'check_boxes': {
        let values = value;
        if (values && values.hasOwnProperty('main')) {
          const allValues = [];
          if (values.main) {
            allValues.push(`${values.main} (main option)`);
          }
          allValues.push(`${values.other.join(', ')}`);
          values = allValues;
        }
        return (
          <div className={styles.half_width}>
            <LabeledInformation
              key={formParams.field_description}
              label={formParams.field_description}
              data={
                Array.isArray(values) ? `${values.join(', ') || '-'}` : values
              }
              noHumanize
            />
          </div>
        );
      }
      default:
        return (
          <div className={styles.half_width}>
            <LabeledInformation
              key={formParams.field_description}
              label={formParams.field_description}
              data={
                Array.isArray(value)
                  ? `${value.join(', ')}`
                  : this.parseNewlines(value)
              }
              noHumanize
            />
          </div>
        );
    }
  }

  parseNewlines(text) {
    const string = [];
    text = text.toString();
    text.split('\n').forEach((v, i) => {
      let value = v;
      if (!value) value = <br />;
      string.push(<div key={i}>{value}</div>);
    });

    return [string];
  }

  render() {
    return this.renderFormComponent();
  }
}

module.exports = connect((_state, ownProps) => {
  return {
    answer: ownProps.answer || { value: null, value_1: null, value_2: null },
  };
})(ReviewFormComponentRender);
