import api from 'api';
import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { processError } from 'utils';
import {
  BulkUploadJobType,
  UploadJobCondensedType,
} from 'types/BulkUploadJobType';
import { useHistoryLogs } from './useHistoryLogs';
import { isFeatureAvailable } from 'modules/shared/helpers/headquarterDetect';
import AddonConfigModel from 'models/AddonConfigModel';

// Interval duration to poll for status check, in milli seconds
const REFRESH_INTERVAL = 1000;

const DEFAULT_TIME_PER_RECORD_AU = 240; // in milli seconds
const DEFAULT_TIME_PER_RECORD_NZ = 240; // in milli seconds
const MIN_TIME_PER_RECORD = 3; // in seconds

// Approximate time requried to import a single record, in seconds, for each region
const timePerRecord = {
  au: window._env_.BULK_UPLOAD_TIME_PER_RECORD_AU || DEFAULT_TIME_PER_RECORD_AU,
  nz: window._env_.BULK_UPLOAD_TIME_PER_RECORD_NZ || DEFAULT_TIME_PER_RECORD_NZ,
};

const getTimeRemaining = (count: number, region: 'au' | 'nz') => {
  console.log({ count, tpr: timePerRecord[region] });
  const timeRemaining = (count * timePerRecord[region]) / 1000;
  if (timeRemaining < MIN_TIME_PER_RECORD) {
    return MIN_TIME_PER_RECORD;
  }
  return timeRemaining;
};

const getLocalStorageKey = (entityId: string, region: 'au' | 'nz') =>
  `${entityId}-${region}-upload-job-id`;

const defaultJob = {
  status: 'NOT_STARTED' as const,
  secondsRemaining: 0,
  errorCsvUrl: null,
};

type RapidTransferContextType = {
  entityId: string;

  auFile: File | null;
  setAuFile: ((file: File | null) => void) | null;

  nzFile: File | null;
  setNzFile: ((file: File | null) => void) | null;

  hasFile: boolean;

  isUploading: boolean;

  auJobId: string | null;
  nzJobId: string | null;

  auJob: UploadJobCondensedType | null;
  nzJob: UploadJobCondensedType | null;

  uploadFiles: (() => void) | null;

  isFileSelectionDisabled: boolean;

  historyLogs: {
    hasMorePages: boolean;
    historyLogItems: BulkUploadJobType[];
    isLoading: boolean;
    loadMore: () => void;
    refresh: () => void;
    error?: string;
  };

  isWatchtowerActive: boolean;
};

const defaultValues = {
  entityId: '',

  auFile: null,
  setAuFile: null,

  nzFile: null,
  setNzFile: null,

  hasFile: false,

  isUploading: false,

  auJobId: null,
  nzJobId: null,

  auJob: null,
  nzJob: null,

  uploadFiles: null,

  isFileSelectionDisabled: false,

  historyLogs: {
    hasMorePages: false,
    historyLogItems: [],
    isLoading: false,
    loadMore: () => {},
    refresh: () => {},
  },

  isWatchtowerActive: false,
};

export const RapidTransferContext =
  createContext<RapidTransferContextType>(defaultValues);

type RapidTransferContextProviderProps = {
  children: JSX.Element[];
  entityId: string;
  accessToken: string;
};

export const RapidTransferContextProvider = connect((state) => ({
  entityId: state.current_user.current_entity.id,
  accessToken: state.current_user.access_token,
}))((props: RapidTransferContextProviderProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { entityId, accessToken, children } = props;

  const [auFile, setAuFile] = useState<File | null>(null);
  const [nzFile, setNzFile] = useState<File | null>(null);

  const [auUploading, setAuUploading] = useState(false);
  const [nzUploading, setNzUploading] = useState(false);

  const [auJobId, setAuJobId] = useState<string | null>(null);
  const [nzJobId, setNzJobId] = useState<string | null>(null);

  const [auIntervalId, setAuIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [nzIntervalId, setNzIntervalId] = useState<NodeJS.Timeout | null>(null);

  const [auJob, setAuJob] = useState<UploadJobCondensedType | null>(null);
  const [nzJob, setNzJob] = useState<UploadJobCondensedType | null>(null);

  const [isWatchtowerActive, setIsWatchtowerActive] = useState<boolean>(false);

  const historyLogs = useHistoryLogs(entityId);

  const refreshUploadStatus = (region: 'au' | 'nz', jobId: string) => {
    const applicationApi = api('applications', '', entityId);
    applicationApi
      .getUploadStatus(jobId)
      .then((response) => {
        const attributes: BulkUploadJobType = response.data.data.attributes;
        const {
          status,
          error_csv,
          success_csv,
          total_rows,
          current_processed_row,
        } = attributes;
        const setJob = region === 'au' ? setAuJob : setNzJob;
        if (status === 'completed') {
          if (error_csv.url) {
            // The upload job is complete, but an error CSV is generated. ⭕️
            setJob({
              status: 'ERROR',
              secondsRemaining: 0,
              errorCsvUrl: error_csv.url,
              hasSuccessEntries: Boolean(success_csv?.url),
            });
          } else {
            // The job completed without any errors. ✅
            setJob({ status: 'SUCCESS', secondsRemaining: 0 });
          }
          const setJobId = region === 'au' ? setAuJobId : setNzJobId;
          setJobId(null);
          localStorage.removeItem(getLocalStorageKey(entityId, region));
          historyLogs.refresh();
        } else if (status === 'in_progress') {
          const rowsRemaining = total_rows - current_processed_row;
          setJob({
            status: 'IN_PROGRESS',
            secondsRemaining: getTimeRemaining(rowsRemaining, region),
          });
        }
      })
      .catch((error) => {
        const { errorMessage } = processError(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });

        // Clear the job ID from both local storage, and from state.
        const setJobId = region === 'au' ? setAuJobId : setNzJobId;
        const setIntervalId =
          region === 'au' ? setAuIntervalId : setNzIntervalId;
        if (region === 'au' && auIntervalId) {
          clearInterval(auIntervalId);
        }
        if (region === 'nz' && nzIntervalId) {
          clearInterval(nzIntervalId);
        }
        setIntervalId(null);
        setJobId(null);
        localStorage.removeItem(getLocalStorageKey(entityId, region));
      });
  };

  useEffect(() => {
    // Decalare a variable to hold id of setInterval that we will call later
    let intervalId: NodeJS.Timeout | null = null;
    if (auJobId) {
      // There is a job ID for AU.
      // We'll set an interval, which will allow us to poll the API
      // to get the status of the import.
      intervalId = setInterval(() => {
        refreshUploadStatus('au', auJobId);
      }, REFRESH_INTERVAL);
      setAuIntervalId(intervalId);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [auJobId]);

  useEffect(() => {
    // Decalare a variable to hold id of setInterval that we will call later
    let intervalId: NodeJS.Timeout | null = null;
    if (nzJobId) {
      // There is a job ID for NZ.
      // We'll set an interval, which will allow us to poll the API
      // to get the status of the import.
      intervalId = setInterval(() => {
        refreshUploadStatus('nz', nzJobId);
      }, REFRESH_INTERVAL);
      setNzIntervalId(intervalId);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [nzJobId]);

  useEffect(() => {
    // Reset job object when a new file is selected
    auFile && setAuJob(null);
    nzFile && setNzJob(null);
  }, [auFile, nzFile]);

  useEffect(() => {
    // Read bulk upload job ids from local storage, and set it to local state.

    const storedAuJobId = localStorage.getItem(
      getLocalStorageKey(entityId, 'au')
    );
    storedAuJobId && setAuJobId(storedAuJobId);

    const storedNzJobId = localStorage.getItem(
      getLocalStorageKey(entityId, 'nz')
    );
    storedNzJobId && setNzJobId(storedNzJobId);
  }, []);

  useEffect(() => {
    const isWatchtowerAvailable = isFeatureAvailable('watchtower');

    if (isWatchtowerAvailable) {
      const MODULE_NAME = 'watchtower_module';
      const addonConfigsAPI = api('addon_configs', accessToken, entityId);

      addonConfigsAPI
        .getAddonConfigs({
          params: { addon_module_name: MODULE_NAME },
        })
        .then((response) => {
          const watchtowerModule: any = new AddonConfigModel(
            response.data.data[0],
            response.data.included
          );

          setIsWatchtowerActive(watchtowerModule.active);
        });
    }
  }, []);

  const hasFile = Boolean(auFile) || Boolean(nzFile);

  const isUploading = auUploading || nzUploading;

  const uploadFile = (region: 'au' | 'nz') => {
    const file = region === 'au' ? auFile : nzFile;
    if (!file) {
      return;
    }

    const loadingSetter = region === 'au' ? setAuUploading : setNzUploading;
    loadingSetter(true);

    const applicationApi = api('applications', '', entityId);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('region', region);

    applicationApi
      .upload(formData)
      .then(async (response) => {
        const jobId = response.data.data?.id;

        // Set the job ID to the local state
        const setJobId = region === 'au' ? setAuJobId : setNzJobId;
        setJobId(jobId);

        // Set the job ID to local storage.
        // This will ensure that we can query for in progress jobs even if user
        // navigates away from the page or refreshes the page.
        localStorage.setItem(getLocalStorageKey(entityId, region), jobId);

        // Read the selected file to get the number of rows.
        // This is to calculate the approximate time remaining.
        await fetch(URL.createObjectURL(file)).then((response) =>
          response.text().then((text) => {
            const numberOfRecords = text.split('\n').length - 1;
            const setJob = region === 'au' ? setAuJob : setNzJob;
            setJob({
              status: 'NOT_STARTED',
              secondsRemaining: getTimeRemaining(numberOfRecords, region),
            });
          })
        );

        // Clear the csv file
        const setFile = region === 'au' ? setAuFile : setNzFile;
        setFile(null);
      })
      .catch((error) => {
        const { errorMessage } = processError(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
      })
      .finally(() => loadingSetter(false));
  };

  const uploadFiles = () => {
    if (!auFile && !nzFile) {
      enqueueSnackbar('Select atleast one file', { variant: 'info' });
    }

    if (auFile) {
      uploadFile('au');
    }

    if (nzFile) {
      uploadFile('nz');
    }
  };

  const isFileSelectionDisabled = Boolean(isUploading || auJobId || nzJobId);

  return (
    <RapidTransferContext.Provider
      value={{
        entityId,
        auFile,
        setAuFile,
        nzFile,
        setNzFile,
        hasFile,
        isUploading,
        auJobId,
        nzJobId,
        auJob,
        nzJob,
        uploadFiles,
        isFileSelectionDisabled,
        historyLogs,
        isWatchtowerActive,
      }}
    >
      {children}
    </RapidTransferContext.Provider>
  );
});
