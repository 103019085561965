/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

/* Import components */
import AdminTitle from '../../shared/components/widgets/static/AdminTitle';
import ConnectionsList from './ConnectionsList';
import SimpleLoader from '../../shared/components/widgets/static/SimpleLoader';

import styles from './css/AllConnectionsList.css';

var AllConnectionsList = createClass({
  render: function() {
    const { title, loading, connections, total_count, ...rest } = this.props;
    let search = null;

    if (loading) {
      return <SimpleLoader />;
    }

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.content}>
            {/* <AdminTitle text={title} /> */}
            <div className={styles.controls}>{search}</div>
            <ConnectionsList
              data={connections}
              total_count={total_count}
              {...rest}
            />

            {this.props.children}
          </div>
        </div>
      </section>
    );
  },
});

var defaults = {
  title: 'Connections',
};

module.exports = connect((state, ownProps) => {
  return {
    title: defaults.title,
  };
})(AllConnectionsList);
