import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import {
  COLLATERAL_PROPERTY_TYPE_OPTIONS,
  COLLATERAL_TYPE_OPTIONS,
} from 'models/PpsrFinancingStatementModel';
import { useCollateralDescriptionVisibilityState } from 'modules/new-applications/components/application-actions/PPSRRegister/PPSRForm/AUPPSRForm';
import {
  DateOfBirth,
  ExpiryDate,
  FinancingStatementPin,
  Navigation,
  OrganisationName,
  PDFFile,
} from 'modules/new-applications/components/application-actions/PPSRRegister/shared/RegisterDetails';
import styles from 'modules/new-applications/css/PPSRRegister.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import isBlank from 'utils/isBlank';
import * as yup from 'yup';

const ADDITIONAL_DETAILS_DESCRIPTION = {
  inventory: 'The collateral is inventory',
  purchase_money: 'Purchase money security interest applies',
  subject_to_control: 'Current assets are subject to control',
  subordinate: 'This registration is subordinate to another registration',
  to_be_claimed: 'Proceeds to be claimed',
};

const pinValidationSchema = (financingStatementKey) =>
  yup.object().shape({
    financingStatementPin: yup
      .string()
      .required('Token is required.')
      .test(
        'financing-statement-pin',
        'Invalid token. The token entered is the same as the registration number.',
        (value) => value !== financingStatementKey
      ),
  });

function FinancingStatementPinForm(props) {
  const {
    currentUser,
    onFetchApplicationRecord,
    onHideForm,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
    disabled,
  } = props;

  const { isLoading, setIsLoading } = useIsLoadingState();

  const { errors, handleSubmit, register } = useForm({
    defaultValues: {
      financingStatementPin: ppsrFinancingStatement.financingStatementPin,
    },
    mode: 'onBlur',
    validationSchema: pinValidationSchema(
      ppsrFinancingStatement.financingStatementKey
    ),
  });

  const onSubmit = ({ financingStatementPin }) => {
    setIsLoading(true);

    ppsrFinancingStatement.updateFinancingStatementPin({
      currentUser,
      financingStatementPin,
      onErrorCallback: (error) => {
        onSetAlert({ message: error, type: 'error' });
        setIsLoading(false);
      },
      onSuccessCallback: (newPpsrFinancingStatement) => {
        onSetPPSRFinancingStatement(newPpsrFinancingStatement);

        onFetchApplicationRecord();
        onSetAlert({ message: 'Token saved.', type: 'success' });
        setIsLoading(false);
        onHideForm();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContent className="mt-4 mb-4 is-align-items-center">
        <BorderedTextField
          error={Boolean(errors.financingStatementPin)}
          helperText={get(errors, 'financingStatementPin.message', ' ')}
          inputRef={register}
          label="Token"
          name="financingStatementPin"
          placeholder="Token"
          customProps={{ withBottomMargin: false }}
          disabled={disabled}
        />
        <div className="is-flex">
          <div className={styles.financing_statement_pin_save_button}>
            <Button
              loading={isLoading}
              text="Save"
              type="submit"
              disabled={disabled}
            />
          </div>
          <div>
            <Button
              white
              text="Cancel"
              handleClick={onHideForm}
              disabled={disabled}
            />
          </div>
        </div>
      </GridContent>
    </form>
  );
}

function RegistrationPin(props) {
  const {
    currentUser,
    onFetchApplicationRecord,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
    disabled,
  } = props;
  const { financingStatementPin } = ppsrFinancingStatement;

  const [isFormVisible, setIsFormVisible] = useState(
    isBlank(financingStatementPin)
  );

  if (isFormVisible) {
    return (
      <FinancingStatementPinForm
        currentUser={currentUser}
        onFetchApplicationRecord={onFetchApplicationRecord}
        onHideForm={() => setIsFormVisible(false)}
        onSetAlert={onSetAlert}
        onSetPPSRFinancingStatement={onSetPPSRFinancingStatement}
        ppsrFinancingStatement={ppsrFinancingStatement}
        disabled={disabled}
      />
    );
  }

  return (
    <FinancingStatementPin
      label="Token"
      ppsrFinancingStatement={ppsrFinancingStatement}
      isEditable={true && !disabled}
      onShowEditForm={() => setIsFormVisible(true)}
    />
  );
}

function IndividualDebtor(props) {
  const { debtor } = props;

  return (
    <Fragment>
      <GridContent>
        <LabeledContent label="Type" content="Individual" />
      </GridContent>
      <GridContent>
        <LabeledContent label="First name" content={debtor.personFirstName} />
        <LabeledContent label="Last name" content={debtor.personLastName} />
        <DateOfBirth debtor={debtor} />
      </GridContent>
    </Fragment>
  );
}

function OrganisationDebtor(props) {
  const { debtor } = props;

  return (
    <GridContent>
      <LabeledContent label="Type" content="Organisation" />
      <OrganisationName debtor={debtor} />
      <LabeledContent label="ACN/ABN" content={debtor.organisationNumber} />
    </GridContent>
  );
}

function Debtor(props) {
  const { debtor, index } = props;

  let content = null;
  if (debtor.debtorType === 'organisation') {
    content = <OrganisationDebtor debtor={debtor} />;
  } else {
    content = <IndividualDebtor debtor={debtor} />;
  }

  return (
    <Fragment>
      <div className={styles.sub_header}>Grantor {index}</div>
      {content}
    </Fragment>
  );
}

function CollateralAdditionalDetails(props) {
  const { collateral } = props;
  const { additionalDetails } = collateral;

  const content = [];
  const relevantKeys = Object.keys(additionalDetails).filter(
    (key) => key !== 'to_be_claimed_description'
  );

  for (const key of relevantKeys) {
    const isSelected = additionalDetails[key];

    if (isSelected) {
      let description = ADDITIONAL_DETAILS_DESCRIPTION[key];

      if (key === 'to_be_claimed') {
        description += `- ${additionalDetails['to_be_claimed_description']}`;
      }

      content.push(<div key={key}>{description}</div>);
    }
  }

  return <LabeledContent label="Additional details">{content}</LabeledContent>;
}

function CollateralDescription(props) {
  const { collateral } = props;

  const { isVisible } = useCollateralDescriptionVisibilityState({
    collateralType: collateral.collateralType,
  });

  if (!isVisible) {
    return null;
  }

  return (
    <LabeledContent
      label="Description"
      content={collateral.collateralDescription}
    />
  );
}

function Collateral(props) {
  const { collateral, index } = props;

  const collateralTypeLabel = (
    COLLATERAL_TYPE_OPTIONS.find(
      (option) => option.value === collateral.collateralType
    ) || {}
  ).label;

  const collateralPropertyTypeLabel = (
    COLLATERAL_PROPERTY_TYPE_OPTIONS.find(
      (option) => option.value === collateral.collateralPropertyType
    ) || {}
  ).label;

  return (
    <Fragment>
      <div className={styles.sub_header}>Collateral {index}</div>
      <GridContent>
        <LabeledContent label="Type" content={collateralPropertyTypeLabel} />
        <LabeledContent label="Class" content={collateralTypeLabel} />
      </GridContent>
      <GridContent>
        <CollateralDescription collateral={collateral} />
        <CollateralAdditionalDetails collateral={collateral} />
      </GridContent>
    </Fragment>
  );
}

export default function AUPPSRDetails(props) {
  const {
    application,
    currentUser,
    onFetchApplicationRecord,
    onSetAlert,
    onSetPPSRFinancingStatement,
    ppsrFinancingStatement,
    setCurrentPage,
  } = props;

  const debtors = (
    ppsrFinancingStatement.ppsrFinancingStatementDebtors || []
  ).map((debtor, index) => (
    <Debtor key={`debtor-${debtor.id}`} index={index + 1} debtor={debtor} />
  ));

  const collaterals = (
    ppsrFinancingStatement.ppsrFinancingStatementCollaterals || []
  ).map((collateral, index) => (
    <Collateral
      key={`collateral-${collateral.id}`}
      index={index + 1}
      collateral={collateral}
    />
  ));

  const userPpsrPermission = get(
    currentUser,
    'currentUserEntityLink.effectivePagePermissions.can_access_ppsr',
    false
  );
  const userCanAccessPpsr = FEATURE_FLAGS.FEATURE_FLAG_PPSR_PERMISSIONING
    ? userPpsrPermission
    : true;

  return (
    <Fragment>
      <Navigation
        application={application}
        ppsrFinancingStatement={ppsrFinancingStatement}
        setCurrentPage={setCurrentPage}
        disabled={!userCanAccessPpsr}
      />
      <FixedContent>
        <GridContent>
          <RegistrationPin
            currentUser={currentUser}
            onFetchApplicationRecord={onFetchApplicationRecord}
            onSetAlert={onSetAlert}
            onSetPPSRFinancingStatement={onSetPPSRFinancingStatement}
            ppsrFinancingStatement={ppsrFinancingStatement}
            disabled={!userCanAccessPpsr}
          />
        </GridContent>
        <GridContent>
          <LabeledContent
            label="Financing statement registration number"
            content={ppsrFinancingStatement.financingStatementKey}
          />
          <ExpiryDate ppsrFinancingStatement={ppsrFinancingStatement} />
        </GridContent>
        <GridContent>
          <LabeledContent
            label="Giving of notice identifier"
            content={ppsrFinancingStatement.registrationReference}
          />
        </GridContent>
      </FixedContent>
      <FixedContent>
        <div className={styles.sub_header}>Grantor details</div>
        {debtors}
      </FixedContent>
      <FixedContent>
        <div className={styles.sub_header}>Collateral details</div>
        {collaterals}
      </FixedContent>
      <PDFFile currentUser={currentUser} pdf={ppsrFinancingStatement.pdf} />
    </Fragment>
  );
}
