import styled from 'styled-components';
import { breakpointMax, breakpointMin } from 'variables/responsive';
import { FONT_SIZES } from 'variables/typography';

export const Wrapper = styled.div`
  margin-top: 35px;

  ${breakpointMin('lg')} {
    margin-top: 128px;
  }
`;

export const Header = styled.div`
  font-size: ${FONT_SIZES.heading};
  margin-bottom: 30px;
`;

export const Content = styled.div`
  margin-bottom: 44px;
`;

export const ActionContent = styled.div`
  margin-bottom: 25px;
`;

export const ButtonContainer = styled.div`
  ${breakpointMax('md')} {
    display: flex;
    justify-content: center;
  }
`;
