import { IRuleSetAttributes } from '../type';
import { processDirectDebitAddons } from '../utils';

export const getAdditionTabPauseReasons = (
  rulesetAttributes: IRuleSetAttributes,
  tradeReferenceCount: number = 0,
  directDebitAddons: any
) => {
  const {
    trade_reference: isTradeReferenceRequiredInRuleset,
    trade_reference_count: rulesetTradeReferenceCount,
    paperless_check: isDirectDebitRequiredInRuleset,
    additions_status: status,
  } = rulesetAttributes;

  if (status !== 'paused') {
    return {
      directDebit: false,
      tradeReference: false,
    };
  }

  // If direct debit is required in the auto decision ruleset,
  // check if there is a matching direct debit addon.
  // If there is a matching direct debit addon, then it means that the ruleset
  // was not paused due to change in direct debit addons.
  // If no matching direct debit addon is found, then we know that a change in
  // the direct debit addons caused the ruleset to be paused.
  const directDebit = isDirectDebitRequiredInRuleset
    ? !processDirectDebitAddons(directDebitAddons, rulesetAttributes)
    : false;

  // If trade reference check is required in the auto decision ruleset,
  // check if the count in the ruleset is greater than the count in the settings.
  // If that is the case, we know that the ruleset was paused due to a change in
  // the trade reference count.
  const tradeReference =
    isTradeReferenceRequiredInRuleset &&
    rulesetTradeReferenceCount !== null &&
    rulesetTradeReferenceCount !== undefined
      ? rulesetTradeReferenceCount > tradeReferenceCount
      : false;

  return {
    directDebit,
    tradeReference,
  };
};
