import { Divider } from '@material-ui/core';
import { FEATURE_FLAGS } from 'conf';
import { isFeatureAvailable } from 'modules/shared/helpers/headquarterDetect';
import React from 'react';

import {
  HistoryLogs,
  ImportSection,
  PrepareSection,
  Status,
  UploadSection,
} from './components';
import { RapidTransferContextProvider } from './components/RapidTransferContext';
import { isMobileDevice } from 'modules/shared/helpers/mobileDetect';

const RapidTransfer: React.FC = () => {
  if (
    !FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER ||
    !isFeatureAvailable('rapid_transfer') ||
    isMobileDevice()
  ) {
    return null;
  }

  return (
    <RapidTransferContextProvider>
      <PrepareSection />
      <Divider />
      <UploadSection />
      <Divider />
      <ImportSection />
      <Status />
      <HistoryLogs />
    </RapidTransferContextProvider>
  );
};

export default RapidTransfer;
