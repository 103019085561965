import React from 'react';
import styles from './css/SquareModal.css';

import CloseButton from 'modules/shared/components/inputs/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SquareModal(props) {
  const { children, dismissHandler, size, title, title_css } = props;

  let dismissButton = null;
  let modal_title = null;
  let content_style = styles.content;
  let title_style = styles.title;

  if (size) {
    content_style = styles['content_' + size];
  }

  if (title_css) {
    title_style = styles[title_css];
  }

  if (dismissHandler) {
    dismissButton = (
      <CloseButton
        css_class="button_position_top106x40"
        text="Close"
        handleClick={dismissHandler}
      />
    );
  }

  if (title) {
    modal_title = <h2 className={title_style}>{title}</h2>;
  }

  return (
    <div className={styles.modal}>
      {dismissButton}
      <div className={styles.fade}>
        <div className={content_style}>
          <button onClick={dismissHandler} className={styles.mobile_close_btn}>
            <FontAwesomeIcon icon="times" />
          </button>
          <div className={'square-modal-body ' + styles.body}>
            {modal_title}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SquareModal;
