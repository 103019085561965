const GENERIC_ERROR_MESSAGE = 'An unexpected error occurred';

type ErrorMessageItem = {
  code: string;
  detail: string;
  status: string;
  title: string;
};

/**
 * Represents the structure of an API error response.
 */
type ApiErrorResponse = {
  response: {
    status: number;
    data?: {
      error?: string;
      errors?: ErrorMessageItem[];
    };
  };
};

/**
 * Represents a general error response.
 */
type GeneralErrorResponse = {
  error?: string;
  message?: string;
};

/**
 * Represents a custom error that can be either an API error response or a general error response.
 */
type CustomError = ApiErrorResponse | GeneralErrorResponse;

/**
 * Represents the structure of a processed error.
 */
type ProcessedError = {
  errorObject: any;
  errorMessage: string;
  errorMessages: ErrorMessageItem[];
  httpStatusCode?: number;
};

/**
 * Processes a custom error and returns a structured ProcessedError object.
 *
 * @param {CustomError} error - The error to be processed.
 * @returns {ProcessedError} The processed error object with
 * errorMessage, errorMessages, the original errorObject and optional httpStatusCode.
 */
export const processError = (error: CustomError): ProcessedError => {
  let errorObject = {};
  let errorMessage: string = GENERIC_ERROR_MESSAGE;
  let errorMessages: ErrorMessageItem[] = [];
  let httpStatusCode: number | undefined;

  if ('response' in error) {
    // The error is most likely thrown by an API call
    const {
      response: { status, data },
    } = error;

    httpStatusCode = status;

    if (data) {
      if (typeof data === 'string') {
        errorMessage = data;
      } else {
        const { error: apiError, errors: apiErrors } = data;

        errorObject = data;
        errorMessage = apiError || errorMessage;
        errorMessages = apiErrors || errorMessages;
      }
    }
  } else if ('message' in error) {
    // Generic error
    errorMessage = error.message || errorMessage;
  } else if ('error' in error) {
    // Another generic error
    errorMessage = error.error || errorMessage;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  return { errorObject, errorMessage, errorMessages, httpStatusCode };
};
