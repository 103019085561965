import React, { useContext } from 'react';

import { Error } from './Error';
import { Progress } from './Progress';
import { Success } from './Success';
import { RapidTransferContext } from '../RapidTransferContext';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 140px;
`;

export const Status = () => {
  const { auJob, nzJob } = useContext(RapidTransferContext);

  if (auJob === null && nzJob === null) {
    return null;
  }

  const renderAuResult = () => {
    if (auJob?.status === 'SUCCESS') {
      return <Success />;
    }
    if (auJob?.errorCsvUrl) {
      return (
        <Error
          csvUrl={auJob.errorCsvUrl}
          hasSuccessEntries={auJob.hasSuccessEntries}
        />
      );
    }
    if (auJob?.status === 'NOT_STARTED' || auJob?.status === 'IN_PROGRESS') {
      return <Progress secondsRemaining={auJob.secondsRemaining} />;
    }
    return null;
  };

  const renderNzResult = () => {
    if (nzJob?.status === 'SUCCESS') {
      return <Success />;
    }
    if (nzJob?.errorCsvUrl) {
      return (
        <Error
          csvUrl={nzJob.errorCsvUrl}
          hasSuccessEntries={nzJob.hasSuccessEntries}
        />
      );
    }
    if (nzJob?.status === 'NOT_STARTED' || nzJob?.status === 'IN_PROGRESS') {
      return <Progress secondsRemaining={nzJob.secondsRemaining} />;
    }
    return null;
  };

  return (
    <Container>
      {renderAuResult()}
      {renderNzResult()}
    </Container>
  );
};
