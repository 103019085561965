import React from 'react';

/* Import CSS */
import styles from './css/Review.css';

import LabeledInformation from '../../shared/components/widgets/static/LabeledInformation';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { FEATURE_FLAGS } from 'conf';
import { TRUSTEE_LABEL_TYPE_MAP } from 'modules/new-applications/components/application-sections/BusinessDetails/People/constants';

var ReviewGuarantors = createClass({
  render: function () {
    const { data } = this.props;
    let guarantors = data.guarantors;
    const application = data.application.attributes;

    const showPositionLabel =
      application.legal_type === 'trust' &&
      FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;

    let guarantorElms = [];
    guarantors.forEach((v, i) => {
      guarantorElms.push(
        <ReviewContainer key={i} subHeading={'Guarantor ' + (i + 1)}>
          <div className={styles.col}>
            <LabeledInformation
              label="Name"
              data={v.attributes.full_name}
              noHumanize={true}
            />
          </div>
          <div className={styles.col}>
            <LabeledInformation label="Email" data={v.attributes.email} />
          </div>
          {showPositionLabel && (
            <LabeledInformation
              label="Position"
              data={TRUSTEE_LABEL_TYPE_MAP[v.attributes.legal_type]}
            />
          )}
        </ReviewContainer>
      );
    });

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {guarantorElms}
      </section>
    );
  },
});

module.exports = ReviewGuarantors;
