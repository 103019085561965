import axios from 'axios';
import get from 'lodash.get';
import { browserHistory } from 'react-router';
import store from 'stores/store';

function Axios(config) {
  const processedAxios = axios.create(config);
  processedAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const currentUser = store.getState().current_user;
      if (
        error &&
        get(error, 'response.status') === 401 &&
        currentUser.loggedIn
      ) {
        browserHistory.push({
          pathname: '/user/logout',
          state: {
            referrer: window.location.pathname,
          },
        });
      }
      return Promise.reject(error);
    }
  );

  return processedAxios;
}

export default Axios;
