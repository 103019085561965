import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#people-creating-a-person-for-an-entity
 */
const Reviews = function(config) {
  this.axios = axios(config);
  this.type = 'reviews';
};

Reviews.prototype.createReview = function(
  attributes,
  applicationId,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        relationships: {
          application: {
            data: {
              id: applicationId,
              type: 'applications',
            },
          },
        },
        type: this.type,
      },
      include: 'review_histories',
    })
    .then(success)
    .catch(error);
};

Reviews.prototype.updateReview = function(id, attributes, success, error) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        attributes,
        id,
        type: this.type,
      },
      include: 'review_histories',
    })
    .then(success)
    .catch(error);
};

Reviews.prototype.lastReviewCheck = function({
  applicationId,
  creditLimit,
  tradeAccountLimit,
  level,
}) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.post(`/${this.type}/last_review_check`, {
    application_id: applicationId,
    credit_limit: creditLimit,
    level,
    trade_account_limit: tradeAccountLimit,
  });
};

Reviews.prototype.deescalate = function({
  id,
  notes,
  onErrorCallback,
  onSuccessCallback,
}) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios
    .post(`/${this.type}/${id}/deescalate`, {
      data: { notes },
    })
    .then(onSuccessCallback)
    .catch(onErrorCallback);
};

export default Reviews;
