import Logo from 'images/svgs/external_link.svg';
import React, { Fragment, ReactElement } from 'react';

import { Description, DrawerPanel, Header, TermsUrlWrapper } from '../styles';
import { IconHolder, SupplierLink } from './styles';
import { FEATURE_FLAGS } from 'conf';

const termTypeLabel = {
  supplier: 'General T&Cs',
  guarantor: 'Guarantor T&Cs',
  paperless: 'Paperless T&Cs',
};

export const TermsPanel = ({
  supplierTerms,
  termsList,
}: {
  supplierTerms?: string;
  termsList?: { [key: string]: string }[];
}): ReactElement => {
  const onClick = (url) => {
    const win = window.open('', 'pdf_window');
    if (FEATURE_FLAGS.FEATURE_FLAGS_AUTH_TNCS && win && url) {
      win.open(url, 'pdf_window');
    }
    if (win && supplierTerms) {
      win.open(supplierTerms, 'pdf_window');
    }
  };

  return (
    <DrawerPanel>
      <Header>Terms &amp; Conditions</Header>
      {FEATURE_FLAGS.FEATURE_FLAGS_AUTH_TNCS ? (
        <Fragment>
          {supplierTerms && (
            <Description>
              {' '}
              T&amp;C&apos;s are downloadable if required, click{' '}
              <SupplierLink onClick={() => onClick(supplierTerms)}>
                here
              </SupplierLink>{' '}
              to access in new tab
              <IconHolder>
                <Logo
                  height="20px"
                  width="20px"
                  onClick={() => onClick(supplierTerms)}
                />
              </IconHolder>
            </Description>
          )}
          {termsList && (
            <TermsUrlWrapper>
              {termsList.map((termItem) => {
                return (
                  <Fragment>
                    <SupplierLink onClick={() => onClick(termItem.url)}>
                      {termTypeLabel[termItem.type]}
                    </SupplierLink>
                    <IconHolder>
                      <Logo
                        height="20px"
                        width="20px"
                        onClick={() => onClick(termItem.url)}
                      />
                    </IconHolder>
                    <br />
                  </Fragment>
                );
              })}
            </TermsUrlWrapper>
          )}
        </Fragment>
      ) : (
        <Description>
          {' '}
          T&amp;C&apos;s are downloadable if required, click{' '}
          <SupplierLink onClick={onClick}> here</SupplierLink> to access in new
          tab
          <IconHolder>
            <Logo height="20px" width="20px" onClick={onClick} />
          </IconHolder>
        </Description>
      )}
    </DrawerPanel>
  );
};
