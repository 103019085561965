import React, { ReactElement } from 'react';

const MenuItem = ({
  children,
  section,
  activeSection,
  onClick,
}: {
  children: any;
  section: string;
  activeSection: string;
  onClick: (event: any) => void;
}): ReactElement => {
  const activeMenuClassName = (section: string): string => {
    return section === activeSection ? 'is-active' : '';
  };

  return (
    <li>
      <a
        className={activeMenuClassName(section)}
        data-section={section}
        onClick={onClick}
      >
        {children}
      </a>
    </li>
  );
};

export default MenuItem;
