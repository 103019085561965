export const ACCOUNT_STATUS_OPTIONS = [
  {
    label: 'Undesirable',
    value: 'undesirable',
  },
  {
    label: 'Monitoring',
    value: 'monitoring',
  },
  {
    label: 'Write off',
    value: 'write_off',
  },
  {
    label: 'Delinquent 60 Days',
    value: 'days_60',
  },
  {
    label: 'Delinquent 90 Days',
    value: 'days_90',
  },
  {
    label: 'Delinquent >120 Days +',
    value: 'days_120_plus',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
  {
    label: 'Staff account',
    value: 'staff_account',
  },
];
