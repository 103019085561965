import React from 'react';
import styles from 'modules/shared/components/widgets/static/css/LabeledInputContent.css';

export default function LabeledContent(props) {
  const { children, label } = props;

  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      {children}
    </div>
  );
}
