/* Import libraries */
import React, { Component } from 'react';

import CloseButton from 'modules/shared/components/inputs/CloseButton';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import { connect } from 'react-redux';
import styles from './css/CreditCheckModal.css';

/* Import components */

class AmlCheckModal extends Component {
  nzAmlCheckProviders() {
    const { nzAmlCheckProviders } = this.props;

    if (nzAmlCheckProviders.length === 0) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div className={styles.subTitle}>AML Checks New Zealand</div>

        <p className={styles.bodyText}>
          You have the option of completing an AML check where the individuals
          have approved, on the applicant, cardholder/s and guarantors. The
          checks completed include drivers licence verification, address
          verification, PEP/sanctions list. 1Centre Suppliers should take legal
          advice regarding your AML/CFT identity verification compliance
          requirements.
        </p>
        {nzAmlCheckProviders}
      </div>
    );
  }

  auAmlCheckProviders() {
    const { auAmlCheckProviders } = this.props;

    if (auAmlCheckProviders.length === 0) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div className={styles.subTitle}>AML Checks Australia</div>
        <p className={styles.bodyText}>
          You can verify the applicant and/or guarantors Drivers Licence or
          Passport manually at this stage. By selecting this option we ask your
          customer to provide details of their ID and enable them to take a
          photo of it.
        </p>
        {auAmlCheckProviders}
      </div>
    );
  }

  creditCardMessage() {
    const { card, roleTypes } = this.props;

    if (!card && roleTypes.includes('admin')) {
      return (
        <span className={styles.message}>
          Please ask your administrator to add credit card.
        </span>
      );
    }
  }

  render() {
    const {
      title,
      button,
      dismissHandler,
      closeButtonStyle,
      updateLoading,
    } = this.props;

    if (updateLoading) {
      return (
        <div className={styles.modal}>
          <SimpleLoader />
        </div>
      );
    }

    return (
      <div className={styles.modal}>
        <div className={styles.modal}>
          <CloseButton
            text="Close"
            css_class={closeButtonStyle}
            handleClick={dismissHandler}
          />

          <div className={styles.fade}>
            <div className={styles.content}>
              <div className={styles.container}>
                <div className={styles.col}>
                  <div className={styles.body}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.titleDesc}>
                      If you are trading in New Zealand and require AML checks
                      on New Zealand residents we are able to provide this
                      option for you.
                    </p>

                    {this.nzAmlCheckProviders()}
                    {this.auAmlCheckProviders()}
                    {this.creditCardMessage()}
                    {button}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    card: state.manage_profile.current_entity_card,
    roleTypes:
      state.current_user.current_user_entity_link.attributes.role_types,
  };
})(AmlCheckModal);
