/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

/* Import CSS */
import styles from './css/RejectTerms.css';

/* Import components */
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import Button from 'modules/shared/components/inputs/Button';
import TextArea from 'modules/shared/components/inputs/TextArea';

var RejectTerms = createClass({
  getInitialState: function() {
    return { canceling: false };
  },

  skip: function() {
    const { handleBail } = this.props;
    handleBail();
  },

  send: function() {
    const { handleBail } = this.props;
    handleBail();
  },

  render: function() {
    const { title, handleDismiss } = this.props;

    return (
      <CircleModal
        title={title}
        title_css="title_small"
        dismissHandler={handleDismiss}
      >
        <div className={styles.fieldset}>
          <TextArea label="Feedback" />
        </div>
        <div className={styles.button}>
          <Button white={true} text="skip" handleClick={this.skip} />
          <Button text="send" handleClick={this.send} />
        </div>
      </CircleModal>
    );
  },
});

var defaults = {
  title: "We're sorry it didn't work out",
};

module.exports = connect((state, ownProps) => {
  return {
    title: defaults.title,
  };
})(RejectTerms);
