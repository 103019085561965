import 'c3/c3.css';

import { DDS_SCORE_RANGE, GREY } from '../constants';
import React, { Component } from 'react';

import AdverseInformation from './AdverseInformation';
import C3Chart from 'react-c3js';
import PaymentRiskAnalysis from './PaymentRiskAnalysis';
import Popover from '@material-ui/core/Popover';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';
import { formatDate } from 'utils/dateFormatter';
import get from 'lodash.get';
import { getRiskClass } from '../PaymentPredictorComponent';
import isBlank from 'utils/isBlank';
import styles from '../css/payment-predictor/Summary.css';

const DONUT_DATA = [
  ['A', 1],
  ['B', 1],
  ['C', 1],
  ['D', 1],
  ['E', 1],
  ['F', 1],
  ['G', 1],
  ['H', 1],
];

function getColors(ddsScore) {
  const score = parseInt(ddsScore);
  const colors = {};
  const grades = Object.keys(DDS_SCORE_RANGE);

  for (const grade of grades) {
    const range = DDS_SCORE_RANGE[grade];
    let color = GREY;

    if (score <= range.max) {
      color = range.color;
    }

    colors[grade] = color;
  }

  return colors;
}

export default class Summary extends Component {
  get companyBasicDetails() {
    return this.props.response.company_basic_details || {};
  }

  get summaryInformation() {
    return this.props.response.summary_information || {};
  }

  get dds() {
    return this.props.response.dynamic_delinquency_score || {};
  }

  get reportDate() {
    return formatDate(this.props.sequence_date, 'DD MMMM YYYY');
  }

  constructor(props) {
    super(props);

    this.donutLegends = this.buildDonutLegends();

    this.state = { popoverAnchorElement: null };
  }

  buildDonutLegends() {
    const donuts = DONUT_DATA.map((donutData, i) =>
      this.renderDonutLegend(donutData, i)
    );

    return <div className={styles.donut_legend}>{donuts}</div>;
  }

  onDonutMouseOver = (event) => {
    this.setState({ popoverAnchorElement: event.currentTarget });
  };

  onDonutMouseOut = () => {
    this.setState({ popoverAnchorElement: null });
  };

  renderDDS() {
    const { popoverAnchorElement } = this.state;

    return (
      <div>
        <div
          className={styles.dds_content}
          onMouseOver={this.onDonutMouseOver}
          onMouseOut={this.onDonutMouseOut}
        >
          {this.renderDonut()}
          {this.renderDescription()}
        </div>
        <Popover
          anchorEl={popoverAnchorElement}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          className={commonStyles.popover}
          disableRestoreFocus
          open={Boolean(popoverAnchorElement)}
          transitionDuration={0}
          onClose={this.onDonutMouseOut}
        >
          {this.donutLegends}
        </Popover>
      </div>
    );
  }

  renderDonut() {
    const ddsScore = this.summaryInformation.dds_score;

    if (isBlank(ddsScore)) {
      return;
    }

    const riskClass = getRiskClass(ddsScore);
    const score = DDS_SCORE_RANGE[riskClass];
    const title = `${score.riskLevel.toUpperCase()} RISK`;
    const colors = getColors(this.summaryInformation.dds_score);
    const data = {
      colors,
      columns: DONUT_DATA,
      type: 'donut',
    };

    const donutProperties = {
      expand: false,
      label: {
        show: false,
      },
      title,
    };

    const tooltipProperties = {
      show: false,
    };

    return (
      <C3Chart
        data={data}
        size={{ height: 200, width: 200 }}
        interaction={{ enabled: false }}
        legend={{ hide: true }}
        donut={donutProperties}
        tooltip={tooltipProperties}
      />
    );
  }

  renderDescription() {
    return (
      <div className={styles.dds_description}>
        <div className={commonStyles.emphasize}>Late payment risk</div>
        <p>
          {this.companyBasicDetails.organisation_name} has a{' '}
          {this.dds.sev_delinq_pay_probability}% probability of late payment in
          the coming 12 months.
        </p>
      </div>
    );
  }

  renderDnbRating() {
    const dnbRating = get(this.summaryInformation, 'dnb_rating', '');
    const [financialStrength, creditAppraisal] = dnbRating.split(' ');

    return (
      <div className={styles.dnb_rating_container}>
        <div className={commonStyles.emphasize}>D&amp;B rating</div>
        <div className={styles.dnb_rating_content}>
          <div className={styles.dnb_rating_summary_container}>
            <div className={styles.dnb_rating_summary}>{dnbRating}</div>
          </div>
          <div className={styles.dnb_rating_details}>
            <div>
              <p className={commonStyles.emphasize}>{financialStrength}</p>
              <p>Financial strength</p>
            </div>
            <div>
              <p className={commonStyles.emphasize}>{creditAppraisal}</p>
              <p>Credit appraisal</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDonutLegend(donutData, i) {
    const score = DDS_SCORE_RANGE[donutData[0]];
    const colors = getColors(score.max);
    const title = `${score.riskLevel.toUpperCase()} RISK`;

    const data = {
      colors,
      columns: DONUT_DATA,
      type: 'donut',
    };

    const donutProperties = {
      expand: false,
      label: {
        show: false,
      },
      title,
    };

    const tooltipProperties = { show: false };

    return (
      <C3Chart
        key={`donut-legend-${i}`}
        data={data}
        size={{ height: 200, width: 200 }}
        interaction={{ enabled: false }}
        legend={{ hide: true }}
        donut={donutProperties}
        tooltip={tooltipProperties}
      />
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Risk of Late Payment Report (DDS)"
        content_class="content_wide"
        css_class="block_noborder"
      >
        <div className={styles.summary_header}>
          <div className={commonStyles.emphasize}>Report Summary</div>
          <div>Date: {this.reportDate}</div>
        </div>
        <div className={styles.report_summary_content}>
          {this.renderDDS()}
          {this.renderDnbRating()}
        </div>
        <PaymentRiskAnalysis {...this.props} />
        <AdverseInformation {...this.props} />
      </ReviewContainer>
    );
  }
}
