import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import api from 'api';
import debounce from 'debounce';
import get from 'lodash.get';
import { ContentContainer } from 'modules/addons/components//DigitalOnboarding';
import commonStyles from 'modules/addons/components/css/PPSRRegistration/EditComponent.css';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import TextInput from 'modules/shared/components/inputs/TextInput';
import UncontrolledTextInput from 'modules/shared/components/inputs/UncontrolledTextInput';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import React, { Fragment, useRef, useState } from 'react';

const DEBOUNCE_INTERVAL = 500;

const SPG_DETAILS_SUB_HEADER = {
  AU: `If you have not registered, enter your details here and we will register
    an SPG for you. You will be able to attach this group to your
    organisation account if you ever open an account with the Australian PPSR.
    *Please be aware that every PPSR registered you will
    receive a Registration Statement and PIN Statement directly from the
    Australian PPSR. If you envisage high volumes we recommend a
    centralised email address for these to be stored.`,
  NZ: `If you have not registered, enter your details here and we will register
    an SPG for you. You will be able to attach this group to your
    organisation account if you ever open an account with the PPSR NZ
    Companies office. *Please be aware that every PPSR registered you will
    receive a Registration Statement and PIN Statement directly from the
    companies office. If you envisage high volumes we recommend a
    centralised email address for these to be stored.`,
};

const SPG_PASSWORD_LABEL = {
  AU: 'Access code',
  NZ: 'Password',
};

function SpgSubHeader(props) {
  const { region } = props;

  if (region === 'NZ') {
    return (
      <Fragment>
        If you are already registered with PPSR NZ Companies office and have a
        SPG ID enter it here with your PIN, this can be found on your dashboard
        page. <span className="has-text-weight-normal">IMPORTANT:</span> As your
        financing statements will now be generated via the 1Centre Application
        Programming Interface (API), you need to turn on your &apos;Email
        notification options&apos; in your SPG and change from NO to YES.
      </Fragment>
    );
  }

  if (region === 'AU') {
    return (
      <Fragment>
        If you are already registered with the Australian PPSR page and have an
        SPG number enter it here with your access code, this can be found on
        your dashboard page.{' '}
        <span className="has-text-weight-normal">IMPORTANT:</span> As your
        financing statements will now be generated via the 1Centre Application
        Programming Interface (API), you need to turn on your &apos;Email
        notification options&apos; in your SPG and change from NO to YES.
      </Fragment>
    );
  }

  return null;
}

function SpgPasswordAdornment(props) {
  const { currentUser, isPasswordVisible, onClick } = props;

  if (currentUser.isStandard) {
    return null;
  }

  let Icon = VisibilityOffOutlinedIcon;
  if (isPasswordVisible) {
    Icon = VisibilityOutlinedIcon;
  }

  return (
    <InputAdornment position="end">
      <Icon className="cursor-pointer" onClick={onClick} />
    </InputAdornment>
  );
}

function useAddressLookupState({
  accessToken,
  addressType,
  region,
  setFullAddress,
}) {
  const [addressOptions, setAddressOptions] = useState({});
  const [rawAddressOptions, setRawAddressOptions] = useState({});
  const { isLoading, setIsLoading } = useIsLoadingState();

  const addressSearchAPI = api('address_search', accessToken);
  const onSearchSuccessCallback = (result) => {
    const addressSearchResult = get(result, 'data.data');
    const rawAddresses = {};
    const addresses = {};

    for (const address of addressSearchResult) {
      rawAddresses[address.api_id] = address;
      addresses[address.api_id] = address.full_address;
    }

    setAddressOptions(addresses);
    setRawAddressOptions(rawAddresses);

    setIsLoading(false);
  };

  const searchAddress = (params) =>
    addressSearchAPI.addressSearch(
      onSearchSuccessCallback,
      (error) => {
        setIsLoading(false);
        console.error(error);
      },
      { params }
    );
  const debouncedSearchAddress = useRef(
    debounce(searchAddress, DEBOUNCE_INTERVAL)
  ).current;

  const onSearch = (event) => {
    const keyword = get(event, 'target.value', '');

    setFullAddress(keyword);

    if (keyword.length > 1) {
      setIsLoading(true);

      debouncedSearchAddress({
        address: keyword,
        region,
        type: addressType,
      });
    }
  };

  return { addressOptions, isLoading, onSearch, rawAddressOptions };
}

function AddressFinder(props) {
  const {
    clearError,
    currentUser,
    error,
    isDisabled,
    label,
    text,
    region,
    setFullAddress,
    setRawAddress,
  } = props;
  const {
    addressOptions,
    isLoading,
    rawAddressOptions,
    onSearch,
  } = useAddressLookupState({
    accessToken: currentUser.accessToken,
    addressType: 'All',
    region,
    setFullAddress,
  });

  const onClick = (value) => {
    const selectedAddress = rawAddressOptions[value];

    setFullAddress(selectedAddress.full_address);
    setRawAddress(selectedAddress);
    clearError('organisationAddress');
  };

  return (
    <AutoSuggest
      label={label}
      value={text}
      loading={isLoading}
      suggest_items={addressOptions}
      handleChange={onSearch}
      handleClick={onClick}
      required={true}
      css_class="address_lookup"
      error={error}
      disabled={isDisabled}
    />
  );
}

export default function SecurityPartyGroup(props) {
  const {
    addressState,
    clearError,
    currentUser,
    errors,
    isSPGDetailsDisabled,
    isSpgPasswordVisible,
    onBlurSpgCredentials,
    region,
    register,
    setIsSpgPasswordVisible,
    spgPwdRef,
    versionConfig,
  } = props;

  return (
    <ContentContainer header="Step 1: Security party group (SPG) details.">
      <div className={commonStyles.sub_header}>
        <SpgSubHeader region={region} />
      </div>
      <div className={commonStyles.input_fields}>
        <UncontrolledTextInput
          id="spgId"
          label="SPG ID"
          inputRef={register}
          autocomplete="off"
          error={get(errors, 'spgId.message')}
          defaultValue={get(versionConfig, 'secured_party_group.spg_id')}
          formControlProps={{
            onBlur: onBlurSpgCredentials,
          }}
          shrinkLabel={true}
        />
        <UncontrolledTextInput
          id="spgPwd"
          label={SPG_PASSWORD_LABEL[region]}
          type={isSpgPasswordVisible ? 'text' : 'password'}
          inputRef={(e) => {
            register(e);
            spgPwdRef.current = e;
          }}
          autocomplete="new-password"
          error={get(errors, 'spgPwd.message')}
          endAdornment={
            <SpgPasswordAdornment
              currentUser={currentUser}
              isPasswordVisible={isSpgPasswordVisible}
              onClick={() => setIsSpgPasswordVisible(!isSpgPasswordVisible)}
            />
          }
          defaultValue={get(versionConfig, 'secured_party_group.spg_pwd')}
          formControlProps={{
            onBlur: onBlurSpgCredentials,
          }}
          shrinkLabel={true}
        />
      </div>
      <div className={commonStyles.sub_header}>
        {SPG_DETAILS_SUB_HEADER[region]}
      </div>
      <div className={commonStyles.input_fields}>
        <UncontrolledTextInput
          id="personFirstName"
          label="First name"
          inputRef={register}
          error={get(errors, 'personFirstName.message')}
          defaultValue={get(
            versionConfig,
            'secured_party_group.person.first_name'
          )}
          disabled={isSPGDetailsDisabled}
        />
        <UncontrolledTextInput
          id="personLastName"
          label="Last name"
          inputRef={register}
          error={get(errors, 'personLastName.message')}
          defaultValue={get(
            versionConfig,
            'secured_party_group.person.last_name'
          )}
          disabled={isSPGDetailsDisabled}
        />
      </div>
      <div className={commonStyles.input_fields}>
        <TextInput
          id="organisationType"
          label="Type"
          disabled={true}
          value="Organisation"
          required={true}
        />
        <UncontrolledTextInput
          id="organisationName"
          label="Name"
          inputRef={register}
          error={get(errors, 'organisationName.message')}
          defaultValue={get(
            versionConfig,
            'secured_party_group.organisation.name'
          )}
          disabled={isSPGDetailsDisabled}
        />
      </div>
      <div className={commonStyles.input_fields}>
        <UncontrolledTextInput
          id="organisationEmail"
          label="Financing statement recipient email"
          inputRef={register}
          error={get(errors, 'organisationEmail.message')}
          defaultValue={get(
            versionConfig,
            'secured_party_group.organisation.email'
          )}
          disabled={isSPGDetailsDisabled}
        />
        <UncontrolledTextInput
          id="organisationPhone"
          label="Phone"
          inputRef={register}
          error={get(errors, 'organisationPhone.message')}
          defaultValue={get(
            versionConfig,
            'secured_party_group.organisation.phone'
          )}
          disabled={isSPGDetailsDisabled}
        />
      </div>
      <div className={commonStyles.input_fields}>
        <AddressFinder
          id="organisationAddress"
          label="Business address"
          text={addressState.fullAddress}
          setFullAddress={addressState.onSetNewFullAddress}
          setRawAddress={addressState.onSetNewRawAddress}
          clearError={clearError}
          currentUser={currentUser}
          error={get(errors, 'organisationAddress.message')}
          region={region}
          isDisabled={isSPGDetailsDisabled}
        />
      </div>
    </ContentContainer>
  );
}
