import React, { Fragment } from 'react';
import styles from './report-styles.css';
import { formatDate } from 'utils/dateFormatter';

const ReportIdentity = (props) => {
  let { enquiryReport, primaryMatch, individualName, address, employerList } =
    props;

  let dateOfBirth = primaryMatch.date_of_birth || {},
    gender = primaryMatch.gender || null,
    fileNo = primaryMatch.bureau_reference || null,
    driversLicence = primaryMatch.drivers_licence || {};

  if (!enquiryReport) return <p>Information Unavailable</p>;

  if (address.constructor !== Array) {
    address = [{ ...address }];
  }

  let individualFullName =
    individualName.first_given_name +
    ' ' +
    (individualName.other_given_name
      ? individualName.other_given_name + ' '
      : '') +
    individualName.family_name;

  return (
    <Fragment>
      <div>
        <p className="is-pulled-right">
          Identities:{' '}
          {!primaryMatch['@id_index'] ? 0 : primaryMatch['@id_index']}
        </p>
        <br />
        <div className={styles.table + ' mt-2'}>
          <div className={styles.row}>
            <div className={styles.th + ' ' + styles.col_2}>ID source</div>
            <div className={styles.th + ' ' + styles.col_4}>Names</div>
            <div className={styles.th + ' ' + styles.col_2}>
              First reported date
            </div>
            <div className={styles.th + ' ' + styles.col_2}>
              Last reported date
            </div>
            <div className={styles.th + ' ' + styles.col_2}>
              Last credit enquiry
            </div>
          </div>
          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_12}>Primary</div>
          </div>
          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_2}>P</div>
            <div className={styles.td + ' ' + styles.col_4}>
              {individualFullName}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!individualName['@first_reported_date']
                ? '-'
                : formatDate(
                    individualName['@first_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!individualName['@last_reported_date']
                ? '-'
                : formatDate(
                    individualName['@last_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!individualName['@last_credit_enquiry_date']
                ? '-'
                : formatDate(
                    individualName['@last_credit_enquiry_date'],
                    'DD MMM YYYY'
                  )}
            </div>
          </div>
        </div>
        <div className={styles.table + ' mt-6'}>
          <div className={styles.row}>
            <div className={styles.th + ' ' + styles.col_2}>ID data</div>
            <div className={styles.th + ' ' + styles.col_4}>Details</div>
            <div className={styles.th + ' ' + styles.col_2}>
              First reported date
            </div>
            <div className={styles.th + ' ' + styles.col_2}>
              Last reported date
            </div>
            <div className={styles.th + ' ' + styles.col_2}>ID source</div>
          </div>

          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_2}>Date of birth</div>
            <div className={styles.td + ' ' + styles.col_4}>
              {!dateOfBirth ? '-' : formatDate(dateOfBirth, 'DD MMM YYYY')}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!dateOfBirth['@first_reported_date']
                ? '-'
                : formatDate(
                    dateOfBirth['@first_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!dateOfBirth['@last_reported_date']
                ? '-'
                : formatDate(dateOfBirth['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>P</div>
          </div>

          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_2}>
              Drivers licence
            </div>
            <div className={styles.td + ' ' + styles.col_4}>
              {!driversLicence.number ? '-' : driversLicence.number}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!driversLicence['@first_reported_date']
                ? '-'
                : formatDate(
                    driversLicence['@first_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!driversLicence['@last_reported_date']
                ? '-'
                : formatDate(
                    driversLicence['@last_reported_date'],
                    'DD MMM YYYY'
                  )}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>P</div>
          </div>

          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_2}>Gender</div>
            <div className={styles.td + ' ' + styles.col_4}>
              {!gender ? '-' : gender}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!gender || !gender['@first_reported_date']
                ? '-'
                : formatDate(gender['@first_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!gender || !gender['@last_reported_date']
                ? '-'
                : formatDate(gender['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>P</div>
          </div>

          <div className={styles.tr}>
            <div className={styles.td + ' ' + styles.col_2}>File no.</div>
            <div className={styles.td + ' ' + styles.col_4}>
              {!fileNo ? '-' : fileNo}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!fileNo || !fileNo['@fileNo']
                ? '-'
                : formatDate(gender['@fileNo'], 'DD MMM YYYY')}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>
              {!fileNo || !fileNo['@last_reported_date']
                ? '-'
                : formatDate(fileNo['@last_reported_date'], 'DD MMM YYYY')}
            </div>
            <div className={styles.td + ' ' + styles.col_2}>P</div>
          </div>
        </div>
        <hr />
        <div className={styles.table + ' mt-2'}>
          {address.map((a, index) => {
            return (
              <div key={index} className={styles.tr}>
                <div className={styles.td + ' ' + styles.col_2}>
                  {index == 0 ? 'Addresses' : ''}
                </div>
                <div className={styles.td + ' ' + styles.col_4}>
                  {`${!a.unit_number ? '' : `${a.unit_number}/`}
                     ${a.street_number || ''} ${a.street_name || ''} ${
                       a.street_type || ''
                     }
                     ${a.suburb || ''} ${a.state || ''} ${a.postcode || ''}`}
                </div>
                <div className={styles.td + ' ' + styles.col_2}>
                  {!a['@first_reported_date']
                    ? '-'
                    : formatDate(a['@first_reported_date'], 'DD MMM YYYY')}
                </div>
                <div className={styles.td + ' ' + styles.col_2}>
                  {!a['@last_reported_date']
                    ? '-'
                    : formatDate(a['@last_reported_date'], 'DD MMM YYYY')}
                </div>
                <div className={styles.td + ' ' + styles.col_2}>P</div>
              </div>
            );
          })}
        </div>
        <hr />
        <div className={styles.table + ' mt-2'}>
          {employerList.map((employerItem, index) => (
            <div key={index} className={styles.tr}>
              <div className={styles.td + ' ' + styles.col_2}>
                {index == 0 ? 'Employer' : ''}
              </div>
              <div
                key={employerItem.name}
                className={styles.td + ' ' + styles.col_4}
              >
                {!employerItem.name ? '-' : employerItem.name}
              </div>
              <div className={styles.td + ' ' + styles.col_2}>
                {!employerItem['@first_reported_date']
                  ? '-'
                  : formatDate(
                      employerItem['@first_reported_date'],
                      'DD MMM YYYY'
                    )}
              </div>
              <div className={styles.td + ' ' + styles.col_2}>
                {!employerItem['@last_reported_date']
                  ? '-'
                  : formatDate(
                      employerItem['@last_reported_date'],
                      'DD MMM YYYY'
                    )}
              </div>
              <div className={styles.td + ' ' + styles.col_2}>P</div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.row + ' mt-4'}>
        <div className={styles.col_4}>
          <label className="has-text-danger has-text-weight-semibold">
            Possible Match
          </label>
          <p>Not present on file</p>
        </div>
        <div className={styles.col_4}>
          <p>Possible match: 0</p>
        </div>
      </div>
    </Fragment>
  );
};

export default ReportIdentity;
