import {
  CONNECTIONS_ALL_CONNECTIONS_LIST_START,
  CONNECTIONS_ALL_CONNECTIONS_LIST_SUCCESS,
  CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR,
  CONNECTIONS_CONNECTION_START,
  CONNECTIONS_CONNECTION_SUCCESS,
  CONNECTIONS_CONNECTION_ERROR,
  CONNECTIONS_CREATE_STOP_CREDIT_START,
  CONNECTIONS_CREATE_STOP_CREDIT_SUCCESS,
  CONNECTIONS_CREATE_STOP_CREDIT_ERROR,
  CONNECTIONS_CHECK_FOR_STOP_CREDIT_START,
  CONNECTIONS_CHECK_FOR_STOP_CREDIT_SUCCESS,
  CONNECTIONS_CHECK_FOR_STOP_CREDIT_ERROR,
  CONNECTIONS_REMOVE_STOP_CREDIT_START,
  CONNECTIONS_REMOVE_STOP_CREDIT_SUCCESS,
  CONNECTIONS_REMOVE_STOP_CREDIT_ERROR,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_START,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_START,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_SUCCESS,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR,
  CONNECTIONS_CREATE_NOTES_START,
  CONNECTIONS_CREATE_NOTES_SUCCESS,
  CONNECTIONS_CREATE_NOTES_ERROR,
  CONNECTIONS_LOAD_NOTES_START,
  CONNECTIONS_LOAD_NOTES_SUCCESS,
  CONNECTIONS_LOAD_NOTES_ERROR,
  CONNECTIONS_LOAD_TEAMMEMBER_START,
  CONNECTIONS_LOAD_TEAMMEMBER_SUCCESS,
  CONNECTIONS_LOAD_TEAMMEMBER_ERROR,
  CONNECTIONS_SET_REGION,
  CONNECTIONS_SET_STATE,
  CONNECTIONS_CONNECTION_APPLICATIONS_LIST_START,
  CONNECTIONS_CONNECTION_APPLICATIONS_LIST_SUCCESS,
  CONNECTIONS_CONNECTION_APPLICATIONS_LIST_ERROR,
} from './constants';

var connections_defaults = {
  contact_candidate_list: [],
  contact_candidate_list_loading: false,
  all_connections_list: [],
  all_connections_list_loading: false,
  total_connections_count: 0,
  connection: null,
  connection_loading: false,
  stop_credit_apply_loading: false,
  stop_credit_check_loading: false,
  stop_credit: null,
  all_notes_list: [],
  team_members_list: [],
  region: 'All',
  state: 'All',
};

export function connectionsReducer(state = connections_defaults, action) {
  switch (action.type) {
    case CONNECTIONS_CHECK_FOR_STOP_CREDIT_START:
      return { ...state, stop_credit: null, stop_credit_check_loading: true };
    case CONNECTIONS_CHECK_FOR_STOP_CREDIT_ERROR:
      return { ...state, stop_credit: null, stop_credit_check_loading: false };
    case CONNECTIONS_CHECK_FOR_STOP_CREDIT_SUCCESS:
      return {
        ...state,
        stop_credit: action.payload,
        stop_credit_check_loading: false,
      };
    case CONNECTIONS_CREATE_STOP_CREDIT_START:
      return { ...state, stop_credit_apply_loading: true };
    case CONNECTIONS_CREATE_STOP_CREDIT_SUCCESS:
      return {
        ...state,
        stop_credit_apply_loading: false,
        stop_credit: action.payload,
      };
    case CONNECTIONS_CREATE_STOP_CREDIT_ERROR:
      return { ...state, stop_credit_apply_loading: false };
    case CONNECTIONS_REMOVE_STOP_CREDIT_START:
      return { ...state, stop_credit_apply_loading: true };
    case CONNECTIONS_REMOVE_STOP_CREDIT_SUCCESS:
      return { ...state, stop_credit_apply_loading: false, stop_credit: null };
    case CONNECTIONS_REMOVE_STOP_CREDIT_ERROR:
      return { ...state, stop_credit_apply_loading: false };
    case CONNECTIONS_CONTACT_CANDIDATES_LIST_START:
      return { ...state, contact_candidate_list_loading: true };
    case CONNECTIONS_CONTACT_CANDIDATES_LIST_SUCCESS:
      return {
        ...state,
        contact_candidate_list: action.payload,
        contact_candidate_list_loading: false,
      };

    case CONNECTIONS_ALL_CONNECTIONS_LIST_START:
      return { ...state, all_connections_list_loading: true };
    case CONNECTIONS_ALL_CONNECTIONS_LIST_SUCCESS:
      return {
        ...state,
        all_connections_list: action.payload.connections,
        total_connections_count: action.payload.total_count,
        all_connections_list_loading: false,
      };
    case CONNECTIONS_CONNECTION_START:
      return { ...state, connection_loading: true };
    case CONNECTIONS_CONNECTION_SUCCESS:
      return {
        ...state,
        connection: action.payload,
        connection_loading: false,
      };

    case CONNECTIONS_CREATE_NOTES_START:
      return { ...state, note_updating: true };
    case CONNECTIONS_CREATE_NOTES_SUCCESS:
      return {
        ...state,
        note_updating: false,
        all_notes_list: [...state.all_notes_list, action.payload],
      };
    case CONNECTIONS_CREATE_NOTES_ERROR:
      return { ...state, note_updating: false };

    case CONNECTIONS_LOAD_NOTES_START:
      return { ...state, note_updating: true };
    case CONNECTIONS_LOAD_NOTES_SUCCESS:
      return { ...state, note_updating: false, all_notes_list: action.payload };
    case CONNECTIONS_LOAD_NOTES_ERROR:
      return { ...state, note_updating: false };

    case CONNECTIONS_SET_REGION:
      return { ...state, region: action.payload };
    case CONNECTIONS_SET_STATE:
      return { ...state, state: action.payload };

    case CONNECTIONS_CONNECTION_APPLICATIONS_LIST_START:
      return { ...state, connection_applications_loading: true };
    case CONNECTIONS_CONNECTION_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        connection_applications_loading: false,
        connection_applications: action.payload,
      };
    case CONNECTIONS_CONNECTION_APPLICATIONS_LIST_ERROR:
      return { ...state, connection_applications_loading: false };

    default:
      return { ...state };
  }
}
