import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import axios from './axios';

export default class SSOSAML {
  axios: AxiosInstance;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);

    this.axios.defaults.baseURL = (this.axios.defaults.baseURL || '').replace(
      'v1',
      'v2'
    );
  }

  getIDPParams(entityID: string): Promise<AxiosResponse<unknown>> {
    return this.axios.get(`/saml_configuration/${entityID}/idp_params`);
  }

  getDetails(entityID: string): Promise<AxiosResponse<unknown>> {
    return this.axios.get(`/saml_configuration/${entityID}/details`);
  }

  updateDetails(
    entityID: string,
    details: { sso_url: string; certificate_fingerprint: string },
    hasSAMLConfig = false
  ): Promise<AxiosResponse<unknown>> {
    const body = { data: details };

    // update existing config
    if (hasSAMLConfig) {
      return this.axios.patch(`/saml_configuration/${entityID}`, body);
    }

    // create new config
    return this.axios.post(`/saml_configuration/${entityID}`, body);
  }
}
