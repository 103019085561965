import get from 'lodash.get';
import ReviewModel from 'models/ReviewModel';
import sortByDate from 'utils/sortByDate';

// get array of escalation from level
export const getEscalationFromLevels = (reviewEscalations: any) => {
  return getEscalationValuesArray({
    reviewEscalations,
    propertyName: 'escalated_from_level',
  });
};

export const checkUserHasEscalation = ({
  currentUserId,
  reviewEscalations,
}) => {
  return !!currentEscalationEscalatedByUser({
    currentUserId,
    reviewEscalations,
  });
};

// checks if theres a review escalated
export const hasEscalatedReview = (reviewEscalations) => {
  return (getEscalationFromLevels(reviewEscalations) || []).includes(0);
};

export const getEscalationDataFromReview = ({ review, reviewEscalations }) => {
  return reviewEscalations.find(
    (escalation) =>
      escalation.attributes.review_id === review.id && !review.isCloned
  );
};

export const checkUserHasAssignedEscalation = ({
  currentUserId,
  reviewEscalations,
}) => {
  return !!currentEscalationEscalatedForUser({
    currentUserId,
    reviewEscalations,
  });
};

// get escalations by current user
export const currentEscalationEscalatedByUser = ({
  reviewEscalations,
  currentUserId,
}) => {
  const foundReviewEscalation = reviewEscalations.find(
    (escalations) => escalations.attributes.escalated_by_id === currentUserId
  );

  return foundReviewEscalation;
};

// get escalations for current user
export const currentEscalationEscalatedForUser = ({
  reviewEscalations,
  currentUserId,
}) => {
  const foundReviewEscalation = reviewEscalations.find(
    (escalations) => escalations.attributes.escalated_for_id === currentUserId
  );

  return foundReviewEscalation;
};

// get escalations for tagged approver
export const taggedApproverEscalationData = ({ review, reviewEscalations }) => {
  const escalationForUserData = currentEscalationEscalatedForUser({
    reviewEscalations: reviewEscalations,
    currentUserId: review.taggedApproverId,
  });

  const escalationByUserData = currentEscalationEscalatedByUser({
    reviewEscalations: reviewEscalations,
    currentUserId: review.taggedApproverId,
  });

  return escalationByUserData || escalationForUserData;
};

// get array of escalation values
export const getEscalationValuesArray = ({
  reviewEscalations,
  propertyName,
}) => {
  return reviewEscalations.map((escalation: any) =>
    propertyName === 'id' ? escalation.id : escalation.attributes[propertyName]
  );
};

export const getEscalationsEscalatedByUser = (reviewEscalations, level) => {
  return reviewEscalations
    .filter(
      (escalation) => escalation.attributes.escalated_from_level === level
    )
    .map((escalation) => escalation.attributes.escalated_by_id);
};

// Get review based on escalation data
export const findEscalatedReviews = ({ reviews, currentEscalation }) => {
  const currentEscalationReviewEscalationId = get(
    currentEscalation,
    'attributes.review_id',
    null
  );

  return reviews.find(
    (review) =>
      review.id === currentEscalationReviewEscalationId && !review.isCloned
  );
};

// clone previous escalations
export const createPreviousEscalationData = ({
  reviews,
  reviewEscalations,
}) => {
  const clonedReviews = reviewEscalations.map((escalation, index) => {
    const {
      escalated_from_level,
      escalated_to_level,
      escalated_by_user_name,
      escalated_by_id,
      notes,
    } = escalation.attributes;
    const escalatedReview = findEscalatedReviews({
      currentEscalation: escalation,
      reviews,
    });
    const sameLevelEscalation = escalated_to_level === escalated_from_level;
    if (!escalatedReview || sameLevelEscalation) {
      return;
    }

    const clonedReview = cloneReviewModel(escalatedReview);

    // @ts-ignore
    clonedReview.level = escalated_from_level;
    // @ts-ignore
    clonedReview.taggedApproverId = escalated_by_id;
    // @ts-ignore
    clonedReview.reviewerName = escalated_by_user_name;
    // @ts-ignore
    clonedReview.decision = 'escalated';
    // @ts-ignore
    clonedReview.originalDecision = escalatedReview.decision;
    // @ts-ignore
    clonedReview.disableRevert = index + 1 < reviewEscalations.length;
    // @ts-ignore
    clonedReview.isCloned = true;
    // @ts-ignore
    clonedReview.notes = notes;

    return clonedReview;
  });

  // filter undefined
  return clonedReviews.filter((review) => !!review);
};

// clone review model
export const cloneReviewModel = (review) => {
  return new ReviewModel(JSON.parse(JSON.stringify(review)));
};

// get current escalation for the reviewid for the tagged approver
export const getCurrentReviewLevelEscalation = ({
  reviewId,
  reviewEscalations,
  currentUserIsTaggedApprover,
  level,
}) => {
  const sortedReviewEscalation = sortByDate({
    dateAttribute: 'attributes.created_at',
    direction: 'descending',
    records: reviewEscalations,
  });
  return sortedReviewEscalation.find((escalation) => {
    const { review_id, escalated_to_level, escalated_from_level } =
      escalation.attributes;
    const isReviewEscalation = review_id === reviewId;
    let isInLevel = escalated_to_level === level;
    if (!currentUserIsTaggedApprover) {
      isInLevel = escalated_from_level === level;
    }

    return isReviewEscalation && isInLevel;
  });
};

// check if user has escalation or has escalated for
export const isUserInvolvedInEscalation = ({
  reviewEscalations,
  currentUserId,
}) => {
  const isUserHasEscalation = checkUserHasEscalation({
    reviewEscalations: reviewEscalations,
    currentUserId: currentUserId,
  });
  const isUserEscalatedFor = checkUserHasAssignedEscalation({
    reviewEscalations: reviewEscalations,
    currentUserId: currentUserId,
  });

  return !!(isUserHasEscalation || isUserEscalatedFor);
};

// check has escalation
export const hasReviewEscalations = (reviewEscalations) => {
  return !!reviewEscalations.length;
};

// check all escalations review if escalated
export const hasActiveEscalations = (reviewEscalations, reviews) => {
  const reviewEscalationStatus = reviewEscalations.map((escalation) => {
    const escalatedReview = findEscalatedReviews({
      currentEscalation: escalation,
      reviews,
    });
    return escalatedReview ? escalatedReview.decision : null;
  });

  return reviewEscalationStatus.includes('escalated');
};
