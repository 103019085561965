import styled from 'styled-components';

export const ProofOfLifeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 220px;
`;

interface ProofOfLifeOutcomeProps {
  outcome: boolean;
}

export const ProofOfLifeOutcome = styled.div<ProofOfLifeOutcomeProps>`
  display: flex;
  gap: 4px;

  span:first-child {
    text-decoration: underline;
  }

  span:last-child {
    color: ${(props) => (props.outcome ? 'green' : 'red')};
  }
`;
