import { watchtowerAlertTheme } from 'modules/shared/helpers/colorPalettes';
import { Icon } from 'mui-latest';
import styled from 'styled-components';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';

export const StatisticBlocksWrapper = styled.div`
  display: flex;
  gap: 17px;
`;

const alertTypeColorMap = {
  internal: watchtowerAlertTheme.internal_alert,
  external: watchtowerAlertTheme.external_alert,
};
export const AlertNameWrapper = styled.span`
  background-color: ${(props: { type?: string }) =>
    props.type ? alertTypeColorMap[props.type] : 'initial'};
  padding: 3px 5px;
  border-radius: 5px;
`;

export const HeaderWithTootltip = styled.span`
  display: flex;
  gap: 10px;
  align-items: flex-start;
`;

export const RowIcon = styled(Icon)`
  overflow: visible !important;
  margin-top: -2px;
`;

export const AccountMonitoringIconContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 10px;
  top: 4px;
  svg {
    color: var(--main-color);
  }
`;

export const AccountMonitoringIcon = styled(AssignmentLateOutlinedIcon)`
  width: 16px;
  height: 16px;
  color: var(--main-color);
`;

export const IconWrapper = styled.div`
  color: var(--main-color) svg {
    color: var(--main-color);
  }
`;
