import {
  CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR,
  CONNECTIONS_ALL_CONNECTIONS_LIST_START,
  CONNECTIONS_ALL_CONNECTIONS_LIST_SUCCESS,
  CONNECTIONS_CHECK_FOR_STOP_CREDIT_ERROR,
  CONNECTIONS_CHECK_FOR_STOP_CREDIT_START,
  CONNECTIONS_CHECK_FOR_STOP_CREDIT_SUCCESS,
  CONNECTIONS_CONNECTION_APPLICATIONS_LIST_ERROR,
  CONNECTIONS_CONNECTION_APPLICATIONS_LIST_START,
  CONNECTIONS_CONNECTION_APPLICATIONS_LIST_SUCCESS,
  CONNECTIONS_CONNECTION_ERROR,
  CONNECTIONS_CONNECTION_START,
  CONNECTIONS_CONNECTION_SUCCESS,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_START,
  CONNECTIONS_CONTACT_CANDIDATES_LIST_SUCCESS,
  CONNECTIONS_CREATE_NOTES_ERROR,
  CONNECTIONS_CREATE_NOTES_START,
  CONNECTIONS_CREATE_NOTES_SUCCESS,
  CONNECTIONS_CREATE_STOP_CREDIT_ERROR,
  CONNECTIONS_CREATE_STOP_CREDIT_START,
  CONNECTIONS_CREATE_STOP_CREDIT_SUCCESS,
  CONNECTIONS_LOAD_NOTES_ERROR,
  CONNECTIONS_LOAD_NOTES_START,
  CONNECTIONS_LOAD_NOTES_SUCCESS,
  CONNECTIONS_REMOVE_STOP_CREDIT_ERROR,
  CONNECTIONS_REMOVE_STOP_CREDIT_START,
  CONNECTIONS_REMOVE_STOP_CREDIT_SUCCESS,
  CONNECTIONS_SET_REGION,
  CONNECTIONS_SET_STATE,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_START,
  CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS,
} from './constants';

import api from '../../api';

// Needs updating for some sort of notification system...
export function notifyBusinessKeyContactChange(connectionId) {
  return (dispatch, getState) => {
    return connectionId;
  };
}

export function loadContactCandidates() {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_CONTACT_CANDIDATES_LIST_START,
    });

    var users = api(
        'users',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      data = [];

    users.getUsers(
      (result) => {
        if (result.data.data.length > 0) {
          data = result.data.data;
          dispatch({
            type: CONNECTIONS_CONTACT_CANDIDATES_LIST_SUCCESS,
            payload: data,
          });
        }
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_CONTACT_CANDIDATES_LIST_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createStopCredit(connectionId, attributes, success) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_CREATE_STOP_CREDIT_START,
    });

    var stops = api(
        'stop_credits',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      data = {};

    stops.createStopCredit(
      connectionId,
      attributes,
      (result) => {
        if (result.data.data) {
          data = result.data.data;
          dispatch({
            type: CONNECTIONS_CREATE_STOP_CREDIT_SUCCESS,
            payload: data,
            meta: {
              mixpanel: {
                event: 'Apply stop credit',
                props: {
                  distinct_id: getState().current_user.data.data.id,
                  Connection: connectionId,
                  'Entity ID': getState().current_user.current_entity.id,
                },
              },
            },
          });

          success();
        }
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_CREATE_STOP_CREDIT_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function checkForStopCredit(connectionId) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_CHECK_FOR_STOP_CREDIT_START,
      payload: connectionId,
    });

    var stops = api(
        'stop_credits',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      data = null;

    stops.getStopCredit(
      connectionId,
      (result) => {
        data = result.data.data;
        dispatch({
          type: CONNECTIONS_CHECK_FOR_STOP_CREDIT_SUCCESS,
          payload: data,
        });
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_CHECK_FOR_STOP_CREDIT_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function removeStopCredit(id, success) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_REMOVE_STOP_CREDIT_START,
      payload: id,
    });

    var stops = api(
        'stop_credits',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      data = {};

    stops.updateStopCredit(
      id,
      { active: false },
      (result) => {
        if (result.data.data) {
          data = result.data.data;
          dispatch({
            type: CONNECTIONS_REMOVE_STOP_CREDIT_SUCCESS,
            payload: data,
            meta: {
              mixpanel: {
                event: 'Remove stop credit',
                props: {
                  distinct_id: getState().current_user.data.data.id,
                  Connection: id,
                  'Entity ID': getState().current_user.current_entity.id,
                },
              },
            },
          });

          success();
        }
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_REMOVE_STOP_CREDIT_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateConnectionKeyContact(
  connectionType,
  connectionId,
  userId,
  success
) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_START,
      payload: connectionType,
      meta: {
        mixpanel: {
          event: 'Change connection key contact',
          props: {
            distinct_id: getState().current_user.data.data.id,
            Connection: connectionId,
            'Connection type': connectionType,
            'Entity ID': getState().current_user.current_entity.id,
          },
        },
      },
    });

    const connections = api(
      'connections',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    let apiAction;

    if (connectionType === 'consumer') {
      apiAction = 'setSupplierContact';
    } else if (connectionType === 'supplier') {
      apiAction = 'setConsumerContact';
    }

    connections[apiAction](
      connectionId,
      userId,
      (result) => {
        dispatch({
          type: CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_SUCCESS,
          payload: connectionId,
        });
        success(result.data.data);
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_UPDATE_CONNECTION_KEY_CONTACT_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function loadConnection(connectionId, relationType) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_CONNECTION_START,
      payload: connectionId,
      meta: {
        mixpanel: {
          event: 'Connection details',
          props: {
            distinct_id: getState().current_user.data.data.id,
            Connection: connectionId,
            'Entity ID': getState().current_user.current_entity.id,
          },
        },
      },
    });

    var con = api(
        'connections',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      data = [],
      included;

    con.getConnection(
      connectionId,
      (result) => {
        data = result.data.data;
        included = result.data.included;

        data.relation_type = relationType;

        if (relationType === 'consumer') {
          data.showparty = included.find((inc) => {
            return inc.id === data.relationships.consumer.data.id;
          });
          data.ourparty = included.find((inc) => {
            return inc.id === data.relationships.supplier.data.id;
          });
          data.showcontact = included.find((inc) => {
            return inc.id === data.relationships.consumer_contact.data.id;
          });
          data.ourcontact = included.find((inc) => {
            return inc.id === data.relationships.supplier_contact.data.id;
          });
        } else {
          data.showparty = included.find((inc) => {
            return inc.id === data.relationships.supplier.data.id;
          });
          data.ourparty = included.find((inc) => {
            return inc.id === data.relationships.consumer.data.id;
          });
          data.showcontact = included.find((inc) => {
            return inc.id === data.relationships.supplier_contact.data.id;
          });
          data.ourcontact = included.find((inc) => {
            return inc.id === data.relationships.consumer_contact.data.id;
          });
        }

        data.supplier = included.find((inc) => {
          return inc.id === data.relationships.supplier.data.id;
        });

        data.application = included.find((inc) => {
          return inc.id === data.relationships.application.data.id;
        });

        dispatch({
          type: CONNECTIONS_CONNECTION_SUCCESS,
          payload: data,
        });
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_CONNECTION_ERROR,
          payload: error,
        });
      },
      {
        params: {
          include:
            'supplier,consumer,application,consumer_contact,supplier_contact',
        },
      }
    );
  };
}

export function loadConnections(params) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_ALL_CONNECTIONS_LIST_START,
    });

    var connections = api(
        'connections',
        getState().current_user.access_token,
        getState().current_user.current_entity.id,
        { headers: { Accept: 'application/json' } }
      ),
      data = [],
      included;

    connections.getConnections(
      (result) => {
        if (result.data.data.length > 0) {
          data = result.data.data;

          data = data.map((v, i) => {
            if (params.entity_role === 'consumer') {
              v.relation_type = 'consumer';
              v.trading_name = v.attributes.consumer_trading_name;
              v.region = v.attributes.consumer_region;
              v.state = v.attributes.consumer_state;
            } else {
              v.relation_type = 'supplier';
              v.trading_name = v.attributes.supplier_trading_name;
              v.region = v.attributes.supplier_region;
              v.state = v.attributes.supplier_state;
            }
            return v;
          });
        }
        dispatch({
          type: CONNECTIONS_ALL_CONNECTIONS_LIST_SUCCESS,
          payload: {
            connections: data,
            total_count: result.data.meta.total_count,
          },
        });
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_ALL_CONNECTIONS_LIST_ERROR,
          payload: error,
        });
      },
      { params: params }
    );
  };
}

export function addNote(id, note) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_CREATE_NOTES_START,
      payload: id,
    });

    var notes_api = api(
      'notes',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    notes_api.createNote(
      id,
      note.attributes,
      'connections',
      (result) => {
        dispatch({
          type: CONNECTIONS_CREATE_NOTES_SUCCESS,
          payload: result.data.data,
        });
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_CREATE_NOTES_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getNotes(id) {
  return (dispatch, getState) => {
    dispatch({
      type: CONNECTIONS_LOAD_NOTES_START,
      payload: id,
    });

    var notes_api = api(
      'notes',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );

    notes_api.getNotes(
      id,
      'connections',
      (result) => {
        dispatch({
          type: CONNECTIONS_LOAD_NOTES_SUCCESS,
          payload: result.data.data,
        });
      },
      (error) => {
        dispatch({
          type: CONNECTIONS_LOAD_NOTES_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function setLocation(key, value) {
  var type;
  switch (key) {
    case 'region':
      type = CONNECTIONS_SET_REGION;
      break;
    case 'state':
      type = CONNECTIONS_SET_STATE;
      break;
  }
  return {
    type: type,
    payload: value,
  };
}
