import React from 'react';
import styles from 'modules/shared/components/widgets/static/css/StatusIndicator.css';

function StatusName(props) {
  const { status, withStatusName, green_text } = props;

  if (!withStatusName) {
    return null;
  }

  if (status === 'not_started') {
    return <span className={styles.status_name}>not started</span>;
  }

  if (status === 'green') {
    return (
      <span className={styles.status_name}>{green_text || 'complete'}</span>
    );
  }

  return (
    <span className={styles.status_name}>
      {status ? status.split('_').join(' ') : ''}
    </span>
  );
}

function dotStyle(props) {
  const { status, withStatusName } = props;

  if (withStatusName) {
    return styles[status];
  }

  return styles[`${status}_simple`];
}

export default function StatusIndicator(props) {
  return (
    <span className={dotStyle(props)}>
      <span className={styles.dot}></span> <StatusName {...props} />
    </span>
  );
}

StatusIndicator.defaultProps = {
  withStatusName: false,
};
