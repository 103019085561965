/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/* Import CSS */
import styles from './css/AutoDecisioning.css';
/* Import components */
import ExpandableSection from 'modules/addons/auto_decisioning/components/ExpandableSection';

function AntiFraud(props) {
  const { requiresAntiFraudCheck, error, read_only } = props;

  return (
    <div className={styles.section}>
      <ExpandableSection
        id="require_anti_fraud"
        title="1CAF"
        info_text="Do you require 1CAF for auto decisioning?"
        disabled={read_only}
        account_rule_on={requiresAntiFraudCheck}
        hide_setting_message={read_only}
        error={error}
      />
    </div>
  );
}

export default connect((_, ownProps) => {
  return {
    requiresAntiFraudCheck: ownProps.current_entity.requires_anti_fraud_check,
  };
})(AntiFraud);
