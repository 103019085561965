import Modal from 'modules/dashboard/containers/components/Modal';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement } from 'react';

import { WatchtowerButtonWrapper, WatchtowerModalTitle } from './styles';

type IWatchtowerConfirmModal = {
  onConfirm: () => void;
  onCancel: () => void;
};

function WatchtowerConfirmModal(props: IWatchtowerConfirmModal): ReactElement {
  const { onConfirm, onCancel } = props;

  return (
    <Modal
      onClose={onCancel}
      title={
        <WatchtowerModalTitle>
          <span>Turn on Watchtower</span>
        </WatchtowerModalTitle>
      }
      content={
        <p>
          By clicking confirm, you are authorising 1Centre to do a retrospective
          check on all of your existing and future applications, unless the
          module is paused.
        </p>
      }
      footer={
        <WatchtowerButtonWrapper>
          <Button text="Confirm" handleClick={onConfirm} />
          <Button text="Cancel" white={true} handleClick={onCancel} />
        </WatchtowerButtonWrapper>
      }
    />
  );
}

export default WatchtowerConfirmModal;
