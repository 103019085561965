import { breakpointMax, breakpointMin } from 'variables/responsive';

type ScreenSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxx';
type MediaBoundary = 'min' | 'max';

const getBoundaryString = (
  screenSize: ScreenSize,
  boundary: MediaBoundary
): string => {
  const mediaQuery =
    boundary === 'max' ? breakpointMax(screenSize) : breakpointMin(screenSize);

  return mediaQuery.replace('@media ', '');
};

export const useMediaQuery = (
  upperBound: ScreenSize,
  lowerBound?: ScreenSize,
  boundary: MediaBoundary = 'max'
): boolean => {
  const mediaQueryString = lowerBound
    ? `${getBoundaryString(lowerBound, 'min')} and ${getBoundaryString(
        upperBound,
        'max'
      )}`
    : getBoundaryString(upperBound, boundary);
  const query = window.matchMedia(mediaQueryString);

  return query.matches;
};
