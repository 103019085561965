import { SuccessIcon } from 'modules/consumer-invite/v2/ConsumerInvite/SendSuccessMessage/styles';
import React, { ReactElement } from 'react';

const SendSuccessMessage = ({ email }: { email: string }): ReactElement => (
  <>
    <SuccessIcon className="icon mr-2">
      <i className="fas fa-check-circle"></i>
    </SuccessIcon>
    Application was sent successfully to {email}
  </>
);

export default SendSuccessMessage;
