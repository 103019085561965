import { InputAdornment } from '@material-ui/core';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form-latest';

const RHFBorderedEmailField = (props): ReactElement => {
  const {
    control,
    defaultValue,
    disabled,
    error,
    inputProps = {},
    label,
    name,
    onBlur,
    placeholder,
    required,
    value: passedValue,
    withBottomMargin,
    withIcon,
    noOnBlurValidation,
    dataCy = {},
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({
        field: {
          ref,
          onChange,
          onBlur: defaultOnBlur,
          value,
          ...textFieldProps
        },
        fieldState: { error: fieldError },
      }) => {
        const targetValue = passedValue || value;
        const processedError = error || fieldError;
        const processedValue = targetValue;

        return (
          <BorderedTextField
            customProps={{
              startAdornment: withIcon && (
                <InputAdornment position="start">
                  <span className="icon is-small is-left ">
                    <i className="fas fa-envelope"></i>
                  </span>
                </InputAdornment>
              ),
              withBottomMargin,
            }}
            disabled={disabled || false}
            error={!!processedError}
            helperText={processedError && processedError.message}
            inputProps={inputProps}
            inputRef={ref}
            data-cy={dataCy}
            label={label || 'Email address'}
            onBlur={onBlur || (noOnBlurValidation ? () => {} : defaultOnBlur)}
            onChange={(e) => {
              const value = e.target.value;
              const processedValue = (value || '').trim().toLowerCase();

              onChange(processedValue);
            }}
            placeholder={placeholder || 'Email address'}
            required={required}
            value={processedValue}
            {...textFieldProps}
          />
        );
      }}
    />
  );
};

RHFBorderedEmailField.defaultProps = {
  required: false,
  withBottomMargin: true,
  withIcon: true,
};

export default RHFBorderedEmailField;
