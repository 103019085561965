import { DateRangePicker } from 'react-dates';
import NoteModal from './NotesModal';
import NotesTable from './NotesTable';
import PlusButton from 'modules/shared/components/inputs/PlusButton';
import React from 'react';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import isBlank from 'utils/isBlank';
import styles from './css/Notes.css';

export default createClass({
  componentWillMount: function() {
    const { dispatch, notable_id, getNotes } = this.props;
    if (getNotes) {
      dispatch(getNotes(notable_id));
    }
  },

  getInitialState: function() {
    return {
      noteId: null,
      noteName: null,
      showNoteModal: null,
    };
  },

  showNoteModal: function(id) {
    const { notes } = this.props;
    this.setState({
      showNoteModal: true,
    });
    if (id) {
      let note = notes.find((v) => {
        return v.id === id;
      });
      this.setState({
        showNoteModal: (
          <NoteModal
            handleSave={this.addNote}
            handleCancel={this.cancelNoteModal}
            title="Edit note"
            notename={note.attributes.name}
            noteid={note.id}
          />
        ),
      });
    } else {
      this.setState({
        showNoteModal: (
          <NoteModal
            handleSave={this.addNote}
            handleCancel={this.cancelNoteModal}
            title="Upload notes"
          />
        ),
      });
    }
  },

  cancelNoteModal: function() {
    this.setState({
      showNoteModal: null,
      noteId: null,
      noteName: null,
    });
  },

  addNote: function(data) {
    const { dispatch, notable_id, addNote } = this.props;
    let note = {
      attributes: {
        name: data.name,
        text: data.text,
        file: data.file,
        parent_id: data.parent_id,
      },
    };

    dispatch(addNote(notable_id, note));

    this.setState({
      showNoteModal: null,
      noteId: null,
      noteName: null,
    });
  },

  renderAddButton() {
    const { isAddButtonHidden } = this.props;
    const isHidden = isBlank(isAddButtonHidden) ? false : isAddButtonHidden;

    if (!isHidden) {
      return (
        <div className={styles.addNote}>
          <PlusButton handleClick={() => this.showNoteModal()} />
        </div>
      );
    }
  },

  render: function() {
    const {
      notes,
      location,
      loading,
      container_class,
      hide_dates,
      ...rest
    } = this.props;
    const { showNoteModal, noteId, startDate, endDate } = this.state;

    if (loading) {
      return <SimpleLoader />;
    }

    return (
      <div className={styles.row}>
        <div className={styles.content}>
          {hide_dates || (
            <div>
              <div className={styles.date_label}>
                <label className={styles.date_to}>Date range</label>
              </div>
              <div className={styles.date_range}>
                <DateRangePicker
                  isOutsideRange={() => false}
                  displayFormat="DD/MM/YYYY"
                  startDateId="notes_range_start"
                  startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                  endDateId="notes_range_end"
                  endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                  onDatesChange={({ startDate, endDate }) =>
                    this.setState({ startDate, endDate })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput })
                  } // PropTypes.func.isRequired,
                  hideKeyboardShortcutsPanel={true}
                />
              </div>
            </div>
          )}

          {this.renderAddButton()}
          <div
            className={
              container_class ? styles[container_class] : styles.notes_list
            }
          >
            <NotesTable
              data={notes}
              handleEdit={this.showNoteModal}
              location={location}
              startDate={startDate}
              endDate={endDate}
              {...rest}
            />
          </div>
          {showNoteModal}
        </div>
      </div>
    );
  },
});
