/* Import libraries */
import React, { Component } from 'react';
import FinancialsDetails from '../financials/FinancialsDetails';
import { reviewComponentComplete } from '../../actions/review';
import styles from './css/Review.css';

class ReviewFinancials extends Component {
  updateReviewComponentComplete(complete) {
    const { dispatch, review_complete } = this.props;
    if (review_complete[defaults.moduleName] !== complete) {
      dispatch(reviewComponentComplete(defaults.moduleName, complete));
    }
  }

  render() {
    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        <FinancialsDetails
          {...this.props}
          handleComplete={(value) => this.updateReviewComponentComplete(value)}
          collapsible={true}
        />
      </section>
    );
  }
}

const defaults = {
  moduleName: 'financials',
};

module.exports = ReviewFinancials;
