import axios from './axios';
import { transformRequest, transformResponse } from 'utils/axios_transforms';

export default class AmlCheckLookup {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'aml_check_lookup';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );

    this.axios.defaults.transformResponse = [transformResponse];
    this.axios.defaults.transformRequest = [transformRequest];

    this.path = `/${this.type}`;
  }

  getAmlCheckLookup() {
    return this.axios.get(this.path);
  }
}
