import axios from './axios';

const ReportApplications = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
};

ReportApplications.prototype.getSupplierApplications = function(
  params,
  success,
  error
) {
  return this.axios
    .get('/report_supplier_applications', { params })
    .then(success)
    .catch(error);
};

ReportApplications.prototype.getConsumerApplications = function(
  params,
  success,
  error
) {
  return this.axios
    .get('/report_consumer_applications', { params })
    .then(success)
    .catch(error);
};

ReportApplications.prototype.getApplications = function(params) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios.get('/report_applications', params);
};

ReportApplications.prototype.getApplicationFilterOptions = function() {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
  return this.axios.get('/report_applications/filter_options');
};

export default ReportApplications;
