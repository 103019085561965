import { IconButton } from 'mui-latest';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
  &&& {
    margin-top: -20px;
    margin-right: -20px;
    background-color: transparent;
  }
`;
