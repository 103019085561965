const termsButton = {
  buttonLabel: 'T&Cs',
  buttonName: 't-and-c',
};

const defaultButtons = [
  {
    buttonLabel: 'Progress',
    buttonName: 'progress',
  },
  termsButton,
  {
    buttonLabel: 'Q&A',
    buttonName: 'q-and-a',
  },
];

const checklistButtonProperties = {
  buttonLabel: 'Checklist',
  buttonName: 'checklist',
};

const OverlayButtonsList = (withChecklist, isAuthorisations) => {
  if (isAuthorisations) return [termsButton];
  if (!withChecklist) return defaultButtons;
  return [checklistButtonProperties, ...defaultButtons];
};

export default OverlayButtonsList;
