import React from 'react';
import { connect } from 'react-redux';

/* Import CSS */
import styles from './css/Review.css';

/* Import components */
import SectionTitle from 'modules/shared/components/widgets/static/SectionTitle';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import SectionBadge from 'modules/shared/components/widgets/static/SectionBadge';

var Complete = createClass({
  render: function() {
    const { approved, consumer_name } = this.props;

    let title = 'Guarantor approval complete!',
      subtext = '',
      titleComponents = [],
      icon = 'thumbsup';
    if (!approved) {
      title = "No worries,\n You can't win 'em all!";
      subtext =
        "We'll let " +
        consumer_name +
        ' know that you chose not to guarantee this account.';
      icon = 'thumbsdown';
    }

    return (
      <div className={styles.page}>
        <div className={styles.containerFlex}>
          <section className={styles.blue_section}>
            <div className={styles.row}>
              <div className={styles.blue_panel}>
                <SectionTitle white={true} text={title} />
                <SectionBadge icon={icon} state="section_complete" />
              </div>
            </div>
            <div className={styles.description_row}>
              <div className={styles.description}>
                <SectionDescription text={subtext} />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  },
});

module.exports = connect((state, ownProps) => {
  let consumer_name = '',
    approved = state.guarantor.approved;
  if (state.guarantor.data) {
    consumer_name = state.guarantor.data.application.attributes.consumer_name;
    if (state.guarantor.data.attributes.acknowledged) {
      approved = state.guarantor.approved;
    }
  }

  return {
    consumer_name,
    approved: approved,
  };
})(Complete);
