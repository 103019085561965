export const GUARANTOR_TITLE = 'Guarantors';
export const GUARANTOR_SETTINGS_DESCRIPTION = `Guarantors apply to both New Zealand
and Australia if turned on. For New Zealand Company applications,
the system automatically retrieve directors and major shareholders data from
the Companies Office free of charge. For Australia,
you have the option to enable this or let your customers
fill out guarantor details manually.`;

export const ADD_CREDIT_CARD_STRING =
  'Please add credit card details before selecting this option.';

export const DEFAULT_GUARANTOR_SETTINGS = {
  minimum_guarantees: 0,
  requires_guarantees: false,
  director_from_credit_check_enabled: false,
};

export const DIRECTOR_CREDIT_CHECK_SECTION = {
  header: 'Automate fill of director details (AU) ',
  headerSubtext: '(optional)',
  label: 'Enable automate fill of director details for Australia applications',
  description: `If enabled, this means a Company credit check (AU only) will
  automatically populate on the credit file irrespective of
  whether the applicant fully completes the application or not
  - this in-turn will generate a charge from your bureau of
  choice. This means while your customer is completing their
  application, 1Centre will automatically populate the
  director details of the company for your customer to choose
  from. This will ensure certainty of director details
  attached to your guarantee.`,
};

export const GUARANTEES_OPTIONS = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
];

export const DISABLED_GUARANTEES_OPTIONS = [{ label: 0, value: 0 }];

export const ENTITY_TYPES_OPTIONS = [
  { brief: 'Company', label: 'Company', value: 'company' },
  { brief: 'Trust', label: 'Trust', value: 'trust' },
];

export const DEFAULT_1CAD_STATE = {
  isAutoDecisionImpactModalVisible: false,
  onConfirm: null,
  rulesetsThatWillBeImpacted: [],
  tabStatusToBePaused: null,
};
