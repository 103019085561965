import { GUARANTOR_LOGIN_SUCCESS } from 'modules/guarantor/constants';
import { getSudoToken } from 'modules/shared/auth/actions';
import isPresent from 'utils/isPresent';

import {
  EMAIL_LOCKED_ERROR,
  USER_ADD_ENTITY_ERROR,
  USER_ADD_ENTITY_START,
  USER_ADD_ENTITY_SUCCESS,
  USER_AUTHENTICATED,
  USER_CHECK_EMAIL_ERROR,
  USER_CHECK_EMAIL_START,
  USER_CHECK_EMAIL_SUCCESS,
  USER_CHECK_VOUCHER_CODE_ERROR,
  USER_CHECK_VOUCHER_CODE_START,
  USER_CHECK_VOUCHER_CODE_SUCCESS,
  USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_ERROR,
  USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_START,
  USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_SUCCESS,
  USER_CURRENT_ENTITY_LEAD_NAME_SET,
  USER_DELETE_CURRENT_USER_POPUP_ERROR,
  USER_DELETE_CURRENT_USER_POPUP_START,
  USER_DELETE_CURRENT_USER_POPUP_SUCCESS,
  USER_FORGOT_PASSWORD_FORM_SET_EMAIL,
  USER_GET_TOKEN_BEGIN,
  USER_GET_TOKEN_ERROR,
  USER_GET_TOKEN_SUCCESS,
  USER_LOAD_CURRENT_ENTITY_ERROR,
  USER_LOAD_CURRENT_ENTITY_START,
  USER_LOAD_CURRENT_ENTITY_SUCCESS,
  USER_LOAD_CURRENT_USER_BEGIN,
  USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR,
  USER_LOAD_CURRENT_USER_ERROR,
  USER_LOAD_CURRENT_USER_POPUPS_ERROR,
  USER_LOAD_CURRENT_USER_POPUPS_START,
  USER_LOAD_CURRENT_USER_POPUPS_SUCCESS,
  USER_LOAD_CURRENT_USER_SUCCESS,
  USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_ERROR,
  USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_START,
  USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_SUCCESS,
  USER_LOAD_SUCCESS,
  USER_LOAD_USER_HEADQUARTER_LINK_ERROR,
  USER_LOAD_USER_HEADQUARTER_LINK_START,
  USER_LOAD_USER_HEADQUARTER_LINK_SUCCESS,
  USER_LOGIN_BEGIN,
  USER_LOGIN_CREDENTIALS_ERROR,
  USER_LOGIN_ERROR,
  USER_LOGIN_FORM_SET_EMAIL,
  USER_LOGIN_FORM_SET_PASSWORD,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_NETWORK_BREAK,
  USER_PASSWORD_RESET_ERROR,
  USER_PASSWORD_RESET_START,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_TOKEN_NOT_FOUND_ERROR,
  USER_REGISTER_CREATE_REGISTRATION_ERROR,
  USER_REGISTER_CREATE_REGISTRATION_START,
  USER_REGISTER_FORM_SET_COUNTRY_CODE,
  USER_REGISTER_FORM_SET_EMAIL,
  USER_REGISTER_FORM_SET_FIRST_NAME,
  USER_REGISTER_FORM_SET_LAST_NAME,
  USER_REGISTER_FORM_SET_PASSWORD,
  USER_REGISTER_FORM_SET_PASSWORD_CONFIRM,
  USER_REGISTER_FORM_SET_PHONE_NUMBER,
  USER_REGISTER_FORM_SET_REGION,
  USER_REGISTER_FORM_SET_STATE,
  USER_REGISTER_FORM_SET_STRENGTH,
  USER_REGISTER_FORM_SET_VOUCHER_CODE,
  USER_REGISTER_FORM_SET_VOUCHER_CODE_VALID,
  USER_REGISTER_RESET,
  USER_SEND_PASSWORD_RESET_BEGIN,
  USER_SEND_PASSWORD_RESET_ERROR,
  USER_SEND_PASSWORD_RESET_SUCCESS,
  USER_SET_CURRENT_ENTITY_STORE_NAME_ALIAS,
  USER_TOKEN_AUTH_SET_QUERY_PARAMS,
  USER_UPDATE_FIRST_LAST_NAME_FROM_SIGNATURE_SUCCESS,
  USER_UPDATE_LOADING_END,
  USER_UPDATE_PREFERENCE_ERROR,
  USER_UPDATE_PREFERENCE_START,
  USER_UPDATE_PREFERENCE_SUCCESS,
} from './constants';

const authReducerState = {
  access_token: null,
  current_entity: null,
  current_user_entity_link: null,
  current_user_popups: null,
  data: {},
  entity_consumer_applications: null,
  isSudoMode: false,
  loading: false,
  loggedIn: false,
  new_entity_submitting: false,
  origin: '',
  password_reset_loading: false,
  query_params: {},
};

export function userAuthReducer(state = authReducerState, action) {
  switch (action.type) {
    case USER_SEND_PASSWORD_RESET_BEGIN:
      return { ...state, password_reset_loading: true };

    case USER_SEND_PASSWORD_RESET_SUCCESS:
      return { ...state, password_reset_loading: false };

    case USER_SEND_PASSWORD_RESET_ERROR:
      return { ...state, password_reset_loading: false };

    case USER_PASSWORD_RESET_START:
      return { ...state, loading: true };

    case USER_PASSWORD_RESET_SUCCESS:
      return { ...state, loading: false };

    case USER_PASSWORD_RESET_ERROR:
      return { ...state, loading: false };

    case USER_PASSWORD_RESET_TOKEN_NOT_FOUND_ERROR:
      return { ...state, loading: false };

    case USER_LOAD_CURRENT_USER_BEGIN:
      return { ...state, loading: true };

    case USER_TOKEN_AUTH_SET_QUERY_PARAMS:
      return { ...state, query_params: action.payload };

    case USER_GET_TOKEN_BEGIN:
      return state;

    case USER_GET_TOKEN_ERROR:
      return state;

    case USER_GET_TOKEN_SUCCESS:
      return state;

    case USER_LOAD_CURRENT_USER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isSudoMode: isPresent(getSudoToken()),
        loggedIn: true,
        origin: action.payload.token_origin,
      };

    case USER_LOAD_CURRENT_USER_DEACTIVATED_ERROR:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
        loggedIn: false,
      };

    case USER_LOAD_CURRENT_USER_ERROR:
      return {
        ...state,
        data: {},
        error: action.payload,
        loading: false,
        loggedIn: false,
      };

    case USER_NETWORK_BREAK:
      return { ...state, loading: false };

    case USER_LOGIN_BEGIN:
      return { ...state, loading: true };

    case USER_LOGIN_SUCCESS:
      return { ...state, access_token: action.payload.jwt, loading: false };

    case GUARANTOR_LOGIN_SUCCESS:
      return { ...state, access_token: action.payload.jwt, loading: false };

    case USER_LOGIN_ERROR:
      return { ...state, access_token: null, loading: false };

    case USER_LOGIN_CREDENTIALS_ERROR:
      return { ...state, access_token: null, loading: false };

    case EMAIL_LOCKED_ERROR:
      return { ...state, access_token: null, loading: false };

    case USER_LOGOUT:
      return { ...authReducerState };

    case USER_AUTHENTICATED:
      return { ...state, access_token: action.payload };

    case USER_LOAD_CURRENT_ENTITY_START:
      return { ...state, loading: true };
    case USER_LOAD_CURRENT_ENTITY_ERROR:
      return { ...state, loading: false };
    case USER_LOAD_CURRENT_ENTITY_SUCCESS:
      return {
        ...state,
        current_entity: action.payload.current_entity,
        current_user_entity_link: action.payload.current_user_entity_link,
        loading: false,
      };

    case USER_LOAD_USER_HEADQUARTER_LINK_START:
      return state;
    case USER_LOAD_USER_HEADQUARTER_LINK_SUCCESS:
      return state;
    case USER_LOAD_USER_HEADQUARTER_LINK_ERROR:
      return state;

    case USER_LOAD_SUCCESS: {
      return { ...state, loading: false };
    }
    case USER_ADD_ENTITY_START:
      return { ...state, new_entity_submitting: true };
    case USER_ADD_ENTITY_SUCCESS:
      return { ...state, new_entity_submitting: false };
    case USER_ADD_ENTITY_ERROR:
      return { ...state, new_entity_submitting: false };

    case USER_LOAD_CURRENT_USER_POPUPS_START:
      return state;
    case USER_LOAD_CURRENT_USER_POPUPS_SUCCESS:
      return { ...state, current_user_popups: action.payload };
    case USER_LOAD_CURRENT_USER_POPUPS_ERROR:
      return state;

    case USER_DELETE_CURRENT_USER_POPUP_START:
      return state;
    case USER_DELETE_CURRENT_USER_POPUP_SUCCESS:
      return state;
    case USER_DELETE_CURRENT_USER_POPUP_ERROR:
      return state;

    case USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_START:
      return state;
    case USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_SUCCESS:
      return { ...state, entity_consumer_applications: action.payload };
    case USER_LOAD_ENTITY_CONSUMER_APPLICATIONS_ERROR:
      return state;
    case USER_CURRENT_ENTITY_LEAD_NAME_SET:
      return {
        ...state,
        current_entity: {
          ...state.current_entity,
          attributes: {
            ...state.current_entity.attributes,
            lead_name: action.payload,
          },
        },
      };
    case USER_SET_CURRENT_ENTITY_STORE_NAME_ALIAS:
      return {
        ...state,
        current_entity: {
          ...state.current_entity,
          attributes: {
            ...state.current_entity.attributes,
            store_name_alias: action.payload,
          },
        },
      };
    case USER_UPDATE_FIRST_LAST_NAME_FROM_SIGNATURE_SUCCESS:
      return { ...state, data: { ...state.data, data: action.payload } };
    case USER_UPDATE_PREFERENCE_ERROR:
      return state;
    case USER_UPDATE_PREFERENCE_START:
      return state;
    case USER_UPDATE_PREFERENCE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          data: {
            ...state.data.data,
            attributes: {
              ...state.data.data.attributes,
              preference: {
                ...state.data.data.preference,
                ...action.payload,
              },
            },
          },
        },
      };
    case USER_UPDATE_LOADING_END:
      return { ...state, loading: false };

    case USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_START:
    case USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_ERROR:
      return state;
    case USER_CURRENT_ENTITY_CREDIT_CHECK_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        current_entity: {
          ...state.current_entity,
          attributes: {
            ...state.current_entity.attributes,
            credit_check_application_types: action.payload,
          },
        },
      };
    default:
      return state;
  }
}

const defaultLoginState = {
  confirm_password: '',
  email: '',
  forgot_password_email: '',
  password: '',
};

export function loginReducer(state = defaultLoginState, action) {
  switch (action.type) {
    case USER_LOGIN_FORM_SET_EMAIL:
      return { ...state, email: action.payload };
    case USER_LOGIN_FORM_SET_PASSWORD:
      return { ...state, password: action.payload };
    case USER_FORGOT_PASSWORD_FORM_SET_EMAIL:
      return { ...state, forgot_password_email: action.payload };
    case USER_LOGOUT:
      return { ...defaultLoginState };
    default:
      return state;
  }
}

const defaultRegistrationState = {
  confirm_password: '',
  country_code: '',
  email_address: '',
  email_valid: null,
  email_validating: false,
  first_name: '',
  last_name: '',
  password: '',
  phone_number: '',
  region: '',
  state: '',
  strength: 0,
  submitting: false,
  voucher_code: '',
  voucher_code_valid: true,
  voucher_code_validating: false,
};

export function registrationReducer(state = defaultRegistrationState, action) {
  switch (action.type) {
    case USER_REGISTER_FORM_SET_FIRST_NAME:
      return { ...state, first_name: action.payload };
    case USER_REGISTER_FORM_SET_LAST_NAME:
      return { ...state, last_name: action.payload };
    case USER_REGISTER_FORM_SET_PHONE_NUMBER:
      return { ...state, phone_number: action.payload };
    case USER_REGISTER_FORM_SET_EMAIL:
      return { ...state, email_address: action.payload };
    case USER_REGISTER_FORM_SET_PASSWORD:
      return { ...state, password: action.payload };
    case USER_REGISTER_FORM_SET_PASSWORD_CONFIRM:
      return { ...state, confirm_password: action.payload };
    case USER_REGISTER_FORM_SET_REGION:
      return { ...state, region: action.payload };
    case USER_REGISTER_FORM_SET_STATE:
      return { ...state, state: action.payload };
    case USER_REGISTER_FORM_SET_COUNTRY_CODE:
      return { ...state, country_code: action.payload };
    case USER_REGISTER_FORM_SET_VOUCHER_CODE:
      return { ...state, voucher_code: action.payload };
    case USER_REGISTER_FORM_SET_STRENGTH:
      return { ...state, strength: action.payload };
    case USER_CHECK_EMAIL_START:
      return { ...state, email_validating: true };
    case USER_CHECK_EMAIL_ERROR:
      return { ...state, email_validating: false };
    case USER_CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        email_valid: !action.payload,
        email_validating: false,
      };
    case USER_CHECK_VOUCHER_CODE_ERROR:
      return { ...state, voucher_code_validating: false };
    case USER_CHECK_VOUCHER_CODE_START:
      return { ...state, voucher_code_validating: true };
    case USER_CHECK_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        voucher_code_valid: action.payload,
        voucher_code_validating: false,
      };
    case USER_REGISTER_FORM_SET_VOUCHER_CODE_VALID:
      return { ...state, voucher_code_valid: action.payload };
    case USER_REGISTER_CREATE_REGISTRATION_START:
      return { ...state, submitting: true };
    case USER_REGISTER_CREATE_REGISTRATION_ERROR:
      return { ...state, submitting: false };
    case USER_REGISTER_RESET:
      return { ...state, ...defaultRegistrationState };

    case USER_ADD_ENTITY_START:
      return { ...state, submitting: true };
    case USER_ADD_ENTITY_SUCCESS:
      return { ...state, submitting: false };
    case USER_ADD_ENTITY_ERROR:
      return { ...state, submitting: false };
    default:
      return state;
  }
}

const defaultUserRoutesState = {
  visited: [],
};

export function userRoutesReducer(state = defaultUserRoutesState, action) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return { ...state, visited: [action.payload.pathname, ...state.visited] };
    default:
      return state;
  }
}
