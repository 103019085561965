import * as ValidationSchemas from 'modules/new-applications/components/application-actions/PPSRRegister/validationSchema/index';
import isBlank from 'utils/isBlank';
import * as yup from 'yup';

function getValidationSchemaByRegion(region) {
  return ValidationSchemas[`${region}ValidationSchema`];
}

export function getFinancingStatementValidationSchema(region) {
  if (isBlank(region)) {
    return yup.object().shape({});
  }

  const validationSchema = getValidationSchemaByRegion(region);
  return yup
    .object()
    .shape(validationSchema.getFinancingStatementValidationSchema());
}

export function getDebtorDetailsValidationSchema(debtorType, region) {
  if (isBlank(region)) {
    return yup.object().shape({});
  }

  const validationSchema = getValidationSchemaByRegion(region);
  return yup
    .object()
    .shape(validationSchema.getDebtorDetailsValidationSchema(debtorType));
}

export function getCollateralDetailsValidationSchema(region) {
  if (isBlank(region)) {
    return yup.object().shape({});
  }

  const validationSchema = getValidationSchemaByRegion(region);
  return yup
    .object()
    .shape(validationSchema.getCollateralDetailsValidationSchema());
}

export function getRenewFinancingStatementValidationSchema(region) {
  if (isBlank(region)) {
    return yup.object().shape({});
  }

  const validationSchema = getValidationSchemaByRegion(region);
  return yup
    .object()
    .shape(validationSchema.getRenewFinancingStatementValidationSchema());
}

export function getModifyFinancingStatementValidationSchema(region) {
  if (isBlank(region)) {
    return yup.object().shape({});
  }

  const validationSchema = getValidationSchemaByRegion(region);
  return yup
    .object()
    .shape(validationSchema.getModifyFinancingStatementValidationSchema());
}
