import styled from 'styled-components';

export const Wrapper = styled.div`
  bottom: 0;
  color: transparent;
  cursor: pointer;
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 0;
`;
