import 'react-table/react-table.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty } from 'lodash';
import Container from 'modules/applications/components/credit-checks/credit-works/v2/Container';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { CREDITWORKS_COLORS } from 'variables/theme';

type Props = {
  data: any;
  sequenceDate: string;
};

function ExecutiveSummary(props: Props): ReactElement | null {
  const { data, sequenceDate } = props;

  if (!data) {
    return null;
  }

  const {
    addresses,
    companyNumber,
    companyNameChanges,
    combinedCreditLimit,
    dsoTrend,
    defaults,
    directors,
    industry,
    judgements,
    ppsrRegistrations,
    previousEnquiries,
    notifications,
    numberOfSuppliersLastEom,
    nzbn,
    relatedCompanyInsolvencies,
    status,
    threeMonthDsoAverage,
    timeInBusiness,
    totalExposureEom,
  } = data;

  const blankToNone = (value: string | number) => {
    const processedValue =
      typeof value === 'number' && value !== 0 ? value.toString() : value;
    return isEmpty(processedValue) ? 'none' : processedValue;
  };

  const dsoTrends = {
    Improving: {
      color: CREDITWORKS_COLORS.fernGreen,
      icon: 'caret-down',
      size: '2x',
    },
    'No Change': {
      color: CREDITWORKS_COLORS.olivine,
      icon: 'circle',
    },
    Worsening: {
      color: CREDITWORKS_COLORS.cinnabar,
      icon: 'caret-up',
      size: '2x',
    },
  };

  const currentTrend = dsoTrends[dsoTrend] || {};
  const { color, icon, size } = currentTrend;

  return (
    <Container subHeading="Executive Summary">
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Company number</th>
              <td>{companyNumber}</td>
              <th>Previous Enquiries</th>
              <td>{blankToNone(previousEnquiries)}</td>
            </tr>
            <tr>
              <th>Company status</th>
              <td>{get(status, 'description')}</td>
              <th>Number of Suppliers Last EoM</th>
              <td>{blankToNone(numberOfSuppliersLastEom)}</td>
            </tr>
            <tr>
              <th>NZBN</th>
              <td>{nzbn}</td>
              <th>Total Exposure Last EoM</th>
              <td>{blankToNone(totalExposureEom)}</td>
            </tr>
            <tr>
              <th>Company name changes</th>
              <td>{blankToNone(companyNameChanges)}</td>
              <th>DSO Trend</th>
              <td>
                {isEmpty(currentTrend) ? (
                  dsoTrend
                ) : (
                  <FontAwesomeIcon
                    icon={['fas', icon]}
                    color={color}
                    size={size ? size : ''}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>Address</th>
              <td>{get(addresses, 'registeredAddress.address')}</td>
              <th>PPSR Registrations</th>
              <td>{blankToNone(ppsrRegistrations)}</td>
            </tr>
            <tr>
              <th>Combined Credit Limit</th>
              <td>
                {parseFloat(combinedCreditLimit).toLocaleString('en', {
                  maximumFractionDigits: 2,
                })}
              </td>
              <th>Defaults</th>
              <td>{blankToNone(defaults)}</td>
            </tr>
            <tr>
              <th>Directors</th>
              <td>{directors}</td>
              <th>Notifications</th>
              <td>{blankToNone(notifications)}</td>
            </tr>
            <tr>
              <th>Time in Business</th>
              <td>{timeInBusiness}</td>
              <th>Judgements</th>
              <td>{blankToNone(judgements)}</td>
            </tr>
            <tr>
              <th>Industry</th>
              <td>{industry}</td>
              <th>Three months-DSO average</th>
              <td>{blankToNone(threeMonthDsoAverage)}</td>
            </tr>
            <tr>
              <th>Generated on</th>
              <td>{moment(sequenceDate).format('DD-MMM-YYYY')}</td>
              <th>Related Co. Insolvencies</th>
              <td>{blankToNone(relatedCompanyInsolvencies)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
}

export default ExecutiveSummary;
