import get from 'lodash.get';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import React, { Component } from 'react';
import { formatDateFromObject } from 'utils/dateFormatter';
import { displayEmpty, formatMoney } from 'utils/formatting';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import objectToArray from 'utils/objectToArray';

import styles from '../css/payment-predictor/CompanyInformation.css';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';
import { renderAddress } from '../PaymentPredictorComponent';

export default class CompanyInformation extends Component {
  get companyBasicDetails() {
    return get(this.props, 'response.company_basic_details') || {};
  }

  get companyExtendedDetails() {
    return get(this.props, 'response.company_extended_details') || {};
  }

  get contactDetails() {
    return get(this.props, 'response.contact_details') || {};
  }

  get incorporation() {
    return get(this.props, 'response.incorporation') || {};
  }

  get summaryInformation() {
    return get(this.props, 'response.summary_information') || {};
  }

  get courtActionsSummary() {
    return get(this.props, 'response.court_actions_summary') || {};
  }

  get collectionAndDefaultSummary() {
    return get(this.props, 'response.collections_and_default_summary') || {};
  }

  get lastAsicUpdateDate() {
    return formatDateFromObject(
      this.companyBasicDetails.asic_extract_date,
      'DD MMMM YYYY HH:mm:ss'
    );
  }

  get abnIssueDate() {
    return formatDateFromObject(this.companyBasicDetails.abn_issue_date);
  }

  get incorporationDate() {
    return formatDateFromObject(this.incorporation.reg_incorp_date);
  }

  get telephone() {
    const phoneNumber = this.contactDetails.phone_num;

    if (isPresent(phoneNumber)) {
      return `(${phoneNumber.area_code}) ${phoneNumber.local_num}`;
    }

    return '-';
  }

  get revenue() {
    const { value, isGroup } = this.summaryInformation.revenue || {};
    if (isBlank(value)) {
      return '-';
    }

    const amount = formatMoney(parseFloat(value), 0);

    if (isGroup) {
      return `$${amount} (group)`;
    }

    return `$${amount}`;
  }

  get netWorth() {
    const { value, isGroup } = this.summaryInformation.tangible_net_worth || {};
    if (isBlank(value)) {
      return '-';
    }

    const amount = formatMoney(parseFloat(value), 0);

    if (isGroup) {
      return `$${amount} (group)`;
    }

    return `$${amount}`;
  }

  get headOffice() {
    return objectToArray(this.contactDetails.head_office || {});
  }

  get sicCodes() {
    return objectToArray(this.companyExtendedDetails.sic_code || {});
  }

  renderHeadOffice() {
    const headOffice =
      this.headOffice.find((office) => !office['@mailing_address']) || {};
    const address = headOffice.address;

    if (isBlank(address)) {
      return null;
    }

    return renderAddress(address);
  }

  renderSicCodes() {
    return this.sicCodes.map((sicCode, i) => (
      <div key={`sic-code-${i}`} className={styles.company_information_row}>
        <div>Industry SIC codes</div>
        <div
          className={commonStyles.emphasize}
        >{`${sicCode.code} - ${sicCode.desc}`}</div>
      </div>
    ));
  }

  renderLeftSection() {
    return (
      <div className={styles.company_information_content}>
        <div className={styles.company_information_row}>
          <div>Company Name</div>
          <div className={commonStyles.emphasize}>
            {this.companyBasicDetails.organisation_name}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Head office address</div>
          <div className={commonStyles.emphasize}>
            {this.renderHeadOffice()}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Telephone</div>
          <div className={commonStyles.emphasize}>{this.telephone}</div>
        </div>

        <div className={styles.company_information_row}>
          <div>Website</div>
          <div className={commonStyles.emphasize}>
            {displayEmpty(this.contactDetails.website)}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>ACN</div>
          <div className={commonStyles.emphasize}>
            {this.companyBasicDetails.company_num}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>ABN</div>
          <div className={commonStyles.emphasize}>
            {this.companyBasicDetails.abn}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Type</div>
          <div className={commonStyles.emphasize}>
            {this.incorporation.company_type}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Status</div>
          <div className={commonStyles.emphasize}>
            {this.incorporation.company_status}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Founded</div>
          <div className={commonStyles.emphasize}>
            {this.companyBasicDetails.year_founded}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>ABN Issue date</div>
          <div className={commonStyles.emphasize}>{this.abnIssueDate}</div>
        </div>

        <div className={styles.company_information_row}>
          <div>Date of Incorporation</div>
          <div className={commonStyles.emphasize}>{this.incorporationDate}</div>
        </div>

        <div className={styles.company_information_row}>
          <div>Employees</div>
          <div className={commonStyles.emphasize}>
            {displayEmpty(this.companyExtendedDetails.employees_count)}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Exports</div>
          <div className={commonStyles.emphasize}>
            {this.companyExtendedDetails.is_export ? 'Yes' : 'No'}
          </div>
        </div>

        {this.renderSicCodes()}

        <div className={styles.company_information_row}>
          <div>Line of business</div>
          <div className={commonStyles.emphasize}>
            {displayEmpty(this.companyBasicDetails.line_of_business)}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Trading style</div>
          <div className={commonStyles.emphasize}>
            {displayEmpty(this.companyBasicDetails.main_trading_style)}
          </div>
        </div>

        <div className={styles.company_information_row}>
          <div>Revenue</div>
          <div className={commonStyles.emphasize}>{this.revenue}</div>
        </div>

        <div className={styles.company_information_row}>
          <div>Tangible net worth</div>
          <div className={commonStyles.emphasize}>{this.netWorth}</div>
        </div>

        <div className={styles.company_information_row}>
          <div>Credit enquiries &lt; 12 months</div>
          <div className={commonStyles.emphasize}>
            {this.summaryInformation.enquiries_count}
          </div>
        </div>
      </div>
    );
  }

  renderRightSection() {
    return (
      <div className={styles.other_information_content}>
        {this.renderCorporateOwnership()}
        {this.renderPaymentInformation()}
        {this.renderDirectorInformation()}
      </div>
    );
  }

  renderCorporateOwnership() {
    const parentCompany = this.companyBasicDetails.parent_company || {};

    return (
      <div>
        <p className={commonStyles.emphasize}>Corporate ownership</p>
        <table className={commonStyles.table}>
          <tbody>
            <tr>
              <td className={styles.table_left_column}>Immediate parent</td>
              <td
                className={`${commonStyles.highlight_cell} ${styles.table_right_column}`}
              >
                <span className={commonStyles.emphasize}>
                  {displayEmpty(parentCompany.organisation_name)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPaymentInformation() {
    return (
      <div>
        <p className={commonStyles.emphasize}>Payment information</p>
        <table className={commonStyles.table}>
          <tbody>
            <tr>
              <td className={styles.table_left_column}>Trade references</td>
              <td
                className={`${commonStyles.highlight_cell} ${styles.table_right_column}`}
              >
                <span className={commonStyles.emphasize}>
                  {this.summaryInformation.trade_references_count}
                </span>
              </td>
            </tr>
            <tr>
              <td className={styles.table_left_column}>
                Industry average payment days
              </td>
              <td
                className={`${commonStyles.highlight_cell} ${styles.table_right_column}`}
              >
                <span className={commonStyles.emphasize}>-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderDirectorInformation() {
    return (
      <div>
        <p className={commonStyles.emphasize}>Director information</p>
        <table className={commonStyles.table}>
          <tbody>
            <tr>
              <td className={styles.table_left_column}>Number of Directors</td>
              <td
                className={`${commonStyles.highlight_cell} ${styles.table_right_column}`}
              >
                <span className={commonStyles.emphasize}>
                  {this.summaryInformation.directors_count}
                </span>
              </td>
            </tr>
            <tr>
              <td className={styles.table_left_column}>
                Adverse director experiences
              </td>
              <td
                className={`${commonStyles.highlight_cell} ${styles.table_right_column}`}
              >
                <span className={commonStyles.emphasize}>
                  {this.summaryInformation.director_court_action_count}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Company information"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        <p>Last ASIC update {this.lastAsicUpdateDate}</p>
        <p>All ($) values are represented in $AUD&apos;000</p>
        <div className={styles.company_information_container}>
          {this.renderLeftSection()}
          {this.renderRightSection()}
        </div>
      </ReviewContainer>
    );
  }
}
