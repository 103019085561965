import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection5 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>5.</span> Fees and payment
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>5.1</span> <strong>Fees</strong>:
          1Centre will invoice, and the Supplier shall pay the Fees, at the
          times and in the manner specified by 1Centre, which will include
          direct debit weekly in arrears for the weekly access fee and monthly
          in arrears for the credit checking services. Any additional services
          provided by 1Centre shall be charged on a time and materials basis at
          1Centre’s then-current standard rates.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>5.2</span>{' '}
          <strong>Payment</strong>: The Supplier will pay each 1Centre invoice
          on receipt of the invoice. If payment is not received by the due date
          for payment, 1Centre may give written notice to the Supplier requiring
          payment within 5 Working Days. If that payment is not received by the
          fifth Working Day after 1Centre issued that notice 1Centre may:
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> without prejudice to
          clause 9 (Termination), suspend the performance of any or all Service
          until all invoiced Fees have been paid in full;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> charge default interest
          on overdue amounts calculated on a daily basis at an interest rate
          that is 2% per annum above the overdraft interest rate charged to
          1Centre by its bank from time to time; and
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> recover from the
          Supplier, upon demand, the costs of collection of overdue amounts,
          including all legal costs incurred by 1Centre on a solicitor-client
          basis.
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>5.3</span> <strong>Taxes</strong>
          : The Fees and charges do not include GST nor any other applicable
          taxes, duties, Government fees, duties, levies, imposts or charges
          (together, ‘<strong>taxes</strong>’). All such taxes may be invoiced
          by 1Centre to the Supplier in addition to the Fees and charges at the
          rate applicable at the time of supply.
        </p>
      </div>
    );
  }
}
