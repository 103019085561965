import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import CountryCodeDropdown from 'modules/shared/components/widgets/static/CountryCodeDropdown';
import EmailInput from '../../../shared/components/inputs/EmailInput';
import Radiobox from 'modules/shared/components/inputs/Radiobox';
import React, { Fragment } from 'react';
import TextInput from '../../../shared/components/inputs/TextInput';
import isBlank from 'utils/isBlank';
import styles from './css/Business.css';
import getIsThisYouLabel from 'utils/getIsThisYouLabel';
import get from 'lodash.get';
import { TrusteeType } from './corporate-trustee/TrusteeType';
import { CorporateTrusteeDetails } from './corporate-trustee/CorporateTrusteeDetails';
import { FEATURE_FLAGS } from 'conf';

class BusinessPartyDetailsForm extends React.Component {
  isEmailValid = () => {
    const { values } = this.props;
    return this.refs.email ? this.refs.email._validate(values['email']) : true;
  };

  isApplicantText = () => {
    const isApplicant = this.props.values.is_applicant;
    if (isBlank(isApplicant)) {
      return '';
    }

    return isApplicant ? 'Yes' : 'No';
  };

  hideTrusteeApplicantOption = () => {
    const { values, isCorporateTrusteeGuarantorEnabled, subComponent } =
      this.props;

    const hideForIndependentTrustee = values.trustee_type === 'independent';

    const hideForIndividualTrustee =
      values.trustee_type === 'individual'
        ? isCorporateTrusteeGuarantorEnabled
        : hideForIndependentTrustee;

    const hideForTrusteePeople =
      values.trustee_type === 'corporate'
        ? isCorporateTrusteeGuarantorEnabled || !subComponent
        : hideForIndividualTrustee;

    return hideForTrusteePeople || !values.trustee_type;
  };

  renderSignatoryApplicant = () => {
    const {
      application,
      canSelectSignatoryApplicant,
      errors,
      handleIsApplicantChange,
      values,
      isGuarantorApproved,
      isCorporateTrusteeEnabled,
      hasApplicantSignatoryDecision,
    } = this.props;

    const isTrustApplication = application.attributes.legal_type === 'trust';

    const trusteeIsApplicantSelectionHide =
      isTrustApplication && isCorporateTrusteeEnabled
        ? this.hideTrusteeApplicantOption()
        : false;

    if (!canSelectSignatoryApplicant || trusteeIsApplicantSelectionHide) return;

    const { first_name: firstName, last_name: lastName } = values;
    const isThisYouLabel = getIsThisYouLabel({ firstName, lastName });

    const originalLogic =
      get(application, 'attributes.submission_status', '') === 'complete' ||
      isGuarantorApproved;
    const disableIsApplicant = FEATURE_FLAGS.FEATURE_FLAG_DUPLICATE_AUTH_FIX
      ? hasApplicantSignatoryDecision || isGuarantorApproved
      : originalLogic;
    return (
      <div className={styles.is_this_you_radio_container}>
        <Radiobox
          id="is_applicant"
          name="is_applicant"
          error={errors['is_applicant']}
          handleChange={handleIsApplicantChange}
          label={isThisYouLabel}
          value={this.isApplicantText()}
          radioList={['Yes', 'No']}
          disabled={disableIsApplicant}
        />
      </div>
    );
  };

  render() {
    const {
      emailBlurHanlder,
      emailChangeHandler,
      entity_type,
      errors,
      handleBlur,
      handleChange,
      handlePostalAddressChange,
      handlePostalAddressClick,
      handleResidentialChange,
      handleResidentialClick,
      handleResidentialTypeChange,
      onCountryCodeChangeHandler,
      values,
      isGuarantorApproved,
      setCompanyNumberInvalidError,
      subComponent,
      dispatch,
      entityRegion,
      isCorporateTrusteeEnabled,
      isCorporateTrusteeLocked,
    } = this.props;

    return (
      <div>
        <div className={styles.party_details_col}>
          {isCorporateTrusteeEnabled &&
          entity_type === 'trust' &&
          !subComponent ? (
            <TrusteeType
              onChange={handleChange}
              value={values.trustee_type}
              error={errors.trustee_type}
              disabled={isCorporateTrusteeLocked}
              dispatch={dispatch}
            />
          ) : null}
          {isCorporateTrusteeEnabled &&
          values.trustee_type &&
          values.trustee_type === 'corporate' &&
          !subComponent ? (
            <CorporateTrusteeDetails
              region={entityRegion}
              handleChange={handleChange}
              companyName={values.corporate_trustee_company_name}
              companyNumber={values.corporate_trustee_company_number}
              companyNameError={errors.corporate_trustee_company_name}
              companyNumberError={errors.corporate_trustee_company_number}
              setCompanyNumberInvalidError={setCompanyNumberInvalidError}
              isCorporateTrusteeLocked={isCorporateTrusteeLocked}
              dispatch={dispatch}
            />
          ) : entity_type === 'trust' &&
            isCorporateTrusteeEnabled &&
            !values.trustee_type ? null : (
            <Fragment>
              <TextInput
                id="first_name"
                name="first_name"
                error={errors['first_name']}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label="Legal first name"
                required={true}
                value={values['first_name']}
                disabled={isGuarantorApproved}
              />
              <TextInput
                id="middle_name"
                name="middle_name"
                error={errors['middle_name']}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label="Middle name"
                required={false}
                value={values['middle_name']}
                disabled={isGuarantorApproved}
              />
              <TextInput
                id="last_name"
                name="last_name"
                error={errors['last_name']}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label="Last name"
                required={true}
                value={values['last_name']}
                disabled={isGuarantorApproved}
              />
              {(isCorporateTrusteeEnabled && entity_type === 'trust'
                ? !this.hideTrusteeApplicantOption() ||
                  values.trustee_type === 'independent'
                : entity_type !== 'company' && !subComponent) && (
                <EmailInput
                  id="email"
                  name="email"
                  type="email"
                  ref="email"
                  handleChange={emailChangeHandler}
                  handleBlur={emailBlurHanlder}
                  label="Email"
                  required={true}
                  value={values['email']}
                  disabled={values.is_applicant || isGuarantorApproved}
                  customError={errors.emailErrorMessage}
                />
              )}
            </Fragment>
          )}

          {entity_type === 'personal' && (
            <div className={styles.row}>
              <div className={styles.contact_country_code}>
                <CountryCodeDropdown
                  key="contact_country_code"
                  countryCodeId={'contact_country_code'}
                  label={'Country'}
                  countryCodeError={errors['contact_country_code']}
                  countryCodeValue={values['contact_country_code']}
                  required={true}
                  handleChange={onCountryCodeChangeHandler}
                />
              </div>
              <div className={styles.contact_phone_number}>
                <TextInput
                  key="contact_phone_number"
                  id="contact_phone_number"
                  name="contact_phone_number"
                  type="tel"
                  error={errors['contact_phone_number']}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Phone number"
                  required={true}
                  value={values['contact_phone_number']}
                />
              </div>
            </div>
          )}
        </div>
        {entity_type === 'personal' && (
          <div className={styles.party_details_col}>
            <TextInput
              id="previous_last_name"
              name="previous_last_name"
              error={errors['previous_last_name']}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label="Previous Last name"
              required={false}
              helper_text={'If married'}
              value={values['previous_last_name']}
            />
            <div className={styles.row}>
              <div className={styles.party_details_col}>
                <TextInput
                  id="employer"
                  name="employer"
                  error={errors['employer']}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Employer"
                  required={false}
                  helper_text={'If applicable'}
                  value={values['employer']}
                />
              </div>
              <div className={styles.party_details_col}>
                <TextInput
                  id="position"
                  name="position"
                  error={errors['position']}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  label="Position"
                  required={false}
                  helper_text={'If applicable'}
                  value={values['position']}
                />
              </div>
            </div>
            <AutoSuggest
              id="residential_address"
              label="Residential Address"
              value={
                (values['residential_address'] || { full_address: '' })
                  .full_address
              }
              loading={values['residential_address_loading']}
              suggest_items={values['residential_address_list'] || {}}
              error={errors['residential_address']}
              handleChange={handleResidentialChange}
              handleClick={handleResidentialClick}
              handleBlur={handleBlur}
              required={true}
              css_class="address_lookup"
              selected_item={
                values.residential_api_id
                  ? values['residential_address'].full_address
                  : ''
              }
              force_focus={true}
            />
            <div className={styles.residential_type}>
              <Radiobox
                id="residential_type"
                name="residential_type"
                error={errors['residential_type']}
                handleChange={handleResidentialTypeChange}
                label="Residental Type"
                value={values['residential_type']}
                radioList={['rented', 'owned']}
              />
            </div>
            <AutoSuggest
              id="person_postal_address"
              label="Postal Address"
              value={
                (values['person_postal_address'] || { full_address: '' })
                  .full_address
              }
              loading={values['person_postal_address_loading']}
              suggest_items={values['person_postal_address_list'] || {}}
              error={errors['person_postal_address']}
              handleChange={handlePostalAddressChange}
              handleClick={handlePostalAddressClick}
              handleBlur={handleBlur}
              required={false}
              css_class="address_lookup"
              selected_item={
                values.person_postal_address_api_id
                  ? values['person_postal_address'].full_address
                  : ''
              }
            />
          </div>
        )}
        {this.renderSignatoryApplicant()}
      </div>
    );
  }
}

export default BusinessPartyDetailsForm;
