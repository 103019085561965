import get from 'lodash.get';
import { ITradingName } from 'models/TradingNameModel';
import React from 'react';

import SupplierForm from './SupplierForm';
import useProcessedAddonAnswers, {
  ownerType,
} from './useProcessedAddonAnswers';

type Props = {
  data: any;
  formProps: any;
  isCompany: boolean;
  owner: any;
};

function TradingNamesForm({ data, formProps, isCompany, owner }: Props) {
  if (
    ownerType(owner) !== 'Application' ||
    !isCompany ||
    !get(data, 'enabled', false)
  ) {
    return null;
  }

  const {
    handleTradingNameChange,
    tradingNameAnswers,
  } = useProcessedAddonAnswers(owner);

  const { applicationTradingNameLinks } = owner;
  const tradingNames: ITradingName[] = owner.consumerTradingNames || [];

  return applicationTradingNameLinks.map(({ id, tradingNameId }) => {
    // TODO - [9079] has updated structure on ApplicationModel
    // We can now grab tradingName data straight out of ApplicationTradingNameLinkModel
    const tradingName = tradingNames.find(
      ({ id }) => id === tradingNameId
    ) as ITradingName;
    const key = `iufTradingEntity-${tradingName.id}`;
    const addonAnswer = tradingNameAnswers.find(
      ({ owner_id }) => owner_id === id
    );

    return (
      <SupplierForm
        answers={get(addonAnswer, 'answers.results', [])}
        key={key}
        label={tradingName.tradingName}
        data={data}
        onChange={(params) =>
          handleTradingNameChange(get(addonAnswer, 'owner_id', ''), params)
        }
        reduxKey={key}
        {...formProps}
      />
    );
  });
}

export default TradingNamesForm;
