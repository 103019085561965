import api from 'api';
import BaseModel from './BaseModel';
import IUFApprovalHistoryModel from './IUFApprovalHistoryModel';

export default class IUFApprovalModel extends BaseModel {
  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  async create({
    attributes = this.attributes,
    onSuccessCallback,
    onErrorCallback = () => {},
  }) {
    const iufApprovalsAPI = api('iuf_approvals');

    try {
      await iufApprovalsAPI.createIUFApproval(attributes);

      onSuccessCallback();
    } catch (error) {
      console.error(error);
      onErrorCallback();
    }
  }

  async update({
    attributes = this.attributes,
    onSuccessCallback,
    onErrorCallback = () => {},
  }) {
    const iufApprovalsAPI = api('iuf_approvals');

    try {
      await iufApprovalsAPI.updateIUFApproval(this.id, attributes);

      onSuccessCallback();
    } catch (error) {
      console.error(error);
      onErrorCallback();
    }
  }

  /** Private functions */

  assignRelationships() {
    /** Many relationships */
    // @ts-ignore-next-line
    this.assignManyRelationship({
      key: 'iuf_approval_histories',
      model: IUFApprovalHistoryModel,
    });
  }
}
