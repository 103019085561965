import styled from 'styled-components';
import { FONT_SIZES } from 'variables/typography';

export const Container = styled.div`
  @media (max-width: 767px) {
    width: 80%;
    left: 10%;
    position: relative;
  }

  input {
    background-color: white;
  }
`;

export const SectionHeader = styled.div`
  margin-bottom: 30px;
  font-size: ${FONT_SIZES.size2};

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

export const FieldWrapper = styled.div`
  @media (max-width: 767px) {
    margin-top: -1rem !important;
  }
`;
