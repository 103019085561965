import { FEATURE_FLAGS } from 'conf';
// @ts-ignore
import { browserHistory } from 'react-router';

export const useFeatureFlag = () => {
  const isAccountMonitoringEnabled =
    FEATURE_FLAGS.FEATURE_FLAG_ACCOUNT_MONITORING;

  if (!isAccountMonitoringEnabled) {
    browserHistory.goBack('/');
    return null;
  }

  return null;
};
