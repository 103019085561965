import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import {
  Section,
  Title,
  Wrapper,
} from 'modules/shared/components/v2/SupplierSetup/styles';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import Form from './Form';

function AppValuesApprovalLevels(props): ReactElement {
  const { dismissHandler } = props;

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <Wrapper>
        <div className="container">
          <div className="column">
            <Section>
              <Title>1Centre Approval Hierarchy (1CAH)</Title>
              <p>
                You can set up the approval structure for trade applications
                here.
              </p>
              <CloseButton
                handleClick={dismissHandler}
                style={{ right: 0, top: 0 }}
              />
            </Section>
            <Form {...props} />
          </div>
        </div>
      </Wrapper>
    </MuiThemeProvider>
  );
}

export default connect((state, ownProps) => {
  const manageProfile = state.manage_profile;
  const currentEntityAttributes = manageProfile.current_entity.attributes;
  const passedApprovalLevels = ownProps.approval_levels;
  const approvalLevels =
    passedApprovalLevels.length === 0 ? [{}] : passedApprovalLevels;

  return {
    approvalLevels,
    approval_hierarchy_version:
      (manageProfile.current_entity_approval_hierarchy_history || []).length +
      1,
    brokerConfig: currentEntityAttributes.broker_config || {},
    brokerEnabled: currentEntityAttributes.enable_broker || false,
    reviewerEnabled: currentEntityAttributes.reviewer_enabled,
  };
})(AppValuesApprovalLevels);
