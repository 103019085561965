import React, { useEffect, useState, ReactElement } from 'react';

// import styles from "../css/Modal.css";
import { ContentWrapper, Footer, Header, Wrapper } from './styles';

type Props = {
  content: ReactElement;
  footer?: ReactElement;
  title?: ReactElement | string;
  onClose: () => void;
  width?: number;
  zIndex?: number;
  dataCy?: string;
};

function Modal(props: Props): ReactElement {
  const { content, title, footer, onClose, width, zIndex, dataCy } = props;
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Wrapper
      className={`modal columns ${show ? 'is-active' : ''}`}
      zIndex={zIndex}
      data-cy={dataCy}
    >
      <div className="modal-background"></div>
      <ContentWrapper className="modal-card column" width={width}>
        <Header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            type="button"
            className="delete"
            aria-label="Close"
            onClick={() => {
              setShow(false);
              onClose();
            }}
          ></button>
        </Header>
        <section className="modal-card-body is-size-5 px-6 has-text-black has-text-weight-normal has-text-left">
          {content}
        </section>
        <Footer className="modal-card-foot">
          <div className="container has-text-centered has-text-weight-normal">
            {footer}
          </div>
        </Footer>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Modal;
