import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React, { ReactElement } from 'react';

type handleArgs = {
  value: boolean;
};

interface Props {
  handleCheck: (args: handleArgs) => void;
  isChecked: boolean;
  userType: string;
}

function AccessTokenisedDocument({
  handleCheck,
  isChecked,
}: Props): ReactElement {
  const item = {
    description:
      'Allow this user to access tokenised Anti-Fraud\
      identification prior to the final approval.',
    id: 'can_access_tokenised_document',
    label: 'Access tokenised document',
    type: 'page_permissions',
  };

  const onClick = () => handleCheck({ value: !isChecked, ...item });

  return (
    <Checkbox
      checkboxId="can_access_tokenised_document"
      checked={isChecked}
      handleChange={onClick}
      label={item.label}
      description={item.description}
    />
  );
}

export default AccessTokenisedDocument;
