import axios from './axios';

export default class SecuredPartyGroups {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'secured_party_groups';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
  }

  getSecuredPartyGroup({ region, spgId, spgPwd }) {
    return this.axios.get(`${region}/${this.type}/${spgId}`, {
      params: { spg_pwd: spgPwd },
    });
  }
}
