import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileSize from 'filesize';
import {
  clickEvent,
  setIdentityValue,
  updateImageLoading,
} from 'modules/identity/actions';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

import Actions from './Actions/Actions';
import Camera from './Camera/Camera';
import { InnerWrapper, Wrapper } from './styles';
import UploadedFile from './UploadedFile/UploadedFile';
import {
  getBase64,
  getPdfImage,
  getPresignedUrl,
  mb2Bytes,
  storeFileToS3,
  toPreviewObj,
  updateFilename,
} from './utils';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import Button from 'modules/shared/components/inputs/Button';
import { FEATURE_FLAGS } from 'conf';
import { UploadStatus } from 'modules/shared/components';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const FILE_TYPES = {
  jpeg: {
    format: 'image/jpeg',
    name: 'JPEG',
  },
  jpg: {
    format: 'image/jpg',
    name: 'JPG',
  },
  png: {
    format: 'image/png',
    name: 'PNG',
  },
  pdf: {
    format: 'application/pdf',
    name: 'one page PDF',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

function FileUpload(props) {
  const {
    attachmentType,
    callback,
    cameraOnly,
    dispatch,
    distinctId,
    fileId,
    hasError,
    errorMessage,
    acceptedFileTypes: passedFileTypes,
    uploadedFile,
    maxFileSize,
    hasDependency,
    currentImageName,
    currentBackImageName,
    hasTypeSelected,
    isUploading,
    hasImageUploaded,
    uploadedFrontImage,
    uploadedBackImage,
    identityType,
    identityRegion,
    scraping,
    maxRetries,
    isOtherIdType,
  } = props;

  const latestProps = useRef();
  latestProps.current = props;
  const [file, setFile] = useState(toPreviewObj(uploadedFile));
  const [status, setStatus] = useState({
    message: null,
    type: null,
  });

  const [cameraMode, setCameraMode] = useState(false);
  const [progress, setProgress] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [scrapeTrigger, setScrapeTrigger] = useState(false);
  const [currentIdentityType, setCurrentIdentityType] = useState(
    identityType || null
  );
  const [uploadedInCameraMode, setUploadedInCameraMode] = useState(false);

  const hasFile = !!file;
  const acceptedFileTypes = passedFileTypes
    ? passedFileTypes
    : Object.keys(FILE_TYPES);
  const fileTypeFormats = acceptedFileTypes.map(
    (fileType) => FILE_TYPES[fileType].format
  );

  const isIdentificationImage = [
    'identification_image',
    'identification_back_image',
  ].includes(attachmentType);

  const uploadIcon = cameraOnly ? 'camera' : 'upload';

  function saveFile(file) {
    if (maxFileSize && file.size > maxFileSize) {
      const processedFileSize = fileSize(maxFileSize, { exponent: 2 });

      return setStatus({
        message: `File size cannot be over than ${processedFileSize}.`,
        type: 'danger',
      });
    }

    setCameraMode(false);
    setFile(file);

    return uploadFile(file);
  }

  const storeFileToS3Callback = (response) => {
    dispatch(updateImageLoading(response.error ? 'error' : 'success'));
  };

  const showStatusMessage = () => {
    const { type, message } = status;
    if (!message || !hasFile) {
      return false;
    }

    const isErrorStatus = type === 'danger';
    const isBackImageUploader = fileId === 'back_image_64';
    const internalError = message.includes('Please try again');

    const showIdStatus =
      identityType === 'driver_licence'
        ? uploadedFrontImage && uploadedBackImage
        : uploadedFrontImage;

    const hideBackImageUploaderError =
      isErrorStatus && isBackImageUploader && !internalError;
    const hideIncompleteUploadError =
      isErrorStatus && isIdentificationImage && !showIdStatus;

    if (hideBackImageUploaderError || hideIncompleteUploadError) {
      return false;
    }

    return true;
  };

  async function uploadFile(file) {
    try {
      dispatch(updateImageLoading('start'));
      dispatch(clickEvent('upload photo', distinctId));

      setStatus({
        message: 'Uploading',
        type: 'loading',
      });

      // Get presigned URL
      const data = await getPresignedUrl({ ...props, file });
      // Store file to S3
      await storeFileToS3(data.url, file, setProgress, storeFileToS3Callback);
      // Set store value
      dispatch(setIdentityValue(fileId, data.url));

      let attachmentTypeParam = attachmentType;
      let attachmentValue = data.filename;
      let attachments = {};

      if (fileId.includes('image_64')) {
        dispatch(setIdentityValue(`${fileId}_name`, data.filename));
      }

      if (hasDependency) {
        attachmentTypeParam = 'identification_image';
        attachmentValue =
          currentImageName ||
          (fileId === 'image_64' ? data.filename : undefined);

        attachments = {
          'identification_back_image':
            currentBackImageName ||
            (fileId === 'back_image_64' ? data.filename : undefined),
        };
      }

      attachments = {
        ...attachments,
        [attachmentTypeParam]: attachmentValue,
      };

      if (
        (hasDependency && !Object.values(attachments).includes(undefined)) ||
        !hasDependency
      ) {
        if (isIdentificationImage) {
          dispatch(setIdentityValue('scraping'));
          setScrapeTrigger(true);
        }

        const ocrData = await updateFilename({
          ...props,
          attachments,
          identityType,
          ...(isIdentificationImage ? { region: identityRegion } : {}),
        });
        if (hasDependency) {
          dispatch(
            setIdentityValue('image_64', ocrData['identification_image_url'])
          );
          dispatch(
            setIdentityValue(
              'back_image_64',
              ocrData['identification_back_image_url']
            )
          );
        } else {
          dispatch(setIdentityValue(fileId, ocrData[`${attachmentType}_url`]));
        }

        if (identityType !== 'driver_licence' && fileId.includes('image_64')) {
          unsetBackImage();
        }
        dispatch(
          setIdentityValue('authenticity_score', ocrData['authenticity_score'])
        );

        const identificationTypeToSave =
          identityType === 'other' &&
          ['driver_licence', 'passport'].includes(ocrData.identification_type)
            ? ocrData.identification_type
            : identityType;

        if (callback) {
          callback({
            ...ocrData,
            identification_type: FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES
              ? identityType
              : identificationTypeToSave,
            scraped_identification_type: ocrData.identification_type,
          });
        }
      }

      const oldErrorChecks = latestProps.current.hasError && hasImageUploaded;
      const newErrorChecks =
        (FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES && maxRetries) ||
        (FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdType)
          ? false
          : oldErrorChecks;

      if (newErrorChecks) {
        setStatus({
          message: latestProps.current.errorMessage,
          type: 'danger',
        });
      }

      const successCheck =
        (FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES && maxRetries) ||
        (FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdType)
          ? true
          : !latestProps.current.hasError;

      if (successCheck) {
        setStatus({
          message: 'Upload completed!',
          type: 'success',
        });
      }
      if (
        FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES &&
        maxRetries &&
        fileId.includes('image_64')
      ) {
        setStatus({
          message: "You've reached the maximum ID upload attempts.",
          type: 'danger',
        });
      }
      setScrapeTrigger(false);
    } catch (e) {
      const message = `${e.error ? e.error : 'Error.'} Please try again.`;
      // console.log(e);
      return setStatus({ message, type: 'danger' });
    }
  }

  function removeBeforeOpen() {
    setShowChangeModal(true);
  }

  function handleRemoveFile() {
    if (hasDependency && hasImageUploaded) {
      setShowModal(true);
    } else {
      if (hasDependency) {
        dispatch(setIdentityValue(`${fileId}_name`, null));
      }
      dispatch(setIdentityValue(fileId, null));
      setFile(null);
      setStatus({ message: null, type: null });
    }
    setScrapeTrigger(false);
  }

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }

    setStatus({
      message: 'Processing',
      type: 'loading',
    });

    const preview =
      file.type === 'application/pdf'
        ? await getPdfImage(file)
        : await getBase64(file);

    saveFile(Object.assign(file, { preview }));
  };

  const onDropRejected = (rejectedFiles) => {
    const file = rejectedFiles[0];
    const error = file.errors[0];
    let message = error.message;
    let fileTypes = acceptedFileTypes
      .filter((_, index) => index < acceptedFileTypes.length - 1)
      .map((type) => FILE_TYPES[type].name)
      .join(', ');

    if (acceptedFileTypes.length > 1) {
      const lastFile = acceptedFileTypes[acceptedFileTypes.length - 1];
      fileTypes = `${fileTypes} or a ${FILE_TYPES[lastFile].name}`;
    }

    if (error.code === 'file-invalid-type') {
      message = `Upload failed. Please upload ${fileTypes}.`;
    }

    setStatus({ message, type: 'danger' });
  };

  function unsetBackImage() {
    dispatch(setIdentityValue('back_image_64', null));
    dispatch(setIdentityValue('back_image_64_name', null));
  }

  function unsetFrontImage() {
    dispatch(setIdentityValue('image_64', null));
    dispatch(setIdentityValue('image_64_name', null));
  }

  const handleIdInput = () => {
    uploadedInCameraMode ? setCameraMode(true) : open();
  };

  function handleModalConfirm() {
    unsetFrontImage();
    unsetBackImage();
    setStatus({ message: null, type: null });
    setShowModal(false);
    setScrapeTrigger(false);
    if (showChangeModal) {
      setShowChangeModal(false);
      handleIdInput();
    }
  }

  function closeModal() {
    setShowModal(false);
    setShowChangeModal(false);
  }

  const { getInputProps, getRootProps, open } = useDropzone({
    accept: fileTypeFormats.join(','),
    disabled: cameraOnly,
    maxFiles: 1,
    maxSize: mb2Bytes(20),
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  function handleChangeFile() {
    if (hasDependency && hasImageUploaded) {
      removeBeforeOpen();
    } else {
      handleIdInput();
    }
  }

  const handleCameraUpload = () => {
    setCameraMode(true);
    setUploadedInCameraMode(true);
  };

  const handleFileUpload = () => {
    setUploadedInCameraMode(false);
    open();
  };

  useEffect(() => {
    if (!uploadedFrontImage && fileId === 'image_64') {
      setFile(null);
    }
  }, [uploadedFrontImage]);

  useEffect(() => {
    if (!uploadedBackImage && fileId === 'back_image_64') {
      setFile(null);
    }
  }, [uploadedBackImage]);

  useEffect(() => {
    if (hasError) {
      setStatus({ message: errorMessage, type: 'danger' });
    }

    if (!hasError && status.type === 'danger') {
      setStatus({ message: null, type: null });
    }
  }, [hasError, errorMessage]);

  useEffect(() => {
    const { message, type } = status;
    if (scraping && scrapeTrigger) {
      setStatus({
        message: 'Processing',
        type: 'loading',
      });
    } else if (
      FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES
        ? identityType !== 'other'
        : true
    ) {
      setStatus({
        message: message !== 'Processing' ? message : null,
        type: type !== 'loading' ? type : null,
      });
    }
  }, [scraping, scrapeTrigger]);

  useEffect(() => {
    if (
      (currentIdentityType &&
        currentIdentityType !== identityType &&
        currentIdentityType !== 'other') ||
      (hasFile && !hasTypeSelected && isIdentificationImage)
    ) {
      unsetBackImage();
      unsetFrontImage();
      if (FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES) {
        setStatus({
          message: null,
          type: null,
        });
      }
    }

    setScrapeTrigger(false);
    setCurrentIdentityType(identityType);
  }, [identityType]);

  useEffect(() => {
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  }, [file]);

  return (
    <Wrapper
      {...getRootProps({ className: 'dropzone' })}
      hasFile={hasFile}
      hasError={hasError}
      cameraMode={cameraMode}
    >
      <input
        {...getInputProps({
          capture: 'camera',
        })}
      />

      {cameraMode && (
        <Camera
          cameraOnly={cameraOnly}
          distinctId={distinctId}
          dispatch={dispatch}
          open={open}
          setFile={saveFile}
          setCameraMode={setCameraMode}
        />
      )}
      {!cameraMode &&
        hasFile &&
        ((hasTypeSelected && isIdentificationImage) ||
          !isIdentificationImage) && (
          <UploadedFile
            cameraOnly={cameraOnly}
            file={file}
            handleChangeFile={handleChangeFile}
            handleRemoveFile={handleRemoveFile}
          />
        )}

      {progress && progress !== 100 && (
        <progress
          className="progress is-primary is-small"
          value={progress}
          max="100"
        >
          {progress}%
        </progress>
      )}

      {!cameraMode && !hasFile && (
        <InnerWrapper>
          <div>
            <FontAwesomeIcon
              icon={['fas', uploadIcon]}
              size="2x"
              className="has-text-primary"
            />
            <br />
            {!cameraOnly && !isMobile() && (
              <p className="is-size-2">Drag and drop file here, or</p>
            )}
          </div>
          <Actions
            handleFileUpload={handleFileUpload}
            handleCameraUpload={handleCameraUpload}
            cameraOnly={cameraOnly}
            disabled={!hasTypeSelected || isUploading}
          />
        </InnerWrapper>
      )}
      {showStatusMessage() && (
        <UploadStatus type={status.type} message={status.message} />
      )}
      {hasDependency && (showModal || showChangeModal) && (
        <SquareModal title={'Confirm Modification'} size={'small'}>
          <div>
            <p>
              The system will remove both images if you need to make a change.
              Please confirm.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button text={'Confirm'} handleClick={handleModalConfirm} />
              <Button text={'Cancel'} white={true} handleClick={closeModal} />
            </div>
          </div>
        </SquareModal>
      )}
    </Wrapper>
  );
}

File.propTypes = {
  attachmentType: PropTypes.string.isRequired,
  cameraOnly: PropTypes.boolean,
  resourceType: PropTypes.string.isRequired,
};

export default connect((state, ownProps) => {
  const { identity } = state;
  const uploadedBackImage = identity.back_image_64 || undefined;
  const uploadedFrontImage = identity.image_64 || undefined;
  const hasImageUploaded = !!uploadedFrontImage || !!uploadedBackImage;
  return {
    currentUser: state.current_user,
    currentImageName: identity.image_64_name,
    currentBackImageName: identity.back_image_64_name,
    hasTypeSelected: identity.type,
    isUploading: identity.image_uploading,
    uploadedFrontImage,
    uploadedBackImage,
    hasImageUploaded,
    identityType: identity.type,
    identityRegion: identity.region,
    scraping: identity.scraping,
    scrapedIdentificationType: identity.scraped_identification_type,
  };
})(FileUpload);
