import getAccountTypeName from 'utils/getAccountTypeName';

const getAccountTypeOptions = () => {
  const creditName = getAccountTypeName('credit');
  const cashName = getAccountTypeName('cash');

  return [
    { brief: creditName, label: creditName, value: 'credit' },
    { brief: cashName, label: cashName, value: 'cash' },
  ];
};

export default getAccountTypeOptions;
