import {
  DASHBOARD_GET_REPORT_START,
  DASHBOARD_GET_REPORT_ERROR,
  DASHBOARD_GET_REPORT_SUCCESS,
  DASHBOARD_GET_MONTHLY_REPORT_START,
  DASHBOARD_GET_MONTHLY_REPORT_ERROR,
  DASHBOARD_GET_MONTHLY_REPORT_SUCCESS,
  DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_START,
  DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_RESET,
  DASHBOARD_LOAD_CURRENT_ENTITY_START,
  DASHBOARD_LOAD_CURRENT_ENTITY_ERROR,
  DASHBOARD_LOAD_CURRENT_ENTITY_SUCCESS,
  DASHBOARD_SET_CONSUMER_AS_SUPPLIER_START,
  DASHBOARD_SET_CONSUMER_AS_SUPPLIER_ERROR,
  DASHBOARD_SET_CONSUMER_AS_SUPPLIER_SUCCESS,
  DASHBOARD_SET_PERIOD,
  DASHBOARD_SET_TIER,
} from './constants';
import moment from 'moment';
import { remove, set } from 'modules/dashboard/utils';
import { FEATURE_FLAGS } from 'conf';

const DEFAULT_FORMAT = 'YYYY-MM-DD';
const DEFAULT_MIN_DATE = '2016-11-25';

const defaultPeriod =
  FEATURE_FLAGS.FEATURE_FLAG_DASHBOARD_DEFAULT_PERIOD_FILTER_ALL
    ? {
        from: moment(DEFAULT_MIN_DATE).format(DEFAULT_FORMAT),
        to: moment().format(DEFAULT_FORMAT),
        selectedOption: 'All',
      }
    : {
        from: moment().subtract(30, 'days').format(DEFAULT_FORMAT),
        to: moment().format(DEFAULT_FORMAT),
        selectedOption: 'Last 30 days',
        compare: {
          from: moment().subtract(60, 'days').format(DEFAULT_FORMAT),
          to: moment().subtract(31, 'days').format(DEFAULT_FORMAT),
        },
      };

const initialState = {
  failed_application: false,
  report: {
    kpis: {
      total_started: 0,
      approved: 0,
      declined: 0,
      total_limit: 0,
    },
    trends: [
      {
        total_started: 4,
        declined: 2,
        approved: 1,
        pending: 1,
        incomplete: 0,
      },
      {
        total_started: 6,
        declined: 2,
        approved: 0,
        pending: 3,
        incomplete: 1,
      },
      {
        total_started: 2,
        declined: 0,
        approved: 1,
        pending: 0,
        incomplete: 1,
      },
    ],
    to_review_list: [],
    incomplete_list: [],
  },
  monthly_report: {
    kpis: {
      total_started: 0,
      incomplete: 0,
      pending: 0,
      approved: 0,
      declined: 0,
      total_limit: 0,
    },
  },
  loading: {
    report: false,
    monthly_report: false,
  },
  current_entity: null,

  period: defaultPeriod,
  comparison: {
    from: moment().subtract(1, 'month'),
    to: moment(),
    selectedOption: 'Previous month',
  },
  tier: null,
};

export function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_START:
      return { ...state, failed_application: true };
    case DASHBOARD_FAILED_START_APPLICATION_ATTEMPT_RESET:
      return { ...state, failed_application: false };

    case DASHBOARD_GET_REPORT_START:
      return { ...state, loading: { ...state.loading, report: true } };
    case DASHBOARD_GET_REPORT_ERROR:
      return { ...state, loading: { ...state.loading, report: false } };
    case DASHBOARD_GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, report: false },
        report: action.payload,
      };
    case DASHBOARD_GET_MONTHLY_REPORT_START:
      return { ...state, loading: { ...state.loading, monthly_report: true } };
    case DASHBOARD_GET_MONTHLY_REPORT_ERROR:
      return { ...state, loading: { ...state.loading, monthly_report: false } };
    case DASHBOARD_GET_MONTHLY_REPORT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, monthly_report: false },
        monthly_report: action.payload,
      };
    case DASHBOARD_LOAD_CURRENT_ENTITY_START:
      return { ...state };
    case DASHBOARD_LOAD_CURRENT_ENTITY_ERROR:
      return { ...state };
    case DASHBOARD_LOAD_CURRENT_ENTITY_SUCCESS:
      return { ...state, current_entity: action.payload };

    case DASHBOARD_SET_CONSUMER_AS_SUPPLIER_START:
      return { ...state };
    case DASHBOARD_SET_CONSUMER_AS_SUPPLIER_ERROR:
      return { ...state };
    case DASHBOARD_SET_CONSUMER_AS_SUPPLIER_SUCCESS:
      return { ...state };
    case DASHBOARD_SET_PERIOD:
      const { period, persistInLocalStorage } = action.payload;

      if (persistInLocalStorage) {
        set('period', period, action.payload.currentUserEntityId);
      } else {
        remove(action.payload.currentUserEntityId);
      }

      return {
        ...state,
        period,
      };
    case DASHBOARD_SET_TIER:
      const { tier } = action.payload;
      if (persistInLocalStorage) {
        set('tier', tier, action.payload.currentUserEntityId);
      }

      return {
        ...state,
        tier,
      };
    default:
      return { ...state };
  }
}
