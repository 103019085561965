import { formatMoney as utilityFormatMoney } from 'utils/formatting';

const formatMoney = (money: number | string | undefined): string => {
  if (typeof money === 'number') {
    return utilityFormatMoney(money, null, null, null, null);
  }

  if (typeof money === 'undefined' || (money && money === '')) {
    return '';
  }

  return utilityFormatMoney(parseInt(money), null, null, null, null);
};

export default formatMoney;
