/* Import libraries */
import Button from 'modules/shared/components/inputs/Button';
import Checkbox from 'modules/shared/components/inputs/Checkbox';
/* Import components */
import TextInput from 'modules/shared/components/inputs/TextInput';
import ExtraDescription from 'modules/shared/components/text/ExtraDescription';
import CircleIconButton from 'modules/shared/components/widgets/interactive/CircleIconButton';
import RoundedDropdown from 'modules/shared/components/widgets/interactive/RoundedDropdown';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import Modal from 'modules/shared/components/widgets/static/Modal';
import {
  isFeatureEditEnabled,
  isHeadquarter,
  isRegular,
} from 'modules/shared/helpers/headquarterDetect';
import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { updateRuleSet } from 'modules/addons/auto_decisioning/actions';

/* Import actions */
import {
  loadCard,
  setMinimumTradeReferences,
  setTradeReferenceCheck,
  updateEntityProfileStatus,
  updateTradeRefChecks,
} from '../actions';
import CreditCardModal from './CreditCardModal';
/* Import CSS */
import styles from './css/CommonEditProfile.css';
import checks_styles from './css/MandatoryChecks.css';
import { FEATURE_FLAGS } from 'conf';
import { ImpactAutoDecisionRulesetPopup } from 'modules/shared/components/widgets/static/ImpactAutoDecisionRulesetPopup/ImpactAutoDecisionRulesetPopup';

const isUpdatedTrcsFlagEnabled = FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TRCS;

const TradeReferenceCheck = createClass({
  cancelCardInfo() {
    this.setState({ showCreditInfoModal: false });
  },

  cancelTradeRefModal(addCardInfo) {
    const { dispatch, card, minimum_trade_references, autosave } = this.props;
    this.setState({ showTradeRefModal: false });
    if (!card) {
      dispatch(setTradeReferenceCheck(false));
      if (autosave) {
        const attributes = {
          minimum_trade_references,
          requires_trade_reference_check: false,
        };
        dispatch(updateTradeRefChecks(attributes));
      }
    }
    if (addCardInfo) {
      this.setState({ showCreditInfoModal: true });
    }
  },

  componentDidMount() {
    const { dispatch, current_entity } = this.props;
    if (current_entity && this.isSetupCardAccountRules()) {
      let attributes = {};
      attributes['admin_updated_at'] = moment();
      dispatch(updateEntityProfileStatus(attributes));
    }
  },

  dismissTradeRefModal() {
    this.setState({ showTradeRefModal: false });
  },

  getInitialState() {
    return {
      check: false,
      isTooltipActive: false,
      showCreditModal: false,
      showIdentityModal: false,
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
        tabStatusToBePaused: null,
      },
    };
  },

  hideTooltip() {
    this.setState({ isTooltipActive: false });
  },

  isSetupCardAccountRules() {
    const { current_entity } = this.props;
    return !!(
      current_entity.attributes.supplier &&
      current_entity.attributes.supplier_onboarding_checklist &&
      !current_entity.attributes.supplier_onboarding_checklist.admin
    );
  },

  render() {
    const {
      title,
      options,
      minimum_trade_references,
      update_loading,
      tradeRefModalText,
      requires_trade_reference_check,
      card,
      role_types,
      autosave,
      tip_text,
      invite,
    } = this.props;
    const {
      showTradeRefModal,
      showCreditInfoModal,
      isTooltipActive,
      autoDecisionImpact: { isAutoDecisionImpactModalVisible },
    } = this.state;
    const button_components = [];
    let modalMarkup;

    const tradeRefCheckList = [];
    Object.keys(options.checkOptions).forEach((key, index) => {
      tradeRefCheckList.push(
        <li key={index}>{options.checkOptions[key].text}</li>
      );
    });
    if (showTradeRefModal) {
      let button;
      let credit_card_message;
      if (!card && isRegular()) {
        button =
          role_types && role_types.includes('admin') ? (
            <Button
              text="Add Card"
              handleClick={() => this.cancelTradeRefModal(true)}
            />
          ) : (
            <Button
              text="Cancel"
              handleClick={() => this.cancelTradeRefModal(false)}
            />
          );
        credit_card_message =
          role_types && role_types.includes('admin')
            ? 'Please ask your administrator to add credit card.'
            : '';
      } else {
        button = <Button text="OK" handleClick={this.dismissTradeRefModal} />;
      }
      modalMarkup = (
        <Modal
          title={
            isUpdatedTrcsFlagEnabled
              ? 'Trade Reference Checks (TRCs)'
              : 'Trade Reference Checks'
          }
          bodyText={!isUpdatedTrcsFlagEnabled ? tradeRefModalText : null}
          css_close_button={
            autosave
              ? 'mandatory_button_close'
              : 'invite_mandatory_button_close'
          }
          dismissHandler={() => this.cancelTradeRefModal(false)}
        >
          {isUpdatedTrcsFlagEnabled && (
            <span className={checks_styles.mail_to}>{tradeRefModalText}</span>
          )}
          <div
            className={
              isUpdatedTrcsFlagEnabled
                ? checks_styles.list_new
                : checks_styles.list
            }
          >
            <ul>{tradeRefCheckList}</ul>
            <p className={checks_styles.credit_card_message}>
              {credit_card_message}
            </p>
          </div>
          {button}
        </Modal>
      );
    }

    if (showCreditInfoModal) {
      modalMarkup = (
        <CreditCardModal
          onSave={this.saveCard}
          onCancel={this.cancelCardInfo}
        ></CreditCardModal>
      );
    }

    //Create Trade Reference button list

    for (let i = options.count.minimum; i <= options.count.maximum; i++) {
      let selected = false;
      if (i == minimum_trade_references) {
        selected = true;
      }
      button_components.push(
        <CircleIconButton
          key={i}
          value={i}
          type="number"
          selected={selected}
          text={i}
          small={true}
          disabled={!isFeatureEditEnabled('Credit')}
          handleClick={this.handleChangeTradeReferenceCount}
        />
      );
    }

    let dropdown = null;
    if (minimum_trade_references > 0) {
      dropdown = (
        <RoundedDropdown
          list={options.list}
          value={requires_trade_reference_check}
          defaultValue={false}
          handleChange={this.setTradeReferCheck}
          label={'Please complete my checks'}
          disabled={!isFeatureEditEnabled('Credit')}
          wide
        />
      );
    }

    let tool_tip;
    if (isTooltipActive && invite) {
      tool_tip = (
        <ToolTip
          tip_name="TradeReferenceCheck"
          css_style="trade_reference_check"
          dismissHandler={this.hideTooltip}
        />
      );
    }

    let loader = null;
    let update_button_text = 'update changes';
    let update_click = this.submit;
    let update_button = null;
    if (update_loading) {
      loader = <span className={styles.loading}></span>;
      update_button_text = 'saving';
      update_click = (event) => {};
    }

    update_button = (
      <div className={styles.button}>
        <Button handleClick={update_click} text={update_button_text} />
        {loader}
      </div>
    );

    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            <h2 className={styles.header}>
              {autosave ? title : 'Trade Reference check'}
            </h2>
            <ExtraDescription
              hintName={'headquarterSettingHint'}
              show={isHeadquarter()}
            />
            {update_loading && update_button}
            <div className={checks_styles.row}>
              <div className={checks_styles.tradeRef_options}>
                <div className={checks_styles.tradeRef_components}>
                  {button_components}
                </div>
                <div className={checks_styles.tradeRef_dropdown}>
                  {dropdown}
                  {tool_tip}
                </div>
              </div>
            </div>
            {modalMarkup}
          </div>
        </div>
        {isAutoDecisionImpactModalVisible && FEATURE_FLAGS.REVAMPED_1CAD && (
          <ImpactAutoDecisionRulesetPopup
            onCancel={this.handleCancelAutoDecisionImpact}
            onConfirm={this.handleConfirmAutoDecisionImpact}
            rulesetNames={this.getRulesetNames()}
          />
        )}
      </div>
    );
  },

  saveCard(response) {
    const { dispatch, minimum_trade_references, autosave } = this.props;
    this.setState({ showCreditInfoModal: false });
    if (response) {
      dispatch(loadCard());
      dispatch(setTradeReferenceCheck(true));

      const attributes = {
        minimum_trade_references,
        requires_trade_reference_check: true,
      };
      if (autosave) {
        dispatch(updateTradeRefChecks(attributes));
      }
    }
  },

  submit() {
    const {
      dispatch,
      requires_trade_reference_check,
      minimum_trade_references,
      autosave,
    } = this.props;
    if (autosave) {
      let attributes = {
        requires_trade_reference_check: requires_trade_reference_check || false,
        minimum_trade_references: minimum_trade_references,
      };
      dispatch(updateTradeRefChecks(attributes));
    }
  },

  handleCancelAutoDecisionImpact() {
    this.setState({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
      },
    });
  },

  handleConfirmAutoDecisionImpact() {
    const onConfirm = this.state.autoDecisionImpact.onConfirm;
    onConfirm && onConfirm();

    const { rulesetsThatWillBeImpacted, tabStatusToBePaused } =
      this.state.autoDecisionImpact;
    rulesetsThatWillBeImpacted.forEach((ruleset) => {
      // Let's pause the auto decision rules
      const { attributes, id } = ruleset;
      const updatedAttributes = {
        ...attributes,
        [tabStatusToBePaused]: 'paused',
      };
      this.props.dispatch(updateRuleSet(id, updatedAttributes));
    });

    this.setState(() => ({
      autoDecisionImpact: {
        isAutoDecisionImpactModalVisible: false,
        onConfirm: null,
        rulesetsThatWillBeImpacted: [],
      },
    }));
  },

  getRulesetNames() {
    const {
      autoDecisionImpact: { rulesetsThatWillBeImpacted },
    } = this.state;
    return rulesetsThatWillBeImpacted
      .map((ruleset) => ruleset.attributes.name)
      .join(', ');
  },

  handleChangeTradeReferenceCount(newCount) {
    if (FEATURE_FLAGS.REVAMPED_1CAD) {
      // An auto decision ruleset will be affected if that one requries
      // trade reference checks, that is, the corresponding flag should be true,
      // and the count should be greater than zero,
      // and the new count should be less than it
      const rulesetsThatWillBeImpacted = this.props.autoDecisionRulesets.filter(
        (ruleset) =>
          ruleset.attributes.trade_reference === true &&
          ruleset.attributes.trade_reference_count > 0 &&
          newCount < ruleset.attributes.trade_reference_count
      );

      if (!rulesetsThatWillBeImpacted.length) {
        // We didn't find any ruleset that will be impacted
        // We'll just proced to update the settings
        this.setMinimumTradeReferences(newCount);
        return;
      }

      this.setState({
        autoDecisionImpact: {
          rulesetsThatWillBeImpacted,
          isAutoDecisionImpactModalVisible: true,
          onConfirm: () => this.setMinimumTradeReferences(newCount),
          tabStatusToBePaused: 'additions_status',
        },
      });
      return;
    }
    this.setMinimumTradeReferences(newCount);
  },

  setMinimumTradeReferences(value) {
    const { dispatch, requires_trade_reference_check, autosave } = this.props;
    let _requiresTradeReferenceCheck = requires_trade_reference_check || false;

    if (value == 0) {
      _requiresTradeReferenceCheck = false;
      dispatch(setTradeReferenceCheck(false));
    }
    dispatch(setMinimumTradeReferences(value));

    if (autosave) {
      const attributes = {
        minimum_trade_references: value,
        requires_trade_reference_check: _requiresTradeReferenceCheck,
      };
      dispatch(updateTradeRefChecks(attributes));
    }
  },

  setTradeReferCheck(event) {
    const {
      dispatch,
      requires_trade_reference_check,
      minimum_trade_references,
      autosave,
    } = this.props;
    if (event.value) {
      this.setState({ isTooltipActive: true, showTradeRefModal: true });
    }
    dispatch(setTradeReferenceCheck(event.value));

    if (autosave) {
      const attributes = {
        minimum_trade_references,
        requires_trade_reference_check: event.value,
      };
      dispatch(updateTradeRefChecks(attributes));
    }
  },
});

const defaults = {
  options: {
    checkOptions: [
      {
        text: 'Confirm referee name',
      },
      {
        text: 'Relationship to applicant',
      },
      {
        text: 'How long have you had a relationship with applicant?',
      },
      {
        text: 'What credit limit did you extend to applicant?',
      },
      {
        text: 'What date was the last purchase made by the applicant?',
      },
      {
        text: 'How much is currently owed to you by this applicant?',
      },
      {
        text: 'Of the amount owed, how much is overdue?',
      },
      {
        text: 'Age of overdue amount?',
      },
      {
        text: 'What are the applicant payment habits?',
      },
    ],
    count: {
      default_value: 0,
      maximum: 4,
      minimum: 0,
    },
    creditCheckOptions: {
      Centrix: {
        text: 'Coming soon.',
        title: 'Centrix',
      },
      CreditWorks: {
        text: 'Coming soon.',
        title: 'CreditWorks',
      },
      DunBradstreet: {
        text: 'Coming soon.',
        title: 'Dun & Bradstreet',
      },
      Equifax: {
        text: 'Check for registered companies @$25 or a Vedascore plus report for sole traders and individuals @$10 each. Costs incl GST.',
        title: 'Equifax',
      },
    },
    list: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
  title: 'Trade Reference check (required)',
  tradeRefModalText: isUpdatedTrcsFlagEnabled ? (
    <Fragment>
      You are able to outsource this function to our experienced team here at
      1Centre. You will see in the Credit File for your customer, the responses
      to the below questions that we will source for you from the referee
      provided. There are three possible outcomes of these checks -
      PASS/FAIL/DEFER, you will be provided notes along with these outcomes in
      this section of your customers credit file. If you have any questions
      please contact <a href="mailto:success@1centre.com">support@centre.com</a>
      . The cost of this service is $13.50 per check, excluding GST.
    </Fragment>
  ) : (
    'Trade reference checks are completed by Total Risk Management Limited, a specialist provider of business and risk solutions. The price for this service via 1Centre is $13.50 per check, excluding GST. All information is provided back to you.'
  ),
};

export default connect((state, ownProps) => {
  const s = state.manage_profile;
  const ent = state.manage_profile.current_entity.attributes;

  const requires_trade_reference_check =
    s.settings_requires_trade_reference_check != null
      ? s.settings_requires_trade_reference_check
      : ent.requires_trade_reference_check;
  const minimum_trade_references =
    s.settings_minimum_trade_references != null
      ? s.settings_minimum_trade_references
      : ent.minimum_trade_references || defaults.options.count.default_value;

  return {
    card: s.current_entity_card,
    autosave: ownProps.autosave || false,
    entity: state.current_user.current_entity,
    current_entity: state.current_user.current_entity,
    minimum_trade_references,
    options: defaults.options,

    requires_trade_reference_check,
    role_types:
      state.current_user.current_user_entity_link.attributes.role_types,
    title: defaults.title,
    tradeRefModalText: defaults.tradeRefModalText,
    update_loading: s.settings_trade_reference_checks_updating,
    autoDecisionRulesets: state.add_ons.auto_decisions,
  };
})(TradeReferenceCheck);
