import React from 'react';

/* Import CSS */
import styles from './css/Review.css';

/* Import components */
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CountryCodeDropdown from 'modules/shared/components/widgets/static/CountryCodeDropdown';
import TextInput from 'modules/shared/components/inputs/TextInput';
import EmailInput from 'modules/shared/components/inputs/EmailInput';

var ReviewGuarantors = createClass({
  _changeHandler(e) {
    const { index, onChangeHandler } = this.props;
    onChangeHandler(index, e);
  },

  _blurHandler(e) {
    const { index, onBlurHandler } = this.props;
    onBlurHandler(index, e);
  },

  _countryCodeChangeHandler(e) {
    const { index, onCountryCodeChangeHandler } = this.props;
    onCountryCodeChangeHandler(index, e.value);
  },

  _emailChangeHandler(value, isValid) {
    const { index, onEmailChangeHandler } = this.props;
    onEmailChangeHandler(index, value, isValid);
  },

  render() {
    const {
      index,
      first_name,
      last_name,
      contact_phone,
      business_name,
      email,
      form_error,
      contact_country_code,
    } = this.props;

    let digits = ['', '1', '2', '3', '4'];

    return (
      <ReviewContainer
        key={`referee-${index}`}
        css_class={`block_noborder`}
        subHeading={'Reference ' + digits[index + 1]}
      >
        <div className={styles.col}>
          <div className={styles.half_input}>
            <TextInput
              key="first_name"
              id="first_name"
              name="first_name"
              error={form_error.first_name}
              handleChange={this._changeHandler}
              handleBlur={this._blurHandler}
              label="First name"
              required={true}
              value={first_name}
            />
          </div>
          <div className={styles.half_input + ' ' + styles.right_input}>
            <TextInput
              key="last_name"
              id="last_name"
              name="last_name"
              error={form_error.last_name}
              handleChange={this._changeHandler}
              handleBlur={this._blurHandler}
              label="Last name"
              required={false}
              value={last_name}
            />
          </div>
          <div className={styles.half_input}>
            <CountryCodeDropdown
              key="contact_country_code"
              countryCodeId={`contact_country_code${index}`}
              label={'Country'}
              countryCodeError={form_error.contact_country_code}
              countryCodeValue={contact_country_code}
              required={true}
              handleChange={this._countryCodeChangeHandler}
            />
          </div>
          <div className={styles.half_input + ' ' + styles.right_input}>
            <TextInput
              key="contact_phone"
              id="contact_phone"
              name="contact_phone"
              type="tel"
              error={form_error.contact_phone}
              handleChange={this._changeHandler}
              handleBlur={this._blurHandler}
              label="Phone number"
              required={true}
              value={contact_phone}
            />
          </div>
        </div>
        <div className={styles.col}>
          <TextInput
            key="business_name"
            id="business_name"
            name="business_name"
            error={form_error.business_name}
            handleChange={this._changeHandler}
            handleBlur={this._blurHandler}
            label="Business name"
            required={true}
            value={business_name}
          />

          <EmailInput
            key="email"
            id="email"
            ref="email"
            name="email"
            handleChange={this._emailChangeHandler}
            handleBlur={this._emailChangeHandler}
            label="Email"
            required={false}
            value={email}
          />
        </div>
      </ReviewContainer>
    );
  },
});

module.exports = ReviewGuarantors;
