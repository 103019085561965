import { PPSR_MODULE_NAME } from 'models/AddonConfigModel';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import { DigitalOnboardingItem } from 'modules/addons/components/DigitalOnboarding';
import React, { useContext } from 'react';

const BODY = {
  ppsr_australia_module: `*Australia companies only. You can now facilitate your Personal
  Property Securities Registrations (PPSR) through 1Centre. Click here to set
  up and get going.`,
  ppsr_module: `*New Zealand companies only. You can now facilitate your
  Personal Property Securities Registrations (PPSR) through
  1Centre. Click here to set up and get going.`,
};

const MIXPANEL_EVENT = {
  ppsr_australia_module: 'Update PPSR Australia registration module ruleset',
  ppsr_module: 'Update PPSR registration module ruleset',
};

export default function Item(props) {
  const { addonConfig, currentUser, onUpdateModuleItem } = props;
  const { addonModuleName } = addonConfig;

  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfig);

  const onSuccessCallback = (updatedAddonConfig) => {
    mixpanel.track(MIXPANEL_EVENT[addonModuleName], {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Ruleset: updatedAddonConfig.data,
      'Ruleset ID': updatedAddonConfig.id,
      distinct_id: currentUser.id,
    });

    onUpdateModuleItem(updatedAddonConfig);
  };

  const onClickToggleState = () => {
    addonConfig.setAttribute('active', !addonConfig.active);
    addonConfig.update({ currentUser, onSuccessCallback });
  };

  return (
    <DigitalOnboardingItem
      activeState={addonConfig.active}
      areActionButtonsVisible={addonConfig.isPersisted}
      body={BODY[addonModuleName]}
      formattedState={addonConfig.formattedActiveState}
      header={PPSR_MODULE_NAME[addonModuleName]}
      isConfigured={addonConfig.isConfigured}
      isEnabled
      onClickEdit={onClickEdit}
      onClickToggleState={onClickToggleState}
    />
  );
}
