import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user
 */
let ApplicationReports = function(config) {
  this.axios = axios(config);
  this.type = 'application_summary_report';
};

ApplicationReports.prototype.getReport = function(
  id,
  entity_type,
  success,
  error
) {
  return this.axios
    .get(`/users/${id}/${this.type}/${entity_type}`)
    .then(success)
    .catch(error);
};

export default ApplicationReports;
