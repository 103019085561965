import React, { Component } from 'react';
import { displayEmpty, formatMoney } from 'utils/formatting';

import { CCR_PURPOSE } from '../constants';
import commonStyles from '../css/consumer-risk-score/ConsumerRiskScoreComponent.css';
import { extractText } from '../ConsumerRiskScoreComponent';
import get from 'lodash.get';
import isPresent from 'utils/isPresent';
import objectToArray from 'utils/objectToArray';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../css/consumer-risk-score/Defaults.css';

export default class Defaults extends Component {
  get defaults() {
    return objectToArray(get(this.props.creditReport, 'defaults.default'));
  }

  renderDefaults() {
    const defaults = this.defaults.map((def, i) => this.renderDefault(def, i));

    if (isPresent(defaults)) {
      return defaults;
    }

    return (
      <tr>
        <td colSpan={6} className={commonStyles.centered}>
          No defaults recorded
        </td>
      </tr>
    );
  }

  renderDefault(def, i) {
    return (
      <tr key={`default-${i}`}>
        <td>{stringToSimpleDate(extractText(def, 'date_of_default'))}</td>
        <td>{this.renderAccountDetails(def, i)}</td>
        <td>{this.renderAmount(def, i)}</td>
        <td>{this.renderReasonToReport(def, i)}</td>
        <td>{get(def, 'default_status.value_desc', '-')}</td>
        <td>{displayEmpty(CCR_PURPOSE[extractText(def, 'purpose')], '-')}</td>
      </tr>
    );
  }

  renderAccountDetails(def, i) {
    return (
      <div
        key={`default-account-details-${i}`}
        className={styles.default_details_container}
      >
        <div className={commonStyles.emphasize}>Current credit provider:</div>
        <div>{extractText(def, 'current_credit_provider', '-')}</div>

        <div className={commonStyles.emphasize}>Original credit provider:</div>
        <div></div>

        <div className={commonStyles.emphasize}>Credit type:</div>
        <div>{extractText(def, 'original_credit_provider', '-')}</div>

        <div className={commonStyles.emphasize}>Obligation:</div>
        <div>{get(def, 'credit_obligation.value_desc', '-')}</div>

        <div className={commonStyles.emphasize}>Orig. cust. ref:</div>
        <div>{extractText(def, 'original_customer_reference', '-')}</div>

        <div className={commonStyles.emphasize}>Current cust. ref:</div>
        <div>{extractText(def, 'current_customer_reference', '-')}</div>
      </div>
    );
  }

  renderAmount(def, i) {
    return (
      <div
        key={`default-amount-${i}`}
        className={styles.default_details_container}
      >
        <div className={commonStyles.emphasize}>Current:</div>
        <div>
          ${formatMoney(parseFloat(extractText(def, 'current_amount', 0), 0))}
        </div>

        <div className={commonStyles.emphasize}>Original:</div>
        <div>
          ${formatMoney(parseFloat(extractText(def, 'original_amount', 0), 0))}
        </div>
      </div>
    );
  }

  renderReasonToReport(def, i) {
    return (
      <div
        key={`reason-to-report-${i}`}
        className={styles.default_details_container}
      >
        <div className={commonStyles.emphasize}>Current:</div>
        <div>{get(def, 'current_reason_to_report.value_desc', '-')}</div>

        <div className={commonStyles.emphasize}>Original:</div>
        <div>{get(def, 'original_reason_to_report.value_desc', '-')}</div>
      </div>
    );
  }

  render() {
    return (
      <table className={`${commonStyles.table} ${commonStyles.full_width}`}>
        <thead>
          <tr>
            <th colSpan={6}>Defaults</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Account details</th>
            <th>Amount</th>
            <th>Reason to report</th>
            <th>Status</th>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody>{this.renderDefaults()}</tbody>
      </table>
    );
  }
}
