import isEqual from 'lodash.isequal';

export function dashboardRedirect(path, current_user) {
  const trm = current_user.data.data.attributes.trm;

  if (path.match(/^\/dashboard\/?(applications)?(\/)?$/)) {
    if (trm) {
      return '/trm/list';
    }
    return '/dashboard/home';
  }
  if (path.match(/^\/dashboard\/connections(\/)?$/)) {
    return '/dashboard/connections/all';
  }
  if (path.match(/^\/dashboard\/settings(\/)?$/)) {
    return '/dashboard/settings/business';
  }
  if (path.match(/^\/dashboard/)) {
    const roleTypes =
      current_user.current_user_entity_link.attributes.role_types;
    if (isEqual(roleTypes, ['consumer_manager'])) {
      return '/dashboard/reporting?acting_as_supplier=false&limit_type=requested_limits&page=1&per=25&status=started';
    }
  }
}
