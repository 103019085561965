import get from 'lodash.get';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import { isSanitizedStringEqual } from 'utils/sanitizeName';

export function retrieveDirectorAddress({ application, person }) {
  if (isPresent(person.formattedResidentialAddress)) {
    return person.formattedResidentialAddress;
  }

  const guarantor = (application.guarantors || []).find(
    (g) =>
      isSanitizedStringEqual(g.firstName, person.firstName) &&
      isSanitizedStringEqual(g.lastName, person.lastName)
  );
  if (isBlank(guarantor)) {
    return null;
  }

  const authorisation = (application.authorisations || []).find(
    (a) => a.id === guarantor.authorisationId
  );
  if (isBlank(authorisation)) {
    return null;
  }

  return get(authorisation, 'signature.fullResidentialAddress');
}

export function isPeopleComponentVisible({
  applicationType,
  hasGuarantors,
  legalType,
}) {
  if (legalType === 'company' && !hasGuarantors) {
    return false;
  }

  const visible = ['company', 'personal', 'sole_trader'];

  if (applicationType === 'credit') {
    visible.push('partnership', 'trust');
  }

  return visible.includes(legalType);
}
