import { setLoginFormField, userLogin } from '../actions';

/* Actions */
/* Components */
import { Base64 } from 'js-base64';
/* Import components */
import Loader from 'modules/shared/components/widgets/static/Loader';
import {
  LoginFormSSO,
  SignInFailed,
} from 'modules/new-applications/components/NewLogin/index.js';
import React, { useEffect } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import SectionCircle from 'modules/shared/components/widgets/static/SectionCircle';
import WorkflowOverlay from 'modules/shared/components/top/WorkflowOverlay';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
// import LoginForm from "../forms/LoginForm";
/* Import CSS */
import styles from './css/Login.css';
import get from 'lodash.get';
import UserModel from 'models/UserModel';

/* Login */
function Login(props) {
  const {
    dispatch,
    email,
    password,
    loading,
    isTrm,
    location: {
      query: {
        ent,
        entity,
        email: locationEmail,
        password: locationPassword,
        redirect,
        sso_token,
        error_msg,
      },
    },
    loggedIn,
  } = props;

  function _emailChangeHandler(value) {
    dispatch(setLoginFormField('email_address', (value || '').trim()));
  }

  function _passwordChangeHandler(event) {
    dispatch(setLoginFormField('password', event.target.value));
  }

  function loginv2(email, password) {
    if (!password) {
      return false;
    }

    let redirectPath = '/dashboard';
    if (redirect) {
      redirectPath = redirect;
    }

    dispatch(userLogin(email, password, redirectPath, null, entity));
  }

  // The <Loader> complete animation duration is 1.75s. We need to add the timer delay.
  let content = (
    <Loader complete={false} title="Just a second while we log you in!" />
  );

  const title = 'Log in';

  let redirectPath = '/dashboard';

  if (redirect) {
    redirectPath = redirect;
  }

  if (isTrm) {
    redirectPath = '/trm/list';
  }

  useEffect(() => {
    if (loggedIn) {
      // Pass in the entity params if available to get context to which entity
      // to connect to.
      browserHistory.push({
        pathname: redirectPath,
        query: { entity },
      });
    }
  }, [loggedIn]);

  if (!loading) {
    // http://localhost:8888/user/login?email=lance.wickman@officetorque.com&ent=demo&password=Password
    if (ent === 'demo' && window._env_.NODE_ENV !== 'production') {
      dispatch(
        userLogin(locationEmail, locationPassword, redirectPath, null, entity)
      );
    } else if (ent === 'sso') {
      let str = Base64.decode(sso_token);

      dispatch(
        userLogin(
          str.split('|')[0],
          str.split('|')[1],
          redirectPath,
          null,
          entity,
          'sso'
        )
      );
    } else if (error_msg === 'no_exist') {
      content = <SignInFailed></SignInFailed>;
    } else {
      content = (
        <LoginFormSSO
          submitHandler={loginv2}
          changeHandler={_passwordChangeHandler}
          emailChangeHandler={_emailChangeHandler}
          blurHandler={_passwordChangeHandler}
          password={password}
          email_address={email}
          button_label="Log in"
          redirectPath={redirectPath}
        />
      );
    }
  }

  return <div>{content}</div>;
}

export default connect((state, ownProps) => {
  const type = 'user';
  const currentUser = get(state, 'current_user', {});
  const modelledCurrentUser = new UserModel.fromCurrentUser(currentUser);
  let email;
  if (ownProps.location.query.e && !state.login.email) {
    email = ownProps.location.query.e.replace(' ', '+');
  } else {
    email = state.login.email;
  }

  return {
    type,
    loading: state.current_user.loading,
    email,
    password: state.login.password,
    loggedIn: currentUser.loggedIn,
    isTrm: modelledCurrentUser.trm,
  };
})(Login);
