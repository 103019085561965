import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import RecordHistoryModel from 'models/RecordHistoryModel';
import { formatLocalTime } from 'utils/dateFormatter';
import { formatMoney } from 'utils/formatting';
import isPresent from 'utils/isPresent';

const REVIEWER_LEVEL = 0;
const isReviewerLevel = (level) => level === REVIEWER_LEVEL;

export { REVIEWER_LEVEL, isReviewerLevel };

export default class ReviewModel extends BaseModel {
  static createReview({
    accessToken,
    application,
    attributes,
    entityId,
    onErrorCallback,
    onSuccessCallback,
  }) {
    const reviewsAPI = api('reviews', accessToken, entityId);
    const onError = (error) => {
      console.error(error);

      if (onErrorCallback) {
        onErrorCallback(error);
      }
    };

    reviewsAPI.createReview(
      attributes,
      application.id,
      onSuccessCallback,
      onError
    );
  }

  static updateReview({
    accessToken,
    attributes,
    entityId,
    onErrorCallback,
    onSuccessCallback,
    review,
  }) {
    const reviewsAPI = api('reviews', accessToken, entityId);
    const onError = (error) => {
      console.error(error);

      if (onErrorCallback) {
        onErrorCallback(error);
      }
    };

    reviewsAPI.updateReview(review.id, attributes, onSuccessCallback, onError);
  }

  static deescalate({
    accessToken,
    entityId,
    notes,
    onSuccessCallback,
    review,
  }) {
    const reviewsAPI = api('reviews', accessToken, entityId);
    const onErrorCallback = (error) => console.error(error);

    reviewsAPI.deescalate({
      id: review.id,
      notes,
      onErrorCallback,
      onSuccessCallback,
    });
  }

  get isActioned() {
    return isPresent(this.decision);
  }

  get formattedTradeAccountLimit() {
    return `$${formatMoney(parseFloat(this.tradeAccountLimit) || 0)}`;
  }

  get formattedDiscount() {
    return `${this.trade_account_discount_percentage || 0}%`;
  }

  get formattedDecision() {
    if (
      FEATURE_FLAGS.FEATURE_FLAG_IMPROVED_NOTES &&
      this.decision === 'accepted'
    ) {
      return 'Approved';
    }

    return capitalize(this.decision || '');
  }

  get formattedReviewedAt() {
    return formatLocalTime(this.reviewedAt, 'minute');
  }

  get isReviewerLevel() {
    return isReviewerLevel(this.level);
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  save({
    application,
    attributes,
    currentUser,
    onErrorCallback,
    onSuccessCallback,
  }) {
    this.setAttributes(attributes);

    const successCallback = (result) => {
      const newReview = new ReviewModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
      onSuccessCallback(newReview);
    };

    const accessToken = currentUser.accessToken;
    const entityId = get(currentUser, 'currentEntity.id');

    if (isPresent(this.id)) {
      ReviewModel.updateReview({
        accessToken,
        attributes: this.attributes,
        entityId,
        onErrorCallback,
        onSuccessCallback: successCallback,
        review: this,
      });
    } else {
      ReviewModel.createReview({
        accessToken,
        application,
        attributes: this.attributes,
        entityId,
        onErrorCallback,
        onSuccessCallback: successCallback,
      });
    }
  }

  deescalate({ currentUser, onSuccessCallback, notes }) {
    const accessToken = currentUser.accessToken;
    const entityId = get(currentUser, 'currentEntity.id');

    ReviewModel.deescalate({
      accessToken,
      entityId,
      notes,
      onSuccessCallback,
      review: this,
    });
  }

  /** Private functions */

  assignRelationships() {
    this.assignManyRelationship({
      key: 'review_histories',
      model: RecordHistoryModel,
    });
  }
}
