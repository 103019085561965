import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import { isCentraPassAllVerified } from 'models/IdentificationCheckModel';
import AntiFraudCategoryRule from 'utils/AntiFraudCategoryRule';

const getIsGovernmentVerificationSatisfied = ({
  antiFraudCategoryRules,
  identificationCheck,
}) => {
  const { formattedDetails } = identificationCheck;

  return (
    antiFraudCategoryRules.isGovernmentVerificationRequired &&
    identificationCheck.status === 'completed' &&
    isCentraPassAllVerified({
      dobVerified: formattedDetails.dob_verified,
      idVerified: formattedDetails.identification_verification_string,
      nameVerified: formattedDetails.name_verified,
    })
  );
};

const getIsFaceMatchVerificationSatisfied = ({
  antiFraudCategoryRules,
  antiFraudCheck,
}) => {
  if (!antiFraudCategoryRules.isFrontFaceImageRequired) {
    return true;
  }

  return antiFraudCheck.pass;
};

const getIsProofOfLifeVerificationSatisfied = ({
  antiFraudCategoryRules,
  application,
  signature,
}) => {
  if (
    !antiFraudCategoryRules.isProofOfLifeRequired ||
    !FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6
  ) {
    return true;
  }

  const { antiFraudSimilarityScore, antiFraudConfidenceScore, livenessImages } =
    signature.proofOfLifeDetails || {};
  const { auto_pass_min_confidence_score, auto_pass_min_score } =
    application.proofOfLifeThreshold || {};

  return (
    parseFloat(antiFraudSimilarityScore) >= auto_pass_min_score &&
    parseFloat(antiFraudConfidenceScore) >= auto_pass_min_confidence_score
  );
};

export default function getIsAntiFraudVerified({
  application,
  authorisation,
  identificationCheck,
  signature,
}): boolean {
  const antiFraudConfig = get(application, 'antiFraudAddonRule.config', {});
  const applicableCategory =
    authorisation.getApplicableAntiFraudCategory(antiFraudConfig);
  const antiFraudCategoryRules = new AntiFraudCategoryRule(applicableCategory);

  const isGovernmentVerificationSatisfied =
    getIsGovernmentVerificationSatisfied({
      antiFraudCategoryRules,
      identificationCheck,
    });

  const isFaceMatchVerificationSatisfied = getIsFaceMatchVerificationSatisfied({
    antiFraudCategoryRules,
    antiFraudCheck: authorisation.antiFraudCheck || {},
  });

  const isProofOfLifeSatisfied = getIsProofOfLifeVerificationSatisfied({
    antiFraudCategoryRules,
    application,
    signature,
  });

  return (
    isGovernmentVerificationSatisfied &&
    isFaceMatchVerificationSatisfied &&
    isProofOfLifeSatisfied
  );
}
