import { Skeleton } from '@material-ui/lab';
import { FEATURE_FLAGS } from 'conf';
import * as Sentry from '@sentry/browser';
import api from 'api';
import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import EntityModel from 'models/EntityModel';
import UserModel from 'models/UserModel';
import SelectField from 'modules/consumer-onboarding/v2/ApplicationDetails/SelectField';
import TradingNameField from 'modules/consumer-onboarding/v2/ApplicationDetails/TradingNameField';
import {
  getConsumerUserEntityLinks,
  useConsumerIdOptions,
} from 'modules/consumer-onboarding/v2/ApplicationDetails/utils/consumerUserEntityLinks';
import {
  getTradingNames,
  useTradingNamesState,
} from 'modules/consumer-onboarding/v2/ApplicationDetails/utils/tradingNames';
import ButtonGroupWrapper from 'modules/consumer-onboarding/v2/ButtonGroupWrapper';
import ColumnWrapper from 'modules/consumer-onboarding/v2/ColumnWrapper';
import { OnboardingChecklistContext } from 'modules/consumer-onboarding/v2/Container';
import {
  FormWrapper,
  SectionHeader,
} from 'modules/consumer-onboarding/v2/styles';
import {
  fetchApplicationDetails,
  hasDetailsRequired,
} from 'modules/consumer-onboarding/v2/utils/applicationDetailsHelper';
import {
  getApplicationTypes,
  setApplicationTypeFromSalesChannel,
  useApplicationTypeOptions,
} from 'modules/consumer-onboarding/v2/utils/applicationTypes';
import Button from 'modules/shared/components/inputs/Button';
import { onSetActiveQuestionAndAnswer } from 'modules/shared/components/top/Overlay/redux/actions';
import { NAME_OF_BUSINESS_OR_PERSON_APPLYING } from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/constants/questionAndAnswers';
import QuestionIcon from 'modules/shared/components/top/Overlay/SectionDrawer/QandAPanel/QAndAHelpLink/QuestionIcon';
import { COUNTRY_OPTIONS } from 'modules/shared/constants/country_states_options';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
// @ts-ignore-next-line: Type cannot be inferred
import { browserHistory } from 'react-router';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';
import AddonConfigModel from 'models/AddonConfigModel';
import { getCustomerView } from './utils/getCustomerView';

type FormValues = {
  applicationType: string;
  country: string;
  consumerId: string;
  tradingNameId: string;
  accountUpdateReason: string;
};

const APPLICATION_TYPE_FIELD_NAME = 'applicationType';
const COUNTRY_FIELD_NAME = 'country';
const CONSUMER_ID_FIELD_NAME = 'consumerId';
const TRADING_NAME_ID_FIELD_NAME = 'tradingNameId';
const ACCOUNT_UPDATE_REASON_FIELD_NAME = 'accountUpdateReason';

const buildValidOptions = (options) => {
  const validOptions = options.map((option) => option.value);

  // Need to pass an empty string as part of the `oneOf` Yup validation as
  // a work around because it is prioritising `oneOf` validation over
  // `required` validation
  validOptions.push('');

  return validOptions;
};

const validationSchema = ({
  validApplicationTypes,
  validConsumerIds,
  validCountries,
  validTradingNameIds,
}) =>
  yup.object().shape({
    applicationType: yup
      .string()
      .required('This field is mandatory')
      .oneOf(validApplicationTypes, 'Invalid option'),
    consumerId: yup
      .string()
      .when('$isConsumerIdRequired', {
        is: true,
        otherwise: (schema) => schema,
        then: (schema) => schema.required('This field is mandatory'),
      })
      .oneOf(validConsumerIds, 'Invalid option'),
    country: yup
      .string()
      .required('This field is mandatory')
      .oneOf(validCountries, 'Invalid option'),
    tradingNameId: yup
      .string()
      .when('$isTradingNameRequired', {
        is: true,
        otherwise: (schema) => schema,
        then: (schema) => schema.required('This field is mandatory'),
      })
      .when('$isTradingNameHasValue', {
        is: true,
        otherwise: (schema) =>
          schema.oneOf(validTradingNameIds, 'Invalid option'),
        then: (schema) => schema,
      }),
  });

const setIndividualFieldValue = ({
  fieldName,
  setValue,
  value,
}: {
  fieldName: string;
  setValue: any;
  value: any;
}) => {
  if (isPresent(value)) {
    setValue(fieldName, value);
  }
};

const getRedirectPath = (applicationId: string, search: string) =>
  `/register/consumer/${applicationId}/business/other-details${search}`;

const setDefaultValuesFromLoadedApplication = ({
  application,
  setValue,
}: {
  application: any;
  setValue: any;
}) => {
  setIndividualFieldValue({
    fieldName: APPLICATION_TYPE_FIELD_NAME,
    setValue,
    value: application.applicationType,
  });
  setIndividualFieldValue({
    fieldName: CONSUMER_ID_FIELD_NAME,
    setValue,
    value: application.consumerId,
  });
  setIndividualFieldValue({
    fieldName: COUNTRY_FIELD_NAME,
    setValue,
    value: application.region,
  });
  setIndividualFieldValue({
    fieldName: TRADING_NAME_ID_FIELD_NAME,
    setValue,
    value: application.supplierTradingNameId,
  });
  setIndividualFieldValue({
    fieldName: ACCOUNT_UPDATE_REASON_FIELD_NAME,
    setValue,
    value: application.accountUpdateReason,
  });
};

const ApplicationDetails = (props): ReactElement => {
  const {
    location: {
      query: { application_id: applicationId, channel, supplier_id },
      search,
    },
  } = props;

  const {
    activeQuestionAndAnswer,
    currentUser,
    dispatch,
    supplier,
    supplierRegion,
    configs,
    previewAddon,
  } = props;

  const isPreview = Boolean(previewAddon);

  const [tradingNameOptionsArr, setTradingNameOptionsArr] = useState<any>([]);

  //#region hook states
  const [loadedApplication, setLoadedApplication] = useState<ApplicationModel>(
    new ApplicationModel({})
  );
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const [isApplicationTypesLoaded, setIsApplicationTypesLoaded] =
    useState(false);
  const [isTradingNamesLoaded, setIsTradingNamesLoaded] = useState(false);
  const [isConsumerIdsLoaded, setIsConsumerIdsLoaded] = useState(false);
  const [isConsumerIdHasRegion, setIsConsumerIdHasRegion] = useState(false);
  //#endregion

  const [isAccountUpdateApplication, setAccountUpdateApplication] =
    useState(false);

  const [isCustomerViewActive, setCustomerViewActive] = useState(false);

  const isPageReady = isPreview
    ? true
    : isApplicationLoaded &&
      isApplicationTypesLoaded &&
      isTradingNamesLoaded &&
      isConsumerIdsLoaded;

  //#region Dropdown option states
  const {
    applicationTypes,
    applicationTypeOptions,
    setApplicationTypes,
    setCustomApplicationTypeNames,
  } = useApplicationTypeOptions();

  const { consumerIdOptions, setConsumerUserEntityLinks } =
    useConsumerIdOptions(supplier.id);

  // TradingName option can be from any of the following:
  // 1. no trading name
  // 2. Regular account with "store_name"
  // 3. 1Account trading names
  const { tradingNameOptions, tradingNameRawOptions, setTradingNames } =
    useTradingNamesState(supplier.type);

  const [stateOptions, setStateOptions] = useState<Array<any>>([]);
  //#endregion

  //#region Initialize dropdown values
  // Supplier trading names
  useEffect(() => {
    if (isPreview) {
      setTradingNameOptionsArr(previewAddon.tradingNameOptions);
    } else if (isPresent(supplier.id)) {
      getTradingNames({
        channel,
        currentUser,
        errorCallback: () => setIsTradingNamesLoaded(true),
        successCallback: async (retrievedTradingNames) => {
          const { customerViewOptions, isCustomerViewActive } =
            await getCustomerView(retrievedTradingNames, supplier.id);

          if (isCustomerViewActive) {
            setTradingNameOptionsArr(customerViewOptions);
            setTradingNames(customerViewOptions);
            setIsTradingNamesLoaded(true);
            setCustomerViewActive(true);
          } else {
            setTradingNameOptionsArr(
              retrievedTradingNames.filter((t) => t.type !== 'Regular')
            );
            setTradingNames(retrievedTradingNames);
            setIsTradingNamesLoaded(true);
          }
        },
        supplierId: supplier.id,
      });
    }
  }, [isPreview, supplier.id]);

  // Application types
  useEffect(() => {
    if (isPresent(applicationId) && channel === 'sales') {
      setApplicationTypeFromSalesChannel({
        isApplicationLoaded,
        loadedApplication,
        setApplicationTypes,
        setIsApplicationTypesLoaded,
      });
    } else if (isPresent(supplier.id)) {
      getApplicationTypes({
        channel,
        errorCallback: () => setIsApplicationTypesLoaded(true),
        successCallback: ({ applicationTypes, customApplicationTypeNames }) => {
          setApplicationTypes(applicationTypes);
          setCustomApplicationTypeNames(customApplicationTypeNames);
          setIsApplicationTypesLoaded(true);
        },
        supplierId: supplier.id,
      });
    }
  }, [
    supplier.id,
    applicationId,
    // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
    loadedApplication.applicationType,
    channel,
    isApplicationLoaded,
  ]);

  useEffect(() => {
    getConsumerUserEntityLinks({
      accessToken: currentUser.accessToken,
      email: currentUser.email,
      errorCallback: () => setIsConsumerIdsLoaded(true),
      successCallback: (result) => {
        setConsumerUserEntityLinks(get(result, 'user_entity_links', []));
        setIsConsumerIdsLoaded(true);
      },
    });
  }, [currentUser.email]);
  //#endregion

  //#region React-Hook-Form related code
  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      applicationType: '',
      consumerId: '',
      country: '',
      tradingNameId: '',
      accountUpdateReason: '',
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(
      () =>
        validationSchema({
          validApplicationTypes: buildValidOptions(applicationTypeOptions),
          validConsumerIds: buildValidOptions(consumerIdOptions),
          validCountries: buildValidOptions(COUNTRY_OPTIONS),
          validTradingNameIds: buildValidOptions(tradingNameOptions),
        }),
      {},
      {
        context: {
          isConsumerIdRequired: consumerIdOptions.length > 1,
          isTradingNameHasValue: isPresent(
            // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
            loadedApplication.supplierTradingNameId
          ),
          isTradingNameRequired: FEATURE_FLAGS.FEATURE_FLAG_DROPDOWN_TIER
            ? tradingNameOptionsArr.length > 1
            : tradingNameOptions.length > 1,
        },
      }
    ),
  });

  //#region Register fields
  register(APPLICATION_TYPE_FIELD_NAME);
  register(CONSUMER_ID_FIELD_NAME);
  register(COUNTRY_FIELD_NAME);
  register(TRADING_NAME_ID_FIELD_NAME);
  //#endregion

  const setSelectValue = (event) => {
    const fieldName = get(event, 'target.name');
    const value = get(event, 'target.value');

    setValue(fieldName, value);
    clearErrors(fieldName);
  };

  const setConsumerIdValue = (event) => {
    setSelectValue(event);
    const selectedId = get(event, 'target.value');
    const selectedOption =
      consumerIdOptions.find((option) => option.value === selectedId) || {};

    const selectedOptionRegion = selectedOption.region;

    if (selectedOptionRegion) {
      setValue(COUNTRY_FIELD_NAME, selectedOptionRegion);
      setIsConsumerIdHasRegion(true);
    } else {
      setValue(COUNTRY_FIELD_NAME, supplierRegion || '');
      setIsConsumerIdHasRegion(false);
    }
  };
  //#endregion

  //#region Set check application status
  useEffect(() => {
    if (applicationId) {
      fetchApplicationDetails({
        applicationId,
        currentUser,
        errorCallback: () => setIsApplicationLoaded(true),
        successCallback: (result) => {
          const application = new ApplicationModel(
            get(result, 'data.data', {})
          );
          setLoadedApplication(application);
          if (
            hasDetailsRequired({ application, consumerIdOptions }) &&
            !application.isAccountUpdateApplication
          ) {
            browserHistory.push(getRedirectPath(application.id, search));
          } else {
            setAccountUpdateApplication(application.isAccountUpdateApplication);
            setIsApplicationLoaded(true);
            setDefaultValuesFromLoadedApplication({
              application,
              setValue,
            });
          }
        },
      });
    } else {
      setIsApplicationLoaded(true);
    }
  }, [applicationId, currentUser]);
  //#endregion

  //#region Set initial form values
  useEffect(() => {
    if (isPageReady && consumerIdOptions.length === 1) {
      setValue(CONSUMER_ID_FIELD_NAME, consumerIdOptions[0].value);
    }
  }, [consumerIdOptions, isPageReady]);

  useEffect(() => {
    if (isPageReady && applicationTypes.length === 1) {
      setValue(APPLICATION_TYPE_FIELD_NAME, applicationTypes[0]);
    }
  }, [applicationTypes, isPageReady]);

  useEffect(() => {
    if (supplierRegion) {
      setValue(COUNTRY_FIELD_NAME, supplierRegion);
    }
  }, [supplierRegion]);

  const { onboardingChecklist } = useContext(OnboardingChecklistContext);

  const onSubmit = async (data) => {
    if (isAccountUpdateApplication) {
      browserHistory.push(getRedirectPath(applicationId, search));
      return;
    }

    const {
      applicationType: application_type,
      consumerId: consumer_id,
      country: region,
      countryState: state,
      tradingNameId: supplier_trading_name_id,
    } = data;

    const attributes = {
      application_type,
      channel: channel || 'website',
      check_list: onboardingChecklist,
      consumer_id,
      region: region || supplierRegion,
      state: state || '',
      supplier_id: supplier.id,
      supplier_trading_name_id,
    };

    if (applicationId) {
      attributes['application_id'] = applicationId;
    }

    const onboardingAPI = api('onboarding', currentUser.accessToken);

    try {
      const response = await onboardingAPI.applicationDetails({ attributes });

      const applicationAttributes = get(response, 'data.data', {});

      if (applicationAttributes.id) {
        browserHistory.push(getRedirectPath(applicationAttributes.id, search));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitError = (error) => {
    console.error(error);
    Sentry.captureException(error, (scope) => {
      scope.setExtra(
        'file',
        'src/modules/consumer-onboarding/v2/ApplicationDetails/index.tsx'
      );
      scope.setExtra('function', 'onSubmitError');
      scope.setExtra('applicationId', applicationId);
      scope.setExtra('channel', channel);
      scope.setExtra('supplierId', supplier.id);
      scope.setExtra('consumerId', currentUser.id);

      return scope;
    });
  };

  let content = (
    <Skeleton animation="wave" variant="rect" height={100} width="100%" />
  );

  const showConsumerSelect =
    // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
    (consumerIdOptions.length > 1 && isBlank(loadedApplication.consumerId)) ||
    isAccountUpdateApplication;

  const showRegionSelect =
    // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
    isBlank(loadedApplication.region) &&
    !isConsumerIdHasRegion &&
    !supplierRegion &&
    !isAccountUpdateApplication &&
    !isPreview;

  const showSupplier = isPreview
    ? true
    : // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
      !isPresent(loadedApplication.supplierTradingNameId) &&
      isPresent(
        FEATURE_FLAGS.FEATURE_FLAG_DROPDOWN_TIER
          ? tradingNameOptionsArr
          : tradingNameOptions
      ) &&
      !isAccountUpdateApplication;

  const showApplicationSelect = applicationTypeOptions.length > 1;

  const hideForm =
    isPageReady &&
    !showConsumerSelect &&
    !showRegionSelect &&
    !showApplicationSelect &&
    !showSupplier &&
    !isAccountUpdateApplication;

  useEffect(() => {
    if (hideForm) {
      try {
        onSubmit(getValues());
      } catch (error) {
        onSubmitError(error);
      }
    }
  }, [isPageReady]);

  if (isPageReady && !hideForm) {
    content = (
      <FormWrapper onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <SelectField
          errors={errors}
          isDisabled={isAccountUpdateApplication}
          isVisible={showConsumerSelect}
          label={
            <span>
              Name of business/person applying{' '}
              {!isAccountUpdateApplication && (
                <span
                  onClick={() =>
                    dispatch(
                      onSetActiveQuestionAndAnswer(
                        NAME_OF_BUSINESS_OR_PERSON_APPLYING
                      )
                    )
                  }
                >
                  <QuestionIcon
                    isActive={
                      activeQuestionAndAnswer ===
                      NAME_OF_BUSINESS_OR_PERSON_APPLYING
                    }
                  />
                </span>
              )}
            </span>
          }
          name={CONSUMER_ID_FIELD_NAME}
          onChange={setConsumerIdValue}
          options={consumerIdOptions}
          watch={watch}
        />

        <SelectField
          errors={errors}
          isVisible={showRegionSelect}
          label="Country"
          name={COUNTRY_FIELD_NAME}
          onChange={setSelectValue}
          options={COUNTRY_OPTIONS}
          watch={watch}
        />

        {showSupplier && (
          <TradingNameField
            errors={errors}
            loadedSupplierTradingNameId={
              // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
              loadedApplication.supplierTradingNameId
            }
            // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
            loadedSupplierName={loadedApplication.supplierName}
            name={TRADING_NAME_ID_FIELD_NAME}
            onChange={setSelectValue}
            options={
              FEATURE_FLAGS.FEATURE_FLAG_DROPDOWN_TIER
                ? tradingNameOptionsArr
                : tradingNameOptions
            }
            rawOptions={tradingNameRawOptions}
            watch={watch}
            isCustomerViewActive={isCustomerViewActive || isPreview}
          />
        )}

        <SelectField
          errors={errors}
          // @ts-ignore-next-line: ApplicationModel is not a TS file causing this type error
          isDisabled={isPresent(loadedApplication.applicationType)}
          isVisible={showApplicationSelect}
          label="Application type"
          name={APPLICATION_TYPE_FIELD_NAME}
          onChange={setSelectValue}
          options={applicationTypeOptions}
          watch={watch}
        />

        <SelectField
          isDisabled
          isVisible={isAccountUpdateApplication}
          label="Account update reason"
          name={ACCOUNT_UPDATE_REASON_FIELD_NAME}
          watch={watch}
          options={[
            {
              label: getValues().accountUpdateReason,
              value: getValues().accountUpdateReason,
            },
          ]}
        />

        {!isPreview && (
          <ButtonGroupWrapper>
            <Button text="Next" type="submit" />
          </ButtonGroupWrapper>
        )}
      </FormWrapper>
    );
  }

  return (
    <div className="columns is-mobile is-centered">
      <ColumnWrapper>
        <SectionHeader>Application details</SectionHeader>
        {content}
      </ColumnWrapper>
    </div>
  );
};

export default connect((state) => {
  const currentUser = get(state, 'current_user', {});

  return {
    activeQuestionAndAnswer: get(
      state,
      'applicationOverlayReducer.activeQuestionAndAnswer',
      ''
    ),
    currentUser: UserModel.fromCurrentUser(currentUser),
    supplier: new EntityModel(get(state, 'cob_section.supplier', {})),
    supplierRegion: get(state, 'cob_business.entity_region', undefined),
  };
})(ApplicationDetails);
