/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import seedrandom from 'seedrandom';

import styles from './css/Business.css';

/* Import actions */
import {
  setEntityPartyDetailsData,
  componentComplete,
} from '../../actions/onboarding';

/* Import components */
import PanelTitle from 'modules/shared/components/widgets/static/PanelTitle';
import PercentageWidget from 'modules/shared/components/widgets/interactive/PercentageWidget';
import TextInput from 'modules/shared/components/inputs/TextInput';
import DonutChart from 'modules/shared/components/widgets/interactive/DonutChart';
import { isMobile } from 'modules/shared/helpers/mobileDetect';

var BusinessPartyShare = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete(nextProps.total_percent, nextProps.parties));
  },

  isComplete: function(total_percent, parties) {
    const { setPageValidationStartFinish, error_message } = this.props;
    if (!total_percent) {
      var { total_percent } = this.props;
    }
    if (!parties) {
      var { parties } = this.props;
    }

    var is_no_value = Object.keys(parties).find((v, i) => {
      return (
        parties[v].percentage_share === undefined ||
        parties[v].percentage_share === 0 ||
        parties[v].percentage_share === ''
      );
    });

    if (is_no_value) {
      this.setState({
        form_errors: error_message,
      });
    }

    if (total_percent === 100 && !is_no_value) {
      setPageValidationStartFinish();
      return true;
    }
    return false;
  },

  componentDidMount: function() {
    const { handleComplete } = this.props;
    handleComplete(this.isComplete());
  },

  handlePercentChange: function(index, event) {
    const { dispatch, parties } = this.props;

    let value = event.target.value;
    let maxPercent = 100;
    Object.keys(parties).forEach((v, i) => {
      if (
        parties[v].hasOwnProperty('percentage_share') &&
        parties[v].percentage_share
      ) {
        let iPercent = parties[v].percentage_share;
        if (i != index) {
          maxPercent -= parseInt(iPercent);
        }
      }
    });

    if (maxPercent < value) value = maxPercent;

    dispatch(setEntityPartyDetailsData(index, 'percent', value));
  },

  handleChange: function(event) {
    const { dispatch } = this.props;
    if (event.hasOwnProperty('target')) {
      if (event.target.value.length > 0) {
        if (
          event.target.value ==
          parseInt(event.target.value.toString().replace(/[^0-9]/g, ''), 10)
        ) {
          dispatch(
            setEntityPartyDetailsData(
              event.target.name,
              'percent',
              parseInt(event.target.value.toString().replace(/[^0-9]/g, ''), 10)
            )
          );
        }
      } else {
        dispatch(setEntityPartyDetailsData(event.target.name, 'percent', ''));
      }
    }
  },

  _setColor: function(name) {
    if (name === undefined) {
      name = 'Unknown';
    }
    for (let i = 0; i < 5; ++i) {
      var hue =
        seedrandom(
          name +
            Array(i)
              .fill()
              .join(' ')
        )() * 360;
      const hasHueHistory = this._hueHistory.find(
        (h) => Math.abs(hue - h) < 30
      );
      if (!hasHueHistory) {
        this._hueHistory.push(hue);
        break;
      }
    }
    const sat = seedrandom(name.toUpperCase())() * (75 - 45) + 45;
    const lum = seedrandom(name.toLowerCase())() * (60 - 50) + 50;
    return `hsl(${hue.toFixed(1)},${sat.toFixed(1)}%,${lum.toFixed(1)}%)`;
  },

  getInitialState: function() {
    return {
      form_errors: '',
    };
  },

  render: function() {
    const {
      title,
      parties,
      total_percent,
      error_message,
      page_validation_start,
      info,
    } = this.props;

    var error = page_validation_start ? error_message : this.state.form_errors;
    var percentage_styles = styles.total_percent;
    if (total_percent < 100) {
      percentage_styles = styles.total_percent__low;
    } else if (total_percent > 100) {
      percentage_styles = styles.total_percent__error;
    } else {
      percentage_styles = styles.total_percent;
      error = '';
    }

    this._hueHistory = [];
    var children = [];
    let leftPercent = 0;
    Object.keys(parties).forEach((item, index) => {
      var percent = '';
      var full_name = parties[item].first_name + ' ' + parties[item].last_name;

      parties[item].color = this._setColor(full_name);

      if (
        parties[item].hasOwnProperty('percentage_share') &&
        parties[item].percentage_share
      ) {
        percent = parties[item].percentage_share;
        if (index !== parties.length - 1) {
          leftPercent += parseInt(percent);
        }
      }

      children.push(
        <TextInput
          key={index}
          id={index}
          name={index}
          type="tel"
          handleChange={this.handleChange}
          label={full_name + ' shares'}
          label_color={parties[item].color}
          value={percent}
          required={true}
        />
      );
    });

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.party_share_col}>
            <PanelTitle text={title} />
            <h3 className={styles.info}>{info}</h3>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.party_share_chart}>
            <DonutChart
              key={'percentage'}
              id={'percentage'}
              name={'percentage'}
              parties={parties}
            />
          </div>
        </div>
        <div className={styles.error_chart}>{error}</div>
        <div className={styles.row}>
          <div className={styles.party_share_col}>{children}</div>
        </div>
      </section>
    );
  },
});

var defaults = {
  title_partners: "Partner's share",
  title_trustees: "Trustee's share",
  error_message: 'Please ensure total is 100%.',
  info_partners: "Please define each partner's shares in the business",
  info_trustees: "Please define each trustee's shares in the business",
};

module.exports = connect((state, ownProps) => {
  var title = 'What’s their share?';
  var info = null;

  if (state.cob_business.entity_type !== null) {
    if (state.cob_business.entity_type == 'trust') {
      title = defaults.title_trustees;
      info = defaults.info_trustees;
    } else {
      title = defaults.title_partners;
      info = defaults.info_partners;
    }
  }

  var parties = state.cob_business.entity_party_details_values;
  var total_percent = 0;
  parties.forEach((item, index) => {
    if (item.hasOwnProperty('percentage_share') && item.percentage_share) {
      if (item.percentage_share !== '') {
        total_percent += parseInt(item.percentage_share);
      }
    }
  });

  return {
    title: title,
    total_percent: total_percent,
    parties: state.cob_business.entity_party_details_values,
    error_message: defaults.error_message,
    info: info,
  };
})(BusinessPartyShare);
