import { Typography } from '@material-ui/core';
import React from 'react';

export const Description = () => (
  <Typography>
    The 1Account Customer View helps your customer recognise and choose their
    local stores easily when opening a trade account with you. You can arrange
    your Tier 3 into categories based on your customer’s knowledge, for example
    region or product groups. You can add a group by clicking the plus button,
    then drag and drop the relevant Tier 3 from the list on the right-hand side
    into a corresponding group. Please contact{' '}
    <a href="mailto:success@1centre.com">success@1centre.com</a> to enable this
    feature.
  </Typography>
);
