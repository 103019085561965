import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clickEvent } from 'modules/identity/actions';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useRef, useState } from 'react';

import {
  CameraCapture,
  CameraCaptureWrapper,
  CameraFrame,
  CameraReadyMessage,
  ErrorMsg,
  Webcam,
} from './styles';
import { getErrorMessage } from './utils';

function Camera({ dispatch, distinctId, open, setFile }) {
  const webcamRef = useRef(null);
  const [error, setError] = useState(null);

  // const videoConstraints = {
  //   aspectRatio: 1.6667,
  //   height: { min: 720 },
  //   width: { min: 1280 },
  // }

  const capture = useCallback(async () => {
    const image = webcamRef.current.getScreenshot({
      height: 1080,
      width: 1920,
    });

    if (image) {
      const blob = await fetch(image).then((res) => res.blob());
      const blobFile = new File([blob], 'webcam-shot.jpeg', {
        lastModifiedDate: new Date(),
        type: 'image/jpeg',
      });

      dispatch(clickEvent('take photo', distinctId));

      setFile(Object.assign(blobFile, { preview: image }));
    }
  }, [webcamRef]);

  return (
    <CameraFrame onClick={capture} error={!!error}>
      {!error && (
        <Fragment>
          <CameraReadyMessage>
            <i className="fas fa-spinner fa-spin" /> Initializing camera...
          </CameraReadyMessage>
          <Webcam
            audio={false}
            ref={webcamRef}
            // videoConstraints={videoConstraints}
            screenshotFormat="image/jpeg"
            onUserMediaError={(e) => setError(e)}
          />
          <CameraCaptureWrapper>
            <button className="button is-primary is-rounded is-outlined is-white-base">
              <FontAwesomeIcon icon={['fas', 'camera']} />
            </button>
          </CameraCaptureWrapper>
        </Fragment>
      )}
      {error && !isMobile() && (
        <Fragment>
          <ErrorMsg
            className="has-text-danger"
            style={{
              lineHeight: 1.2,
            }}
          >
            <i className="fas fa-exclamation-circle" /> {getErrorMessage(error)}
          </ErrorMsg>
          <button
            className="button is-primary is-rounded"
            type="button"
            onClick={open}
            tabIndex={0}
          >
            Browse
          </button>
        </Fragment>
      )}
    </CameraFrame>
  );
}

Camera.propTypes = {
  setFile: PropTypes.func.isRequired,
};

export default Camera;
