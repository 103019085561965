/* eslint-disable max-classes-per-file */
export function calculateMonthlyCost(
  currentPlan,
  numberOfUsers,
  perUserCharge
) {
  const ENTERPRISE_PLAN_NO = 0;
  const DEFAULT_NUMBER_OF_USERS = 0;
  const DEFAULT_PER_USER_CHARGE = 0;

  const _numberOfUsers = numberOfUsers || DEFAULT_NUMBER_OF_USERS;
  const _perUserCharge = perUserCharge || DEFAULT_PER_USER_CHARGE;

  if (currentPlan === ENTERPRISE_PLAN_NO) return null;

  return parseInt(_numberOfUsers) * parseFloat(_perUserCharge);
}
/* eslint-enable max-classes-per-file */
