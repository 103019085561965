import axios from './axios';

let PricingPlans = function(config) {
  config.timeout = 20000;
  this.axios = axios(config);
};

PricingPlans.prototype.getPricingPlans = function(success, error, options) {
  return this.axios
    .get('/pricing_plans', options)
    .then(success)
    .catch(error);
};

PricingPlans.prototype.upgrade = function(id, attributes, success, error) {
  return this.axios
    .patch(`/entity_plans/${id}/upgrade`, {
      data: {
        plan_no: attributes.plan_no,
      },
    })
    .then(success)
    .catch(error);
};

PricingPlans.prototype.downgrade = function(id, attributes, success, error) {
  return this.axios
    .patch(`/entity_plans/${id}/downgrade`, {
      data: {
        plan_no: attributes.plan_no,
      },
    })
    .then(success)
    .catch(error);
};

export default PricingPlans;
