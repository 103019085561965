import React from 'react';

import Position from './Position';

var TopLeft = createClass({
  render: function() {
    const { children, absolute } = this.props;
    return (
      <Position absolute={absolute} position="top_left">
        {children}
      </Position>
    );
  },
});

module.exports = TopLeft;
