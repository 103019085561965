import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import { COLORS } from 'variables/theme';

const Container = styled.div<{ $scrolling: boolean }>`
  background: ${COLORS.lightGrey};
  padding: 20px;
  position: ${(props) => (props.$scrolling ? 'static' : 'sticky')};
  top: ${(props) => (props.$scrolling ? '0' : '66px')};
  .title {
    font-size: 20px;
  }
  z-index: 1;
  @media screen and (min-width: 992px) {
    padding: 32px 50px;
  }
`;

type Props = {
  title?: string;
  children: JSX.Element | JSX.Element[];
  scrolling: boolean;
};

export const Header = (props: Props) => {
  const { children, title, scrolling } = props;
  return (
    <Container $scrolling={scrolling}>
      {title ? (
        <Typography className="title" variant="h1">
          {title}
        </Typography>
      ) : null}
      {children}
    </Container>
  );
};
