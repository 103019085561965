import React, { Component } from 'react';

import Button from 'modules/shared/components/inputs/Button';
import SectionRect from 'modules/shared/components/widgets/static/SectionRect';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import styles from '../css/Alert.css';

class Alert extends Component {
  onHandleDecline() {
    // TODO: Do something
    browserHistory.push({
      pathname: '/cardholder/complete',
      query: location.query,
    });
  }

  onHandleNext() {
    const { location } = this.props;
    // TODO: Get condition if identification check is required
    browserHistory.push({
      pathname: '/cardholder/identity/capture',
      query: location.query,
    });
  }

  render() {
    const { consumerTradingName, supplierTradingName } = this.props;

    // TODO: Replace with actual values
    return (
      <div className={styles.alertContainer}>
        <SectionRect key="alert" title="">
          <p>
            You have been nominated to be a cardholder for a trade application
            between {supplierTradingName} and {consumerTradingName}
          </p>

          <div className={styles.alertButtonsContainer}>
            <Button
              text="Decline"
              handleClick={this.onHandleDecline.bind(this)}
            />
            <Button text="Next" handleClick={this.onHandleNext.bind(this)} />
          </div>
        </SectionRect>
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    consumerTradingName: 'Coffee Bean',
    supplierTradingName: 'ACME Corp',
  };
})(Alert);
