import { FormHelperText, ListItemText, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ThemeProvider } from '@material-ui/core/styles';
import styles from 'modules/shared/components/inputs/css/BorderedSelect.css';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import { Radio } from 'mui-latest';
import React, { useState } from 'react';

function formControlRootClasses(props) {
  const { disabled, withBottomMargin } = props;
  const defaultClasses = [styles.form_control];

  if (withBottomMargin) {
    defaultClasses.push(styles.with_bottom_margin);
  }

  if (disabled) {
    defaultClasses.push(styles.form_control_disabled);
  }

  return defaultClasses.join(' ');
}

function selectRootClasses(props) {
  const { isCompact, placeholder, selectedOption, hasNullValue } = props;
  const defaultClasses = [styles.select_root];

  if (isCompact) {
    defaultClasses.push(styles.select_compact);
  }

  if (
    placeholder &&
    !selectedOption &&
    ![0, ...(hasNullValue ? [null] : [])].includes(selectedOption)
  ) {
    defaultClasses.push(styles.placeholder);
  }

  return defaultClasses.join(' ');
}

export default function BorderedSelect(props) {
  const {
    error,
    formControlCustomProps,
    helperText,
    label,
    options,
    placeholder,
    selectCustomProps,
    variant,
    required,
    rawOptions,
    register,
    menuProps,
    renderCustomOption,
    customOptionValue,
    customOptionRenderValue,
    customOptionItemStyle,
    hasNullValue,
    ...selectProps
  } = props;

  const processedLabel = required ? `${label}*` : label;
  const [selectedOption, setSelectedOption] = useState('');

  const renderOption = (option) => {
    if (option.customOption) {
      return renderCustomOption(option, customOptionValue, styles);
    }

    return (
      <React.Fragment>
        {option.showRadio && (
          <Radio
            className={styles.radio_input}
            checked={selectedOption === option.value}
          />
        )}
        {option.description ? (
          <ListItemText
            className={styles.text_wrap}
            primary={option.label}
            secondary={option.description}
          />
        ) : (
          option.label
        )}
      </React.Fragment>
    );
  };

  return (
    <ThemeProvider theme={muiTheme()}>
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        error={error}
        className={formControlRootClasses({
          ...defaultFormControlCustomProps,
          ...formControlCustomProps,
          disabled: props.disabled,
        })}
      >
        <InputLabel
          classes={{
            root: props.disabled
              ? styles.label_root_disabled
              : styles.label_root,
            shrink: styles.label_shrink,
          }}
        >
          {processedLabel}
        </InputLabel>
        <Select
          variant={variant}
          label={processedLabel}
          displayEmpty
          inputProps={{ ...register }}
          classes={{
            root: selectRootClasses({
              ...defaultSelectCustomProps,
              ...selectCustomProps,
              hasNullValue,
              placeholder,
              selectedOption: selectedOption,
            }),
          }}
          MenuProps={{
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            getContentAnchorEl: null,
            transformOrigin: {
              horizontal: 'left',
              vertical: 'top',
            },
            ...menuProps,
          }}
          {...selectProps}
          renderValue={(selected) => {
            if (!options) {
              return placeholder;
            }

            const currentSelectedOption = options.find(
              ({ value }) => value === selected
            );

            if (placeholder && !currentSelectedOption) {
              return placeholder;
            }

            if (
              currentSelectedOption &&
              (currentSelectedOption.value ||
                [0, ...(hasNullValue ? [null] : [])].includes(
                  currentSelectedOption.value
                ))
            ) {
              setSelectedOption(currentSelectedOption.value);
            }

            const customRenderOptionLabel =
              customOptionRenderValue &&
              customOptionRenderValue(
                customOptionValue,
                options.filter((option) => option.customOption)
              );
            const customRenderOptionLabelDisplay = customRenderOptionLabel
              ? `${
                  currentSelectedOption
                    ? ', ' + customRenderOptionLabel
                    : customRenderOptionLabel
                }`
              : '';

            if (
              currentSelectedOption &&
              currentSelectedOption.label &&
              typeof currentSelectedOption.label === 'object'
            ) {
              return (
                <div>
                  {currentSelectedOption.label}{' '}
                  <span>{customRenderOptionLabelDisplay}</span>
                </div>
              );
            }

            return (
              currentSelectedOption &&
              currentSelectedOption.label + customRenderOptionLabelDisplay
            );
          }}
        >
          {!rawOptions &&
            options.map((option) => (
              <MenuItem
                className={
                  option.customOption
                    ? customOptionItemStyle + ' ' + styles.item
                    : styles.item
                }
                key={option.value}
                value={option.value}
                isCustomOption={option.customOption}
              >
                {renderOption(option)}
              </MenuItem>
            ))}
          {rawOptions ? rawOptions : []}
        </Select>
        <FormHelperText
          classes={{
            root: error
              ? styles.form_error_text_root
              : styles.form_helper_text_root,
          }}
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
}

const defaultSelectCustomProps = {
  isCompact: false,
};

const defaultFormControlCustomProps = {
  withBottomMargin: true,
};

BorderedSelect.defaultProps = {
  error: false,
  formControlCustomProps: defaultFormControlCustomProps,
  selectCustomProps: defaultSelectCustomProps,
};
