import React from 'react';
import { humanize } from 'utils/humanize';

/* Import CSS */
import styles from './css/TableCell.css';

var TableCell = createClass({
  render: function() {
    const {
      label,
      label_only,
      showEmpty,
      showIcon,
      table_layout,
      th_position,
      th_left_width,
      td_align,
      rawChildren,
      noHumanize,
    } = this.props;
    let { data } = this.props,
      dataValues = [],
      item_css = styles.item,
      th_css = styles.th,
      td_css = styles.td;

    if (table_layout) {
      item_css = `${item_css} ${styles[table_layout]}`;
    }

    if (th_position) {
      th_css = `${th_css} ${styles[th_position]}`;
    }

    if (th_left_width) {
      th_css = `${th_css} ${styles[th_left_width]}`;
    }

    if (showIcon) {
      if (
        humanize(label).includes('Incorporated period') ||
        humanize(label).includes('Total directors') ||
        humanize(label).includes('Total shareholders') ||
        humanize(label).includes('Registered security interests') ||
        humanize(label).includes('Public notices') ||
        humanize(label).includes('Known company affiliations') ||
        humanize(label).includes('Inquiries') ||
        humanize(label).includes('Age of credit file')
      ) {
        th_css = `${th_css} ${styles.th_icon} ${styles.icon_info}`;
      }

      th_css = `${th_css} ${styles.th_icon} ${
        styles[label.replace(/\s+/g, '').toLowerCase()]
      }`;
    }

    if (td_align) {
      td_css = `${td_css} ${styles[td_align]}`;
    }

    if (rawChildren) {
      return (
        <div className={item_css}>
          <div className={styles.liner}>
            <div className={th_css}>{humanize(label)}</div>
            {this.props.children}
          </div>
        </div>
      );
    }

    if (!showEmpty && !data) {
      return null;
    }

    // If data is a component, but the value is still null...
    if (!label_only) {
      if (!showEmpty && data && data.props && !data.props.value) {
        return null;
      }

      if (!data || data === '') {
        data = '—';
      }

      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          dataValues.push(
            <div className={td_css} key={`dataPoint-${i}`}>
              {v}
            </div>
          );
        });
      } else {
        let value;
        if (noHumanize) {
          value = data;
        } else {
          value = humanize(data);
        }
        dataValues = <div className={td_css}>{value}</div>;

        if (value == 0 && showIcon) {
          th_css = `${th_css} ${styles.th_icon} ${styles.icon_tick}`;
        }
      }
    }

    return (
      <div className={item_css}>
        <div className={styles.liner}>
          <div className={th_css}>{humanize(label)}</div>
          {dataValues}
        </div>
      </div>
    );
  },
});

export default TableCell;
