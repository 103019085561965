import BaseModel from 'models/BaseModel';
import { formatLocalTime } from 'utils/dateFormatter';
import isBlank from 'utils/isBlank';

export default class RecordHistoryModel extends BaseModel {
  get formattedContent() {
    if (isBlank(this.content)) {
      return '';
    }

    /* eslint-disable no-template-curly-in-string */
    return this.replacePlaceholderContent({
      actualValue: formatLocalTime(this.createdAt, 'minute'),
      placeholder: '${date}',
      string: this.content,
    });
    /* eslint-enable no-template-curly-in-string */
  }

  /** Private functions */

  replacePlaceholderContent({ string, placeholder, actualValue }) {
    return string.replace(placeholder, actualValue);
  }
}
