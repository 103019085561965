import get from 'lodash.get';
import styles from 'modules/card-management-onboarding/css/Details.css';
import commonStyles from 'modules/card-management-onboarding/css/Section.css';
import TextInput from 'modules/shared/components/inputs/TextInput';
import ConsumerFromRender from 'modules/shared/components/widgets/interactive/form_builder/ConsumerFromRender';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import React, { Fragment } from 'react';

import { Wrapper } from './styles';

const REGION_OPTIONS = [
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
];

function AccountDetails(props) {
  const {
    accountName,
    accountNumber,
    addCardholders,
    additionalFieldsAnswer,
    additionalFieldCheck,
    application,
    errors,
    numberOfCards,
    region,
    setAccountName,
    setAccountNumber,
    setAdditionalFieldsAnswer,
    setRegion,
    signatureRequired,
  } = props;

  const handleAdditionalFieldChange = (params) => {
    const currentAnswers = additionalFieldsAnswer || [];

    const answerIndex = currentAnswers.findIndex(
      (answer) => answer.description === params.description
    );
    if (answerIndex === -1) {
      currentAnswers.push(params);
    } else {
      currentAnswers[answerIndex] = {
        ...currentAnswers[answerIndex],
        ...params,
      };
    }

    setAdditionalFieldsAnswer(currentAnswers);
  };

  return (
    <div className="columns is-multiline">
      {signatureRequired && (
        <div className="column is-12-mobile">
          <OptionsDropdown
            id="addressRegion"
            name="addressRegion"
            handleChange={(selectedOption) => setRegion(selectedOption.value)}
            label="Region"
            value={region}
            required={true}
            options={REGION_OPTIONS}
            error={get(errors, 'region', '')}
          />
        </div>
      )}
      <div className="column is-12-mobile">
        <TextInput
          label={application.accountNumberLabel || 'Account number'}
          handleChange={(event) =>
            setAccountNumber(event.target.value.replace(/\D/g, ''))
          }
          required={true}
          value={accountNumber}
          error={get(errors, 'account_number', '')}
        />
      </div>
      <div className="column is-12-mobile">
        <TextInput
          label={application.accountNameLabel || 'Account name'}
          handleChange={(event) => setAccountName(event.target.value)}
          required={true}
          value={accountName}
          error={get(errors, 'account_name', '')}
        />
      </div>
      <div className="column is-12">
        <ConsumerFromRender
          components={application.additionalFieldsGeneral || []}
          onChange={handleAdditionalFieldChange}
          reduxKey={'card_management_general'}
          validationTrigger={additionalFieldCheck}
          answers={additionalFieldsAnswer}
        />
      </div>
    </div>
  );
}

export default AccountDetails;
