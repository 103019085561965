import React from 'react';
import styles from './report-styles.css';
import { getCharacteristicsData, getHeadlineData } from './utils';

const ReportHeadline = ({ characteristic }) => {
  const insolvenciesAndActions = getCharacteristicsData(
      characteristic,
      'NP8510'
    ),
    headlineData = getHeadlineData(characteristic),
    currentDirectorships = getCharacteristicsData(characteristic, 'NP8500'),
    businessHeld = getCharacteristicsData(characteristic, 'NP8508'),
    prevDirectorships = getCharacteristicsData(characteristic, 'NP8509'),
    disqDirectorships = getCharacteristicsData(characteristic, 'NP8503');

  const { adverseValue, creditEnquiriesValue, defaultValue } =
    headlineData || {};

  const businessRelationshipsCount =
    parseInt(currentDirectorships.value) +
    parseInt(prevDirectorships.value) +
    parseInt(disqDirectorships.value) +
    parseInt(businessHeld.value);

  return (
    <div>
      <div className={styles.table + ' ' + styles.bg_space}>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_3}>Adverse on file</div>
          <div className={styles.td + ' ' + styles.col_3}>
            {adverseValue < 0 ? '-' : adverseValue}
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            Insolvencies & Actions
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!insolvenciesAndActions || insolvenciesAndActions.value < 0
              ? '-'
              : insolvenciesAndActions.value}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_3}>Credit enquiries</div>
          <div className={styles.td + ' ' + styles.col_3}>
            {creditEnquiriesValue < 0 ? '-' : creditEnquiriesValue}
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            Business relationships
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!businessRelationshipsCount || businessRelationshipsCount < 0
              ? 0
              : businessRelationshipsCount}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_3}>Defaults</div>
          <div className={styles.td + ' ' + styles.col_3}>
            {defaultValue < 0 ? '-' : defaultValue}
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            Disq. directorships
          </div>
          <div className={styles.td + ' ' + styles.col_3}>
            {!disqDirectorships || disqDirectorships.value < 0
              ? 0
              : disqDirectorships.value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeadline;
