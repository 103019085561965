import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { muiTheme } from 'modules/shared/helpers/colorPalettes';

import { Description } from './Description';
import { Structure } from './Structure';
import { StoreList } from './StoreList';
import { Buttons } from './Buttons';
import { HistoryLogs } from './HistoryLogs';
import { CustomerViewContextProvider } from './CustomerViewContext';
import { InteractionContainer } from './InteractionContainer';
import { UnsavedChangesWrapper } from './UnsavedChangesWrapper';
import { Preview } from './Preview';
import { EmptyAlert } from './EmptyAlert';

export const CustomerView = () => {
  return (
    <MuiThemeProvider theme={muiTheme()}>
      <CustomerViewContextProvider>
        <Description />
        <InteractionContainer>
          <Structure />
          <StoreList />
        </InteractionContainer>
        <EmptyAlert />
        <Buttons />
        <HistoryLogs />
        <UnsavedChangesWrapper />
        <Preview />
      </CustomerViewContextProvider>
    </MuiThemeProvider>
  );
};
