import LabeledContent from 'modules/shared/components/widgets/static/LabeledContent';
import React, { Fragment, ReactElement } from 'react';

interface ICardholderSignatory {
  email: string;
  phoneNumber: string;
  fullDeliveryAddress: string;
}

const CardholderWithSignatureDetails = ({
  moduleCardholderSignatory,
}: {
  moduleCardholderSignatory: ICardholderSignatory;
}): ReactElement => (
  <Fragment>
    <LabeledContent label="Email" content={moduleCardholderSignatory.email} />
    <LabeledContent
      label="Phone number"
      content={moduleCardholderSignatory.phoneNumber}
    />
    <LabeledContent
      label="Card delivery address"
      content={moduleCardholderSignatory.fullDeliveryAddress}
    />
  </Fragment>
);

export default CardholderWithSignatureDetails;
