import PropTypes from 'prop-types';
import React from 'react';

import { InputElement } from './styles';

const Input = ({
  defaultValue,
  disableThemeOverride,
  disabled,
  error,
  name,
  placeholder,
  required,
  inputRef,
  type,
}) => (
  <div className="field">
    <div className="control">
      <InputElement
        className="input"
        defaultValue={defaultValue}
        disabled={disabled}
        disableThemeOverride={disableThemeOverride}
        type={type}
        placeholder={placeholder}
        name={name}
        required={required}
        ref={inputRef}
      />
    </div>
    {error && <p className="help is-danger">{error}</p>}
  </div>
);

Input.propTypes = {
  defaultValue: PropTypes.string,
  disableThemeOverride: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

Input.defaultProps = {
  defaultValue: null,
  disableThemeOverride: false,
  disabled: false,
  error: null,
  type: 'text',
};

export default Input;
