/* eslint-disable max-lines */
import Input from '@material-ui/core/Input';
import AddOnsNotSavePopup from 'modules/addons/addons_form/components/AddOnsNotSavePopup';
import { resetFinancials } from 'modules/consumer-onboarding/actions/financials';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import FinancialsForm from 'modules/shared/components/widgets/interactive/FinancialsForm';
import React from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';

import LegalTypeCheckbox from '../../addons_form/components/LegalTypeCheckbox';
import LimitRange from '../../addons_form/components/LimitRange';
import PreviewSaveButtons from '../../addons_form/components/PreviewSaveButtons';
/* Import CSS */
import styles from '../../components/css/AddOnsDetails.css';
import {
  createAddonVersion,
  loadAddons,
  resetCurrentAddonRuleset,
  setAddonEntityType,
  setAddonValue,
  setFinancialsAdditionalFields,
  setFinancialsIndicatorFields,
  setFinancialsMandatoryFields,
} from './../../actions';
import {
  createLimitConflictMessage,
  findLimitBreakpoints,
  findLimitConflicts,
  getCreditLimitError,
  getLegalTypesError,
} from './../../helpers';

class FinancialsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: props.data.attributes.config.additional_fields,
      errors: {},
      notSaved: false,
      notSavedPopup: false,
    };
  }

  get addonName() {
    const { data, readOnly } = this.props;
    const attributes = data.attributes;
    const name = attributes.name;

    if (readOnly) {
      return `${name} (Version ${attributes.version})`;
    }

    return name;
  }

  get creditLimitRangeConflictError() {
    const { financials, data } = this.props;
    const limitBreakpoints = findLimitBreakpoints(financials, data);
    const limitConflicts = findLimitConflicts(limitBreakpoints, data);

    if (limitConflicts) {
      return createLimitConflictMessage(limitConflicts);
    }

    return null;
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCurrentAddonRuleset(defaults.moduleName));
  }

  addonContentChanged() {
    this.setState({ notSaved: true });
  }

  showNotSavedPopup() {
    this.setState({ notSavedPopup: true });
  }

  hideNotSavedPopup() {
    this.setState({ notSavedPopup: false });
  }

  submit() {
    const { data, defaults, dispatch, handleDiscard } = this.props;
    if (this.checkValidAll()) {
      dispatch(
        createAddonVersion(data.attributes, defaults.moduleName, () => {
          handleDiscard();
          dispatch(loadAddons(defaults.moduleName));
        })
      );
    }
  }

  discard() {
    const { handleDiscard } = this.props;
    if (this.state.notSaved) {
      this.showNotSavedPopup();
    } else {
      handleDiscard();
    }
  }

  _checkIndicatorComplete() {
    const { data } = this.props;
    const att = data.attributes;
    const indicatorFields = att.config.indicator_fields;
    if ((indicatorFields || []).length === 0) {
      return true;
    }

    const components = att.config.additional_fields;
    const hasAnyIncomeField =
      components['income'].findIndex((component) => !!component.indicator) !==
      -1;
    const hasAnyExpensesField =
      components['expenses'].findIndex((component) => !!component.indicator) !==
      -1;
    return hasAnyIncomeField || hasAnyExpensesField;
  }

  checkValidAll() {
    const { data } = this.props;
    const att = data.attributes;

    let errors = {};

    const legalTypesError = getLegalTypesError(att.legal_types);
    const creditLimitError = getCreditLimitError(
      att.min_credit_value,
      att.max_credit_value
    );

    if (!att.config.mandatory_fields.length) {
      errors.mandatory_fields = 'Please select at least one mandatory fields';
    }
    if (!this._checkIndicatorComplete()) {
      errors.indicator_fields =
        'Please complete the self defined indicator formula';
    }
    att.config.mandatory_fields.forEach((mandatory) => {
      if (mandatory === 'file') return;

      mandatory.split('_').forEach((item) => {
        if (!att.config.additional_fields[item].length) {
          errors.additional_fields =
            'Please add at least one additional fields of each mandatory';
        }
      });
    });

    errors = { ...errors, ...legalTypesError, ...creditLimitError };
    this.setState({ errors });

    return (
      Object.keys(errors).length === 0 &&
      isBlank(this.creditLimitRangeConflictError)
    );
  }

  selectType(event) {
    const { errors } = this.state;
    delete errors.legal_types;
    this.setState({ errors });

    const { defaults, dispatch } = this.props;
    dispatch(setAddonEntityType(event.target.id, defaults.moduleName));
    this.addonContentChanged();
  }

  _needToUnselectedIndicators(mandatoryField, indicatorField) {
    const { data } = this.props;
    const config = data.attributes.config;
    const isUnselectingMandatory = config.mandatory_fields.includes(
      mandatoryField
    );
    const isIndicatorSelected = (config.indicator_fields || []).includes(
      indicatorField
    );
    return isUnselectingMandatory && isIndicatorSelected;
  }

  selectMandatoryFields(event) {
    const { errors } = this.state;
    const mandatoryField = event.target.id;
    const indicatorField = mandatoryIndicatorMapper[mandatoryField];

    delete errors.mandatory_fields;
    this.setState({ errors });

    const { dispatch } = this.props;
    if (this._needToUnselectedIndicators(mandatoryField, indicatorField)) {
      dispatch(setFinancialsIndicatorFields(indicatorField));
    }
    dispatch(setFinancialsMandatoryFields(mandatoryField));
    this.addonContentChanged();
  }

  selectSelfDefinedIndicators(event) {
    const { dispatch } = this.props;
    dispatch(setFinancialsIndicatorFields(event.target.id));
    this.addonContentChanged();
  }

  setValue(name, value) {
    this.resetCreditLimitError(name);

    const { defaults, dispatch } = this.props;
    dispatch(setAddonValue(name, value, defaults.moduleName));
    this.addonContentChanged();
  }

  resetCreditLimitError(name) {
    if (!['max_credit_value', 'min_credit_value'].includes(name)) {
      return;
    }

    const { errors } = this.state;
    delete errors.credit_limit;
    this.setState({ errors });
  }

  setComponent(key, params) {
    const { dispatch } = this.props;
    const { components } = this.state;

    this.setState({ components: { ...components, [key]: params } });
    dispatch(setFinancialsAdditionalFields(key, params));
    this.addonContentChanged();
  }

  renderMandatoryFields() {
    const { readOnly, data, defaults } = this.props;
    const { errors, components } = this.state;

    const options = defaults.options;
    const items = [];
    Object.keys(defaults.options).forEach((item, index) => {
      items.push(
        <div key={`mandatory-${index}`} className={styles.col4}>
          <SquareCheckbox
            key={item}
            checkboxId={item}
            checkboxName={item}
            checked={data.attributes.config.mandatory_fields.includes(item)}
            handleChange={(e) => {
              this.selectMandatoryFields(e);
            }}
            label={options[item].label}
            disabled={readOnly}
          />
        </div>
      );
    });

    const userDefineItems = [];

    ['assets', 'liabilities', 'income', 'expenses'].forEach((caty) => {
      components[caty].forEach((component) => {
        if (component.mandatory) {
          userDefineItems.push(
            <p>
              {component.field_description_1} & {component.field_description_2}
            </p>
          );
        }
      });
    });

    return (
      <div className={styles.section}>
        <h3>Mandatory Fields</h3>
        {errors.mandatory_fields && (
          <div className={styles.error}>{errors.mandatory_fields}</div>
        )}
        <div className={styles.row}>{items}</div>
        <div className={styles.row}>{userDefineItems}</div>
      </div>
    );
  }

  renderSelfDefinedIndicators() {
    const { readOnly, data } = this.props;
    const { errors, components } = this.state;

    if (!data.attributes.config.mandatory_fields.includes('income_expenses')) {
      return null;
    }

    const surplusChecked = (
      data.attributes.config.indicator_fields || []
    ).includes('surplus');
    const item = (
      <div key={'indicator-surplus'} className={styles.col4}>
        <SquareCheckbox
          checkboxId={'surplus'}
          checked={surplusChecked}
          handleChange={(e) => this.selectSelfDefinedIndicators(e)}
          label={
            'Tailor how your surplus income is calculated for Auto Decisioning'
          }
          disabled={readOnly}
        />
      </div>
    );

    const incomeFields = [];
    components['income'].forEach((component) => {
      if (component.indicator) incomeFields.push(component.field_description_1);
    });
    const expensesFields = [];
    components['expenses'].forEach((component) => {
      if (component.indicator) {
        expensesFields.push(component.field_description_1);
      }
    });
    let userDefinedSurplus = '';
    if (surplusChecked) userDefinedSurplus = 'Surplus = ';
    if (incomeFields.length > 0) {
      userDefinedSurplus = `${userDefinedSurplus}${incomeFields.join(' + ')}`;
    }
    if (expensesFields.length > 0) {
      userDefinedSurplus = `${userDefinedSurplus} - ${expensesFields.join(
        ' - '
      )}`;
    }

    return (
      <div className={styles.section}>
        <h3>Self defined 1CAD indicators</h3>
        {errors.indicator_fields && (
          <div className={styles.error}>{errors.indicator_fields}</div>
        )}
        <div className={styles.row}>{item}</div>
        <div className={styles.row}>{userDefinedSurplus}</div>
      </div>
    );
  }

  renderAdditionalFields() {
    const { readOnly, data, src } = this.props;
    const { components, errors } = this.state;

    if (!data.attributes.config.mandatory_fields.length) {
      return null;
    }

    return (
      <div className={styles.section}>
        <h3>Additional Fields</h3>
        {errors.additional_fields && (
          <div className={styles.error}>{errors.additional_fields}</div>
        )}
        <div className={styles.row}>
          <FinancialsForm
            selected={data.attributes.config.mandatory_fields}
            selfDefinedIndicators={
              data.attributes.config.indicator_fields || []
            }
            components={components}
            readOnly={readOnly}
            supplier={true}
            fileSrc={src}
            handleChange={(key, params) => {
              this.setComponent(key, params);
            }}
          />
        </div>
      </div>
    );
  }

  resetPreviewData() {
    const { dispatch } = this.props;
    dispatch(resetFinancials());
  }

  render() {
    const { readOnly, handleDiscard, data, updating } = this.props;
    const { errors, notSavedPopup } = this.state;

    return (
      <section className={styles.container}>
        <div>
          {data.serial_number && (
            <span className={styles.serial}>#{data.serial_number}</span>
          )}
          <Input
            classes={{ input: styles.name_text, root: styles.input_parent }}
            id={'name'}
            value={this.addonName}
            placeholder={'Name your ruleset here - eg: Company'}
            onChange={(e) => {
              this.setValue(e.target.id, e.target.value);
            }}
            required
            disabled={readOnly}
          />
          <CloseButton onClick={this.discard.bind(this)} />
        </div>
        <div className={styles.section}>
          <div className={styles.row}>
            <div className={styles.info}>{defaults.description}</div>
          </div>
        </div>
        <LegalTypeCheckbox
          selected={data.attributes.legal_types}
          handleChange={(e) => {
            this.selectType(e);
          }}
          error={errors.legal_types}
          readOnly={readOnly}
        />
        <LimitRange
          addOn={{
            max_credit_value: data.attributes.max_credit_value,
            min_credit_value: data.attributes.min_credit_value,
          }}
          handleChange={(name, value) => {
            this.setValue(name, value);
          }}
          error={this.creditLimitRangeConflictError || errors.credit_limit}
          readOnly={readOnly}
          moduleName={defaults.moduleName}
        />
        {this.renderMandatoryFields()}
        {this.renderSelfDefinedIndicators()}
        {this.renderAdditionalFields()}
        <PreviewSaveButtons
          moduleName={defaults.moduleName}
          previewAddon={data.attributes}
          handleSubmit={() => {
            this.submit();
          }}
          loading={updating}
          readOnly={readOnly}
          dismissHandler={() => this.resetPreviewData()}
        />
        {notSavedPopup && (
          <AddOnsNotSavePopup
            handleSave={this.submit.bind(this)}
            handleLeave={handleDiscard}
            hidePopup={this.hideNotSavedPopup.bind(this)}
          />
        )}
      </section>
    );
  }
}
const defaults = {
  description:
    'Collect Assets & Liabilities, Income & Expenditure (generally from personal) \
    and you have the option of requesting the latest copy of their monthly management reports - \
    which can be mandatory or optional.  Consider entity types, eg: unlikely schools, government \
    departments will allow, and limits - the higher the limit the more risk for you. ',
  moduleName: 'financials',
  /* eslint-disable sort-keys-fix/sort-keys-fix */
  options: {
    assets_liabilities: { label: 'Assets & Liabilities' },
    income_expenses: { label: 'Income & Expenses' },
    file: { label: 'Financial statement required' },
  },
  /* eslint-enable sort-keys-fix/sort-keys-fix */
};

const mandatoryIndicatorMapper = {
  assets_liabilities: 'new_worth',
  income_expenses: 'surplus',
};

export default connect((state) => {
  const data = state.add_ons.current_financials;
  const financials = state.add_ons.financials;
  const updating = state.add_ons.financials_updating;
  return {
    data,
    defaults,
    financials,
    src: null,
    updating,
  };
})(FinancialsDetails);
