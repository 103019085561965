import get from 'lodash.get';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import React, { Component } from 'react';
import { formatMoney } from 'utils/formatting';
import objectToArray from 'utils/objectToArray';

import styles from '../css/payment-predictor/CorporateOwnership.css';
import commonStyles from '../css/payment-predictor/PaymentPredictorComponent.css';

export default class CorporateOwnership extends Component {
  get asicOfficerRoleDetails() {
    const responseAsicOfficeRoleDetails =
      get(this.props, 'response.asic_officer_role_details') || {};

    return objectToArray(responseAsicOfficeRoleDetails.asic_officer_role);
  }

  get ultimateHoldingCompanies() {
    return this.asicOfficerRoleDetails.filter(
      (officer) => officer.officer_role === 'Ultimate Holding Company'
    );
  }

  get shares() {
    return get(this.props, 'response.shares') || {};
  }

  renderLinkages() {
    const linkages = this.ultimateHoldingCompanies.map((company, i) =>
      this.renderUltimateHoldingCompany(company, i)
    );

    return (
      <div className={styles.ultimate_holding_company_container}>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Linkages</p>
        </div>
        <p className={commonStyles.emphasize}>
          Current ultimate holding company
        </p>
        <div>{linkages}</div>
      </div>
    );
  }

  renderUltimateHoldingCompany(company, i) {
    return (
      <div key={`company-${i}`} className={styles.ultimate_holding_company}>
        <p>{company.organisation_name}</p>
      </div>
    );
  }

  renderShareholdings() {
    const structures = objectToArray((this.shares.structure || {}).class);
    const shareholdings = structures.map((share, i) =>
      this.renderShareholding(share, i)
    );

    return (
      <div>
        <div className={commonStyles.subsection_header}>
          <p className={commonStyles.emphasize}>Shareholding</p>
        </div>
        <table className={`${commonStyles.table} ${styles.shareholding_table}`}>
          <thead>
            <tr>
              <th>Structure</th>
              <th>Number of shares held</th>
              <th>Total Amount to be paid</th>
            </tr>
          </thead>
          <tbody>{shareholdings}</tbody>
        </table>
      </div>
    );
  }

  renderShareholding(share, i) {
    return (
      <tr key={`share-${i}`}>
        <td>{share.class_code}</td>
        <td className={styles.shareholding_shares_issued}>
          {formatMoney(parseFloat(share.shares_issued), 2)}
        </td>
        <td className={styles.shareholding_total_paid_on_shares}>
          ${formatMoney(parseFloat(share.total_paid_on_shares), 2)}
        </td>
      </tr>
    );
  }

  renderNotes() {
    return (
      <div className={styles.notes_container}>
        <p className={styles.emphasize}>Notes</p>
        <ul className={commonStyles.list}>
          <li>
            For each class of shares issued by a proprietary company, ASIC
            records the details of the twenty members of the class (based on
            shareholdings). The details of any other members holding the same
            number of shares as the twentieth ranked member will also be
            recorded by ASIC on the database. Where available, historical
            records show that a member has ceased to be ranked amongst the
            twenty members. This may, but does not necessarily mean, that they
            have ceased to be a member of the company.
          </li>
          <li>
            Information on this extract such as Share Structure, Shareholders,
            Principal Place of Business and Ultimate Holding Company is obtained
            from the Company Annual Return.
          </li>
          <li>
            Care should be taken when using this information as it may have
            changed since the most recent Annual Return was processed by ASIC.
          </li>
          <li>
            Care should also be taken when using the shareholders information,
            as only the top ten shareholders are listed on the extract.
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <ReviewContainer
        subHeading="Corporate Ownership"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={this.props.isAllExpanded}
        fontSize="medium"
      >
        {this.renderLinkages()}
        {this.renderShareholdings()}
        {this.renderNotes()}
      </ReviewContainer>
    );
  }
}
