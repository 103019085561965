import sortByDate from 'utils/sortByDate';

/**
 * @deprecated
 * This needs to be deprecated in favour of `sortByDate`. It has not been
 * removed yet as it is being used in other places that need to be tested first.
 */
export default function sortByCreatedAt(records) {
  return sortByDate({
    dateAttribute: 'createdAt',
    direction: 'descending',
    records,
  });
}
