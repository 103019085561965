/* Import libraries */
import Collapse from '@material-ui/core/Collapse';
import Button from 'modules/shared/components/inputs/Button';
import ApprovalFlowChart from 'modules/shared/components/widgets/static/ApprovalFlowChart';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React from 'react';
import { connect } from 'react-redux';
import { formatLocalTime } from 'utils/dateFormatter';

import {
  getApprovalHierarchyHistory,
  getApprovalLevelByVersion,
  getLatestApprovalLevels,
} from '../actions';
import AppValuesApprovalLevels from './AppValuesApprovalLevels';
/* Import CSS */
import styles from './css/CommonEditProfile.css';

const ManageApprovalLevels = createClass({
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(getLatestApprovalLevels());
    dispatch(getApprovalHierarchyHistory());
  },

  description() {
    const { approval_levels, reviewerEnabled, hasReviewer } = this.props;

    if (approval_levels.length > 0) {
      const desc = [];
      approval_levels.forEach((approval_level) => {
        const short_of_approver =
          approval_level.minimum_approvers - approval_level.approver_num;
        if (short_of_approver > 0) {
          desc.push(
            <p key={`approval_level_warning_${approval_level.level}`}>
              {`Please assign ${short_of_approver} more approver/s to level${approval_level.level}. Currently you have ${approval_level.approver_num} assigned to this level.`}
            </p>
          );
        }
      });

      if (reviewerEnabled && !hasReviewer)
        desc.push(
          <p>
            Please assign 1 more reviewer to reviewer level. Currently you have
            0 assigned to this level
          </p>
        );

      return desc;
    }
    return 'To configure your approval hierarchy select the Setup button and follow the instructions provided.';
  },

  dismissManageApprovalLevelsModal() {
    this.setState({ showManageApprovalLevelsModal: false });
  },

  getInitialState() {
    return {
      approvalHierarchyVersion: 0,
      history_open: false,
      showHistoryApprovalLevels: false,
      showManageApprovalLevelsModal: false,
    };
  },

  handleCollapseToggle() {
    const { history_open } = this.state;
    this.setState({ history_open: !history_open });
  },

  openHistoryApprovalLevelsManageModal(version) {
    const { dispatch } = this.props;
    dispatch(getApprovalLevelByVersion(version));
    this.setState({
      approvalHierarchyVersion: version,
      showHistoryApprovalLevels: true,
      showManageApprovalLevelsModal: true,
    });
  },

  openLatestApprovalLevelsManageModal() {
    this.setState({
      showHistoryApprovalLevels: false,
      showManageApprovalLevelsModal: true,
    });
  },

  render() {
    const {
      title,
      history_versions,
      approval_levels,
      history_approval_levels,
      not_loading,
    } = this.props;
    const { history_open } = this.state;

    let modalMarkup;
    if (this.state.showManageApprovalLevelsModal && not_loading) {
      let data = approval_levels;
      if (this.state.showHistoryApprovalLevels) {
        data = history_approval_levels;
      }
      modalMarkup = (
        <AppValuesApprovalLevels
          dismissHandler={this.dismissManageApprovalLevelsModal}
          approval_levels={data}
          disabled={this.state.showHistoryApprovalLevels}
          approverHierarchyVersion={this.state.approvalHierarchyVersion}
        />
        // <ManageApprovalLevelsModal
        //   dismissHandler={this.dismissManageApprovalLevelsModal}
        //   approval_levels={data}
        //   disabled={this.state.showHistoryApprovalLevels}
        //   approverHierarchyVersion={this.state.approvalHierarchyVersion}
        // />
      );
    }

    let approval_flow_chart;
    let buttonText;
    if (this.props.approval_levels.length > 0) {
      approval_flow_chart = (
        <ApprovalFlowChart
          theme={'activate'}
          display_level={this.props.approval_levels.length}
          approval_levels={this.props.approval_levels}
        />
      );
      buttonText = 'Edit';
    } else {
      approval_flow_chart = (
        <ApprovalFlowChart
          theme={'inactivate'}
          display_level={3}
          approval_levels={[
            { credit_limit: 1000, minimum_approvers: 2 },
            { credit_limit: 5000, minimum_approvers: 2 },
            { credit_limit: 10000, minimum_approvers: 2 },
          ]}
        />
      );
      buttonText = 'Setup';
    }

    const history_section =
      history_versions.length > 0 ? (
        <div>
          <img
            onClick={this.handleCollapseToggle}
            src={require('images/icon_chevron_down-white.svg')}
            className={styles.collapse_arrow}
          />
          <Collapse in={history_open} className={styles.collapse_container}>
            {history_versions.map((record, ind) => (
              <div
                key={`approval_hierarchy_history_version_${ind}`}
                className={styles.history_item}
                onClick={this.openHistoryApprovalLevelsManageModal.bind(
                  null,
                  record.version
                )}
              >
                <div>
                  Version: {record.version}{' '}
                  {ind === 0 ? '(current version)' : ''}
                </div>
                <div>
                  Created At: {formatLocalTime(record.created_at, 'minute')}
                </div>
              </div>
            ))}
          </Collapse>
        </div>
      ) : (
        ''
      );

    return (
      <div className={styles.row}>
        <div className={styles.block_wide}>
          <div className={styles.liner}>
            <div className={styles.bottom_border}>
              <h2 className={styles.header}>{title}</h2>
              <div className={styles.button}>
                {isFeatureEditEnabled('1CAH') && (
                  <Button
                    text={buttonText}
                    handleClick={this.openLatestApprovalLevelsManageModal}
                  />
                )}
              </div>
            </div>
            <div className={styles.description}>{this.description()}</div>
            {approval_flow_chart}
            {history_section}
          </div>
          {modalMarkup}
        </div>
      </div>
    );
  },
});

const defaults = {
  title: 'Set application values & approval levels',
};

module.exports = connect((state, ownProps) => {
  return {
    approval_levels: state.manage_profile.current_entity_approval_levels || [],
    history_approval_levels: state.manage_profile.history_approval_levels || [],
    history_versions:
      state.manage_profile.current_entity_approval_hierarchy_history || [],
    not_loading: !state.manage_profile.history_approval_levels_loading || false,
    title: defaults.title,
    reviewerEnabled:
      state.manage_profile.current_entity.attributes.reviewer_enabled,
    hasReviewer: state.manage_profile.current_entity.attributes.hasReviewer,
  };
})(ManageApprovalLevels);
