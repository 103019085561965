import { formatMoney } from 'utils/formatting';

export function valueByKey(summary, key) {
  let entry = summary.filter((entry) => {
    return entry.summary_name === key;
  });

  return entry[0].summary_value;
}

export function countTypeValue(summary, key) {
  return formatMoney(parseInt(valueByKey(summary, key)), 0);
}

export function amountTypeValue(summary, key) {
  return '$'.concat(formatMoney(parseFloat(valueByKey(summary, key)), 2));
}
