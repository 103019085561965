/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { deepCopyArray } from '../../../utils/deepCopy';

/* Import actions */
import {
  loadTrmApplications,
  clearTrmApplications,
  clearTrm,
} from '../actions';

/* Import components */
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import AdminTitle from 'modules/shared/components/widgets/static/AdminTitle';
import TrmApplicationList from './TrmApplicationList';
import Switch from 'modules/shared/components/widgets/interactive/Switch';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';

import styles from './css/CommonApplicationsList.css';

class TrmApplicationWrapper extends React.Component {
  state = {
    current_page: 1,
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearTrm());
    dispatch(loadTrmApplications({ pending: true, page: 1 }));
  }

  applyAllFilter = () => {
    const { dispatch, location } = this.props;
    dispatch(
      loadTrmApplications({ pending: false, page: this.state.current_page })
    );
    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, { filter: 'all' }),
    });
  };

  applyPendingFilter = () => {
    const { dispatch, location } = this.props;
    dispatch(
      loadTrmApplications({ pending: true, page: this.state.current_page })
    );
    browserHistory.push({
      pathname: location.pathname,
      query: Object.assign(location.query, { filter: 'pending' }),
    });
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearTrmApplications());
  }

  changeCurrentPage = (value) => {
    const { dispatch, activeFilter } = this.props;
    dispatch(
      loadTrmApplications({ pending: activeFilter == 'pending', page: value })
    );
    this.setState({ current_page: value });
  };

  render() {
    const {
      title,
      loading,
      applications,
      dispatch,
      activeFilter,
      total_count,
      ...rest
    } = this.props;

    if (loading) {
      return <SimpleLoader />;
    }

    let filterButton = (
      <Switch
        leftOption={'pending'}
        rightOption={'all'}
        leftLabel={'pending'}
        rightLabel={'all'}
        current={activeFilter}
        targetLeft={this.applyPendingFilter}
        targetRight={this.applyAllFilter}
      />
    );

    let noApplicationsText;
    if (total_count < 1) {
      noApplicationsText = (
        <p>Congrats!! All trade reference checks have been completed!</p>
      );
    }

    return (
      <section className={styles.section}>
        <div className={styles.row}>
          <div className={styles.content}>
            <AdminTitle text={title}>
              <div className={styles.application_total}>{total_count}</div>
            </AdminTitle>

            <div className={styles.controls}>{filterButton}</div>

            <TrmApplicationList
              data={applications}
              activeFilter={activeFilter}
              {...rest}
            />
            {noApplicationsText}
            {this.props.children}
            <Pagination
              dataLength={total_count}
              currentPage={this.state.current_page}
              todosPerPage={25}
              optionViewPage={[{ label: '25', value: 25 }]}
              handleClick={this.changeCurrentPage}
              resourceName={'applications per page'}
            />
          </div>
        </div>
      </section>
    );
  }
}

const defaults = {
  title: 'Trade reference checks pending',
};

export default connect((state, ownProps) => {
  const list = [...state.trade_references.list] || [];
  const loading = state.trade_references.list_loading;
  const length = list.length;
  const activeFilter = ownProps.location.query.filter || 'pending';

  let new_list = deepCopyArray(list);

  if (activeFilter === 'pending') {
    if (list.length > 0) {
      new_list = new_list.filter((tr) => {
        return tr.attributes.pending;
      });
      new_list.forEach((l) => {
        const new_attrbutes = Object.assign({}, l.attributes);
        if (new_attrbutes.checks.length > 0) {
          let new_checks = deepCopyArray(new_attrbutes.checks);
          new_checks = new_checks.filter((check) => {
            return !check.archived;
          });
          new_attrbutes.checks = new_checks;
        }
        l.attributes = new_attrbutes;
      });
    }
  }

  return {
    title: defaults.title,
    loading: loading && length < 1,
    applications: activeFilter === 'pending' ? new_list : list,
    total_count: state.trade_references.meta.total_count,
    activeFilter,
  };
})(TrmApplicationWrapper);
