import {
  NameContent,
  PrivacyPolicyLink,
  SuffixContent,
} from 'modules/shared/components/v2/Footer/styles';
import React, { ReactElement } from 'react';

const Footer = (): ReactElement => (
  <div className="is-flex is-flex-direction-column">
    <div className="is-align-self-center">
      <span>Powered by</span> <NameContent>1Centre</NameContent>
      <SuffixContent>.com</SuffixContent>
    </div>
    <div className="is-align-self-center mt-2">
      <PrivacyPolicyLink
        href="https://www.1centre.com/Privacy/index.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy policy
      </PrivacyPolicyLink>
    </div>
  </div>
);

export default Footer;
