import React, { Component } from 'react';
import {
  verticalContainerCSS,
  verticalScrollbarCSS,
} from 'modules/shared/components/text/StandardTermsAndConditions';

import TermsScroller from 'modules/shared/components/text/TermsScroller';
import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class SupplierAcknowledgements extends Component {
  render() {
    const { scrollable, region } = this.props;

    let content = (
      <div>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>4.2</span> The Supplier
          acknowledges and agrees that if it chooses to use the generic Supplier
          Terms of Trade supplied by 1Centre as an option on the Platform (‘
          <strong>Generic Supply Terms</strong>’):
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>a.</span> the Generic Supply
          Terms are supplied by 1Centre for use by the Supplier in relation to
          the supply of goods and services to Business Customers who have
          submitted a trade application to the Supplier through the Platform
          only;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>b.</span> the Generic Supply
          Terms have not been prepared or tailored specifically for any
          particular business, and neither 1Centre or its legal advisors make
          any representation or warranty that the Generic Supply Terms are
          suitable or appropriate to govern all aspects of the relationship
          between the Business Customer and the Supplier. The provision of the
          Generic Supply Terms for the Supplier’s use does not constitute legal
          advice or professional advice;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>c.</span> to the extent the
          Supplier is supplying goods or services that are of a kind ordinarily
          acquired for personal, domestic or household use or consumption, the
          Generic Supply Terms may not necessarily be suitable for the
          Supplier’s business;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>d.</span> the Personal Property
          Security Act provisions won’t offer the intended protection to the
          Supplier unless PPSR registrations are completed correctly;
        </p>
        <p className={styles.list_type_2}>
          <span className={styles.copy_number}>e.</span> the licence to use the
          Generic Supply Terms does not include the right to amend them – for
          legal assistance with tailoring the Generic Supply Terms to the
          Supplier’s specific business needs, the Supplier can contact{' '}
          <a href="mailto:1Centre@Kensingtonswan.com">
            1Centre@Kensingtonswan.com
          </a>
        </p>
      </div>
    );

    if (region === 'AU') {
      content = (
        <div>
          <p className={styles.list_type_1}>
            <span className={styles.copy_number}>4.2</span> The Supplier
            acknowledges and agrees that if it chooses to use the generic
            Supplier Terms of Trade supplied by 1Centre as an option on the
            Platform (‘<strong>Generic Supply Terms</strong>’):
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>a.</span> the Generic Supply
            Terms are supplied by 1Centre for use by the Supplier in relation to
            the supply of goods and services to Business Customers who have
            submitted a trade application to the Supplier through the Platform
            only;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>b.</span> the Generic Supply
            Terms have not been prepared or tailored specifically for any
            particular business, and neither 1Centre or its legal advisors make
            any representation or warranty that the Generic Supply Terms are
            suitable or appropriate to govern all aspects of the relationship
            between the Business Customer and the Supplier. The provision of the
            Generic Supply Terms for the Supplier’s use does not constitute
            legal advice or professional advice;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>c.</span> to the extent the
            Supplier is supplying goods or services that are of a kind
            ordinarily acquired for personal, domestic or household use or
            consumption, the Generic Supply Terms may not necessarily be
            suitable for the Supplier’s business;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>d.</span> the Personal Property
            Securities Act 2009 (Cth) provisions will not offer the intended
            protection to the Supplier unless PPSR registrations are completed
            correctly;
          </p>
          <p className={styles.list_type_2}>
            <span className={styles.copy_number}>e.</span> the licence to use
            the Generic Supply Terms does not include the right to amend them.
          </p>
        </div>
      );
    }

    if (scrollable) {
      content = (
        <TermsScroller
          speed={0.8}
          className={styles.scroll_section}
          contentClassName="options"
          horizontal={false}
          verticalContainerStyle={verticalContainerCSS}
          verticalScrollbarStyle={verticalScrollbarCSS}
        >
          <div className={styles.copy}>{content}</div>
        </TermsScroller>
      );
    }

    return content;
  }
}
