import React, { Component } from 'react';

import PdfReader from 'modules/shared/components/widgets/static/pdfReader';
import isBlank from 'utils/isBlank';
import isPDF from 'utils/isPDF';
import loadImage from 'blueimp-load-image';
import styles from './css/ImageClick.css';

export default class ImageClick extends Component {
  constructor(props) {
    super(props);

    this.state = { orientedImage: null };
  }

  componentDidUpdate(prevProps) {
    const oldSrc = prevProps.src;
    const newSrc = this.props.src;

    if (oldSrc !== newSrc) {
      this.setState({ orientedImage: null });
      this.reorient();
    }
  }

  reorient() {
    const { src } = this.props;

    if (isBlank(src) || isPDF(src)) {
      return;
    }

    loadImage(
      src,
      (img) => {
        if (img.type === 'error') {
          this.setState({ orientedImage: null });
          console.error(`Error loading image: ${src}`);
        } else {
          this.setState({ orientedImage: img.toDataURL() });
        }
      },
      { crossOrigin: 'anonymous', orientation: true }
    );
  }

  renderContent() {
    const { src, width, height, handleClick } = this.props;
    const { orientedImage } = this.state;

    if (isPDF(src)) {
      return <PdfReader url={src} no_wrapper={true} onClick={handleClick} />;
    }

    return (
      <img
        className={styles.image}
        style={{ height: height || '200px', width: width || 'auto' }}
        src={orientedImage || src}
        onClick={handleClick}
        crossOrigin="anonymous"
      />
    );
  }

  render() {
    const { theme } = this.props;

    return <div className={styles[theme]}>{this.renderContent()}</div>;
  }
}
