import { Typography } from '@material-ui/core';
import Dialog from 'modules/shared/components/v2/Dialog';
import React from 'react';

type ConfirmClearProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmClear = (props: ConfirmClearProps) => {
  const { isOpen, onConfirm, onCancel } = props;

  return (
    <Dialog
      title="Confirm action"
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      cancelButtonProps={{ white: true }}
    >
      <Typography>Confirm that you would like to clear this field.</Typography>
    </Dialog>
  );
};
