/**
 * Pluralizes a noun based on the given count.
 *
 * @param {string} noun - The singular form of the noun.
 * @param {number} count - The count to determine whether the noun should be pluralized.
 * @param {string} [plural] - An optional parameter representing an alternative plural form of the noun.
 * @returns {string} The pluralized form of the noun.
 */
export const pluralizeNoun = (noun: string, count: number, plural?: string) => {
  /**
   * If the count is 1, return the singular form of the noun.
   * Otherwise, return the provided plural form or default to appending 's'.
   */
  return count === 1 ? noun : plural || `${noun}s`;
};
