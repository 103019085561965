import IconIdFail from 'images/svgs/icon-id-fail.svg';
import IconIdPass from 'images/svgs/icon-id-pass.svg';
import IconId from 'images/svgs/icon-id.svg';
import { IconWrapper } from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/IdentificationIcon/styles';
import React, { ReactElement } from 'react';

const getIcon = (identificationCheckStatus) => {
  if (identificationCheckStatus === 'Pass') {
    return IconIdPass;
  }

  if (identificationCheckStatus === 'Fail') {
    return IconIdFail;
  }

  return IconId;
};

const IdentificationIcon = ({
  canAccess,
  identificationCheckStatus,
  onIconClick,
}: {
  canAccess: boolean;
  identificationCheckStatus: 'Pass' | 'Fail' | null;
  onIconClick: () => void;
}): ReactElement => {
  const Icon = getIcon(identificationCheckStatus);

  return (
    <IconWrapper canAccess={canAccess}>
      <Icon onClick={onIconClick} />
    </IconWrapper>
  );
};

export default IdentificationIcon;
