import UserModel from 'models/UserModel';
import Payment from 'modules/payment/components/Payment';
import Button from 'modules/shared/components/inputs/Button';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';

import AdminTitle from '../../shared/components/widgets/static/AdminTitle';
import * as asActions from '../actions';
import BillingDetails from './BillingDetails';
import Bye from './Bye';
import styles from './css/AccountSettings.css';
import DeactivateAccount from './DeactivateAccount';
import LicenceInfo from './LicenceInfo';

function AccountSettings(props) {
  const { as, asActions, dispatch } = props;

  function _goHome() {
    asActions.set({ showFinishModal: false });
    browserHistory.push({
      pathname: '/dashboard/home',
    });
  }

  function _deactivateAccount() {
    dispatch(
      asActions.deactivateAccount(() => {
        asActions.set({
          showDeactivateModal: false,
          showFinishModal: true,
        });
      })
    );
  }

  const dismissHandler = () => asActions.set({ showDeactivateModal: false });

  return (
    <div className={styles.section}>
      <div className={styles.row}>
        <main className={styles.content}>
          <AdminTitle text="Account settings" />
          <LicenceInfo />
          <BillingDetails {...props} />
          <Payment />
          <DeactivateAccount
            isLoading={false}
            deactivate={() => asActions.set({ showDeactivateModal: true })}
          />
          {as.showDeactivateModal && (
            <CircleModal
              title="Are you sure you want to deactivate your entity account?"
              dismissHandler={dismissHandler}
            >
              <Button
                text="Yes"
                white
                style={{ width: '100px' }}
                handleClick={() => _deactivateAccount()}
              />
              <Button
                text="No"
                style={{ marginLeft: '10px', width: '100px' }}
                handleClick={dismissHandler}
              />
            </CircleModal>
          )}
          {as.showFinishModal && <Bye hide={() => _goHome()()} />}
        </main>
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => {
    return {
      as: state.account_settings,
      currentUser: UserModel.fromCurrentUser(state.current_user),
      entityId: state.current_user.current_entity.id,
    };
  },
  (dispatch) => {
    return {
      asActions: bindActionCreators(asActions, dispatch),
      dispatch,
    };
  }
)(AccountSettings);
