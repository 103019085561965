/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { EMAIL_REGEX } from 'constants';
/* Import functions */
import {
  getEmailTemplate,
  setEmailTemplateValue,
  updateEmailTemplate,
  previewEmail,
} from '../actions';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

/* Import components */
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import Button from 'modules/shared/components/inputs/Button';
import TextArea from 'modules/shared/components/inputs/TextArea';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import FileWidgetButton from 'modules/shared/components/widgets/interactive/FileWidgetButton';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import PreviewEmailModal from './PreviewEmailModal';
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';

import styles from './css/EmailTemplateModal.css';

const getNotificationsTooltip = (isMandatory) => {
  if (isMandatory) {
    return 'EmailNotificationsMandatory';
  }

  return 'EmailNotificationsToggle';
};

var EmailTemplateModal = createClass({
  getInitialState: function() {
    return {
      form_errors: {},
      showPreviewEmailModal: false,
      showConfirmModal: false,
      confirmModal: '',
    };
  },

  openPreviewEmailModal() {
    const { dispatch } = this.props;
    dispatch(previewEmail());
    this.setState({
      showPreviewEmailModal: true,
    });
  },

  dismissPreviewEmailModal() {
    this.setState({
      showPreviewEmailModal: false,
    });
  },

  dismissConfirmModal() {
    this.setState({
      showConfirmModal: false,
      confirmModal: '',
    });
  },

  turnOffNotifications() {
    const { dispatch } = this.props;
    this.dismissConfirmModal();
    dispatch(setEmailTemplateValue('notifications', false));
  },

  checkValid: function(target) {
    const { form_values, needFixedEmail } = this.props;
    const { form_errors } = this.state;
    switch (target.name) {
      case 'delay_time':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              entidelay_timety_name: 'You must enter delay time',
            },
          });
        } else {
          const { delay_time, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case 'subject':
        if (target.value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              subject: 'You must enter a subject',
            },
          });
        } else {
          const { subject, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case 'body':
        if (target.value.length < 1) {
          this.setState({
            form_errors: { ...form_errors, body: 'You must enter a body' },
          });
        } else {
          const { body, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        }
        break;
      case 'fixed_email':
        if (!needFixedEmail) {
          return;
        }
        if (target.value && EMAIL_REGEX.test(target.value)) {
          const { fixed_email, ...rest } = this.state.form_errors;
          this.setState({
            form_errors: { ...rest },
          });
        } else {
          this.setState({
            form_errors: {
              ...form_errors,
              fixed_email: 'You must enter a valid email',
            },
          });
        }
        break;
    }
  },

  setValue: function(event) {
    const { dispatch, data, delay_options, form_inputs } = this.props;
    var value = event.target.value;

    if (event.target.name == 'default') {
      value = !data.attributes.default;

      if (value) {
        dispatch(getEmailTemplate(data.attributes.template_no));
      } else {
        this.setState({
          form_errors: {},
          showConfirmModal: true,
          confirmModal: event.target.name,
        });
      }
    } else if (event.target.name == 'notifications') {
      value = !data.attributes.notifications;

      if (!value) {
        this.setState({
          showConfirmModal: true,
          confirmModal: event.target.name,
        });
        return;
      }
    } else if (event.target.name == 'is_delay') {
      value = !data.attributes.is_delay;
      if (!value) {
        dispatch(setEmailTemplateValue('delay_time', delay_options[0].value));
      }
    }
    dispatch(setEmailTemplateValue(event.target.name, value));

    if (event.target) {
      this.checkValid(event.target);
    }
  },

  handleBodyChange: function(event) {
    const { dispatch } = this.props;
    const {
      target: { name, value },
    } = event;
    dispatch(setEmailTemplateValue(name, value));
  },

  handleChange: function(event) {
    const { dispatch } = this.props;
    dispatch(setEmailTemplateValue(event.name, event.value));
    if (event) {
      this.checkValid(event);
    }
  },

  fileUploaded: function(data, filename) {
    const { dispatch } = this.props;
    dispatch(setEmailTemplateValue('attachment', data));
    dispatch(setEmailTemplateValue('filename', filename));
  },

  update: function() {
    const { dispatch, form_values, needFixedEmail } = this.props;

    //add mixpanel
    if (this.isComplete()) {
      dispatch(updateEmailTemplate());
      return;
    }

    // Moving the validation of body here rather than onChange because
    // of rerendering causing the cursor to scroll to the bottom when typing
    this.checkValid({
      name: 'body',
      value: form_values.body,
    });

    if (needFixedEmail) {
      this.checkValid({
        name: 'fixed_email',
        value: form_values.fixed_email,
      });
    }
  },

  isComplete: function() {
    const { form_values, needFixedEmail } = this.props;
    const needCheckEmail = needFixedEmail && form_values.notifications;

    if (
      form_values.delay_time !== '' &&
      form_values.subject !== '' &&
      form_values.body !== '' &&
      (!needCheckEmail ||
        (form_values.fixed_email && EMAIL_REGEX.test(form_values.fixed_email)))
    ) {
      return true;
    }
    return false;
  },

  downloadAttachment: function(url) {
    var win = window.open(url, 'attachment_window');
  },

  helpSection: function(field_name) {
    const { template_fields_options, form_values } = this.props;

    if (
      !template_fields_options ||
      (template_fields_options &&
        template_fields_options[field_name].length < 1)
    )
      return;

    var is_disabled =
      form_values.default ||
      !form_values.notifications ||
      !isFeatureEditEnabled('Email');

    return (
      <p className={styles.titleDesc}>
        {template_fields_options[field_name].map((option) => {
          return (
            <span>
              <Clickable
                inline={true}
                target={() =>
                  is_disabled ? {} : this.mergeField(field_name, option)
                }
              >
                <span
                  className={
                    is_disabled ? styles.option_disabled : styles.option
                  }
                >
                  {option}
                </span>
              </Clickable>
              ,{' '}
            </span>
          );
        })}
      </p>
    );
  },

  mergeField: function(field_name, option) {
    const { form_values } = this.props;
    var element = document.getElementById(field_name);
    var startPosition = element.selectionStart;

    var front = form_values[field_name].substring(0, startPosition);
    var back = form_values[field_name].substring(
      startPosition,
      form_values[field_name].length
    );
    var new_event = {
      target: { name: field_name, value: front + option + back },
    };
    this.setValue(new_event);
    element.focus();
  },

  render: function() {
    const {
      dismissHandler,
      data,
      needFixedEmail,
      form_inputs,
      form_values,
      delay_options,
      settings_email_template_loading,
      settings_email_template_updating,
      email_fields_options,
    } = this.props;
    const { form_errors } = this.state;

    const buttomMarkup = (
      <div className={styles.buttons}>
        <Button text="Preview" handleClick={this.openPreviewEmailModal} />
        <Button
          style={{ marginLeft: '10px' }}
          text="Save"
          handleClick={this.update}
          loading={
            settings_email_template_loading || settings_email_template_updating
          }
          loading_text={'saving'}
          disabled={!isFeatureEditEnabled('Email')}
          disableOnLoading={true}
        />
      </div>
    );

    var is_disabled =
      form_values.default ||
      !form_values.notifications ||
      !isFeatureEditEnabled('Email');
    var form_elements = [];
    var form_elements_checks = [];
    let { fixed_email, ...formInputsDup } = form_inputs;
    if (needFixedEmail) {
      formInputsDup = form_inputs;
    }

    Object.keys(formInputsDup).forEach((value) => {
      switch (value) {
        case 'default':
          form_elements_checks.push(
            <div className={styles.checkbox}>
              <SquareCheckbox
                checkboxId={value}
                checkboxName={value}
                checked={form_values[value]}
                handleChange={this.setValue}
                label={form_inputs[value].label}
                disabled={!isFeatureEditEnabled('Email')}
                tip_name={form_inputs[value].tip_name}
                tip_css_style="squarecheckbox"
              />
            </div>
          );
          break;
        case 'notifications':
          form_elements_checks.push(
            <div className={styles.checkbox}>
              <SquareCheckbox
                checkboxId={value}
                checkboxName={value}
                checked={form_values[value]}
                handleChange={this.setValue}
                label={form_inputs[value].label}
                disabled={
                  data.attributes.mandatory ||
                  form_values.default ||
                  !isFeatureEditEnabled('Email')
                }
                tip_name={getNotificationsTooltip(data.attributes.mandatory)}
                tip_css_style="squarecheckbox"
              />
            </div>
          );
          break;
        case 'is_delay':
          form_elements_checks.push(
            <div className={styles.checkbox}>
              <SquareCheckbox
                checkboxId={value}
                checkboxName={value}
                checked={form_values[value]}
                handleChange={this.setValue}
                label={form_inputs[value].label}
                disabled={is_disabled}
                tip_name={form_inputs[value].tip_name}
                tip_css_style="squarecheckbox"
              />
            </div>
          );
          break;
        case 'delay_time':
          if (!form_values.is_delay) break;
          form_elements.push(
            <div className={styles.row}>
              <div className={styles.left_fields}>
                <OptionsDropdown
                  id={value}
                  key={value}
                  handleChange={this.handleChange}
                  label={form_inputs[value].label}
                  value={form_values[value]}
                  error={form_errors[value]}
                  required={form_inputs[value].required}
                  options={delay_options}
                  disabled={is_disabled}
                />
              </div>
              <div className={styles.right_fields}></div>
            </div>
          );
          break;
        case 'body':
          form_elements.push(
            <div className={styles.row}>
              <div className={styles.left_fields}>
                <TextArea
                  id={value}
                  key={value}
                  handleChange={this.handleBodyChange}
                  label={form_inputs[value].label}
                  value={form_values[value]}
                  error={form_errors[value]}
                  required={form_inputs[value].required}
                  rows={value == 'body' ? 12 : 4}
                  disabled={is_disabled}
                  mentions={
                    form_inputs[value].help_section && email_fields_options
                  }
                />
              </div>
              <div className={styles.right_fields}>
                {form_inputs[value].help_section && this.helpSection(value)}
              </div>
            </div>
          );
          break;
        case 'signature':
          form_elements.push(
            <div className={styles.row}>
              <div className={styles.left_fields}>
                <TextArea
                  id={value}
                  key={value}
                  handleChange={this.setValue}
                  label={form_inputs[value].label}
                  value={form_values[value]}
                  error={form_errors[value]}
                  required={form_inputs[value].required}
                  rows={value == 'body' ? 12 : 4}
                  disabled={is_disabled}
                  mentions={
                    form_inputs[value].help_section && email_fields_options
                  }
                />
              </div>
              <div className={styles.right_fields}>
                {form_inputs[value].help_section && this.helpSection(value)}
              </div>
            </div>
          );
          break;
        case 'attachment':
          let has_attachment = !!form_values[value];
          let has_save_attachment =
            form_values[value] &&
            form_values[value].attachment &&
            form_values[value].attachment.url;
          let filename = form_values['filename'];
          if (has_save_attachment) {
            let attachment_url = form_values[value].attachment.url;
            filename = attachment_url.substring(
              attachment_url.lastIndexOf('/') + 1
            );
          }
          form_elements.push(
            <div className={styles.upload_file}>
              <FileWidgetButton
                text={
                  form_values['filename'] !== ''
                    ? 'edit attachment'
                    : 'upload attachment'
                }
                edit_text={'edit attachment'}
                handleFileCallback={this.fileUploaded}
                disabled={is_disabled}
              />
            </div>
          );
          if (form_values['filename'] !== '' && has_attachment) {
            form_elements.push(
              <div className={styles.attachment}>
                <span>{filename}</span>
                <CloseButton
                  text="Close"
                  css_class="clear_file_button"
                  handleClick={() => this.fileUploaded(null)}
                />
              </div>
            );
          }
          break;
        default:
          form_elements.push(
            <div className={styles.row}>
              <div className={styles.left_fields}>
                <TextArea
                  key={value}
                  id={value}
                  handleChange={this.setValue}
                  error={form_errors[value]}
                  label={form_inputs[value].label}
                  required={form_inputs[value].required}
                  value={form_values[value]}
                  disabled={is_disabled}
                  rows="1"
                  mentions={
                    form_inputs[value].help_section && email_fields_options
                  }
                />
              </div>
              <div className={styles.right_fields}>
                {form_inputs[value].help_section && this.helpSection(value)}
              </div>
            </div>
          );
          break;
      }
    });

    var modalMarkup;
    if (this.state.showPreviewEmailModal) {
      modalMarkup = (
        <PreviewEmailModal dismissHandler={this.dismissPreviewEmailModal} />
      );
    }

    if (this.state.showConfirmModal) {
      let confirm_contents;
      if (this.state.confirmModal == 'notifications') {
        confirm_contents = (
          <div>
            <p>{form_inputs[this.state.confirmModal].confirm_message}</p>
            <Button
              text={'Yes'}
              handleClick={this.turnOffNotifications}
              style={{ marginRight: '1em' }}
            />
            <Button text={'No'} handleClick={this.dismissConfirmModal} />
          </div>
        );
      } else {
        confirm_contents = (
          <div>
            <p>{form_inputs[this.state.confirmModal].confirm_message}</p>
            <Button text={'OK'} handleClick={this.dismissConfirmModal} />
          </div>
        );
      }

      modalMarkup = (
        <CircleModal dismissHandler={this.dismissConfirmModal}>
          {confirm_contents}
        </CircleModal>
      );
    }

    return (
      <div className={styles.modal}>
        <CloseButton
          text="Close"
          css_class={'mandatory_button_close'}
          handleClick={dismissHandler}
        />
        <div className={styles.fade}>
          <div className={styles.content}>
            <div className={styles.container}>
              <div className={styles.col}>
                <div className={styles.body}>
                  <h2 className={styles.title}>
                    {data.attributes.custom_name}
                  </h2>
                  <div className={styles.section}>
                    <div className={styles.row}>
                      <div className={styles.left_fields}>
                        {form_elements_checks}
                      </div>
                      <div className={styles.right_fields}>
                        Place cursor where you want merge field to go, then
                        click on the desired field.
                      </div>
                      {form_elements}
                    </div>
                  </div>
                </div>
                {buttomMarkup}
              </div>
            </div>
          </div>
        </div>
        {modalMarkup}
      </div>
    );
  },
});

/* eslint-disable sort-keys-fix/sort-keys-fix */
var defaults = {
  form_inputs: {
    default: {
      label: 'Default',
      required: true,
      help_section: false,
      tip_name: 'EmailDefaultToggle',
      confirm_message:
        'Unticking this option allows you to customise messaging for this event, including stopping notifications altogether, or allowing a time delay for when these messages are sent.',
    },
    notifications: {
      label: 'Active',
      required: true,
      help_section: false,
      tip_name: 'EmailNotificationsToggle',
      confirm_message:
        'By unticking notifications, emails will NOT be sent to your customers for this event. Please confirm you would like these emails to stop.',
    },
    is_delay: {
      label: 'Time delay',
      required: true,
      help_section: false,
      tip_name: 'EmailIsDelayToggle',
    },
    delay_time: {
      label: 'Delay Time',
      required: true,
      help_section: false,
    },
    fixed_email: {
      label: 'Centralized email address',
      required: true,
      help_section: false,
    },
    subject: {
      label: 'Subject',
      required: true,
      type: 'text',
      help_section: true,
    },
    greeting: {
      label: 'Greeting',
      required: false,
      type: 'text',
      help_section: true,
    },
    body: {
      label: 'Body',
      required: true,
      help_section: true,
    },
    signature: {
      label: 'Signature',
      required: false,
      help_section: true,
    },
    link: {
      label: 'Link',
      required: false,
      help_section: false,
    },
    link_text: {
      label: 'Name of link',
      required: false,
      help_section: false,
    },
    // video_url: {
    //   label: 'Embed video',
    //   required: false,
    //   type: 'text',
    //   help_section: false
    // },
    attachment: {
      label: 'Attachment',
      required: false,
      help_section: false,
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

module.exports = connect((state, ownProps) => {
  var data = state.manage_profile.settings_email_template || {};
  var delay_time_options = data.attributes.delay_time_options || {};
  var delay_options = [];
  Object.keys(delay_time_options).map((key) => {
    delay_options.push({
      label: key,
      value: key,
      id: 'delay_time',
    });
  });
  var email_fields_options = data.attributes.email_fields_options || [];
  var template_fields_options = (
    data.attributes.template_fields_options || []
  ).find((v) => {
    return v.template_no == data.attributes.template_no;
  });

  return {
    data: data,
    needFixedEmail: data.attributes.can_set_fixed_email,
    form_inputs: defaults.form_inputs,
    form_values: {
      default: data.attributes.default || false,
      is_delay: data.attributes.is_delay || false,
      delay_time: data.attributes.delay_time,
      notifications: data.attributes.notifications || false,
      subject: data.attributes.subject || '',
      greeting: data.attributes.greeting || '',
      body: data.attributes.body || '',
      link: data.attributes.link || '',
      link_text: data.attributes.link_text || '',
      attachment: data.attributes.attachment || null,
      video_url: data.attributes.video_url || '',
      signature: data.attributes.signature || '',
      filename: data.attributes.filename || '',
      fixed_email: data.attributes.fixed_email || '',
    },
    delay_options: delay_options,
    settings_email_template_loading:
      state.manage_profile.settings_email_template_loading,
    settings_email_template_updating:
      state.manage_profile.settings_email_template_updating,
    email_fields_options: email_fields_options,
    template_fields_options: template_fields_options,
  };
})(EmailTemplateModal);
