import React, { ReactElement } from 'react';
import { Header } from 'modules/request-permission-flow/components/Review/styles';

const PermissionDeclined = ({
  consumerName,
  requesterContactPhoneNumber,
  requesterEmail,
  requesterName,
  supplierName,
}: {
  consumerName: string;
  requesterContactPhoneNumber: string;
  requesterEmail: string;
  requesterName: string;
  supplierName: string;
}): ReactElement => (
  <>
    <Header>Permission declined</Header>
    <div className="content">
      <p>
        Thank you! The applicant {requesterName} ({requesterEmail},{' '}
        {requesterContactPhoneNumber}) won&apos;t be able to proceed with the
        credit application for {consumerName} with {supplierName}. If you have
        done this by mistake please get in touch with{' '}
        <a href="mailto:support@1centre.com">support@1centre.com</a>. If you
        suspect this to be a fraud attempt, please get in touch with{' '}
        {supplierName}.
      </p>
    </div>
  </>
);

export default PermissionDeclined;
