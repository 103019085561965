import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { Fragment, ReactElement } from 'react';
import {
  getAuthenticityOutcome,
  getAuthenticityScoreDetails,
} from '../utils/getAuthenticityOutcome';
import SignatureDetails from '../SignatureDetails';
import { getOtherTypeTooltipLabel } from '../utils/utils';
import SignatureHelpTooltip from '../SignatureTooltip';

const AuthenticityCheck = ({
  authenticityScore,
  followNewRule,
  isOtherIdentificationType,
}): ReactElement | null => {
  const currAuthScore = authenticityScore ? parseFloat(authenticityScore) : 0;
  const authenticityScoreDisplay = Math.trunc(currAuthScore * 100) / 100;

  const minPassScore = followNewRule ? 0.7 : 0.75;
  const currentAuthScoreLabel = isOtherIdentificationType
    ? 'N/A'
    : getAuthenticityOutcome(currAuthScore, minPassScore);

  const authentictyScoreColor = currAuthScore >= minPassScore ? 'green' : 'red';
  const outcomeColor = {
    color: isOtherIdentificationType ? 'black' : authentictyScoreColor,
  };
  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        <span className="underlined">{'Authenticity check:'}</span>
        <span style={outcomeColor}>&nbsp;{currentAuthScoreLabel}</span>
        <SignatureHelpTooltip noMaxWidth={!isOtherIdentificationType}>
          {isOtherIdentificationType ? (
            <span>{getOtherTypeTooltipLabel('authenticity')}</span>
          ) : (
            <Fragment>
              <span className={styles.nowrap}>
                {getAuthenticityScoreDetails(currAuthScore, minPassScore)}
              </span>
              <br />
              {followNewRule ? (
                <span className={styles.subtext}>
                  (0.7-1 Pass / 0.5-0.69 Warning / 0-0.49 Fail)
                </span>
              ) : (
                <span className={styles.subtext}>
                  (0.75-1 Pass/ 0.5-0.75 Warning / 0-0.5 Fail)
                </span>
              )}
            </Fragment>
          )}
        </SignatureHelpTooltip>
        {!isOtherIdentificationType && (
          <SignatureDetails
            label="Authenticity score"
            content={
              <span>{`${authenticityScoreDisplay}/1 (${currentAuthScoreLabel})`}</span>
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default AuthenticityCheck;
