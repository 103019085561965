import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { THEME_COLORS } from '../../../../variables/theme';
import Button from '../../../shared/components/inputs/Button';
import Message from '../Message';

export const ErrorState: React.FC<{ refresh: () => void }> = ({ refresh }) => (
  <Message
    faProps={{
      color: THEME_COLORS.danger,
      icon: ['fas', 'exclamation-triangle'],
      size: '4x',
    }}
    message="Ops! Something went wrong. Please refresh the widget."
    actions={
      <Button
        type="button"
        handleClick={refresh}
        text={
          <React.Fragment>
            <FontAwesomeIcon icon={['fas', 'sync']} /> Refresh
          </React.Fragment>
        }
      />
    }
  />
);
