/* Import components */
import CircleIconButton from 'modules/shared/components/widgets/interactive/CircleIconButton';
/* Import actions */
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React from 'react';
import { connect } from 'react-redux';

/* Import CSS */
import styles from './css/CreateLead.css';

// eslint-disable-next-line no-undef
function CreateLeadLegalType(props) {
  const { resetBusinessFields, setValue, selected } = props;

  function selectType(type) {
    if (type !== selected) {
      setValue('legal_type', type);
      resetBusinessFields();
    }
  }

  const buttonComponents = Object.keys(defaults.options).map((item, index) => {
    const currentButton = defaults.options[item];
    return (
      <CircleIconButton
        key={`lead-legal-type-${index}`}
        value={item}
        type={item}
        selected={selected === item}
        text={currentButton.title}
        icon={currentButton.icon}
        handleClick={selectType}
        small={!!isMobile()}
        tip_name={currentButton.title}
        isTextNormal
      />
    );
  });

  const optionsStyle = isMobile() ? styles.options_mobile : styles.options;

  return (
    <div className={styles.section}>
      <div className={styles.select_entity_header}>Select Entity Type</div>
      <div className={optionsStyle}>{buttonComponents}</div>
    </div>
  );
}

/* eslint-disable sort-keys-fix/sort-keys-fix */
const defaults = {
  options: {
    company: {
      icon: 'entity_x3plus',
      title: 'Company',
    },
    partnership: {
      icon: 'entity_x2',
      title: 'Partnership',
    },
    trust: {
      icon: 'entity_x3',
      title: 'Trust',
    },
    sole_trader: {
      icon: 'entity_x1',
      title: 'Sole trader',
    },
    other: {
      icon: 'entity_x3',
      title: 'Other',
    },
  },
  title: 'Select your business type',
}; /* eslint-enable sort-keys-fix/sort-keys-fix */

export default connect((state) => {
  return { newLead: state.leads.new_lead };
})(CreateLeadLegalType);
