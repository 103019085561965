import Modal from 'modules/shared/components/v2/Modal';
import React from 'react';

export default function AUNoticeModal(props) {
  const { message } = props;

  return (
    <Modal>
      <div className="has-text-centered">{message}</div>
    </Modal>
  );
}
