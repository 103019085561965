import React from 'react';
import styles from './report-styles.css';
import { capitalize, formatMoney } from 'utils/formatting';
import { formatDate } from 'utils/dateFormatter';

const createAccountsData = (accounts) => {
  let accountsSections = {};
  accounts.forEach((account) => {
    const accountsSectionKeys = Object.keys(accountsSections);
    const key = account.account_type;

    if (!accountsSectionKeys.includes(key)) {
      accountsSections[key] = [];
      accountsSections[key].push(account);
    } else {
      accountsSections[key].push(account);
    }
  });

  return accountsSections;
};

const getConsumerColumnName = (account) => {
  const { defaults, association_information } = account;
  const { additional_data_fields = {} } = defaults.default || {};
  const { is_serious_credit_infringement, default_association_start_date } =
    association_information || {};

  if (is_serious_credit_infringement) {
    return 'Serious credit infringement';
  }

  if (additional_data_fields.data_field) {
    return 'New arrangement';
  }

  return '';
};

const getConsumerColumnData = (account) => {
  const { defaults, association_information } = account;
  const { additional_data_fields = {} } = defaults.default || {};
  const { is_serious_credit_infringement, default_association_start_date } =
    association_information || {};

  if (is_serious_credit_infringement) {
    return `Yes (${formatDate(default_association_start_date, 'DD MMM YYYY')}`;
  }

  if (additional_data_fields.data_field) {
    const arrangementLabelMap = {
      'N': 'New Arrangement',
      'V': 'New Arrangement-Varied',
    };
    return arrangementLabelMap[additional_data_fields.data_field.value] || '-';
  }

  return '';
};

const DefaultsData = ({ account, type }) => {
  const {
    latest_credit_provider,
    original_credit_provider,
    account_id,
    defaults,
    association_information,
    account_type,
    relationship,
  } = account;
  const {
    status,
    original_default,
    current_default,
    status_date,
    cease_date,
    additional_data_fields = {},
  } = defaults.default || {};
  const { is_serious_credit_infringement } = association_information || {};
  const isConsumer = type === 'consumer';

  const consumerExtraColumn =
    is_serious_credit_infringement || additional_data_fields.data_field;
  const consumerColumnLabel = consumerExtraColumn
    ? getConsumerColumnName(account)
    : '';
  const consumerColumnData = consumerExtraColumn
    ? getConsumerColumnData(account)
    : '';

  const originalProvider = original_credit_provider.name;
  const latestProvider = latest_credit_provider.name;
  const showOriginalProvider = originalProvider !== latestProvider;
  const providerName = `${latestProvider} (${account_id})`;
  return (
    <div className="mt-4">
      <h3 className="mt-2">{providerName}</h3>
      {showOriginalProvider && (
        <p>
          <i>{`Original provider: ${originalProvider}`}</i>
        </p>
      )}

      <p className="mt-4">Payment defaults</p>
      <div className={styles.table + ' mt-2'}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_1}>Default status</div>
          <div className={styles.th + ' ' + styles.col_3}>
            Default association
          </div>
          <div
            className={styles.th + ' ' + styles[isConsumer ? 'col_3' : 'col_4']}
          >
            Original
          </div>
          <div
            className={
              styles.th +
              ' ' +
              styles[
                isConsumer ? (consumerColumnLabel ? 'col_3' : 'col_5') : 'col_4'
              ]
            }
          >
            Latest
          </div>
          {isConsumer && consumerColumnLabel && (
            <div className={styles.th + ' ' + styles.col_2}>
              {consumerColumnLabel}
            </div>
          )}
        </div>
        <div className={styles.row}>
          <div className={styles.col_1}></div>
          <div className={styles.col_3}>
            <div className={styles.th_underline + ' ' + styles.half_col}>
              Start date
            </div>
            <div className={styles.th_underline + ' ' + styles.half_col}>
              Cease date
            </div>
          </div>
          <div className={styles[isConsumer ? 'col_3' : 'col_4']}>
            <div
              className={
                styles.th_underline +
                ' ' +
                styles[isConsumer ? 'half_col' : 'col_4']
              }
            >
              Date
            </div>
            <div
              className={
                styles.th_underline +
                ' ' +
                styles[isConsumer ? 'half_col' : 'col_4']
              }
            >
              Amount
            </div>
            {!isConsumer && (
              <div className={styles.th_underline + ' ' + styles.col_4}>
                Reason to report
              </div>
            )}
          </div>
          <div className={styles[isConsumer ? 'col_3' : 'col_4']}>
            <div
              className={
                styles.th_underline +
                ' ' +
                styles[isConsumer ? 'half_col' : 'col_4']
              }
            >
              Date
            </div>
            <div
              className={
                styles.th_underline +
                ' ' +
                styles[isConsumer ? 'half_col' : 'col_4']
              }
            >
              Amount
            </div>
            {!isConsumer && (
              <div className={styles.th_underline + ' ' + styles.col_4}>
                Reason to report
              </div>
            )}
          </div>
          {isConsumer && <div className={styles.col_2}></div>}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tr}></div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_1}>{status}</div>
          <div className={styles.td + ' ' + styles.col_3}>
            <div className={styles.half_col}>
              {status_date ? formatDate(status_date, 'DD MMM YYYY') : '-'}
            </div>
            <div className={styles.half_col}>
              {cease_date ? formatDate(cease_date, 'DD MMM YYYY') : '-'}
            </div>
          </div>
          <div
            className={styles.td + ' ' + styles[isConsumer ? 'col_3' : 'col_4']}
          >
            <div className={styles[isConsumer ? 'half_col' : 'col_4']}>
              {original_default.date_recorded
                ? formatDate(original_default.date_recorded, 'DD MMM YYYY')
                : '-'}
            </div>
            <div className={styles[isConsumer ? 'half_col' : 'col_4']}>
              {original_default.default_amount < 0
                ? '-'
                : `$${formatMoney(
                    parseFloat(original_default.default_amount),
                    null,
                    null,
                    null,
                    null
                  )}`}
            </div>
            {!isConsumer && (
              <div className={styles.col_4}>
                {original_default.reason_to_report}
              </div>
            )}
          </div>
          <div
            className={styles.td + ' ' + styles[isConsumer ? 'col_3' : 'col_4']}
          >
            <div className={styles[isConsumer ? 'half_col' : 'col_4']}>
              {current_default.date_recorded
                ? formatDate(current_default.date_recorded, 'DD MMM YYYY')
                : '-'}
            </div>
            <div className={styles[isConsumer ? 'half_col' : 'col_4']}>
              {' '}
              {current_default.default_amount < 0
                ? '-'
                : `$${formatMoney(
                    parseFloat(current_default.default_amount),
                    null,
                    null,
                    null,
                    null
                  )}`}
            </div>
            {!isConsumer && (
              <div className={styles.col_4}>
                {current_default.reason_to_report}
              </div>
            )}
          </div>
          {isConsumer && (
            <div className={styles.col_2}>{consumerColumnData}</div>
          )}
        </div>
      </div>

      <br />
      <p className="mt-4">Other details</p>
      <div className={styles.table + ' mt-2'}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_1}>ID Source</div>
          <div
            className={styles.th + ' ' + styles[isConsumer ? 'col_3' : 'col_5']}
          >
            Default association
          </div>
          <div className={styles.th + ' ' + styles.col_3}>Account type</div>
          <div className={styles.th + ' ' + styles.col_3}>Relationship</div>
          {isConsumer && (
            <div className={styles.th + ' ' + styles.col_2}>
              Loan payment method
            </div>
          )}
        </div>
        <div className={styles.row}>
          <div className={styles.col_1}></div>
          <div className={styles[isConsumer ? 'col_3' : 'col_5']}>
            <div className={styles.th_underline + ' ' + styles.half_col}>
              Latest start date
            </div>
            <div className={styles.th_underline + ' ' + styles.half_col}>
              Latest cease date
            </div>
          </div>
          <div className={styles.col_3}></div>
          <div className={styles.col_3}></div>
          {isConsumer && <div className={styles.col_2}></div>}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.tr}></div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_1}>P</div>
          <div
            className={styles.td + ' ' + styles[isConsumer ? 'col_3' : 'col_5']}
          >
            <div className={styles.half_col}>{}</div>
            <div className={styles.half_col}>{}</div>
          </div>
          <div className={styles.td + ' ' + styles.col_3}>{account_type}</div>
          <div className={styles.td + ' ' + styles.col_3}>{relationship}</div>
          {isConsumer && <div className={styles.td + ' ' + styles.col_2}></div>}
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};

const ReportDefaultAccounts = ({ type, accounts }) => {
  // TODO: We need to have a mapping on which account types belongs to what section
  // Right now it's all displayed under consumer/commercial sections

  //const accountsData = createAccountsData(accounts);
  //const sections = Object.keys(accountsData) || [];

  return (
    <div>
      <label className="has-text-danger has-text-weight-semibold">
        {`${capitalize(type)} account`}
      </label>
      {accounts.length < 1 ? (
        <p>Not present on file</p>
      ) : (
        <div className={'mt-4'}>
          {accounts.map((account) => {
            return <DefaultsData account={account} type={type} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ReportDefaultAccounts;
