import api from 'api';
import get from 'lodash.get';
import AuthorisationRequestRequesteeModel from 'models/AuthorisationRequestRequesteeModel';

import BaseModel from './BaseModel';

export default class AuthorisationRequestAwaitingPermissionModel extends BaseModel {
  static fetchRecord = async (
    token: string
  ): Promise<AuthorisationRequestAwaitingPermissionModel> => {
    const awaitingPermissionsAPI = api('awaiting_permissions');

    try {
      const result = await awaitingPermissionsAPI.fetchAwaitingPermission(
        token
      );

      return new AuthorisationRequestAwaitingPermissionModel(
        get(result, 'data.data')
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  static saveDecision = async ({
    status,
    token,
  }: {
    status: string;
    token: string;
  }): Promise<string> => {
    const awaitingPermissionsAPI = api('awaiting_permissions');

    try {
      const result = await awaitingPermissionsAPI.saveDecision({
        status,
        token,
      });

      return get(result, 'data.code');
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  consumerName: string;

  requesterContactPhoneNumber: string;

  requesterEmail: string;

  requesterFirstName: string;

  requesterLastName: string;

  supplierKeyContactName: string;

  supplierName: string;

  requesteeFullName: string;

  requesteeEmail: string;

  requesteeId: string;

  applicationId: string;

  status: 'created' | 'request_sent' | 'approved' | 'rejected';

  applicationInaccessibleReason: string;

  get requesterName(): string {
    return `${this.requesterFirstName} ${this.requesterLastName}`.trim();
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  assignRelationships() {
    /** One-to-one relationship */
    // @ts-ignore-next-line
    this.assignSingleRelationship({
      key: 'requestee',
      model: AuthorisationRequestRequesteeModel,
    });
  }
}
