import React, { ReactElement, useState } from 'react';
import {
  GridContainer,
  GridSidebar,
  GridContent,
} from 'modules/webhooks/v2/components/V2Documentation/styes';
import Navigation from 'modules/webhooks/v2/components/V2Documentation/Navigation';
import Content from 'modules/webhooks/v2/components/V2Documentation/Content';

const V2Documentation = (): ReactElement => {
  const [activeSection, setActiveSection] = useState('introduction');

  return (
    <GridContainer>
      <GridSidebar>
        <Navigation
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </GridSidebar>
      <GridContent className="content">
        <Content activeSection={activeSection} />
      </GridContent>
    </GridContainer>
  );
};

export default V2Documentation;
