import React from 'react';
import { connect } from 'react-redux';

/* Import constants */
import { EMAIL_REGEX } from 'constants';

/* Import CSS */
import styles from './css/Review.css';

/* Import components */
import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import CircleLogo from 'modules/shared/components/widgets/static/CircleLogo';
import DateString from 'modules/shared/components/widgets/static/DateString';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import TextInput from 'modules/shared/components/inputs/TextInput';
import CountryCodeDropdown from 'modules/shared/components/widgets/static/CountryCodeDropdown';

import ReviewGuarantors from './ReviewGuarantors';
import ReviewTradeReferenceForm from './ReviewTradeReferenceForm';

/* Import actions */
import { setRefereeData } from '../../actions/trade-reference';
import { setGuarantorFormItem } from '../../actions/guarantors';

import { reviewComponentComplete } from '../../actions/review';

var ReviewTradeReferences = createClass({
  componentWillReceiveProps: function(nextProps) {
    const { dispatch, review_complete } = this.props;
    let complete = this.isComplete(
      nextProps.data,
      nextProps.application,
      nextProps.people,
      nextProps.guarantorList
    );
    if (review_complete.checks !== complete) {
      dispatch(reviewComponentComplete('checks', complete));
    }

    if (nextProps.summary_validation_start) {
      this.checkValidAll();
    }
  },

  checkValidAll: function() {
    const {
      data,
      setPageValidationStartFinish,
      application,
      people,
      guarantorList,
      entity_type,
      entity_region,
    } = this.props;
    let { errors } = this.state;
    let error = errors;

    var minimum_guarantees = 0;
    var dropdown_values = [];

    if (application) {
      if (
        application.attributes.requires_guarantees &&
        entity_type === 'company'
      ) {
        minimum_guarantees = application.attributes.minimum_guarantees;

        if (guarantorList.length < minimum_guarantees) {
          minimum_guarantees = guarantorList.length;
        }

        if (guarantorList.length > 0) {
          guarantorList.forEach((person, index) => {
            let name = person.name;
            let label = name;

            if (entity_type == 'company') {
              if (entity_region === 'NZ') {
                label =
                  label +
                  ' (' +
                  person.position +
                  ', ' +
                  person.percentage_share +
                  '%)';
              } else {
                label = label + ' (Director)';
              }
            }
            dropdown_values.push({
              id: 'name',
              label: label,
              value: name,
            });
          });
        }
      }
    }

    data.referees.forEach((v, i) => {
      setTimeout(() => {
        [
          'first_name',
          'last_name',
          'business_name',
          'contact_phone',
          'contact_country_code',
          'email',
        ].forEach((value, index) => {
          let target = {
            id: value,
            value: data.referees[i][value],
          };
          if (value === 'email') {
            this.refs[`reference-${i}`].refs.email._onBlur();
          } else {
            this.checkFormErrors(i, target);
          }
        });
      }, 500);
    });

    data.form_values.forEach((v, i) => {
      this.refs[`guarantor-${i}`].refs.email._onBlur();
      if (!dropdown_values.some((x) => x.value === data.form_values[i].name)) {
        error[i] = { ...error[i], name: 'Please select the guarantor.' };
        this.setState({
          errors: error,
        });
      }
    });

    setPageValidationStartFinish();
  },

  isComplete: function(data, application, people, guarantorList) {
    const { form_errors, errors } = this.state;

    if (!data) {
      var { data } = this.props;
    }
    if (!application) {
      var { application } = this.props;
    }
    if (!people) {
      var { people } = this.props;
    }
    if (!guarantorList) {
      var { guarantorList } = this.props;
    }

    var completed_count = 0;
    var completed_guarantor_count = 0;

    var minimum_guarantees = 0;
    var dropdown_values = [];
    var re = EMAIL_REGEX;

    if (application) {
      if (this.props.entity_type === 'company') {
        minimum_guarantees = application.attributes.minimum_guarantees;
        if (guarantorList.length < minimum_guarantees) {
          minimum_guarantees = guarantorList.length;
        }

        if (guarantorList.length > 0) {
          guarantorList.forEach((person, index) => {
            const name = person.name;
            const first_name = person.first_name;
            const last_name = person.last_name;
            const middle_name = person.middle_name;
            let label = name;
            let percentage_share = null,
              position = null;

            if (this.props.entity_type == 'company') {
              if (this.props.entity_region === 'NZ') {
                position = person.position ? person.position : 'Director';
                percentage_share = person.percentage_share
                  ? person.percentage_share
                  : null;
                label =
                  label +
                  ' (' +
                  position +
                  (percentage_share ? ', ' + percentage_share + '%' : '') +
                  ')';
              } else {
                label = label + ' (Director)';
              }
            }

            dropdown_values.push({
              first_name,
              id: 'name',
              label,
              last_name,
              middle_name,
              value: name,
              percentage_share,
              position,
            });
          });
        }

        for (var i = 0; i < minimum_guarantees; i++) {
          let error = errors[i];
          if (
            (error.hasOwnProperty('email') && error.email) ||
            !data.form_values[i].email ||
            !re.test(data.form_values[i].email)
          )
            return false;

          let name = data.form_values[i].name;
          if (
            dropdown_values.find((item) => item.value == name) !== undefined
          ) {
            completed_guarantor_count++;
          }
        }
      }
    }

    for (var i = 0; i < data.referees.length; i++) {
      let error = form_errors[i];
      if (
        (error.hasOwnProperty('email') && error.email) ||
        (data.referees[i].email &&
          data.referees[i].email.length > 0 &&
          !re.test(data.referees[i].email))
      )
        return false;
    }

    data.referees.forEach((item) => {
      if (item.business_name && item.contact_phone && item.first_name) {
        completed_count++;
      }
    });

    if (
      completed_count === data.referees.length &&
      completed_guarantor_count === minimum_guarantees
    ) {
      return true;
    }
    return false;
  },

  componentDidMount: function() {
    const { dispatch, data } = this.props;
    dispatch(reviewComponentComplete('checks', this.isComplete()));
  },

  handleChange: function(index, event) {
    const { dispatch } = this.props;
    if (event.target) {
      this.checkFormErrors(index, event.target);
    }
    dispatch(setRefereeData(index, event.target.name, event.target.value));
  },

  handleBlur: function(index, event) {
    this.checkFormErrors(index, event.target);
  },

  countryCodeHandleChange(index, value) {
    const { dispatch } = this.props;
    dispatch(setRefereeData(index, 'contact_country_code', value));
  },

  tradeReferenceEmailChangeHandler(index, value, isValid) {
    const { dispatch } = this.props;
    const { form_errors } = this.state;

    let new_errors = form_errors;

    dispatch(setRefereeData(index, 'email', value));

    if (isValid) {
      const { email, ...rest } = form_errors[index];
      new_errors[index] = { ...rest };
    } else {
      new_errors[index] = { ...new_errors[index], email: true };
    }

    this.setState({
      form_errors: new_errors,
    });
  },

  checkFormErrors: function(index, target) {
    const { form_errors, error_messages } = this.state;

    switch (target.id) {
      case 'first_name':
        var new_form_errors = form_errors;
        if (target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          const { [target.id]: error, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
          this.setState({
            form_errors: new_form_errors,
          });
        }
        break;
      case 'business_name':
        var new_form_errors = form_errors;
        if (target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          const { [target.id]: error, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
          this.setState({
            form_errors: new_form_errors,
          });
        }
        break;
      case 'contact_phone':
        var new_form_errors = form_errors;
        if (target.value.length < 1) {
          new_form_errors[index] = {
            ...new_form_errors[index],
            [target.id]: error_messages[target.id],
          };
          this.setState({
            form_errors: new_form_errors,
          });
        } else {
          const { [target.id]: error, ...rest } = form_errors[index];
          new_form_errors[index] = { ...rest };
          this.setState({
            form_errors: new_form_errors,
          });
        }
        break;
    }
  },

  guarantorNameChangeHandler(
    index,
    value,
    percentage_share,
    position,
    first_name,
    last_name,
    middle_name
  ) {
    const { dispatch, guarantorList } = this.props;
    const { errors } = this.state;
    let error = errors;

    error[index] = { ...error[index], name: '' };
    this.setState({
      errors: error,
    });

    dispatch(setGuarantorFormItem(index, 'name', value));
    dispatch(setGuarantorFormItem(index, 'first_name', first_name));
    dispatch(setGuarantorFormItem(index, 'last_name', last_name));
    dispatch(setGuarantorFormItem(index, 'middle_name', middle_name));
    dispatch(setGuarantorFormItem(index, 'percentage_share', percentage_share));
    dispatch(setGuarantorFormItem(index, 'position', position));

    guarantorList.forEach((person) => {
      let name = person.name;
      if (name === value && person.email) {
        dispatch(setGuarantorFormItem(index, 'email', person.email));
      }
    });

    setTimeout(() => {
      this.refs[`guarantor-${index}`].refs.email._onBlur();
    }, 300);
  },

  guarantorEmailChangeHandler(index, value, isValid) {
    const { dispatch } = this.props;
    const { errors } = this.state;

    let new_errors = errors;

    dispatch(setGuarantorFormItem(index, 'email', value));

    if (isValid) {
      const { email, ...rest } = errors[index];
      new_errors[index] = { ...rest };
    } else {
      new_errors[index] = { ...new_errors[index], email: true };
    }

    this.setState({
      errors: new_errors,
    });
  },

  getInitialState: function() {
    var { data } = this.props;
    var form_errors = [];
    var errors = [];

    for (var i = 0; i < data.referees.length; i++) {
      form_errors.push({
        first_name: '',
        contact_phone: '',
        business_name: '',
      });
    }

    for (var i = 0; i < data.form_values.length; i++) {
      errors.push({ name: '', email: false });
    }

    return {
      form_errors: form_errors,
      errors: errors,
      error_messages: {
        first_name: 'You need to enter a first name.',
        contact_phone: 'You need to enter a phone number.',
        business_name: 'You need to enter a business name.',
        email: 'Please enter a valid email address.',
      },
    };
  },

  render: function() {
    const {
      data,
      application,
      people,
      guarantorList,
      entity_type,
      entity_region,
    } = this.props;
    const { form_errors, errors } = this.state;
    var referees = [];
    var guarantors = [];
    var minimum_guarantees = 0;
    var minimum_trade_references = 0;
    var dropdown_values = [];
    var form_values = [];
    if (application) {
      if (entity_type !== 'personal')
        minimum_trade_references =
          application.attributes.minimum_trade_references;
      if (
        application.attributes.requires_guarantees &&
        entity_type === 'company'
      ) {
        minimum_guarantees = application.attributes.minimum_guarantees;

        if (guarantorList.length < minimum_guarantees) {
          minimum_guarantees = guarantorList.length;
        }

        if (guarantorList.length > 0) {
          guarantorList.forEach((person, index) => {
            const name = person.name;
            const first_name = person.first_name;
            const last_name = person.last_name;
            const middle_name = person.middle_name;
            let label = name;
            let percentage_share = null,
              position = null;

            if (entity_type == 'company') {
              if (entity_region === 'NZ') {
                position = person.position ? person.position : 'Director';
                percentage_share = person.percentage_share
                  ? person.percentage_share
                  : null;
                label =
                  label +
                  ' (' +
                  position +
                  (percentage_share ? ', ' + percentage_share + '%' : '') +
                  ')';
              } else {
                label = label + ' (Director)';
              }
            }

            dropdown_values.push({
              first_name,
              id: 'name',
              label,
              last_name,
              middle_name,
              value: name,
              percentage_share,
              position,
            });
          });
        }

        if (data.form_values.length > 0) {
          form_values = data.form_values;
        } else {
          for (var i = 0; i < minimum_guarantees; i++) {
            form_values.push(guarantorList[i]);
          }
        }
      }
    }

    for (var index = 0; index < minimum_trade_references; index++) {
      referees.push(
        <ReviewTradeReferenceForm
          key={index}
          index={index}
          ref={`reference-` + index}
          form_error={form_errors[index]}
          first_name={data.referees[index].first_name}
          last_name={data.referees[index].last_name}
          contact_country_code={data.referees[index].contact_country_code}
          contact_phone={data.referees[index].contact_phone}
          business_name={data.referees[index].business_name}
          email={data.referees[index].email}
          onChangeHandler={this.handleChange}
          onBlurHandler={this.handleBlur}
          onCountryCodeChangeHandler={this.countryCodeHandleChange}
          onEmailChangeHandler={this.tradeReferenceEmailChangeHandler}
        />
      );
    }

    if (minimum_guarantees > 0) {
      for (var i = 0; i < minimum_guarantees; i++) {
        let selected = [];
        let sort_dropdown = dropdown_values;
        let current_name = form_values[i].name;
        let current_percentage_share = form_values[i].percentage_share;
        let current_position = form_values[i].position;
        let is_applicant = form_values[i].is_applicant;
        let current_first_name = form_values[i].first_name;
        let current_last_name = form_values[i].last_name;
        let current_middle_name = form_values[i].middle_name;

        form_values.forEach((item, index) => {
          let name = item.name;
          if (name !== current_name) {
            selected.push({
              id: 'name',
              label: name,
              value: name,
            });
          }
        });

        var css_class = 'block_noborder';

        sort_dropdown = sort_dropdown.filter(
          (x) => !selected.some((y) => y.value == x.value)
        );
        guarantors.push(
          <ReviewGuarantors
            key={i}
            index={i}
            ref={`guarantor-` + i}
            error={errors}
            css_class={css_class}
            sort_dropdown={sort_dropdown}
            name={current_name}
            email={form_values[i].email}
            onChangeNameSelection={this.guarantorNameChangeHandler}
            onChangeEmail={this.guarantorEmailChangeHandler}
            percentage_share={current_percentage_share}
            position={current_position}
            is_applicant={is_applicant}
            first_name={current_first_name}
            last_name={current_last_name}
            middle_name={current_middle_name}
          />
        );
      }
    }

    return (
      <section className={styles.expandable}>
        <span className={styles.shadow} />
        {referees}
        {guarantors}
      </section>
    );
  },
});

module.exports = connect((state, ownProps) => {
  return {
    entity_type: state.cob_business.entity_type,
    entity_region: state.cob_business.entity_region,
  };
})(ReviewTradeReferences);
