import get from 'lodash.get';
import { ApplicationType } from 'modules/shared/types/application';
import store from 'stores/store';

const getAccountTypeName = (accountType: ApplicationType): string => {
  const currentEntityAttributes = get(
    store.getState(),
    'current_user.current_entity.attributes',
    {}
  );

  switch (accountType) {
    case 'credit':
      return currentEntityAttributes.credit_application_type_name || 'Credit';
    case 'cash':
      return currentEntityAttributes.cash_application_type_name || 'Cash';
    case 'leads':
      return currentEntityAttributes.lead_name || 'Lead';
    default:
      return '';
  }
};

export default getAccountTypeName;
