/* Import libraries */
import React, { Component } from 'react';
import PaperlessDetails from '../paperless/PaperlessDetails';
import { connect } from 'react-redux';

import { reviewComponentComplete } from '../../actions/review';
import styles from './css/Review.css';

const moduleName = 'paperless';

const ReviewPaperless = (props) => {
  const { dispatch, review_complete } = props;

  const updateReviewComponentComplete = (complete) => {
    if (review_complete[moduleName] !== complete) {
      dispatch(reviewComponentComplete(moduleName, complete));
    }
  };

  return (
    <section className={styles.expandable}>
      <span className={styles.shadow} />
      <PaperlessDetails
        {...props}
        reviewMode={true}
        handleComplete={updateReviewComponentComplete}
      />
    </section>
  );
};

export default connect()(ReviewPaperless);
