import api from '../../api';
import { calculateMonthlyCost } from 'utils/subscriptionsCalculate';
import { SET, SET_CARD, RESET } from './constants';

export function set(payload) {
  return {
    type: SET,
    payload,
  };
}
export function setCard(payload) {
  return {
    type: SET_CARD,
    payload,
  };
}
export function reset() {
  return {
    type: RESET,
  };
}

export function getUsers() {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { usersLoading: true },
    });
    const entities = api(
        'entities',
        getState().current_user.access_token,
        getState().current_user.current_entity.id
      ),
      entityId = getState().current_user.current_entity.id;

    entities.getEntityUsers(
      entityId,
      (result) => {
        const users = result.data.data || [];
        const num_users = result.data.meta.record_count;
        dispatch({
          type: SET,
          payload: {
            users,
            numUsers: num_users,
            totalCost: calculateMonthlyCost(num_users),
            usersLoading: false,
          },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { userError: error },
        });
      }
    );
  };
}
export function getSubscription() {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { subscriptionLoading: true },
    });
    const entities = api(
      'entities',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    entities.getEntitySubscriptions(
      entityId,
      (result) => {
        const subscriptions = result.data.data;
        const subscription =
          (subscriptions && subscriptions.length && subscriptions[0]) || null;
        dispatch({
          type: SET,
          payload: { subscriptionLoading: false, subscription },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { subscriptionLoading: false },
        });
      }
    );
  };
}
export function getCard() {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { cardLoading: true },
    });
    const entities = api(
      'entities',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    entities.getEntityCards(
      entityId,
      (result) => {
        dispatch({
          type: SET,
          payload: { cardLoading: false, cards: result.data.data },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: {
            cardLoading: false,
            cardError: error,
            errorText: 'Failed to get your credit card info',
          },
        });
      }
    );
  };
}
export function addCard(attributes, entity_id, callback) {
  return (dispatch, getState) => {
    dispatch({
      type: SET,
      payload: { cardLoading: true },
    });
    if (!entity_id) {
      entity_id = getState().current_user.current_entity.id;
    }
    const cards = api('cards', getState().current_user.access_token, entity_id);
    cards.createCard(
      attributes,
      (result) => {
        dispatch({
          type: SET,
          payload: {
            cardLoading: false,
            updateCard: false,
            cards: [result.data.data],
            showSuccessCard: true,
          },
          meta: {
            mixpanel: {
              event: 'Add credit card',
              props: {
                distinct_id: getState().current_user.data.data.id,
                'Entity ID': entity_id,
              },
            },
          },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: {
            cardLoading: false,
            cardError: error,
            errorText: 'Failed to add your card',
          },
        });
      }
    );
  };
}
export function updateCard(id, attributes, callback) {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { cardLoading: true },
    });
    const cards = api(
      'cards',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    cards.updateCard(
      id,
      attributes,
      (result) => {
        dispatch({
          type: SET,
          payload: {
            cardLoading: false,
            updateCard: false,
            cards: [result.data.data],
            showSuccessCard: true,
          },
          meta: {
            mixpanel: {
              event: 'Update credit card',
              props: {
                distinct_id: getState().current_user.data.data.id,
                'Entity ID': getState().current_user.current_entity.id,
              },
            },
          },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: {
            cardLoading: false,
            cardError: error,
            errorText: 'Failed to updated your card',
          },
        });
      }
    );
  };
}
export function getTransactions() {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { transactionsLoading: true },
    });
    const entities = api(
      'entities',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    entities.getEntityTransactions(
      entityId,
      (result) => {
        const data = result.data.data;
        dispatch({
          type: SET,
          payload: { transactions: data, transactionsLoading: false },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { transactionsError: error },
        });
      }
    );
  };
}
export function getInvoices() {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { invoicesLoading: true },
    });
    const transactions = api(
      'transactions',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    transactions.getTransactions(
      (result) => {
        const invoices = result.data.invoices.data || [];
        dispatch({
          type: SET,
          payload: { invoices, invoicesLoading: false },
        });
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { invoiceError: error, invoicesLoading: false },
        });
      }
    );
  };
}
export function getInvoice(invoice_id, callback) {
  return (dispatch, getState) => {
    const entityId = getState().current_user.current_entity.id;
    dispatch({
      type: SET,
      payload: { invoiceLoading: true },
      meta: {
        mixpanel: {
          event: 'View invoice',
          props: {
            distinct_id: getState().current_user.data.data.id,
            Invoice: invoice_id,
            'Entity ID': getState().current_user.current_entity.id,
          },
        },
      },
    });
    const transactions = api(
      'transactions',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    transactions.getInvoice(
      invoice_id,
      (result) => {
        const invoice = result.data.invoice;
        dispatch({
          type: SET,
          payload: { invoice, invoiceLoading: false },
        });
        callback(invoice);
      },
      (error) => {
        dispatch({
          type: SET,
          payload: { invoiceError: error, invoiceLoading: false },
        });
      }
    );
  };
}
