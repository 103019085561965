import React, { ChangeEvent } from 'react';
import { Typography } from '@material-ui/core';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';

interface FormFieldSectionProps {
  hasPausedMessage?: boolean;
  label?: React.ReactNode;
  id: string;
  inputClassName?: string;
  children?: React.ReactElement;
  checkboxProps?: {
    isChecked: boolean;
    isDisabled?: boolean;
    onCheck: (isChecked: boolean) => void;
  };
}

const FormFieldSection: React.FC<FormFieldSectionProps> = (props) => {
  const {
    hasPausedMessage,
    id,
    label,
    children,
    inputClassName,
    checkboxProps,
  } = props;
  const { isChecked, isDisabled, onCheck } = checkboxProps || {};
  const hasCheckBox = Boolean(checkboxProps);

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (onCheck) {
      onCheck(checked);
    }
  };

  return (
    <>
      {hasCheckBox ? (
        <div>
          <SquareCheckbox
            label={label}
            checkboxId={`enable-${id}`}
            checked={isChecked}
            disabled={isDisabled}
            handleChange={handleCheck}
            no_float
          />
        </div>
      ) : label ? (
        <>
          {hasPausedMessage && (
            <Typography color="error">
              This rule is paused according to your recent changes in settings,
              please reconfigure this rule to enable the ruleset.
            </Typography>
          )}

          <label className="pb-4" htmlFor={id}>
            {label}
          </label>
        </>
      ) : null}

      <div className="columns">
        <div className={`column is-6 ${inputClassName ? inputClassName : ''}`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default FormFieldSection;
