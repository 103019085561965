import React, { Fragment } from 'react';
import BusinessInformation from './BusinessInformation';
import Attachments from './Attachments';
import Addresses from './Addresses';
import People from './People';

export default function BusinessDetails(props) {
  const { application, currentUser, onFetchApplicationRecord } = props;
  const { isBulkApplication } = application || {};

  return (
    <Fragment>
      <BusinessInformation application={application} />
      {!isBulkApplication && <Addresses application={application} />}
      <People application={application} />
      {!isBulkApplication && (
        <Attachments
          application={application}
          currentUser={currentUser}
          onFetchApplicationRecord={onFetchApplicationRecord}
        />
      )}
    </Fragment>
  );
}
