import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import { RenderInputParams } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { FEATURE_FLAGS } from 'conf';

const useInputStyles = makeStyles({
  root: {
    background: '#fff',
    '&:hover $notchedOutline': {
      borderColor: 'var(--main-color)',
    },
    '&$focused $notchedOutline': {
      borderColor: 'var(--main-color)',
    },
    '&:active $notchedOutline': {
      borderColor: 'var(--main-color)',
    },
  },
  notchedOutline: {
    border: '2px solid var(--main-color)',
  },
  focused: {
    background: '#fff',
    '&:hover $notchedOutline': {
      borderColor: 'var(--main-color)',
    },
    '&$focused $notchedOutline': {
      borderColor: 'var(--main-color)',
    },
    '&:active $notchedOutline': {
      borderColor: 'var(--main-color)',
    },
  },
});

const useInputLabelStyles = makeStyles({
  notchedOutline: {
    color: '#000 !important',
  },
  root: {
    color: '#000 !important',
  },
  focused: {
    color: 'var(--main-color) !important',
  },
});

const useInputStyles_old = makeStyles({
  root: { background: '#fff' },
  notchedOutline: {
    border: '2px solid var(--main-color)',
  },
});

const useInputLabelStyles_old = makeStyles({
  root: {
    color: '#000',
  },
});

const useFormHelperStyles = makeStyles({
  root: {
    fontSize: 12,
    letterSpacing: 'normal',
    marginLeft: 0,
  },
});

type InputProps = {
  error?: boolean;
  helperText?: string;
  label: string;
  labelShrink: boolean;
  renderInputParams: RenderInputParams;
  required?: boolean;
};

export const Input: FC<InputProps> = (props) => {
  const iufFixEnabled = FEATURE_FLAGS.FEATURE_FLAG_IUF_PERFORMANCE_FIX;
  const inputClasses = iufFixEnabled ? useInputStyles() : useInputStyles_old();
  const inputLabelClasses = iufFixEnabled
    ? useInputLabelStyles()
    : useInputLabelStyles_old();
  const formHelperClasses = useFormHelperStyles();
  const { error, helperText, label, labelShrink, renderInputParams, required } =
    props;
  return (
    <TextField
      {...renderInputParams}
      error={error}
      helperText={helperText}
      FormHelperTextProps={{ classes: formHelperClasses }}
      InputLabelProps={{
        ...renderInputParams.InputLabelProps,
        classes: inputLabelClasses,
        shrink: labelShrink,
      }}
      InputProps={{ ...renderInputParams.InputProps, classes: inputClasses }}
      label={label}
      variant="outlined"
      required={required}
    />
  );
};
