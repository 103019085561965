import React from 'react';
import ScrollArea from 'react-scrollbar';
import onClickOutside from 'react-onclickoutside';

/* Import CSS */
import styles from './css/DateField.css';

import OptionsDropdown from './OptionsDropdown';
import TextInput from 'modules/shared/components/inputs/TextInput';

var DateField = createClass({
  getInitialState: function() {
    return {};
  },

  onBlur: function(event) {
    const { handleBlur, form_values, id, name } = this.props;
    const { month, year, day } = form_values;
    if (handleBlur) {
      var target = event.target || event;
      var return_event = event;
      return_event = {
        target: { value: { month, day, year }, id, name, field: target.id },
      };
      handleBlur(return_event);
    }
  },

  onChange: function(event) {
    const { handleChange, form_values, id, name } = this.props;
    const { month, year, day } = form_values;
    var target = event.target || event;
    var return_event = event;
    switch (target.id) {
      case 'day':
        if (isNaN(target.value)) {
          return;
        }
        var value = target.value;
        if (value.length === 1) {
          value = '0' + value;
        }

        if (value.length > 2 && value.charAt(0) === '0') {
          value = value.substr(1);
        }

        if (value.length > 2) {
          return;
        }

        if (month > 0 && target.value > config.months[month - 1].max_days) {
          value = config.months[month - 1].max_days.toString();
        }
        return_event = {
          target: {
            value: { month, day: value, year },
            id,
            name,
            field: target.id,
          },
        };
        break;
      case 'year':
        if (isNaN(target.value) || target.value.length > 4) {
          return;
        }
        return_event = {
          target: {
            value: { month, day: day, year: target.value },
            id,
            name,
            field: target.id,
          },
        };
        break;
      case 'month':
        var new_day = config.months[target.value - 1].max_days.toString();
        if (day < new_day) {
          new_day = day;
        }
        return_event = {
          target: {
            value: { month: target.value, day: new_day, year: year },
            id,
            name,
            field: target.id,
          },
        };
        break;
    }
    handleChange(return_event);
  },

  render: function() {
    const {
      handleBlur,
      form_values,
      errors,
      label,
      id,
      hidden,
      hide_label,
      hint_left_bottom,
    } = this.props;
    const { month, year, day } = form_values;
    var labels = { day: 'Day', month: 'Month', year: 'Year' };
    var form_errors = errors || {};
    var labelElement;
    let datefield_hidden;

    if (label) {
      labelElement = (
        <label className={styles.label} htmlFor={id}>
          <span>{label}</span>
        </label>
      );
    }

    if (form_errors.hasOwnProperty('main')) {
      labelElement = (
        <label className={styles.label_error} htmlFor={id}>
          <span>{form_errors['main']}</span>
        </label>
      );
    }

    if (hidden) {
      datefield_hidden = styles.hidden;
    }

    return (
      <div className={styles.date_fields + ' ' + datefield_hidden}>
        {!hide_label && labelElement}
        <span className={styles.field}>
          <TextInput
            key={'day'}
            id={'day'}
            label={'Day'}
            value={day}
            type="tel"
            error={form_errors['day']}
            handleChange={this.onChange}
            handleBlur={this.onBlur}
            required={true}
          />
        </span>
        <span className={styles.field}>
          <OptionsDropdown
            className={styles.month}
            label={'Month'}
            options={config.months}
            handleChange={this.onChange}
            handleBlur={this.onBlur}
            value={month}
            required={true}
            id={'month'}
            error={form_errors['month']}
            under_label={hint_left_bottom && 'Date of birth'}
          />
        </span>
        <span className={styles.field}>
          <TextInput
            key={'year'}
            id={'year'}
            type="tel"
            label={'Year'}
            value={year}
            error={form_errors['year']}
            handleChange={this.onChange}
            handleBlur={this.onBlur}
            required={true}
          />
        </span>
      </div>
    );
  },
});

var config = {
  months: [
    {
      label: 'Jan',
      value: 1,
      id: 'month',
      max_days: 31,
    },
    {
      label: 'Feb',
      value: 2,
      id: 'month',
      max_days: 29,
    },
    {
      label: 'Mar',
      value: 3,
      id: 'month',
      max_days: 31,
    },
    {
      label: 'Apr',
      value: 4,
      id: 'month',
      max_days: 30,
    },
    {
      label: 'May',
      value: 5,
      id: 'month',
      max_days: 31,
    },
    {
      label: 'Jun',
      value: 6,
      id: 'month',
      max_days: 30,
    },
    {
      label: 'Jul',
      value: 7,
      id: 'month',
      max_days: 31,
    },
    {
      label: 'Aug',
      value: 8,
      id: 'month',
      max_days: 31,
    },
    {
      label: 'Sep',
      value: 9,
      id: 'month',
      max_days: 30,
    },
    {
      label: 'Oct',
      value: 10,
      id: 'month',
      max_days: 31,
    },
    {
      label: 'Nov',
      value: 11,
      id: 'month',
      max_days: 30,
    },
    {
      label: 'Dec',
      value: 12,
      id: 'month',
      max_days: 31,
    },
  ],
};
export default DateField;
