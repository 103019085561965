import { Drawer } from 'mui-latest';
import styled from 'styled-components';

export const HamburgerButtonDrawer = styled(Drawer)`
  .MuiDrawer-paper{
    box-shadow: 0 0 25px rgb(0 0 0 / 25%) !important;
    margin-top: 80px;
    overflow-x: hidden;
    width: 100%
`;
