import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './css/ResetButton.css';

export default class ResetButton extends Component {
  render() {
    return (
      <div className={styles.resetButton} onClick={this.props.resetFunc}>
        reset map view
      </div>
    );
  }
}
ResetButton.propTypes = {
  resetFunc: PropTypes.func.isRequired,
};
