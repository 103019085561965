/* eslint-disable max-lines */
import get from 'lodash.get';
import AddressAndIdDetails from 'modules/card-management-shared/AddressAndIdDetails';
import commonStyles from 'modules/card-management-shared/css/Section.css';
import IdentityCapture from 'modules/identity/components/IdentityCapture/IdentityCapture';
import { getIsAntifraudAml } from 'modules/identity/components/utils';
import Button from 'modules/shared/components/inputs/Button';
import TextInput from 'modules/shared/components/inputs/TextInput';
import SectionHeader from 'modules/shared/components/v2/SectionHeader';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isBlank from 'utils/isBlank';
import isObjectPresent from 'utils/isObjectPresent';
import FieldValidators from 'utils/yupFieldValidators';
import * as yup from 'yup';

import { idUploadSignatureRequired, isFaceMatchEnabled } from './Details/utils';
import { FEATURE_FLAGS } from 'conf';

const enableCmmAuthorisationPageUpdate =
  FEATURE_FLAGS.FEATURE_FLAG_CMM_AUTHORISATION_PAGE_UPDATE;

function Details(props) {
  const { application, errors, signature, updateSignature, applicant } = props;

  const inputForm = [
    { label: 'Legal first name', name: 'first_name', required: true },
    { label: 'Middle name', name: 'middle_name', required: false },
    { label: 'Last name', name: 'last_name', required: true },
  ];

  return (
    <div className={commonStyles.section_block}>
      <SectionHeader title="Confirm your details" />
      <div className={commonStyles.row}>
        {inputForm.map((input) => (
          <div key={input.label} className={commonStyles.one_third_col}>
            <TextInput
              label={input.label}
              handleChange={(event) =>
                updateSignature({ [input.name]: event.target.value })
              }
              required={input.required}
              value={signature[input.name]}
              error={get(errors, input.name, '')}
            />
          </div>
        ))}
        <AddressAndIdDetails {...props} region={application.region} />
      </div>
    </div>
  );
}

function useApplicantSignatureState(
  applicantCardholder,
  applicantCardholderSignature
) {
  let applicantSignature = applicantCardholderSignature;

  if (isBlank(applicantSignature)) {
    applicantSignature = applicantCardholder;
  }

  if (isBlank(applicantSignature.address)) {
    applicantSignature.address = applicantCardholder.address;
  }

  if (isBlank(applicantSignature.first_name)) {
    applicantSignature.first_name = applicantCardholder.first_name;
  }

  if (isBlank(applicantSignature.last_name)) {
    applicantSignature.last_name = applicantCardholder.last_name;
  }

  if (isBlank(applicantSignature.middle_name)) {
    applicantSignature.middle_name = applicantCardholder.middle_name;
  }

  const [signature, setSignature] = useState(applicantSignature);

  return { setSignature, signature };
}

export function Authorisation(props) {
  const {
    applicant,
    cardManagementApplication,
    displayConfig,
    identificationImage,
    identificationBackImage,
    frontFaceImage,
    isFaceMatchEnabled,
    setApplicant,
    signatureRequired,
    selectedIdentityType,
    selectedRegion,
  } = props;

  let schemaConfig = {};
  let ocrFields = ['first_name', 'middle_name', 'last_name'];

  const { signature, setSignature } = useApplicantSignatureState(
    applicant,
    cardManagementApplication.attributes.applicant_signature
  );

  const [errors, setErrors] = useState({});
  const [validSwitch, setValidswitch] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(!!identificationImage);

  if (displayConfig.details) {
    schemaConfig = {
      address: FieldValidators.address,
      dob: FieldValidators.dob,
      first_name: FieldValidators.required_name(
        'Please input first name',
        'Please input a valid first name'
      ),
      identification_card_number:
        FieldValidators.identification_card_number(signature),
      identification_expiry_date: FieldValidators.identification_expiry,
      identification_number: FieldValidators.identification_number,
      identification_state: FieldValidators.identification_state,
      identification_version: FieldValidators.identification_version,
      last_name: FieldValidators.required_name(
        'Please input last name',
        'Please input a valid last name'
      ),
      middle_name: FieldValidators.name('Please input a valid middle name'),
    };
    ocrFields = [
      ...ocrFields,
      'dob',
      'region',
      'identification_number',
      'identification_version',
      'identification_expiry_date',
      'identification_state',
      'identification_card_number',
      'identification_type',
    ];
  }

  const authorisationSchema = yup.object().shape(schemaConfig);

  useEffect(() => {
    if (validSwitch) {
      valid();
    }
  }, [signature]);

  useEffect(() => {
    updateSignature({ identification_type: selectedIdentityType });
  }, [selectedIdentityType]);

  useEffect(() => {
    updateSignature({ region: selectedRegion });
  }, [selectedRegion]);

  const updateSignature = (attributes) => {
    setSignature({ ...signature, ...attributes });
  };

  const setIdDetailsByOcrResult = (ocrResult) => {
    const result = {};
    ocrFields.forEach((field) => {
      switch (field) {
        case 'first_name':
        case 'middle_name':
        case 'last_name':
          if (ocrResult[field]) {
            result[field] = ocrResult[field];
          }
          break;
        case 'dob':
        case 'identification_expiry_date':
          if (ocrResult[field]) {
            result[field] = ocrResult[field].replace(/-/g, '/');
          }
          break;
        default:
          if (ocrResult[field]) {
            result[field] = ocrResult[field];
          }
          break;
      }
    });
    updateSignature({ ...result });
  };

  const valid = (callback) => {
    if (!selectedRegion) {
      return;
    }
    cardManagementApplication.isLoading = true;
    authorisationSchema
      .validate(signature, { abortEarly: false })
      .then((val) => {
        setErrors({});
        if (callback) {
          callback(val);
        }
        cardManagementApplication.isLoading = false;
      })
      .catch((err) => {
        cardManagementApplication.isLoading = false;
        const errorMessages = {};
        err.inner.forEach((validationError) => {
          errorMessages[validationError.params.path] = validationError.message;
        });
        setErrors(errorMessages);
      });
  };

  const applicationSubmit = (data) => {
    if (uploadCompleted) {
      const processedData = {
        ...(signatureRequired
          ? data
          : { identification_image: data.identification_image }),
      };

      delete processedData['anti_fraud_check_passed'];
      delete processedData['front_face_image'];

      if (signatureRequired) {
        setApplicant({
          first_name: data.first_name,
          last_name: data.last_name,
        });
      }

      cardManagementApplication.setAttributes({
        applicant_signature: processedData,
      });
      (async () => {
        const result = await cardManagementApplication.saveApplicantSignature(
          props.supplierId
        );
        if (result && result.status === 200) {
          props.toNextSection();
        }
      })();
    }
  };

  const onSubmit = () => {
    setValidswitch(true);
    valid(applicationSubmit);
  };

  return (
    <div>
      <div className={commonStyles.container}>
        <section className={commonStyles.section}>
          <IdentityCapture
            panelTitleSize={'14px'}
            distinctId={cardManagementApplication.applicantAuthorisationId}
            authorisationID={cardManagementApplication.applicantAuthorisationId}
            imageUrl={identificationImage}
            backImageUrl={identificationBackImage}
            frontFaceImageUrl={frontFaceImage}
            page_validation_start={validSwitch}
            handleComplete={(completed) => setUploadCompleted(completed)}
            getOcrResult={(ocrResult) => setIdDetailsByOcrResult(ocrResult)}
            needDobAddress={displayConfig.details}
            isProofOfAddressVisible={displayConfig.proofOfAddress}
            isFaceMatchEnabled={isFaceMatchEnabled}
            isValidIdentification={true} // TO DO: Temporary fix to allow CMM to proceed. Needs proper fix
            proofOfAddress={signature.proof_of_address}
            {...props}
          />
          {signatureRequired && (
            <Details
              application={cardManagementApplication}
              signature={signature}
              errors={errors}
              updateSignature={updateSignature}
              isVisible={displayConfig.details}
              {...props}
            />
          )}
          <div className={commonStyles.flow_buttons}>
            <Button text="Back" onClick={props.toPrevSection} />
            <Button
              text="Next"
              type="submit"
              onClick={onSubmit}
              loading={cardManagementApplication.isLoading}
              //disabled={!!!Object.keys(errors).length}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => {
  const identity = state.identity;
  const { applicant, cardManagementApplication } = ownProps;
  const { requireAntiFraudCheck, requireAmlCheck, requireIdCheck } =
    cardManagementApplication;
  const signatureRequired = idUploadSignatureRequired(
    cardManagementApplication
  );

  const antifraudAttributes = get(
    cardManagementApplication,
    'antiFraudCheckConfig',
    {}
  );

  const isAntifraudProofOfAddressVisible = getIsAntifraudAml({
    antifraudAttributes,
    person: 'cardholder',
  });

  const displayConfig = {
    details: enableCmmAuthorisationPageUpdate
      ? requireAntiFraudCheck ||
        requireAmlCheck ||
        (isObjectPresent(applicant) && requireIdCheck)
      : requireAntiFraudCheck || (isObjectPresent(applicant) && requireIdCheck),
    proofOfAddress: enableCmmAuthorisationPageUpdate
      ? (isObjectPresent(applicant) && requireAmlCheck) ||
        isAntifraudProofOfAddressVisible ||
        requireAmlCheck
      : (isObjectPresent(applicant) && requireAmlCheck) ||
        isAntifraudProofOfAddressVisible,
  };

  const {
    image_64,
    back_image_64,
    front_face_image,
    antiFraudCheckPassed,
    type,
    region,
  } = identity;

  const frontFaceImage =
    front_face_image ||
    get(cardManagementApplication, 'applicantSignature.front_face_image');
  const identificationImage =
    image_64 ||
    get(cardManagementApplication, 'applicantSignature.identification_image');
  const identificationBackImage =
    back_image_64 ||
    get(
      cardManagementApplication,
      'applicantSignature.identification_back_image'
    );
  const isAntiFraudPassing =
    antiFraudCheckPassed ||
    get(
      cardManagementApplication,
      'applicantSignature.anti_fraud_check_passed'
    );

  return {
    deliveryAddressData: state.cob_business.delivery_address_raw_list,
    deliveryAddressLoading: state.cob_business.delivery_address_loading,
    deliveryAddressOptions: state.cob_business.delivery_address_list,
    displayConfig,
    frontFaceImage,
    identificationBackImage,
    identificationImage,
    isAntiFraudPassing,
    isFaceMatchEnabled: isFaceMatchEnabled({ cardManagementApplication }),
    selectedIdentityType: type,
    selectedRegion: region,
    signatureRequired,
  };
})(Authorisation);
