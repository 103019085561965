import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

import './dashboardheader.scss';
import DashboardLogo from './Logo';
import CompanyDropdown from './CompanyDropdown';
import NewApplicationButton from './NewApplicationButton';
import get from 'lodash.get';
import NavMenuSearch from './NavMenuSearch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const DashboardHeader = (props) => {
  const { current_user: currentUser = {}, children } = props;
  const currentEntity = currentUser.current_entity || {};
  const logoUrl = get(currentEntity, 'attributes.logo.url');

  const theme = useTheme();
  //@ts-ignore
  const isDesktop = useMediaQuery(theme.breakpoints.up('tablet'));

  return isDesktop ? (
    <header>
      <div className="dashboard-header">
        <Helmet
          defaultTitle="1Centre"
          titleTemplate="1Centre - %s"
          title="Dashboard"
        />
        <div className="dashboard-header_nav-container">
          <div className="dashboard-header_branding">
            <DashboardLogo logoUrl={logoUrl} />
            <CompanyDropdown
              currentUser={currentUser}
              entityHandleChange={props.entityHandleChange}
            />
          </div>
          <NewApplicationButton
            currentEntity={currentEntity}
            startApplicationClick={props.startApplicationClick}
          />
          <NavMenuSearch {...props} />
        </div>
      </div>
    </header>
  ) : (
    children
  );
};

export default DashboardHeader;
