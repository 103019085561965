import api from 'api';
import get from 'lodash.get';
import AddonAnswerModel from 'models/AddonAnswerModel';
import AddonRuleModel from 'models/AddonRuleModel';
import AuthorisationModel from 'models/AuthorisationModel';
import BaseModel from 'models/BaseModel';
import NoteModel from 'models/NoteModel';
import UserModel from 'models/UserModel';
import flattenArray from 'utils/flattenArray';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import sortByCreatedAt from 'utils/sortByCreatedAt';

import RecordHistoryModel from './RecordHistoryModel';

export default class ModuleCardholderApplicationModel extends BaseModel {
  static async fetchModuleCardholderApplications({
    accessToken,
    entityId,
    params,
  }) {
    const moduleCardholderApplicationAPI = api(
      'module_cardholder_applications',
      accessToken,
      entityId
    );

    try {
      const result = await moduleCardholderApplicationAPI.getModuleCardholderApplications(
        params
      );

      const applications = get(result, 'data.data', []);
      const included = get(result, 'data.included', []);
      const meta = get(result, 'data.meta', {});

      const moduleCardholderApplications = applications.map(
        (application) =>
          new ModuleCardholderApplicationModel(application, included)
      );

      return { meta, moduleCardholderApplications };
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchModuleCardholderApplication({
    accessToken,
    entityId,
    id,
    params,
  }) {
    const moduleCardholderApplicationAPI = api(
      'module_cardholder_applications',
      accessToken,
      entityId
    );

    try {
      const result = await moduleCardholderApplicationAPI.getModuleCardholderApplication(
        id,
        params
      );

      const meta = get(result, 'data.meta', {});
      const moduleCardholderApplication = new ModuleCardholderApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );

      return { meta, moduleCardholderApplication };
    } catch (error) {
      console.error(error);
    }
  }

  static async updateModuleCardholderApplication({
    accessToken,
    attributes,
    entityId,
    moduleCardholderApplication,
    onSuccessCallback,
  }) {
    const moduleCardholderApplicationAPI = api(
      'module_cardholder_applications',
      accessToken,
      entityId
    );

    try {
      const result = await moduleCardholderApplicationAPI.update(
        moduleCardholderApplication.id,
        attributes
      );

      const meta = get(result, 'data.meta', {});
      const updatedModuleCardholderApplication = new ModuleCardholderApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );

      onSuccessCallback({
        meta,
        moduleCardholderApplication: updatedModuleCardholderApplication,
      });
    } catch (error) {
      console.error(error);
    }
  }

  static async loadedInSystem({
    accessToken,
    entityId,
    moduleCardholderApplication,
    onSuccessCallback,
  }) {
    const moduleCardholderApplicationAPI = api(
      'module_cardholder_applications',
      accessToken,
      entityId
    );

    try {
      const result = await moduleCardholderApplicationAPI.loadedInSystem(
        moduleCardholderApplication.id
      );
      const meta = get(result, 'data.meta', {});
      const updatedModuleCardholderApplication = new ModuleCardholderApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );
      onSuccessCallback({
        meta,
        moduleCardholderApplication: updatedModuleCardholderApplication,
      });
    } catch (error) {
      console.error(error);
    }
  }

  get isLoadedInSystem() {
    return this.status === 'loaded_in_system';
  }

  // Alias of `isLoadedInSystem`
  get isReviewed() {
    return this.isLoadedInSystem;
  }

  get isComplete() {
    return this.status === 'completed';
  }

  get isSubmitted() {
    return this.submitted;
  }

  get formattedStatus() {
    if (this.isLoadedInSystem) {
      return 'green';
    }

    if (this.isComplete) {
      return 'complete';
    }

    return this.status;
  }

  get isViewOnly() {
    if (this.archived) {
      return true;
    }

    return ['loaded_in_system', 'deleted'].includes(this.status);
  }

  get cardManagementModuleRule() {
    return this.addonRules.find(
      (addonRule) => addonRule.addonModuleName === 'card_management_module'
    );
  }

  get isCardLimitEnabled() {
    return this.cardManagementModuleRule.isCardLimitEnabled || false;
  }

  get isSignatureRequired() {
    return this.cardManagementModuleRule.isCardholderSignatureRequired || false;
  }

  get antiFraudAddonRule() {
    return this.addonRules.find((addonRule) => addonRule.isAntiFraudAddon);
  }

  get AMLCheckRule() {
    const addonRule = this.addonRules.find(
      (addonRule) => addonRule.addonModuleName === 'aml_check'
    );

    if (isBlank(addonRule)) {
      return {};
    }

    return addonRule.addonVersion || {};
  }

  get isIdentificationCheckRequired() {
    const personToCheck = get(
      this.AMLCheckRule,
      'data.attributes.config.person_to_check',
      []
    );

    return (
      isPresent(this.identificationCheckRuleId) || isPresent(personToCheck)
    );
  }

  get formattedCMMHistories() {
    const cmmHistories = this.attributes.cmm_histories;

    if (!cmmHistories || cmmHistories.length === 0) {
      return [];
    }

    return cmmHistories.map((cmmHistory) => {
      const { id } = cmmHistory;

      const data = {
        attributes: cmmHistory,
        id,
        type: 'authorisation_history',
      };

      return new RecordHistoryModel(data);
    });
  }

  get authorisationHistories() {
    const authorisationHistories = flattenArray(
      this.authorisations.map(
        (authorisation) => authorisation.authorisationHistories
      )
    ).filter(
      (history) =>
        isPresent(history.content) &&
        isPresent(get(history, 'attributes.attribute_changes.email_sent_to'))
    );

    const manuallyApprovedSignatures = this.authorisations
      .map((authorisation) => {
        const signature = authorisation.signature;

        return {
          createdAt: signature.manuallyApprovedAt,
          formattedContent: signature.manuallyApprovedText,
        };
      })
      .filter((signature) => isPresent(signature.formattedContent));

    const cmmHistories = this.formattedCMMHistories;

    const histories = [
      ...authorisationHistories,
      ...manuallyApprovedSignatures,
      ...cmmHistories,
    ];

    return sortByCreatedAt(histories);
  }

  get answers() {
    return get(this.addonAnswer || {}, 'answers.additional_fields', []);
  }

  isAMLCheckRequired({ actingAs, proofOfAddressUrl, region }) {
    if (isPresent(proofOfAddressUrl)) {
      return true;
    }

    if (!this.AMLCheckRule.active) {
      return false;
    }

    const { config } = this.AMLCheckRule || {};
    const ruleByRegion = config[region];

    if (isBlank(ruleByRegion)) {
      return false;
    }

    const personToCheck = config.person_to_check || [];
    for (const person of actingAs) {
      if (personToCheck.includes(person)) {
        return true;
      }
    }

    return false;
  }

  get cardholderAuthorisations() {
    return this.authorisations.filter(
      (authorisation) => authorisation.isCardholder
    );
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  onLoadedInSystem({ currentUser, onSuccessCallback }) {
    this.isLoading = true;

    const successCallback = (response) => {
      onSuccessCallback(response);

      this.isLoading = false;
    };

    ModuleCardholderApplicationModel.loadedInSystem({
      accessToken: currentUser.accessToken,
      entityId: get(currentUser, 'currentEntity.id'),
      moduleCardholderApplication: this,
      onSuccessCallback: successCallback,
    });
  }

  update({ currentUser, onSuccessCallback }) {
    this.isLoading = true;

    const successCallback = (response) => {
      onSuccessCallback(response);
      this.isLoading = false;
    };

    ModuleCardholderApplicationModel.updateModuleCardholderApplication({
      accessToken: currentUser.accessToken,
      attributes: { data: { attributes: this.attributes } },
      entityId: get(currentUser, 'currentEntity.id'),
      moduleCardholderApplication: this,
      onSuccessCallback: successCallback,
    });
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: 'loaded_in_system_by',
      model: UserModel,
    });

    this.assignSingleRelationship({
      key: 'addon_answer',
      model: AddonAnswerModel,
    });

    this.assignManyRelationship({
      key: 'notes',
      model: NoteModel,
    });

    this.assignManyRelationship({
      included: this.included,
      key: 'authorisations',
      model: AuthorisationModel,
    });
    this.assignManyRelationship({ key: 'addon_rules', model: AddonRuleModel });
  }

  get mainNotes() {
    return this.notes.filter((note) => isBlank(note.parentId));
  }

  addNote(note, index) {
    if (typeof index === 'undefined' || index < 0) {
      this.notes.unshift(note);
    } else {
      this.notes.splice(index, 1, note);
    }

    this.refreshNoteRelationship(note);
    this.refreshNoteIncluded(note);
  }

  refreshNoteRelationship(note) {
    const noteRelationships = get(this.relationships, 'notes.data', []);

    const index = noteRelationships.findIndex(
      (relationship) => relationship.id === note.id
    );

    if (index < 0) {
      this.addNoteRelationship({ note, noteRelationships });
    } else {
      this.updateNoteRelationship({ index, note, noteRelationships });
    }
  }

  addNoteRelationship({ note, noteRelationships }) {
    noteRelationships.unshift({ id: note.id, type: 'notes' });

    this.relationships = {
      ...this.relationships,
      notes: { data: noteRelationships },
    };

    this.data = { ...this.data, relationships: this.relationships };
  }

  updateNoteRelationship({ index, note, noteRelationships }) {
    noteRelationships.splice(index, 1, { id: note.id, type: 'notes' });

    this.relationships = {
      ...this.relationships,
      notes: { data: noteRelationships },
    };

    this.data = { ...this.data, relationships: this.relationships };
  }

  refreshNoteIncluded(note) {
    const index = this.included.findIndex(
      (included) => included.type === 'notes' && included.id === note.id
    );

    if (index < 0) {
      this.included.unshift(note.data);
    } else {
      this.included.splice(index, 1, note.data);
    }
  }
}
