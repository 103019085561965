import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Button from 'modules/shared/components/inputs/Button';
import { CustomerViewContext } from './CustomerViewContext';
import { ActionStatus, ConfigStatus } from './types';
import { ConfirmTurnOn } from './ConfirmTurnOn';
import { ConfirmTurnOff } from './ConfirmTurnOff';
import { Skeleton } from '@material-ui/lab';

const Container = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 36px;
`;

export const Buttons = () => {
  const [isConfirmTurnOnVisible, setConfirmTurnOnVisible] = useState(false);
  const [isConfirmTurnOffVisible, setConfirmTurnOffVisible] = useState(false);

  const showConfirmTurnOn = () => setConfirmTurnOnVisible(true);

  const showConfirmTurnOff = () => setConfirmTurnOffVisible(true);

  const confirmTurnOn = () => {
    setConfirmTurnOnVisible(false);
    turnOn();
  };

  const confirmTurnOff = () => {
    setConfirmTurnOffVisible(false);
    turnOff();
  };

  const cancelTurnOn = () => setConfirmTurnOnVisible(false);

  const cancelTurnOff = () => setConfirmTurnOffVisible(false);

  const {
    treeData,

    showPreview,

    saveDraft,
    save,
    turnOn,
    turnOff,

    isCustomerViewEnabled,
    configStatus,
    actionStatus,
    isSaveDisabled,
    isTurnOnDisabled,
  } = useContext(CustomerViewContext);

  const isDisabled = actionStatus !== ActionStatus.None;

  return (
    <Container>
      {treeData.length ? (
        <>
          <Button
            text="Preview"
            handleClick={showPreview}
            disabled={isDisabled}
          />
          {(configStatus === ConfigStatus.Draft ||
            configStatus === ConfigStatus.Absent) && (
            <Button
              text="Save draft"
              handleClick={saveDraft}
              disabled={isDisabled || isSaveDisabled}
              loading={actionStatus === ActionStatus.SavingDraft}
            />
          )}

          {configStatus !== ConfigStatus.Absent &&
            configStatus !== ConfigStatus.Draft && (
              <Button
                text="Save"
                handleClick={save}
                disabled={isDisabled || isSaveDisabled}
                loading={actionStatus === ActionStatus.Saving}
              />
            )}

          {isCustomerViewEnabled && configStatus !== ConfigStatus.On && (
            <Button
              text="Turn on"
              handleClick={showConfirmTurnOn}
              white
              disabled={isDisabled || isTurnOnDisabled}
              loading={actionStatus === ActionStatus.TurningOn}
            />
          )}

          {configStatus === ConfigStatus.On && (
            <>
              <Button
                text="Turn off"
                handleClick={showConfirmTurnOff}
                white
                disabled={isDisabled}
                loading={actionStatus === ActionStatus.TurningOff}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Skeleton variant="rect" width={100} height={32} />
          <Skeleton variant="rect" width={100} height={32} />
          <Skeleton variant="rect" width={100} height={32} />
        </>
      )}

      <ConfirmTurnOn
        isOpen={isConfirmTurnOnVisible}
        onCancel={cancelTurnOn}
        onConfirm={confirmTurnOn}
      />
      <ConfirmTurnOff
        isOpen={isConfirmTurnOffVisible}
        onCancel={cancelTurnOff}
        onConfirm={confirmTurnOff}
      />
    </Container>
  );
};
