import Button from 'modules/shared/components/inputs/Button';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators } from 'redux';

import * as mappingActions from '../actions';
import styles from './css/BusinessCard.css';

class ApplicationBusinessCard extends Component {
  _getDetails() {
    const { tradingName, entity, contact } = this.props;

    return {
      email: contact.email || entity.key_contact_email,
      name:
        contact.full_name ||
        `${entity.key_contact_first_name} ${entity.key_contact_last_name}`,
      number: contact.contact_phone_number || entity.key_contact_phone_number,
      trading_name: tradingName,
    };
  }

  _viewApplication(applicationId) {
    browserHistory.push(`/dashboard/applications/${applicationId}`);
  }

  _editKeyContacts(applicationId) {
    this.props.mappingActions.set({ applicationId });
    //browserHistory.push('/dashboard/settings/business')
  }

  render() {
    const title = this.props.isSupplier ? 'Supplier' : 'Consumer';

    const details = this._getDetails();
    if (this.props.isSupplier && details.name === 'Website button') {
      details.email = '';
    }

    return (
      <div>
        <article className={styles.block}>
          <div className={styles.liner}>
            <h3 className={styles.header}>{title}</h3>
            <h4>{details.trading_name}</h4>
            <p>{details.name}</p>
            <p>{details.number}</p>
            <p>{details.email}</p>
            {(this.props.isAdmin || !this.props.isSupplier) && (
              <div className={styles.button}>
                <Button
                  white={true}
                  handleClick={() => {
                    !this.props.isSupplier
                      ? this._viewApplication(this.props.applicationId)
                      : this._editKeyContacts(this.props.applicationId);
                  }}
                  text={
                    !this.props.isSupplier
                      ? 'View application'
                      : 'Edit key contact'
                  }
                />
              </div>
            )}
          </div>
        </article>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      isAdmin: state.current_user.current_user_entity_link.attributes.role_types.includes(
        'admin'
      ),
      mapping: state.mapping,
    };
  },
  (dispatch) => {
    return {
      dispatch,
      mappingActions: bindActionCreators(mappingActions, dispatch),
    };
  }
)(ApplicationBusinessCard);
