/* Import libraries */
import React, { Component } from 'react';
import { loadAddons, setCurrentAddonRuleset } from 'modules/addons/actions';

/* Import components */
import AddOnsListItem from './AddOnsListItem';
import AdditionalDetails from 'modules/addons/additional/components/AdditionalDetails';
import Button from 'modules/shared/components/inputs/Button';
import PopperList from 'modules/shared/components/widgets/interactive/PopperList.js';
import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';
import VideoModal from 'modules/shared/components/widgets/interactive/VideoModal';
/* Import CSS */
import commonStyles from './css/CommonAddOns.css';
import { connect } from 'react-redux';
/* Import actions */
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import { getRulesetConflicts, sortedAddonList } from './../helpers';
import AddonSkeleton from './AddonSkeleton';

class AdditionalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addListAnchorEl: null,
      read_only: false,
      showAddList: false,
      show_detail_page: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadAddons(addonType));
  }

  showDetailPage() {
    this.setState({ show_detail_page: true });
  }

  hideDetailPage() {
    this.setState({ show_detail_page: false });
  }

  buildNewTemplate(configId) {
    const { configs, dispatch } = this.props;

    this.hideNewTemplateCreateList();
    if (configId) {
      const config = configs.find((c) => c.id === configId);
      const currentVersion = config.attributes.history_version.data[0];
      const versionDup = {
        attributes: {
          addon_module_name: 'additional',
          config: currentVersion.attributes.config,
          legal_types: [],
          max_credit_value: 0,
          min_credit_value: 0,
          name: null,
        },
      };
      dispatch(setCurrentAddonRuleset(null, versionDup, addonType));
    }
    this.showDetailPage();
  }

  clickAddButton(target) {
    const { configs } = this.props;
    if (configs.length === 0) {
      this.buildNewTemplate();
    } else {
      this.showNewTemplateCreateList(target);
    }
  }

  showNewTemplateCreateList(target) {
    this.setState({
      addListAnchorEl: target,
      read_only: false,
      showAddList: true,
    });
  }

  hideNewTemplateCreateList() {
    this.setState({
      addListAnchorEl: null,
      read_only: false,
      showAddList: false,
    });
  }

  openDetailPageForExsitingRuleset(serialNumber, config) {
    const { dispatch } = this.props;
    if (config) {
      dispatch(setCurrentAddonRuleset(serialNumber, config, addonType));
    }
    this.showDetailPage();
  }

  onEdit(serialNumber, currentConfig) {
    this.setState({ read_only: false });
    this.openDetailPageForExsitingRuleset(serialNumber, currentConfig);
  }

  onView(serialNumber, version) {
    this.setState({ read_only: true });
    this.openDetailPageForExsitingRuleset(serialNumber, version);
  }

  renderList() {
    const { configs, activeUpdatingId } = this.props;
    const { showAddList, addListAnchorEl } = this.state;
    const items = [];
    const addOptions = [{ id: null, label: 'New' }];

    configs.forEach((config, index) => {
      const versionData = config.attributes.history_version.data;
      const latestConfig = versionData[0].attributes;
      items.push(
        <AddOnsListItem
          key={`${config.attributes.addon_module_name}_${index}`}
          addonType={addonType}
          rulesetConflicts={getRulesetConflicts(
            configs,
            config.attributes.history_version.data[0]
          )}
          currentAddonData={config}
          addonVersions={versionData}
          onEdit={(serialNumber, currentConfig) => {
            this.onEdit(serialNumber, currentConfig);
          }}
          viewHistory={(serialNumber, version) => {
            this.onView(serialNumber, version);
          }}
          updating={config.id === activeUpdatingId}
          disabled={!isFeatureEditEnabled('Additional')}
        />
      );
      addOptions.push({
        label: `Base on #${config.attributes.serial_number} ${latestConfig.name}`,
        value: config.id,
      });
    });

    return (
      <div>
        <div className={commonStyles.header_container}>
          <span>
            <span className={commonStyles.header}>
              Set your additional questions here
            </span>
            <VideoModal videoName={'additional'} />
          </span>
          {isFeatureEditEnabled('Additional') && (
            <Button
              style={{ fontWeight: '700' }}
              disabled={false}
              text="+ Add"
              handleClick={(e) => {
                this.clickAddButton(e.target);
              }}
            />
          )}
        </div>
        <PopperList
          open={showAddList}
          anchorEl={addListAnchorEl}
          options={addOptions}
          handleBlur={this.hideNewTemplateCreateList.bind(this)}
          handleSelect={(value) => this.buildNewTemplate(value)}
        />
        <div className={commonStyles.container}>
          <ul className={commonStyles.list}>{items}</ul>
        </div>
      </div>
    );
  }

  renderDetails() {
    return (
      <AdditionalDetails
        handleDiscard={() => {
          this.hideDetailPage();
        }}
        readOnly={this.state.read_only || !isFeatureEditEnabled('Additional')}
      />
    );
  }

  render() {
    if (this.props.loading) {
      return <AddonSkeleton />;
    }

    return (
      <div className={commonStyles.row}>
        <div className={commonStyles.block_wide}>
          {this.state.show_detail_page
            ? this.renderDetails()
            : this.renderList()}
        </div>
      </div>
    );
  }
}

const addonType = 'additional';

export default connect((state) => {
  const configs = sortedAddonList(state, addonType);

  return {
    activeUpdatingId: state.add_ons.additional_active_updating_id,
    configs,
    loading: state.add_ons.additional_loading,
  };
})(AdditionalList);
