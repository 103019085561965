import { DesktopCopyLinkAction } from 'modules/new-applications/components/application-sections/IdentificationChecks';
import React, { memo, useCallback } from 'react';
import { useState } from 'react';

const CopyTextToClipboard = memo(
  ({ text, className }: { text?: string; className?: string }) => {
    if (!text) {
      return null;
    }

    const [isCopied, setIsCopied] = useState(false);

    const onCopyToClipboard = useCallback(async () => {
      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
      } catch (error) {
        setIsCopied(false);
        console.error(error);
      }
    }, []);

    const label = isCopied ? 'Copied!' : 'Copy';

    return (
      <div className={['is-inline-block', className || ''].join(' ')}>
        <DesktopCopyLinkAction
          isCopied={isCopied}
          label={label}
          onClick={onCopyToClipboard}
        />
      </div>
    );
  }
);

CopyTextToClipboard.displayName = 'CopyTextToClipboard';

export default CopyTextToClipboard;
