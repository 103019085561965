import React from 'react';
import styles from './report-styles.css';
import { capitalize, formatMoney } from 'utils/formatting';
import { getEnquiryData } from './utils';

const ReportEnquiriesDefaults = ({ characteristic, enquiryType }) => {
  const enquiriesData = getEnquiryData(characteristic, enquiryType);

  if (characteristic.length < 1) return <p>Information Unavailable</p>;

  return (
    <div>
      <p className="is-pulled-right">
        No. of enquiries: {enquiriesData.numEnquiries5years.value}
        &emsp; No. of defaults: {enquiriesData.numDefaults.value}
      </p>
      <br />
      <div className={styles.table + ' mt-2'}>
        <div className={styles.row}>
          <div className={styles.th + ' ' + styles.col_4}>Data</div>
          <div className={styles.th + ' ' + styles.col_8}>
            {capitalize(enquiryType)}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.th_underline + ' ' + styles.col_4}>
            Time since last enquiry
          </div>
          <div className={styles.th_underline + ' ' + styles.col_8}>
            {!enquiriesData.timeSinceLastEnquiry ||
            enquiriesData.timeSinceLastEnquiry.value < 0
              ? '-'
              : `Less than ${enquiriesData.timeSinceLastEnquiry.value} month(s)`}
          </div>
        </div>
      </div>

      <div className={styles.table}>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}></div>
          <div className={styles.td + ' ' + styles.col_4}>
            <strong>Number</strong>
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            <strong>Total</strong>
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}>
            Enquiries in the last 3 months
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {enquiriesData.numEnquiries3months.value}
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!enquiriesData.totalEnquiries3months ||
            enquiriesData.totalEnquiries3months.value < 0
              ? '-'
              : `$${formatMoney(
                  parseFloat(enquiriesData.totalEnquiries3months.value),
                  null,
                  null,
                  null,
                  null
                )}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}>
            Enquiries in the last 5 years
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {enquiriesData.numEnquiries5years.value}
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!enquiriesData.totalEnquiries5years ||
            enquiriesData.totalEnquiries5years.value < 0
              ? '-'
              : `$${formatMoney(
                  parseFloat(enquiriesData.totalEnquiries5years.value),
                  null,
                  null,
                  null,
                  null
                )}`}
          </div>
        </div>
        <div className={styles.tr}>
          <div className={styles.td + ' ' + styles.col_4}>Defaults</div>
          <div className={styles.td + ' ' + styles.col_4}>
            {enquiriesData.numDefaults.value}
          </div>
          <div className={styles.td + ' ' + styles.col_4}>
            {!enquiriesData.totalDefaults ||
            enquiriesData.totalDefaults.value < 0
              ? '-'
              : `$${formatMoney(
                  parseFloat(enquiriesData.totalDefaults.value),
                  null,
                  null,
                  null,
                  null
                )}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportEnquiriesDefaults;
