import React from 'react';
import images from './images.js';
import Payment from 'payment';
import createClass from 'create-react-class';
import '!style-loader!css-loader!./css/card.css';
import '!style-loader!css-loader!./css/card-types.css';

const validate = Payment.fns;

let prefix = 'react-credit-card';

export default createClass({
  displayName: 'Card',
  getDefaultProps: function() {
    return {
      number: null,
      cvc: null,
      name: '',
      expiry: '',
      focused: null,
      namePlaceholder: 'FULL NAME',
      expiryBefore: 'month/year',
      expiryAfter: 'valid thru',
      shinyAfterBack: '',
      type: null,
    };
  },
  render: function() {
    var isAmex;
    isAmex = this.state.type && this.state.type.name === 'amex';
    return React.createElement(
      'div',
      {
        className: '' + prefix + '__container',
      },
      React.createElement(
        'div',
        {
          className:
            '' +
            prefix +
            ' ' +
            this.typeClassName() +
            (this.props.focused === 'cvc' && !isAmex
              ? ' ' + prefix + '--flipped'
              : ''),
        },
        React.createElement(
          'div',
          {
            className: '' + prefix + '__front',
          },
          React.createElement(
            'div',
            {
              className: '' + prefix + '__lower',
            },
            React.createElement('div', {
              className: '' + prefix + '__shiny',
            }),
            React.createElement('img', {
              className: '' + prefix + '__logo ' + this.typeClassName(),
              src:
                images[
                  this.props.type ? this.props.type : this.state.type.name
                ],
            }),
            isAmex
              ? React.createElement(
                  'div',
                  {
                    className: this.displayClassName('cvc_front'),
                  },
                  this.getValue('cvc')
                )
              : void 0,
            React.createElement(
              'div',
              {
                className: this.displayClassName('number'),
              },
              this.getValue('number')
            ),
            React.createElement(
              'div',
              {
                className: this.displayClassName('name'),
              },
              this.getValue('name')
            ),
            React.createElement(
              'div',
              {
                className: this.displayClassName('expiry'),
                'data-before': this.props.expiryBefore,
                'data-after': this.props.expiryAfter,
              },
              this.getValue('expiry')
            )
          )
        ),
        React.createElement(
          'div',
          {
            className: '' + prefix + '__back',
          },
          React.createElement('div', {
            className: '' + prefix + '__bar',
          }),
          React.createElement(
            'div',
            {
              className: this.displayClassName('cvc'),
            },
            this.getValue('cvc')
          ),
          React.createElement('div', {
            className: '' + prefix + '__shiny',
            'data-after': this.props.shinyAfterBack,
          })
        )
      )
    );
  },
  displayClassName: function(base) {
    var className;
    className = '' + prefix + '__' + base + (' ' + prefix + '__display');
    if (this.props.focused === base) {
      className += ' ' + prefix + '--focused';
    }
    return className;
  },
  typeClassName: function() {
    return (
      '' +
      prefix +
      '--' +
      (this.props.type ? this.props.type : this.state.type.name)
    );
  },
  getValue: function(name) {
    return this[name]();
  },
  componentWillMount: function() {
    return this.updateType(this.props);
  },
  componentWillReceiveProps: function(nextProps) {
    return this.updateType(nextProps);
  },
  getInitialState: function() {
    return {
      type: {
        name: 'unknown',
        length: 16,
      },
    };
  },
  updateType: function(props) {
    var type;
    if (!props.number) {
      return this.setState({
        type: {
          name: 'unknown',
          length: 16,
        },
      });
    }
    if ((type = this.props.type || validate.cardType(props.number))) {
      if (type === 'amex') {
        return this.setState({
          type: {
            name: type,
            length: 15,
          },
        });
      } else {
        return this.setState({
          type: {
            name: type,
            length: 16,
          },
        });
      }
    }
    return this.setState({
      type: {
        name: 'unknown',
        length: 16,
      },
    });
  },
  number: function() {
    var amountOfSpaces,
      i,
      maxLength,
      space_index,
      space_index1,
      space_index2,
      string,
      _i;
    if (!this.props.number) {
      string = '';
    } else {
      string = this.props.number.toString();
    }
    maxLength = this.state.type.length;
    if (string.length > maxLength) {
      string = string.slice(0, maxLength);
    }
    while (string.length < maxLength) {
      string += '•';
    }
    if (this.state.type.name === 'amex') {
      space_index1 = 4;
      space_index2 = 10;
      string =
        string.substring(0, space_index1) +
        ' ' +
        string.substring(space_index1, space_index2) +
        ' ' +
        string.substring(space_index2);
    } else {
      amountOfSpaces = Math.ceil(maxLength / 4);
      for (
        i = _i = 1;
        1 <= amountOfSpaces ? _i < amountOfSpaces : _i > amountOfSpaces;
        i = 1 <= amountOfSpaces ? ++_i : --_i
      ) {
        space_index = i * 4 + (i - 1);
        string = string.slice(0, space_index) + ' ' + string.slice(space_index);
      }
    }
    return string;
  },
  name: function() {
    if (this.props.name === '') {
      return this.props.namePlaceholder;
    } else {
      return this.props.name;
    }
  },
  expiry: function() {
    var expiry, expiryMaxLength;
    if (this.props.expiry === '') {
      return '••/••';
    } else {
      expiry = this.props.expiry.toString();
      expiryMaxLength = 6;
      if (expiry.match(/\//)) {
        expiry = expiry.replace('/', '');
      }
      if (!expiry.match(/^[0-9]*$/)) {
        return '••/••';
      }
      while (expiry.length < 4) {
        expiry += '•';
      }
      expiry = expiry.slice(0, 2) + '/' + expiry.slice(2, expiryMaxLength);
      return expiry;
    }
  },
  cvc: function() {
    if (this.props.cvc === null) {
      return '•••';
    } else {
      if (this.props.cvc.toString().length <= 4) {
        return this.props.cvc;
      } else {
        return this.props.cvc.toString().slice(0, 4);
      }
    }
  },
});
