import { useState } from 'react';

export default function useAddressState(props) {
  const { initialFullAddress, initialRawAddress, onSetAddressCallback } = props;

  const [fullAddress, setFullAddress] = useState(initialFullAddress);
  const [rawAddress, setRawAddress] = useState(initialRawAddress);

  const onSetNewFullAddress = (newAddress) => setFullAddress(newAddress);

  const onSetNewRawAddress = (newRawAddress) => {
    setRawAddress(newRawAddress);

    if (onSetAddressCallback) {
      onSetAddressCallback(newRawAddress);
    }
  };

  return { fullAddress, onSetNewFullAddress, onSetNewRawAddress, rawAddress };
}
