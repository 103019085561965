import React from 'react';
import styles from './report-styles.css';

const ReportScoreGraph = (props) => {
  const { score } = props;
  if (!score || !score.score_masterscale) return null;

  let score_masterscale = score.score_masterscale;
  let maxValue = 1200;
  let minValue = -200;
  let valuePosition: any = minValue;
  let riskOdds = parseFloat(score.risk_odds);
  let probabilityAdverse = 100 / riskOdds;

  if (score_masterscale < minValue) score_masterscale = minValue;
  valuePosition =
    (
      ((score_masterscale - minValue) / (maxValue - minValue)) *
      100
    ).toString() + '%';

  return (
    <div className={styles.overview + ' pt-6 px-6 mb-4'}>
      <div className={styles.overview_rating_full}>
        <div className={styles.liner}>
          <div className={styles.overview_rating_graph}>
            <div
              className={
                styles.overview_rating_indicator +
                ' is-size-2 has-text-warning has-text-weight-bold'
              }
              style={{ left: valuePosition }}
            >
              {score_masterscale}
            </div>
            <div className={styles.overview_rating_graph_bg_reverse} />
            <span className={styles.overview_rating_graph_minus_20}>-200</span>
            <span className={styles.overview_rating_graph_0}>0</span>
            <span className={styles.overview_rating_graph_20}>200</span>
            <span className={styles.overview_rating_graph_40}>400</span>
            <span className={styles.overview_rating_graph_60}>600</span>
            <span className={styles.overview_rating_graph_80}>800</span>
            <span className={styles.overview_rating_graph_100}>1000</span>
            <span className={styles.overview_rating_graph_120}>1200</span>

            <span className={styles.overview_rating_graph_minus_20_label}>
              <strong>Increased Risk</strong>
            </span>
            <span className={styles.overview_rating_graph_120_label}>
              <strong>Reduced Risk</strong>
            </span>
          </div>
        </div>
      </div>

      <div className={styles.row + ' pt-6 ' + styles.risk_odds}>
        <p>
          <span className="is-size-5 has-text-weight-semibold">
            {probabilityAdverse.toFixed(2)}%
          </span>{' '}
          chance of adverse (incl. repayment 90+ days overdue) recorded at
          Equifax in the next 12 months
        </p>
      </div>
    </div>
  );
};

export default ReportScoreGraph;
