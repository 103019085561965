import {
  TRADE_REFERENCES_LOAD_APPLICATIONS_START,
  TRADE_REFERENCES_LOAD_APPLICATIONS_SUCCESS,
  TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_START,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_SUCCESS,
  TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR,
  TRADE_REFERENCES_UPDATE_CHECK_SUCCESS,
  TRADE_REFERENCES_CREATE_CHECK_SUCCESS,
  TRADE_REFERENCES_CLEAR_REFERENCE,
  TRADE_REFERENCES_CLEAR_APPLICATIONS,
} from './constants';

var trade_references_defaults = {
  list_loading: false,
  list: [],
  meta: {},
  trm: null,
  trm_loading: false,
  save_successful: false,
};

export function tradeReferencesReducer(
  state = trade_references_defaults,
  action
) {
  switch (action.type) {
    case TRADE_REFERENCES_CLEAR_APPLICATIONS:
      return { ...state, list: [] };
    case TRADE_REFERENCES_CLEAR_REFERENCE:
      return { ...state, trm: null, save_successful: false };

    case TRADE_REFERENCES_LOAD_APPLICATIONS_START:
      return { ...state, list_loading: true };
    case TRADE_REFERENCES_LOAD_APPLICATIONS_SUCCESS:
      return {
        ...state,
        list_loading: false,
        list: action.payload.data,
        meta: action.payload.meta,
      };
    case TRADE_REFERENCES_LOAD_APPLICATIONS_ERROR:
      return { ...state, list_loading: false };

    case TRADE_REFERENCES_UPDATE_CHECK_SUCCESS:
    case TRADE_REFERENCES_CREATE_CHECK_SUCCESS:
      return { ...state, save_successful: true };

    case TRADE_REFERENCES_LOAD_TRADE_REFERENCE_START:
      return { ...state, trm_loading: true, trm: null };
    case TRADE_REFERENCES_LOAD_TRADE_REFERENCE_SUCCESS:
      return { ...state, trm_loading: false, trm: action.payload };
    case TRADE_REFERENCES_LOAD_TRADE_REFERENCE_ERROR:
      return { ...state, trm_loading: false };

    default:
      return { ...state };
  }
}
