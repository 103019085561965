import React, { ReactElement } from 'react';
import { Tab, Wrapper } from './styles';

type Props = {
  currentTab: string;
  isStandardUser: boolean;
  setTab: (tab: string) => void;
};

function MobileTabs({
  currentTab,
  isStandardUser,
  setTab,
}: Props): ReactElement {
  const tabs = isStandardUser
    ? ['Applications', 'QR Code']
    : ['Effectiveness', 'Efficiency', 'Experience'];

  return (
    <Wrapper>
      {tabs.map((tab) => (
        <Tab
          key={`mobile-tab-${tab}`}
          active={currentTab === tab}
          onClick={() => setTab(tab)}
        >
          {tab}
        </Tab>
      ))}
    </Wrapper>
  );
}

export default MobileTabs;
