import React, { Fragment, ReactElement } from 'react';

const Content = (): ReactElement => (
  <Fragment>
    <span className="underlined">ID check outcome:</span>
    <span style={{ color: 'green' }}>&nbsp;Pass</span>
    <span>&nbsp;(Manual)</span>
  </Fragment>
);

export default Content;
