/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import mixpanel from 'mixpanel-browser';

/* Import actions */
import { setCurrentRuleset } from '../auto_decisioning/actions';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';

/* Import CSS */
import styles from './css/AutoDecisioningList.css';
import common_styles from './css/CommonAddOns.css';

/* Import components */
import AutoDecisioningListItem from './AutoDecisioningListItem';
import CreateAutoDecisioning from '../auto_decisioning/components/CreateAutoDecisioning';
import UpdateAutoDecisioning from '../auto_decisioning/components/UpdateAutoDecisioning';
import Button from 'modules/shared/components/inputs/Button';
import ViewAutoDecisioning from '../auto_decisioning/components/ViewAutoDecisioning';
import PricingPlanModal from '../../pricing-plans/components/PricingPlanModal';
import VideoModal from 'modules/shared/components/widgets/interactive/VideoModal';
import AddonSkeleton from './AddonSkeleton';

var AutoDecisioningList = createClass({
  getInitialState: function() {
    return {
      creating: this.props.location.query.task === 'create-auto',
      editing: false,
      viewing: false,
      hide_upgrade_modal: true,
    };
  },

  startCreate: function() {
    const { location, current_user } = this.props;

    if (current_user) {
      mixpanel.track('Auto Decisioning page - Add button clicked', {
        distinct_id: current_user.data.data.id,
        'Entity ID': current_user.current_entity.id,
      });
    }

    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, task: 'create-auto' },
    });
    this.setState({
      creating: true,
    });
  },

  discardCreate: function() {
    const { location } = this.props;
    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, task: undefined },
    });
    this.setState({
      creating: false,
    });
  },

  startEdit: function(rule_set) {
    const { dispatch, location } = this.props;
    dispatch(setCurrentRuleset(rule_set));
    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, task: 'edit-auto', id: rule_set.id },
    });
    this.setState({
      editing: true,
    });
  },

  discardEdit: function() {
    const { dispatch, location } = this.props;
    dispatch(setCurrentRuleset(null));
    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, task: undefined, id: undefined },
    });
    this.setState({
      editing: false,
    });
  },

  discardView: function() {
    const { dispatch, location } = this.props;
    dispatch(setCurrentRuleset(null));
    browserHistory.push({
      pathname: location.pathname,
      query: {},
    });
    this.setState({
      viewing: false,
    });
  },

  showUpgradeModal: function() {
    const { current_user } = this.props;

    if (current_user) {
      mixpanel.track(
        'Auto Decisioning page - Add button clicked - on_free_plan',
        {
          distinct_id: current_user.data.data.id,
          'Entity ID': current_user.current_entity.id,
        }
      );
    }

    this.setState({
      hide_upgrade_modal: false,
    });
  },

  componentDidUpdate: function() {
    const { location, rule_sets, dispatch, current_rule_set } = this.props;
    const { editing, viewing, creating } = this.state;
    if (!editing && location.query.id) {
      let rule_set = rule_sets.find((r) => r.id === location.query.id);
      if (!rule_set) {
        return;
      }
      if (location.query.task === 'edit-auto') {
        this.startEdit(rule_set);
      } else if (location.query.task === 'view-auto') {
        let version = rule_set.attributes.history_data.find(
          (v) => v.current_index == location.query.version_index
        );
        if (
          !current_rule_set ||
          version.id !== current_rule_set.attributes.id
        ) {
          dispatch(setCurrentRuleset({ attributes: version }));
        }
        if (!viewing) {
          this.setState({ viewing: true });
        }
      }
    }
    if (editing && location.query.task != 'edit-auto') {
      this.discardEdit();
    }
    if (creating && location.query.task != 'create-auto') {
      this.discardCreate();
    }
  },

  render: function() {
    const { loading, rule_sets, updating_id, pricing_plan } = this.props;
    const { creating, editing, viewing, hide_upgrade_modal } = this.state;
    const on_free_plan = pricing_plan
      ? pricing_plan.attributes.name === 'On Your Own'
      : true;

    let items = [];
    rule_sets.forEach((rule_set) => {
      items.push(
        <AutoDecisioningListItem
          updating={rule_set.id === updating_id}
          disabled={
            rule_set.id === updating_id || !isFeatureEditEnabled('1CAD')
          }
          rule_set={rule_set}
          key={rule_set.id}
          id={rule_set.id}
          view_only={!isFeatureEditEnabled('1CAD')}
          onEdit={this.startEdit}
        />
      );
    });

    return (
      <div className={common_styles.row}>
        <div className={common_styles.block_wide}>
          {!creating && !editing && !viewing && (
            <div>
              <div className={styles.header_container}>
                <span>
                  <span className={styles.header}>Set your rules here</span>
                  <VideoModal videoName={'auto_decisioning'} />
                </span>
                {isFeatureEditEnabled('1CAD') && (
                  <Button
                    style={{ fontWeight: '700' }}
                    grey={on_free_plan}
                    disabled={false}
                    text="+ Add"
                    handleClick={
                      on_free_plan ? this.showUpgradeModal : this.startCreate
                    }
                  />
                )}
              </div>
              {on_free_plan && !hide_upgrade_modal && (
                <PricingPlanModal
                  type={'others'}
                  onCancel={() => {
                    this.setState({ hide_upgrade_modal: true });
                  }}
                />
              )}
              <div className={styles.container}>
                {!rule_sets.length && !loading && (
                  <div className={styles.empty_state_background} />
                )}
                {loading && (
                  <div className={styles.empty_state_background}>
                    <AddonSkeleton />
                  </div>
                )}
                {!loading && <ul className={styles.list}>{items}</ul>}
              </div>
            </div>
          )}
          {!!creating && (
            <CreateAutoDecisioning handleDiscard={this.discardCreate} />
          )}
          {!!editing && (
            <UpdateAutoDecisioning handleDiscard={this.discardEdit} />
          )}
          {viewing && <ViewAutoDecisioning handleDiscard={this.discardView} />}
        </div>
      </div>
    );
  },
});

export default connect((state, ownProps) => {
  let rule_sets = [];
  if (state.add_ons.auto_decisions) {
    rule_sets = [
      ...state.add_ons.auto_decisions
        .filter((ad) => !ad.attributes.paused && !ad.attributes.on_hold)
        .sort(
          (a, b) => a.attributes.serial_number - b.attributes.serial_number
        ),
      ...state.add_ons.auto_decisions
        .filter((ad) => !ad.attributes.paused && ad.attributes.on_hold)
        .sort(
          (a, b) => a.attributes.serial_number - b.attributes.serial_number
        ),
      ...state.add_ons.auto_decisions
        .filter((ad) => ad.attributes.paused)
        .sort(
          (a, b) => a.attributes.serial_number - b.attributes.serial_number
        ),
    ];
  }

  return {
    rule_sets,
    loading: state.add_ons.auto_decisions_loading,
    updating_id: state.add_ons.auto_decisions_enabling_loading,
    location: ownProps.location,
    pricing_plan: state.pricing_plans.current_pricing_plan,
    current_rule_set: state.auto_decisions.current_rule_set,
    current_user: state.current_user,
  };
})(AutoDecisioningList);
