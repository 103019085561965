import React from 'react';
// @ts-ignore
import { browserHistory } from 'react-router';

import { CustomersContextProvider } from './CustomersContext';
import { CustomersContent, CustomersHeader } from './components';
import { DashboardPageContainer } from 'modules/shared/components';
import { FEATURE_FLAGS } from 'conf';

export const Customers = () => {
  const headerComponents = () => <CustomersHeader />;

  const contentComponents = () => <CustomersContent />;

  if (!FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIPELINE) {
    browserHistory.push('/dashboard/home');
    return null;
  }

  return (
    <CustomersContextProvider>
      <DashboardPageContainer
        pageTitle="Customer pipeline"
        headerComponents={headerComponents()}
        contentComponents={contentComponents()}
      />
    </CustomersContextProvider>
  );
};
