import diaryNotes from 'images/webhooks/diary-notes.png';
import vcfAlerts from 'images/webhooks/vcf-alerts.png';
import {
  DefinitionList,
  Figure,
  OrderedList,
  SectionDescription,
  SectionHeader,
  SubSection,
} from 'modules/webhooks/v2/components/V2Documentation/Content/styles';
import React, { Fragment, ReactElement } from 'react';
import {FEATURE_FLAGS} from "../../../../../../../conf";

const Events = (): ReactElement => (
  <Fragment>
    <SectionHeader>Events</SectionHeader>
    <SubSection>
      <SectionDescription>
        We currently support the following events:
      </SectionDescription>
      <OrderedList>
        <li>
          <DefinitionList>
            <dt>Approved application</dt>
            <dd>
              This webhook event is triggered when an application has been
              accepted.
            </dd>
            <dd>
              When the receiving endpoint responds with a success HTTP code, the
              application from 1Centre will be then flagged as loaded in system.
            </dd>
            <dd>
              <div>
                If 1Centre gets back a response with the payload containing
                account_number, 1Centre loads the account number in the diary
                notes section. Sample response needs to look like this:
                {`{"account_number": "123456789"}`}
              </div>
              <Figure>
                <img src={diaryNotes} alt="diary-notes" />
              </Figure>
            </dd>
          </DefinitionList>
        </li>
        <li>
          <DefinitionList>
            <dt>Completed application</dt>
            <dd>
              This webhook event is triggered when an application has been
              completed by the consumer.
            </dd>
            <dd>
              When the receiving endpoint responds with a success HTTP code, the
              application from 1Centre will be then flagged as loaded in system.
            </dd>
            <dd>
              <div>
                If 1Centre gets back a response with the payload containing
                account_number, 1Centre loads the account number in the diary
                notes section. Sample response needs to look like this:
                {`{"account_number": "123456789"}`}
              </div>
              <Figure>
                <img src={diaryNotes} alt="diary-notes" />
              </Figure>
            </dd>
          </DefinitionList>
        </li>
        <li>
          <DefinitionList>
            <dt>Declined application</dt>
            <dd>
              This webhook event is triggered when an application has been
              declined.
            </dd>
          </DefinitionList>
        </li>
        <li>
          <DefinitionList>
            <dt>Incomplete application</dt>
            <dd>
              This webhook event is triggered when a consumer starts the
              application but does not complete it within 24 hours.
            </dd>
            <dd>
              When the receiving endpoint responds with a success HTTP code, the
              application from 1Centre will be then flagged as loaded in system.
            </dd>
            <dd>
              The payload for this event is the same as the approved
              application, with fewer details
            </dd>
          </DefinitionList>
        </li>
        <li>
          <DefinitionList>
            <dt>Lead submitted</dt>
            <dd>
              This webhook event is triggered when a new lead is submitted
            </dd>
          </DefinitionList>
        </li>
        <li>
          <DefinitionList>
            <dt>Alert notification</dt>
            <dd>
              <div>
                This webhook event is triggered when there is a change in an
                application&apos;s alerts. The application&apos;s alerts can be
                found in the Virtual Credit File - Credit control tab.
              </div>
              <Figure>
                <img src={vcfAlerts} alt="vcf-alerts" />
              </Figure>
            </dd>
          </DefinitionList>
        </li>
      </OrderedList>
    </SubSection>
  </Fragment>
);

export default Events;
