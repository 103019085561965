import { default as BusinessInfo } from './BusinessInformation';
import BulkUploadBusinessInformation from './BulkUploadBusinessInformation';
import React from 'react';

const BusinessInformation = ({ application }) => {
  return application.isBulkApplication ? (
    <BulkUploadBusinessInformation application={application} />
  ) : (
    <BusinessInfo application={application} />
  );
};

export default BusinessInformation;
