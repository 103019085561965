import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

/* Import CSS */
import styles from './css/CommonReviewBusiness.css';

import LabeledInformation from 'modules/shared/components/widgets/static/LabeledInformation';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';

export default createClass({
  render: function() {
    const { data, default_page_size } = this.props;
    let contents,
      left = [],
      right = [];

    data.forEach((v, i) => {
      if (i % 2) {
        left.push(
          <LabeledInformation
            key={i}
            label={v.name}
            data={v.value}
            showEmpty={true}
          />
        );
      } else {
        right.push(
          <LabeledInformation
            key={i}
            label={v.name}
            data={v.value}
            showEmpty={true}
          />
        );
      }
    });

    contents = (
      <div>
        <ReviewContainer>
          <p>
            We are in the process of improving the display of our credit agency
            checks - new applications will display more detailed output.
          </p>
          <br />
        </ReviewContainer>
        <ReviewContainer>
          <div className={styles.col}>{left}</div>
          <div className={styles.col}>{right}</div>
        </ReviewContainer>
      </div>
    );

    return <div>{contents}</div>;
  },
});
