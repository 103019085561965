import Checkbox from 'modules/shared/components/inputs/Checkbox';
import React, { ReactElement } from 'react';

type handleArgs = {
  value: boolean;
};

interface Props {
  handleCheck: (args: handleArgs) => void;
  isChecked: boolean;
  userType: string;
}

function VirtualCreditFile({ handleCheck, isChecked }: Props): ReactElement {
  const item = {
    description: `
      By unselecting, the user will not be able to view the Virtual credit file after the Loaded in system checkbox has been checked.\
      All users will default to 'can' view.
    `,
    id: 'can_review_virtual_credit_file',
    label: 'Virtual credit file',
    type: 'page_permissions',
  };

  const onClick = () => handleCheck({ value: !isChecked, ...item });

  return (
    <Checkbox
      checkboxId="can_review_virtual_credit_file"
      checked={isChecked}
      handleChange={onClick}
      label={item.label}
      description={item.description}
    />
  );
}

export default VirtualCreditFile;
