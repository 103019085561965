import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import get from 'lodash.get';
import EntityModel from 'models/EntityModel';
import UserModel from 'models/UserModel';
import {
  loadApplicationBusiness,
  loadApplicationSections,
  loadApplicationSupplier,
  loadCurrentEntity,
  setIsBusinessOverviewLoaded,
} from 'modules/consumer-onboarding/actions/section';
import { availableEntityTypes } from 'modules/consumer-onboarding/components/onboarding/utils/disablePersonalEntityType';
import BusinessNumberField from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/BusinessNumberField';
import CompanyNumberField from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/CompanyNumberField';
import EntityName from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/EntityName';
import LegalTypeField from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/LegalTypeField';
import {
  Container,
  FieldWrapper,
  SectionHeader,
} from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/style';
import { FormValues } from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/type';
import { getBusinessNamesForRegion } from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/utils/businessDetails';
import setReduxStates from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/OverviewForm/utils/setReduxStates';
import InformationContent from 'modules/consumer-onboarding/components/onboarding/v2/BusinessOverview/RequestAccess/InformationContent';
import useRetrieveBusinessDetailsState from 'modules/consumer-onboarding/components/onboarding/v2/hooks/useRetrieveBusinessDetailsState';
import { getEntityFieldText } from 'modules/consumer-onboarding/helpers';
import Button from 'modules/shared/components/inputs/Button';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import { USER_LOAD_CURRENT_USER_SUCCESS } from 'modules/user/constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
// @ts-ignore-next-line: Type cannot be inferred
import { browserHistory } from 'react-router';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import objectKeysToSnakeCase from 'utils/objectKeysToSnakeCase';

const BUSINESS_NUMBER_FIELD_NAME = 'businessNumber';
const COMPANY_NAME_FIELD_NAME = 'companyName';
const COMPANY_NUMBER_FIELD_NAME = 'companyNumber';
const ENTITY_NAME_FIELD_NAME = 'entityName';
const LEGAL_TYPE_FIELD_NAME = 'legalType';
const REGISTERED_AT_FIELD_NAME = 'registeredAt';
const TRADING_NAME_FIELD_NAME = 'tradingName';

const retrieveCorrectName = ({
  defaultBusinessNumber,
  defaultName,
  newBusinessNumber,
  newName,
}) => {
  if (defaultBusinessNumber === newBusinessNumber && isBlank(newName)) {
    return defaultName;
  }

  return newName;
};

const resetFields = ({
  fieldNames,
  onSetConsumerIdToJoin,
  setHasEntityNameFromApi,
  setValue,
  defaultValues,
  resetToDefault = false,
}) => {
  fieldNames.forEach((fieldName) =>
    setValue(
      fieldName,
      resetToDefault && defaultValues[fieldName] ? defaultValues[fieldName] : ''
    )
  );
  setHasEntityNameFromApi(false);
  onSetConsumerIdToJoin(null, false);
};

const getDefaultBusinessNumber = ({
  businessOverviewState,
  cobSectionApplicationAttributes,
}) => {
  const businessNumberFromReduxState = get(
    cobSectionApplicationAttributes,
    'business_number'
  );
  const businessNumberFromOverviewState = get(
    businessOverviewState,
    'authorisationRequest.businessNumber'
  );

  return businessNumberFromReduxState || businessNumberFromOverviewState || '';
};

const getDefaultCompanyNumber = ({
  businessOverviewState,
  cobSectionApplicationAttributes,
}) => {
  const companyNumberFromReduxState = get(
    cobSectionApplicationAttributes,
    'company_number'
  );
  const companyNumberFromOverviewState = get(
    businessOverviewState,
    'authorisationRequest.companyNumber'
  );

  return companyNumberFromReduxState || companyNumberFromOverviewState || '';
};

const getConsumerIdToAssign = ({
  consumerId,
  consumerIdToJoin,
}): string | null => {
  if (consumerId) {
    return consumerId;
  }

  if (consumerIdToJoin) {
    return consumerIdToJoin;
  }

  return null;
};

const shouldSetValue = ({
  defaultValue,
  currentValue,
  hasEntityNameFromApi,
}) => {
  if (isBlank(defaultValue)) {
    return false;
  }

  if (!hasEntityNameFromApi) {
    return true;
  }

  return isPresent(defaultValue) && defaultValue !== currentValue;
};

const getIsEntityNameDisabled = ({
  consumerDetailsLocked,
  hasAuthorisationRequest,
  hasEntityNameFromApi,
  isSearching,
  legalType,
  region,
}) => {
  if (consumerDetailsLocked || hasAuthorisationRequest || isBlank(legalType)) {
    return true;
  }

  if (legalType === 'company' && region === 'NZ') {
    return false;
  }

  return isSearching || hasEntityNameFromApi;
};

const OverviewForm = (props): ReactElement => {
  const {
    application,
    applicationConsumerId,
    businessNumberLabel,
    businessOverviewState,
    companyNumberLabel,
    companyNumberDisabled,
    consumerDetailsLocked,
    currentUser,
    defaultValues,
    dispatch,
    isBusinessOverviewLoaded,
    onRetrieveDetailsWithBusinessNumber,
    onRetrieveDetailsWithCompanyNumber,
    region,
    supplier,
    validationSchema,
    isPrevApplicationBulk,
  } = props;

  const entityTypeOptions = availableEntityTypes(
    // @ts-ignore-next-line: EntityModel is not a TS file causing this type error
    supplier.customEntityTypeConfiguration
  );

  const [isEntityNameFocused, setIsEntityNameFocused] = useState(false);
  const [hasEntityNameFromApi, setHasEntityNameFromApi] = useState(false);
  const {
    authorisationRequest,
    consumerIdToJoin,
    onSetConsumerIdToJoin,
    isRequestAccessFormVisible,
  } = businessOverviewState;

  const { isSearching, onEndSearch, onStartSearch } =
    useRetrieveBusinessDetailsState();

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      [BUSINESS_NUMBER_FIELD_NAME]: defaultValues.businessNumber,
      [COMPANY_NAME_FIELD_NAME]: defaultValues.companyName,
      [COMPANY_NUMBER_FIELD_NAME]: defaultValues.companyNumber,
      [ENTITY_NAME_FIELD_NAME]: defaultValues.entityName,
      [LEGAL_TYPE_FIELD_NAME]: defaultValues.legalType,
      [REGISTERED_AT_FIELD_NAME]: defaultValues.registeredAt,
      [TRADING_NAME_FIELD_NAME]: defaultValues.tradingName,
    },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(() =>
      validationSchema({
        validEntityTypes: entityTypeOptions.map((option) => option.value),
      })
    ),
  });

  register(COMPANY_NAME_FIELD_NAME);
  register(LEGAL_TYPE_FIELD_NAME);
  register(REGISTERED_AT_FIELD_NAME);
  register(TRADING_NAME_FIELD_NAME);

  const onEmptyBusinessNumberResult = () =>
    setError(
      'businessNumber',
      {
        message: `Please enter a valid ${businessNumberLabel}.`,
        type: 'custom',
      },
      { shouldFocus: true }
    );

  const onBusinessNumberError = (errorMessage: string) =>
    setError(
      'businessNumber',
      {
        message: errorMessage,
        type: 'custom',
      },
      { shouldFocus: true }
    );

  const onEmptyCompanyNumberResult = () =>
    setError(
      'companyNumber',
      {
        message: `Please enter a valid ${companyNumberLabel}.`,
        type: 'custom',
      },
      { shouldFocus: true }
    );

  const onCompanyNumberError = (errorMessage: string) =>
    setError(
      'companyNumber',
      {
        message: errorMessage,
        type: 'custom',
      },
      { shouldFocus: true }
    );

  const setValueWithDefaults = (fieldName, value) => {
    if (!isPrevApplicationBulk) {
      setValue(fieldName, value);
    } else {
      setValue(fieldName, !value ? defaultValues[fieldName] : value);
    }
  };

  const onSetBusinessNumber = (businessNumber) =>
    onRetrieveDetailsWithBusinessNumber({
      businessNumber,
      onError: onBusinessNumberError,
      onEmptyResultCallback: onEmptyBusinessNumberResult,
      onEndEvent: onEndSearch,
      onSetConsumerIdToJoin,
      onSetValuesCallback: ({
        companyName,
        companyNumber,
        entityName,
        legalType,
        registeredAt,
        tradingName,
      }) => {
        // An entity name may be suppressed by NZBN. If this happens,
        // do not replace the entity name and trading name fields with an
        // empty value if for the same NZBN and use the already existing name
        // supplied by the user.
        const newEntityName = retrieveCorrectName({
          defaultBusinessNumber: defaultValues.businessNumber,
          defaultName: defaultValues.entityName,
          newBusinessNumber: businessNumber,
          newName: entityName,
        });
        setValueWithDefaults(ENTITY_NAME_FIELD_NAME, newEntityName);

        const newTradingName = retrieveCorrectName({
          defaultBusinessNumber: defaultValues.businessNumber,
          defaultName: defaultValues.tradingName,
          newBusinessNumber: businessNumber,
          newName: tradingName,
        });
        setValueWithDefaults(TRADING_NAME_FIELD_NAME, newTradingName);
        setHasEntityNameFromApi(isPresent(entityName));

        setValueWithDefaults(COMPANY_NAME_FIELD_NAME, companyName);
        setValueWithDefaults(COMPANY_NUMBER_FIELD_NAME, companyNumber);
        setValueWithDefaults(LEGAL_TYPE_FIELD_NAME, legalType);
        setValueWithDefaults(REGISTERED_AT_FIELD_NAME, registeredAt);
        clearErrors();
      },
      onStartEvent: onStartSearch,
    });

  const onSetCompanyNumber = (companyNumber) =>
    onRetrieveDetailsWithCompanyNumber({
      companyNumber,
      onError: onCompanyNumberError,
      onEmptyResultCallback: onEmptyCompanyNumberResult,
      onEndEvent: onEndSearch,
      onSetConsumerIdToJoin,
      onSetValuesCallback: ({
        businessNumber,
        companyName,
        entityName,
        legalType,
        registeredAt,
        tradingName,
      }) => {
        setValueWithDefaults(BUSINESS_NUMBER_FIELD_NAME, businessNumber);
        setValueWithDefaults(COMPANY_NAME_FIELD_NAME, companyName);
        setValueWithDefaults(ENTITY_NAME_FIELD_NAME, entityName);
        setValueWithDefaults(LEGAL_TYPE_FIELD_NAME, legalType);
        setValueWithDefaults(REGISTERED_AT_FIELD_NAME, registeredAt);
        setValueWithDefaults(TRADING_NAME_FIELD_NAME, tradingName);

        setHasEntityNameFromApi(isPresent(entityName));

        clearErrors();
      },
      onStartEvent: onStartSearch,
    });

  useEffect(() => {
    if (isBusinessOverviewLoaded) {
      return;
    }

    const { businessNumber, companyNumber } = defaultValues;
    const [currentBusinessNumber, currentCompanyNumber] = getValues([
      'businessNumber',
      'companyNumber',
    ]);

    const shouldSetBusinessNumber = shouldSetValue({
      currentValue: currentBusinessNumber,
      defaultValue: businessNumber,
      hasEntityNameFromApi,
    });
    const shouldSetCompanyNumber = shouldSetValue({
      currentValue: currentCompanyNumber,
      defaultValue: companyNumber,
      hasEntityNameFromApi,
    });

    if (shouldSetBusinessNumber) {
      onSetBusinessNumber(businessNumber);
    } else if (shouldSetCompanyNumber) {
      onSetCompanyNumber(companyNumber);
    }

    dispatch(setIsBusinessOverviewLoaded());
  }, [
    defaultValues.businessNumber,
    defaultValues.companyNumber,
    isBusinessOverviewLoaded,
  ]);

  const setValueIfEmpty = ({ defaultValue, fieldName }) => {
    const currentValue = getValues(fieldName);

    if (isPresent(currentValue)) {
      return;
    }

    setValue(fieldName, defaultValue);
  };

  useEffect(() => {
    if (isBusinessOverviewLoaded) {
      return;
    }

    setValueIfEmpty({
      defaultValue: defaultValues.businessNumber,
      fieldName: BUSINESS_NUMBER_FIELD_NAME,
    });

    setValueIfEmpty({
      defaultValue: defaultValues.companyName,
      fieldName: COMPANY_NAME_FIELD_NAME,
    });

    setValueIfEmpty({
      defaultValue: defaultValues.companyNumber,
      fieldName: COMPANY_NUMBER_FIELD_NAME,
    });

    setValueIfEmpty({
      defaultValue: defaultValues.entityName,
      fieldName: ENTITY_NAME_FIELD_NAME,
    });

    setValueIfEmpty({
      defaultValue: defaultValues.legalType,
      fieldName: LEGAL_TYPE_FIELD_NAME,
    });

    setValueIfEmpty({
      defaultValue: defaultValues.registeredAt,
      fieldName: REGISTERED_AT_FIELD_NAME,
    });

    setValueIfEmpty({
      defaultValue: defaultValues.tradingName,
      fieldName: TRADING_NAME_FIELD_NAME,
    });
  }, [defaultValues, isBusinessOverviewLoaded]);

  const onSubmit = async (data) => {
    if (isSearching) {
      return;
    }

    const attributes = objectKeysToSnakeCase(data);
    attributes['application_id'] = application.id;

    const consumerId = getConsumerIdToAssign({
      consumerId: applicationConsumerId,
      consumerIdToJoin,
    });

    if (consumerId) {
      attributes['consumer_id'] = consumerId;
    }

    const onboardingAPI = api('onboarding', currentUser.accessToken);

    try {
      const response = await onboardingAPI.entityDetails({ attributes });

      const entityId = get(response, 'data.entity_id');

      dispatch(
        setReduxStates({
          data,
          hasEntityNameFromApi,
          region,
        })
      );

      const userAPI = api('users', currentUser.accessToken);
      userAPI.getUser(
        currentUser.id,
        (response) => {
          dispatch({
            payload: { data: response.data, token_origin: origin },
            type: USER_LOAD_CURRENT_USER_SUCCESS,
          });

          dispatch(loadCurrentEntity(entityId));
        },
        (error) => {
          console.error(error);
        },
        { params: { include: 'user_entity_links' } }
      );

      dispatch(
        loadApplicationSupplier(application.id, () => {
          dispatch(loadApplicationSections(application.id));
          dispatch(
            loadApplicationBusiness(() =>
              browserHistory.push(
                `/register/consumer/${application.id}/business/other-details`
              )
            )
          );
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const bulkApplicationFieldEnabledCheck = (fieldName) => {
    if (!isPrevApplicationBulk) return false;
    switch (fieldName) {
      case BUSINESS_NUMBER_FIELD_NAME:
      case COMPANY_NAME_FIELD_NAME:
        return !defaultValues[fieldName];
      default:
        return hasEntityNameFromApi
          ? !watch(fieldName) && !defaultValues[fieldName]
          : !defaultValues[fieldName];
    }
  };

  return (
    <Container>
      <SectionHeader>Entity details</SectionHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns">
          <BusinessNumberField
            control={control}
            isDisabled={
              (consumerDetailsLocked || authorisationRequest.lockDetails) &&
              !bulkApplicationFieldEnabledCheck('businessNumber')
            }
            isLoading={isSearching}
            label={businessNumberLabel}
            placeholder={businessNumberLabel}
            labelShrink={isPresent(watch(BUSINESS_NUMBER_FIELD_NAME))}
            name={BUSINESS_NUMBER_FIELD_NAME}
            onReset={() => {
              resetFields({
                fieldNames: [
                  COMPANY_NAME_FIELD_NAME,
                  COMPANY_NUMBER_FIELD_NAME,
                  ENTITY_NAME_FIELD_NAME,
                  LEGAL_TYPE_FIELD_NAME,
                  REGISTERED_AT_FIELD_NAME,
                  TRADING_NAME_FIELD_NAME,
                ],
                onSetConsumerIdToJoin,
                setHasEntityNameFromApi,
                setValue,
                defaultValues,
                resetToDefault: isPrevApplicationBulk,
              });
            }}
            onRetrieveDetailsWithBusinessNumber={onSetBusinessNumber}
          />
          <CompanyNumberField
            control={control}
            isDisabled={
              (consumerDetailsLocked ||
                companyNumberDisabled ||
                authorisationRequest.lockDetails) &&
              (companyNumberDisabled ||
                !bulkApplicationFieldEnabledCheck('companyNumber'))
            }
            isLoading={isSearching}
            label={companyNumberLabel}
            placeholder={companyNumberLabel}
            labelShrink={isPresent(watch(COMPANY_NUMBER_FIELD_NAME))}
            name={COMPANY_NUMBER_FIELD_NAME}
            onReset={() => {
              resetFields({
                fieldNames: [
                  BUSINESS_NUMBER_FIELD_NAME,
                  COMPANY_NAME_FIELD_NAME,
                  ENTITY_NAME_FIELD_NAME,
                  LEGAL_TYPE_FIELD_NAME,
                  REGISTERED_AT_FIELD_NAME,
                  TRADING_NAME_FIELD_NAME,
                ],
                onSetConsumerIdToJoin,
                setHasEntityNameFromApi,
                setValue,
                defaultValues,
                resetToDefault: isPrevApplicationBulk,
              });
            }}
            onRetrieveDetailsWithCompanyNumber={onSetCompanyNumber}
            region={region}
          />
        </div>
        <FieldWrapper className="columns mt-4 ">
          <LegalTypeField
            entityTypeOptions={entityTypeOptions}
            errorMessage={get(errors, `${LEGAL_TYPE_FIELD_NAME}.message`)}
            isDisabled={
              (consumerDetailsLocked || authorisationRequest.lockDetails) &&
              !bulkApplicationFieldEnabledCheck('legalType')
            }
            name={LEGAL_TYPE_FIELD_NAME}
            onReset={() => {
              resetFields({
                fieldNames: [
                  BUSINESS_NUMBER_FIELD_NAME,
                  COMPANY_NAME_FIELD_NAME,
                  COMPANY_NUMBER_FIELD_NAME,
                  ENTITY_NAME_FIELD_NAME,
                  REGISTERED_AT_FIELD_NAME,
                  TRADING_NAME_FIELD_NAME,
                ],
                onSetConsumerIdToJoin,
                setHasEntityNameFromApi,
                setValue,
                defaultValues,
                resetToDefault: isPrevApplicationBulk,
              });
              clearErrors();
            }}
            setValue={setValue}
            value={watch(LEGAL_TYPE_FIELD_NAME)}
          />
          <div className="column">
            <EntityName
              businessNumber={watch(BUSINESS_NUMBER_FIELD_NAME)}
              control={control}
              currentUser={currentUser}
              entityName={watch(ENTITY_NAME_FIELD_NAME)}
              isSearchable={
                region === 'NZ' && watch(LEGAL_TYPE_FIELD_NAME) === 'company'
              }
              name={ENTITY_NAME_FIELD_NAME}
              label={getEntityFieldText(defaultValues.legalType)}
              labelShrink={
                isPresent(watch(ENTITY_NAME_FIELD_NAME)) || isEntityNameFocused
              }
              onBlur={() => setIsEntityNameFocused(false)}
              onFocus={() => setIsEntityNameFocused(true)}
              onReset={() => {
                resetFields({
                  fieldNames: [
                    BUSINESS_NUMBER_FIELD_NAME,
                    COMPANY_NAME_FIELD_NAME,
                    COMPANY_NUMBER_FIELD_NAME,
                    ENTITY_NAME_FIELD_NAME,
                    REGISTERED_AT_FIELD_NAME,
                    TRADING_NAME_FIELD_NAME,
                  ],
                  onSetConsumerIdToJoin,
                  setHasEntityNameFromApi,
                  setValue,
                  defaultValues,
                  resetToDefault: isPrevApplicationBulk,
                });
              }}
              onSetBusinessNumber={(businessNumber) => {
                setValue(BUSINESS_NUMBER_FIELD_NAME, businessNumber);
                onSetBusinessNumber(businessNumber);
              }}
              placeholder={getEntityFieldText(defaultValues.legalType)}
              readOnly={
                getIsEntityNameDisabled({
                  consumerDetailsLocked,
                  hasAuthorisationRequest: authorisationRequest.lockDetails,
                  hasEntityNameFromApi,
                  isSearching,
                  legalType: watch(LEGAL_TYPE_FIELD_NAME),
                  region,
                }) && !bulkApplicationFieldEnabledCheck('entityName')
              }
              region={region}
            />
          </div>
        </FieldWrapper>
        {!isRequestAccessFormVisible && (
          <div className="has-text-centered-mobile">
            <Button text="Next" type="submit" />
          </div>
        )}
      </form>
    </Container>
  );
};

OverviewForm.defaultProps = {
  companyNumberDisabled: false,
};

export default connect((state, ownProps) => {
  const { businessOverviewState, region } = ownProps;
  const isBusinessOverviewLoaded =
    get(state, 'cob_section.isBusinessOverviewLoaded', false) &&
    businessOverviewState.isAuthorisationRequestLoaded;
  const cobSectionApplicationAttributes = get(
    state,
    'cob_section.application.attributes',
    {}
  );

  const consumerId = cobSectionApplicationAttributes.consumer_id;
  const supplier = new EntityModel(get(state, 'cob_section.supplier'));

  const legalType = get(
    cobSectionApplicationAttributes,
    'consumer_legal_type',
    ''
  );

  // Enable legal type selection if there's no legal type for bulk applications only
  const previousApplicationSource = get(
    cobSectionApplicationAttributes,
    'prev_application_source'
  );
  const isPrevApplicationBulk =
    FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER &&
    previousApplicationSource === 'bulk';

  const { companyName, entityName, tradingName } = getBusinessNamesForRegion({
    applicationAttributes: cobSectionApplicationAttributes,
    legalType,
    region,
  });

  return {
    applicationConsumerId: consumerId,
    consumerDetailsLocked:
      cobSectionApplicationAttributes.consumer_details_locked,
    currentUser: UserModel.fromCurrentUser(state.current_user),
    isPrevApplicationBulk,
    defaultValues: {
      businessNumber: getDefaultBusinessNumber({
        businessOverviewState,
        cobSectionApplicationAttributes,
      }),
      companyName,
      companyNumber: getDefaultCompanyNumber({
        businessOverviewState,
        cobSectionApplicationAttributes,
      }),
      entityName,
      legalType,
      registeredAt: get(cobSectionApplicationAttributes, 'registered_at', ''),
      tradingName,
    },
    isBusinessOverviewLoaded,
    supplier,
  };
})(OverviewForm);
