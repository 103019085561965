import 'react-table/react-table.css';

import Button from 'modules/shared/components/inputs/Button';
import Clickable from 'modules/shared/components/widgets/interactive/Clickable';
import React from 'react';
/* Import components */
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import logo from '../../../images/logo-equifax.png';
/* Import CSS */
import styles from './css/CommonReviewBusiness.css';
import vedaStyles from './css/VedaV2CommercialCheck.css';

import { FEATURE_FLAGS } from 'conf';

const SUMMARY_MAPPING = {
  organisationStatus: 'Status',
  enquiriesCount: 'Enquiries',
  securitiesCount: 'Securities',
  averagePaymentDays: 'Payment Days',
  defaultsCount: 'Defaults',
  judgementsCount: 'Judgements',
};

const CREDIT_ACTIVITY_MAPPING = {
  ageOfCreditFile: 'Age Of Credit File',
  collectionAgencyDefaultsLast12Months:
    'Collection Agency Defaults < 12 Months',
  collectionAgencyDefaultsOver12Months:
    'Collection Agency Defaults > 12 Months',
  courtJudgmentsLast12Months: 'Court Judgments < 12 Months',
  courtJudgmentsOver12Months: 'Court Judgments > 12 Months',
  creditorDefaultsLast12Months: 'Creditor Defaults < 12 Months',
  creditorDefaultsOver12Months: 'Creditor Defaults > 12 Months',
  enquiriesCount: 'Enquiries',
  publicNoticesCount: 'Public Notices',
  registeredSecuritiesCount: 'Registered Securities',
  tradeSuppliersCount: 'Trade Suppliers',
};

const RISK_ODDS_MAPPING = {
  'Late Payment Risk': 'LatePaymentRisk',
  'Default Risk': 'DefaultRisk',
  'Business Failure Risk': 'BusinessFailureRisk',
};

var VedaV2CommercialCheck = createClass({
  getInitialState: function() {
    return {
      isTooltipActive: false,
      riskIndex: null,
    };
  },

  showTooltip: function(index) {
    this.setState({ isTooltipActive: true, riskIndex: index });
  },

  hideTooltip: function() {
    this.setState({ isTooltipActive: false, riskIndex: null });
  },

  _scoreGraph() {
    const { data } = this.props;
    if (!data.score || !data.score.scores) return null;

    let score = data.score.scores.find((item) => {
      return item.type === 'Business';
    });

    if (!score) return null;

    let businessScore = score.score,
      maxValue = 1200,
      valuePosition = 0;

    if (businessScore < 0) businessScore = 0;
    valuePosition = ((businessScore / maxValue) * 100).toString() + '%';

    return (
      <div className={styles.overview}>
        <div className={styles.overview_rating_full}>
          <div className={styles.liner}>
            <div className={styles.overview_rating_graph}>
              <div
                className={styles.overview_rating_indicator}
                style={{ left: valuePosition }}
              >
                {businessScore}
              </div>
              <div className={vedaStyles.overview_rating_graph_bg_reverse} />
              <span className={styles.overview_rating_graph_0}>0</span>
              <span className={styles.overview_rating_graph_30}>300</span>
              <span className={styles.overview_rating_graph_60}>600</span>
              <span className={styles.overview_rating_graph_90}>900</span>
              <span className={styles.overview_rating_graph_120}>1200</span>
            </div>
          </div>
        </div>
      </div>
    );
  },

  _scoreRisks() {
    const { data } = this.props;
    if (!data.score || !data.score.risks) return null;

    let riskList = [];

    data.score.risks.map((risk, index) => {
      let riskMessage = [];

      risk.messages.map((msg) => {
        riskMessage.push(<div>{msg.text}</div>);
      });

      let warning_style;
      if (
        (risk.name == 'Late Payment Risk' &&
          0 < risk.percentageValue &&
          risk.percentageValue <= 2.0) ||
        (risk.name == 'Default Risk' &&
          0 < risk.percentageValue &&
          risk.percentageValue <= 0.9) ||
        (risk.name == 'Business Failure Risk' &&
          0 < risk.percentageValue &&
          risk.percentageValue <= 0.3)
      ) {
        warning_style = vedaStyles.score_risk_green;
      } else if (
        (risk.name == 'Late Payment Risk' &&
          2.0 < risk.percentageValue &&
          risk.percentageValue <= 8.2) ||
        (risk.name == 'Default Risk' &&
          0.9 < risk.percentageValue &&
          risk.percentageValue <= 11.1) ||
        (risk.name == 'Business Failure Risk' &&
          0.3 < risk.percentageValue &&
          risk.percentageValue <= 5.0)
      ) {
        warning_style = vedaStyles.score_risk_yellow;
      } else if (
        (risk.name == 'Late Payment Risk' && risk.percentageValue > 8.2) ||
        (risk.name == 'Default Risk' && risk.percentageValue > 11.1) ||
        (risk.name == 'Business Failure Risk' && risk.percentageValue > 5.0)
      ) {
        warning_style = vedaStyles.score_risk_red;
      }

      let percentage =
        risk.percentageValue == 0 && riskMessage.length > 0 ? (
          <span className={vedaStyles.icon_warning}></span>
        ) : (
          risk.percentageValue + '%'
        );

      let tool_tip, tip_name;
      tip_name = RISK_ODDS_MAPPING[risk.name];

      if (
        this.state.isTooltipActive &&
        this.state.riskIndex === index &&
        tip_name
      ) {
        tool_tip = (
          <ToolTip tip_name={tip_name} css_style={'veda_commercial'} />
        );
      }

      riskList.push(
        <div
          key={`risk-${index}`}
          className={vedaStyles.score_risk_col + ' ' + warning_style}
          onMouseEnter={() => this.showTooltip(index)}
          onMouseLeave={this.hideTooltip}
        >
          <div className={vedaStyles.score_risk_name}>{risk.name}</div>
          <div className={vedaStyles.score_risk_percentage}>{percentage}</div>
          <div className={vedaStyles.score_risk_message}>{riskMessage}</div>
          {tool_tip}
        </div>
      );
    });

    return (
      <div className={vedaStyles.row}>
        <div className={vedaStyles.score_risk_row}>{riskList}</div>
      </div>
    );
  },

  _summary() {
    const { data } = this.props;
    if (!data.summary) return null;

    let summaryList = [];

    Object.keys(data.summary).map((item, index) => {
      let summary_style = vedaStyles[item];
      if (
        (item == 'organisationStatus' &&
          data.summary[item].toLowerCase() === 'active') ||
        (item == 'averagePaymentDays' &&
          1 <= data.summary[item] &&
          data.summary[item] <= 30) ||
        (item == 'defaultsCount' && data.summary[item] == 0) ||
        (item == 'judgementsCount' && data.summary[item] == 0)
      ) {
        summary_style = vedaStyles[item + '_good'];
      } else if (
        (typeof data.summary[item] === 'string' &&
          data.summary[item].toLowerCase() === 'inactive') ||
        (item == 'averagePaymentDays' && data.summary[item] >= 91) ||
        (item == 'defaultsCount' && data.summary[item] >= 1) ||
        (item == 'judgementsCount' && data.summary[item] >= 1)
      ) {
        summary_style = vedaStyles[item + '_bad'];
      } else if (
        item == 'averagePaymentDays' &&
        31 <= data.summary[item] &&
        data.summary[item] <= 60
      ) {
        summary_style = vedaStyles[item + '_yellow'];
      } else if (
        item == 'averagePaymentDays' &&
        61 <= data.summary[item] &&
        data.summary[item] <= 90
      ) {
        summary_style = vedaStyles[item + '_orange'];
      }

      summaryList.push(
        <div key={`summary-${index}`} className={vedaStyles.score_summary_col}>
          <div>
            <span className={summary_style}></span>
          </div>
          <div className={vedaStyles.score_summary_name}>
            {SUMMARY_MAPPING[item]}
          </div>
          <div>{data.summary[item]}</div>
        </div>
      );
    });

    return (
      <div className={vedaStyles.row}>
        <div className={vedaStyles.score_summary_row}>{summaryList}</div>
      </div>
    );
  },

  _keyfactors() {
    const { data } = this.props;
    if (
      !data.score ||
      !data.score.keyFactors ||
      data.score.keyFactors.length < 1
    )
      return null;

    let keyFactorsList = [];

    data.score.keyFactors.map((factor, index) => {
      keyFactorsList.push(
        <div className={vedaStyles.score_keyfactors_row}>
          <div className={vedaStyles.score_keyfactors_number_col}>
            <span className={vedaStyles.score_keyfactors_number}>
              {index + 1}
            </span>
          </div>
          <div className={vedaStyles.score_keyfactors_name_col}>
            <div className={vedaStyles.score_keyfactors_name}>
              {factor.name}
            </div>
            <div>{factor.desc}</div>
          </div>
          <div className={vedaStyles.score_keyfactors_impact_col}>
            <div className={vedaStyles[factor.impact.toLowerCase()]}>
              {factor.impact}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className={vedaStyles.row}>
        <div className={vedaStyles.score_keyfactors}>
          <div className={vedaStyles.score_keyfactors_row}>
            <div className={vedaStyles.score_keyfactors_title_col}>
              <div className={vedaStyles.score_keyfactors_name}>
                Top Four Key Scoring Factors
              </div>
              <div className={vedaStyles.score_keyfactors_desc}>
                This section shows key credit attributes and observations that
                influence the scoring outcomes(s), such as registration status,
                enquiry patterns, recent and historical payments and the
                presence or otherwise of any adverse events. The factors can be
                positive or negative and are ranked in order of importance and
                magnitude.
              </div>
            </div>
            <div className={vedaStyles.score_keyfactors_impact_col}>
              <div>Impact</div>
            </div>
          </div>
          {keyFactorsList}
        </div>
      </div>
    );
  },

  _creditActivity() {
    const { data } = this.props;
    if (!data.credit_activity) return null;

    let activityList = [];

    Object.keys(data.credit_activity).map((item, index) => {
      let credit_activity_value =
        data.credit_activity[item] == 0 ? '-' : data.credit_activity[item];
      activityList.push(
        <div
          key={`credit_activity-${index}`}
          className={vedaStyles.credit_activity_row}
        >
          <div className={vedaStyles.half_col}>
            {CREDIT_ACTIVITY_MAPPING[item]}
          </div>
          <div className={vedaStyles.half_col}>{credit_activity_value}</div>
        </div>
      );
    });

    return <div className={vedaStyles.row}>{activityList}</div>;
  },

  _pdf() {
    const { data, check_attributes } = this.props;

    if (!data.pdf) return null;

    let pdf_url = data.pdf;

    if (FEATURE_FLAGS.FEATURE_FLAG_SECURE_S3 && check_attributes && check_attributes.signed_pdf) {
      pdf_url = check_attributes.signed_pdf
    }

    return (
      <Clickable target={() => this._downloadPdf(pdf_url)}>
        <Button css_style="button_white_outer" text="download pdf" />
      </Clickable>
    );
  },

  _downloadPdf: function(url) {
    var win = window.open(url, 'equfiax_pdf_window');
  },

  render: function() {
    const { data, check_attributes } = this.props;
    let errorMessage =
      'Oops! Something went wrong, please contact 1Centre for more details.';
    if (!data && ['completed', 'errored'].includes(check_attributes.status)) {
      if (check_attributes.status == 'errored' && check_attributes.raw_data) {
        errorMessage = check_attributes.raw_data;
      }

      return (
        <div>
          <ReviewContainer
            subHeading={'Equifax'}
            content_class="content_wide"
            css_class="block_noborder"
            fontSize="medium"
          >
            <p>{errorMessage}</p>
            <p>
              Contact{' '}
              <a href="mailto:support@1centre.com">support@1centre.com</a> or
              live chat with us.
            </p>
          </ReviewContainer>
        </div>
      );
    } else if (!data) {
      return null;
    }

    return (
      <div>
        <div className={styles.equifax_logo}>
          <img src={logo} alt="Equifax" width="277.9" height="79.1" />
        </div>
        <ReviewContainer
          subHeading="Report Summary"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._scoreGraph()}
          {this._scoreRisks()}
          {this._summary()}
          {this._keyfactors()}
        </ReviewContainer>
        <ReviewContainer
          subHeading="Business Credit Activity Summary"
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._creditActivity()}
        </ReviewContainer>
        <ReviewContainer
          content_class="content_wide"
          css_class="block_noborder"
          fontSize="medium"
        >
          {this._pdf()}
        </ReviewContainer>
      </div>
    );
  },
});

export default VedaV2CommercialCheck;
