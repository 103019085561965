/* eslint-disable max-classes-per-file */
export function getObjectFromArray(array, type) {
  let item = null;

  if (!array) return item;

  array.forEach((element) => {
    if (element.type === type) item = element;
  });

  return item;
}
/* eslint-enable max-classes-per-file */
