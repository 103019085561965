import Helmet from 'react-helmet';
import React from 'react';
import styles from './css/AdminInnerTitle.css';

export default function AdminInnerTitle(props) {
  const { text, isNormalFont } = props;
  let titleStyle = styles.title;

  if (isNormalFont) {
    titleStyle += ` ${styles.title_normal_font}`;
  }

  return (
    <div className={styles.topbar}>
      <Helmet title={text} />
      <h2 className={titleStyle}>{text}</h2>
    </div>
  );
}

AdminInnerTitle.defaultProps = {
  isNormalFont: false,
};
