import React, { useContext } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';

// Use SortableTreeWithoutDndContext to avoid the error of multipple HTML5 backends thrown by react-dnd
import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';

import { CustomerViewContext } from './CustomerViewContext';
import { DND_TYPE, MAX_TREE_DEPTH } from './constants';
import { GroupMenu } from './GroupMenu';
import { EditGroup } from './EditGroup';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Container = styled.div`
  flex: 2;
  .rst__rowLabel {
    flex: 1;
    padding-right: 0;
    .rst__rowTitle {
      width: 100%;
      font-weight: initial !important;
    }
  }
  .rst__moveHandle {
    background-color: var(--main-color);
  }
`;

const AddButton = styled(Fab)`
  position: sticky !important;
  bottom: 24px;
`;

export const Structure = () => {
  const { treeData, setTreeData, addNode, editPath, isAddDisabled } =
    useContext(CustomerViewContext);

  return (
    <Container>
      Customer store structure
      {treeData.length ? (
        <SortableTree
          treeData={treeData}
          onChange={setTreeData}
          dndType={DND_TYPE}
          canDrag={({ treeIndex }) => treeIndex > 0}
          maxDepth={MAX_TREE_DEPTH}
          canDrop={({ nextParent, node }) => {
            if (!nextParent) {
              // The tree can only have a single root node, which is the HQ
              return false;
            }

            return (
              // A t3 node can be dropped only under a group node
              (node.type === 't3' && nextParent.type === 'group') ||
              // A group node can be dropped only under the HQ node
              (node.type === 'group' && nextParent.type === 'hq')
            );
          }}
          generateNodeProps={({ node, path }) => {
            return {
              title: isEqual(path, editPath) ? (
                <EditGroup group={node} path={path} />
              ) : null,
              buttons:
                node.type === 'group' && !isEqual(path, editPath)
                  ? [<GroupMenu groupName={node.title} path={path} />]
                  : [],
            };
          }}
        />
      ) : (
        <SkeletonContainer>
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
          <Skeleton variant="rect" width={500} height={24} />
        </SkeletonContainer>
      )}
      {Boolean(treeData.length) && (
        <AddButton color="primary" onClick={addNode} disabled={isAddDisabled}>
          <Add />
        </AddButton>
      )}
    </Container>
  );
};
