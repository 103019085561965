import React, { Component } from 'react';

import styles from 'modules/shared/components/text/css/StandardTermsAndConditions.css';

export default class AgreementSection2 extends Component {
  render() {
    return (
      <div>
        <p className={styles.list_type_1}>
          <strong>
            <span className={styles.copy_number}>2.</span> Commencement and Term
          </strong>
        </p>
        <p className={styles.list_type_1}>
          <span className={styles.copy_number}>2.1</span> <strong>Term</strong>:
          This agreement shall come into effect on the Commencement Date and
          shall continue until terminated in accordance with clause 9
          (Termination) or until either party gives at least ninety (90) days’
          notice to the other party of termination.
        </p>
      </div>
    );
  }
}
