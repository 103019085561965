import axios from './axios';

export default class ReportingTradingHours {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'reporting_trading_hours';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
    this.path = `/${this.type}`;
  }

  get(attributes) {
    return this.axios.get(`/reports/trading_hours`, {
      data: attributes,
      params: attributes.params,
    });
  }
}
