import React, { ReactElement, useEffect, useState } from 'react';
import Modal from 'modules/consumer-invite/v2/ConsumerInvite/Modal';
import { connect } from 'react-redux';
// @ts-ignore-next-line
import { browserHistory } from 'react-router';
import get from 'lodash.get';
import { loadCurrentEntity } from 'modules/profile/actions';
import { loadInternalUseFieldsAddon } from 'modules/addons/actions';
import isPresent from 'utils/isPresent';
import { hasActiveIUFSearchSendConfig } from 'modules/shared/helpers/internalUseFieldsHelper';
import getCanSetAccountRules from 'modules/consumer-invite/v2/ConsumerInvite/utils/getCanSetAccountRules';

const ConsumerInvite = (props): ReactElement | null => {
  const {
    applicationType,
    currentEntity,
    currentUserProfile,
    dispatch,
    internalUseFieldsLoaded,
  } = props;

  const [isFullPage, setIsFullPage] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(loadCurrentEntity());
    dispatch(loadInternalUseFieldsAddon());
  }, []);

  useEffect(() => {
    setIsLoaded(
      isPresent(currentEntity) &&
        isPresent(currentUserProfile) &&
        internalUseFieldsLoaded
    );
  }, [currentEntity, currentUserProfile, internalUseFieldsLoaded]);

  useEffect(() => {
    const canSetAccountRules = getCanSetAccountRules({
      applicationType,
      currentEntity,
      currentUserProfile,
    });

    setIsFullPage(
      canSetAccountRules || hasActiveIUFSearchSendConfig(applicationType)
    );
  }, [applicationType, currentEntity, currentUserProfile, isLoaded]);

  if (isLoaded) {
    if (isFullPage) {
      browserHistory.push(`/dashboard/invite/${applicationType}`);
      return null;
    }

    return <Modal {...props} />;
  }

  return null;
};

export default connect((state) => {
  const manageProfileState = get(state, 'manage_profile', {});
  const addOnsState = get(state, 'add_ons', {});

  return {
    currentEntity: manageProfileState.current_entity,
    currentUserProfile: manageProfileState.current_user_profile,
    internalUseFields: addOnsState.internal_use_fields || [],
    internalUseFieldsLoaded: addOnsState.internal_use_fields_loaded,
  };
})(ConsumerInvite);
