import React from 'react';

/* Import styles */
import styles from './css/Checkbox.css';

function Checkbox(props) {
  const {
    checkboxId,
    checked,
    handleChange,
    label,
    numOfRow,
    description,
    disabled,
    divStyle,
    noFloat,
  } = props;
  let numOfRowStyle = null;
  let labelContent;
  const checkboxStyleName = disabled
    ? 'custom_checkbox_disabled'
    : 'custom_checkbox';

  if (numOfRow) {
    numOfRowStyle = styles[numOfRow];
  }
  if (description) {
    labelContent = (
      <label htmlFor={checkboxId}>
        {label}
        <span className={styles.description}>{description}</span>
      </label>
    );
  } else {
    labelContent = <label htmlFor={checkboxId}>{label}</label>;
  }

  const processedDivStyle = { ...divStyle };

  if (noFloat) {
    processedDivStyle['float'] = 'none';
  }

  return (
    <div
      className={`${styles.position_relative} ${numOfRowStyle}`}
      style={processedDivStyle}
    >
      <input
        className={styles[checkboxStyleName]}
        id={checkboxId}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      {labelContent}
    </div>
  );
}

export default Checkbox;
