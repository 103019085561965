import {
  COB_PAPERLESS_CLEAR_ANSWER,
  COB_PAPERLESS_COMPONENT_COMPLETE,
  COB_PAPERLESS_LOAD_ANSWER,
  COB_PAPERLESS_SET_ANSWER,
} from '../constants/paperless';
import { updateAddonAnswerData } from './review';

export function paperlessComponentComplete(component, state) {
  return (dispatch, getState) => {
    let meta = {};

    if (state === true) {
      meta = {
        mixpanel: {
          event: 'Consumer onboarding',
          props: {
            Component: component,
            Section: 'paperless',
            distinct_id: getState().current_user.data.data.id,
          },
        },
      };
    }

    dispatch({
      meta,
      payload: {
        component,
        state,
      },
      type: COB_PAPERLESS_COMPONENT_COMPLETE,
    });
  };
}

export function loadPaperlessAnswers(params) {
  const data = {
    addon_rule_id: params.attributes.addon_rule_id,
    answers: params.attributes.answers,
    id: params.id,
  };
  return (dispatch) => {
    dispatch({
      payload: data,
      type: COB_PAPERLESS_LOAD_ANSWER,
    });
  };
}

export const setPaperlessAnswer = (params) => ({
  payload: params,
  type: COB_PAPERLESS_SET_ANSWER,
});

export function clearPaperless() {
  return (dispatch, getState) => {
    const application = getState().cob_section.application;
    const paperlessAddon = getState().cob_section.addonRules.find(
      ({ attributes }) => attributes.addon_module_name === 'paperless'
    );

    dispatch({
      type: COB_PAPERLESS_CLEAR_ANSWER,
    });

    dispatch(
      updateAddonAnswerData(application.id, paperlessAddon.id, 'paperless')
    );
  };
}
