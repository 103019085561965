import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { countTypeValue } from './helpers/summary';
import { displayEmpty } from 'utils/formatting';
import { enquiryResponseData } from './helpers/reportHelpers';
import { organisationType } from './helpers/codeTable';
import { stringToSimpleDate } from 'utils/dateFormatter';
import styles from '../../../css/CommonReviewBusiness.css';

var SoleIndividual = createClass({
  _individualName(name) {
    if (name === null || name === undefined) return displayEmpty('');

    return ''.concat(
      name.family_name,
      ', ',
      name.first_given_name,
      ' ',
      name.other_given_name
    );
  },

  _soleIndividual() {
    const { data, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      businessProprietors = response.business_proprietors || {},
      soleOwnership = businessProprietors.sole_ownership || {};

    if (!soleOwnership.hasOwnProperty('individual_proprietor')) return null;

    let individualProprietor = soleOwnership.individual_proprietor || {};

    return (
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <tbody>
            <tr>
              <th>Name</th>
              <td>
                {this._individualName(individualProprietor.individual_name)}
              </td>
            </tr>
            <tr>
              <th>Previous Name</th>
              <td>
                {this._individualName(
                  individualProprietor.individual_previous_name
                )}
              </td>
            </tr>
            <tr>
              <th>ABN</th>
              <td>
                {displayEmpty(individualProprietor.australian_business_number)}
              </td>
            </tr>
            <tr>
              <th>Start Date</th>
              <td>
                {stringToSimpleDate(individualProprietor.appointment_date)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },

  render() {
    const { data, isAllExpanded, entityType } = this.props;

    let response = enquiryResponseData(data, entityType),
      summaryData = response.summary_data || {},
      summaryEntry = summaryData.summary_entry || [];

    return (
      <ReviewContainer
        subHeading="Sole Individual"
        content_class="content_wide"
        css_class="block_noborder"
        collapsible={true}
        isAllExpanded={isAllExpanded}
        fontSize="medium"
      >
        <p>
          <strong>
            Total: {countTypeValue(summaryEntry, 'Sole_Individual_Proprietors')}
          </strong>
        </p>
        {this._soleIndividual()}
      </ReviewContainer>
    );
  },
});

export default SoleIndividual;
