import get from 'lodash.get';
import ApplicationModel from 'models/ApplicationModel';
import AuthorisationModel from 'models/AuthorisationModel';
import getApplicableAntiFraudRules from 'utils/anti-fraud/getApplicableAntiFraudRules';

const getAuthorisationRoles = ({ authorisation, authorisationState }) => {
  const roles: string[] = [];

  if (authorisation.isCardholder && authorisationState.cardholderApproved) {
    roles.push('cardholder');
  }

  if (authorisation.isGuarantor && authorisationState.guarantorApproved) {
    roles.push('guarantor');
  }

  if (authorisation.isPayment && authorisationState.paymentApproved) {
    roles.push('direct_debit');
  }

  if (authorisation.isSignatory && authorisationState.signatoryApproved) {
    roles.push('signatory');
  }

  return roles;
};

const getApplicableAntiFraudRulesForAuthorisationFlow = (
  authorisationState
) => {
  const authorisation = new AuthorisationModel(
    get(authorisationState, 'data.authorisation')
  );

  // Get application from authorisation
  const application = new ApplicationModel(
    get(authorisationState, 'data.application')
  );

  // Get requestedLimit from application
  // @ts-ignore-next-line: ApplicationModel is a JS file
  const requestedLimit = application.tradeAccountLimit;

  const authorisationRoles = getAuthorisationRoles({
    authorisation,
    authorisationState,
  });

  return getApplicableAntiFraudRules({
    antiFraud: authorisationState.antiFraud,
    application,
    authorisationRoles,
    requestedLimit,
  });
};

export default getApplicableAntiFraudRulesForAuthorisationFlow;
