import React from 'react';

import './panel.scss';

interface SideMenuPanel {
  children?: any;
  className?: string;
}

const SideMenuPanel = ({ children, className }: SideMenuPanel) => {
  return <div className={'sidemenu-panel' + ` ${className}`}>{children}</div>;
};

export default SideMenuPanel;
