import React from 'react';

/* Import CSS */
import styles from './css/SectionDescription.css';
import DOMPurify from 'dompurify';

var SectionDescription = createClass({
  render: function() {
    const { text } = this.props;
    return (
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
      ></p>
    );
  },
});

export default SectionDescription;
