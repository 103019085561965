/* Import libraries */
/* Import Components */
import Icon from 'modules/shared/components/svg/Icon';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React, { useState } from 'react';
import { getTipText } from 'utils/tipHelper.js';

/* Import CSS */
import styles from './css/CircleIconButton.css';

function CircleIconButton({
  customTipMessage,
  disabled,
  handleClick,
  icon_label,
  icon,
  isTextNormal,
  selected,
  size,
  small,
  text,
  tip_name,
  type,
  value,
}) {
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  function hideTooltip() {
    setIsTooltipActive(false);
  }

  function renderTitle({ isTextNormal, title }) {
    if (isTextNormal) {
      return <div className={styles.title_normal}>{title}</div>;
    }

    return <h3 className={styles.title}>{title}</h3>;
  }

  function showTooltip() {
    setIsTooltipActive(true);
  }

  let style = styles.item;
  let title = text;
  let badge_val = null;
  let icon_class = styles[type];
  let badge_title;
  let badge_outer = styles.badge_outer;
  let tool_tip;
  let badge_description;

  if (type == 'number') {
    title = null;
    badge_val = text;
  }

  if (type != 'number' && !icon) {
    title = null;
    badge_val = text;
    icon_class = styles.badge;
  }

  if (icon) {
    badge_val = (
      <span className={styles.icon}>
        <Icon icon={icon} size={size} />
      </span>
    );
    icon_class = styles.badge;
  }

  if (icon_label) {
    badge_title = <span className={styles.subtitle}>{icon_label}</span>;
  }

  if (selected === true) {
    style = styles.item_selected;
  }

  if (title !== null) {
    title = renderTitle({ isTextNormal, title });
  }

  if (small === true) {
    icon_class = [icon_class, styles.small].join(' ');
    badge_outer = [badge_outer, styles.small].join(' ');
  }

  const hasTipText = tip_name || customTipMessage;
  if (hasTipText && isTooltipActive && !isMobile()) {
    tool_tip = (
      <ToolTip tip_name={tip_name} customTipMessage={customTipMessage} />
    );
  }

  if (hasTipText && isMobile()) {
    tool_tip = getTipText(tip_name, customTipMessage);
    style = styles.item_with_tip;
    badge_outer = styles.mobile_badge_container;
    badge_description = styles.mobile_description;
    if (selected === true) {
      style = styles.item_with_tip_selected;
    }
  }

  let shadow_class = styles.shadow;
  if (disabled) {
    icon_class = `${icon_class} ${styles.disabled}`;
    shadow_class = `${shadow_class} ${styles.shadow_disabled}`;
  }

  return (
    <div
      className={style}
      onClick={!disabled && handleClick.bind(null, value)}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      <div className={badge_outer}>
        <div className={icon_class}>
          {badge_val}
          {badge_title}
        </div>
        <div className={shadow_class} />
      </div>
      <div className={badge_description}>
        {title}
        {tool_tip}
      </div>
    </div>
  );
}

CircleIconButton.defaultProps = {
  isTextNormal: false,
};

export default CircleIconButton;
