/* eslint-disable prettier/prettier */
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from 'api';
import AddonConfigModel from 'models/AddonConfigModel';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip';
import React, { ReactElement, useEffect, useState } from 'react';
import { formatMoney } from 'utils/formatting';
import { THEME_COLORS } from 'variables/theme';

import useParamsCompare from '../../hooks/useParamsCompare';
import useRequestQuery from '../../hooks/useRequestQuery';
import Panel from '../Panel';
import { Props } from '../Panel/model';
import useComparisonFilterStates from './hooks/useComparisonFilterStates';
import {
  Alert,
  LineItem,
  TooltipContentWrapper,
  Trend,
  Value,
  Wrapper,
} from './styles';
import { FEATURE_FLAGS } from 'conf';

function SummaryPanel(props: Props): ReactElement {
  const {
    accessToken,
    currentRoleTypes,
    entityId,
    filterState: params,
  } = props;

  const apiKey = 'summary';
  const apiAction = () =>
    api('reporting', accessToken, entityId).get({
      api: apiKey,
      params,
    });

  const [modules, setModules] = useState<any>({});

  const addonConfigsAPI = api('addon_configs', accessToken, entityId);
  const { data, error, forceUpdate, loading } = useRequestQuery(apiAction);

  const {
    data: comparisonData,
    error: comparisonError,
    forceUpdate: comparisonForceUpdate,
    loading: comparisonLoading,
  } = useComparisonFilterStates({
    ...props,
    filterState: { ...params.compare, entity_id: params.entity_id },
  });

  const isStandardUser = currentRoleTypes.includes('standard');

  const paramString = JSON.stringify(params);
  const { oldParams, setOldParams } = useParamsCompare(paramString);

  useEffect(() => {
    if (paramString !== oldParams) {
      setOldParams(paramString);
      forceUpdate();
      comparisonForceUpdate();
    }
  }, [
    comparisonForceUpdate,
    forceUpdate,
    oldParams,
    params,
    paramString,
    setOldParams,
  ]);

  const getWatchtowerAddonModule = () => {
    const MODULE_NAME = 'watchtower_module';
    addonConfigsAPI
      .getAddonConfigs({
        params: { addon_module_name: MODULE_NAME },
      })
      .then((response) =>
        setModules({
          [MODULE_NAME]: new AddonConfigModel(
            response.data.data[0],
            response.data.included
          ),
        })
      );
  };

  useEffect(() => {
    getWatchtowerAddonModule();
  }, []);

  const multipleLineItemValuesMapping = {
    total_consumer_individual_credit_checks: [
      'total_consumer_individual_credit_checks',
      'total_personal_credit_checks',
    ],
  };

  /* eslint-disable */
  const lineItems = {
    total_started: {
      label: 'Started',
    },
    total_approved: {
      label: 'Approved',
    },
    total_declined: {
      label: 'Declined',
    },
    total_requested_limits: {
      isCurrency: true,
      label: 'Requested',
      prefix: '$',
    },
    total_approved_limits: {
      isCurrency: true,
      label: 'Approved Value',
      prefix: '$',
    },
    [FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER
      ? 'total_consumer_individual_credit_checks'
      : 'total_personal_credit_checks']: {
      label: 'Pers. Credit Checks',
    },
    total_business_credit_checks: {
      label: 'Bus. Credit Checks',
    },
    total_identification_checks: {
      label: 'ID Checks',
    },
    total_aml_checks: {
      label: 'AML Checks',
    },
    total_ppsr: {
      label: 'PPSR',
    },
    total_watchtower_alerts: {
      label: 'Total Watchtower Alerts',
    },
    total_irregular_accounts: {
      label: 'Irregular Accounts',
      alert: true,
      tooltipText:
        'Turn on Watchtower to action on the irregular accounts to protect your business from risks.',
    },
  };
  /* eslint-enable */

  const getComparisonState = (currentData: number, pastData: number) => {
    if (currentData > pastData) return 1;
    if (currentData < pastData) return -1;
    if (currentData === pastData) return 0;

    return null;
  };

  const getMultipleValues = (keys: string[], data: any) => {
    return keys.reduce(
      (value: number, key: string) => (value = value + (data[key] || 0)),
      0
    );
  };

  const getTrendIcon = (trendValue: number | null): React.ReactNode | null => {
    if (!trendValue) {
      return null;
    }

    if (trendValue === 0) {
      return '-';
    }

    const isUp = trendValue === 1;
    const color = isUp ? THEME_COLORS.trendUp : THEME_COLORS.trendDown;
    const direction = (isUp ? 'caret-up' : 'caret-down') as IconName;

    return (
      <FontAwesomeIcon color={color} icon={['fas', direction]} size="lg" />
    );
  };

  return (
    <Panel
      {...props}
      beta
      error={error || comparisonError}
      loading={loading || comparisonLoading}
      refresh={forceUpdate}
      title="Summary"
    >
      <Wrapper>
        {data &&
          Object.keys(lineItems).map((lineItem) => {
            const { label, isCurrency, prefix, alert, tooltipText } =
              lineItems[lineItem];

            const multipleValueKeys = Object.keys(
              multipleLineItemValuesMapping
            );

            const value =
              multipleValueKeys.includes(lineItem) &&
              FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER
                ? getMultipleValues(
                    multipleLineItemValuesMapping[lineItem],
                    data
                  )
                : data[lineItem];
            const trendValue = comparisonData
              ? getComparisonState(data[lineItem], comparisonData[lineItem])
              : null;
            const formattedValue = isCurrency
              ? formatMoney(parseFloat(value), null, null, null, null)
              : value;
            if (!formattedValue) {
              return [];
            }

            const tooltipConditions = {
              total_irregular_accounts:
                modules['watchtower_module'] &&
                !modules['watchtower_module'].active,
            };

            const displayTooltip = tooltipText && tooltipConditions[lineItem];

            const contents = (
              <React.Fragment>
                <span>{label}:</span>
                {alert ? (
                  <Alert>
                    {prefix}
                    {formattedValue}
                  </Alert>
                ) : (
                  <Value>
                    {prefix}
                    {formattedValue}
                  </Value>
                )}
                <Trend>{getTrendIcon(trendValue)}</Trend>
              </React.Fragment>
            );

            return (
              <LineItem key={lineItem}>
                {displayTooltip ? (
                  <PopperTooltip title={tooltipText}>
                    <TooltipContentWrapper>{contents}</TooltipContentWrapper>
                  </PopperTooltip>
                ) : (
                  contents
                )}
              </LineItem>
            );
          })}
      </Wrapper>
    </Panel>
  );
}

export default SummaryPanel;
