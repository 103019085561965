import React from 'react';
import { DialogContent, Dialog, DialogTitle, IconButton } from 'mui-latest';
import { makeStyles } from 'mui-latest';
import { IRulesetConflict } from '../type';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ConflictRow, { HighlightSpan, StyledContentText } from './ConflictRow';

interface IProps {
  open: boolean;
  onClose: () => void;
  rulesetConflict?: IRulesetConflict;
}

const useStyles = makeStyles({
  root: {
    width: '393px',
    paddingBottom: '36px',
  },
  content: {
    padding: '8px 39px',
  },
  title: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '20px',
  },
  highlight: {
    fontStyle: 'italic',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    '& svg': {
      width: '27px',
      height: '27px',
    },
  },
});

const RulesetConflictDialog = ({
  open,
  onClose,
  rulesetConflict,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const handleClose = (_, reason: 'escapeKeyDown' | 'backdropClick') => {
    // reason: Can be: "escapeKeyDown", "backdropClick".
    if (reason) return;
    onClose();
  };

  if (!rulesetConflict) return <></>;

  const { regions, entityType, applicationType, limitRange } = rulesetConflict;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.root }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {'Conflict ruleset'}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={onClose}
        >
          <CancelRoundedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <StyledContentText className="mb-5">
          These rules conflict with your ruleset{' '}
          <HighlightSpan>{rulesetConflict.rulesetName}</HighlightSpan>. <br />
          Please change one of the following (region, entity type, account type
          and limit range) to remove the conflict.
        </StyledContentText>
        <ConflictRow conflictName="Region" ruleConflict={regions} />
        <ConflictRow conflictName="Entity type" ruleConflict={entityType} />
        <ConflictRow
          conflictName="Application type"
          ruleConflict={applicationType}
        />
        {limitRange.value.length > 0 && (
          <ConflictRow conflictName="Limit range" ruleConflict={limitRange} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RulesetConflictDialog;
