import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from './axios';

export default class SalesChannels {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = 'sales_channels';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  fetchOverview(entityId: string): Promise<void> {
    return this.axios.get(`${this.path}/overview/${entityId}`);
  }

  fetchRepChannel(entityId: string, options?: any): Promise<void> {
    return this.axios.get(`${this.path}/rep_channel/${entityId}`, options);
  }

  downloadRepChannel(entityId: string): Promise<void> {
    return this.axios.get(`${this.path}/rep_channel_download/${entityId}`, {
      responseType: 'blob',
    });
  }
}
