import axios from './axios';

export default class Referrals {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'referrals';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
    this.path = `/${this.type}`;
  }

  create(attributes) {
    return this.axios.post(this.path, {
      data: { attributes, type: this.type },
    });
  }
}
