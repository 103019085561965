import React from 'react';
import { connect } from 'react-redux';

import styles from './css/Layout.css';

var Layout = createClass({
  render: function() {
    return <div>{this.props.children}</div>;
  },
});

export default connect((state, ownProps) => {
  return {};
})(Layout);
