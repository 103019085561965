function formatAddress(address) {
  const { addressLine1, cityTown, postCode, suburb } = address;
  const fullAddress = `${addressLine1}, ${suburb}, ${cityTown} ${postCode}`;

  return {
    fullAddress,
    rawAddress: address,
  };
}

export default class NZRetrieveSecurityGroupDetails {
  static onRetrieveSecurityGroupDetails(data) {
    const securedParties = (data.securedParties || [])[0] || {};
    const securedPartyOrganisation =
      securedParties.securedPartyOrganisation || {};
    const securedPartyPersonOrAob =
      securedParties.securedPartyPersonOrAob || {};

    return {
      address: formatAddress(securedPartyOrganisation.contactAddress || {}),
      organisationEmail: securedPartyOrganisation.email,
      organisationName: securedPartyOrganisation.organisationName,
      organisationPhone: securedPartyOrganisation.phone,
      personFirstName: securedPartyPersonOrAob.firstName,
      personLastName: securedPartyPersonOrAob.lastName,
    };
  }
}
