import { Typography } from '@material-ui/core';
import { FEATURE_FLAGS } from 'conf';
import { addFlaggedObject } from 'conf/flaggingHelpers';
import { THIRD_PARTY_CONSENT_LABEL } from 'constants';
import get from 'lodash.get';
import commonStyles from 'modules/card-management-onboarding/css/Section.css';
import styles from 'modules/card-management-onboarding/css/Terms.css';
import Agreements1Centre, {
  agreements1CYupSchema,
} from 'modules/card-management-shared/Agreements1Centre';
// @ts-ignore-next-line - Should be imported
import Checklist from 'modules/card-management-shared/Checklist';
import DigitalSignature from 'modules/card-management-shared/DigitalSignature';
import Button from 'modules/shared/components/inputs/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { areStringsEqual } from 'utils';
import * as yup from 'yup';
import Terms_deprecated from './Terms_deprecated';
import mixpanel from 'mixpanel-browser';
import { useSignatureStyle } from 'hooks';

export function checkListItems(application) {
  const supplierName = application.supplierName;
  const isTokenised = get(application, 'antiFraudCheckConfig.config.tokenised');

  const items = [
    {
      label: 'I am authorised to sign this form.',
      name: 'authorised',
    },
    {
      label: `I have read and agree to the terms and conditions for ${supplierName}`,
      name: 'read_terms',
    },
  ];

  if (isTokenised && !items.find(({ name }) => name === 'anti_fraud_id_hold')) {
    items.push({
      label: `I confirm that ${supplierName} has the right to hold my\n
        ID documents on file until my identity has been confirmed`,
      name: 'anti_fraud_id_hold',
    });
  }

  items.push({
    label: THIRD_PARTY_CONSENT_LABEL,
    name: 'thirdPartyCheckConsent',
  });

  return items;
}

function GeneralAcknowledgementChecklist(props) {
  const { application } = props;
  const checkList = checkListItems(application);

  return (
    <div>
      <Typography variant="h5">
        General Acknowledgement and Agreement
      </Typography>
      <div className={commonStyles.checklist_container}>
        <Checklist checklist={checkList} {...props} />
      </div>
    </div>
  );
}

function TermsAndConditions(props) {
  const { application } = props;

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.tnc_title}>Terms and Conditions</div>
      <div className={styles.terms_block}>
        <span className={styles.terms}>{application.terms}</span>
      </div>
      <GeneralAcknowledgementChecklist {...props} />

      <hr className={styles.divider} />

      <Agreements1Centre {...props} />

      <hr className={styles.divider} />

      <Typography variant="h5" gutterBottom>
        Electronic Signature
      </Typography>

      <div className={styles.tnc_instruction}>
        By electronically signing this section you confirm and acknowledge that:
        You have read and understood this application and the information
        supplied by you is true and complete.
      </div>
    </div>
  );
}

export default function Terms(props) {
  if (!FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS) {
    return <Terms_deprecated {...props} />;
  }

  const { signatureFont, signatureImage, setSignatureStyle } =
    useSignatureStyle();

  const { cardManagementApplication, applicant, supplierId } = props;
  const signatureRequired =
    cardManagementApplication.signatureRequired === 'on';

  const termsSchema = yup.object().shape({
    authorised: yup
      .boolean()
      .test('authorised', 'Please check to agree.', (value) => value),
    first_name: yup
      .string()
      .required('Please input first name.')
      .test('first_name_test', 'Incorrect first name.', (value) =>
        signatureRequired ? areStringsEqual(applicant.first_name, value) : true
      ),
    last_name: yup
      .string()
      .required('Please input last name.')
      .test('last_name_test', 'Incorrect last name.', (value) =>
        signatureRequired ? areStringsEqual(applicant.last_name, value) : true
      ),
    read_terms: yup
      .boolean()
      .test('readTerms', 'Please check to agree.', (value) => value),
    thirdPartyCheckConsent: yup
      .boolean()
      .test(
        'thirdPartyCheckConsent',
        'Please check to agree.',
        (value) => value
      ),
    ...agreements1CYupSchema,
  });

  const onSubmit = (data) => {
    cardManagementApplication.setAttributes({
      applicant_signature: {
        first_name: data.first_name,
        last_name: data.last_name,
      },
      approved: true,
    });

    (async () => {
      const signatureResult =
        await cardManagementApplication.saveApplicantSignature(supplierId);
      if (signatureResult.status !== 200) {
        return;
      }

      const result = await cardManagementApplication.saveDecision(supplierId);
      if (result.status === 200) {
        mixpanel.people.set({
          $first_name: data.first_name,
          $last_name: data.last_name,
        });

        props.toNextSection();
      }
    })();
  };

  const { clearError, errors, handleSubmit, register, watch } = useForm({
    mode: 'onBlur',
    validationSchema: termsSchema,
  });

  const { first_name, last_name } = watch(['first_name', 'last_name']);

  const isSubmitButtonShown = FEATURE_FLAGS.FEATURE_FLAG_SIGNATURE_FONT
    ? signatureFont || signatureImage
    : true;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={commonStyles.container}>
          <section className={commonStyles.section}>
            <TermsAndConditions
              application={cardManagementApplication}
              inputRef={register}
              errors={errors}
              clearError={clearError}
            />
            <DigitalSignature
              inputRef={register}
              errors={errors}
              firstName={first_name}
              lastName={last_name}
              signatureFont={signatureFont}
              signatureImage={signatureImage}
              setSignatureStyle={setSignatureStyle}
            />
            <div className={commonStyles.flow_buttons}>
              <Button text="Back" onClick={props.toPrevSection} />
              {isSubmitButtonShown && (
                <Button
                  text="Agree"
                  type="submit"
                  loading={cardManagementApplication.isLoading}
                />
              )}
            </div>
          </section>
        </div>
      </form>
    </div>
  );
}
