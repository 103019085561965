import React from 'react';
import styles from './report-styles.css';
import { getCharacteristicsData } from './utils';

const ReportFileNotes = ({ characteristic }: any) => {
  const totalNumber = getCharacteristicsData(characteristic, 'NA8922');

  return (
    <div className={styles.row + ' mt-4'}>
      <div className={styles.col_4}>
        <label className="has-text-danger has-text-weight-semibold">
          Commercial File notes
        </label>
        <p>Not present on file</p>
      </div>
      <div className={styles.col_4}>
        <p>
          Total number: &nbsp;
          {!totalNumber || totalNumber.value < 0 ? 0 : totalNumber.value}
        </p>
      </div>
    </div>
  );
};

export default ReportFileNotes;
