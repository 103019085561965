import { setIdentityValue } from 'modules/identity/actions';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import { isMobileNew } from 'modules/shared/helpers/mobileDetect';
import React, { ReactElement } from 'react';

import { IDENTITY_TYPE_DROPDOWN_PROPERTIES } from '../../../../constants';

interface IIdentityTypeDropdown {
  id: string;
  identityType: string;
  dispatch: any;
  error?: boolean;
  disabled?: boolean;
}

const IdentityTypeDropdown = (props: IIdentityTypeDropdown): ReactElement => {
  const { id, identityType, error, dispatch, disabled } = props;
  const {
    label,
    required,
    options,
    errorMessage,
  } = IDENTITY_TYPE_DROPDOWN_PROPERTIES;

  return (
    <div
      key={`${label}-${id}`}
      style={{ minHeight: '85px', width: isMobileNew() ? '100%' : '25%' }}
    >
      <OptionsDropdown
        id={id}
        handleChange={(event) =>
          dispatch(setIdentityValue('type', event.value))
        }
        error={error ? errorMessage : ''}
        label={label}
        value={identityType || ''}
        required={required}
        options={options}
        disabled={disabled}
      />
    </div>
  );
};

export default IdentityTypeDropdown;
