import useIdCheckDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationImage/v2/hooks/useIdCheckDetails';
import ErrorDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/ErrorDetails';
import Outcome from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/Outcome';
import UnknownDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/UnknownDetails';
import VerificationDetails from 'modules/new-applications/components/application-sections/IdentificationCheck/IdentificationOutcome/v2/GovernmentVerification/VerificationDetails';
import styles from 'modules/new-applications/css/IdentificationChecks.css';
import React, { Fragment, ReactElement } from 'react';
import isBlank from 'utils/isBlank';
import SignatureDetails from '../../../SignatureDetails';
import { FEATURE_FLAGS } from 'conf';

const GovernmentVerification = ({
  identificationCheck,
  signature,
}): ReactElement | null => {
  const {
    dobVerified,
    idVerified,
    nameVerified,
    pass,
    verificationToken,
    driverLicenceVerified,
    passportVerified,
    addressVerified,
    isAml,
    amlVerified,
  } = useIdCheckDetails(identificationCheck, signature);

  const { status, retryAttempts } = identificationCheck;
  const { idType, identificationType, isOtherIdentificationType } =
    signature || {};

  if (status === 'completed' && isBlank(idVerified)) {
    return null;
  }

  const displayOutcome = status && idVerified;
  const displayCompleted = status === 'completed';
  const displayErrored = status === 'errored';

  const flaggedOtherIdType =
    FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE && isOtherIdentificationType;

  const showOtherIdType =
    flaggedOtherIdType && displayOutcome && idVerified !== 'Unknown';

  return (
    <Fragment>
      <div className={styles.e_signature_header}>
        {displayOutcome && (
          <Outcome
            idVerified={idVerified}
            manuallyApproved={signature.manuallyApproved}
            pass={pass}
            status={status}
            isAml={isAml}
            amlVerified={amlVerified}
            isOtherIdentificationType={flaggedOtherIdType}
          />
        )}
        {displayCompleted && (
          <Fragment>
            <VerificationDetails
              dobVerified={dobVerified}
              idVerified={idVerified}
              nameVerified={nameVerified}
              idType={idType || identificationType}
              signature={signature}
              verificationToken={verificationToken}
              driverLicenceVerified={driverLicenceVerified}
              passportVerified={passportVerified}
              addressVerified={addressVerified}
              isAml={isAml}
            />
            {!flaggedOtherIdType && <UnknownDetails idVerified={idVerified} />}
          </Fragment>
        )}
        {displayErrored && <ErrorDetails retryAttempts={retryAttempts} />}
        {showOtherIdType && (
          <SignatureDetails label="ID Type" content="Other" />
        )}
      </div>
    </Fragment>
  );
};

export default GovernmentVerification;
