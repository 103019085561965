import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form-latest';
import BorderedTextField from '../../inputs/BorderedTextArea';

import { Error } from './types';

type Props = {
  control: any;
  customProps?: any;
  error?: Error;
  inputProps?: any;
  isMoney?: boolean;
  label?: string;
  name: string;
  onBlur?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rules?: any;
  value?: any;
};

function RHFBorderedTextArea(props: Props): ReactElement {
  const {
    customProps = {},
    error,
    inputProps = {},
    placeholder,
    label,
    readOnly,
    required,
    ...controllerProps
  } = props;

  const processedLabel = required ? `${label}*` : label;

  return (
    <Controller
      {...controllerProps}
      render={({
        field: { ref, value, ...textFieldProps },
        fieldState: { error: fieldError },
      }) => {
        const processedError = error || fieldError;
        return (
          <BorderedTextField
            {...textFieldProps}
            inputProps={inputProps}
            customProps={customProps}
            error={!!processedError}
            helperText={processedError && processedError.message}
            disabled={readOnly}
            inputRef={ref}
            placeholder={placeholder}
            label={processedLabel}
            value={value}
          />
        );
      }}
    />
  );
}

export default RHFBorderedTextArea;
