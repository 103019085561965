import { MANAGE_PROFILE_MANDATORY_CHECKS_CLEAR_STATE } from 'modules/profile/constants';
import { sendSurvey } from 'utils/askNicely';
import { setChurnZeroAttributes } from 'utils/churnZero';
import isPresent from 'utils/isPresent';
import get from 'lodash.get';

import api from 'api';
import {
  CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS,
  CNS_INVITE_RESEND_APPLICATION_ERROR,
  CNS_INVITE_RESEND_APPLICATION_START,
  CNS_INVITE_RESEND_APPLICATION_SUCCESS,
  CNS_INVITE_RESEND_AUTHORISATION_ERROR,
  CNS_INVITE_RESEND_AUTHORISATION_START,
  CNS_INVITE_RESEND_AUTHORISATION_SUCCESS,
  CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_EMAIL,
  CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME,
  CNS_INVITE_SET_IUF_ANSWERS,
  CNS_INVITE_SET_TYPE,
} from '../constants/invite';

export function createApplicationForNewConsumer({
  email,
  application_type,
  overriddenRules,
  iufAnswers,
  successCallback,
  errorCallback,
}) {
  return (dispatch, getState) => {
    const data = {
      application_type,
      email,
      iuf_answers: iufAnswers,
      supplier_id: getState().current_user.current_entity.id,
      ...overriddenRules,
    };

    const invites = api(
      'invites',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    invites.createForNonConsumer(
      data,
      (success) => {
        const applications = get(success, 'data.data', []);
        const application = applications[0] || {};
        const applicationId = application.id;
        const consumerId = application.consumer_id;
        const name =
          getState().current_user.data.data.attributes.full_name || '';
        const email = getState().current_user.data.data.attributes.email || '';
        const supplier_name =
          getState().current_user.current_entity.attributes.company_name || '';

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: 'Invite new consumer to apply',
          supplier_name,
          access_token: getState().current_user.access_token,
          supplier_id: getState().current_user.current_entity.id,
          application_id: applicationId,
          consumer_id: consumerId,
          consumer_name: null,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: `Sent Application - New customer - ${application_type}`,
              props: {
                'Entity ID': getState().current_user.current_entity.id,
                'New consumer email': data.email,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS,
        });

        // ChurnZero
        if (getState().current_user.current_entity.attributes.supplier) {
          setChurnZeroAttributes(
            getState().current_user.current_entity.id,
            getState
          );
        }

        if (successCallback) {
          successCallback();
        }
      },
      (error) => {
        if (errorCallback) {
          errorCallback(error);
        }
      }
    );
  };
}

export function setInviteType(type) {
  return (dispatch, getState) => {
    dispatch({
      payload: type,
      type: CNS_INVITE_SET_TYPE,
    });
  };
}

export function setNewConsumerInviteFormValue(key, value) {
  let type;
  switch (key) {
    case 'consumer_first_name':
      type = CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME;
      break;
    case 'consumer_last_name':
      type = CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME;
      break;
    case 'consumer_email':
      type = CNS_INVITE_SET_NEW_CONSUMER_EMAIL;
      break;
    case 'consumer_company_name':
      type = CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME;
      break;
    case 'consumer_iuf_answers':
      type = CNS_INVITE_SET_IUF_ANSWERS;
      break;
  }

  return {
    payload: value,
    type,
  };
}

function formatSearchResponse({ dispatch, getState, result }) {
  const data = {};

  for (let i = 0; i < result.data.data.length; i++) {
    const entity = result.data.data[i];

    if (entity) {
      const entityId = entity.id;
      let full_physical_address = null;
      if (entity.physical_address) {
        full_physical_address = entity.physical_address;
      }

      let consumer_application = null;
      let application_type = null;
      let sent_application = null;

      if (isPresent(entity.supplier_connections)) {
        consumer_application = entity.supplier_connections.find(
          (item) =>
            item.supplier_id === getState().current_user.current_entity.id
        );

        if (consumer_application) {
          application_type = consumer_application.type;
        }
      }

      const supplier = entity.supplier;
      const company_users_email = [];

      if (isPresent(entity.users)) {
        for (let j = 0; j < entity.users.length; j++) {
          if (isPresent(entity.supplier_applications)) {
            sent_application = entity.supplier_applications.find(
              (item) =>
                item.supplier_id ===
                  getState().current_user.current_entity.id &&
                item.consumer_contact_id === result.data.data[i].users[j].id
            );
          }

          company_users_email.push({
            email: entity.users[j].email,
            exisiting_connection:
              consumer_application &&
              consumer_application.consumer_contact_id === entity.users[j].id
                ? consumer_application
                : null,
            full_name: [
              entity.users[j].first_name,
              entity.users[j].last_name,
            ].join(' '),
            id: entity.users[j].id,
            sent_application,
          });
        }
      }

      const first_admin = entity.first_admin ? entity.first_admin : null;
      if (first_admin) {
        first_admin.full_name = [
          first_admin.first_name,
          first_admin.last_name,
        ].join(' ');
      }
      const trading_names = entity.trading_names || [];

      data[`${entityId}`] = {
        address: full_physical_address,
        application_type,
        company_name: entity.company_name,
        first_admin,
        id: entityId,
        is_registered: entity.is_registered,
        region: entity.region,
        subsidiaries: entity.subsidiaries,
        supplier,
        trading_names,
        user: company_users_email,
      };
    }
  }

  dispatch({
    payload: data,
    type: CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS,
  });
}

export function resendApplication(applicationId, callback) {
  return (dispatch, getState) => {
    const data = {
      application_id: applicationId,
      supplier_id: getState().current_user.current_entity.id,
    };

    dispatch({
      type: CNS_INVITE_RESEND_APPLICATION_START,
    });

    const invites = api(
      'invites',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    invites.resendInvite(
      data,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Resend the application',
              props: {
                'Application ID': applicationId,
                'Entity ID': getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_RESEND_APPLICATION_SUCCESS,
        });

        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: CNS_INVITE_RESEND_APPLICATION_ERROR,
        });
      }
    );
  };
}

export function resendAuthorisation(applicationId, callback) {
  return (dispatch, getState) => {
    const data = {
      application_id: applicationId,
      supplier_id: getState().current_user.current_entity.id,
    };

    dispatch({
      type: CNS_INVITE_RESEND_AUTHORISATION_START,
    });

    const invites = api(
      'invites',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    invites.resendAuthorisationInvite(
      data,
      (success) => {
        dispatch({
          meta: {
            mixpanel: {
              event: 'Resend the authorisation request',
              props: {
                'Application ID': applicationId,
                'Entity ID': getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_RESEND_AUTHORISATION_SUCCESS,
        });

        if (callback) {
          const results = success.data.data;
          callback(results);
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: CNS_INVITE_RESEND_AUTHORISATION_ERROR,
        });
      }
    );
  };
}

export function createCreditApplicationForExistingCashConsumer(
  consumer_id,
  consumer_legal_type,
  callback
) {
  return (dispatch, getState) => {
    dispatch({
      payload: consumer_id,
      type: CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START,
    });

    let data = {
      application_type: 'credit',
      consumer_target: consumer_id,
      supplier_id: getState().current_user.current_entity.id,
    };

    data = getCashToCreditAccountRules(data, consumer_legal_type);

    const invites = api(
      'invites',
      getState().current_user.access_token,
      getState().current_user.current_entity.id
    );
    invites.createForConsumer(
      data,
      (success) => {
        const applications = get(success, 'data.data', []);
        const application = applications[0] || {};
        const applicationId = application.id;
        const consumerId = application.consumer_id;
        const name =
          getState().current_user.data.data.attributes.full_name || '';
        const email = getState().current_user.data.data.attributes.email || '';
        const supplier_name =
          getState().current_user.current_entity.attributes.company_name || '';

        sendSurvey({
          name,
          email,
          supplier: true,
          event_flow: 'Invite existing cash consumer to apply',
          supplier_name,
          access_token: getState().current_user.access_token,
          supplier_id: getState().current_user.current_entity.id,
          application_id: applicationId,
          consumer_id: consumerId,
          consumer_name: null,
        });

        dispatch({
          meta: {
            mixpanel: {
              event: 'Invite existing cash consumer to apply',
              props: {
                'Consumer ID': consumer_id,
                'Entity ID': getState().current_user.current_entity.id,
                distinct_id: getState().current_user.data.data.id,
              },
            },
          },
          payload: success,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS,
        });

        if (callback) {
          callback();
        }
      },
      (error) => {
        dispatch({
          payload: error,
          type: CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR,
        });
      }
    );
  };
}

export function getCashToCreditAccountRules(attributes, consumer_legal_type) {
  if (!attributes) {
    attributes = new Object();
  }
  /* Add Credit Check */
  attributes['requires_credit_check'] = false;

  /* Add Identification Check */
  attributes['requires_identity_check'] = false;
  attributes['requires_applicant_identification_check'] = false;
  attributes['requires_guarantees_identification_check'] = false;

  /* Add Guarantor */
  // create it depend on supplier account rules in backend if consumer_legal_type is not 'sole_trader'
  if (consumer_legal_type === 'sole_trader') {
    attributes['requires_guarantees'] = false;
    attributes['minimum_guarantees'] = 0;
  }

  /* Add Trade Reference */
  attributes['requires_trade_reference_check'] = false;
  attributes['minimum_trade_references'] = 0;

  return attributes;
}
