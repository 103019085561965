import React from 'react';
import ReviewContainer from 'modules/shared/components/containers/ReviewContainer';
import { formatMoney } from 'utils/formatting';
import { enquiryResponseData } from './helpers/reportHelpers';
import styles from '../../../css/CommonReviewBusiness.css';
import { get } from 'lodash';
import { stringToSimpleDate } from 'utils/dateFormatter';

const NewCourtActions = ({ data, entityType, isAllExpanded }) => {
  const response = enquiryResponseData(data, entityType);
  let writ_list = get(response, 'organisation_legal.court_writ_list.writs', []);
  if (writ_list.constructor === Object) writ_list = [writ_list];
  let judgement_list = get(
    response,
    'organisation_legal.court_judgement_list.judgements',
    []
  );
  if (judgement_list.constructor === Object) judgement_list = [judgement_list];
  const documents = [...writ_list, ...judgement_list];

  const documentRows = documents.map((document, index) => {
    return (
      <tr key={index}>
        <td>{stringToSimpleDate(document.action_date)}</td>
        <td>{document.writ_type || '-'}</td>
        <td>
          {formatMoney(
            parseFloat(document.amount),
            undefined,
            undefined,
            undefined,
            undefined
          )}
        </td>
        <td>{document.creditor}</td>
        <td>{document.plaint_number}</td>
        <td>{get(document, 'court_type.type', '')}</td>
        <td>{get(document, 'action_status.type', '')}</td>
      </tr>
    );
  });

  return (
    <ReviewContainer
      subHeading="Court Actions"
      content_class="content_wide"
      css_class="block_noborder"
      collapsible={true}
      isAllExpanded={isAllExpanded}
      fontSize="medium"
    >
      <div className={styles.table_wrapper}>
        <table className={styles.table_w20}>
          <thead>
            <tr>
              <th>Action Date</th>
              <th>Action Type</th>
              <th>Amount</th>
              <th>Creditor</th>
              <th>Plaint Number</th>
              <th>Court Type</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{documentRows}</tbody>
        </table>
      </div>
    </ReviewContainer>
  );
};

export default NewCourtActions;
