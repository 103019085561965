import REGION from 'types/region';
import { EntityType } from 'utils/entityType';

const ENTITY_TYPES = {
  association: {
    title: 'Association',
    trading_name: 'Name of association',
  },
  club: {
    title: 'Club',
    trading_name: 'Name of club',
  },
  company: {
    title: 'Company',
    trading_name: 'Trading name',
  },
  education: {
    title: 'Education',
    trading_name: 'Name of institution',
  },
  government: {
    title: 'Government',
    trading_name: 'Name of organisation',
  },
  other: {
    title: 'Other',
    trading_name: 'Name of organisation',
  },
  partnership: {
    title: 'Partnership',
    trading_name: 'Trading name',
  },
  personal: {
    title: 'Personal',
    trading_name: 'Account name',
  },
  society: {
    title: 'Society',
    trading_name: 'Name of society',
  },
  sole_trader: {
    title: 'Sole trader',
    trading_name: 'Trading name',
  },
  trust: {
    title: 'Trust',
    trading_name: 'Trading name',
  },
};

const OTHER_ENTITY_TYPES = [
  'association',
  'club',
  'education',
  'government',
  'other',
  'society',
];

export function entityTypeTitle(
  entityType: EntityType,
  type = 'title'
): string {
  if (Object.keys(ENTITY_TYPES).includes(entityType)) {
    return ENTITY_TYPES[entityType][type];
  }

  return '';
}

export function getTradingNameTitleByRegion({
  entityType,
  region,
}: {
  entityType: EntityType;
  region: REGION;
}): string {
  if (region === 'AU') {
    return 'Main trading name';
  }

  return entityTypeTitle(entityType, 'trading_name');
}

export function getEntityNameFieldLabel(region: REGION): string {
  if (region === 'AU') {
    return 'Entity name';
  }

  return 'Company/Business name';
}

export function isOtherEntityType(entityType: EntityType): boolean {
  return OTHER_ENTITY_TYPES.includes(entityType);
}
