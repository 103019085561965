/* eslint-disabled max-lines */
import { isEmpty } from 'lodash';
import get from 'lodash.get';
import UserEntityLinkModel from 'models/UserEntityLinkModel';
import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import styles from 'modules/addons/components/css/DirectDebitModuleComponents.css';
import Button from 'modules/shared/components/inputs/Button';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import MultipleInputField from 'modules/shared/components/inputs/MultipleInputField';
import ContentContainer from 'modules/shared/components/v2/ContentContainer';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import { isFeatureEditEnabled } from 'modules/shared/helpers/headquarterDetect';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';

import Header from '../PPSRRegistration/forms/Header';
import {
  exclusionOptions,
  externalAlertDropdownOptions,
  formConstants,
  internalAlertsDropdownOptions,
  DEFAULT_CREDIT_SCORE_DECREASE,
} from './constants';
import EmailAgeInput from './DynamicFields/EmailAgeInput';
import ForeignIpSelect from './DynamicFields/ForeignIpSelect';
import UnusualApplicationTimeInput from './DynamicFields/UnusualApplicationTimeInput';
import EmailDomainsInput from './DynamicFields/EmailDomainsInput';
import {
  generateNewPayload,
  getAlertsNameArray,
  getAlertValuesFromOption,
  getContactOptions,
  getEmailAgeDefaultData,
  getEmailDomainsDefaultData,
  getNewVersionAttribute,
  getPermissionDefaultData,
  getUnusualApplicationTimesDefaultData,
  watchtowerAddonHasEmptyFields,
} from './helpers';
import { HeaderWrapper } from './styles';
import {
  DropdownOptions,
  StepKeys,
  WatchtowerConfig,
  WatchtowerData,
} from './types';
import WatchtowerConfirmModal from './WatchtowerConfirmModal';
import { FEATURE_FLAGS } from 'conf';
import { addFlaggedObject } from 'conf/flaggingHelpers';

const Stepper = ({
  children,
  stepKey,
}: {
  children: React.ReactElement;
  stepKey: StepKeys;
}): React.ReactElement => {
  const { header, step, description, isOptional } = formConstants[stepKey];
  return (
    <ContentContainer header={header} customStep={step} isOptional={isOptional}>
      <div className="pb-4">
        <div className={`${styles.info} pb-4`}>{description}</div>
        {children}
      </div>
    </ContentContainer>
  );
};

const WatchtowerEditComponent = (props) => {
  const {
    addonConfig,
    currentUser,
    currentAddonConfig,
    onRefreshDigitalOnboardingModules,
    onUpdateModuleItem,
    entityTimezone,
    isReadonly,
  } = props;

  const { onClickBack } = useContext(DigitalOnboardingContext);
  const [alertContactOptions, setAlertContactOptions] = useState<
    DropdownOptions[]
  >([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const currentConfig: WatchtowerConfig = get(
    currentAddonConfig,
    'attributes.config',
    {}
  );
  const noConfig = isEmpty(currentConfig);
  const defaultExternalAlerts = noConfig
    ? getAlertValuesFromOption(externalAlertDropdownOptions)
    : [];
  const defaultInternalAlerts = noConfig
    ? getAlertValuesFromOption(internalAlertsDropdownOptions)
    : [];

  const defaultValues: WatchtowerData = {
    countryPermissions: getPermissionDefaultData(currentConfig),
    creditScoreDecreaseThreshold: DEFAULT_CREDIT_SCORE_DECREASE,
    emailAge: getEmailAgeDefaultData(currentConfig),
    ...addFlaggedObject('FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS', {
      emailDomains: getEmailDomainsDefaultData(currentConfig),
    }),
    externalAlertContacts: get(currentConfig, 'external_alerts.contacts', []),
    externalAlerts:
      getAlertsNameArray(
        get(
          currentConfig,
          'external_alerts.alerts_enabled',
          defaultExternalAlerts
        )
      ) || [],
    internalAlertContacts: get(currentConfig, 'internal_alerts.contacts', []),
    internalAlerts:
      getAlertsNameArray(
        get(
          currentConfig,
          'internal_alerts.alerts_enabled',
          defaultInternalAlerts
        )
      ) || [],
    unusualApplicationTimes: getUnusualApplicationTimesDefaultData(
      currentConfig,
      entityTimezone
    ),
    exclusions: get(
      currentConfig,
      'internal_alerts.application_status_exclusions',
      []
    ),
    accountMonitoringContacts: get(
      currentConfig,
      'account_monitoring_contacts',
      []
    ),
  };

  const { setValue, handleSubmit, watch } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const watchedValues = watch();
  const currentValues = {
    ...watchedValues,
    externalAlerts: watchedValues.externalAlerts ?? [],
    externalAlertContacts: watchedValues.externalAlertContacts ?? [],
    internalAlerts: watchedValues.internalAlerts ?? [],
    internalAlertContacts: watchedValues.internalAlertContacts ?? [],
    accountMonitoringContacts: watchedValues.accountMonitoringContacts ?? [],
  } as WatchtowerData;

  const isDirty =
    JSON.stringify(defaultValues) !== JSON.stringify(currentValues);

  const hasUndefinedCurrentValues = () => {
    const {
      externalAlerts,
      externalAlertContacts,
      internalAlerts,
      internalAlertContacts,
    } = currentValues;
    if (
      !externalAlerts ||
      !externalAlertContacts ||
      !internalAlerts ||
      !internalAlertContacts
    ) {
      return true;
    }
    return false;
  };

  const hasEmptyFields = !hasUndefinedCurrentValues()
    ? watchtowerAddonHasEmptyFields(currentValues)
    : true;

  const onSuccessCallback = (updatedAddonConfig) => {
    onRefreshDigitalOnboardingModules();
    onUpdateModuleItem(updatedAddonConfig);
    location.reload();
  };

  const onClickCloseButton = () => {
    onClickBack();
  };

  const onSubmit = (data: WatchtowerData) => {
    const newVersion = addonConfig.generateNewVersion();
    const payload: WatchtowerConfig = generateNewPayload(data);
    const attributes = getNewVersionAttribute(addonConfig, payload);
    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onSuccessCallback,
    });

    if (addonConfig.isPersisted) {
      addonConfig.setAttribute('active', true);
      addonConfig.update({
        currentUser,
        onSuccessCallback,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const allUsers = await UserEntityLinkModel.fetchUsers({
        accessToken: currentUser.accessToken,
        entityId: currentUser.currentEntity.id,
      });

      setAlertContactOptions(getContactOptions(allUsers));
    })();
  }, []);

  const isDisabled =
    ((currentUser.isStandard || (noConfig && currentUser.isManager)) &&
      !currentUser.isAdmin) ||
    isReadonly;

  const isSaveButtonDisabled = isDisabled || !isDirty || hasEmptyFields;

  const showConfimModal = (event) => {
    if (isSaveButtonDisabled) return;
    event.preventDefault();
    setShowModal(true);
  };

  const isWatchtowerActive = addonConfig.attributes.active;

  return (
    <div className={styles.container}>
      <HeaderWrapper>
        <Header addonModuleName="watchtower_module" />
        {!showModal && <CloseButton handleClick={onClickCloseButton} />}
      </HeaderWrapper>
      <form
        onSubmit={isWatchtowerActive ? handleSubmit(onSubmit) : showConfimModal}
      >
        <div className="mt-6">
          <Stepper stepKey="step1">
            <div className="columns">
              <div className="column is-6">
                <SimpleMultiSelectDropdown
                  options={externalAlertDropdownOptions}
                  onChange={(target) =>
                    setValue('externalAlerts', target.value)
                  }
                  id="external-alert-select"
                  multiple
                  bulkSelect
                  disabled={isDisabled}
                  value={currentValues.externalAlerts}
                  placeholder="External alert"
                />
              </div>
            </div>
          </Stepper>
          <Stepper stepKey="step1a">
            <div className="columns">
              <div className="column is-6">
                <MultipleInputField
                  type="autocomplete"
                  options={alertContactOptions}
                  value={currentValues.externalAlertContacts}
                  onChange={(value) => setValue('externalAlertContacts', value)}
                  disabled={isDisabled}
                  buttonLabel="+ Add recipient"
                  textFieldProps={{
                    placeholder: 'Email',
                  }}
                />
              </div>
            </div>
          </Stepper>
          <Stepper stepKey="step2">
            <div className="columns">
              <div className="column is-6">
                <SimpleMultiSelectDropdown
                  options={internalAlertsDropdownOptions}
                  onChange={(target) =>
                    setValue('internalAlerts', target.value)
                  }
                  id="external-alert-select"
                  multiple
                  bulkSelect
                  disabled={isDisabled}
                  value={currentValues.internalAlerts}
                  placeholder="Internal alert"
                />
              </div>
            </div>
          </Stepper>
          {currentValues.internalAlerts.includes('blocked_geo_ips') && (
            <ForeignIpSelect
              value={currentValues.countryPermissions}
              onChange={(value) => setValue('countryPermissions', value)}
              disabled={isDisabled}
            />
          )}
          {currentValues.internalAlerts.includes(
            'unusual_application_times'
          ) && (
            <UnusualApplicationTimeInput
              value={currentValues.unusualApplicationTimes}
              onChange={(value) => setValue('unusualApplicationTimes', value)}
              disabled={isDisabled}
            />
          )}
          {currentValues.internalAlerts.includes('email_age') && (
            <EmailAgeInput
              value={currentValues.emailAge}
              onChange={(event) => setValue('emailAge', event.target.value)}
              disabled={isDisabled}
            />
          )}
          {FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS &&
            currentValues.internalAlerts.includes('email_domains') && (
              <EmailDomainsInput
                value={currentValues.emailDomains}
                onChange={(value) => setValue('emailDomains', value)}
                disabled={isDisabled}
              />
            )}
          <Stepper stepKey="step2a">
            <div className="columns">
              <div className="column is-6">
                <MultipleInputField
                  type="autocomplete"
                  options={alertContactOptions}
                  value={currentValues.internalAlertContacts}
                  onChange={(value) => setValue('internalAlertContacts', value)}
                  disabled={isDisabled}
                  buttonLabel="+ Add recipient"
                  textFieldProps={{
                    placeholder: 'Email',
                  }}
                />
              </div>
            </div>
          </Stepper>
          {FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_ACCOUNT_MONITORING_CONTACT && (
            <Stepper stepKey="step3">
              <div className="columns">
                <div className="column is-6">
                  <MultipleInputField
                    type="autocomplete"
                    options={alertContactOptions}
                    value={currentValues.accountMonitoringContacts}
                    onChange={(value) =>
                      setValue('accountMonitoringContacts', value)
                    }
                    disabled={isDisabled}
                    buttonLabel="+ Add recipient"
                    textFieldProps={{
                      placeholder: 'Email',
                    }}
                  />
                </div>
              </div>
            </Stepper>
          )}
          <Stepper
            stepKey={
              FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_EXCLUSIONS_FIX
                ? FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_ACCOUNT_MONITORING_CONTACT
                  ? 'step4'
                  : 'step3'
                : 'step2b'
            }
          >
            <div className="columns">
              <div className="column is-4">
                <SimpleMultiSelectDropdown
                  options={exclusionOptions}
                  onChange={(target) => setValue('exclusions', target.value)}
                  id="exclusions"
                  multiple
                  bulkSelect
                  disabled={isDisabled}
                  value={currentValues.exclusions}
                  placeholder="Exclusions"
                />
              </div>
            </div>
          </Stepper>
        </div>
        {showModal && (
          <WatchtowerConfirmModal
            onCancel={() => setShowModal(false)}
            onConfirm={handleSubmit(onSubmit)}
          />
        )}
        <div>
          <Button text="save" disabled={isSaveButtonDisabled} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default connect((state, ownProps) => {
  const entityTimezone = get(
    state,
    'current_user.current_entity.attributes.timezone',
    'Pacific/Auckland'
  );

  const isReadonly = !isFeatureEditEnabled('Watchtower');

  return {
    currentAddonConfig: get(
      ownProps,
      'addonConfig.attributes.history_version.data[0]',
      {}
    ),
    entityTimezone,
    isReadonly,
  };
})(WatchtowerEditComponent);
