import { FontAwesomeIcon as OriginalFAIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { BORDERS } from 'variables/theme';
import { FONT_SIZES } from 'variables/typography';

export const CurrentUserTypes = styled.div`
  margin-bottom: 3rem;

  > * {
    border-bottom: ${BORDERS.generic};

    &:first-child {
      border-top: ${BORDERS.generic};
    }
  }
`;

export const CurrentUserType = styled.div`
  padding: 2rem 0;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const FontAwesomeIcon = styled(OriginalFAIcon)`
  color: var(--main-color);
`;

export const SubHeading = styled.h3`
  font-size: ${FONT_SIZES.title};
`;

export const Role = styled.span`
  text-transform: capitalize;
`;
