import React from 'react';
import styles from '../centrix.css';
import moment from 'moment';
import isPresent from 'utils/isPresent';

class PropertyOwnership extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  renderAddress(address_details) {
    return (
      <span>
        {address_details.physical_address}
        {address_details.suburb && `, ${address_details.suburb}`}
        {address_details.territorial_authority &&
          `, ${address_details.territorial_authority}`}
      </span>
    );
  }

  renderAddresses(input_addresses) {
    let addresses;
    if (input_addresses.constructor !== Array) {
      addresses = [input_addresses];
    } else {
      addresses = input_addresses;
    }
    return addresses.map((address) => {
      return (
        <tr>
          <td>{this.renderAddress(address.property_details)}</td>
          <td>{address.certificate_of_title}</td>
          <td>{moment(address.issue_date).format('DD/MM/YYYY')}</td>
        </tr>
      );
    });
  }

  render() {
    const { data } = this.props;
    const { data_sets } = data;
    const { property_ownership } = data_sets;

    if (isPresent(property_ownership.properties)) {
      return (
        <div>
          <div className={styles.table_container}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Title Number</th>
                  <th>Transfer Date</th>
                </tr>
              </thead>
              <tbody>
                {this.renderAddresses(property_ownership.properties.property)}
              </tbody>
            </table>
          </div>
          <p>
            Important Note: The above addresses are those that we have matched
            to the subject consumer. It is possible the subject is the
            beneficial owner of other properties.
          </p>
        </div>
      );
    }

    return (
      <div>
        No matches on the name and address as provided have been found on the
        Property Database.
      </div>
    );
  }
}

export default PropertyOwnership;
