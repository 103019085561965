export const LOAD_WEBHOOKS_START = 'LOAD_WEBHOOKS_START';
export const LOAD_WEBHOOKS_SUCCESS = 'LOAD_WEBHOOKS_SUCCESS';
export const LOAD_WEBHOOKS_ERROR = 'LOAD_WEBHOOKS_ERROR';

export const CREATE_WEBHOOK_START = 'CREATE_WEBHOOK_START';
export const CREATE_WEBHOOK_SUCCESS = 'CREATE_WEBHOOK_SUCCESS';
export const CREATE_WEBHOOK_ERROR = 'CREATE_WEBHOOK_ERROR';

export const SAVE_WEBHOOK_START = 'SAVE_WEBHOOK_START';
export const SAVE_WEBHOOK_SUCCESS = 'SAVE_WEBHOOK_SUCCESS';
export const SAVE_WEBHOOK_ERROR = 'SAVE_WEBHOOK_ERROR';

export const DELETE_WEBHOOK_START = 'DELETE_WEBHOOK_START';
export const DELETE_WEBHOOK_SUCCESS = 'DELETE_WEBHOOK_SUCCESS;';
export const DELETE_WEBHOOK_ERROR = 'DELETE_WEBHOOK_ERROR';

export const TEST_WEBHOOK_START = 'TEST_WEBHOOK_START';
export const TEST_WEBHOOK_SUCCESS = 'TEST_WEBHOOK_SUCCESS';
export const TEST_WEBHOOK_ERROR = 'TEST_WEBHOOK_ERROR';
