import Checkbox from 'modules/shared/components/inputs/Checkbox';
import ToolTip from 'modules/shared/components/widgets/interactive/ToolTip';
import { isMobile } from 'modules/shared/helpers/mobileDetect';
import React, { ReactElement } from 'react';

type handleArgs = {
  value: boolean;
};

interface Props {
  handleCheck: (args: handleArgs) => void;
  isChecked: boolean;
  userType: string;
}

function CallCentre({ handleCheck, isChecked }: Props): ReactElement {
  const item = {
    description: `
      This user can fill in applications on behalf of customers.
    `,
    id: 'can_call_centre_sudo',
    label: 'Call Centre',
    type: 'page_permissions',
  };

  const [isTooltipActive, setIsTooltipActive] = React.useState(false);

  function showTooltip() {
    setIsTooltipActive(true);
  }

  function hideTooltip() {
    setIsTooltipActive(false);
  }

  const onClick = () => handleCheck({ value: !isChecked, ...item });
  const TipMessage = () => (
    <span>
      {
        'The user needs to be trained and accredited to have this permission, please contact '
      }
      <a
        style={{ color: 'var(--main-font-color)', textDecoration: 'underline' }}
        href="mailto:success@1centre.com"
      >
        success@1centre.com
      </a>
      {' for assistance.'}
    </span>
  );

  return (
    <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <Checkbox
        checkboxId="can_call_centre_sudo"
        checked={isChecked}
        handleChange={onClick}
        label={item.label}
        description={item.description}
      />
      {isTooltipActive && !isMobile() && (
        <ToolTip css_style="call_centre" customTipMessage={TipMessage}>
          <TipMessage />
        </ToolTip>
      )}
    </div>
  );
}

export default CallCentre;
