import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
// @ts-ignore
import { browserHistory } from 'react-router';

import { CustomersContext } from '../CustomersContext';
import { DEFAULT_PAGE_NUMBER } from 'constants/pagination';
import { PipelinePageHeaderComponents } from 'modules/shared/components';

const { FilterButton } = PipelinePageHeaderComponents;

export const ToggleView = () => {
  const {
    params: { only_current_user },
  } = useContext(CustomersContext);

  const urlParams = new URLSearchParams(window.location.search);

  const handleClick = (e) => {
    e.preventDefault();
    const newValue = only_current_user === 'true' ? 'false' : 'true';
    urlParams.set('only_current_user', newValue);
    urlParams.set('page', String(DEFAULT_PAGE_NUMBER));
    browserHistory.push(`/dashboard/customers?${urlParams}`);
  };

  return (
    <FilterButton>
      <Typography onClick={handleClick}>
        {'Show '}
        <span>{only_current_user === 'true' ? 'all' : 'my'}</span>
        {' applications'}
      </Typography>
    </FilterButton>
  );
};
