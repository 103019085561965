import {
  CNS_INVITE_SET_TYPE,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS,
  CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS,
  CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME,
  CNS_INVITE_SET_NEW_CONSUMER_EMAIL,
  CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME,
  CNS_INVITE_SET_IUF_ANSWERS,
  CNS_INVITE_RESEND_APPLICATION_START,
  CNS_INVITE_RESEND_APPLICATION_SUCCESS,
  CNS_INVITE_RESEND_APPLICATION_ERROR,
  CNS_INVITE_RESEND_AUTHORISATION_START,
  CNS_INVITE_RESEND_AUTHORISATION_SUCCESS,
  CNS_INVITE_RESEND_AUTHORISATION_ERROR,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS,
  CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR,
} from '../constants/invite';

var cns_invite_default = {
  invite_type: null,
  return_path: '/dashboard/home',

  company_autosuggest_field_value: '',
  company_autosuggest_lookup_loading: false,
  company_autosuggest_list: {},
  company_autosuggest_selected_name: '',
  company_autosuggest_selected_name_with_address: '',
  company_autosuggest_selected_details: {},
  company_autosuggest_selected_user: null,

  new_consumer_first_name: null,
  new_consumer_last_name: null,
  new_consumer_email: null,
  new_consumer_company_name: null,

  iuf_answers: null,

  section_index: 0,

  section_data: {},
  section_complete: false,
  completed: {},

  entity_type: null,
  entity_type_title: null,
  entity_name: '',

  logo: '',

  saving: false,

  entity_staff_count: '',
  entity_phone: '',
  entity_website_url: '',
  entity_address: '',
  entity_postal: '',
  entity_party_count: 0,
  entity_party_details_total_percent: 0,
  entity_party_details_values: [],
  entity_party_details_container_index: 0,

  key_contact_percentage: 0,
  key_contact_first_name: '',
  key_contact_last_name: '',
  key_contact_phone: '',
  key_contact_email: '',

  company_nzbn_details: {},

  nz_company_autosuggest_field_value: '',
  nz_company_autosuggest_lookup_loading: false,
  nz_company_autosuggest_list: {},
  nz_company_autosuggest_selected_name: '',
  nz_company_autosuggest_selected_details: {},

  nz_company_nzbn_details_loading: false,
  company_directors_container_index: 0,

  physical_address_list: {},
  postal_address_list: {},
  postal_address: '',
  physical_address: '',
  postal_dpid: null,
  physical_dpid: null,
  postal_address_loading: false,
  physical_address_loading: false,

  email_valid: null,
  email_validating: false,
  user_permissions: '',

  invite_resending: false,
};

export function consumerInviteReducer(state = cns_invite_default, action) {
  switch (action.type) {
    case CNS_INVITE_CREATE_FOR_NEW_CONSUMER_START:
    case CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_START:
    case CNS_INVITE_CREATE_FOR_NEW_CONSUMER_SUCCESS:
      return { ...state };
    case CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_SUCCESS:
      return { ...state, saving: false };
    case CNS_INVITE_CREATE_FOR_EXISTING_CASH_CONSUMER_ERROR:
      return { ...state, saving: false };
    case CNS_INVITE_SET_TYPE:
      return { ...state, invite_type: action.payload };
    case CNS_INVITE_COMPANY_NAME_LOOKUP_SUCCESS:
      return {
        ...state,
        company_autosuggest_list: action.payload,
        company_autosuggest_lookup_loading: false,
      };
    case CNS_INVITE_SET_NEW_CONSUMER_FIRST_NAME:
      return { ...state, new_consumer_first_name: action.payload };
    case CNS_INVITE_SET_NEW_CONSUMER_LAST_NAME:
      return { ...state, new_consumer_last_name: action.payload };
    case CNS_INVITE_SET_IUF_ANSWERS:
      return { ...state, iuf_answers: action.payload };
    case CNS_INVITE_SET_NEW_CONSUMER_EMAIL:
      return { ...state, new_consumer_email: action.payload };
    case CNS_INVITE_SET_NEW_CONSUMER_COMPANY_NAME:
      return { ...state, new_consumer_company_name: action.payload };
    case CNS_INVITE_RESEND_APPLICATION_START:
    case CNS_INVITE_RESEND_AUTHORISATION_START:
      return { ...state, invite_resending: true };
    case CNS_INVITE_RESEND_APPLICATION_SUCCESS:
    case CNS_INVITE_RESEND_AUTHORISATION_SUCCESS:
      return { ...state, invite_resending: false };
    case CNS_INVITE_RESEND_APPLICATION_ERROR:
    case CNS_INVITE_RESEND_AUTHORISATION_ERROR:
      return { ...state, invite_resending: false };
    default:
      return { ...state };
  }
}
