import styled from 'styled-components';

export const BulkReassignButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
`;

export const BulkReassignModalTitle = styled.div`
  display: flex;
  justify-content: center;
  span {
    position: absolute;
    margin-top: 10px;
  }
`;
