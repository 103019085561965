import { get } from 'lodash';
import AddonConfigModel from 'models/AddonConfigModel';
import { loadInternalUseFieldsAddon } from 'modules/addons/actions';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Button from 'modules/shared/components/inputs/Button';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import { isEmailPatternValid } from 'modules/shared/components/inputs/EmailInput';
import SquareCheckbox from 'modules/shared/components/inputs/SquareCheckbox';
import SimpleMultiSelectDropdown from 'modules/shared/components/widgets/interactive/SimpleMultiSelectDropdown';
import AdminTitle from 'modules/shared/components/widgets/static/AdminTitle';
import isReviewerEnabled from 'modules/shared/helpers/isReviewerEnabled';
import { RoleType } from 'modules/user/components/UserProfile/types';
import { Checkbox, ListItemText, makeStyles } from 'mui-latest';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-latest';
import { connect } from 'react-redux';
// @ts-ignore - this is still version 3
import { browserHistory } from 'react-router';

import { getLatestApprovalLevels } from '../../../profile/actions';
import {
  checkIfEmailExists,
  clearEmailValidation,
  clearUserContacts,
  loadUser,
  updateUser,
} from '../../actions';
import styles from '../css/UpdateUser.css';
import ManageNonUserKeyContacts from '../ManageNonUserKeyContacts';
import BulkReassign from './components/BulkReassign';
import { ROLE_TYPE_CONSUMER_MANAGER, UserUpdateDefaults } from './constants';
import {
  getApprovalLevelOptions,
  getFormValues,
  getPermissionOptions,
  getUserTypes,
} from './helpers';

const useStyles = makeStyles({
  fullInput: {
    maxWidth: 'calc(90vw * .6)',
  },
  halfInput: {
    maxWidth: 'calc(90vw * .3)',
  },
});

function BulkReassignSection(props) {
  const { user_type } = props;
  if (user_type === 'websitebutton') {
    return <ManageNonUserKeyContacts {...props} />;
  }
  return <BulkReassign {...props} />;
}

const UpdateUser = ({
  dispatch,
  title,
  saving,
  user,
  email_valid,
  isSupplier,
  user_id,
  entityApprovalLevel,
  currentUser,
}) => {
  const classes = useStyles();
  const [bulkReassignModalOpen, setBulkReassignModalOpen] =
    useState<boolean>(false);
  const [hasBulkReassignSelection, setHasBulkReassignSelection] =
    useState<boolean>(false);
  const [originalEmail, setOriginalEmail] = useState<string>('');
  const [shouldBulkReassign, setShouldBulkReassign] = useState<boolean>(false);
  const [isCallCentreActive, setIsCallCentreActive] = useState<boolean>(false);
  const [persistedValues, setPersistedValues] = useState<any>(null);

  const defaultValues = getFormValues(user);

  const { setValue, watch } = useForm({
    defaultValues,
    mode: 'onSubmit',
  });

  const currentValues = watch();

  const backToProfilePage = () => {
    dispatch(clearUserContacts());
    browserHistory.push('/dashboard/profile?active_tab=your_team');
  };

  const evalRoleTypes = (formValues) => {
    const result = [formValues.user_type];
    if (formValues.user_type_consumer_manager) {
      result.push(ROLE_TYPE_CONSUMER_MANAGER);
    }
    return result;
  };

  const checkUpdate = (event) => {
    event.preventDefault();
    if (shouldBulkReassign) {
      setBulkReassignModalOpen(true);
      return;
    } else {
      updateUserData();
    }
  };

  const updateBeforeBulkReassign = (bulkReassign) => {
    updateUserData();
    if (bulkReassign && shouldBulkReassign) {
      bulkReassign();
    }
  };

  const updateUserData = () => {
    const { all_permissions } = currentValues;
    const payload = {
      can_override_limit: all_permissions.includes('can_override_limit'),
      contact_phone_number: currentValues.user_contact_phone_number,
      email: currentValues.user_email,
      first_name: currentValues.user_first_name,
      last_name: currentValues.user_last_name,
      level: currentValues.user_approval_level,
      page_permissions: {
        can_access_tokenised_document: all_permissions.includes(
          'can_access_tokenised_document'
        ),
        can_approve_iuf: all_permissions.includes('can_approve_iuf'),
        can_call_centre_sudo: all_permissions.includes('can_call_centre_sudo'),
        can_review_credit_checks: all_permissions.includes(
          'can_review_credit_checks'
        ),
        can_review_virtual_credit_file: all_permissions.includes(
          'can_review_virtual_credit_file'
        ),
        can_access_ppsr: all_permissions.includes('can_access_ppsr'),
        can_override_direct_debit: all_permissions.includes(
          'can_override_direct_debit'
        ),
        can_create_shadow_vcf: all_permissions.includes(
          'can_create_shadow_vcf'
        ),
      },
      permissions: all_permissions.includes('mandatory_checks') ? 'm' : 'n',
      receive_email_notifications:
        currentValues.user_receive_email_notifications,
      role_types: evalRoleTypes(currentValues),
    };

    dispatch(
      updateUser(user.id, payload, () => setPersistedValues(currentValues))
    );
  };

  useEffect(() => {
    dispatch(clearEmailValidation());
    dispatch(getLatestApprovalLevels());
    dispatch(loadUser(user_id));
    dispatch(loadInternalUseFieldsAddon());
  }, []);

  useEffect(() => {
    AddonConfigModel.fetchAddonConfigByAddonType({
      accessToken: null,
      addonType: ['call_centre'],
      entityId: currentUser.current_entity.id,
    }).then((addonConfigs) => {
      const addonConfig = addonConfigs[0];
      setIsCallCentreActive(!!(addonConfig && addonConfig.active));
    });
  }, []);

  useEffect(() => {
    const currentFormValues = getFormValues(user);

    setOriginalEmail(get(user, 'attributes.email', ''));
    Object.keys(currentFormValues).forEach((key) =>
      setValue(key, currentFormValues[key])
    );
  }, [user]);

  const isSelectedUserStandard = currentValues.user_type === RoleType.Standard;

  useEffect(() => {
    if (isSelectedUserStandard) {
      setValue('user_approval_level', null);
      const nonStandardPermissions = currentValues.all_permissions.filter(
        (permission) =>
          ![
            'can_override_limit',
            'can_approve_iuf',
            'can_access_ppsr',
          ].includes(permission)
      );
      setValue('all_permissions', nonStandardPermissions);
    }
  }, [currentValues.user_type]);

  const isDirty = persistedValues
    ? JSON.stringify(persistedValues) !== JSON.stringify(currentValues)
    : JSON.stringify(defaultValues) !== JSON.stringify(currentValues);

  const validateEmail = (email) => {
    return (
      !!email &&
      isEmailPatternValid(email) &&
      (originalEmail !== currentValues.user_email
        ? email_valid !== false
        : true)
    );
  };

  const validatePhone = (phone) => {
    return !!phone && /^[0-9]*$/.test(phone);
  };

  const isOtherInputValid = () => {
    const { user_first_name, user_last_name, user_type, user_approval_level } =
      currentValues;
    return (
      !!user_first_name &&
      !!user_last_name &&
      !!user_type &&
      ![undefined, ''].includes(user_approval_level)
    );
  };

  const bulkReassignUpdate = hasBulkReassignSelection
    ? !shouldBulkReassign
    : true;

  const isValidEmail = validateEmail(currentValues.user_email);
  const isValidPhone = validatePhone(currentValues.user_contact_phone_number);

  const updateUserCheck =
    !isOtherInputValid() || !isValidEmail || !isValidPhone;

  const updateDisabled = isDirty
    ? hasBulkReassignSelection
      ? updateUserCheck || !shouldBulkReassign
      : updateUserCheck
    : bulkReassignUpdate;

  return (
    <section className={styles.section}>
      {!bulkReassignModalOpen && (
        <CloseButton
          text="Close"
          css_class={'mandatory_button_close'}
          handleClick={backToProfilePage}
        />
      )}
      <form onSubmit={checkUpdate}>
        <div className={styles.row}>
          <div className={styles.columns}>
            <AdminTitle text={title} className={styles.update_user_title} />
            <div className={styles.update_user_section}>
              <div className={styles.update_user_input_columns}>
                <BorderedTextField
                  id="first-name"
                  label="First name"
                  placeholder="First name"
                  value={currentValues.user_first_name}
                  onChange={(event) =>
                    setValue('user_first_name', event.target.value)
                  }
                />
                <BorderedTextField
                  label="Last name"
                  id="last-name"
                  value={currentValues.user_last_name}
                  onChange={(event) =>
                    setValue('user_last_name', event.target.value)
                  }
                />
                <BorderedTextField
                  label="Email"
                  id="email"
                  value={currentValues.user_email}
                  onChange={(event) => {
                    setValue('user_email', event.target.value);
                    if (email_valid !== false) {
                      dispatch(clearEmailValidation());
                    }
                  }}
                  onBlur={(event) => {
                    dispatch(checkIfEmailExists(event.target.value));
                  }}
                  error={!isValidEmail}
                  helperText={!isValidEmail && 'Please enter a valid email'}
                />
                <BorderedTextField
                  label="Phone"
                  id="phone"
                  value={currentValues.user_contact_phone_number}
                  onChange={(event) =>
                    setValue('user_contact_phone_number', event.target.value)
                  }
                  error={!isValidPhone}
                  helperText={
                    !isValidPhone && 'Please enter a valid phone number'
                  }
                />
                <SquareCheckbox
                  checkboxId="emailNotification"
                  checkboxName="emailNotification"
                  checked={currentValues.user_receive_email_notifications}
                  handleChange={() => {
                    setValue(
                      'user_receive_email_notifications',
                      !currentValues.user_receive_email_notifications
                    );
                  }}
                  label="Email notifications"
                  disabled={false}
                />
              </div>
            </div>
            <span className={styles.update_user_input_divider} />
            <div className={styles.update_user_section}>
              <div className={styles.update_user_input_columns}>
                <div className={styles.update_user_input_half}>
                  <AdminTitle
                    text="User type"
                    className={styles.update_user_input_title}
                  />
                  <BorderedSelect
                    id="user-type"
                    menuProps={{
                      MenuListProps: {
                        classes: {
                          root: classes.halfInput,
                        },
                      },
                    }}
                    value={currentValues.user_type}
                    onChange={(event, child) => {
                      const { value, isCustomOption } = child.props;
                      if (isCustomOption) {
                        setValue('user_type_consumer_manager', value);
                      } else {
                        setValue('user_type', event.target.value);
                      }
                    }}
                    placeholder="User type"
                    options={getUserTypes(
                      currentValues.user_type_consumer_manager
                    )}
                    customOptionRenderValue={(value, customOptions) => {
                      const customOption = customOptions[0] || {};
                      return value === true ? customOption.label : null;
                    }}
                    customOptionItemStyle={styles.update_user_custom_item_style}
                    customOptionValue={currentValues.user_type_consumer_manager}
                    renderCustomOption={(
                      option,
                      customOptionValue,
                      inputStyle
                    ) => {
                      return (
                        <React.Fragment>
                          <Checkbox
                            className={inputStyle.radio_input}
                            checked={customOptionValue}
                          />
                          <ListItemText
                            className={inputStyle.text_wrap}
                            primary={option.label}
                            secondary={option.description}
                          />
                        </React.Fragment>
                      );
                    }}
                  />
                </div>
                <div className={styles.update_user_input_half}>
                  <AdminTitle
                    text="Approval level"
                    className={styles.update_user_input_title}
                  />
                  <BorderedSelect
                    hasNullValue
                    id="approval-level"
                    placeholder="Approval level"
                    menuProps={{
                      MenuListProps: {
                        classes: {
                          root: classes.halfInput,
                        },
                      },
                    }}
                    options={getApprovalLevelOptions(
                      entityApprovalLevel,
                      isReviewerEnabled()
                    )}
                    value={currentValues.user_approval_level}
                    onChange={(event) =>
                      setValue('user_approval_level', event.target.value)
                    }
                    disabled={isSelectedUserStandard}
                  />
                </div>
              </div>
              <AdminTitle
                text="Permissions"
                className={styles.update_user_input_title}
              />
              <SimpleMultiSelectDropdown
                id="permissions"
                multiple
                placeholder="Permissions"
                value={currentValues.all_permissions}
                onChange={(target) => setValue('all_permissions', target.value)}
                options={getPermissionOptions(
                  currentUser.isStandardUser || isSelectedUserStandard,
                  isCallCentreActive
                )}
                listHeight={184}
              />
            </div>
            <span className={styles.update_user_input_divider} />
            <div className={styles.update_user_section}>
              {isSupplier && user.attributes && (
                <BulkReassignSection
                  shouldUpdateUser={isDirty}
                  setHasBulkReassignSelection={setHasBulkReassignSelection}
                  setShouldBulkReassign={setShouldBulkReassign}
                  setBulkReassignModalOpen={setBulkReassignModalOpen}
                  bulkReassignModalOpen={bulkReassignModalOpen}
                  updateBeforeBulkReassign={updateBeforeBulkReassign}
                  user_id={user_id}
                  user_type={user.attributes.role_type}
                  userFullName={user.attributes.full_name}
                />
              )}
            </div>
          </div>
        </div>
        <Button
          small
          text="Update"
          disableOnLoading
          type="submit"
          loading_text={'Saving'}
          disabled={updateDisabled}
          loading={saving}
        />
      </form>
    </section>
  );
};

const defaults = UserUpdateDefaults;

export default connect((state, ownProps) => {
  const currentUserId = ownProps.params.userId,
    currentUser = state.current_user,
    ownEntityName = state.current_user.current_entity.attributes.trading_name,
    team_list_loading = state.manage_users.team_list_loading;

  return {
    currentUser,
    current_pricing_plan: state.pricing_plans.current_pricing_plan,
    email_valid: state.manage_users.exists_user
      ? !state.manage_users.exists_user.user_exists
      : false,
    email_validating: state.manage_users.email_validating,
    entityApprovalLevel: state.manage_profile.current_entity_approval_levels,
    isSupplier: state.current_user.current_entity.attributes.supplier,
    ownEntityName,
    saving: state.manage_users.saving,
    team_list_loading: team_list_loading,
    title: defaults.title,
    user: state.manage_users.edit_user || {},
    user_id: currentUserId,
    user_type: defaults.user_type,
  };
})(UpdateUser);
