import {
  useBranchesState,
  useEntityUsersState,
  useSelectedUserState,
} from 'modules/new-applications/hooks/useBranchUserStates';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import React, { useEffect, useState } from 'react';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';

import styles from '../../css/UpdateUser.css';

function useSelectedBranchState(props) {
  const { branchId, branches } = props;

  const [selectedBranchId, setSelectedBranchId] = useState(branchId);
  const selectedOption =
    branches.find((branch) => branch.value === selectedBranchId) || {};

  return { selectedBranchId, selectedOption, setSelectedBranchId };
}

function Form(props) {
  const {
    assignKeyContact,
    assignKeySupplier,
    branches,
    currentUser,
    users,
    onFetchUsers,
    setShouldBulkReassign,
    setHasBulkReassignSelection,
  } = props;

  const { selectedBranchId, setSelectedBranchId } = useSelectedBranchState({
    branches,
  });
  const { selectedUserId, setSelectedUserId } = useSelectedUserState({ users });
  const [hasBranchSelected, setHasBranchSelected] = useState<boolean>(false);
  const [hasUserSelected, setHasUserSelected] = useState<boolean>(false);

  const defaultValues = { userId: selectedUserId };
  if (isPresent(branches)) {
    defaultValues['branchId'] = selectedBranchId;
  }

  const isReallocateFieldVisible =
    isPresent(branches) && !currentUser.isStandard;
  const isDisabled = isBlank(users) || currentUser.isStandard;

  const onSelectBranch = (value) => {
    const keySupplier = branches.find((branch) => branch.value === value);
    setSelectedBranchId(value);
    assignKeySupplier(keySupplier);
    onFetchUsers(value);
  };

  const onSelectUser = (value) => {
    const keyContact = users.find((user) => user.value === value);

    setSelectedUserId(value);
    assignKeyContact(keyContact);
  };

  const hasUsers = users.length > 0;

  const handleSelectBranch = (event) => {
    if (!hasBranchSelected) {
      setHasBranchSelected(true);
      setHasBulkReassignSelection(true);
    }
    onSelectBranch(event.target.value);
  };

  const handleUserSelect = (event) => {
    if (!hasUserSelected) {
      setHasUserSelected(true);
      setHasBulkReassignSelection(true);
    }
    setShouldBulkReassign(true);
    onSelectUser(event.target.value);
  };

  return (
    <div className={styles.row}>
      <div className={styles.columns}>
        <div className={styles.update_user_input_columns}>
          {isReallocateFieldVisible && (
            <BorderedSelect
              id="branch"
              error={!hasUsers}
              helperText={!hasUsers && 'There is no user under this branch.'}
              name="branchId"
              value={selectedBranchId}
              placeholder="Reallocate to"
              options={branches}
              onChange={handleSelectBranch}
            />
          )}
          {hasUsers && (
            <BorderedSelect
              id="users"
              name="userId"
              value={selectedUserId}
              placeholder="Reassign to"
              options={users}
              disabled={isDisabled}
              onChange={handleUserSelect}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default function useBulkReassignForm(props) {
  const { supplierId } = props;
  const { formattedBranches, onFetchBranches } = useBranchesState(props);
  const { formattedUsers, onFetchUsers } = useEntityUsersState(props);

  useEffect(() => {
    onFetchBranches();
  }, []);

  useEffect(() => {
    onFetchUsers(supplierId);
  }, [supplierId]);

  return {
    branches: formattedBranches,
    component: (
      <Form
        branches={formattedBranches}
        onFetchUsers={onFetchUsers}
        {...props}
        users={formattedUsers}
      />
    ),
  };
}
