import EmailVerification from 'modules/shared/components/v2/Modules/EmailVerification';

import Authorisation from '../modules/paperless-onboarding/components/Authorisation';
import Complete from '../modules/paperless-onboarding/components/Complete';
import Details from '../modules/paperless-onboarding/components/Details';
import PaperlessOnBoarding from '../modules/paperless-onboarding/components/PaperlessOnBoarding';
import Layout from '../modules/paperless-onboarding/container/Layout';

const PaperlessOnBoardingRoutes = [
  {
    component: Authorisation,
    path: 'authorisation',
  },
  {
    component: Complete,
    path: 'complete',
  },
  {
    component: EmailVerification,
    path: 'email-verification',
  },
  {
    component: Details,
    path: 'details',
  },
  {
    component: PaperlessOnBoarding,
    path: ':supplier_id/:component(/**)',
  },
];

module.exports = {
  childRoutes: PaperlessOnBoardingRoutes,
  component: Layout,
  path: 'paperless',
};
