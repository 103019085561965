/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/*Import css*/
import styles from './css/NotesModal.css';

/* Import components */
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import FileWidgetButton from 'modules/shared/components/widgets/interactive/FileWidgetButton';
import Button from 'modules/shared/components/inputs/Button';
import LeftArrowButton from 'modules/shared/components/inputs/LeftArrowButton';
import TextArea from 'modules/shared/components/inputs/TextArea';

var NotesModal = createClass({
  handleChange: function(event) {
    this.setState({
      note_text: event.target.value,
    });
  },

  fileUploaded: function(data) {
    this.setState({
      file: data,
    });
  },

  getInitialState: function() {
    return {
      note_text: '',
    };
  },

  save: function() {
    const { handleSave } = this.props;
    const { note_text, file } = this.state;
    handleSave({
      text: note_text,
      file,
    });
  },

  render: function() {
    const { title, handleCancel } = this.props;
    const { note_text } = this.state;
    let body, value;

    // <div className={styles.button}>
    //   <Button text="upload attachment" white={true} handleClick={this.upload} />
    // </div>

    body = (
      <div>
        <div className={styles.textarea}>
          <TextArea
            id={value}
            key={'notes-field'}
            value={note_text}
            handleChange={this.handleChange}
          />
        </div>
        <FileWidgetButton
          text={'upload attachment'}
          handleFileCallback={this.fileUploaded}
        />
        <div className={styles.button}>
          <Button text="close" white={true} handleClick={handleCancel} />
          <Button text={'save'} handleClick={this.save} />
        </div>
      </div>
    );

    return (
      <CircleModal title_css="title" title={title}>
        {body}
      </CircleModal>
    );
  },
});

var defaults = {
  title: 'Upload notes',
};

module.exports = connect((state, ownProps) => {
  return {
    title: defaults.title,
    note_text: '',
  };
})(NotesModal);
