import get from 'lodash.get';
import CompleteApplication from '../modules/user/components/CompleteApplication';
import ForgotPassword from '../modules/user/components/ForgotPassword';
import Login from '../modules/user/components/Login';
import LoginApplication from '../modules/user/components/LoginApplication';
import Logout from '../modules/user/components/Logout';
import PasswordApplication from '../modules/user/components/PasswordApplication';
import Registration from '../modules/user/components/Registration';
import SetPassword from '../modules/user/components/SetPassword';
import Layout from '../modules/user/containers/Layout';

module.exports = {
  childRoutes: [
    {
      component: Login,
      path: 'login',
    },
    {
      component: Logout,
      path: 'logout',
    },
    {
      component: Registration,
      path: 'register',
    },
    {
      component: SetPassword,
      path: 'password',
    },
    {
      component: ForgotPassword,
      path: 'forgot-password',
    },
    {
      component: CompleteApplication,
      path: 'complete-application',
    },
    {
      component: LoginApplication,
      path: 'login-application',
    },
    {
      component: PasswordApplication,
      path: 'password-application',
    },
    {
      component: () => {
        window.location = `${window._env_.API_URL}/auth/google_oauth2`;
        return null;
      },
      path: 'google',
    },
    {
      component: () => {
        window.location = `${window._env_.API_URL}/auth/microsoft_v2_auth`;
        return null;
      },
      path: 'microsoft',
    },
    {
      component: (props) => {
        const entityID = get(props, ['location', 'query', 'entity_id'], '');
        window.location = `${window._env_.API_URL}/auth/saml?entity_id=${entityID}`;
        return null;
      },
      path: 'saml',
    },
  ],
  component: Layout,
  path: 'user',
};
