/* eslint-disable max-classes-per-file */
export function getObjectsFromArray(array, type) {
  const items = [];
  if (!array) return items;

  array.forEach((element) => {
    if (element.type === type) items.push(element);
  });

  return items;
}
/* eslint-enable max-classes-per-file */
