import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS } from 'variables/theme';
import { Typography } from '@material-ui/core';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  .empty-icon {
    color: ${COLORS.mediumGrey};
    font-size: 3rem;
  }
  p {
    color: ${COLORS.mediumGrey};
    font-size: 1.5rem;
  }
`;

export const Empty = () => {
  return (
    <Container>
      <FontAwesomeIcon className="empty-icon" icon="folder-open" />
      <Typography>No items to display</Typography>
    </Container>
  );
};
