import api from 'api';
import get from 'lodash.get';
import BaseModel from 'models/BaseModel';
import UserModel from 'models/UserModel';
import UserRoleModel from 'models/UserRoleModel';
import flattenArray from 'utils/flattenArray';
import isPresent from 'utils/isPresent';
import sortByCreatedAt from 'utils/sortByCreatedAt';

export default class ModulePaperlessApplicationModel extends BaseModel {
  static async fetchModulePaperlessApplications({
    accessToken,
    entityId,
    params,
  }) {
    const modulePaperlessApplicationAPI = api(
      'module_paperless_applications',
      accessToken,
      entityId
    );

    try {
      const result = await modulePaperlessApplicationAPI.getModulePaperlessApplications(
        params
      );

      const applications = get(result, 'data.data', []);
      const included = get(result, 'data.included', []);
      const meta = get(result, 'data.meta', {});

      const modulesPaperlessApplications = applications.map(
        (application) =>
          new ModulePaperlessApplicationModel(application, included)
      );

      return { meta, modulesPaperlessApplications };
    } catch (error) {
      console.error(error);
    }
  }

  static async fetchModulePaperlessApplication({ accessToken, entityId, id }) {
    const modulePaperlessApplicationAPI = api(
      'module_paperless_applications',
      accessToken,
      entityId
    );

    try {
      const result = await modulePaperlessApplicationAPI.getModulePaperlessApplication(
        id
      );

      const meta = get(result, 'data.meta', {});
      const modulePaperlessApplication = new ModulePaperlessApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );

      return { meta, modulePaperlessApplication };
    } catch (error) {
      console.error(error);
    }
  }

  static async updateModulePaperlessApplication({
    accessToken,
    attributes,
    entityId,
    modulePaperlessApplication,
    onSuccessCallback,
  }) {
    const modulePaperlessApplicationAPI = api(
      'module_paperless_applications',
      accessToken,
      entityId
    );

    try {
      const result = await modulePaperlessApplicationAPI.update(
        modulePaperlessApplication.id,
        attributes
      );

      const meta = get(result, 'data.meta', {});
      const updatedModulePaperlessApplication = new ModulePaperlessApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );

      onSuccessCallback({
        meta,
        modulePaperlessApplication: updatedModulePaperlessApplication,
      });
    } catch (error) {
      console.error(error);
    }
  }

  static async reviewModulePaperlessApplication({
    accessToken,
    entityId,
    modulePaperlessApplication,
    onSuccessCallback,
  }) {
    const modulePaperlessApplicationAPI = api(
      'module_paperless_applications',
      accessToken,
      entityId
    );

    try {
      const result = await modulePaperlessApplicationAPI.review(
        modulePaperlessApplication.id
      );
      const meta = get(result, 'data.meta', {});
      const updatedModulePaperlessApplication = new ModulePaperlessApplicationModel(
        get(result, 'data.data'),
        get(result, 'data.included')
      );

      onSuccessCallback({
        meta,
        modulePaperlessApplication: updatedModulePaperlessApplication,
      });
    } catch (error) {
      console.error(error);
    }
  }

  get formattedReviewed() {
    return this.reviewed ? 'Yes' : '-';
  }

  get applicant() {
    return (
      this.userRoles.find((userRole) =>
        get(userRole, 'actingAs.applicant', false)
      ) || {}
    );
  }

  get isComplete() {
    return this.status === 'completed';
  }

  get showAsGreen() {
    return this.load_into_system || this.reviewed;
  }

  get statusMap() {
    if (this.showAsGreen) {
      return 'green';
    }
    if (this.status === 'completed') {
      return 'complete';
    }

    return this.status;
  }

  get isViewOnly() {
    return this.isLocked || this.loadedIntoSystem;
  }

  get isLocked() {
    return this.archived || this.status === 'deleted';
  }

  get isSubmitted() {
    return this.submitted;
  }

  get userRoleHistories() {
    const histories = flattenArray(
      this.userRoles.map((userRole) => userRole.userRoleHistories)
    ).filter((history) => isPresent(history.content));

    return sortByCreatedAt(histories);
  }

  constructor(data = {}, included = []) {
    super(data, included);

    this.assignRelationships();
  }

  onReview({ currentUser, onSuccessCallback }) {
    this.isLoading = true;

    const successCallback = (response) => {
      onSuccessCallback(response);

      this.isLoading = false;
    };

    ModulePaperlessApplicationModel.reviewModulePaperlessApplication({
      accessToken: currentUser.accessToken,
      entityId: get(currentUser, 'currentEntity.id'),
      modulePaperlessApplication: this,
      onSuccessCallback: successCallback,
    });
  }

  update({ currentUser, onSuccessCallback }) {
    this.isLoading = true;

    const successCallback = (response) => {
      onSuccessCallback(response);

      this.isLoading = false;
    };

    ModulePaperlessApplicationModel.updateModulePaperlessApplication({
      accessToken: currentUser.accessToken,
      attributes: { data: { attributes: this.attributes } },
      entityId: get(currentUser, 'currentEntity.id'),
      modulePaperlessApplication: this,
      onSuccessCallback: successCallback,
    });
  }

  /** Private functions */

  assignRelationships() {
    this.assignSingleRelationship({
      key: 'reviewed_by',
      model: UserModel,
    });

    this.assignManyRelationship({
      included: this.included,
      key: 'user_roles',
      model: UserRoleModel,
    });
  }
}
