import styled from 'styled-components';

export const ConfirmAcnModalButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
`;

export const ConfirmAcnTitle = styled.div`
  display: flex;
  justify-content: center;
  span {
    position: absolute;
    margin-top: 10px;
  }
`;

export const ConfirmAcnBoldItalicText = styled.span`
  font-weight: bold !important;
  font-style: italic;
`;
