import { Skeleton } from '@material-ui/lab';
import arrayFromNumber from 'modules/shared/helpers/arrayFromNumber';
import React from 'react';

function AddonSkeleton() {
  function getSkeleton(count: number) {
    return arrayFromNumber(count, (number) => (
      <Skeleton
        key={`addon-skeleton-${number}`}
        animation="wave"
        className="mb-4"
        height={130}
        variant="rect"
      />
    ));
  }

  return (
    <div>
      <Skeleton animation="wave" className="my-6" height={25} variant="rect" />
      {getSkeleton(2)}
    </div>
  );
}

export default AddonSkeleton;
