import React, { ChangeEvent, useContext } from 'react';
// @ts-ignore
import { browserHistory } from 'react-router';

import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import { CustomersContext } from '../CustomersContext';

export const CustomerSearch = () => {
  const { params } = useContext(CustomersContext);

  const currentUrlParams = new URLSearchParams(window.location.search);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    currentUrlParams.set('key_word', e.target.value);
    browserHistory.push(`/dashboard/customers?${currentUrlParams}`);
  };

  return (
    <BorderedTextField
      id="customer-search"
      label="Search"
      onChange={handleChange}
      value={params.key_word}
    />
  );
};
