var StorageService = function(type) {
  this.type = type || 'local';
};

StorageService.prototype.set = function(key, token) {
  if (this.type == 'local') {
    localStorage.setItem(key, token);
  } else {
    sessionStorage.setItem(key, token);
  }
};

StorageService.prototype.get = function(key) {
  if (this.type == 'local') {
    return localStorage.getItem(key);
  } else {
    return sessionStorage.getItem(key);
  }
};

StorageService.prototype.del = function(key) {
  if (this.type == 'local') {
    localStorage.removeItem(key);
  } else {
    sessionStorage.removeItem(key);
  }
};

export default StorageService;
