import React from 'react';
import moment from 'moment';

import styles from '../centrix.css';

class CreditDefaults extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  getArrayifiedData(data) {
    if (!data.data_sets.defaults || !data.data_sets.defaults.default) {
      return [];
    }
    let defaults = data.data_sets.defaults.default;
    if (defaults.constructor !== Array) {
      return [defaults];
    }
    return defaults;
  }

  render() {
    const { data } = this.props;
    let arrayified_data = this.getArrayifiedData(data);

    return (
      <div className={styles.table_container}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className="has-text-weight-medium">Date</th>
              <th className="has-text-weight-medium">Account Type</th>
              <th className="has-text-weight-medium">Credit Provider</th>
              <th className="has-text-weight-medium">Reference</th>
              <th className="has-text-weight-medium">Original Amount</th>
              <th className="has-text-weight-medium">Current Balance</th>
              <th className="has-text-weight-medium">Status</th>
              <th className="has-text-weight-medium">Status Date</th>
            </tr>
          </thead>
          <tbody>
            {arrayified_data.map((item) => (
              <tr>
                <td>{moment(item.date_of_default).format('DD/MM/YYYY')}</td>
                <td>{item.account_type_description}</td>
                <td>{item.credit_provider}</td>
                <td>{item.reference}</td>
                <td>
                  {item.original_default_amount &&
                    item.original_default_amount.constructor === String &&
                    item.original_default_amount}
                </td>
                <td>
                  {item.current_default_balance &&
                    item.current_default_balance.constructor === String &&
                    '$' + item.current_default_balance}
                </td>
                <td>{item.default_status_description}</td>
                <td>{moment(item.status_date).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CreditDefaults;
