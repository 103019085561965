import DateInput from 'modules/shared/components/inputs/DateInput';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { defaultTimeZone, formatLocalTime } from 'utils/dateFormatter';

/* Import actions */
import { loadNotifications } from '../actions';
/* Import CSS */
import styles from './css/AllNotifications.css';
import Notification from './Notification';
import { getRangeNotifications, NotificationItem } from './NotificationItem.js';

const FILTER_PERIODS = [
  {
    callback: () => {
      return { from: null, to: null };
    },
    label: 'All',
    value: 'All',
  },
  {
    callback: () => {
      return { from: moment().startOf('day'), to: moment().endOf('day') };
    },
    label: 'Today',
    value: 'Today',
  },
  {
    callback: () => {
      return {
        from: moment()
          .subtract(1, 'days')
          .startOf('day'),
        to: moment()
          .subtract(1, 'days')
          .endOf('day'),
      };
    },
    label: 'Yesterday',
    value: 'Yesterday',
  },
  {
    callback: () => {
      return {
        from: moment().startOf('week'),
        to: moment().endOf('day'),
      };
    },
    label: 'This week',
    value: 'This week',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(7, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 7 days',
    value: 'Last 7 days',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(30, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 30 days',
    value: 'Last 30 days',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(60, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 60 days',
    value: 'Last 60 days',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(90, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 90 days',
    value: 'Last 90 days',
  },
  {
    label: 'Custom date range',
    value: 'Custom date range',
  },
];

const DROPMENU = {
  filters: [
    {
      label: 'All notifications',
      value: 'all',
    },
    // {
    //   label: "All unactioned notifications",
    //   value: "unactioned",
    // },
    {
      label: 'Tagged as approver',
      value: 'cat_tagged',
    },
    {
      label: 'Application reassign',
      value: 'cat_reassigned',
    },
    {
      label: 'Application review date',
      value: 'cat_app_review',
    },
    {
      label: 'Application approvals',
      value: 'cat_approved',
    },
    {
      label: 'Application declines',
      value: 'cat_declined',
    },
    {
      label: 'Application escalated',
      value: 'cat_escalated',
    },
    {
      label: 'Application de-escalated',
      value: 'cat_deescalated',
    },
    {
      label: 'PPSR expire date',
      value: 'cat_ppsr_renew',
    },
    {
      label: 'Internal use field',
      value: 'cat_iuf',
    },
    {
      label: 'Broker notification',
      value: 'cat_broker',
    },
  ],
  title: 'Notifications',
};

function CustomPeriodFilter(props) {
  const { dateRange, isVisible, onFetchData } = props;

  if (!isVisible) {
    return null;
  }

  const onChangeDateFrom = (value) => {
    onFetchData({
      from: formatLocalTime(moment(value, 'DD/MM/YYYY'), 'DD/MM/YYYY'),
    });
  };
  const onChangeDateTo = (value) => {
    onFetchData({
      to: formatLocalTime(moment(value, 'DD/MM/YYYY'), 'DD/MM/YYYY'),
    });
  };

  return (
    <Fragment>
      <DateInput
        id="from"
        name="from"
        label="Custom date from"
        value={moment(dateRange.from || new Date(), 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        )}
        required={true}
        onChange={onChangeDateFrom}
      />
      <DateInput
        id="to"
        name="to"
        label="Custom date to"
        value={moment(dateRange.to || new Date(), 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        )}
        required={true}
        onChange={onChangeDateTo}
      />
    </Fragment>
  );
}

function PeriodFilter(props) {
  const { onFetchData } = props;
  const [selectedPeriod, setSelectedPeriod] = useState('All');
  const onChangePeriod = (selectedOption) => {
    const { callback, value } = selectedOption;

    if (callback) {
      const { from, to } = callback();

      onFetchData({ from, to });
    }
    setSelectedPeriod(value);
  };

  return (
    <Fragment>
      <OptionsDropdown
        label="Search period"
        required={true}
        value={selectedPeriod}
        options={FILTER_PERIODS}
        handleChange={onChangePeriod}
      />
      <CustomPeriodFilter
        isVisible={selectedPeriod === 'Custom date range'}
        {...props}
      />
    </Fragment>
  );
}

function Filter(props) {
  const { dropMenuValue, dropMenuOptions, dropMenuHandler } = props;

  return (
    <div className={styles.filter_container}>
      <div className={styles.dropdown}>
        <OptionsDropdown
          label="Filter by"
          label_display="onHover"
          value={dropMenuValue}
          options={dropMenuOptions}
          theme="default"
          required={true}
          handleChange={dropMenuHandler}
        />
      </div>
      <PeriodFilter {...props} />
    </div>
  );
}

const AllNotifications = (props) => {
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [range, setRange] = useState({});
  useEffect(() => {
    const timezone = defaultTimeZone();

    try {
      if (range.from) {
        range.from = range.from.tz(timezone).format();
      }

      if (range.to) {
        range.to = range.to.tz(timezone).format();
      }
    } catch (e) {
      console.error(e);
    }
    dispatch(loadNotifications({ filter: selectedFilter, ...range }));
  }, [range, selectedFilter]);

  const { dispatch, notifications, title, count, filters } = props;
  const notificationElements = [];

  notifications.forEach((v, i) => {
    notificationElements.push(
      <Notification key={i} notification={v} layout="full" />
    );
  });

  const onFetchData = (value) => {
    setRange(value);
  };

  const handleDropDown = (v) => {
    setSelectedFilter(v.value);
  };

  return (
    <section className={styles.section}>
      <div className="is-size-large has-text-weight-medium mt-4 mb-6">
        {title}
      </div>
      <div className={styles.row}>
        <div className={styles.content}>
          <Filter
            dropMenuValue={selectedFilter}
            dropMenuOptions={filters}
            dropMenuHandler={handleDropDown}
            dateRange={range}
            onFetchData={onFetchData}
            {...props}
          />
          <div className="block mb-0 has-background-primary-light ">
            <div className="columns">
              <div className="column is-12 ">
                <NotificationItem
                  notifications={getRangeNotifications(notifications, 0, 1)}
                  title="Today"
                  detail="true"
                />
                <NotificationItem
                  notifications={getRangeNotifications(notifications, -1, 1)}
                  title="Yesterday"
                  detail="true"
                />
                <NotificationItem
                  notifications={getRangeNotifications(notifications, -2, -1)}
                  title="Earlier"
                  detail="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect((state) => {
  const notifications = state.notifications.notifications;
  const unactioned = notifications.filter((v) => !v.attributes.actioned);

  return {
    count: unactioned ? unactioned.length : 0,
    filters: DROPMENU.filters,
    notifications,
    timezone: state.current_user.current_entity.attributes.timezone,
    title: DROPMENU.title,
  };
})(AllNotifications);
