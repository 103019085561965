// import "bulma/css/bulma.css";

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import api from 'api';
import { FEATURE_FLAGS } from 'conf';
import google from 'images/google.png';
import microsoft from 'images/microsoft.png';
import GroupIcon from '@material-ui/icons/Group';
import get from 'lodash.get';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import Button from 'modules/shared/components/inputs/Button';
import SquareModal from 'modules/shared/components/widgets/static/SquareModal';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import styles from './css/CommonEditProfile.css';
import SAMLSettingsModal from './SAMLSettingsModal';

const EditSAMLSettingsButton = styled.div`
  color: var(--main-color);
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 8px;
  transform: translateX(calc(100% + 16px));
`;

const SAMLIcon = styled(GroupIcon)`
  color: var(--main-color);
  font-size: 17px;
  margin-left: 0.5rem;
`

const ConfirmPopup = ({ popup, handleClose, handleConfirm, handleCancel }) =>
  popup && (
    <SquareModal title="Important" size="small" dismissHandler={handleClose}>
      <div>
        <p className={styles.confirm_title}>
          Single Sign-On (SSO) is a session and user authentication service that
          permits a user to use one set of login credentials - for example, a
          name and password to access multiple applications. In this instance,
          turning on SSO in your 1Centre account{' '}
          <span className={styles.confirm_emphasize}>
            will force your users to sign in using your domain credentials.{' '}
          </span>
          It also safe guards your 1Centre account, ensuring only users with a
          correct and current email address can login.{' '}
        </p>
        <div>
          <Button text={'Yes, I confirm'} handleClick={handleConfirm} />
          <Button text={'Cancel'} white={true} handleClick={handleCancel} />
        </div>
      </div>
    </SquareModal>
  );

const ssoOptions = [
  { brief: 'No Provider', label: <div>No Provider</div>, value: 'no_provider' },
  {
    brief: 'Google',
    label: (
      <div className="is-flex is-align-items-center">
        Google (OAuth 2.0)
        <img src={google} className={`ml-2 mt-0 ${styles.m_img_v2}`} />
      </div>
    ),
    value: 'google',
  },
  {
    brief: 'Microsoft',
    label: (
      <div className="is-flex is-align-items-center">
        Microsoft (OAuth 2.0)
        <img src={microsoft} className={`ml-2 mt-0 ${styles.m_img_v2}`} />
      </div>
    ),
    value: 'microsoft',
  },
  {
    brief: 'SAML',
    label: (
      <div className="is-flex is-align-items-center">
        SAML (Azure AD, Okta)
        <SAMLIcon />
      </div>
    ),
    value: 'saml',
  },
];

const SsoComponent = ({ eid, access_token }) => {
  const [provider, setProvider] = useState(null);
  const [popup, setPopup] = useState(false);
  const [targetValue, setTargetValue] = useState(null);

  // SAML settings related states
  const [baseSAMLSettings, setBaseSAMLSettings] = useState();
  const [showSAMLSettings, setShowSAMLSettings] = useState(false);
  const [isLoadingSAMLSettings, setIsLoadingSAMLSettings] = useState(false);

  const hasBaseSAMLConfig = useMemo(
    () =>
      baseSAMLSettings &&
      baseSAMLSettings.certificate_fingerprint &&
      baseSAMLSettings.sso_url,
    [baseSAMLSettings]
  );

  useEffect(() => {
    let isSubscribed = true;

    (async function () {
      const res = await api('entities', access_token, eid).getEntity(eid);

      if (!res || !isSubscribed) {
        return;
      }

      setProvider(res.data.data.attributes.sso_provider);
    })();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (
      FEATURE_FLAGS.SAML_FLOW &&
      targetValue === 'saml' // when user changes from other SSO method to SAML
    ) {
      (async () => {
        try {
          setIsLoadingSAMLSettings(true);
          const res = await api('sso_saml').getDetails(eid);
          const settings = get(res, 'data.data', undefined);
          setBaseSAMLSettings(settings);
          setIsLoadingSAMLSettings(false);

          const hasConfig =
            !!settings &&
            !!settings.sso_url &&
            !!settings.certificate_fingerprint;

          // if user is changing their SSO to SAML and already has a SAML config for their org
          if (targetValue === 'saml' && hasConfig) {
            setPopup(true);
          }
        } catch (error) {
          // if user is changing their SSO to SAML and their org is has no SAML config then show SAML settings modal
          if (targetValue === 'saml' && error.response.status === 404) {
            setIsLoadingSAMLSettings(false);
            setShowSAMLSettings(true);
          }
        }
      })();
    }
  }, [targetValue]);

  useEffect(() => {
    if (
      FEATURE_FLAGS.SAML_FLOW &&
      // when user is already on SAML and component has mounted or when user opens the SAML settings modal
      ((targetValue === null && provider === 'saml' && !hasBaseSAMLConfig) ||
        showSAMLSettings)
    ) {
      (async () => {
        try {
          setIsLoadingSAMLSettings(true);
          const res = await api('sso_saml').getDetails(eid);
          const settings = get(res, 'data.data', undefined);
          setBaseSAMLSettings(settings);
          setIsLoadingSAMLSettings(false);
        } catch (error) {
          setIsLoadingSAMLSettings(false);
        }
      })();
    }
  }, [targetValue, provider, hasBaseSAMLConfig, showSAMLSettings]);

  const tips = {
    google: 'This section requires users to log in with Google account',
    microsoft: 'This section requires users to log in with Microsoft account',
    no_provider: "This section doesn't require single sign on(SSO)",
    ...(FEATURE_FLAGS.SAML_FLOW && {
      saml: 'This section requires user to log in with SAML provider account',
    }),
  };

  async function update(v) {
    if (!v) {
      return;
    }
    try {
      await api('entities').setSSOProvider(eid, {
        sso_provider: v,
      });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const handleConfirm = useCallback(async () => {
    setPopup(false);
    setProvider(targetValue);
    await update(targetValue);
  }, [targetValue]);

  const closePopup = useCallback(() => {
    setTargetValue(null);
    setPopup(false);
  }, []);

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <FormControl component="fieldset" className={styles.sso_font_size}>
        <div className="mb-2">
          <label htmlFor="require_sso">Single sign on (SSO) log in</label>
        </div>
        {/* Show radio group if SAML feature is disabled */}
        {!FEATURE_FLAGS.SAML_FLOW && (
          <RadioGroup
            row
            aria-label="sso-provider"
            name="sso-provider"
            value={provider}
            onChange={(e) => {
              setPopup(true);
              setTargetValue(e.target.value);
            }}
          >
            <FormControlLabel
              value="no_provider"
              control={<Radio />}
              label="None"
              classes={{
                label: styles.radio_first_label,
                root: styles.radio_root,
              }}
            />
            <FormControlLabel
              value="google"
              control={<Radio />}
              label="Google"
              classes={{
                label: styles.radio_next_label,
                root: styles.radio_root,
              }}
            />
            <img src={google} className={styles.m_img} />
            <FormControlLabel
              value="microsoft"
              control={<Radio />}
              label="Microsoft"
              classes={{
                label: styles.radio_next_label,
                root: styles.radio_root,
              }}
            />
            <img src={microsoft} className={styles.m_img} />
          </RadioGroup>
        )}
        {/* Show new SSO dropdown if SAML feature is enabled */}
        {FEATURE_FLAGS.SAML_FLOW && (
          <div style={{ position: 'relative' }}>
            <BorderedSelect
              disabled={isLoadingSAMLSettings}
              name="sso-select"
              onChange={(e) => {
                if (e.target.value !== 'saml') {
                  setPopup(true);
                }
                setTargetValue(e.target.value);
              }}
              formControlCustomProps={{ withBottomMargin: false }}
              options={ssoOptions}
              value={provider}
            />

            {provider === 'saml' && (
              <EditSAMLSettingsButton onClick={() => setShowSAMLSettings(true)}>
                Edit SAML Settings
              </EditSAMLSettingsButton>
            )}
          </div>
        )}
        <div className="mt-2">
          <label className="pl-1">{tips[provider]}</label>
        </div>

        {FEATURE_FLAGS.SAML_FLOW && (
          <SAMLSettingsModal
            isOpen={showSAMLSettings}
            entityID={eid}
            showPopup={() => setPopup(true)}
            handlePopupClose={closePopup}
            baseSAMLSettings={baseSAMLSettings}
            handleClose={() => setShowSAMLSettings(false)}
          />
        )}
        <br></br>
        <br></br>
      </FormControl>

      <ConfirmPopup
        provider={provider}
        popup={popup}
        handleCancel={closePopup}
        handleConfirm={handleConfirm}
        handleClose={closePopup}
      />
    </MuiThemeProvider>
  );
};

export default SsoComponent;
