import axios from './axios';

let EntityEmailTemplates = function(config) {
  config.timeout = 90000;
  this.axios = axios(config);
  this.type = 'entity_email_templates';
};

EntityEmailTemplates.prototype.getEntityEmailTemplate = function(
  id,
  success,
  error,
  options = {}
) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

EntityEmailTemplates.prototype.updateEntityEmailTemplate = function(
  id,
  attributes,
  success,
  error
) {
  return this.axios
    .patch(`/${this.type}/${id}`, {
      data: {
        type: this.type,
        id: id,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default EntityEmailTemplates;
