import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import CardholderModel from 'models/CardholderModel';
import Cardholder from 'modules/new-applications/components/application-sections/Cardholder/index';
import ContentWithFooter from 'modules/shared/components/containers/ContentWithFooter';
import React from 'react';

export default function Cards(props) {
  const { application } = props;

  if (!application && !application.id) {
    return;
  }

  let cardholders = application.cardholders;

  if (isEmpty(cardholders)) {
    cardholders = [new CardholderModel()];
  }

  const cardholderComponents = cardholders.map((cardholder, index) => (
    <Cardholder
      key={`cardholder-${index + 1}`}
      addonRule={application.cardsAddonRule}
      cardholder={cardholder}
      index={index + 1}
      isSignatureRequired={application.requiresCardholderSignature}
    />
  ));

  return (
    <ContentWithFooter
      footer={get(application, 'cardsAddonRule.formattedRuleLabel')}
    >
      {cardholderComponents}
    </ContentWithFooter>
  );
}
