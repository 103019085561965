import axios from './axios';

let ApprovalHierarchies = function(config) {
  config.timeout = 60000;
  this.axios = axios(config);
  this.type = 'approval_hierarchies';
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
};

ApprovalHierarchies.prototype.getLatestApprovalHierarchy = function(
  success,
  error
) {
  return this.axios
    .get(`/${this.type}/latest`)
    .then(success)
    .catch(error);
};

ApprovalHierarchies.prototype.getApprovalHierarchy = function(
  version,
  success,
  error
) {
  return this.axios
    .get(`/${this.type}/${version}`)
    .then(success)
    .catch(error);
};

ApprovalHierarchies.prototype.getApprovalHierarchies = function(
  success,
  error
) {
  return this.axios
    .get(`/${this.type}`)
    .then(success)
    .catch(error);
};

ApprovalHierarchies.prototype.updateApprovalHierarchy = function(
  attributes,
  success,
  error
) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default ApprovalHierarchies;
