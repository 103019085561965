import React from 'react';

/* Import styles */
import styles from './css/TextInput.css';

var Password = createClass({
  getInitialState: function() {
    const { value } = this.props;

    // My attempt to make the fields show values on edit forms...
    // used to just be false.
    return {
      focus: !!value,
    };
  },

  onClick: function() {
    this.setState({
      focus: true,
    });
  },

  getClass: function() {
    if (this.state.focus === true) {
      return styles.text_input_focus;
    } else {
      return styles.text_input;
    }
  },

  render: function() {
    const { label, handleChange, handleBlur, error, id, value } = this.props;
    let labelElement,
      input_style = styles.input;
    var container_style = this.getClass();

    if (label) {
      labelElement = (
        <label className={styles.label} htmlFor={id}>
          <span>{label}</span>
        </label>
      );
    }

    if (value) {
      container_style = styles.text_input_focus;
    }

    if (error) {
      labelElement = (
        <label className={styles.label_error} htmlFor={id}>
          <span>{error}</span>
        </label>
      );
      input_style = styles.input_error;
    }

    return (
      <div className={container_style}>
        <input
          className={input_style}
          name={id}
          type="password"
          onClick={this.onClick}
          onFocus={this.onClick}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          required={true}
        />
        {labelElement}
      </div>
    );
  },
});

module.exports = Password;
