import styles from 'modules/shared/components/containers/css/ScrollableContent.css';
import React from 'react';

function containerClassName(props) {
  const { withLeftMargin, withRightPadding, withWhiteBackground } = props;
  const defaultClassNames = [styles.scrollable];

  if (withLeftMargin) {
    defaultClassNames.push(styles.with_left_margin);
  }

  if (withRightPadding) {
    defaultClassNames.push(styles.with_right_padding);
  }

  if (withWhiteBackground) {
    defaultClassNames.push(styles.with_white_background);
  }

  return defaultClassNames.join(' ');
}

export default function ScrollableContent(props) {
  return <div className={containerClassName(props)}>{props.children}</div>;
}

ScrollableContent.defaultProps = {
  withLeftMargin: false,
  withRightPadding: true,
  withWhiteBackground: false,
};
