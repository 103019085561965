import {
  APPLICATIONS_ACCEPT_SUBMIT_ERROR,
  APPLICATIONS_ACCEPT_SUBMIT_START,
  APPLICATIONS_ACCEPT_SUBMIT_SUCCESS,
  APPLICATIONS_CLEAR_REVIEW_DATA,
  APPLICATIONS_CONSUMER_LOAD_LIST_ERROR,
  APPLICATIONS_CONSUMER_LOAD_LIST_START,
  APPLICATIONS_CONSUMER_LOAD_LIST_SUCCESS,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_ERROR,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_START,
  APPLICATIONS_CONSUMER_USER_LOAD_LIST_SUCCESS,
  APPLICATIONS_CREATE_NOTES_ERROR,
  APPLICATIONS_CREATE_NOTES_START,
  APPLICATIONS_CREATE_NOTES_SUCCESS,
  APPLICATIONS_DECLINE_SUBMIT_ERROR,
  APPLICATIONS_DECLINE_SUBMIT_START,
  APPLICATIONS_DECLINE_SUBMIT_SUCCESS,
  APPLICATIONS_DOWNLOAD_CSV_ERROR,
  APPLICATIONS_DOWNLOAD_CSV_START,
  APPLICATIONS_DOWNLOAD_CSV_SUCCESS,
  APPLICATIONS_EXPORT_ERROR,
  APPLICATIONS_EXPORT_START,
  APPLICATIONS_EXPORT_SUCCESS,
  APPLICATIONS_GET_CONNECTION_ID_SUCCESS,
  APPLICATIONS_LOAD_APPROVAL_LEVELS_ERROR,
  APPLICATIONS_LOAD_APPROVAL_LEVELS_START,
  APPLICATIONS_LOAD_APPROVAL_LEVELS_SUCCESS,
  APPLICATIONS_LOAD_CREDIT_CHECKS_ERROR,
  APPLICATIONS_LOAD_CREDIT_CHECKS_START,
  APPLICATIONS_LOAD_CREDIT_CHECKS_SUCCESS,
  APPLICATIONS_LOAD_CREDIT_STATUS_ERROR,
  APPLICATIONS_LOAD_CREDIT_STATUS_START,
  APPLICATIONS_LOAD_CREDIT_STATUS_SUCCESS,
  APPLICATIONS_LOAD_NOTES_ERROR,
  APPLICATIONS_LOAD_NOTES_START,
  APPLICATIONS_LOAD_NOTES_SUCCESS,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_ERROR,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_STARTED,
  APPLICATIONS_REVIEW_BUSINESS_DATA_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_BUSINESS_PEOPLE_DATA_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_LOAD_ERROR,
  APPLICATIONS_REVIEW_LOAD_START,
  APPLICATIONS_REVIEW_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_ERROR,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_START,
  APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_SUCCESS,
  APPLICATIONS_REVIEW_SET_ACCOUNT_LIMIT,
  APPLICATIONS_REVIEW_SET_TRADE_DISCOUNT,
  APPLICATIONS_SET_GUARANTOR_ID_STATUS_ERROR,
  APPLICATIONS_SET_GUARANTOR_ID_STATUS_START,
  APPLICATIONS_SET_GUARANTOR_ID_STATUS_SUCCESS,
  APPLICATIONS_SET_REVIEW_ACCEPT_NOTES_DATA,
  APPLICATIONS_SET_REVIEW_DECLINE_NOTES_DATA,
  APPLICATIONS_SET_REVIEW_INDEX,
  APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_ERROR,
  APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_START,
  APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_SUCCESS,
  APPLICATIONS_SET_SIGNATURE_STATUS_ERROR,
  APPLICATIONS_SET_SIGNATURE_STATUS_START,
  APPLICATIONS_SET_SIGNATURE_STATUS_SUCCESS,
  APPLICATIONS_SET_TRADE_REFERENCE_STATUS_ERROR,
  APPLICATIONS_SET_TRADE_REFERENCE_STATUS_START,
  APPLICATIONS_SET_TRADE_REFERENCE_STATUS_SUCCESS,
  APPLICATIONS_SUPPLIER_LOAD_LIST_ERROR,
  APPLICATIONS_SUPPLIER_LOAD_LIST_START,
  APPLICATIONS_SUPPLIER_LOAD_LIST_SUCCESS,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_ERROR,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_START,
  APPLICATIONS_SUPPLIER_USER_LOAD_LIST_SUCCESS,
  APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_ERROR,
  APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_START,
  APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_SUCCESS,
  APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_ERROR,
  APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_START,
  APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_SUCCESS,
  APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_ERROR,
  APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_START,
  APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_SUCCESS,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_START,
  APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_SUCCESS,
  APPLICATIONS_UPLOAD_ID_ERROR,
  APPLICATIONS_UPLOAD_ID_START,
  APPLICATIONS_UPLOAD_ID_SUCCESS,
  APPLICATIONS_WEBSITEBUTTON_SEND_ERROR,
  APPLICATIONS_WEBSITEBUTTON_SEND_START,
  APPLICATIONS_WEBSITEBUTTON_SEND_SUCCESS,
} from './constants';

var applications_defaults = {
  supplier_list: [],
  supplier_user_list: [],
  supplier_list_loading: false,
  consumer_list: [],
  consumer_user_list: [],
  consumer_list_loading: false,
  review_application_loading: false,
  review_application: null,
  review_container_index: null,
  review_business_people: null,
  review_payment_info_reviews: [],
  decline_notes: '',
  accept_notes: '',
  review_account_limit: null,
  review_trade_discount: null,
  update_trade_reference_id: null,
  download_csv_loading: false,
  exporting: false,
  websitebutton_saving: false,
  temp_ID_src: null,
  ID_update_loading: false,
  guarantor_form_values: [],
  review_notes_loading: false,
  all_notes_list: [],
  note_updating: false,
  trc_notes_updating: [],
  trc_notes_errors: [],
  approval_levels_loading: false,
  approval_levels: [],
  credit_status: [],
  credit_status_loading: false,
  credit_checks_loading: false,
  signature_status_saving: false,
  addonAnswerFileUpdating: false,
  proofOfAddressUpdating: false,
  legalEntityAgreementUpdating: false,
};

export function applicationsReducer(state = applications_defaults, action) {
  switch (action.type) {
    case APPLICATIONS_CLEAR_REVIEW_DATA:
      return {
        ...state,
        review_application: null,
        review_account_limit: null,
        review_trade_discount: null,
        submitting: false,
      };
    case APPLICATIONS_ACCEPT_SUBMIT_SUCCESS:
      return {
        ...state,
        accept_notes: '',
        review_account_limit: null,
        review_trade_discount: null,
        submitting: false,
      };
    case APPLICATIONS_DECLINE_SUBMIT_SUCCESS:
      return { ...state, decline_notes: '', submitting: false };
    case APPLICATIONS_REVIEW_SET_TRADE_DISCOUNT:
      return { ...state, review_trade_discount: action.payload };
    case APPLICATIONS_REVIEW_SET_ACCOUNT_LIMIT:
      return { ...state, review_account_limit: action.payload };
    case APPLICATIONS_SET_REVIEW_DECLINE_NOTES_DATA:
      return { ...state, decline_notes: action.payload };
    case APPLICATIONS_SET_REVIEW_ACCEPT_NOTES_DATA:
      return { ...state, accept_notes: action.payload };
    case APPLICATIONS_REVIEW_PAYMENT_DATA_LOAD_SUCCESS:
      return { ...state, review_payment_info_reviews: action.payload };
    case APPLICATIONS_REVIEW_BUSINESS_PEOPLE_DATA_LOAD_SUCCESS:
      return { ...state, review_business_people: action.payload };
    case APPLICATIONS_REVIEW_LOAD_START:
      return { ...state, review_application_loading: true };
    case APPLICATIONS_REVIEW_LOAD_SUCCESS:
      return {
        ...state,
        review_application: action.payload,
        review_application_loading: false,
      };
    case APPLICATIONS_SUPPLIER_LOAD_LIST_START:
      return { ...state, supplier_list_loading: true };
    case APPLICATIONS_SUPPLIER_LOAD_LIST_SUCCESS:
      return {
        ...state,
        supplier_list: action.payload,
        supplier_list_loading: false,
      };
    case APPLICATIONS_SUPPLIER_USER_LOAD_LIST_START:
      return { ...state, supplier_list_loading: true };
    case APPLICATIONS_SUPPLIER_USER_LOAD_LIST_SUCCESS:
      return {
        ...state,
        supplier_user_list: action.payload,
        supplier_list_loading: false,
      };
    case APPLICATIONS_CONSUMER_LOAD_LIST_START:
      return { ...state, consumer_list_loading: true };
    case APPLICATIONS_CONSUMER_LOAD_LIST_SUCCESS:
      return {
        ...state,
        consumer_list: action.payload,
        consumer_list_loading: false,
      };
    case APPLICATIONS_CONSUMER_USER_LOAD_LIST_START:
      return { ...state, consumer_list_loading: true };
    case APPLICATIONS_CONSUMER_USER_LOAD_LIST_SUCCESS:
      return {
        ...state,
        consumer_user_list: action.payload,
        consumer_list_loading: false,
      };
    case APPLICATIONS_SET_REVIEW_INDEX:
      return { ...state, review_container_index: action.payload };
    case APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_START:
      var notes_updating = state.trc_notes_updating;
      var id = action.payload;
      var new_array =
        notes_updating.indexOf(id) === -1
          ? [...notes_updating, id]
          : notes_updating;

      return { ...state, trc_notes_updating: new_array };
    case APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_SUCCESS:
      var notes_updating = state.trc_notes_updating;
      var notes_errors = state.trc_notes_errors;
      var success_id = action.payload;
      var new_array = notes_updating.filter((id) => id !== success_id);
      var new_errors_array = notes_errors.filter((id) => id !== success_id);

      return {
        ...state,
        trc_notes_updating: new_array,
        trc_notes_errors: new_errors_array,
      };
    case APPLICATIONS_SET_REVIEW_TRADE_REFERENCE_NOTES_ERROR:
      var notes_errors = state.trc_notes_errors;
      var id = action.payload;
      var new_array =
        notes_errors.indexOf(id) === -1 ? [...notes_errors, id] : notes_errors;

      return { ...state, trc_notes_errors: new_array };
    case APPLICATIONS_SET_TRADE_REFERENCE_STATUS_START:
      return { ...state, update_trade_reference_id: action.payload.id };
    case APPLICATIONS_SET_TRADE_REFERENCE_STATUS_SUCCESS:
      return { ...state, update_trade_reference_id: action.payload };
    case APPLICATIONS_SET_TRADE_REFERENCE_STATUS_ERROR:
      return { ...state, update_trade_reference_id: action.payload };

    case APPLICATIONS_SET_GUARANTOR_ID_STATUS_START:
      return { ...state, update_guarantor_id: action.payload.id };
    case APPLICATIONS_SET_GUARANTOR_ID_STATUS_SUCCESS:
      return { ...state, update_guarantor_id: action.payload };
    case APPLICATIONS_SET_GUARANTOR_ID_STATUS_ERROR:
      return { ...state, update_guarantor_id: action.payload };

    case APPLICATIONS_DOWNLOAD_CSV_START:
      return { ...state, download_csv_loading: true };
    case APPLICATIONS_DOWNLOAD_CSV_SUCCESS:
      return { ...state, download_csv_loading: false };

    case APPLICATIONS_EXPORT_START:
      return { ...state, exporting: true };
    case APPLICATIONS_EXPORT_SUCCESS:
      return { ...state, exporting: false };
    case APPLICATIONS_EXPORT_ERROR:
      return { ...state, exporting: false };

    case APPLICATIONS_WEBSITEBUTTON_SEND_START:
      return { ...state, websitebutton_saving: true };
    case APPLICATIONS_WEBSITEBUTTON_SEND_SUCCESS:
      return { ...state, websitebutton_saving: false };
    case APPLICATIONS_WEBSITEBUTTON_SEND_ERROR:
      return { ...state, websitebutton_saving: false };

    case APPLICATIONS_UPLOAD_ID_START:
      return { ...state, ID_update_loading: true };
    case APPLICATIONS_UPLOAD_ID_SUCCESS:
      return { ...state, ID_update_loading: false };
    case APPLICATIONS_UPLOAD_ID_ERROR:
      return { ...state, ID_update_loading: false };

    case APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_START:
      return { ...state, review_notes_loading: true };
    case APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_SUCCESS:
      return { ...state, review_notes_loading: false };
    case APPLICATIONS_UPDATE_REVIEW_APPROVE_NOTES_ERROR:
      return { ...state, review_notes_loading: false };

    case APPLICATIONS_CREATE_NOTES_START:
      return { ...state, note_updating: true };
    case APPLICATIONS_CREATE_NOTES_SUCCESS:
      return {
        ...state,
        note_updating: false,
        all_notes_list: [...state.all_notes_list, action.payload],
      };
    case APPLICATIONS_CREATE_NOTES_ERROR:
      return { ...state, note_updating: false };

    case APPLICATIONS_LOAD_NOTES_START:
      return { ...state, note_updating: true };
    case APPLICATIONS_LOAD_NOTES_SUCCESS:
      return { ...state, note_updating: false, all_notes_list: action.payload };
    case APPLICATIONS_LOAD_NOTES_ERROR:
      return { ...state, note_updating: false };

    case APPLICATIONS_LOAD_APPROVAL_LEVELS_START:
      return { ...state, approval_levels_loading: true };
    case APPLICATIONS_LOAD_APPROVAL_LEVELS_SUCCESS:
      return {
        ...state,
        approval_levels_loading: false,
        approval_levels: action.payload,
      };
    case APPLICATIONS_LOAD_APPROVAL_LEVELS_ERROR:
      return { ...state, approval_levels_loading: false };

    case APPLICATIONS_GET_CONNECTION_ID_SUCCESS:
      return { ...state, current_connection_id: action.payload };

    case APPLICATIONS_LOAD_CREDIT_STATUS_START:
      return { ...state, credit_status_loading: true };
    case APPLICATIONS_LOAD_CREDIT_STATUS_SUCCESS:
      return {
        ...state,
        credit_status_loading: false,
        credit_status: action.payload,
      };
    case APPLICATIONS_LOAD_CREDIT_STATUS_ERROR:
      return { ...state, credit_status_loading: false };
    case APPLICATIONS_LOAD_CREDIT_CHECKS_START:
      return { ...state, credit_checks_loading: true };
    case APPLICATIONS_LOAD_CREDIT_CHECKS_SUCCESS:
      return {
        ...state,
        credit_checks_loading: false,
        review_application: action.payload,
      };
    case APPLICATIONS_LOAD_CREDIT_CHECKS_ERROR:
      return { ...state, credit_checks_loading: false };
    case APPLICATIONS_SET_SIGNATURE_STATUS_START:
      return { ...state, signature_status_saving: true };
    case APPLICATIONS_SET_SIGNATURE_STATUS_SUCCESS:
      return { ...state, signature_status_saving: false };
    case APPLICATIONS_SET_SIGNATURE_STATUS_ERROR:
      return { ...state, signature_status_saving: false };

    case APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_START:
      return { ...state, addonAnswerFileUpdating: true };
    case APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_SUCCESS:
      return { ...state, addonAnswerFileUpdating: false };
    case APPLICATIONS_UPLOAD_ADDON_ANSWER_FILE_ERROR:
      return { ...state, addonAnswerFileUpdating: false };

    case APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_START:
      return { ...state, proofOfAddressUpdating: true };
    case APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_SUCCESS:
    case APPLICATIONS_UPDATE_PROOF_OF_ADDRESS_ERROR:
      return { ...state, proofOfAddressUpdating: false };

    case APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_START:
      return { ...state, legalEntityAgreementUpdating: true };
    case APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_SUCCESS:
    case APPLICATIONS_UPDATE_LEGAL_ENTITY_AGREEMENT_ERROR:
      return { ...state, legalEntityAgreementUpdating: false };

    default:
      return { ...state };
  }
}
