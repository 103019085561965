import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form-latest';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import RuleSetSection from './RuleSetSection';
import Button from 'modules/shared/components/inputs/Button';
import { loadAutoDecisions } from 'modules/addons/actions';
import { loadCurrentEntity } from 'modules/profile/actions.js';
import { IAutoDecision, IFinancials, IRuleSetAttributes } from './type';
import FormFieldSection from './FormFieldSection';
import YesOrNoRadioBoxGroup, {
  YesOrNo,
  getYesOrNo,
} from './YesOrNoRadioBoxGroup';
import LimitRangeWithControls from 'modules/shared/components/v2/LimitRangeWithControls/LimitRangeWithControls';
import RHFBorderedTextField from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextField';
import InputRow from './InputRow';
import { processFinancialAddons } from './utils';
import { getFinancialTabPauseReasons } from './getPauseReasons';

const PropertySectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacing(3)}px`,
  '& .MuiInputBase-root': {
    minWidth: 224,
  },
}));

export interface IFormFields {
  financialsRequired: YesOrNo;
  netWorth: {
    fixed: { min: number | null; max: number | null };
    dynamic: { min: number | null; max: number | null };
  };
  surplus: {
    fixed: { min: number | null; max: number | null };
    dynamic: { min: number | null; max: number | null };
  };
  property: {
    min: number | null;
    minNet: number | null;
  };
}
interface IProps {
  attributes: IRuleSetAttributes;
  isReadyToSubmitForm: () => boolean;
  isUpdating: boolean;
  actions: {
    loadCurrentEntity: () => void;
    loadAutoDecisions: () => void;
  };
  currentRuleSet: IAutoDecision;
  nameModified: boolean;
  setNameModified: (boolean) => void;
  creditCheckApplicationTypes: string[];
  financialAddonRules: any[];
  submitButtonLabel: string;
  onSubmitTabData: (data: IFinancials) => void;
  disabled?: boolean;
  hideSaveButton?: boolean;
  rulesetName: IRuleSetAttributes['name'];
  setDirty: (flag: boolean) => void;
  isViewingHistoryVersion: boolean;
}

const FinancialsForm = ({
  attributes,
  isReadyToSubmitForm,
  isUpdating,
  nameModified,
  setNameModified,
  actions,
  financialAddonRules,
  submitButtonLabel,
  onSubmitTabData,
  disabled = false,
  hideSaveButton = false,
  rulesetName,
  setDirty,
  isViewingHistoryVersion,
}: IProps): JSX.Element => {
  useEffect(() => {
    actions.loadCurrentEntity();
  }, []);

  const pauseReasons = getFinancialTabPauseReasons(
    attributes,
    financialAddonRules
  );

  const { isFinancialsEditable, hasAssetsLiabilities, hasIncomeExpenditure } =
    processFinancialAddons(financialAddonRules, attributes);

  const isAssetsLiabilitiesDisabled = !hasAssetsLiabilities || disabled;
  const isIncomeExpenditureDisabled = !hasIncomeExpenditure || disabled;

  const [isEnabled, setIsEnabled] = useState({
    financials:
      !isFinancialsEditable && pauseReasons.financial
        ? YesOrNo.NO
        : getYesOrNo(attributes.financials_check),
    netWorth: {
      dynamic: { min: false, max: false },
      fixed: { min: false, max: false },
    },
    surplus: {
      dynamic: { min: false, max: false },
      fixed: { min: false, max: false },
    },
  });

  const defaultValues = {
    financialsRequired: isEnabled.financials,
    netWorth: {
      dynamic: {
        min: attributes.net_worth_indicators?.dynamic_min,
        max: attributes.net_worth_indicators?.dynamic_max,
      },
      fixed: {
        min: attributes.net_worth_indicators?.fixed_min,
        max: attributes.net_worth_indicators?.fixed_max,
      },
    },
    surplus: {
      dynamic: {
        min: attributes.surplus_indicators?.dynamic_min,
        max: attributes.surplus_indicators?.dynamic_max,
      },
      fixed: {
        min: attributes.surplus_indicators?.fixed_min,
        max: attributes.surplus_indicators?.fixed_max,
      },
    },
    property: {
      min: attributes.property_ownership_indicator?.fixed_min,
      minNet: attributes.net_property_value_indicator?.fixed_min,
    },
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm<IFormFields>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    setDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const isEnabled = {
      financials:
        isFinancialsEditable || isViewingHistoryVersion
          ? getYesOrNo(attributes.financials_check)
          : YesOrNo.NO,
      netWorth: {
        dynamic: {
          min:
            !isAssetsLiabilitiesDisabled &&
            !!attributes.net_worth_indicators?.dynamic_min,
          max:
            !isAssetsLiabilitiesDisabled &&
            !!attributes.net_worth_indicators?.dynamic_max,
        },
        fixed: {
          min:
            !isAssetsLiabilitiesDisabled &&
            !!attributes.net_worth_indicators?.fixed_min,
          max:
            !isAssetsLiabilitiesDisabled &&
            !!attributes.net_worth_indicators?.fixed_max,
        },
      },
      surplus: {
        dynamic: {
          min:
            !isIncomeExpenditureDisabled &&
            !!attributes.surplus_indicators?.dynamic_min,
          max:
            !isIncomeExpenditureDisabled &&
            !!attributes.surplus_indicators?.dynamic_max,
        },
        fixed: {
          min:
            !isIncomeExpenditureDisabled &&
            !!attributes.surplus_indicators?.fixed_min,
          max:
            !isIncomeExpenditureDisabled &&
            !!attributes.surplus_indicators?.fixed_max,
        },
      },
    };
    setIsEnabled(isEnabled);
  }, [attributes]);

  useEffect(() => {
    const newDefaultValues = {
      ...defaultValues,
      financialsRequired: getYesOrNo(attributes.financials_check),
    };
    reset(newDefaultValues);
  }, [attributes]);

  const submitSuccessCallback = (data) => {
    const { attributes } = data;
    // need to reset the form with updated data as the default values so that the isDirty and other state
    // are reset as if the form is just loaded.
    reset({
      financialsRequired: isEnabled.financials,
    });
    setNameModified(false);
    actions.loadAutoDecisions();
  };

  const onSubmit = (values: IFormFields): void => {
    const isReady = isReadyToSubmitForm();
    if (isReady) {
      const isFinancialsRequired = values.financialsRequired === YesOrNo.YES;
      let rulesetPartial: IFinancials = {
        name: rulesetName,
        financials_status: 'complete',
        financials_check: isFinancialsRequired,
        net_worth_indicators: {},
        surplus_indicators: {},
        property_ownership_indicator: {},
        net_property_value_indicator: {},
      };
      if (isFinancialsRequired) {
        rulesetPartial.net_worth_indicators.dynamic_min = isEnabled.netWorth
          .dynamic.min
          ? values.netWorth.dynamic.min
          : null;

        rulesetPartial.net_worth_indicators.dynamic_max = isEnabled.netWorth
          .dynamic.max
          ? values.netWorth.dynamic.max
          : null;

        rulesetPartial.net_worth_indicators.fixed_min = isEnabled.netWorth.fixed
          .min
          ? values.netWorth.fixed.min
          : null;

        rulesetPartial.net_worth_indicators.fixed_max = isEnabled.netWorth.fixed
          .max
          ? values.netWorth.fixed.max
          : null;

        rulesetPartial.surplus_indicators.dynamic_min = isEnabled.surplus
          .dynamic.min
          ? values.surplus.dynamic.min
          : null;

        rulesetPartial.surplus_indicators.dynamic_max = isEnabled.surplus
          .dynamic.max
          ? values.surplus.dynamic.max
          : null;

        rulesetPartial.surplus_indicators.fixed_min = isEnabled.surplus.fixed
          .min
          ? values.surplus.fixed.min
          : null;

        rulesetPartial.surplus_indicators.fixed_max = isEnabled.surplus.fixed
          .max
          ? values.surplus.fixed.max
          : null;

        rulesetPartial.property_ownership_indicator.fixed_min =
          values.property.min;
        rulesetPartial.net_property_value_indicator.fixed_min =
          values.property.minNet;
      }
      onSubmitTabData(rulesetPartial);
    }
  };

  const handleChangeFinancialsRequired = (value) => {
    setIsEnabled((prev) => ({ ...prev, financials: value }));
  };

  const minFixedNetWorthValue = watch('netWorth.fixed.min');
  const maxFixedNetWorthValue = watch('netWorth.fixed.max');
  const minDynamicNetWorthValue = watch('netWorth.dynamic.min');
  const maxDynamicNetWorthValue = watch('netWorth.dynamic.max');
  const minFixedSurplusValue = watch('surplus.fixed.min');
  const maxFixedSurplusValue = watch('surplus.fixed.max');
  const minDynamicSurplusValue = watch('surplus.dynamic.min');
  const maxDynamicSurplusValue = watch('surplus.dynamic.max');
  const minPropertyValue = watch('property.min');
  const minNetPropertyValue = watch('property.minNet');

  let hasEmptyFields = isEnabled.financials === YesOrNo.NOT_SELECTED;

  if (isEnabled.financials === YesOrNo.YES) {
    const fields = [
      {
        enabled: isEnabled.netWorth.fixed.min,
        value: minFixedNetWorthValue,
      },
      {
        enabled: isEnabled.netWorth.fixed.max,
        value: maxFixedNetWorthValue,
      },
      {
        enabled: isEnabled.netWorth.dynamic.min,
        value: minDynamicNetWorthValue,
      },
      {
        enabled: isEnabled.netWorth.dynamic.max,
        value: maxDynamicNetWorthValue,
      },
      {
        enabled: isEnabled.surplus.fixed.min,
        value: minFixedSurplusValue,
      },
      {
        enabled: isEnabled.surplus.fixed.max,
        value: maxFixedSurplusValue,
      },
      {
        enabled: isEnabled.surplus.dynamic.min,
        value: minDynamicSurplusValue,
      },
      {
        enabled: isEnabled.surplus.dynamic.max,
        value: maxDynamicSurplusValue,
      },
      { enabled: !isAssetsLiabilitiesDisabled, value: minPropertyValue },
      {
        enabled: !isAssetsLiabilitiesDisabled,
        value: minNetPropertyValue,
      },
    ];
    hasEmptyFields = fields.some((config) => config.enabled && !config.value);
  }

  let isSaveButtonDisabled =
    (!isDirty && !nameModified) || hasEmptyFields || disabled;

  if (!isFinancialsEditable && pauseReasons.financial) {
    isSaveButtonDisabled = false;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RuleSetSection title="Financials">
        <FormFieldSection
          hasPausedMessage={pauseReasons.financial}
          id="financialsRequired"
          label="Do you require financials?"
        >
          <YesOrNoRadioBoxGroup
            name="financialsRequired"
            control={control}
            value={isEnabled.financials}
            onChange={handleChangeFinancialsRequired}
            disabled={!isFinancialsEditable || disabled}
          />
        </FormFieldSection>
      </RuleSetSection>

      {isEnabled.financials === YesOrNo.YES && (
        <>
          <RuleSetSection
            title="Net worth indicator (Assets - Liabilities)"
            description="You can set up a fixed range and or a dynamic range of net worth
            required for auto decisioning."
            disabled={isAssetsLiabilitiesDisabled}
          >
            <FormFieldSection
              id="fixedNetWorth"
              inputClassName="is-12"
              label="Fixed range - Please set the range of net worth"
            >
              <LimitRangeWithControls
                checkBoxIds={{
                  minCheckBoxId: 'netWorth.fixed.min.checkbox',
                  maxCheckBoxId: 'netWorth.fixed.max.checkbox',
                }}
                isChecked={{
                  isMinChecked: isEnabled.netWorth.fixed.min,
                  isMaxChecked: isEnabled.netWorth.fixed.max,
                }}
                onCheck={(key, value) => {
                  setIsEnabled((prev) => ({
                    ...prev,
                    netWorth: {
                      ...prev.netWorth,
                      fixed: { ...prev.netWorth.fixed, [key]: value },
                    },
                  }));
                }}
                registers={{
                  minRegister: register('netWorth.fixed.min'),
                  maxRegister: register('netWorth.fixed.max', {
                    validate:
                      isEnabled.netWorth.fixed.min &&
                      isEnabled.netWorth.fixed.max
                        ? (value) =>
                            (value &&
                              minFixedNetWorthValue &&
                              value >= minFixedNetWorthValue) ||
                            'The value must be greater than minimum value'
                        : undefined,
                  }),
                }}
                errors={{
                  minError: errors?.netWorth?.fixed?.min?.message,
                  maxError: errors?.netWorth?.fixed?.max?.message,
                }}
                type="currency"
                isDisabled={isAssetsLiabilitiesDisabled}
              />
            </FormFieldSection>
          </RuleSetSection>

          <RuleSetSection title="" disabled={isAssetsLiabilitiesDisabled}>
            <FormFieldSection
              id="dynamicNetWorth"
              inputClassName="is-12"
              label="Dynamic range - Please set the range of times the net worth needs to be over and above the credit limit being applied for"
            >
              <LimitRangeWithControls
                checkBoxIds={{
                  minCheckBoxId: 'netWorth.dynamic.min.checkbox',
                  maxCheckBoxId: 'netWorth.dynamic.max.checkbox',
                }}
                isChecked={{
                  isMinChecked: isEnabled.netWorth.dynamic.min,
                  isMaxChecked: isEnabled.netWorth.dynamic.max,
                }}
                onCheck={(key, value) => {
                  setIsEnabled((prev) => ({
                    ...prev,
                    netWorth: {
                      ...prev.netWorth,
                      dynamic: { ...prev.netWorth.dynamic, [key]: value },
                    },
                  }));
                }}
                registers={{
                  minRegister: register('netWorth.dynamic.min'),
                  maxRegister: register('netWorth.dynamic.max', {
                    validate:
                      isEnabled.netWorth.dynamic.min &&
                      isEnabled.netWorth.dynamic.max
                        ? (value) =>
                            (value &&
                              minDynamicNetWorthValue &&
                              value >= minDynamicNetWorthValue) ||
                            'The value must be greater than minimum value'
                        : undefined,
                  }),
                }}
                errors={{
                  minError: errors?.netWorth?.dynamic?.min?.message,
                  maxError: errors?.netWorth?.dynamic?.max?.message,
                }}
                type="number"
                isDisabled={isAssetsLiabilitiesDisabled}
              />
            </FormFieldSection>
          </RuleSetSection>

          <RuleSetSection
            title="Surplus indicator (Income - Expenditure)"
            description="You can set up a fixed range and or a dynamic range of surplus required for auto decisioning."
            disabled={isIncomeExpenditureDisabled}
          >
            <FormFieldSection
              id="fixedSurplus"
              inputClassName="is-12"
              label="Fixed range - Please set the range of surplus"
            >
              <LimitRangeWithControls
                checkBoxIds={{
                  minCheckBoxId: 'surplus.fixed.min.checkbox',
                  maxCheckBoxId: 'surplus.fixed.max.checkbox',
                }}
                isChecked={{
                  isMinChecked: isEnabled.surplus.fixed.min,
                  isMaxChecked: isEnabled.surplus.fixed.max,
                }}
                onCheck={(key, value) => {
                  setIsEnabled((prev) => ({
                    ...prev,
                    surplus: {
                      ...prev.surplus,
                      fixed: { ...prev.surplus.fixed, [key]: value },
                    },
                  }));
                }}
                registers={{
                  minRegister: register('surplus.fixed.min'),
                  maxRegister: register('surplus.fixed.max', {
                    validate:
                      isEnabled.surplus.fixed.min && isEnabled.surplus.fixed.max
                        ? (value) =>
                            (value &&
                              minFixedSurplusValue &&
                              value >= minFixedSurplusValue) ||
                            'The value must be greater than minimum value'
                        : undefined,
                  }),
                }}
                errors={{
                  minError: errors?.surplus?.fixed?.min?.message,
                  maxError: errors?.surplus?.fixed?.max?.message,
                }}
                type="currency"
                isDisabled={isIncomeExpenditureDisabled}
              />
            </FormFieldSection>
          </RuleSetSection>

          <RuleSetSection disabled={isIncomeExpenditureDisabled}>
            <FormFieldSection
              id="dynamicSurplus"
              inputClassName="is-12"
              label="Dynamic range - Please set the range of times the surplus needs to be over and above the credit limit being applied for"
            >
              <LimitRangeWithControls
                checkBoxIds={{
                  minCheckBoxId: 'surplus.dynamic.min.checkbox',
                  maxCheckBoxId: 'surplus.dynamic.max.checkbox',
                }}
                isChecked={{
                  isMinChecked: isEnabled.surplus.dynamic.min,
                  isMaxChecked: isEnabled.surplus.dynamic.max,
                }}
                onCheck={(key, value) => {
                  setIsEnabled((prev) => ({
                    ...prev,
                    surplus: {
                      ...prev.surplus,
                      dynamic: { ...prev.surplus.dynamic, [key]: value },
                    },
                  }));
                }}
                registers={{
                  minRegister: register('surplus.dynamic.min'),
                  maxRegister: register('surplus.dynamic.max', {
                    validate:
                      isEnabled.surplus.dynamic.min &&
                      isEnabled.surplus.dynamic.max
                        ? (value) =>
                            (value &&
                              minDynamicSurplusValue &&
                              value >= minDynamicSurplusValue) ||
                            'The value must be greater than minimum value'
                        : undefined,
                  }),
                }}
                errors={{
                  minError: errors?.surplus?.dynamic?.min?.message,
                  maxError: errors?.surplus?.dynamic?.max?.message,
                }}
                type="number"
                isDisabled={isIncomeExpenditureDisabled}
              />
            </FormFieldSection>
          </RuleSetSection>

          <RuleSetSection
            title="Property ownership"
            disabled={isAssetsLiabilitiesDisabled}
          >
            <PropertySectionContainer>
              <InputRow
                left={<Typography>Minimum Property value</Typography>}
                center={
                  <RHFBorderedTextField
                    control={control}
                    isMoney
                    name="property.min"
                    readOnly={isAssetsLiabilitiesDisabled}
                  />
                }
              />

              <InputRow
                left={<Typography>Minimum Net property value</Typography>}
                center={
                  <RHFBorderedTextField
                    control={control}
                    isMoney
                    name="property.minNet"
                    readOnly={isAssetsLiabilitiesDisabled}
                    rules={{
                      max: {
                        value: minPropertyValue || 0,
                        message:
                          'The value cannot be greater than the Minimum Property Value',
                      },
                    }}
                  />
                }
                right={
                  <Typography>
                    Net property value = Property value - mortgage owing
                  </Typography>
                }
              />
            </PropertySectionContainer>
          </RuleSetSection>
        </>
      )}

      {!hideSaveButton ? (
        <Button
          type="submit"
          className="mt-6"
          text={submitButtonLabel}
          disabled={isSaveButtonDisabled}
          loading={isUpdating}
          loading_text="Saving..."
        />
      ) : null}
    </form>
  );
};

const mapStateToProps = (state, props) => ({
  currentRuleSet: state.add_ons.auto_decisions[props.rulesetIndex],
  financialAddonRules: state.add_ons.financials,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCurrentEntity,
      loadAutoDecisions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialsForm);
