import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ColorValue = createClass({
  propTypes: {
    children: PropTypes.node,
    placeholder: PropTypes.string,
    value: PropTypes.object,
  },
  render() {
    var colorStyle = {
      borderRadius: 3,
      display: 'inline-block',
      marginRight: 10,
      position: 'relative',
      top: -2,
      verticalAlign: 'middle',
      minWidth: '10px',
      minHeight: '10px',
      borderRadius: '50%',
      backgroundColor: this.props.value.color,
    };
    if (!this.props.value.color) {
      colorStyle.border = '1px solid #00afef';
    }
    return (
      <div className="Select-value" title={this.props.value.title}>
        <span className="Select-value-label">
          <div style={colorStyle} />
          {this.props.children}
        </span>
      </div>
    );
  },
});

export default ColorValue;
