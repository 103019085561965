import styles from 'modules/consumer-onboarding/components/paperless/css/Paperless.css';
import inputProperties from 'modules/consumer-onboarding/components/paperless/utils/inputProperties';
import TextInput from 'modules/shared/components/inputs/TextInput';
import React, { Fragment } from 'react';
import { NEW_ZEALAND_ACCOUNT_NUMBER_FORMAT } from 'utils/direct-debit/accountNumberFormat';

const formatBankNumber = (answers, onSetValue) => {
  const value = answers['accountNumber'];
  const valueArray = value.split('-');

  // Clean input if value is empty
  if (valueArray.every((group) => !group.split('_').some((s) => s))) {
    return onSetValue(null, 'accountNumber');
  }

  const replacedValue = valueArray
    .map((group, index) => {
      if (index !== 0 && group.includes('_')) {
        const replacedString = group.match(/_/g || []).map(() => '0');
        return replacedString.join('') + group.replace(/_/g, '');
      }

      return group;
    })
    .join('-');

  if (replacedValue === value) {
    return;
  }

  onSetValue(replacedValue, 'accountNumber');
};

const NewZealandPaperlessDetails = (props) => {
  const { answers, error, onSetState, onSetValue } = props;

  return (
    <Fragment>
      <div className={styles.half_col}>
        <TextInput
          id="account"
          label={inputProperties.account.label}
          required={inputProperties.account.require}
          onChange={(event) => onSetValue(event.target.value, 'account')}
          handleBlur={(_) => onSetState({ triggerValidation: true })}
          value={answers.account}
          error={error.account}
        />
      </div>
      <div className={styles.half_col}>
        <TextInput
          id="accountNumber"
          label={inputProperties.accountNumber.label}
          required={inputProperties.accountNumber.require}
          onChange={(event) => onSetValue(event.target.value, 'accountNumber')}
          handleBlur={(_) => {
            formatBankNumber(answers, onSetValue);
            onSetState({ triggerValidation: true });
          }}
          value={answers.accountNumber}
          error={error.accountNumber}
          placeholder="xx-xxxx-xxxxxxxx-xxx"
          mask={NEW_ZEALAND_ACCOUNT_NUMBER_FORMAT}
        />
      </div>
    </Fragment>
  );
};

export default NewZealandPaperlessDetails;
