/* Import libraries */
import React from 'react';
import { connect } from 'react-redux';

/*Import css*/
import styles from './css/NotesModal.css';

/* Import components */
import CircleModal from 'modules/shared/components/widgets/static/CircleModal';
import FileWidgetButton from 'modules/shared/components/widgets/interactive/FileWidgetButton';
import Button from 'modules/shared/components/inputs/Button';
import LeftArrowButton from 'modules/shared/components/inputs/LeftArrowButton';
import TextArea from 'modules/shared/components/inputs/TextArea';
import TextInput from 'modules/shared/components/inputs/TextInput';

var NotesModal = createClass({
  handleChange: function(event) {
    if (event.target.name === 'note-name') {
      this.setState({
        note_name: event.target.value,
      });
    }
    if (event.target.name === 'note-text') {
      this.setState({
        note_text: event.target.value,
      });
    }
  },

  handleBlur: function(event) {
    if (event.hasOwnProperty('target')) {
      this.checkValid(event.target.name, event.target.value);
    }
  },

  checkValid: function(key, value) {
    const { form_errors } = this.state;
    switch (key) {
      case 'note-name':
        if (value.length < 1) {
          this.setState({
            form_errors: {
              ...form_errors,
              name: "You must enter note's name.",
            },
          });
          return false;
        } else {
          const { name, ...rest } = form_errors;
          this.setState({
            form_errors: { ...rest },
          });
          return true;
        }
        break;
    }
  },

  fileUploaded: function(data) {
    this.setState({
      file: data,
    });
  },

  getInitialState: function() {
    return {
      note_name: '',
      note_text: '',
      form_errors: {},
    };
  },

  save: function() {
    const { handleSave, notename, noteid } = this.props;
    const { note_name, note_text, file } = this.state;
    if (this.checkValid('note-name', note_name) && !noteid) {
      handleSave({
        name: note_name,
        text: note_text,
        file,
      });
    }
    if (noteid) {
      handleSave({
        name: notename,
        text: note_text,
        parent_id: noteid,
        file,
      });
    }
  },

  render: function() {
    const { title, handleCancel, notename, noteid } = this.props;
    const { note_name, note_text, form_errors } = this.state;
    let body, value;
    let noteNameInput = (
      <TextInput
        id="note-name"
        name="note-name"
        error={form_errors['name']}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        label="Note name"
        required={true}
        value={note_name}
      />
    );
    if (noteid) {
      noteNameInput = (
        <TextInput
          id="note-name"
          name="note-name"
          label="Note name"
          value={notename}
          required={true}
          disabled
        />
      );
    }

    body = (
      <div style={{ marginTop: '1em' }}>
        {noteNameInput}
        <div className={styles.textarea}>
          <TextArea
            id="note-text"
            key={'note-text'}
            name={'note-text'}
            label="Note Content"
            value={note_text}
            handleChange={this.handleChange}
          />
        </div>
        <FileWidgetButton
          text={'upload attachment'}
          handleFileCallback={this.fileUploaded}
        />
        <div className={styles.button}>
          <Button text="close" white={true} handleClick={handleCancel} />
          <Button text={'save'} handleClick={this.save} />
        </div>
      </div>
    );

    return (
      <CircleModal title_css="title" title={title}>
        {body}
      </CircleModal>
    );
  },
});

// var defaults = {
//     title: 'Upload notes',
// };

module.exports = connect((state, ownProps) => {
  return {
    title: ownProps.title,
  };
})(NotesModal);
