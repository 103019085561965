import Modal from 'modules/dashboard/containers/components/Modal';
import Button from 'modules/shared/components/inputs/Button';
import React, { ReactElement } from 'react';

import { BulkReassignButtonWrapper, BulkReassignModalTitle } from './styles';

type IBulkReassignModal = {
  onConfirm: () => void;
  onCancel: () => void;
  selectedUser: string;
  currentUser: string;
};

function BulkReassignModal(props: IBulkReassignModal): ReactElement {
  const { onConfirm, onCancel, selectedUser, currentUser } = props;

  return (
    <Modal
      onClose={onCancel}
      title={
        <BulkReassignModalTitle>
          <span>Confirm reassign</span>
        </BulkReassignModalTitle>
      }
      content={
        <p>
          {`Applications under ${currentUser} will be reassigned to ${selectedUser}.
              Any applications with IUF or credit approval
              in progress can only be reallocated after the approval
              is completed or reverted.`}
        </p>
      }
      footer={
        <BulkReassignButtonWrapper>
          <Button text="Confirm" handleClick={onConfirm} />
          <Button text="Cancel" white={true} handleClick={onCancel} />
        </BulkReassignButtonWrapper>
      }
    />
  );
}

export default BulkReassignModal;
