import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user
 */
const ApplicationMonthlyReports = function(config) {
  this.axios = axios(config);
  this.type = 'application_monthly_report';
};

ApplicationMonthlyReports.prototype.getMonthlyReport = function(
  id,
  entity_type,
  date,
  success,
  error
) {
  return this.axios
    .get(
      `/users/${id}/${this.type}/${entity_type}/${date.year}/${date.month + 1}`,
      { params: { timezone: date.timezone } }
    )
    .then(success)
    .catch(error);
};

export default ApplicationMonthlyReports;
