import axios from './axios';

export default class ReportingTierOptions {
  constructor(config) {
    this.axios = axios(config);
    this.type = 'reporting_tier_options';
    this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace(
      'v1',
      'v2'
    );
    this.path = `/${this.type}`;
  }

  get(attributes) {
    return this.axios.get(`/reports/report_options`, {
      data: attributes,
    });
  }
}
