import React from 'react';

import ReactTimeout from 'react-timeout';
import { connect } from 'react-redux';

import styles from './css/SimpleLoader.css';

var SimpleLoader = createClass({
  render: function() {
    const { css_class } = this.props;
    let style = styles.loader;

    if (css_class) {
      style = styles[css_class];
    }

    return (
      <div className={style}>
        <svg className={styles.loading} width="42" height="42">
          <defs>
            <linearGradient id="gradientStroke">
              <stop offset="0%" stopColor="var(--main-color)" />
              <stop offset="100%" stopColor="#ffffff" />
            </linearGradient>
          </defs>
          <circle
            cx="21"
            cy="21"
            r="18"
            fill="none"
            stroke="url(#gradientStroke)"
            strokeWidth="4"
          />
        </svg>
      </div>
    );
  },
});

export default SimpleLoader;
