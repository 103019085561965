import { AxiosInstance, AxiosRequestConfig } from 'axios';

import axios from './axios';

export default class BlockedResources {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = 'blocked_resources';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  isResourceBlocked(params: {
    value: string;
    type: 'Email' | 'Ip';
    supplierId: string;
  }) {
    return this.axios.get(`${this.path}`, {
      params,
    });
  }
}
