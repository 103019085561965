import axios from './axios';

/**
 * https://api-1centre-dev.herokuapp.com/docs/v1#users-creating-a-user-entity-link
 */
const ApplicationReviews = function(config) {
  config.timeout = 30000;
  this.axios = axios(config);
  this.type = 'reviews';
};

ApplicationReviews.prototype.create = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        attributes,
        type: this.type,
      },
    })
    .then(success)
    .catch(error);
};

ApplicationReviews.prototype.get = function(id, success, error, options = {}) {
  return this.axios
    .get(`/${this.type}/${id}`, options)
    .then(success)
    .catch(error);
};

ApplicationReviews.prototype.escalate = function(
  reviewId,
  notes,
  userEscalationId
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.post(`/${this.type}/${reviewId}/escalate`, {
    data: {
      attributes: { notes, escalated_for_id: userEscalationId },
    },
  });
};

ApplicationReviews.prototype.deescalate = function(
  reviewId,
  escalationId,
  notes
) {
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');

  return this.axios.post(
    `/${this.type}/${reviewId}/escalate/${escalationId}/deescalate`,
    {
      data: {
        attributes: {
          notes,
        },
      },
    }
  );
};

export default ApplicationReviews;
