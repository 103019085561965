import React from 'react';
import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';

type Props = {
  data: any;
};

function PPSRFinanceStatementsCollateralSummary(props: Props) {
  const { data } = props;
  const items = data ? data.items : [];

  const getSummary = () => {
    const groupedItems = {};

    items.forEach(({ collateralTypes }) => {
      collateralTypes.forEach(({ description }) => {
        groupedItems[description] = (groupedItems[description] || 0) + 1;
      });
    });

    return Object.keys(groupedItems).map((collateral, index) => {
      let total = groupedItems[collateral],
        statement = 'statement';

      if (total > 1) statement = 'statements';

      return (
        <p key={index}>
          {groupedItems[collateral]} finance {statement} with {collateral}
        </p>
      );
    });
  };

  return (
    <div className={historyStyles.container}>
      <SubHeading>PPSR Finance Statements Collateral Summary</SubHeading>
      <p>
        The following Finance Statement Collateral Items are registered on the
        PPSR against this company. Some finance statements have multiple
        collateral items so the total number of statements might be less than
        the total of collateral items.
      </p>
      <p>
        Please use the PPSR page to view complete details of PPSR Finance
        Statements.
      </p>
      {items.length === 0 && (
        <p>
          There are no Finance Statements registered on the PPSR against this
          company.
        </p>
      )}
      {items.length > 0 && getSummary()}
    </div>
  );
}

export default PPSRFinanceStatementsCollateralSummary;
