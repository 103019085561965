const reportDataPrefixMap = {
  consumer: 'NX',
  commercial: 'NY',
};

export const getCharacteristicsData = (characteristic, objectId) => {
  return characteristic.find((obj) => obj['@id'] === objectId) || {};
};

export const getEnquiryData = (characteristic, enquiryType) => {
  const reportDataPrefix = reportDataPrefixMap[enquiryType];

  const timeSinceLastEnquiry = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}8059`
    ),
    numEnquiries3months = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}8001`
    ),
    totalEnquiries3months = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}8062`
    ),
    numEnquiries5years = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}8006`
    ),
    totalEnquiries5years = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}8063`
    ),
    numDefaults = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}7516`
    ),
    totalDefaults = getCharacteristicsData(
      characteristic,
      `${reportDataPrefix}7588`
    );

  return {
    timeSinceLastEnquiry,
    numEnquiries3months,
    totalEnquiries3months,
    numEnquiries5years,
    totalEnquiries5years,
    numDefaults,
    totalDefaults,
  };
};

export const getSpecificHeadlineData = (characteristic, reportType) => {
  const reportDataPrefix = reportDataPrefixMap[reportType],
    creditEnquiries =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}8006`) ||
      {},
    defaults =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}7516`) ||
      {},
    adverseOnFile =
      characteristic.find((obj) => obj['@id'] === `${reportDataPrefix}7601`) ||
      {};
  const adverseFileValueMapping = { N: 'No', Y: 'Yes' };
  const adverseOnFileValue =
    adverseFileValueMapping[adverseOnFile.value] || adverseOnFile.value;

  return {
    creditEnquiries,
    defaults,
    adverseOnFile,
    adverseOnFileValue,
  };
};

export const getHeadlineData = (characteristic) => {
  const commercialHeadline = getSpecificHeadlineData(
    characteristic,
    'commercial'
  );
  const consumerHeadline = getSpecificHeadlineData(characteristic, 'consumer');
  const commercialAdverse =
    commercialHeadline.adverseOnFileValue || commercialHeadline.adverseOnFile;
  const commercialAdverseValue = ['Yes', 'No'].includes(commercialAdverse)
    ? commercialAdverse
    : commercialAdverse || commercialAdverse < 0
    ? 0
    : parseInt(commercialAdverse);
  const consumerAdverse =
    consumerHeadline.adverseOnFileValue || consumerHeadline.adverseOnFile;
  const consumerAdverseValue = ['Yes', 'No'].includes(commercialAdverse)
    ? consumerAdverse
    : consumerAdverse || consumerAdverse < 0
    ? 0
    : parseInt(consumerAdverse);

  const commercialCreditEnquiry = commercialHeadline.creditEnquiries
    ? commercialHeadline.creditEnquiries.value
    : 0;
  const commercialCreditEnquiryValue = parseInt(commercialCreditEnquiry);
  const consumerCreditEnquiry = consumerHeadline.creditEnquiries
    ? consumerHeadline.creditEnquiries.value
    : 0;
  const consumerCreditEnquiryValue = parseInt(consumerCreditEnquiry);

  const commercialDefaults = commercialHeadline.defaults
    ? commercialHeadline.defaults.value
    : 0;
  const commercialDefaultsValue = parseInt(commercialDefaults);
  const consumerDefaults = consumerHeadline.defaults
    ? consumerHeadline.defaults.value
    : 0;
  const consumerDefaultsValue = parseInt(consumerDefaults);

  const adverseValue =
    typeof commercialAdverseValue === 'string' ||
    typeof consumerAdverseValue === 'string'
      ? [commercialAdverseValue, consumerAdverseValue].includes('Yes')
        ? 'Yes'
        : 'No'
      : commercialAdverseValue + consumerAdverseValue;
  const creditEnquiriesValue =
    commercialCreditEnquiryValue + consumerCreditEnquiryValue;
  const defaultValue = commercialDefaultsValue + consumerDefaultsValue;

  return {
    adverseValue,
    creditEnquiriesValue,
    defaultValue,
  };
};
