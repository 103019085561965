import {
  PERMISSIONS_UPDATE_COLOR_PALETTE_ERROR,
  PERMISSIONS_UPDATE_COLOR_PALETTE_START,
  PERMISSIONS_UPDATE_COLOR_PALETTE_SUCCESS,
} from './constants';

export function permissionsFlowReducer(state, action) {
  switch (action.type) {
    case PERMISSIONS_UPDATE_COLOR_PALETTE_ERROR:
      return { ...state, settings_color_palette_loading: false };
    case PERMISSIONS_UPDATE_COLOR_PALETTE_START:
      return { ...state, settings_color_palette_loading: true };
    case PERMISSIONS_UPDATE_COLOR_PALETTE_SUCCESS:
      return {
        ...state,
        settings_color_palette: {
          ...state.settings_color_palette,
          ...action.payload,
        },
        settings_color_palette_loading: false,
      };
    default:
      return { ...state };
  }
}
