import ModuleCardholderAuthorisationFlowModel from 'models/ModuleCardholderAuthorisationFlowModel';
import styles from 'modules/card-management-authorisation/css/CardManagementAuthorisation.css';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import Loader from 'modules/shared/components/widgets/static/Loader';
import SectionCircle from 'modules/shared/components/widgets/static/SectionCircle';
import SectionDescription from 'modules/shared/components/widgets/static/SectionDescription';
import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';

import { componentFactory } from '../index';
import { addFlaggedObject } from 'conf/flaggingHelpers';

const flow = ['abstract', 'authorisation', 'terms', 'complete'];

function useCardholderAuthorisationRecord() {
  const [cardholderAuthorisation, setCardholderAuthorisation] = useState(
    new ModuleCardholderAuthorisationFlowModel()
  );

  return { cardholderAuthorisation, setCardholderAuthorisation };
}

export default function CardManagementAuthorisation(props) {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const { cardholderAuthorisation, setCardholderAuthorisation } =
    useCardholderAuthorisationRecord();

  const cardholderAuthorisationId = props.params.cardholder_authorisation_id;

  const redirect = (sectionIndex) => {
    const { location } = props;
    browserHistory.push({
      pathname: `/card-management-authorisation/${cardholderAuthorisationId}/${flow[sectionIndex]}`,
      query: location.query,
    });
  };

  const toNextSection = () => {
    setCurrentSectionIndex(currentSectionIndex + 1);
    redirect(currentSectionIndex + 1);
  };

  const toPrevSection = () => {
    setCurrentSectionIndex(currentSectionIndex - 1);
    redirect(currentSectionIndex - 1);
  };

  const toLastSection = () => {
    setCurrentSectionIndex(3);
    redirect(3);
  };

  const childComponentProps = {
    cardholderAuthorisation,
    cardholderAuthorisationId,
    setCardholderAuthorisation,
    toLastSection,
    toNextSection,
    toPrevSection,
    ...addFlaggedObject('FEATURE_FLAG_FARMLANDS_ID_UPLOAD_UPDATES', {
      allowPdfUpload: cardholderAuthorisation.allowPdfUpload,
      noIdScrape: cardholderAuthorisation.noScrape,
    }),
  };

  useEffect(() => {
    setShowLoading(true);
    cardholderAuthorisation.setInProgress(
      props.params.cardholder_authorisation_id
    );
    (async () => {
      const result = await cardholderAuthorisation.load(
        props.params.cardholder_authorisation_id
      );
      if (result.status === 200) setShowLoading(false);
    })();
  }, []);

  let content = <Loader />;

  if (cardholderAuthorisation.lockDownFlag) {
    return (
      <SectionCircle title="">
        <SectionDescription text="Looks like the application has been reviewed. Please contact your supplier for more information." />
      </SectionCircle>
    );
  }

  if (!showLoading) {
    content = (
      <div className={styles.application_content}>
        {[0, 1, 2].includes(currentSectionIndex) && (
          <div className={styles.controls}>
            <ApplicationOverlay
              trading_name={cardholderAuthorisation.attributes.supplier_name}
              logo_url={cardholderAuthorisation.attributes.logo_url}
              label={'Cardholder authorisation'}
              color={'white'}
            />
          </div>
        )}
        {componentFactory(flow[currentSectionIndex], childComponentProps)}
      </div>
    );
  }

  return <div className={styles.container}>{content}</div>;
}
