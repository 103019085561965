import React, { useContext } from 'react';
import styled from 'styled-components';
import { Divider } from '@material-ui/core';

import { CustomerViewContext } from './CustomerViewContext';

const Container = styled.div`
  margin-top: 28px;
`;

const LogsContaioner = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 160px;
  overflow-y: scroll;
`;

export const HistoryLogs = () => {
  const { history } = useContext(CustomerViewContext);

  return (
    <Container>
      <Divider />
      <LogsContaioner>
        {history.map((historyItem, index) => (
          <p key={index}>{historyItem}</p>
        ))}
      </LogsContaioner>
    </Container>
  );
};
