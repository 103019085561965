import styled, { css } from 'styled-components';

export const SupplierLink = styled.a`
  color: var(--main-color) !important;
  cursor: pointer;
  text-decoration: underline;

  :hover {
    color: var(--main-color-active) !important;
  }
`;

export const IconHolder = styled.div`
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
`;
