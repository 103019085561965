import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Sidebar from 'react-sidebar';
import _ from 'lodash';
import seedrandom from 'seedrandom';
import { browserHistory } from 'react-router';

import styles from './css/Mapping.css';
import mapStyle from './mapStyle';
import ControlPanel from './ControlPanel';
import ResetButton from './ResetButton';
import Marker from './Marker';
import SelectKeyContact from './SelectKeyContact';
import ApplicationSelectKeyContact from './ApplicationSelectKeyContact';

import SimpleLoader from 'modules/shared/components/widgets/static/SimpleLoader';

import * as mappingActions from '../actions';

class Mapping extends Component {
  constructor(props) {
    super(props);
    this._resetView = this._resetView.bind(this);
    this._mediaQueryChanged = this._mediaQueryChanged.bind(this);
  }
  componentWillMount() {
    this.nzCentre = [-41.2449452, 173.9944471];
    this.aucklandCentre = [-36.8630231, 174.8654693];
    this.auCentre = [-24.994167, 134.866944];

    this._mql = window.matchMedia(`(min-width: 800px)`);
    this._mql.addListener(this._mediaQueryChanged);
    this.setState({ sidebarDocked: this._mql.matches });

    const { dispatch, params, current_entity } = this.props;

    let defaultMap = this.nzCentre;
    let defaultZoom = 6;
    if (current_entity.region === 'AU') {
      defaultMap = this.auCentre;
      defaultZoom = 5;
    }

    this.props.mappingActions.set({ centre: defaultMap, zoom: defaultZoom });

    //dispatch(mappingActions.getEntities())
    dispatch(mappingActions.loadConsumerApplications());
    dispatch(mappingActions.loadConnections());
  }
  componentWillUnmount() {
    this._mql.removeListener(this._mediaQueryChanged);
  }
  _mediaQueryChanged() {
    this.props.mappingActions.set({
      sidebarDocked: this._mql.matches,
      sidebarOpen: this._mql.matches,
    });
  }
  _createMapsOptions(maps) {
    return {
      styles: mapStyle,
    };
  }
  _resetView() {
    const { current_entity } = this.props;

    let defaultMap = this.nzCentre;
    let defaultZoom = 6;
    if (current_entity.region === 'AU') {
      defaultMap = this.auCentre;
      defaultZoom = 5;
    }

    this.props.mappingActions.set({
      filterType: null,
      userFilter: null,
      centre: defaultMap,
      zoom: defaultZoom,
    });
  }
  _getApplications() {
    const { params } = this.props;
    const { applications } = this.props.mapping;

    return applications.supplier_list;
  }
  _filterEntities(entitiesList) {
    const { userFilter, filterType } = this.props.mapping;
    return usersList.filter(
      (e) =>
        !userFilter ||
        userFilter.special === 'all' ||
        e.supplier.full_name === userFilter.value
    );
  }
  _getConnectionsEntities(
    connections,
    connectionEntities,
    connectionApplications,
    connectionUsers
  ) {
    const { currentUserId } = this.props;
    const entities = connections
      .map((c) => {
        const consumerEntity = connectionEntities.find(
          (e) => e.id === c.relationships.consumer.data.id
        );
        const supplierEntity = connectionEntities.find(
          (e) => e.id === c.relationships.supplier.data.id
        );
        const application = connectionApplications.find(
          (a) => a.id === c.relationships.application.data.id
        );
        const supplier = connectionUsers.find(
          (s) => s.id === c.relationships.supplier_contact.data.id
        );
        const consumer = connectionUsers.find(
          (s) => s.id === c.relationships.consumer_contact.data.id
        );

        return {
          supplier: supplier.attributes,
          supplierEntity: supplierEntity.attributes,
          consumer: consumer.attributes,
          latitude: consumerEntity.attributes.latitude,
          longitude: consumerEntity.attributes.longitude,
          color: this._setColor(supplier.attributes.full_name),
          attr: consumerEntity.attributes,
          application: application,
          type: 'consumer',
          id: c.id,
          archived: application.attributes.archived,
        };
      })
      .filter((e) => e.archived === false);
    return entities;
  }
  _getApplicationsEntities(
    applications,
    applicationEntities,
    applicationUsers
  ) {
    const { currentUserId, current_entity } = this.props;
    const suppliers = applications.map((a) => {
      if (!a.relationships.consumer) return;
      if (a.attributes.status !== 'in_progress') return;
      const consumerEntity = applicationEntities.find(
        (e) => e.id === a.relationships.consumer.data.id
      );
      const supplierEntity = applicationEntities.find(
        (e) => e.id === a.relationships.supplier.data.id
      );
      const supplier = applicationUsers.find(
        (e) => e.id === a.relationships.supplier_contact.data.id
      );
      const consumer = applicationUsers.find(
        (e) => e.id === a.relationships.consumer_contact.data.id
      );

      return {
        supplier: supplier.attributes,
        supplierEntity: supplierEntity.attributes,
        consumer: consumer.attributes,
        latitude: consumerEntity.attributes.latitude,
        longitude: consumerEntity.attributes.longitude,
        color: this._setColor(supplier.attributes.full_name),
        attr: consumerEntity.attributes,
        application: a,
        type: 'consumer',
        id: a.id,
        archived: a.attributes.archived,
      };
    });
    return suppliers.filter((s) => s).filter((e) => e.archived === false);
  }
  _setColor(name) {
    if (!name) {
      name = 'Random Colour';
    }
    if (this._hueHistory[name]) {
      var hue = this._hueHistory[name];
    } else {
      for (let i = 0; i < 5; ++i) {
        var hue = seedrandom(name + 'a'.repeat(i))() * 360;
        const hasHueHistory = Object.keys(this._hueHistory).find(
          (h) => Math.abs(hue - this._hueHistory[h]) < 30
        );
        if (!hasHueHistory) {
          this._hueHistory[name] = hue;
          break;
        }
      }
    }
    const sat = seedrandom(name.toUpperCase())() * (75 - 45) + 45;
    const lum = seedrandom(name.toLowerCase())() * (60 - 50) + 50;
    return `hsl(${hue.toFixed(1)},${sat.toFixed(1)}%,${lum.toFixed(1)}%)`;
  }
  _renderSidebarContent(entities, users) {
    const filterTypes = ['Existing connections', 'Pending applications'].map(
      (v) => ({ label: v, value: v })
    );
    return (
      <ControlPanel
        filterType={this.props.mapping.filterType}
        setFilterType={this._setFilterType.bind(this)}
        userFilter={this.props.mapping.userFilter}
        setUserFilter={(userFilter) =>
          this.props.mappingActions.set({ userFilter })
        }
        entity={this.props.mapping.entity}
        setEntity={(entity) => this.props.mappingActions.set({ entity })}
        current_entity={this.props.current_entity}
        entities={entities}
        users={users}
        filterTypes={filterTypes}
      />
    );
  }
  _setFilterType(filterType) {
    this.props.mappingActions.set({ filterType });
  }
  _renderApplicationEditContactModal() {
    // I have a connection, supplier contact, consumer contact, both entities
    const {
      applications,
      applicationEntities,
      applicationUsers,
      applicationId,
    } = this.props.mapping;
    const application = applications.find((c) => c.id === applicationId);
    const consumerEntity = applicationEntities.find(
      (e) => e.id === application.relationships.consumer.data.id
    );
    const supplierEntity = applicationEntities.find(
      (e) => e.id === application.relationships.supplier.data.id
    );
    const supplierContact = applicationUsers.find(
      (s) => s.id === application.relationships.supplier_contact.data.id
    );
    const consumerContact = applicationUsers.find(
      (s) => s.id === application.relationships.consumer_contact.data.id
    );
    const props = {
      applicationId,
      application,
      consumerEntity,
      supplierEntity,
      supplierContact,
      consumerContact,
    };
    return (
      <ApplicationSelectKeyContact
        {...props}
        hide={(save) => {
          if (!save) {
            this.props.mappingActions.set({ applicationId: null });
            return;
          }
          this.props.mappingActions.set({ applicationId: null, entity: null });
          this.props.dispatch(mappingActions.loadConsumerApplications());
        }}
      />
    );
  }
  _renderEditContactModal() {
    // I have a connection, supplier contact, consumer contact, both entities
    const {
      connections,
      connectionEntities,
      connectionUsers,
      connectionId,
    } = this.props.mapping;
    const connection = connections.find((c) => c.id === connectionId);
    const consumerEntity = connectionEntities.find(
      (e) => e.id === connection.relationships.consumer.data.id
    );
    const supplierEntity = connectionEntities.find(
      (e) => e.id === connection.relationships.supplier.data.id
    );
    const supplierContact = connectionUsers.find(
      (s) => s.id === connection.relationships.supplier_contact.data.id
    );
    const consumerContact = connectionUsers.find(
      (s) => s.id === connection.relationships.consumer_contact.data.id
    );
    const props = {
      connectionId,
      connection,
      consumerEntity,
      supplierEntity,
      supplierContact,
      consumerContact,
    };
    return (
      <SelectKeyContact
        {...props}
        hide={(save) => {
          if (!save) {
            this.props.mappingActions.set({ connectionId: null });
            return;
          }
          this.props.mappingActions.set({ connectionId: null, entity: null });
          this.props.dispatch(mappingActions.loadConnections());
        }}
      />
    );
  }

  _hidePricingPlan() {
    browserHistory.goBack();
  }

  render() {
    const {
      userFilter,
      filterType,
      entitiesLoading,
      entity,
      connections,
      connectionEntities,
      connectionApplications,
      connectionsLoading,
      connectionUsers,
      applications,
      applicationEntities,
      applicationUsers,
    } = this.props.mapping;

    const { current_entity } = this.props;

    let defaultMap = this.nzCentre;
    let defaultZoom = 6;
    if (current_entity.region === 'AU') {
      defaultMap = this.auCentre;
      defaultZoom = 5;
    }

    if (connectionsLoading || entitiesLoading || !connectionEntities) {
      return <SimpleLoader />;
    }

    // This is kinda global to subsequent calls just for render, and I haven't bothered to dep inject it
    this._hueHistory = {};
    const entitiesList =
      !filterType || filterType.value === 'Existing connections'
        ? this._getConnectionsEntities(
            connections,
            connectionEntities,
            connectionApplications,
            connectionUsers
          )
        : this._getApplicationsEntities(
            applications,
            applicationEntities,
            applicationUsers
          );

    const usersList = _.uniqBy(
      entitiesList.map((e) => ({ name: e.supplier.full_name, color: e.color })),
      'name'
    );

    const filteredEntities = entitiesList.filter(
      (e) =>
        !userFilter ||
        userFilter.special === 'all' ||
        e.supplier.full_name === userFilter.value
    );

    // entities list markers need this
    let index = -1;
    if (entity) {
      index = filteredEntities.findIndex((e) => e.id === entity.id);
    }

    return (
      <Sidebar
        sidebar={this._renderSidebarContent(filteredEntities, usersList)}
        open={this.props.mapping.sidebarOpen}
        onSetOpen={(sidebarOpen) =>
          this.props.mappingActions.set({ sidebarOpen })
        }
        docked={this.props.mapping.sidebarDocked}
        rootClassName={styles.root}
        sidebarClassName={styles.sidebar}
        transitions={false}
      >
        <div className={styles.section}>
          <div>
            <GoogleMap
              defaultCenter={defaultMap}
              defaultZoom={defaultZoom}
              onChange={(e) =>
                this.props.mappingActions.set({
                  centre: e.center,
                  zoom: e.zoom,
                })
              }
              center={this.props.mapping.centre}
              zoom={this.props.mapping.zoom}
              style={{ minHeight: '400px' }}
              bootstrapURLKeys={{
                key: window._env_.GOOGLE_CLIENT_KEY,
              }}
              options={this._createMapsOptions}
            >
              {filteredEntities.map((e, i) => (
                <Marker
                  lat={e.latitude}
                  lng={e.longitude}
                  text={i + 1}
                  selected={i === index}
                  key={'Marker-' + i}
                  onClick={
                    e.attr
                      ? () => this.props.mappingActions.set({ entity: e })
                      : null
                  }
                  color={e.color}
                />
              ))}
            </GoogleMap>
          </div>
          <ResetButton resetFunc={this._resetView} />
          {this.props.mapping.connectionId && this._renderEditContactModal()}
          {this.props.mapping.applicationId &&
            this._renderApplicationEditContactModal()}
        </div>
      </Sidebar>
    );
  }
}
export default connect(
  (state, ownProps) => ({
    mapping: state.mapping,
    current_entity: state.current_user.current_entity.attributes,
    currentUserId: state.current_user.data.data.id,
  }),
  (dispatch) => ({
    mappingActions: bindActionCreators(mappingActions, dispatch),
    dispatch,
  })
)(Mapping);
