import './ScoreBoxes.scss';

import React from 'react';

const scoreCheck = (): JSX.Element => {
  return <span className="equifax_scorebox__icon--check"></span>;
};

const scoreCross = (): JSX.Element => {
  return <span className="equifax_scorebox__icon--cross"></span>;
};

const equifaxReportImpactorMapping = Object.freeze({
  'Greatly Decreases Risk': 'SIGNIFICANTLYINCREASES',
  'Greatly Increases Risk': 'SIGNIFICANTLYDECREASES',
  'Marginally Decreases Risk': 'MARGINALLYINCREASES',
  'Marginally Increases Risk': 'MARGINALLYDECREASES',
  'Moderately Decreases Risk': 'MODERATELYINCREASES',
  'Moderately Increases Risk': 'MODERATELYDECREASES',
});

interface IScoreImapctorProps {
  impactor: string;
}

const ScoreImpactorBoxes = ({ impactor }: IScoreImapctorProps): JSX.Element => {
  let box1: null | JSX.Element = null;
  let box2: null | JSX.Element = null;
  let box3: null | JSX.Element = null;

  const finalImpactor = equifaxReportImpactorMapping[impactor] || impactor;

  switch (finalImpactor) {
    case 'MARGINALLYINCREASES':
      box1 = scoreCheck();
      break;
    case 'MODERATELYINCREASES':
      box1 = scoreCheck();
      box2 = scoreCheck();
      break;
    case 'SIGNIFICANTLYINCREASES':
      box1 = scoreCheck();
      box2 = scoreCheck();
      box3 = scoreCheck();
      break;
    case 'MARGINALLYDECREASES':
      box1 = scoreCross();
      break;
    case 'MODERATELYDECREASES':
      box1 = scoreCross();
      box2 = scoreCross();
      break;
    case 'SIGNICANTLYDECREASES': // This is the value from API. Catching both spelling if Equifax will fix this in the future
    case 'SIGNIFICANTLYDECREASES':
      box1 = scoreCross();
      box2 = scoreCross();
      box3 = scoreCross();
      break;
  }

  return (
    <div className="equifax_scorebox">
      <div className="equifax_scorebox__icon-container">{box1}</div>
      <div className="equifax_scorebox__icon-container">{box2}</div>
      <div className="equifax_scorebox__icon-container">{box3}</div>
    </div>
  );
};

export default ScoreImpactorBoxes;
