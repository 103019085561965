import store from 'stores/store';

export function storeNameAlias() {
  return (
    store.getState().current_user.current_entity.attributes.store_name_alias ||
    ''
  );
}

export function isSupplier() {
  return (
    store.getState().current_user.current_entity.attributes.supplier || false
  );
}

export function getBasicInfoProperty(
  propertyName,
  profile,
  shouldReturnEmptyString = false
) {
  const persistedProfile = profile.current_entity.attributes;
  const currentPropertyName = `settings_${propertyName}`;
  const currentUpdatedName = `${currentPropertyName}_updated`;

  // profile[currentUpdatedName] defaults load to false and updating the current value sets this to true
  // persistedProfile[propertyName] defaults load to the saved value
  // profile[currentPropertyName] defaults load to null and holds the current input value

  // allows empty input for properties that are optional
  if (
    profile[currentUpdatedName] &&
    persistedProfile[propertyName] &&
    !profile[currentPropertyName]
  ) {
    return '';
  }

  if (profile[currentPropertyName]) {
    return profile[currentPropertyName];
  }

  if (shouldReturnEmptyString) {
    return persistedProfile[propertyName] || '';
  }

  return persistedProfile[propertyName];
}
