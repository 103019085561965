/* Import libraries */
import React from 'react';
import styles from './css/CircleLogo.css';

function CircleLogo({ layout, src }) {
  let layout_style = styles.background;

  if (layout) {
    layout_style = styles[layout];
  }

  const divStyle = {
    backgroundImage: 'url(' + src + ')',
  };

  return <div style={divStyle} className={layout_style}></div>;
}

export default CircleLogo;
