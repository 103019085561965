/* eslint-disabled max-lines */
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import { DigitalOnboardingContext } from 'modules/addons/components//DigitalOnboarding';
import { DigitalOnboardingItem } from 'modules/addons/components/DigitalOnboarding';
import React, { useContext, useState } from 'react';

import WatchtowerConfirmModal from './WatchtowerConfirmModal';
import { isFeatureAvailable } from 'modules/shared/helpers/headquarterDetect';

const WatchtowerItemComponent = (props) => {
  const { addonConfig, currentUser, onUpdateModuleItem } = props;

  const { onSetAddonToEdit } = useContext(DigitalOnboardingContext);
  const onClickEdit = () => onSetAddonToEdit(addonConfig);
  const [showModal, setShowModal] = useState(false);

  const onSuccessCallback = (updatedAddonConfig) => {
    mixpanel.track('Update Alerts module ruleset', {
      'Entity ID': get(currentUser, 'currentEntity.id'),
      Ruleset: updatedAddonConfig.data,
      'Ruleset ID': updatedAddonConfig.id,
      distinct_id: currentUser.id,
    });

    onUpdateModuleItem(updatedAddonConfig);
    location.reload();
  };

  const onClickToggleState = () => {
    addonConfig.setAttribute('active', !addonConfig.active);
    addonConfig.update({ currentUser, onSuccessCallback });
    setShowModal(false);
  };

  const handleOnclickToggleState = () => {
    if (!addonConfig.active) {
      setShowModal(true);
    } else {
      onClickToggleState();
    }
  };

  const isWatchtowerAvailable = isFeatureAvailable('watchtower');
  const isWatchtowerActive = isWatchtowerAvailable && addonConfig.active;
  const isWatchtowerFormattedActiveState = isWatchtowerAvailable
    ? addonConfig.formattedActiveState
    : 'Paused';
  const isWatchtowerPersisted =
    isWatchtowerAvailable && addonConfig.isPersisted;
  const isWatchtowerConfigured =
    isWatchtowerAvailable && addonConfig.isConfigured;

  return (
    <React.Fragment>
      <DigitalOnboardingItem
        activeState={isWatchtowerActive}
        areActionButtonsVisible={isWatchtowerPersisted}
        body="Set up the Watchtower module to take control of any external and internal risks."
        formattedState={isWatchtowerFormattedActiveState}
        header="Watchtower module"
        isConfigured={isWatchtowerConfigured}
        isEnabled
        onClickEdit={isWatchtowerAvailable ? onClickEdit : null}
        onClickToggleState={handleOnclickToggleState}
      />
      {showModal && (
        <WatchtowerConfirmModal
          onCancel={() => setShowModal(false)}
          onConfirm={() => onClickToggleState()}
        />
      )}
    </React.Fragment>
  );
};

export default WatchtowerItemComponent;
