import React from 'react';

import './dashboardheader.scss';

const DashboardLogo = ({ logoUrl }) => (
  <div className="dashboard-header_logo-container">
    <div className="dashboard-header_logo">
      <img src={logoUrl} alt="logo" />
    </div>
  </div>
);

export default DashboardLogo;
