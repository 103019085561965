import axios from './axios';

let EmailPreviews = function(config) {
  config.timeout = 90000;
  this.axios = axios(config);
  this.type = 'email_previews';
};

EmailPreviews.prototype.getEmailPreview = function(attributes, success, error) {
  return this.axios
    .post(`/${this.type}`, {
      data: {
        type: this.type,
        attributes: attributes,
      },
    })
    .then(success)
    .catch(error);
};

export default EmailPreviews;
