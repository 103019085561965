/* eslint-disable max-lines */
import api from 'api';
import get from 'lodash.get';
import mixpanel from 'mixpanel-browser';
import { PPSR_MODULE_NAME, PPSR_REGION } from 'models/AddonConfigModel';
import { COLLATERAL_TYPE_OPTIONS } from 'models/PpsrFinancingStatementModel';
import {
  ContentContainer,
  DigitalOnboardingContext,
  DirtyPopup,
} from 'modules/addons/components//DigitalOnboarding';
import commonStyles from 'modules/addons/components/css/DigitalOnboarding.css';
import * as CollateralPresets from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/CollateralPresets/index';
import * as DebtorDetails from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/DebtorDetails/index';
import Header from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/Header';
import * as NoticeModalComponents from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/NoticeModal/index';
import * as PPSRFunctions from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/PPSRFunctions/index';
import * as RetrieveSecurityGroupDetails from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/RetrieveSecurityGroupDetails/index';
import SecurityPartyGroup from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/SecurityPartyGroup';
import * as NoticeModalContentComponents from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/SPGAccessCodeModal/index';
import * as ValidationSchema from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/validationSchema/index';
import * as VersionAttributes from 'modules/addons/components/digital-onboarding/PPSRRegistration/forms/versionAttributes/index';
import useAddressState from 'modules/new-applications/hooks/useAddressState';
import useIsLoadingState from 'modules/new-applications/hooks/useIsLoadingState';
import Button from 'modules/shared/components/inputs/Button';
import CloseButton from 'modules/shared/components/inputs/CloseButton';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import isBlank from 'utils/isBlank';
import isPresent from 'utils/isPresent';
import * as yup from 'yup';

function determineFormSchema(region) {
  const formSchema = ValidationSchema[`${region}ValidationSchema`];

  if (isBlank(formSchema)) {
    console.error(`Cannot determine form schema for ${region}`);
    return;
  }

  return yup.object().shape(formSchema);
}

function getNewVersionAttribute({ addonConfig, data, region }) {
  const VersionAttributesByRegion =
    VersionAttributes[`${region}VersionAttributes`];

  const config = VersionAttributesByRegion.getNewVersionConfigAttribute(
    addonConfig,
    data
  );

  const attributes = { config };

  if (addonConfig.isPersisted) {
    attributes.addon_config_id = addonConfig.id;
  } else {
    attributes.name = PPSR_MODULE_NAME[data.addonModuleName];
    attributes.addon_module_name = data.addonModuleName;
  }

  return attributes;
}

function getDefaultConfigValues({ currentEntity, newVersion, region }) {
  const VersionAttributesByRegion =
    VersionAttributes[`${region}VersionAttributes`];

  return VersionAttributesByRegion.getDefaultConfigValues(
    currentEntity,
    newVersion
  );
}

function isSPGPartyDetailsDisabled(config) {
  const spgId = get(config, 'secured_party_group.spg_id');
  const spgPwd = get(config, 'secured_party_group.spg_pwd');

  return isPresent(spgId) && isPresent(spgPwd);
}

async function retrieveSecurityGroupPartyDetails({
  accessToken,
  onErrorCallback,
  onSuccessCallback,
  region,
  spgId,
  spgPwd,
}) {
  const spgAPI = api('secured_party_groups', accessToken);

  try {
    const response = await spgAPI.getSecuredPartyGroup({
      region: region.toLowerCase(),
      spgId,
      spgPwd,
    });

    onSuccessCallback(response.data);
  } catch (error) {
    console.error(error);

    onErrorCallback(error);
  }
}

function formatAddressFromSecurePartyGroupAPI({
  address,
  onSetNewFullAddress,
  onSetNewRawAddress,
}) {
  onSetNewFullAddress(address.fullAddress);
  onSetNewRawAddress(address.rawAddress);
}

function PPSRFunctionComponent(props) {
  const { control, errors, region, register, versionConfig } = props;
  const PPSRFunctionByRegionComponent = PPSRFunctions[`${region}Functions`];

  return (
    <PPSRFunctionByRegionComponent
      control={control}
      errors={errors}
      register={register}
      versionConfig={versionConfig}
    />
  );
}

function CollateralPresetComponent(props) {
  const {
    clearError,
    collateralTypeOptionsByRegion,
    errors,
    register,
    region,
    setValue,
    versionConfig,
  } = props;

  const CollateralPresentByRegionComponent =
    CollateralPresets[`${region}CollateralPresets`];

  return (
    <CollateralPresentByRegionComponent
      clearError={clearError}
      collateralTypes={collateralTypeOptionsByRegion}
      errors={errors}
      register={register}
      setValue={setValue}
      versionConfig={versionConfig}
    />
  );
}

function DebtorDetailsComponent(props) {
  const { region } = props;

  const DebtorDetailsByRegionComponent =
    DebtorDetails[`${region}DebtorDetails`];

  return <DebtorDetailsByRegionComponent />;
}

function useNoticeModalState() {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState(null);

  const onShowModal = (message) => {
    setIsVisible(true);
    setMessage(message);
  };

  const onHideModal = () => setIsVisible(false);

  return { isVisible, message, onHideModal, onShowModal };
}

function NoticeModal(props) {
  const { isVisible, message, onClose, region } = props;

  if (!isVisible) {
    return null;
  }

  const NoticeModalByRegionComponent =
    NoticeModalComponents[`${region}NoticeModal`];

  return <NoticeModalByRegionComponent message={message} onClose={onClose} />;
}

function NoticeModalContent(props) {
  const { region, ...contentProps } = props;
  const NoticeModalContentByRegionComponent =
    NoticeModalContentComponents[`${region}SPGAccessCodeModal`];

  return <NoticeModalContentByRegionComponent {...contentProps} />;
}

function shouldNotifyUser({ addonModuleName, securedPartyGroup }) {
  return (
    addonModuleName === 'ppsr_australia_module' &&
    isBlank(securedPartyGroup.spg_pwd)
  );
}

export default function Edit(props) {
  const { addonConfig, currentUser, onRefreshDigitalOnboardingModules } = props;
  const { addonModuleName } = addonConfig;
  const region = PPSR_REGION[addonModuleName];

  const [isDirtyPopupVisible, setIsDirtyPopupVisible] = useState(false);
  const { isLoading, setIsLoading } = useIsLoadingState();
  const [isSpgPasswordVisible, setIsSpgPasswordVisible] = useState(false);
  const noticeModalState = useNoticeModalState();

  const { onClickBack } = useContext(DigitalOnboardingContext);

  const currentEntity = currentUser.currentEntity || {};
  const newVersion = addonConfig.generateNewVersion();
  const versionConfig = getDefaultConfigValues({
    currentEntity,
    newVersion,
    region,
  });

  const [isSPGDetailsDisabled, setIsSPGDetailsDisabled] = useState(
    isSPGPartyDetailsDisabled(versionConfig)
  );

  const spgPwdRef = useRef();
  const formSchema = determineFormSchema(region);

  let stayOnPage = false;

  const {
    clearError,
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      collateralPreset: versionConfig.collateral_preset || {},
      organisationAddress: get(
        versionConfig,
        'secured_party_group.organisation.address',
        {}
      ),
      personAddress: get(
        versionConfig,
        'secured_party_group.person.address',
        {}
      ),
    },
    mode: 'onBlur',
    validationSchema: formSchema,
  });
  const { dirty } = formState;

  useEffect(() => {
    register({ name: 'organisationAddress' });
    register({ name: 'personAddress' });
  }, [register]);

  const addressState = useAddressState({
    initialFullAddress: get(
      versionConfig,
      'secured_party_group.organisation.address.full_address',
      ''
    ),
    initialRawAddress: get(
      versionConfig,
      'secured_party_group.organisation.address',
      {}
    ),
    onSetAddressCallback: (newRawAddress) => {
      setValue('organisationAddress', newRawAddress);
    },
  });

  const onSetSPGPwd = ({ spgId, spgPwd, successCallback }) => {
    // TODO: setValue(name, value, { shouldDirty: false }) does not work in
    // react-hook-form version 5. There is no need to do a reset when upgrading
    // to version 6 as the dirty formState can just be set to false.
    setValue('spgPwd', spgPwd);
    setValue('organisationAddress', addressState.rawAddress);
    spgPwdRef.current.focus();
    handleSubmit(onSubmit)();

    // Need to call reset to prevent setting the `dirty` formState to true
    reset({ spgId, spgPwd });
    successCallback();
  };

  const onSuccessCallback = (addonVersion) => {
    const config = get(addonVersion, 'data.attributes.config', {});
    const {
      financing,
      functions,
      secured_party_group: securedPartyGroup,
    } = config;

    mixpanel.track('Configure PPSR module', {
      'Entity ID': currentEntity.id,
      Ruleset: {
        config: {
          financing,
          functions,
        },
      },
      distinct_id: currentUser.id,
    });

    setIsLoading(false);
    onRefreshDigitalOnboardingModules();

    const organisationEmail = get(securedPartyGroup, 'organisation.email');

    if (shouldNotifyUser({ addonModuleName, securedPartyGroup })) {
      stayOnPage = true;
      addonConfig.setId(addonVersion.addonConfigId);

      const spgId = securedPartyGroup.spg_id;

      setValue('spgId', spgId);
      // Need to call reset to prevent setting the `dirty` formState to true
      reset({ spgId });
      setIsSPGDetailsDisabled(true);

      noticeModalState.onShowModal(
        <NoticeModalContent
          email={organisationEmail}
          onSetAccessCode={(spgPwd) => {
            onSetSPGPwd({
              spgId,
              spgPwd,
              successCallback: noticeModalState.onHideModal,
            });
          }}
          region={region}
        />
      );
    }

    if (!stayOnPage) {
      onClickBack();
    }
  };

  const onSubmit = (data) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const attributes = getNewVersionAttribute({
      addonConfig,
      data: {
        addonModuleName,
        ...data,
      },
      region,
    });

    newVersion.setAttributes(attributes);
    newVersion.save({
      addonConfig,
      currentUser,
      onSuccessCallback,
    });
  };

  const onClickCloseButton = () => {
    if (dirty) {
      setIsDirtyPopupVisible(true);
    } else {
      onClickBack();
    }
  };

  const onRetrieveSecurityGroupDetails = (data, region) => {
    const RetrieveDetailsByRegion =
      RetrieveSecurityGroupDetails[`${region}RetrieveSecurityGroupDetails`];

    const retrievedDetails = RetrieveDetailsByRegion.onRetrieveSecurityGroupDetails(
      data
    );

    setValue('organisationName', retrievedDetails.organisationName, {
      shouldDirty: true,
    });
    setValue('organisationEmail', retrievedDetails.organisationEmail, {
      shouldDirty: true,
    });
    setValue('organisationPhone', retrievedDetails.organisationPhone, {
      shouldDirty: true,
    });
    setValue('personFirstName', retrievedDetails.personFirstName, {
      shouldDirty: true,
    });
    setValue('personLastName', retrievedDetails.personLastName, {
      shouldDirty: true,
    });
    formatAddressFromSecurePartyGroupAPI({
      address: retrievedDetails.address || {},
      onSetNewFullAddress: addressState.onSetNewFullAddress,
      onSetNewRawAddress: addressState.onSetNewRawAddress,
    });
  };

  const onSecuredPartyGroupAPIErrorCallback = (error) => {
    const errorMessage = get(
      error,
      'response.data.errorMessage',
      'There was an error pulling the secured party group details'
    );

    setError('spgId', 'required', errorMessage);
    setError('spgPwd', 'required', errorMessage);

    setIsSPGDetailsDisabled(false);
  };

  const onBlurSpgCredentials = () => {
    const spgId = getValues('spgId');
    const spgPwd = getValues('spgPwd');

    if (!dirty) {
      return;
    }

    if (isBlank(spgId) || isBlank(spgPwd)) {
      setIsSPGDetailsDisabled(false);
      return;
    }

    setIsSPGDetailsDisabled(true);

    retrieveSecurityGroupPartyDetails({
      accessToken: currentUser.accessToken,
      onErrorCallback: onSecuredPartyGroupAPIErrorCallback,
      onSuccessCallback: (data) => onRetrieveSecurityGroupDetails(data, region),
      region,
      spgId,
      spgPwd,
    });
  };

  const [
    collateralTypeOptionsByRegion,
    setCollateralTypeOptionsByRegion,
  ] = useState([]);

  useEffect(() => {
    setCollateralTypeOptionsByRegion(
      COLLATERAL_TYPE_OPTIONS.filter((option) => option.region === region)
    );
  }, [COLLATERAL_TYPE_OPTIONS, region]);

  return (
    <Fragment>
      <DirtyPopup
        isVisible={isDirtyPopupVisible}
        redirectUrl={null}
        onHidePopup={() => setIsDirtyPopupVisible(false)}
        onLeave={onClickBack}
        onSave={() => handleSubmit(onSubmit)()}
      />
      <NoticeModal
        isVisible={noticeModalState.isVisible}
        message={noticeModalState.message}
        region={region}
      />
      <div className={commonStyles.container}>
        <Header addonModuleName={addonModuleName} />
        <CloseButton handleClick={onClickCloseButton} />
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <SecurityPartyGroup
            addressState={addressState}
            clearError={clearError}
            currentUser={currentUser}
            errors={errors}
            isSPGDetailsDisabled={isSPGDetailsDisabled}
            isSpgPasswordVisible={isSpgPasswordVisible}
            onBlurSpgCredentials={onBlurSpgCredentials}
            region={region}
            register={register}
            setIsSpgPasswordVisible={setIsSpgPasswordVisible}
            spgPwdRef={spgPwdRef}
            versionConfig={versionConfig}
          />
          <PPSRFunctionComponent
            control={control}
            errors={errors}
            region={region}
            register={register}
            versionConfig={versionConfig}
          />
          <CollateralPresetComponent
            clearError={clearError}
            collateralTypeOptionsByRegion={collateralTypeOptionsByRegion}
            errors={errors}
            register={register}
            region={region}
            setValue={setValue}
            versionConfig={versionConfig}
          />
          <DebtorDetailsComponent region={region} />
          <ContentContainer>
            <div>
              <span className="has-text-weight-normal">IMPORTANT:</span> Once
              you save, refresh your screen. The &apos;Credit control&apos;
              feature will appear in your top menu bar and in here you&apos;ll
              see the PPSR module tab. When you complete a registration from the
              customers credit file - it will appear on this dashboard for
              consolidated viewing.
            </div>
            <div>
              <span className="has-text-weight-normal">DON&apos;T FORGET</span>{' '}
              - To register a financing statement this is all done in your
              customers credit file, accessible via your Trade Sales Pipeline.
            </div>
          </ContentContainer>
          <div>
            <Button
              text="save"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
}
