import historyStyles from 'modules/applications/components/credit-checks/credit-works/css/History.css';
import styles from 'modules/applications/components/css/CommonReviewBusiness.css';
import React, { Fragment, ReactElement } from 'react';
import { SubHeading } from 'modules/applications/components/credit-checks/credit-works/v2/Container/styles';

type Affiliation = {
  groupName: string;
  companyNumber: number;
  entityStatusDescription: string;
  vendorCount: number;
  billedThisMonth: string | null;
  dueThisMonth: string | null;
  overdue1Month: string | null;
  overdue2Months: string | null;
  overdue3Months: string | null;
  unallocated: string | null;
  totalDebt: string | null;
  creditLimit: string | null;
};

type DirectorAffiliation = Affiliation & {
  directorFirstNames: string;
  directorSurname: string;
};

type Props = {
  data: {
    directors: DirectorAffiliation[];
    childCompanies: Affiliation[];
    parentCompanies: Affiliation[];
  };
};

function Affiliations(props: Props): ReactElement {
  const { data } = props;

  const keys = [
    {
      key: 'directors',
      title: 'Director Affiliations',
    },
    {
      key: 'childCompanies',
      title: 'Child Companies',
    },
    {
      key: 'parentCompanies',
      title: 'Parent Companies',
    },
  ];

  return (
    <div className={historyStyles.container}>
      {keys.map(({ key, title }): ReactElement | null => {
        const tableData = data[key];

        if (!tableData || tableData.length === 0) {
          return null;
        }

        return (
          <Fragment key={title}>
            <SubHeading>{title}</SubHeading>
            <table className={styles.table_w20}>
              <thead>
                <tr>
                  <td>Company</td>
                  <td>Number</td>
                  <td>Status</td>
                  <td>Credit Limit</td>
                  <td>Suppliers</td>
                  <td>DSO (EoM)</td>
                </tr>
              </thead>
              <tbody>
                {tableData.map(
                  ({
                    companyNumber,
                    creditLimit,
                    entityStatusDescription,
                    groupName,
                    vendorCount,
                  }) => (
                    <tr key={`affiliation-${companyNumber}`}>
                      <td>{groupName}</td>
                      <td>{companyNumber}</td>
                      <td>{entityStatusDescription}</td>
                      <td>{creditLimit}</td>
                      <td>{vendorCount}</td>
                      <td></td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Fragment>
        );
      })}
    </div>
  );
}

export default Affiliations;
