import { AmlSettings } from 'types/AmlSettings';
import { IRuleSetAttributes } from '../type';
import { checkAmlEnabledInSettings, processAntiFraudSettings } from '../utils';

/**
 *
 * Function to check if there is a conflict between the supplied auto decision
 * ruleset and the current AML settings
 */
const checkAmlSettings = (
  amlSettings: AmlSettings,
  isAmlRequiredInRuleset: boolean
) => {
  // Let's see if AML is enabled in the supplier settings
  const isAmlApplicable = checkAmlEnabledInSettings(amlSettings);

  // If AML is required in auto decision ruleset, but the same is not enabled in
  // settings, then it means that a change in AML settings
  // was a reason why the ruleset was paused.
  return isAmlRequiredInRuleset ? !isAmlApplicable : false;
};

/**
 *
 * Function to check if there is a conflict between the supplied auto decision
 * ruleset and the current anti fraud settings
 */
const checkAntiFraudSettings = (
  antiFraudSettings: any,
  rulesetAttributes: IRuleSetAttributes
) => {
  // Let's see if anti fraud is applicable to the auto decision ruleset
  const isAntiFraudApplicable = processAntiFraudSettings(
    antiFraudSettings,
    rulesetAttributes
  );

  // If anti fraud is required in auto decision ruleset, but there is no overlap
  // with the anti fraud settings, then it means that a change in anti fraud settings
  // was a reason why the ruleset was paused.
  return rulesetAttributes.require_anti_fraud ? !isAntiFraudApplicable : false;
};

/**
 *
 * Function to check if there is a conflict between the supplied auto decision
 * ruleset and the current guarantor settings
 */
const checkGuarantorSettings = (
  isGuarantorRequiredInRuleset: boolean,
  rulesetGuarantorCount: number,
  settingsGuarantorCount: number
) => {
  // Let's see whether guarantor is required in the auto decision ruleset,
  // but the settings conflicts with the count conffigured in the rule.
  return (
    isGuarantorRequiredInRuleset &&
    rulesetGuarantorCount > 0 &&
    rulesetGuarantorCount > settingsGuarantorCount
  );
};

type GetValidationPauseReasonParams = {
  amlSettings: AmlSettings;
  antiFraudSettings: any;
  rulesetAttributes: IRuleSetAttributes;
  settingsGuarantorCount: number;
};

/**
 * Function to find out the reasons why the specified ruleset was paused,
 * if the validation tab is paused
 */
export const getValidationTabPauseReasons = (
  params: GetValidationPauseReasonParams
) => {
  const {
    amlSettings,
    antiFraudSettings,
    rulesetAttributes,
    settingsGuarantorCount,
  } = params;
  const {
    aml_verification: isAmlRequiredInRuleset,
    guarantors: isGuarantorRequiredInRuleset,
    guarantors_count: rulesetGuarantorCount,
    validation_status: status,
  } = rulesetAttributes;

  // Validation tab can get paused due to change in any one of the following settings:
  // 1. 1CAF
  // 2. AML
  // 3. Guarantor requirements

  if (status !== 'paused') {
    // The tab is not paused. We have nothing to check further.
    return {
      aml: false,
      antiFraud: false,
      guarantor: false,
    };
  }

  const aml = checkAmlSettings(amlSettings, isAmlRequiredInRuleset);

  const antiFraud = checkAntiFraudSettings(
    antiFraudSettings,
    rulesetAttributes
  );

  const guarantor = checkGuarantorSettings(
    isGuarantorRequiredInRuleset,
    rulesetGuarantorCount,
    settingsGuarantorCount
  );

  return {
    aml,
    antiFraud,
    guarantor,
  };
};
