import get from 'lodash.get';
import AuthorisationRequestAwaitingPermissionModel from 'models/AuthorisationRequestAwaitingPermissionModel';
import { setPermissionsTheme } from 'modules/request-permission-flow/actions';
import { GridContainer } from 'modules/request-permission-flow/containers/Layout/styles';
import Overlay from 'modules/shared/components/top/Overlay';
import Footer from 'modules/shared/components/v2/Footer';
import React, { cloneElement, ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const Layout = (props): ReactElement => {
  const {
    children,
    params: { token },
    dispatch,
  } = props;

  const [awaitingPermission, setAwaitingPermission] = useState<
    AuthorisationRequestAwaitingPermissionModel
  >(new AuthorisationRequestAwaitingPermissionModel());

  const [supplierLogoUrl, setSupplierLogoUrl] = useState('');
  const [isInvalidToken, setIsInvalidToken] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const record = await AuthorisationRequestAwaitingPermissionModel.fetchRecord(
          token
        );
        const supplierLogo = get(record, 'attributes.supplier_logo');
        if (supplierLogo) setSupplierLogoUrl(supplierLogo);

        const themeFromRecord = get(
          record,
          'attributes.supplier_color_palette'
        );
        const theme = themeFromRecord ? { theme: themeFromRecord } : null;
        setAwaitingPermission(record);
        dispatch(setPermissionsTheme(theme));
      } catch (error) {
        console.error(error);
        setIsInvalidToken(true);
      }
    })();
  }, [token]);

  return (
    <GridContainer>
      <Overlay supplierLogoUrl={supplierLogoUrl} withButtons={false} />
      {cloneElement(children, { awaitingPermission, isInvalidToken, token })}
      <Footer />
    </GridContainer>
  );
};

export default connect((state) => {
  const permissionsTheme = get(
    state,
    'permissions_flow.settings_color_palette'
  );

  return {
    permissionsTheme,
  };
})(Layout);
