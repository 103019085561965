import { MESSAGES_REMOVE_MESSAGE, MESSAGES_ADD_ADHOC } from './constants';
import get from 'lodash.get';

export function removeMessage(index) {
  return (dispatch, getState) => {
    dispatch({
      type: MESSAGES_REMOVE_MESSAGE,
      payload: index,
    });
  };
}

export function addMessage(text, type) {
  return (dispatch, getState) => {
    if (!type) {
      type = 'error';
    }

    dispatch({
      type: MESSAGES_ADD_ADHOC,
      payload: {
        text: text,
        type: type,
      },
    });
  };
}

export function removeMessageByText(text) {
  return (dispatch, getState) => {
    const messages = get(getState(), 'messages.messages', []);
    const index = messages.findIndex((message) => message.text === text);

    if (index >= 0) {
      dispatch(removeMessage(index));
      dispatch(removeMessageByText(text));
    }
  };
}
