import axios from './axios';

const Timezones = function(config) {
  this.axios = axios(config);
  this.type = 'timezones';
  this.axios.defaults.baseURL = this.axios.defaults.baseURL.replace('v1', 'v2');
};

//
// Await-able functions
// The following functions allows the use of async/await
//
Timezones.prototype.getTimezones = function() {
  return this.axios.get(`/${this.type}`);
};

export default Timezones;
